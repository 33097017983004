import React, {useState, useEffect, }from 'react';
import { useParams, useHistory} from "react-router-dom";
import cn from 'classnames';
import axios from 'axios';
import Filesaver from 'file-saver';
import queryString from 'query-string';

// css
import Style from './service.module.css'
// import icoRight from '@/assetsNew/image/ico/ico-arr-right.svg'

const ServiceReq = (props) => {
    // const plismImgUrl ='https://www.plism.com/images/nplism3';
    const plismImgUrl =process.env.REACT_APP_PLISM_URL+'/images/nplism3';
    const history = useHistory();
    const {id} =useParams();
    const [ tabList,setTabList ]=useState( [
        {id :'t0', tname:'서비스 요금' , active: true ,file : {}},
        {id :'t1', tname:'KL-Net 서비스 이용신청서', active: false
                , file : "KLNet.doc"},
        {id :'t2', tname:'적하목록취합 신청서' , active: false
                , file : [{'선사용': "KLNet_CARRIER.doc"},{'포워더용': "KLNet_FWD.doc"},{'장치장,운송사용': "KLNet_TMS.hwp"},{'검수사용': "KLNet_INSPECT.hwp"}]},
        {id :'t3', tname:'AFR 서비스 신청서' , active: false, file : "KLNet_AFR.doc"},
        {id :'t4', tname:'인보이스 서비스 신청서' , active: false, file : "KLNet_INVOICE.doc"},
    ])
    const [tabContent, setTabContent]= useState(tabList&&tabList.find(x=>x.active));

    useEffect( ()=>{
      if(queryString){
          let tId = queryString.parse(window.location.search);
        tId&&handleClick('tabList',tId.tab);
      }
      },[window.location.search]);

    const handleClick =(target, id)=>{
        const old= [...tabList];
        let newTarget =  old.map((item,index)=> 
            item.id==id 
            ? {...item, active: true}
            : {...item, active: false}
            
         )
        setTabContent(newTarget&&newTarget.find(x=>x.active))
        switch (target){
                case 'tabList':
                return     setTabList([...newTarget])     
        }
      
    }


    const downloadManual = (file)=>{
        axios.post("/api/downloadbyURL",{ type : 'reqManual' , file : file,}, {responseType:'blob',})
        .then(res => {
            Filesaver.saveAs(new Blob([res.data]),file);
        }).catch(err => {
            console.log(err)
            props.onAlert('error', '다운로드에 실패하였습니다. 관리자 문의바랍니다.');
        })
    }

    return (
        <div>
            <div className={Style.subHeaderWrap}>
                <div className={Style.stit}>
                    <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-sub-sunsa.png`}/>
                    <strong>서비스 이용 안내</strong>
                </div>
                {/* <ul>
                    <li onClick={()=>history.push("/")}><img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-home.png`}/></li> */}
                    {/* <li>물류정보</li> */}
                    {/* <li><strong>서비스 이용 안내</strong></li>
                </ul> */}
            </div>
            <div className={Style.sunsaPageWrap}>
                <ul className={Style.tabmenuListWrap}>
                    {tabList&&tabList.map((val, ind, arry)=>
                        <li className={cn(Style.tab, val.active? Style.on:'')} key={ind}
                            onClick={()=>{handleClick('tabList',val.id)}}>
                            {val.tname}
                        </li>
                    )} 
                </ul>
                
                 {/*서비스 이용신청서 tabid =0*/}
                 {tabContent.id  === 't0'&&
                <div className={Style.scrolls}>
                    <table>
                        <colgroup>
                            <col width="150"/>
                            <col width="120"/>
                            <col width="175"/>
                            <col width="170"/>
                            <col width=""/>
                            <col width="220"/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>서비스</th>
                            <th>메뉴</th>
                            <th>구분</th>
                            <th>이용료(월)</th>
                            <th>기준</th>
                            <th>비고</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Win-EDI 2.0</strong>
                            </td>
                            <td>DOCU</td>
                            <td>Win-EDI 2.0
                                <br/>(웹) 이용료
                            </td>
                            <td>
                                <strong>구간별 월 정액1)</strong>
                                (0원~4만원)
                            </td>
                            <td>
                                <strong>“웹 이용료 건수 산정 방식 및 대상문서”2)</strong>
                                의 월 전송건수 기준 구간별 금액 적용
                            </td>
                            <td>EDI 전송료 별도</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>e-A/N
                                    <br/>(선사)
                                </strong>
                            </td>
                            <td>A/N</td>
                            <td>A/N 웹 이용료</td>
                            <td>
                                <strong>구간별 월 정액1)</strong>
                                (0원~4만원)
                            </td>
                            <td>A/N 월 발송건수(FAX(장) + e-Mail(건)의 구간별 금액 적용</td>
                            <td>FAX, e-Mail 전송료
                                <br/>별도4)
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>AFR
                                    <br/>서비스
                                </strong>
                            </td>
                            <td>AFR
                                <br/>서비스
                            </td>
                            <td colSpan="4"><strong className={Style.str}>"케이엘넷 일본 세관 AFR 서비스 이용료"</strong> 파일 참조5)</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>위험물 정보연계
                                    <br/>서비스</strong>
                            </td>
                            <td>DG</td>
                            <td>위험물 정보연계
                                <br/>서비스 웹 이용료
                            </td>
                            <td><strong>구간별 월 정액6)</strong></td>
                            <td colSpan="2">수출 위험물 컨테이너 반입 요청 건수</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>위험물
                                    <br/>취합 서비스
                                </strong>
                            </td>
                            <td>위험물
                            </td>
                            <td>위험물 반입신고서 및
                                <br/>위험물 적하일람표
                            </td>
                            <td>반입신고서 : 400원/건
                                <br/>적하일람표 : 100원/건
                                <br/><strong className={Style.str}>(최저기본료 : 1만원)</strong>
                            </td>
                            <td>
                            </td>
                            <td><strong className={Style.str}> 구간할인(적하일람표) 9) <br/> EDI 전송료 별도</strong></td>
                        </tr>
                        <tr>
                            <td>
                                <strong>해상적하목록
                                    <br/>취합 서비스
                                </strong>
                            </td>
                            <td>수입 MFCS
                                <br/>수출 MFCS
                            </td>
                            <td>MFCS 기본 이용료</td>
                            <td>월정액</td>
                            <td>선 사 : 40,000원/월
                                <br/>포워더 : 40,000원/월
                                <br/>웹 입력료 20,000원/월)
                                <br/>검수사 : 40,000원/월
                            </td>
                            <td><strong className={Style.str}> 이용료 참고사항7) <br/> EDI 전송료 별도</strong></td>
                        </tr>
                        <tr>
                            <td>
                                <strong>화물정보수신료</strong>
                            </td>
                            <td>수입 MFCS</td>
                            <td>화물정보 웹다운로드
                                <br/>Query & Download
                            </td>
                            <td>
                                B/L건당 80원
                                <strong>(최저기본료 : 1만원)</strong>
                            </td>
                            <td>웹 또는 직접 쿼리를 통해 다운로드 받은
                                <br/>화물정보 상의 B/L 1건당 80원 청구
                            </td>
                            <td>구간할인 8) <strong className={Style.str}>EDI 전송료 별도</strong></td>
                        </tr>
                        <tr>
                            <td>
                                <strong>
                                    수입인보이스 요청
                                    <br/>(DOC/DEM/DET)
                                </strong>
                            </td>
                            <td>CNTR</td>
                            <td>수입인보이스 서비스</td>
                            <td>300원 /건
                                <strong>(최저사용료 1만원)</strong>
                            </td>
                            <td>수입인보이스 요청 컨수</td>
                            <td>컨별 요금으로 산정</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>
                                    수입인보이스 요청 결제
                                    <br/>(DOC/DEM/DET)
                                    <br/>PLISM+ARA 서비스
                                    <br/>(구 로지스페이)
                                </strong>
                            </td>
                            <td>CNTR</td>
                            <td>수입인보이스 결제 서비스
                            </td>
                            <td>700원 /건
                                <strong>(최저사용료 1만원)</strong>
                            </td>
                            <td>수입인보이스 결제 컨수</td>
                            <td>컨별 요금으로 산정</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>
                                우편환적
                                </strong>
                            </td>
                            <td>수입MFCS</td>
                            <td>국제우편 <br/>
                                환적화물서비스
                            </td>
                            <td>100원 /건
                            </td>
                            <td></td>
                            <td>단, 당사 적하목록취합서비스로 적하목록 전송 시 80원/건</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>
                                Logisview 기본료
                                </strong>
                            </td>
                            <td>Logisview 사이트</td>
                            <td>Logisview 사이트 <br/>
                                기본료
                            </td>
                            <td>10,000원 /월
                            </td>
                            <td>"Logisview"사이트 (www.logisview.com)에서 제공한 컨텐츠를 이용한 월에 한하여 기본료 부과</td>
                            <td></td>
                        </tr>
                        </tbody>
                    </table>
                    <ul className={Style.nlist}>
                        <li>1) 0건: 0원, 1~14건: 1만원, 15~300건: 1.5만원, 301~1000건: 2.5만원, 1001~10000건: 3만원, 10001건 이상: 4만원</li>
                        <li style ={{textDecoration: 'underline', textUnderlinePosition:'under'}}><a href="https://www.plism.com/file_download/WIN-EDI 2.0 웹 이용료 건수산정 대상 문서.pdf" target='_blank'>
                            2) [Win-EDI 2.0] 웹 이용료 건수 산정 방식 및 대상 문서</a></li>
                        <li>3) 0건: 0원, 1~100건: 1만원, 101~300건: 3만원, 301~3000건: 5만원, 3001~5,000건: 7만원, 5,001건 이상: 10만원</li>
                        <li>4) AN(FAX서비스) : 80원/1장, AN(e-Mail서비스) : 40원/1건</li>
                        <li style ={{textDecoration: 'underline', textUnderlinePosition:'under'}}><a href="https://www.plism.com/file_download/KLNET_AFR_SERVICE_CHARGE.pdf" target='_blank'>
                            5) AFR 서비스 이용료 : 케이엘넷 일본세관 AFR서비스 이용료</a>
                        </li>
                        <li>6) 0~2 건 : 무료, 3~50건 : 10,000원, 51~100건 : 20,000원, 101~200건 : 30,000원, 201~300건 : 40,000원, 301~400건 : 50,000원.
                            <br/> * 400건 이후 100건 초과시 마다 10,000원씩 증가</li>
                        <li>7) 해상적하목록 취합서비스 이용료는 '16년 8월 사용분부터 부과 (프로모션 기한내 가입 고객은 ’16.12까지 기본 이용료 부과유예)
                            <br/>   ※ 별도과금 : 1. 해상보세창고/해상보세운송 화물정보수신료 2. 웹 입력료 250원/건 3. EDI 전송료 : 250원/KB(최저기본료 1만원)</li>
                        <li>8) 0~250건 : 0%, 251~1,000건 : 10%, 1,001~2,500건 : 15%, 2,501~10,000건 : 20%, 10,001건 이상 : 30%</li>
                        <li>9) 1,001~2,000건 : 50%, 2,001~3,000건 : 60%, 3,001~4,000건 : 70%, 4,001~5,000건 : 80%, 5,001건 이상 : 90%</li>
                    </ul>
                </div>
                }
                {/*서비스 이용신청서 tabid =1*/}
                {tabContent.id  === 't1'&&
                <div className={Style.scrolls}>
                    <div className={Style.serviceReqWrap}>
                        {/* <div className={Style.lt}><img src={process.env.PUBLIC_URL  + `/assetsNew/image/etc/klnet_new.gif`}/></div> */}
                        <div className={Style.lt}><img src={`${plismImgUrl}/klnet_new.GIF`}/></div>
                        <div className={Style.rt}>
                            <dl>
                                <dt>
                                    <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-tit.png`}/>
                                    <strong>가입신청서 작성방법</strong>
                                </dt>
                                <dd>
                                    <ul>
                                        <li>신청서 상의 2.신청서비스에 원하는 항목에 체크</li>
                                        <li>PLISM 입출항 서비스 이용 시 항만 업체 코드(예. ZZ-Z-9999)와 선사 코드
                                            <br/>(①관세청 부여 4자리 및 ②3자리 선사 코드 (컨테이너 선사일 경우만 해당)) 항목 기재
                                        </li>
                                        <li>명판/직인 날인 후 사업자등록증 사본과 함께 팩스 송부 후,
                                            <br/>접수 여부 유선 확인 및 케이엘넷 ID 부여 받음
                                        </li>
                                    </ul>
                                    <button type="button" onClick={()=>{downloadManual(tabContent.file)}}>
                                        <span>서비스 이용신청서 다운로드</span>
                                        <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-arr.png`}/>
                                    </button>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
                }
                {tabContent.id  === 't2'&&   
                <div className={Style.scrolls}>
                    <div className={Style.serviceReqWrap}>
                        {/* <div className={Style.lt}><img className={Style.img} src={process.env.PUBLIC_URL  + `/assetsNew/image/etc/fwd_new.jpg`}/></div> */}
                        <div className={Style.lt}><img className={Style.img} src={`${plismImgUrl}/fwd_new.GIF`}/></div>
                        <div className={Style.rt}>
                        {tabList&&tabList.find(x=>x.id=='t2').file.map((value, ind,arry)=>
                            <button type="button" className={Style.btn} key ={ind} onClick={()=>{downloadManual(value[Object.keys(value)])}}>
                                <span>취합서비스 이용신청서({Object.keys(value)}) 다운로드</span>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-arr.png`}/>
                            </button>
                        )}
                        </div>
                    </div>
                </div>
                
                }
                {tabContent.id  === 't3'&& 
                <div className={Style.scrolls}>
                    <div className={Style.serviceReqWrap}>
                        <div className={Style.lt}><img src={`${plismImgUrl}/afr_01.GIF`}/></div>
                        <div className={Style.rt}>
                            <dl>
                                <dt>
                                    <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-tit.png`}/>
                                    <strong>1. 고객정보</strong>
                                </dt>
                                <dd>
                                    <ul>
                                        <li>- 좌측 고객정보 입력</li>
                                    </ul>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <br/>
                    <hr/>
                    <br/>
                    <div className={Style.serviceReqWrap}>
                        <div className={Style.lt}><img src={`${plismImgUrl}/afr_02.GIF`}/></div>
                        <div className={Style.rt}>
                            <dl>
                                <dt>
                                    <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-tit.png`}/>
                                    <strong>2. 신청서비스</strong>
                                </dt>
                                <dd>
                                    <ul>
                                        <li>① EDI 서비스의 일본세관 AFR에 표시(V)</li>
                                        <li>② 웹서비스(plism2.0)를 이용할 경우 EDI 서비스의 기타에 "일본세관AFR웹서비스 이용"이라고 기재</li>
                                        <li>③ REPORTER ID란에 NACCS에서 부여 받은 Reporter ID(5자리)를 기입</li>
                                    </ul>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <br/>
                    <hr/>
                    <br/>
                    <div className={Style.serviceReqWrap}>
                        <div className={Style.lt}><img src={`${plismImgUrl}/afr_03.GIF`}/></div>
                        <div className={Style.rt}>
                            <dl>
                                <dt>
                                    <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-tit.png`}/>
                                    <strong>3. 이용환경 사항</strong>
                                </dt>
                                <dd>
                                    <ul>
                                        <li>① NACCS에서 부여 받은 선사 또는 NVOCC(포워더) 코드(4자리)</li>
                                        <li>② 국내세관코드(4자리)</li>
                                        <li>③ 협력업체정보 기입
                                            <br/> (ex:비즈넷/양재IT /T2L/반석/위즈 랩 등)</li>
                                        <li>④ 명판 / 직인 날인 후 사업자등록증 사본과 함께 팩스 송부 후 , 접수여부 유선 확인 및 케이엘넷 ID 부여 받음</li>
                                    </ul>
                                </dd>
                            </dl>
                            <button type="button" onClick={()=>{downloadManual(tabContent.file)}}>
                                <span>서비스 이용신청서 다운로드</span>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-arr.png`}/>
                            </button>
                        </div>
                    </div>
                </div>
                }
                {tabContent.id  === 't4'&&
                    <div className={Style.scrolls}>
                        <div className={Style.serviceReqWrap}>
                            {/* <div className={Style.lt}><img className={Style.img}  src={process.env.PUBLIC_URL  + `/assetsNew/image/etc/invoice_01.jpg`}/></div> */}
                            <div className={Style.lt}><img className={Style.img}  src={`${plismImgUrl}/invoice_01.GIF`}/></div>
                            <div className={Style.rt}>
                                <dl>
                                    <dd>
                                        <ul>
                                            <li>2020년 07월 01일 부로 인보이스-신청서비스 및 수출입물류비 자동수납서비스(PLISM+ARA) 이용을 하려고 하면 별도의 가입신청이 필요합니다.
                                                (eTrans 에서는 가입없이 사용가능)
                                            </li>
                                            <li>  가입신청서(다운로드)를 작성하여 사업 등록증 사본과 함께 02)538-3078 팩스로 보내신 후 1577-1172 로 연락바랍니다.
                                            </li>
                                        </ul>
                                        <button type="button" onClick={()=>{downloadManual(tabContent.file)}}>
                                            <span>서비스 이용신청서 다운로드</span>
                                            <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-arr.png`}/>
                                        </button>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ServiceReq
