/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import {Row, Col, CardBody, Collapse, Button, Card,  FormGroup,Label, UncontrolledTooltip} from "reactstrap";
import Select from "react-select";
import TransportBookmark from "./TransportBookmark.js";
import InputValid from "components/CustomInput/InputValid.js";


const TransportCard = forwardRef((props, transportFocus) => {
    
    // Collapse Flag
    const [coll, setColl] = useState(false);
    // TransPort
    const [booking, setBooking] = useState({});

    const [transportLineFix, setTransportLineFix] = useState(false);

    const [transportList, setTransportList] = useState([]);
    const {bookingKecaYN} = props;
    const transSelfList = [{value:'Y', label:'자가운송'},{value:'N', label:'라인운송'}]

    useEffect(()=>{
        setColl(props.openWindow);
    },[props.openWindow]);

    // 부모로부터 파라미터 전달 받음
    useEffect(()=>{
        if( props.booking.bkg_no ) {
            setBooking(props.booking);
        }
    },[props.booking]);

    //transport 라인운송 옵션화 (23.7.7확인안쓰는듯 )
    useEffect(()=>{
        if( props.booking.bkg_no ) {
            // console.log(props.booking.trans_self_yn);
            if(props.booking.transport_line_fix&&props.booking.transport_line_fix==='true'){
                fncOnChangeSelect('N', 'trans_self_yn');
                props.fncBookingParent({...booking,'trans_self_yn':'N'});
                setTransportLineFix(true);
            }else{
                setTransportLineFix(false);
            }
        }
    },[props.booking.transport_line_fix]);

    useEffect(()=>{
        setTransportList(props.transportList);
    },[props.transportList]);

    // Transport Bookmark 선택
    const fncSelectTransport=(value)=>{
        if( value ) {
            // 선택
            if( 1 > value.value ) {
                // setBooking({});
                if ( coll ) {
                    setColl(!coll)
                }
            // 그외 데이터인 경우
            } else {
                transportList.forEach((element, key)=>{
                    if( value.value === element.transport_bookmark_seq) {
                        // console.log(element)
                        // select로 새로운 document를 세팅한다
                        // 기존 Booking 정보
                        setBooking({...booking
                            ,'transport_bookmark_seq':element.transport_bookmark_seq
                            ,'transport_bookmark_name':element.transport_bookmark_name
                            // name1이 존재하면 name2도 같이 세팅 2021.10.19
                            ,'trans_name1':bookingKecaYN==='Y'?(element.trans_name1?element.trans_name1:booking.trans_name1):(element.trans_name_eng1?element.trans_name_eng1:booking.trans_name1)
                            ,'trans_name2':bookingKecaYN==='Y'?(element.trans_name2?element.trans_name2:booking.trans_name2):(element.trans_name_eng2?element.trans_name_eng2:booking.trans_name2)
                            ,'trans_code':element.trans_code?element.trans_code:booking.trans_code
                            ,'trans_self_yn':element.trans_self_yn?element.trans_self_yn:booking.trans_self_yn
                            ,'trans_user_fax':element.trans_user_fax?element.trans_user_fax:booking.trans_user_fax
                            ,'trans_user_name':bookingKecaYN==='Y'?(element.trans_user_name?element.trans_user_name:booking.trans_user_name):(element.trans_user_name_eng?element.trans_user_name_eng:booking.trans_user_name)
                            ,'trans_user_tel':element.trans_user_tel?element.trans_user_tel:booking.trans_user_tel
                            ,'trans_user_email':element.trans_user_email?element.trans_user_email:booking.trans_user_email
                            ,'selected_yn':'Y'
                        });
                        props.fncBookingParent({...booking
                            ,'transport_bookmark_seq':element.transport_bookmark_seq
                            ,'transport_bookmark_name':element.transport_bookmark_name
                            // name1이 존재하면 name2도 같이 세팅 2021.10.19
                            ,'trans_name1':bookingKecaYN==='Y'?(element.trans_name1?element.trans_name1:booking.trans_name1):(element.trans_name_eng1?element.trans_name_eng1:booking.trans_name1)
                            ,'trans_name2':bookingKecaYN==='Y'?(element.trans_name2?element.trans_name2:booking.trans_name2):(element.trans_name_eng2?element.trans_name_eng2:booking.trans_name2)
                            ,'trans_code':element.trans_code?element.trans_code:booking.trans_code
                            ,'trans_self_yn':element.trans_self_yn?element.trans_self_yn:booking.trans_self_yn
                            ,'trans_user_fax':element.trans_user_fax?element.trans_user_fax:booking.trans_user_fax
                            ,'trans_user_name':bookingKecaYN==='Y'?(element.trans_user_name?element.trans_user_name:booking.trans_user_name):(element.trans_user_name_eng?element.trans_user_name_eng:booking.trans_user_name)
                            ,'trans_user_tel':element.trans_user_tel?element.trans_user_tel:booking.trans_user_tel
                            ,'trans_user_email':element.trans_user_email?element.trans_user_email:booking.trans_user_email
                            ,'selected_yn':'Y'
                        });
                    }
                });
                if ( !coll ) {
                    setColl(!coll);
                }
            }
        } else {
            if( booking.transport_bookmark_seq) {
                setBooking({...booking
                    ,'transport_bookmark_seq': null
                    ,'transport_bookmark_name': null
                    ,'trans_name1': null
                    ,'trans_name2': null
                    ,'trans_code': null
                    ,'trans_self_yn': null
                    ,'trans_user_fax': null
                    ,'trans_user_name': null
                    ,'trans_user_tel': null
                    ,'trans_user_email': null
                    ,'selected_yn':'Y'
                });
                props.fncBookingParent({...booking
                    ,'transport_bookmark_seq': null
                    ,'transport_bookmark_name': null
                    ,'trans_name1': null
                    ,'trans_name2': null
                    ,'trans_code': null
                    ,'trans_self_yn': null
                    ,'trans_user_fax': null
                    ,'trans_user_name': null
                    ,'trans_user_tel': null
                    ,'trans_user_email': null
                    ,'selected_yn':'Y'
                });
            }
        }
    }
    const fncOnChange = ( e, key ) => {
        e.preventDefault();
        setBooking({...booking, [key]:(e.target.value.toUpperCase())||null});
    }

    // 수정된 내용은 Trans 저장
    const fncOnChangeSelect = ( e, key ) => {
        setBooking({...booking, [key]:e.value});
    }
    return (
        <>
            <Row id="Transport">
                <Col xl="12" lg="12">
                    <Card>
                        <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>  
                            <Row className="pb-2">
                                <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>TRANSPORT
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="col-10 pr-0">
                                            <Select
                                                className="react-select react-select-primary"
                                                name="carrierbookmark"
                                                value={{value:booking.transport_bookmark_seq?booking.transport_bookmark_seq:''
                                                ,label:booking.transport_bookmark_name?booking.transport_bookmark_name:'선택'}}
                                                onChange={(value)=>fncSelectTransport(value?value:null)}
                                                options={transportList}
                                                placeholder="선택"
                                                ref={transportFocus}
                                                isClearable={booking.transport_bookmark_seq?true:false}
                                            />
                                        </Col>
                                        <Col className="col-2 pl-auto pr-auto">
                                            <TransportBookmark
                                                transportList={transportList}
                                                selectBookingTransportBookmark={props.selectBookingTransportBookmark}
                                                onAlert={props.onAlert}
                                                bookingKecaYN={bookingKecaYN}
                                                {...props}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Collapse isOpen={coll}>
                                <hr className="mt-0"/>
                                <Row>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Haulage</Label></Col>
                                                <Col>
                                                    <Select
                                                        isDisabled={transportLineFix}
                                                        className="customSelect"
                                                        name="trans_self_yn"
                                                        value={{value:booking.trans_self_yn?booking.trans_self_yn:'',
                                                            label:booking.trans_self_yn?transSelfList[transSelfList.findIndex(x=>x.value===booking.trans_self_yn)].label:'선택'}
                                                        }
                                                        onChange={(value)=>{fncOnChangeSelect(value, 'trans_self_yn');props.fncBookingParent({...booking,'trans_self_yn':value.value});}}
                                                        options={transSelfList}
                                                        styles={{
                                                            control: provided => ({...provided,border:!booking.trans_self_yn?'1px solid red':'' }),
                                                            indicatorContainer: provided => ({...provided,color:''})
                                                        }}
                                                    />
                                                    <InputValid
                                                        hidden
                                                        type="text"
                                                        name="trans_self_yn"
                                                        id="trans_self_yn"
                                                        maxLength="20"
                                                        value={booking.trans_self_yn?booking.trans_self_yn:''}
                                                        validtype="select"
                                                        required={booking.trans_self_yn?false:true} 
                                                        feedid="transport"
                                                        readOnly
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2">
                                                    <Label className="mb-0">Code</Label>
                                                </Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="trans_code"
                                                    id="trans_code"
                                                    maxLength="10"
                                                    value={booking.trans_code?booking.trans_code:''}
                                                    onChange={(e)=>fncOnChange(e, 'trans_code')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    required={false} 
                                                    feedid="transport"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2">
                                                    <Label className="mb-0">Name</Label>
                                                </Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="trans_name1"
                                                        id="trans_name1"
                                                        maxLength="35"
                                                        // bsSize="sm"
                                                        value={booking.trans_name1?booking.trans_name1:''}
                                                        onChange={(e)=>fncOnChange(e, 'trans_name1')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="eng"
                                                        required={('Y'===booking.trans_self_yn)?true:false} 
                                                        feedid="transport"
                                                    />
                                                    </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2">
                                                    <Label className="mb-0"></Label>
                                                </Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="trans_name2"
                                                        id="trans_name2"
                                                        maxLength="35"
                                                        value={booking.trans_name2?booking.trans_name2:''}
                                                        onChange={(e)=>fncOnChange(e, 'trans_name2')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="eng"
                                                        required={false} 
                                                        feedid="transport"
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2">
                                                    <Label className="mb-0">User</Label>
                                                </Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="trans_user_name"
                                                        id="trans_user_name"
                                                        maxLength="17"
                                                        value={booking.trans_user_name?booking.trans_user_name:''}
                                                        onChange={(e)=>fncOnChange(e, 'trans_user_name')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="eng"
                                                        required={('Y'===booking.trans_self_yn)?true:(booking.trans_user_email||booking.trans_user_fax||booking.trans_user_tel)?true:false} 
                                                        feedid="transport"
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2">
                                                    <Label className="mb-0">Tel</Label>
                                                </Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="trans_user_tel"
                                                        id="trans_user_tel"
                                                        maxLength="25"
                                                        value={booking.trans_user_tel?booking.trans_user_tel:''}
                                                        onChange={(e)=>fncOnChange(e, 'trans_user_tel')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="tel"
                                                        required={('Y'===booking.trans_self_yn)?true:false} 
                                                        feedid="transport"
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2">
                                                    <Label className="mb-0">Fax</Label>
                                                </Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="trans_user_fax"
                                                        id="trans_user_fax"
                                                        maxLength="25"
                                                        value={booking.trans_user_fax?booking.trans_user_fax:''}
                                                        onChange={(e)=>fncOnChange(e, 'trans_user_fax')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="tel"
                                                        required={false} 
                                                        feedid="transport"
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2">
                                                    <Label className="mb-0">E-mail</Label>
                                                </Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="trans_user_email"
                                                        id="trans_user_email"
                                                        maxLength="50"
                                                        value={booking.trans_user_email?booking.trans_user_email:''}
                                                        onChange={(e)=>fncOnChange(e, 'trans_user_email')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="email"
                                                        required={false} 
                                                        feedid="transport"
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Collapse>
                            <div className="text-center" onClick={() => setColl(!coll)}>
                                <div>         
                                    <Button className="p-0" color="link" id="linemore" onClick={() => setColl(!coll)} style={{height:'21px'}}>
                                        {coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="linemore">{coll?'Close':'Open'}</UncontrolledTooltip>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
});

export default TransportCard;