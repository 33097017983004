/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
//eshipCommon은 Door를 안쓰고 있어서 수정대상에서 제외함
import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import {Row, Col, CardBody, Collapse, UncontrolledTooltip, Button,Input, Card, Label, ButtonGroup, FormGroup, Tooltip,Badge} from "reactstrap";
import axios from 'axios';
import _ from 'lodash';
// import moment from 'moment';
import Select from "react-select";
// import Door from './Door.js';
import {Container} from "./Container.js";
import ContainerBookmark from "./ContainerBookmark.js";

import {Spinner} from 'reactstrap';

const ContainerCard = forwardRef((props, containerFocus) => {
    // Collapse Flag
    const [coll, setColl] = useState(false);
    // modal 창을 위한 state
    // Container Bookmark List  
    const [containerBookmarkList, setContainerBookmarkList] = useState([]);
    // Special Bookmark List
    const [specialBookmarkList, setSpecialBookmarkList] = useState([]);
    // Container List
    const [containerList, setContainerList] = useState([]);
    // Container Special List
    const [containerSpecialList, setContainerSpecialList] = useState([]);
    // const [containerAttachList, setContainerAttachList] = useState([]);
    const [booking, setBooking] = useState({});
    const [allCheck,setAllCheck] = useState(true);
    const [cntrCheck,setCntrCheck] = useState(true);
    // Door 입력 정보 Weidong의 경우 단건처리이기에 이렇게 처리합니다.
    // const [tooltipOpen,setTooltipOpen] = useState(false);
    const [clearFile,setClearFile] = useState(false);
    const [door, setDoor] = useState({});
    const [loading, setLoading] = useState(false);
    const [lineCodeCntrSztp, setLineCodeCntrSztp] = useState([]);

    const {userData, lineCode, cargo} = props;
    // useEffect(() => {
    //     if(props.booking.attach_file) {
    //         setFiles(props.booking.attach_file.map((value,index)=> {return value}))
    //     }
    // },[props.booking])
    useEffect(() => {
        if( userData && userData.user_no ) {
            selectBookingSpecialBookmark();
        }
    },[userData]);
    useEffect(()=> {
        if(lineCode && !_.isEmpty(booking)) {
            selectContainerOfBooking(booking);
            selectLineCodeCntrSztp({line_code:lineCode,cargoType:cargo.cargo_type});
        }
    },[lineCode])
    useEffect(()=>{
        setColl(props.openWindow);
    },[props.openWindow]);

    useEffect(() => {
        props.fncContainerSpecialParent(containerSpecialList);
    },[containerSpecialList]);
    useEffect(()=> {
        if(props.cargo) {
            if(props.cargo.cargo_type){
                // if(props.cargo.cargo_type !== '3' && props.cargo.cargo_type!== '4'){
                //     deleteAllFiles();
                // }
                selectLineCodeCntrSztp({line_code:lineCode,cargoType:cargo.cargo_type});
            }
        }
    },[props.cargo]);
    
    // 부모로부터 파라미터 전달 받음 (전체북마크)
    useEffect(useCallback(()=>{
        if( "Y" === props.booking.bookmark_yn && props.booking.bookmark_seq ) {
            if( props.booking.container_bookmark_seq ) {
                containerBookmarkList.forEach((element, key)=>{
                    if( props.booking.container_bookmark_seq === element.container_bookmark_seq) {

                        setBooking({
                            ...booking
                            ,'container_bookmark_seq':element.container_bookmark_seq
                            ,'container_bookmark_name':element.container_bookmark_name
                            ,'cntr_selected_yn':'Y'
                        });
                        if( 0 < containerList.length ) {
                            const cntr = containerList[0];
                            let params = {
                                'cntr_seq':1
                                ,'container_bookmark_seq': element.container_bookmark_seq
                                ,'container_bookmark_name': element.container_bookmark_name
                                ,'cntr_selected_yn':'Y'
                                ,'cntr_yn': 'Y'
                                ,'cntr_code': element.cntr_code?element.cntr_code:cntr.cntr_code
                                ,'cntr_iso_code': element.cntr_iso_code?element.cntr_iso_code:cntr.cntr_iso_code
                                ,'cntr_qty': element.cntr_qty?element.cntr_qty:cntr.cntr_qty
                                ,'cntr_soc_yn':element.cntr_soc_yn?element.cntr_soc_yn:cntr.cntr_soc_yn
                                ,'cntr_empty_yn': element.cntr_empty_yn?element.cntr_empty_yn:cntr.cntr_empty_yn
                                ,'cntr_nor_flag': element.cntr_nor_flag?element.cntr_nor_flag:cntr.cntr_nor_flag

                                ,'cntr_frozen_tmp': element.cntr_frozen_tmp?element.cntr_frozen_tmp:cntr.cntr_frozen_tmp
                                // ,'cntr_frozen_fc': element.cntr_frozen_fc?element.cntr_frozen_fc:cntr.cntr_frozen_fc
                                ,'cntr_frozen_tmp_unit': element.cntr_frozen_tmp_unit?element.cntr_frozen_tmp_unit:cntr.cntr_frozen_tmp_unit
                                ,'cntr_humidity': element.cntr_humidity?element.cntr_humidity:cntr.cntr_humidity
                                ,'cntr_vent_open': element.cntr_vent_open?element.cntr_vent_open:cntr.cntr_vent_open
                                // oog일때
                                ,'cntr_special_type':element.cntr_special_type?element.cntr_special_type:cntr.cntr_special_type
                                ,'cntr_length':element.cntr_length?element.cntr_length:cntr.cntr_length
                                ,'cntr_width':element.cntr_width?element.cntr_width:cntr.cntr_width
                                ,'cntr_height':element.cntr_height?element.cntr_height:cntr.cntr_height
                                ,'cntr_item_qty' : element.cntr_item_qty? element.cntr_item_qty : cntr.cntr_item_qty
                                ,'cntr_stowage_cell' : element.cntr_stowage_cell? element.cntr_stowage_cell : cntr.cntr_stowage_cell
                                ,'cntr_break_bulk_yn' : element.cntr_break_bulk_yn? element.cntr_break_bulk_yn : cntr.cntr_break_bulk_yn
                                ,'cntr_commodity' : element.cntr_commodity? element.cntr_commodity : cntr.cntr_commodity
                                ,'cntr_mng_remark' : element.cntr_mng_remark? element.cntr_mng_remark : cntr.cntr_mng_remark

                                
                                ,'cntr_pick_up_cy_address1': element.cntr_pick_up_cy_address1?element.cntr_pick_up_cy_address1:cntr.cntr_pick_up_cy_address1
                                ,'cntr_pick_up_cy_address2': element.cntr_pick_up_cy_address1?element.cntr_pick_up_cy_address2:cntr.cntr_pick_up_cy_address2
                                ,'cntr_pick_up_cy_address3': element.cntr_pick_up_cy_address1?element.cntr_pick_up_cy_address3:cntr.cntr_pick_up_cy_address3
                                ,'cntr_pick_up_cy_address4': element.cntr_pick_up_cy_address1?element.cntr_pick_up_cy_address4:cntr.cntr_pick_up_cy_address4
                                ,'cntr_pick_up_cy_address5': element.cntr_pick_up_cy_address1?element.cntr_pick_up_cy_address5:cntr.cntr_pick_up_cy_address5
                                ,'cntr_pick_up_cy_code': element.cntr_pick_up_cy_code?element.cntr_pick_up_cy_code:cntr.cntr_pick_up_cy_code
                                ,'cntr_pick_up_cy_name1': element.cntr_pick_up_cy_name1?element.cntr_pick_up_cy_name1:cntr.cntr_pick_up_cy_name1
                                ,'cntr_pick_up_cy_name2': element.cntr_pick_up_cy_name1?element.cntr_pick_up_cy_name2:cntr.cntr_pick_up_cy_name2
                                ,'cntr_pick_up_cy_user_email': element.cntr_pick_up_cy_user_email?element.cntr_pick_up_cy_user_email:cntr.cntr_pick_up_cy_user_email
                                ,'cntr_pick_up_cy_user_fax': element.cntr_pick_up_cy_user_fax?element.cntr_pick_up_cy_user_fax:cntr.cntr_pick_up_cy_user_fax
                                ,'cntr_pick_up_cy_user_name': element.cntr_pick_up_cy_user_name?element.cntr_pick_up_cy_user_name:cntr.cntr_pick_up_cy_user_name
                                ,'cntr_pick_up_cy_user_tel': element.cntr_pick_up_cy_user_tel?element.cntr_pick_up_cy_user_tel:cntr.cntr_pick_up_cy_user_tel
                                ,'cntr_pick_up_cy_date': element.cntr_pick_up_cy_date?element.cntr_pick_up_cy_date:cntr.cntr_pick_up_cy_date
                                ,'cntr_pick_up_cy_date_name': element.cntr_pick_up_cy_date_name?element.cntr_pick_up_cy_date_name:cntr.cntr_pick_up_cy_date_name
                               
                                //안쓰이는 항목
                                // ,'cntr_pre_cooling':element.cntr_pre_cooling?element.cntr_pre_cooling:cntr.cntr_pre_cooling
                                // ,'cntr_seal_no': element.cntr_seal_no?element.cntr_seal_no:cntr.cntr_seal_no
                                // ,'cntr_size':element.cntr_size?element.cntr_size:cntr.cntr_size
                                // ,'cntr_type':element.cntr_type?element.cntr_type:cntr.cntr_type

                                //door분리해야할듯 (eship에서는 Door안쓰임)
                                // , 'cntr_door_code':element.cntr_door_code?element.cntr_door_code:cntr.cntr_door_code
                                // , 'cntr_door_name1':element.cntr_door_name1?element.cntr_door_name1:cntr.cntr_door_name1
                                // , 'cntr_door_name2':element.cntr_door_name1?element.cntr_door_name2:cntr.cntr_door_name2
                                // , 'cntr_door_date':element.cntr_door_date?element.cntr_door_date:cntr.cntr_door_date
                                // , 'cntr_door_date_name':element.cntr_door_date_name?element.cntr_door_date_name:cntr.cntr_door_date_name
                                // , 'cntr_door_user_name':element.cntr_door_user_name?element.cntr_door_user_name:cntr.cntr_door_user_name
                                // , 'cntr_door_user_dept':element.cntr_door_user_dept?element.cntr_door_user_dept:cntr.cntr_door_user_dept
                                // , 'cntr_door_user_fax':element.cntr_door_user_fax?element.cntr_door_user_fax:cntr.cntr_door_user_fax
                                // , 'cntr_door_user_tel':element.cntr_door_user_tel?element.cntr_door_user_tel:cntr.cntr_door_user_tel
                                // , 'cntr_door_user_email':element.cntr_door_user_email?element.cntr_door_user_email:cntr.cntr_door_user_email
                                // , 'cntr_door_address1':element.cntr_door_address1?element.cntr_door_address1:cntr.cntr_door_address1
                                // , 'cntr_door_address2':element.cntr_door_address1?element.cntr_door_address2:cntr.cntr_door_address2
                                // , 'cntr_door_address3':element.cntr_door_address1?element.cntr_door_address3:cntr.cntr_door_address3
                                // , 'cntr_door_address4':element.cntr_door_address1?element.cntr_door_address4:cntr.cntr_door_address4
                                // , 'cntr_door_address5':element.cntr_door_address1?element.cntr_door_address5:cntr.cntr_door_address5
                                // , 'cntr_remark1':element.cntr_remark1?element.cntr_remark1:cntr.cntr_remark1
                                // , 'cntr_remark2':element.cntr_remark1?element.cntr_remark2:cntr.cntr_remark2
                                // , 'cntr_remark3':element.cntr_remark1?element.cntr_remark3:cntr.cntr_remark3
                                // , 'cntr_remark4':element.cntr_remark1?element.cntr_remark4:cntr.cntr_remark4
                                // , 'cntr_remark5':element.cntr_remark1?element.cntr_remark5:cntr.cntr_remark5
                            }
                            setContainerList([{...params}]);
                            props.fncContainerParent([{...params}]);
                            // let doorParams ={
                            //         ...door
                            //         , 'cntr_door_user_name':("N" === props.booking.trans_self_yn )?element.cntr_door_user_name?element.cntr_door_user_name:door.cntr_door_user_name:null
                            //         , 'cntr_door_user_tel':("N" === props.booking.trans_self_yn )?element.cntr_door_user_tel?element.cntr_door_user_tel:door.cntr_door_user_tel:null
                            //         , 'cntr_door_address1':("N" === props.booking.trans_self_yn )?(element.cntr_door_address1?element.cntr_door_address1:door.cntr_door_address1):null
                            //         , 'cntr_door_address2':("N" === props.booking.trans_self_yn )?(element.cntr_door_address1?element.cntr_door_address2:door.cntr_door_address2):null
                            //         , 'cntr_door_address3':("N" === props.booking.trans_self_yn )?(element.cntr_door_address1?element.cntr_door_address3:door.cntr_door_address3):null
                            //         , 'cntr_door_address4':("N" === props.booking.trans_self_yn )?(element.cntr_door_address1?element.cntr_door_address4:door.cntr_door_address4):null
                            //         , 'cntr_door_address5':("N" === props.booking.trans_self_yn )?(element.cntr_door_address1?element.cntr_door_address5:door.cntr_door_address5):null
                            //         , 'cntr_remark1':element.cntr_remark1?element.cntr_remark1:door.cntr_remark1
                            //         , 'cntr_remark2':element.cntr_remark1?element.cntr_remark2:door.cntr_remark2
                            //         , 'cntr_remark3':element.cntr_remark1?element.cntr_remark3:door.cntr_remark3
                            //         , 'cntr_remark4':element.cntr_remark1?element.cntr_remark4:door.cntr_remark4
                            //         , 'cntr_remark5':element.cntr_remark1?element.cntr_remark5:door.cntr_remark5
                            //     }
                            // door 입력
                            // setDoor({...doorParams});
                            // props.fncDoorParent({...doorParams});
                        } else {
                            let eParam = {
                                'cntr_seq':1
                                ,'container_bookmark_seq': element.container_bookmark_seq
                                ,'container_bookmark_name': element.container_bookmark_name
                                ,'cntr_selected_yn':'Y'
                                ,'cntr_yn': 'Y'
                                ,'cntr_code': element.cntr_code
                                ,'cntr_iso_code': element.cntr_iso_code
                                ,'cntr_qty':element.cntr_qty
                                ,'cntr_soc_yn':element.cntr_soc_yn
                                ,'cntr_empty_yn':element.cntr_empty_yn
                                ,'cntr_nor_flag': element.cntr_nor_flag

                                ,'cntr_frozen_tmp':element.cntr_frozen_tmp
                                // ,'cntr_frozen_fc':element.cntr_frozen_fc
                                ,'cntr_frozen_tmp_unit':element.cntr_frozen_tmp_unit
                                ,'cntr_humidity':element.cntr_humidity
                                ,'cntr_vent_open':element.cntr_vent_open
                                // oog일때
                                ,'cntr_special_type':element.cntr_special_type
                                ,'cntr_length':element.cntr_length
                                ,'cntr_width':element.cntr_width
                                ,'cntr_height':element.cntr_height
                                ,'cntr_item_qty' : element.cntr_item_qty
                                ,'cntr_stowage_cell' : element.cntr_stowage_cell
                                ,'cntr_break_bulk_yn' : element.cntr_break_bulk_yn
                                ,'cntr_commodity' : element.cntr_commodity
                                ,'cntr_mng_remark' : element.cntr_mng_remark

                       
                                // ,'cntr_pick_up_cy_address1':element.cntr_pick_up_cy_address1
                                // ,'cntr_pick_up_cy_address2':element.cntr_pick_up_cy_address2
                                // ,'cntr_pick_up_cy_address3':element.cntr_pick_up_cy_address3
                                // ,'cntr_pick_up_cy_address4':element.cntr_pick_up_cy_address4
                                // ,'cntr_pick_up_cy_address5':element.cntr_pick_up_cy_address5
                                // ,'cntr_pick_up_cy_code':element.cntr_pick_up_cy_code
                                // ,'cntr_pick_up_cy_name1':element.cntr_pick_up_cy_name1
                                // ,'cntr_pick_up_cy_name2':element.cntr_pick_up_cy_name2
                                // ,'cntr_pick_up_cy_user_email':element.cntr_pick_up_cy_user_email
                                // ,'cntr_pick_up_cy_user_fax':element.cntr_pick_up_cy_user_fax
                                // ,'cntr_pick_up_cy_user_name':element.cntr_pick_up_cy_user_name
                                // ,'cntr_pick_up_cy_user_tel':element.cntr_pick_up_cy_user_tel
                                // ,'cntr_pick_up_cy_date':element.cntr_pick_up_cy_date
                                 //안쓰이는 항목들,,,//// 
                                // ,'cntr_pre_cooling':element.cntr_pre_cooling
                                // ,'cntr_seal_no':element.cntr_seal_no
                                // ,'cntr_size':element.cntr_size
                                // ,'cntr_type':element.cntr_type
                                // (eship에서는 Door안쓰임)
                                // ,'cntr_door_code':element.cntr_door_code, 'cntr_door_name1':element.cntr_door_name1, 'cntr_door_name2':element.cntr_door_name2
                                // ,'cntr_door_date':element.cntr_door_date, 'cntr_door_date_name':element.cntr_door_date_name
                                // ,'cntr_door_user_name':element.cntr_door_user_name
                                // ,'cntr_door_user_dept':element.cntr_door_user_dept, 'cntr_door_user_fax':element.cntr_door_user_fax
                                // ,'cntr_door_user_tel':element.cntr_door_user_tel, 'cntr_door_user_email':element.cntr_door_user_email
                                // ,'cntr_door_address1':element.cntr_door_address1, 'cntr_door_address2':element.cntr_door_address2, 'cntr_door_address3':element.cntr_door_address3, 'cntr_door_address4':element.cntr_door_address4, 'cntr_door_address5':element.cntr_door_address5
                                // ,'cntr_remark1':element.cntr_remark1, 'cntr_remark2':element.cntr_remark2, 'cntr_remark3':element.cntr_remark3, 'cntr_remark4':element.cntr_remark4, 'cntr_remark5':element.cntr_remark5
                                
                            }
                            setContainerList([{...eParam}]);
                            props.fncContainerParent([{...eParam}]);
                        }

                        // door 입력 (eship에서는 Door안쓰임)
                        // setDoor({
                        //     ...door
                        //     , 'cntr_door_user_name':("N" === props.booking.trans_self_yn )?element.cntr_door_user_name:null
                        //     , 'cntr_door_user_tel':("N" === props.booking.trans_self_yn )?element.cntr_door_user_tel:null
                        //     , 'cntr_door_address1':("N" === props.booking.trans_self_yn )?element.cntr_door_address1:null
                        //     , 'cntr_door_address2':("N" === props.booking.trans_self_yn )?element.cntr_door_address2:null
                        //     , 'cntr_door_address3':("N" === props.booking.trans_self_yn )?element.cntr_door_address3:null
                        //     , 'cntr_door_address4':("N" === props.booking.trans_self_yn )?element.cntr_door_address4:null
                        //     , 'cntr_door_address5':("N" === props.booking.trans_self_yn )?element.cntr_door_address5:null

                        //     , 'cntr_remark1':element.cntr_remark1?element.cntr_remark1:null
                        //     , 'cntr_remark2':element.cntr_remark1?element.cntr_remark2:null
                        //     , 'cntr_remark3':element.cntr_remark1?element.cntr_remark3:null
                        //     , 'cntr_remark4':element.cntr_remark1?element.cntr_remark4:null
                        //     , 'cntr_remark5':element.cntr_remark1?element.cntr_remark5:null
                        // });
                        // props.fncDoorParent({
                        //     ...door
                        //     , 'cntr_door_user_name':("N" === props.booking.trans_self_yn )?element.cntr_door_user_name:null
                        //     , 'cntr_door_user_tel':("N" === props.booking.trans_self_yn )?element.cntr_door_user_tel:null
                        //     , 'cntr_door_address1':("N" === props.booking.trans_self_yn )?element.cntr_door_address1:null
                        //     , 'cntr_door_address2':("N" === props.booking.trans_self_yn )?element.cntr_door_address2:null
                        //     , 'cntr_door_address3':("N" === props.booking.trans_self_yn )?element.cntr_door_address3:null
                        //     , 'cntr_door_address4':("N" === props.booking.trans_self_yn )?element.cntr_door_address4:null
                        //     , 'cntr_door_address5':("N" === props.booking.trans_self_yn )?element.cntr_door_address5:null

                        //     , 'cntr_remark1':element.cntr_remark1?element.cntr_remark1:null
                        //     , 'cntr_remark2':element.cntr_remark1?element.cntr_remark2:null
                        //     , 'cntr_remark3':element.cntr_remark1?element.cntr_remark3:null
                        //     , 'cntr_remark4':element.cntr_remark1?element.cntr_remark4:null
                        //     , 'cntr_remark5':element.cntr_remark1?element.cntr_remark5:null
                        // });
                        element.cntr_seq=1;
                        // selectBookingContainerSpecialBookmarkRelation(element);
                    }
                })
            }
            setContainerSpecialList([ {'key':'1', 'cntr_seq':1}]);
        } else if ( "D" === props.booking.bookmark_yn ){
            setBooking({...booking
                ,'container_bookmark_seq': null
                ,'container_bookmark_name': null
                ,'cntr_selected_yn':'N'
            });
            let list = containerList;

            containerList.forEach((data,index)=> {
                if( "Y" === data.cntr_yn ) {
                    list[index] = {...data
                        ,'container_bookmark_seq': null
                        ,'container_bookmark_name': null
                        ,'cntr_selected_yn':'N'
                        ,'cntr_yn': 'Y'
                        ,'cntr_code': null
                        ,'cntr_iso_code': null
                        ,'cntr_frozen_tmp': null
                        ,'cntr_qty': null
                        ,'cntr_soc_yn': 'N'
                        ,'cntr_empty_yn': 'N'
                        ,'cntr_nor_flag': null
                        ,'cntr_frozen_fc': null
                        ,'cntr_frozen_tmp_unit': null
                        ,'cntr_vent_open': null
                        ,'cntr_humidity':null
                        // oog일때
                        ,'cntr_length': null
                        ,'cntr_width': null
                        ,'cntr_height': null
                        ,'cntr_pick_up_cy_address1': null
                        ,'cntr_pick_up_cy_address2': null
                        ,'cntr_pick_up_cy_address3': null
                        ,'cntr_pick_up_cy_address4': null
                        ,'cntr_pick_up_cy_address5': null
                        ,'cntr_pick_up_cy_code': null
                        ,'cntr_pick_up_cy_name1': null
                        ,'cntr_pick_up_cy_name2': null
                        ,'cntr_pick_up_cy_user_email': null
                        ,'cntr_pick_up_cy_user_fax': null
                        ,'cntr_pick_up_cy_user_name': null
                        ,'cntr_pick_up_cy_user_tel': null
                        ,'cntr_pick_up_cy_date': null
                        ,'cntr_pick_up_cy_date_name': null
                        ,'cntr_pre_cooling': null
                        ,'cntr_seal_no': null
                        ,'cntr_size': null
                        ,'cntr_special_type': null
                        ,'cntr_type': null
                        , 'cntr_door_code': null
                        , 'cntr_door_name1': null
                        , 'cntr_door_name2': null
                        , 'cntr_door_date': null
                        , 'cntr_door_date_name': null
                        , 'cntr_door_user_name': null
                        , 'cntr_door_user_dept': null
                        , 'cntr_door_user_fax': null
                        , 'cntr_door_user_tel': null
                        , 'cntr_door_user_email': null
                        , 'cntr_door_address1': null
                        , 'cntr_door_address2': null
                        , 'cntr_door_address3': null
                        , 'cntr_door_address4': null
                        , 'cntr_door_address5': null
                        , 'cntr_remark1': null
                        , 'cntr_remark2': null
                        , 'cntr_remark3': null
                        , 'cntr_remark4': null
                        , 'cntr_remark5': null                   
                        , 'cntr_item_qty' : null
                        , 'cntr_stowage_cell' : null
                        , 'cntr_break_bulk_yn' : null
                        , 'cntr_commodity' : null                        
                        , 'cntr_mng_remark' : null
                    };
                }
            });
            
            setContainerList(list);
            props.fncContainerParent(list);
            setDoor({});
            props.fncDoorParent(door);
        } else {
            // if( props.booking.sch_etd ) {
            //     let list = containerList;
            //     containerList.forEach((data,key)=> {
            //         const date = props.booking.sch_etd.substr(0,8);
            //         if( !data.cntr_pick_up_cy_date ) {
            //             list[key] = {...data
            //                 , 'cntr_pick_up_cy_date':moment(date).subtract(1,'d').format('YYYYMMDD')
            //                 , 'cntr_pick_up_cy_date_name':moment(date).subtract(1,'d').format('YYYY-MM-DD')}
            //         }
            //     });
                
            //     setContainerList(list);
            //     props.fncContainerParent(list);
            // }
            let bkg_no = props.booking.bkg_no;
            let bkg_date = props.booking.bkg_date;
            let status_cus = props.booking.status_cus;
            let sc_no = props.booking.sc_no;
            let user_no = props.booking.user_no;

            setBooking({...booking, 'bkg_no':bkg_no, 'bkg_date':bkg_date
                , 'status_cus':status_cus, 'sc_no':sc_no, 'user_no':user_no
                ,'container_bookmark_seq': null
                ,'container_bookmark_name': null
            });
            if( booking.bkg_no !== props.booking.bkg_no ) {
                if( props.booking.bkg_no && props.booking.bkg_date && props.booking.user_no ) {
                    selectContainerOfBooking(props.booking);
                    selectContainerSpecialOfBooking(props.booking);
                }
            }
        }
    },[props.booking]),[props.booking]);

    // 부모로부터 파라미터 전달 받음
    useEffect(()=>{
        setContainerBookmarkList(props.containerBookmarkList);
    },[props.containerBookmarkList]);

    // 메인 화면에서 select 선택한 경우
    const fncSelectContainer = (e)=>{
        if( e ) {
            if( 1 > e.value ) {
                if ( coll ) {
                    setColl(!coll)
                }
            // 그외 데이터인 경우
            } else {
                const element = containerBookmarkList.find(element=> element.container_bookmark_seq === e.value);
                setBooking({...booking
                    ,'container_bookmark_seq':element.container_bookmark_seq
                    ,'container_bookmark_name':element.container_bookmark_name
                    ,'cntr_selected_yn':'Y'
                });
    
                let list = containerList;
                //각화면 적용
                containerList.forEach((data,index)=> {
                    if( "Y" === data.cntr_yn ) {
                        list[index] = {...data
                            ,'cntr_selected_yn':'Y'
                            ,'cntr_yn': 'Y'
                            // ,'cntr_code': element.cntr_code?element.cntr_code:data.cntr_code
                            ,'cntr_code': element.cntr_code
                                                ? (lineCodeCntrSztp.findIndex(x=>x.cntr_code===element.cntr_code)>=0)
                                                    ?element.cntr_code
                                                    :data.cntr_code||null
                                                :null
                            ,'cntr_iso_code': element.cntr_iso_code?element.cntr_iso_code:data.cntr_iso_code
                            ,'cntr_qty': element.cntr_qty?element.cntr_qty:data.cntr_qty
                            ,'cntr_soc_yn':element.cntr_soc_yn?element.cntr_soc_yn:data.cntr_soc_yn
                        

                            ,'cntr_empty_yn': element.cntr_empty_yn?element.cntr_empty_yn:data.cntr_empty_yn
                            ,'cntr_nor_flag': element.cntr_nor_flag?element.cntr_nor_flag:data.cntr_nor_flag

                            ,'cntr_frozen_tmp': element.cntr_frozen_tmp?element.cntr_frozen_tmp:data.cntr_frozen_tmp
                            // ,'cntr_frozen_fc': element.cntr_frozen_fc?element.cntr_frozen_fc:data.cntr_frozen_fc
                            ,'cntr_frozen_tmp_unit': element.cntr_frozen_tmp_unit?element.cntr_frozen_tmp_unit:data.cntr_frozen_tmp_unit
                            ,'cntr_humidity': element.cntr_humidity?element.cntr_humidity:data.cntr_humidity
                            ,'cntr_vent_open':element.cntr_vent_open?element.cntr_vent_open:data.cntr_vent_open
                            ,'cntr_special_type':element.cntr_special_type?element.cntr_special_type:data.cntr_special_type
                            ,'cntr_length':element.cntr_length?element.cntr_length:data.cntr_length
                            ,'cntr_width':element.cntr_width?element.cntr_width:data.cntr_width
                            ,'cntr_height':element.cntr_height?element.cntr_height:data.cntr_height
                            ,'cntr_item_qty' : element.cntr_item_qty? element.cntr_item_qty : data.cntr_item_qty
                            ,'cntr_stowage_cell' : element.cntr_stowage_cell? element.cntr_stowage_cell : data.cntr_stowage_cell
                            ,'cntr_break_bulk_yn' : element.cntr_break_bulk_yn? element.cntr_break_bulk_yn : data.cntr_break_bulk_yn
                            ,'cntr_commodity' : element.cntr_commodity? element.cntr_commodity : data.cntr_commodity
                            ,'cntr_mng_remark' : element.cntr_mng_remark? element.cntr_mng_remark : data.cntr_mng_remark           
                            // ,'cntr_pre_cooling':element.cntr_pre_cooling?element.cntr_pre_cooling:data.cntr_pre_cooling
                            // ,'cntr_seal_no': element.cntr_seal_no?element.cntr_seal_no:data.cntr_seal_no
                            // ,'cntr_size':element.cntr_size?element.cntr_size:data.cntr_size
                            // ,'cntr_type':element.cntr_type?element.cntr_type:data.cntr_type

                            // ,'cntr_pick_up_cy_address1': element.cntr_pick_up_cy_address1?element.cntr_pick_up_cy_address1:data.cntr_pick_up_cy_address1
                            // ,'cntr_pick_up_cy_address2': element.cntr_pick_up_cy_address1?element.cntr_pick_up_cy_address2:data.cntr_pick_up_cy_address2
                            // ,'cntr_pick_up_cy_address3': element.cntr_pick_up_cy_address1?element.cntr_pick_up_cy_address3:data.cntr_pick_up_cy_address3
                            // ,'cntr_pick_up_cy_address4': element.cntr_pick_up_cy_address1?element.cntr_pick_up_cy_address4:data.cntr_pick_up_cy_address4
                            // ,'cntr_pick_up_cy_address5': element.cntr_pick_up_cy_address1?element.cntr_pick_up_cy_address5:data.cntr_pick_up_cy_address5
                            // ,'cntr_pick_up_cy_code': element.cntr_pick_up_cy_code?element.cntr_pick_up_cy_code:data.cntr_pick_up_cy_code
                            // ,'cntr_pick_up_cy_name1': element.cntr_pick_up_cy_name1?element.cntr_pick_up_cy_name1:data.cntr_pick_up_cy_name1
                            // ,'cntr_pick_up_cy_name2': element.cntr_pick_up_cy_name1?element.cntr_pick_up_cy_name2:data.cntr_pick_up_cy_name2
                            // ,'cntr_pick_up_cy_user_email': element.cntr_pick_up_cy_user_email?element.cntr_pick_up_cy_user_email:data.cntr_pick_up_cy_user_email
                            // ,'cntr_pick_up_cy_user_fax': element.cntr_pick_up_cy_user_fax?element.cntr_pick_up_cy_user_fax:data.cntr_pick_up_cy_user_fax
                            // ,'cntr_pick_up_cy_user_name': element.cntr_pick_up_cy_user_name?element.cntr_pick_up_cy_user_name:data.cntr_pick_up_cy_user_name
                            // ,'cntr_pick_up_cy_user_tel': element.cntr_pick_up_cy_user_tel?element.cntr_pick_up_cy_user_tel:data.cntr_pick_up_cy_user_tel
                            // ,'cntr_pick_up_cy_date': element.cntr_pick_up_cy_date?element.cntr_pick_up_cy_date:data.cntr_pick_up_cy_date
                            // ,'cntr_pick_up_cy_date_name': element.cntr_pick_up_cy_date_name?element.cntr_pick_up_cy_date_name:data.cntr_pick_up_cy_date_name

                            
                            
                            // , 'cntr_door_code':("N" === props.booking.trans_self_yn )?element.cntr_door_code?element.cntr_door_code:data.cntr_door_code:null
                            // , 'cntr_door_name1':("N" === props.booking.trans_self_yn )?element.cntr_door_name1?element.cntr_door_name1:data.cntr_door_name1:null
                            // , 'cntr_door_name2':("N" === props.booking.trans_self_yn )?element.cntr_door_name1?element.cntr_door_name2:data.cntr_door_name2:null
                            // , 'cntr_door_date':("N" === props.booking.trans_self_yn )?element.cntr_door_date?element.cntr_door_date:data.cntr_door_date:null
                            // , 'cntr_door_date_name':("N" === props.booking.trans_self_yn )?element.cntr_door_date_name?element.cntr_door_date_name:data.cntr_door_date_name:null
                            // , 'cntr_door_user_name':("N" === props.booking.trans_self_yn )?element.cntr_door_user_name?element.cntr_door_user_name:data.cntr_door_user_name:null
                            // , 'cntr_door_user_dept':("N" === props.booking.trans_self_yn )?element.cntr_door_user_dept?element.cntr_door_user_dept:data.cntr_door_user_dept:null
                            // , 'cntr_door_user_fax':("N" === props.booking.trans_self_yn )?element.cntr_door_user_fax?element.cntr_door_user_fax:data.cntr_door_user_fax:null
                            // , 'cntr_door_user_tel':("N" === props.booking.trans_self_yn )?element.cntr_door_user_tel?element.cntr_door_user_tel:data.cntr_door_user_tel:null
                            // , 'cntr_door_user_email':("N" === props.booking.trans_self_yn )?element.cntr_door_user_email?element.cntr_door_user_email:data.cntr_door_user_email:null
                            // , 'cntr_door_address1':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address1:data.cntr_door_address1:null
                            // , 'cntr_door_address2':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address2:data.cntr_door_address2:null
                            // , 'cntr_door_address3':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address3:data.cntr_door_address3:null
                            // , 'cntr_door_address4':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address4:data.cntr_door_address4:null
                            // , 'cntr_door_address5':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address5:data.cntr_door_address5:null
                            // , 'cntr_remark1':element.cntr_remark1?element.cntr_remark1:data.cntr_remark1
                            // , 'cntr_remark2':element.cntr_remark1?element.cntr_remark2:data.cntr_remark2
                            // , 'cntr_remark3':element.cntr_remark1?element.cntr_remark3:data.cntr_remark3
                            // , 'cntr_remark4':element.cntr_remark1?element.cntr_remark4:data.cntr_remark4
                            // , 'cntr_remark5':element.cntr_remark1?element.cntr_remark5:data.cntr_remark5
                        };
                    }
                });
                
                setContainerList([...list]);
                props.fncContainerParent([...list]);
                
                
                // door 입력
                // setDoor({
                //     ...door
                //     , 'cntr_door_user_name':("N" === props.booking.trans_self_yn )?element.cntr_door_user_name?element.cntr_door_user_name:door.cntr_door_user_name:null
                //     , 'cntr_door_user_tel':("N" === props.booking.trans_self_yn )?element.cntr_door_user_tel?element.cntr_door_user_tel:door.cntr_door_user_tel:null
                //     , 'cntr_door_address1':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address1:door.cntr_door_address1:null
                //     , 'cntr_door_address2':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address2:door.cntr_door_address2:null
                //     , 'cntr_door_address3':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address3:door.cntr_door_address3:null
                //     , 'cntr_door_address4':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address4:door.cntr_door_address4:null
                //     , 'cntr_door_address5':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address5:door.cntr_door_address5:null
                //     , 'cntr_remark1':element.cntr_remark1?element.cntr_remark1:door.cntr_remark1
                //     , 'cntr_remark2':element.cntr_remark1?element.cntr_remark2:door.cntr_remark2
                //     , 'cntr_remark3':element.cntr_remark1?element.cntr_remark3:door.cntr_remark3
                //     , 'cntr_remark4':element.cntr_remark1?element.cntr_remark4:door.cntr_remark4
                //     , 'cntr_remark5':element.cntr_remark1?element.cntr_remark5:door.cntr_remark5
                // });
                // props.fncDoorParent({
                //     ...door
                //     , 'cntr_door_user_name':("N" === props.booking.trans_self_yn )?element.cntr_door_user_name?element.cntr_door_user_name:door.cntr_door_user_name:null
                //     , 'cntr_door_user_tel':("N" === props.booking.trans_self_yn )?element.cntr_door_user_tel?element.cntr_door_user_tel:door.cntr_door_user_tel:null
                //     , 'cntr_door_address1':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address1:door.cntr_door_address1:null
                //     , 'cntr_door_address2':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address2:door.cntr_door_address2:null
                //     , 'cntr_door_address3':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address3:door.cntr_door_address3:null
                //     , 'cntr_door_address4':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address4:door.cntr_door_address4:null
                //     , 'cntr_door_address5':("N" === props.booking.trans_self_yn )?element.cntr_door_address1?element.cntr_door_address5:door.cntr_door_address5:null
                //     , 'cntr_remark1':element.cntr_remark1?element.cntr_remark1:door.cntr_remark1
                //     , 'cntr_remark2':element.cntr_remark1?element.cntr_remark2:door.cntr_remark2
                //     , 'cntr_remark3':element.cntr_remark1?element.cntr_remark3:door.cntr_remark3
                //     , 'cntr_remark4':element.cntr_remark1?element.cntr_remark4:door.cntr_remark4
                //     , 'cntr_remark5':element.cntr_remark1?element.cntr_remark5:door.cntr_remark5
                // });
                if ( !coll ) {
                    setColl(!coll);
                }
            }
        } else {
            if( booking.container_bookmark_seq ) {
                setBooking({...booking
                    ,'container_bookmark_seq': null
                    ,'container_bookmark_name': null
                    ,'cntr_selected_yn':'N'
                });
                let list = containerList;
    
                containerList.forEach((data,index)=> {
                    if( "Y" === data.cntr_yn ) {
                        list[index] = {
                            ...data
                            ,'container_bookmark_seq': null
                            ,'container_bookmark_name': null
                            ,'cntr_code': null
                            ,'cntr_iso_code': null
                            ,'cntr_qty': null
                            ,'cntr_soc_yn': 'N'
                            ,'cntr_empty_yn': 'N'
                            ,'cntr_nor_flag': null
                            ,'cntr_frozen_fc': null
                            ,'cntr_frozen_tmp': null
                            ,'cntr_frozen_tmp_unit': null
                            ,'cntr_humidity':null
                            ,'cntr_vent_open': null
                            ,'cntr_pick_up_cy_address1': null
                            ,'cntr_pick_up_cy_address2': null
                            ,'cntr_pick_up_cy_address3': null
                            ,'cntr_pick_up_cy_address4': null
                            ,'cntr_pick_up_cy_address5': null
                            ,'cntr_pick_up_cy_code': null
                            ,'cntr_pick_up_cy_name1': null
                            ,'cntr_pick_up_cy_name2': null
                            ,'cntr_pick_up_cy_user_email': null
                            ,'cntr_pick_up_cy_user_fax': null
                            ,'cntr_pick_up_cy_user_name': null
                            ,'cntr_pick_up_cy_user_tel': null
                            ,'cntr_pick_up_cy_date': null
                            ,'cntr_pick_up_cy_date_name': null
                            ,'cntr_pre_cooling': null
                            ,'cntr_seal_no': null
                            ,'cntr_size': null
                            ,'cntr_special_type': null
                            ,'cntr_type': null
                            ,'cntr_width': null
                            ,'cntr_selected_yn':'N'
                            ,'cntr_yn': 'Y'
                            , 'cntr_door_code': null
                            , 'cntr_door_name1': null
                            , 'cntr_door_name2': null
                            , 'cntr_door_date': null
                            , 'cntr_door_date_name': null
                            , 'cntr_door_user_name': null
                            , 'cntr_door_user_dept': null
                            , 'cntr_door_user_fax': null
                            , 'cntr_door_user_tel': null
                            , 'cntr_door_user_email': null
                            , 'cntr_door_address1': null
                            , 'cntr_door_address2': null
                            , 'cntr_door_address3': null
                            , 'cntr_door_address4': null
                            , 'cntr_door_address5': null
                            , 'cntr_remark1': null
                            , 'cntr_remark2': null
                            , 'cntr_remark3': null
                            , 'cntr_remark4': null
                            , 'cntr_remark5': null
                            , 'cntr_item_qty' : null
                            , 'cntr_stowage_cell' : null
                            , 'cntr_break_bulk_yn' : null
                            , 'cntr_commodity' : null
                            , 'cntr_mng_remark' : null                            
                        };
                    }
                });
                
                setContainerList(list);
                props.fncContainerParent(list);
                setDoor({});
                props.fncDoorParent({});
            }
        }
    }

    // Card 팝업 화면에서 select 선택한 경우
    const fncSelectContainerList=(e, index)=>{
        if( e ) {
            let chDup = false;
            containerList.forEach(function(n){
                if( n.container_bookmark_seq === e.value ) {
                    chDup = true;
                    return;
                }
            });
            if( chDup ) {
                props.onNotiAlert("danger", "Bookmark 는 이미 추가되었습니다.");
                return false;
            }

            let bookmark = {}
            containerList.forEach((element, key)=>{
                if( key === index ) {
                    containerBookmarkList.forEach((row, i)=>{
                        if( e.value === row.container_bookmark_seq ) {
                            bookmark = row;
                            if( element.cntr_seq ) {
                                row.cntr_seq = element.cntr_seq;
                            } else {
                                row.cntr_seq = index+1;
                            }
                            // 찾은걸 list에 넣어주자.
                            containerList[key] = {
                                ...element
                                ,'container_bookmark_seq': row.container_bookmark_seq
                                ,'container_bookmark_name': row.container_bookmark_name
                                ,'cntr_selected_yn':'Y'
                                ,'cntr_yn': 'Y'
                                // ,'cntr_code': row.cntr_code?row.cntr_code:element.cntr_code
                                ,'cntr_code': row.cntr_code
                                            ? (lineCodeCntrSztp.findIndex(x=>x.cntr_code===row.cntr_code)>=0)
                                                ?row.cntr_code
                                                :element.cntr_code||null
                                            :null
                                ,'cntr_iso_code': row.cntr_iso_code?row.cntr_iso_code:element.cntr_iso_code
                                ,'cntr_qty': row.cntr_qty?row.cntr_qty:element.cntr_qty
                                ,'cntr_soc_yn':row.cntr_soc_yn?row.cntr_soc_yn:element.cntr_soc_yn
                                ,'cntr_empty_yn': row.cntr_empty_yn?row.cntr_empty_yn:element.cntr_empty_yn
                                ,'cntr_nor_flag': row.cntr_nor_flag?row.cntr_nor_flag:element.cntr_nor_flag

                                ,'cntr_frozen_tmp': row.cntr_frozen_tmp?row.cntr_frozen_tmp:element.cntr_frozen_tmp
                                // ,'cntr_frozen_fc': row.cntr_frozen_fc?row.cntr_frozen_fc:element.cntr_frozen_fc
                                ,'cntr_frozen_tmp_unit': row.cntr_frozen_tmp_unit?row.cntr_frozen_tmp_unit:element.cntr_frozen_tmp_unit
                                ,'cntr_humidity':row.cntr_humidity?row.cntr_humidity:element.cntr_humidity
                                ,'cntr_vent_open':row.cntr_vent_open?row.cntr_vent_open:element.cntr_vent_open

                                ,'cntr_special_type':row.cntr_special_type?row.cntr_special_type:element.cntr_special_type
                                ,'cntr_length':row.cntr_length?row.cntr_length:element.cntr_length
                                ,'cntr_width':row.cntr_width?row.cntr_width:element.cntr_width
                                ,'cntr_height':row.cntr_height?row.cntr_height:element.cntr_height
                                , 'cntr_item_qty' : row.cntr_item_qty? row.cntr_item_qty : element.cntr_item_qty
                                , 'cntr_stowage_cell' : row.cntr_stowage_cell? row.cntr_stowage_cell : element.cntr_stowage_cell
                                , 'cntr_break_bulk_yn' : row.cntr_break_bulk_yn? row.cntr_break_bulk_yn : element.cntr_break_bulk_yn
                                , 'cntr_commodity' : row.cntr_commodity? row.cntr_commodity : element.cntr_commodity
                                , 'cntr_mng_remark' : row.cntr_mng_remark? row.cntr_mng_remark : element.cntr_mng_remark     

                                // ,'cntr_pick_up_cy_address1': row.cntr_pick_up_cy_address1?row.cntr_pick_up_cy_address1:element.cntr_pick_up_cy_address1
                                // ,'cntr_pick_up_cy_address2': row.cntr_pick_up_cy_address1?row.cntr_pick_up_cy_address2:element.cntr_pick_up_cy_address2
                                // ,'cntr_pick_up_cy_address3': row.cntr_pick_up_cy_address1?row.cntr_pick_up_cy_address3:element.cntr_pick_up_cy_address3
                                // ,'cntr_pick_up_cy_address4': row.cntr_pick_up_cy_address1?row.cntr_pick_up_cy_address4:element.cntr_pick_up_cy_address4
                                // ,'cntr_pick_up_cy_address5': row.cntr_pick_up_cy_address1?row.cntr_pick_up_cy_address5:element.cntr_pick_up_cy_address5
                                // ,'cntr_pick_up_cy_code': row.cntr_pick_up_cy_code?row.cntr_pick_up_cy_code:element.cntr_pick_up_cy_code
                                // ,'cntr_pick_up_cy_name1': row.cntr_pick_up_cy_name1?row.cntr_pick_up_cy_name1:element.cntr_pick_up_cy_name1
                                // ,'cntr_pick_up_cy_name2': row.cntr_pick_up_cy_name1?row.cntr_pick_up_cy_name2:element.cntr_pick_up_cy_name2
                                // ,'cntr_pick_up_cy_user_email': row.cntr_pick_up_cy_user_email?row.cntr_pick_up_cy_user_email:element.cntr_pick_up_cy_user_email
                                // ,'cntr_pick_up_cy_user_fax': row.cntr_pick_up_cy_user_fax?row.cntr_pick_up_cy_user_fax:element.cntr_pick_up_cy_user_fax
                                // ,'cntr_pick_up_cy_user_name': row.cntr_pick_up_cy_user_name?row.cntr_pick_up_cy_user_name:element.cntr_pick_up_cy_user_name
                                // ,'cntr_pick_up_cy_user_tel': row.cntr_pick_up_cy_user_tel?row.cntr_pick_up_cy_user_tel:element.cntr_pick_up_cy_user_tel
                                // ,'cntr_pick_up_cy_date': row.cntr_pick_up_cy_date?row.cntr_pick_up_cy_date:element.cntr_pick_up_cy_date
                                // ,'cntr_pick_up_cy_date_name': row.cntr_pick_up_cy_date_name?row.cntr_pick_up_cy_date_name:element.cntr_pick_up_cy_date_name
                                // ,'cntr_pre_cooling':row.cntr_pre_cooling?row.cntr_pre_cooling:element.cntr_pre_cooling
                                // ,'cntr_seal_no': row.cntr_seal_no?row.cntr_seal_no:element.cntr_seal_no
                                // ,'cntr_size':row.cntr_size?row.cntr_size:element.cntr_size
                                // ,'cntr_type':row.cntr_type?row.cntr_type:element.cntr_type
                                // , 'cntr_door_code':("N" === props.booking.trans_self_yn )?row.cntr_door_code?row.cntr_door_code:element.cntr_door_code:null
                                // , 'cntr_door_name1':("N" === props.booking.trans_self_yn )?row.cntr_door_name1?row.cntr_door_name1:element.cntr_door_name1:null
                                // , 'cntr_door_name2':("N" === props.booking.trans_self_yn )?row.cntr_door_name1?row.cntr_door_name2:element.cntr_door_name2:null
                                // , 'cntr_door_date':("N" === props.booking.trans_self_yn )?row.cntr_door_date?row.cntr_door_date:element.cntr_door_date:null
                                // , 'cntr_door_date_name':("N" === props.booking.trans_self_yn )?row.cntr_door_date_name?row.cntr_door_date_name:element.cntr_door_date_name:null
                                // , 'cntr_door_user_name':("N" === props.booking.trans_self_yn )?row.cntr_door_user_name?row.cntr_door_user_name:element.cntr_door_user_name:null
                                // , 'cntr_door_user_dept':("N" === props.booking.trans_self_yn )?row.cntr_door_user_dept?row.cntr_door_user_dept:element.cntr_door_user_dept:null
                                // , 'cntr_door_user_fax':("N" === props.booking.trans_self_yn )?row.cntr_door_user_fax?row.cntr_door_user_fax:element.cntr_door_user_fax:null
                                // , 'cntr_door_user_tel':("N" === props.booking.trans_self_yn )?row.cntr_door_user_tel?row.cntr_door_user_tel:element.cntr_door_user_tel:null
                                // , 'cntr_door_user_email':("N" === props.booking.trans_self_yn )?row.cntr_door_user_email?row.cntr_door_user_email:element.cntr_door_user_email:null
                                // , 'cntr_door_address1':("N" === props.booking.trans_self_yn )?row.cntr_door_address1?row.cntr_door_address1:element.cntr_door_address1:null
                                // , 'cntr_door_address2':("N" === props.booking.trans_self_yn )?row.cntr_door_address1?row.cntr_door_address2:element.cntr_door_address2:null
                                // , 'cntr_door_address3':("N" === props.booking.trans_self_yn )?row.cntr_door_address1?row.cntr_door_address3:element.cntr_door_address3:null
                                // , 'cntr_door_address4':("N" === props.booking.trans_self_yn )?row.cntr_door_address1?row.cntr_door_address4:element.cntr_door_address4:null
                                // , 'cntr_door_address5':("N" === props.booking.trans_self_yn )?row.cntr_door_address1?row.cntr_door_address5:element.cntr_door_address5:null
                                // , 'cntr_remark1':row.cntr_remark1?row.cntr_remark1:element.cntr_remark1
                                // , 'cntr_remark2':row.cntr_remark1?row.cntr_remark2:element.cntr_remark2
                                // , 'cntr_remark3':row.cntr_remark1?row.cntr_remark3:element.cntr_remark3
                                // , 'cntr_remark4':row.cntr_remark1?row.cntr_remark4:element.cntr_remark4
                                // , 'cntr_remark5':row.cntr_remark1?row.cntr_remark5:element.cntr_remark5
                            }
                            // Bookmark에서 Container와 연결된 Special Relation 정보도 넣어주자.
                            // selectBookingContainerSpecialBookmarkRelation(row);
                        }
                    });
                }
            });
            setContainerList([...containerList]);
            // door 입력
            // setDoor({
            //     ...door
            //     , 'cntr_door_user_name':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_user_name?bookmark.cntr_door_user_name:door.cntr_door_user_name:null
            //     , 'cntr_door_user_tel':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_user_tel?bookmark.cntr_door_user_tel:door.cntr_door_user_tel:null
            //     , 'cntr_door_address1':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_address1?bookmark.cntr_door_address1:door.cntr_door_address1:null
            //     , 'cntr_door_address2':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_address1?bookmark.cntr_door_address2:door.cntr_door_address2:null
            //     , 'cntr_door_address3':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_address1?bookmark.cntr_door_address3:door.cntr_door_address3:null
            //     , 'cntr_door_address4':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_address1?bookmark.cntr_door_address4:door.cntr_door_address4:null
            //     , 'cntr_door_address5':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_address1?bookmark.cntr_door_address5:door.cntr_door_address5:null
            //     , 'cntr_remark1':bookmark.cntr_remark1?bookmark.cntr_remark1:door.cntr_remark1
            //     , 'cntr_remark2':bookmark.cntr_remark1?bookmark.cntr_remark2:door.cntr_remark2
            //     , 'cntr_remark3':bookmark.cntr_remark1?bookmark.cntr_remark3:door.cntr_remark3
            //     , 'cntr_remark4':bookmark.cntr_remark1?bookmark.cntr_remark4:door.cntr_remark4
            //     , 'cntr_remark5':bookmark.cntr_remark1?bookmark.cntr_remark5:door.cntr_remark5
            // });
            // props.fncDoorParent({
            //     ...door
            //     , 'cntr_door_user_name':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_user_name?bookmark.cntr_door_user_name:door.cntr_door_user_name:null
            //     , 'cntr_door_user_tel':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_user_tel?bookmark.cntr_door_user_tel:door.cntr_door_user_tel:null
            //     , 'cntr_door_address1':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_address1?bookmark.cntr_door_address1:door.cntr_door_address1:null
            //     , 'cntr_door_address2':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_address1?bookmark.cntr_door_address2:door.cntr_door_address2:null
            //     , 'cntr_door_address3':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_address1?bookmark.cntr_door_address3:door.cntr_door_address3:null
            //     , 'cntr_door_address4':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_address1?bookmark.cntr_door_address4:door.cntr_door_address4:null
            //     , 'cntr_door_address5':("N" === props.booking.trans_self_yn )?bookmark.cntr_door_address1?bookmark.cntr_door_address5:door.cntr_door_address5:null
            //     , 'cntr_remark1':bookmark.cntr_remark1?bookmark.cntr_remark1:door.cntr_remark1
            //     , 'cntr_remark2':bookmark.cntr_remark1?bookmark.cntr_remark2:door.cntr_remark2
            //     , 'cntr_remark3':bookmark.cntr_remark1?bookmark.cntr_remark3:door.cntr_remark3
            //     , 'cntr_remark4':bookmark.cntr_remark1?bookmark.cntr_remark4:door.cntr_remark4
            //     , 'cntr_remark5':bookmark.cntr_remark1?bookmark.cntr_remark5:door.cntr_remark5
            // });
        } else {
            // Container 목록과 일치하는 index 찾기
            containerList.forEach((element, key)=>{
                if( key === index ) {
                    containerBookmarkList.forEach((row, i)=>{
                        if( element.cntr_seq ) {
                            row.cntr_seq = element.cntr_seq;
                        } else {
                            row.cntr_seq = index+1;
                        }
                        containerList[key] = {
                            ...element
                            ,'container_bookmark_seq': null
                            ,'container_bookmark_name': null
                            ,'cntr_code': null
                            ,'cntr_iso_code': null
                            ,'cntr_frozen_tmp': null
                            ,'cntr_qty': null
                            ,'cntr_soc_yn': 'N'
                            ,'cntr_empty_yn': 'N'
                            ,'cntr_nor_flag': null
                            ,'cntr_frozen_fc': null
                            ,'cntr_frozen_tmp_unit': null
                            ,'cntr_pick_up_cy_address1': null
                            ,'cntr_pick_up_cy_address2': null
                            ,'cntr_pick_up_cy_address3': null
                            ,'cntr_pick_up_cy_address4': null
                            ,'cntr_pick_up_cy_address5': null
                            ,'cntr_pick_up_cy_code': null
                            ,'cntr_pick_up_cy_name1': null
                            ,'cntr_pick_up_cy_name2': null
                            ,'cntr_pick_up_cy_user_email': null
                            ,'cntr_pick_up_cy_user_fax': null
                            ,'cntr_pick_up_cy_user_name': null
                            ,'cntr_pick_up_cy_user_tel': null
                            ,'cntr_pick_up_cy_date': null
                            ,'cntr_pick_up_cy_date_name': null
                            ,'cntr_pre_cooling': null
                            ,'cntr_seal_no': null
                            ,'cntr_size': null
                            ,'cntr_special_type': null
                            ,'cntr_type': null
                            ,'cntr_vent_open': null
                            ,'cntr_humidity ': null
                            ,'cntr_width': null
                            ,'cntr_selected_yn':'N'
                            ,'cntr_yn': 'Y'
                            , 'cntr_door_code': null
                            , 'cntr_door_name1': null
                            , 'cntr_door_name2': null
                            , 'cntr_door_date': null
                            , 'cntr_door_date_name': null
                            , 'cntr_door_user_name': null
                            , 'cntr_door_user_dept': null
                            , 'cntr_door_user_fax': null
                            , 'cntr_door_user_tel': null
                            , 'cntr_door_user_email': null
                            , 'cntr_door_address1': null
                            , 'cntr_door_address2': null
                            , 'cntr_door_address3': null
                            , 'cntr_door_address4': null
                            , 'cntr_door_address5': null
                            , 'cntr_remark1': null
                            , 'cntr_remark2': null
                            , 'cntr_remark3': null
                            , 'cntr_remark4': null
                            , 'cntr_remark5': null
                            , 'cntr_item_qty' : null                            
                            , 'cntr_stowage_cell' : null
                            , 'cntr_break_bulk_yn' : null
                            , 'cntr_commodity' : null
                            , 'cntr_mng_remark' : null                            
                        }
                    });
                }
            });
            
            setContainerList([...containerList]);
            props.fncContainerParent([...containerList]);
            setDoor({});
            props.fncDoorParent({});
        }
    }


    const selectBookingSpecialBookmark=()=>{
        axios.post("/shipper/selectBookingSpecialBookmark",{ user_no: userData?userData.user_no:null}).then(res => {
            setSpecialBookmarkList(res.data);
        });
    }

    // Container Booking 정보 조회
    const selectContainerOfBooking=(booking)=>{
        axios.post("/shipper/selectContainerOfBooking",{ user_no: userData?userData.user_no:null, booking, lineCode:lineCode}).then(res => {
            let list = res.data;
            // if( props.booking.sch_etd ) {
            //     res.data.forEach((data,key)=> {
            //         const date = props.booking.sch_etd.substr(0,8);
                        
            //         if( !(data.cntr_pick_up_cy_date )) {
            //             list[key] = Object.assign(data,
            //                 {'cntr_pick_up_cy_date':moment(date).subtract(1,'d').format('YYYYMMDD')},
            //                 {'cntr_pick_up_cy_date_name':moment(date).subtract(1,'d').format('YYYY-MM-DD')},);

            //         }
            //     });
            //     setContainerList(list);
            //     props.fncContainerParent(list);
            //     // props.setAllAttachFiles(list.attach_file);
            // } else {
                setContainerList(list);
                props.fncContainerParent(list);
                //??????
                // res.data.forEach((value,index) => {
                // props.setAllAttachFiles(value.attach_file);
                // })
            // }
            if( list.length > 0 ) {
                getAttachFile(list);//모든 첨부파일 조회
                setDoor(list[0]);
            }
        });
    }

    const getAttachFile = async (list) =>{
        await axios.post("/shipper/getAttachFile",{container:list[0],gubun:'BK'}). then(res=> {
            if(res.data.length > 0) {
                let obj = {...props.allAttachFiles}
                let result = res.data||{};
                list.forEach((el, ind)=>{
                    //cntr_seq따라 파일 세팅
                    obj[el.cntr_seq]=  result.filter(x=>x.cntr_seq==el.cntr_seq)
                })

                //저장안된 컨테이너에 있는 파일은 삭제해준다.
                let cntrSeq = list.map(x=>x.cntr_seq)
                let fileSeq = result.map(x=>x.cntr_seq); 
                let delAttList = fileSeq.filter(x=>!cntrSeq.includes(parseInt(x))); 
                if(delAttList.length>0){delAttList.forEach(rownum=> deleteRowAttachFiles( list, rownum))}
                props.setAllAttachFiles({...obj});
            }else {
                props.setAllAttachFiles({});
            }
        }).catch(e => {
            console.log(e)
        })
    }
    const deleteRowAttachFiles = async (contaier,rownum) =>{
        axios.post("/shipper/deleteAttachRow",{container:contaier[0],rownum: rownum,gubun:'BK'}).then(res=>{
        })
    }
    // Container Special Booking 정보 조회
    const selectContainerSpecialOfBooking=(booking)=>{
        axios.post("/shipper/selectContainerSpecialOfBooking",{ user_no: userData?userData.user_no:null, booking}).then(res => {
            if( res.data.length === 0 ) {
                setContainerSpecialList([{'key':1, 'cntr_seq':1}]);
            } else {
                setContainerSpecialList(res.data);
            }
        });
    }

    // Container Special Relation Bookmark 조회 (쓰이지 않음 23.07. 28)
    // const selectBookingContainerSpecialBookmarkRelation = (container) => {
    //     axios.post("/shipper/selectBookingContainerSpecialBookmarkRelation",{ user_no: userData?userData.user_no:null,container}).then(res => {
    //         for( let i =containerSpecialList.length; i > 0; i-- ) {
    //             let row = containerSpecialList[i-1];

    //             if( row.cntr_seq === container.cntr_seq ) {
    //                 containerSpecialList.splice(i-1, 1);
    //             }
    //         }
    //         let data = res.data;

    //         if( 0 !== data.length ) {         

    //             data.forEach((element, key)=>{
    //                 element.cntr_seq = container.cntr_seq;
    //                 containerSpecialList.push(element);
    //             });
    //             setContainerSpecialList([...containerSpecialList]);
    //         }
    //     });
    // }


    const onAddContainer = () => {
        let maxCntrSeq = 0;
        containerList.forEach((row, i) => {
            if( maxCntrSeq < parseInt(row.cntr_seq) ) {
                maxCntrSeq = parseInt(row.cntr_seq);
            }
        });
        if( 999 < maxCntrSeq ) {
            props.onNotiAlert("danger", "더이상 추가할 수 없습니다.");
            return false;
        }
        setContainerList([...containerList,{'key': parseInt(maxCntrSeq)+1, 'cntr_seq': parseInt(maxCntrSeq)+1, 'cntr_yn': (allCheck?'Y':'N'), 'cntr_soc_yn':'N', 'bkg_no':booking.bkg_no, 'bkg_date':booking.bkg_date,'cntr_nor_flag':containerList[0]?.cntr_nor_flag||null}]);
        setContainerSpecialList([...containerSpecialList,{'key':parseInt(maxCntrSeq)+1, 'cntr_seq':parseInt(maxCntrSeq)+1, 'cntr_yn': (allCheck?'Y':'N'),'cntr_soc_yn':'N'}]);
    }
    
    const onDelContainer = (index) => {
        let cntr_seq = containerList[index].cntr_seq;
        deleteRowAttachFiles( containerList,cntr_seq);
        if( containerList.length === 1 ) {
            setContainerList([{'key':1, 'cntr_seq':1, 'cntr_yn': (allCheck?'Y':'N')}])
        } else {
            const cntrIdx = containerList.findIndex((item) => {return item.cntr_seq == cntr_seq});
            if(cntrIdx > -1) containerList.splice(cntrIdx,1);
            setContainerList([...containerList]);
            const specialIdx = containerSpecialList.findIndex((item) => {return item.cntr_seq === cntr_seq});
            containerSpecialList.splice(specialIdx,1);
            if(specialIdx > -1) setContainerSpecialList([...containerSpecialList]);
        }
    }

    // Special 목록 삭제
    const onDelSpecial = (special) => {
        // 어느 컨테이너가 삭제되었는지 cntr_seq 값을 뽑는다.
        let cntr_seq = special.cntr_seq;
        let special_seq = special.special_seq;

        if(containerSpecialList.length === 1) {
            setContainerSpecialList([{'key':1, 'cntr_seq':cntr_seq,'special_seq':1}])
        } else {
            const specialIdx = containerSpecialList.findIndex((item) => {return item.cntr_seq === cntr_seq && item.special_seq === special_seq });
            containerSpecialList.splice(specialIdx,1);
            if(specialIdx > -1) setContainerSpecialList([...containerSpecialList]);
        }
    }

    // 컨테이너 자식에게 받은 정보
    const fncOnBlurContainer=(index, container)=>{
        let newCntrList = _.cloneDeep(containerList);
        newCntrList[index] = container;
        newCntrList.map(val=>{
            if(container.cntr_nor_flag=='Y'){//기존 냉동정보리셋
                val.cntr_nor_flag = 'Y'
                val.cntr_frozen_tmp = null
                // val.cntr_frozen_tmp_unit = null
                val.cntr_humidity = null
                val.cntr_vent_open = null
            }else{
                val.cntr_nor_flag = null;
            }
        });
        
        setContainerList(newCntrList);
        props.fncContainerParent(newCntrList);
    }
    // 컨테이너 자식에게 받은 정보
    const fncOnBlurSpecialList=(specialList)=>{
        setContainerSpecialList([...specialList]);
    }
    // const fncOnBlurDoor=(door)=>{
    //     setDoor(door);
    //     let list = containerList;
    //     containerList.forEach((data,key)=> {
    //         if( key === 0 || key === '0' ) {
    //             list[key] = {...data,
    //                 'cntr_door_code':door.cntr_door_code, 'cntr_door_name1':door.cntr_door_name1, 'cntr_door_name2':door.cntr_door_name2,
    //                 'cntr_door_date':door.cntr_door_date, 'cntr_door_date_name':door.cntr_door_date_name, 'cntr_door_user_name':door.cntr_door_user_name,
    //                 'cntr_door_user_dept':door.cntr_door_user_dept, 'cntr_door_user_fax':door.cntr_door_user_fax,
    //                 'cntr_door_user_tel':door.cntr_door_user_tel, 'cntr_door_user_email':door.cntr_door_user_email,
    //                 'cntr_door_address1':door.cntr_door_address1, 'cntr_door_address2':door.cntr_door_address2, 'cntr_door_address3':door.cntr_door_address3, 'cntr_door_address4':door.cntr_door_address4, 'cntr_door_address5':door.cntr_door_address5,
    //                 'cntr_remark1':door.cntr_remark1, 'cntr_remark2':door.cntr_remark2, 'cntr_remark3':door.cntr_remark3, 'cntr_remark4':door.cntr_remark4, 'cntr_remark5':door.cntr_remark5
    //             }
    //         }
    //     });
    //     setContainerList(list);
    //     props.fncDoorParent(door);
    // }
    const onContainerCheck = () => {
        let list = containerList;
        let vVal = 'N';
        if(allCheck) {
            vVal = 'N';
        } else {
            vVal = 'Y';
        }
        containerList.forEach((data,key)=> {
            list[key] = {...data,'cntr_yn':vVal}
        });        
        setContainerList(list);
        setAllCheck(!allCheck);
    }

    const fncCheckBoxOnChange =(element)=> {
        let list = containerList;
        
        containerList.forEach((data,key)=> {
            if( element.cntr_seq == data.cntr_seq ) {
                list[key] = {...data,'cntr_yn': (element.cntr_yn === 'Y'?'N':'Y')}
            }
        });
        
        setContainerList(list);
        setCntrCheck(!cntrCheck);
    }

    const attachFileDownload = async() => {
        setLoading(true);
        // if(props.cargo) {
        //     if(props.cargo.cargo_type==="4"||props.cargo.cargo_type==="3") {
                await axios.post("/loc/downloadSample",{service:'C1'},{responseType:'arraybuffer',headers:{'Content-Type':'application/json','Accept':'application/xlsx'}}).then(res => { 
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href=url;
                    link.setAttribute('download',`${booking.bkg_no}_1.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }).catch((e)=> {
                    props.onAlert('danger',"파일을 찾을 수 없습니다.");
                })
            // }
            // if(props.cargo.cargo_type==="3") {
                await axios.post("/loc/downloadSample",{service:'C2'},{responseType:'arraybuffer',headers:{'Content-Type':'application/json','Accept':'application/pdf'}}).then(res => { 
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href=url;
                    // link.setAttribute('download','MSDS_Acrylic_Acid.pdf');
                    link.setAttribute('download',`${booking.bkg_no}_2.pdf`);
                    document.body.appendChild(link);
                    link.click();
                }).catch((e)=> {
                    props.onAlert('danger',"파일을 찾을 수 없습니다.");
                })
            
                await axios.post("/loc/downloadSample",{service:'C3'},{responseType:'arraybuffer',headers:{'Content-Type':'application/json','Accept':'application/pdf'}}).then(res => { 
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href=url;
                    // link.setAttribute('download','22-20_Certificate_of_inspection.pdf');
                    link.setAttribute('download',`${booking.bkg_no}_3.pdf`);
                    document.body.appendChild(link);
                    link.click();
                }).catch((e)=> {
                    props.onAlert('danger',"파일을 찾을 수 없습니다.");
                })
            // }else if(props.cargo.cargo_type!=="4" && props.cargo.cargo_type!=="3") {
            //     props.onAlert('danger',"Cargo Type이 위험물, OOG 화물인 경우에만 다운로드가 가능합니다.");
            // }
        // }
        setLoading(false);
    }

    // Container Size Type 목록조회
    const selectLineCodeCntrSztp = (params) => {
        axios.post("/shipper/selectLineCodeCntrSztp",{ params,lineCode:lineCode }).then(res => {
            setLineCodeCntrSztp(res.data);
        });
    }
    
    return (
        <>
            <Row id="Container">
                <Col xl="12" lg="12">
                <Card style ={{zIndex:'initial'}}>
                        <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
                            <Row className="pb-2">
                                <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>CONTAINER
                                </Col>
                                <Col className="mt-2 mb-0 col-7">
                                    <Row id="bookmarkselect">
                                        <Col className="col-10 pr-0" >
                                        {/* <Col className="col-10 pr-0" style={{zIndex:'10'}}> */}
                                            <Select
                                                className="react-select react-select-primary"
                                                name="cargoBookmark"
                                                value={{
                                                    value:booking.container_bookmark_seq?booking.container_bookmark_seq:'',
                                                    label:booking.container_bookmark_name?booking.container_bookmark_name:'선택'
                                                }}
                                                onChange={(e)=>fncSelectContainer(e?e:null)}
                                                options={containerBookmarkList}
                                                placeholder="선택"
                                                ref={containerFocus}
                                                isClearable={booking.container_bookmark_seq?true:false}/>
                                        </Col>
                                        <Col className="col-2 pl-auto pr-auto" style={{textAlign:'center'}}>
                                            <ContainerBookmark
                                                containerBookmarkList={containerBookmarkList}
                                                specialBookmarkList={specialBookmarkList}
                                                selectBookingContainerBookmark={props.selectBookingContainerBookmark}
                                                selectBookingSpecialBookmark={selectBookingSpecialBookmark}
                                                onAlert={props.onAlert}
                                                lineCodeCntrSztp={lineCodeCntrSztp}
                                                {...props}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Collapse isOpen={coll} id="cntr_roop">
                                <Col xl="12" className="mb-2 col-12">
                                    <Row>
                                        <Col className="pr-0">
                                            <FormGroup check className="mt-2">
                                                <Label check>
                                                <Input defaultValue="" type="checkbox"  checked={allCheck} 
                                                    onChange = {()=>onContainerCheck()}
                                                    />전체
                                                    <span className="form-check-sign" />
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col className="mb-2 col-5">
                                            <ButtonGroup className="pull-right pr-2">
                                            {loading?
                                                [1,2,3,4,5,6].map((value, index)=>
                                                <div key={index}><Spinner color='primary' size='sm' type='grow'/></div>)
                                                :<Button
                                                    className="pt-0 pb-0"
                                                    color="default"
                                                    data-toggle="tooltip"
                                                    outline
                                                    size="sm"
                                                    id="attach"
                                                    type="button"
                                                    onClick={attachFileDownload}><i className="fa fa-download"/>샘플 다운로드
                                                </Button>
                                                }
                                                <UncontrolledTooltip delay={0} target="attach">첨부파일 양식 다운로드</UncontrolledTooltip>
                                                <Button
                                                    className="pt-0 pb-0"
                                                    color="default"
                                                    data-toggle="tooltip"
                                                    outline
                                                    id="addCntr"
                                                    size="sm"
                                                    type="button"
                                                    onClick={onAddContainer}><i className="fa fa-plus"/>추가
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <hr className="mt-0"/>
                                <Col className="col-12 mb-3 ml-auto mr-auto">
                                    {(containerList.length>0) && containerList.map((element, key)=>{
                                        return (
                                            <Row key={key}>
                                                <Col xl="12" lg="12">
                                                    <Row>
                                                        <Col xl="0" className="col-0 pl-2 mt-auto mb-auto">
                                                            <FormGroup check style={{height:'69px'}}>
                                                                <Label check>
                                                                <Input 
                                                                    defaultValue="" 
                                                                    type="checkbox"  
                                                                    name="cntr_check"
                                                                    checked={element?.cntr_yn=="Y"?true:false}
                                                                    onChange={()=>fncCheckBoxOnChange(element)}
                                                                />
                                                                <span className="form-check-sign" />
                                                                </Label>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <Row >
                                                            {/* <Row style={{zIndex:1000-key}}> */}
                                                                <Container
                                                                    index={key}
                                                                    container={element}
                                                                    clearFile={clearFile}
                                                                    setClearFile={(param)=>setClearFile(param)}
                                                                    containerBookmarkList={containerBookmarkList}
                                                                    containerSpecialList={containerSpecialList}
                                                                    specialBookmarkList={specialBookmarkList}
                                                                    fncOnBlurContainer={fncOnBlurContainer}
                                                                    fncOnBlurSpecialList={fncOnBlurSpecialList}
                                                                    onDelContainer={onDelContainer}
                                                                    onDelSpecial={onDelSpecial}
                                                                    openType="MAIN"
                                                                    fncSelectContainerList={fncSelectContainerList}
                                                                    booking={props.booking}
                                                                    lineCodeCntrSztp={lineCodeCntrSztp}
                                                                    {...props}
                                                                    />
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Col>
                                {/* <Row style={{paddingTop:'25px'}}>
                                    <Door 
                                        door={door}
                                        booking={booking}
                                        openType="MAIN"
                                        fncOnBlurDoor={fncOnBlurDoor}
                                        {...props}
                                    />
                                </Row> */}
                            </Collapse>
                            <div className="text-center" onClick={() => setColl(!coll)}>
                                <div>
                                    <Button className="p-0" color="link" id="linemore" onClick={() => setColl(!coll)} style={{height:'21px'}}>
                                        {coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="linemore">{coll?'Close':'Open'}</UncontrolledTooltip>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
});

export default ContainerCard;