import React from 'react';
import {Modal, Button} from 'reactstrap';
import PropTypes from 'prop-types';


const ConfirmMessage =((props)=>{
    const {onClose,
        message,
        title,
        confirmText,
        cancelText,
        buttonsComponent} = props;

    console.log(props.message);
    if ( buttonsComponent ) {
        const CustomComponent = buttonsComponent;
        buttonsComponent = <CustomComponent onClose={onClose} />;
    }
    const fncCheckConfirm =()=> {
        const checkbox = document.getElementById("CONFIRM_CHECK_BOX");
        if( checkbox ) {
            if( checkbox.checked ) {
                onClose(true);
            } else {
                return false;
            }
        } else {
            onClose(true);
        }
    }
    return (
        <Modal isOpen toggle={()=>onClose(false)}>
            <div className="modal-header">
                <button
                    className="close"
                    type="button"
                    onClick={()=> onClose(false)}
                    >
                <span>×</span>
            </button>
                <h5  style={{whiteSpace:'pre-wrap',fontSize: '14px', margin: '0', fontWeight: '500', lineHeight: '1'}}
                    className="modal-title text-center"
                    id="exampleModalLabel"
                    >
                {title}
                </h5>
            </div>
            <div className="modal-body"
            style={{whiteSpace:'pre-wrap',fontSize: '13px', marginBottom: '6px', fontWeight: '500', lineHeight: '1.2', textAlign:'center', padding:'20px'}}>
                {/* {message} */}
                {message&&message.length>0&&message.includes('\n')
                    ? message.split('\n').map((line,inx)=>{
                        return (<span key={inx}>{line}<br/></span>)})
                    : message
                }
            </div>
            <div className="modal-footer">
                <div className="left-side">
                    <Button
                        className="btn-link"
                        color="default"
                        type="button"
                        onClick={()=> fncCheckConfirm()}
                        >
                    {confirmText}
                    </Button>
                </div>
            <div className="divider" />
                <div className="right-side">
                    <Button className="btn-link"
                        color="danger"
                        type="button" 
                        onClick={()=> onClose(false)}>
                    {cancelText}
                    </Button>
                </div>
            </div>
        </Modal>
    )

});
// const ConfirmMessage = ( {
//     onClose,
//     message,
//     title,
//     confirmText,
//     cancelText,
//     buttonsComponent,
// } ) => {
//     // const { title, message, confirmText, cancelText } = props;
//     // const [classic, setClassic] = React.useState(false);
//     if ( buttonsComponent ) {
//         const CustomComponent = buttonsComponent;
//         buttonsComponent = <CustomComponent onClose={onClose} />;
//     }
//     console.log('confirm message>> ',message)
//     const fncCheckConfirm =()=> {
//         const checkbox = document.getElementById("CONFIRM_CHECK_BOX");
//         if( checkbox ) {
//             if( checkbox.checked ) {
//                 onClose(true);
//             } else {
//                 return false;
//             }
//         } else {
//             onClose(true);
//         }
//     }
//     return (
//         <Modal isOpen toggle={()=>onClose(false)}>
//             <div className="modal-header">
//                 <button
//                     className="close"
//                     type="button"
//                     onClick={()=> onClose(false)}
//                     >
//                 <span>×</span>
//             </button>
//                 <h5
//                     className="modal-title text-center"
//                     id="exampleModalLabel"
//                     >
//                 {title}
//                 </h5>
//             </div>
//             <div className="modal-body">
//                 {/* {message} */}
//                 {message&&message.includes('\n')
//                     ? message.split('\n').map(line=>{
//                         return (<span>{line}<br/></span>)})
//                     : message
//                 }
//             </div>
//             <div className="modal-footer">
//                 <div className="left-side">
//                     <Button
//                         className="btn-link"
//                         color="default"
//                         type="button"
//                         onClick={()=> fncCheckConfirm()}
//                         >
//                     {confirmText}
//                     </Button>
//                 </div>
//             <div className="divider" />
//                 <div className="right-side">
//                     <Button className="btn-link"
//                         color="danger"
//                         type="button" 
//                         onClick={()=> onClose(false)}>
//                     {cancelText}
//                     </Button>
//                 </div>
//             </div>
//         </Modal>
//     )
// }

// ConfirmMessage.propTypes = {
//     onClose: PropTypes.func.isRequired,
//     message: PropTypes.node,
//     title: PropTypes.node,
//     confirmText: PropTypes.node,
//     cancelText: PropTypes.node,
//     buttonsComponent: PropTypes.func
// }

export default ConfirmMessage;