import 'date-fns';
import React,{useState} from 'react';
import {InputAdornment, } from '@material-ui/core';
import {makeStyles, styled ,ThemeProvider} from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import krLocale from 'date-fns/locale/ko';
import {DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const useStyles = makeStyles({
    root:{fontSize:'17px'
      ,color:'#111'
      ,fontWeight:'500;border:1px solid #06b3b9'
      ,borderRadius:'8px'
      ,height:'34px'
      ,boxSizing: 'initial'
      ,cursor:'pointer'
    },
   input:{
    // width:'80%',
    padding:'0 0 0 13px'
    ,height:'34px'
    ,boxSizing: 'initial'
    // ,width:'100%'
    ,flex:1
    ,cursor:'pointer'
   },
  MuiInputAdornment:{
      width:'20%'
  
  }
})


const defaultMaterialTheme = createTheme ({
  palette:{
      primary:{main:'#06b3ba',contrastText:'#fff'}
  },
})
export default function DatePickersCustom(props) {
const classes=useStyles()
const [focusStyle,setFocusStyle] = useState('assetsNew/image/ico/ico-calendar.png')
const {formControlProps,labelText,id,format,onChangeValue,setValue,autoOk,variant,disabled, type} = props;

  return (
    <ThemeProvider theme ={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={krLocale}>
          <DatePicker 
          // disableToolbar
          variant={variant}
          onOpen={()=>{setFocusStyle(`assetsNew/image/ico/ico-calendar-on.png`)}}
          onClose={()=>setFocusStyle(`assetsNew/image/ico/ico-calendar.png`)}
          format={format}
          autoOk={autoOk}
          id={id}
          disabled={disabled}
          value={setValue}
          onChange={onChangeValue}
          InputProps={{...props.InputProps, classes:{input:classes.input, root:classes.root},disableUnderline:true ,
            endAdornment:<InputAdornment position= 'end' classes={{root:classes.MuiInputAdornment}}>
              <img src={process.env.PUBLIC_URL + focusStyle } />
            </InputAdornment>
          }}
        />
    </MuiPickersUtilsProvider></ThemeProvider>
  )
}