import React,{ useState, useEffect, useRef } from "react";
import {useHistory, useLocation} from "react-router-dom";
import axios from 'axios';

import SchCalStatus from './SchCalStatus.js'
import SchCalHistory from './SchCalHistory.js'
import SchCalMap from './SchCalMap.js'
import NotiFormModal from './NotiFormModal.js'
import SchCalAgreement from './SchCalAgreement.js'

// core components
import {TextField} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import {Search as SearchIcon,
} from "@material-ui/icons";

import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Button from "muiComponents/CustomButtons/Button.js";

export default function ShipmentIssueIndex(props) {
	const {userData,alertMessage} = props;
    const location = useLocation();
    const history = useHistory();

    const {state} = location;

    //조건param
	const [searchBlNo , setSearchBlNo] = useState("");
    //현재상태Tbl
	const [ownMrnCalSch , setOwnMrnCalSch] = useState([]);
    //히스토리Tbl
	const [calSchHist , setCalSchHist] = useState([]);
    const [openNotiForm,setOpenNotiForm] = React.useState(false);
    const [openAgreeForm,setOpenAgreeForm] = React.useState(false);
    const openNotiFormMode = useRef('');
    //선박위치 
    const [openMap,setOpenMap] = React.useState(false);

    // const locState = props.location.state ||null;  
    
    // useEffect(()=>{
    //     console.log(state)
    //     if(state?.pageType=='main'&&state?.agreeYN=='Y'){
    //         setOpenNotiForm(true);
    //         // history.replaceState('',null)
    //     }
    // }, []);

    const onHandleSearchBtn = async() => {
        setOpenMap(false);
        if(!searchBlNo){alertMessage('BL No. 정보를 입력바랍니다.','error'); return; }
        try { 
            let res = await axios.post("/api/selectShipmentIssueList"
                                        ,{bl_no : searchBlNo})
            const result = res.data;//{desc:'Y', ownMrnCalSch :res_calSch, calSchHist: res_CalSchHist}
            if(result?.desc =='Y'){
                setOwnMrnCalSch(result?.ownMrnCalSch ||[]);
                setCalSchHist(result?.calSchHist ||[]);
                setOpenMap(true);
            }else{
                alertMessage('오류가 발생했습니다.','error'); 
            }
        }catch(err){
            let errMsg = err.response?.data||'';
            console.log(errMsg)
            if(errMsg)alertMessage(errMsg,'error'); 
            else alertMessage('오류가 발생했습니다.','error'); 
            setOwnMrnCalSch([]);
            setCalSchHist([]);
            setOpenMap(false);
        }
	}

    const handleKeyDown = (e, fn) => {
        if(e.key ==='Enter'){
            fn();
        }
    }
    const onHandleNotiSetting = (e, fn) => {
        if(userData) {
            //동의여부 확인 
            setOpenAgreeForm(true);
        } else {
            props.openLogin();
        }
    }

    const onHandleOpenNotiForm = (state , mode)=>{
        openNotiFormMode.current = mode||'';
        setOpenNotiForm(state)
        setOpenAgreeForm(false);
    }

    const fncReportViewer=(data)=> {
        if( data.length<1 ) {
            alertMessage("BL No. 정보를 조회바랍니다.","error");
            return ;
        }

        let ie_type = data[0]?.i_e;

        if( !ie_type ) {
            alertMessage("정보를 다시 조회바랍니다.","error");
            return ;
        }

        var obj = {}
        obj.mbl_no = data[0].mbl_no;
        var json = JSON.stringify(obj);

        let form = document.reportForm;
        // form.action = 'http://localhost:5000/shipper/reportViewer';
        form.action = '/api/reportViewer';
        form.target = 'popReport'; // window.open() 의 두번째 변수와 동일해야.
        form.file_id.value = ie_type=='E' ? 'schCal_E' :'schCal_I';
        form.file_path.value = 'LOGISVW';
        form.name.value = 'FILENAME';
        form.connection.value = 'logisvw' ;
        form.parameters.value = json;
        window.open('', 'popReport', 'width=1050px, height=850px');
        form.submit();
    }

	return (<>
		<div  style={{marginTop:'20px', marginBottom:'10px'}}>
            {/* 검색 조건 */}
            <Card style={{marginTop:'0',marginBottom:'5px'}}>
                <CardBody>
                    <Grid container justifyContent='space-between'>
                        <Grid item lg={5} sm={5} xs={5}>
                            <Grid container style ={{display :'flex',}}>	
                                <Grid item  lg={6} sm={6} xs ={6}style={{marginRight:'15px'}}>
                                    <TextField id="searchBlNo" label="B/L No." 
                                        onChange={e => setSearchBlNo(e.target.value)} 
                                        onKeyDown={(e)=>handleKeyDown(e, ()=>onHandleSearchBtn())}
                                        value={searchBlNo} fullWidth/>
                                </Grid>
                                <Grid item  lg={2} sm={2} xs ={2} style ={{display:'flex', justifyContent:'space-around'}} >
                                    <Button color="info" endIcon={<SearchIcon/>} size ='sm'  style={{fontSize:'12px', marginTop:'10px',marginBottom:0, height:'35px'}}
                                    onClick = {()=>onHandleSearchBtn()}
                                    >Search</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item  lg={2} sm={2} xs ={2} style ={{display:'flex', justifyContent:'flex-end' }} >
                            <Button color="warning" size ='sm' style={{fontSize:'12px', marginTop:'10px',marginRight:'8px', marginBottom:0, height:'35px'}}
                            // disabled={ownMrnCalSch.length<1}
                            onClick = {()=>fncReportViewer(ownMrnCalSch)}
                            >NOTICCE 다운로드</Button>
                            <Button color="primary" size ='sm' style={{fontSize:'12px', marginTop:'10px', marginBottom:0, height:'35px'}}
                            onClick = {()=>onHandleNotiSetting()}
                            >이메일 알림 설정</Button>
                        </Grid>
                    </Grid>
                </CardBody>
            </Card>
            <div style={{width:'100%', display:'flex', justifyContent:'flex-end', color:'#ed7d31'}}>
                <strong>※ KL-Net으로 적하목록 신고한 선적 건만 조회 가능합니다. </strong>
            </div>
            {/* 현재 상태*/}
            <SchCalStatus rows={ownMrnCalSch} ieType = {ownMrnCalSch.length>0?ownMrnCalSch[0]?.i_e:'E'} {...props}/>
            {/* HISTORY*/}
            <SchCalHistory rows={calSchHist} ieType = {ownMrnCalSch.length>0?ownMrnCalSch[0]?.i_e:'E'} ownMrnCalSch={ownMrnCalSch.length>0?ownMrnCalSch[0]:{}} {...props}/>
            {/* 실시간 선박 위치*/}
            <SchCalMap parameter={ownMrnCalSch.length>0?ownMrnCalSch[0]:{}} openMap={openMap} setOpenMap = {setOpenMap} {...props}/>
            {/* 이메일 알림 설정 모달 */}
            <NotiFormModal
                openNotiForm={openNotiForm}
                onHandleOpenNotiForm={onHandleOpenNotiForm}
                setOpenNotiForm={e=> setOpenNotiForm(e)}
                openNotiFormMode={openNotiFormMode||''}
                {...props}
            /> 
            {/* 이메일 수집 동의 팝업 */}
            <SchCalAgreement  pageType={'schCal'}
            openAgreeForm={openAgreeForm} 
            setOpenAgreeForm={setOpenAgreeForm} 
            // setOpenNotiForm={e=> setOpenNotiForm(e)} 
            onHandleOpenNotiForm={onHandleOpenNotiForm}
            {...props}/>
		</div> 
        <form id="reportForm" name="reportForm" >
            <input type="hidden" name="system_id"   value="LOGISVIEW" />
            <input type="hidden" name="user_id"     value={`${(userData && userData.user_no)||'GUEST'}`} />
            <input type="hidden" name="file_type"   value="pdf" />
            <input type="hidden" name="file_id"     value="" />
            <input type="hidden" name="file_path"   value="" />
            <input type="hidden" name="name"        value="" />
            <input type="hidden" name="connection"  value='logisvw'/>
            <input type="hidden" name="parameters" id="parameters"/>
        </form>
    </>
	);
}