import React,{useState,useRef, useEffect, useLayoutEffect,useMemo, useCallback, forwardRef} from 'react';
// import { Link, useHistory} from "react-router-dom";
// import Select from "react-select";
import cn from 'classnames';
import * as validation from 'muiComponents/common/validation.js';
import axios from 'axios';
import Style from './main.module.css'
import _ from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {InputBase,} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
const useStyles = makeStyles((theme) => ({
    paper:{
        boxShadow: 'none'
    },
    listbox: {
        boxSizing: 'initial',
        width:'calc( 100% - 10px )',
        height:'100%',
        maxHeight:'256px',
        overflow:'hidden',
        overflowY:'auto',
        margin:'0 auto',
        padding: 0,
    '& li':{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        flexDirection: 'row-reverse',
        boxSizing: 'initial',
        padding:0,
        height:'42px',
        overflow:'hidden', 
        fontSize:'16px',
        color:'#111',
        fontWeight:'500'
    },
      '& li[data-focus="true"]': {
        backgroundColor: '#26bdc3',
        color: 'white',
        cursor: 'pointer',
      },
      '& li:active': {
        backgroundColor: '#26bdc3',
        color: 'white',
      }
    }
  }));

const CustomPaper =({children,...paperProps})=>{
    return (<>{children}
                <button type="button" className={Style.btnLayerClose} // onClick={()=>paperProps.setClickActive(false)}
                    ><img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-layer-close.png`}/>
                </button></>
)}

export default function CustomSelectBox(props) {
    const classes = useStyles();
    const {portData ,itemType ,userData, onAlert
        ,portValue, setPortValue} =props;
    const [ checkedItems, setCheckedItems ] = useState(new Set());
    const [ options, setOptions ] = useState([]);
    const [ portList, setPortList ] = useState([]);
    const [ clickActive , setClickActive ] = useState(false);

    useEffect(() => {
		if((portData&&portData.length) !== (portList&&portList.length)){
			portData.length>0&&setPortList([...portData])
			if(options&&options.length>0){
                selectBookmark([...options])
            }else{selectBookmark([...portData])};
		}
    }, [portData]);
    // useEffect(() => {
    //     console.log('call')
	// 	if((portData&&portData.length) !== (portList&&portList.length)){
	// 		portData.length>0&&selectBookmark([...portData]);
	// 		setPortList([...portData])
	// 	}
    // }, [portData]);
    const selectBookmark =(portList)=>{ 
        if(userData){
            const checkType = itemType=='pol'?'S':itemType=='pod'?'E':''
            axios.post(`/shipper/selectPortBookmark`,{type :checkType, })  //최초셀렉트
            .then(res => {      
                let result =res.data;
                // console.log(checkType,res.data,result.length,portData)
				let opt =[]
                if(result.length>0){
					 opt = portList.map(obj=>{
						const bookmarkBool = result.findIndex(y=>y.port_code==obj.port_code)>-1?'즐겨찾기':'기본'
						// console.log(obj,bookmarkBool)
						return {...obj, bookmark: bookmarkBool}
					})	
					// console.log(opt)
					// setOptions(opt.sort((a,b)=>b.bookmark.localeCompare(a.bookmark)))
					setCheckedItems(new Set(result.map(item=>item.port_code)))
				}else{
					// setOptions(portData)
					opt = portList.map(obj=>{
						return {...obj, bookmark: '기본'}
					})	
				}
				setOptions(opt.sort((a,b)=>b.bookmark.localeCompare(a.bookmark)))
				// setOptions(portData)
            }).catch(err => {
                if(err.response.status === 403||err.response.status === 401) {
                    // alertMessage('[오류] PORT 조회할 수 없습니다', 'error');
                    console.log('[오류]', err);
                    // setPortData([])
                }
            });
        }else{
            setCheckedItems(new Set())
            setOptions(portData)
        }
	}
    const onCheckedBookmark = (isChecked , type , value)=>{
        if(!userData){
            onAlert('error',validation.NOTLOGIN_MSG);
            return false
        }
        if(isChecked){// 즐겨찾기 추가 
            axios.post("/shipper/insertPortBookmark",{port_code:value , type :type})
            .then(res => {      
                setCheckedItems(prev=>{
                    const set = new Set(prev);
                    set.add(value)
                    return set
                });
				const opt = options.map(obj=>{
					if(obj.port_code ==value){
						return {...obj, bookmark: '즐겨찾기'}
					}else{return obj}
				})
				// console.log('add',opt);
				setOptions(opt.sort((a,b)=>b.bookmark.localeCompare(a.bookmark)))
                // console.log(res)
                if(res.data === 23505) {
                    onAlert('error',"이미 등록있는 업체입니다.")
                }
            }).catch(err => {                
                onAlert('error','[오류] 등록하는 중 오류가 발생하였습니다.');
                // console.log('[오류]', err);
            });
        }else if (!isChecked){
            axios.post("/shipper/deletePortBookmark",{port_code:value , type :type})
            .then(res => {      
                setCheckedItems(prev=>{
                    const set = new Set(prev);
                    set.delete(value)
                    return set
                });
				const opt = options.map(obj=>{
					if(obj.port_code ==value){
						return {...obj, bookmark: '기본'}
					}else{return obj}
				})
				// console.log(opt)
				setOptions(opt.sort((a,b)=>b.bookmark&&b.bookmark.localeCompare(a.bookmark)))
                // console.log(res)
                if(res.data === 23505) {
                    onAlert('error',"이미 등록있는 업체입니다.");
                }
            }).catch(err => {
                console.log('[오류]', err);
            });
        }
    }
   
    return (<>
    <div className={Style.selectItemWrap}  >
      <Autocomplete
            options = {options}
            // disableCloseOnSelect
            disableClearable={false}
            getOptionLabel = { option =>option.port_code? "["+option.port_code+"] "+option.port_name:''}
            getOptionSelected = { (option,value) => option.port_code == value.port_code}
            // id="start"
            onChange={(e,option)=>setPortValue(option||null)}
            value={portValue}
            classes={{
                popper:Style.resultWrap,
                paper:classes.paper,
                listbox:cn(classes.listbox,Style.resultWrapScrollul),
            }}
            renderInput={params => { 
                const {InputProps, InputLabelProps, ...rest}=params;
                return (<>
                <InputBase  {...rest} 
                ref = {InputProps.ref}
                placeholder={itemType=='pol'?"POL":'POD'} 
                classes={{root:Style.inpArea}}
                // inputProps={{...params.inputProps}}
                fullWidth 
                endAdornment={ <img src={process.env.PUBLIC_URL + `/assetsNew/image/bg/bg-select01.png`} 
                                onClick={()=>setClickActive(true)} style={{cursor:'pointer'}}/>}
              
                />
                </>
            )}}
            groupBy={option=>option.bookmark}
            renderGroup={(params)=>[<div className={Style.resultWrapScrollulliLast}>{params.children}</div>]}
            PaperComponent={CustomPaper}
            open={clickActive}
            onOpen={()=>setClickActive(true)}
            onClose={()=>setClickActive(false)}
            renderOption={(option,{inputValue,selected})=>{
                return(<>
                <div className = {Style.resultWrapScrollulli} >
                    <span className={Style.resultWrapScrollullilabel}>   
                        {!userData?<div className={Style.none}/>
                        :checkedItems.has(option.port_code)
                        ?<StarIcon style={{color:'#00acc1',verticalAlign:'bottom'}}
                                    onClick={(e)=>onCheckedBookmark(false,itemType,option.port_code.toUpperCase())} />
                        :<StarBorderIcon style={{color:'#00acc1',verticalAlign:'bottom'}}
                                    onClick={(e)=>onCheckedBookmark(true,itemType,option.port_code.toUpperCase())} />
                        }
                        <span style={{display: 'flex',width: 'inherit'}}>
                            <div style={{display: 'inline-flex',minWidth:'64px',justifyContent: 'space-between',marginRight:'4px'}}>
                                <span>[</span>
                                <span>{option.port_code.toUpperCase()}</span>
                                <span>]</span>
                            </div>
                            <span style={{display: 'inline-block',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'}}>{option.port_name.toUpperCase()}</span>
                        </span>
                    </span>
                </div>
                </>
            )}}
        /> 
    </div>
    </>)
}