/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect,useState } from 'react';
import { Row, Col, FormGroup,Label,Button} from "reactstrap";
import InputValid from "components/CustomInput/InputValid.js";

export default function Consignee(props) {
	const {loadFormData} = props;	
	const [consigneeData,setConsigneeData] = useState({});

	useEffect(() => {
		setConsigneeData(loadFormData);
	},[loadFormData]);

	const onHandleReturnVal = (event,name) => {
		let list = {...consigneeData, [name]:(event.target.value.toUpperCase())||null};
		setConsigneeData(list);
	}
	
	const onPropsReturn = ()=> {
		props.propsData(consigneeData);
	}

	const onCopyProps=()=>{
		var list = {
			...consigneeData,
			c_cons_name1:loadFormData.cons_name1,
			c_cons_name2:loadFormData.cons_name2,
			c_cons_address1:loadFormData.cons_address1,
			c_cons_address2:loadFormData.cons_address2,
			c_cons_address3:loadFormData.cons_address3,
			c_cons_address4:loadFormData.cons_address4,
			c_cons_address5:loadFormData.cons_address5
		}
		setConsigneeData(list);
		props.propsData(list);
		props.onAlert("success","Consignee 데이터가 복사 되었습니다.");
	}
	
  	return (
    	<>
			{!props.type?
			<Row>
				<Col className="col-12 text-right pr-0 mr-1">
					<Button className="btn-link pr-0 pt-0 pb-0" color="default" type="button" size="sm" onClick={()=>onCopyProps()}>
						Copy Consignee
					</Button>
				</Col>
			</Row>:
			<Row>
				<Col xl="6" lg="6" md="12">
					<FormGroup className="mb-2">
						<Label className="mb-0">BookMark Name</Label>
						<InputValid 
							type="text"
							name="c_consignee_bookmark_name"
							id="c_consignee_bookmark_name"
							maxLength="35"
							value={consigneeData.c_consignee_bookmark_name?consigneeData.c_consignee_bookmark_name:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_consignee_bookmark_name')}
							onBlur={onPropsReturn}
							validtype="text"
							required={props.type?true:false}
							feedid="consignee2"
						/>
					</FormGroup>
				</Col>
			</Row>}
    		
		    <Row>
			    <Col xl="5" lg="5" md="12">
			        <FormGroup>
			            <Label className="mb-0">상호1</Label>
						<InputValid 
							type="text"
							name="c_cons_name1"
							id="c_cons_name1"
							maxLength="35"
							value={consigneeData.c_cons_name1?consigneeData.c_cons_name1:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_name1')}
							onBlur={onPropsReturn}
							validtype="koreanban" 
							required={!props.type?true:false}
							feedid="consignee2"
						/>
			        </FormGroup>
			    </Col>
			    <Col xl="5" lg="5" md="12">
			        <FormGroup>
			            <Label className="mb-0">상호2</Label>
						<InputValid 
							type="text"
							name="c_cons_name2"
							id="c_cons_name2"
							maxLength="35"
							value={consigneeData.c_cons_name2?consigneeData.c_cons_name2:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_name2')}
							onBlur={onPropsReturn}
							validtype="koreanban" 
							required={false}
				            feedid="consignee2"
						/>
			        </FormGroup>
			    </Col> 
			    <Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0">담당자이름</Label>
						<InputValid 
							type="text"
							name="c_cons_user_name"
							id="c_cons_user_name"
							maxLength="17"
							value={consigneeData.c_cons_user_name?consigneeData.c_cons_user_name:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_user_name')}
							onBlur={onPropsReturn}
							validtype="koreanban" 
							required={!props.type?true:false}
							feedid="consignee2"
						/>
					</FormGroup>
				</Col>       
			</Row>
			<Row>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">주소1</Label>
						<InputValid 
							type="text"
							name="c_cons_address1"
							id="c_cons_address1"
							maxLength="35"
							value={consigneeData.c_cons_address1?consigneeData.c_cons_address1:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_address1')}
							onBlur={onPropsReturn}
							validtype="koreanban" 
							required={!props.type?true:false}
							feedid="consignee2"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">주소2</Label>
						<InputValid 
							type="text"
							name="c_cons_address2"
							id="c_cons_address2"
							maxLength="35"
							value={consigneeData.c_cons_address2?consigneeData.c_cons_address2:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_address2')}
							onBlur={onPropsReturn}
							validtype="koreanban" 
							required={false}
							feedid="consignee2"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">주소3</Label>
						<InputValid 
							type="text"
							name="c_cons_address3"
							id="c_cons_address3"
							maxLength="35"
							value={consigneeData.c_cons_address3?consigneeData.c_cons_address3:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_address3')}
							onBlur={onPropsReturn}
							validtype="koreanban" 
							required={false}
							feedid="consignee2"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">주소4</Label>
						<InputValid 
							type="text"
							name="c_cons_address4"
							id="c_cons_address4"
							maxLength="35"
							value={consigneeData.c_cons_address4?consigneeData.c_cons_address4:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_address4')}
							onBlur={onPropsReturn}
							validtype="koreanban" 
							required={false}
							feedid="consignee2"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">주소5</Label>
						<InputValid 
							type="text"
							name="c_cons_address5"
							id="c_cons_address5"
							maxLength="35"
							value={consigneeData.c_cons_address5?consigneeData.c_cons_address5:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_address5')}
							onBlur={onPropsReturn}
							validtype="koreanban" 
							required={false}
							feedid="consignee2"
						/>
					</FormGroup>
				</Col>
        	</Row>
			<Row>
			 	<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0">연락처</Label>
						<InputValid 
							type="text"
							name="c_cons_user_tel"
							id="c_cons_user_tel"
							maxLength="35"
							value={consigneeData.c_cons_user_tel?consigneeData.c_cons_user_tel:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_user_tel')}
							onBlur={onPropsReturn}
							validtype="tel" 
							required={!props.type?true:false}
							feedid="consignee2"
						/>
					</FormGroup>
				</Col> 
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0">사업자코드</Label>
						<InputValid 
							type="text"
							name="c_cons_code"
							id="c_cons_code"
							maxLength="18"
							value={consigneeData.c_cons_code?consigneeData.c_cons_code:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_code')}
							onBlur={onPropsReturn}
							validtype="koreanban" 
							required={!props.type?true:false}
							feedid="consignee2"
						/>
					</FormGroup>
				</Col>
				<Col xl="12" lg="12" md="12">
					<FormGroup>
						<Label className="mb-0">국가코드</Label>
						<InputValid 
							type="text"
							name="c_cons_country_code"
							id="c_cons_country_code"
							maxLength="2"
							value={consigneeData.c_cons_country_code?consigneeData.c_cons_country_code:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_cons_country_code')}
							onBlur={onPropsReturn}
							validtype="koreanban" 
							required={!props.type?true:false}
							feedid="consignee2"
						/>
					</FormGroup>
				</Col>
			</Row>
        </>
    );
}