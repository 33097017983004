import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
//import useScrollTrigger from '@material-ui/core/useScrollTrigger';
//import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import HighlightOff from "@material-ui/icons/HighlightOff";
import { Table,Button } from "reactstrap";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children } = props;
  //console.log("props:",props);
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  /*  const trigger = useScrollTrigger({
    //target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });*/

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={true}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.root}
        style={{ position: "absolute" }}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function PersonalInfoPPolicy(props) {
  const [version,setVersion] =  useState('now'); 
  const topRef=useRef(null);
  const goTop =() => {
    topRef.current.scrollIntoView({behavior:'auto'});
  }
  const changePage =(when) => {
    goTop();
    setVersion(when);
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar style={{ backgroundColor: "#26c6da", position: "absolute" }}>
        <Toolbar style={{ paddingRight: "0" }}>
          <Typography variant="h6" style={{ flexGrow: "1" }}>
            (주)케이엘넷 개인정보처리방침
          </Typography>
          <HighlightOff onClick={() => props.handleClose()} />
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <div ref={topRef} >
      {version==='now'? 
        <Container >
          ㈜케이엘넷(이하 “회사”라 함)은 이용자의 권익 보호를 위해 「개인정보보호법」 및 관계 법령이 정한 바를 준수하며, 「개인정보보호법」 제 30조에 따라 개인정보 처리방침을 수립하여 공개합니다.
          <br />
          <br />
          회사는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하는 경우 반드시 사전에 이용자에게 해당 내용을 알리고 동의절차를 거치며, 이용자가 동의하지 않을 경우에는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하지 않습니다. 단, 동의를 거부하는 경우 서비스의 전부 또는 일부 이용이 제한될 수 있습니다.
          <br />
          <br />
          회사는 개인정보 처리방침을 통해 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 적극적으로 보호하기 위해 항상 노력하고 있음을 알려드리고자 합니다.
          <br />
          <br /> <br />
          <br />
          <b>제 1 조 (처리하는 개인정보의 항목)</b><br/>
          ① 회사는 다음의 개인정보 항목을 수집하여 처리하고 있습니다.
          <br />
          <br />
          1. 기업회원(모든 서비스) 가입
          <br />
          가. 필수항목 : 상호, 이름(대표자, 담당자), 사업장주소, 담당부서, 직위, 전화번호, 팩스번호, 이메일
          <br />
          나. 선택항목 : 없음
          <br />
          <br />
          2. AFR 서비스 신청
          <br />
          가. 필수항목 : 상호, 이름(대표자, 담당자), 사업장주소, 사업자등록번호
          <br />
          나. 선택항목 : 이름(담당자), 사업장주소, 담당부서, 직위, 전화번호, 팩스번호, 이메일
          <br />
          <br />
          3. 해상적하목록 취합시스템 이용 신청
          <br />
          가. 필수항목 : 상호, 이름(대표자, 담당자), 사업장주소, 사업자등록번호, 담당부서, 직위, 전화번호, 팩스번호, 이메일
          <br />
          나. 선택항목 : 없음
          <br />
          <br />
          4. 개인 회원가입
          <br />
          가. 필수항목 : 이름, 아이디(ID), 비밀번호, 이메일
          <br />
          나. 선택항목 : 상호, 영문이름, 전화번호, 팩스번호
          <br />
          <br /> <br />
          ② 또한, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
          <br />
          <br />
          1. PC웹, 모바일 웹/앱 이용과정에서 단말기정보(OS, 모델, 디바이스 아이디), 이용자의 브라우저 종류, 검색어, IP Address, 쿠키
          <br />
          <br />
          2. 방문 일시, 서비스이용기록, 불량이용기록, 결제기록
          <br />
          <br />
          ③ 회사는 다음과 같은 방법으로 개인정보를 수집합니다.
          <br />
          <br />
          1. 서비스 회원가입, 서면양식, 팩스, 전화, 상담게시판, 이메일, 고객센터 문의하기
          <br />
          <br />
          2. 협력회사 또는 제휴사로부터의 제공
          <br />
          <br />
          3. 생성정보 수집 툴을 통한 수집
          <br />
          <br />
          <br /> 
          <br />
          <b>제 2 조 (개인정보의 처리목적)</b> 회사는 수집한 개인정보를
          다음의 목적을 위해 활용합니다. 이용자가 제공한 모든 정보는 하기 목적에
          필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전
          동의를 구할 것입니다.
          <br />
          <br />
          1. 회원 관리
          <br /> &nbsp;회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의
          부정 이용방지와 비인가 사용방지, 가입의사 확인, 분쟁조정을 위한
          기록보존, 불만처리 등 민원처리, 고지사항 전달
          <br />
          <br />
          2. 서비스 제공에 관한 계약이행 및 서비스 제공에 따른 요금정산, 특정
          맞춤서비스 제공, 청구서 등 발송, 본인인증, 요금결제 및 요금추심
          <br />
          3. 서비스 이용 고객간의 전자적인 업무 처리 지원
          <br />
          <br /> <br />
          <br />
          <b>제 3 조 (개인정보의 제3자 제공)</b> 회사는 이용자들의 개인정보를
          상기 제2조 “개인정보의 수집 및 이용목적"에서 고지한 범위 내에서
          사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나
          원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의
          경우에는 예외로 합니다.
          <br />
          <br />
          1. 이용자들이 사전에 제공 및 공개에 동의한 경우
          <br />
          <br />
          2. 서비스 이용자가 전자적인 업무 처리를 위해 상대업체에게 정보를
          제공하는 경우
          <br />
          <br />
          3. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
          <br />
          <br />
          4. 정보 주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나
          주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는
          제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
          <br />
          <br />
          <br /><br />
          <b>제 4조 (개인정보의 처리위탁)</b> 회사는 서비스 제공에 관한 계약의
          이행을 위해 아래와 같이 외부 전문업체에 위탁하여 운영하고 있으며,
          위탁업무에 필요한 범위 내에서 정보를 제공하고 있습니다. 또한 위탁계약 시
          개인정보보호 관련 지시 엄수, 개인정보에 관한 금지 및 사고시의 책임부담
          등을 명확히 규정하고, 수탁업체가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
          <br /><br />
          {/* <table class="w2group w2tb tb_fb mb20" id="wq_uuid_66" style="width: 95%; margin-left: 15px;"> */}
          <Table className="mb-0" size="sm" style={{ listStyle: "none" }}>
            <tbody>
              <tr>
                <th style={{ height: "27px" }}>수탁업체</th>
                <th>&nbsp;위탁업무</th>
                <th>개인정보의&nbsp;보유&nbsp;및&nbsp;이용기간</th>
              </tr>
              <tr>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li className="w2textbox " id="wq_uuid_77">
                    (주)에스티아이
                  </li>
                </td>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li>서비스운영 및 고객상담</li>
                </td>
                <td rowSpan="2" colSpan="1">
                  <li>회원탈퇴시 혹은 위탁계약 종료시까지</li>
                </td>
              </tr>
              <tr>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li className="w2textbox " id="wq_uuid_84">
                    빌포스트
                  </li>
                </td>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li className="w2textbox " id="wq_uuid_86">
                    물품배송 또는 청구서 등 발송, 고지사항 전달
                  </li>
                </td>
              </tr>
              <tr>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li>
                    코리아크레딧뷰로(주)
                    {/* <!--NICE신용평가정보(주)<br>코리아크레딧뷰로(주)--> */}
                  </li>
                </td>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li>
                    본인확인
                    {/* <!--실명인증--> */}
                  </li>
                </td>
                <td
                  // style={{
                  //   borderBottomColor: 'rgb(174, 174, 174)',
                  //   borderBottomWidth: '1px',
                  //   borderBottomStyle: 'solid',
                  // }}
                  style={{ borderBottom: " 1px solid #ccc" }}
                  colSpan="1"
                >
                  <li>
                    본인확인 기관인 이들 기관에서 이미 보유한 개인정보이므로
                    별도로 저장하지 않음
                  </li>
                </td>
              </tr>
            </tbody>
          </Table>
          <br />
          <br />
          <br /><br />
            <b>제 5 조 (개인정보의 처리 및 보유 기간)</b> <br />① 회사는 회원가입일로부터
            서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및
            이용하게 됩니다. 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한
            동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이
            종료한 경우 해당 개인정보를 지체 없이 파기합니다.
            <br />
            <br />② 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의
            규정에 의하여 보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한
            기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그
            보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
            <br />
            <br /><br /><br />
            1. 계약 또는 청약철회 등에 관한 기록
            <br />- 보존 근거 : 「전자상거래 등에서의 소비자보호에 관한 법률」 제6조 및 시행령 제6조
            <br />- 보존 기간 : 5년
            <br />
            <br />
            2. 대금결제 및 재화 등의 공급에 관한 기록
            <br />- 보존 근거 : 「전자상거래 등에서의 소비자보호에 관한 법률」 제6조 및 시행령 제6조
            <br />- 보존 기간 : 5년
            <br />
            <br />
            3. 소비자의 불만 또는 분쟁처리에 관한 기록
            <br />- 보존 근거 : 「전자상거래 등에서의 소비자보호에 관한 법률」 제6조 및 시행령 제6조
            <br />- 보존 기간 : 3년
            <br />
            <br />
            4. 서비스 이용 관련 개인정보(서비스 이용기록, 접속로그, 접속 IP 정보)
            <br />- 보존 근거: 「통신비밀보호법」 제15조의2 및 시행령 제41조
            <br />- 보존 기간: 3개월
            <br />
            <br /> <br />
            <br />
            <b>제 6 조 (개인정보의 파기절차 및 방법)</b>
            <br /> ① 회사는 개인정보의 보유 및 이용 기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 경우 지체없이 해당 개인정보를 파기합니다.
            <br />
            <br />
            ② 개인정보의 이용 목적이 달성되거나 그 보유 및 이용 기간이 경과되었음에도 불구하고 다른 법령에 의한 정보보호 사유에 따라
            보존해야하는 경우, 해당 개인정보는 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 일정 기간 저장 후 파기 됩니다. 
            동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
            <br />
            <br />
            ③ 1년간 서비스를 이용하지 않은 이용자의 정보는 휴면계정으로 전환되며, 별도의 DB로 옮겨져 24개월 저장 후 파기 됩니다.
            <br />
            <br />
            ④ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
            <br />
            <br />
            1. 파기절차
            <br />- 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보보호책임자의 승인을 받아 개인정보를 파기합니다.
            <br />
            <br />
            2. 파기방법
            <br />- 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없으도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
            <br /> <br />
            <br />
            <br />
            <b>제 7 조 (이용자 및 법정대리인의 권리와 그 행사방법)</b>
            <br /> ① 이용자 및
            법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나
            수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를
            거부하거나 가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한
            경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.
            <br />
            <br />② 이용자의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는
            '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를
            클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가
            가능합니다. 또는, 개인정보보호책임자에게 서면, 전화 또는 이메일로
            연락하시면 지체 없이 조치하겠습니다.
            <br />
            <br />③ 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는
            정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
            또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
            제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
            <br />
            <br />④ 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
            개인정보는 제5조 "개인정보의 보유 및 이용기간"에 명시된 바에 따라
            처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
            <br />
            <br />⑤ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
            대리인을 통해서도 하실 수 있으며, 이 경우에는 법에서 정한 위임장을
            제출하셔야 합니다.
            <br />
            <br />⑥ 회사는 만 14세 미만의 아동에 대한 개인정보를 수집하지
            않습니다.
            <br />
            <br /><br /><br />
            <b>
              제 8 조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)
            </b>{" "}
            회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
            저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는
            웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는
            아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.
            회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
            <br />
            <br />
            1. 쿠키의 사용목적
            <br />
            이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 방문 및
            이용형태, 보안접속 여부, 이용자 규모 등을 파악하여 이용자에게 최적화된
            정보 제공을 위하여 사용합니다.
            <br />
            <br />
            이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는
            웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
            저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
            있습니다.
            <br /> <br />
            2. 쿠키의 거부방법
            <br />이 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹
            브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할
            때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단,
            쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 이용에
            어려움이 있을 수 있습니다.
            <br />
            <br />▶ 설정방법<br/>
            <br/>인터넷 익스플로어 : 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 쿠키의 차단/허용
            <br/>- 웹 브라우저에서 쿠키 허용/차단				
            <br/>  . 크롬(Chrome) : 웹 브라우저 설정 &gt; 개인정보 보호 및 보안 &gt; 인터넷 사용 기록 삭제				
            <br/>  . 엣지(Edge) : 웹 브라우저 설정 &gt; 쿠키 및 사이트 권한 &gt; 쿠키 및 사이트 데이터 관리/삭제				
            <br/>- 모바일 브라우저에서 쿠키 허용/차단				
            <br/>  . 크롬(Chrome) : 모바일 브라우저 설정 &gt; 개인정보 보호 및 보안 &gt; 인터넷 사용 기록 삭제				
            <br/>  . 사파리(Safari) : 모바일 기기 설정 &gt; 사파리(Safari) &gt; 고급 &gt; 모든 쿠키 차단				
            <br/>  . 삼성 인터넷 : 모바일 브라우저 설정 &gt; 인터넷 사용 기록 &gt; 인터넷 사용 기록 삭제				
            <br />
            <br /> <br />
            <br />
            <b>제 9 조 개인정보의 국외 수집 및 이전에 관한 사항</b>
            <br />회사는 개인정보를 국외로부터 수집하거나 처리하는 개인정보를 국외로 이전하지 않습니다.
            <br />
            <br /> <br />
            <br />
            <b>제 10 조 (개인정보의 안전성 확보 조치에 관한 사항)</b> 
            <br/>회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
            <br />
            <br />
            ① 관리적 조치
            <br />
            '내부관리계획의 수립 및 시행, 개인정보취급자에 대한 정기적인 교육 실시, ISMS 인증취득 등
            <br />
            <br />
            ② 기술적 조치
            <br />
            개인정보처리시스템 등의 접근권한 관리 및 통제, 접근통제시스템 설치 및 운영, 주요 개인정보 암호화, 단말기 보안프로그램 설치 등
            <br />
            <br />
            ② 물리적 조치
            <br />
            전산실, 자료보관실 등의 접근통제
            <br />
            <br /> <br />
            <br />
            <b>제 11 조 (개인정보 보호책임자 및 민원처리 담당부서에 관한 사항)</b> 
            <br/>① 이용자는
            회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
            개인정보 관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는
            이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
            <br /><br />▶ 개인정보보호책임자
            <br />
            성명 : 이명학
            <br />
            직책 : eBiz사업총괄본부 본부장
            <br />
            연락처 : 1577-1172, privacy@klnet.co.kr
            <br /><br />
            ▶ 개인정보보호 담당부서
            <br />부서명 : eBiz 사업팀
            <br />연락처 : 1577-1172, privacy@klnet.co.kr
            <br /><br /> 
            <br /><br />
            <b>제 12 조 (정보주체의 권익침해에 대한 구제 방법)</b> 
            <br /><br />
            ① 정보주체는+ 개인정보침해로 인한 구제를 받기 위하여 개인정보 침해신고센터, 개인정보 분쟁조정위원회 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
            <br />
            <br />▶ 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)				
            <br />▶ 개인정보 분쟁조정 : (국번없이) 1833-6972 (www.kopico.go.kr) 				
            <br />▶ 대검찰청 : (국번없이) 1301 (www.spo.go.kr)				
            <br />▶ 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr) 	
            <br /><br /> 			
            ② 회사는 정보주체의 개인정보자기결정권을 보장하고, 개인정보침해로 인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한 경우 아래의 담당부서로 연락해 주시기 바랍니다.				
            <br />
            <br /> ▶ 개인정보보호 담당부서				
            <br /> 부서명 : eBiz 사업팀				
            <br /> 연락처 : 1577-1172, privacy@klnet.co.kr				
            <br /><br />
            <br /><br />
            
            <b>제 13 조 (기타)</b> 회사에 링크되어 있는 웹사이트들이 개인정보를
            수집하는 행위에 대해서는 본 "㈜케이엘넷 개인정보처리방침"이 적용되지
            않음을 알려드립니다.
            <br />
            <br /> <br />
            <br />
            <b>제 14 조 (개인정보 처리방침의 변경에 관한 사항)</b> 
            <br/>현 개인정보처리방침 내용 추가, 삭제 및
            수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해
            고지할 것입니다.
            <br />
            <br /> <br />
            <br />▶ 공고일자 : 2024년 12월 12일
            <br />
            <br />▶ 시행일자 : 2024년 12월 19일 부터 시행됩니다.
            <br />
            <br />
            <br />

            <Button onClick={() => {changePage('~241219')}}> ▶ 이전 개인정보처리 방침 보기 (2023.03.16 ~ 2024.12.12)</Button>
        </Container>
        : version =='~241219'?
        <Container >
          ㈜케이엘넷(이하 “회사”라 함)은 이용자의 권익 보호를 위해 「개인정보보호법」 및 관계 법령이 정한 바를 준수하며, 「개인정보보호법」 제 30조에 따라 개인정보 처리방침을 수립하여 공개합니다.
          <br />
          <br /> <br />
          <br />
          회사는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하는 경우 반드시 사전에 이용자에게 해당 내용을 알리고 동의절차를 거치며, 이용자가 동의하지 않을 경우에는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하지 않습니다. 단, 동의를 거부하는 경우 서비스의 전부 또는 일부 이용이 제한될 수 있습니다.
          <br />
          <br /> <br />
          <br />
          회사는 개인정보 처리방침을 통해 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 적극적으로 보호하기 위해 항상 노력하고 있음을 알려드리고자 합니다.
          <br />
          <br /> <br />
          <br />
          <b>제 1 조 (수집하는 개인정보의 항목 및 수집방법)</b><br/>
          ① 회사는 다음의 개인정보 항목을 수집하여 처리하고 있습니다.
          <br />
          <br />
          1. 기업회원(모든 서비스) 가입
          <br />
          <br />
          가. 필수항목 : 상호, 이름(대표자, 담당자), 사업장주소, 담당부서, 직위, 전화번호, 팩스번호, 이메일
          <br />
          <br />
          나. 선택항목 : 없음
          <br />
          <br />
          <br />
          <br />
          2. AFR 서비스 신청
          <br />
          <br />
          가. 필수항목 : 상호, 이름(대표자, 담당자), 사업장주소, 사업자등록번호
          <br />
          <br />
          나. 선택항목 : 이름(담당자), 사업장주소, 사업자등록번호, 담당부서, 직위, 전화번호, 팩스번호, 이메일
          <br />
          <br />
          3. 해상적하목록 취합시스템 이용 신청
          <br />
          <br />
          가. 필수항목 : 상호, 이름(대표자, 담당자), 사업장주소, 사업자등록번호, 담당부서, 직위, 전화번호, 팩스번호, 이메일
          <br />
          <br />
          나. 선택항목 : 없음
          <br />
          <br />
          4. 개인 회원가입
          <br />
          <br />
          가. 필수항목 : 이름, 아이디(ID), 비밀번호, 이메일
          <br />
          <br />
          나. 선택항목 : 상호, 영문이름, 전화번호, 팩스번호
          <br />
          <br />
          ② 또한, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
          <br />
          <br />
          1. PC웹, 모바일 웹/앱 이용과정에서 단말기정보(OS, 모델, 디바이스 아이디), 이용자의 브라우저 종류, 검색어, IP Address, 쿠키
          <br />
          <br />
          2. 방문 일시, 서비스이용기록, 불량이용기록, 결제기록
          <br />
          <br />
          ③ 회사는 다음과 같은 방법으로 개인정보를 수집합니다.
          <br />
          <br />
          1. 서비스 회원가입, 서면양식, 팩스, 전화, 상담게시판, 이메일, 고객센터 문의하기
          <br />
          <br />
          2. 협력회사 또는 제휴사로부터의 제공
          <br />
          <br />
          3. 생성정보 수집 툴을 통한 수집
          <br />
          <br />
          <br />
          <b>제 2 조 (개인정보의 수집 및 이용목적)</b> 회사는 수집한 개인정보를
          다음의 목적을 위해 활용합니다. 이용자가 제공한 모든 정보는 하기 목적에
          필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전
          동의를 구할 것입니다.
          <br />
          <br />
          1. 회원 관리
          <br />
          <br /> &nbsp;회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의
          부정 이용방지와 비인가 사용방지, 가입의사 확인, 분쟁조정을 위한
          기록보존, 불만처리 등 민원처리, 고지사항 전달
          <br />
          <br />
          2. 서비스 제공에 관한 계약이행 및 서비스 제공에 따른 요금정산, 특정
          맞춤서비스 제공, 청구서 등 발송, 본인인증, 요금결제 및 요금추심
          <br />
          <br />
          3. 서비스 이용 고객간의 전자적인 업무 처리 지원
          <br />
          <br /> <br />
          <br />
          <b>제 3 조 (개인정보의 공유 및 제공)</b> 회사는 이용자들의 개인정보를
          상기 제2조 “개인정보의 수집 및 이용목적"에서 고지한 범위 내에서
          사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나
          원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의
          경우에는 예외로 합니다.
          <br />
          <br />
          1. 이용자들이 사전에 제공 및 공개에 동의한 경우
          <br />
          <br />
          2. 서비스 이용자가 전자적인 업무 처리를 위해 상대업체에게 정보를
          제공하는 경우
          <br />
          <br />
          3. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
          <br />
          <br />
          4. 정보 주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나
          주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는
          제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
          <br />
          <br />
          <br />
          <b>제 4조 (개인정보의 처리위탁)</b> 회사는 서비스 제공에 관한 계약의
          이행을 위해 아래와 같이 외부 전문업체에 위탁하여 운영하고 있으며,
          위탁업무에 필요한 범위 내에서 정보를 제공하고 있습니다. 또한 위탁계약 시
          개인정보보호 관련 지시 엄수, 개인정보에 관한 금지 및 사고시의 책임부담
          등을 명확히 규정하고, 수탁업체가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
          <br />
          {/* <table class="w2group w2tb tb_fb mb20" id="wq_uuid_66" style="width: 95%; margin-left: 15px;"> */}
          <Table className="mb-0" size="sm" style={{ listStyle: "none" }}>
            <tbody>
              <tr>
                <th style={{ height: "27px" }}>수탁업체</th>
                <th>&nbsp;위탁업무</th>
                <th>개인정보의&nbsp;보유&nbsp;및&nbsp;이용기간</th>
              </tr>
              <tr>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li className="w2textbox " id="wq_uuid_77">
                    (주)에스티아이
                  </li>
                </td>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li>서비스운영 및 고객상담</li>
                </td>
                <td rowSpan="2" colSpan="1">
                  <li>회원탈퇴시 혹은 위탁계약 종료시까지</li>
                </td>
              </tr>
              <tr>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li className="w2textbox " id="wq_uuid_84">
                    빌포스트
                  </li>
                </td>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li className="w2textbox " id="wq_uuid_86">
                    물품배송 또는 청구서 등 발송, 고지사항 전달
                  </li>
                </td>
              </tr>
              <tr>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li>
                    코리아크레딧뷰로(주)
                    {/* <!--NICE신용평가정보(주)<br>코리아크레딧뷰로(주)--> */}
                  </li>
                </td>
                <td style={{ borderRight: "1px solid #d9d9d9" }}>
                  <li>
                    본인확인
                    {/* <!--실명인증--> */}
                  </li>
                </td>
                <td
                  // style={{
                  //   borderBottomColor: 'rgb(174, 174, 174)',
                  //   borderBottomWidth: '1px',
                  //   borderBottomStyle: 'solid',
                  // }}
                  style={{ borderBottom: " 1px solid #ccc" }}
                  colSpan="1"
                >
                  <li>
                    본인확인 기관인 이들 기관에서 이미 보유한 개인정보이므로
                    별도로 저장하지 않음
                  </li>
                </td>
              </tr>
            </tbody>
          </Table>
            <b>제 5 조 (개인정보의 보유 및 이용기간)</b> ① 회사는 회원가입일로부터
            서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및
            이용하게 됩니다. 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한
            동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이
            종료한 경우 해당 개인정보를 지체 없이 파기합니다.
            <br />
            <br />② 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의
            규정에 의하여 보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한
            기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그
            보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
            <br />
            <br />
            1. 계약 또는 청약철회 등에 관한 기록
            <br />
            <br />- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
            <br />
            <br />- 보존 기간 : 5년
            <br />
            <br />
            2. 대금결제 및 재화 등의 공급에 관한 기록
            <br />
            <br />- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
            <br />
            <br />- 보존 기간 : 5년
            <br />
            <br />
            3. 소비자의 불만 또는 분쟁처리에 관한 기록
            <br />
            <br />- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
            <br />
            <br />- 보존 기간 : 3년
            <br />
            <br />
            4. 서비스 이용 관련 개인정보(서비스 이용기록, 접속로그, 접속 IP 정보)
            <br />
            <br />- 보존 근거: 통신비밀보호법
            <br />
            <br />- 보존 기간: 3개월
            <br />
            <br /> <br />
            <br />
            <b>제 6 조 (개인정보의 파기절차 및 방법)</b>
            ① 회사는 개인정보의 보유 및 이용 기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 경우 지체없이 해당 개인정보를 파기합니다.
            <br />
            <br />
            ② 개인정보의 이용 목적이 달성되거나 그 보유 및 이용 기간이 경과되었음에도 불구하고 다른 법령에 의한 정보보호 사유에 따라
            보존해야하는 경우, 해당 개인정보는 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 일정 기간 저장 후 파기 됩니다. 
            동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
            <br />
            <br />
            ③ 1년간 서비스를 이용하지 않은 이용자의 정보는 휴면계정으로 전환되며, 별도의 DB로 옮겨져 24개월 저장 후 파기 됩니다.
            <br />
            <br />
            ④ 파기 방법은 아래와 같습니다.
            <br />
            <br />
            1. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
            <br />
            <br />
            2. 전자적 파일 형태로 저장되는 개인정보는 기록을 복구 및 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
            <br />
            <br /> <br />
            <br />
            <b>제 7 조 (이용자 및 법정대리인의 권리와 그 행사방법)</b> ① 이용자 및
            법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나
            수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를
            거부하거나 가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한
            경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.
            <br />
            <br />② 이용자의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는
            '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를
            클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가
            가능합니다. 또는, 개인정보보호책임자에게 서면, 전화 또는 이메일로
            연락하시면 지체 없이 조치하겠습니다.
            <br />
            <br />③ 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는
            정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
            또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
            제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
            <br />
            <br />④ 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
            개인정보는 제5조 "개인정보의 보유 및 이용기간"에 명시된 바에 따라
            처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
            <br />
            <br />⑤ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
            대리인을 통해서도 하실 수 있으며, 이 경우에는 법에서 정한 위임장을
            제출하셔야 합니다.
            <br />
            <br />⑥ 회사는 만 14세 미만의 아동에 대한 개인정보를 수집하지
            않습니다.
            <br />
            <br />
            <b>
              제 8 조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)
            </b>{" "}
            회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
            저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는
            웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는
            아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.
            회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
            <br />
            <br /> <br />
            <br />
            1. 쿠키의 사용목적
            <br />
            <br />
            이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 방문 및
            이용형태, 보안접속 여부, 이용자 규모 등을 파악하여 이용자에게 최적화된
            정보 제공을 위하여 사용합니다.
            <br />
            <br />
            이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는
            웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
            저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
            있습니다.
            <br />
            <br /> <br />
            <br />
            2. 쿠키의 거부방법
            <br />
            <br />이 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹
            브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할
            때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단,
            쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 이용에
            어려움이 있을 수 있습니다.
            <br />
            <br /> <br />
            <br />▶ 설정방법<br/>
            인터넷 익스플로어 : 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 쿠키의 차단/허용
            <br />
            <br /> <br />
            <br />
            <b>제 9 조 (개인정보의 안전성 확보 조치에 관한 사항)</b> 회사는 이용자들의
            개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는
            훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을
            강구하고 있습니다.
            <br />
            <br /> <br />
            <br />
            ① 기술적 조치
            <br />
            <br />
            1. 개인정보 암호화
            <br />
            <br />
            이용자의 비밀번호, 계좌번호, 여권번호 등 중요 개인정보는 암호화되어 저장 및 관리되고 있습니다.
            <br />
            <br /> <br />
            <br />
            2. 해킹 등에 대비한 기술적 대책
            <br />
            <br />
            회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가
            유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에
            시스템을 설치하고, 침입 차단장치 이용 및 침입탐지시스템을 설치하여
            24시간 감시하고 있습니다. 또한 백신 프로그램의 주기적인 업데이트, 
            정기적인 백업 수행, 암호화 통신 들 기술적으로 보안성을 확보하기 위해 노력하고 있습니다.
            <br />
            <br /> <br />
            <br />
            3. 개인정보처리시스템 접근 제한
            <br />
            <br />
            회사는 개인정보 처리시스템에 대한 접근권한의 부여, 변경, 말소 등에 관한 기준을
            수립하고 비밀번호의 생성 방법, 변경 주기 등을 규정하여
            개인정보에 대한 접근통제를 위해 필요한 조치를 다하고 있습니다.
            <br />
            <br /> <br />
            <br />
            ② 관리적 조치
            <br />
            <br />
            1. 개인정보 처리자 최소화 및 교육 수행
            <br />
            <br />
            개인정보를 처리하는 직원은 담당자에 한정시켜 최소화하고 새로운 보안기술의 습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시합니다.
            <br />
            <br /> 
            2. 개인정보 보호 감사 활동
            <br />
            <br />
            회사는 개인정보 처리방침 및 지침의 이행 사항과 준수 여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.
            <br />
            단, 이용자의 부주의로 인한 개인정보 유출 문제에 대해 회사는 일체의 책임을 지지 않습니다.
            <br />
            <br /> <br />
            <br />
            <b>제 10 조 (개인정보보호책임자 및 담당자의 연락처)</b> ① 이용자는
            회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
            개인정보 관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는
            이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
            <br />
            <br /> <br />
            <br />▶ 개인정보보호책임자
            <br />
            <br />
            성명 : 이명학
            <br />
            <br />
            직책 : 전자물류사업총괄본부 본부장
            <br />
            <br />
            연락처 : 1577-1172, privacy@klnet.co.kr
            <br />
            <br /> <br />
            <br />
            <br />
            <br /> <br />
            <br />② 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
            기관에 문의하시기 바랍니다.
            <br />
            <br /> <br />
            <br />▶ 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이
            118)
            <br />
            <br />▶ 개인정보분쟁조정위원회 (www.kopico.go.kr / 국번없이 1833-6972)
            <br />
            <br />▶ 대검찰청 사이버수사과 (spo.go.kr / 국번없이 1301)
            <br />
            <br />▶ 경찰청 사이버안전국 (ecrm.cyber.go.kr /
            국번없이 182)
            <br />
            <br /> <br />
            <br />
            <b>제 11 조 (기타)</b> 회사에 링크되어 있는 웹사이트들이 개인정보를
            수집하는 행위에 대해서는 본 "㈜케이엘넷 개인정보처리방침"이 적용되지
            않음을 알려드립니다.
            <br />
            <br /> <br />
            <br />
            <b>제 12 조 (고지의 의무)</b> 현 개인정보처리방침 내용 추가, 삭제 및
            수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해
            고지할 것입니다.
            <br />
            <br /> <br />
            <br />▶ 공고일자 : 2023년 10월 24일
            <br />
            <br />▶ 시행일자 : 2023년 10월 30일 부터 시행됩니다.
            <br />
            <br />
            <br />

            <Button onClick={() => {changePage('now')}}> ▶ 개인정보처리방침 보기 (2024.12.12 ~ )</Button>
            {/* <Button onClick={() => {changePage('~231029')}}> ▶이전 개인정보처리 방침 보기 (2023.03.16~2023.10.29)</Button> */}
        </Container>
        : version === '~231029'?
        <Container >
                  ㈜케이엘넷(이하 “회사”라 함)은 이용자의 권익 보호를 위해 「개인정보보호법」 및 관계 법령이 정한 바를 준수하며, 「개인정보보호법」 제 30조에 따라 개인정보 처리방침을 수립하여 공개합니다.
                  <br />
                  <br /> <br />
                  <br />
                  회사는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하는 경우 반드시 사전에 이용자에게 해당 내용을 알리고 동의절차를 거치며, 이용자가 동의하지 않을 경우에는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하지 않습니다. 단, 동의를 거부하는 경우 서비스의 전부 또는 일부 이용이 제한될 수 있습니다.
                  <br />
                  <br /> <br />
                  <br />
                  회사는 개인정보 처리방침을 통해 이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 적극적으로 보호하기 위해 항상 노력하고 있음을 알려드리고자 합니다.
                  <br />
                  <br /> <br />
                  <br />
                  <b>제 1 조 (수집하는 개인정보의 항목 및 수집방법)</b> ① 회사는 회원가입,
                  원활한 고객상담, 각종 서비스 등 기본적인 서비스 제공을 위한 회원 가입시
                  아래와 같은 개인정보를 수집하고 있습니다.
                  <br />
                  <br />
                  1. 수집항목
                  <br />
                  <br />
                  가. 필수항목 :{" "}
                  <b>
                    이름, 아이디(ID), 비밀번호, 전화번호, 이메일, 회사명, 사업자등록번호,
                    대표자명, 본인확인(생년월일, 성별, 휴대폰 번호, 본인확인값(CI, DI),
                    이동통신사, 아이핀 정보, 아이핀 인증 수탁업체(아이핀 확인 시),
                    내/외국인 여부 *별도 저장하지 않음), 주소
                    <br />
                    <br />* 단, Mobile 단말기 고유식별번호는 PLISM3.0 모바일 앱 설치
                    시에만 해당 됨.
                  </b>
                  <br />
                  <br />
                  나. 선택항목 : 영문이름
                  <br />
                  <br />
                  <br />
                  <br />
                  2. 개인정보의 보유 및 이용기간
                  <br />
                  <br />
                  가. 회사는 회원가입일로부터 서비스를 제공하는기간 동안에 한하여 이용자의
                  개인정보를 보유 및 이용
                  <br />
                  <br />
                  나. 회원 탈퇴, 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우 또는
                  수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당
                  개인정보를 지체 없이 파기
                  <br />
                  <br />
                  다. 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의
                  규정에 의하여 보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한
                  기간 동안 회원정보 보관
                  <br />
                  <br />
                  <br />
                  서비스 제공을 위해 필요한 최소한의 개인정보이므로 동의를 해 주셔야
                  서비스 이용이 가능합니다.
                  <br />
                  <br />더 자세한 내용에 대해서는 개인정보처리방침을 참고하시기 바랍니다.
                  <br />
                  <br />
                  <br />
                  <b>제 2 조 (개인정보의 수집 및 이용목적)</b> 회사는 수집한 개인정보를
                  다음의 목적을 위해 활용합니다. 이용자가 제공한 모든 정보는 하기 목적에
                  필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전
                  동의를 구할 것입니다.
                  <br />
                  <br />
                  1. 회원 관리
                  <br />
                  <br /> &nbsp;회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의
                  부정 이용방지와 비인가 사용방지, 가입의사 확인, 분쟁조정을 위한
                  기록보존, 불만처리 등 민원처리, 고지사항 전달
                  <br />
                  <br />
                  2. 서비스 제공에 관한 계약이행 및 서비스 제공에 따른 요금정산, 특정
                  맞춤서비스 제공, 청구서 등 발송, 본인인증, 요금결제 및 요금추심
                  <br />
                  <br />
                  3. 서비스 이용 고객간의 전자적인 업무 처리 지원
                  <br />
                  <br />
                  4. 신규 서비스 개발 및 마케팅·광고에 활용
                  <br /> &nbsp;
                  <br /> &nbsp;신규 서비스(제품) 개발, 통계학적 특성에 따른 서비스 제공,
                  특화, 이벤트 등 광고성 정보전달 및 참여기회 제공, 서비스의 유효성 확인,
                  접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
                  <br />
                  <br /> <br />
                  <br />
                  <b>제 3 조 (개인정보의 공유 및 제공)</b> 회사는 이용자들의 개인정보를
                  상기 제2조 “개인정보의 수집 및 이용목적"에서 고지한 범위 내에서
                  사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나
                  원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의
                  경우에는 예외로 합니다.
                  <br />
                  <br />
                  1. 이용자들이 사전에 공개에 동의한 경우
                  <br />
                  <br />
                  2. 서비스 이용자가 전자적인 업무 처리를 위해 상대업체에게 정보를
                  제공하는 경우
                  <br />
                  <br />
                  3. 정보 주체 또는 법정대리인이 의사표시를 할 수 없는 상태에 있거나
                  주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는
                  제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
                  <br />
                  <br />
                  <br />
                  <b>제 4조 (개인정보의 처리위탁)</b> 회사는 서비스 제공에 관한 계약의
                  이행을 위해 아래와 같이 외부 전문업체에 위탁하여 운영하고 있으며,
                  위탁업무에 필요한 범위 내에서 정보를 제공하고 있습니다. 또한 위탁계약 시
                  개인정보보호 관련 지시 엄수, 개인정보에 관한 금지 및 사고시의 책임부담
                  등을 명확히 규정하고 있습니다.
                  <br />
                  {/* <table class="w2group w2tb tb_fb mb20" id="wq_uuid_66" style="width: 95%; margin-left: 15px;"> */}
                  <Table className="mb-0" size="sm" style={{ listStyle: "none" }}>
                    <tbody>
                      <tr>
                        <th style={{ height: "27px" }}>수탁업체</th>
                        <th>&nbsp;위탁업무</th>
                        <th>개인정보의&nbsp;보유&nbsp;및&nbsp;이용기간</th>
                      </tr>
                      <tr>
                        <td style={{ borderRight: "1px solid #d9d9d9" }}>
                          <li className="w2textbox " id="wq_uuid_77">
                            (주)에스티아이
                          </li>
                        </td>
                        <td style={{ borderRight: "1px solid #d9d9d9" }}>
                          <li>서비스운영 및 고객상담</li>
                        </td>
                        <td rowSpan="2" colSpan="1">
                          <li>회원탈퇴시 혹은 위탁계약 종료시까지</li>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderRight: "1px solid #d9d9d9" }}>
                          <li className="w2textbox " id="wq_uuid_84">
                            빌포스트
                          </li>
                        </td>
                        <td style={{ borderRight: "1px solid #d9d9d9" }}>
                          <li className="w2textbox " id="wq_uuid_86">
                            물품배송 또는 청구서 등 발송, 고지사항 전달
                          </li>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ borderRight: "1px solid #d9d9d9" }}>
                          <li>
                            코리아크레딧뷰로(주)
                            {/* <!--NICE신용평가정보(주)<br>코리아크레딧뷰로(주)--> */}
                          </li>
                        </td>
                        <td style={{ borderRight: "1px solid #d9d9d9" }}>
                          <li>
                            본인확인
                            {/* <!--실명인증--> */}
                          </li>
                        </td>
                        <td
                          // style={{
                          //   borderBottomColor: 'rgb(174, 174, 174)',
                          //   borderBottomWidth: '1px',
                          //   borderBottomStyle: 'solid',
                          // }}
                          style={{ borderBottom: " 1px solid #ccc" }}
                          rowSpan="2"
                          colSpan="1"
                        >
                          <li>
                            본인확인 기관인 이들 기관에서 이미 보유한 개인정보이므로
                            별도로 저장하지 않음
                          </li>
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            borderRight: "1px solid #d9d9d9",
                            borderBottom: " 1px solid #ccc",
                          }}
                        >
                          <li>가입한 이동통신사</li>
                        </td>
                        <td
                          style={{
                            borderRight: "1px solid #d9d9d9",
                            borderBottom: " 1px solid #ccc",
                          }}
                        >
                          <li>본인확인</li>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                    <b>제 5 조 (개인정보의 보유 및 이용기간)</b> ① 회사는 회원가입일로부터
                    서비스를 제공하는 기간 동안에 한하여 이용자의 개인정보를 보유 및
                    이용하게 됩니다. 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한
                    동의를 철회하는 경우, 수집 및 이용목적이 달성되거나 보유 및 이용기간이
                    종료한 경우 해당 개인정보를 지체 없이 파기합니다.
                    <br />
                    <br />② 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의
                    규정에 의하여 보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한
                    기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그
                    보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.
                    <br />
                    <br />
                    1. 계약 또는 청약철회 등에 관한 기록
                    <br />
                    <br />- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
                    <br />
                    <br />- 보존 기간 : 5년
                    <br />
                    <br />
                    2. 대금결제 및 재화 등의 공급에 관한 기록
                    <br />
                    <br />- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
                    <br />
                    <br />- 보존 기간 : 5년
                    <br />
                    <br />
                    3. 소비자의 불만 또는 분쟁처리에 관한 기록
                    <br />
                    <br />- 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
                    <br />
                    <br />- 보존 기간 : 3년
                    <br />
                    <br />
                    4. 본인확인에 관한 기록 : 6개월
                    <br />
                    <br />- 보존 근거 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
                    <br />
                    <br />- 보존 기간 : 6개월
                    <br />
                    <br />
                    5. 서비스 이용 관련 개인정보(서비스 이용기록, 접속로그, 접속 IP 정보)
                    <br />
                    <br />- 보존 근거: 통신비밀보호법
                    <br />
                    <br />- 보존 기간: 3개월
                    <br />
                    <br />
                    6. 개인위치정보에 관한 기록
                    <br />
                    <br />- 보존 근거 : 위치정보의 보호 및 이용 등에 관한 법률
                    <br />
                    <br />- 보존 기간 : 1년
                    <br />
                    <br /> <br />
                    <br />
                    <b>제 6 조 (개인정보의 파기절차 및 방법)</b> 이용자의 개인정보는
                    원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이
                    파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
                    <br />
                    <br /> <br />
                    <br />
                    1. 파기절차
                    <br />
                    <br />
                    이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성되거나 또는 1년간
                    서비스를 이용하지 않는 이용자의 정보는 별도의 DB로 옮겨져(종이의 경우
                    별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에
                    따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다. 동
                    개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로
                    이용되지 않습니다.
                    <br />
                    <br /> <br />
                    <br />
                    2. 파기방법
                    <br />
                    <br />
                    종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                    전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
                    방법을 사용하여 삭제합니다.
                    <br />
                    <br /> <br />
                    <br />
                    <b>제 7 조 (이용자 및 법정대리인의 권리와 그 행사방법)</b> ① 이용자 및
                    법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나
                    수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를
                    거부하거나 가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한
                    경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.
                    <br />
                    <br />② 이용자의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는
                    '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 "회원탈퇴"를
                    클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가
                    가능합니다. 또는, 개인정보보호책임자에게 서면, 전화 또는 이메일로
                    연락하시면 지체 없이 조치하겠습니다.
                    <br />
                    <br />③ 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는
                    정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다.
                    또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
                    제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
                    <br />
                    <br />④ 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
                    개인정보는 제5조 "개인정보의 보유 및 이용기간"에 명시된 바에 따라
                    처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                    <br />
                    <br />⑤ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
                    대리인을 통해서도 하실 수 있으며, 이 경우에는 법에서 정한 위임장을
                    제출하셔야 합니다.
                    <br />
                    <br />⑥ 회사는 만 14세 미만의 아동에 대한 개인정보를 수집하지
                    않습니다.
                    <br />
                    <br />
                    <b>
                      제 8 조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)
                    </b>{" "}
                    회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
                    저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는
                    웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는
                    아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.
                    회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
                    <br />
                    <br /> <br />
                    <br />
                    1. 쿠키의 사용목적
                    <br />
                    <br />
                    이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 방문 및
                    이용형태, 보안접속 여부, 이용자 규모 등을 파악하여 이용자에게 최적화된
                    정보 제공을 위하여 사용합니다.
                    <br />
                    <br />
                    이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는
                    웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
                    저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
                    있습니다.
                    <br />
                    <br /> <br />
                    <br />
                    2. 쿠키의 거부방법
                    <br />
                    <br />이 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹
                    브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할
                    때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단,
                    쿠키 설치를 거부하였을 경우 로그인이 필요한 일부 서비스 이용에
                    어려움이 있을 수 있습니다.
                    <br />
                    <br /> <br />
                    <br />▶ 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의
                    도구 &gt; 인터넷 옵션 &gt; 개인정보
                    <br />
                    <br /> <br />
                    <br />
                    <b>제 9 조 (개인정보의 기술적/관리적 보호 대책)</b> 회사는 이용자들의
                    개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는
                    훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을
                    강구하고 있습니다.
                    <br />
                    <br /> <br />
                    <br />
                    1. 비밀번호 암호화
                    <br />
                    <br />
                    사용자 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어
                    본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는
                    본인에 의해서만 가능합니다.
                    <br />
                    <br /> <br />
                    <br />
                    2. 해킹 등에 대비한 기술적 대책
                    <br />
                    <br />
                    회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가
                    유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에
                    시스템을 설치하고, 침입 차단장치 이용 및 침입탐지시스템을 설치하여
                    24시간 감시하고 있습니다. 그리고 백신 프로그램을 주기적으로
                    업데이트하며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를
                    적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다. 또한
                    개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 암호화 통신을
                    통하여 네트워크 상에서 개인정보를 안전하게 전송 할 수 있도록 하고
                    있습니다.
                    <br />
                    <br /> <br />
                    <br />
                    3. 개인정보처리시스템 접근 제한
                    <br />
                    <br />
                    회사는 개인정보를 처리할 수 있도록 체계적으로 구성한
                    데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소 등에 관한 기준을
                    수립하고 비밀번호의 생성 방법, 변경 주기 등을 규정 운영하며 기타
                    개인정보에 대한 접근통제를 위해 필요한 조치를 다하고 있습니다.
                    <br />
                    <br /> <br />
                    <br />
                    4. 개인정보 처리직원의 최소화 및 교육
                    <br />
                    <br />
                    개인정보관련 처리 직원은 담당자에 한정시켜 최소화 하고 새로운
                    보안기술의 습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며
                    별도의 비밀번호를 부여하여 접근 권한을 관리하는 등 관리적 대책을
                    시행하고 있습니다.
                    <br />
                    <br /> <br />
                    <br />
                    5. 개인정보보호 활동
                    <br />
                    <br />
                    회사는 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여
                    문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고
                    있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 아이디(ID),
                    비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해
                    회사는 일체의 책임을 지지 않습니다.
                    <br />
                    <br /> <br />
                    <br />
                    <b>제 10 조 (개인정보보호책임자 및 담당자의 연락처)</b> ① 이용자는
                    회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
                    개인정보 관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는
                    이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
                    <br />
                    <br /> <br />
                    <br />▶ 개인정보보호책임자
                    <br />
                    <br />
                    성명 : 이명학
                    <br />
                    <br />
                    직책 : 전자물류사업총괄본부 본부장
                    <br />
                    <br />
                    연락처 : 1577-1172, privacy@klnet.co.kr
                    <br />
                    <br /> <br />
                    <br />
                    <br />
                    <br /> <br />
                    <br />② 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래
                    기관에 문의하시기 바랍니다.
                    <br />
                    <br /> <br />
                    <br />▶ 개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이
                    118)
                    <br />
                    <br />▶ 개인정보분쟁조정위원회 (www.kopico.go.kr / 국번없이 1833-6972)
                    <br />
                    <br />▶ 대검찰청 사이버수사과 (http://spo.go.kr / 국번없이 1301)
                    <br />
                    <br />▶ 경찰청 사이버안전국 (http://cyberbureau.police.go.kr /
                    국번없이 182)
                    <br />
                    <br /> <br />
                    <br />
                    <b>제 11 조 (기타)</b> 회사에 링크되어 있는 웹사이트들이 개인정보를
                    수집하는 행위에 대해서는 본 "㈜케이엘넷 개인정보처리방침"이 적용되지
                    않음을 알려드립니다.
                    <br />
                    <br /> <br />
                    <br />
                    <b>제 12 조 (고지의 의무)</b> 현 개인정보처리방침 내용 추가, 삭제 및
                    수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해
                    고지할 것입니다.
                    <br />
                    <br /> <br />
                    <br />▶ 공고일자 : 2023년 06월 13일
                    <br />
                    <br />▶ 시행일자 : 2023년 06월 15일 부터 시행됩니다.
                    <br />
                    <br />
                    <br />
        
                    <Button onClick={() => {changePage('~230315')}}> ▶이전 개인정보처리 방침 보기 (2018.12.04~2023.03.15)</Button>
                </Container>: version==='~230315'?
      
      <Container>
        <br />
        <br />
        <br />
        <br />
        <br /> 주식회사 케이엘넷(이하 "회사"라 합니다.)은 “정보통신망 이용촉진
        및 정보보호에 관한 법률”, “개인정보보호법”, “전기통신사업법”,
        “전자거래기본법”, “위치정보의 보호 및 이용 등에 관한 법률” 등 개인정보와
        관련된 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한
        개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
        <br />
        회사는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하는 경우 반드시 사전에
        이용자에게 해당 내용을 알리고 동의절차를 거치며, 이용자가 동의하지 않을
        경우에는 이용자의 개인정보를 수집ㆍ이용ㆍ제공하지 않습니다. 단, 동의를
        거부하는 경우 서비스의 전부 또는 일부 이용이 제한될 수 있습니다.
        <br />
        <br />
        회사는 개인정보처리방침을 통해 이용자가 제공하는 개인정보가 어떠한
        용도와 방식으로 이용되고 있으며, 적극적으로 보호하기 위해 항상 노력하고
        있음을 알려드리고자 합니다.
        <br />
        <br />
        제 1 조 (수집하는 개인정보의 항목 및 수집방법)
        <br />
        ① 회사는 회원가입, 원활한 고객상담, 각종 서비스 등 기본적인 서비스
        제공을 위한 회원 가입시 아래와 같은 개인정보를 수집하고 있습니다.
        <br />
        1. 기업회원 (모든 서비스)
        <br />
        가. 필수항목 : 이름, 아이디(ID), 비밀번호, 회사명, 사업자등록번호,
        대표자명, 본인확인(생년월일, 성별, 휴대폰 번호, 본인확인값(CI, DI),
        이동통신사, 아이핀 정보(아이핀 확인 시), 내/외국인 여부, 이메일인증),
        이메일, 주소, Mobile 단말기 고유식별번호
        <br />
        *단 Mobile 단말기 고유식별헌호는 PLISM3.0 모바일 앱 설치 시에만 해당 됨.
        <br />
        나. 선택항목 : 영문이름, 전화번호, 팩스번호
        <br />
        2. 기업회원(정보조회용), 개인회원
        <br />
        가. 필수항목 : 필수항목 : 이름, 아이디(ID), 비밀번호, 본인확인(생년월일,
        성별, 휴대폰 번호, 본인확인값(CI, DI), 이동통신사, 아이핀 정보(아이핀
        확인 시), 내/외국인 여부, 이메일인증), 이메일, Mobile 단말기
        고유식별번호
        <br />
        *단 Mobile 단말기 고유식별헌호는 PLISM3.0 모바일 앱 설치 시에만 해당 됨.
        <br />
        나. 선택항목 : 회사명, 영문이름, 전화번호, 팩스번호
        <br />
        ② 또한, 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이
        생성되어 수집될 수 있습니다.
        <br />
        1. PC웹, 모바일 웹/앱 이용과정에서 단말기정보(OS, 모델, 디바이스
        아이디), 이용자의 브라우저 종류, 검색어, IP Address, 쿠키
        <br />
        2. 방문 일시, 서비스이용기록, 불량이용기록, 결제기록
        <br />
        ③ 회사는 다음과 같은 방법으로 개인정보를 수집합니다.
        <br />
        1. 서비스 이용가입, 서면양식, 팩스, 전화, 상담게시판, 이메일, 고객센터
        문의하기, 이벤트 응모
        <br />
        2. 협력회사 또는 제휴사로부터의 제공
        <br />
        3. 생성정보 수집 툴을 통한 수집
        <br />
        4. DO발급요청, INVOICE 등 LogisView서비스를 통한 수집
        <br />
        <br />
        제 2 조 (개인정보의 수집 및 이용목적)
        <br />
        회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 이용자가 제공한
        모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이
        변경될 시에는 사전 동의를 구할 것입니다.
        <br />
        1. 회원 관리
        <br />
        - 회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정
        이용방지와 비인가 사용방지, 가입의사 확인, 분쟁조정을 위한 기록보존,
        불만처리 등 민원처리, 고지사항 전달
        <br />
        2. 서비스 제공에 관한 계약이행 및 서비스 제공에 따른 요금정산특정
        맞춤서비스 제공, 청구서 등 발송, 본인인증, 요금결제 및 요금추심
        <br />
        3. 서비스 이용 고객간의 전자적인 업무 처리 지원
        <br />
        4. 신규 서비스 개발 및 마케팅·광고에 활용
        <br />
        신규 서비스(제품) 개발, 통계학적 특성에 따른 서비스 제공, 특화, 이벤트
        등 광고성 정보전달 및 참여기회 제공, 서비스의 유효성 확인,접속 빈도 파악
        또는 회원의 서비스 이용에 대한 통계
        <br />
        <br />
        제 3 조 (개인정보의 공유 및 제공)
        <br />
        회사는 이용자들의 개인정보를 상기 제2조 “개인정보의 수집 및
        이용목적"에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동
        범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에
        공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
        <br />
        1. 이용자들이 사전에 공개에 동의한 경우
        <br />
        2. 서비스 이용자가 전자적인 업무 처리를 위해 상대 업체에게 정보를
        제공하는 경우
        <br />
        3. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에
        따라 수사기관의 요구가 있는 경우
        <br />
        4. 정보주체 또는 법정대리인의 의사표시를 할 수 없는 상태에 있거나
        주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는
        제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
        <br />
        5. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을
        알아 볼 수 없는 형태로 개인정보를 제공하는 경우
        <br />
        <br />
        제 4조 (개인정보의 처리위탁)
        <br />
        회사는 서비스 제공에 관한 계약의 이행을 위해 아래와 같이 외부 전문업체에
        위탁하여 운영하고 있으며, 위탁업무에 필요한 범위 내에서 정보를 제공하고
        있습니다. 또한 위탁계약 시 개인정보보호 관련 지시 엄수, 개인정보에 관한
        금지 및 사고시의 책임부담 등을 명확히 규정하고 있습니다.
        <br />
        <br />
        {/* <table class="w2group w2tb tb_fb mb20" id="wq_uuid_66" style="width: 95%; margin-left: 15px;"> */}
        <Table className="mb-0" size="sm" style={{listStyle: 'none'}}>
          <tbody>
            <tr>
              <th style={{ height: '27px' }}>수탁업체</th>
              <th>&nbsp;위탁업무</th>
              <th>개인정보의&nbsp;보유&nbsp;및&nbsp;이용기간</th>
            </tr>
            <tr>
              <td style={{ borderRight: '1px solid #d9d9d9' }}>
                <li className="w2textbox " id="wq_uuid_77">
                  (주)에스티아이
                </li>
              </td>
              <td style={{ borderRight: '1px solid #d9d9d9' }}>
                <li>서비스운영 및 고객상담</li>
              </td>
              <td rowSpan="2" colSpan="1">
                <li>회원탈퇴시 혹은 위탁계약 종료시까지</li>
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: '1px solid #d9d9d9' }}>
                <li className="w2textbox " id="wq_uuid_84">
                  명진인쇄
                </li>
              </td>
              <td style={{ borderRight: '1px solid #d9d9d9' }}>
                <li className="w2textbox " id="wq_uuid_86">
                  물품배송 또는 청구서 등 발송, 고지사항 전달
                </li>
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: '1px solid #d9d9d9' }}>
                <li>
                  코리아크레딧뷰로(주)
                  {/* <!--NICE신용평가정보(주)<br>코리아크레딧뷰로(주)--> */}
                </li>
              </td>
              <td style={{ borderRight: '1px solid #d9d9d9' }}>
                <li>
                  본인확인
                  {/* <!--실명인증--> */}
                </li>
              </td>
              <td
                // style={{
                //   borderBottomColor: 'rgb(174, 174, 174)',
                //   borderBottomWidth: '1px',
                //   borderBottomStyle: 'solid',
                // }}
                style={{ borderBottom: ' 1px solid #ccc' }}
                rowSpan="2"
                colSpan="1"
              >
                <li>
                  본인확인 기관인 이들 기관에서 이미 보유한 개인정보이므로
                  별도로 저장하지 않음
                </li>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: '1px solid #d9d9d9',
                  borderBottom: ' 1px solid #ccc',
                }}
              >
                <li>가입한 이동통신사</li>
              </td>
              <td
                style={{
                  borderRight: '1px solid #d9d9d9',
                  borderBottom: ' 1px solid #ccc',
                }}
              >
                <li>본인확인</li>
              </td>
            </tr>
          </tbody>
        </Table>
        <br />
        <br />
        제 5 조 (개인정보의 보유 및 이용기간)
        <br />
        ① 회사는 회원가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의
        개인정보를 보유 및 이용하게 됩니다. 회원 탈퇴를 요청하거나 개인정보의
        수집 및 이용에 대한 동의를 철회하는 경우, 수집 및 이용목적이 달성되거나
        보유 및 이용기간이 종료한 경우 해당 개인정보를 지체 없이 파기합니다.
        <br />
        ② 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에
        의하여 보존할 필요가 있는 경우, 회사는 관계법령에서 정한 일정한 기간
        동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의
        목적으로만 이용하며 보존기간은 아래와 같습니다.
        <br />
        1. 계약 또는 청약철회 등에 관한 기록
        <br />
        - 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        - 보존 기간 : 5년
        <br />
        2. 대금결제 및 재화 등의 공급에 관한 기록
        <br />
        - 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        - 보존 기간 : 5년
        <br />
        3. 소비자의 불만 또는 분쟁처리에 관한 기록
        <br />
        - 보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률
        <br />
        - 보존 기간 : 3년
        <br />
        4. 본인확인에 관한 기록 : 6개월
        <br />
        - 보존 근거 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률
        <br />
        - 보존 기간 : 6개월
        <br />
        5. 서비스 이용 관련 개인정보(서비스 이용기록, 접속로그, 접속 IP 정보)
        <br />
        - 보존 근거: 통신비밀보호법
        <br />
        - 보존 기간: 3개월
        <br />
        6. 개인위치정보에 관한 기록
        <br />
        - 보존 근거 : 위치정보의 보호 및 이용 등에 관한 법률
        <br />
        - 보존 기간 : 1년
        <br />
        <br />
        제 6 조 (개인정보의 파기절차 및 방법)
        <br />
        이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면
        지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과
        같습니다.
        <br />
        1. 파기절차
        <br />
        이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성되거나 또는 1년간
        서비스를 이용하지 않는 이용자의 정보는 별도의 DB로 옮겨져(종이의 경우
        별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에
        따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다. 동 개인정보는
        법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지
        않습니다.
        <br />
        2. 파기방법
        <br />
        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을
        사용하여 삭제합니다.
        <br />
        <br />
        제 7 조 (이용자 및 법정대리인의 권리와 그 행사방법)
        <br />
        ① 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세
        미만 아동의 개인정보를 조회하거나 수정할 수 있으며, 회사의 개인정보의
        처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를
        요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부 이용이
        어려울 수 있습니다.
        <br />
        ② 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는
        '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는
        "회원탈퇴"를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는
        탈퇴가 가능합니다. 또는, 개인정보관리책임자에게 서면, 전화 또는 이메일로
        연락하시면 지체 없이 조치하겠습니다.
        <br />
        ③ 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을
        완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된
        개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
        없이 통지하여 정정이 이루어지도록 하겠습니다.
        <br />
        ④ 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
        개인정보는 제5조 “개인정보의 보유 및 이용기간"에 명시된 바에 따라
        처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
        <br />
        ⑤ 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
        통해서도 하실 수 있으며, 이 경우에는 법에서 정한 위임장을 제출하셔야
        합니다.
        <br />
        <br />
        제 8 조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)
        <br />
        회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를
        저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다.
        <br />
        쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게
        보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다.
        <br />
        회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.
        <br />
        1. 쿠키의 사용목적
        <br />
        이용자들이 방문한 회사의 각 서비스와 웹 사이트들에 대한 이용자의 취향과
        관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악
        등을 통한 타겟마케팅 및 개인 맞춤 서비스를 제공하기 위해 사용됩니다.
        <br />
        이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹
        브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될
        때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
        <br />
        2. 쿠키의 거부방법
        <br />
        이 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의
        옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을
        거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키 설치를
        거부하였을 경우 로그인이 필요한 일부 서비스 이용에 어려움이 있을 수
        있습니다.
        <br />
        <br />
        ▶ 설정방법 예(인터넷 익스플로어의 경우) : 웹 브라우저 상단의 도구 &gt;
        인터넷 옵션 &gt; 개인정보
        <br />
        <br />
        제 9 조 (개인정보의 기술적/관리적 보호 대책)
        <br />
        회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출,
        변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적
        <br />
        대책을 강구하고 있습니다.
        <br />
        1. 비밀번호 암호화
        <br />
        사용자 아이디(ID)의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이
        알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에
        의해서만 가능합니다. 2. 해킹 등에 대비한 기술적 대책
        <br />
        회사는 해킹이나 컴퓨터 바이러스 등에 의해 이용자의 개인정보가 유출되거나
        훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을
        설치하고, 침입 차단장치 이용 및 침입탐지시스템을 설치하여 24시간
        감시하고 있습니다. 그리고 백신 프로그램을 주기적으로 업데이트하며
        갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 적용함으로써
        개인정보가 침해되는 것을 방지하고 있습니다.또한, 개인정보의 훼손에
        대비해서 자료를 수시로 백업하고 있고, 암호화 통신 등을 통하여
        네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.
        <br />
        3. 개인정보처리시스템 접근 제한
        <br />
        회사는 개인정보를 처리할 수 있도록 체계적으로 구성한
        데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소 등에 관한 기준을
        수립하고 비밀번호의 생성 방법, 변경 주기 등을 규정 운영하며 기타
        개인정보에 대한 접근통제를 위해 필요한 조치를 다하고 있습니다.
        <br />
        4. 개인정보 처리직원의 최소화 및 교육
        <br />
        개인정보관련 처리 직원은 담당자에 한정시켜 최소화 하고 새로운 보안기술의
        습득 및 개인정보보호 의무에 관해 정기적인 교육을 실시하며 별도의
        비밀번호를 부여하여 접근 권한을 관리하는 등 관리적 대책을 시행하고
        있습니다.
        <br />
        5. 개인정보보호 활동
        <br />
        회사는 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여
        문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.
        단, 이용자 본인의 부주의나 인터넷상의 문제로 아이디(ID), 비밀번호,
        주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의
        책임을 지지 않습니다.
        <br />
        <br />
        제 10 조 (개인정보보호책임자 및 담당자의 연락처)
        <br />
        ① 이용자는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련
        민원을 개인정보 관리책임자 혹은 담당부서로 신고하실 수 있습니다.
        <br />
        회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
        <br />
        <br />
        ▶ 개인정보보호책임자
        <br />
        성명 : 이명학
        <br />
        직책 : eBiz사업본부장
        <br />
        연락처 : 1577-1172, privacy@klnet.co.kr
        <br />
        <br />
        ② 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
        <br />
        ▶ 개인정보침해신고센터 (http://privacy.kisa.or.kr / 국번없이 118)
        <br />
        ▶ 개인정보분쟁조정위원회 (www.kopico.go.kr / 국번없이 1833-6972)
        <br />
        ▶ 대검찰청 사이버수사과 (http://spo.go.kr / 국번없이 1301)
        <br />
        ▶ 경찰청 사이버안전국 (http://cyberbureau.police.go.kr / 국번없이 182)
        <br />
        <br />
        제 11 조 (기타)
        <br />
        회사에 링크되어 있는 웹사이트들이 개인정보를 수집하는 행위에 대해서는 본
        "㈜케이엘넷 개인정보처리방침"이 적용되지 않음을 알려드립니다.
        <br />
        <br />
        제 12 조 (고지의 의무)
        <br />
        현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소
        7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다.
        <br />
        ▶ 최초공고일자 : 2017년 7월 1일
        <br />
        ▶ 변경공고일자 : 2018년 11월 28일
        <br />
        ▶ 시행일자 : 2018년 12월 04일 부터 시행됩니다.
        <br />
        <br />
        <br />
        <Button onClick={() => {changePage('now')}}> ▶ 개인정보처리 방침 보기 (2023.10.25~)</Button>
    </Container>:<Container></Container>

        }
      </div>
      <ScrollTop {...props}>
        <Fab
          color="secondary"
          size="small"
          aria-label="scroll back to top"
          style={{ backgroundColor: "#26c6da" }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
