import React, { useState, useEffect } from 'react';
import {Row, Col, FormGroup,Label,Input, Card, CardTitle, CardSubtitle, CardBody, CardFooter, Button, UncontrolledTooltip} from "reactstrap";
import {Tooltip, Popover} from "@material-ui/core";
import Moment from 'moment';
import axios from "axios";
import {CustomDatePickerInput} from 'components/CustomInput/reactstrap/CustomInput';
import { Link ,useHistory} from "react-router-dom";
import Select from "react-select";
import * as validation from 'components/common/validation.js';
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'
import ShipMap from "muiComponents/Map/ShipMap.js";

export default function ConfirmList (props) {
    let history = useHistory();
    const [toDate,setToDate] = useState(Moment(new Date()).subtract(7,'days'));
    const [endDate,setEndDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [num, setNum] = useState(1);
    const [req_bkg, setReq_bkg] = useState("");
    const [bookingNumber, setBookingNumber] = useState("");
    const [totCnt, setTotCnt] = useState(0);
    const [isBottom, setBottom] = useState(false);
    const [moreTodate, setMoreTodate] = useState(Moment(new Date()).subtract(7,'days'));
    const [moreEndDate,setMoreEndDate] = useState(new Date());
    const [moreReq_bkg, setMoreReq_bkg] = useState("");
    const [moreBookingNumber, setMorebookingNumber] = useState("");
    const [carrierList, setCarrierList] = useState([]);
    const [lineCode, setLineCode] = useState('');
    const [popupMap,setPopupMap] = React.useState(null);

    const {userData} = props;

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
          if(userData){
            axios.post("/loc/getCustomLineCode",{}).then(res => setCarrierList(res.data));
          }else {
            props.openLogin();
          }
        return function cleanup() {
          document.removeEventListener("scroll", onScroll);
      };
    },[]);

    useEffect(() => {
        onSubmit();
    }, [userData]);

    function onScroll(){
        const view = document.getElementById('card');  
        // console.log(view,view.getBoundingClientRect().bottom ,window.innerHeight)
        if(view.getBoundingClientRect().bottom <= window.innerHeight) {
        setBottom(true);
        }else {
        setBottom(false);
        }
    }

    const onSubmit = () => {
        if(userData){
            setNum(1);
            setMoreTodate(toDate);
            setMoreEndDate(endDate);
            setMoreReq_bkg(req_bkg);
            setMorebookingNumber(bookingNumber);
            if(toDate > endDate){
                props.onAlert('danger',validation.DATE_MSG);
                return false;
            }
            axios.post("/shipper/selectShpConfirmList",{
                  userNo:userData?userData:''
                , bkg_no:req_bkg
                , res_bkg_no:bookingNumber
                , toDate:Moment(toDate).format('YYYYMMDD')
                , endDate:Moment(endDate).format('YYYYMMDD')
                , num:1
                , lineCode: lineCode
                , key:'LOGISVIEW'
            }).then(res => {
                if(res.statusText==="OK") {
                    if(res.data.length > 0) {
                        setTotCnt(res.data[0].tot_cnt);
                        setData(res.data);
                        props.onAlert('success',validation.OK_CHECK_MSG);
                    }else {
                        setData([]);
                        setTotCnt(0);
                        props.onAlert('danger',validation.FAIL_CHECK_MSG);
                    }
                }
            })
        }else{
            props.onAlert('danger',validation.NO_USER_MSG);
        }
    }

    const onScrolltoTop =() => {
        window.scrollTo(0,0);
    }

    const onMore = (param) => {
        if(userData){
            if((param-1) !== Number(data[0].tot_page)) {
                setNum(param);
                axios.post("/shipper/selectShpConfirmList",{
                    userNo:userData?userData:''
                    , bkg_no:moreReq_bkg
                    , res_bkg_no:moreBookingNumber
                    , toDate:Moment(moreTodate).format('YYYYMMDD')
                    , endDate:Moment(moreEndDate).format('YYYYMMDD')
                    , num:param
                    , lineCode: lineCode
                    , key:'LOGISVIEW'
                }).then(res => (setData([...data,...res.data])));
            }else {
                props.onAlert('warning',validation.LAST_PAGE_MSG);
            }
        }else{
            props.onAlert('danger',validation.NO_USER_MSG);
        }
    }

    const onHandleConfirm=(value)=>{
        if( 'W' === value.bkg_type &&value.user_no !== userData.user_no){
            props.onAlert("error", '위동 선사는 작성 유저만 조회가능합니다.');
            return ;
        }
        if( value?.status_cus =='S5'||value?.status_cus =='S4'){
            history.push({
                pathname: 'W' === value.bkg_type
                ?`/svc/bookingWdfc`
                :'E'=== value.bkg_type
                    ?'/svc/bkgPlism':`/svc/booking`,
                    state:{
                    bkg_no: value.req_bkg_no?value.req_bkg_no:null,
                    user_no: value.user_no?value.user_no:null,
                    bkg_date: value.req_bkg_date?value.req_bkg_date:null,
                    line_code: value.line_code||null,
                    new_yn:'N'
                }
            });
            return ;
        }
        
        history.push({
            pathname: 'W' === value.bkg_type
            ?`/svc/confirmWdfc`
            :'E'=== value.bkg_type
            ?'/svc/confirmPlism':`/svc/confirm`,
            state:{user_no:value.user_no, res_bkg_no:value.res_bkg_no,
                 res_confirm_date:value.res_confirm_date, line_code:value.line_code}
        })
    
    }
    const onHandleSr=(value)=>{
        if(value&&value.sr_eshipping_use_yn==='N'){
            props.onAlert('danger',`SR ${validation.NOT_SERVE_CARRIER}`);
        }else{
            if( 'W' === value.bkg_type &&value.user_no !== userData.user_no){
                props.onAlert("error", '위동 선사는 작성 유저만 조회가능합니다.');
                return false;
            }
            history.push({
                pathname: 'W' === value.bkg_type
                ?`/svc/srWDFC`
                :'E'=== value.bkg_type
                    ?'/svc/srPlism':`/svc/sr`,
                state:{
                res_bkg_no: value.res_bkg_no?value.res_bkg_no:null,
                user_no: value.user_no?value.user_no:null,
                sr_no:value.sr_no?value.sr_no:null, 
                sr_date:value.sr_date?value.sr_date:null,
                line_code: value.line_code||null,
                confirm_yn:'Y'}
            })
        }
    }

    const openPopup = async(data)=>{
        if(!userData){
            props.onAler('error',validation.NOTLOGIN_MSG);
            return false
        }
        
        await axios.post('/api/oraMenuAccessLog', 
        {modalName : '/bkglist/shipmap',
            user: userData ? userData.user_no : 'GUEST',});//pathName

        setPopupMap(								
            <Popover
                id="popover_map"
                open={true}
                onClose={()=>setPopupMap(null)}
                anchorReference="anchorPosition"
                anchorPosition={{top:80,left:550}}
                anchorOrigin={{vertical:'bottom',horizontal:'center',}}
                transformOrigin={{vertical:'top',horizontal:'center',}}>
                <ShipMap parameter={data} onClose={()=>setPopupMap(null)} {...props}></ShipMap>
            </Popover>
            );	    
    }

    return (
        <>
            {/* <Col className="ml-auto mr-auto mt-4" xs="11"> */}
                <Card className="card-raised card-form-horizontal no-transition mb-4" id="card">
                    <CardTitle>
                        <div className='search_option'>
                            <Col xl='3' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        CARRIER
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Select
                                        className="customSelect "
                                        name="lineCode"
                                        value={{
                                            value:lineCode||'',
                                            label:lineCode?
                                            (carrierList.findIndex(x=>x.value===lineCode)>=0)?
                                            carrierList[carrierList.findIndex(x=>x.value===lineCode)].label:
                                              '선택':'선택'
                                        }}
                                        onChange={(value)=>setLineCode(value&&value.id||'')}
                                        options={carrierList}
                                        getOptionLabel={(option)=>option.value+option.label}
                                        isClearable={lineCode?true:false}
                                        />
                                    </Col>
                                    {/* <Col className='search_option--number--input col-8'>
                                        <Select
                                        className="customSelect "
                                        name="sch_vessel_name"
                                        value={{
                                            value:sch_vessel_name?sch_vessel_name:'',
                                            label:sch_vessel_name?sch_vessel_name:'선택'
                                        }}
                                        onChange={(value)=>value?setSchVesselName(value.vessel_name):setSchVesselName('')}
                                        options={lineVesselList}
                                        isClearable={sch_vessel_name?true:false}
                                        />
                                    </Col> */}
                                </FormGroup> 
                            </Col> 
                            <Col xl='4' className='search_option--calendar'>
                                <FormGroup row>
                                    <Label className='search_option--calendar--text '>컨펌일자</Label>
                                    <Col xl='5'>
                                        <CustomDatePickerInput
                                            id="startDate"
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            value={toDate}
                                            onChange={(date)=>setToDate(date)}
                                            {...props}/>
                                    </Col>
                                    <div className='search_option--calendar--text '>~</div>
                                    <Col xl='5'>
                                        <CustomDatePickerInput
                                            id="endDate"
                                            dateFormat="YYYY-MM-DD"
                                            timeFormat={false}
                                            value={endDate}
                                            onChange={(date)=>setEndDate(date)}
                                            {...props}/>
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col xl='2' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-3'>
                                        REQ BKG
                                    </Col>
                                    <Col className='search_option--number--input col-9'>
                                        <Input 
                                            type="text" 
                                            id="req_bkg"
                                            placeholder="REQ BKG"
                                            maxLength="50"
                                            value={req_bkg}
                                            onChange={(e)=>setReq_bkg(e.target.value.toUpperCase())}
                                            onKeyPress ={(e)=> {"Enter" === e.key && req_bkg&&onSubmit();}}
                                            />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col xl='3' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-3'>
                                        BOOKING NUMBER
                                    </Col>
                                    <Col className='search_option--number--input col-9'>
                                        <Input 
                                            type="text" 
                                            id="bknum"
                                            placeholder="Booking Number"
                                            maxLength="50"
                                            value={bookingNumber}
                                            onChange={(e)=>setBookingNumber(e.target.value.toUpperCase())}
                                            onKeyPress ={(e)=> {"Enter" === e.key && bookingNumber&&onSubmit();}}
                                            />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </div>
                    </CardTitle>
                    <div className='result_option'>
                        <CardSubtitle className="text-left result_count">
                            <span>[ Data Count: {data.length}건 / {totCnt}건 ]</span>
                        </CardSubtitle>
                        <Button
                            color="primary"
                            className='search_option--search'
                            onClick={()=>onSubmit()}>SEARCH</Button>
                    </div>
                    <CardBody className="result_table">
                        <Row className="table_th bg-light border-top" >
                            <div className='table_th--no border-right border-left border-bottom'>#
                            </div> 
                            <Col>
                                <Row >
                                    <Col md="6" xs="12">
                                        <Row className='table_th--rows'>
                                        <Col className="table_th--text" xs="2">CARRIER</Col>
                                        <Col className="table_th--text" xs="3">BKG NO</Col>
                                        <Col className="table_th--text" xs="2">STATUS</Col>
                                        <Col className="table_th--text" xs="2">CONFIRM DATE</Col>
                                        <Col className="table_th--text" xs="3">VESSEL</Col>
                                        
                                        </Row>
                                    </Col>
                                    <Col md="3" xs="12">
                                        <Row className='table_th--rows'>
                                        <Col className="table_th--text" xs="3" >POL</Col>
                                        <Col className="table_th--text" xs="3" >POD</Col>
                                        <Col className="table_th--text" xs="3" >LED</Col>
                                        <Col className="table_th--text" xs="3" >DCT</Col>
                                        </Row>
                                    </Col>
                                    <Col md="3" xs="12">
                                        <Row className='table_th--rows'>
                                        <Col className="table_th--text" xs="3" >CCT</Col>
                                        <Col className="table_th--text" xs="4" >REQ BKG</Col>
                                        <Col className="table_th--text" xs="5" >SR</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {data.length > 0 && data.map((value,index) => {
                            return <Row className="table_tb table_tb--hover border-bottom pb-3" key={index}>
                                        <div className="table_tb--no text-center pt-3 pl-1 pr-1">
                                            {value.rownum}
                                        </div>
                                        <Col>
                                            <Row>
                                                <Col md="6" xs="12" >
                                                    <Row className='table_tb--rows'>
                                                        {/* <Col className="text-center pt-3" xs="2">{value.line_code}</Col> */}
                                                        <Col className="text-center pt-3" xs="2" >
                                                        {value.logo_img?
                                                            <LoadCarrierImage line_code={value.line_code} logo={value.logo_img} classStyle={"img-rounded img-responsive"} 
                                                            width={42} height={40} kr_name={value.line_kr_name}
                                                            />
                                                            :value.line_kr_name||value.line_code}
                                                        </Col>
                                                        <Col className="text-center pt-3" xs="3" >
                                                            <Button
                                                                    className="btn-link"
                                                                    color="primary"
                                                                    type="button"
                                                                    style={{width:'100%',padding:'0'}}
                                                                    onClick={()=>onHandleConfirm(value)}>
                                                                {value.res_bkg_no}
                                                            </Button>
                                                        </Col>
                                                        <Col className="text-center pt-3" xs="2" >{value.status_name}</Col>
                                                        <Col className="text-center pt-3" xs="2" >{value.res_confirm_date_format}</Col>
                                                        <Col className="text-center pt-3" xs="3" >
                                                        {value.sch_vessel_name&& <Tooltip title="현재 선박위치 조회">
                                                            <div onClick={e =>openPopup(value)} style ={{textDecoration: 'underline', cursor:'pointer'}}>
                                                                {value.sch_vessel_name}<br/>
                                                                {value.sch_vessel_voyage?'('+value.sch_vessel_voyage+')':''}
                                                            </div>
                                                        </Tooltip>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md="3" xs="12" >
                                                    <Row className='table_tb--rows'>
                                                        <Col className="text-center pt-3" xs="3"><span data-html="true" data-toggle="tooltip" title={value.sch_pol_name} >{value.sch_pol}<br/>{value.sch_etd?'('+Moment(value.sch_etd).format('MM-DD')+')':null}</span></Col>
                                                        <Col className="text-center pt-3" xs="3"><span data-html="true" data-toggle="tooltip" title={value.sch_pod_name} >{value.sch_pod}<br/>{value.sch_eta?'('+Moment(value.sch_eta).format('MM-DD')+')':null}</span></Col>
                                                        <Col className="text-center pt-3" xs="3">{value.sch_led_format}</Col>
                                                        <Col className="text-center pt-3" xs="3">{value.sch_dct_format}</Col>
                                                    </Row>
                                                </Col>
                                                {/* {value.res_bkg_date} */}
                                                <Col md="3" xs="12">
                                                    <Row className='table_tb--rows'>
                                                        <Col className="text-center pt-3" xs="3">{value.sch_cct_format}</Col>
                                                        <Col className="text-center pt-3" xs="4">
                                                            {/* <Link key={"bkg_"+index} to={{
                                                                pathname: 'W' === value.bkg_type
                                                                ?`/svc/bookingWdfc`
                                                                :'E'=== value.bkg_type
                                                                    ?'/svc/bkgPlism':`/svc/booking`,
                                                                state:{
                                                                bkg_no: value.req_bkg_no?value.req_bkg_no:null,
                                                                user_no: value.user_no?value.user_no:null,
                                                                bkg_date: value.req_bkg_date?value.req_bkg_date:null,
                                                                line_code: value.line_code||null,
                                                                new_yn:'N'
                                                            }}}> */}
                                                                <Button
                                                                    className="btn-link"
                                                                    color="primary"
                                                                    type="button"
                                                                    style={{width:'100%',padding:'0'}}
                                                                    onClick={()=>{
                                                                        if( 'W' === value.bkg_type &&value.user_no !== userData.user_no){
                                                                            props.onAlert("error", '위동 선사는 작성 유저만 조회가능합니다.');
                                                                            return false;
                                                                        }
                                                                        history.push({
                                                                            pathname: 'W' === value.bkg_type
                                                                            ?`/svc/bookingWdfc`
                                                                            :'E'=== value.bkg_type
                                                                                ?'/svc/bkgPlism':`/svc/booking`,
                                                                                state:{
                                                                                bkg_no: value.req_bkg_no?value.req_bkg_no:null,
                                                                                user_no: value.user_no?value.user_no:null,
                                                                                bkg_date: value.req_bkg_date?value.req_bkg_date:null,
                                                                                line_code: value.line_code||null,
                                                                                new_yn:'N'
                                                                            }
                                                                        });
                                                                    }}
                                                                    >{value.req_bkg_no}
                                                                </Button>
                                                            {/* </Link> */}
                                                        </Col>
                                                        <Col className="text-center pt-3" xs="5">
                                                        {(value.status_cus == 'RA' || value.status_cus == '4C') &&//정정작성 상태 추가
                                                        // {!(value.status_cus == 'EJ' || value.status_cus == 'EC'||value.status_cus == 'EA'|| value.status_cus == '4D'
                                                        //     ||value.status_cus =='S5'||value.status_cus =='S4') &&//정정작성 상태 추가
                                                            // <Link key={"sr_"+index} to={{
                                                            //     pathname: 'W' === value.bkg_type
                                                            //     ?`/svc/srWDFC`
                                                            //     :'E'=== value.bkg_type
                                                            //         ?'/svc/srPlism':`/svc/sr`,
                                                            //     state:{
                                                            //     res_bkg_no: value.res_bkg_no?value.res_bkg_no:null,
                                                            //     user_no: value.user_no?value.user_no:null,
                                                            //     sr_no:value.sr_no?value.sr_no:null, 
                                                            //     sr_date:value.sr_date?value.sr_date:null,
                                                            //     line_code: value.line_code||null,
                                                            //     confirm_yn:'Y'
                                                            // }}}>
                                                                <Button
                                                                    className="btn-link"
                                                                    color="primary"
                                                                    type="button"
                                                                    style={{width:'100%',padding:'0'}}
                                                                    onClick ={()=>onHandleSr(value)}
                                                                    >{value.sr_no?value.sr_no:'NEW'}
                                                                </Button>
                                                            // </Link>
                                                        }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row> 
                        })}
                        {popupMap}
                    </CardBody>
                    <CardFooter className="text-center">
                    {data.length > 0 &&
                    <Button
                        size="lg"
                        className="btn-round"
                        onClick={() => {onMore(num + 1);}}
                        color="primary"><span>More&nbsp;{num}&nbsp;/&nbsp;{data.length!==0?data[0].tot_page:"0"}</span>
                    </Button>}
                    {isBottom &&
                    <Button
                        style={{float:'right'}}
                        size="sm"
                        id="scrollTop"
                        onClick={() => {onScrolltoTop()}}
                        color="link"><i className="fa fa-chevron-circle-up fa-2x"></i>
                        <UncontrolledTooltip delay={0} target="scrollTop">TOP</UncontrolledTooltip>
                    </Button>}
                    </CardFooter>
                </Card>
            {/* </Col> */}
        </>
    )
}
