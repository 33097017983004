import React, { useState, useEffect, useCallback } from 'react';
import {Row, Col, FormGroup,Label,Input, Card, CardTitle, CardBody, CardFooter, Button, UncontrolledTooltip, Modal} from "reactstrap";
import {Tooltip, Popover} from "@material-ui/core";
import Moment from 'moment';
import axios from "axios";

import {CustomDatePickerInput} from 'components/CustomInput/reactstrap/CustomInput'
import CardSubtitle from 'reactstrap/lib/CardSubtitle';
import { Link ,useHistory} from "react-router-dom";
import * as validation from 'muiComponents/common/validation.js';
import Select from "react-select";
import ScheduleCalendarModal from "muiComponents/Modals/ScheduleCalendarModal.js";
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'
import ShipMap from "muiComponents/Map/ShipMap.js";

export default function BookingList (props) {
    let history = useHistory();
    const [toDate,setToDate] = useState(Moment(new Date()).subtract(7,'days'));
    const [endDate,setEndDate] = useState(Moment(new Date()));
    const [data, setData] = useState([]);
    const [num, setNum] = useState(1);
    const [bookingNumber, setBookingNumber] = useState("");
    const [totCnt, setTotCnt] = useState(0);
    const [isBottom, setBottom] = useState(false);
    const [moreTodate, setMoreTodate] = useState(Moment(new Date()).subtract(7,'days'));
    const [moreEndDate,setMoreEndDate] = useState(Moment(new Date()));
    const [moreBookingNumber, setMorebookingNumber] = useState("");
    const [modalMsg, setModalMsg] = useState(null);
    const [lineVesselList ,setLineVesselList] = useState([]);
    const [sch_vessel_name, setSchVesselName] = useState("");
    const [carrierList, setCarrierList] = useState([]);
    const [lineCode, setLineCode] = useState('');
    const [scheduleCalendarOpen, setScheduleCalendarOpen] = useState(false);
    const [popupMap,setPopupMap] = React.useState(null);
    
    const {userData}=props;

    useEffect(() => {
        document.addEventListener('scroll', onScroll);
        if(userData){
          axios.post("/loc/getCustomLineCode",{}).then(res => setCarrierList(res.data));
        }else {
          props.openLogin();
        }
        if(props.location.state){
            const state = props.location.state;
            state.start_date&& setToDate(state.start_date); 
            state.end_date&& setEndDate(state.end_date);
        }
        return function cleanup() {
            document.removeEventListener("scroll", onScroll);
        };
    }, []);

    useEffect(() => {
        if(lineCode) {
            selectLineCodeVesselName({line_code: lineCode,key: 'in'});
        }
        onSubmit();
    }, [userData, lineCode]);

    // useEffect(()=> {
    //     if(props.site) {
    //         setLineCode(props.site.line_code)
    //     }else {
    //         props.onAlert('danger','사이트 정보가 없습니다.')
    //     }
    // },[props.site]);

    useEffect(useCallback(()=>{
        if( sch_vessel_name ) {
            onSubmit();
        }
    },[sch_vessel_name]),[sch_vessel_name]);

    function onScroll(){
        const view = document.getElementById('card');
        if(view.getBoundingClientRect().bottom <= window.innerHeight) {
            setBottom(true);
        }else {
            setBottom(false);
        }
    }
    const onSubmit = () => {
        if(userData){
            setNum(1);
            setMoreTodate(toDate);
            setMoreEndDate(endDate);
            setMorebookingNumber(bookingNumber);
            if(toDate > endDate){
                props.onAlert("error",validation.DATE_MSG);
                return false;
            }
            axios.post("/shipper/selectBookingList",{
                userNo:userData?userData:''
                , bkg_no:bookingNumber
                , toDate:Moment(toDate).format('YYYYMMDD')
                , endDate:Moment(endDate).format('YYYYMMDD')
                , num:1
                , sch_vessel_name: sch_vessel_name
                , lineCode:lineCode
                , key:'LOGISVIEW'
            }).then(res => {
                if(res.statusText==="OK") {
                    if(res.data.length > 0) {
                        setTotCnt(res.data[0].tot_cnt);
                        setData(res.data);
                        props.onAlert('success',validation.OK_CHECK_MSG);
                    }else {
                        setData([]);
                        setTotCnt(0);
                        props.onAlert('danger',validation.FAIL_CHECK_MSG);
                    }
                }
            })
        }else{
            props.onAlert("danger",validation.NO_USER_MSG);
        }
    }
    const onScrolltoTop =() => {
        window.scrollTo(0,0);
    }
    const onMore = (param) => {
        if(userData){
            if((param-1) !== Number(data[0].tot_page)) {
                setNum(param);
                axios.post("/shipper/selectBookingList",{
                    userNo:userData?userData:''
                    , bkg_no:moreBookingNumber
                    , toDate:Moment(moreTodate).format('YYYYMMDD')
                    , endDate:Moment(moreEndDate).format('YYYYMMDD')
                    , lineCode:lineCode
                    , num:param
                    , key:'LOGISVIEW'}).then(res => {
                    setData([...data,...res.data])
                });
            }else {
                props.onAlert('warning',validation.LAST_PAGE_MSG);
            }
        }else{
            props.onAlert('danger',validation.NO_USER_MSG);
        }
    }
    
    const onDeleteRows = (seq,vVal) => {
        if(vVal && (vVal.status_cus === "NO" ||  vVal.status_cus === "S0") ) {

            setModalMsg(
                <Modal
                    isOpen={true}>
                    <div className="modal-header no-border-header">
                        <button
                        className="close"
                        type="button"
                        onClick={() => setModalMsg(null)}
                        >×</button>
                    </div>
                    <div className="modal-body text-center pl-0 pr-0">
                        <h5>선택한 Request 문서 [{vVal.bkg_no}]를 삭제 하시겠습니까?</h5>
                    </div>
                    <div className="modal-footer">
                        <div className="left-side">
                            <Button className="btn-link" color="danger" type="button" onClick={()=>fncDeleteRow(seq,vVal)}>Yes</Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button className="btn-link" color="default" type="button" onClick={() => setModalMsg(null)}>No</Button>
                        </div>
                    </div>
                </Modal>);

        } else {
            props.onAlert("error","전송 된  Booking 문서는 삭제 할수 없습니다.");
            return false;
        }  
    }

    const fncDeleteRow=(seq,vVal)=>{
        axios.post("/shipper/deleteBooking",{user_no:userData?userData.user_no:'', booking:vVal}).then(res => {
            data.splice(seq,1);
            setTotCnt(totCnt-1);
            setModalMsg(null);
            props.onAlert("success",vVal.bkg_no+"이 정상 삭제 되었습니다.");
        }).catch(err => {
            props.onNotiAlert("danger","삭제에 실패했습니다. 관리자에 문의바랍니다.");
        });
    }

    const selectLineCodeVesselName = (params) => {
        if(lineCode) {
            axios.post("/shipper/selectLineCodeVesselName",{ params }).then(res=>{
                setLineVesselList(res.data);
            });
        }
    }

    const openPopup = async(data)=>{
        if(!userData){
            props.onAler('error',validation.NOTLOGIN_MSG);
            return false
        }
        
        await axios.post('/api/oraMenuAccessLog', 
        {modalName : '/bkglist/shipmap',
            user: userData ? userData.user_no : 'GUEST',});//pathName

        setPopupMap(								
            <Popover
                id="popover_map"
                open={true}
                onClose={()=>setPopupMap(null)}
                anchorReference="anchorPosition"
                anchorPosition={{top:80,left:550}}
                anchorOrigin={{vertical:'bottom',horizontal:'center',}}
                transformOrigin={{vertical:'top',horizontal:'center',}}>
                <ShipMap parameter={data} onClose={()=>setPopupMap(null)} {...props}></ShipMap>
            </Popover>
            );	    
    }

    return (
        <>
            {/* <Col className="ml-auto mr-auto mt-4" xs="11"> */}
            {modalMsg}
                <Card className="card-raised card-form-horizontal no-transition mb-4" id="card">
                    <CardTitle>
                        <div className='search_option'>
                            <Col xl='4' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        CARRIER
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Select
                                        className="customSelect "
                                        name="lineCode"
                                        value={{
                                            value:lineCode||'',
                                            label:lineCode?
                                            (carrierList.findIndex(x=>x.value===lineCode)>=0)?
                                            carrierList[carrierList.findIndex(x=>x.value===lineCode)].label:
                                              '선택':'선택'
                                        }}
                                        onChange={(value)=>setLineCode(value&&value.id||'')}
                                        options={carrierList}
                                        getOptionLabel={(option)=>option.value+option.label}
                                        isClearable={lineCode?true:false}
                                        />
                                    </Col>
                                </FormGroup> 
                            </Col> 
                            <Col xl='4' className='search_option--calendar'>
                                <FormGroup row>
                                    <Label className='search_option--calendar--text'>부킹일자</Label>
                                    <Col>
                                        <CustomDatePickerInput
                                        id="startDate"
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        value={toDate}
                                        onChange={(date)=>setToDate(date)}
                                        {...props}/>
                                    </Col>
                                    <div className='search_option--calendar--text'>~</div>
                                    <Col>
                                        <CustomDatePickerInput
                                        id="endDate"
                                        dateFormat="YYYY-MM-DD"
                                        timeFormat={false}
                                        value={endDate}
                                        onChange={(date)=>setEndDate(date)}
                                        {...props}/>
                                    </Col>
                                </FormGroup>
                            </Col>
                            {/* <Col xl='3' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        VESSEL
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Select
                                        className="customSelect "
                                        name="sch_vessel_name"
                                        value={{
                                            value:sch_vessel_name?sch_vessel_name:'',
                                            label:sch_vessel_name?sch_vessel_name:'선택'
                                        }}
                                        onChange={(value)=>value?setSchVesselName(value.vessel_name):setSchVesselName('')}
                                        options={lineVesselList}
                                        isClearable={sch_vessel_name?true:false}
                                        />
                                    </Col>
                                </FormGroup> 
                            </Col>  */}
                            <Col xl='4' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        REQ BKG NO
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Input 
                                        type="text" 
                                        id="bknum"
                                        placeholder="Request Booking Number"
                                        maxLength="50"
                                        value={bookingNumber}
                                        onChange={(e)=>setBookingNumber(e.target.value.toUpperCase())}
                                        onKeyPress ={(e)=> {"Enter" === e.key && bookingNumber&&onSubmit();}}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </div>
                    </CardTitle>
                    <div className='result_option'>
                        <CardSubtitle className="result_count text-left">                      
                            <span>[ Data Count: {data.length}건 / {totCnt}건 ]</span>
                        </CardSubtitle>
                        <div className='search_option--new'>
                            {userData &&
                            // <Link to={{pathname: `/svc/booking`, state: {user_no:'', bkg_no:'', bkg_date:'', new_yn:'Y'}}}>
                                <Button color="primary" id="new" type="button" outline
                                    onClick={()=>setScheduleCalendarOpen(true)}
                                >
                                    <i className="fa fa-plus"/>NEW
                                </Button>
                            // </Link>
                            }
                            <Button
                                color="primary"
                                className='search_option--search'
                                onClick={()=>onSubmit()}>SEARCH</Button>  
                        </div>
                    </div>
                    <CardBody className="result_table">
                        <Row className="table_th bg-light border-top" >
                            <div className='table_th--no border-right border-left border-bottom'>#</div> 
                            <Col>
                                <Row>
                                    <Col xl="3" md="6" xs="12">
                                        <Row className='table_th--rows'>
                                            <Col className="table_th--text" xs="3">CARRIER</Col>
                                            <Col className="table_th--text" xs="5">REQ BKG NO</Col>
                                            <Col className="table_th--text" xs="4">REQ BKG DATE</Col>                
                                        </Row>                          
                                    </Col>
                                    <Col xl="3" md="6" xs="12">
                                        <Row className='table_th--rows'>
                                            <Col className="table_th--text" xs="3">STATUS</Col>
                                            <Col className="table_th--text" xs="5">BKG NO</Col>
                                            <Col className="table_th--text" xs="4">CONFIRM DATE</Col>
                                        </Row>
                                    </Col>
                                    <Col xl="3" md="6" xs="12">
                                        <Row className='table_th--rows'>
                                            <Col className="table_th--text" xs="6">VESSEL</Col>
                                            <Col className="table_th--text" xs="3">POL</Col>
                                            <Col className="table_th--text" xs="3">POD</Col>
                                        </Row>
                                    </Col>
                                    <Col xl="3" md="6" xs="12">
                                        <Row className='table_th--rows'>
                                            <Col className="table_th--text" xs="6">SEND DATE</Col>
                                            <Col className="table_th--text" xs="3">CARGO</Col>
                                            <Col className="table_th--text" xs="3">CNTR</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {data.length > 0 &&
                        <>
                            {data.map((value,key) => {
                                return(
                                    
                                    <Row key={key} className="table_tb table_tb--hover border-bottom pb-3">
                                        <div key={"child_"+key} className="table_tb--no text-center pt-3 pl-1 pr-1">
                                        <Button
                                        className="btn-link"
                                        color="danger"
                                        data-toggle="tooltip"
                                        id={"remove_"+key}
                                        size="sm"
                                        type="button"
                                        style={{marginBottom:'0'}}
                                        onClick={()=>{
                                            if( 'W' === value.bkg_type &&value.user_no !== userData.user_no){
                                                props.onAlert("error", '위동 선사는 작성 유저만 삭제 가능합니다.');
                                                return false;
                                            }else{
                                                onDeleteRows(key,value)
                                            }
                                        }}
                                    >
                                        <i className="fa fa-times" />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        placement="top"
                                        target={"remove_"+key}
                                    >
                                        Remove
                                    </UncontrolledTooltip>
                                    </div>
                                        <Col>
                                        <Row >
                                            <Col xl="3" md="6" xs="12">
                                            <Row className='table_tb--rows'>
                                                {/* <Col className="text-center pt-3" xs="3">{value.line_code}</Col> */}
                                                <Col className="text-center pt-3" xs="3">
                                                {value.logo_img?
                                                    <LoadCarrierImage line_code={value.line_code} logo={value.logo_img} classStyle={"img-rounded img-responsive"} 
                                                    width={42} height={40} kr_name={value.line_kr_name}
                                                    />
                                                    :value.line_kr_name||value.line_code}
                                                </Col>
                                                <Col className=" text-center pt-3" xs="5">
                                                  {/* <Link  
                                                  to={{pathname: 
                                                    value.bkg_type=='W'?`/svc/bookingWdfc`
                                                                    :value.bkg_type=='E'?`/svc/bkgPlism`:`/svc/booking`,
                                                   state: {user_no:value.user_no, bkg_no:value.bkg_no, bkg_date:value.bkg_date
                                                   ,line_code: value.line_code||null,  new_yn:'N'}
                                                   }}>  */}
                                                    <Button 
                                                    className="btn-link"
                                                    color="primary"
                                                    type="button"
                                                    style={{width:'100%',padding:'0'}}
                                                    id={"remove_"+value.bkg_no}
                                                    onClick={()=>{
                                                        if( 'W' === value.bkg_type &&value.user_no !== userData.user_no){
                                                            props.onAlert("error", '위동 선사는 작성 유저만 조회 가능합니다.');
                                                            return false;
                                                        }
                                                        history.push({
                                                            pathname: 
                                                            value.bkg_type=='W'?`/svc/bookingWdfc`
                                                            :value.bkg_type=='E'?`/svc/bkgPlism`:`/svc/booking`,
                                                           state: {user_no:value.user_no, bkg_no:value.bkg_no, bkg_date:value.bkg_date
                                                           ,line_code: value.line_code||null,  new_yn:'N'}
                                                        })
                                                    }}
                                                    > 
                                                    {value.bkg_no}
                                                    </Button>
                                                    <UncontrolledTooltip
                                                        delay={0}
                                                        placement="top"
                                                        target={"remove_"+value.bkg_no}
                                                        >
                                                        {value.bkg_no} 부킹문서 상세보기
                                                    </UncontrolledTooltip>
                                                  {/* </Link> */}
                                                </Col>
                                                <Col className=" text-center pt-3" xs="4">{value.bkg_date_format}</Col>
                                            </Row>
                                            </Col>
                                            <Col xl="3" md="6" xs="12">
                                            <Row className='table_tb--rows'>
                                                <Col className=" text-center pt-3" xs="3" >{value.status_name}</Col>
                                                <Col className=" text-center pt-3" xs="5">{value.res_bkg_no?
                                                // <Link key={"rowscon_"+key} 
                                                // to={{pathname: 
                                                //     value.bkg_type=='W'?`/svc/confirmWdfc`
                                                //                     :value.bkg_type=='E'?`/svc/confirmPlism`:`/svc/confirm`,
                                                // state:{user_no:value.user_no, res_bkg_no:value.res_bkg_no, res_confirm_date:value.res_confirm_date, line_code:value.line_code||null}}}>
                                                <Button
                                                className="btn-link"
                                                color="primary"
                                                type="button"
                                                style={{width:'100%',padding:'0'}}
                                                onClick={()=>{
                                                    if( 'W' === value.bkg_type &&value.user_no !== userData.user_no){
                                                        props.onAlert("error", '위동 선사는 작성 유저만 조회 가능합니다.');
                                                        return false;
                                                    }
                                                    history.push({
                                                        pathname: 
                                                        value.bkg_type=='W'?`/svc/confirmWdfc`
                                                                    :value.bkg_type=='E'?`/svc/confirmPlism`:`/svc/confirm`,
                                                        state:{user_no:value.user_no, res_bkg_no:value.res_bkg_no, res_confirm_date:value.res_confirm_date, line_code:value.line_code||null}
                                                    })
                                                }}
                                                >     
                                                {value.res_bkg_no}
                                                </Button>
                                                // </Link>
                                                :<></>}
                                                </Col>
                                                <Col className=" text-center pt-3" xs="4">{value.res_confirm_date_format}</Col>
                                            </Row>
                                            </Col>
                                            <Col xl="3" md="6" xs="12">
                                            <Row className='table_tb--rows'>
                                                <Col className=" text-center pt-3" xs="6">
                                                    {value.sch_vessel_name&&
                                                    <Tooltip title="현재 선박위치 조회">
                                                    <div onClick={e =>openPopup(value)} style ={{textDecoration: 'underline', cursor:'pointer'}}>
                                                        {value.sch_vessel_name}<br/>
                                                        {value.sch_vessel_voyage?'('+value.sch_vessel_voyage+')':''}
                                                    </div></Tooltip>}
                                                </Col>
                                                <Col className=" text-center pt-3" xs="3"><span data-html="true" data-toggle="tooltip" title={value.sch_pol_name} >{value.sch_pol}
                                                {/* <br/>{value.sch_etd ? '('+Moment(value.sch_etd.substr(0,8)).format('MM-DD')+')':''} </span>
                                                </Col>
                                                <Col className=" text-center pt-3" xs="3"><span data-html="true" data-toggle="tooltip" title={value.sch_pod_name} >{value.sch_pod}
                                                <br/>{value.sch_eta ? '('+Moment(value.sch_eta.substr(0,8)).format('MM-DD')+')':''}</span> */}
                                                    <br/> {value.sch_etd?value.sch_etd.length>7
                                                    ?`(${Moment(value.sch_etd.substr(0, 8)).format('MM-DD')})`
                                                    :`(${Moment(value.sch_etd).format('MM-DD')})`:''
                                                    }
                                                    </span>
                                                </Col>
                                                <Col className=" text-center pt-3" xs="3"><span data-html="true" data-toggle="tooltip" title={value.sch_pod_name} >{value.sch_pod}
                                                    <br/>{value.sch_eta?value.sch_eta.length>7
                                                    ?`(${Moment(value.sch_eta.substr(0, 8)).format('MM-DD')})`
                                                    :`(${Moment(value.sch_eta).format('MM-DD')})`:''
                                                    }
                                                    </span>
                                                </Col>
                                            </Row>
                                            </Col>
                                            <Col xl="3" md="6" xs="12">
                                            <Row className='table_tb--rows'>
                                                <Col className=" text-center pt-3" xs="6">{value.send_date_format}</Col>
                                                <Col className=" text-center pt-3" xs="3">{value.cargo_pack_qty}</Col>
                                                <Col className=" text-center pt-3" xs="3">{value.cntr_qty}</Col>
                                            </Row>
                                            </Col>
                                        </Row>
                                        </Col>
                                    </Row>
                                );
                            })}
                        </>}
                        {popupMap}
                    </CardBody>
                    <CardFooter className="text-center">
                        {data.length > 0 &&
                        <Button
                            size="lg"
                            className="btn-round"
                            onClick={() => {
                                onMore(num + 1);
                            }}
                            color="primary"><span>More&nbsp;{num}&nbsp;/&nbsp;{data.length!==0?data[0].tot_page:"0"}</span>
                        </Button>}
                        
                        {isBottom &&
                        <Button
                            style={{float:'right'}}
                            size="sm"
                            id="scrollTop"
                            onClick={() => {onScrolltoTop()}}
                            color="link">
                            <i className="fa fa-chevron-circle-up fa-2x"></i>
                            <UncontrolledTooltip delay={0} target="scrollTop">TOP</UncontrolledTooltip>
                        </Button>}                     
                    </CardFooter>
                </Card>     
            {/* </Col> */}
            {scheduleCalendarOpen&&
				<ScheduleCalendarModal open={scheduleCalendarOpen}  toggle={()=>setScheduleCalendarOpen(false)}
                    lineCode = {lineCode}
                    selectedEventCalendar={(event)=>{
                        if ('N' === event.booking_yn) {
                            props.onAlert('error', '해당 스케줄은 선택 불가합니다.');
                            return false;
                        } else {
                            props.alert(
                                ()=>{history.push({
                                    pathname: event.bkg_type=='W'
                                            ?`/svc/bookingWdfc`
                                            :'E'=== event.bkg_type
                                                ?'/svc/bkgPlism':`/svc/booking`,
                                    state:{
                                        ...event,
                                        schedule_yn:'Y',
                                        sch_vessel_name: event.vsl_name || null,
                                        sch_vessel_voyage: event.voyage_no || null,
                                    }
                                })}
                                , 'BOOKING 을 새로 생성하시겠습니까?'
                                , 'warning', true, true, 'lg'
                                ,  `${event&&(event.carrier_knm||event.line_code||event.sch_line_code)}\n 부킹 번호 : 자동 생성`
                                , true, true, () => { return false;}
                            );
                        };
                    }}    
                {...props}/>
			}
        </>
    )
}

