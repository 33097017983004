/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,Col, Nav
    , Button, UncontrolledTooltip, NavItem, NavLink, TabContent
    , TabPane, FormGroup, CardBody, Table, Card, Input, FormFeedback, Label, Alert as ReactAlert} from "reactstrap";
import axios from "axios";
import * as validation from 'muiComponents/common/validation.js';
import Select from "react-select";
import classnames from 'classnames';
import CardFooter from 'reactstrap/lib/CardFooter';

export default function BookingBookmark (props){
    // modal 창을 위한 state
    const [open, setOpen] = useState(false);
    // Bookmark
    const [bookmark, setBookmark] = useState([]);
    const [bookmarkList, setBookmarkList] = useState([]);
    const [relationList, setRelationList] = useState([]);
    // 전체화면 css 적용을 위한 state
    // Alert을 위한 state
    const {booking, otherList, scheduleList, shipperList, forwarderList, transportList, cargoBookmarkList, containerBookmarkList,documentList, userData} = props;
    const [activeTab, setActiveTab] = useState('1');
    // booking 상태에 따라 bookmark 부킹번호로 등록 기능 활성화
    const [bookingStatus, setBookingStatus] = useState(true);
    const [bookingExistsNo, setBookingExistsNo] = useState(false);
    const [newBookmarkName, setNewBookmarkName] = useState("");

    useEffect(() => {
        if( userData && userData.user_no ) {
            selectBookingBkgBookmark();
        }
    },[userData]);

    useEffect(() => {
        fncGetRelation();
    },[relationList]);

    useEffect(() => {
        // booking 승인(BC) 상태에만 북마크 등록 가능
        if( booking ) {
            if( "RA" === booking.status_cus ) {
                setBookingStatus(true);
            } else {
                setBookingStatus(false);
            }
        } else {
            setBookingStatus(false);
        }
    },[booking]);

    useEffect(()=>{
        let check = false;
        bookmarkList.forEach((data, i)=>{
            if( booking && data.bookmark_bkg_no ) {
                if( booking.bkg_no === data.bookmark_bkg_no ) {
                    setNewBookmarkName( data.bookmark_name );
                    setBookingExistsNo(true);
                    check = true;
                }
            }
        })
        if( !check ) {
            setNewBookmarkName("");
            setBookingExistsNo(false);
        }
    },[bookmarkList, booking]);
    // select Bkg Bookmark
    const selectBookingBkgBookmark = () => {
        axios.post("/shipper/selectBookingBkgBookmark",{user_no : userData?userData.user_no:null,booking: booking,}).then(
            res=>{
                setBookmarkList(res.data);
            }
        );
    }

    const fncSelectBookmark =(row)=>{
        setBookmark( row );
        selectBookingBkgBookmarkRelation( row );
    }

    const selectBookingBkgBookmarkRelation =( bookmark )=>{
        axios.post("/shipper/selectBookingBkgBookmarkRelation",{user_no: userData?userData.user_no:null,bookmark: bookmark,}).then(res=>{
            setRelationList(res.data);
            // fncGetRelation();
        });
    }
    // Bookmark 입력하기
    const fncSaveBookmark=(params)=>{
        if( userData && !userData.user_no ) {
            props.onNotiAlert("danger", validation.NOTLOGIN_MSG);
            return false;
        }
        if( "N" === params ) {
            if( !bookmark.bookmark_name ) return false;
            if( relationList.length < 1 ) {
                props.onNotiAlert("danger", "Bookmark 사용된 내용이 없습니다.")
                return false;
            }
        } else if( "Y" === params ) {
            if( bookingExistsNo ) {
                props.onNotiAlert("danger", "이미 해당 부킹은 등록되어 있습니다.")
                return false;
            }
            if( !bookingStatus ) {
                props.onNotiAlert("danger", "승인된 Booking만 가능합니다.")
                return false;
            }
            if( !newBookmarkName ) {
                props.onNotiAlert("danger", "Bookmark 명을 입력하세요.")
                return false;
            }
            if( booking.bookmark_bkg_no ) {
                props.onNotiAlert("danger", booking.bookmark_bkg_no+" 부킹번호로 이미 적용되어 있습니다.")
                return false;
            }
            bookmark.new_bookmark_name = newBookmarkName;
        }
        axios.post("/shipper/saveBookingBkgBookmark",{user_no: userData?userData.user_no:null,bookmark: bookmark,relationList: relationList,booking: booking,makeBooking: params}).then(res=>{
            selectBookingBkgBookmark();
        });
    }
    const deleteBookmark = (e) => {
        if( !bookmark.bookmark_name ) {
            props.onNotiAlert("danger", "삭제할 대상을 사용안함하세요.");
            return false;
        }
        axios.post("/shipper/deleteBookmark",{user_no: userData?userData.user_no:null,bookmark: bookmark,}).then(res=>{
            selectBookingBkgBookmark();
            fncInitBookmark();
        });
    }
    const toggle = () => {
        setOpen(!open);
    }

    // New Bookmark
    const fncInitBookmark = (e) => {
        // e.preventDefault();
        setBookmark({});
        setRelationList([]);
    }

    const fncOnChange =(e, key)=> {
        setBookmark({...bookmark, [key]:(e.target.value.toUpperCase())||null});
    }
    const fncOnchangeRelation = (e, key)=> {
        // relationList 정보에 취합
        let row = relationList.find( function( item ) {
            return item.reference_type === key;
        });

        if( row ) {
            row.reference_seq = e?e.value:'';
            row.bookmark_name = e?e.label:'';
            
        } else {
            relationList.push({
                'reference_type': key,
                'reference_seq': e.value,
                'bookmark_name': e.label,
            });
        }
        setRelationList([...relationList]);

    }

    const fncGetRelation =()=>{
        let obj = Object();
        if( relationList.length > 0 ) {
            relationList.forEach( function( element ) {
                if( 'BOOKING' === element.reference_type ) {
                    obj.other_bookmark_name = element.bookmark_name;
                    obj.other_reference_seq = element.reference_seq;
                }
                if( 'SCHEDULE' === element.reference_type ) {
                    obj.schedule_bookmark_name = element.bookmark_name;
                    obj.schedule_reference_seq = element.reference_seq;
                }
                // if( 'CARRIER' === element.reference_type ) {
                //     obj.carrier_bookmark_name = element.bookmark_name;
                //     obj.carrier_reference_seq = element.reference_seq;
                // }
                if( 'SHIPPER' === element.reference_type ) {
                    obj.shipper_bookmark_name = element.bookmark_name;
                    obj.shipper_reference_seq = element.reference_seq;
                }
                // if( 'CONSIGNEE' === element.reference_type ) {
                //     obj.consignee_bookmark_name = element.bookmark_name;
                //     obj.consignee_reference_seq = element.reference_seq;
                // }
                if( 'DOCUMENT' === element.reference_type ) {
                    obj.document_bookmark_name = element.bookmark_name;
                    obj.document_reference_seq = element.reference_seq;
                }
                if( 'FORWARDER' === element.reference_type ) {
                    obj.forwarder_bookmark_name = element.bookmark_name;
                    obj.forwarder_reference_seq = element.reference_seq;
                }
                if( 'TRANSPORT' === element.reference_type ) {
                    obj.transport_bookmark_name = element.bookmark_name;
                    obj.transport_reference_seq = element.reference_seq;
                }
                if( 'CARGO' === element.reference_type ) {
                    obj.cargo_bookmark_name = element.bookmark_name;
                    obj.cargo_reference_seq = element.reference_seq;
                }
                if( 'CONTAINER' === element.reference_type ) {
                    obj.container_bookmark_name = element.bookmark_name;
                    obj.container_reference_seq = element.reference_seq;
                }
            });
        } else {
            obj = {};
        }
        let merge = Object.assign(bookmark, obj);
        setBookmark({...merge});
    }

    const clickTab = (tab) => {
        if(activeTab !== tab ) {
            setActiveTab(tab);
        }
    }

    return (
        <>
            <Row>
                <Col className="col-10 pr-0" style={{zIndex:'120'}}>
                    <Select
                        className="react-select react-select-primary"
                        name="bookingBookmark"
                        value={{
                            value:booking.bookmark_seq?booking.bookmark_seq:'',
                            label:booking.bookmark_name?booking.bookmark_name:'선택'
                        }}
                        onChange={(e)=>props.fncBookmarkParent(e)}
                        options={bookmarkList}
                        placeholder={"선택"}
                        isClearable={booking.bookmark_seq?booking.bookmark_seq:false}
                        />
                </Col>
                <Col className="col-2 pl-auto pr-auto">
                    <Button className="pl-0 pr-0" 
                        color="link" id="linebookmark"
                        onClick={toggle.bind(this, 'B')}>
                            <i className="fa fa-bookmark-o fa-2x" />
                    </Button>
                </Col>
            </Row>
            <UncontrolledTooltip delay={0} target="linebookmark">Bookmark</UncontrolledTooltip>
            <Modal isOpen={open} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Bookmark</ModalHeader>
                    <ModalBody >
                        <Row>
                            <Col xl="4" lg="4" md="12">
                                <Row>
                                    <Col>Bookmark List</Col>
                                </Row>
                                <FormGroup style={{height:'550px',overflow:'auto'}} className="mb-0">
                                    <CardBody className="bg-white p-0">
                                        <Table className="mb-0" responsive hover size="sm">
                                            <thead>
                                                <tr>
                                                    <td className="p-2 bg-info" style={{textAlign: 'center'}}><b>Bookmark Name</b></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {(bookmarkList.length > 0) && bookmarkList.map((element,key)=>{
                                                return(
                                                    <tr key={key} onClick={()=>{fncSelectBookmark(element)}}
                                                    style={element.bookmark_seq===bookmark.bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}}>
                                                        <td>{element.bookmark_name}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </FormGroup>
                            </Col>
                            <Col xl="8" lg="8" md="12">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active:activeTab === '1' })}
                                            onClick={()=>{clickTab('1');}}
                                            style={{paddingBottom: '10px'}}>
                                                <h6>Bookmark 등록 </h6>
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            className={classnames({ active:activeTab === '2' })}
                                            onClick={()=>{clickTab('2');}}
                                            style={{paddingBottom: '10px'}}>
                                            <h6>현재부킹 등록</h6>
                                        </NavLink>
                                    </NavItem> */}
                                </Nav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col>
                                                <Row>
                                                    <Col>Bookmark</Col>
                                                </Row>
                                                <Row id="Transport">
                                                    <Col xl="12" lg="12">
                                                        <Card style={{zIndex:'70'}} className="card-raised card-form-horizontal no-transition mb-0">
                                                            <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
                                                                <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>Bookmark Name
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Input
                                                                                    type="text" name="bookmark_name" id="bookmark_name"
                                                                                    placeholder=""
                                                                                    maxLength="35"
                                                                                    value={bookmark.bookmark_name?bookmark.bookmark_name:''}
                                                                                    onChange={(e)=>fncOnChange(e, 'bookmark_name')}
                                                                                    invalid={bookmark.bookmark_name?false:true}
                                                                                />
                                                                                <FormFeedback>{validation.REQ_MSG}</FormFeedback>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>BOOKING</Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Select
                                                                                    className="react-select react-select-primary"
                                                                                    name="bookingbookmark"
                                                                                    value={{
                                                                                        value:bookmark.other_reference_seq?bookmark.other_reference_seq:'',
                                                                                        label:bookmark.other_bookmark_name?bookmark.other_bookmark_name:'사용안함',
                                                                                    }}
                                                                                    onChange={(value)=>fncOnchangeRelation(value?value:null, 'BOOKING')}
                                                                                    options={otherList}
                                                                                    placeholder="사용안함"
                                                                                    isClearable={bookmark.other_reference_seq?true:false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>SCHEDULE
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Select
                                                                                    className="react-select react-select-primary"
                                                                                    name="schedulebookmark"
                                                                                    value={{
                                                                                        value:bookmark.schedule_reference_seq?bookmark.schedule_reference_seq:'',
                                                                                        label:bookmark.schedule_bookmark_name?bookmark.schedule_bookmark_name:'사용안함',
                                                                                    }}
                                                                                    onChange={(value)=>fncOnchangeRelation(value?value:null, 'SCHEDULE')}
                                                                                    options={scheduleList}
                                                                                    placeholder="사용안함"
                                                                                    isClearable={bookmark.schedule_reference_seq?true:false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                {/* <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>CARRIER
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Select
                                                                                    className="react-select react-select-primary"
                                                                                    name="carrierbookmark"
                                                                                    value={{
                                                                                        value:bookmark.carrier_reference_seq?bookmark.carrier_reference_seq:'',
                                                                                        label:bookmark.carrier_bookmark_name?bookmark.carrier_bookmark_name:'사용안함',
                                                                                    }}
                                                                                    onChange={(value)=>fncOnchangeRelation(value?value:null, 'CARRIER')}
                                                                                    options={lineList}
                                                                                    placeholder="사용안함"
                                                                                    isClearable={bookmark.carrier_reference_seq?true:false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row> */}
                                                                <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>SHIPPER
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Select
                                                                                    className="react-select react-select-primary"
                                                                                    name="shipperbookmark"
                                                                                    value={{
                                                                                        value:bookmark.shipper_reference_seq?bookmark.shipper_reference_seq:'',
                                                                                        label:bookmark.shipper_bookmark_name?bookmark.shipper_bookmark_name:'사용안함',
                                                                                    }}
                                                                                    onChange={(value)=>fncOnchangeRelation(value?value:null, 'SHIPPER')}
                                                                                    options={shipperList}
                                                                                    placeholder="사용안함"
                                                                                    isClearable={bookmark.shipper_reference_seq?true:false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                {/* <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>CONSIGNEE
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Select
                                                                                    className="react-select react-select-primary"
                                                                                    name="consigneebookmark"
                                                                                    value={{
                                                                                        value:bookmark.consignee_reference_seq?bookmark.consignee_reference_seq:'',
                                                                                        label:bookmark.consignee_bookmark_name?bookmark.consignee_bookmark_name:'사용안함',
                                                                                    }}
                                                                                    onChange={(value)=>fncOnchangeRelation(value?value:null, 'CONSIGNEE')}
                                                                                    options={consigneeList}
                                                                                    placeholder="사용안함"
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row> */}
                                                                <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>FORWARDER
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Select
                                                                                    className="react-select react-select-primary"
                                                                                    name="forwarderbookmark"
                                                                                    value={{
                                                                                        value:bookmark.forwarder_reference_seq?bookmark.forwarder_reference_seq:'',
                                                                                        label:bookmark.forwarder_bookmark_name?bookmark.forwarder_bookmark_name:'사용안함',
                                                                                    }}
                                                                                    onChange={(value)=>fncOnchangeRelation(value?value:null, 'FORWARDER')}
                                                                                    options={forwarderList}
                                                                                    placeholder="사용안함"
                                                                                    isClearable={bookmark.forwarder_reference_seq?true:false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>DOCUMENT
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Select
                                                                                    className="react-select react-select-primary"
                                                                                    name="documentbookmark"
                                                                                    value={{
                                                                                        value:bookmark.document_reference_seq?bookmark.document_reference_seq:'',
                                                                                        label:bookmark.document_bookmark_name?bookmark.document_bookmark_name:'사용안함',
                                                                                    }}
                                                                                    onChange={(value)=>fncOnchangeRelation(value?value:null, 'DOCUMENT')}
                                                                                    options={documentList}
                                                                                    placeholder="사용안함"
                                                                                    isClearable={bookmark.document_reference_seq?true:false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>TRANSPORT
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Select
                                                                                    className="react-select react-select-primary"
                                                                                    name="transportbookmark"
                                                                                    value={{
                                                                                        value:bookmark.transport_reference_seq?bookmark.transport_reference_seq:'',
                                                                                        label:bookmark.transport_bookmark_name?bookmark.transport_bookmark_name:'사용안함',
                                                                                    }}
                                                                                    onChange={(value)=>fncOnchangeRelation(value?value:null, 'TRANSPORT')}
                                                                                    options={transportList}
                                                                                    placeholder="사용안함"
                                                                                    isClearable={bookmark.transport_reference_seq?true:false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>CARGO
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Select
                                                                                    className="react-select react-select-primary"
                                                                                    name="cargobookmark"
                                                                                    value={{
                                                                                        value:bookmark.cargo_reference_seq?bookmark.cargo_reference_seq:'',
                                                                                        label:bookmark.cargo_bookmark_name?bookmark.cargo_bookmark_name:'사용안함',
                                                                                    }}
                                                                                    onChange={(value)=>fncOnchangeRelation(value?value:null, 'CARGO')}
                                                                                    options={cargoBookmarkList}
                                                                                    placeholder="사용안함"
                                                                                    isClearable={bookmark.cargo_reference_seq?true:false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="pb-2">
                                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>CONTAINER
                                                                    </Col>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col className="col-10 pr-0">
                                                                                <Select
                                                                                    className="react-select react-select-primary"
                                                                                    name="containerbookmark"
                                                                                    value={{
                                                                                        value:bookmark.container_reference_seq?bookmark.container_reference_seq:'',
                                                                                        label:bookmark.container_bookmark_name?bookmark.container_bookmark_name:'사용안함',
                                                                                    }}
                                                                                    onChange={(value)=>fncOnchangeRelation(value?value:null, 'CONTAINER')}
                                                                                    options={containerBookmarkList}
                                                                                    placeholder="사용안함"
                                                                                    isClearable={bookmark.container_reference_seq?true:false}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="2">
                                        <Card style={{zIndex:'70'}} className="card-raised card-form-horizontal no-transition mb-0">
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <h6>현재 부킹을 Bookmark로 등록합니다.</h6>
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop: '20px'}}>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label className="mb-0">전체 Bookmark Name</Label>
                                                            <Input
                                                                type="text" name="new_bookmark_name" id="new_bookmark_name"
                                                                placeholder=""
                                                                maxLength="35"
                                                                value={newBookmarkName?newBookmarkName:''}
                                                                onChange={(e)=>setNewBookmarkName(e.target.value)}
                                                                invalid={newBookmarkName?false:true}
                                                            />
                                                            <FormFeedback>{validation.REQ_MSG}</FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop: '10px'}}>
                                                    <Col>
                                                        <FormGroup className="mt-3" check>
                                                            <Label check>
                                                                <Input type="checkbox"
                                                                    disabled={true}
                                                                    checked={bookingStatus}
                                                                /><span className="form-check-sign" />
                                                                <span>부킹 승인 여부(필수)</span>
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop: '10px'}}>
                                                    <Col>
                                                        <FormGroup className="mt-3" check>
                                                            <Label check>
                                                                <Input type="checkbox"
                                                                    disabled={true}
                                                                    checked={bookingStatus}
                                                                /><span className="form-check-sign" />
                                                                <span>승인된 부킹 정보로 저장됩니다.</span><br/>
                                                                <span>단, Bookmark 사용시엔 해당 Bookmark를 적용합니다.</span>
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop: '10px'}}>
                                                    {bookingExistsNo?<Col>
                                                        <ReactAlert color="danger"><b>이미 해당 부킹번호로 등록되어 있습니다.</b></ReactAlert>
                                                    </Col>:<></>}
                                                </Row>
                                                <Row style={{paddingTop: '10px'}}>
                                                    {booking.bookmark_bkg_no?<Col>
                                                        <ReactAlert color="danger"><b>{booking.bookmark_bkg_no} 해당 부킹으로 이미 적용되어 있습니다.</b></ReactAlert>
                                                    </Col>:<></>}
                                                </Row>
                                            </CardBody>
                                            <CardFooter>
                                                <Row style={{textAlign: 'right'}}>
                                                    <Col>
                                                        <Button onClick={()=>fncSaveBookmark("Y")}>생성하기</Button>
                                                    </Col>
                                                </Row>
                                            </CardFooter>
                                        </Card>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e)=>fncInitBookmark(e)}>New</Button>{' '}
                    <Button color="primary" onClick={(e)=>fncSaveBookmark("N")}>Save</Button>{' '}
                    <Button color="primary" onClick={(e)=>deleteBookmark(e)}>Delete</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}