import React, { useState, useEffect, } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,Col,
    Button,Container} from "reactstrap";
import axios from "axios";
import Select from "react-select";
// import ScheduleBookmark from "./ScheduleBookmark.js";
// Calendar
import {Calendar,momentLocalizer} from 'react-big-calendar';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/sass/styles.scss';
import Moment from 'moment';
import * as validation from 'components/common/validation.js';
const selectStyle = {
    container:base =>({
        ...base,
        marginTop:'5px'
    }),
    control: base => ({
      ...base,
      fontFamily:'Nanum Gothic',
      fontSize:'0.8rem',
      color:'rgb(68, 68, 68)'
  
    }),
    menu: base => ({
      ...base,
      fontFamly: "Nanum Gothic",
      fontSize:'0.8rem',
      color:'rgb(68, 68, 68)'
    })
}
const ScheduleCalendarModal = ((props) => {
    const localizer = momentLocalizer(Moment);
    const {lineCode,open,toggle
        ,selectedEventCalendar,scheduleMode
        ,scheduleFerryCargo } = props;
    
    const [carrierList, setCarrierList] = useState([]);
    const [startPortList, setStartPortList] = useState([]);
    const [endPortList, setEndPortList] = useState([]);
    const [startPort,setStartPort] = useState("");
    const [endPort,setEndPort] = useState("");
    
    const [carrierValue, setCarrierValue] = useState(lineCode||'');
    const [calendarData,setCalendarData] = useState([]);
    const [calDate,setCalDate] = React.useState(new Date());
    useEffect(() => {
        // if(props.loadData){
        //     let sp = props.loadData&&props.loadData.sch_pol||'';
        //     let ep = props.loadData&&props.loadData.sch_pod||'';
        //     setStartPort(sp);
        //     setEndPort(ep);
        //     onSubmit( sp, ep);
        // }else{
        //     onSubmit(startPort, endPort);
        // }
        selectLineServiceRoute();
    }, []);

    useEffect(()=> {
        setCarrierValue(lineCode||null);
        if(lineCode) {
            getLinePortOptionList(lineCode);
        }
    },[lineCode]);

    useEffect(()=> {
        onSubmit(startPort, endPort);
    },[carrierValue]);
    
    const getLinePortOptionList =(val)=>{
        axios.post('/api/selectLinePort',{params:{line_code:val,key:'out'}}).then(res=> {
            setStartPortList(res.data); 
        })
        axios.post('/api/selectLinePort',{params:{line_code:val,key:'in'}}).then(res=> {
            setEndPortList(res.data);  
        })   
    }
    // Line 목록조회
    const selectLineServiceRoute = (params) => {
        axios.post(
            "/sch/selectLineServiceRoute" ,{},
        ).then(res=>{
            setCarrierList(res.data);
        }).catch(err => {
        });
    }
    
    const fncCacelModal =()=>{
        toggle();
    }
    
    const onSubmit = ( sp, ep) => { 
        var vDate = calDate;
        vDate.setDate('01');
        calendarSql(vDate, sp, ep);
    }

    const calendarSql = (etd, sp, ep) =>{
        axios.post("/api/getLineSchCal",{ 
            startport:  sp,
            endport:  ep 
            ,eta:Moment(etd).format('YYYYMMDD')
            ,week:'5 week', line_code: carrierValue}).then(res => {
            if(res.data&& res.data.length > 0) {
                setCalendarData(res.data);
            } else { 
                setCalendarData([]);
                props.onAlert("error","조회된 (스케줄) 정보가 없습니다.");
            }
        });
    };
    const CustomToolbar =(toolbar) => {
        const goToBack = () => {       
            let mDate = toolbar.date;
            let newDate;     
            newDate = new Date(mDate.getFullYear(),mDate.getMonth()-1,1)
            toolbar.onNavigate('Back', newDate);
            setCalDate(newDate);
            calendarSql(newDate, startPort, endPort);
        }
        const goToNext=()=> {
            let mDate = toolbar.date;
            let newDate; 
            newDate = new Date(mDate.getFullYear(),mDate.getMonth()+1,1);
            toolbar.onNavigate('Next',newDate);
            setCalDate(newDate);
            calendarSql(newDate, startPort, endPort);
        }
        const goToCurrent=()=> {
            const now = new Date();
                toolbar.date.setYear(now.getFullYear());
                toolbar.date.setMonth(now.getMonth());
                toolbar.onNavigate('Today',toolbar.date);
                calendarSql(now, startPort, endPort);
                setCalDate(toolbar.date);
        }
        
        const label =()=>{
            const date = Moment(toolbar.date);
            return (
              <h2><b>{date.format('YYYY')+" / "+date.format('MM')}</b></h2>	  
            );
        };
        
        return (
            <div style={{width:'87%',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',marginBottom:'10px',fontSize:'16px'}}>	
                <div>
                    <Button style={{border:'solid 1px #DDD'}} color="default" outline size="sm" type="button" className="mr-1" onClick={goToCurrent}>Today</Button>
                    <Button style={{border:'solid 1px #DDD'}} color="default" outline size="sm" type="button" className="mr-1" onClick={goToBack}>Back</Button>
                    <Button style={{border:'solid 1px #DDD'}} color="default" outline size="sm" type="button" className="mr-1" onClick={goToNext}>Next</Button>
                </div>
                <label style={{textAlign:'center',padding:'0 10px',flexGrow:'1'}}>{label()}</label>
            </div>		  		
        );
        
    }

    const onHandleCarrierSelect = (op)=>{
        let val = op? op.value : null;
        setCarrierValue(val);
        setCalendarData([]);
        setStartPort('');
        setEndPort('');
        if(val) {
            getLinePortOptionList(op&&op.value||null);
        }else{
            setStartPortList([]);
            setEndPortList([]);
        }
    }

return (
    <Modal isOpen={open} toggle={toggle} className="pt-0" size="xl">
        <ModalHeader>{scheduleMode=='NEW'?'신규 스케줄 선택':'SCHEDULE'}</ModalHeader>
        <ModalBody>
            <Row>
                <Col className="col-12 mb-2" style={{zIndex:'10'}}>
                            <Row>
                                <Col>
                                    <Row>
                                        {!props.site&&
                                            <Col md="4" sm="12" xs="12">
                                            <span >선사</span>
                                                <Select
                                                    className="customSelect"
                                                    placeholder="선사 선택"
                                                    options={carrierList}
                                                    onChange={(op)=>{
                                                        onHandleCarrierSelect(op)
                                                    }}
                                                    value={carrierList.length>0?carrierList.find(x=>x.value==carrierValue):null}
                                                    name="startPort"
                                                    styles={selectStyle}
                                                    isClearable={carrierValue?true:false}
                                                />
                                        </Col>
                                        }
                                        <Col md={!props.site?'4':"6"} sm="12" xs="12">
                                            <span >출발지</span>
                                                <Select
                                                    className="customSelect"
                                                    placeholder="출발지 선택"
                                                    options={startPortList}
                                                    onChange={(e)=>{//setStartPortTemp(e)
                                                        let val =e?e.value : '';
                                                        setStartPort(val);
                                                        onSubmit(val);
                                                    }}
                                                    value={startPortList.length>0&&startPort?
                                                        startPortList.find(x=>x.value == startPort)
                                                        :null}
                                                    // value={startPortTemp}
                                                    name="startPort"
                                                    styles={selectStyle}
                                                    isClearable={startPort?true:false}
                                                />
                                        </Col>
                                        <Col md={!props.site?'4':"6"} sm="12" xs="12">
                                            <span>도착지</span>
                                            <Select
                                                className="customSelect"
                                                placeholder="도착지 선택"
                                                options={endPortList}
                                                onChange={(e)=>{//setEndPortTemp(e)
                                                    let val =e?e.value : '';
                                                    setEndPort(val);
                                                    onSubmit(null, val);
                                                }}
                                                value={endPortList.length>0&&endPort?
                                                    endPortList.find(x=>x.value == endPort)
                                                    :null}
                                                // value={endPortTemp}
                                                name="endPort"
                                                styles={selectStyle}
                                                isClearable={endPort?true:false}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                    <Button  className ="ml-auto" style={{alignSelf:'flex-end'}} color="primary" onClick={()=> onSubmit(startPort, endPort)} >검색</Button>
                            </Row>
                </Col>
                <Col className="col-12 mb-2 mt-2 p-0">
                        <Container>
                        <Calendar
                            selectable
                            popup
                            localizer={localizer}
                            events={calendarData}
                            startAccessor="start"
                            endAccessor="end"
                            // style={{ height: 400 }}
                            showAllEvents={true}
                            defaultDate={new Date()}
                            views={["month"]}
                            onSelectEvent={(event,e) => selectedEventCalendar(event,e)}
                            components={{toolbar:CustomToolbar}}
                            eventPropGetter={(event)=>{
                                if((scheduleFerryCargo!==null)&&scheduleFerryCargo==='F'){
                                    if(event && event.vsl_type2 === '41') {
                                        return {className:"bg-warning",style:{fontSize:'0.85em'}}
                                    } else {
                                        return {className:"bg-info",style:{fontSize:'0.85em'}}
                                    }
                                }else{
                                    return {style:{fontSize:'0.85em',backgroundColor:event.sch_color?event.sch_color:'#000000'}}
                                }   
                            }}
                        /> </Container>
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={fncCacelModal}>Cancel</Button>
        </ModalFooter>
    </Modal>
    )
});
export default ScheduleCalendarModal;