import React,{ useState, useEffect, useRef } from "react";
import {Row, Col, InputGroup,Label,Input, Card, CardSubtitle, CardBody, CardFooter, Button, UncontrolledTooltip, InputGroupAddon, InputGroupText} from "reactstrap";
import Select from 'react-select'
import Moment from 'moment';
import axios from "axios";
import { Link } from "react-router-dom";
import * as validation from 'muiComponents/common/validation.js';

export default function GridRow(props) {
    const [value,setValue] = useState(props.value);
    const [open,setOpen] = useState(false);
    const [index,setIndex] = useState(props.index);
    const [data,setData] = useState([]);
    useEffect(() => {
      setValue(props.value);
      setIndex(props.index);
  
      
  
      return function cleanup() {
        // setValue(null);
        // setOpen(false);
      };
    },[open]);
  
    useEffect(()=> {
        setValue(props.value);
    },[props]);
    
    return(
        <Row className="pb-2 pt-2" style={{cursor:'pointer'}} onClick={() => setOpen(!open)}>
            <Col className=" text-center pt-3" xl="1" lg="1" md="1" sm="1" xs="1">{Number(index)+1}</Col>
            <Col className=" text-center pt-3" xl="4" lg="4" md="4" sm="4" xs="4">{value.user_no}</Col>
            <Col className=" text-center pt-3" xl="4" lg="4" md="4" sm="4" xs="4">{value.bkg_date}</Col> 
            <Col className=" text-center pt-3" xl="3" lg="3" md="3" sm="3" xs="3">{value.bkg_no}</Col>
        </Row>
    )
  }