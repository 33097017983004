import React,{useState,useEffect} from "react";
import axios from 'axios';
import { makeStyles } from "@material-ui/core/styles";
import Moment from 'moment';
import { Col, FormGroup,Label,Input} from "reactstrap";
import {Table,TableHead,TableRow,TableBody,TableCell,TableFooter,Popover
    ,TextField,Collapse,FormControl,TableContainer
	, CircularProgress,Snackbar,
} from "@material-ui/core";

import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import CardBody from "muiComponents/Card/CardBody.js";
// import CalendarBox from "muiComponents/CustomInput/CustomCalendar.js";
import {CustomDatePickerInput} from 'components/CustomInput/reactstrap/CustomInput';
import * as validation from 'components/common/validation.js'
import Button from "muiComponents/CustomButtons/Button.js";

import {
    Search as SearchIcon,
    Clear as ClearIcon 
  } from "@material-ui/icons";
  import {
	Autocomplete as Autocomplete,
	Alert
} from '@material-ui/lab';

import RowTable from './CntrTrackingTable'
// import RowTable from './InlandDriveDetail.js'
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const styles2 = {
    header: {
    //   padding: '15px 10px !important',
      borderTop:'2px solid #00b1b7', 
      borderBottom:'2px solid #00b1b7',
      color:'#717172',
      fontWeight:'bold',
      textAlign: 'center',
      paddingTop:'6px'
      ,paddingBottom:'6px'
      ,backgroundColor: "#f2fefd"
    //   ,borderRight:'1px solid silver'
    },
    grid: {
      padding: '0px 10px !important',
      textAlign: 'center',
      alignSelf: 'center'
    },
     gridLabel: {
              textAlign:'left',
              marginTop:'12px',
            },
  };
const useStyles = makeStyles(styles);
const useStyles2 = makeStyles(styles2);


export default function InlandDriveList(props) {
const classes = useStyles();
const classes2 = useStyles2();
const {alertMessage} = props;

const [totPage,setTotPage] = useState(0);
const [numCnt,setNumCnt] = useState(1);
const [tableData,setTableData] = useState([]);
const [loading, setLoading] = useState(false);
const [loading2, setLoading2] = useState(false);

const [cntrNo, setCntrNo] = useState('');
const [startDate, setStartDate] = useState(Moment().set('y',2000));
const [endDate, setEndDate] = useState(Moment(new Date()));

// const [inputs, setInputs] = useState({
// 	cntrNo:''
// 	,startDate: Moment().subtract(2,'years')
// 	,endDate: Moment(new Date())
// });
// const {cntrNo,startDate,endDate} = inputs

// const onChange =(value,id)=> {
// 	setInputs({
// 		...inputs,
// 		[id]:value
// 	});
// }

const onReset =()=>{
	setCntrNo('')
	setStartDate(Moment().subtract(2,'years'))
	setEndDate(Moment(new Date()))
	// setInputs({
	// 	cntrNo:''
	// 	,startDate:Moment().subtract(2,'years')
	//     ,endDate:Moment(new Date())
	// })
}

const onSubmit = async(numCnt) => {
	// console.log(startDate, endDate)
	numCnt==1?setLoading(true):setLoading2(true)

    if(!cntrNo) {
        alertMessage('컨테이너번호는 필수항목입니다','error'); 
		setLoading(false);setLoading2(false); return false;
    }
    if(!startDate||!endDate){alertMessage('조회일자 필수항목입니다','error');   	
	setLoading(false);setLoading2(false); return false;}
    if(!Moment(startDate,'YYYYMMDD').isValid()||!Moment(endDate,'YYYYMMDD').isValid() 
		||startDate>endDate) {
			console.log(Moment(startDate,'YYYYMMDD').isValid(),Moment(endDate,'YYYYMMDD').isValid(),startDate<endDate)
        alertMessage(validation.DATE_MSG,'error');    
		setLoading(false);setLoading2(false); return false;
    }
	
	// console.log(inputs)
	await axios.post("/loc/getCntrTrackingList",{
		// ...inputs
		cntrNo:cntrNo
		,startDate:Moment(startDate).startOf('day').format('YYYYMMDDHHmmss')
		,endDate:Moment(endDate).endOf('day').format('YYYYMMDDHHmmss')
		,page_index:numCnt
		,page_size:10
	})
	.then(async(res) => {
			if(res.status === 200&&!res.data.msg&&res.data.length>0) {
				numCnt==1?setTableData(res.data):setTableData([...tableData,...res.data])
				setTotPage(res.data[0].TOT_PAGE)
			} else {
				console.log(res)
				setTableData([]);
				setTotPage(0);
				alertMessage('조회된 데이터가 존재 하지 않습니다.','error');
				// alertMessage(res.data.msg,'error');	
			}
	})
	.catch(err => {
		// if(err.response !== undefined ) {
		// 	if(err.response.status === 403||err.response.status === 401) {
		// 	props.openLogin();
		// 	}
		// } else {
			console.log("error ", err);
			setTableData([]);
			setTotPage(0);
			alertMessage('조회 중 오류가 발생했습니다. 다시 시도해주세요.','error');
		// }
		
	});
	
  	setLoading(false)
	setLoading2(false)
}

// function AlertComponent(props) {
// 	return <Alert elevation={6} variant="filled" {...props} />;
// }

// const alertMessage = (message,icon) => {
// 	// console.log(message)
// 	setErrmessage(message)
// 	setSeverity(icon)
// 	setAlertOpen(true);
// }


// const handleAlertClose = (event, reason) => {
//     if(reason ==='clickaway') {
//       return;
//     }
//     setAlertOpen(false);
//   }

const handleSearchButton = () => {
    if(props.userData){
        onSubmit(1)
        setNumCnt(1)
    }else {
        props.openLogin();
    }
}  

const handleMoreButton = (numCnt) => {
	++numCnt;
	onSubmit(numCnt)
	setNumCnt(numCnt)
}  

return(
    // <div style={{paddingLeft:'3%',paddingRight:'3%'}}>
	<GridItem lg={12} md={12} sm={12} xs={12} style={{marginTop:'20px', marginBottom:'10px'}}>
		<GridContainer >
			<Col sm='3' className='search_option--number'>
				<FormGroup row className='search_option--number--formGroup' >
					<Col className='search_option--number--text col-4'>
						컨테이너번호
					</Col>
					<Col className='search_option--number--input col-8'>
						<TextField fullWidth id="cntrNo" // label="컨테이너번호"
							onChange={(e)=>setCntrNo(e.target.value.toUpperCase().trim())}
							value={cntrNo}/>
					</Col>
				</FormGroup> 
			</Col> 
			<Col   className='search_option--calendar'>
				<FormGroup row>
					<Label className='search_option--calendar--text'>조회일자</Label>
					<Col>
						<CustomDatePickerInput
						id="fromDate"
						dateFormat="YYYY-MM-DD"
						timeFormat={false}
						value={startDate}
						onChange={data =>setStartDate(data)}
						onAlert ={props.onAlert}
						/>
					</Col>
					<div className='search_option--calendar--text'>~</div>
					<Col>
						<CustomDatePickerInput
						id="toDate"
						dateFormat="YYYY-MM-DD"
						timeFormat={false}
						value={endDate}
						onChange={data =>setEndDate(data)}
						onAlert ={props.onAlert}/>
					</Col>
				</FormGroup>
			</Col>
			<Col sm='3' className='search_option--number'>
				<Button color="info" style={{width:'50%'}} endIcon={<SearchIcon/>}  onClick = {handleSearchButton}>조회</Button>
				<Button color="info" style={{width:'50%'}} endIcon={<ClearIcon/>} onClick={onReset}>초기화</Button> 
			</Col>
		</GridContainer>
        {/* <GridContainer justifycontent="space-between" style={{marginBottom:'10px'}}>
					<GridItem xs={12} md={9}>
						<GridContainer>	
							<GridItem xs={4} md={4}>
								<TextField fullWidth id="cntrNo" label="컨테이너번호"
								onChange={(e)=>onChange(e.target.value.toUpperCase().trim(), e.target.id)}
								value={cntrNo}/>
							</GridItem>
                            <GridItem xs={4} md={4}>
							<FormControl fullWidth style={{marginBottom:'10px'}}>
								<CalendarBox
								labelText ="시작일"
								id="fromDate"
								variant="inline"
								format="yyyy-MM-dd"
								// setValue={e =>console.log(e.target.value)}
								setValue={startDate}
								autoOk={true}
								// onChangeValue={date =>setOutgateTime(date)}
								onChangeValue={data =>onChange(data, 'startDate')}
								formControlProps={{fullWidth: true}} 
								/>
							</FormControl>
						</GridItem>
						<GridItem xs={4} md={4}>
							<FormControl fullWidth style={{marginBottom:'10px'}}>
								<CalendarBox
								labelText ="도착일"
								id="toDate"
								variant="inline"
								format="yyyy-MM-dd"
								setValue={endDate}
								autoOk={true}
								onChangeValue={data =>onChange(data, 'endDate')}
								formControlProps={{fullWidth: true}} 
								/>
							</FormControl>
						</GridItem>		
						</GridContainer>
					</GridItem>
					<GridItem style={{textAlign:'end', display:'flex'}}  xs={12} md={3}>
						<Button color="info" style={{width:'50%'}} endIcon={<SearchIcon/>}  onClick = {handleSearchButton}>조회</Button>
						<Button color="info" style={{width:'50%'}} endIcon={<ClearIcon/>} onClick={onReset}>초기화</Button> 
					</GridItem>
		</GridContainer> */}
		<GridContainer   justifycontent="center">
			<GridItem xs={12} sm={12} md={12} style={{marginBottom: '25px'}} >
				<div style = {{display:'flex',justifyContent:'space-between',marginBottom:'10px'}}>
				<h4 className={classes.cardIconTitle}>Result List</h4>	            
				<span style={{color:"#000000" ,marginTop:'15px'}}>[ Data Count: {tableData.length}건 / {tableData.length!==0?tableData[0].TOTAL:0}건 ]</span>
				</div>
					<TableContainer className={classes.tablecontainer}>
						<Table  style={{ borderBottom:'2px solid #00b1b7' }}>
							<TableHead color='info' //style={{borderTop:'2px solid #00b1b7',}}
                             >
								<TableRow //className={classes.tableRowHead}
                                >
								{/* <TableRow className={classes.tableRow + " " + classes.tableRowHead}> */}
										{/* <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',backgroundColor: "#f2fefd",borderRight:'1px solid silver',borderLeft:'1px solid silver'}}>수출입</TableCell> */}
										<TableCell align='center' className={classes2.header}>수출입</TableCell>
										<TableCell align='center' className={classes2.header}>구분</TableCell>
										<TableCell align='center' className={classes2.header}>터미널</TableCell>
										<TableCell align='center' className={classes2.header}>MOVE TIME</TableCell>
										<TableCell align='center' className={classes2.header}>모선</TableCell>
										<TableCell align='center' className={classes2.header}>항차</TableCell>
										<TableCell align='center' className={classes2.header}>선사</TableCell>
										<TableCell align='center' className={classes2.header}>적공</TableCell>
										<TableCell align='center' className={classes2.header}>SIZE</TableCell>
										<TableCell align='center' className={classes2.header}>POD</TableCell>
										<TableCell align='center' className={classes2.header}>POL</TableCell>
										<TableCell align='center' className={classes2.header}>차량번호</TableCell>
								</TableRow>
								
							</TableHead>
							<TableBody  //style={{borderTop:'2px solid #00b1b7',}}
                            >
								{loading ? (<TableRow><TableCell colSpan={15} style={{textAlignLast:'center'}}><CircularProgress /></TableCell></TableRow>):
									tableData.length>0?
										tableData.map((row, key) => { 
										return (
											<RowTable key={key} index={key + 1} 
											rowData={row}
											alertMessage={alertMessage}
											{...props}
											/>
										);
										})
									:
									<TableRow >
										<TableCell colSpan="15" align="center" style={{fontSize:"10px"}}>NO DATA</TableCell>	
									</TableRow>
								}
							</TableBody>
							{(tableData.length >= 10  &&numCnt!==totPage?
							<TableFooter >
								<TableRow  >
									<TableCell style={{textAlignLast:'center',paddingTop:'0',paddingBottom:'0'}} colSpan={15}>
										<Button
											color="info"
											onClick={()=>handleMoreButton(numCnt)}
											style={{paddingLeft:'60px',paddingRight:'60px'}}
											disabled={loading2}
											endIcon={loading2 && <CircularProgress size={24} />}
										>
										{!loading2? `MORE (${numCnt}/${totPage})` : ''}
										</Button>
										&nbsp;&nbsp;&nbsp;			
									</TableCell>
								</TableRow>
							</TableFooter>: null )}
						</Table>
					</TableContainer>
			</GridItem>
			{/* <Snackbar open={alertOpen} autoHideDuration={2500} onClose={handleAlertClose}>
				<AlertComponent 
					onClose={handleAlertClose}
					severity={severity}>
						{errMessage}
				</AlertComponent>
			</Snackbar> */}
		</GridContainer>
    {/* </div> */}
	</GridItem>
)}
