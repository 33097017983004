import React, {useState,  forwardRef, useImperativeHandle} from 'react'
// import { useNavigate } from "react-router-dom";
// import { useSelector } from 'react-redux';
// import {selectMode} from '@/redux/reducers/global'
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
// css
import Style from './footer.module.css'
// import {useLocation} from "react-router-dom/index";
import { Modal, Backdrop, Fade } from "@material-ui/core";
import { SettingsInputComponent } from '@material-ui/icons';
import { Component } from 'devextreme-react/core/component';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// assets image
//import Logo from '@/assets/image/etc/etc-flogo.png'
import Terms from 'muiComponents/Footer/TermsOfService.js';
import Privacy from 'muiComponents/Footer/PrivacyPolicy.js';
import Email from 'muiComponents/Footer/EmailPolicy.js';

const useStyles = makeStyles((theme)=>({
    modal:{
        display:'flex'
        ,alignItems:'center'
        ,justifyContent:'center'
    }
}))
const Footer = forwardRef((props,ref)=> {
    const [ modalOpen,setModalOpen ]=useState( false);
    const [ modalComponent,setModalComponent ]=useState('');
    const classes = useStyles();
//   const navigate = useNavigate(selectMode)
//   const globalMode = useSelector(selectMode);

//   const locationNow = useLocation();
  //console.log('locationNow', locationNow)
//   if (locationNow.pathname === '/manual') return null;

useImperativeHandle(ref,()=>({
    handleClickOpen
 }));


const handleClickOpen = (itemName) => {
    setModalOpen(true);  
    switch(itemName) {
        case 'terms': setModalComponent( <Terms handleClose={handleClose}/>);break;
        case 'privacy':setModalComponent( <Privacy handleClose={handleClose} />);break;
        case 'email':setModalComponent( <Email handleClose={handleClose} />);break;
        case 'about':setModalComponent(<div>준비중<br/></div>);break;
        case 'carrierBoard':setModalComponent(<div>준비중<br/></div>);break;
        case 'faq':setModalComponent(<div>준비중<br/></div>);break;
        default: return ''; 
    }
    // itemName=== "T"?<Terms handleClose={handleClose}/>
         
    // serviceText ==="B"&&(<Baltic handleClose={handleClose} param={props} token={props.token} userData={props.userData}/>)
    //      serviceText === "S"&&<Scfi handleClose={handleClose} param={props} token={props.token} userData={props.userData}/>
    //      serviceText === "U"&&<TeuRank handleClose={handleClose} param={props} token={props.token} userData={props.userData}/>
  
}
const handleClose = () => {
    setModalOpen(false);
    setModalComponent(<></>)
}
return (
    <>
        <div className={Style.footer}>
            <div className={Style.fnbItemWrap}>
                <ul>
                    <li><div className={Style.anchor} onClick={()=>handleClickOpen('terms')}>이용약관</div></li>
                    <li><div className={Style.anchor} onClick={()=>handleClickOpen('privacy')} style={{color:'blue',fontWeight:'600'}}>개인정보처리방침</div></li>
                    <li><div className={Style.anchor} onClick={()=>handleClickOpen('email')}>이메일무단수집거부</div></li>
                    {/* <li><div className={Style.anchor} onClick={()=>handleClickOpen('about')}>About US</div></li> */}
                    <li><Link to='/svc/plismboardlist' className={Style.anchor} >공지사항</Link></li>
                    <li><Link to='/svc/board' className={Style.anchor} >업무공지</Link></li>
                    <li><Link to='/svc/qna' className={Style.anchor} >Q&A</Link></li>
                    <li><Link to='/svc/faq' className={Style.anchor}>FAQ</Link></li>
                    {/* <li><a href="https://eservice.logisview.com/uat/uia/eserviceSub_nologin.do?menu_no=32438&link=Y'" className={Style.anchor} target="_blank">선사게시판</a></li> */}
                    <li><Link to='/svc/shipperboardlist' className={Style.anchor} >선사게시판</Link></li>
                    <li><a href="https://cyberdesk.klnet.co.kr"  className={Style.anchor} target="_blank">고객지원센터(1577-1172)</a></li>
                </ul>
            </div>
            <div className={Style.rela}>
                <div className={Style.flogo}><img src={process.env.PUBLIC_URL  + `/assetsNew/image/etc/etc-flogo.png`} /></div>
                <div className={Style.fcont}>
                    <div className={Style.addr}>고객지원센터 1577 1172 (06264) 서울시 강남구 역삼로 153 케이엘넷 빌딩
                        <br/>본 사이트는 1280*720 해상도에 최적화 되었으며 마이크로소프트 엣지, 크롬 최신 브라우저 사용을 권장합니다.
                        {/* <br/>본 사이트는 1280*720 해상도에 최적화 되었으며 익스플로러 10.0+, 크롬 최신 브라우저 사용을 권장합니다 . 익스플로러 버전 10 이상부터 지원합니다 */}
                    </div>
                    <div className={Style.copy}>Copyright © 2022 KL Net.,Ltd. All Rights Reserved.</div>
                </div>
            </div>
        </div> 
        <Dialog
         className={classes.modal}
  			open={modalOpen}
  		    onClose={handleClose}
              maxWidth='sm'
  		>
  		    <DialogContent>
                {modalComponent}
            </DialogContent>
        </Dialog>
    </>
  )
})

export default Footer;
