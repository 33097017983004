import React , { useState , useEffect }from 'react'
import cn from 'classnames';
import { Link, useHistory} from "react-router-dom";
import { createTheme, makeStyles ,withStyles} from '@material-ui/core/styles';
import {Close, AttachFile, LocalDiningOutlined} from '@material-ui/icons';
import {getDate, add, format, differenceInSeconds, isValid, parse} from 'date-fns';
import axios from 'axios';
import {
    TextField,
    Table,
	TableBody,
	TableCell,
	TableRow,  FormControlLabel, Radio
    } from '@material-ui/core';
// core components
import GridContainer from "muiComponents/Grid/GridContainer.js";
import Button from "muiComponents/CustomButtons/Button.js";
import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import Style from './VerifyApplication.module.css'
import {userStore} from 'store/userStore.js';
import {setCookie, getCookie, removeCookie} from 'muiComponents/common/cookie.js';

const SERVICE=process.env.REACT_APP_SERVICE;

// css
const LogisviewCheckbox = withStyles({
    root:{
      color:'#5fb0b5',
      '&$checked': {
        color: '#5fb0b5',
      },
      padding:'2px'
    }
  })((props)=><Checkbox color = 'default' {...props}/>)

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background:'#ecf2f2'
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    stepIcon:{color:'yellow',
            '&$active':{color:'pupple'},
            '&$completed':{color:'red'},
        
    }
}));

  
 
const VerifyAdmin = (props) => {
    const {toggle,onAlert,userData,lockKlnetId}=props;
    const history = useHistory();
    const classes = useStyles();
    const [certifyPhone,setCertifyPhone] = useState('');
    const [certifyEmail,setCertifyEmail] = useState('');
    const [certifyNo,setCertifyNo] = useState('');
    const [cerifyType,setCerifyType] = useState('email');
    const [certifyInfo,setCertifyInfo] = useState({});
    

    useEffect(() => {
        if(userStore.isAuth?.result){
            let info = getCookie('ctfyRes');
            onHandleCertify(info)
        }
    }, [userStore.isAuth]);
    
    const onHandleCertify = (info) =>{
        setCertifyInfo(info);
        if(SERVICE=='PROD')setCertifyPhone(info?.userInfo?.phone ||'');
        setCertifyEmail(info?.userInfo?.email ||'');
        setCerifyType(info?.certify_type||'email');
    }

    const onSubmitCertifyNo = ()=>{
        if(!certifyNo){
            onAlert('error','인증번호를 입력하세요.');
            return;
        }
        
        if( !getCookie('ctfyRes')){
            onAlert('error','인증 번호가 만료되었습니다 재전송 요청바랍니다.');
            return;
        }
        if(getCookie('ctfyRes')?.certify_no !==certifyNo){
            onAlert('error','인증 번호가 일치하지 않습니다.');
            return;
        }
        if(!certifyInfo?.userInfo?.user_id){
            onAlert('error','인증 아이디를 확인할 수 없습니다. 재로그인하세요.');
            return;
        }
        removeCookie('ctfyRes');
        setCookie('ctfyUser', certifyInfo?.userInfo?.user_id, {path:'/'});
        window.location.reload();
    }

    const resendCertifyNo = ()=>{
        axios.post(`/api/sendCertifyNo`,{
            user_id : certifyInfo?.userInfo?.user_id,
            certifyType : cerifyType,
            userPhone : certifyPhone,
            userEmail : certifyEmail,
        })
        .then(res => { 
            if(res.data?.message){
                removeCookie('ctfyRes');
                setCookie('ctfyRes',res.data?.message, {maxAge:600, path:'/'})
                onAlert('success','재전송되었습니다.');
            }
        }).catch(err => {
            console.log(err)
        });    
    }

    return (<>
        <GridContainer justifyContent="center">	
          <Card className={classes.cardSignup} style={{margin:'0',paddingTop:'0',paddingBottom:'0'}}>
			<CardBody style={{paddingTop:'15px',paddingBottom:'15px'}}>       
                <div style={{display: 'flex',	flexDirection: 'column',justifyContent: 'center'}}>		
                    <div className={cn(Style.wrapper, Style.ptype)}>
                        <div className={Style.popHeader}>
                            <div className={Style.ptit}>
                                <strong>관리자 이중 인증</strong>
                            </div>
                            <div style={{display:'flex', cursor:'pointer'}} >
                                <div className={Style.plogo}><img src={process.env.PUBLIC_URL  + `/assetsNew/image/etc/etc-logo.png`}/></div>
                                <Close onClick={() =>{ props.toggle(); }} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={Style.popCont}>
                    <div className={cn(Style.rtwrap,Style.li)}>
                        <dl>
                            <dt>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-tit.png`}/>
                                <strong>관리자는 사용자 개인정보 보호를 위해 본인인증이 필요합니다.</strong>
                            </dt>
                            <dd>
                                <Table style={{maxWidth:'100%',tableLayout:'fixed',borderTop:'2px solid #06b3ba',borderBottom:'2px solid #06b3ba', margin:'20px 0'}}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={4} style={{backgroundColor:'#f2fefd', fontWeight:'bold'}}>
                                                {/* <FormControlLabel value='phone' onClicrak={(e)=>{setCerifyType("phone")}}
                                                control = {<Radio color='primary'/>}  Label='휴대폰 번호'/> */}
                                                <Radio
                                                    checked={cerifyType==='phone'}
                                                    onChange={()=>setCerifyType("phone")}
                                                    value='phone'
                                                    color='default'
                                                    name='certifyPh'
                                                    disabled={SERVICE!=='PROD'}
                                                ></Radio>  휴대폰 번호 
                                            </TableCell>
                                            <TableCell colSpan={5} >{certifyPhone}
                                            </TableCell> 
                                            <TableCell colSpan={3} padding ={0}>
                                                {cerifyType==='phone'&&
                                                <Button color='success' //style = {{width:'130px'}} 
                                                size='sm'
                                                onClick={()=>{resendCertifyNo()}}>인증번호 재전송</Button> }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4} style={{backgroundColor:'#f2fefd', fontWeight:'bold'}}>
                                                <Radio
                                                    checked={cerifyType==='email'}
                                                    onChange={()=>setCerifyType("email")}
                                                    value='email'
                                                    color='default'
                                                    name='certifyEmail'
                                                ></Radio> 
                                                이메일</TableCell>
                                            <TableCell colSpan={5} >
                                                <TextField  label=''
                                                onChange={e => setCertifyEmail(e.target.value)}
                                                value={certifyEmail} />
                                            </TableCell>
                                            <TableCell colSpan={3} padding ={'none'}>
                                                {cerifyType==='email'&&
                                                <Button color='success' //style = {{width:'130px'}} 
                                                size='sm'
                                                onClick={()=>{resendCertifyNo()}}>인증번호 재전송</Button> }
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4} style={{backgroundColor:'#f2fefd', fontWeight:'bold', textAlign:'center'}}>인증번호 6자리 숫자</TableCell>
                                            <TableCell colSpan={8} >  
                                                <TextField  label=''
                                                onChange={e => setCertifyNo(e.target.value)}
                                                value={certifyNo}
                                                size="small" />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell  colSpan={12} style={{fontWeight:'bold'}} >
                                                <div style ={{display:'flex', justifyContent:'space-evenly',width:'70%',margin:'auto'}}>                                                   
                                                    <Button color='primary' style = {{width:'130px'}} onClick={()=>{onSubmitCertifyNo()}}>확인</Button>    
                                                    <Button color='warning' style = {{width:'130px'}} onClick={()=>{ props.toggle();}}>취소</Button>    
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </dd>
                        </dl>
                    </div>
                </div>
            </CardBody>
        </Card>
    </GridContainer>
</>
    )
  }
  
  export default VerifyAdmin;
  