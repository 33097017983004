/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row,Col, FormGroup,Label,Input } from "reactstrap";
//import * as validation from 'muiComponents/common/validation.js';
import InputValid from "components/CustomInput/InputValid.js";


export default function Transport (props){
    const [transport, setTransport] = useState({});
    const [openType, setOpenType] = useState("");
    
    useEffect(()=> {
        setTransport(props.transport);
        if( !props.transport.trans_self_yn ) {
            setTransport({...props.transport, trans_self_yn:'Y'});
        }
    }, [props.transport]);

    useEffect(()=> {
        setOpenType(props.openType);
    }, [props.openType]);

    // 수정된 내용은 Trans 저장
    const fncOnChange = ( e, key ) => {
        e.preventDefault();
        setTransport({...transport, [key]:(e.target.value.toUpperCase())||null});
    }

    // 수정된 내용은 Trans 저장
    const fncOnChangeSelect = ( e, key ) => {
        setTransport({...transport, [key]:e.target.value});
        props.fncOnBlur( {...transport, [key]:e.target.value} );
    }

    // 완료된 내용은 부모로 전달
    const fncOnBlur = (e) => {
        e.preventDefault();
        props.fncOnBlur( transport );
    }
    return (
        <>
            <Row>
                {(openType === 'BOOK') &&
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0">Bookmark Name</Label>
                        <InputValid 
                            type="text"
                            name="transport_bookmark_name"
                            id="transport_bookmark_name"
                            maxLength="50"
                            value={transport.transport_bookmark_name?transport.transport_bookmark_name:''}
                            onChange={(e)=>fncOnChange(e, 'transport_bookmark_name')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="text"
                            required={'BOOK'===openType?true:false} 
                        />
                    </FormGroup>
                </Col>}
            </Row>
            <Row>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0">Haulage</Label>
                        <Input 
                            type="select" 
                            name="trans_self_yn" 
                            id="trans_self_yn"
                            value={transport.trans_self_yn?transport.trans_self_yn:''}
                            onChange={(e) => {
                                fncOnChangeSelect(e, 'trans_self_yn');
                            }}>
                            <option key={0} value={'0'}>
                                선택
                            </option>
                            <option value='Y'>자가운송</option>
                            <option value='N'>라인운송</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0">Transport Code</Label>
                        <InputValid 
                            type="text"
                            name="trans_code"
                            id="trans_code"
                            maxLength="10"
                            value={transport.trans_code?transport.trans_code:''}
                            onChange={(e)=>fncOnChange(e, 'trans_code')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                {/* bookingKecaYN&&bookingKecaYN==='Y'? */}
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Transport Name1(한글)</Label>
                        <InputValid 
                            type="text"
                            name="trans_name1"
                            id="trans_name1"
                            maxLength="35"
                            value={transport.trans_name1?transport.trans_name1:''}
                            onChange={(e)=>fncOnChange(e, 'trans_name1')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="text"
                            required={('Y'===transport.trans_self_yn)?true:false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="12">
                <FormGroup>
                    <Label className="mb-0">Transport Name1(영문)</Label>
                    <InputValid 
                        type="text"
                        name="trans_name_eng1"
                        id="trans_name_eng1"
                        maxLength="35"
                        value={transport.trans_name_eng1?transport.trans_name_eng1:''}
                        onChange={(e)=>fncOnChange(e, 'trans_name_eng1')}
                        onBlur={(e) => {fncOnBlur(e)}}
                        validtype="eng"
                        required={('Y'===transport.trans_self_yn)?true:false} 
                    />
                </FormGroup>
                </Col>
            </Row>
            <Row>
                {/* bookingKecaYN&&bookingKecaYN==='Y'? */}
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Transport Name2(한글)</Label>
                        <InputValid 
                            type="text"
                            name="trans_name2"
                            id="trans_name2"
                            maxLength="35"
                            value={transport.trans_name2?transport.trans_name2:''}
                            onChange={(e)=>fncOnChange(e, 'trans_name2')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="text"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="12">
                <FormGroup>
                    <Label className="mb-0">Transport Name2(영문)</Label>
                    <InputValid 
                        type="text"
                        name="trans_name_eng2"
                        id="trans_name_eng2"
                        maxLength="35"
                        value={transport.trans_name_eng2?transport.trans_name_eng2:''}
                        onChange={(e)=>fncOnChange(e, 'trans_name_eng2')}
                        onBlur={(e) => {fncOnBlur(e)}}
                        validtype="eng"
                        required={false} 
                    />
                </FormGroup>
                </Col>
            </Row>
            <Row>
                {/* bookingKecaYN&&bookingKecaYN==='Y'? */}
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Name(한글)</Label>
                        <InputValid 
                            type="text"
                            name="trans_user_name"
                            id="trans_user_name"
                            maxLength="17"
                            value={transport.trans_user_name?transport.trans_user_name:''}
                            onChange={(e)=>fncOnChange(e, 'trans_user_name')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="text"
                            required={('Y'===transport.trans_self_yn)?true:(transport.trans_user_email||transport.trans_user_fax||transport.trans_user_tel)?true:false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="12">
                <FormGroup>
                    <Label className="mb-0">Name(영문)</Label>
                    <InputValid 
                        type="text"
                        name="trans_user_name_eng"
                        id="trans_user_name_eng"
                        maxLength="17"
                        value={transport.trans_user_name_eng?transport.trans_user_name_eng:''}
                        onChange={(e)=>fncOnChange(e, 'trans_user_name_eng')}
                        onBlur={(e) => {fncOnBlur(e)}}
                        validtype="eng"
                        required={('Y'===transport.trans_self_yn)?true:(transport.trans_user_email||transport.trans_user_fax||transport.trans_user_tel)?true:false} 
                    />
                </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Tel</Label>
                        <InputValid 
                            type="text"
                            name="trans_user_tel"
                            id="trans_user_tel"
                            maxLength="25"
                            value={transport.trans_user_tel?transport.trans_user_tel:''}
                            onChange={(e)=>fncOnChange(e, 'trans_user_tel')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="tel"
                            required={('Y'===transport.trans_self_yn)?true:false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">E-mail</Label>
                        <InputValid 
                            type="text"
                            name="trans_user_email"
                            id="trans_user_email"
                            maxLength="50"
                            value={transport.trans_user_email?transport.trans_user_email:''}
                            onChange={(e)=>fncOnChange(e, 'trans_user_email')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="email"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Fax</Label>
                        <InputValid 
                            type="text"
                            name="trans_user_fax"
                            id="trans_user_fax"
                            maxLength="25"
                            value={transport.trans_user_fax?transport.trans_user_fax:''}
                            onChange={(e)=>fncOnChange(e, 'trans_user_fax')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="tel"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Factory Area</Label>
                        <InputValid 
                            type="text"
                            name="trans_fac_area_name"
                            id="trans_fac_area_name"
                            maxLength="20"
                            value={transport.trans_fac_area_name?transport.trans_fac_area_name:''}
                            onChange={(e)=>fncOnChange(e, 'trans_fac_area_name')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Factory Name</Label>
                        <InputValid 
                            type="text"
                            name="trans_fac_name"
                            id="trans_fac_name"
                            maxLength="20"
                            value={transport.trans_fac_name?transport.trans_fac_name:''}
                            onChange={(e)=>fncOnChange(e, 'trans_fac_name')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Transport Remark</Label>
                        <InputValid 
                            type="text"
                            name="trans_remark"
                            id="trans_remark"
                            maxLength="50"
                            value={transport.trans_remark?transport.trans_remark:''}
                            onChange={(e)=>fncOnChange(e, 'trans_remark')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
}