import React,{ useState, useEffect } from "react";
import axios from 'axios';
import {sub, add, format, differenceInMonths, isValid, parse} from 'date-fns';
// core components
import {Divider, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';

import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Button from "muiComponents/CustomButtons/Button.js";
import CalendarBox from "muiComponents/CustomInput/CustomCalendar.js";

import DetailTable from "views/DemDet/Import/importDetailTable.js";

const overDayOptions = ['1','2','3','4','5','6','7','8','9','10'];
const  searchTypeList = [{label:'ETA(ATA)' , value:'eta'},
						{label:'B/L 또는 CNTR' , value:'bl_cntr'},
						];
export default function DemDetList(props) {
	const {userData,alertMessage} = props;
	const [loading, setLoading] = useState(false);

	const [demDetList,setDemDetList] = useState([]);
	const [carrierList, setCarrierList] = useState([]);

	//조회조건
	const [carrierCode, setCarrierCode] = useState(null);
	const [searchType,setSearchType] = useState(searchTypeList[0]);
	const [fromDate,setFromDate] = useState(sub(new Date(),{months:1}));
	// const [fromDate,setFromDate] = useState(add(sub(new Date(),{months:1}),{days:1}));
	const [toDate,setToDate] = useState(new Date());

	const [typeDemDet, setTypeDemDet] = useState('DEM');
	const [blNo , setBlNo] = useState("");
	const [cntrNo , setCntrNo] = useState("");

	const [overDayFrom , setOverDayFrom] = useState('1');
	const [overDayTo , setOverDayTo] = useState('∞');

	useEffect(() => {
		axios.post("/loc/getDemDetLineCode",{}
		).then(res =>setCarrierList(res.data))
		.catch(err => {console.log(err)
			// if(err.response.status === 403||err.response.status === 401) {
			// 	props.openLogin();
			// }
		});
		onSubmit();
	return () => {
		};
	},[]);

	useEffect(() => {
		const queryObj = props.location.state;
		let key = queryObj? queryObj.key ||null :'';
		if( key ){
			setTypeDemDet(key)
		}else{
			setTypeDemDet('DEM')
		}
	},[window.location]);

	useEffect(() => {
		setBlNo('');
		setCntrNo('');
	},[searchType]);
	
	const onSubmit = () => {
	// console.log("====> carrierCode :",carrierCode,
	// 				', searchDateType : ',searchType
	// 				,', typeDemDet : ',typeDemDet
	// 				,', blNo : ',blNo
	// 				,', cntrNo : ',cntrNo
	// 				,', overDayFrom : ',overDayFrom
	// 				,', overDayTo : ',overDayTo
	// 				);
		if(userData) {
		
			if(//!carrierCode || 
				!overDayFrom){
				alertMessage('필수 값을 입력하세요.','error');
				return false;}
			
			let params = {klnetId:userData.klnet_id
						, carrierCode : carrierCode, typeDemDet : typeDemDet
						, overDayFrom : overDayFrom, overDayTo : overDayTo =='∞'?null:overDayTo}

			if(searchType=='eta'|| (!blNo&&!cntrNo)){//blNo, cntrNo 값 없으면 eta로 검색한다 
				setSearchType(searchTypeList[0]); 
				if(differenceInMonths(toDate, fromDate)>5 ){
					alertMessage('조회 기간은 최대 6개월입니다.','error');
					return false;
				}
				if(!isValid(fromDate)||!isValid(toDate)){
					alertMessage('조회 기간 형식을 확인하세요.','error');
					 return false;
				}
				params={...params , fromDate:format(fromDate,'yyyyMMdd'), toDate:format(toDate,'yyyyMMdd')}
			}else{
				params={...params , blNo:blNo, cntrNo:cntrNo}
			}
			setLoading(true)
			axios.post("/loc/selectImportDemDetList",{
				params
			}).then(res => {
				if(res.data&&res.data.length>0){
					let list = res.data;
					console.log(list)
					setDemDetList(list.map((val)=> val.DUE_DATE&&isValid(val.DUE_DATE)? {...val , DUE_DATE : format(parse(val.DUE_DATE,'yyyyMMdd',new Date()),'yyyy/MM/dd')}: val))
				}else{
					setDemDetList([])
				}

				setLoading(false);
			}).catch(err => {
				console.log(err)
				// if(err.response&&err.response.status === 403||err.response.status === 401) {
				// 	props.openLogin();
				// 	setLoading(false);
				// }else{
					alertMessage('오류가 발생했습니다.','error');
					setLoading(false);
				// }
			})
		} else {
				props.openLogin();
				setLoading(false);
		}
	
	}

	return (
		<div  style={{marginTop:'20px', marginBottom:'10px'}} >
		<Card style={{marginTop:'0',marginBottom:'5px'}}>
			<CardBody style={{paddingTop:'10px',paddingBottom:'10px'}}>
				<Grid container  spacing ={2} >
					<Grid item lg={1} sm={3} xs={2}>
						<Autocomplete
						disableClearable
						defaultValue={'DEM'}
						id="typeDemDet"
						value={typeDemDet}
						options = {['DEM','DET']}
						onChange={(e, data)=> setTypeDemDet(data)}
						renderInput={params => (
						<TextField {...params} label='DEM / DET' required/>
						)}
						/>
					</Grid>
					<Grid item lg={2} sm={3}  xs ={4}>
						<Autocomplete
						id="carrierCode"
						options = {carrierList&&carrierList.filter(x=>x.TYPE ==typeDemDet)}
						getOptionLabel = { option =>`${option.LINE_CODE} - ${option.LINE_KR_NAME} ${option.LINE_KR_NAME!==option.LINE_NAME? '['+option.LINE_NAME+']' :''}`}
						getOptionSelected = {(option,value) => option.LINE_CODE === value.LINE_CODE}
						onChange={(_,selected)=>{setCarrierCode(selected&&selected.value)}}
						renderInput={params => (
							<TextField {...params} label="Carrier" fullWidth
							// error={!carrierCode} helperText={!carrierCode ? '필수 값입니다.':''}
								/>
						)}
						/>
					</Grid>
					<Grid item lg={2} sm={6} xs={4}>
						<Grid container spacing={1} style ={{display :'flex'}}>	
							<Grid item style ={{width :'45%'}} >
								<Autocomplete
								disableClearable
								defaultValue={'1'}
								id="overDayFrom"
								options = {overDayOptions}
								onChange={(e, data)=> setOverDayFrom(data)}
								renderInput={params => (
								<TextField {...params} label='OVER DAY'/>
								)}
								/>
							</Grid>
							<Grid item style ={{width :'4%', marginTop:'25px', padding:'0'}}  > 
							~
							</Grid>
							<Grid item style ={{width :'45%'}} > 
								<Autocomplete
								disableClearable
								defaultValue={'∞'}
								id="overDayTo"
								options = {['∞',...overDayOptions]}
								onChange={(e, data)=> setOverDayTo(data)}
								renderInput={params => (
								<TextField {...params} label=' '  style ={{fontSize :'20px'} } />
								)}
								/>
							</Grid>
							<Divider orientation='vertical' flexItem component={'div'} style ={{marginLeft:'5px'}}/>
						</Grid>
					</Grid>
					<Grid item  lg={5} sm={9} xs ={9}>
						<Grid container spacing={1} style ={{display :'flex'}}>	
							<Grid item style ={{width :'27%'}} >
								<Autocomplete
								disableClearable
								// defaultValue={searchTypeList[0]}
								id="searchType"
								value={searchType}
								options = {searchTypeList}
								getOptionLabel = { option => option.label}
								getOptionSelected = {(option,value) => option.value === value.value}
								onChange={(e, data)=> setSearchType(data)}
								renderInput={params => (
								<TextField {...params} label='* 검색 조건'  />
								)}
								/>
							</Grid>
						{searchType&&searchType.value==='eta'?<>
							<Grid item style ={{width :'35%'}} > 
								<CalendarBox
									labelText =" "
									id="fromDate"
									format="yyyy-MM-dd"
									variant="inline"
									setValue={fromDate}
									onChangeValue={date =>setFromDate(date)}
									autoOk={true}
									fullWidth={true}
								/>
							</Grid>
							<Grid item style ={{width :'3%', marginTop:'25px', padding:'0', textAlign:'center'}} > <span > ~ </span> </Grid>
							<Grid item style ={{width :'35%'}} >
								<CalendarBox
									labelText =" "
									id="toDate"
									format="yyyy-MM-dd"
									variant="inline"
									setValue={toDate}
									onChangeValue={date =>setToDate(date)}
									autoOk={true}
									fullWidth={true}
								/>
							</Grid></>
							:<>
							<Grid item lg sm xs>
								<TextField id="blNo" label="B/L No." 
									onChange={e => setBlNo(e.target.value)} 
									value={blNo} fullWidth/>
							</Grid>
							<Grid item lg sm xs>
								<TextField id="cntrNo" label="CNTR No." 
									onChange={e => setCntrNo(e.target.value)} 
									value={cntrNo} fullWidth/>
							</Grid>
							</>}
						</Grid>
					</Grid>
					<Grid item lg sm xs style ={{display:'flex', justifyContent:'flex-end'}} >
						<Button color="info"  endIcon={<SearchIcon/>} size ='lg' onClick = {onSubmit}>Search</Button>
					</Grid>
				</Grid> 
			</CardBody>
		</Card>
		<Grid container style={{paddingBottom:'10px'}} justifyContent='flex-end' direction='column' alignItems='flex-end'>
		<h6 style={{textAlign:'end', color: '#66615b',fontSize: '12px'}}
			>※ 기간으로 조회 시, LOGISVIEW에서 D/O 승인 완료된 선적 건만 조회됩니다.</h6>
		</Grid>
		<DetailTable 
		tableHeaderColor = "info"
		rows = { demDetList }
		loading={loading}
		onSubmit={onSubmit}
		{...props}
		/>
		</div> 
	);
}