
import React,{useState,useEffect} from "react";
import axios from 'axios';
import Moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";


import {Grid,TableRow,TableCell,Box,Collapse,Tooltip,Icon,Popover,CardMedia, Checkbox
} from "@material-ui/core";

// import InlandDriveTimeline from './InlandDriveTimeline.js'
// import InlandDriveVmap from './InlandDriveVworld.js'

import Card from "muiComponents/Card/Card.js";
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import Button from "muiComponents/CustomButtons/Button.js";

// import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
// const useStyles = makeStyles(styles);

export default function RowTable(props) {
	const {rowData} =props
	// const [iconstate,setIconstate] = useState("add_circle");
	// const [borderBottomStyleValue,setBorderBottomStyleValue] = useState("1px solid #e0e0e0");
	// const [open, setOpen] = React.useState(false);
	// // const [bookmarkIcon,setBookmarkIcon] = useState(false);
	// const [bookmarkIcon,setBookmarkIcon] = useState(props.checkedStar);
	// const [rowDetailData, setRowDetailData] = React.useState('');
	// // const [loading,setLoading ] = useState(false);
	// const [imagePath, setImagePath] = React.useState('');
	// const [point1, setPoint1] =useState([]);
	// const [move1, setMove1] =useState([]);
	// const [dataAddr,setDataAddr]=useState('');
	// const [listRowData,setListRowData]=useState([]);
	// const classes = useStyles();
	
	// useEffect(() => {
	// 	console.log(bookmarkIcon,props.data.RNUM)
	// 	axios.post("/loc/selectInlandChecked",{	
	// 		carrierCode: props.data.CARRIER_CODE
	// 		,ie_type: props.data.IE_TYPE
	// 		,cntrNo : props.data.CNTR_NO?props.data.CNTR_NO:''
	// 		,blNo : props.data.BL_NO?props.data.BL_NO:''
	// 		,bkgNo : props.data.BKG_NO?props.data.BKG_NO:''
	// 	}).then(res => {
	// 		console.log(res.data); 
	// 		if(res.data.length>0){
	// 			setBookmarkIcon(true) 
	// 		}
	// 	});
   	// }, []);
	// useEffect(() => {
	// 	console.log(rowData)
	//    doDetail(props.data)
   	// }, []);
	// const doDetail = (param) => {
	// 		 axios.post("/loc/getInlandDriveDetail",{
	// 			// cntr_seq:props.data.CNTR_SEQ
	// 			load_in_out_time:param.LOAD_IN_OUT_TIME,
	// 			cntr_no :param.CNTR_NO
	// 		})
	// 		.then(res => {
	// 			// console.log('res.data', '>>',res.data )
	// 				if(res.status === 200&&!res.data.msg) {
	// 					setRowDetailData(res.data)
	// 					let rowDetailData1= res.data;
	// 					let list = rowData;
	// 					//리스트 데이터와 비교 
	// 					//1. 현재위치
	// 					const dkIndex = [...rowDetailData1].reverse().findIndex(x=>x.DETAIL_KIND=='반입완료')
	// 					const addrIndx = [...rowDetailData1].reverse().findIndex(x=>x.ADDR!=null)
	// 					const dkData = [...rowDetailData1].reverse().find(x=>x.DETAIL_KIND=='반입완료')
	// 					const addrData = [...rowDetailData1].reverse().find(x=>x.ADDR!=null)
						
	// 					dkIndex<=addrIndx
	// 					?dkData?setDataAddr(dkData.MAIN_KIND):setDataAddr('')
	// 					:addrData? setDataAddr(`${(addrData.ADDR).split(' ')[0]} ${(addrData.ADDR).split(' ')[1]}` )
	// 								:setDataAddr(dkData.MAIN_KIND);

	// 					//2. 차량정보
	// 					const lastCarCode =[...rowDetailData1].reverse().find(x=>x.CAR_CODE).CAR_CODE
	// 					const lastMobileNo = [...rowDetailData1].reverse().find(x=> x.CAR_CODE==lastCarCode&&x.MOBILE_NO).MOBILE_NO 
	// 					console.log(lastCarCode,lastMobileNo);
	// 					props.data.CAR_CODE== lastCarCode? list = {...list,CAR_CODE: props.data.CAR_CODE||'' , MOBILE_NO : props.data.MOBILE_NO||''}
	// 														:list = {...list,CAR_CODE: lastCarCode||'', MOBILE_NO : lastMobileNo||''}

	// 					//3. 운송사정보
	// 					const lastComName =[...rowDetailData1].reverse().find(x=>x.COM_NAME).COM_NAME||''
	// 					const lastComPhone = [...rowDetailData1].reverse().find(x=> x.COM_NAME==lastComName&&x.COM_PHONE)//.COM_PHONE ||''
	// 					console.log(lastComName,lastComPhone)
	// 					props.data.COM_NAME==lastComName? list ={...list,COM_NAME: props.data.COM_NAME||'' , COM_PHONE : props.data.COM_PHONE||''} 
	// 														:list ={...list,COM_NAME: lastComName||'', COM_PHONE : lastComPhone||''}

	// 					setListRowData(list)
	// 					return res.data;
	// 				} else {
	// 					setRowDetailData([]);
	// 					props.alertMessage(res.data.msg,'error');	
	// 					return [];
	// 				}
	// 		})
	// 		.catch(err => {
	// 			if(err.response !== undefined ) {
	// 				if(err.response.status === 403||err.response.status === 401) {
	// 				props.openLogin();
	// 				}
	// 			} else {
	// 				console.log("error ",err);
	// 				props.alertMessage('조회된 데이터가 존재 하지 않습니다.','error');
	// 			}
	// 		});	
	// 	}
	// const handleCollOpen = async() => {
		// console.log(props.data)
		// if (!open) {
		// 	let rowDetailData1= rowDetailData;
		// 	let move = new Array();
		// 	for (let i = 0; i < rowDetailData1.length; i++) {
		// 		if(rowDetailData1[i].WGS84_X&&rowDetailData1[i].WGS84_Y){
		// 			let movePoint = new Array(4)
		// 			movePoint[0] = `${rowDetailData1[i].DETAIL_KIND||''} ${rowDetailData1[i].CAR_CODE||''}  (${rowDetailData1[i].BASE_DATE||''})`
		// 			movePoint[1] = rowDetailData1[i].ADDR||''
		// 			movePoint[2] = rowDetailData1[i].WGS84_Y
		// 			movePoint[3] = rowDetailData1[i].WGS84_X
		// 			move.push(movePoint)
		// 		//   console.log('move>>',move)
		// 		}
		// 		}
		// 	let rowData=props.data
		// 	let point=[
		// 	["반출지", `${rowData.OUTGATE_CY} ${rowData.OUTGATE_NAME}`||'', rowData.OUTGATE_WGS84_Y, rowData.OUTGATE_WGS84_X],
		// 	["반입지", `${rowData.INGATE_CY} ${rowData.INGATE_NAME}`||'', rowData.INGATE_WGS84_Y, rowData.INGATE_WGS84_X],
		// 	["도어지", "도어지", rowData.DOOR_WGS84_Y ,rowData.DOOR_WGS84_X],
		// 	]
		// 	setMove1(move)
		// 	setPoint1(point)				
		// 	setBorderBottomStyleValue("1px dotted #e0e0e0");
		// 	setIconstate("remove_circle");
		// 	setOpen(true);
		// } else {
		// 	setBorderBottomStyleValue("1px solid #e0e0e0");
		// 	setIconstate("add_circle");
		// 	setOpen(false);
		// }		  
	// }
	
	// const handleStarClick = async(num) => {
		// console.log(props.data,num)

		// if (!bookmarkIcon){//등록
		// 	if(props.submitCheckedbox(num)){setBookmarkIcon(!bookmarkIcon)}
		// }else{//등록해제props.data.RNUM
		// 	await axios.post("/loc/deleteInlandChecked",{
		// 		carrierCode: props.data.CARRIER_CODE
		// 		,ie_type: props.data.IE_TYPE
		// 		,cntrNo : props.data.CNTR_NO?props.data.CNTR_NO:''
		// 		,blNo : props.data.BL_NO?props.data.BL_NO:''
		// 		,bkgNo : props.data.BKG_NO?props.data.BKG_NO:''
		// 	}) .then(res => {
		// 		if(res.status === 200) {
		// 		//   console.log(res)
		// 		  setBookmarkIcon(!bookmarkIcon)
		// 		}
		// 	})
		// 	.catch(err => {
		// 		  console.log("error ");
		// 		//   alertMessage('에러발생','error')		  
		// 	});
		// }

	// }


	
	// const [anchorEl,setAnchorEl] =useState(null)
	// const openPop =Boolean(anchorEl);
	// const id = openPop? 'simple-popover' :undefined;
	

	// const handleClick=(e,data)=>{
	// 	// console.log(e.currentTarget,data)
	// 	setAnchorEl(e.currentTarget)
	// 	let fileName= data.split('~')[2]
	// 	let filePath= data.split('~')[1]
	// 	let imagePath=`https://www.plism.com/plism3/shp/oub/mng/viewSealImage.do?fileName=${fileName}&filePath=${filePath}`
	// 	setImagePath(imagePath)
	// }
	// const handleClose=()=>{
	// 	setAnchorEl(null)
	// }


	return (
	    <>
			<TableRow //className={classes.tableRow + " " + classes.tableRowHead}  
			style = {{cursor:'context-menu'}}
			key={props.index}>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.INOUT} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.MOVE_TYPE} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.TERMINAL_NAME} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.IN_OUT_TIME} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.VESSEL_CODE} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.TERMINAL_REF_NO} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.CARRIER_CODE} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.FULL_EMPTY} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.TYPE_SIZE} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.MOVE_FROM} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.MOVE_TO} </TableCell>
                    <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'11px'}}>{rowData.CAR_NO} </TableCell>
					
					{/* <Popover
							id={id}
							open={openPop}
							onClose={handleClose}
							anchorEl={anchorEl}
							// anchorReference="anchorPosition"
							// anchorPosition={{top:100,left:550}}
							anchorOrigin={{vertical:'top',horizontal:'left',}}
							transformOrigin={{vertical:'bottom',horizontal:'right',}}
						>  */}
						{/* <Card style={{minWidth:'400px'}}>
							<img src={imagePath} alt='NOT FOUND IMAGE' style={{maxWidth:'400px'}} />
						</Card>
					</Popover> */}
					{/* <TableCell align='center' style={{paddingTop:'6px',paddingBottom:'6px',fontSize:'10px',borderRight:'1px solid silver'}}>
						<Tooltip title="more"><Icon style={{color:'#00acc1',paddingTop:'1px'}} onClick={handleCollOpen}>{iconstate}</Icon></Tooltip>
					</TableCell> */}
			</TableRow>
	    </>
	  );
}

