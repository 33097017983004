/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row,Col, FormGroup,Label} from "reactstrap";
//import * as validation from 'components/common/validation.js';
import InputValid from "components/CustomInput/InputValid.js";

export default function SpecialContainer (props){
    const [index, setIndex] = useState(0);
    const [special, setSpecial] = useState({});
    const [openType, setOpenType] = useState("");
    useEffect(() => {
        setSpecial(props.special);
        setIndex(props.index);
        setOpenType(props.openType);
    },[props]);

    // 완료된 내용은 부모로 전달
    const fncOnBlur = (e) => {
        e.preventDefault();
        props.fncOnBlurSpecial(index, special);
    }
    // 수정된 내용은 container 저장
    const fncOnChange = ( e, key ) => {
        e.preventDefault();
        setSpecial({...special, [key]:(e.target.value.toUpperCase())||null});
    }

    return (
        <Row>
            <Col>
            {("BOOK" === openType) &&
                <Row>
                    <Col xl="6" lg="6" md="12">
                        <FormGroup>
                            <Label className="mb-0">Bookmark Name</Label>
                            <InputValid 
                                type="text"
                                name="container_special_bookmark_name"
                                id="container_special_bookmark_name"
                                maxLength="50"
                                value={special.container_special_bookmark_name?special.container_special_bookmark_name:''}
                                onChange={(e)=>fncOnChange(e, 'container_special_bookmark_name')}
                                onBlur={(e) => {fncOnBlur(e)}}
                                validtype="text"
                                required={'BOOK'===openType?true:false} 
                            />
                        </FormGroup>
                    </Col>
                </Row>}
                <Row>
                    <Col xl="4" lg="4">
                        <FormGroup>
                            <Label className="mb-0">CLASS</Label>
                            <InputValid 
                                type="text"
                                name="special_imdg"
                                id="special_imdg"
                                maxLength="7"
                                value={special.special_imdg?special.special_imdg:''}
                                onChange={(e)=>fncOnChange(e, 'special_imdg')}
                                onBlur={(e) => {fncOnBlur(e)}}
                                validtype="eng"
                                required={props.dangerTrue} 
                                feedid="container"
                            />
                        </FormGroup>
                    </Col>
                    <Col xl="4" lg="4">
                        <FormGroup>
                            <Label className="mb-0">UNDG</Label>
                            <InputValid 
                                type="number"
                                name="special_undg"
                                id="special_undg"
                                maxLength="4"
                                value={special.special_undg?special.special_undg:''}
                                onChange={(e)=>fncOnChange(e, 'special_undg')}
                                onBlur={(e) => {fncOnBlur(e)}}
                                validtype="text"
                                required={props.dangerTrue} 
                                feedid="container"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
