import 'date-fns';
import React from 'react';
import { makeStyles ,ThemeProvider} from "@material-ui/core/styles";
//import Grid from '@material-ui/core/Grid';
import { createTheme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  //KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";

//const useStyles = makeStyles(styles);

const defaultMaterialTheme = createTheme ({
  palette:{
      primary:{main:'#06b3ba',contrastText:'#fff'}
  },
})

export default function MaterialUIPickers(props) {


  //const classes = useStyles();
  // The first commit of Material-UI
  const {invalidDateMessage,labelText,id,format,onChangeValue,setValue,inputVariant,margin,autoOk,variant,disabled,required} = props;

  return (
    <ThemeProvider theme ={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* <FormControl
        {...formControlProps}
      > */}
          <KeyboardDatePicker 
            // disableToolbar
            variant={variant}
            inputVariant={inputVariant}
            margin={margin}
            format={format}
            autoOk={autoOk}
            id={id}
            disabled={disabled}
            label={labelText}
            value={setValue}
            onChange={onChangeValue}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            // error = {required ? !setValue : !props.isValid ||false}
            // helperText= {required? (!setValue&&'필수 값입니다.'):''}
            fullWidth ={props.fullWidth}
            InputAdornmentProps={props.InputAdornmentProps}
            style ={props.style}
            emptyLabel={props.emptyLabel}
            invalidDateMessage="잘못된 형식값입니다."
            // invalidDateMessage={invalidDateMessage}
            // {...props}
          />
        {/* </FormControl> */}
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}