import React , { useState , useEffect, useRef, }from 'react'
import cn from 'classnames';
import { StylesProvider, createGenerateClassName, createStyles, makeStyles, MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import {   } from '@material-ui/styles';
import {Close} from '@material-ui/icons';
import axios from 'axios';
import * as validation from 'muiComponents/common/validation.js';
import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import CustomInput from "muiComponents/CustomInput/CustomInput.js";
import CustomSelect from "muiComponents/CustomInput/CustomSelect.js";

import {TableRow
    , TableCell, CircularProgress,
    Dialog,Paper, TableBody,} from "@material-ui/core";
import Button from "muiComponents/CustomButtons/Button.js";    
import MaterialTable,{} from 'material-table';
import {
    Add as AddIcon,
} from "@material-ui/icons"
// css
import Style from './SchCal.module.css';

// const generateClassName = createGenerateClassName({
//     productionPrefix:'cstst'
//     ,disableGlobal:true
//     , seed : 'stst'
// })


const theme = createTheme({
    overrides:{
        MuiTableFooter:{
            root:{ borderBottom:'2px solid #00b1b7',marginBottom:'10px'}
        },
        MuiTableHead:{
            root:{ borderTop:'2px solid #00b1b7'}
        }
    }
})


    const useStyles = makeStyles((theme) => createStyles({
    tableLine:{//borderBottom:'2px solid #00b1b7',marginBottom:'10px'
        // '& [class*="Component-horizontalScrollContainer"]': {
        //     height:'300px',overflow:'auto',borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7',marginBottom:'10px'
        // },
        // '& [class="jss149"]': {
        //     height:'300px',overflow:'auto',borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7',marginBottom:'10px'
        // },
        // '& [class="jss171"]': {
        //     height:'300px',overflow:'auto',borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7',marginBottom:'10px'
        // },
        // '& [class="jss185"]': {
        //     height:'300px',overflow:'auto',borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7',marginBottom:'10px'
        // }
    },
}));



const  tbRef = React.createRef(null);
export default function NotiFormModal (props){
    const {userData,alertMessage, openNotiForm, setOpenNotiForm, onHandleOpenNotiForm, openNotiFormMode}=props;
    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [loading, setLoading] = useState(false);
	const [inputCompData,setInputCompData] =useState({});
    const inputEmailRef = useRef('');
    const companyNmRef = useRef('-');

    useEffect( ()=>{
        if(openNotiForm){
            if(userData){ getCompanyNm(userData?.klnet_id);  }
            else{ props.openLogin(); onHandleOpenNotiForm(false); return}
        }        
    },[openNotiForm]);
    
    const getCompanyNm =(klent_id)=>{
        setLoading(true);
        axios.post("/com/selectCompanyNm",{klnet_id : userData.klnet_id}
        ).then(async(res) =>{
            const result = res.data;
            companyNmRef.current = result?.company_name||'-'
            selectIssueNotiList(result?.company_name);
        }).catch(err => {
            console.log(err);
        });
    }

    const  selectIssueNotiList = async(compnm)=>{
        setLoading(true);
        if(openNotiFormMode&&openNotiFormMode.current=='NEW'){
            let data = {...inputCompData,
                //기본값
                noti_export:'Y'
                ,noti_import:'Y'
                ,mode:'NEW'
                ,klnet_id :userData?.klnet_id||''
                ,user_id : userData?.user_no||''
                ,memo:userData?.user_name||''
                ,email:userData?.user_email||'' 
                ,company_name:compnm||companyNmRef.current
            };

            await axios.post("/loc/insertIssueNotiEmail",{data:data}).then(res => {
                if (res.data==="OK"){
                    alertMessage("등록되었습니다.",'success');
                }else{
                    alertMessage("저장에 실패하였습니다.",'error');
                }
            }).catch(err => {
                let errMsg = err.response?.data||err;
                if(errMsg)alertMessage(errMsg,'error'); 
                else alertMessage('오류가 발생했습니다.','error'); 
            })
            // let tb = tbRef.current;
            // setInputCompData(data)
            // tb.dataManager.changeRowEditing();
            // tb.setState({...tb.dataManager.getRenderState(), showAddRow:true})
        }
        await axios.post("/loc/selectIssueNotiList",{klnet_id : userData?.klnet_id}
        ).then(async(res) =>{
            const result = res.data;
            setRowsData(result);
            // if(result.length>0&& compnm!==result[0]?.company_name ){companyNmRef.current =result[0].company_name }//혹시 회사명 다르면 수정
        
        }).catch(err => {
            console.log(err)
            alertMessage('정보를 가져오는 데 실패하였습니다. 다시 시도하세요.','error');
        }).finally(()=> setLoading(false));
    }

    const onToggle = (e,eventName)=>{
		if(eventName=='ADD') {
			e.find(x=>x.tooltip=="Add").onClick();
			setInputCompData({
			//기본값
			noti_export:'Y'
			,noti_import:'Y'
			,mode:'ADD'
            ,klnet_id :userData?.klnet_id||''
            ,user_id : userData?.user_no||''
            ,company_name:companyNmRef.current
			})
		}else{
            setInputCompData({})
        }
	}
    return ( <Dialog 
        onClose={()=>onHandleOpenNotiForm(false)}
        color="primary"
        open={openNotiForm}
        maxWidth='md'
        >
        <Card style={{margin:'0',paddingTop:'0',paddingBottom:'0', width:'960px'}}>
            <CardBody >       
                <div style={{display: 'flex',	flexDirection: 'column',justifyContent: 'center'}}>		
                    <div className={Style.pHeader}>
                        <div className={Style.ptit}>
                            <strong>이메일 알림 설정</strong>
                        </div>
                        <div style={{display:'flex', cursor:'pointer'}} >
                            <Close onClick={()=>onHandleOpenNotiForm(false)}/>
                        </div>
                    </div>
                </div>
                <div className={Style.pCont}>
                    <div className={Style.pwrap} style={{maxHeight:'500px',display: 'flex',	flexDirection: 'column',justifyContent: 'space-between'}}>
                        <MuiThemeProvider theme={theme}>
                        <MaterialTable
                            tableRef = {tbRef}
                            data={rowsData}
                            options={{actionsColumnIndex: -1,addRowPosition:'first',pageSizeOptions:[5],// paging:false ,
                            showEmptyDataSourceMessage: inputCompData?.mode=='NEW'||inputCompData?.mode=='ADD'? false : true,
                            // headerStyle:{border:'2px solid #00bd1b7' }
                            // minBodyHeight:'300px',
                            // maxBodyHeight:'300px',
                            }}
                            // style = {{ height:'40px'}}
                            // style = {{ borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7',marginBottom:'10px'}}
                            isLoading={loading}
                            localization={{body:{editRow:{deleteText:(<strong>삭제 하시겠습니까?</strong>)}}
                                        , header:{actions:'변경'}
                                        }}
                            columns={[
                                { width: '15%' ,title: '업체명', field: 'company_name', editable:'onAdd',initialEditValue:companyNmRef.current||userData?.klnet_id,
                                    editComponent: (props) => (<>{companyNmRef.current||userData?.klnet_id}</>)}
                                ,{ width: '35%', title: '구분(담당자 or 팀 명)', field: 'memo',
                                    editComponent: (props) => (
                                    <CustomInput
                                        value={props.value||''}
                                        id='memo'
                                        formControlProps={{
                                        fullWidth: true
                                        ,style:{paddingTop:0, marginBottom:0}
                                        }}
                                        inputProps={{
                                            onChange:event =>{ props.onChange(event.target.value) 
                                            },
                                            value:`${inputCompData?.memo||props.value||''}`,
                                            maxLength:30
                                        }}
                                    />)}
                                ,{ width: '30%', title: 'e-Mail 주소', field: 'email', 
                                    editComponent: (props) => (
                                    <CustomInput
                                        value={props.value||''}
                                        id='email'
                                        formControlProps={{
                                        fullWidth: true
                                        ,style:{paddingTop:0, marginBottom:0}
                                        }}
                                        inputProps={{
                                            onChange:event =>{ 
                                                props.onChange(event.target.value) ;
                                                inputEmailRef.current.value=event.target.value;
                                            },
                                            defaultValue :`${inputCompData?.email||props.value||''}`,
                                            maxLength:30,
                                            ref:inputEmailRef
                                        }}
                                    />
                                )}
                                ,{ width: '10%' ,title: '수출 알림', field: 'noti_export', align :'center' , initialEditValue:'Y',sorting:false,
                                    editComponent: (props) => (
                                    <CustomSelect
                                        id="noti_export"
                                        option = {["Y","N"]}
                                        inputProps={{
                                            onChange:e=>props.onChange(e.target.value),
                                            value:props.value,
                                            fullWidth:true,style:{marginTop:0}}}
                                        formControlProps={{fullWidth: true }} />
                                )}
                                ,{ width: '10%' ,title: '수입 알림', field: 'noti_import', align :'center' , initialEditValue:'Y',sorting:false,
                                    editComponent: (props) => (
                                    <CustomSelect
                                        id="noti_import"
                                        option = {["Y","N"]}
                                        inputProps={{
                                            onChange:e=>props.onChange(e.target.value),
                                            value:props.value,
                                            fullWidth:true,style:{marginTop:0}}}
                                        formControlProps={{fullWidth: true}} />
                                )}
                            ]
                        }
                        components={{
                            Toolbar: props => (
                                <div style ={{display:'flex', justifyContent:'flex-end'}}>
                                    <div style={{display:'flex', marginBottom:'5px', color:'#ed7d31', alignItems:'flex-end', marginRight: '5px'}}>
                                        <p>※ 선박 스케줄(날짜) 변동 발생 시, 이메일로 안내드립니다.</p>
                                    </div>
                                    <Button
                                        variant="contained"
                                        color="primary" endIcon={<AddIcon/>} size ='sm'
                                        style={{lineHeight:"1"}}
                                        onClick={(event) => {
                                            onToggle(props.actions,'ADD')
                                        }}
                                        >이메일 추가
                                    </Button>
                                </div>
                            ),
                            Container: props => <Paper {...props} elevation={0} className={classes.tableLine}/>,		
                            // Body: props=><div style={{border:'2px solid red'}} ><MTableBody {...props}></MTableBody> </div>
                        }}
                        actions={[
                            
                        ]} 
                        editable={{
                            onRowAddCancelled: rowData=> {setInputCompData({});
                                // if(openNotiFormMode&&openNotiFormMode.current=='NEW'){
                                //     selectIssueNotiList();
                                //     openNotiFormMode.current='';
                                // }
                            },
                            onRowAdd: newData =>
                                new Promise((resolve, reject) => {
                                        // console.log(newData,";;;; ",inputCompData )
                                        let data ={...inputCompData,...newData}
                                        // let line_code = data.line_code ||'';
                                        let klnet_id= data.klnet_id ||'';
                                        let email = data.email||'';
                                        if (!klnet_id) {
                                            // setTimeout(() => { reject();}, 100);
                                            alertMessage("등록 할 수 없는 업체입니다.");
                                            reject();
                                            return 
                                        }
                                        if (!email) {
                                            alertMessage("E-MAIL을 입력 해 주세요.");
                                            reject();
                                            // setTimeout(() => {reject();}, 100);
                                            return 
                                        }
                                        //형식체크
                                        if (!validation.validationEmail(email)){
                                            alertMessage(validation.EMAIL_MSG ,'error'); 
                                            // setTimeout(() => {reject();}, 100);
                                            reject();
                                            return 
                                        }
                                        axios.post("/loc/insertIssueNotiEmail",{data:data}).then(res => {
                                            if (res.data==="OK"){
                                                setRowsData([data, ...rowsData])
                                                alertMessage("등록되었습니다.",'success');
                                                setTimeout(() => { resolve();}, 600);
                                            }else{
                                                reject();
                                                alertMessage("저장에 실패하였습니다.",'error');
                                            }
                                        }).catch(err => {
                                            let errMsg = err.response?.data||err;
                                            if(errMsg)alertMessage(errMsg,'error'); 
                                            else alertMessage('오류가 발생했습니다.','error'); 
                                            reject();
                                        })
                            }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject )=> {
                                    // console.log(newData, oldData)
                                    if (oldData) {
                                        let klnet_id= newData.klnet_id ||'';
                                        let email = newData.email||'';
                                        if (!klnet_id) {
                                            // setTimeout(() => { reject();}, 100);
                                            alertMessage("등록 할 수 없는 업체입니다.",'error');
                                            reject();
                                            return 
                                        }
                                        if (!email) {
                                            alertMessage("E-MAIL을 입력 해 주세요.",'error');
                                            reject();
                                            // setTimeout(() => {reject();}, 100);
                                            return 
                                        }
                                        //형식체크
                                        if (!validation.validationEmail(email)){
                                            alertMessage(validation.EMAIL_MSG ,'error'); 
                                            // setTimeout(() => {reject();}, 100);
                                            reject();
                                            return 
                                        }
                                        axios.post("/loc/updateIssueNotiEmail",{newData:newData,oldData:oldData}).then(res => {
                                            if (res.data==="OK"){
                                                setRowsData(prevState => {
                                                    prevState[prevState.indexOf(oldData)] = newData
                                                    // console.log(prevState)
                                                    return [ ...prevState ];
                                                });
                                                alertMessage("수정되었습니다.",'success');
                                                setTimeout(() => { resolve();}, 600);
                                            }else {
                                                alertMessage(" 업데이트에 실패 하였습니다. ",'error')
                                                reject();
                                            }
                                        }).catch(err => {
                                            alertMessage('오류가 발생했습니다.','error'); 
                                            reject();
                                        })
                                        setInputCompData({})
                                    }
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    axios.post("/loc/deleteIssueNotiEmail",{ oldData:oldData, update_user:userData?.user_no||'' }).then(
                                    res=> {
                                        if (res.data==="OK"){
                                            setRowsData(prevState => {
                                                prevState.splice(prevState.indexOf(oldData),1)
                                            // console.log(prevState.splice(prevState.indexOf(oldData),1))
                                            return [ ...prevState];
                                            });
                                            alertMessage(" 삭제 하였습니다.",'success');
                                            setTimeout(() => { resolve();}, 600);
                                        }else {
                                            alertMessage(" 삭제에 실패 하였습니다. ",'error');
                                            reject();
                                        }
                                    }).catch(err => {
                                        alertMessage('오류가 발생했습니다.','error'); 
                                        reject();
                                    })
                                    setInputCompData({})
                                }),
                            }}
                        />
                        </MuiThemeProvider>
                    </div>
                </div>
                {/* <DialogActions style ={{justifyContent:'center'}}>
                    <Button color="info"
                    // onClick={()=>{onHblSelecedSubmit(hblSelected)}}
                    >확인</Button>
                    <Button color='default' onClick={()=>{setOpenNotiForm(false)}}>취소</Button>
                </DialogActions>  */}
            </CardBody>
        </Card>
    </Dialog>
    )
}
