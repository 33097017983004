import React,{ useState, useEffect, useMemo ,useRef} from "react";
import CardBody from "muiComponents/Card/CardBody.js";
import {
	Collapse,
	Card,
	Checkbox,
	CardContent,
	TextField,
	TableFooter,
	MenuItem,
	FormControl,
	InputLabel,
	Grid,
	Divider,
	Container,
	Avatar,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Input
} from "@material-ui/core";

import Button from "muiComponents/CustomButtons/Button.js";

import {
	Chat as Chat,
	Check,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	Search as SearchIcon
}from "@material-ui/icons";
import axios from 'axios';
import Filesaver from 'file-saver';
import {yellow, red} from '@material-ui/core/colors';
import ReactQuill from 'react-quill';
import { useHistory } from "react-router-dom";
import "assets/css/quillEditer.css";
import {Row,Col,Label} from "reactstrap";
import Select from "react-select";
const selectStyle = {
    control: base => ({
      ...base,
      fontFamily:'Nanum Gothic',
      fontSize:'0.8rem',
      color:'rgb(68, 68, 68)'
  
    }),
    menu: base => ({
      ...base,
      fontFamly: "Nanum Gothic",
      fontSize:'0.8rem',
      color:'rgb(68, 68, 68)'
    })
}

export default function QnADetail(props) {
	const history = useHistory();
	const [title,setTitle] =useState('');
	const [onlyChecked, setOnlyChecked] = useState(false);
	const [password,setPassword]= useState('');
	const [bbsList,setBbsList]= useState([]);
	const [bbs,setBbs]= useState({value: "BBSMSTR_300000000005" ,label: "QnA - 사이트문의하기" });
	const { userData} = props;
	//quill에디터
	const [value, setValue] = useState('');
	const quillRef = useRef();

	useEffect(() => {
		//console.log(userData);
		if(userData.klnet_id){
			axios.post("/api/selectBbsCfgCodeList",{sKlnetId:userData.klnet_id,bbs_type:'3'})
			.then(res => {
				if(res.data.length > 0) {
					const newArray = res.data.map( item => {
						return {value: item.BBS_ID, label: item.BBS_NM }
					})
					setBbsList(newArray);
				}
			})
		}
    },[])

	// Quill 에디터에서 사용하고싶은 모듈들을 설정한다.
	// useMemo를 사용해 modules를 만들지 않는다면 매 렌더링 마다 modules가 다시 생성된다.
	const modules = useMemo(() => {
		return {
		toolbar: {
			container: [
			['image'],
			[{ header: [1, 2, 3, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{color:[]},{background:[]}]
			],
		},
		};
	}, []);
	// 위에서 설정한 모듈들 foramts을 설정한다
	const formats = [
		'image',
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'color',
		'background',
		 ];

	//게시물 삭제 후 이동
	const goQnAList = () => {
		history.push({
			pathname:"/svc/qna",
		})
	}
	const QnASave = () => {

		if(password&&password.length!==4){
			props.onAlert('error','비밀번호를 다시 입력해주세요.');
			return;
		}
		if(!title){
			props.onAlert('error','제목을 입력해주세요.');
			return;
		}
		if(!value){
			props.onAlert('error','내용을 입력해주세요.');
			return;
		}
		if(!bbs){
			props.onAlert('error','구분을 선택해주세요.');
			return;
		}

		axios.post("/api/insertQnaDetail", {
			bbs_id:bbs.value //게시판 아이디
			,sUserId:userData.user_no//게시자 아이디
			,sUserNm:userData.user_name//게시자 명
			,ntt_sj:title //게시물 제목
			,ntt_cn:value//게시물 내용
			,ntt_no:null//답장글 순번
			,reply_lc:null//답장 게시물 아이디
			,atch_file_id:null//첨부 파일 명
			,ntt_tp:null//게시물 분류
			,qna_pw:password//비밀번호
		}).then(res => {
			if(res.data.rowsAffected>0){
				props.onAlert('success','저장');
				history.push({
					pathname:"/svc/qna",
				})
			}else{
				props.onAlert('error','저장을 실패하였습니다.');
			}
		})
	}

	const onlyHandleChange = () => {
		setOnlyChecked(!onlyChecked); 
	  }

	return (
	  	<React.Fragment>
			<Card  className="m-4">
				<CardContent>
					<h4 style={{textAlignLast:'left',color:'black'}}><Chat style={{color:'#00acc1'}}></Chat> Q & A 작성</h4>
				</CardContent>
				<CardBody>
					<Row style={{marginBottom:'20px'}}>
						<Col xl="1" lg="1" md="1" ><Label style={{marginTop:'6px'}}>제목</Label></Col>
						<Col xl="5" lg="5" md="5">
						<TextField id="Title" placeholder=" 문의드립니다. " style={{width:'100%'}} value={title} onChange={(e)=>setTitle(e.target.value)}></TextField>
						</Col>
						<Col xl="2" lg="2" md="2" >
							<Select
							// className="react-select react-select-primary"
							// classNamePrefix="react-select"
							name="bbsList"
							value={{
								value:bbs.value,
								label:bbs.label,
							}}
							lable
							onChange={(value)=>setBbs(value)}
							options={bbsList}
							styles={selectStyle}
							//placeholder="구분"
							/>
						</Col>
						<Col xl="1" lg="1" md="1" ><Label style={{marginTop:'6px'}}>비밀글</Label></Col>
						<Col xl="1" lg="1" md="1">
							<input style={{marginTop:'6px'}}type='checkbox'value={onlyChecked}
							onChange={()=>onlyHandleChange()}
							color="primary"></input>
						</Col>
						<Col xl="2" lg="2" md="2">
						{onlyChecked?
							<>
							<TextField id="password" label={<font size="2">숫자4자리</font>} onChange={event => setPassword(event.target.value) } value={password}//onKeyPress={onKeyDownEnter} 
							variant="outlined" size="small" type="password" fullWidth />
							</>
							:<></>}
						</Col>
					</Row>
					<Row>
						{/* <button onClick={onClickContents}>에디터 안의 내용들</button> */}
						<Col style={{height:"600px",padding:"0px"}}>
							<ReactQuill
								style={{width: "100%",height:"90%",borderBottom:"50px"}}
								ref={quillRef}
								theme='snow'
								placeholder='내용'
								value={value}
								onChange={(value)=>setValue(value)}
								modules={modules}
								formats={formats}
								/>
						</Col>
					</Row>
					<Row>
						<Col style={{textAlign:"right"}}>
							<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> goQnAList()}>목록</Button>
							<Button style={{paddingBottom:'8px'}}color="info" onClick={()=> QnASave()}>게시물 등록</Button>
						</Col>
					</Row>
					{/* <TableContainer>
					<Table style={{borderTop:'2px solid #06b3ba'}}>
						<TableBody>
							
							<TableRow>
								<TableCell colSpan={4} style={{height: "600px",borderBottom: '1px solid rgba(0, 0, 0, 0)'}}>
								<button onClick={onClickContents}>에디터 안의 내용들</button> 
								<ReactQuill
									style={{height: "90%",borderBottom:"50px"}}
									ref={quillRef}
									theme='snow'
									placeholder='내용'
									value={value}
									// onChange={setValue}
									modules={modules}
									formats={formats}
      								/>
								</TableCell>
							</TableRow>
						</TableBody>
						<TableFooter>
								<TableRow>
									<TableCell align="right" colSpan={4} style={{borderTop:'2px solid #06b3ba'}}>
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> goQnA()}>목록</Button>
										<Button style={{paddingBottom:'8px'}}color="info" onClick={()=> QnAsave()}>게시물 등록</Button>
									</TableCell>
								</TableRow>
						</TableFooter>
					</Table>
					</TableContainer> */}
				</CardBody>
			</Card>
		</React.Fragment>
	);
  }
