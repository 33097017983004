import React,{ useState, useEffect } from "react";
import axios from 'axios';
import Button from "muiComponents/CustomButtons/Button.js";
import { IconButton, TableContainer, Table, TableHead, TableRow
, TableCell, TableBody
, Dialog, DialogTitle, DialogContent, DialogActions,Checkbox,withStyles} from "@material-ui/core";
import {ViewComfy, Bookmarks, Close, RegistrationTwoTone} from "@material-ui/icons";
import {useStyles} from 'views/Booking/BKG/styles';
import * as validation from 'muiComponents/common/validation.js';
import ApplicationParamsForm from './ApplicationParamsForm';
import Slide from "@material-ui/core/Slide";
import Confirm from 'components/Confirm/Confirm.js';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const LogisviewCheckbox = withStyles({
    root:{
        color:'#5fb0b5',
        '&$checked': {
            color: '#5fb0b5',
            },
        padding:'2px'
    }
})((props)=><Checkbox color = 'default' {...props}/>);

const ApplicationModal = (props) => {
    
const classes = useStyles();
const {setOpenApplicationModal,userData,onAlert} =props;
const [registrationData, setRegistrationData] = useState([]);
const [accountData, setAccountData] = useState({});
// const [openModal, setOpenModal] = useState(true);
const [cmsOpen, setCmsOpen] = useState(false);
const [cmsYn, setCmsYn] = useState(true);

const locState = props.location.state ||null;   
const lockKlnetId = (locState&&locState.lockKlnetId)||null;  
const [checkBizNumState , setCheckBizNumState] = useState({
        COMP_NO : false, BILL_COM_NO : false, USED_COM_NO : false,
    });

// console.log(locState,lockKlnetId)
useEffect(()=>{
    if(!locState||locState&&locState.agreeYN!=='Y'){
        onAlert( 'error','약관동의가 필요한 페이지입니다.');
        window.location.href = "/";
    }else if(locState&&lockKlnetId&&locState.agreeYN=='Y'){
        selectCompanyServiceInfo();
    }else{
        onAlert( 'error','신청할 수 없는 정보입니다. 다시 시도하시기 바랍니다.');
    }
}, [])
useEffect(()=>{
    if(registrationData.CMS_AGREE!=='Y'){
        setCmsYn(false);
    }else{
        setCmsYn(true);
        // selectCompanyAccountInfo()
    };
}, [cmsOpen])

useEffect(()=>{
    if(cmsYn){
        selectCompanyAccountInfo()
    }else{
        setAccountData({});
    };
}, [cmsYn])

const selectCompanyServiceInfo = async() =>{
    await axios.post("/api/selectCompanyServiceInfo",{ klnetId:lockKlnetId})
    .then(
        async(res) => {
            const result = res.data;
            if(result){
                let checkedBizNum ={
                    COM_NO :result.COM_NO? await validation.checkCompanyNumber(result.COM_NO) : false
                    ,BILL_COM_NO :result.BILL_COM_NO? await validation.checkCompanyNumber(result.BILL_COM_NO) : false
                    ,USED_COM_NO :result.USED_COM_NO? await validation.checkCompanyNumber(result.USED_COM_NO) : false
                };
                setCheckBizNumState(checkedBizNum);
                setRegistrationData(result);

                //3.CMS신청 필요
                if((result.COMP_TYPE=='A'&&result.CHG_YN=='Y')
                ||result.COMP_TYPE=='B'||result.COMP_TYPE=='C'||result.COMP_TYPE=='X'){
                    setCmsOpen(true);
                    if(result.CMS_AGREE!=='Y'){
                        setCmsYn(false);
                    }else{
                        setCmsYn(true);
                        // selectCompanyAccountInfo();
                    }
                }
            }
        }
    );
}

const selectCompanyAccountInfo = ()=>{
    axios.post("/api/selectCompanyAccountInfo",{ klnetId: lockKlnetId,}
    ).then(res => {
            const result = res.data;
            if(result.length>0){
                setAccountData({...result[0],
                CMS_AGREE : result[0].CMS_AGREE=='Y'?true:false ,
                CMS_THIRD_AGREE :result[0].CMS_THIRD_AGREE=='Y'?true:false});
            }else{
                setAccountData({});
            }
        }
    );
}

const fncOnBlurData =(index, registrationData)=> {
    setRegistrationData({...registrationData});
}

const submitRegistration = async ()=>{
    if( !validation.fncValidationMui() ) {
        props.onAlert("error","필수값 또는 입력가능을(를) 확인하세요.",);
        return false;
    };

    if(cmsYn&&(!accountData.CMS_AGREE ||!accountData.CMS_THIRD_AGREE)){
        props.onAlert("error","CMS 자동이체 약관동의는 필수입니다.",);
        return false;
    }   
    let result = await Confirm({
        title: '작성 완료하시겠습니까?',
        message: ' 수정사항이 발생시, \n 이용신청서를 출력 후 팩스(02-538-3078) 재송부하여 주시기 바랍니다.',
        confirmText: "확인",
        cancelText: '취소',
    });

    if(result){
        await axios.post(
            "/api/updateCompanyInfo",{
            klnetId: lockKlnetId,
            serviceData: registrationData,
            accountData:accountData,
            userno:userData?userData.user_no:'GUEST'
            }
        ).then( res => {
            if(res.data){
                props.onAlert("success",'정상적으로 제출되었습니다.');
                setTimeout(  
                    function(){
                        userData
                        ?window.location.href = "/svc/profile"
                        :window.location.reload()
                    }
                ,2000);
            }else{
                props.onAlert("error",'오류가 발생하여 제출이 되지 않았습니다.');
            }
        }).catch(e=> console.log(e));
    }
}

const fncReportViewer= async() => {
    try{ 
        await axios.post("/api/insertCompReport",{klnetId:lockKlnetId})
        .then(res => {
            if(res.data){
                const faxKey=res.data.fax_key[0];

                var obj = {}
                obj.klnet_id = lockKlnetId;
                obj.fax_key = faxKey;
                var json = JSON.stringify(obj);

                let form = document.reportForm;
                // form.action = 'http://localhost:5000/api/reportViewer';
                form.action = '/api/reportViewer';
                form.target = 'popReport'; // window.open() 의 두번째 변수와 동일해야.
                form.file_id.value = 'USERSERVICE';
                form.file_path.value = 'USERSERVICE'
                form.name.value = 'FILENAME';
                form.connection.value = 'logisvw';
                form.parameters.value = json;
                window.open('', 'popReport', 'width=1050px, height=850px');
                form.submit();
            }
        });
    }catch(err){
        console.log(err);
        onAlert('error',validation.ERR_MSG);
    };
}

return (
<React.Fragment>
    {lockKlnetId&&registrationData.COMP_TYPE== 'A'
    ?registrationData&&registrationData.CHG_YN =='Y'
        ?<div>
            <div style={{display:'flex', justifyContent:'center' }}>
                <h4 className={classes.modalTitle}>
                    <ViewComfy fontSize={'medium'} style={{marginRight: '20px'}}/>
                    KL-Net 서비스 이용신청서 
                </h4>
            </div>
            <div style={{display:'flex',justifyContent:'flex-end' ,padding:'0 40px'}}>
                <label style={{display:'flex', alignItems:'center'}}>
                    <LogisviewCheckbox  checked={false} />
                    <span>신규</span>
                </label>
                <label style={{display:'flex', alignItems:'center', marginLeft:'12px'}}>
                    <LogisviewCheckbox  checked={true}/>
                    <span>변경</span>
                </label>
            </div>
        </div>
        :
        <h4 className={classes.modalTitle}>
            <ViewComfy fontSize={'medium'} style={{marginRight: '20px'}}/>
            최신 청구 정보 입력     
        </h4>
    :''}
    {lockKlnetId&&(registrationData.COMP_TYPE== 'B' ||registrationData.COMP_TYPE== 'C' ||registrationData.COMP_TYPE== 'X') ? 
    <div>
        <div style={{display:'flex', justifyContent:'center' }}>
            <h4 className={classes.modalTitle}>
                <ViewComfy fontSize={'medium'} style={{marginRight: '20px'}}/>
                KL-Net 서비스 이용신청서 
            </h4>
        </div>
        <div style={{display:'flex',justifyContent:'flex-end' ,padding:'0 40px'}}>
            <label style={{display:'flex', alignItems:'center'}}>
                <LogisviewCheckbox  checked={true} />
                <span>신규</span>
            </label>
            <label style={{display:'flex', alignItems:'center', marginLeft:'12px'}}>
                <LogisviewCheckbox  checked={false}/>
                <span>변경</span>
            </label>
        </div>
    </div>
    :''}
    {lockKlnetId?<>
    <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
        <div className={classes.tableLine} style ={{height:'100%',overflow:'initial'}}>
            <div 	
            className={classes.table}
            aria-labelledby="tableTitle"
            size='medium'
            >
                <div style={{margin:'0 10px'}}>
                    <ApplicationParamsForm
                    registrationData={registrationData}
                    fncOnBlurData={fncOnBlurData}
                    cmsOpen={cmsOpen}
                    setCmsOpen={(e)=>setCmsOpen(e)}
                    cmsYn={cmsYn}
                    setCmsYn ={(e)=>setCmsYn(e)}
                    accountData={accountData} 
                    setAccountData={(e)=>setAccountData(e)}
                    selectCompanyAccountInfo={selectCompanyAccountInfo}
                    checkBizNumState={checkBizNumState}
                    setCheckBizNumState={(e)=>setCheckBizNumState(e)}
                    lockKlnetId={userData?userData.klnet_id : lockKlnetId ||null}
                    {...props}
                    /> 
                </div>
            </div>
        </div>      
        {lockKlnetId&&!(registrationData.COMP_TYPE=='A'&&registrationData.CHG_YN!=='Y')&&
        <p style={{fontSize:'12px', wordBreak:'keep-all' ,textAlign:'end',color: '#f44336'}}>
            * 수정사항이 발생시, 이용신청서를 출력 후 팩스(02-538-3078) 재송부하여 주시기 바랍니다.
        </p>
        }
    </DialogContent> 
    <DialogActions className={classes.modalFooter} >
    {lockKlnetId&&!(registrationData.COMP_TYPE=='A'&&registrationData.CHG_YN!=='Y')&&
        <Button color="warning" onClick={fncReportViewer} 
        disabled={!(registrationData&&registrationData.AGREE_YN=='Y')}
        >이용신청서</Button>
    }
        {/* <div onClick={()=>{
        if((!locState||locState&&locState.agreeYN!=='Y')||(registrationData&&registrationData.AGREE_YN=='Y')){
        props.onAlert("error",'승인 대기 중입니다.\n 팩스 접수 후, 미승인 상태가 계속될 시 문의 바랍니다. ');
        return false;
        }}}> */}
            <Button color="info" onClick={submitRegistration} 
            // disabled={(!locState||locState&&locState.agreeYN!=='Y')||(registrationData&&registrationData.AGREE_YN=='Y')||false}
            >작성 완료</Button>
        {/* </div> */}

      
    </DialogActions>
    </>:''}
    <form id="reportForm" name="reportForm" >
    <input type="hidden" name="system_id"   value="LOGISVIEW" />
    <input type="hidden" name="user_id"     value={`${(userData && userData.user_no)||'GUEST'}`} />
    <input type="hidden" name="file_type"   value="pdf" />
    <input type="hidden" name="file_id"     value="" />
    <input type="hidden" name="file_path"   value="" />
    <input type="hidden" name="name"        value="" />
    <input type="hidden" name="connection"  value="logisvw" />
    <input type="hidden" name="parameters" id="parameters"/>
    </form>
</React.Fragment>
);
}

export default ApplicationModal;