// export const  trkImages = {
//     m_ts_icon_barge_end :'/trk/m_ts_icon_barge_end.png',

export const m_ts_icon_barge_end = require('./trk/m_ts_icon_barge_end.png');
export const m_ts_icon_barge_start = require('./trk/m_ts_icon_barge_start.png');
export const m_ts_icon_rail_end = require('./trk/m_ts_icon_rail_end.png');
export const m_ts_icon_rail_start = require('./trk/m_ts_icon_rail_start.png');
export const m_ts_icon_truck_end = require('./trk/m_ts_icon_truck_end.png');
export const m_ts_icon_truck_start = require('./trk/m_ts_icon_truck_start.png');
export const m_ts_icon_vsl_1 = require('./trk/m_ts_icon_vsl_1.png');
export const m_ts_icon_vsl_2 = require('./trk/m_ts_icon_vsl_2.png');
export const m_ts_icon_vsl_3 = require('./trk/m_ts_icon_vsl_3.png');
export const m_ts_icon_vsl_end = require('./trk/m_ts_icon_vsl_end.png');
export const m_ts_icon_vsl_start = require('./trk/m_ts_icon_vsl_start.png');
export const pinOff = require('./trk/pin-off.png');
export const pinOn = require('./trk/pin-on.png');
// export const time_dot_A = require('./trk/time_dot_A.jpg');
export const time_dot_A = require('./trk/time_dot_A.png');
// export const time_dot_B = require('./trk/time_dot_B.jpg');
export const time_dot_E = require('./trk/time_dot_E.png');
export const ts_dot_1 = require('./trk/ts_dot_1.png');
export const ts_dot_2 = require('./trk/ts_dot_2.png');
export const ts_dot_end = require('./trk/ts_dot_end.png');
export const ts_dot_start = require('./trk/ts_dot_start.png');
export const ts_icon_barge_end = require('./trk/ts_icon_barge_end.png');
export const ts_icon_barge_start = require('./trk/ts_icon_barge_start.png');
export const ts_icon_rail_end = require('./trk/ts_icon_rail_end.png');
export const ts_icon_rail_start = require('./trk/ts_icon_rail_start.png');
export const ts_icon_truck_end = require('./trk/ts_icon_truck_end.png');
export const ts_icon_truck_start = require('./trk/ts_icon_truck_start.png');
export const ts_icon_vsl_2 = require('./trk/ts_icon_vsl_1.png');
export const ts_icon_vsl_3 = require('./trk/ts_icon_vsl_2.png');
export const ts_icon_vsl_4 = require('./trk/ts_icon_vsl_3.png');
export const ts_icon_vsl_end = require('./trk/ts_icon_vsl_end.png');
export const ts_icon_vsl_start = require('./trk/ts_icon_vsl_start.png');
export const ts_line_1 = require('./trk/ts_line_1.png');
export const ts_line_2 = require('./trk/ts_line_2.png');
export const ts_line_3 = require('./trk/ts_line_3.png');
export const ts_line_plain = require('./trk/ts_line_plain.png');

// }