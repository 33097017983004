import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'axios';
import {format, isValid} from 'date-fns';
import {CSVLink} from 'react-csv';
import Filesaver from 'file-saver';

import { lighten, makeStyles,withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import Grid from '@material-ui/core/Grid';
import Button from "muiComponents/CustomButtons/Button.js";
import { TableFooter, CircularProgress } from '@material-ui/core';
import CalendarBox from "muiComponents/CustomInput/CustomCalendar.js";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'CARRIER_CODE', numeric: false, disablePadding: true, label: 'CARRIER' },
  { id: 'RECV_CNAME', numeric: false, disablePadding: true, label: '업체명' },
  { id: 'BL_NO', numeric: false, disablePadding: true, label: 'BL No.' },
  { id: 'INVOICE_TYPE', numeric: false, disablePadding: true, label: 'DEM / DET' },
  { id: 'POD', numeric: false, disablePadding: true, label: 'POD' },
  { id: 'CNTR_NO', numeric: false, disablePadding: true, label: 'CNTR No.' },
  { id: 'CNTR_TYPE', numeric: false, disablePadding: true, label: 'CNTR TYPE' },
  { id: 'CNTR_SIZE', numeric: false, disablePadding: true, label: 'CNTR SIZE' },
  { id: 'ETA', numeric: false, disablePadding: true, label: 'ETA(ATA)' },
  { id: 'OUT_DUE_DATE', numeric: false, disablePadding: true, label: '반출 기한' },
  { id: 'RET_DUE_DATE', numeric: false, disablePadding: true, label: '반납 기한' },
  { id: 'OVER_DAYS', numeric: false, disablePadding: true, label: 'OVER' },
  { id: 'M_GUBUN', numeric: false, disablePadding: true, label: '단위' },
  { id: 'CHARGE', numeric: false, disablePadding: true, label: '예상 비용' },
  { id: 'HISTORY_SEQ', numeric: false, disablePadding: true, label: '선사 Notice' },
  { id: 'DUE_DATE', numeric: false, disablePadding: true, label: '반출/납 예정일' },
];
const TableSortLabelCustom = withStyles((theme)=>({
  icon:{
    margin:0,
    fontSize:'14px'
  },

}))(TableSortLabel)
function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{    height:'56px'}}>
      <TableRow>
        <TableCell padding="checkbox"
        style={{paddingTop:'0',paddingBottom:'0',borderBottomWidth:'3px',fontWeight:'bold',fontWeight:'500',color:'#717172',borderBottom:'2px solid #00b1b7'}} >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{paddingTop:'0',paddingBottom:'0',borderBottomWidth:'3px',color:'#717172',borderBottom:'2px solid #00b1b7'}} 
          >
            <TableSortLabelCustom
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className ={classes.headLabel}
            >
              <span style ={{    fontSize: '12px',
                fontWeight: '500'
            }}>{headCell.label}</span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabelCustom>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
    minHeight: '48px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected , allData, updateDueDate,selected} = props;
  
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title}  id="tableTitle" component="div" style = {{fontSize:'14px' ,fontWeight:'500',color: '#66615b'}} >
          기준일 : {format(new Date(),'yyyy-MM-dd')}
        </Typography>
      )}
      <Grid container justifyContent='flex-end'  style={{textAlign:'center', }}>
        {allData.length>0?
          <CSVLink data={allData}
          headers={headCells.filter(x=>x.id !== 'HISTORY_SEQ').map(item =>{ return {label: item.label , key : item.id}})}
          filename="import_demdet_data.csv">
            <Button color="success" 
            id='btnExport'size="sm" disabled={allData.length<1} >EXCEL 다운로드</Button>
          </CSVLink>
        :<Button color="success" id='btnExport'size="sm" disabled={allData.length<1} >EXCEL 다운로드</Button>
        }
        <Button color="linkedin" size="sm" onClick = {updateDueDate} disabled={selected.length<1}>반출납 예정 일자 전송</Button>
			</Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    borderTop:'2px solid #00b1b7', 
    borderBottom:'2px solid #00b1b7',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headLabel:{
    justifyContent: 'center ',
    width:'100%'
  },
}));

export default function EnhancedTable(props) {
  const {rows,loading,alertMessage,userData} = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    setSelected([])
	},[rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(rows);
      return;
    }
    setSelected([]);
  };

  const handleSelectList = (e, data) => {
    if(data){
      let newSelected = [];
      if(selected.findIndex(x=>x.RNUM==data.RNUM)<0) {
				newSelected = [...selected,data];
			} else {
				newSelected = selected.filter((x)=>x.RNUM !== data.RNUM);		
			}
      // console.log(newSelected)
      setSelected(newSelected);
    }
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDueDate = (row, date) => {
    setSelected( old=>[...old].map((val)=>val.RNUM == row.RNUM? {...val, DUE_DATE : date }: val  ))
  };

  const isSelected = (rnum) => selected.findIndex(x=>x.RNUM==rnum)!== -1; //selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const updateDueDate = ()=>{
    // console.log(selected)
    if(selected.length<1){return false }
    if(selected.findIndex(x=>x.DUE_DATE&&!isValid(x.DUE_DATE))>-1){
      alertMessage('입력 날짜를 확인하세요.','error');
      return
    }else{
      let setParams = selected.map(item=>item.DUE_DATE? {...item, DUE_DATE: format(item.DUE_DATE,'yyyyMMdd')} :item)
      axios.post("/loc/updateDueDate",{selected:setParams, klnetId:userData.klnet_id}
      ).then(res => {
        if(res.data==true){ 
          alertMessage('반납 예정 일자 전송이 성공하였습니다.','success'); 
          props.onSubmit();
				}else{
					alertMessage('반납 예정 일자 전송 실패하였습니다.','error'); 
				}
     }).catch(err => {
        if(err.response.status === 403||err.response.status === 401) {
          props.openLogin();
        }else{
          alertMessage('오류가 발생했습니다.','error');
          console.log(err)
        }
      });
    }
  }

  const downloadNoti= (HISTORY_SEQ)=>{
    if(loading || rows.length<1){
      alertMessage('다운로드할 데이터가 없습니다.','error');
    }else{

      axios.post("/api/downloadbyURL",{ type : 'demdetNoti' , file : HISTORY_SEQ,}, {responseType:'blob',})
      .then(res => {
        let blob = new Blob([res.data], {type:'application/pdf'})
          Filesaver.saveAs(blob,`noti_${HISTORY_SEQ}`);
      }).catch(err => {
          // console.log(err)
          alertMessage('다운로드에 실패하였습니다. 관리자 문의바랍니다.','error');
      });
    }
  }

  return (
    <div className={classes.root}>
        <EnhancedTableToolbar numSelected={selected.length} allData = {rows} updateDueDate={updateDueDate} selected={selected}/>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            stickyHeader={true}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {loading 
              ? (<TableRow style={{ height: '330px'}}><TableCell colSpan={17} style={{textAlignLast:'center'}}>
                <CircularProgress /></TableCell></TableRow>)
              :rows.length>0 //|| emptyRows > 0 
              ?stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.RNUM);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let selectedItem=isItemSelected&&selected.find(x=>x.RNUM==row.RNUM)
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.RNUM}
                      selected={isItemSelected}
                      style ={{height:'30px'}}
                    >
                      <TableCell padding="checkbox" onClick={(event) => {handleSelectList(event, row)}}>
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" 
                                 align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.CARRIER_CODE}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.RECV_CNAME}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.BL_NO}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.INVOICE_TYPE }</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.POD}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.CNTR_NO}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.CNTR_TYPE}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.CNTR_SIZE}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.ETA}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.OUT_DUE_DATE}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.RET_DUE_DATE}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.OVER_DAYS}</TableCell>
                      <TableCell align="center" onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">{row.M_GUBUN=='1'?'KRW':row.M_GUBUN=='2'?'USD':'-'}</TableCell>
                      <TableCell align="right"  onClick={(event) => {handleSelectList(event, row)}} style ={{fontSize:"12px",paddingRight:'14px',minHeight:'30px'}}>{row.CHARGE>0?row.CHARGE:' '}</TableCell>
                      <TableCell align="center" onClick={(event) => {!row.HISTORY_SEQ&&(handleSelectList(event, row))}} 
                      style ={{fontSize:"12px",paddingRight:'14px'}} padding="none">
                        {row.HISTORY_SEQ?  <Button color="warning" size="sm" style={{height:'18px'}} onClick={()=>downloadNoti(row.HISTORY_SEQ)}>다운로드</Button> : '-'}
                      </TableCell>
                      <TableCell align="center" style ={{fontSize:"12px",paddingRight:'14px'}} padding="none" onClick={(event) => {!isItemSelected&&handleSelectList(event, row)}}>
                      {isItemSelected?
                        <CalendarBox
                        id={ `enhanced-calendar-${index}`}
                        format="yyyy/MM/dd"
                        setValue={selectedItem&&selectedItem.DUE_DATE}
                        onChangeValue={(date)=> handleChangeDueDate(row, date)}
                        style ={{width:'75px'}}
                        InputAdornmentProps={{position:'start' ,style :{display:'none'}}}
                        emptyLabel={'yyyy/mm/dd'}
                        autoOk={true}
                      />
                      : row.DUE_DATE|| ''}
                      </TableCell>
                    </TableRow>
                  );
                })
              : (
                <TableRow style={{ height: '330px'}}> {/* (dense ? 33 : 53) * emptyRows }}> */}
                  <TableCell colSpan="17" align="center" style={{fontSize:"10px"}}>NO DATA</TableCell>	
                </TableRow>
              )}
            </TableBody>
            {(rows.length >= 10 ?
              <TableFooter>
                  <TableRow >
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}                      
                      // component="div"
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      colSpan={17}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
            </TableFooter>
            : null )}
          </Table>
          <div style={{margin: '8px 2px', display: 'flex', justifyContent: 'flex-end', color: '#66615b',fontSize: '12px'}}>
            예상 비용은 실제 청구 비용과 다를 수 있으니, 정확한 비용은 선사와 확인 바랍니다.<br/>
            선사 Notice는 발행일부터 최대 30일까지 다운로드 가능합니다.</div>    
        </TableContainer>
    </div>
  );
}
