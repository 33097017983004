import 'date-fns';
import React,{useState} from 'react';
import {InputAdornment, } from '@material-ui/core';
import {makeStyles, styled ,ThemeProvider} from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import krLocale from 'date-fns/locale/ko';
import {DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const defaultMaterialTheme = createTheme ({
  palette:{
      primary:{main:'#06b3ba',contrastText:'#fff'}
  },
  overrides:{
    
  }
})


const useStyles = makeStyles({
    root:{
      cursor:'pointer'
      ,display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      boxSizing: 'initial',
      width:'calc( 100% - 2px - 0px )',
      paddingLeft:'0px',
      height:'40px',
      overflow:'Hidden',
      border:'1px solid #4ea7ad',
      borderRadius:'8px',
      background:'#fff',
    },
   input:{
    display:'flex',
    marginLeft:'20px',
    fontSize:'16px',
    color:'#111',
    fontWeight:'500',
    cursor:'pointer',
    fontFamily: 'Noto Sans KR,sans-serif',
    padding:'initial'
  },
  MuiInputAdornment:{
    // root:{
      display:'flex'
      ,marginRight:'10px'
    // }
  }
})
export default function DatePickersCustom(props) {
const classes=useStyles()
const [focusStyle,setFocusStyle] = useState('assetsNew/image/ico/ico-calendar.png')
const {formControlProps,labelText,id,format,onChangeValue,setValue,autoOk,variant,disabled, type} = props;

  return (
    <ThemeProvider theme ={defaultMaterialTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={krLocale}>
          <DatePicker 
          // disableToolbar
          openTo='month'
          views={['year','month']}
          variant={variant}
          onOpen={()=>{setFocusStyle(`assetsNew/image/ico/ico-calendar-on.png`)}}
          onClose={()=>setFocusStyle(`assetsNew/image/ico/ico-calendar.png`)}
          format={format}
          autoOk={autoOk}
          value={setValue}
          onChange={onChangeValue}
          InputProps={{...props.InputProps, classes:{input:classes.input, root:classes.root},disableUnderline:true ,
          endAdornment:<InputAdornment position= 'end' classes={{root:classes.MuiInputAdornment}}>
            <img src={process.env.PUBLIC_URL + focusStyle } />
          </InputAdornment>
        }}
        />
    </MuiPickersUtilsProvider></ThemeProvider>
  )
}