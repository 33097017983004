/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import { Row,Col, CardBody, Collapse,
     Button,FormGroup,Label, Card,  UncontrolledTooltip,FormFeedback} from "reactstrap";
// import Select from "react-select";
// import ScheduleBookmark from './ScheduleBookmark.js';
// import axios from 'axios';
import moment from 'moment';
import InputValid from "components/CustomInput/InputValid.js";
import * as validation from 'components/common/validation.js';

const ScheduleCard = forwardRef((props,scheduleFocus) => {
	// const localizer = momentLocalizer(Moment);
	const {loadData, openWindow, toggle, } = props;
	// Collapse Flag
	const [coll, setColl] = useState(false);
	// modal 창을 위한 state
	const [schedule, setSchedule] = useState(props.loadData);
	// const [lineVesselList ,setLineVesselList] = useState([]);
    // const [polPortCodeList, setPolPortCodeList] = useState([]);
    // const [podPortCodeList, setPodPortCodeList] = useState([]);
    // const [porPortCodeList, setPorPortCodeList] = useState([]);
    // const [pldPortCodeList, setPldPortCodeList] = useState([]);
	// const [fdpPortCodeList,setFdpPortCodeList] = useState([]);
	// const [searchPol, setSearchPol] = useState(loadData.sch_pol?loadData.sch_pol:"");
    // const [searchPod, setSearchPod] = useState(loadData.sch_pod?loadData.sch_pod:"");
    // const [searchPor, setSearchPor] = useState(loadData.sch_por?loadData.sch_por:"");
    // const [searchPld, setSearchPld] = useState(loadData.sch_pld?loadData.sch_pld:"");
	// const [searchFdp, setSearchFdp] = useState(loadData.sch_fdp?loadData.sch_fdp:"");


	
	useEffect(() => {
		setSchedule({...loadData,'sch_bl_issue_name':loadData.sch_bl_issue_name?loadData.sch_bl_issue_name:'SEOUL, KOREA'});
		// if(userData) {
		// 	if( !loadData.sch_pol ) {
		// 		selectPolCodePortList({port_code: null});
		// 	} else {
		// 		if( schedule.sch_pol !== loadData.sch_pol ){
		// 			//FDP의 경우 전체 Port조회
		// 			selectPolCodePortList({port_code:loadData.sch_pol});
		// 		}
		// 	}
		// 	if( !loadData.sch_pod ) {
		// 		selectPodCodePortList({port_code: null});
		// 	} else {
		// 		if( schedule.sch_pod !== loadData.sch_pod ){
		// 			//FDP의 경우 전체 Port조회
		// 			selectPodCodePortList({port_code:loadData.sch_pod});
		// 		}
		// 	}
		// 	if( !loadData.sch_por ) {
		// 		selectPorCodePortList({port_code: null});
		// 	} else {
		// 		if( schedule.sch_por !== loadData.sch_por ){
		// 			//FDP의 경우 전체 Port조회
		// 			selectPorCodePortList({port_code:loadData.sch_por});
		// 		}
		// 	}
		// 	if( !loadData.sch_pld ) {
		// 		selectPldCodePortList({port_code: null});
		// 	} else {
		// 		if( schedule.sch_pld !== loadData.sch_pld ){
		// 			//FDP의 경우 전체 Port조회
		// 			selectPldCodePortList({port_code:loadData.sch_pld});
		// 		}
		// 	}
		// 	if( !loadData.sch_fdp ) {
		// 		selectFdpCodePortList({port_code: null});
		// 	} else {
		// 		if( schedule.sch_fdp !== loadData.sch_fdp ){
		// 			//FDP의 경우 전체 Port조회
		// 			selectFdpCodePortList({port_code:loadData.sch_fdp});
		// 		}
		// 	}
		// }
	},[loadData]);
	// useEffect(() => {
	// 	if(lineCode) {
	// 		// selectLineCodeVesselName({line_code:lineCode});
	// 		axios.post('/api/selectLinePort',{params:{line_code:lineCode,key:'out'}}).then(res=> {
    //             setStartPortList(res.data);
    //         })
    //         axios.post('/api/selectLinePort',{params:{line_code:lineCode,key:'in'}}).then(res=> {
    //             setEndPortList(res.data);
    //         })
	// 	}
	// },[lineCode])
	useEffect(() => {
		setColl(openWindow);
	},[openWindow]);
	
	
    // useEffect(()=>{
	// 	if( searchPol ) {
    //         selectPolCodePortList({
    //             port_code: searchPol
    //         });
    //     }
    // },[searchPol]);
	// useEffect(()=>{
	// 	if( searchPod ) {
    //         selectPodCodePortList({
    //             port_code: searchPod
    //         });
    //     }
    // },[searchPod]);
	// useEffect(()=>{
	// 	if( searchPor ) {
    //         selectPorCodePortList({
    //             port_code: searchPor
    //         });
    //     }
    // },[searchPor]);
	// useEffect(()=>{
	// 	if( searchPld ) {
    //         selectPldCodePortList({
    //             port_code: searchPld
    //         });
    //     }
    // },[searchPld]);
    // useEffect(()=>{
	// 	if( searchFdp ) {
    //         selectFdpCodePortList({
    //             port_code: searchFdp
    //         });
    //     }
    // },[searchFdp]);
	// useEffect(()=> {
	// 	setSearchPol(schedule.sch_pol)
	// },[schedule.sch_pol])
	// useEffect(()=> {
	// 	setSearchPod(schedule.sch_pod)
	// },[schedule.sch_pod])
	// useEffect(()=> {
	// 	setSearchPor(schedule.sch_por)
	// },[schedule.sch_por])
	// useEffect(()=> {
	// 	setSearchPld(schedule.sch_pld)
	// },[schedule.sch_pld])
	// useEffect(()=> {
	// 	setSearchFdp(schedule.sch_fdp)
	// },[schedule.sch_fdp]);

	// const getNationList = () => {
    //     axios.post('/api/getNationList',{}).then(res=> {
    //         if(res.data.length > 0) {
    //             setNationList(res.data);
    //         }else {
    //             setNationList([]);
    //         }
    //     })
    // }
	// const toggleSchedule = () => {
	// 	// setOpenSchedule(!openSchedule)
	// 	setScheduleCalendarOpen(!scheduleCalendarOpen);
	// 	// setCarrierValue(lineCode||null)
	// }
	// const selectLineCodeVesselName = (params) => {
	// 	axios.post("/shipper/selectLineCodeVesselName",{ params }).then(res=>{
	// 		setLineVesselList(res.data);
	// 	});
	// }
	// const selectPolCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setPolPortCodeList(res.data);
    //     });
    // }
	// const selectPodCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setPodPortCodeList(res.data);
    //     });
    // }
	// const selectPorCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setPorPortCodeList(res.data);
    //     });
    // }
	// const selectPldCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setPldPortCodeList(res.data);
    //     });
    // }
	// const selectFdpCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setFdpPortCodeList(res.data);
    //     });
    // }
	const onHandleReturnVal = (event,name) => {
		let list = {...schedule, [name]:(event.target.value.toUpperCase())||null};
		setSchedule(list);
	}
	
	const onPropsReturn = ()=> {
		props.mergeData(schedule);
	}

	// const selectedEventCalendar = (event) => {
	// 	// if( 'WDFC' === event.line_code) {
	// 	// 	props.alert(()=>{
	// 	// 		history.push({
	// 	// 		  pathname: `/svc/srWdfc`,
	// 	// 		  state:{
	// 	// 			...event,
	// 	// 			schedule_yn:'Y'
	// 	// 			,doc_new: "Y"
	// 	// 			,line_code:'WDFC'
	// 	// 			,sch_vessel_name:event.vsl_name||null
	// 	// 			,sch_vessel_voyage:event.voyage_no||null
	// 	// 			,sch_pol:event.start_port||null
	// 	// 			,sch_pod:event.end_port||null	
	// 	// 		  }
	// 	// 		});
	// 	// 	  }
	// 	// 	  , "위동 스케줄 선택 하셨습니다.",'warning'
	// 	// 	  ,true,true,'lg',"위동 SR 화면으로 이동하시겠습니까?",true,true,
	// 	// 	  null
	// 	// 	  );
	// 	// }else{
	// 		if( "N" === event.booking_yn ) {
	// 			props.onAlert("error","해당 스케줄은 선택 불가합니다.");
	// 			return false;
	// 		} else {
	// 			 if(lineCode && lineCode !== event.line_code){
	// 				props.alert(()=>{
	// 				// 	createNewSr(event.line_code,()=>eventInfofn(event))}
	// 				let eventInfo = eventInfofn(event);
	// 				history.push({
	// 					pathname: 'WDFC' === event.line_code?`/svc/srWdfc`:`/svc/sr`,
	// 					state:{
	// 					  ...eventInfo,
	// 					  schedule_yn:'Y'
	// 					  ,doc_new: "Y"
	// 					}
	// 				  });
	// 				}
	// 				,'기존 선사 정보와 일치하지 않습니다','warning'
	// 				,true,true,'lg','SR 을 새로 생성하시겠습니까?',true,true,()=> {return false});
	// 			}else{
	// 				changeSchInfo(event);
	// 			}
	// 			toggleSchedule();
	// 		}
	// 	// }
    // };
	// const eventInfofn =(event)=>{
	// 	return {
	// 		...event,
	// 		sch_por:event.sch_por?event.sch_por:event.sch_pol,
	// 		sch_por_name:event.sch_por_name?event.sch_por_name:event.sch_pol_name,
	// 		sch_pld:event.sch_pld?event.sch_pld:event.sch_pod,
	// 		sch_pld_name:event.sch_pld_name?event.sch_pld_name:event.sch_pod_name,
	// 		sch_fdp:event.sch_fdp?event.sch_fdp:event.sch_pod,
	// 		sch_fdp_name:event.sch_fdp_name?event.sch_fdp_name:event.sch_pod_name,
	// 		sch_vessel_name:event.vsl_name||null,
	// 		sch_vessel_voyage:event.voyage_no||null,
	// 		sch_pol:event.start_port||null,
	// 		sch_pod:event.end_port||null,
	// 		}
	// }
	
	// const changeSchInfo =(event)=>{
	// 			let user_no = schedule.user_no;
	// 			let bkg_no = schedule.sr_no;
	// 			let bkg_date = schedule.sr_date;
	// 			let eventInfo = eventInfofn(event);
	// 			// setSearchPol(event.sch_pol);
	// 			// setSearchPod(event.sch_pod);
	// 			// setSearchPor(event.sch_por?event.sch_por:event.sch_pol);
	// 			// setSearchPld(event.sch_pld?event.sch_pld:event.sch_pod);
	// 			// setSearchFdp(event.sch_fdp?event.sch_fdp:event.sch_pod);
	// 			setLineCode(event.line_code);
	// 			setSchedule({
	// 				...schedule,...eventInfo,
	// 				user_no:user_no,
	// 				bkg_no:bkg_no,
	// 				bkg_date:bkg_date,
	// 			});
	// 			props.mergeData({
	// 				...schedule,...eventInfo,
	// 				user_no:user_no,
	// 				bkg_no:bkg_no,
	// 				bkg_date:bkg_date,
	// 				});
	// }
	
  	return (
    	<>
			<Row id="Schedule">
				<Col xl="12" lg="12">
					<Card style={{zIndex:'10',border:'1px solid silver',borderRadius:'10px'}}>
						<CardBody className="pt-3 pb-0">  
							<Row className="pb-2">
								<Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>SCHEDULE
									<Button className="pl-1" color="link" id="lineview" onClick={toggle}><i className="fa fa-pencil-square-o fa-2x"/></Button>
                                    <UncontrolledTooltip delay={0} target="lineview">Schedule</UncontrolledTooltip>
								</Col>
								<Col>
								</Col>
							</Row>
							<Collapse isOpen={coll}>
								<hr className="mt-0"/>
								<Row style={{fontSize:'12px'}}>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-3">
													<Label className="mb-0">Vessel/Voy</Label>
												</Col>
												<Col className="pl-0">
													<Row>
														<Col className="col-7 pr-1" onClick={(e)=>props.onAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
															<InputValid 
																// type="hidden"
																name="sch_vessel_name"
																id="sch_vessel_name"
																maxLength="35"
																value={schedule.sch_vessel_name?schedule.sch_vessel_name:''}
																onBlur={onPropsReturn}
																validtype="eng"
																required={false} 
																feedid="schedule"
																readOnly
															/>
														</Col>
														<Col className="text-center pl-0 pr-0 pt-1">/
														</Col>
														<Col className="col-4 pl-1">
															<InputValid 
																type="text"
																name="sch_vessel_voyage"
																id="sch_vessel_voyage"
																maxLength="17"
																value={schedule.sch_vessel_voyage?schedule.sch_vessel_voyage:''}
																onChange={(e)=>onHandleReturnVal(e, 'sch_vessel_voyage')}
																onBlur={onPropsReturn}
																validtype="eng"
																required={false} 
																feedid="schedule"
																readOnly
																/>
														</Col>
													</Row>
												</Col>
											</Row>
										</FormGroup> 
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-3">
													<Label className="mb-0">POL</Label>
												</Col>
												<Col className="pl-0">
													<Row>
														<Col xl="4" className="col-4 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
														<InputValid
															// hidden
															type="text"
															name="sch_pol"
															id="sch_pol"
															maxLength="5"
															value={schedule.sch_pol ?schedule.sch_pol : ''}
															validtype="select"
															required={true}
															feedid="schedule"
															readOnly
															/>
														</Col>
														<Col className={schedule.sch_srd?"col-6 pl-1":"col-8 pl-1"}>
															<FormFeedback>{validation.REQ_MSG}</FormFeedback>
															<InputValid 
																type="text"
																name="sch_pol_name"
																id="sch_pol_name"
																maxLength="35"
																value={schedule.sch_pol_name?schedule.sch_pol_name:''}
																onChange={(e)=>onHandleReturnVal(e, 'sch_pol_name')}
																onBlur={onPropsReturn}
																validtype="eng"
																required={false} 
																feedid="schedule"
																// readOnly
															/>
														</Col>
														{(schedule.sch_srd) &&
														<Col xl="2" className="col-2 pl-1">
															<Label className="mt-2">{schedule.sch_srd?moment(schedule.sch_srd).format('MM-DD'):''}</Label>
														</Col>}
													</Row>
												</Col>
											</Row>
										</FormGroup> 
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-3">
													<Label className="mb-0">Place Of Receipt</Label>
												</Col>
												<Col className="pl-0">
													<Row>
														<Col xl="5" className="col-5 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
														<InputValid
																// hidden
																type="text"
																name="sch_por"
																id="sch_por"
																maxLength="5"
																value={schedule.sch_por ? schedule.sch_por : ''}
																validtype="select"
																required={true}
																feedid="schedule"
																readOnly
															/>
														</Col>
														<Col xl="7" className="col-7 pl-1">
															<InputValid 
																type="text"
																name="sch_por_name"
																id="sch_por_name"
																maxLength="35"
																value={schedule.sch_por_name?schedule.sch_por_name:''}
																onChange={(e)=>onHandleReturnVal(e, 'sch_por_name')}
																onBlur={onPropsReturn}
																validtype="eng"
																required={false} 
																feedid="schedule"
																// readOnly
															/>
														</Col>
													</Row>
												</Col>
											</Row>
										</FormGroup> 
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-3">
													<Label className="mb-0">POD</Label>
												</Col>
												<Col className="pl-0">
													<Row>
														<Col xl="4" className="col-4 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
															<InputValid
																// hidden
																type="text"
																name="sch_pod"
																id="sch_pod"
																maxLength="5"
																value={schedule.sch_pod ? schedule.sch_pod : ''}
																validtype="select"
																required={true}
																feedid="schedule"
																readOnly
																/>
														</Col>
														<Col className={schedule.sch_eta?"col-6 pl-1":"col-8 pl-1"}>
															<InputValid 
																type="text"
																name="sch_pod_name"
																id="sch_pod_name"
																maxLength="35"
																value={schedule.sch_pod_name?schedule.sch_pod_name:''}
																onChange={(e)=>onHandleReturnVal(e, 'sch_pod_name')}
																onBlur={onPropsReturn}
																validtype="eng"
																required={false}
																feedid="schedule"
																// readOnly
															/>
														</Col>{(schedule.sch_eta) &&
														<Col xl="2" className="col-2 pl-1">
															<Label className="mt-2">{schedule.sch_eta?moment(schedule.sch_eta.substr(0,8)).format('MM-DD'):''}</Label>
														</Col>}
													</Row>
												</Col>
											</Row>
										</FormGroup> 
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-3">
													<Label className="mb-0">Place Of Delivery</Label>
												</Col>
												<Col className="pl-0">
													<Row>
														<Col xl="5" className="col-5 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
															<InputValid
																// hidden
																type="text"
																name="sch_pld"
																id="sch_pld"
																maxLength="5"
																value={schedule.sch_pld ? schedule.sch_pld : ''}
																validtype="select"
																required={true}
																feedid="schedule"
																readOnly
															/>
														</Col>
														<Col xl="7" className="col-7 pl-1">
															<InputValid 
																type="text"
																name="sch_pld_name"
																id="sch_pld_name"
																maxLength="35"
																value={schedule.sch_pld_name?schedule.sch_pld_name:''}
																onChange={(e)=>onHandleReturnVal(e, 'sch_pld_name')}
																onBlur={onPropsReturn}
																validtype="eng"
																required={false} 
																feedid="schedule"
																// readOnly
																/>
														</Col>
													</Row>
												</Col>
											</Row>
										</FormGroup> 
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-3">
													<Label className="mb-0">Final Des</Label>
												</Col>
												<Col className="pl-0">
													<Row>
														<Col xl="5" className="col-5 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
															<InputValid
																// hidden
																type="text"
																name="sch_fdp"
																id="sch_fdp"
																maxLength="5"
																value={schedule.sch_fdp ? schedule.sch_fdp : ''}
																validtype="select"
																required={true}
																feedid="schedule"
																readOnly
															/>
														</Col>
														<Col xl="7" className="col-7 pl-1">
															<InputValid 
																type="text"
																name="sch_fdp_name"
																id="sch_fdp_name"
																maxLength="35"
																value={schedule.sch_fdp_name?schedule.sch_fdp_name:''}
																onChange={(e)=>onHandleReturnVal(e, 'sch_fdp_name')}
																onBlur={onPropsReturn}
																validtype="eng"
																required={false} 
																feedid="schedule"
																// readOnly
															/>
														</Col>
													</Row>
												</Col>
											</Row>
										</FormGroup> 
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col xl="3" className="pr-0 pt-1 col-3">
													<Label className="mb-0" >Place Of B/L Issue</Label>
												</Col>
												<Col xl="9" className="pl-0 col-9">
													<InputValid 
														type="text"
														name="sch_bl_issue_name"
														id="sch_bl_issue_name"
														maxLength="35"
														value={schedule.sch_bl_issue_name?schedule.sch_bl_issue_name:''}
														onChange={(e)=>onHandleReturnVal(e, 'sch_bl_issue_name')}
														onBlur={onPropsReturn}
														validtype="eng"
														required={false} 
														feedid="schedule"
														/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
								</Row>
							</Collapse>
						</CardBody>
						<Col className="text-center col-12 p-0" onClick={() => setColl(!coll)}>        
							<Button
								className="p-0"
								color="link"
								//outline
								id="shlmore"
								onClick={() => setColl(!coll)}
								style={{height:'21px',marginBottom:'4px',width:'100%'}}>
								{coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
							</Button>
							<UncontrolledTooltip delay={0} target="shlmore">{coll?'Close':'Open'}</UncontrolledTooltip>
						</Col>	
					</Card>    
				</Col>
			</Row>
			{/* <Modal isOpen={open} toggle={toggle} className="pt-0" size="xl">
				<ModalHeader toggle={toggle} className="pt-3 pb-3">Schedule BookMark</ModalHeader>
					<ModalBody className="p-3">
						<ScheduleBookmark 
							bookmark={bookmark}
							loadData={propsData}
							onPropsSchBookmark={onBookMarkData}
							onPropsSchDeleteBookmark={onBookMarkDelete}
							getVsl={lineVesselList}
							onAlert={props.onAlert}/>
					</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={onInitData}>NEW</Button>
					<Button color="primary" onClick={onSaveBookmark}>SAVE</Button>
					<Button color="primary" onClick={onBookMarkDelete}>DELETE</Button>
					<Button color="secondary" onClick={()=>setOpen(!open)}>CANCEL</Button>
				</ModalFooter>
			</Modal> */}
		</>
	);
});

export default ScheduleCard;



