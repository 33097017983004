import React , { useState, useEffect, useRef, useCallback} from "react";
import {Row,Col,CardBody,UncontrolledTooltip,Container,Form,Button,
    Card, Collapse} from "reactstrap";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import ScheduleCard from './schedule/ScheduleCard.js';
import ShipperCard from './shipper/ShipperCard.js';
import ForwarderCard from "./forwarder/ForwarderCard.js";
import ConsigneeCard from "./consignee/ConsigneeCard.js";
import DocumentCard from './document/DocumentCard.js';
import TransportCard from "./transport/TransportCard.js";
import CargoCard from "./cargo/CargoCard.js";
import ContainerCard from "./container/ContainerCard.js";
import BookingCard from "./booking/BookingCard.js";
import BookingTitleCard from "./booking/BookingTitleCard.js";
import MemoCard from "./booking/MemoCard.js";
import ScheduleCalendarModal from "muiComponents/Modals/ScheduleCalendarModal.js";
// import 'assets/css/App.css';
import axios from 'axios';
import * as validation from 'muiComponents/common/validation.js';
import Confirm from 'components/Confirm/Confirm.js';
import Switch from "react-bootstrap-switch";
import InputValid from 'components/CustomInput/InputValid.js';
import moment from 'moment';
import LogisviewPageHeader from 'components/Headers/LogisviewPageHeader.js';
// import { LocalDrinkSharp } from '@material-ui/icons';
// import LineCode from 'views/TestPage/DemDetOsc.js';
// import CardHeader from 'muiComponents/Card/CardHeader.js';
//import { Redirect } from "react-router-dom";

export default function BookingMain( props ) {
    const history = useHistory();
    const {userData} = props;
    const [booking, setBooking] = useState({});
    const [allOpen, setAllOpen] = useState(true);
    const [openSchedule, setOpenSchedule] = useState(true);
    const [openShipper, setOpenShipper] = useState(true);
    const [openForwarder, setOpenForwarder] = useState(true);
    const [openDocument, setOpenDocument] = useState(false);
    const [openCargo, setOpenCargo] = useState(true);
    const [openTrans, setOpenTrans] = useState(true);
    const [openBooking, setOpenBooking] = useState(true);
    const [openReqMemo, setOpenReqMemo] = useState(true);
    const [openContainer, setOpenContainer] = useState(true);
    const [openConsignee, setOpenConsignee] = useState(true);
    // Container Send 전 필수체크를 위한 정보
    const [containerList, setContainerList] = useState([]);
    // Container Special List 
    const [containerSpecialList, setContainerSpecialList] = useState([]);
    // Cargo
    const [cargo, setCargo] = useState({});
    // Cargo Goods
    const [goodsRelationList, setGoodsRelationList] = useState([]);
    // Door 입력항목
    const [door, setDoor] = useState({});
    const [allAttachFiles, setAllAttachFiles] = useState({})
    
    //schedule modal
    const [scheduleCalendarOpen, setScheduleCalendarOpen] = useState(false);
    const [scheduleMode, setScheduleMode] = useState('');

    // 채번
    const [autoSelf, setAutoSelf] = useState(true);
    const [bkgNoDupCheck, setBkgNoDupCheck] = useState(false);
    const [params, setParams] = useState({});
    const [paramSchedule,setParamSchedule] = useState({
        new_yn: props.location.state && props.location.state.new_yn ? props.location.state.new_yn || '' : null,
        user_no: props.location.state && props.location.state.user_no  ?  props.location.state.user_no || '' : null, 
        bkg_no: props.location.state && props.location.state.bkg_no  ? props.location.state.bkg_no || '' : null,  
        bkg_date: props.location.state && props.location.state.bkg_date ? props.location.state.bkg_date || '' : null,
        sch_vessel_name: props.location.state && props.location.state.sch_vessel_name ? props.location.state.sch_vessel_name || '' : null,
        sch_vessel_code: props.location.state && props.location.state.sch_vessel_code ? props.location.state.sch_vessel_code || '' : null,
        sch_vessel_voyage: props.location.state && props.location.state.sch_vessel_voyage ? props.location.state.sch_vessel_voyage || '' : null,
        sch_pol: props.location.state && props.location.state.sch_pol ? props.location.state.sch_pol || '' : null,
        sch_pod: props.location.state && props.location.state.sch_pod ? props.location.state.sch_pod || '' : null,
        sch_por: props.location.state && props.location.state.sch_pol ? props.location.state.sch_pol || '' : null,
        sch_pld: props.location.state && props.location.state.sch_pod ? props.location.state.sch_pod || '' : null,
        sch_fdp: props.location.state && props.location.state.sch_pod ? props.location.state.sch_pod || '' : null,
        schedule_yn: props.location.state && props.location.state.schedule_yn ? props.location.state.schedule_yn || '' : null,
        line_code: props.location.state && props.location.state.line_code ? props.location.state.line_code || '' : null,
        sch_line_code: props.location.state && props.location.state.sch_line_code ? props.location.state.sch_line_code || '' : null,
        sch_etd: props.location.state && props.location.state.sch_etd ? props.location.state.sch_etd || '' : null,
        sch_eta: props.location.state && props.location.state.sch_eta ? props.location.state.sch_eta || '' : null,
        vsl_type: props.location.state && props.location.state.vsl_type ? props.location.state.vsl_type || '' : null,
        sch_call_sign: props.location.state && props.location.state.sch_call_sign ? props.location.state.sch_call_sign || '' : null
        ,new_bkg_no: props.location.state&&props.location.state.new_bkg_no || null
        ,plism_line_code: props.location.state&&props.location.state.plism_line_code|| null
        ,bkg_type: props.location.state&&props.location.state.bkg_type|| null
    });
    const [newBkgNo, setNewBkgNo] = useState(null);
    // other Bookmark
    const [otherList, setOtherList] = useState([]);
    // schedule Bookmark
    const [scheduleList, setScheduleList] = useState([]);
    // line Bookmark
    const [lineList, setLineList] = useState([]);
    // shipper Bookmark
    const [shipperList, setShipperList] = useState([]);
    // consignee Bookmark
    const [consigneeList, setConsigneeList] = useState([]);
    // Forwarder Bookmark
    const [forwarderList, setForwarderList] = useState([]);
    // Accounting& Document Bookmark
    const [documentList, setDocumentList] = useState([]);
    const [documentUnionList, setDocumentUnionList] = useState([]);
    // Transport Bookmark
    const [transportList, setTransportList] = useState([]);
    // Cargo Bookmark
    const [cargoBookmarkList, setCargoBookmarkList] = useState([]);
    // Container Bookmark
    const [containerBookmarkList, setContainerBookmarkList] = useState([]);
    // Bookmark Seq
    const [bookmarkSeq, setBookmarkSeq] = useState({});
    // Booking Bookmark 전체 적용정보
    const [relationList, setRelationList] = useState([]);
    // 위험물인 경우 dangerTrue = True
    const [dangerTrue, setDangerTrue] = useState(false);
    // Shipper Company 목록
    const [shipperCompanyList, setShipperCompanyList] = useState([]);
    // Forwarder Company 목록
    const [forwarderCompanyList, setForwarderCompanyList] = useState([]);
    // FocusTarget 어느건지
    const [focusTarget, setFocusTarget] = useState("");
    const [lineCode, setLineCode] = useState(null);
    const [lineViewerCode, setLineViewerCode] = useState('');
    const [lineConfig, setLineConfig] = useState({});
    // focusing 처리를 위한 useRef
    const bookingFocus = useRef(null);
    const scheduleFocus = useRef(null);
    const shipperFocus = useRef(null);
    const consigneeFocus = useRef(null);
    const forwarderFocus = useRef(null);
    const documentFocus = useRef(null);
    const transportFocus = useRef(null);
    const cargoFocus = useRef(null);
    const containerFocus = useRef(null);
    const memoFocus = useRef(null);

    useEffect(()=> {
        props.fncClickMenu("NAV_REQUEST");
        // console.log(userData);
        if( userData && userData.user_no &&lineCode ) {
            // Other Bookmark 조회
            selectBookingOtherBookmark();
            // Schedule Bookmark 조회
            selectBookingScheduleBookmark();
            // Line(Carrier) Bookmark 조회
            selectBookingLineBookmark();
            // Shipper Bookmark 조회
            selectBookingShipperBookmark();
            // Consignee Bookmark 조회
            selectBookingConsigneeBookmark();
            // Forwarder Bookmark 조회
            selectBookingForwarderBookmark();
            // Document&Accounting Bookmark 조회
            selectBookingDocumentBookmark();
            // Transport Bookmark 조회
            selectBookingTransportBookmark();
            // Cargo Bookmark 조회
            selectBookingCargoBookmark();
            // Container Bookmark 조회
            selectBookingContainerBookmark();
            // Shipper 여부 확인 및 Shipper 목록 조회
            selectShipperCompanyListByUser();
            // Forwarder 여부 확인 및 Forwarder 목록 조회
            selectForwarderCompanyListByUser();
            checkconfig(lineCode);
        }
    },[lineCode]);

    useEffect(() => {
        if(!userData) {
            props.onAlert('danger','로그인이 필요한 서비스입니다.');
            window.history.back();
        }else if(props.location.state&&props.location.state?.line_code||paramSchedule.line_code){
            setLineCode(props.location.state?.line_code||paramSchedule.line_code);
        }else if (!lineCode){ 
           fncCheckLinecode();
        }
	},[]);
    

    useEffect(()=> {
        //document Bookmark는 union하여 보여준다
        let unionList = _.cloneDeep( [...shipperList,...forwarderList,...documentList]) 
        let result = unionList.sort((a,b)=> new Date(b.insert_date)- new Date( a.insert_date) );
        result.map((obj,index)=> {
            obj.value = index;
            obj.document_bookmark_seq=index;
        })
        setDocumentUnionList(result)
    },[shipperList,forwarderList,documentList]);

    useEffect(()=> {
        if(cargo&&cargo.cargo_type && cargo.cargo_type === '3') {
            setDangerTrue(true)
        } else {
            setDangerTrue(false)
        }
    },[cargo&&cargo.cargo_type])

    useEffect(()=>{
        if( params.bkg_no && userData && userData.user_no ) {
            // Dash 보드 및 일반 파라미터에서 넘어온 경우
            selectBookingParams();
            selectCargoOfBooking();
        } else {
            if( userData && userData.user_no ) {
                if( "Y" === params.schedule_yn || "Y" === params.new_yn ) {
                    // 스케줄에서 넘어온 경우
                    insertBooking();
                }
            }
        }
    },[params]);

    useEffect(()=>{
        if(paramSchedule) {
            if(paramSchedule.new_bkg_no){setNewBkgNo(paramSchedule.new_bkg_no)}
            setParams(paramSchedule);
            setLineCode(paramSchedule.line_code);
        }
    },[paramSchedule])


    useEffect(()=>{
        setOpenSchedule(allOpen);
        setOpenShipper(allOpen);
        setOpenForwarder(allOpen);
        // setOpenDocument(allOpen);
        setOpenCargo(allOpen);
        setOpenTrans(allOpen);
        setOpenBooking(allOpen);
        setOpenReqMemo(allOpen);
        setOpenContainer(allOpen);
        setOpenConsignee(allOpen);
    },[allOpen]);


    // useEffect(()=>{
    //     if( bkgNoDupCheck ) {
    //         insertBooking();
    //     }
    // },[bkgNoDupCheck]);

    // 전체 Bookmark 조회
    useEffect(()=>{
        if( bookmarkSeq && bookmarkSeq.bookmark_seq) {
            selectBookingBkgBookmarkRelation();
        }
    }, [bookmarkSeq])

    // Booking Bookmark 전체 적용
    // 2021.10.19 북마크 적용 입력안한게 있으면 유지, 입력한것만 바꿔줌
    useEffect(()=>{
        let obj = Object();
        obj.bookmark_seq = bookmarkSeq.bookmark_seq;
        obj.bookmark_yn = 'Y';
        if( relationList.length > 0 ) {
            relationList.forEach( function( element ) {
                if( 'BOOKING' === element.reference_type ) {
                    obj.other_bookmark_name = element.bookmark_name;
                    obj.other_bookmark_seq = element.reference_seq;
                    
                    // Other Bookmark 상세내용
                    let row = otherList.find( ( item ) => {
                        return item.other_bookmark_seq === element.reference_seq;
                    });
                    
                    if( row ) {                        
                        obj.sc_no = row.sc_no?row.sc_no:booking.sc_no;
                        obj.remark1 = row.remark1?row.remark1:booking.remark1;
                        obj.remark2 = row.remark1?row.remark2:booking.remark2;
                        obj.trans_service_code = row.trans_service_code?row.trans_service_code:booking.trans_service_code;
                        obj.shp_payment_type= row.shp_payment_type?row.shp_payment_type:booking.shp_payment_type;
                    }
                }
                if( 'SCHEDULE' === element.reference_type ) {
                    obj.schedule_bookmark_name = element.bookmark_name;
                    obj.schedule_bookmark_seq = element.reference_seq;

                    // Schedule Bookmark 상세내용
                    let row = scheduleList.find( ( item ) => {
                        return item.schedule_bookmark_seq === element.reference_seq;
                    });
                    if( row ) {
                        obj.schedule_bookmark_seq = row.schedule_bookmark_seq?row.schedule_bookmark_seq:booking.schedule_bookmark_seq;
                        obj.schedule_bookmark_name = row.schedule_bookmark_name?row.schedule_bookmark_name:booking.schedule_bookmark_name;
                        obj.sch_vessel_name = row.sch_vessel_name?row.sch_vessel_name:booking.sch_vessel_name;
                        obj.sch_vessel_code = row.sch_vessel_code?row.sch_vessel_code:booking.sch_vessel_code;
                        // port 5코드 이 입력되어 있다면 같이 port name도 세팅되어야한다. 2021.10.19
                        obj.sch_pol = row.sch_pol?row.sch_pol:booking.sch_pol;
                        obj.sch_pol_name = row.sch_pol?row.sch_pol_name:booking.sch_pol_name;
                        obj.sch_pod = row.sch_pod?row.sch_pod:booking.sch_pod;
                        obj.sch_pod_name = row.sch_pod?row.sch_pod_name:booking.sch_pod_name;
                        obj.sch_por = row.sch_por?row.sch_por:booking.sch_por;
                        obj.sch_por_name = row.sch_por?row.sch_por_name:booking.sch_por_name;
                        obj.sch_pld = row.sch_pld?row.sch_pld:booking.sch_pld;
                        obj.sch_pld_name = row.sch_pld?row.sch_pld_name:booking.sch_pld_name;
                        obj.sch_etd = row.sch_etd?row.sch_etd:booking.sch_etd;
                        obj.sch_eta = row.sch_eta?row.sch_eta:booking.sch_eta;
                        obj.sch_fdp = row.sch_fdp?row.sch_fdp:booking.sch_fdp;
                        obj.sch_fdp_name = row.sch_fdp?row.sch_fdp_name:booking.sch_fdp_name;
                        // obj = Object.assign(obj, row);
                    }
                }
                if( 'SHIPPER' === element.reference_type ) {
                    obj.shipper_bookmark_name = element.bookmark_name;
                    obj.shipper_bookmark_seq = element.reference_seq;

                    // Shipper Bookmark 상세내용
                    let row = shipperList.find( ( item ) => {
                        return item.shipper_bookmark_seq === element.reference_seq;
                    });
                    if( row ) {
                        obj.shp_name1= row.shp_name1?row.shp_name1:booking.shp_name1;
                        obj.shp_code= row.shp_code?row.shp_code:booking.shp_code;
                        obj.shp_user_email= row.shp_user_email?row.shp_user_email:booking.shp_user_email;
                        // obj = Object.assign(obj, row);
                    } 
                }
                if( 'FORWARDER' === element.reference_type ) {
                    obj.forwarder_bookmark_name = element.bookmark_name;
                    obj.forwarder_bookmark_seq = element.reference_seq;

                    // Forwarder Bookmark 상세내용
                    let row = forwarderList.find( ( item ) => {
                        return item.forwarder_bookmark_seq === element.reference_seq;
                    });
                    if( row ) {
                        obj.fwd_name1 = row.fwd_name1?row.fwd_name1:booking.fwd_name1;
                        obj.fwd_code = row.fwd_code?row.fwd_code:booking.fwd_code;
                        obj.fwd_user_email = row.fwd_user_email?row.fwd_user_email:booking.fwd_user_email;
                        obj.fwd_user_fax = row.fwd_user_fax?row.fwd_user_fax:booking.fwd_user_fax;
                        obj.fwd_user_name = row.fwd_user_name?row.fwd_user_name:booking.fwd_user_name;
                        obj.fwd_user_tel = row.fwd_user_tel?row.fwd_user_tel:booking.fwd_user_tel;
                        obj.fwd_user_dept = row.fwd_user_dept?row.fwd_user_dept:booking.fwd_user_dept;
                        // obj = Object.assign(obj, row);
                    }
                }
                if( 'DOCUMENT' === element.reference_type ) {
                    obj.document_bookmark_name = element.bookmark_name;
                    obj.document_bookmark_seq = element.reference_seq;

                    // Accounting Bookmark 상세내용
                    let row = documentList.find( ( item ) => {
                        return item.document_bookmark_seq === element.reference_seq;
                    });
                    // console.log(e.label);
                    if( row ) {
                        obj.doc_user_name = row.doc_user_name?row.doc_user_name:booking.doc_user_name;
                        obj.doc_user_tel = row.doc_user_tel?row.doc_user_tel:booking.doc_user_tel;
                        obj.doc_user_fax = row.doc_user_fax?row.doc_user_fax:booking.doc_user_fax;
                        obj.docu_user_phone = row.docu_user_phone?row.docu_user_phone:booking.docu_user_phone;
                        obj.doc_user_email = row.doc_user_email?row.doc_user_email:booking.doc_user_email;
                        obj.docu_tax_email = row.docu_tax_email?row.docu_tax_email:booking.docu_tax_email;
                        obj.docu__address1 = row.docu__address1?row.docu__address1:booking.docu__address1;
                        obj.docu__address2 = row.docu__address2?row.docu__address2:booking.docu__address2;
                        // obj = Object.assign(obj, row);
                    }
                }
                if( 'TRANSPORT' === element.reference_type ) {
                    obj.transport_bookmark_name = element.bookmark_name;
                    obj.transport_bookmark_seq = element.reference_seq;

                    // Transport Bookmark 상세내용
                    let row = transportList.find(( item ) => {
                        return item.transport_bookmark_seq === element.reference_seq;
                    });
                    if( row ) {
                        obj.trans_name1 = row.trans_name1?row.trans_name1:booking.trans_name1;
                        obj.trans_name2 = row.trans_name1?row.trans_name2:booking.trans_name2;
                        obj.trans_code = row.trans_code?row.trans_code:booking.trans_code;
                        obj.trans_self_yn = row.trans_self_yn?row.trans_self_yn:booking.trans_self_yn;
                        obj.trans_user_fax = row.trans_user_fax?row.trans_user_fax:booking.trans_user_fax;
                        obj.trans_user_name = row.trans_user_name?row.trans_user_name:booking.trans_user_name;
                        obj.trans_user_tel = row.trans_user_tel?row.trans_user_tel:booking.trans_user_tel;
                        obj.trans_user_email = row.trans_user_email?row.trans_user_email:booking.trans_user_email;
                        // obj.trans_fac_name = row.trans_fac_name?row.trans_fac_name:booking.trans_fac_name;
                        // obj.trans_fac_area_name = row.trans_fac_area_name?row.trans_fac_area_name:booking.trans_fac_area_name;
                        // obj.trans_remark = row.trans_remark?row.trans_remark:booking.trans_remark;
                        // obj = Object.assign(obj, row);
                    }

                }
                if( 'CARGO' === element.reference_type ) {
                    obj.cargo_bookmark_name = element.bookmark_name;
                    obj.cargo_bookmark_seq = element.reference_seq;
                    // obj.cargo_selected_yn = 'Y'
                    // Cargo Bookmark 상세내용
                    let row = cargoBookmarkList.find(( item ) => {
                        return item.cargo_bookmark_seq === element.reference_seq;
                    });
                    if( row ) {
                        obj.cargo_pack_qty = row.cargo_pack_qty?row.cargo_pack_qty:booking.cargo_pack_qty;
                        obj.cargo_pack_type = row.cargo_pack_type?row.cargo_pack_type:booking.cargo_pack_type;
                        obj.cargo_pack_type_name = row.cargo_pack_type_name?row.cargo_pack_type_name:booking.cargo_pack_type_name;
                        obj.cargo_total_volume = row.cargo_total_volume?row.cargo_total_volume:booking.cargo_total_volume;
                        // obj.cargo_total_weight = row.cargo_total_weight?row.cargo_total_weight:booking.cargo_total_weight;
                        obj.cargo_type = row.cargo_type?row.cargo_type:booking.cargo_type;
                        obj.cargo_type_name = row.cargo_type_name?row.cargo_type_name:booking.cargo_type_name;
                        obj.cargo_weight = row.cargo_weight?row.cargo_weight:booking.cargo_weight;
                        // obj = Object.assign(obj, row);
                    }
                }
                if( 'CONTAINER' === element.reference_type ) {
                    obj.container_bookmark_name = element.bookmark_name;
                    obj.container_bookmark_seq = element.reference_seq;

                    // Container Bookmark 상세내용
                    let row = containerBookmarkList.find(( item ) => {
                        return item.container_bookmark_seq === element.reference_seq;
                    });
                    if( row ) {
                        obj = Object.assign(obj, row);
                    }
                }
            });

            let merge = Object.assign(booking, obj);
            setBooking({...merge});
        } 
    },[relationList]);

    useEffect(()=>{
        setNewBkgNo('');
        if(!autoSelf)setBkgNoDupCheck(false);
    }, [autoSelf]);
    // useEffect(()=>{
    //     window.addEventListener("keydown", keyPress);

    //     return () => {
    //         window.removeEventListener("keydown", keyPress);
    //     }
    // })
    useEffect(()=>{
        
        if( "booking" === focusTarget ) {
            bookingFocus.current.focus();
        } else if ( "schedule" === focusTarget ) {
            scheduleFocus.current.focus();
        } else if ( "shipper" === focusTarget ) {
            shipperFocus.current.focus();
        } else if ( "forwarder" === focusTarget ) {
            forwarderFocus.current.focus();
        } else if ( "document" === focusTarget ) {
            documentFocus.current.focus();
        } else if ( "transport" === focusTarget ) {
            transportFocus.current.focus();
        } else if ( "cargo" === focusTarget ) {
            cargoFocus.current.focus();
        } else if ( "container" === focusTarget ) {
            containerFocus.current.focus();
        }else if ("memo" ===focusTarget){
            memoFocus.current.focust();
        }
    }, [focusTarget])

    //linecode 없을  시 캘린더 오픈
    const fncCheckLinecode = useCallback(_.debounce((e)=>{
        // let siteLineCode = props.site&&props.site.line_code;
        // if(siteLineCode){
        //     setLineCode(siteLineCode)
        // }else {
            setScheduleCalendarOpen(true)
        // }
        props.onAlert('danger',validation.NO_LINECODE_MSG);
        // fncCheckLinecode();
    },3000),[scheduleCalendarOpen]);

	const checkconfig =()=>{
		axios.post("/api/selectEshipConfig", {lineCode: lineCode}).then( res => {
			if(res.data && res.data.length > 0) {
				setLineConfig(res.data[0]);
				setLineViewerCode(res.data[0].line_email_code);
			} 
		}).catch(err => {
			if(err.response.status) {
				props.onAlert('danger','선사 정보를 가져오는데 실패하였습니다');
			}
		});
	}
    const selectBookingParams = () => {
        if( !(params.user_no && params.bkg_no && params.bkg_date) ) {
            return false;
        }
        axios.post("/shipper/selectBooking"
        ,{lineCode:lineCode 
            ,klnet_id : userData.klnet_id?userData.klnet_id:props.userData.klnet_id
            ,user_no : params.user_no
            ,params}).then(
            res => {
                const result =  res.data;
                if( 0 < result.length ) {
                    if( "D" === result[0].status_cud ) {
                        props.onAlert("danger","이미 BOOKING ["+result[0].booking_no+"] 가 삭제되었습니다."); 
                        setTimeout(
                            window.location.href="/svc/bookinglist",3000);
                    } else {
                        setBooking(result[0]);
                        // setFiles(result[0].attach_file);
                    }
                }
            });
    }

    // Booking 정보 취합
    const fncBookingParent = (booking) => {
        // 전체북마크는 초기화 해줘야함 그래야 재 호출이 되지 않아요
        let merge = Object.assign(booking, {'bookmark_yn':'N'});
        setBooking({...merge});
    }

    // 컨테이너 정보 취합
    const fncContainerParent =(cntrlist)=>{
        cntrlist.map((data,key)=>{
            data.cntr_seq = key+1;
        });
        setContainerList( cntrlist );
    }
    // 컨테이너 Special 정보 취합
    const fncContainerSpecialParent =(containerSpecialList)=>{
        setContainerSpecialList( containerSpecialList );
    }
    // Cargo 정보 취합
    const fncCargoParent = (cargo) => {
        setCargo( cargo );
    }
    // Cargo Goods 정보 취합
    const fncGoodsParent = (goodsRelationList)=> {
        setGoodsRelationList( goodsRelationList );
    }
    // 입력
    const insertBooking = () => {
        if( !userData ) {
            props.onNotiAlert("error",validation.NOTLOGIN_MSG);   
            return false;
        }
        // if( !userData.bkg_recipient ) {
        if( userData.klnet_id =='KLDUMY01') {
            props.onNotiAlert("error", validation.NO_BKG_RECIPIENT);
            return false;
        }
        //중복확인시 체크 (이동)
        // if( !autoSelf && !bkgNoDupCheck ) {
        //     props.onNotiAlert("error",validation.NO_DUP_CHECK_MSG);
        //     return false;
        // }
        axios.post("/shipper/insertBooking",{user_no: userData.user_no?userData.user_no:null
                                            ,klnet_id: userData.klnet_id
                                            ,newBkgNo: !autoSelf&&newBkgNo?newBkgNo:null
                                            ,booking
                                            ,params:params
                                            ,lineCode:lineCode})
            .then(res => {
                const result = res.data.rows; 
                if(result.length>0){
                    let data = res.data.rows[0];
                    let obj = Object.assign(params, data, {schedule_yn:'N',new_yn:'N'});
                    setParams({...params, obj});
                }else{
                    props.onNotiAlert("error",'[오류] 부킹 생성을 실패하였습니다');
                }
                if( !autoSelf ) {
                    setAutoSelf(!autoSelf);
                }
                // SELF 부킹화면 입력 되어 있으면 없애주자
                setNewBkgNo(null);
                // 닫혀 있으면 열어주자
                if( !allOpen ) {
                    setAllOpen(!allOpen);
                }
                // check 정보도 초기화
                if( bkgNoDupCheck ) {
                    setBkgNoDupCheck(!bkgNoDupCheck);
                }
                //lineCode 새로 체크
                if (!lineCode){fncCheckLinecode(); } 
                props.onAlert("success",validation.NEW_MSG);
        });
    }
    const fncOpenCardInvalid =()=>{
        if( validation.fncFeedIdInvalid('booking') ) {
            setOpenBooking(true);
        }
        if( validation.fncFeedIdInvalid('schedule') ) {
            setOpenSchedule(true);
        }
        if( validation.fncFeedIdInvalid('shipper') ) {
            setOpenShipper(true);
        }
        if( validation.fncFeedIdInvalid('consignee') ) {
            setOpenConsignee(true);
        }
        if( validation.fncFeedIdInvalid('forwarder') ) {
            setOpenForwarder(true);
        }
        if( validation.fncFeedIdInvalid('document') ) {
            setOpenDocument(true);
        }
        if( validation.fncFeedIdInvalid('transport') ) {
            setOpenTrans(true);
        }
        if( validation.fncFeedIdInvalid('cargo') ) {
            setOpenCargo(true);
        }
        if( containerList.length ===0 ) {
            setOpenContainer(true);
        }
        if( validation.fncFeedIdInvalid('container') ) {
            setOpenContainer(true);
        }
    }
    // SEND
    const sendBooking = async(status)=> {
        if( !userData ) {
            props.onNotiAlert("error",validation.NOTLOGIN_MSG);   
            return false;
        }
        // if( !userData.bkg_recipient ) {
        if( userData.klnet_id =='KLDUMY01') {
            props.onNotiAlert("error", validation.NO_BKG_RECIPIENT);
            return false;
        }

        if( status === 'SEND' ) {
            if( !('NO' === booking.status_cus || 'S0' === booking.status_cus || 'EJ' === booking.status_cus|| 'S5' === booking.status_cus) ) {
                props.onNotiAlert("error","부킹전송은 저장, 정정작성 또는 거절승인인 경우에만 가능합니다.");
                return false;
            }
        } else {
            if( !('RA' === booking.status_cus||'S5' === booking.status_cus||'4C' === booking.status_cus) ) {
                props.onNotiAlert("error","부킹취소는 승인인 경우에만 가능합니다.");
                return false;
            }
        }
        if( !booking.bkg_no ) return false;
        setAllOpen(false);
        let statusName = status==='CANCEL'?booking.status_cus=='S5'?'정정작성 취소':'CANCEL ':'SEND ';
        let result = await Confirm({
            title: (<>부킹번호 : {booking.bkg_no}</>),
            message: fncBookingTerms(statusName),
            confirmText: 'YES',//booking.status_cus=='S5'? 'YES': "SEND",
            cancelText: 'NO',//'Cancel',
            terms: false
        });
        if ( true === result ) {
            //부킹 전송
            if( status === 'SEND' ) {
                if( !validation.fncValidation() ) {
                    fncOpenCardInvalid();
                    props.onNotiAlert("danger","필수값 또는 입력가능을(를) 확인 후 다시 "+statusName+" 하세요.");
                    return false;
                };

                //cntr_code 체크
                let errorCntrCode='';
                await axios.post("/shipper/selectLineCodeCntrSztp",{params:{line_code:lineCode}}
                ).then(res => {
                    containerList.map((data,key)=> {
                        let cntrCodeExist=false;
                        if(res.data){
                            res.data.some(function(element){
                                if( element.cntr_code === data.cntr_code ){
                                    cntrCodeExist= true;
                                }
                            } ) 
                        }
                        data.cntr_seq = key +1;
                        if(!cntrCodeExist)errorCntrCode+= " ["+data.cntr_code+"] ";
                    });
                }).catch(err => {
                    props.onAlert("error","전송 중 에러가 발생했습니다. 다시 시도해주세요");
                    return false;
				});

                if(errorCntrCode){
                    props.onAlert("error","컨테이너 사이즈타입"+errorCntrCode+"을 다시 선택해주세요.");
                    return false;
                }
                
                axios.post("/shipper/updateAllBooking",{user_no: userData?userData.user_no:null,booking ,containerList, containerSpecialList, cargo, goodsRelationList, dangerTrue, door, lineCode:lineCode ,status : status},{}
                ).then(res => {
                    if(res.status === 200) {
                        setTimeout(() => {
                            sendBookingfn(status);
                        }, 1000);
                    }else{
                        props.onAlert("error",`저장하는 데 ${validation.ERR_MSG} 관리자에 문의바랍니다.` ); return false;
                    }
                }).catch(error => {
                    props.onAlert("error",`저장하는 데 ${validation.ERR_MSG} 관리자에 문의바랍니다.` ); return false;
                });
            } else {
                // 정정작성 취소
                if(booking.status_cus=='S5'){
                    axios.post("/shipper/cancelModifyStatus",{user_no : userData?userData.user_no:null,lineCode:lineCode, klnet_id: userData.klnet_id,booking,}).then(
                        res => {
                            history.push({pathname:`/svc/confirmList`})   
                        }   
                    ).catch(error => {
                        console.error(error)
                    })
                }else{// 취소인 경우 (정정작성 이외 )
                    sendBookingfn(status);
                }
            }
        }
    }
    const sendBookingfn = (status)=>{
        axios.post("/shipper/sendBooking",{
            user_no : userData?userData.user_no:null
            ,lineCode:lineCode
            , klnet_id: userData.klnet_id
            ,status : status
            ,booking
            ,cargo
            ,containerList
            ,containerSpecialList
            ,dangerTrue
            ,door}).then(
            res => {
                selectBookingParams();
                props.onAlert("success",validation.SEND_MSG);
                axios.post("/shipper/insertServiceTermsHistory",{klnet_id: userData.klnet_id,user_no: userData?userData.user_no:null,dv_cd: 'BK',status_cus: status,send_no: booking.bkg_no,line_code: lineCode,});
            }   
        ).catch(error => {
            const data = error.response.data;
            let message = [];
            if( data.service_code ) {
                setOpenBooking(true);
                message.push( data.service_code);
            }
            if( data.vessel_name || data.route) {
                setOpenSchedule(true);
                if( data.vessel_name )
                    message.push(data.vessel_name);
                if( data.route )
                    message.push(data.route);
            }
            if( data.cargo_pack_type ) {
                setOpenCargo(true);
                message.push(data.cargo_pack_type);
            }
            if( data.cntr_sztp || data.pick_up || data.danger ) {
                setOpenContainer(true);
                if( data.cntr_sztp )
                    message.push(data.cntr_sztp);
                if( data.pick_up )
                    message.push(data.pick_up);
                if( data.danger )
                    message.push(data.danger);
            }
            if( data.originator || data.recipient ) {
                    message.push(data.originator);
            }
            if( data.transport ) {
                setOpenTrans(true);
                message.push(data.transport);
            }
            if( data.danger) {
                setOpenContainer(true);
                message.push(data.danger);
            }
            if( data === "typeerror") {
                setOpenContainer(true);
                message.push("컨테이너 사이즈 타입을 동일하게 입력하셨습니다.")
            }
            if( data.company ) {
                message.push(...data.company);
            }
            if( message ) {
                // message=message.join('/n') 
                message=message&&Array.isArray(message)&&message.join('\n') 
                props.onNotiAlert('danger', message);
                return false;
            }
        });
    }
    // CargoOfBooking
    const selectCargoOfBooking = async() => {
        try{
            await axios.post("/shipper/selectCargoOfBooking",{ user_no: userData?userData.user_no:null, booking: {user_no:params.user_no, bkg_no: params.bkg_no, bkg_date: params.bkg_date}, lineCode:lineCode}).then(
                res => {
                    setCargo(res.data[0]);
                    selectCargoGoodsOfBooking(res.data[0]);
                }
            );
        }catch(e){console.error(e)}
    }
    const selectCargoGoodsOfBooking = async(booking) => {
        try{
            await axios.post("/shipper/selectCargoGoodsOfBooking",{ user_no: userData?userData.user_no:null, booking}).then(
                res => {
                    setGoodsRelationList(res.data);
                }
            );
        }catch(e){console.error(e)}
    }

    const fncBookingTerms =(statusName)=>{        
        return(
            <Row>
                <Col className="ml-auto mr-auto" xl="12" lg="12" md="12" sm="12">
                    <h4 className="mt-1 text-center">
                        {`BOOKING 을(를) ${statusName} 하시겠습니까?`}
                        {/* {"["+ booking.bkg_no+"] Booking 을(를) "+statusName+"하시겠습니까?"} */}
                    </h4>
                </Col>
            </Row>
        )
    }


    const fncOpenCardInvalidMaxLength =()=>{
        if( validation.fncFeedIdInvalidMaxLength('booking') ) {
            setOpenBooking(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('reqMemo') ) {
            setOpenReqMemo(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('schedule') ) {
            setOpenSchedule(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('shipper') ) {
            setOpenShipper(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('consignee') ) {
            setOpenConsignee(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('forwarder') ) {
            setOpenForwarder(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('document') ) {
            setOpenDocument(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('transport') ) {
            setOpenTrans(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('cargo') ) {
            setOpenCargo(true);
        }
        if( containerList.length ===0 ) {
            setOpenContainer(true);
        }
        if( validation.fncFeedIdInvalidMaxLength('container') ) {
            setOpenContainer(true);
        }
    }
    const updateAllBooking = async(e)=> {
        if( !('NO' === booking.status_cus || 'S0' === booking.status_cus || 'EJ' === booking.status_cus|| 'S5' === booking.status_cus) ) {
            props.onNotiAlert("error","부킹저장은 저장, 정정작성 또는 거절승인 인 경우에만 가능합니다.");
            return false;
        }
        
        if( !validation.fncValidationMaxLength() ) {
            fncOpenCardInvalidMaxLength();
            props.onNotiAlert("info","입력가능을(를) 확인 후 다시 저장 하세요.");
            return false;
        };

        let checkDate = false;
        let checkType = false;
        containerList.forEach((data)=>{
            if(data.cntr_soc_yn ==='N') {                
                if(data.cntr_pick_up_cy_date&& !moment(data.cntr_pick_up_cy_date,'YYYYMMDD', true).isValid()) {
                    checkDate=true;
                }

                if( !data.cntr_code ) {
                    checkType= true;
                }
            }
        });

        if( checkType ) {
            props.onNotiAlert("info","컨테이너 Size Type 입력하세요.");
            setOpenContainer(true);
            return false;
        }

        if( checkDate ) {
            props.onNotiAlert("info","컨테이너 Pick up 일자가 올바르지 않습니다.");
            setOpenContainer(true);
            return false;
        }

        containerList.forEach((data)=>{
            if( data.cntr_door_date ) {
                if( !moment(data.cntr_door_date,'YYYYMMDDHHmm').isValid() ) {
                    checkDate=true;
                }
            }
        });

        if( door.cntr_door_date ) {
            if( !moment(door.cntr_door_date,'YYYYMMDDHHmm').isValid() ) {
                checkDate=true;
            }
        }
    

        if( checkDate ) {
            props.onNotiAlert("info","컨테이너 배차오더일시가 올바르지 않습니다.");
            setOpenContainer(true);
            return false;
        }


        if( !booking.bkg_no ) return false;
        if( !userData ) {
            props.onNotiAlert("error",validation.NOTLOGIN_MSG);   
            return false;
        }
        setAllOpen(false);
        axios.post("/shipper/updateAllBooking",{user_no :userData?userData.user_no:null, booking, containerList, containerSpecialList, cargo, goodsRelationList, dangerTrue, door, lineCode:lineCode})
        .then(res => {
            if(res.status == 200) {
                props.onAlert("success",validation.SAVE_MSG);
                setParams({...params,schedule_yn:'N',new_yn:'N',});
            }else{
                props.onAlert("error",`저장하는 데 ${validation.ERR_MSG} 관리자에 문의바랍니다.` ); return false;
            }
        }).catch(error => {
            props.onAlert("error",`저장하는 데 ${validation.ERR_MSG} 관리자에 문의바랍니다.` ); return false;
        });
    }

    const fncOnChangeNewBkgNo =(e)=>{
        let selfBkgNo = e.target.value.toUpperCase();
        setNewBkgNo( selfBkgNo.trim());
    }

    const mainSumAttatchFile = (newFile) => {
        setAllAttachFiles(newFile)
    }
    
    const deleteAllFiles = () => {
        axios.post('/shipper/deleteAllAttachFile',{booking:booking,gubun:'BK'}).then(res=> {
            if(res.data ==='success') {
                setAllAttachFiles({});
                props.onAlert('success','삭제가 완료되었습니다.');
            }
        }).catch(e => {
            props.onAlert('danger','첨부파일 삭제 중 오류가 발생하였습니다.');
        })
    }

    const fncDupCheckBkgNo = (e)=>{
        if( !newBkgNo ) {
            props.onNotiAlert("danger",validation.NO_NULL_MSG);
            return false;
        }
        if( !validation.validationNo(newBkgNo) ) {
            props.onNotiAlert("danger",validation.NO_CHECK_MSG);
            return false;
        }
        if( validation.fncFeedIdInvalid('dup_bkg') ) {
			props.onNotiAlert("error","입력 값을 확인해주세요.");
			return false;
		}
        axios.post("/shipper/selectDupCheckBkgNo",{user_no : userData?userData.user_no:null, newBkgNo}).then(res => {
            if( res.data.length && res.data[0].bkg_no ) {
                props.onNotiAlert("danger","Booking 번호 중복입니다. 다시 입력하세요.");            
            } else {
                // fncSelfNewBkgNoCheck();
                setBkgNoDupCheck(true);
                toggle('NEW',true );
            }
        });
    }

    // const fncSelfNewBkgNoCheck = async() => {
    //     let title = "["+newBkgNo+"] 사용 가능" ;
    //     let message = "["+ newBkgNo+"] Booking "+validation.NO_MAKE_MSG;
    //     let result = await Confirm({
    //         title: title,
    //         message: message,
    //         confirmText: "SEND",
    //         cancelText: 'Cancel',
    //     });
    //     if ( true === result ) {
    //         if( !bkgNoDupCheck ) {
    //             setBkgNoDupCheck(!bkgNoDupCheck);
    //         }
    //     } else {
    //         setNewBkgNo(null);
    //         setAutoSelf(!autoSelf);
    //     }
    // }

    const fncGetBkgNo = ( bkgNo ) => {
        setBooking({...booking, 'bkg_no':bkgNo, 'bkg_date':null});
    }

    const fncReportViewer=()=> {
        if( !(booking.user_no && booking.bkg_date && booking.bkg_no ) ) {
            props.onNotiAlert("danger","Booking 먼저 조회 하세요.");
            return false;
        }
        if(!lineViewerCode) {
            fncCheckLinecode();
            return false;
        }

        var obj = {}
        obj.user_no = booking.user_no;
        obj.bkg_date = booking.bkg_date;
        obj.bkg_no = booking.bkg_no;
        obj.file_path =lineViewerCode;
        var json = JSON.stringify(obj);

        let form = document.reportForm;
        form.action = '/shipper/reportViewer';
        form.target = 'popReport'; // window.open() 의 두번째 변수와 동일해야.
        form.file_id.value = 'BOOKING';
        form.file_path.value = lineViewerCode;//라인코드없이는 레포트선택 x 
        form.name.value = 'FILENAME';
        form.connection.value = process.env.REACT_APP_DB=='ORACLE'?'logisvw' : 'pgsql';
        form.parameters.value = json;
        window.open('', 'popReport', 'width=1050px, height=850px');
        form.submit();
    }
    // OTHER 조회
    const selectBookingOtherBookmark = () => {
        axios.post("/shipper/selectBookingOtherBookmark",{ user_no: userData?userData.user_no:null,booking }).then(
            res => {
                setOtherList(res.data);
            }
        );
    }
    // SCHEDULE BOOKMARK 조회
    const selectBookingScheduleBookmark = () => {
        axios.post("/shipper/selectBookingScheduleBookmark",{ user_no: userData?userData.user_no:null ,lineCode:lineCode}).then(
            setScheduleList([])
        ).then(res => 
            setScheduleList(res.data)
        );
    }
    // Line Bookmark 조회
    const selectBookingLineBookmark = () => {
        axios.post("/shipper/selectBookingLineBookmark",{ user_no: userData?userData.user_no:null }).then(
            res => {
                setLineList(res.data);
            }
        );
    }
    // Shipper Bookmark 조회
    const selectBookingShipperBookmark = () => {
        axios.post("/shipper/selectBookingShipperBookmark",{ user_no: userData?userData.user_no:null }).then(
            setShipperList([])
        ).then(
            res => setShipperList(res.data)
        );
    }
    // Consignee Bookmark 조회
    const selectBookingConsigneeBookmark = () => {
        axios.post("/shipper/selectBookingConsigneeBookmark",{ user_no: userData?userData.user_no:null }).then(
            res => {
                setConsigneeList(res.data);
            }
        );
    }
    // Forwarder Bookmark 조회
    const selectBookingForwarderBookmark = () => {
        axios.post("/shipper/selectBookingForwarderBookmark",{ user_no: userData?userData.user_no:null}).then(
            res => {
                setForwarderList(res.data);
            }
        );
    }
    const selectBookingDocumentBookmark = () => {
        axios.post("/shipper/selectBookingDocumentBookmark",{ user_no: userData?userData.user_no:null}).then(
            res => {
                // console.log(res.data)
                setDocumentList(res.data);
            }
        );
    }
    // Transport Bookmark 조회
    const selectBookingTransportBookmark = () => {
        axios.post("/shipper/selectBookingTransportBookmark",{ user_no: userData?userData.user_no:null }).then(
            res => {
                setTransportList(res.data);
            }
        );
    }
    // Cargo Bookmark 조회
    const selectBookingCargoBookmark = () => {
        axios.post("/shipper/selectBookingCargoBookmark",{ user_no: userData?userData.user_no:null,line_code: lineCode }).then(
            res => {
                setCargoBookmarkList(res.data);
            }
        );
    }
    // Container Bookmark 조회
    const selectBookingContainerBookmark = () => {
        if(lineCode) {
            axios.post("/shipper/selectBookingContainerBookmark",{ user_no: userData?userData.user_no:null,line_code:lineCode}).then(res => {
                setContainerBookmarkList(res.data);
            });
        //}else {
            // props.onAlert('danger',validation.NO_LINECODE_MSG);
          //  fncCheckLinecode();   //console.log(validation.NO_LINECODE_MSG)
        }
    }

    const fncBookmarkParent = (e) => {
        if(lineCode) {
            if( e ) {
                let obj = Object();
                obj.bookmark_seq = e.value
                setBooking({...booking, bookmark_seq:e.value, bookmark_name:e.label})
                setBookmarkSeq(obj);
            } else {
                if( booking && booking.bookmark_seq ) {
                    let bkgNo = booking.bkg_no;
                    let userNo = booking.user_no;
                    let bkgDate = booking.bkg_date;
                    let statusCus = booking.status_cus;
                    let klnetId = booking.klnet_id;
                    let schLineCode = booking.sch_line_code;
                    setBooking({
                        bkg_no: bkgNo,
                        user_no: userNo,
                        bkg_date: bkgDate,
                        status_cus: statusCus,
                        klnet_id: klnetId,
                        line_code: lineCode,
                        sch_line_code: schLineCode,
                        bookmark_yn: 'D',
                    });
                }
            }
        }else {
            props.onAlert('danger','설정된 선사코드가 존재하지않습니다.');
        }
    }
    const selectBookingBkgBookmarkRelation = () => {
        axios.post("/shipper/selectBookingBkgBookmarkRelation",{user_no: userData?userData.user_no:null,bookmark: bookmarkSeq,}).then(res=>{
            setRelationList(res.data);
        });
    }

    // 삭제
    const deleteBooking = async() => {
        if( !booking.bkg_no ) return false;
       
        // 취소승인(캔슬컨펌) 또는 저장 상태인 것만 삭제 가능
        if( !("NO" === booking.status_cus || "S0" === booking.status_cus || "CC" === booking.status_cus) ) {
            props.onAlert("danger", "취소승인(캔슬컨펌) 또는 저장 상태만 삭제 가능합니다.");
            return false;
        }
        let title = "["+booking.bkg_no+"]" ;
        let message = "["+ booking.bkg_no+"] Booking 을(를) 삭제 하시겠습니까? 삭제한 경우 재조회가 불가합니다.";
        let result = await Confirm({
            title: title,
            message: message,
            confirmText: "DELETE",
            cancelText: 'Cancel',
        });
        if ( true === result ) {
            axios.post("/shipper/deleteBooking",{user_no : userData?userData.user_no:null,booking}).then(res=>{
                props.onAlert("success", validation.DEL_MSG);
                window.location.href="/svc/bookinglist";
            })
        }
    }
    const selectShipperCompanyListByUser = () => {
        if(lineCode) {
            axios.post("/shipper/selectShipperCompanyListByUser",{line_code: lineCode,klnet_id: userData.klnet_id,}).then(res=>{
                setShipperCompanyList(res.data);
            });
        }
    }
    const selectForwarderCompanyListByUser = () => {
        if(lineCode) {
            axios.post("/shipper/selectForwarderCompanyListByUser",{line_code: lineCode,klnet_id: userData.klnet_id,}).then(res=>{
                setForwarderCompanyList(res.data);
            });
        }
    }

    const fncDoorParent =(door)=> {
        setDoor(door);
    }

    const keyPress =(key)=> {
        if( key.key === 'F2' ) {
            if( !focusTarget ) {
                setFocusTarget("booking");
            } else if( "booking" === focusTarget ) {
                setFocusTarget("schedule");
            } else if( "schedule" === focusTarget ) {
                setFocusTarget("shipper");
            } else if( "shipper" === focusTarget ) {
                setFocusTarget("forwarder");
            } else if( "forwarder" === focusTarget ) {
                setFocusTarget("transport");
            } else if( "transport" === focusTarget ) {
                setFocusTarget("cargo");
            } else if( "cargo" === focusTarget ) {
                setFocusTarget("container");
            } else if( "container" === focusTarget ) {
                setFocusTarget(null);
            }
        }
    }

    const toggle = (mode,bkgNoDupCheck) => {
        if( mode =='NEW'&&!autoSelf && !bkgNoDupCheck ) {
            props.onNotiAlert("error",validation.NO_DUP_CHECK_MSG);
            return false;
        }else{
            mode ? setScheduleMode(mode): setScheduleMode ('')
            setScheduleCalendarOpen(!scheduleCalendarOpen);
        }
      };

      // Calendar click event
      const selectedEventCalendar = (event) => {
        if ('N' === event.booking_yn) {
            props.onAlert('error', '해당 스케줄은 선택 불가합니다.');
            return false;
        } else {
            if (scheduleMode=='NEW'||lineCode && lineCode !== event.line_code) {
                let eventInfo = eventInfofn(event);
                props.alert(
                    () => {
                        // if(event.line_code=='WDFC'){
                        if(event.bkg_type=='W'){
                            history.push({
                            pathname: `/svc/bookingWdfc`,
                            state: {
                                ...event,
                                schedule_yn: 'Y',
                                user_no:userData.user_no,
                                sch_vessel_name: event.vsl_name || null,
                                sch_vessel_voyage: event.voyage_no || null, 
                                new_bkg_no:newBkgNo||null,
                                sch_etd:event.sch_etd&&event.sch_etd.length>8?event.sch_etd.substr(0,8):event.sch_etd||null,
                                ...(scheduleMode=='NEW'&& {new_yn:'Y'})
                                },
                            });
                        }else if(event.bkg_type=='E'){
                        // }else if(event.line_code=='YTF'&&event.line_code=='JCS'){
                            setParamSchedule({
                                ...eventInfo,
                                schedule_yn: 'Y',
                                user_no:userData.user_no,
                                new_bkg_no:newBkgNo||null,
                                sch_vessel_name: event.vsl_name || null,
                                sch_vessel_voyage: event.voyage_no || null,
                                ...(scheduleMode=='NEW'&& {new_yn:'Y'})
                            });
                        }else{
                            history.push({
                                pathname: `/svc/booking`,
                                state: {...eventInfo,
                                    schedule_yn: 'Y',
                                    user_no:userData.user_no,
                                    new_bkg_no:newBkgNo||null,
                                    sch_vessel_name: event.vsl_name || null,
                                    sch_vessel_voyage: event.voyage_no || null,
                                    ...(scheduleMode=='NEW'&& {new_yn:'Y'})
                                    },
                                });  
                        }  
                    }
                    , scheduleMode=='NEW'?'BOOKING 을 새로 생성하시겠습니까?':'기존 선사 정보와 일치하지 않습니다'
                    , 'warning', true, true, 'lg'
                    ,  `${event&&(event.carrier_knm||event.line_code||event.sch_line_code)}\n ${scheduleMode=='NEW'?`부킹 번호 : ${!autoSelf?newBkgNo:`자동 생성`}`:'BOOKING 을 새로 생성하시겠습니까?'}`
                    , true, true, () => { return false;}
                );
            } else {
                changeSchInfo(event);
            }
            toggle();
        }
      };
      const eventInfofn = (event) => {
        return {
          ...event,
          sch_por: event.sch_por ? event.sch_por : event.sch_pol,
          sch_por_name: event.sch_por_name ? event.sch_por_name  : event.sch_pol_name,
          sch_pld: event.sch_pld ? event.sch_pld : event.sch_pod,
          sch_pld_name: event.sch_pld_name ? event.sch_pld_name : event.sch_pod_name,
          sch_fdp: event.sch_fdp ? event.sch_fdp : event.sch_pod,
          sch_fdp_name: event.sch_fdp_name ? event.sch_fdp_name : event.sch_pod_name,
        };
      };
      const changeSchInfo = (event) => {
        let eventInfo = eventInfofn(event);
        setLineCode(event.line_code);
        fncBookingParent({
          ...booking,
          ...eventInfo,
          user_no: booking.user_no,
          bkg_no: booking.bkg_no,
          bkg_date: booking.bkg_date,
          owner_no: booking.owner_no,
        });
      };

    return (
        <div className="section section-white">
            <Form>
                <Container>
                    <CardBody className="pt-2 pb-2 bg-white">
                    <LogisviewPageHeader
                        carrierInfo = {props.logoImgList.length>0
                            &&(booking.sch_line_code
                                ?props.logoImgList.find(x=>x.sch_line_code==booking.sch_line_code):{})}
                        pageName= 'BOOKING'
                        />
                        <Row className="mt-0">
                            <Col className="text-right">
                                <label className="mt-2 mb-0 mr-2" >
                                    <Switch onColor="info" offColor="success"
                                    onText="AUTO"
                                    offText="SELF" 
                                    // defaultValue={true} 
                                    value={autoSelf} 
                                    onChange={(e)=>setAutoSelf(!autoSelf)} 
                                    />
                                </label>
                                <Button id="bkg_new" color="default" outline type="button" className="mr-1"
                                    onClick={()=>toggle('NEW',bkgNoDupCheck)
                                    }>NEW</Button>
                                <UncontrolledTooltip delay={0} target="bkg_new">Booking 신규 생성</UncontrolledTooltip>
                                <Button id="report" color="default" outline type="button" className="mr-1"
                                    onClick={(e)=>fncReportViewer()} disabled={lineCode?false:true}>REPORT</Button>
                                <UncontrolledTooltip delay={0} target="report">Report View</UncontrolledTooltip>
                                <Button id="bkg_save" color="default" outline type="button" className="mr-1"
                                    onClick={(e)=>updateAllBooking(e)}>SAVE</Button>
                                <UncontrolledTooltip delay={0} target="bkg_save">임시저장</UncontrolledTooltip>
                                <Button id="bkg_send" color="default" outline type="button" className="mr-1"
                                    onClick={(e)=>sendBooking('SEND')}>SEND</Button>
                                <UncontrolledTooltip delay={0} target="bkg_send">Booking 문서전송</UncontrolledTooltip>
                                <Button id="bkg_cancel" color="default" outline type="button" className="mr-1"
                                    onClick={(e)=>sendBooking('CANCEL')}>CANCEL</Button>
                                {booking.status_cus== "S5"?<UncontrolledTooltip delay={0} target="bkg_cancel">정정작성 취소</UncontrolledTooltip>
                                :<UncontrolledTooltip delay={0} target="bkg_cancel">Booking 취소전송</UncontrolledTooltip>}
                                <Button id="bkg_delete" color="default" outline type="button" className="mr-1"
                                    onClick={(e)=>deleteBooking()}>DELETE</Button>
                                <UncontrolledTooltip delay={0} target="bkg_delete">Booking 문서삭제</UncontrolledTooltip>
                            </Col>
                        </Row>
                        <Collapse isOpen={!autoSelf}>
                        <Row className="mt-2">
                            <Col xl="4" lg="4" md="4" className="col-12 ml-auto">
                                <Row className="mt-2">
                                    <Col xl="8" lg="8" md="8" className="col-8 pr-0 ml-auto ">
                                        <InputValid 
                                            type="text"
                                            name="bkg_no"
                                            id="bkg_no"
                                            placeholder="직접 생성할 부킹번호 입력"
                                            maxLength="35"
                                            value={newBkgNo||''}
                                            onChange={(e)=>fncOnChangeNewBkgNo(e)}
                                            validtype="text" 
                                            required={!autoSelf?true:false} 
                                            feedid="dup_bkg"
                                            disabled = {bkgNoDupCheck?1:0}
                                        />
                                    </Col>
                                    <Col xl="4" lg="4" md="4" className="col-4">
                                    {!bkgNoDupCheck
                                        ?<Button
                                            color="danger" outline type="button" className="mr-1" 
                                            onClick={()=>fncDupCheckBkgNo()}>중복 확인
                                        </Button>
                                        :<Button
                                            color="info" outline type="button" className="mr-1" 
                                            onClick={()=>{
                                                setBkgNoDupCheck(false)
                                                setNewBkgNo('')    
                                            }}>새로 입력
                                        </Button>}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        </Collapse>
                        <hr className="mt-2"/>
                        <Row style={{zIndex:'200'}}>
                            <nav id="cd-vertical-nav">
                                <Card className="no-transition mb-0 rounded-0" 
                                style={{ zIndex:'200',position:'fixed'//,right:'0.5%',top:'11%'
                                    ,right: 'calc(100% - 25px)',top:'70px'
                                }}>
                                    <CardBody className="pl-1 pr-1 pt-2 pb-0 text-center"> 
                                        <Button
                                            color="link"
                                            size="sm"
                                            type="button" className="pl-1 pr-1"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                sendBooking('SEND');
                                            }}>SEND
                                        </Button>
                                        <hr className="m-0 " />
                                        <Button
                                            color="link"
                                            size="sm"
                                            type="button" className="pl-1 pr-1"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                updateAllBooking();
                                            }}
                                            >SAVE
                                        </Button>
                                        <hr className="m-0" />
                                        <Button
                                            color="link"
                                            size="sm"
                                            type="button" className="pl-1 pr-1"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAllOpen(!allOpen);
                                            }}>{allOpen?'Close':'Open'}
                                        </Button>
                                    </CardBody>
                                </Card>
                                    <ul>
                                        <li>
                                            <a  href="#!"
                                                data-number="1"
                                                //href="#headers"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    document.getElementById("general").scrollIntoView(true);
                                                }}><span className="cd-dot bg-secondary" /><span className="cd-label">TOP</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a  href="#!"
                                                data-number="6"
                                                //href="#projects"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenBooking(true);
                                                    document.getElementById("Booking").scrollIntoView(true);
                                                }}><span className="cd-dot bg-secondary" /><span className="cd-label">Booking</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a  href="#!"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenReqMemo(true);
                                                    document.getElementById("ReqMemo").scrollIntoView(true);
                                                }}><span className="cd-dot bg-secondary" /><span className="cd-label">Request Memo</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a  href="#!"
                                                data-number="7"
                                                //href="#projects"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenSchedule(true);
                                                    document.getElementById("Schedule").scrollIntoView(true);
                                                }}><span className="cd-dot bg-secondary" /><span className="cd-label">Schedule</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a  href="#!"
                                                data-number="3"
                                                //href="#features"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenShipper(true);
                                                    document.getElementById("Shipper").scrollIntoView(true);
                                                }}>
                                                <span className="cd-dot bg-secondary" />
                                                <span className="cd-label">Shipper</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#!"
                                            data-number="4"
                                            //href="#features"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOpenConsignee(true);
                                                document.getElementById("Consignee").scrollIntoView(true);
                                            }}
                                            >
                                            <span className="cd-dot bg-secondary" />
                                            <span className="cd-label">Consignee</span>
                                            </a>
                                        </li> 
                                        <li>
                                            <a  href="#!"
                                                data-number="5"
                                                //href="#teams"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenForwarder(true);
                                                    document.getElementById("Forwarder").scrollIntoView(true);
                                                }}><span className="cd-dot bg-secondary" /><span className="cd-label">Forwarder</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a  href="#!"
                                                data-number="4"
                                                //href="#teams"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenDocument(true);
                                                    document.getElementById("Document").scrollIntoView(true);
                                                }}><span className="cd-dot bg-secondary" /><span className="cd-label">Document</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a  href="#!"
                                                data-number="6"
                                                //href="#projects"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenTrans(true);
                                                    document.getElementById("Transport").scrollIntoView(true);
                                                }}><span className="cd-dot bg-secondary" /><span className="cd-label">Transport</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a  href="#!"
                                                data-number="6"
                                                //href="#projects"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenCargo(true);
                                                    document.getElementById("Cargo").scrollIntoView(true);
                                                }}><span className="cd-dot bg-secondary" /><span className="cd-label">Cargo</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a  href="#!"
                                                data-number="7"
                                                //href="#projects"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenContainer(true);
                                                    document.getElementById("Container").scrollIntoView(true);
                                                }}><span className="cd-dot bg-secondary" /><span className="cd-label">Container</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                        </Row>
                        <Row id="cardList">
                            <Col xl="12" lg="12" className="pl-4 pr-4">
                                <BookingTitleCard
                                    booking={booking}
                                    // fncBookingParent={fncBookingParent}
                                    fncGetBkgNo={fncGetBkgNo}
                                    otherList={otherList}
                                    scheduleList={scheduleList}
                                    lineList={lineList}
                                    shipperList={shipperList}
                                    consigneeList={consigneeList}
                                    forwarderList={forwarderList}
                                    documentList={documentList}
                                    documentUnionList={documentUnionList}
                                    transportList={transportList}
                                    cargoBookmarkList={cargoBookmarkList}
                                    containerBookmarkList={containerBookmarkList}
                                    fncBookmarkParent={fncBookmarkParent}
                                    {...props}
                                    />
                            </Col>
                            <Col xl="6" lg="6">
                                <Row>
                                    <Col xl="12" lg="12">
                                        <BookingCard
                                            openWindow={openBooking}
                                            booking={booking}
                                            otherList={otherList}
                                            selectBookingOtherBookmark={selectBookingOtherBookmark}
                                            fncBookingParent={fncBookingParent}
                                            ref={bookingFocus}
                                            lineCode={lineCode}
                                            {...props}
                                            />
                                    </Col> 
                                    <Col xl="12" lg="12">
                                        <MemoCard
                                            openWindow={openReqMemo}
                                            booking={booking}
                                            fncBookingParent={fncBookingParent}
                                            ref={memoFocus}
                                            {...props}
                                            />
                                    </Col>
                                </Row>
                            </Col> 
                             <Col xl="6" lg="6">
                                <ScheduleCard
                                    openWindow={openSchedule}
                                    booking={booking}
                                    fncBookingParent={fncBookingParent}
                                    scheduleList={scheduleList}
                                    selectBookingScheduleBookmark={selectBookingScheduleBookmark}
                                    ref={scheduleFocus}
                                    toggle = {toggle}
                                    {...props}
                                    />
                            </Col>
                            <Col xl="6" lg="6">
                                <ShipperCard
                                    openWindow={openShipper}
                                    booking={booking}
                                    fncBookingParent={fncBookingParent}
                                    shipperList={shipperList}
                                    selectBookingShipperBookmark={selectBookingShipperBookmark}
                                    shipperCompanyList={shipperCompanyList}
                                    ref={shipperFocus}
                                    bookingKecaYN={lineConfig&&lineConfig.bkg_keca_yn}
                                    {...props}
                                    />
                            </Col>
                             <Col xl="6" lg="6">
                                <ForwarderCard
                                    openWindow={openForwarder}
                                    booking={booking} 
                                    fncBookingParent={fncBookingParent}
                                    forwarderList={forwarderList}
                                    selectBookingForwarderBookmark={selectBookingForwarderBookmark}
                                    forwarderCompanyList={forwarderCompanyList}
                                    ref={forwarderFocus}
                                    bookingKecaYN={lineConfig&&lineConfig.bkg_keca_yn}
                                    {...props}
                                    />
                            </Col>
                            <Col xl="6" lg="6">
                                <ConsigneeCard
                                    openWindow={openConsignee}
                                    booking={booking}
                                    fncBookingParent={fncBookingParent}
                                    consigneeList={consigneeList}
                                    selectBookingConsigneeBookmark={selectBookingConsigneeBookmark}
                                    ref={consigneeFocus}
                                    {...props}
                                    />
                            </Col> 
                            <Col xl="6" lg="6">
                                <TransportCard
                                    openWindow={openTrans}
                                    booking={booking}
                                    fncBookingParent={fncBookingParent}
                                    transportList={transportList}
                                    selectBookingTransportBookmark={selectBookingTransportBookmark}
                                    ref={transportFocus}
                                    bookingKecaYN={lineConfig&&lineConfig.bkg_keca_yn}
                                    {...props}
                                    />
                            </Col>
                            <Col xl="6" lg="6">
                                <CargoCard
                                    openWindow={openCargo}
                                    booking={booking}
                                    fncCargoParent={fncCargoParent}
                                    fncGoodsParent={fncGoodsParent}
                                    cargoBookmarkList={cargoBookmarkList}
                                    cargo={cargo}
                                    goodsRelationList={goodsRelationList}
                                    selectBookingCargoBookmark={selectBookingCargoBookmark}
                                    dangerTrue={dangerTrue}
                                    ref={cargoFocus}
                                    lineCode={lineCode}
                                     files={allAttachFiles}
                                    // mainSumAttatchFile={mainSumAttatchFile}
                                    deleteAllFiles={deleteAllFiles}
                                    containerList={containerList}
                                    fncCheckLinecode={fncCheckLinecode}
                                    {...props}
                                    />
                            </Col>
                            <Col xl="6" lg="6">
                                <DocumentCard
                                    booking={booking}
                                    openWindow={openDocument}
                                    fncBookingParent={fncBookingParent}
                                    documentList={documentList}
                                    documentUnionList={documentUnionList}
                                    shipperList={shipperList}
                                    selectBookingDocumentBookmark={selectBookingDocumentBookmark}
                                    // documentBookmarkList={documentBookmarkList}
                                    // shipperCompanyList={shipperCompanyList}
                                    selectBookingShipperBookmark={selectBookingShipperBookmark}
                                    shipperCompanyList={shipperCompanyList}
                                    ref={documentFocus}
                                    {...props}
                                    />   
                            </Col>
                            <Col xl="12" lg="12">
                                <ContainerCard
                                    openWindow={openContainer}
                                    booking={booking}
                                    fncDoorParent={fncDoorParent}
                                    fncBookingParent={fncBookingParent}
                                    fncContainerParent={fncContainerParent}
                                    fncContainerSpecialParent={fncContainerSpecialParent}
                                    containerBookmarkList={containerBookmarkList}
                                    selectBookingContainerBookmark={selectBookingContainerBookmark}
                                    setAllAttachFiles={(e)=>mainSumAttatchFile(e)}
                                    allAttachFiles={allAttachFiles}
                                    dangerTrue={dangerTrue}
                                    ref={containerFocus}
                                    lineCode={lineCode}
                                    cargo={cargo}
                                    bookingDoorYN={lineConfig&&lineConfig.bkg_door_yn||''}
                                    {...props}
                                />
                            </Col>
                        </Row>
                        {/* </>)
                        :
                        <Modal isOpen={carrierListOpen} centered scrollable size='md' >
                                <ModalHeader>
                                    <h3>부킹 선사를 선택하세요</h3>
                                </ModalHeader>
                                <ModalBody>
                                    <Container>
                                    <Row className="mt-2 m-2">               
                                        {carrierList && carrierList.length>0?carrierList.map((data,key)=>
                                            <Col className="text-center pl-0 pr-0 border-secondary" 
                                            style={{margin:'1px' 
                                            ,border:'1px solid'}} 
                                            key={key} onClick={()=>setLineCode(data.value)}>
                                                <span style={{fontSize:'17px'}}>{data.label}</span>
                                            </Col>
                                        ): <Col className="col-12">선택 가능한 선사가 존재하지 않습니다.</Col>}
                                    </Row>
                                    </Container>
                                </ModalBody>
                                <ModalFooter>
                                    <Button  color="default" outline className='m-2'
                                        onClick = {()=> lineCode? setCarrierListOpen(false) :   props.onAlert("danger","부킹을 위한 선사 선택이 필수입니다") }>취소</Button>
                                </ModalFooter>
                        </Modal>
                        :<Row><ScheduleCalendarModal open={true} toggle={(e)=>!e} booking={booking} setLineCode={(v)=> setLineCode(v)}lineCode={lineCode}{...props}/></Row> */}
                        {/* } */}
                    </CardBody>
                </Container>
            </Form>
            {scheduleCalendarOpen && (
                <ScheduleCalendarModal
                open={scheduleCalendarOpen}
                toggle={() => setScheduleCalendarOpen(false)}
                loadData={booking}
                lineCode={lineCode}
                selectedEventCalendar={selectedEventCalendar}
                scheduleMode={scheduleMode}
                {...props}
                />
            )}
            <form id="reportForm" name="reportForm" >
                <input type="hidden" name="system_id"   value="LOGISVIEW" />
                <input type="hidden" name="user_id"     value={`${(userData && userData.user_no)||'GUEST'}`} />
                <input type="hidden" name="file_type"   value="pdf" />
                <input type="hidden" name="file_id"     value="" />
                <input type="hidden" name="file_path"   value="" />
                <input type="hidden" name="name"        value="" />
                <input type="hidden" name="connection"  value={process.env.REACT_APP_DB=='ORACLE'?'logisvw' : 'pgsql'} />
                <input type="hidden" name="parameters" id="parameters"/>
            </form>
            <form id="termsForm" name="termsForm" >
                <input type="hidden" name="menuKey"     value="219" />
                <input type="hidden" name="contentKey"  value="45" />
            </form>
        </div>
    )
}