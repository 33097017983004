import React,{useState,useEffect, useCallback, useRef}from 'react'
import { Modal, Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import _ from 'lodash';
import cn from 'classnames'
import axios from 'axios';
import {useObserver} from 'mobx-react-lite';
import {userStore} from 'store/userStore.js';
// component
import Header from '../muiComponents/headerNew/header'
import Footer from '../muiComponents/footerNew/footer'
import GlobalAlert from 'components/Alert/GlobalAlert.js';
import DialogContent from '@material-ui/core/DialogContent';
// pages
import MainPage from '../pages/main/main'
// css
import globalStyle from'../styles/global.module.css'

import FindInfoPage from "views/Member/FindInfoPage.js";
import VerifyApplication from 'pages/popup/VerifyApplication'
import VerifyAdmin from 'pages/popup/VerifyAdmin'
import SchCalAgreement from 'views/Tracking/ShipmentIssue/SchCalAgreement.js'

import NoticePopup from "components/Notice/NoticeMain.js"
import * as validation from 'muiComponents/common/validation.js';
import { menuAccessLog } from 'muiComponents/common/menuAccess';
import {setCookie, getCookie, removeCookie} from 'muiComponents/common/cookie.js';

const useStyles = makeStyles((theme)=>({
  modal:{
      display:'flex'
      ,alignItems:'center'
      ,justifyContent:'center'
  }
  ,root:{
    display:'flex'
    ,justifyContent:'center'
    ,flex:'none'
  }
  ,form:{minWidth:'600px',width:'750px', outline:'none'}
  ,findForm:{width:'40%', outline:'none'}
}))

/**HOSTNAME별 분기  */
const SERVICE=process.env.REACT_APP_SERVICE;
const PLISM_URL=process.env.REACT_APP_ESERVICE_URL;

const HomeLayout = () => {
  const classes = useStyles();
  const footerRef = useRef();
  const [ modalOpen,setModalOpen ]=useState(false)
  const [ modalContent,setModalContent ]=useState( '')
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [lockKlnetId, setLockKlnetId] = useState(null);
  const [alertTime, setAlertTime] = React.useState(3000);

  const userStoreMenu = useObserver(() => {
    return userStore.menu;
  });

  const userData =useObserver(()=>{
		return  userStore.user
  })

  const isLogOut = useCallback(async()=>{
    if(userStore.user)userStore.logout();
    onAlert( 'success','로그아웃 되었습니다.');
  },[userStore])

    
  useEffect(() => {
    handleVerifyModal();
  }, [userData,lockKlnetId]);

  const handleVerifyModal = () =>{
    if(userData&&(userData.comp_verify_type =='A' ||userData.comp_verify_type =='B'||userData.comp_verify_type =='C'||userData.comp_verify_type =='X')
    ||!userData&&lockKlnetId
      ){
        setModalContent('VerifyApplication');
        setModalOpen(true);
      }else if(userStore.isAuth?.result){
        if(getCookie('certifyUser')){ removeCookie('ctfyRes')}
        setModalContent('VerifyAdmin');
        setModalOpen(true);
      }else{
        setModalContent('');
        setModalOpen(false);
      }
  }
  


  const checkOptionPage= (element)=>{
    if(element&&element.option1 =='Y'
    &&(userData&&(userData.comp_verify_type =='C')
    // &&(userData&&(userData.comp_verify_type =='A' ||userData.comp_verify_type =='B'||userData.comp_verify_type =='C'||userData.comp_verify_type =='X')
    ||!userData&&lockKlnetId)
    ){ //유료 정보받기
        onAlert('error', '해당 서비스는 과금 서비스 입니다. \n사용을 위해서는 가입 신청서를 제출해주세요(문의:1577-1172)',5000);
        handleVerifyModal();
        return false;
    }else if (element&&element.option2 =='Y'){
      onAlert('error', validation.E1007,5000);
    }else { 
      return true
    }
  }

  const goPlism = (param, options) => {
    // const {carrier_code , queryStr} =option;
    let linkParam = param?.linkParam||param||null;
    const logout_use_flag = options?.logout_use_flag ||null;
     if(userData?.user_no ) {
      let menuNo = SERVICE=='PROD'?  linkParam?.prodNo :linkParam?.devNo;
      if(!menuNo){return}
      const carrier_code = options&&options.carrier_code ||null;
      const queryStr = options&&options.id ||null;
      const userNo = userData?.user_no || 'GUEST';
      axios.get("/auth/readyplism" ).then(res => {
        if (res.data.auth_code){
          let plismUrl = `${PLISM_URL}/uat/uia/ownerMain.do?id=${userNo}&auth_code=${res.data.auth_code}&linkMenuNo=${menuNo}${carrier_code?`&ownLineCode=${carrier_code}`:''}${queryStr?`&${queryStr}`:''}`;
          // let plismUrl = `https://test.plism.com/uat/uia/ownerMain.do?id=${userData.user_no}&auth_code=${res.data.auth_code}&linkMenuNo=${menuNo}${carrier_code?`&ownLineCode=${carrier_code}`:''}${queryStr?`&${queryStr}`:''}`;
          window.open(plismUrl, '_plism');
          const value={ path : `plism.com/${menuNo}`, plismMenuNo:menuNo};
          menuAccessLog(value, userData||'GUEST');
        } else {
          onAlert('error',validation.NO_PERMISSION_MSG);
        }
      }).catch(err => {
        isLogOut();
      })
    } else if( logout_use_flag =='Y'){
      let pathUrl=param?.path||null;
      if(!pathUrl){return}
        window.open(
          // ` ${PLISM_URL}/uat/uia/plism3Sub.do?menu_no=${menuNo}`,
          ` ${PLISM_URL}${pathUrl}`,
          '_plism'
        );
      const value = { path: pathUrl, plismMenuNo:param?.menuNo ||param?.name};
      menuAccessLog(value, {user_id :'GUEST'});
    } else {
      onAlert( 'error','로그인이 필요한 서비스입니다.');
    }
  }
  // const menuAccessLog = async(value) => {
  //   try{
  //     await axios.post('/api/oraMenuAccessLog', {layout:value.layout,path:value.path,menuNo:value.menuNo,menuNmKr:value.menuNmKr,plismMenuNo:value.plismMenuNo,user: userData ? userData.user_no : 'GUEST',});
  //     const result = await axios.post('/api/menuAccessLog',{
  //       user:userData?userData.user_no:'GUEST'
  //       ,path:value.layout?value.layout+value.path : value.path
  //                 ,name:value.name?value.name:value
  //       ,systemName :'BOOKINGHOME'
  //     });
  //     // console.log(result)
  //   }catch(e){
  //     console.log(e)
  //   }
  // }
  
  // //기존 Alert (GlobalAlert)
  const alertMessage = (message,status,time) => {
    setOpenAlert(true);
    setStatus(status);
    setMessage(message);
    if(time)setAlertTime(time);
  };

  const onAlert = (status, message,time) => {
    setOpenAlert(true);
    setStatus(status);
    setMessage(message);
    if(time)setAlertTime(time);
  };

  const handleLockId = (e)=>{
    setLockKlnetId(e)
  }
  return (
    
    <> 
      <div className={globalStyle.container}>
        <Header  onAlert={onAlert} userData={userData} isLogOut={isLogOut} 
          goPlism={goPlism} //menuAccessLog={menuAccessLog}
          setModalOpen={(e)=>setModalOpen(e)} setModalContent={(e)=>setModalContent(e)}
          handleLockId={handleLockId}
          handleVerifyModal={handleVerifyModal}
          checkOptionPage={checkOptionPage}
        />
        {!userStore.isAuth&&!userStore.loading?(
        <MainPage onAlert={onAlert} userData={userData} 
        isLogOut={isLogOut} goPlism={goPlism} 
        menuAccessLog={menuAccessLog} 
        setModalOpen={(e)=>setModalOpen(e)} setModalContent={(e)=>setModalContent(e)}
        checkOptionPage={checkOptionPage}
        userStoreMenu={userStoreMenu}
        /> 
        ):
        <div style={{ padding: '0px', minHeight: "calc(100vh - 123px)" ,width:'100%'}}>
        {/* <div className={classes.container}> */}
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              zIndex: '100',
            }}
            open={true}
          >
            <CircularProgress />
          </div>
        </div>
        }
        <Footer  goPlism={goPlism} ref={footerRef} />
        
        {/* 모달 */}
        <Modal
            className={classes.modal}
            open={modalOpen}
            onClose={() =>  modalContent!=='VerifyApplication'?setModalOpen(false):''}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout:500}}>
          <DialogContent className={cn(classes.root , modalContent=='VerifyApplication'?classes.form:classes.findForm)} > 
            {modalContent=='FindInfoPage'
                &&<FindInfoPage toggle={() => {setModalOpen(false); setModalContent(null)}}
                    onAlert={onAlert}
                    alertMessage={alertMessage}
                  />}
            {modalContent=='VerifyApplication'
                &&<VerifyApplication
                    toggle={() => {setModalOpen(false); setModalContent(null)}}
                    userData={userData}
                    onAlert={onAlert}
                    onHandleFooterAgree={(v)=>footerRef.current.handleClickOpen(v)}
                    lockKlnetId={lockKlnetId}
                    />}                    
          </DialogContent>
        </Modal>
        {modalContent=='VerifyAdmin'
              &&<Modal
                className={classes.modal}
                open={modalOpen}
                onClose={(event, reason)=>{
                  console.log(event, reason)
                  if(reason=='backdropClick' ){return }
                }}
                // onClose={{event: ()=>{setModalOpen(false); setModalContent(null); userStore.logout(); }, reason: 'backdropClick' }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout:500}}>
                  <DialogContent className={cn(classes.root , modalContent=='VerifyApplication'?classes.form:classes.findForm)} > 
                    <VerifyAdmin
                      toggle={() => {setModalOpen(false); setModalContent(null); userStore.logout(); }}
                      userData={userData}
                      onAlert={onAlert}
                      onHandleFooterAgree={(v)=>footerRef.current.handleClickOpen(v)}
                      lockKlnetId={lockKlnetId}
                      />
                  </DialogContent>
                </Modal>}       
        {/* 알럿 경고창 */}
        <GlobalAlert
            message={message}
            isOpen={openAlert}
            isClose={() => setOpenAlert(false)}
            alertColor={status || 'danger'} //선택사항  //primary, secondary, success, danger, warning, info, light, dark
            alertTime={alertTime||3000}
          />
      </div> 
      
      {!userStore.isAuth&&!userStore.loading&&<NoticePopup className={classes.modal}/>}
      {/* 선적이슈 이메일 수집 동의 팝업 */}
      {!userStore.isAuth&&!userStore.loading&&<SchCalAgreement pageType={'main'} alertMessage={alertMessage} userData={userData} />}
    </>
  )
}

export default HomeLayout;
