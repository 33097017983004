import React,{useState,useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import TextField from '@material-ui/core/TextField';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import Icon from "@material-ui/core/Icon";
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import Autocomplete from '@material-ui/lab/Autocomplete';
// core components
import GridItem from "muiComponents/Grid/GridItem.js";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import Card from "muiComponents/Card/Card.js";
import CardHeader from "muiComponents/Card/CardHeader.js";
import CardBody from "muiComponents/Card/CardBody.js";
import CardIcon from "muiComponents/Card/CardIcon.js";
import Button from "muiComponents/CustomButtons/Button.js";
// other import
import axios from 'axios';
import Select from "react-select";
import { Scrollbar } from "swiper";

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding:'15px',
    width: '100%',
    height: '80vh',
    marginBottom: theme.spacing(2),
    overflow:'scroll'
  },gridContainer: {
    padding:'15px'
  }
}))

export default function VslInfoList(props) {
  const classes = styles();
  const {store} = props;
  const [lineCode,setLineCode] = useState("");
  const [id,setId] = useState("");
  const [carrierCode, setCarrierCode] = useState("")
  const [vesselCode, setVesselCode] = useState("");
	const [vesselName,setVesselName ] = useState("");
	const [imo,setImo ] = useState("");
	const [callSign,setCallSign ] = useState("");
	const [maxteu,setMaxteu ] = useState("");
	const [vslType,setVslType ] = useState("");
	const [srVgmCloseOption,setSrVgmCloseOption ] = useState("");
  const [vesselList, setVesselList] = useState([]);
  const [srchCarrier, setSrchCarrier] = useState("");
  const [srchVslCode, setSrchVslCode] = useState("");

  const [vsltype,setVsltype] = useState("");
  const [shipimo,setShipimo] = useState("");
  const [Num,setNum] = useState(1);
  const [vslInfoData, setVslInfoData] = useState([]);
  const [setOpenJoin] = useState(false);
  
  useEffect(() => {
    if(props.userData) {
      axios.post("/com/selectEshipCarrierList",{}).then(
        res => 
          setLineCode(res.data)
      );
    } else {
      props.isLogOut();
      props.openLogin();
    }
  }, []);

  const onSubmit = (param) => {
    if(!carrierCode){
      return props.alertMessage('Carrier를 선택해주세요.')
    }
    if(!vesselCode){
      return props.alertMessage('vesselCode를 선택해주세요.')
    }
    if(!vesselName){
      return props.alertMessage('vesselName를 선택해주세요.')
    }
    if(!callSign){
      return props.alertMessage('callSign를 선택해주세요.')
    }

    if(props.userData){
      setNum(1);
      console.log('lineCode',carrierCode);
      axios.post("/com/insertVesselInfo",{
        lineCode:carrierCode,
        vesselCode:vesselCode,
        vesselName:vesselName,
        imo:imo,
        callSign:callSign,
        maxteu:maxteu,
        vslType:vslType.value,
        vslTypeName:vslType.label,
        srVgmCloseOption:srVgmCloseOption.value,
      })
      .then(res => {
        if(res.data==='success'){
          props.alertMessage("저장이 완료되었습니다.","success")
        }else{
          props.alertMessage('저장 실패')
      }})
      .catch(err => {
        console.log(err);
        props.alertMessage('저장 실패 :')
      });
    }else {
      props.openLogin();
    }
  }

  const onCarrierChange = (e,data) => {
    if(data) {setCarrierCode(data.id);} else {setCarrierCode("");}
  }

  const onSrchCarrierChange = (e,data) => {
    if(data) {setSrchCarrier(data.id);} else {setSrchCarrier("");}
  }

  const onSearchSubmit = (param) => {
    if(props.userData){
      setNum(1);
      axios.post("/com/getVesselInfo",{
        lineCode:srchCarrier,
        vesselCode:srchVslCode,
        num:param
      })
      .then(res => {setVesselList(res.data)})
      .catch(err => {
          if(err.response.status === 403 || err.response.status === 401) {
            //  setOpenJoin(true);
          }
      });
    }else {
      props.openLogin();
    }
  }

  const onMore = (param) => {
    if(props.userData){
      if(Num != vesselList[0].tot_page) {
      //page ++
      setNum(param);

      axios.post("/com/getVesselInfo",{
          lineCode:srchCarrier,
          vesselCode:srchVslCode,
          num:param
      })
      .then(res => setVesselList([...vesselList,...res.data]))
      .catch(err => {
          if(err.response.status === 403 || err.response.status === 401) {
          //   setOpenJoin(true);
          }
      });
      }
    }else{
      props.openLogin();
    }
  }

  return (
    <div>
    <GridContainer className={classes.gridContainer}>
    	<GridItem xs={12} sm={12} md={12}>
        <Card style={{marginBottom:'0px'}}>
          <CardHeader color="info" stats icon style={{paddingBottom:'2px'}}>
            <CardIcon color="info" style={{height:'55px'}}>
              <Icon style={{width:'26px',fontSize:'20px',lineHeight:'26px'}}>content_copy</Icon>
            </CardIcon>
          </CardHeader>
          <CardBody style={{paddingBottom: '15px',paddingTop: '10px',paddingLeft: '15px',paddingRight: '15px'}}>
            <Grid item xs={12} sm={9} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    options = {lineCode}
                    getOptionLabel = { option => option.id + '\n' +option.nm }
                    id="carrierCode"
                    onChange={onCarrierChange}
                    
                    renderInput={params => (
                      <TextField inputProps={{maxLength:4}} {...params} label="Carrier (필수)" fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField id="vesselCode" label="Vessel Code (필수)" onChange={event => setVesselCode(event.target.value.toUpperCase().trim())} value={vesselCode} inputProps={{maxLength:10}} fullWidth />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField id="vesselName" label="Vessel Name (필수)" onChange={event => setVesselName(event.target.value.toUpperCase())} value={vesselName} inputProps={{maxLength:50}} fullWidth />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField id="callSign" label="Call Sign (필수)" onChange={event => setCallSign(event.target.value.toUpperCase().trim())} value={callSign} inputProps={{maxLength:9}} fullWidth />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <Select
                      placeholder="모선유형"
                      className="customSelect"
                      name="vslType"
                      value={vslType}
                      onChange={(e) => setVslType(e)}
                      options={[{value:'13', label:'국제카페리'},{value:'41', label:'풀컨테이너선'}]}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Select
                      placeholder="모선별 SR VGM 비활성화 옵션"
                      className="customSelect"
                      name="srVgmCloseOption"
                      value={srVgmCloseOption}
                      onChange={(e) => setSrVgmCloseOption(e)}
                      options={[{value:'Y', label:'Y'},{value:'N', label:'N'}]}
                  />
                 </Grid>
                 <Grid item xs={12} md={3}>
                  <TextField id="imo" label="imo" onChange={event => setImo(event.target.value.toUpperCase().trim())} value={imo} inputProps={{maxLength:10}} fullWidth />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField id="maxteu" label="max teu" onChange={event => setMaxteu(event.target.value.toUpperCase().trim())} value={maxteu} inputProps={{maxLength:10}} fullWidth />
                </Grid>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} style={{textAlign:'-webkit-right'}}>
				<GridItem style={{paddingTop: '10px'}} xs={12} sm={3} md={3}>
          <Button color="info" onClick = {() => onSubmit(1)} fullWidth>모선 추가하기</Button>	
				</GridItem>
			</GridItem>		
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{marginBottom:'0px'}}>
          <GridContainer className={classes.gridContainer}>
            <GridItem xs={12} sm={12} md={12}>
              <Card style={{marginBottom:'0px'}}>
                <CardHeader color="info" stats icon style={{paddingBottom:'2px'}}>
                  <CardIcon color="info" style={{height:'26px'}}>
                    <Icon style={{width:'26px',fontSize:'20px',lineHeight:'26px'}}>content_copy</Icon>
                  </CardIcon>
                  <h4 className={classes.cardTitleBlack}>User Page</h4>
                </CardHeader>
                <CardBody style={{paddingBottom: '0px',paddingTop: '10px',paddingLeft: '15px',paddingRight: '15px'}}>
                  <Grid item xs={12} sm={9} md={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={3}>
                        <Autocomplete
                          options = {lineCode}
                          getOptionLabel = { option => option.id + '\n' +option.nm }
                          id="carrierCode"
                          onChange={onSrchCarrierChange}
                          
                          renderInput={params => (
                            <TextField label="CARRIER" inputProps={{maxLength:4}} {...params} fullWidth />
                          )}
                        />
                      </Grid>	
                      <Grid item xs={12} md={3}>
                        <TextField id="srchVslCode" label="VESSEL CODE" onChange={event => setSrchVslCode(event.target.value.toUpperCase().trim())} value={srchVslCode} inputProps={{maxLength:10}} fullWidth />
                      </Grid>
                      <Grid item xs={12} md={3} >
                        <Button color="info" onClick = {() => onSearchSubmit(1)}  
                        fullWidth>Search</Button>							
                      </Grid>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>  
          <Paper className={classes.paper} style={{margin:'5px', width:'97%', overflow:'auto'}}>
            <TableContainer>
              <Table>
                <TableHead>
                    <TableRow>
                      <TableCell>CARRIER</TableCell>
                      <TableCell>VESSEL CODE</TableCell>
                      <TableCell>VESSEL NAME</TableCell>
                      <TableCell>CALL SIGN</TableCell>
                      <TableCell>모선 유형</TableCell>
                      <TableCell>SR VGM 비활성화 옵션</TableCell>
                      <TableCell>IMO</TableCell>
                      <TableCell>MAX TEU</TableCell>
                    </TableRow>               
                </TableHead>
                <TableBody>
                    {
                        vesselList.map((element,key) => {
                            return(
                              <TableRow>    
                                  <TableCell>{element.line_code}</TableCell>
                                  <TableCell>{element.vessel_code}</TableCell>
                                  <TableCell>{element.vessel_name}</TableCell>
                                  <TableCell>{element.call_sign}</TableCell>
                                  <TableCell>{element.vsl_type_name}</TableCell>
                                  <TableCell>{element.sr_vgm_close_option}</TableCell>
                                  <TableCell>{element.imo}</TableCell>
                                  <TableCell>{element.max_teu}</TableCell>
                              </TableRow>
                            )
                        })
                    }
                </TableBody>
                {
                    vesselList.length >= 10 ? (
                      <TableFooter >
                      <TableRow  >
                      <TableCell style={{textAlignLast:'center',paddingTop:'0',paddingBottom:'0'}} colSpan={28}>
                          <Button
                              color="info"
                              onClick={() => onMore(Num + 1)}
                              style={{paddingLeft:'60px',paddingRight:'60px'}}
                          >MORE&nbsp;(&nbsp;{Num}&nbsp;/&nbsp;{vesselList[0].tot_page}&nbsp;)</Button>
                      </TableCell>
                      </TableRow>
                    </TableFooter>):null
                }
              </Table>
            </TableContainer>
          </Paper>              
        </Card>      
      </GridItem>	
    </GridContainer>
    	
    </div>
  );
}



