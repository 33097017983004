import React,{ useState ,useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


import {TextField,Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

// core components
import GridItem from "muiComponents/Grid/GridItem.js";
import GridContainer from "muiComponents/Grid/GridContainer.js";
//import Table from "muiComponents/Table/Table.js";
import Card from "muiComponents/Card/Card.js";
import CardHeader from "muiComponents/Card/CardHeader.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Button from "muiComponents/CustomButtons/Button.js";
import TableContainer from "@material-ui/core/TableContainer";
//import CardIcon from "muiComponents/Card/CardIcon.js";
// other import
import axios from 'axios';
//import moment from 'moment';

import Icon from "@material-ui/core/Icon";
import CardIcon from "muiComponents/Card/CardIcon.js";

import Grid from '@material-ui/core/Grid';
import CustomTabs from "muiComponents/CustomTabs/CustomTabs2.js";
//import ImpTable from "muiComponents/Table/TablePaging.js";
//import ExpTable from "muiComponents/Table/TablePaging.js";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import Assignment from "@material-ui/icons/Assignment";
import tablestyles from "assets/jss/material-dashboard-pro-react/components/tableStyle.js";
import Select from "react-select";

let numCnt =1;

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleBlack: {
	    textAlign: "left",
	    color: "#000000",
	    minHeight: "auto",
	    fontWeight: "300",
	    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
	    marginBottom: "3px",
	    textDecoration: "none",
	    "& small": {
	      color: "#777",
	      fontSize: "65%",
	      fontWeight: "400",
	      lineHeight: "1"
	    }
	  },
	container: {
				maxHeight:590,
			}
};

const useStyles = makeStyles(styles);

const tableStyles = makeStyles(tablestyles);

export default function ScheduleList(props) {
  const {alertMessage} = props;
  //const [carrierCode,setCarrierCode] = useState("");
  const [uid,setUid] = useState("");
  const [kid,setKid] = useState("");
  const [nttID,setNttID] = useState("");
  const classes = tableStyles();
  const classes2 = useStyles();
    
  const [klnetID, setKlnetID] = useState({});
  const [compType, setCompType] = useState({});
  const [agreeYN, setAgreeYN] = useState({});
  const [faxYN, setFaxYN] = useState({});

  const handleTapsClick = (e) => {
	  if(uid) {
		  onSubmit(); 
	  }
  }
  
  const onSubmit = () => {
	if(props.userData) {
			axios.post("/com/getCompSetting",{user_id:uid,klnet_id:kid})
			.then(res => {
        setKlnetID({value:res.data[0].KLNET_ID,label:res.data[0].KLNET_ID});
        setCompType({value:res.data[0].COMP_TYPE,label:res.data[0].COMP_TYPE});
        setAgreeYN({value:res.data[0].AGREE_YN,label:res.data[0].AGREE_YN});
        setFaxYN({value:res.data[0].FAX_YN,label:res.data[0].FAX_YN});
      }).catch(err => {
				console.log("error");
				alertMessage('조회된 데이터가 존재 하지 않습니다.','error');
			});
    }else {
      props.isLogOut();
      props.openLogin();
    }
  }

  const saveData = () => {
    if(props.userData) {
        axios.post("/com/insertCompSetting",
        {klnet_id:klnetID.value,comp_type:compType.value,agree_yn:agreeYN.value,fax_yn:faxYN.value,user_id:uid})
        .then(res => {
          alertMessage('저장이 완료 되었습니다.','success');
          console.log("저장성공");
        }).catch(err => {
          console.log("error");
          alertMessage('저장하지 못했습니다.','error');
        });
      }else {
        props.isLogOut();
        props.openLogin();
      }
    }

  const saveData2 = () => {
    if(nttID){
      if(isNaN(Number(nttID))) {
        alertMessage('숫자를 입력해주세요.','error');
        }
        
      if(props.userData.user_no){
        axios.post("/api/updatePlismBoardRegTime",
        {ntt_id:nttID,user_id:props.userData.user_no})
        .then(res => {
            if(res.data===1){
              alertMessage('등록일 수정 완료 되었습니다.','success');
            }else{
              alertMessage(nttID+'게시물을 수정하지 못했습니다.','error');
            }
          }).catch(err => {
            alertMessage('수정하지 못했습니다.','error');
          });
        }
    }else{
        alertMessage('공지사항 번호를 입력해주세요.','error');
    }
  }
  
  return (
    <GridContainer>
    	<GridItem xs={12} sm={12} md={12}>
        <Card style={{marginBottom:'0px'}}>
          <CardHeader color="info" icon style={{height:'10px',marginBottom:'10px'}}>
            <CardIcon color="info" style={{padding:'0'}}>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardTitleBlack} style={{color:'black'}}> 이용신청서 설정 수정 </h4>			
          </CardHeader>
          <CardBody style={{paddingBottom: '0px',paddingTop: '10px',paddingLeft: '15px',paddingRight: '15px'}}>
                <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={9} md={2}>
                  <TextField label="User ID." onChange={event => setUid(event.target.value.toUpperCase())} value={uid} fullWidth />
                </Grid>	
                <Grid item xs={12} sm={9} md={2}>
                  <TextField label="KL-Net ID." onChange={event => setKid(event.target.value.toUpperCase())} value={kid} fullWidth />
                </Grid>	
                <Grid item xs={12} sm={12} md={2} >
                  <Button color="info" onClick = {onSubmit}  
                  fullWidth>Search</Button>							
                </Grid>
                <Grid item xs={12} sm={9} md={3}></Grid>
                <Grid item xs={12} sm={12} md={2} >
                  <Button color="info" onClick = {saveData}  
                  fullWidth>save</Button>							
                </Grid>
                </Grid>
              </Grid>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
      <div className={classes.tableResponsive} style={{height:'200px'}}>
	      <TableContainer className={classes2.container} style={{overflow:'visible'}}>
          <Table stickyHeader className={classes.table} style={{overflow:'visible'}}>
              <TableHead style={{padding:'5px'}}>
                <TableRow  style={{borderBottomStyle:'solid',borderBottomColor:'#00acc1'}}>
                  <TableCell style={{backgroundColor:'#e4fefe',fontSize:'14px'}} >KLNET_ID</TableCell>
                  <TableCell style={{backgroundColor:'#e4fefe',fontSize:'14px'}} >업체 청구 정보(COMP_TYPE)</TableCell>
                  <TableCell style={{backgroundColor:'#e4fefe',fontSize:'14px'}} >서비스 동의 사용여부(AGREE_YN)</TableCell>
                  <TableCell style={{backgroundColor:'#e4fefe',fontSize:'14px'}} >FAX 수신 여부(FAX_YN)</TableCell>
                </TableRow>
              </TableHead>
            <TableBody>
              {klnetID.value?
              <TableRow>
                <TableCell style={{backgroundColor:'#e4fefe',fontSize:'13px'}} >
                  {klnetID.value}</TableCell>
                <TableCell style={{backgroundColor:'#e4fefe',fontSize:'11px'}} >
                  <Select
                      className="customSelect "
                      name="compType"
                      value={{
                          value:compType.value||'',
                          label:compType.label||''
                      }}
                      onChange={(e)=>setCompType(e)}
                      options={[{value:'A',label:'A'},{value:'B',label:'B'},{value:'C',label:'C'},{value:'D',label:'D'},{value:'E',label:'E'},{value:'O',label:'O'},{value:'X',label:'X'}]}
                      />
                </TableCell>
                <TableCell style={{backgroundColor:'#e4fefe',fontSize:'13px'}} >
                  <Select
                      className="customSelect "
                      name="agreeYN"
                      value={{
                        value:agreeYN.value||'',
                        label:agreeYN.label||''
                      }}
                      onChange={(e)=>setAgreeYN(e)}
                      options={[{value:'Y',label:'Y'},{value:'N',label:'N'}]}
                      />
                </TableCell>
                <TableCell style={{backgroundColor:'#e4fefe',fontSize:'13px'}} >
                  <Select
                      className="customSelect "
                      name="faxYN"
                      value={{
                        value:faxYN.value||'',
                        label:faxYN.label||''
                      }}
                      onChange={(e)=>setFaxYN(e)}
                      options={[{value:'Y',label:'Y'},{value:'N',label:'N'}]}
                      />
                </TableCell>
              </TableRow>:<></>}     
            </TableBody>
          </Table>
	      </TableContainer>
	    </div>
		</GridItem>
    <GridItem xs={12} sm={12} md={12}>
      <Card style={{marginBottom:'0px'}}>
          <CardHeader color="info" icon style={{height:'10px',marginBottom:'10px'}}>
            <CardIcon color="info" style={{padding:'0'}}>
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardTitleBlack} style={{color:'black'}}> 공지사항 등록일 수정</h4>			
          </CardHeader>
          <CardBody style={{paddingBottom: '0px',paddingTop: '10px',paddingLeft: '15px',paddingRight: '15px'}}>
                <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={9} md={2}>
                  <TextField label="공지사항 번호" onChange={event => setNttID(event.target.value)} value={nttID} fullWidth />
                </Grid>	
                <Grid item xs={12} sm={9} md={2}>
                  <TextField label="등록일" value={new Date().toISOString().replace('T', ' ').slice(0, -13)+'23:59:59'} fullWidth />
                </Grid>	
                <Grid item xs={12} sm={9} md={5}></Grid>
                <Grid item xs={12} sm={12} md={2} >
                  <Button color="info" onClick = {saveData2}  
                  fullWidth>save</Button>							
                </Grid>
                </Grid>
              </Grid>
          </CardBody>
        </Card>
    </GridItem>
    </GridContainer>
  );
}


