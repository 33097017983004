import React,{ useState, useEffect ,useMemo} from "react";
import {CSVLink} from 'react-csv';
import {format, parse} from 'date-fns';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField,Collapse, FormControlLabel,FormControl,Backdrop,CardActions, withStyles, Table, TableBody, TableCell, TableRow, Divider} from '@material-ui/core';
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
// import { ImageList , ImageListItem,ImageListItemBar} from '@material-ui/core'


// import ListSubheader from '@material-ui/core/ListSubheader';
import {IconButton,CircularProgress} from '@material-ui/core';
import Popover from "@material-ui/core/Popover";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InfoIcon from '@material-ui/icons/Info';
import Filter1 from '@material-ui/icons/Filter1';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
// import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import SearchIcon from '@material-ui/icons/Search';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
// core components
import GridItem from "muiComponents/Grid/GridItem.js";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
// import CardFooter from "muiComponents/Card/CardFooter.js";
import Button from "muiComponents/CustomButtons/Button.js";
import CalendarBox from "muiComponents/CustomInput/CustomCalendar.js";
import CustomTabs from "muiComponents/CustomTabs/CustomScheduleTabs.js";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

// other import
import axios from 'axios';
import moment from 'moment';
import ProgressBar from './ProgressBar/ProgressBar.js';

import ScheduleTable from "views/Schedule/FclScheduleTable.js";
// import SchLinePicPop from "views/Schedule/SchLinePicPop.js";
import SchDetailPop from "views/Schedule/SchDetailPop.js";

import * as validation from 'muiComponents/common/validation.js';

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import Badge from '@material-ui/core/Badge';

const localizer = momentLocalizer(moment);
var curCount = 1;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const headCells = [
	{ key: 'line_nm',label: 'CARRIER' },
	{ key: 'vsl_name',label: 'VESSEL NAME' },
	{ key: 'voyage_no',label:  'VOYAGE No.' },
	{ key: 'start_port',label: 'POL' },
	{ key: 'start_day',label: 'ETD' },
	{ key: 'end_port',label: 'POD' },
	{ key: 'end_day',label: 'ETA' },
	{ key: 'doc_closing_date',label: 'DCT' },
	{ key: 'cargo_closing_date',label: 'CCT' },
	{ key: 'ts_yn',label: 'T/S' },
];

const StyleBadge = withStyles((theme)=>({
	badge:{
		top:10
	}
}))(Badge);

const Accordion = withStyles({
	root: {
		// width:'130px',
	marginTop:'10px',
	//   border: '1px solid rgba(0, 0, 0, .125)',
	  boxShadow: 'none',
	  '&:not(:last-child)': {
		borderBottom: 0,
	  },
	  '&:before': {
		display: 'none',
	  },
	  '&$expanded': {
		margin: '30px 0',
	  },
	},
	expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
	root: {
	//   backgroundColor: 'rgba(0, 0, 0, .03)',
	width:'130px',
	  borderBottom: '1px solid rgba(0, 0, 0, .125)',
	  marginBottom: -1,
	//   minHeight: 56,
	//   '&$expanded': {
	// 	minHeight: 56,
	//   },
	},
	content: {
	  '&$expanded': {
		margin: '12px 0',
	  },
	},
	expanded: {},
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
	root: {
	  padding: theme.spacing(2),
	  backgroundColor: 'rgba(0, 0, 0, .03)',
	},
  }))(MuiAccordionDetails);
  
const useStyles = makeStyles((theme)=> ({
	cardCategoryWhite: {
		"&,& a,& a:hover,& a:focus": {
		color: "rgba(255,255,255,.62)",
		margin: "0",
		fontSize: "14px",
		marginTop: "0",
		marginBottom: "0"
		},
		"& a,& a:hover,& a:focus": {
		color: "#FFFFFF"
		}
	},
	cardTitleWhite: {
		color: "#FFFFFF",
		marginTop: "0px",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
		color: "#777",
		fontSize: "65%",
		fontWeight: "400",
		lineHeight: "1"
		}
	},
	cardTitleBlack: {
		textAlign: "left",
		color: "#000000",
		minHeight: "auto",
		fontWeight: "300",
		fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
		marginBottom: "3px",
		textDecoration: "none",
		"& small": {
		color: "#777",
		fontSize: "65%",
		fontWeight: "400",
		lineHeight: "1"
		}
	},
  	carrierList: {
	    padding: '0 0 0 12px !important',
	},
	carrierListGrid: {
	    padding: '0 10px !important',
		color: 'black',
		fontFamily: 'Noto Sans KR, sans-serif',
		fontSize: '13px'
	},
	backdrop:{zIndex:theme.zIndex.drawer +1,color:'#fff'},
}));

export default function ScheduleList(props) {
	const {userData, openLogin, alertMessage, onAlert} = props;
	const classes = useStyles();
	const setEndDate = new Date();
	const [processOpen,setProcessOpen] = useState(false);
	const [tapNum,setTapNum] = useState(0);

	const [carrierData,setCarrierData] = useState([]); //셀렉트박스 선사리스트 (전체리스트)
	const [checkBoxData,setCheckBoxData] = useState([]); // 체크박스 선사리스트  (1. 콤보박스에서 선택된 선사들 2.)

	const [selectedCarrier,setSelectedCarrier] = useState([]); // 셀렉트박스 선택된+ 체크박스에서 체크된 선사 
	
	// const [volumCarrierData,setVolumCarrierData] = useState(null); //물동량 조회결과 선사목록
	const [volumData,setVolumData] = useState([]); //물동량 조회결과 선사,퍼센트
	const [expanded, setExpanded] = React.useState(false);

	const [portData,setPortData] = useState([]); //처음부터 세팅된 전체 port 데이터
	const [sPort,setSPort] = useState(''); 
	const [ePort,setEPort] = useState('');
	
	const [sDate,setSDate] = useState(new Date());
	const [eDate,setEDate] = useState((moment(setEndDate).endOf('month')).format('YYYYMMDD'));
	// const [eDate,setEDate] = useState(setEndDate.setDate(setEndDate.getDate()+6));
	const [eWeek,setEWeek] = useState("4 week");

	const [scheduleData,setScheduleData] = useState([]); //검색 후 결과 데이터(리스트)
	const [tileData,setTileData] = useState([]);
	
	/**탭2 Calendar 관련 */
	const [anchorEl, setAnchorEl] = useState(null);
	const [detailParam, setDetailParam] = useState(null);

	/**탭3 Carrier List 관련 */
	const [state, setState] = React.useState({
			// checkedA: false,
			pop2: false,
			sch_line_code: ""
			});
	const [ checkedItems, setCheckedItems ] = useState(new Set());

	/**MY SCHEDULE */
	const [openMySchedule,setOpenMySchedule] = useState(false);
	const [schQuickCondiList,setSchQuickCondiList] = useState([]);

	const [vesselName, setVesselName] = useState('');
	const [voyageNo, setVoyageNo] = useState('');

  	useEffect(() => {
		let mounted = true;
		async function callAxio() {
	
			await axios.post("/api/getCarrierInfo",{})
			.then(res => {
				if(mounted){
					let list = res.data;
					list.map((x)=>{x.color= `#${Math.floor(Math.random()*16777215).toString(16)}`; return x})
					setCarrierData(list)
				}
			});
			

			await axios.post("/api/getPortCodeInfo",{ portCode:'K'})
			.then(res => mounted&&setPortData(res.data))
			.catch(err => {
				if(err.response.status === 403||err.response.status === 401) {
					openLogin();
					mounted = false;
				}
			});

			
		}

		if (!props.authLoading) {
			if(props.location.state){
				setCheckBoxData(props.location.state&&props.location.state.sch_line_code||[]);
				setSelectedCarrier(props.location.state&&props.location.state.sch_line_code||[]);
				// onSearchDataSet(props.location.state&&props.location.state.sch_line_code)
			}
			callAxio()
		}
		selectSchQuickCondiList();
	    return () => {
		  mounted = false;
	    };
	}, [userData]);

  	useEffect(() => {
		if(props.location.state&&(portData&&portData.length>0)){
			let pol_l = props.location.state.sch_pol&&portData.find(x=>x.port_code == props.location.state.sch_pol)||'';
			let pod_l = props.location.state.sch_pod&&portData.find(x=>x.port_code == props.location.state.sch_pod)||'';
			let date_l = props.location.state.start_date||new Date();
			setSPort(pol_l);
			setEPort(pod_l);
			setSDate(date_l)	;
			onSubmit(null,  {startDate:moment(date_l).format('YYYYMMDD'),
							startPort:pol_l&&pol_l.port_code,
							endPort:pod_l&&pod_l.port_code,})
		}
	}, [portData]);
  
	/**조회조건관련 함수  */
	const onHandleCheckAll =(e) => {
		setSelectedCarrier( selectedCarrier.length == checkBoxData.length  ? [] : checkBoxData);
	}

	const onHandleSelectBox = (data) => {
		if(data) {
			setSelectedCarrier(data); 
			setCheckBoxData(data);
		}   
	}

	const onHandleCheckBox = (e,data ) => {
		if(data){
			let list = [];
			if(selectedCarrier.findIndex(x=>x.sch_line_code==data.sch_line_code)<0) {
				list = [...selectedCarrier,data];
			} else {
				list = selectedCarrier.filter((x)=>x.sch_line_code !== data.sch_line_code);		
			}
			setSelectedCarrier(list);
		}
	}

	const schlineSelectedCarrier = (data, type) => {
		if(process.env.REACT_APP_DB=='ORACLE'){
		/////오라클 변환 -----///////////////
			if (data.length>0){
				let multiCarrier = "";
				if(type =='mySch'){
					for (let index in data) {
						multiCarrier =// type =='mySch' ?
						`${multiCarrier}${data[index].sch_line_code}`
						// :multiCarrier + "'" + data[index].sch_line_code + "'";
						if(data.length-1 != index) multiCarrier = multiCarrier + ",";
					}
				}else{
					multiCarrier =data.map(row=>row.sch_line_code);
				}
				return multiCarrier;
			}else{
				return data.sch_line_code||false;
			}
		}else{
			//pg/////////////////////////
			if (data.length>0){
				let multiCarrier = "";
				for (let index in data) {
					multiCarrier = type =='mySch' ? `${multiCarrier}${data[index].sch_line_code}`
					:multiCarrier + "'" + data[index].sch_line_code + "'";
					if(data.length-1 != index) multiCarrier = multiCarrier + ",";
				}
				return multiCarrier;
			}else{
				return data.sch_line_code||false;
			}
		}
	}
	
	const handleTapsClick = (e) => {
		setTapNum(e);
		if (e==0) { //리스트
			setSDate(new Date());
			onSubmit(e,{
				startDate:moment(new Date()).format('YYYYMMDD'),
				eWeek:eWeek,//리스트일때 필요 (eTabnum ===0/ mode:'L')
				startPort:sPort&&sPort.port_code||'',
				endPort:ePort&&ePort.port_code||'',
			});
		}
		if(e==1) {//캘린더
			let	startDay =  moment(sDate).startOf('month')
			let endDay= moment(startDay).endOf('month')
			setSDate(startDay.format('yyyy-MM'));
			onSubmit(e,{startDate:startDay.format('YYYYMMDD'),
						endDate: endDay.format('YYYYMMDD'),
						startPort:sPort&&sPort.port_code||'',
						endPort:ePort&&ePort.port_code||'',
					});
		} 
		// if(e==2) getServiceCarrierList();
	}
	
	const onNextPage= (data)=> {
		curCount = data;
		getScheduleList({curpage:data},true)
	}

	const onSubmit = (eTabnum, arr) => {
		if(arr?(!arr.startPort||!arr.endPort)
			:!(sPort&&sPort.port_code)||!(ePort&&ePort.port_code)
		){
            onAlert( 'error','POL, POD는(은) 필수 항목 입니다.');
            return false
        }
		let init_params = {startDate:moment(sDate).format('YYYYMMDD'),
							// endDate:moment(eDate).format('YYYYMMDD'),
							eWeek:eWeek,
							mode:eTabnum ===1?'C':'L',
							startPort:sPort&&sPort.port_code||'',
							endPort:ePort&&ePort.port_code||'',
							vesselName: vesselName, 
							voyageNo: voyageNo
							}

		let params = {...init_params, ...arr};
		// if(userData) {
			setProcessOpen(true);
			if(eTabnum == undefined && tapNum==2) {
				getServiceCarrierList();
			} else {
				curCount=1; 
				let carrierCode ="";
				axios.post("/api/getScheduleCarrierList",{ 
					...params,
					carrierCode :checkBoxData.length>0?schlineSelectedCarrier(selectedCarrier):''
				})
				.then(res=>{ 
					if(res.data.length>0){						
						if(checkBoxData.length>0){
							carrierCode=schlineSelectedCarrier(selectedCarrier.filter((x)=>res.data.some(y=> y.sch_line_code==x.sch_line_code)))
						}else{//검색조건에 선사 없음(전체검색)
							setSelectedCarrier(res.data); 
							//setCheckBoxData(res.data);
							carrierCode=schlineSelectedCarrier(res.data);
						}
					}else{//스케줄가진 선사 없음
						carrierCode=schlineSelectedCarrier(res.data)
					}
				})
				.then(()=>{
					getScheduleList({
						...params,
						mode:eTabnum ===1?'C':'L',
						carrierCode:carrierCode,
					})
				}).catch(err => { 
					props.onAlert('error',validation.ERR_MSG);
					setProcessOpen(false);
				});
			}
		// }
	}

	const getScheduleList = (params, nextPage) =>{
		let bind = {
			carrierCode: schlineSelectedCarrier(checkBoxData)||schlineSelectedCarrier(selectedCarrier),
			startDate:moment(sDate).format('YYYYMMDD'),
			endDate:moment(eDate).format('YYYYMMDD'),
			eWeek:eWeek,
			curpage:curCount,
			startPort:sPort&&sPort.port_code ||'',
			endPort:ePort&&ePort.port_code ||'',
			vesselName: vesselName, 
			voyageNo: voyageNo,
			mode:'L'};
		
		bind={...bind, ...params};
		setProcessOpen(true);
		if(bind&&!bind.carrierCode){
			props.onAlert('error',validation.FAIL_CHECK_MSG)
			setScheduleData([])
			setProcessOpen(false)
			return false;
		}else{
			axios.post("/api/getScheduleList",bind)
			.then(res => {
				if (res.data.length>0){ 
					nextPage? setScheduleData([...scheduleData,...res.data])
							: setScheduleData(res.data)					
				}else{
					props.onAlert('error',validation.FAIL_CHECK_MSG)
					setScheduleData([])
				}
				
			})
			.then(()=>setProcessOpen(false))
			.catch(err => { 
				// console.log(err)
				props.onAlert('error',validation.ERR_MSG)
				// if(err.response.status === 403||err.response.status === 401) {
				// 	openLogin();
				// }
				setProcessOpen(false)
			});
		}
	}

	function getServiceCarrierList() {
		if(userData) {
			return axios ({
				url:'/sch/getServiceCarrierList',
				method:'POST',
				data: {startPort:sPort&&sPort.port_code|'',
					endPort:ePort&&ePort.port_code||''
					},
				headers:userData
			}).then(setTileData([]))
			.then(res => {
				setTileData(res.data)
			})
			.then(()=>setProcessOpen(false))
			.catch(err => {
				if(err.response.status === 403||err.response.status === 401) {
					openLogin();
				}
			});
		} else {
			openLogin();
		}
	}

	/**물동량 관련 */
	const onHandleTopVolum = () => {//물동량 조회
		if(userData) {
			if(!(sPort&&sPort.port_code)) {
				props.onAlert('error',"물동량 데이터 조회시 출발지는 필수값 입니다.");
				return false;
			} else if (!(ePort&&ePort.port_code)) {
				props.onAlert('error',"물동량 데이터 조회시 도착지는 필수값 입니다.");
				return false;
			} else {
				if(!expanded) {
							return axios ({
								url:'/sch/getProgressInfo',
								method:'POST',
								data: {startPort:sPort&&sPort.port_code||'',
									endPort:ePort&&ePort.port_code||''
									}
							}).then(setVolumData([]))
							.then(res => {setVolumData(res.data); setExpanded(!expanded);})
							.catch(err => {
								if(err.response.status === 403||err.response.status === 401) {
									openLogin();
								}
							});
				} else {
					setExpanded(!expanded);
				}
			}
		} else {
			openLogin();
		}	  
	}
  
	const onSearchDataSet = (data, checked) => { //물동량목록 선사선택,,
		let result = carrierData.filter(x=>data.some(z => z.line_code==x.sch_line_code)) ;
		checked?onHandleSelectBox([...new Set ([...selectedCarrier,...result])])
				:onHandleCheckBox(null,...result)
	}
  

	/**탭 2 캘린더 관련 */
	const selectedEvent = (event,e) => {
		setDetailParam(event);
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const navigatedEvent = date => {
		const nDate = moment(date).startOf('month');
		const lDate = moment(date).endOf('month');

		setSDate(nDate);
		onSubmit(1,{startDate:nDate.format('YYYYMMDD'),
					endDate: lDate.format('YYYYMMDD'),
					startPort:sPort&&sPort.port_code||'',
					endPort:ePort&&ePort.port_code||'',
					});
	};


	/**탭3 Carrier List 관련*/
	const handleClick2 = (e,sch_line_code) => {
	setState({pop2:true, sch_line_code:sch_line_code});
	};

	const handleClose2 = () => {
	setState({pop2:false});
	};
  
	/**MY SCHEDULE */
	const selectSchQuickCondiList =() => {
		if(userData){
			axios.post('/sch/selectSchQuickCondiList',{user_id: userData.user_no})
			.then(res => setSchQuickCondiList(res.data))
			.catch(err => {
				if(err.response.status === 403||err.response.status === 401) {
					openLogin();
				}
			});
		}
	}

	const onHandleQuickCondi =(val)=>{		
		setSPort(portData.find(x=>x.port_code ==val.START_PORT) ||'');
		setEPort(portData.find(x=>x.port_code ==val.END_PORT) ||'');
		let carriers =	val.CARRIER_CODES.split(',');	
		let lineList =  val.CARRIER_CODES=='ALL'? [] :carrierData.filter(x=> {return carriers.indexOf(x.sch_line_code)>-1});
		onHandleSelectBox(lineList.length>0? [...lineList]:[] );
	}

	const addQuickCondi =(val)=>{
		if(schQuickCondiList.length>11){
			onAlert( 'error','MY SCHEDULE은 최대 12개까지 설정할 수 있습니다.');
			return false
		}
		if(!(sPort&&sPort.port_code)||!(ePort&&ePort.port_code)){
			onAlert( 'error','MY SCHEDULE 등록은 POL, POD이(가) 필수 항목 입니다.');
			return false;
		}
		
		let checkList = schQuickCondiList.filter(x=> x.START_PORT == sPort.port_code 
						&& x.END_PORT ==ePort.port_code
						&& x.CARRIER_CODES == (checkBoxData.length>0?schlineSelectedCarrier(selectedCarrier,'mySch'):'ALL'));

		if(checkList.length>0){
			onAlert( 'error','이미 등록된 스케줄입니다.');
			setOpenMySchedule(true);
			return false;
		}
						
		if(userData){
			axios ({
				url:'/sch/saveSchQuickCondiList',
				method:'POST',
				data: {	user_id:userData.user_no,
						start_port:sPort.port_code,
						end_port:ePort.port_code,
						carrier_codes:checkBoxData.length>0?schlineSelectedCarrier(selectedCarrier,'mySch'):'ALL'
					}
			}).then(res => {
				if(res.data==true){ 
					selectSchQuickCondiList();
					setOpenMySchedule(true);
				}else{
					onAlert('error','등록에 실패하였습니다.'); 
				}
			}).catch(err => {
				onAlert('error','등록에 실패하였습니다.');
			});
		}
	}

	const deleteQuickCondi =(val)=>{
		if(userData){
			axios ({
				url:'/sch/deleteSchQuickCondiList',
				method:'POST',
				data: {	user_id:userData.user_no,
						start_port:val.START_PORT,
						end_port:val.END_PORT,
						carrier_codes:val.CARRIER_CODES
					}
			}).then(res => {
				res.data==true ? selectSchQuickCondiList() : onAlert('error','삭제에 실패하였습니다.');
			}).catch(err => {
				onAlert('error','삭제에 실패하였습니다.');
			});
		}
	}
	
	return (
		// <div  style={{paddingLeft:'10px',paddingRight:'10px'}}>
		<GridItem xs={12} style={{marginTop:'20px', marginBottom:'10px'}}>
			<Backdrop className={classes.backdrop} open={processOpen}>
				<CircularProgress color="primary" />
			</Backdrop>
			<Card style={{marginTop:'0',marginBottom:'10px'}}>
				<CardBody style={{paddingTop:'15px',paddingBottom:'15px'}}>
					<GridContainer alignItems='flex-end' >
						<GridItem lg={3} md={4} sm={4} xs={12}>
							<CustomAutoComplete
							itemType='pol' id='pol' key='pol'
							portData={portData&&portData.filter(x=>x.port_code.substring(0,2)=='KR')} 
							setPortValue={setSPort} portValue={sPort} alertMessage={alertMessage}
							{...props}
							/>
						</GridItem>
						<GridItem lg={3} md={4} sm={4} xs={12}>
							<CustomAutoComplete
							itemType='pod' id='pod' key='pod'
							portData={portData&&[...portData]} 
							setPortValue={setEPort} portValue={ePort} alertMessage={alertMessage}
							{...props}
							/>
						</GridItem>
						<GridItem lg={2} md={4} sm={4} xs={12}>
						{tapNum != 2 && (
							<CalendarBox
								labelText ="Start Date"
								id="portDate"
								disabled={tapNum != 0}
								format={tapNum ===1 ? "yyyy-MM":"yyyy-MM-dd"}
								setValue={sDate}
								onChangeValue={date => setSDate(date)}
								fullWidth = {true}
								required={true}
								variant="inline"
								autoOk={true}
							/>)}
						</GridItem>
						<GridItem lg={1} md={6} sm={6} xs={12}>
						{tapNum != 2 && (<FormControl fullWidth >
							<InputLabel></InputLabel>
							<Select 
							native
							id = "portDateWeek"
							disabled={tapNum != 0}
							value={eWeek}
							label=""
							onChange={e=>setEWeek(e.target.value)}
							style={{marginTop:'13px'}}
							>
							<option value="2 week">2 Weeks</option>
							<option value="4 week">4 Weeks</option>
							<option value="6 week">6 Weeks</option>
							<option value="8 week">8 Weeks</option>
							</Select>
							</FormControl>)}
						</GridItem>
						<GridItem lg={3} md={6} sm={6} xs={12}>
							{tapNum != 2 &&
							(<Autocomplete
								disabled={tapNum == 2}
								size="small"
								multiple
								// disableCloseOnSelect
								limitTags={3}
								options = {carrierData}
								// value = {selectedCarrier}
								value = {checkBoxData}
								getOptionLabel = { option =>"["+option.sch_line_code+"]"+option.line_name2 }
								getOptionSelected = {(option,value) => option.sch_line_code === value.sch_line_code}
								id="carrierCode"
								onChange={(_,selectedOptions)=>onHandleSelectBox(selectedOptions)}
								renderInput={params => (
									<TextField {...params} label="Carrier" fullWidth />
								)}
								renderOption={(option, { selected }) => (
									<React.Fragment>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ margin: '0'}}
										checked={selected}
									/>
									<font size="2">{"["+option.sch_line_code+"] "+option.line_name2}</font>
									</React.Fragment>
								)}
							/>)}
						</GridItem>
						<GridItem lg={3} md={6} sm={6} xs={12}>
							<TextField id="vesselName" label="Vessel Name" onChange={event => setVesselName(event.target.value)} value={vesselName} fullWidth />
						</GridItem>
						<GridItem lg={3} md={6} sm={6} xs={12}>
							<TextField id="voyageNo" label="Voyage No" onChange={event => setVoyageNo(event.target.value)} value={voyageNo} fullWidth />
						</GridItem>
					</GridContainer>   
					{checkBoxData.length>0?<>
						<GridContainer style={{margin:'15px 0 10px'}}>
							<GridItem lg={1} md={1} sm={1} xs={2} className={classes.carrierListGrid}>
								<Checkbox 	icon={icon} checkedIcon={checkedIcon} style={{ margin: '0'}} 
											checked={selectedCarrier.length === checkBoxData.length} 
											onClick={(e)=>onHandleCheckAll(e)} /><font size="2"> 전체</font>
							</GridItem>
							<GridItem lg={11} md={11} sm={11} xs={11}>
								<GridContainer>
									{checkBoxData.map((data,key)=>( 
											<GridItem key={key} lg={2} md={3} sm={4} xs={6} className={classes.carrierListGrid}>
												<Checkbox  style={{ margin: '0'}} 
													checked={(selectedCarrier.findIndex(x=>x.sch_line_code===data.sch_line_code)>=0)?true:false}
													onClick={(e)=>onHandleCheckBox(e,data)}/>
												<font size="2">{data.line_name2?data.line_name2.length>8?`[${data.sch_line_code}] ${data.line_name2.substring(0,7)}...`:`[${data.sch_line_code}] ${data.line_name2}`:""}</font>
											</GridItem>
										))
									}
								</GridContainer>
							</GridItem>
						</GridContainer>	
					</>:<></>}
					{userData&&
					<Accordion expanded={openMySchedule} onChange={()=>setOpenMySchedule(!openMySchedule)}>
						<GridContainer justifyContent='flex-end'>
							<GridItem>
								<Button size='sm' color='primary' startIcon={<AddIcon/>} style={{width:'130px'}} onClick={addQuickCondi}>Add My Schedule</Button>
								{!openMySchedule?
								<StyleBadge badgeContent={schQuickCondiList.length} color='error' showZero={true} overlap="rectangular">
									<Button size='sm'  startIcon={<ExpandMore/>} color='warning' style ={{width:'130px'}} onClick ={()=>{setOpenMySchedule(true); selectSchQuickCondiList();}}>
									<div  style ={{fontColor: '#ff9800',}}>View My Schedule</div></Button>
								</StyleBadge>	
								:<Button size='sm'  startIcon={<ExpandLess/>} style ={{backgroundColor : 'gray' ,width:'130px'}} onClick ={()=>setOpenMySchedule(false)}>
									<div style ={{fontColor: 'gray',}}>Hide My Schedule</div></Button>
								}
							</GridItem>
						</GridContainer>
						<AccordionDetails>
						{schQuickCondiList&&schQuickCondiList.length>0?
							<GridContainer style={{margin: '0'}}>
								{schQuickCondiList.map((val, inx, arr)=>
								<GridItem xs={6} sm ={4} md={3} lg={2} style={{border:"1px solid #C8C8C8",height:'50px', display:'flex', justifyContent: 'space-between'}} key ={inx}>
									<div style={{display: 'flex',flexDirection: 'column',justifyContent: 'center', cursor:'pointer'}} onClick={()=>onHandleQuickCondi(val)}>
										<div style={{cursor:'pointer',fontWeight:'bold', fontSize:'17px'}}>{val.START_PORT} → {val.END_PORT}</div>
										<div style={{}} >{val.DISPLAY_CARRI_CODES}</div>
									</div>
									<div style={{ cursor:'pointer', color:'red', fontWeight:'bold', fontSize:'15px', alignSelf: 'center', display: 'flex'}}
										onClick={()=>{deleteQuickCondi(val)}}><i className="material-icons">remove_circle_outline</i>
									</div>
								</GridItem>
								)}
							</GridContainer>
							:<div style={{fontSize:'12px',textAlign:'center',width:'100%',fontWeight:'500'}}>등록된  MY SCHEDULE 이 없습니다.</div>}
						</AccordionDetails>
					</Accordion> }
				</CardBody>	
			</Card>
			{/* <GridItem xs={12} style={{paddingBottom:'10px',textAlign:'-webkit-right'}}>
				<GridItem xs={9} sm={5} md={3} style={{textAlign:'center'}}> */}
			<GridContainer  justifyContent='flex-end'>
				<GridItem>
					<Button color="info" onClick = {onHandleTopVolum} endIcon={<Filter1/>} >TOP</Button>
					<Button color="info" onClick = {()=>tapNum==1?navigatedEvent(sDate):onSubmit()} endIcon={<SearchIcon/>}>Search</Button>
				</GridItem>
			</GridContainer>
				{/* </GridItem>
					</GridItem> */}
					{/* <GridItem xs={12} style={{paddingBottom:'10px',textAlign:'-webkit-right'}}>
						<GridItem xs={9} sm={5} md={3} style={{textAlign:'center'}}>
							<GridContainer style={{margin:'0', justifyContent:'flex-end'}}>
							<GridItem xs={4} sm={4} md={4}>
								<Button color="info" onClick = {onHandleTopVolum} endIcon={<Filter1/>} 
								style={{paddingLeft:'15px',paddingRight:'15px'}}>TOP</Button>
							</GridItem>
						<GridItem  xs={6} sm={6} md={6}>
							<Button color="info" onClick = {()=>
								tapNum==1?navigatedEvent(sDate):onSubmit()
								} endIcon={<SearchIcon/>}>Search</Button>
						</GridItem>
						</GridContainer>
						</GridItem>
					</GridItem> */}
			<Collapse in={expanded} timeout="auto" unmountOnExit style={{width:'100%'}}>
				<ProgressBar data={volumData} eventHandler={onSearchDataSet} selectedCarrier={selectedCarrier}/>
			</Collapse>
				<div>
				<div style={{position:"absolute",zIndex:"1",right:"50px",top:"23px",display:"none"}}>
					<Button size="sm" color="linkedin" target="_blank" href={"https://new.portmis.go.kr/portmis/websquare/websquare.jsp?w2xPath=/portmis/w2/si/pmb/mbrp/info/UI-SI-MBRP-201-51.xml&menuCd=M9106&menuNm=%BC%B1%BB%E7%BA%B0%20%BF%EE%C0%D3%B0%F8%C7%A5%20%B8%AE%BD%BA%C6%AE&w2xHome=/portmis/w2/main/&w2xDocumentRoot="}>해양수산부 운임 공표 조회</Button>
				</div>
				<div style={{position:"relative",zIndex:"0"}}> 
					<CustomTabs headerColor="info"
					handleTapsClick={handleTapsClick}
					tabs={[
						{
						tabName: "List"
						,tabIcon: (AssignmentOutlinedIcon)
						,tabContent: (
							<div style={{marginTop:'10px'}}>
								<div style={{display: 'flex',justifyContent: 'end', alignItems: 'center', marginRight:'5px'}}>
									<span style={{textAlign: "end",paddingBottom: '0px',color:"#000000", paddingRight:"10px", paddingTop:"0px"}}>
										총 : {scheduleData.length>0?scheduleData[0].total_count:0} 건</span>
									{scheduleData.length>0
									?<CSVLink data={[...scheduleData].map((val)=> {
										let newVal = val;
											if(val.start_hour){ newVal = {...newVal , start_day : `${val.start_day||''} ${val.start_hour||''}` }}
											if(val.end_hour){ newVal = {...newVal , end_day : `${val.end_day||''} ${val.end_hour||''}` }}
											if(val.ts_yn){ newVal = {...newVal , ts_yn : `${val.ts_yn=='Y'? 'T/S': 'DIR'}` }}
											return newVal;
										})}
											headers={headCells}
											filename="fcl_sch_data.csv">
										<Button color="success" //onClick = {Download} 
										id='btnExport'size="sm" disabled={scheduleData.length<1}>EXCEL 다운로드</Button>
									</CSVLink> 
									:	<Button color="success" //onClick = {Download} 
										id='btnExport'size="sm" disabled={scheduleData.length<1}>EXCEL 다운로드</Button>
									}
								</div>
								{/* <GridContainer style ={{margin:'5px 0'}}>
									<GridItem xs={12}> */}
								<ScheduleTable propsData={scheduleData} curPage={curCount} nextPage={(data)=>onNextPage(data)} {...props}/>               	
									{/* </GridItem>
								</GridContainer> */}
						</div>)
						}
						,{
							tabName: "Calendar"
							//,tabIcon: Face
							,tabIcon: (TodayOutlinedIcon)
							,tabContent: (
								<GridContainer justifyContent="center">
								<GridItem xs={12}>
								<Card>
									<CardBody calendar>
									<BigCalendar 
										selectable
										localizer={localizer}
										//events={events}
										events={scheduleData}
										defaultView="month"
										popup
										//views={['month','agenda']}
										views={["month"]}
										scrollToTime={new Date(1970, 1, 1, 6)}
										//defaultDate={new Date()}
										defaultDate={new Date(sDate)}
										onSelectEvent={(event,e) => selectedEvent(event,e)}
										//onSelectSlot={slotInfo => addNewEventAlert(slotInfo)}
										//eventPropGetter={eventColors}
										onNavigate={date => navigatedEvent(date)}
										//elementProps={{ onClick: e => selectedEvent(e.currentTarget,e.event)}}
										style={{height: "700px"}}
										eventPropGetter={(event) => {
											const eLine = carrierData&&carrierData.find(x=>x.sch_line_code==event.line_code)
											const eColor = eLine&&eLine.color||'green';
											return { style:{ backgroundColor:eColor} }
											}}
										// eventPropGetter={(event) => {
										// 	const backgroundColor = event.allday ? 'yellow' : 'blue';
										// 	return { style: { backgroundColor } }
										//  }}
									/>
									<Popover
										id={id}
										open={open}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorReference="anchorPosition"
										anchorPosition={{top:80,left:550}}
										anchorOrigin={{vertical:'bottom',horizontal:'center',}}
										transformOrigin={{vertical:'top',horizontal:'center',}}
									>
												<SchDetailPop
													detailParam = {detailParam} //store={token}
													{...props}
												/>
											</Popover>
											</CardBody>
											</Card>								  
										</GridItem>

										</GridContainer>
									)
							},
							// {
							// tabName: "Carrier List"
							// ,tabIcon: (ListAltOutlinedIcon)
							// ,tabContent: (
							// 		<GridContainer>
							// 			<GridItem xs={12}>
							// 			<div className={classes.root}>
							// 				<ImageList cellHeight={180} className={classes.GridList}>
							// 					<ImageListItem key="Subheader" cols={2} style={{ height: 'auto' }}>
							// 					<ListSubheader component="div"></ListSubheader>
							// 					</ImageListItem>
							// 					{tileData.map((tile) => (
							// 					<ImageListItem key={tile.rownum} style={{ height: '150px',width:'400px'}}>
							// 					<div align='center'>{tile.title}
							// 					{/* <img src={require("assets/img/carrier/"+tile.img+".gif")} alt={tile.title} width='100' height='100' /> */}
							// 					</div>
							// 						<ImageListItemBar  
							// 						title={
							// 							<IconButton className={classes.cardTitleWhite} 
							// 							onClick={(e) => handleClick2(e,tile.sch_line_code)} size="small">{tile.title}
							// 						</IconButton>
							// 						}
							// 						//subtitle={<span>by: {tile.author}</span>}
							// 						actionIcon={tile.line_url && (
							// 							<IconButton target="_blank" href={tile.line_url}>
							// 							<InfoIcon className={classes.cardTitleWhite}/>
							// 							</IconButton>)
							// 						}
							// 						/>
							// 					</ImageListItem>
							// 					))}
							// 				</ImageList>
							// 		</div>
							// 		<Popover
							// 			id="pop2"
							// 			open={state.pop2}
							// 			onClose={handleClose2}
							// 			anchorReference="anchorPosition"
							// 			anchorPosition={{top:80,left:550}}
							// 				anchorOrigin={{vertical:'bottom',horizontal:'center',}}
							// 				transformOrigin={{vertical:'top',horizontal:'center',}}
							// 		>
							// 			<SchLinePicPop
							// 				detailParam = {state} //store={token}
							// 				{...props}
							// 			/>
							// 		</Popover>
							// 			</GridItem>
							// 		</GridContainer>
							// )}
						]}>   
					</CustomTabs>
				</div>
			</div> 
			<h6 style={{paddingBottom: '15px',paddingTop: '10px',paddingLeft: '15px',paddingRight: '15px',color:'red',fontWeight:'bold' }}>※ 상기 스케줄은 실제 운항 스케줄과 상이할수 있습니다. 업무 진행시 선사와 필히 확인하시기 바랍니다.</h6>
	{/* </div> */}
		</GridItem>
	);
}

const CustomAutoComplete =(props)=>{
	const {portData ,itemType , userData, alertMessage, onAlert, portValue, setPortValue} =props;
    const [ checkedItems, setCheckedItems ] = useState(new Set());
    const [ options, setOptions ] = useState([]);
    const [ portList, setPortList ] = useState([]);

    useEffect(() => {
		if((portData&&portData.length) !== (portList&&portList.length)){
			portData.length>0&&selectBookmark([...portData]);
			setPortList([...portData])
		}
    }, [portData]);

	
    const selectBookmark =(portList)=>{ 
		if(userData){
            const checkType = itemType=='pol'?'S':itemType=='pod'?'E':''
            axios.post(`/shipper/selectPortBookmark`,{type :checkType, })  //최초셀렉트
            .then(res => {      
                let result =res.data;
				let opt =[];
                if(result.length>0){
					 opt = portList.map(obj=>{
						const bookmarkBool = result.findIndex(y=>y.port_code==obj.port_code)>-1?'즐겨찾기':'기본';
						return {...obj, bookmark: bookmarkBool};
					})	
					// setOptions(opt.sort((a,b)=>b.bookmark.localeCompare(a.bookmark)))
					setCheckedItems(new Set(result.map(item=>item.port_code)));
				}else{
					// setOptions(portData)
					opt = portList.map(obj=>{
						return {...obj, bookmark: '기본'}
					});
				}
				setOptions(opt.sort((a,b)=>b.bookmark.localeCompare(a.bookmark)))
				// setOptions(portData)
            }).catch(err => {
                if(err.response.status === 403||err.response.status === 401) {
                    // alertMessage('[오류] PORT 조회할 수 없습니다', 'error');
                    // console.log('[오류]', err);
                    // setPortData([])
                }
            });
        }else{
            setCheckedItems(new Set())
            setOptions(portData)
        }
	}
   
    const onCheckedBookmark = (isChecked , type , value)=>{
        // setIsFocused(true);
        if(!userData){
            alertMessage(validation.NOTLOGIN_MSG, 'error');
            return false
        }
        if(isChecked){// 즐겨찾기 추가 
            axios.post("/shipper/insertPortBookmark",{port_code:value , type :type})
            .then(res => {      
                setCheckedItems(prev=>{
                    const set = new Set(prev);
                    set.add(value)
                    return set
                });
				const opt = options.map(obj=>{
					if(obj.port_code ==value){
						return {...obj, bookmark: '즐겨찾기'}
					}else{return obj}
				})
				setOptions(opt.sort((a,b)=>b.bookmark.localeCompare(a.bookmark)))
                if(res.data === 23505) {
                    alertMessage("이미 등록있는 업체입니다.", 'error')
                }
            }).catch(err => {
				onAlert( 'error',validation.ERR_MSG);
                // if(err.response.status === 403||err.response.status === 401) {
                    // alertMessage('[오류]', 'error');
                    // setPortData([])
                // }
            });
        }else if (!isChecked){
            axios.post("/shipper/deletePortBookmark",{port_code:value , type :type})
            .then(res => {      
                setCheckedItems(prev=>{
                    const set = new Set(prev);
                    set.delete(value)
                    return set
                });
				const opt = options.map(obj=>{
					if(obj.port_code ==value){
						return {...obj, bookmark: '기본'}
					}else{return obj}
				})
				setOptions(opt.sort((a,b)=>b.bookmark.localeCompare(a.bookmark)));
                if(res.data === 23505) {
                    alertMessage("이미 등록있는 업체입니다.", 'error')
                }
            }).catch(err => {
				onAlert( 'error',validation.ERR_MSG);
                // if(err.response.status === 403||err.response.status === 401) {
                    // console.log('[오류]', err);
                    // setPortData([])
                // }
            });
        }
    }
	return (<>
	<Autocomplete
		options = {options}
		// disableCloseOnSelect
		getOptionLabel = { option =>option.port_code? "["+option.port_code+"] "+option.port_name:''}
		getOptionSelected = { (option,value) => option.port_code == value.port_code}
		id="start"
		onChange={(e,option)=>setPortValue(option||null)}
		value={portValue}
		renderInput={params => (
				<TextField {...params} label={itemType=='pol'?"POL":'POD'}  fullWidth />
		)}
		groupBy={option=>option.bookmark}
		renderOption={(option,{inputValue,selected})=>{
			return(<>
			<div style = {{width:'100%', display:'flex' , justifyContent:'space-between'}}>
				<div style ={{ fontSize:'13px'}}>
					{option.port_code? "["+option.port_code+"] "+option.port_name:''}
				</div> 
				{userData?
				<div style ={{ display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
					{/* <input type="checkbox" checked={checkedItems.has(option.port_code)?true:false}
                                    onChange={(e)=>onCheckedBookmark(e.target.checked,itemType,option.port_code.toUpperCase())}
                                    /> */}
					{checkedItems.has(option.port_code)
					?<StarIcon onClick={(e)=>onCheckedBookmark(false,itemType,option.port_code.toUpperCase())} style={{color:'#00acc1',verticalAlign:'bottom'}}
					 />
					:<StarBorderIcon onClick={(e)=>onCheckedBookmark(true,itemType,option.port_code.toUpperCase())} style={{color:'#00acc1',verticalAlign:'bottom'}}/>
				    }
					{/* <Checkbox
					icon={icon}
					checkedIcon={checkedIcon}
					style={{zIndex:'10020', background:'green' ,width:'100%'}}
					checked={checkedItems.has(option.port_code)?true:false}
					// onChange={(e)=>onCheckedBookmark(e.target.checked,itemType,option.port_code.toUpperCase())}
					onClick={(e)=>onCheckedBookmark(e,itemType,option.port_code.toUpperCase())}
				/> */}
				</div>
				:''}
			</div>
			</>
		)}
	}
	/>
	</>)
}