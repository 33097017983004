/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// lineConfigDetail.fix_cfs_container 사용안하는듯? 

import React, { useState, useEffect, createRef } from 'react';
import { Row, Col,  CardHeader, Collapse,FormGroup,Label,Input, Card, CardBody, Tooltip, ButtonGroup,Badge } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import Button from "muiComponents/CustomButtons/Button.js";

import axios from 'axios';
import Moment from 'moment';
import * as validation from 'components/common/validation.js';
import _ from 'lodash';

export default function Container(props){
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [options, setOptions] = useState({});
    const [inputKey, setInputKey] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [valueObjYn, setValueObjYn] = useState(false);
    const [inputKey2, setInputKey2] = useState('');
    const [inputValue2, setInputValue2] = useState('');
    const [valueObj, setValueObj] = useState({});

    const fileInput = createRef();

    const {userData,} = props;

    const handleClick = () => {
        // if(files && files.length === 3) {
            // alert("첨부파일은 최대 3개 까지 업로드 가능합니다.");
            // return;
        // }
        fileInput.current.click();
    };
    const handleImageChange = async(file) => {
        file.preventDefault();
        if(!file.target.files[0]) {
            return false;
        }
        if(!validation.koreanBan(file.target.files[0].name)) {
           alert( '파일명에 한글이 포함될 수 없습니다.' );
            return false;
        }
        
        if(!/\.(xls|xlsx|txt|pdf)$/i.test(file.target.files[0].name)) {
           alert( '적절하지 않은 파일 형식입니다.' );
            return false;
        }
        if(file.target.files[0].size > 4000000) {
           alert( '파일의 크기가 너무 큽니다.' );
            return false;
        }
        const formData = new FormData();
        formData.append("doc_number",Moment(new Date()).format('YYMMDDHHmm')  );
        formData.append("doc_date",Moment(new Date()).format('YYYYMMDD')  );
        formData.append("doc_user", userData.user_no);
        formData.append("attach_gb","C");
        formData.append("cntr_seq",'1');
        formData.append("file",file.target.files[0]);
        await axios.post("/shipper/saveAttachFile",formData).then(res => {
                    const result =res.data.result;
                    setFiles([...files,result]);
        }).catch(e => {
           alert(validation.ERR_MSG);
        })
    };

    const sendFTP =()=>{
      console.log(files)

      axios.post(' /shipper/sendFileTest',{param:files, option:{...options}}).then(res => {
          if(res.statusText==="OK") {
                  alert('success','완료');
          }
      }).catch(e => {
          alert(validation.ERR_MSG);
      })
      fileInput.current.value = null;
    }

    const onSubmit =(num, key, value)=>{
        if(typeof(value)=='string'){
            if(value?.toLowerCase() =='true'){
                value = true
            }else if (value?.toLowerCase() =='false'){
                value = false
            }
        }
        console.log(key, value)
        if (num ==1 ){
            setOptions({...options, [key]:value})
        }else if (num ==2){
            setValueObj({...valueObj, [key]:value})
        }
    }

    return (
        <>
        <Card className="no-transition" style={{border:'1px solid silver', paddingBottom:'25px', zIndex : 'auto'}} >
            <CardHeader className="pt-1 pb-1">
                <Row>
                    <Col>
                        <input style={{display:'none', width:'30%'}} type="file" onChange={handleImageChange} ref={fileInput} />
                        <ButtonGroup className="pull-right pr-2 pt-3">
                            <>
                                <Button
                                    id="btn1"
                                    className="pt-0 pb-0"
                                    color="rose"
                                    data-toggle="tooltip"
                                    type="button"
                                    onClick={handleClick}><i className="fa fa-upload"/>업로드
                                </Button>
                                <Tooltip placement="top" isOpen={tooltipOpen} target="btn1" toggle={() => setTooltipOpen(!tooltipOpen)}>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <span style={{fontWeight:'bold', fontSize:'1rem'}}>허용 파일</span><span> : xls, xlsx, txt, pdf </span>
                                                <br></br>
                                                <span style={{fontWeight:'bold', fontSize:'1rem'}}>파일 크기 제한</span><span> : 40MB</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Tooltip>
                            </>
                            {/* <Button
                                className="pt-0 pb-0"
                                id="btn1"
                                color="default"
                                data-toggle="tooltip"
                                outline
                                type="button"
                                onClick={() => onDelContainer()}><i className="fa fa-times"/>삭제</Button> */}
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-3">
                    </Col>
                    <Col>
                        <Row>
                        {files.length>0 && files.map((value,index) => {
                        return(
                            <Col key={index} className="pull-right pr-2">
                                {value &&
                                <>
                                <Button 
                                    className="btn-link" 
                                    outline
                                            style={{paddingLeft:'8px',textDecorationLine:'underline',color:'blue'}} >{validation.textLengthOverCut(value.real_file_name?value.real_file_name:'',20,'...')}
                                </Button>
                                {/* <Button
                                    color="danger"
                                    className="btn-round btn-link"
                                    onClick={() => handleRemoveFile(index+1)}><i className="fa fa-times" />
                                </Button> */}
                                </>}
                            </Col>)
                        })}
                        </Row>
                    </Col>
                </Row>
            </CardHeader>
        </Card>
        <Row style={{marginBottom:'30px'}}>
            <Button
                color="danger"
                className="btn-round btn-link"
                onClick={() => sendFTP()}>전송
            </Button>                        
        </Row>
        <Row style={{marginTop:'10px', display:'flex', flexDirection:'column'}}>
            <div >최종 OPTIONS :  {JSON.stringify(options)}</div> 
            <div style={{marginBottom:'10px'}}>
                <TextField id="inputKey" name="inputKey" label={<font size="2">Key</font>} 
                onChange={event => setInputKey(event.target.value)} value={inputKey} variant="outlined" size="small"  />
            </div>
            {valueObjYn &&<div style={{marginBottom:'10px'}}>
                        VALUE : {JSON.stringify(valueObj)}
                        </div>
                }
        </Row>
        <Row style={{margin:'10px 5px' ,display:'flex',alignItems:'center'}}>
            <Button  color="warning" size="md"   onClick={()=>setValueObjYn(!valueObjYn)}>Value Object형{ valueObjYn? ': Y':': N'}</Button>
            {valueObjYn ?
                <Row style={{display:'flex' ,alignItems:'center'}}>
                <div >
                    <TextField id="inputKey" name="inputKey" label={<font size="2">Key2</font>} 
                    onChange={event => setInputKey2(event.target.value)} value={inputKey2} variant="outlined" size="small"  />
                    <TextField id="inputValue" name="inputValue" label={<font size="2">Value2</font>} 
                    onChange={event => setInputValue2(event.target.value) } value={inputValue2} 
                    variant="outlined" size="small"  />
                </div>
                <Button  color="info" size="md"  onClick={()=>onSubmit(2,inputKey2,inputValue2)}>Value추가</Button>
                <Button  color="info" size="md"  onClick={()=>setValueObj({})}>Value초기화</Button>
            </Row>
            :
            <div >
                <TextField id="inputValue" name="inputValue" label={<font size="2">Value</font>} 
                onChange={event => setInputValue(event.target.value) } value={inputValue} 
                variant="outlined" size="small"  />
            </div>
            }
        </Row>
        <Row>
            <Button  color="primary" size="lg"   onClick={()=>onSubmit(1,inputKey,!valueObjYn?inputValue:valueObj)}>옵션추가</Button>
            <Button  color="primary" size="lg"   onClick={()=>setOptions({})}>옵션초기화</Button>
        </Row>
    </>
    );
}

export {Container};