/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row, Col, CardBody,FormGroup,Label,Input, Card,Badge, Collapse} from "reactstrap";
import axios from 'axios';
import Select from "react-select";
import InputValid from "components/CustomInput/InputValid.js";
import Moment from 'moment';
import {CustomDateTimePicker} from 'components/CustomInput/reactstrap/CustomInput';
import * as options from 'components/common/options.js';

export default function Container(props) { //console.log("cntr:",props)

	const {cntrData,codelist,size,bkgdata,checked,pack, userData,zindex, bookmarkoption, bkglist, isDupCheck, loadData} = props;
	const [selectVal,setSelectVal] = useState("");
	const [cntr, setCntr] = useState({});
	const [verifiedDateStyle,setVerifiedDateStyle]= useState({});
	const [verifiedDateValid,setVerifiedDateValid]= useState(cntr.cntr_verifying_date?cntr.cntr_verifying_date:'');
	const [cntrVerifyingDateName,setCntrVerifyingDateName]=useState();
	const [cntrNoNA,setCntrNoNA]=useState(loadData.cntr_no?loadData.cntr_no+(cntrData.cntr_seq-1):null);
	const [cntrCodeNA,setCodeNA]=useState(loadData.cntr_code?loadData.cntr_code:null);
	const [dgOpen,setDgOpen]=useState((cntrData.special_undg||cntrData.special_imdg)?true:false);
	const [vgmOpen,setVgmOpen]=useState(loadData.sr_vgm_close_option==='Y'?false:true);
	const [oogOpen,setOogOpen]=useState((cntrData.cntr_length||cntrData.cntr_width||cntrData.cntr_height)?true:false);

	// const vgmList = [{value:'SM1',label:'방법1'},{value:'SM2',label:'방법2'}]

  	useEffect(() => {	
		if((cntrData.is_dg||cntrData.special_undg||cntrData.special_imdg)?true:false)setDgOpen(true);
		if((cntrData.is_oog||cntrData.cntr_length||cntrData.cntr_width||cntrData.cntr_height)?true:false)setOogOpen(true);
    	setCntr({
			...cntrData,
			cntr_res_bkg_no
			:cntrData.cntr_res_bkg_no
			?cntrData.cntr_res_bkg_no:cntrData.cntr_res_bkg_no===''?'':bkgdata,//?????
			cntr_yn:cntrData.cntr_yn?cntrData.cntr_yn:checked?'Y':'N',
		});
  	},[cntrData]);

	useEffect(()=>{
		if(cntrData.cntr_res_bkg_no&&
			(bkglist.length>0&&bkglist.findIndex(x=>x.value===cntr.cntr_res_bkg_no)>=0))
			onChangeBKGNO(cntrData.cntr_res_bkg_no,'cntr_res_bkg_no')
	},[cntrData.cntr_res_bkg_no]);

	//vgm옵션화
	useEffect(()=>{
		setVgmOpen(loadData.sr_vgm_close_option==='Y'?false:true);
		if(loadData.sr_vgm_close_option==='Y'){
			return props.propsData({...cntr
				,cntr_res_bkg_no:''
				,cntr_total_weight:''
				,cntr_verifying_type:''
				,cntr_auth_user_name:''
				,cntr_verifying_no:''
				,cntr_verifying_date:''
				,cntr_verifying_date_name:''
			});
		}
	},[loadData.sr_vgm_close_option]);

  	useEffect(() => {
			//bkglist&&bkglist.findIndex(x=>x.value===cntrData.cntr_res_bkg_no)<0&&onChangeCntrReturnVal(null,'cntr_res_bkg_no')
			if(bkglist&&bkglist.find(x=>x.cntr_res_bkg_no!=='')&&(bkglist.length!==1||(bkglist.length===1&&bkglist[0].value===cntrData.cntr_res_bkg_no))){
				//console.log('cntrData.cntr_res_bkg_no',cntrData.cntr_res_bkg_no);
				onChangeBKGNO(cntrData.cntr_res_bkg_no,'cntr_res_bkg_no');
			}
  	},[bkglist]);

	useEffect(()=>{
		if(loadData.cntr_no){
			setCntrNoNA(loadData.cntr_no+(cntrData.cntr_seq===0?0:cntrData.cntr_seq-1));
		}else{
			setCntrNoNA(null);
		}
	},[loadData.cntr_no]);

	useEffect(()=>{
		if(loadData.cntr_code){
			setCodeNA(loadData.cntr_code);
		}else{
			setCodeNA(null);
		}
	},[loadData.cntr_code]);

 
	const onHandleReturnVal = (event,name) => {
		if(typeof event==='string'){
			var list = {...cntr, [name]:event};
			setCntr(list);
		}else if(typeof event==='object'){
			var list = {...cntr, [name]:(event.target.value.toUpperCase())||null};
			setCntr(list);
		}
	}
 
	const onHandleCheckReturnVal = (data,name) => {
		let list = {...cntr, [name]:data.toUpperCase()};
		setCntr(list);
		props.propsData(list); 
	}
 
	const onChangeCntrReturnVal = (value,name) => {
		let list = {...cntr, [name]:value,cntr_seq:cntrData.cntr_seq?cntrData.cntr_seq:''};
		setCntr(list);
		props.propsData(list); 
	}
	const onChangeBKGNO = (value,name) => {
		axios.post("/shipper/getOogSrInfo",{sr_no:cntrData.sr_no,bkg_no:value,cntr_code:cntr.cntr_code}).then(res => {  
			if(res.data[0]===undefined||res.data[0].cntr_length===undefined||res.data[0].cntr_width===undefined ||res.data[0].cntr_height===undefined ||(res.data[0].cntr_length==''&&res.data[0].cntr_width==''&&res.data[0].cntr_height=='')||(res.data[0].cntr_length==null&&res.data[0].cntr_width==null&&res.data[0].cntr_height==null)){
				axios.post("/shipper/getOogInfo",{bkg_no:value,cntr_code:cntr.cntr_code}).then(res => {  
					//console.log(res.data[0]);  
					if(res.data[0]===undefined||res.data[0].cntr_length===undefined||res.data[0].cntr_width===undefined ||res.data[0].cntr_height===undefined ||(res.data[0].cntr_length==''&&res.data[0].cntr_width==''&&res.data[0].cntr_height=='')||(res.data[0].cntr_length==null&&res.data[0].cntr_width==null&&res.data[0].cntr_height==null)){
						cntrDgInfo(value,cntrData.cntr_code,cntrData.sr_no,cntrData.cntr_no,cntrData.cntr_seq,value,name);
					}else{
						cntrOogInfo(value,cntr.cntr_code,value,name);
					}
				});
			}else{
				cntrOogInfo(value,cntr.cntr_code,value,name);
			}
		});
		
	}
	

	// const onChangeSizeType = (value,name) => {
	// 	axios.post("/shipper/getOogSrInfo",{sr_no:cntrData.sr_no,bkg_no:cntr.cntr_res_bkg_no,cntr_code:value}).then(res => 
	// 		{  
	// 		if(res.data[0]===undefined||res.data[0].cntr_length===undefined||res.data[0].cntr_width===undefined ||res.data[0].cntr_height===undefined ||(res.data[0].cntr_length==''&&res.data[0].cntr_width==''&&res.data[0].cntr_height=='')||(res.data[0].cntr_length==null&&res.data[0].cntr_width==null&&res.data[0].cntr_height==null)){
	// 			axios.post("/shipper/getOogInfo",{bkg_no:cntr.cntr_res_bkg_no,cntr_code:value}).then(res => {  
	// 				//console.log(res.data[0]);  
	// 				if(res.data[0]===undefined||res.data[0].cntr_length===undefined||res.data[0].cntr_width===undefined ||res.data[0].cntr_height===undefined ||(res.data[0].cntr_length==''&&res.data[0].cntr_width==''&&res.data[0].cntr_height=='')||(res.data[0].cntr_length==null&&res.data[0].cntr_width==null&&res.data[0].cntr_height==null)){
	// 					cntrDgInfo(cntrData.cntr_res_bkg_no,value,cntrData.sr_no,cntrData.cntr_no,cntrData.cntr_seq,value,name);
	// 				}else{
	// 					cntrOogInfo(cntr.cntr_res_bkg_no,value,value,name);
	// 				}
	// 			});
	// 		}else{
	// 			cntrOogInfo(cntr.cntr_res_bkg_no,value,value,name);
	// 		}
	// 	});
	// }
  
	const onPropsReturn = ()=> {
		props.propsData(cntr);
	}
  
  
	const onDeleteCntr =(data) => {
		props.deleteRow(data);
	}

    
	const onBlurProps = ()=>{
		return props.propsData(cntr);
	}
	  
	const onChangeCntrBookmark = (value) =>{
		setSelectVal(value);
		axios.post("/shipper/getUserCntrBookmark",{user_no:userData?userData.user_no:'',seq:value}).then(res => {  
			setCntr({...res.data[0],'cntr_yn':cntr.cntr_yn?cntr.cntr_yn:checked?'Y':'N'}); 
			props.propsData({...res.data[0],'cntr_yn':cntr.cntr_yn?cntr.cntr_yn:checked?'Y':'N'});	            	    
		});
	}
    const fncOnChangeDateInput = (params)=> { 
		var checkType =typeof(params); 
		if( checkType === 'string' ) { 
		 const now =Moment(new Date()).format('YYYYMMDDHHmm'); 
		 let date = params.replace(/[^0-9]/g, ''); 
		 let result =Moment(date,'YYYYMMDDHHmm',true).isValid();
		 setVerifiedDateStyle({style:{border: '1px solid #dc3545'}});
		 setVerifiedDateValid(params);
		 if(!result && params.length === 12) { 
			setVerifiedDateStyle({style:{border: '1px solid #dc3545'}});
			props.onAlert('danger','YYYYMMDDHHmm 형식으로 입력해주세요. ex)'+now);
		 }
		 if( date.length === 12 ) { 
		  let dateTime = Moment(date,'YYYYMMDDHHmm'); 
		  if( dateTime.isValid() ) { 
		  	// if(dateTime > new Date().setDate(new Date().getDate())){
				setCntr({ 
					...cntr, 
					cntr_verifying_date_name:dateTime.format('YYYY-MM-DD HH:mm'), 
					cntr_verifying_date:dateTime.format('YYYYMMDDHHmm') 
				}); 
				setVerifiedDateValid(dateTime.format('YYYYMMDDHHmm'));
				props.propsData({
				  ...cntr,
				  cntr_verifying_date_name:dateTime.format('YYYY-MM-DD HH:mm'),
				  cntr_verifying_date:dateTime.format('YYYYMMDDHHmm'),
				});
				setVerifiedDateStyle({});
					
				setCntrVerifyingDateName(cntr.cntr_verifying_date_name);
			// }else{
			// 	props.onAlert('danger','이전 날짜로 설정 하실 수 없습니다.');
			// 	setVerifiedDateStyle({style:{border: '1px solid #dc3545'}});
			// 	setVerifiedDateValid('이전날짜');
			// } 
		  }else{
			props.onAlert('danger','YYYYMMDDHHmm 형식으로 입력해주세요. ex)'+now);
		  }
		 } 
		} else {
			let dateTime = Moment(params,'YYYYMMDDHHmm');
			if( dateTime.isValid() ) { 
				// if(dateTime > new Date().setDate(new Date().getDate())){
					setCntr({ 
						...cntr, 
						cntr_verifying_date_name:dateTime.format('YYYY-MM-DD HH:mm'), 
						cntr_verifying_date:dateTime.format('YYYYMMDDHHmm') 
					}); 
					setVerifiedDateValid(dateTime.format('YYYYMMDDHHmm'));
					props.propsData({
						...cntr,
						cntr_verifying_date_name:dateTime.format('YYYY-MM-DD HH:mm'),
						cntr_verifying_date:dateTime.format('YYYYMMDDHHmm'),
					});
					setVerifiedDateStyle({});
				// }else{
				// 	props.onAlert('danger','이전 날짜로 설정 하실 수 없습니다.');
				// 	setVerifiedDateStyle({style:{border: '1px solid #dc3545'}});
				// 	setVerifiedDateValid('이전날짜');
				// }
			}else{
				const now =Moment(new Date()).format('YYYYMMDDHHmm');
				props.onAlert('danger','YYYYMMDDHHmm 형식으로 입력해주세요. ex)'+now);
			}
		} 
	}
	
	const cntrDgInfo = (cntr_res_bkg_no,cntr_code,sr_no,cntr_no,cntr_seq,value,name)=>{
		if(bkglist.length!==1||(bkglist.length===1&&bkglist[0].value===cntr_res_bkg_no)){
			if(cntr_res_bkg_no){
				axios.post("/shipper/getSrDgInfo",{sr_no:sr_no,cntr_no:cntr_no,cntr_code:cntr_code,cntr_seq:cntr_seq,cntr_res_bkg_no:cntr_res_bkg_no}).then(res1 => {
					if(res1.data[0]===undefined){
						axios.post("/shipper/getDgInfo",{bkg_no:cntr_res_bkg_no,cntr_code:cntr_code}).then(res => {
							async function cntrSynchronous1() {
								let promise =new Promise((resolve,reject)=>{
									setCntr({
										...cntrData,
										cntr_length:'',
										cntr_width:'',
										cntr_height:'',
										special_undg:'',
										special_imdg:'',
										cntr_code:cntr_code,
										cntr_seq:cntr_seq,
										[name]:value,
									});
									resolve(true);
								}); 
								const flag=await promise; 
								if(flag)props.propsData({
									...cntrData,
									cntr_length:'',
									cntr_width:'',
									cntr_height:'',
									special_undg:'',
									special_imdg:'',
									cntr_code:cntr_code,
									cntr_seq:cntr_seq,
									[name]:value,
								});
								setDgOpen(false);
								setOogOpen(false);
							}
			
							if(res.data[0]===undefined){
								cntrSynchronous1();
							}else{
								async function cntrSynchronous2() {
									let promise =new Promise((resolve,reject)=>{
										setCntr({
											...cntrData,
											special_undg:res.data[0].special_undg,
											special_imdg:res.data[0].special_imdg,
											cntr_code:cntr_code,
											cntr_seq:cntrData.cntr_seq,
											[name]:value,
										});
										resolve(true);
									}); 
									const flag=await promise; 
									if(flag){
										props.propsData({
										...cntrData,
										special_undg:res.data[0].special_undg,
										special_imdg:res.data[0].special_imdg,
										cntr_code:cntr_code,
										cntr_seq:cntrData.cntr_seq,
										is_dg:'dg',
										is_oog:'',
										[name]:value,
										});
									}
									setDgOpen(true);
									setOogOpen(false);
								}
								cntrSynchronous2();
								
							}
						});
					}else{
						async function cntrSynchronous3() {
							let promise =new Promise((resolve,reject)=>{
								setCntr({
									...cntrData,
									cntr_length:'',
									cntr_width:'',
									cntr_height:'',
									special_undg:res1.data[0].special_undg,
									special_imdg:res1.data[0].special_imdg,
									cntr_code:cntr_code,
									cntr_seq:cntr_seq,
									[name]:value,
								});
								resolve(true);
							}); 
							const flag=await promise; 
							if(flag)props.propsData({
								...cntrData,
								cntr_length:'',
								cntr_width:'',
								cntr_height:'',
								special_undg:res1.data[0].special_undg,
								special_imdg:res1.data[0].special_imdg,
								cntr_code:cntr_code,
								cntr_seq:cntr_seq,
								is_dg:'dg',
								is_oog:'',
								[name]:value,
							});
							setDgOpen(true);
							setOogOpen(false);
						}
						cntrSynchronous3();
					}

				});

			}else{
				let list = {...cntrData,cntr_res_bkg_no:'', [name]:value};
				setCntr(list);
				//props.propsData(list); 
				setDgOpen(false);
				setOogOpen(false);
			}
		}
	}

	const cntrOogInfo = (cntr_res_bkg_no,cntr_code,value,name)=>{
		if(bkglist.length!==1||(bkglist.length===1&&bkglist[0].value===cntr_res_bkg_no)){
			if(cntr_res_bkg_no){
				axios.post("/shipper/getOogSrInfo",{sr_no:cntrData.sr_no,bkg_no:cntr_res_bkg_no,cntr_seq:cntrData.cntr_seq?cntrData.cntr_seq:''}).then(res => 
					{ 
					if(res.data[0]===undefined){
						axios.post("/shipper/getOogInfo",{bkg_no:cntr_res_bkg_no,cntr_code:cntr_code}).then(res => {  
							if(res.data[0]===undefined || res.data[0].cntr_length===null){
								setCntr({
									...cntrData,
									cntr_length:'',
									cntr_width:'',
									cntr_height:'',
									special_undg:'',
									special_imdg:'',
									[name]:value,
								});
								props.propsData({
									...cntrData,
									cntr_length:'',
									cntr_width:'',
									cntr_height:'',
									special_undg:'',
									special_imdg:'',
									[name]:value,
								}); 
								setDgOpen(false);
								setOogOpen(false);
							}else{
								setCntr({
									...cntrData,
									cntr_length:res.data[0].cntr_length,
									cntr_width:res.data[0].cntr_width,
									cntr_height:res.data[0].cntr_height,
									special_undg:'',
									special_imdg:'',
									[name]:value,
								});
								props.propsData({
									...cntrData,
									cntr_length:res.data[0].cntr_length,
									cntr_width:res.data[0].cntr_width,
									cntr_height:res.data[0].cntr_height,
									special_undg:'',
									special_imdg:'',
									is_dg:'',
									is_oog:'oog',
									[name]:value,
								}); 
								setDgOpen(false);
								setOogOpen(true);
							}
						});
					}else{
						setCntr({
							...cntrData,
							cntr_length:res.data[0].cntr_length,
							cntr_width:res.data[0].cntr_width,
							cntr_height:res.data[0].cntr_height,
							special_undg:'',
							special_imdg:'',
							[name]:value,
						});
						props.propsData({
							...cntrData,
							cntr_length:res.data[0].cntr_length,
							cntr_width:res.data[0].cntr_width,
							cntr_height:res.data[0].cntr_height,
							special_undg:'',
							special_imdg:'',
							is_dg:'',
							is_oog:'oog',
							[name]:value,
						}); 
						setDgOpen(false);
						setOogOpen(true);
					}
				});
			}else{
				let list = {...cntrData, [name]:value};
				setCntr(list);
				props.propsData(list); 
				setDgOpen(false);
				setOogOpen(false);
			}
		}
	}
  	return (
		<Card className="no-transition mb-2" style={{border:'1px solid silver',zIndex:zindex}}> 	   
			<CardBody className="pt-0 pb-3">
				<Row>
					<Col className="pb-2 pr-2">
				     	<button
			              className="close mt-1"
			              type="button"
			              onClick={(cntr) => onDeleteCntr(cntr)}
			            >×</button>
			      	</Col>
				</Row>
				<Row>
					<Col xl="0" className="col-0 pl-2 mt-auto mb-auto">
						<FormGroup check style={{height:'69px'}}>
							<Label check>
							<Input 
								type="checkbox"  
								checked={cntr.cntr_yn==="Y"?true:false} 
								onChange = {()=>onHandleCheckReturnVal(cntr.cntr_yn==="Y"?"N":"Y",'cntr_yn')}/>
								<span className="form-check-sign"/>
							</Label>
						</FormGroup>
					</Col>
					<Col>
						<Row>
							<Col xl="4" lg="4" md="6">
								<FormGroup className="mb-1">
									<Select
										className="customSelect bg-white"
										name="container_bookmark_seq"
										value={{value:selectVal?selectVal:'',
												label:selectVal?(bookmarkoption.findIndex(x=>x.value===selectVal)>=0)?bookmarkoption[bookmarkoption.findIndex(x=>x.value===selectVal)].label:'선택':'선택'
										}}
										onChange = {(value)=>onChangeCntrBookmark(value.value,'container_bookmark_seq')}
										options={bookmarkoption}
										styles={{
											control: provided => ({...provided,minHeight:!size?'40px':'28px',height:!size?'40px':'28px'}),
											indicatorsContainer: provided => ({...provided,height:!size?'40px':'28px'})
										}}/>
								</FormGroup>	
							</Col>
						</Row>
						<Row>
							<Col xl="4" sm="4" md="4" lg="6" xs="6">
								<FormGroup className="mb-1">
									<Label className="mb-0">Container No </Label>
									<InputValid 
										type="text"
										name="cntr_no"
										id="cntr_no"
										maxLength="11"
										value={cntrNoNA?cntrNoNA:cntr.cntr_no?cntr.cntr_no:''}
										onChange={(e)=>onHandleReturnVal(e, 'cntr_no')}
										onBlur={onBlurProps}
										validtype="engNumber"
										required={true} 
										feedid="container"
										dupcheck = {isDupCheck?isDupCheck:undefined}
									/>
									{/* <div>{isDupNo?'true':'false'}</div> */}
								</FormGroup>	
							</Col>
							<Col xl="4" sm="4" md="4" lg="6" xs="6">
								<FormGroup className="mb-1">
									<Label className="mb-0">Seal No</Label>
									<InputValid 
										type="text"
										name="cntr_seal"
										id="cntr_seal"
										maxLength="10"
										value={cntr.cntr_seal?cntr.cntr_seal:''}
										onChange={(e)=>onHandleReturnVal(e, 'cntr_seal')}
										onBlur={onBlurProps}
										validtype="eng"
										required={true} 
										feedid="container"
									/>
								</FormGroup>
							</Col>
							<Col xl="4" sm="4" md="4" lg="12" xs="12">
								<FormGroup className="mb-1">
									<Label className="mb-0">Size/Type</Label>
									<Select
										className="customSelect"
										name="cntr_code"
										value={cntrCodeNA?cntrCodeNA
											:cntr.cntr_code
												?codelist.findIndex(x=>x.value===cntr.cntr_code)>=0
													&&codelist[codelist.findIndex(x=>x.value===cntr.cntr_code)]
												:{value:'',label:'선택'}
										}
										// value={{value:cntrCodeNA?cntrCodeNA:cntr.cntr_code?cntr.cntr_code:'',
										// label:cntrCodeNA?cntrCodeNA:(cntr.cntr_code?
										// 	(codelist.findIndex(x=>x.value===cntr.cntr_code)>=0)?
										// 			codelist[codelist.findIndex(x=>x.value===cntr.cntr_code)].label:
										// 		'선택':'선택')
										// }}
										getOptionLabel = {options=>options.label+" ["+options.value+"] "}
										// onChange = {(value)=>onChangeSizeType(value.value,'cntr_code')}
										onChange = {(value)=>onChangeCntrReturnVal(value.value,'cntr_code')}
										options={cntrCodeNA?'':codelist}
										styles={{
											control: provided => ({...provided,border:cntrCodeNA?'':!cntr.cntr_code?'1px solid red':'',minHeight:!size?'40px':'',height:!size?'40px':''}),
											indicatorsContainer: provided => ({...provided,height:!size?'40px':''})
										}}
									/>
									<InputValid 
										hidden
										name="cntr_code"
										id="cntr_code"
										maxLength="4"
										value={cntrCodeNA?cntrCodeNA:cntr.cntr_code?cntr.cntr_code:''}
										validtype="eng"
										required={true} 
										readOnly
										feedid="container"
									/>
								</FormGroup>	
							</Col>
						</Row>
						<Row>
							<Col xl="4" sm="4" md="4" lg="4" xs="4">
								<FormGroup className="mb-1">
									<Label className="mb-0">Weight</Label>
									<InputValid 
										type="text"
										name="cntr_weight"
										id="cntr_weight"
										maxLength="18"
										value={cntr.cntr_weight?cntr.cntr_weight:''}
										onChange={(e)=>onHandleReturnVal(e, 'cntr_weight')}
										onBlur={onBlurProps}
										required={true} 
										inputgrouptext="KG"
										feedid="container"
										validtype="decimal"
										decimallength="3"
									/>
								</FormGroup>
							</Col>
							<Col xl="4" sm="4" md="4" lg="4" xs="4">
								<FormGroup className="mb-1">
									<Label className="mb-0">Volume</Label>
									<InputValid 
										type="text"
										name="cntr_total_volume"
										id="cntr_total_volume"
										maxLength="18"
										value={cntr.cntr_total_volume?cntr.cntr_total_volume:''}
										onChange={(e)=>onHandleReturnVal(e, 'cntr_total_volume')}
										onBlur={onBlurProps}
										required={false} 
										inputgrouptext="CBM"
										feedid="container"
										validtype="decimal"
										decimallength="3"
									/>
								</FormGroup>
							</Col>
							<Col xl="4" sm="4" md="4" lg="4" xs="4">
								<FormGroup className="mb-1">
									<Label className="mb-0">Package</Label>
									<Row>
										<Col className="col-8 pr-1">
											<Select
												className="customSelect"
												name="cntr_carton_code"
												value={cntr.cntr_carton_code
														?pack.findIndex(x=>x.value===cntr.cntr_carton_code)>=0
															&&pack[pack.findIndex(x=>x.value===cntr.cntr_carton_code)]
														:{value:'',label:'선택'}
												}
												// value={{value:cntr.cntr_carton_code?cntr.cntr_carton_code:'',
												// 		label:cntr.cntr_carton_code?
												// 		(pack.findIndex(x=>x.value===cntr.cntr_carton_code)>=0)?
												// 				pack[pack.findIndex(x=>x.value===cntr.cntr_carton_code)].label:
												// 			'선택':'선택'
												// }}
												onChange = {(value)=>onChangeCntrReturnVal(value.value,'cntr_carton_code')}
												options={pack}
												getOptionLabel = {options=>options.label}
												styles={{
													control: provided => ({...provided,border:!cntr.cntr_carton_code?'1px solid red':'',minHeight:!size?'40px':'',height:!size?'40px':''}),
													indicatorsContainer: provided => ({...provided,height:!size?'40px':''})
												}}
											/>
											<InputValid 
												hidden
												name="cntr_carton_code"
												id="cntr_carton_code"
												maxLength="2"
												value={cntr.cntr_carton_code?cntr.cntr_carton_code:''}
												validtype="eng"
												required={true} 
												readOnly
												feedid="container"
											/>
										</Col>
										<Col className="col-4 pl-1">
											<InputValid 
												type="text"
												name="cntr_carton_qty"
												id="cntr_carton_qty"
												maxLength="8"
												value={cntr.cntr_carton_qty?cntr.cntr_carton_qty:''}
												onChange={(e)=>onHandleReturnVal(e, 'cntr_carton_qty')}
												onBlur={onPropsReturn}
												validtype="number"
												required={true} 
												feedid="container"
											/>
										</Col>
									</Row>
								</FormGroup>
							</Col>
						</Row>
						{vgmOpen?
						< >
							<Row>
								<Col>
									<Badge className="mr-1" color="default" pill>VGM INFO</Badge>
								</Col>
							</Row>
							<Row>
								<Col xl="3">
									<FormGroup className="mb-1">
										<Label className="mb-0">BkgNo</Label>
										<Select
											className="customSelect"
											name="cntr_res_bkg_no"
											id="cntr_res_bkg_no"
											// value={{value:cntr.cntr_res_bkg_no
											// 	?cntr.cntr_res_bkg_no
											// 	:'',
											// label:cntrData.cntr_res_bkg_no?
											// 		(bkglist.findIndex(x=>x.value===cntrData.cntr_res_bkg_no)>=0)?
											// 		bkglist[bkglist.findIndex(x=>x.value===cntrData.cntr_res_bkg_no)].label:
											// 		'선택':'선택'
											// }}
											value={cntr.cntr_res_bkg_no&&bkglist.length>0
												?bkglist.findIndex(x=>x.value===cntr.cntr_res_bkg_no)>=0
													&&bkglist[bkglist.findIndex(x=>x.value===cntr.cntr_res_bkg_no)]
												:{value:'',label:'선택'}
											}
											getOptionLabel = {options=>options.label}
											onChange = {(value)=>{
												onChangeBKGNO(value.value,'cntr_res_bkg_no')
											}}
											options={bkglist||[]}
											styles={{
												control: provided => ({...provided,border:!cntrData.cntr_res_bkg_no?'1px solid red':'',minHeight:!size?'40px':'',height:!size?'40px':''}),
												indicatorsContainer: provided => ({...provided,height:!size?'40px':''})
											}}
										/>
										<InputValid 
											hidden
											name="cntr_res_bkg_no"
											id="cntr_res_bkg_no"
											maxLength="35"
											value={cntrData.cntr_res_bkg_no?cntrData.cntr_res_bkg_no:''}
											validtype="eng"
											required={true} 
											readOnly
											feedid="container"
										/>
									</FormGroup>
								</Col>
								<Col xl="3" className="col-12">
									<FormGroup className="mb-1">
										<Label className="mb-0">VGM</Label>
										<InputValid 
											type="text"
											name="cntr_total_weight"
											id="cntr_total_weight"
											maxLength="18"
											value={cntr.cntr_total_weight?cntr.cntr_total_weight:''}
											onChange={(e)=>onHandleReturnVal(e, 'cntr_total_weight')}
											onBlur={onBlurProps}
											required={true} 
											inputgrouptext="KG"
											feedid="container"
											validtype="decimal"
											decimallength="3"
										/>
									</FormGroup>
								</Col>
								<Col xl="3">
									<FormGroup className="mb-1">
										<Label className="mb-0">Verifying Type</Label>
										<Select
											className="customSelect"
											name="cntr_verifying_type"
											value={cntr.cntr_verifying_type
												?options.vgmList.findIndex(x=>x.value===cntr.cntr_verifying_type)>=0
													&&options.vgmList[options.vgmList.findIndex(x=>x.value===cntr.cntr_verifying_type)]
												:{value:'',label:'선택'}
											}
											// value={{value:cntr.cntr_verifying_type ?cntr.cntr_verifying_type :'',
											// label:cntr.cntr_verifying_type&&(options.vgmList.findIndex(x=>x.value===cntr.cntr_verifying_type)>=0)?
											// 	options.vgmList[options.vgmList.findIndex(x=>x.value===cntr.cntr_verifying_type)].label:
											// 		'선택'
											// }}
											onChange = {(value)=>onChangeCntrReturnVal(value.value,'cntr_verifying_type')}
											options={options.vgmList}
											styles={{
												control: provided => ({...provided,border:cntr.cntr_total_weight?cntr.cntr_verifying_type?'':'1px solid red':'',minHeight:!size?'40px':'',height:!size?'40px':''}),
												indicatorsContainer: provided => ({...provided,height:!size?'40px':''})
											}}
										/>
										<InputValid
											hidden
											name="cntr_verifying_type"
											id="cntr_verifying_type"
											maxLength="3"
											value={cntr.cntr_verifying_type?cntr.cntr_verifying_type:''}
											validtype="eng"
											// required={cntr.cntr_total_weight?cntr.cntr_verifying_type?false:true:false}
											required={true}
											readOnly
											feedid="container"
										/>
									</FormGroup>
								</Col>
								
								<Col xl="3">
									<FormGroup className="mb-1">
										<Label className="mb-0">Responsible Person Signature</Label>
										<InputValid 
											type="text"
											name="cntr_auth_user_name"
											id="cntr_auth_user_name"
											maxLength="35"
											value={cntr.cntr_auth_user_name?cntr.cntr_auth_user_name:''}
											onChange={(e)=>onHandleReturnVal(e, 'cntr_auth_user_name')}
											onBlur={onBlurProps}
											validtype="eng"
											required={true}
											// required={cntr.cntr_total_weight?cntr.cntr_auth_user_name?false:true:false}
											feedid="container"
										/>
									</FormGroup>
								</Col>
							</Row>
							<Row>
								<Col xl="3">
									<FormGroup className="mb-1">
										<Label className="mb-0">Verification NO</Label>
										<InputValid 
											type="text"
											name="cntr_verifying_no"
											id="cntr_verifying_no"
											maxLength="70"
											value={cntr.cntr_verifying_no?cntr.cntr_verifying_no:''}
											onChange={(e)=>onHandleReturnVal(e, 'cntr_verifying_no')}
											onBlur={onBlurProps}
											validtype="eng"
											required={false}
											feedid="container"
										/>
									</FormGroup>
								</Col>
								<Col xl="4">
									<FormGroup className="mb-1">
										<Label className="mb-0">Verified Date</Label>
										<CustomDateTimePicker
											id="date_test"
											name="date_test"
											dateFormat="YYYY-MM-DD"
											timeFormat="HH:mm"
											value={cntr.cntr_verifying_date_name?cntr.cntr_verifying_date_name:''}
											onChange={(date)=>fncOnChangeDateInput(date)}
											inputProps={verifiedDateStyle}
											cntrVerifyingDateName={cntrVerifyingDateName}
											/>
										<InputValid 
											hidden
											name="date_test"
											id="date_test"
											maxLength="12"
											value={verifiedDateValid}
											validtype="yyyymmddhhmm2"
											required={false} 
											feedid="container"
											autoComplete="off"
											readOnly
										/>
									</FormGroup>
								</Col>
							</Row>
						</>:<></>}
						<Collapse isOpen={dgOpen} >
							<Row>
								<Col>
									<Badge className="mr-1" color="default" pill>DG INFO</Badge>
								</Col>
							</Row>
							<Row>
								<Col xl="6" lg="6" md="6">
									<FormGroup className="mb-1">
										<Label className="mb-0">UNDG</Label>
										<InputValid 
											type="text"
											name="undg"
											id="undg"
											maxLength="4"
											value={cntr.special_undg?cntr.special_undg:''}
											onChange={(e)=>onHandleReturnVal(e, 'special_undg')}
											onBlur={onBlurProps}
											validtype="text"
											feedid="container"
										/>
									</FormGroup>
								</Col>
								<Col xl="6"  lg="6" md="6">
									<FormGroup className="mb-1">
										<Label className="mb-0">IMDG</Label>
										<InputValid 
											type="text"
											name="imdg"
											id="imdg"
											maxLength="4"
											value={cntr.special_imdg?cntr.special_imdg:''}
											onChange={(e)=>onHandleReturnVal(e, 'special_imdg')}
											onBlur={onBlurProps}
											validtype="text"
											feedid="container"
										/>
									</FormGroup>
								</Col>
							</Row>
						</Collapse>
						<Collapse isOpen={oogOpen}>
							<Row>
								<Col>
									<Badge className="mr-1" color="default" pill>OOG INFO</Badge>
								</Col>
							</Row>
							<Row>
								<Col xl="4" lg="4" md="4">
									<FormGroup>
										<Label className="mb-0">Container Length</Label>
										<InputValid 
											type="text"
											name="cntr_length"
											id="cntr_length"
											maxLength="15"
											value={cntr.cntr_length?cntr.cntr_length:''}
											onChange={(e)=>onHandleReturnVal(e, 'cntr_length')}
											onBlur={onBlurProps}
											validtype="number"
											feedid="container"
										/>
									</FormGroup>
								</Col>
								<Col xl="4" lg="4" md="4">
									<FormGroup>
										<Label className="mb-0">Container Width</Label>
										<InputValid 
											type="text"
											name="cntr_width"
											id="cntr_width"
											maxLength="15"
											value={cntr.cntr_width?cntr.cntr_width:''}
											onChange={(e)=>onHandleReturnVal(e, 'cntr_width')}
											onBlur={onBlurProps}
											validtype="number"
											feedid="container"
										/>
									</FormGroup>
								</Col>
								<Col xl="4" lg="4" md="4">
									<FormGroup>
										<Label className="mb-0">Container height</Label>
										<InputValid 
											type="text"
											name="cntr_height"
											id="cntr_height"
											maxLength="15"
											value={cntr.cntr_height?cntr.cntr_height:''}
											onChange={(e)=>onHandleReturnVal(e, 'cntr_height')}
											onBlur={onBlurProps}
											validtype="number"
											feedid="container"
										/>
									</FormGroup>
								</Col>
							</Row>
						</Collapse>
					</Col>
				</Row>
			</CardBody>
		</Card>
    );
}
