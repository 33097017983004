/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import { Row,Col, CardBody, Collapse,
    Button,FormGroup,Label, Card, UncontrolledTooltip,Badge} from "reactstrap";
import axios from "axios";
import Select from "react-select";
// import ScheduleBookmark from "./ScheduleBookmark.js";
// Calendar
// import {Calendar,momentLocalizer} from 'react-big-calendar';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
import Moment from 'moment';
import InputValid from "components/CustomInput/InputValid.js";
// const selectStyle = {
//     control: base => ({
//       ...base,
//       fontFamily:'Nanum Gothic',
//       fontSize:'0.8rem',
//       color:'rgb(68, 68, 68)'
  
//     }),
//     menu: base => ({
//       ...base,
//       fontFamly: "Nanum Gothic",
//       fontSize:'0.8rem',
//       color:'rgb(68, 68, 68)'
//     })
// }
const ScheduleCard = forwardRef((props, scheduleFocus) => {
    const { optionCheck,} = props;
    const {
        toggle,
      } = props;
    // const localizer = momentLocalizer(Moment);
    const [coll, setColl] = useState(false);
    const [open, setOpen] = useState(false);
    // const [scheduleList, setScheduleList] = useState([]);
    const [booking, setBooking] = useState({});
    const [fdpPortCodeList, setFdpPortCodeList] = useState([]);
    
    // const [calendarData,setCalendarData] = useState([]);
    // const [lineVesselList ,setLineVesselList] = useState([]);
    // Open Type
    // const [polPortCodeList, setPolPortCodeList] = useState([]);
    // const [podPortCodeList, setPodPortCodeList] = useState([]);
    // const [porPortCodeList, setPorPortCodeList] = useState([]);
    // const [pldPortCodeList, setPldPortCodeList] = useState([]);
    // const [searchPol, setSearchPol] = useState("");
    // const [searchPod, setSearchPod] = useState("");
    // const [searchPor, setSearchPor] = useState("");
    // const [searchPld, setSearchPld] = useState("");
    const [searchFdp, setSearchFdp] = useState("");
    // const [startPortNation, setStartPortNation] = useState({label:'한국',value:'KR'});
    // const [endPortNation, setEndPortNation] = useState({label:'한국',value:'KR'});
    // const [startPortList, setStartPortList] = useState([]);
    // const [endPortList, setEndPortList] = useState([]);
    // const [NationList, setNationList] = useState([]);
    // const [startPort,setStartPort] = useState("");
    // const [endPort,setEndPort] = useState("");
    // const [startPortTemp,setStartPortTemp] = useState(null);
    // const [endPortTemp,setEndPortTemp] = useState(null);

    // useEffect(() => {
    //     getNationList();
    //     axios.post('/api/getPortCodeNation',{nationCode:'KR'}).then(res=> {
    //         if(res.data.length > 0) {
    //             setStartPortList(res.data);
    //         }else {
    //             setStartPortList([]);
    //         }
    //     })
    //     axios.post('/api/getPortCodeNation',{}).then(res=> {
    //         if(res.data.length > 0) {
    //             setEndPortList(res.data);
    //         }else {
    //             setEndPortList([]);
    //         }
    //     })
    // },[]);

    // useEffect(()=> {
    //     // setOpen(lineCode?false:true);
    //     if(lineCode) {
    //         selectLineCodeVesselName({line_code: lineCode});
    //     }
    //     axios.post('/api/selectLinePort',{params:{line_code:lineCode?lineCode:propsSiteLineCode,key:'out'}}).then(res=> {
    //         setStartPortList(res.data);
    //     })
    //     axios.post('/api/selectLinePort',{params:{line_code:lineCode?lineCode:propsSiteLineCode,key:'in'}}).then(res=> {
    //         setEndPortList(res.data);
    //     })
    // },[lineCode]);


    useEffect(()=>{
        setColl(props.openWindow);
    },[props.openWindow]);


    useEffect(()=>{
        if( props.booking.bkg_no ) {
            setBooking(props.booking);
        }
    },[props.booking]);

    // useEffect(()=>{
    //     setScheduleList(props.scheduleList);
    // },[props.scheduleList]);
    // useEffect(()=>{
    //     if( !props.booking.sch_pol ) {
    //         if( "Y" !== booking.selected_yn ) {
    //             selectPolCodePortList({
    //                 port_code: null
    //             });
    //         }
    //     } else {
    //         // if( booking.sch_pol !== props.booking.sch_pol ){
    //             selectPolCodePortList({
    //                 port_code: props.booking.sch_pol
    //             });
    //         // }
    //     }
    // },[props.booking.sch_pol]);
    // useEffect(()=>{
    //     if( !props.booking.sch_pod ) {
    //         if( "Y" !== booking.selected_yn ) {
    //             selectPodCodePortList({
    //                 port_code: null
    //             });
    //         }
    //     } else {
    //         // if( booking.sch_pod !== props.booking.sch_pod ){
    //             selectPodCodePortList({
    //                 port_code: props.booking.sch_pod
    //             });
    //         // }
    //     }
    // },[props.booking.sch_pod]);
    // useEffect(()=>{
    //     if( !props.booking.sch_por ){
    //         if( "Y" !== booking.selected_yn ) {
    //             selectPorCodePortList({
    //                 port_code: null
    //             });
    //         }
    //     } else {
    //         // if( booking.sch_por !== props.booking.sch_por ){
    //             //POR의 경우 전체 Port조회
    //             selectPorCodePortList({
    //                 port_code: props.booking.sch_por
    //             });
    //         // }
    //     }
    // },[props.booking.sch_por]);
    // useEffect(()=>{
    //     if( !props.booking.sch_pld ) {
    //         if( "Y" !== booking.selected_yn ) {
    //             selectPldCodePortList({
    //                 port_code: null
    //             });
    //         }
    //     } else {
    //         // if( booking.sch_pld !== props.booking.sch_pld ){
    //             //FDP의 경우 전체 Port조회
    //             selectPldCodePortList({
    //                 port_code: props.booking.sch_pld
    //             });
    //         // }
    //     }
    // },[props.booking.sch_pld]);
    useEffect(()=>{
        if( !props.booking.sch_fdp ) {
            if( "Y" !== booking.selected_yn ) {
                selectFdpCodePortList({
                    port_code: null
                });
            }
        } else {
            // if( booking.sch_fdp !== props.booking.sch_fdp ){
                //FDP의 경우 전체 Port조회
                selectFdpCodePortList({
                    port_code: props.booking.sch_fdp
                });
            // }
        }
    },[props.booking.sch_fdp]);
    // 부모로부터 파라미터 전달 받음
    
    // useEffect(()=>{
    //     if( searchPol ) {
    //         selectPolCodePortList({
    //             port_code: searchPol
    //         });
    //     }
    // },[searchPol])

    // useEffect(()=>{
    //     if( searchPod ) {
    //         selectPodCodePortList({
    //             port_code: searchPod
    //         });
    //     }
    // },[searchPod]);
    // useEffect(()=>{
    //     if( searchPor ) {
    //         selectPorCodePortList({
    //             port_code: searchPor
    //         });
    //     }
    // },[searchPor])

    // useEffect(()=>{
    //     if( searchPld ) {
    //         selectPldCodePortList({
    //             port_code: searchPld
    //         });
    //     }
    // },[searchPld])
    useEffect(()=>{
        if( searchFdp ) {
            selectFdpCodePortList({
                port_code: searchFdp
            });
        }
    },[searchFdp])


    useEffect(()=> {
		if(booking?.sch_line_code) optionCheck([{'typeVal':'sch_line_code','codeVal':booking.sch_line_code}]);
	},[booking.sch_line_code]);
    // useEffect(()=> {
    //     if(startPortNation) {
    //         axios.post('/api/getPortCodeNation',{nationCode:startPortNation.value}).then(res=> {
    //             if(res.data.length > 0) {
    //                 setStartPortList(res.data);
    //             }else {
    //                 setStartPortList([])
    //             }
    //         })
    //     }
    // },[startPortNation])
    // useEffect(()=> {
    //     if(endPortNation) {
    //         axios.post('/api/getPortCodeNation',{nationCode:endPortNation.value}).then(res=> {
    //             if(res.data.length > 0) {
    //                 setEndPortList(res.data);
    //             }else {
    //                 setEndPortList([]);
    //             }
    //         })
    //     }
    // },[endPortNation])
    // useEffect(()=> {
    //     if(startPortTemp) {
    //         setStartPort(startPortTemp.value);
    //     }else {
    //         setStartPort("");
    //     }
    // },[startPortTemp])
    // useEffect(()=> {
    //     if(endPortTemp) {
    //         setEndPort(endPortTemp.value);
    //     }else {
    //         setEndPort("");
    //     }
    // },[endPortTemp])
    //const toggle = (params) => {
    //    setOpen(!open);
    //}
  
    // const selectBookingScheduleBookmark = () => {
    //     props.selectBookingScheduleBookmark();
    // }

    // const getNationList = () => {
    
    //     axios.post('/api/getNationList',{}).then(res=> {
    //         if(res.data.length > 0) {
    //             setNationList(res.data);
    //         }else {
    //             setNationList([]);
    //         }
    //     })
    // }
    // const fncSelectSchedule = (e) => {
    //     if( e ) {
    //         if( 1 > e.value ) {
    //             if ( coll ) {
    //                 setColl(!coll)
    //             }
    //         // 그외 데이터인 경우
    //         } else {
    //             scheduleList.forEach((element, key)=>{
    //             if( e.value === element.schedule_bookmark_seq) {
    //                 axios.post("/shipper/getLineSchCal",{ sch_vessel_name:element.sch_vessel_name,startport:element.sch_pol,endport:element.sch_pod,eta:Moment(new Date()).format('YYYYMMDD'),week:'3 week',line_code:lineCode,limit_yn:'Y'}).then(res => {
    //                     setColl(true);
    //                     if(res.data && res.data.length > 0) {
    //                         // select로 새로운 document를 세팅한다
    //                         // 기존 Booking 정보
    //                         let sch = res.data[0];
    //                         setBooking({...booking
    //                             ,'schedule_bookmark_seq':element.schedule_bookmark_seq
    //                             ,'schedule_bookmark_name':element.schedule_bookmark_name
    //                             ,'sch_vessel_name':sch.sch_vessel_name?sch.sch_vessel_name:null
    //                             ,'sch_vessel_code':sch.sch_vessel_code?sch.sch_vessel_code:null
    //                             ,'sch_vessel_voyage':sch.sch_vessel_voyage?sch.sch_vessel_voyage:null
    //                             ,'sch_call_sign':sch.sch_call_sign?sch.sch_call_sign:null
    //                             ,'sch_pol':sch.sch_pol?sch.sch_pol:null
    //                             ,'sch_pol_name':sch.sch_pol_name?sch.sch_pol_name:null
    //                             ,'sch_pod':sch.sch_pod?sch.sch_pod:null
    //                             ,'sch_pod_name':sch.sch_pod_name
    //                             ,'sch_por':sch.sch_por?sch.sch_por:sch.sch_pol?sch.sch_pol:null
    //                             ,'sch_por_name':sch.sch_por_name?sch.sch_por_name:sch.sch_pol_name?sch.sch_pol_name:null
    //                             ,'sch_pld':sch.sch_pld?sch.sch_pld:sch.sch_pod?sch.sch_pod:null
    //                             ,'sch_pld_name':sch.sch_pld_name?sch.sch_pld_name:sch.sch_pod_name?sch.sch_pod_name:null
    //                             ,'sch_etd':sch.sch_etd
    //                             ,'sch_eta':sch.sch_eta
    //                             ,'sch_fdp':element.sch_fdp
    //                             ,'sch_fdp_name':element.sch_fdp_name
    //                             ,'selected_yn':'Y'
    //                             ,'vsl_type': sch.vsl_type
    //                         });
    //                         if( sch.sch_fdp ) setSearchFdp(sch.sch_fdp);
    //                         if( sch.sch_pol ) setSearchPol(sch.sch_pol);
    //                         if( sch.sch_pod ) setSearchPod(sch.sch_pod);
    //                         if( sch.sch_por ) {
    //                             setSearchPor(sch.sch_por)
    //                         }else if( sch.sch_pol ){
    //                             setSearchPor(sch.sch_pol)
    //                         }
    //                         if( sch.sch_pld ) {
    //                             setSearchPld(sch.sch_pld);
    //                         }else if( sch.sch_pod ) {
    //                             setSearchPld(sch.sch_pod);
    //                         }
    //                         props.fncBookingParent({...booking
    //                             ,'schedule_bookmark_seq':element.schedule_bookmark_seq
    //                             ,'schedule_bookmark_name':element.schedule_bookmark_name
    //                             ,'sch_vessel_name':sch.sch_vessel_name?sch.sch_vessel_name:null
    //                             ,'sch_vessel_code':sch.sch_vessel_code?sch.sch_vessel_code:null
    //                             ,'sch_vessel_voyage':sch.sch_vessel_voyage?sch.sch_vessel_voyage:null
    //                             ,'sch_call_sign':sch.sch_call_sign?sch.sch_call_sign:null
    //                             ,'sch_pol':sch.sch_pol?sch.sch_pol:null
    //                             ,'sch_pol_name':sch.sch_pol_name?sch.sch_pol_name:null
    //                             ,'sch_pod':sch.sch_pod?sch.sch_pod:null
    //                             ,'sch_pod_name':sch.sch_pod_name
    //                             ,'sch_por':sch.sch_por?sch.sch_por:sch.sch_pol?sch.sch_pol:null
    //                             ,'sch_por_name':sch.sch_por_name?sch.sch_por_name:sch.sch_pol_name?sch.sch_pol_name:null
    //                             ,'sch_pld':sch.sch_pld?sch.sch_pld:sch.sch_pod?sch.sch_pod:null
    //                             ,'sch_pld_name':sch.sch_pld_name?sch.sch_pld_name:sch.sch_pod_name?sch.sch_pod_name:null
    //                             ,'sch_etd':sch.sch_etd
    //                             ,'sch_eta':sch.sch_eta
    //                             ,'sch_fdp':element.sch_fdp
    //                             ,'sch_fdp_name':element.sch_fdp_name
    //                             ,'selected_yn':'Y'
    //                             ,'vsl_type': sch.vsl_type
    //                         });
    //                     } else {
    //                         // 기존 Booking 정보
    //                         setBooking({...booking
    //                             ,'schedule_bookmark_seq':element.schedule_bookmark_seq
    //                             ,'schedule_bookmark_name':element.schedule_bookmark_name
    //                             ,'sch_vessel_name':element.sch_vessel_name?element.sch_vessel_name:booking.sch_vessel_name
    //                             ,'sch_pol':element.sch_pol?element.sch_pol:booking.sch_pol
    //                             ,'sch_pol_name':element.sch_pol?element.sch_pol_name:booking.sch_pol_name
    //                             ,'sch_pod':element.sch_pod?element.sch_pod:booking.sch_pod
    //                             ,'sch_pod_name':element.sch_pod?element.sch_pod_name:booking.sch_pod_name
    //                             ,'sch_por':element.sch_por?element.sch_por:booking.sch_por
    //                             ,'sch_por_name':element.sch_por?element.sch_por_name:booking.sch_por_name
    //                             ,'sch_pld':element.sch_pld?element.sch_pld:booking.sch_pld
    //                             ,'sch_pld_name':element.sch_pld?element.sch_pld_name:booking.sch_pld_name
    //                             ,'sch_etd':element.sch_etd?element.sch_etd:booking.sch_etd
    //                             ,'sch_eta':element.sch_eta?element.sch_eta:booking.sch_eta
    //                             ,'sch_fdp':element.sch_fdp?element.sch_fdp:booking.sch_fdp
    //                             ,'sch_fdp_name':element.sch_fdp?element.sch_fdp_name:booking.sch_fdp_name
    //                             ,'selected_yn':'Y'
    //                         });
    //                         if( element.sch_fdp) setSearchFdp(element.sch_fdp);
    //                         if( element.sch_pod) setSearchPod(element.sch_pod);
    //                         if( element.sch_pol) setSearchPol(element.sch_pol);
    //                         if( element.sch_pld) setSearchPld(element.sch_pld);
    //                         if( element.sch_por) setSearchPor(element.sch_por);
    //                         props.fncBookingParent({...booking
    //                             ,'schedule_bookmark_seq':element.schedule_bookmark_seq
    //                             ,'schedule_bookmark_name':element.schedule_bookmark_name
    //                             ,'sch_vessel_name':element.sch_vessel_name?element.sch_vessel_name:booking.sch_vessel_name
    //                             ,'sch_pol':element.sch_pol?element.sch_pol:booking.sch_pol
    //                             ,'sch_pol_name':element.sch_pol?element.sch_pol_name:booking.sch_pol_name
    //                             ,'sch_pod':element.sch_pod?element.sch_pod:booking.sch_pod
    //                             ,'sch_pod_name':element.sch_pod?element.sch_pod_name:booking.sch_pod_name
    //                             ,'sch_por':element.sch_por?element.sch_por:booking.sch_por
    //                             ,'sch_por_name':element.sch_por?element.sch_por_name:booking.sch_por_name
    //                             ,'sch_pld':element.sch_pld?element.sch_pld:booking.sch_pld
    //                             ,'sch_pld_name':element.sch_pld?element.sch_pld_name:booking.sch_pld_name
    //                             ,'sch_etd':element.sch_etd?element.sch_etd:booking.sch_etd
    //                             ,'sch_eta':element.sch_eta?element.sch_eta:booking.sch_eta
    //                             ,'sch_fdp':element.sch_fdp?element.sch_fdp:booking.sch_fdp
    //                             ,'sch_fdp_name':element.sch_fdp?element.sch_fdp_name:booking.sch_fdp_name
    //                             ,'selected_yn':'Y'
    //                         });
    //                     }
    //                 });
                    
    //             }
    //             });
    //             if ( !coll ) {
    //                 setColl(!coll);
    //             }
    //         }
    //     } else {
    //         if( booking.schedule_bookmark_seq ) {
    //             setBooking({...booking
    //                 ,'schedule_bookmark_seq': null
    //                 ,'schedule_bookmark_name': null
    //                 ,'sch_vessel_name': null
    //                 ,'sch_vessel_code': null
    //                 ,'sch_vessel_voyage': null
    //                 ,'sch_call_sign': null
    //                 ,'sch_pol': null
    //                 ,'sch_pol_name': null
    //                 ,'sch_pod': null
    //                 ,'sch_pod_name': null
    //                 ,'sch_por': null
    //                 ,'sch_por_name': null
    //                 ,'sch_pld': null
    //                 ,'sch_pld_name': null
    //                 ,'sch_etd': null
    //                 ,'sch_eta': null
    //                 ,'sch_fdp': null
    //                 ,'sch_fdp_name': null
    //                 ,'selected_yn':'Y'
    //                 ,'vsl_type': null
    //             });
    //             props.fncBookingParent({...booking
    //                 ,'schedule_bookmark_seq': null
    //                 ,'schedule_bookmark_name': null
    //                 ,'sch_vessel_name': null
    //                 ,'sch_vessel_code': null
    //                 ,'sch_vessel_voyage': null
    //                 ,'sch_call_sign': null
    //                 ,'sch_pol': null
    //                 ,'sch_pol_name': null
    //                 ,'sch_pod': null
    //                 ,'sch_pod_name': null
    //                 ,'sch_por': null
    //                 ,'sch_por_name': null
    //                 ,'sch_pld': null
    //                 ,'sch_pld_name': null
    //                 ,'sch_etd': null
    //                 ,'sch_eta': null
    //                 ,'sch_fdp': null
    //                 ,'sch_fdp_name': null
    //                 ,'selected_yn':'Y'
    //                 ,'vsl_type': null
    //             });
    //         }
    //     }
    // }

    // 입력값 적용
    const fncOnChange = ( e, key ) => {
        e.preventDefault();
        setBooking({...booking, [key]:(e.target.value.toUpperCase())||null});
    }
    // // Calendar click event
    // const selectedEventCalendar = (event,e) => {
    //     if( "N" === event.booking_yn ) {
    //         props.onAlert("error","해당 스케줄은 선택 불가합니다.");
    //         return false;
    //     } else {
    //         console.log(event)
    //         let user_no = booking.user_no;
    //         let bkg_no = booking.bkg_no;
    //         let bkg_date = booking.bkg_date;
    //         let owner_no = booking.owner_no;
    //         setSearchPol(event.sch_pol);
    //         setSearchPod(event.sch_pod);
    //         setSearchPor(event.sch_por?event.sch_por:event.sch_pol);
    //         setSearchPld(event.sch_pld?event.sch_pld:event.sch_pod);
    //         setSearchFdp(event.sch_fdp?event.sch_fdp:event.sch_pod);
    //         setBooking({
    //             ...booking,
    //             user_no:user_no,
    //             bkg_no:bkg_no,
    //             bkg_date:bkg_date,
    //             owner_no:owner_no,
    //             line_code: event.line_code,
    //             sch_line_code:event.sch_line_code?event.sch_line_code:event.line_code?event.line_code:'',
    //             allDay:event.allDay,
    //             booking_yn:event.booking_yn,
    //             cargo_closing_date:event.cargo_closing_date,
    //             doc_closing_date:event.doc_closing_date,
    //             end:event.end,
    //             end_day:event.end_day,
    //             end_hour:event.end_hour,
    //             end_port:event.end_port,
    //             end_port_name:event.end_port_name,
    //             mrn:event.mrn,
    //             sch_call_sign:event.sch_call_sign,
    //             sch_pod:event.sch_pod,
    //             sch_pod_name:event.sch_pod_name,
    //             sch_pol:event.sch_pol,
    //             sch_pol_name:event.sch_pol_name,
    //             sch_por:event.sch_por?event.sch_por:event.sch_pol,
    //             sch_por_name:event.sch_por_name?event.sch_por_name:event.sch_pol_name,
    //             sch_pld:event.sch_pld?event.sch_pld:event.sch_pod,
    //             sch_pld_name:event.sch_pld_name?event.sch_pld_name:event.sch_pod_name,
    //             sch_fdp:event.sch_fdp?event.sch_fdp:event.sch_pod,
    //             sch_fdp_name:event.sch_fdp_name?event.sch_fdp_name:event.sch_pod_name,
    //             sch_vessel_code:event.sch_vessel_code,
    //             sch_vessel_name:event.sch_vessel_name,
    //             sch_vessel_voyage:event.sch_vessel_voyage,
    //             start:event.start,
    //             start_day:event.start_day,
    //             start_hour:event.start_hour,
    //             start_port:event.start_port,
    //             start_port_name:event.start_port_name,
    //             title:event.title,
    //             tt:event.tt,
    //             voyage_no:event.voyage_no,
    //             vsl_name:event.vsl_name,
    //             vsl_type:event.vsl_type,
    //             sch_etd:event.sch_etd,
    //             sch_eta:event.sch_eta});
    //         props.fncBookingParent({
    //             ...booking,
    //             user_no:user_no,
    //             bkg_no:bkg_no,
    //             bkg_date:bkg_date,
    //             owner_no:owner_no,
    //             line_code: event.line_code,
    //             sch_line_code:event.sch_line_code?event.sch_line_code:event.line_code?event.line_code:'',
    //             allDay:event.allDay,
    //             booking_yn:event.booking_yn,
    //             cargo_closing_date:event.cargo_closing_date,
    //             doc_closing_date:event.doc_closing_date,
    //             end:event.end,
    //             end_day:event.end_day,
    //             end_hour:event.end_hour,
    //             end_port:event.end_port,
    //             end_port_name:event.end_port_name,
    //             mrn:event.mrn,
    //             sch_call_sign:event.sch_call_sign,
    //             sch_pod:event.sch_pod,
    //             sch_pod_name:event.sch_pod_name,
    //             sch_pol:event.sch_pol,
    //             sch_pol_name:event.sch_pol_name,
    //             sch_por:event.sch_por?event.sch_por:event.sch_pol,
    //             sch_por_name:event.sch_por_name?event.sch_por_name:event.sch_pol_name,
    //             sch_pld:event.sch_pld?event.sch_pld:event.sch_pod,
    //             sch_pld_name:event.sch_pld_name?event.sch_pld_name:event.sch_pod_name,
    //             sch_fdp:event.sch_fdp?event.sch_fdp:event.sch_pod,
    //             sch_fdp_name:event.sch_fdp_name?event.sch_fdp_name:event.sch_pod_name,
    //             sch_vessel_code:event.sch_vessel_code,
    //             sch_vessel_name:event.sch_vessel_name,
    //             sch_vessel_voyage:event.sch_vessel_voyage,
    //             start:event.start,
    //             start_day:event.start_day,
    //             start_hour:event.start_hour,
    //             start_port:event.start_port,
    //             start_port_name:event.start_port_name,
    //             title:event.title,
    //             tt:event.tt,
    //             voyage_no:event.voyage_no,
    //             vsl_name:event.vsl_name,
    //             vsl_type:event.vsl_type,
    //             sch_eta:event.sch_eta,
    //             sch_etd:event.sch_etd,});
    //         // setColl(false);
    //         toggle();
    //     }
    // };

    const fncOnChangeSelect = ( e, key ) => {
        if( e ) {
            if( key ) {
                setBooking({...booking, [key]:e.value , [key+'_name']:e.port_name});
            }
        } else {
            setBooking({...booking, [key]:null , [key+'_name']:null});
        }
    }

    // const selectLineCodeVesselName = (params) => {
    //     axios.post("/shipper/selectLineCodeVesselName",{ params }).then(res=>{
    //         setLineVesselList(res.data);
    //     });
    // }

    // const selectPolCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setPolPortCodeList(res.data);
    //     });
    // }
    // const selectPodCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setPodPortCodeList(res.data);
    //     });
    // }
    // const selectPorCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setPorPortCodeList(res.data);
    //     });
    // }
    // const selectPldCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setPldPortCodeList(res.data);
    //     });
    // }
    const selectFdpCodePortList = (params)=>{
        axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
            setFdpPortCodeList(res.data);
        });
    }


    // const fncCacelModal =()=>{
    //     toggle();
    //     if( !coll ) {
    //         setColl( !coll );
    //     }
    // }


    // const onSubmit = () => { 
    //     axios.post("/shipper/getLineSchCal",{ startport:startPort ,endport:endPort,eta:Moment(new Date()).format('YYYYMMDD'),week:'5 week',line_code:lineCode?lineCode:propsSiteLineCode}).then(res => {
    //         setColl(true);
    //         if(res.data && res.data.length > 0) {
    //             console.log(res.data)
    //             setCalendarData(res.data);
    //         } else {
    //             setCalendarData([]);
    //             props.onAlert("error","조회된 (스케쥴) 정보가 없습니다.");
    //         }
    //     });
    // }
    // const CustomToolbar =(toolbar) => {
    //     const goToBack = () => {
    //         toolbar.date.setMonth(toolbar.date.getMonth() -1);
    //         toolbar.onNavigate('Back');
    //         axios.post("/shipper/getLineSchCal",{ startport:startPort,endport:endPort,eta:Moment(toolbar.date).format('YYYYMMDD'),week:'4 week', line_code:lineCode?lineCode:propsSiteLineCode}).then(res => {
    //             setColl(true);
    //             if(res.data && res.data.length > 0) {
    //                 setCalendarData(res.data);
    //             } else {
    //                 props.onAlert("error","조회된 (스케쥴) 정보가 없습니다.");
    //             }
    //         });	
    //     }
    //     const goToNext=()=> {
    //         toolbar.date.setMonth(toolbar.date.getMonth() +1);
    //         toolbar.onNavigate('Next');
    //         axios.post("/shipper/getLineSchCal",{ startport:startPort,endport:endPort,eta:Moment(toolbar.date).format('YYYYMMDD'),week:'4 week', line_code:lineCode?lineCode:propsSiteLineCode}).then(res => {
    //             setColl(true);
    //             if(res.data && res.data.length > 0) {
    //                 setCalendarData(res.data);
    //             } else {
    //                 props.onAlert("error","조회된 (스케쥴) 정보가 없습니다.");
    //             }
    //         });
    //     }
    //     const goToCurrent=()=> {
    //         const now = new Date();
           
    //         if(now.getMonth() !== toolbar.date.getMonth() ) {
    //             toolbar.date.setYear(now.getFullYear());
    //             toolbar.date.setMonth(now.getMonth());
    //             toolbar.onNavigate('ToDay');
    //             axios.post("/shipper/getLineSchCal",{ startport:startPort,endport:endPort,eta:Moment(toolbar.date).format('YYYYMMDD'),week:'4 week', line_code:lineCode?lineCode:propsSiteLineCode}).then(res => {
    //                 setColl(true);
    //                 if(res.data && res.data.length > 0) {
    //                     setCalendarData(res.data);
    //                 } else {
    //                     props.onAlert("error","조회된 (스케쥴) 정보가 없습니다.");
    //                 }
    //             });
    //         }        
    //     }
        
    //     const label =()=>{
    //         const date = Moment(toolbar.date);
    //         return (
    //           <h2><b>{date.format('YYYY')+" / "+date.format('MM')}</b></h2>	  
    //         );
    //     };
        
    //     return (
    //         <div style={{width:'87%',display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',marginBottom:'10px',fontSize:'16px'}}>	
    //             <div>
    //                 <Button style={{border:'solid 1px #DDD'}} color="default" outline size="sm" type="button" className="mr-1" onClick={goToCurrent}>Today</Button>
    //                 <Button style={{border:'solid 1px #DDD'}} color="default" outline size="sm" type="button" className="mr-1" onClick={goToBack}>Back</Button>
    //                 <Button style={{border:'solid 1px #DDD'}} color="default" outline size="sm" type="button" className="mr-1" onClick={goToNext}>Next</Button>
    //             </div>
    //             <label style={{textAlign:'center',padding:'0 10px',flexGrow:'1'}}>{label()}</label>
    //         </div>		  		
    //     );
        
    // }


    // const fncVesselChangeSechudle =( element )=> {
    //     if(lineCode) {
    //         axios.post("/shipper/getLineSchCal",{ sch_vessel_name:element.vessel_name,startport:booking.sch_pol,endport:booking.sch_pod,eta:Moment(new Date()).format('YYYYMMDD'),week:'3 week',line_code:lineCode,limit_yn:'Y'}).then(res => {
    //             setColl(true);
    //             if(res.data && res.data.length > 0) {
    //                 // select로 새로운 document를 세팅한다
    //                 // 기존 Booking 정보
    //                 let sch = res.data[0];
    //                 setBooking({...booking
    //                     ,'schedule_bookmark_seq':element.schedule_bookmark_seq
    //                     ,'schedule_bookmark_name':element.schedule_bookmark_name
    //                     ,'sch_vessel_name':sch.sch_vessel_name?sch.sch_vessel_name:null
    //                     ,'sch_vessel_code':sch.sch_vessel_code?sch.sch_vessel_code:null
    //                     ,'sch_vessel_voyage':sch.sch_vessel_voyage?sch.sch_vessel_voyage:null
    //                     ,'sch_call_sign':sch.sch_call_sign?sch.sch_call_sign:null
    //                     ,'sch_line_code':sch.sch_line_code?sch.sch_line_code:sch.line_code?sch.line_code:null
    //                     ,'sch_pol':sch.sch_pol?sch.sch_pol:null
    //                     ,'sch_pol_name':sch.sch_pol_name?sch.sch_pol_name:null
    //                     ,'sch_pod':sch.sch_pod?sch.sch_pod:null
    //                     ,'sch_pod_name':sch.sch_pod_name
    //                     ,'sch_por':sch.sch_por?sch.sch_por:sch.sch_pol?sch.sch_pol:element.sch_por
    //                     ,'sch_por_name':sch.sch_por_name?sch.sch_por_name:sch.sch_pol_name?sch.sch_pol_name:element.sch_por_name
    //                     ,'sch_pld':sch.sch_pld?sch.sch_pld:sch.sch_pod?sch.sch_pod:element.sch_pld
    //                     ,'sch_pld_name':sch.sch_pld_name?sch.sch_pld_name:sch.sch_pod_name?sch.sch_pod_name:element.sch_pld_name
    //                     ,'sch_etd':sch.sch_etd
    //                     ,'sch_eta':sch.sch_eta
    //                     ,'sch_fdp':element.sch_fdp
    //                     ,'sch_fdp_name':element.sch_fdp_name
    //                     ,'selected_yn':'Y'
    //                     ,'vsl_type': sch.vsl_type
    //                 });
    //                 props.fncBookingParent({...booking
    //                     ,'schedule_bookmark_seq':element.schedule_bookmark_seq
    //                     ,'schedule_bookmark_name':element.schedule_bookmark_name
    //                     ,'sch_vessel_name':sch.sch_vessel_name?sch.sch_vessel_name:null
    //                     ,'sch_vessel_code':sch.sch_vessel_code?sch.sch_vessel_code:null
    //                     ,'sch_vessel_voyage':sch.sch_vessel_voyage?sch.sch_vessel_voyage:null
    //                     ,'sch_call_sign':sch.sch_call_sign?sch.sch_call_sign:null
    //                     ,'sch_line_code':sch.sch_line_code?sch.sch_line_code:sch.line_code?sch.line_code:null
    //                     ,'sch_pol':sch.sch_pol?sch.sch_pol:null
    //                     ,'sch_pol_name':sch.sch_pol_name?sch.sch_pol_name:null
    //                     ,'sch_pod':sch.sch_pod?sch.sch_pod:null
    //                     ,'sch_pod_name':sch.sch_pod_name
    //                     ,'sch_por':sch.sch_por?sch.sch_por:sch.sch_pol?sch.sch_pol:element.sch_por
    //                     ,'sch_por_name':sch.sch_por_name?sch.sch_por_name:sch.sch_pol_name?sch.sch_pol_name:element.sch_por_name
    //                     ,'sch_pld':sch.sch_pld?sch.sch_pld:sch.sch_pod?sch.sch_pod:element.sch_pld
    //                     ,'sch_pld_name':sch.sch_pld_name?sch.sch_pld_name:sch.sch_pod_name?sch.sch_pod_name:element.sch_pld_name
    //                     ,'sch_etd':sch.sch_etd
    //                     ,'sch_eta':sch.sch_eta
    //                     ,'sch_fdp':sch.sch_fdp
    //                     ,'sch_fdp_name':element.sch_fdp_name
    //                     ,'selected_yn':'Y'
    //                     ,'vsl_type': sch.vsl_type
    //                 });
    //                 if( sch.sch_fdp ) setSearchFdp(sch.sch_fdp);
    //                 if( sch.sch_pol ) setSearchPol(sch.sch_pol);
    //                 if( sch.sch_pod ) setSearchPod(sch.sch_pod);
    //                 if( sch.sch_por ) {
    //                     setSearchPor(sch.sch_por)
    //                 }else if( sch.sch_pol ){
    //                     setSearchPor(sch.sch_pol)
    //                 }else {
    //                     setSearchPor(element.sch_por)
    //                 }
    //                 if( sch.sch_pld ) {
    //                     setSearchPld(sch.sch_pld);
    //                 }else if( sch.sch_pod ) {
    //                     setSearchPld(sch.sch_pod);
    //                 }else {
    //                     setSearchPld(element.sch_pod);
    //                 }
    //             } else {
    //                 // 기존 Booking 정보
    //                 setBooking({...booking
    //                     ,'sch_vessel_name':element.vessel_name
    //                     ,'vsl_type': element.vsl_type
    //                 });
    //                 props.fncBookingParent({...booking
    //                     ,'sch_vessel_name':element.vessel_name
    //                     ,'vsl_type': element.vsl_type
    //                 });
    //             }
    //         });
    //     }else {
    //         props.onAlert('danger','사이트 정보가 없습니다.');
    //     }
    // }

    // const fncOnKeyDownPol=(e)=>{
    //     const inputValue = e.replace(/\W/g,'');
    //     setSearchPol( inputValue )
    // }
    // const fncOnKeyDownPod=(e)=>{
    //     const inputValue = e.replace(/\W/g,'');
    //     setSearchPod( inputValue )
    // }
    // const fncOnKeyDownPor=(e)=>{
    //     const inputValue = e.replace(/\W/g,'');
    //     setSearchPor( inputValue )
    // }
    // const fncOnKeyDownPld=(e)=>{
    //     const inputValue = e.replace(/\W/g,'');
    //     setSearchPld( inputValue )
    // }
    const fncOnKeyDownFdp=(e)=>{
        const inputValue = e.replace(/\W/g,'');
        setSearchFdp( inputValue )
    }
    return (
        <>
            <Row id="Schedule">
                <Col xl="12" lg="12">
                    <Card style={{zIndex:'95'}}>
                        <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>  
                            <Row className="pb-2">
                                <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>SCHEDULE
                                    <Button className="pl-1" color="link" id="lineview" onClick={()=>toggle('S')}><i className="fa fa-pencil-square-o fa-2x"/></Button>
                                    <UncontrolledTooltip delay={0} target="lineview">Input</UncontrolledTooltip>
                                </Col>
                                {//(booking.sch_etd||booking.sch_vessel_name||booking.sch_pol||booking.sch_pod) &&
                                booking.sch_condition&&booking.sch_condition !=='Y'
                                ? (<Col style={{textAlign:'end'}}>
                                        <Badge color="danger" size="large" style={{marginTop: '20px', fontSize: '12px'}}>*&nbsp; 
                                        {booking.sch_condition&&booking.sch_condition =='N'
                                                ?'스케줄 정보가 올바르지 않습니다. 새로 선택하세요'
                                                :booking.sch_condition} 
                                        &nbsp;*</Badge>
                                    </Col>)
                                :''
                                }
                                {/* <Col>
                                    <Row>
                                        <Col className="col-10 pr-0">
                                            <Select
                                                className="react-select react-select-primary"
                                                name="schedule_bookmark_seq"
                                                value={{value:booking.schedule_bookmark_seq?booking.schedule_bookmark_seq:''
                                                ,label:booking.schedule_bookmark_name?booking.schedule_bookmark_name:'선택'}}
                                                onChange={(e)=>fncSelectSchedule(e?e:null)}
                                                options={scheduleList}
                                                placeholder="선택"
                                                ref={scheduleFocus}
                                                isClearable={booking.schedule_bookmark_seq?true:false}
                                            />
                                            </Col>
                                            <Col className="col-2 pl-auto pr-auto">
                                                <ScheduleBookmark
                                                    scheduleList={scheduleList}
                                                    selectBookingScheduleBookmark={selectBookingScheduleBookmark}
                                                    onAlert={props.onAlert}
                                                    lineVesselList={lineVesselList}
                                                    {...props}/>
                                            </Col>
                                        </Row>
                                    </Col> */}
                                </Row>
                                <Collapse isOpen={coll}>
                                <hr className="mt-0"/>
                                <Row>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Vessel/Voy</Label></Col>
                                                <Col>
                                                    <Row>
                                                        <Col className="col-7 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
                                                            {/* <Select
                                                                className="customSelect"
                                                                name="sch_vessel_name"
                                                                value={{
                                                                    value:booking.sch_vessel_name?booking.sch_vessel_name:'',
                                                                    label:booking.sch_vessel_name?booking.sch_vessel_name:'선택'
                                                                }}
                                                                //onChange={(value)=>fncVesselChangeSechudle(value)}
                                                                //options={lineVesselList}
                                                                //onBlur={(e)=>props.fncBookingParent(booking)}
                                                                styles={{
                                                                    control: provided => ({...provided,border:!booking.sch_vessel_name?'1px solid red':'' }),
                                                                    indicatorContainer: provided => ({...provided,color:''})
                                                                }}
                                                                
                                                            /> */}
                                                            <InputValid
                                                                // hidden
                                                                type="text"
                                                                name="sch_vessel_name"
                                                                id="sch_vessel_name"
                                                                maxLength="35"
                                                                value={booking.sch_vessel_name?booking.sch_vessel_name:''}
                                                                validtype="select"
                                                                required={true}
                                                                feedid="schedule"
                                                                readOnly
                                                            />
                                                        </Col>
                                                        <Col className="text-center pl-0 pr-0 pt-1">/</Col>
                                                        <Col className="col-4 pl-1">
                                                            <InputValid 
                                                                type="text"
                                                                name="sch_vessel_voyage"
                                                                id="sch_vessel_voyage"
                                                                maxLength="17"
                                                                value={booking.sch_vessel_voyage?booking.sch_vessel_voyage:''}
                                                                onChange={(e)=>fncOnChange(e, 'sch_vessel_voyage')}
                                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                                validtype="eng"
                                                                required={true} 
                                                                feedid="schedule"
                                                                readOnly
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>Call Sign</Label></Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="sch_call_sign"
                                                        id="sch_call_sign"
                                                        maxLength="9"
                                                        value={booking.sch_call_sign?booking.sch_call_sign:''}
                                                        onChange={(e)=>fncOnChange(e, 'sch_call_sign')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="eng"
                                                        required={false} 
                                                        feedid="schedule"
                                                        readOnly
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">POL</Label></Col>
                                                <Col sm="4" className="col-4 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
                                                    {/* <Select
                                                        cachedOptions
                                                        defaultOptions
                                                        className="customSelect"
                                                        name="sch_pol"
                                                        id="select_sch_pol"
                                                        value={{value:booking.sch_pol?booking.sch_pol:'',
                                                            label:booking.sch_pol?
                                                            (polPortCodeList.findIndex(x=>x.value===booking.sch_pol)>=0)?
                                                            polPortCodeList[polPortCodeList.findIndex(x=>x.value===booking.sch_pol)].port_code:
                                                                '선택':
                                                            '선택'
                                                        }}
                                                        //onChange={(value, action)=>fncOnChangeSelect(value, 'sch_pol')}
                                                        //onBlur={(e)=>props.fncBookingParent(booking)}
                                                        //onInputChange={fncOnKeyDownPol}
                                                        //options={polPortCodeList}
                                                        isClearable={booking.sch_pol?true:false}
                                                    /> */}
                                                    <InputValid
                                                        // hidden
                                                        type="text"
                                                        name="sch_pol"
                                                        id="sch_pol"
                                                        maxLength="5"
                                                        value={booking.sch_pol?booking.sch_pol:''}
                                                        validtype="select"
                                                        required={true}
                                                        feedid="schedule"
                                                        readOnly
                                                    />
                                                </Col>
                                                <Col sm="4" className="pl-1">
                                                    <InputValid 
                                                        type="text"
                                                        name="sch_pol_name"
                                                        id="sch_pol_name"
                                                        maxLength="35"
                                                        value={booking.sch_pol_name?booking.sch_pol_name:''}
                                                        onChange={(e)=>fncOnChange(e, 'sch_pol_name')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="eng"
                                                        required={false} 
                                                        feedid="schedule"
                                                        // readOnly
                                                    />
                                                </Col>
                                                <Col xl="2" className="col-2 pl-1">
                                                    <Label className="mt-2">{booking.sch_etd?Moment(booking.sch_etd.substr(0,8)).format('MM-DD'):''}</Label>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">POD</Label></Col>
                                                <Col xl="4" lg="4" md="4" sm="4" className="col-4 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
                                                    {/* <Select
                                                        cachedOptions
                                                        defaultOptions
                                                        className="customSelect"
                                                        name="sch_pod"
                                                        id="select_sch_pod"
                                                        value={{value:booking.sch_pod?booking.sch_pod:'',
                                                            label:booking.sch_pod?
                                                            (podPortCodeList.findIndex(x=>x.value===booking.sch_pod)>=0)?
                                                            podPortCodeList[podPortCodeList.findIndex(x=>x.value===booking.sch_pod)].port_code:
                                                                '선택':
                                                            '선택'
                                                        }}
                                                        //onChange={(value, action)=>fncOnChangeSelect(value, 'sch_pod')}
                                                        //onBlur={(e)=>props.fncBookingParent(booking)}
                                                        //onInputChange={fncOnKeyDownPod}
                                                        //options={podPortCodeList}
                                                        isClearable={booking.sch_pod?true:false}
                                                    /> */}
                                                    <InputValid
                                                        // hidden
                                                        type="text"
                                                        name="sch_pod"
                                                        id="sch_pod"
                                                        maxLength="5"
                                                        value={booking.sch_pod?booking.sch_pod:''}
                                                        validtype="select"
                                                        required={true}
                                                        feedid="schedule"
                                                        readOnly
                                                    />
                                                </Col>
                                                <Col sm="4" className="pl-1">
                                                    <InputValid 
                                                        type="text"
                                                        name="sch_pod_name"
                                                        id="sch_pod_name"
                                                        maxLength="35"
                                                        value={booking.sch_pod_name?booking.sch_pod_name:''}
                                                        onChange={(e)=>fncOnChange(e, 'sch_pod_name')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="eng"
                                                        required={false} 
                                                        feedid="schedule"
                                                        // readOnly
                                                    />
                                                </Col>
                                                <Col sm="2" className="col-2 pl-1">
                                                    <Label className="mt-2">{booking.sch_eta?Moment(booking.sch_eta.substr(0,8)).format('MM-DD'):''}</Label>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">POR</Label></Col>
                                                <Col xl="4" lg="4" md="4" sm="4" className="col-4 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
                                                    <InputValid
                                                        // hidden
                                                        type="text"
                                                        name="sch_por"
                                                        id="sch_por"
                                                        maxLength="5"
                                                        value={booking.sch_por ? booking.sch_por : ''}
                                                        validtype="select"
                                                        required={true}
                                                        feedid="schedule"
                                                        readOnly
                                                    />
                                                    {/* <Select
                                                        cachedOptions
                                                        defaultOptions
                                                        className="customSelect"
                                                        name="sch_por"
                                                        id="select_sch_por"
                                                        value={{value:booking.sch_por?booking.sch_por:'',
                                                            label:booking.sch_por?
                                                            (porPortCodeList.findIndex(x=>x.value===booking.sch_por)>=0)?
                                                            porPortCodeList[porPortCodeList.findIndex(x=>x.value===booking.sch_por)].port_code:
                                                                '선택':
                                                            '선택'
                                                        }}
                                                        //onChange={(value, action)=>fncOnChangeSelect(value, 'sch_por')}
                                                        //onBlur={(e)=>props.fncBookingParent(booking)}
                                                        //onInputChange={fncOnKeyDownPor}
                                                        //options={porPortCodeList}
                                                        isClearable={booking.sch_por?true:false}
                                                    /> */}
                                                </Col>
                                                <Col sm="6" className="col-8 pl-1">
                                                    <InputValid 
                                                        type="text"
                                                        name="sch_por_name"
                                                        id="sch_por_name"
                                                        maxLength="35"
                                                        value={booking.sch_por_name?booking.sch_por_name:''}
                                                        onChange={(e)=>fncOnChange(e, 'sch_por_name')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        required={false} 
                                                        validtype="eng"
                                                        feedid="schedule"
                                                        // readOnly
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">PLD</Label></Col>
                                                <Col xl="4" lg="4" md="4" sm="4" className="col-4 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
                                                    <InputValid
                                                        // hidden
                                                        type="text"
                                                        name="sch_por"
                                                        id="sch_por"
                                                        maxLength="5"
                                                        value={booking.sch_pld ? booking.sch_pld : ''}
                                                        validtype="select"
                                                        required={true}
                                                        feedid="schedule"
                                                        readOnly
                                                    />
                                                    {/* <Select
                                                        cachedOptions
                                                        defaultOptions
                                                        className="customSelect"
                                                        name="sch_pld"
                                                        id="select_sch_pld"
                                                        value={{value:booking.sch_pld?booking.sch_pld:'',
                                                            label:booking.sch_pld?
                                                            (pldPortCodeList.findIndex(x=>x.value===booking.sch_pld)>=0)?
                                                            pldPortCodeList[pldPortCodeList.findIndex(x=>x.value===booking.sch_pld)].port_code:
                                                                '선택':
                                                            '선택'
                                                        }}
                                                        //onChange={(value, action)=>fncOnChangeSelect(value, 'sch_pld')}
                                                        //onBlur={(e)=>props.fncBookingParent(booking)}
                                                        //onInputChange={fncOnKeyDownPld}
                                                        //options={pldPortCodeList}
                                                        isClearable={booking.sch_pld?true:false}
                                                    /> */}
                                                </Col>
                                                <Col sm="6" className="col-8 pl-1">
                                                    <InputValid 
                                                        type="text"
                                                        name="sch_pld_name"
                                                        id="sch_pld_name"
                                                        maxLength="35"
                                                        value={booking.sch_pld_name?booking.sch_pld_name:''}
                                                        onChange={(e)=>fncOnChange(e, 'sch_pld_name')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="eng"
                                                        required={false} 
                                                        feedid="schedule"
                                                        // readOnly
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">FDP</Label></Col>
                                                <Col xl="4" lg="4" md="4" sm="4" className="col-4 pr-1">
                                                    {/* <InputValid
                                                        // hidden
                                                        type="text"
                                                        name="sch_fdp"
                                                        id="sch_fdp"
                                                        maxLength="5"
                                                        value={booking.sch_fdp ? booking.sch_fdp : ''}
                                                        validtype="select"
                                                        required={true}
                                                        feedid="schedule"
                                                        readOnly
                                                    /> */}
                                                    <Select
                                                        cachedOptions
                                                        defaultOptions
                                                        className="customSelect"
                                                        name="sch_fdp"
                                                        id="select_sch_fdp"
                                                        value={{value:booking.sch_fdp?booking.sch_fdp:'',
                                                            label:booking.sch_fdp?
                                                            (fdpPortCodeList.findIndex(x=>x.value===booking.sch_fdp)>=0)?
                                                            fdpPortCodeList[fdpPortCodeList.findIndex(x=>x.value===booking.sch_fdp)].port_code:
                                                                '선택':
                                                            '선택'
                                                        }}
                                                        onChange={(value, action)=>fncOnChangeSelect(value, 'sch_fdp')}
                                                        onBlur={(e)=>props.fncBookingParent(booking)}
                                                        onInputChange={fncOnKeyDownFdp}
                                                        options={fdpPortCodeList}
                                                        isClearable={booking.sch_fdp?true:false}
                                                    /> 
                                                </Col>
                                                <Col xl="6" lg="6" md="6" sm="6" className="col-8 pl-1">
                                                    <InputValid 
                                                        type="text"
                                                        name="sch_fdp_name"
                                                        id="sch_fdp_name"
                                                        maxLength="35"
                                                        value={booking.sch_fdp_name?booking.sch_fdp_name:''}
                                                        onChange={(e)=>fncOnChange(e, 'sch_fdp_name')}
                                                        onBlur={(e) => {props.fncBookingParent(booking)}}
                                                        validtype="eng"
                                                        required={false} 
                                                        feedid="schedule"
                                                        // readOnly
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Collapse>
                            <div className="text-center" onClick={() => setColl(!coll)}>
                                <div>         
                                    <Button className="p-0" color="link" id="linemore" onClick={() => setColl(!coll)} style={{height:'21px'}}>
                                        {coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="linemore">{coll?'Close':'Open'}</UncontrolledTooltip>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {/* <Modal isOpen={open} toggle={toggle} className="pt-0" size="xl">
                <ModalHeader>Schedule</ModalHeader>
                <ModalBody>
                    <CardBody>
                        <Row>
                            <Col className="col-12 mb-2" style={{zIndex:'10'}}>
                                <div className="text-center">
                                    <Row>
                                        <Col xl="5" lg="5" md="6" sm="12" xs="12">
                                            <Row>
                                                <Col>
                                                출발지 선택
                                                </Col>
                                            </Row>
                                            <Row> */}
                                                {/* <Col xl="4" lg="4" md="4" sm="4" xs="4" style={{margin:0, padding:0}}>
                                                <Select
                                                    className="customSelect"
                                                    placeholder="국가선택"
                                                    options={NationList}
                                                    onChange={(value)=>setStartPortNation(value)}
                                                    value={startPortNation}
                                                    name="startNation"
                                                    styles={selectStyle}
                                                    isClearable={startPortNation?true:false}/>
                                                </Col> */}
                                                {/* <Col
                                                //  xl="8" lg="8" md="8" sm="8" xs="8"
                                                >
                                                    <Select
                                                        className="customSelect"
                                                        placeholder="출발지"
                                                        options={startPortList}
                                                        onChange={(value)=>setStartPortTemp(value)}
                                                        value={startPortTemp}
                                                        name="startPort"
                                                        styles={selectStyle}
                                                        isClearable={startPortTemp?true:false}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col> */}
                                        {/* <Col xl="5" lg="5" md="6" sm="12" xs="12">
                                            <Row>
                                                <Col>
                                                도착지 선택
                                                </Col>
                                            </Row>
                                            <Row> */}
                                                {/* <Col xl="4" lg="4" md="4" sm="4" xs="4" style={{margin:0, padding:0}}>
                                                    <Select
                                                        className="customSelect"
                                                        placeholder="국가선택"
                                                        options={NationList}
                                                        onChange={(value)=>setEndPortNation(value)}
                                                        value={endPortNation}
                                                        name="endNation"
                                                        styles={selectStyle}
                                                        isClearable={endPortNation?true:false}/>

                                                </Col> */}
                                                {/* <Col 
                                                // xl="8" lg="8" md="8" sm="8" xs="8"
                                                >
                                                    <Select
                                                        className="customSelect"
                                                        placeholder="도착지"
                                                        options={endPortList}
                                                        onChange={(value)=>setEndPortTemp(value)}
                                                        value={endPortTemp}
                                                        name="endPort"
                                                        styles={selectStyle}
                                                        isClearable={endPortTemp?true:false}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xl="2" lg="2" md="6" sm="12">
                                            <Row>
                                                <Col style={{height:'20px'}}>
                                            
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Button  color="primary" onClick={()=> onSubmit()} >검색</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col> */}
                            {/* <Col className="col-12 mb-2">
                                <Collapse isOpen={coll}>
                                    <Calendar
                                        selectable
                                        popup
                                        localizer={localizer}
                                        events={calendarData}
                                        startAccessor="start"
                                        endAccessor="end"
                                        style={{ height: 370 }}
                                        showAllEvents={true}
                                        defaultDate={new Date()}
                                        views={["month"]}
                                        onSelectEvent={(event,e) => selectedEventCalendar(event,e)}
                                        components={{toolbar:CustomToolbar}}
                                        // eventPropGetter={(event)=>{
                                        //     return {style:{fontSize:'1px',backgroundColor:event.sch_color?event.sch_color:'#000000'}}
                                        // }}
                                        eventPropGetter={(event)=>{
                                            console.log(scheduleFerryCargo);
                                            if((scheduleFerryCargo!==null)?scheduleFerryCargo==='C'?true:false:false){
                                                return {style:{fontSize:'1px',backgroundColor:event.sch_color?event.sch_color:'#000000'}}
                                            }else{
                                                if(event && event.vsl_type2 === '41') {
                                                    return {className:"bg-warning",style:{fontSize:'1px'}}
                                                } else {
                                                    return {className:"bg-info",style:{fontSize:'1px'}}
                                                }
                                            }   
                                        }}
                                    />
                                </Collapse>
                            </Col>
                        </Row>
                    </CardBody>
                </ModalBody> */}
                {/* <ModalFooter>
                    <Button color="secondary" onClick={fncCacelModal}>Cancel</Button>
                </ModalFooter>
            </Modal> */}
        </>
    );
})

export default ScheduleCard;