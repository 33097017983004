import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'axios';
import {parse, differenceInHours} from 'date-fns';
import Filesaver from 'file-saver';
import * as ExcelJS from 'exceljs';
import * as trkImages from 'assets/img/images.js'
import {toDateParse, isBeforeDate, handleLineUrl, insertEventLog} from './ExpTrackingHeader.js'
import styles from "assets/jss/material-dashboard-pro-react/components/snackbarContentStyle.js";
import { TableFooter, CircularProgress, Collapse, Box, Button, TextField, InputAdornment, FormControl,  Select,
  MenuItem,  OutlinedInput, Tooltip, styled, LinearProgress, Link, Input,} from '@material-ui/core';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Buttons from "muiComponents/CustomButtons/Button.js";
import Route from "./ExpTrackingRoute";
// import CalendarBox from "muiComponents/CustomInput/CustomCalendar.js";
import {infoColor, successColor, dangerColor, primaryColor} from "assets/jss/material-dashboard-pro-react.js";
//ICON
import IconButton from '@material-ui/core/IconButton'
import {NotificationsActive,NotificationsOff, LocationOn,AddCircle, RemoveCircle,  Add as AddIcon, AddBox, Cancel, NoteOutlined, Check, CheckCircleOutline, CheckCircle} from "@material-ui/icons/";
import PinOnIcon from 'assetsNew/image/ico/pin-on.png';
import PinOffIcon from 'assetsNew/image/ico/pin-off.png';

import * as validation from 'muiComponents/common/validation.js';
import * as excelComponet from './ExpTrackingExcelDownload.js';
// const addTypeList = [{label:'MBL' , value:'mbl_no'}, {label:'HBL' , value:'hbl_no'},];

function descendingComparator(a, b, orderBy) {
  let aa= a[orderBy] ||'-';
  let bb= b[orderBy] ||'-';
  
  if (bb < aa) {
    return -1;
  }
  if (bb > aa) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  // const stabilizedThis = array.map((el, index) => [el, index]);
  let groupA = [];//view_Top items
  let groupB = [];
  let groupNew = [];
  array.map((el, index) =>{
    if(el?.up_view_yn < 0 ){groupNew.push([el, index])}
    else if(el?.up_view_yn == 1 ){ groupA.push([el, index])}
    else groupB.push([el, index])
  });

  groupNew.sort((a, b) => a.up_view_yn > b.up_view_yn ? 1:-1);
  groupA.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  groupB.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const stabilizedThis=[...groupNew,...groupA, ...groupB];
  return stabilizedThis.map((el) => el[0]);
}

const headOption={numeric: false, disablePadding: true, sorting: true};
const headCells = [
  {...headOption, id: 'mbl_no', label: ['MBL No.']  , sorting: true },
  {...headOption, id: 'hbl_no', label: ['HBL No.']  , sorting: true },
  {...headOption, id: 'ref_content', label: ['Ref No.','(PO No. / 구분 등)']  , sorting: true },
  {...headOption, id: 'status_desc', label: ['STATUS','(Update Date)']  , sorting: true },
  {...headOption, id: 'route', label: ['ROUTE','(POL / TS PORT / POD)'], sorting: false },
  {...headOption, id: 'etd', label: ['POL','(ETD, ATD)'] , sorting: true },
  {...headOption, id: 'eta', label: ['POD','(ETA, ATA)'] , sorting: true },
  {...headOption, id: 'location', label: ['선박','위치'] , sorting: false},
  {...headOption, id: 'mailYn', label: ['메일','알림'] , sorting: false, role: true},
  {...headOption, id: 'up_view_yn', label: ['상단','고정']  , sorting: false, role: true},
  {...headOption, id: 'detailCntr', label: ['상세'] , sorting: false},
];
const TableSortLabelCustom = withStyles((theme)=>({
  icon:{
    margin:0,
    fontSize:'14px',
  },
}))(TableSortLabel)

const TooltipCustom = withStyles((theme)=>({
  tooltip:{
      backgroundColor:'rgb(231,230,230)', //,theme.palette.common.white,
      color : 'rgba(0,0,0,0.87)',
      boxShadow : theme.shadows[1],
      fontSize:14,
      maxWidth:'400px',
      pointerEvents:'auto'
  },
  tooltipPlacementTop:{
      margin:'20px 0 7px 30px',
  },
  arrow: { color:'rgb(231,230,230)',
          '&::before':{boxShadow : theme.shadows[1],}
  },
  popper:{zIndex:'0'}
}))(Tooltip)

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, roleReadOnly} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead style={{    height:'56px'}}>
      <TableRow>
        <TableCell padding="checkbox"
        style={{paddingTop:'0',paddingBottom:'0',borderBottomWidth:'3px',fontWeight:'bold',fontWeight:'500',color:'#717172',borderBottom:'2px solid #00b1b7'}} >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected == rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
            color='primary'
          />
        </TableCell>
        {headCells.map((headCell) => (!roleReadOnly||(roleReadOnly&&!headCell?.role))?
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{paddingTop:'0',paddingBottom:'0',borderBottomWidth:'3px',color:'#717172',borderBottom:'2px solid #00b1b7'}} 
          >
            <TableSortLabelCustom
              active={headCell.sorting&&orderBy === headCell.id}
              direction={headCell.sorting&&orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.sorting?createSortHandler(headCell.id):()=>{}}
              hideSortIcon={!headCell.sorting||true}
              className ={classes.headLabel}
            >
              <div style ={{fontSize: '12px'}}>
              {headCell.label.map((label,ind,arr)=>{
                return <span key={ind} style ={{display:'block', textAlign: 'center',fontWeight: '500'}}
                >{label}</span>
              })}
              {headCell.sorting&& orderBy === headCell.id ? (
                <span className={classes.visuallyHidden} >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
              </div>
            </TableSortLabelCustom>
          </TableCell>:<></>
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: '45px'
  },
  title: {
    flex: '1 1 100%',
    alignSelf :'end'
  },
  outlinedBtn:{borderColor:'#00acc1',color:'#00acc1', '&:focusVisible' :{outline:'none'}, marginLeft:'5px'},
  danger: {borderColor:dangerColor[0],color:dangerColor[0],},
  info: {borderColor:infoColor[0],color:infoColor[0],},
  success: {borderColor:successColor[0],color:successColor[0],},
  primary: {borderColor:primaryColor[0],color:primaryColor[0],},
  btnLabel:{fontWeight :500,display:'contents','&:focusVisible' :{outline:'none'}},
  selectInput:{paddingTop:'13px',paddingBottom:'13px'},
  inputAdornment: {
    marginRight: "18px",
    position: "relative"
  },
  notchedOutline:{ borderColor:'#ff9800'},//borderWidth:'2px',
  customTextField:{'&::placeholder':{fontWeight:'600'}}
}));


const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const classesTool = useSnackBarStyles();
  const inputRef = useRef('');
  const { addLoading , allData, showDeleteAlert, selected, setModalOpen, onUpdateState, handleExcelDownload, downloadLoading, handleKeyDown, showServiceLineList, roleReadOnly} = props;
  //등록
  // const [addType,setAddType] = useState(addTypeList[0].value);
  const [addItem,setAddItem] = useState('');

  //hbl ToolTip open 관리
  const [openHblTooltip, setOpenHblTooltip] = useState(false);
  
  useEffect(() => {
    fnResetValue();
	},[]);

  // useEffect(() => {
  //   if(addType==addTypeList[1].value){
  //     setOpenHblTooltip(true);
  //   }else{
  //     setOpenHblTooltip(false);
  //   }
	// },[addType]);

  const handleClose =(e)=>{
    if(openHblTooltip){
      setOpenHblTooltip(false);
    }
    return null 
  }
  
  const fnResetValue =()=>{
    // setAddType(addTypeList[0].value); 
    setAddItem('');
    if(inputRef.current.value)inputRef.current.value = '';
  }
  const handleInput =(e)=>{
    setAddItem(e.target.value.toUpperCase());
    // e.target.value=e.target.value.toUpperCase();
    // inputRef.current.value = (''+e.target.value).toUpperCase();
  }
  const handleAddButton =()=>{
    props.onAddUserBl(addItem, fnResetValue , (e)=>setOpenHblTooltip(e));
  }

  const toInputUpperCase = (e)=>{
    e.target.value=addItem;
    setAddItem(e.target.value.toUpperCase())
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        // [classes.highlight]: numSelected > 0,
      })}>     
      <Grid container justifyContent='space-between' alignItems='flex-end' style={{textAlign:'center',margin:'12px 0' }}>
        <Grid item xs={6} lg={6}>
          <Grid container alignItems='center' justifyContent='flex-start'   >
            {props.mode !=='single'?<>
                <TooltipCustom 
                      title={
                      <div  style={{ lineHeight:'1.2',color: 'black',fontSize: '0.8rem', fontWeight:'bold', }}>
                        <span>
                          HBL 번호 등록 불가 시, &nbsp;
                          <span style={{fontWeight:'600', color:'#06b3ba'}}>MBL 번호로 등록</span>
                          하시기 바랍니다.
                        </span>
                        <IconButton className={classesTool.iconButton} key="close" aria-label="Close" color="info"
                            onClick ={(e)=>handleClose(e) }>
                            <Cancel  style ={{width:'16px', height:'16px', marginLeft:'15px',  color:'#06b3ba'}}
                            />
                        </IconButton>
                      </div>}
                      placement='top-start' arrow 
                      open={openHblTooltip}
                      onClose={handleClose}
                      disableFocusListener
                      disableTouchListener
                      disableHoverListener
                      >
                <FormControl variant='outlined' hiddenLabel={true}  style = {{width:'53%', marginRight: '5px',}}>
                  <OutlinedInput
                    id='addItem'
                    // defaultValue={addItem}
                    inputRef = {inputRef}
                    onBlur={toInputUpperCase}
                    onChange={event => handleInput(event) }
                    onKeyDown={(e)=>handleKeyDown(e, ()=>handleAddButton())}
                    endAdornment={<InputAdornment position="end" className={classes.inputAdornment} style={{ marginRight:'4px'}}>
                        {addLoading?<div style={{width:'80px'}}><CircularProgress color="primary" size={15} /></div>
                        :<Buttons color="warning" endIcon={<AddIcon/>} size ='sm' style={{width:'80px',fontSize:'12px'}} 
                        onClick = {handleAddButton} disabled={props.loading||false}
                        >BL 등록</Buttons>}
                    </InputAdornment>}
                    aria-describedby='add-item'
                    inputProps={{'aria-label':'addItem' }}
                    labelWidth={0}
                    style ={{height:'42px',paddingRight:'0'}}
                    placeholder='등록할 MBL 또는 HBL No.를 입력하세요.'
                    classes={{notchedOutline:classes.notchedOutline, input:classes.customTextField}}
                />
                </FormControl>
                </TooltipCustom>
                <Buttons color="info" size ='sm' style={{width:'95px', height:'30px',fontSize:'12px',marginRight:'5px'}} 
                  onClick = {()=>setModalOpen(true)}
                  disabled={props.loading||false}
                  >BL 일괄 등록</Buttons>
                  <Buttons color="primary" size ='sm' style={{marginRight: '5px', height:'30px',fontSize:'12px', width:'100px'}} 
                  onClick = {showServiceLineList}>제공 선사 리스트</Buttons>
                  </>:<></>}
              </Grid>
          {/* </Grid> */}
        </Grid>
        <Grid item>
          <Button variant='outlined' size ='small' 
                            className={clsx(classes.outlinedBtn,classes.info)} classes={{label: classes.btnLabel}}
                            disabled={selected.length<1} onClick = {()=>onUpdateState(selected)} >선택 업데이트</Button>
          {!roleReadOnly?<>
          <Button variant='outlined' size ='small' 
                            className={clsx(classes.outlinedBtn,classes.danger)} classes={{label: classes.btnLabel}}
                            disabled={selected.length<1} onClick = {showDeleteAlert}>선택 삭제</Button></>:<></>}
          {allData.length>0
          ?downloadLoading
            ?<Button variant='outlined' size ='small' color="primary"  id='btnExport' disabled style={{height:'29px', width:'93.44px'}}
              className={clsx(classes.outlinedBtn,classes.success)} classes={{label: classes.btnLabel}}>
                <CircularProgress color="primary" size={15} /></Button>
            :<Button variant='outlined' size ='small' color="primary" id='btnExport' onClick = {()=>{handleExcelDownload(selected)}}
                    className={clsx(classes.outlinedBtn,classes.success)} classes={{label: classes.btnLabel}}
                    disabled={selected.length<1} 
                    >EXCEL 다운로드</Button>
          :<Button variant='outlined' size ='small' color="primary"  id='btnExport' disabled
                    className={clsx(classes.outlinedBtn,classes.success)} classes={{label: classes.btnLabel}}
                    >EXCEL 다운로드</Button>
          }
        </Grid>
			</Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom :'50px',
    marginTop:'35px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    borderTop:'2px solid #00b1b7', 
    borderBottom:'2px solid #00b1b7',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headLabel:{
    justifyContent: 'center ',
    width:'100%',
  },
  row:{
    fontSize:"12px",paddingLeft:'7px', paddingTop:'10px', paddingBottom:'10px', paddingRight:'7px',
  },
  // highlight:{backgroundColor:'#f2fefd'},
  detailTblHeader:{color:'#717172',textAlignLast:'center',paddingTop:'3px',paddingBottom:'3px',backgroundColor: "#f2fefd",borderRight:'1px solid silver',borderLeft:'1px solid silver'},
  detailTblCell:{textAlignLast:'center',padding:'3px 0',
  borderRight:'1px solid silver',borderLeft:'1px solid silver'},
  timeCell:{display:'flex', justifyContent:'center', alignItems:'center' , minHeight:'18px'},
  eTimeColor:{color:'#00b0f0'},
  aTimeColor:{color:'#ff6969'},
  time_dot:{width:'15px', marginRight:'2px'},
  updateCell:{minWidth:'115px'},
  portCell:{width:'15%'},
  barColorPrimary:{backgroundColor:'#00acc1'}, colorPrimary:{backgroundColor:'#00acc161'},
  container:{maxHeight:440}
}));
const useSnackBarStyles = makeStyles(styles);

export default function ExpTrackingTable(props) {
  const {rows,loading, showAlert,userData,alertMessage,roleReadOnly}= props;
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('data');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsData, setRowsData] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    setSelected([]);
    //초기화
    setOrder('asc')
    setOrderBy('data')

    setRowsData(rows);//원본
    // if(rows.length !== rowsData.length){
      setPage(0); handleChangePage(null,0)
    // }
    // rows.map(e=>{
    //   if (e?.update_yn>0){  console.log(e.mbl_no, e.update_yn); updateScrpStatus(e);}
    // })
	},[rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleRequestTop = (property, rnum, top) => {
    let list = rowsData.map((val , index )=>{
      if(val.rnum == rnum){return {...val, up_view_yn : top?1:2 }}
      return {...val}
    })
    setRowsData(list);
    setOrder('asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    selected.length==rowsPerPage
    ?setSelected([])
    :setSelected(stableSort(rowsData, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  };

  const handleSelectList = (e, data) => {
    if(data){
      let newSelected = [];
      if(selected.findIndex(x=>x.rnum==data.rnum)<0) {
				newSelected = [...selected,data];
			} else {
				newSelected = selected.filter((x)=>x.rnum !== data.rnum);		
			}
      setSelected(newSelected);
    }
  };
  
  const handleChangePage = (event, newPage) => {
    setSelected([]);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSelected([]);
  };

  const isSelected = (rnum) => selected.findIndex(x=>x.rnum==rnum)!== -1; //selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const deleteUserBl = async(setParams)=>{
    if(!userData){props.onAlert('error', validation.NOTLOGIN_MSG);  return; }
    await axios.post("/loc/deleteUserBl"
    ,{selected:setParams, 
      klnetId:userData.klnet_id, user_id: userData.user_no})
    .then(res =>{
      if(res.data==true){ 
        alertMessage('성공하였습니다.','success'); 
        props.onSearchInit();
      }else{
        alertMessage('실패하였습니다.','error'); 
      }}
    ).catch(err => {
      alertMessage('실패하였습니다.','error'); 
    });
  }

  const onUpdateState = async(e)=>{
    if(!userData){props.onAlert('error', validation.NOTLOGIN_MSG);  return; }
    if(e.length<1){return false }
    
    let now = new Date();
    // const list = e
    const list =e.filter(item=>{
      // console.log(item?.status_time, item?.update_yn)
      if(!item?.update_yn){
        if(!item?.status_time){
          return true
        }else{
          let date = parse(item?.status_time,'yyyyMMddHHmm',new Date);
          return differenceInHours(now,date )>0;
        }
      }
    })

    if(list.length<1){
      alertMessage('업데이트 이후, 1시간 후에 가능합니다.\n (중복 요청 방지)','error',null,{textAlign:'center'}); 
      setSelected([]);
      return false ;
    }

    const rnumList = new Set(list.map(item => item.rnum));
    
    await axios.post("/loc/insertScrpJobTbl",{selected:list, klnet_id :userData.klnet_id , user_id : userData.user_no})
    .then(async(res) =>{
      if(res.data==true){ 
        alertMessage('정보 업데이트 요청 완료\n (약 1 ~ 3분 소요)','success', null,{textAlign:'center'}); 
        props.setCall(true);
      }else{
        alertMessage(res.data||'다시 시도 바랍니다.','error'); 
      }}
    ).catch(err => {  console.log(err); alertMessage('다시 시도 바랍니다.','error'); 
    }).finally( setSelected([]));
  }

  const showDeleteAlert = ()=>{
    if(selected.length<1){return false }
    let setParams = selected.map(item=>({mbl_no: item.mbl_no, hbl_no: item.hbl_no, customs_line_code : item.customs_line_code}))
    showAlert(selected.length, ()=>deleteUserBl(setParams));
  }
  
  const handleExcelDownload = async(data) =>{
    try {
      if(!userData){props.onAlert('error', validation.NOTLOGIN_MSG); setDownloadLoading(false); return; }
      setDownloadLoading(true)
      const res = await axios.post("/loc/selectDownloadExpTrkData",{selected:data, klnet_id:userData.klnet_id})
      
      let sheetInfo_1 = excelComponet.excelSheet.initObj.getList2016Excel();
      const result = res.data||[];
      const workbook = new ExcelJS.Workbook();
      if (result.length <1)return false;
      let excelData = await Promise.all([excelComponet.make_excel(workbook, sheetInfo_1 , result)]);

      const fileData = await workbook.xlsx.writeBuffer();
      let blob = new Blob([fileData], {type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
      Filesaver.saveAs(blob,`Tracking`);
    }catch(err){
      console.log(err); alertMessage('다시 시도 바랍니다.','error'); 
    }finally{
      setDownloadLoading(false);
    }
  }

  return (
    <div className={classes.root}>
        <EnhancedTableToolbar numSelected={selected.length} allData = {rowsData} 
        selected={selected} showDeleteAlert={showDeleteAlert} 
        onUpdateState={onUpdateState} handleExcelDownload={handleExcelDownload}
        downloadLoading={downloadLoading}
        {...props}/>
        {/* <TableContainer className={classes.container}> */}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            stickyHeader={true}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowsPerPage}
              roleReadOnly={roleReadOnly}
            />
            <TableBody>
              {loading 
              ? (<TableRow style={{ height: '330px'}}><TableCell colSpan={17} style={{textAlignLast:'center'}}>
                <CircularProgress /></TableCell></TableRow>)
              :rowsData.length>0 //|| emptyRows > 0 
              ?stableSort(rowsData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.rnum);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let selectedItem=isItemSelected&&selected.find(x=>x.rnum==row.rnum)
                  let newRowYn = props.updatedData.findIndex(x=> x?.mbl_no==row?.mbl_no)>-1?true:false
                  return (
                    row?<RowTable key={index} 
                    propsRow={props.updating.findIndex(x=> x?.mbl_no==row?.mbl_no)>-1&&!newRowYn?{...row, update_yn:1}:row}
                    isItemSelected={isItemSelected} labelId={labelId} selectedItem={selectedItem} 
                    handleSelectList = {(e, data) =>{handleSelectList(e, data)}}
                    handleRequestTop={handleRequestTop}
                    newRowYn = {newRowYn}
                    {...props} />:<></>);
                })
              : (<TableRow style={{ height: '330px'}}>
                  {roleReadOnly
                    ?<TableCell colSpan="17" align="center" style={{fontSize:"20px", fontWeight:'500'}}>
                      본 페이지는 업체가 등록한 BL정보만 조회 가능합니다.
                    </TableCell>	
                    :<TableCell colSpan="17" align="center" style={{fontSize:"18px", fontWeight:'500'}}>
                      등록된 BL만 조회 가능합니다. <br/> 먼저 BL을 등록하세요.
                    </TableCell>	
                  }
                </TableRow>
                )}
            </TableBody>
            {(rowsData.length >= 10 ?
            <TableFooter>
                <TableRow >
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}                      
                    // component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    colSpan={17}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
          </TableFooter>
          : null )}
        </Table>
        {/* </TableContainer> */}
    </div>
  );
}

function RowTable(props) {
  const classes = useStyles();
  const {alertMessage, userData,
      propsRow, isItemSelected, labelId, handleSelectList, handleRequestTop, showModalEmail, showModalHbl, newRowYn, handleKeyDown, roleReadOnly} =props;
  const [locationData, setLocationData] = useState(false);
  const [onNotiEmail, setOnNotiEmail] = useState(propsRow?.cnt_noti_email=='Y'?true:false);
  const [onTopView, setOnTopView] = useState(propsRow?.up_view_yn==1?true:false);
  
  const [row, setRow] = useState(propsRow);
  
	const [openCollDetail, setOpenCollDetail] = useState(false);
  const [cntrLoading, setCntrLoading] = useState(false);
  const [trkCntrData, setTrkCntrData] = useState([]);
  const [openEditRefno, setOpenEditRefno] = useState(false);
  const [inputRefVal, setInputRefVal] = useState(row?.ref_content||'');

  useEffect(() => {
    setRow(propsRow);
    setOpenEditRefno(false)
	},[propsRow]);

  useEffect(() => {
    if(newRowYn){
      let list = props.updatedData.find(x=> x?.mbl_no==row?.mbl_no);
      if(list)setRow({...list, "rnum": row.rnum, update_yn:0, 'up_view_yn':row.up_view_yn,});
    }
	},[newRowYn]);

  useEffect(() => {
    setOnTopView(row?.up_view_yn==1?true:false)
	},[row?.up_view_yn]);

  useEffect(() => {
    if(row?.mbl_no !==trkCntrData[0]?.mbl_no){ setOpenCollDetail(false)}
	},[row?.mbl_no]);

  useEffect(() => {
    setInputRefVal(row?.ref_content||'')
	},[row?.ref_content]);

  useEffect(() => {
    setOnNotiEmail(row.cnt_noti_email>0?true:false)
	},[row?.cnt_noti_email]);

  const onHandleDetail = (blNo, scrp_seq) => {
    setOpenCollDetail(!openCollDetail);
    setCntrLoading(true);
    if(!openCollDetail)getCntrList(blNo, scrp_seq);
  };
  
  const getCntrList =(blNo, scrp_seq)=>{
    if(!userData){props.onAlert('error', validation.NOTLOGIN_MSG); setCntrLoading(false); return; }
    axios.post("/loc/selectExpTrkCntrList",{mbl_no : blNo,scrp_seq:scrp_seq}
    ).then(res =>{
      const result = res.data;
      setCntrLoading(false);
      if(result.length>0 ) setTrkCntrData(result) 
      else{alertMessage(validation.FAIL_CHECK_MSG,'error'); setTrkCntrData([])}
    }).catch(err => {
      setCntrLoading(false);
      alertMessage('오류가 발생했습니다.','error');
    }).finally(()=>{
      
    });
  }

  const onHandleTopView = (row)=>{
    // row?.hbl_no 있으면 //hbl테이블등록
    // 없으면 ${row.cnt_hbl} 건`)//mbl테이블등록
    if(userData) {
      axios.post("/loc/updateUserTopView",{klnet_id : userData.klnet_id,  param : row , topValue : !onTopView}
      ).then(res =>{
        if(row?.up_view_yn < 0) setOnTopView(!onTopView)
        else handleRequestTop('up_view_yn',row.rnum , !onTopView)
      }).catch(err => {
          alertMessage('설정에 실패하였습니다.','error');
      });
    } else {
        props.openLogin();
    }
  }

  const validLocationData =(data)=>{
    if(!data){ return false}
    // console.log(data)
    let {imo, sch_vessel_name}= data;

    if(imo||sch_vessel_name){
      return true;
    }else{// 하나라도 없으면 경로 mapX -> imo만 있으면 루트외의 vessel표시 지도만
      return false
    }
  }

  // const handleEditRefno = (e, row) =>{
  //   e.preventDefault();
  //   console.log('handleEditRefno',)
  //   // if(openEditRefno){
      
  //   // }
  //   // setOpenEditRefno(!openEditRefno)
  // }

  const saveRefno =async(val)=>{
    // console.log(val);
    if(val.length > 15 ){
      setInputRefVal(val.substr(0,15))
      alertMessage('15자리까지 입력 가능합니다.','error'); 
      return false
    }
    // row?.hbl_no 있으면 //hbl테이블등록
    // 없으면 ${row.cnt_hbl} 건`)//mbl테이블등록
    if(userData) {
      axios.post("/loc/updateUserRefno",{klnet_id : userData.klnet_id,  
        param : row , ref_content : val}
      ).then(res =>{
        // console.log(res.data)
        setRow({...row, ref_content:val})
        setOpenEditRefno(false)
      }).catch(err => {
          alertMessage('설정에 실패하였습니다.','error');
      });
    } else {
        props.openLogin();
    }
  }
  return( <>
  <TableRow
    hover
    role="checkbox"
    aria-checked={isItemSelected}
    tabIndex={-1}
    key={row?.rnum}
    selected={row?.up_view_yn <0}
    style ={{height:'30px'}}
  >
    <TableCell  component="th" id={labelId} scope="row"   padding="checkbox" onClick={(event) => {handleSelectList(event, row)}}>
      <Checkbox
        checked={isItemSelected}
        color='primary'
        inputProps={{ 'aria-labelledby': labelId }}
      />
    </TableCell>
    <TableCell align="center" className={classes.row} padding="none">{row?.mbl_no}</TableCell>
    <TableCell align="center" className={classes.row} padding="none">
      {/* {row?.hbl_no? row?.hbl_no: (row?.cnt_hbl==0?'-'
                        :<><span style ={{marginRight:'10px'}}>{row?.cnt_hbl} 건</span>
                            <AddBox color='primary' style ={{cursor:'pointer'}} onClick={()=>{showModalHbl(row)}}/></>)} */}
      { row?.hbl_no || (row?.cnt_hbl==0?'-'
                        :<><span style ={{marginRight:'10px'}}>{row?.cnt_hbl} 건</span>
                            <AddBox color='primary' style ={{cursor:'pointer'}} onClick={()=>{showModalHbl(row)}}/></>)}
    </TableCell>
    <TableCell align="center" className={classes.row} padding="none" style={{maxWidth:'116px', wordBreak:'break-word'}}
      onClick={(event) => {!roleReadOnly&&setOpenEditRefno(!openEditRefno)}}
    >
      {openEditRefno? 
      <FormControl>
        <Input inputProps={{'aria-label':'ref no'}}  autoFocus
        onChange={e => setInputRefVal(e.target.value)} 
        // defaultValue={row?.ref_content}
        value={inputRefVal}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton aria-label='toggle refno' style ={{padding:'3px'}} color='primary' 
              onClick={(e)=>{if(inputRefVal!==row?.ref_content)saveRefno(inputRefVal)}}>
              <CheckCircle />
            </IconButton>
          </InputAdornment>
        }
        onKeyDown={(e)=>{if(inputRefVal!==row?.ref_content)handleKeyDown(e, ()=>{saveRefno(inputRefVal)})}}
        error={inputRefVal&& inputRefVal.length > 15?true : false}
        />
        </FormControl>
      :row?.ref_content}
    </TableCell>
    <TableCell align="center" className={clsx(classes.row,classes.highlight,classes.updateCell)} padding="none">
      {row?.update_yn
        ? <><p style ={{fontWeight:'600', color:'#00b0f0'}}>{row?.up_view_yn<0?'NEW':'업데이트 중'}</p>
              <p style = {{width:'100%',display: 'flex',justifyContent: 'center', marginTop: '12px'}}>
                  <LinearProgress classes={{barColorPrimary:classes.barColorPrimary, colorPrimary:classes.colorPrimary}} style = {{width:'60%',}} color="primary"/>
              </p>
          </>
        :row?.status_time>0?<><p style ={{fontWeight:'500'}}>{row?.status_desc}</p><p>({toDateParse(row?.status_time)})</p></>
                          :<p style ={{fontWeight:'500'}}>등록 대기 중</p>
        }
    </TableCell>
    <TableCell align="center"  className={classes.highlight} style={{padding:"15px 12px 10px", width:'250px'}}>
    {!row?.scrp_seq|| !row?.ts_code ||row?.ts_code=='ER'
      ?<div> </div>
      :<Route tsCode = {row?.ts_code||''} rowData={row} toDateParse={toDateParse} setLocationData={(e)=>setLocationData(e)} isBeforeDate={isBeforeDate} {...props}/>
    }
    </TableCell>
    <TableCell align="center" className={clsx(classes.row,classes.highlight, classes.portCell)} padding="none">{row?.pol_name}
      {(row?.atd&&isBeforeDate(row?.atd))?<p className={clsx(classes.timeCell,classes.aTimeColor)}>
        {toDateParse(row?.atd)&&<><img src={trkImages.time_dot_A} alt={'atd'} className={classes.time_dot}/>{toDateParse(row?.atd)}</>}
        </p>:
        <p className={clsx(classes.timeCell,classes.eTimeColor)}>
        {toDateParse(row?.etd)&&<><img src={trkImages.time_dot_E} alt={'etd'} className={classes.time_dot}/>{toDateParse(row?.etd)}</>}
        </p>
      }
    </TableCell>
    <TableCell align="center" className={clsx(classes.row,classes.highlight, classes.portCell)} padding="none">{row?.pod_name}
      {(row?.ata&&isBeforeDate(row?.ata))?<p className={clsx(classes.timeCell,classes.aTimeColor)}>
        {toDateParse(row?.ata)&&<><img src={trkImages.time_dot_A} alt={'ata'} className={classes.time_dot}/>{toDateParse(row?.ata)}</>}
        </p>:
        <p className={clsx(classes.timeCell,classes.eTimeColor)}>
        {toDateParse(row?.eta)&&<><img src={trkImages.time_dot_E} alt={'eta'} className={classes.time_dot}/>{toDateParse(row?.eta)}</>}
        </p>
      }
    </TableCell>
    <TableCell align="center" padding="none">
      <IconButton onClick={e =>props.openPopup(locationData)} disabled={!validLocationData(locationData)} style = {{padding:'8px'}}>
        <LocationOn className={classes.iconSize}  color={validLocationData(locationData)?'primary':'disabled'}/>
      </IconButton>
    </TableCell>
    {!roleReadOnly&&<>
    <TableCell align="center" padding="none">
      <IconButton onClick = {()=>{showModalEmail(row, (e)=>setRow(e))}} style = {{padding:'8px'}}>
      {/* <IconButton onClick = {()=>{onHandleNotiEmail(row.mbl_no)}}> */}
        {onNotiEmail
        ?<NotificationsActive className={classes.iconSize}  color='primary' />
        :<NotificationsOff className={classes.iconSize}  color='disabled' />}
      </IconButton>
    </TableCell>
    <TableCell align="center" padding="none">
      <IconButton onClick = {()=>{onHandleTopView(row)}} style = {{padding:'8px'}}>
        <img src={onTopView?PinOnIcon:PinOffIcon} alt="PinOnIcon" width="18" height="18"></img>
      </IconButton>
    </TableCell></>
    }
    <TableCell align="center" padding="none"> 
      <IconButton  onClick = {()=>{onHandleDetail(row?.mbl_no, row?.scrp_seq)}} >
      {openCollDetail  
        ?<RemoveCircle className={classes.iconSize}  color='primary' />
        :<AddCircle className={classes.iconSize}  color='primary' />}
      </IconButton>
    </TableCell>
  </TableRow>
  <TableRow>
    <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor:'#f5f5f5' ,width: '100%' }} colSpan={12}>
      <Collapse in={openCollDetail} timeout="auto" unmountOnExit>
        {/* <Box margin={1} style={{marginLeft:'0',marginRight:'0'}} > */}
        <Box style={{margin:'20px 8px'}} >
            <Table className={classes.table} style={{borderTop:'2px solid silver', borderBottom:'2px solid silver'}}>
              <TableHead>
              <TableRow style={{borderTop:'1px solid',borderColor:'#dddddd'}}>
                <TableCell className={classes.detailTblHeader} rowSpan="2">CARRIER</TableCell>
                <TableCell className={classes.detailTblHeader} rowSpan="2">CNTR No.</TableCell>
                <TableCell className={classes.detailTblHeader} rowSpan="2">CNTR Type.</TableCell>
                <TableCell className={classes.detailTblHeader} colSpan="2">DEPARTURE </TableCell>
                <TableCell className={classes.detailTblHeader} colSpan="5">DESTINATION </TableCell>
                {!roleReadOnly&&<TableCell className={classes.detailTblHeader} rowSpan="2">담당자</TableCell>}
              </TableRow>
              <TableRow style={{borderTop:'1px solid',borderColor:'#dddddd'}}>
                  <TableCell className={classes.detailTblHeader} >POL GATE OUT <br/>(Empty) </TableCell>
                  <TableCell className={classes.detailTblHeader} >POL GATE IN <br/>(Full) </TableCell>
                  <TableCell className={classes.detailTblHeader} >F.DEST<br/>(Final Destination)</TableCell>
                  <TableCell className={classes.detailTblHeader} >INDLAND DEPARTURE</TableCell>
                  <TableCell className={classes.detailTblHeader} >INDLAND ARRIVAL</TableCell>
                  <TableCell className={classes.detailTblHeader} >POD GATE OUT<br/>(Full)</TableCell>
                  <TableCell className={classes.detailTblHeader} >POD GATE IN<br/>(Empty)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cntrLoading
                ? (<TableRow style={{ }}><TableCell colSpan={17} style={{textAlignLast:'center'}}>
                  <CircularProgress /></TableCell></TableRow>)
                :trkCntrData.length>0 
                ?trkCntrData.map((value, key)=>{
                  return (
                    <TableRow  key={key} style={{borderBottom:'1px solid',borderColor:'#dddddd'}}>
                      <TableCell className={classes.detailTblCell} >
                        <Link component='button' color='inherit' underline='always'
                        onClick={(e) =>{ handleLineUrl(e, value); insertEventLog(userData, 'TRK_LINE_URL', value?.customs_line_code||''); }}>
                        {value?.line_name||value?.customs_line_code}</Link></TableCell>
                      <TableCell className={classes.detailTblCell} >{value?.cntr_no||'-'}</TableCell>
                      <TableCell className={classes.detailTblCell} >{value?.sz_tp||'-'}</TableCell> 
                      <TableCell className={classes.detailTblCell} >{toDateParse(value?.e_gate_out_time)||'-'}</TableCell> 
                      <TableCell className={classes.detailTblCell} >{toDateParse(value?.f_gate_in_time)||'-'}</TableCell> 
                      <TableCell className={classes.detailTblCell} >{value?.fod_name||value?.fod||'-'}</TableCell> 
                      <TableCell className={classes.detailTblCell} >{(isBeforeDate(value?.departure_time)&&toDateParse(value?.departure_time))||'-'}</TableCell> 
                      <TableCell className={classes.detailTblCell} >{(isBeforeDate(value?.arrival_time)&&toDateParse(value?.arrival_time))||'-'}</TableCell> 
                      <TableCell className={classes.detailTblCell} >{toDateParse(value?.f_gate_out_time)||'-'}</TableCell> 
                      <TableCell className={classes.detailTblCell} >{toDateParse(value?.e_gate_in_time)||'-'}</TableCell> 
                      {!roleReadOnly&&key==0?<TableCell className={classes.detailTblCell} rowSpan={trkCntrData.length}>
                        {row?.insert_user_name}<br/>
                        <span style={{fontSize:'0.83rem'}}>({row?.insert_user_id||'-'})</span></TableCell>:''}
                    </TableRow>)
                  })
                :(<TableRow style={{}}> {/* (dense ? 33 : 53) * emptyRows }}> */}
                    <TableCell colSpan="10" align="center" className={classes.detailTblCell} style={{fontSize:"10px"}}>NO DATA</TableCell>
                    <TableCell className={classes.detailTblCell} >
                        {row?.insert_user_name}<br/>
                        <span style={{fontSize:'0.83rem'}}>({row?.insert_user_id||'-'})</span></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {/* <Table
              expend={true}
              expandedArrow={false}
              tableHeaderColor={"info"}
              tableHead={["CARRIER","CNTR No.", "CNTR Type.", 
              "GATE OUT", "DOC CLOSE", "CY CLOSE", "IMO No.", "CARRIER"]}
              // tableData={tsList}
            /> */}
        </Box>
      </Collapse>
    </TableCell>
  </TableRow>
</>)
}