/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import {Row, Col, CardBody, Collapse, UncontrolledTooltip, Button, Card, FormGroup, Label} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import DocumentBookmark from './DocumentBookmark.js'
import InputValid from "components/CustomInput/InputValid.js";
import * as options from 'muiComponents/common/options.js';
const DocumentCard = forwardRef((props, documentFocus) => {
    // 전체화면 css 적용을 위한 state
    // Collapse Flag
    const [coll, setColl] = useState(false);
    // Document
    const [documentList, setDocumentList] = useState([]);
    const [booking, setBooking] = useState({});
    const {userData, shipperCompanyList} = props;


    useEffect(()=>{
        setColl(props.openWindow);
    },[props.openWindow]);

    // useEffect(() => {
    //     if( booking.bkg_no ) {
    //         if( shipperCompanyList.length === 1 && !(booking.shp_code || booking.shp_name1|| booking.shp_user_email || booking.shp_user_name)) {
    //             axios.post("/com/getCompanyInfo",{ klnetId: shipperCompanyList[0].klnet_id,}).then(res => {
    //                 let company = res.data[0];
    //                 if( company ) {
    //                     if( "Y" === booking.user_shipper_yn ) {
    //                         setBooking({...booking
    //                             ,business_number:company.REG_NO
    //                             ,shp_code:shipperCompanyList[0].partner_code
    //                             ,shp_name1:company.CNAME_EN
    //                             ,shp_user_email: userData.user_email
    //                             ,shp_user_name: userData.user_name_en})
    //                         props.fncBookingParent({...booking
    //                             ,business_number:company.REG_NO
    //                             ,shp_code:shipperCompanyList[0].partner_code
    //                             ,shp_name1:company.CNAME_EN
    //                             ,shp_user_email: userData.user_email
    //                             ,shp_user_name: userData.user_name_en
    //                         ,});
    //                     } else {
    //                         setBooking({...booking,business_number:company.REG_NO,shp_code:shipperCompanyList[0].partner_code,shp_name1:company.CNAME_EN})
    //                         props.fncBookingParent({...booking, business_number:company.REG_NO, shp_code:shipperCompanyList[0].partner_code, shp_name1:company.CNAME_EN});
    //                     }
    //                 } else {
    //                     setBooking({...booking, business_number:null, shp_code:null, shp_name1:null})
    //                     props.fncBookingParent({...booking, business_number:null, shp_code:null, shp_name1:null});
    //                 }
    //             });
    //         } else if( shipperCompanyList.length > 1 && !(booking.shp_code || booking.shp_name1|| booking.shp_user_email|| booking.shp_user_name)) {
    //             if( userData ) {
    //                 setBooking({...booking,shp_user_email: userData.user_email,shp_user_name: userData.user_name_en})
    //                 props.fncBookingParent({...booking,shp_user_email: userData.user_email,shp_user_name: userData.user_name_en});
    //             }
    //         }
    //     }
    // },[shipperCompanyList, booking.bkg_no]);

    useEffect(()=>{
        if( props.booking.bkg_no ) {
            setBooking(props.booking);
        }
    },[props.booking]);

    // useEffect(()=>{
    //     setShipperList(props.shipperList);
    // },[props.shipperList]);
    useEffect(()=>{
        // console.log(props.documentList)
        setDocumentList(props.documentList);
    },[props.documentList]);

  
    const fncSelectDocument = (e) => {
        // 선택
        if( e ) {
            if( 1 > e.union_value ) {
                if ( coll ) {
                    setColl(!coll)
                }
            // 그외 데이터인 경우
            } else {
                setBooking({...booking
                    ,'document_bookmark_seq':e.value
                    ,'document_bookmark_name':e.document_bookmark_name||null
                    ,'docu_user_name':  e[Object.keys(e).find(i=> i.toString().endsWith('user_name'))]||null
                    ,'docu_user_tel': e[Object.keys(e).find(i=> i.toString().endsWith('user_tel'))]||null
                    ,'docu_user_fax': e[Object.keys(e).find(i=> i.toString().endsWith('user_fax'))]||null
                    ,'docu_user_email':e[Object.keys(e).find(i=> i.toString().endsWith('user_email'))]||null
                    ,'docu_tax_email': e[Object.keys(e).find(i=> i.toString().endsWith('tax_email'))]||null
                    ,'docu_user_phone':  e[Object.keys(e).find(i=> i.toString().endsWith('user_phone'))]||null
                    ,'selected_yn':'Y' 
                });
                props.fncBookingParent({...booking
                    ,'document_bookmark_seq':e.value
                    ,'document_bookmark_name':e.document_bookmark_name||null
                    ,'docu_user_name':  e[Object.keys(e).find(i=> i.toString().endsWith('user_name'))]||null
                    ,'docu_user_tel': e[Object.keys(e).find(i=> i.toString().endsWith('user_tel'))]||null
                    ,'docu_user_fax': e[Object.keys(e).find(i=> i.toString().endsWith('user_fax'))]||null
                    ,'docu_user_email':e[Object.keys(e).find(i=> i.toString().endsWith('user_email'))]||null
                    ,'docu_tax_email': e[Object.keys(e).find(i=> i.toString().endsWith('tax_email'))]||null
                    ,'docu_user_phone':  e[Object.keys(e).find(i=> i.toString().endsWith('user_phone'))]||null
                    // ,'document_bookmark_seq':element.document_bookmark_seq
                    // ,'document_bookmark_name':element.document_bookmark_name
                    // ,'docu_user_name':   e.docu_user_name||e[Object.keys(e).find(i=> i.toString().endsWith('user_name'))]||booking.docu_user_name
                    // ,'docu_user_tel':e.docu_user_tel||e[Object.keys(e).find(i=> i.toString().endsWith('user_tel'))]||booking.docu_user_tel
                    // ,'docu_user_fax': e.docu_user_fax||e[Object.keys(e).find(i=> i.toString().endsWith('user_fax'))]||booking.docu_user_fax
                    // ,'docu_user_email': e.docu_user_email||e[Object.keys(e).find(i=> i.toString().endsWith('user_email'))]||booking.docu_user_email
                    // ,'docu_tax_email': e.docu_tax_email||booking.docu_tax_email
                    // ,'docu_user_phone': e.docu_user_phone||e[Object.keys(e).find(i=> i.toString().endsWith('user_phone'))]||booking.docu_user_phone
                    ,'selected_yn':'Y' 
                });
                // documentList.forEach((element, key)=>{
                // if( e.value === element.document_bookmark_seq) {
                //     // select로 새로운 document를 세팅한다
                //     // 기존 Booking 정보
                //     setBooking({...booking
                //         // ,'document_bookmark_seq':element.document_bookmark_seq
                //         ,'document_bookmark_seq':null
                //         ,'document_bookmark_name':element.document_bookmark_name
                //         ,'docu_user_name': element.docu_user_name?element.docu_user_name:booking.docu_user_name
                //         ,'docu_user_tel': element.docu_user_tel?element.docu_user_tel:booking.docu_user_tel
                //         ,'docu_user_phone': element.docu_user_phone?element.docu_user_phone:booking.docu_user_phone
                //         ,'docu_user_fax': element.docu_user_fax?element.docu_user_fax:booking.docu_user_fax
                //         ,'docu_user_email': element.docu_user_email?element.docu_user_email:booking.docu_user_email
                //         ,'docu_tax_email': element.docu_tax_email?element.docu_tax_email:booking.docu_tax_email
                //         // ,'docu__address1':element.docu__address1?element.docu__address1:booking.docu__address1
                //         // ,'docu__address2':element.docu__address2?element.docu__address2:booking.docu__address2
                //         ,'selected_yn':'Y' 
                //     });
    
                //     props.fncBookingParent({...booking
                //         // ,'document_bookmark_seq':element.document_bookmark_seq
                //         ,'document_bookmark_seq':null
                //         ,'document_bookmark_name':element.document_bookmark_name
                //         ,'docu_user_name': element.docu_user_name?element.docu_user_name:booking.docu_user_name
                //         ,'docu_user_tel': element.docu_user_tel?element.docu_user_tel:booking.docu_user_tel
                //         ,'docu_user_phone': element.docu_user_phone?element.docu_user_phone:booking.docu_user_phone
                //         ,'docu_user_fax': element.docu_user_fax?element.docu_user_fax:booking.docu_user_fax
                //         ,'docu_user_email': element.docu_user_email?element.docu_user_email:booking.docu_user_email
                //         ,'docu_tax_email': element.docu_tax_email?element.docu_tax_email:booking.docu_tax_email
                //         // ,'docu__address1':element.docu__address1?element.docu__address1:booking.docu__address1
                //         // ,'docu__address2':element.docu__address2?element.docu__address2:booking.docu__address2
                //         ,'selected_yn':'Y' 
                //     });
                // }
                // });
                if ( !coll ) {
                    setColl(!coll);
                }
            }
        } else {
            if( booking.document_bookmark_seq) {
                setBooking({...booking
                    ,'document_bookmark_seq': null
                    ,'document_bookmark_name': null
                    ,'docu_user_name': null
                    ,'docu_user_tel': null
                    ,'docu_user_phone': null
                    ,'docu_user_fax': null
                    ,'docu_user_email': null
                    ,'docu_tax_email': null
                    // ,'docu__address1': null
                    // ,'docu__address2': null
                    ,'selected_yn':'Y' 
                });

                props.fncBookingParent({...booking
                    ,'document_bookmark_seq': null
                    ,'document_bookmark_name': null
                    ,'docu_user_name': null
                    ,'docu_user_tel': null
                    ,'docu_user_phone': null
                    ,'docu_user_fax': null
                    ,'docu_user_email': null
                    ,'docu_tax_email': null
                    // ,'docu__address1': null
                    // ,'docu__address2': null
                    ,'selected_yn':'Y' 
                });
            }
        }
    }


    // const fncOnChange = ( e, key ) => {
    //     e.preventDefault();
    //     setBooking({
    //         ...booking,[key]:e.target.value.toUpperCase()
    //     });
    // }
    const fncOnChange =(e)=>{
        let key = e.target.id;
        let value = (e.target.value.toUpperCase())||null;
        booking[key] = value;
        setBooking({...booking});
      }
    
    return (
        <>
            <Row id="Document">
                <Col xl="12" lg="12">
                    <Card style={{zIndex:'85'}}>
                        <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>  
                            <Row className="pb-2">
                                <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>DOCUMENT& ACCOUNTING
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="col-10 pr-0">
                                            <Select
                                                className="react-select react-select-primary"
                                                name="document_bookmark_name"
                                                value={{value:booking.document_bookmark_seq||''
                                                ,label: booking.document_bookmark_seq&&options.getLabel(props.documentUnionList,booking,'document_bookmark_seq')  ||'선택'}}                                              
                                                // booking.document_bookmark_seq?props.documentUnionList[booking.document_bookmark_seq].label:'선택'}}
                                                onChange={(e)=>fncSelectDocument(e?e:null)}
                                                options={props.documentUnionList}
                                                placeholder="선택"
                                                ref={documentFocus}
                                                isClearable={booking.document_bookmark_seq?true:false}
                                            />
                                        </Col>
                                        <Col className="col-2 pl-auto pr-auto">
                                            <DocumentBookmark
                                                onAlert={props.onAlert}
                                                documentList={documentList}
                                                selectBookingDocumentBookmark={props.selectBookingDocumentBookmark}
                                                {...props}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Collapse isOpen={coll}>
                            <hr className="mt-0"/>
                            <Row>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0">User</Label></Col>
                                            <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="docu_user_name"
                                                    id="docu_user_name"
                                                    maxLength="35"
                                                    value={booking.docu_user_name?booking.docu_user_name:''}
                                                    onChange={(e)=>fncOnChange(e, 'docu_user_name')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng" 
                                                    // required={true} 
                                                    feedid="document"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Tel</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="docu_user_tel"
                                                    id="docu_user_tel"
                                                    maxLength="25"
                                                    value={booking.docu_user_tel?booking.docu_user_tel:''}
                                                    onChange={(e)=>fncOnChange(e, 'docu_user_tel')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    // required={true} 
                                                    // required={("Y" === booking.user_forwarder_yn)?true:(booking.fwd_user_tel||booking.fwd_user_dept||booking.fwd_user_email||booking.fwd_user_fax)?true:false} 
                                                    feedid="document"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Fax</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="docu_user_fax"
                                                    id="docu_user_fax"
                                                    maxLength="25"
                                                    value={booking.docu_user_fax?booking.docu_user_fax:''}
                                                    onChange={(e)=>fncOnChange(e, 'docu_user_fax')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    required={false} 
                                                    feedid="document"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Phone</Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="docu_user_phone"
                                                id="docu_user_phone"
                                                maxLength="17"
                                                value={booking.docu_user_phone?booking.docu_user_phone:''}
                                                onChange={(e)=>fncOnChange(e, 'docu_user_phone')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                // required={true} 
                                                feedid="document"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0">E-mail</Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="docu_user_email"
                                                id="docu_user_email"
                                                maxLength="30"
                                                value={booking.docu_user_email?booking.docu_user_email:''}
                                                onChange={(e)=>fncOnChange(e, 'docu_user_email')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="email" 
                                                // required={true} 
                                                feedid="document"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Accounting Email</Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="docu_tax_email"
                                                id="docu_tax_email"
                                                maxLength="30"
                                                value={booking.docu_tax_email?booking.docu_tax_email:''}
                                                onChange={(e)=>fncOnChange(e, 'docu_tax_email')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="email" 
                                                // required={true} 
                                                feedid="document"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                {/* <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Address</Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="docu__address1"
                                                id="docu__address1"
                                                maxLength="35"
                                                value={booking.docu__address1?booking.docu__address1:''}
                                                onChange={(e)=>fncOnChange(e, 'docu__address1')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                required={false} 
                                                feedid="document"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="docu__address2"
                                                id="docu__address2"
                                                maxLength="35"
                                                value={booking.docu__address2?booking.docu__address2:''}
                                                onChange={(e)=>fncOnChange(e, 'docu__address2')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                required={false} 
                                                feedid="document"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col> */}
                                {/* <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_address3"
                                                id="shp_address3"
                                                maxLength="35"
                                                value={booking.shp_address3?booking.shp_address3:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_address3')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                required={false} 
                                                feedid="shipper"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_address4"
                                                id="shp_address4"
                                                maxLength="35"
                                                value={booking.shp_address4?booking.shp_address4:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_address4')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                required={false} 
                                                feedid="shipper"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_address5"
                                                id="shp_address5"
                                                maxLength="35"
                                                value={booking.shp_address5?booking.shp_address5:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_address5')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                required={false} 
                                                feedid="shipper"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col> */}
                            </Row>
                            </Collapse>
                            <div className="text-center" onClick={() => setColl(!coll)}>
                                <div>         
                                    <Button className="p-0" color="link" id="linemore" onClick={() => setColl(!coll)} style={{height:'21px'}}>
                                        {coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="linemore">{coll?'Close':'Open'}</UncontrolledTooltip>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
});

export default DocumentCard;