/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect,useState } from 'react';
import { Row,Col, FormGroup,Label} from "reactstrap";
import InputValid from "components/CustomInput/InputValid.js";
export default function Shipper(props){
	const {loadFormData, lineConfigDetail} = props;	
	const [shipperData,setShipperData] = useState({});

	useEffect(() => {
		setShipperData(loadFormData);
	},[loadFormData]);

	const onHandleReturnVal = (event,name) => {
		let list = {...shipperData, [name]:(event.target.value.toUpperCase())||null};
		setShipperData(list);
	}

	const onPropsReturn = ()=> {
		props.propsData(shipperData);
	}

	return (
		<>
			<Row>
				<Col xl="6" lg="6" md="12">
					<FormGroup className="mb-2">
						<Label className="mb-0">BookMark Name</Label>
						<InputValid 
							type="text"
							name="shipper_bookmark_name"
							id="shipper_bookmark_name"
							maxLength="35"
							value={shipperData.shipper_bookmark_name?shipperData.shipper_bookmark_name:''}
							onChange={(e)=>onHandleReturnVal(e, 'shipper_bookmark_name')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={true}
							feedid="shipper"
						/>
					</FormGroup>
				</Col>
	     	</Row>
			 {lineConfigDetail?.showShpCode=='true' &&
			<Row>
				<Col xl="6" lg="6" md="12">
					<FormGroup className="mb-2">
						<Label className="mb-0">Shipper Code</Label>
						<InputValid 
							type="text"
							name="shp_code"
							id="shp_code"
							maxLength="35"
							value={shipperData.shp_code?shipperData.shp_code:''}
							onChange={(e)=>onHandleReturnVal(e, 'shp_code')}
							onBlur={onPropsReturn}
							validtype="engNumber" 
							required={false}
							feedid="shipper"
						/>  
					</FormGroup>
				</Col>
			</Row>}
			<Row>
				<Col xl="6" lg="6" md="12">
					<FormGroup className="mb-2">
						<Label className="mb-0">Name1</Label>
						<InputValid 
							type="text"
							name="shp_name1"
							id="shp_name1"
							maxLength="35"
							value={shipperData.shp_name1?shipperData.shp_name1:''}
							onChange={(e)=>onHandleReturnVal(e, 'shp_name1')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="shipper"
						/>  
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="12">
					<FormGroup className="mb-2">
						<Label className="mb-0">Name2</Label>
						<InputValid 
							type="text"
							name="shp_name2"
							id="shp_name2"
							maxLength="35"
							value={shipperData.shp_name2?shipperData.shp_name2:''}
							onChange={(e)=>onHandleReturnVal(e, 'shp_name2')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="shipper"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup className="mb-2">
						<Label className="mb-0">Address1</Label>
						<InputValid 
							type="text"
							name="shp_address1"
							id="shp_address1"
							maxLength="35"
							value={shipperData.shp_address1?shipperData.shp_address1:''}
							onChange={(e)=>onHandleReturnVal(e, 'shp_address1')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="shipper"
						/>	                
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup className="mb-2">
						<Label className="mb-0">Address2</Label>
						<InputValid 
							type="text"
							name="shp_address2"
							id="shp_address2"
							maxLength="35"
							value={shipperData.shp_address2?shipperData.shp_address2:''}
							onChange={(e)=>onHandleReturnVal(e, 'shp_address2')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="shipper"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">Address3</Label>
						<InputValid 
							type="text"
							name="shp_address3"
							id="shp_address3"
							maxLength="35"
							value={shipperData.shp_address3?shipperData.shp_address3:''}
							onChange={(e)=>onHandleReturnVal(e, 'shp_address3')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup className="mb-2">
						<Label className="mb-0">Address4</Label>
						<InputValid 
							type="text"
							name="shp_address4"
							id="shp_address4"
							maxLength="35"
							value={shipperData.shp_address4?shipperData.shp_address4:''}
							onChange={(e)=>onHandleReturnVal(e, 'shp_address4')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="shipper"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup className="mb-2">
						<Label className="mb-0">Address5</Label>
						<InputValid 
							type="text"
							name="shp_address5"
							id="shp_address5"
							maxLength="35"
							value={shipperData.shp_address5?shipperData.shp_address5:''}
							onChange={(e)=>onHandleReturnVal(e, 'shp_address5')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="shipper"
						/>
					</FormGroup>
				</Col>
      		</Row>
    	</>
    );
}