    import React, { useState, useEffect, useRef } from 'react';
    import axios from 'axios';
    import cn from 'classnames';
    import { createTheme, makeStyles ,withStyles} from '@material-ui/core/styles';
    import { Link, useHistory, useLocation} from "react-router-dom";

    import GridContainer from "muiComponents/Grid/GridContainer.js";
    import GridItem from "muiComponents/Grid/GridItem.js";
    import Card from "muiComponents/Card/Card.js";
    import CardBody from "muiComponents/Card/CardBody.js";

    import {Modal, Backdrop,
        Checkbox, DialogContent, FormGroup, FormControlLabel,} from "@material-ui/core";
    import {Close, } from '@material-ui/icons';
    // css
    import Style from './SchCal.module.css'

    const LogisviewCheckbox = withStyles({
        root:{
        color:'#5fb0b5',
        '&$checked': {
        color: '#5fb0b5',
        },
        padding:'2px'
        }
    })((props)=><Checkbox color = 'default' {...props}/>);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            background:'#ecf2f2',
            '& >*': {
                width:200,
            }
        },
        modalroot:{
            display:'flex'
            ,justifyContent:'center'
            ,flex:'none'
        },
        modalform:{width:'570px', outline:'none'},
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        // stepIcon:{color:'yellow',
        //         '&$active':{color:'pupple'},
        //         '&$completed':{color:'red'},
            
        // },
        root: {
            width: '100%',
        },
        booking_title: {
            boxShadow: 'unset'
        },
        paper: {
            marginTop: theme.spacing(3),
            padding:theme.spacing(2),
            marginBottom: theme.spacing(3),
            [theme.breakpoints.up(600+theme.spacing(3)*2)]: {
                marginTop: theme.spacing(4),
                marginBottom: theme.spacing(2),
                padding: theme.spacing(3),
            }
        },
        paperInner: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
        gridContainer: {
        padding:'30px'
        },
        divider: {
            marginTop:'10px',
            marginBottom:'20px',
            backgroundColor:'#00acc1',
        },
        box: {
            width: '100%',
            marginTop: theme.spacing(3),
            padding:theme.spacing(2),
            marginBottom: theme.spacing(3),
        },
        avatar: {
        backgroundColor: '#00ACC1',
        [theme.breakpoints.between("xs", "sm")]: {
            width: theme.spacing(4),
            height: theme.spacing(4)
        },
        [theme.breakpoints.between("md", "lg", "xl")]: {
            width: theme.spacing(5),
            height: theme.spacing(5)
        }
        },
        headerCell: {
            textAlign: "left",
            backgroundColor: "#f2fefd",
            width:'150px',
            padding:'7px',
        // fontSize: '15px',
        // fontWeight:'bold'
        },
        dataCell: {
            textAlign: "left",
            padding:'7px',
        },
        grid: {
            padding: '0px 10px !important',
        },
        gridLabel: {
            padding: '0px 10px !important',
            textAlign:'left',
            // marginTop:'12px',
            display: 'flex',
            alignItems:'center',
            marginBottom: '20px',
            wordBreak: 'keep-all'
        },
        modalCloseButton:{
            float:'right',
            padding:'0',
            minWidth:'21px',
            height:'21px'
        },
        modalTitle:{
            padding:'15px 24px 0 24px',
            textAlign:'center'
        },
        modal:{
            display:'flex'
            ,alignItems:'center'
            ,justifyContent:'center'
        },
        tableLine:{
            height:'300px',overflow:'auto',borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7',marginBottom:'10px'
        },
        tablecell:{
            padding:'5px 16px',textAlign:'start'
        },
        tableHeadercell:{
            padding:'10px 16px',textAlign:'start',fontWeight: '500'
        },
        paperHeader:{
            fontSize:'16px',fontWeight:'500' ,color:'#222',
        // marginBottom:'16px'
        },
        uploadDiv: {
        borderStyle:'dotted',
        color:'#00b1b7'
        },
        uploadComponent: {
        margin:'0 auto',
        textAlign:'center',
        textAlignLast:'center',
        },
        putComponent:{
        margin:'0 auto',
        textAlign:'center',
        textAlignLast:'center',
        },
        putDiv: {
        borderStyle:'dotted',
        color:'#36B8CF'
        },
        wrapButton: {
            // marginTop:'10px'
            display : 'flex',
            height : '100%',
            alignItems : 'center'
        },
        iconSize: {
        width:50,
        height:50
        },
        arrowIconSize: {
        width:15,
        height:15
        },
        titleName: {
        fontWeight:'bold',
        fontSize:'17px'
        },
        progressStyle: {
        marginTop:'50px',
        textAlign:'center',
        textAlignLast:'center',
        }
    }));

    export default function SchCalAgreement(props) {
    const {userData, alertMessage,pageType } = props;
    const history = useHistory();
    const location = useLocation();
    const {state} = location;

    const classes = useStyles();
    const [agreeOpen,setAgreeOpen] = useState(false);
    const [hiddenPopChecked,setHiddenPopChecked] = useState(false);
    const [dishiddenPopChecked,setDishiddenPopChecked] = useState(false);
    const [rowsData, setRowsData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if(userData){
            //메인 첫화면 또는 메일 알림설정 버튼 눌렀을 시
            if(pageType=='main'||props?.openAgreeForm)getUserAgreement();
		}
    },[userData,props?.openAgreeForm,pageType]);

    useEffect(()=>{
        //메인에서 동의 버튼 눌러서 들어 온 경우
        if(state?.pageType=='main'&&state?.agreeYN=='Y'){
            const res = updateAgreeState();//동의 업데이트 후 메일 팝업 띄우기
        }
    }, []);

    const getUserAgreement = () =>{
        axios.post("/loc/getSchCalUserAgreement",
        {user_no : userData?.user_no, klnet_id : userData.klnet_id }
        ).then(res =>{
            const result = res.data;
            if(result.length>0){
                modalClose();
                if(pageType!=='main')props.onHandleOpenNotiForm&&props.onHandleOpenNotiForm(true);//동의상태이면 메일폼 오픈
            }else {
                if(pageType!=='main')setModalOpen(true);//미동의 상태면 동의폼 오픈
                else{
                    //메인일 경우 '오늘하루보지않기' 여부 체크 후 팝업 오픈
                    !handleStorage.getStorage('hiddenSchCalAgreement')
                    ?setModalOpen(true):setModalOpen(false)
                }
            }
        }).catch(err => {
            console.log(err)
            modalClose();
            alertMessage('정보를 가져오는데 실패하였습니다.','error');
        });
    }

    const modalClose = ()=>{
        if(hiddenPopChecked){
            handleStorage.setStorage('hiddenSchCalAgreement',1);
        }
        setModalOpen(false);
        props.setOpenAgreeForm&&props.setOpenAgreeForm(false);
    }

    const onSubmit = () =>{//동의 버튼 클릭
        if(!userData){
			return false;
		}
        if(pageType =='main'&&userData){//메인일 경우 페이지 이동
            history.push({
                pathname: `/svc/shipmentIssue`,
                state:{
                    agreeYN:'Y'
                    ,pageType:pageType
                }
            })
        }else{
            const res = updateAgreeState();//동의 업데이트 후 메일 팝업 띄우기
        }
    }

    const updateAgreeState =()=>{
        axios.post("/loc/updateAgreeState",
        {user_no : userData?.user_no, klnet_id : userData.klnet_id}
        ).then(res =>{
            const result = res.data;
                modalClose();
                if(result>0)props.onHandleOpenNotiForm&&props.onHandleOpenNotiForm(true,'NEW')
        }).catch(err => {
            alertMessage('정보 업데이트를 실패하였습니다.','error');
            modalClose();
        });
    }

    /* 스토리지 제어 함수 정의 */
    var handleStorage = {
         // 스토리지에 데이터 쓰기(이름, 만료일)
        setStorage: function (name, exp) {
        // 만료 시간 구하기(exp를 ms단위로 변경)
        var date = new Date();
        date = date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
    
        // 로컬 스토리지에 저장하기
            // (값을 따로 저장하지 않고 만료 시간을 저장)
        localStorage.setItem(name, date)
        },
        // 스토리지 읽어오기
        getStorage: function (name) {
        var now = new Date();
        now = now.setTime(now.getTime());
        // 현재 시각과 스토리지에 저장된 시각을 각각 비교하여
        // 시간이 남아 있으면 true, 아니면 false 리턴
        return parseInt(localStorage.getItem(name)) > now
        }
    };

    return (
    <Modal
        className={classes.modal}
        open={modalOpen}
        onClose={() => modalClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{timeout:500}}>
    <DialogContent className={cn(classes.modalroot , classes.modalform)} id="modal-dialog" >
    <GridContainer justifyContent="center">	
        <Card className={classes.cardSignup} style={{margin:'0',paddingTop:'0',paddingBottom:'0'}}>
            <CardBody style={{paddingTop:'15px',paddingBottom:'15px'}}>       
                <div style={{display: 'flex',	flexDirection: 'column' ,paddingBottom:'15px'}}>		
                    <div style={{display:'flex', cursor:'pointer',justifyContent: 'flex-end'}} >
                        <Close onClick={() =>  modalClose()} />
                    </div>
                </div>
                <div className={Style.popCont}>
                <div className={cn(Style.rtwrap,Style.li)} style ={{width:'100%'}}>
                    <dl>
                        <div className={Style.popHeader}>
                            <div className={Style.ptit}>
                                <strong>스케줄 변경 알림 서비스</strong>&nbsp;&nbsp;
                                <p>(무료)</p>
                            </div>
                        </div>
                       
                        <div className={Style.popContBtnWrap} >
                            {pageType=='main'&&<>
                            <Link to='/svc/shipmentIssue'>
                                <button type="button" className={Style.rtbutton}>
                                    <span>서비스 이동</span>
                                    {/* <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-arr.png`}/> */}
                                </button> 
                            </Link>
                            <dt>
                                <strong style={{color:'#005a5c',wordBreak:'keep-all',fontWeight:'bolder'}}>
                                LogisView2.0 &gt; 화물추적 &gt; 스케줄 변경 알림 </strong>
                            </dt></>}
                            <dd>
                                <ul style ={{marginTop: pageType!=='main'&&'1rem'}}>
                                    {pageType=='main'&& 
                                    <li><strong> 선적 건 <span style={{color:'#ed7d31'}}>스케줄(날짜) 변동 발생시</span>, <span style ={{color:'#841797'}}>이메일로 안내</span> 드립니다.</strong></li>
                                    }
                                    <li><strong  style={{fontWeight:'bolder'}}>[스케줄 변경 알림  e-Mail 알림] 무료 서비스 이용에 동의합니다.</strong></li>
                                </ul>
                            </dd>
                        </div>
                    </dl>
                    {pageType=='main'&&<div style={{display:'flex', justifyContent:'center'}}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={ <LogisviewCheckbox   checked={hiddenPopChecked} onChange={() => setHiddenPopChecked(!hiddenPopChecked)} 
                                            disableRipple />}
                                            label={<strong style={{fontSize:'14px'}}>오늘 하루 보지 않기</strong>}
                                        >
                                        </FormControlLabel>
                                    </FormGroup>
                                </div>}
                    <div className={Style.popBtnWrap} >
                        <button type="button" className={Style.rtbutton} onClick={()=>{onSubmit()}} >
                            <span>동의</span>
                            <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-arr.png`}/>
                        </button> 
                        <button type="button" className={Style.rtbutton} style = {{background:'#999'}} 
                            onClick={()=>modalClose()}>
                            <span>취소</span>
                        </button> 
                    </div>
                </div>
            </div>
            </CardBody>
        </Card>
    </GridContainer>
    </DialogContent>
    </Modal>
);}
