import {makeAutoObservable, observable, computed, action, autorun,configure, flow,flowResult} from "mobx"
import axios from 'axios'
import moment from 'moment';
import _ from 'lodash';
import {setCookie, getCookie, removeCookie} from 'muiComponents/common/cookie.js';
import {webLinkStore} from 'store/webLinkStore.js';

configure({enforceActions:'never'});
// import FaceIcon from '@material-ui/icons/Face';

let timer;

function countdown(e) {
    let expTime = e.exp;
    // console.log(expTime)
    let currentTime1 = Math.floor(Date.now() / 1000)
    let currentTime =  Math.floor( moment().valueOf()/1000);
    let currentTime2 =  Math.floor( new Date().getTime()/1000);
    let leftTime = expTime - currentTime ;
    // console.log(currentTime1,currentTime2 ,currentTime)
    // if (timerStore.autoRenew && leftTime < 1) {
    //     console.log('verifyUserCall', leftTime)
    //     userStore.verifyUser()
    //     clearInterval(timer);
    // } else

// console.log('currentTime>:',currentTime,', 1>>:',currentTime1, '2>>:',currentTime2)

    //header.js 로 이동
    //  if (leftTime < 1) {             
    //     userStore.logout();
    //     clearInterval(timer);
    //       return
    // }
    let hours = Math.floor(leftTime / 3600 % 24)
    let minutes = Math.floor(leftTime / 60 % 60)
    let seconds = Math.floor(leftTime % 60)
    if (expTime) {
        timerStore.hoursSpan = (hours < 10? hours < 1? '00': `0${hours}`: hours)
        timerStore.minutesSpan = (minutes < 10? minutes < 1? '00': `0${minutes}`: minutes)
        timerStore.secondsSpan = (seconds < 10? seconds < 1? '00': `0${seconds}`: seconds)
    }
    // timerStore.timer = ` ${timerStore.minutesSpan}:${timerStore.secondsSpan} `
    timerStore.timer = ` ${hours?`${timerStore.hoursSpan}:`:''}${timerStore.minutesSpan}:${timerStore.secondsSpan} `
    timerStore.timerKo = ` ${minutes>0?`${timerStore.minutesSpan}분`:''} ${timerStore.secondsSpan}초 `
    timerStore.leftTime =leftTime
}



class UserStore {
    accessToken = window.localStorage.getItem('LocalUser')?JSON.parse(window.localStorage.getItem('LocalUser')).accessToken : null;
    user =  window.localStorage.getItem('LocalUser')?JSON.parse(window.localStorage.getItem('LocalUser')) :null
    // isAuth = window.localStorage.getItem('booking.plism.com')?JSON.parse(window.localStorage.getItem('booking.plism.com')).isAuth :false
    loading = false;
    admin= window.localStorage.getItem('admin')?JSON.parse(window.localStorage.getItem('admin')) :false
    menu=[];
    adminMenu=[];
    currentMenu='';
    userMenuRole=['GUEST'];

    constructor() {
        makeAutoObservable(this, {
            accessToken: observable,
            user: observable,
            isAuth:observable,
            loading:observable,
            admin:observable,
            menu: observable,
            adminMenu: observable,
            currentMenu: observable,
            userMenuRole: observable,
            setting: action,
            logout: action,
            sessionIn:action,
            sessionOut:action,
            verifyUser:action,
            selectMenuUserRole:action,
            getUser:computed,
            getToken:computed,
        },{autoBind:true})
    }

    logout() {
        if(!userStore.admin){
            // userStore.loading=true;
            let userData = userStore.user|| window.localStorage.getItem('LocalUser');
            if(userData|| getCookie('ctfyRes')){
                axios
                .post("/auth/logout",{user:userData})
                .then(res => {
                    resetUser();
                    console.log(window.location.pathname)
                    let path =window.location.pathname;
                    if(userStore.loading){userStore.loading=false;}
                    if(path&&path!=='/')window.location.replace('/');
                })
                .catch(err => {
                    resetUser();
                }).finally(()=> userStore.isAuth=null);
            }
        }else{
            userStore.sessionOut()
        }
    }

    logoutMySelf() {
        if(!userStore.admin){
            // userStore.loading=true;
            let userData = userStore.user|| window.localStorage.getItem('LocalUser');
            if(userData){
            axios
                .post("/auth/logoutMySelf",{user:userData})
                .then(res => {
                    resetUser();
                    console.log(window.location.pathname)
                    let path =window.location.pathname;
                    if(userStore.loading){userStore.loading=false;}
                    if(path&&path!=='/')window.location.replace('/');
                })
                .catch(err => {
                    resetUser();
                }).finally(()=> userStore.isAuth=null);
            }
            // userStore.loading=false;
            }else{
                userStore.sessionOut()
            }
    }
   
    verifyUser =async()=> {
        const getMenu =(user)=>{
            // console.log('getMenu');
            axios.post("/api/selectWebMenuInfo",{user:user}).then(
                async res=>{
                if (res.data) {
                    
                    let temp = res.data;
                    let menuArray=Object.values(res.data);
                    let resultArray = [];

                    // 미리추가하자
                    if( temp.result) {
                        menuArray = temp.result;
                    }
    
                    menuArray.map((value) => {
                    if(value.LEVEL===2){
                        let menuNo=value.MENU_NO;
                        let views=[];
                        menuArray.map(async(value) => {
                            let upperMenuNo=value.UPPER_MENU_NO;
                            if(value.LEVEL===3&&menuNo===upperMenuNo){
                                views.push({
                                path: value.PATH,
                                name: value.MENU_NM_KR,
                                rtlName: value.MENU_NM_EN,
                                layout: value.LAYOUT,
                                linkParam: {prodNo :value.PROD_NO, devNo:value.DEV_NO},
                                mainShow:value.MAINSHOW==='Y'?true:false,
                                sideShow:value.SIDESHOW==='Y'?true:false,
                                id:value.ID,
                                menuGroup:value.MENU_GROUP,
                                icon:value.ICON,
                                menuNo:value.MENU_NO,
                                menuNmKr:value.MENU_NM_KR,
                                option1:value.OPTION1,
                                option2:value.OPTION2,
                                option3:value.OPTION3,
                                logout_use_flag:value.LOGOUT_USE_FLAG,
                                });
                            }
                        });

                        resultArray.push({
                        collapse: value.STATE?true:false,
                        name: value.MENU_NM_KR,
                        rtlName: value.MENU_NM_EN,
                        state: value.STATE,
                        mainShow:value.MAINSHOW==='Y'?true:false,
                        sideShow:value.SIDESHOW==='Y'?true:false,
                        menuGroup:value.MENU_GROUP,
                        path: value.PATH,
                        layout: value.LAYOUT,
                        views: views,
                        icon:value.ICON,
                        });
                    }
                });
                    userStore.adminMenu=resultArray.filter(data => data.menuGroup==='S');
                    
                    let logisvwMenu = resultArray.filter(data => data.menuGroup==='A');
                    userStore.menu= logisvwMenu;
                    
                    if(userStore.currentMenu){//user의 메뉴에 대한 권한 체크
                        let verifyMenuRole = logisvwMenu.reduce((acc, menu)=> acc.concat(menu?.views),[])
                                                    .find(item => item?.menuNo ==userStore.currentMenu)||null;
                        if(!verifyMenuRole){
                            // console.log('>>>logout')
                            window.location.replace('/');
                            return ;
                        }
                        userStore.selectMenuUserRole(userStore.currentMenu);
                    
                        if(userStore.user?.role =='WL'
                            &&String(webLinkStore.serviceId) !== String(userStore.currentMenu)){
                                // console.log('>>>', userStore.currentMenu, webLinkStore.serviceId)
                                goMain()
                        }
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        }

        try{
            userStore.loading=true;
            // console.log('verify IN>>>>  userStore.loading : ', userStore.loading,', userStore.user : ', userStore.user)
            const res  = await  axios.get(`/auth/verify?timestamp=${new Date().getTime()}`)            
            if (res.data) {
                const result = res.data.user;
                getMenu(res.data.user);
                if(result){
                    if(!userStore.accessToken||!_.isEqual(userStore.user,result)){//로그인
                        userStore.user = result;
                        userStore.accessToken= res.data.user.accessToken;
                        window.localStorage.setItem('LocalUser',JSON.stringify(result))
                        timerStore.getTimer(userStore.getUser);
                    }else if(userStore.accessToken&&_.isEqual(userStore.user,result)){//새로고침
                        timerStore.getTimer(userStore.getUser);
                    }else{
                        userStore.logout();
                    }
                }
            }else {
                userStore.logout()
                userStore.user=null;
                userStore.loading=false;                
            }
        }catch(e){
            console.log('error verify >>',e.response);
            try{
                getMenu(null);
                if (e.response&&e.response.status === 419&& userStore.user){
                    userStore.isAuth=419;
                    userStore.loading=false;
                }
                if (e.response&&e.response.status === 401&& userStore.user){
                    userStore.loading=true;
                    userStore.isAuth=401;
                }
                // console.log( e.response.data)
                if (e.response&&e.response.status === 400&& e.response.data?.result){
                    userStore.loading=false;
                    userStore.isAuth=e.response.data;// 관리자 이중인증
                    if(e.response.data.message){//새로운 ctfyRes 쿠키 추가
                        removeCookie('ctfyRes');
                        setCookie('ctfyRes',e.response.data.message, {maxAge:600, path:'/'})
                    } 
                }
            }catch(e){
                // console.log('error verify getMenu >>',e);
            };
        }finally{
            userStore.loading=false;
            // console.log('verify final>>');
            checkWebLinkSvc(userStore.user);
        }
    }
    sessionIn(){
        userStore.admin= userStore.user
        window.localStorage.setItem('admin',window.localStorage.getItem('LocalUser'))
        window.location.href = "/";
    }
    sessionOut(){
        // console.log('out')
        axios
            .post("/auth/logout",{sessionOut:'admin'})
            .then(res => {
                userStore.user = userStore.admin;
                userStore.admin=false;
                window.localStorage.removeItem('LocalUser');
                // window.localStorage.removeItem('autoRenew') 
                window.localStorage.removeItem('admin')
                clearInterval(timer);
                window.location.href = "/";
            })
            .catch(err => {
                // console.log(err);
            });
    }
    *sessionExtension(){
        // console.log('sessionExtension ')
        try{
            const res  = yield  axios.get(`/auth/sessionExtension?timestamp=${new Date().getTime()}`)
            if (res.data) {
                // console.log(res.data,this.accessToken)
                if(this.accessToken !== res.data.accessToken){
                    let newU = {...userStore.getUser, accessToken: res.data.accessToken , exp: res.data.exp};
                    window.localStorage.setItem('LocalUser',JSON.stringify(newU))
                    timerStore.getTimer(newU)
                }
                return true;
            }else {
                // console.log('sessionExtension fail>>')
                return false;
            }
        }catch(e){
            // console.log(e);
            return false;
        }
    }

    selectMenuUserRole(menuNo){
        if(userStore.user){
            console.log('selectMenuUserRole====>')
            axios.post("/com/selectMenuUserRole",{userId:userStore.user?.user_no, menuNo:menuNo} ).then(res => {
                console.log(res?.data)
                let result =res?.data;
                if(result.length>0){
                    userStore.userMenuRole=result.map(x=>x.AUTHOR_CODE)
                }else{
                    userStore.userMenuRole=[];
                    goMain()
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }
    
    get getUser() {
        return this.user
    }
    set setUser(user) {
        this.user = user;
        // console.log('>>setUser',user)
        // window.localStorage.setItem('user', JSON.stringify(this.user));
    }

    // set setToken(token) {
    //     this.accessToken = token;
    //     // window.localStorage.setItem('accessToken', this.accessToken);
    // }
    // get getToken() {
    //     return this.accessToken;
    // }

    // get getIsAuth(){
    //   return this.isAuth
    // }

    get getLoading(){
        return this.loading
    }
    set setLoading(e){
        this.loading = e ;
        return this.loading
    }

}
const checkWebLinkSvc = (user)=>{
    let path =window.location.pathname;
    console.log(user?.role, path, path.indexOf('/webLinkSvc'));
    if(path.indexOf('/webLinkSvc')>-1){
        if(user?.role !=='WL'){
            goMain()
        }
    }
    if(user?.role =='WL'){
        if(!webLinkStore.setting()){
            userStore.logout();
            return;
        };
    }
}
const goMain = ()=>{
    //권한없음
    let path =window.location.pathname;
    if(path&&path!=='/')window.location.replace('/');
}
const resetUser = ()=>{
    userStore.user = null;
    window.localStorage.removeItem('LocalUser');
    window.localStorage.removeItem('admin')
    clearInterval(timer);
    removeCookie('ctfyRes');
    removeCookie('ctfyUser');
    userStore.userMenuRole=['GUEST'];
}

class TimerStore {
    user = userStore.getUser
    hoursSpan= '--'
    minutesSpan= '--'
    secondsSpan= '--'
    // autoRenew= JSON.parse(window.localStorage.getItem('autoRenew')) || true
    timer= ' -- : -- '
    timerKo= ''
    leftTime= 3600
    // timer= ' 00:00:00 '

    constructor() {
        makeAutoObservable(this, {
            user: observable,
            hoursSpan: observable,
            minutesSpan: observable,
            secondsSpan: observable,
            // autoRenew: observable,
            timer:observable,
            leftTime:observable,
            getTimer:action,
            // getAutoRenew:computed,
            getleftTime:computed
        })
    }

    // get getAutoRenew() {
    //     return this.autoRenew;
    // }
    // set setAutoRenew(e) {
    //     //    console.log('setAutoLogin',e )
    //     window.localStorage.setItem('autoRenew', JSON.stringify(e))
    //     this.autoRenew = e
    // }
    get getleftTime() {
        return this.leftTime;
    }
    getTimer(e){
        // console.log('userStore Timer>>',e)
        clearInterval(timer)
        if (e) {
            timer = setInterval(action(countdown), 1000, e)
        } else {
            clearInterval(timer);
        }
    }
}

const userStore =  new UserStore();
const timerStore =  new TimerStore();

autorun(() => {
    console.log('verify User');
    userStore.verifyUser();

})

export {userStore, timerStore};
