import React,{ useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import CardBody from "muiComponents/Card/CardBody.js";
import {
	Collapse,
	Card,
	CardContent,
	TextField,
	TableFooter,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Grid,
	Divider,
	Container,
	Avatar,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Input
} from "@material-ui/core";

import Button from "muiComponents/CustomButtons/Button.js";

import {
	Chat as Chat,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	Search as SearchIcon
}from "@material-ui/icons";
import axios from 'axios';
import Filesaver from 'file-saver';
import {yellow, red} from '@material-ui/core/colors';
import { useHistory } from "react-router-dom";
// import testimonialsSection from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle";
// import AlertMessage from 'muiComponents/Alert/AlertMessage.js';
import Pagination from '@material-ui/lab/Pagination';

const ico_m_new = require("assets/img/ico_m_new.gif");

const useRowStyles = makeStyles((theme)=> ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	small: {
		width: theme.spacing(2),
		height: theme.spacing(2),
		backgroundColor: red['700']
	}
}));

function Row(props) {
	const history = useHistory();
	const classes = useRowStyles();
	const [row,setRow] = useState(props.row);
	const [open, setOpen] = useState(false);
	const [pwOpen, setPwOpen] = useState(false);
	const [password,setPassword]= useState('');
	const [lock, setLock] = useState(row.QNA_PASSWORD);
	const [isNew,setIsNew] = useState(false);
	const { userData, totalPlismBoardCount} = props;
	useEffect(()=>{
		setRow(props.row);
	},[props.row])
	useEffect(() => {
        if(row.NTT_ID===props.num) {
            setOpen(true)
        }else {
            setOpen(false);
        }
	},[props.num]);
	useEffect(() => {
        if(row.NTT_ID===props.num2) {
            setPwOpen(true)
        }else {
            setPwOpen(false);
        }
	},[props.num2]);


	useEffect(()=>{
		const d= new Date();
		const year=d.getFullYear();
		const month=d.getMonth();
		const day=d.getDate();
		setIsNew(new Date(row.FRST_REGISTER_PNTTM.substring(0,10))>new Date(year,month,day-30));
	},[row])
	const onButtonClick = () => {
		if(props.userData){
			if(lock&&lock==='Y'){
				if(row.NTT_ID===props.num2){
					props.openPWInput("")
				}else{
					props.openPWInput(row.NTT_ID)
				}
				return false
			}
	
			history.push({
				pathname:"/svc/plismboarddetail",
				...props.row,
				login_user_no:props.userData.user_no,
				login_user_role:props.userData.role,
				login_user_name:props.userData.name,
			})
		}else{
			props.onAlert("error","로그인 이후 이용해주세요." );
		}
	}
	
	const atchFileId_onclick = function() {
		let fileName = row.ATCH_FILE_ID;
		const localCheck =window.location.href.split("/")[2];
		if(localCheck.includes('localhost')||localCheck.includes('dev')){
			let fileUrl = "https://testeservice.logisview.com/file_download/";
			let url = fileUrl + fileName;
			window.open(url);
		}else{
			let fileUrl = "https://eservice.logisview.com/file_download/";
			let url = fileUrl + fileName;
			window.open(url);
		}
	};
	

	return (

	  	<React.Fragment>
			<TableRow>
				<TableCell onClick={(e) => onButtonClick(e)} component="th" scope="row" style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.RNUM}</TableCell>
				{row.TOP_FLAG &&row.TOP_FLAG==='Y'?
					<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px',fontWeight:'bold',color:'#06c'}}>{row.NTT_SJ} {isNew&& <img src={ico_m_new}/>} </TableCell>
					:<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.NTT_SJ} {isNew &&<img src={ico_m_new}/>} </TableCell>
				
				}<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.FRST_REGISTER_PNTTM}</TableCell>
				<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.FRST_REGISTER_NM}</TableCell>
				<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.INQIRE_CO}</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'}}>
					{row.ATCH_FILE_ID&&userData?
						<Button className="m-0 pt-1 pb-1" style={{backgroundColor:'#00acc1',fontSize:'10px'}} onClick={(e) => atchFileId_onclick()}>
							DOWN
						</Button>
						:<></>
					}
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
  }

export default function PlismBoard(props) {

    const classes = useRowStyles();
	const { userData} = props;
    const [boardData,setBoardData] = useState([]);
	const [selectValue,setSelectValue] = useState("0");
	const [CopySelectValue,setCopySelectValue] = useState("");
	const [openPage, setOpenPage] = useState(0);
	const [Num,setNum] = useState(1);
	const [subject, setSubject] = useState("");
	const [copySubject, setCopySubject] = useState("");
	const [openPW, setOpenPW] = useState("");
	// const [openAlert, setOpenAlert] = useState(false);
	// const [message, setMessage] = useState('');
	// const [status, setStatus] = useState('');
	const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
	const [totalPlismBoardCount,setTotalPlismBoardCount] = useState(0);
	const [boardIDList,setBoardIDList] =useState([]);
	const [selectedBoardID,setSelectedBoardID] =useState(userData&&(userData.role==='Y'||userData.klnet_id==='KLTEST01')?'default':'BBSMSTR_100000000001');
	const [bbsList,setBbsList]= useState([]);
	const [bbsLang,setBbsLang]= useState('ko');
	// =useState('BBSMSTR_100000000002');
	const pageCount = 10;
	const history = useHistory();


	useEffect(() => {
		onSubmit();
		//selectBoardID();
    }, [page]);

	useEffect(() => {
		if(userData&&(userData.role==='Y'||userData.klnet_id==='KLTEST01'))
		fncCategoryList();
    }, [userData]);

	useEffect(() => {
        if(props.location.state) {
            setOpenPage(props.location.state.param);
        }
    },[props.location]);

	const fncCategoryList = ()=> {
		if(userData){
			axios.post("/api/selectBbsCfgCodeList2",{sKlnetId:userData.klnet_id,bbs_type:'1'})
			.then(res => {
				if(res.data.length > 0) {
					const newArray = res.data.map( item => {
						return {value: item.BBS_ID, label: item.BBS_NM }
					})
					setBbsList(newArray);
				}
			})
		}
	}
	
	const onSubmit = () => {
		let bbs_user_in='Y'
		if (selectedBoardID&&("BBSMSTR_100000000006"===selectedBoardID || "BBSMSTR_100000000000"===selectedBoardID || "BBSMSTR_110000000001"===selectedBoardID || "BBSMSTR_110000000002"===selectedBoardID || "BBSMSTR_1000000000E1"===selectedBoardID)) {
			bbs_user_in='N' //BBSMSTR_100000000000 공지사항 - 전체용 포함안됨.
		} 

		axios.post("/api/selectPlismBoardList",
			{lang:bbsLang,
			bbs_id:selectedBoardID&&selectedBoardID!=='default'?selectedBoardID:null,
			fnc_chk:'N',
			bbs_user_in:bbs_user_in,
			lang:'ko',
			firstIndex:((page-1)*pageCount)+1, 
			recordCountPerPage:((page-1)*pageCount)+pageCount, 
			searchCnd:selectValue,
			searchWrd:subject?subject:null,
			sKlnetId:userData?userData.klnet_id:'',
		})
		.then(res => {
			setBoardData(res.data);
			//console.log(res.data);
			if(res.data.length>0){
				fncListCount();
			}
		})
	
	  }

	const fncListCount = () => {
		let bbs_user_in='Y'
		if (selectedBoardID&&("BBSMSTR_100000000006"===selectedBoardID || "BBSMSTR_100000000000"===selectedBoardID || "BBSMSTR_110000000001"===selectedBoardID || "BBSMSTR_110000000002"===selectedBoardID || "BBSMSTR_1000000000E1"===selectedBoardID)) {
			bbs_user_in='N' //BBSMSTR_100000000000 공지사항 - 전체용 포함안됨.
		} 

		axios.post("/api/selectPlismBoardListCount",
			{lang:bbsLang,
			bbs_id:selectedBoardID&&selectedBoardID!=='default'?selectedBoardID:null,
			fnc_chk:'N',
			bbs_user_in:bbs_user_in,
			lang:'ko', 
			searchCnd:selectValue,
			searchWrd:subject?subject:null,
			sKlnetId:userData?userData.klnet_id:''})
		.then(res => {
			if(res.data.length>0){
				//총페이지 수
				setTotalCount(Math.ceil(res.data[0].CNT/10))
				setTotalPlismBoardCount(res.data[0].CNT);
			}
		})
	}
	
	// const selectBoardID = () => {
	// 	axios.post("/api/selectPlismBoardID",{})
	// 	.then(res => {
	// 		if(res.data.length>0){
	// 			console.log(res.data);
	// 			setBoardIDList(res.data);
	// 		}
	// 	})
	// }
    
	// const onAlert = (meassge,status) => {
	// 	setOpenAlert(!openAlert);
	// 	setStatus(status);
	// 	setMessage(meassge);
	//   };

	// const qnaWrite = () => {
	// 	history.push({
	// 		pathname:"/svc/qnawrite",
	// 		...props.userData,
	// 	})
	// }

	const handleChange = (event, value) => {
		setPage(Number(value));
	  }

    return(
		<React.Fragment>
			<Card className="m-4">
				<CardContent>
					<h4 style={{textAlignLast:'left',color:'black'}}><Chat style={{color:'#00acc1'}}></Chat> 공지사항</h4>
					<GridContainer className="mt-4">
						{/* <GridItem xs={2} sm={2} md={2} >
							<FormControl variant="outlined" >
								<InputLabel>CATEGORY</InputLabel>
								<Select
									label="option"
									value={selectedBoardID}
									onChange={(event)=> setSelectedBoardID(event.target.value)}>
									{boardIDList.map((element, index) => (
												<MenuItem key={index} value={element.BBS_ID}>
													{element.BBS_NM}
												</MenuItem>
											))}
								</Select>
							</FormControl>
						</GridItem> */}
						<GridItem xs={3} sm={1} md={1} >
							<FormControl variant="outlined" >
								<InputLabel>OPTION</InputLabel>
								<Select
									label="option"
									value={selectValue}
									onChange={(event)=> setSelectValue(event.target.value)}>
									<MenuItem value="0">제목</MenuItem>
									<MenuItem value="1">내용</MenuItem>
									<MenuItem value="2">작성자</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs={6} sm={4} md={4} >
							<TextField id="Subject" variant="outlined"  onChange={(event)=>setSubject(event.target.value)} fullWidth />
						</GridItem>
						<GridItem xs={6} sm={2} md={2} >
							{userData&&(userData.role==='Y'||userData.klnet_id==='KLTEST01')? 
								<FormControl variant="outlined" >
									<InputLabel>OPTION</InputLabel>
									<Select
										label="option"
										value={selectedBoardID}
										onChange={(event)=> setSelectedBoardID(event.target.value)}>
											<MenuItem  value="default">전체 공지사항</MenuItem>
										{bbsList.map((data,key) =>
											<MenuItem  value={data.value}>{data.label}</MenuItem>
											)}
									</Select>
								</FormControl>
							:<></>}
						</GridItem>
						<GridItem xs={9} sm={5} md={5} >
							<div style={{textAlign:'-webkit-right'}}>
								<Button color="info" endIcon={<SearchIcon/>}  onClick={()=> onSubmit()}>Search</Button>
							</div>
						</GridItem>
					</GridContainer>
				</CardContent>
				<CardBody>
					<div className="mb-1">
						Total:{totalPlismBoardCount}
					</div>
					<Table>
						<TableHead style={{backgroundColor:'#f2fefd'}} className={classes["info" + "TableHeader"]}>
							<TableRow style={{borderBottomStyle:'solid',borderBottomColor:'#00bcd4'}}>
								<TableCell style={{width:'10%'}}>No</TableCell>
								<TableCell style={{width:'50%'}}>제목</TableCell>
								<TableCell style={{width:'10%'}}>작성일자</TableCell>
								<TableCell style={{width:'10%'}}>작성자</TableCell>
								<TableCell style={{width:'10%'}}>조회수</TableCell>
								<TableCell style={{width:'10%'}}>첨부파일</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{boardData.map((prop, key) => {
								return (
									<Row key={key} openNum={(e) => setOpenPage(e)}  openPWInput={(e) => setOpenPW(e)} userData={props.userData} row={prop} num={openPage} num2={openPW} onAlert={props.onAlert} totalPlismBoardCount={totalPlismBoardCount}/>
								);
							})}
						</TableBody>
						{(boardData.length > 0 ?
						<TableFooter>
							<TableRow>
								<TableCell colSpan={6} style={{paddingLeft:'35%'}}>
									<Pagination
										count={totalCount}
										page={page}
										onChange={handleChange}
										defaultPage={1}
										color="primary"
										size="small"
										showFirstButton
										showLastButton
										>
									</Pagination> 
								</TableCell> 
							</TableRow>
							<TableRow >
							</TableRow>
						</TableFooter>: null )}
					</Table>
				</CardBody>
			</Card>
			{/* <AlertMessage
			message={message}
			isOpen={openAlert}
			isClose={() => setOpenAlert(!openAlert)}
			// fontColor={font}   //선택사항
			alertColor={status === 'success' ? status : 'danger'} //선택사항  //primary, secondary, success, danger, warning, info, light, dark
			// timeOut={2000} //선택사항
			></AlertMessage> */}
		</React.Fragment>
    );
}