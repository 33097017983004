
import BookingDashBoard from 'views/Booking/Dashboard/index.js';//24.03.19 확인

import BookingList    from 'views/Booking/BKG/List/BookingList.js';
import Booking_ORG    from 'views/Booking/BKG/common/BookingMain.js';
import BookingWdfc    from 'views/Booking/BKG/wdfc/sections/BookingMainWdfc'
import Booking_ESHIP  from 'views/Booking/BKG/eshipCommon/BookingMain.js';

import SRList       from 'views/Booking/SR/List/SRList.js';
import SR_ORG       from 'views/Booking/SR/Request/common/Request.js';//userData
import SR_WDFC      from 'views/Booking/SR/Request/wdfc/request/sections/Request.js';
import SR_ESHIP     from 'views/Booking/SR/Request/eshipCommon/Request.js';//userData

import ConfirmList    from 'views/Booking/Confirm/List/ConfirmList.js';
import Confirm_ORG    from 'views/Booking/Confirm/Detail/common/index.js';
import ConfirmWdfc    from 'views/Booking/Confirm/Detail/wdfc/index';
import Confirm_ESHIP  from 'views/Booking/Confirm/Detail/eshipCommon/index.js';

import BLList       from 'views/Booking/bl/list/BLList.js';
import Bl           from 'views/Booking/bl/common/index';
import Bl_WDFC      from 'views/Booking/bl/wdfc/index';
import Bl_ESHIP     from 'views/Booking/bl/eshipCommon/index';

import Apipage      from 'views/Customs/ScrollTapPage.js';//24.03.19 확인
import FclPage      from "views/Schedule/FclScheduleList.js";//24.03.19 확인
import CalPage      from "views/Schedule/TerminalScheduleList.js";//24.03.19 확인

import ImportDemDet from 'views/DemDet/Import/importDemDetList.js';//24.03.19 확인
import SeaMap from "views/Maps/SeavantageMap";//24.03.19 확인
import CntrTracking from 'views/Tracking/CntrTraking/CntrTrackingList.js';//24.03.19 확인

import ProfilePage from "views/Member/UserProfile.js";
import SettingPage from "views/Member/UserServiceSetting.js";
import ApplicationForm from "views/Member/ApplicationForm.js";

import BoardPage from "views/Board/BoardPage.js";
import BoardDetail from "views/Board/BoardDetail.js";
import BoardWrite from "views/Board/BoardWrite.js";

import QnA from "views/QnA/QnA.js";
import QnADetail from "views/QnA/QnADetail.js";
import QnAWrite from "views/QnA/QnAWrite.js";
import PlismBoardList from "views/PlismBoard/PlismBoardList.js";
import PlismBoardDetail from "views/PlismBoard/PlismBoardDetail.js";
import FAQ from "views/FAQ/FAQ.js"; 
import FAQDetail from "views/FAQ/FAQDetail.js"; 
import ShipperBoardList from "views/ShipperBoard/ShipperBoardList.js";
import ShipperBoardDetail from "views/ShipperBoard/ShipperBoardDetail.js";

import ServiceCarrier from 'pages/service/serviceCarrier'
import ServiceReq from 'pages/service/serviceReq'

import ExpTrackingList     from 'views/Tracking/Exp/ExpTrackingList.js';
import TrackingShipment from 'views/Tracking/ShipmentIssue/index.js';

/**old demdet page */
// import ImportDemDetPage from 'views/DemDet/Import/importDemDetList_old.js';

// import IMOList from "views/Ship/ImoList.js";
// import ImoSearch from "views/Ship/ImoList";
// import DashPage     from "views/Dashboard/Dashboard.js";//
// import BookingDashBoard from 'views/Booking/DashBoard.js';
// import OceanFreight from "views/Schedule/OceanFreightSearch.js";
// import TrackingPage      from 'views/Tracking/TrackingList.js';
// import ContainerTracking from 'views/Tracking/TrackingMap/Map.js';
// import ExportDemDetPage from 'views/DemDet/Export/exportDemDetList.js';
// import DemDetMapPage    from 'views/DemDet/Map/DemDetMap.js';
// import MainUploadBL from "views/BLUpload/UploadPage";
// import HblUpload    from 'views/BLUpload/HBLUpload.js';
// import TestMap from "views/TestPage/TestMap";
// import TransportMonitoringShipper from 'views/InlandTransportMonitoring/TransportMonitoringShipper.js';
// import TransportMonitoringAll from 'views/InlandTransportMonitoring/TransportMonitoringAll.js';
// import InlandDrive from 'views/InlandTransportMonitoring/InlandDriveList.js';

// @material-ui/icons
//import Apps from "@material-ui/icons/Apps";
//import DashboardIcon from "@material-ui/icons/Dashboard";
//import DateRange from "@material-ui/icons/DateRange";
//import GridOn from "@material-ui/icons/GridOn";
//import Image from "@material-ui/icons/Image";
//import Place from "@material-ui/icons/Place";
//import Timeline from "@material-ui/icons/Timeline";
//import WidgetsIcon from "@material-ui/icons/Widgets";

// @material-ui/icons
//import DirectionsBoat from  "@material-ui/icons/DirectionsBoat";
//import ScrapIcon from '@material-ui/icons/Description';
//import MapIcon from "@material-ui/icons/Map";
//import BackupIcon from "@material-ui/icons/Backup";
// import AccountBalance from "@material-ui/icons/AccountBalance";
// import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
// import InsertChartOutlinedOutlinedIcon  from '@material-ui/icons/InsertChartOutlinedOutlined';
// import PaymentIcon from '@material-ui/icons/Payment';
// import PostAddIcon from '@material-ui/icons/NoteAdd';
// import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
// import LocalShipping from '@material-ui/icons/LocalShipping';
//import LocalShippingOutlined from '@material-ui/icons/LocalShippingOutlined'
import Person from "@material-ui/icons/Person";
import LocationOn from "@material-ui/icons/LocationOn";
import EventAvailable from "@material-ui/icons/EventAvailable";
import Schedule from "@material-ui/icons/Schedule";
import CloudUploadOutlined from "@material-ui/icons/CloudUploadOutlined";
import ViewList from "@material-ui/icons/ViewList"
import DvrIcon from '@material-ui/icons/Dvr';
import DomainIcon from '@material-ui/icons/Domain';
import {//CollectionsBookmark,LocalShipping,AttachMoney
  Adjust, Ballot } from '@material-ui/icons'

var ServiceRoutes = [
  {
    collapse: true,
    name: "스케줄",
    rtlName: "SCHEDULE",
    icon: Schedule,
    state: "componentsCollapse",
    mainShow:true,
    sideShow:true,
    views: [
      {
        path: "/fcl",
        name: "해상 운송 스케줄",
        rtlName: "FCL SEA SCHEDULE",
        // icon: AssignmentTurnedInIcon,
        mini: "FS",
        rtlMini: "FS",
        component: FclPage,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/cal",
        name: "터미널 스케줄",
        rtlName: "TERMINAL SCHEDULE",
        // icon: AssignmentTurnedInIcon,
        mini: "TS",
        rtlMini: "TS",
        component: CalPage,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/sch/terminalSchedule.do",
        name: "터미널 스케줄",
        rtlName: "TERMINAL SCHEDULE",
        // icon: AssignmentTurnedInIcon,
        mini: "TS",
        rtlMini: "TS",
        component: CalPage,
        layout: "/blgate",
        mainShow:false,
        sideShow:false,
      },
      // {
      //   path: "/ofs",
      //   name: "OCEAN FREIGHT",
      //   rtlName: "해상 운임 조회",
      //   icon: AttachMoney,
      //   component: OceanFreight,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
      {
        name: "스마트 스케줄",
        rtlName: "스마트 스케줄",
        mini: "SCH",
        rtlMini: "SCH",
        // icon: AssignmentTurnedInIcon,
        layout: "PLISM",
        linkParam: {prodNo :927, devNo:927 },
        mainShow:true,
        sideShow:true,
      },
    ]
  },
  {
    collapse: true,
    name: "수출 업무",
    rtlName: "BOOKING",
    icon: Adjust,
    state: "bookCollapse",
    mainShow:true,
    sideShow:true,
    views: [
      {
        path: "/bkdash",
        name:"대시보드",
        rtlname:"대시보드",
        // rtlname: "BOOKING DASHBOARD",
        mini: "BK",
        rtlMini: "BK",
        // icon: InsertChartOutlinedOutlinedIcon, 
        component: BookingDashBoard,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
        option:'en',
      },
      {
        path: "/bookinglist",
        name: "BOOKING",
        rtlname: "BOOKING",
        mini: "BKGL",
        rtlMini: "BKG",
        // icon: ViewList, 
        component: BookingList,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
        option:'en',
      },
      {
        path: "/bookingWdfc",
        name: "BOOKING",
        mini: "BKG",
        rtlMini: "BKG",
        icon: ViewList, 
        component: BookingWdfc,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/bkgPlism",
        name: "BOOKING",
        mini: "BKG",
        rtlMini: "BKG",
        icon: ViewList, 
        component: Booking_ESHIP,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/booking",
        name: "BOOKING",
        mini: "BKG",
        rtlMini: "BKG",
        icon: ViewList, 
        component: Booking_ORG,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/confirmlist",
        name: "CONFIRM",
        rtlname : "CONFIRM",
        mini: "CFM",
        rtlMini: "CFM",
        // icon: ViewList, 
        component: ConfirmList,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
        option:'en',
      },
      {
        path: "/confirmWdfc",
        name: "CONFIRM",
        mini: "CFM",
        rtlMini: "CFM",
        icon: ViewList, 
        component: ConfirmWdfc,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/confirm",
        name: "CONFIRM",
        mini: "CFM",
        rtlMini: "CFM",
        icon: ViewList, 
        component: Confirm_ORG,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/confirmPlism",
        name: "CONFIRM",
        mini: "CFM",
        rtlMini: "CFM",
        icon: ViewList, 
        component: Confirm_ESHIP,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/srlist",
        name: "SR",
        rtlname : "SR",
        mini: "SR",
        rtlMini: "SR",
        // icon: CollectionsBookmark, 
        component: SRList,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
        option:'en',
      },
      {
        path: "/bllist",
        name: "B/L",
        rtlname : "B/L",
        mini: "BL",
        rtlMini: "BL",
        // icon: ViewList, 
        component: BLList,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
        option:'en',
      },
      {
        path: "/bl",
        name: "BL",
        mini: "BL",
        rtlMini: "BL",
        icon: ViewList, 
        component: Bl,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/blWdfc",
        name: "BL",
        mini: "BL",
        rtlMini: "BL",
        icon: ViewList, 
        component: Bl_WDFC,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/blPlism",
        name: "BL",
        mini: "BL",
        rtlMini: "BL",
        icon: ViewList, 
        component: Bl_ESHIP,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/srWdfc",
        name: "SR",
        mini: "SR",
        rtlMini: "SR",
        icon: ViewList, 
        component: SR_WDFC,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/sr",
        name: "SR",
        mini: "SR",
        rtlMini: "SR",
        icon: ViewList, 
        component: SR_ORG,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/srPlism",
        name: "SR",
        mini: "SR",
        rtlMini: "SR",
        icon: ViewList, 
        component: SR_ESHIP,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        name: "수출 해상운임 인보이스",
        rtlName: "수출 인보이스",
        layout: "PLISM",
        linkParam: {prodNo :2420, devNo:2417},
        // icon: DomainIcon, 
        mini: "INV",
        rtlMini: "INV",
        mainShow:true,
        sideShow:true,
      },
      {
        name: "수출 적하목록 제출현황",
        rtlName: "수출 적하목록 제출현황",
        layout: "PLISM",
        linkParam: {prodNo :214, devNo:214},
        // icon: DomainIcon, 
        mini: "EXM",
        rtlMini: "SR",
        mainShow:true,
        sideShow:true,
      },
      {
        name: "위험물",
        rtlName: "위험물",
        layout: "PLISM",
        linkParam: {prodNo :509, devNo:509 },
        mini: "DA",
        rtlMini: "DA",
        // icon: DomainIcon, 
        mainShow:true,
        sideShow:true,
      },
      {
        name: "해외서비스",
        rtlName: "해외서비스",
        layout: "PLISM",
        linkParam: {prodNo :406, devNo:406 },
        mini: "FS",
        rtlMini: "FS",
        // icon: DomainIcon, 
        mainShow:true,
        sideShow:true,
      },
      {
        name: "VGM",
        rtlName: "VGM",
        layout: "PLISM",
        linkParam: {prodNo :992, devNo:990},
        // icon: DomainIcon, 
        mini: "VGM",
        rtlMini: "VGM",
        mainShow:true,
        sideShow:true,
      }, 
      {
        name: "수출면장 정보관리",
        rtlName: "수출면장 정보관리",
        layout: "PLISM",
        linkParam: {prodNo :978, devNo:978},
        id: 'tabIndex=1', 
        mini: "EX",
        rtlMini: "EX",
        mainShow:true,
        sideShow:true,
      },
    ]
  },
  {
    collapse: true,
    name: "수입 업무",
    rtlName: "IMPORT",
    icon: Adjust,
    state: "importCollapse",
    mainShow:true,
    sideShow:true,
    views: [
      {
        name: "대시보드",
        rtlName: "대시보드",
        layout: "PLISM",
        linkParam: {prodNo :935, devNo:604},
        // icon: DomainIcon, 
        mini: "DSH",
        rtlMini: "DSH",
        mainShow:true,
        sideShow:true,
      },
      {
        name: "수입 적하목록 제출현황",
        rtlName: "수입 적하목록 제출현황",
        layout: "PLISM",
        linkParam: {prodNo :120, devNo:120},
        // icon: DomainIcon, 
        mini: "MFI",
        rtlMini: "MFI",
        mainShow:true,
        sideShow:true,
      },
      {
        name: "e-A/N",
        rtlName: "e-A/N",
        layout: "PLISM",
        linkParam: {prodNo :157, devNo:157},
        // icon: DomainIcon, 
        mini: "AN",
        rtlMini: "AN",
        mainShow:true,
        sideShow:true,
      },
      {
        name: "c-D/O",
        rtlName: "D/O",
        layout: "PLISM",
        linkParam: {prodNo :158, devNo:158},
        // icon: DomainIcon, 
        mini: "DO",
        rtlMini: "DO",
        mainShow:true,
        sideShow:true,
      },
      {
        name: "D/O 인보이스 내역관리",
        rtlName: "D/O 인보이스 내역관리",
        layout: "PLISM",
        linkParam: {prodNo :1039, devNo:1049},
        icon: DomainIcon, 
        mainShow:true,
        sideShow:true,
      },
      {
        name: "B/L 관리",
        rtlName: "B/L 관리",
        layout: "PLISM",
        linkParam: {prodNo :2140, devNo:2148},
        icon: DomainIcon, 
        mainShow:true,
        sideShow:true,
      },
      {
        name: "SUR CHG 인보이스 신청",
        rtlName: "SUR CHG 인보이스 신청",
        layout: "PLISM",
        linkParam: {prodNo :936, devNo:936},
        icon: DomainIcon, 
        mainShow:true,
        sideShow:true,
      },
      {
        name: "SUR CHG 인보이스 내역관리",
        rtlName: "SUR CHG 인보이스 내역관리",
        layout: "PLISM",
        linkParam: {prodNo :175, devNo:175},
        icon: DomainIcon, 
        mainShow:true,
        sideShow:true,
      },
      {
        name: "터미널 HOLD 정보",
        rtlName: "터미널 HOLD 정보",
        layout: "PLISM",
        linkParam: {prodNo :606, devNo:606},
        icon: DomainIcon, 
        mainShow:true,
        sideShow:true,
      },
       {
        path: "/demdetImport",
        name: "수입 DEM/DET",
        rtlName: "IMPORT DEM/DET",
        mini: "IMP",
        rtlMini: "IMP",
        component: ImportDemDet,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
    ]
  },
  {
    collapse: true,
    name: "화물 추적",
    rtlName: "LOCATION",
    icon: LocationOn,
    state: "pageCollapse",
    mainShow:true,
    sideShow:true,
    views: [
      {
        path: "/expTracking",
        name: "글로벌 화물 추적",
        rtlName: "TRACKING SERVICE",
        mini: "TK",
        rtlMini: "TK",
        component: ExpTrackingList,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
        menuNo: {prodNo :80406, devNo:80406 },
      },
      {
        path: "/expTrackingSingle",
        name: "글로벌 화물 추적",
        rtlName: "TRACKING SERVICE",
        mini: "TK",
        rtlMini: "TK",
        component: ExpTrackingList,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
        mode:'single',
        menuNo: {prodNo :50418, devNo:50434 },
      },
      {
        path: "/flightinfo",
        name:"선박 위치 조회",
        rtlname: "SHIP FLIGHT INFO",
        mini: "SF",
        // rtlMini: "SF",
        // icon: DirectionsBoatIcon, 
        component: SeaMap,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/trkContainerTracking",
        name: "국내 컨테이너 이동현황",
        rtlName: "CONTAINER TRACKING SERVICE",
        mini: "CTK",
        rtlMini: "CTK",
        component: CntrTracking,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
      // {
      //   path: "/InlandDrive",
      //   name:"내륙 운송 모니터링",
      //   rtlname: "InlandDrive",
      //   mini: "IND",
      //   rtlMini: "IND",
      //   component: InlandDrive,
      //   // icon:LocalShipping,
      //   layout: "/svc",
      //   mainShow:true,
      //   sideShow:true,
      // },
      // {
      //   path: "/unipassapi",
      //   name: "수입화물진행정보",
      //   rtlname: "UNIPASS API SERVICE",
      //   mini: 'API',
      //   rtlMini:"API",
      //   component: Apipage,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
      {
        path: "/unipassapi",
        id:'0',
        name: "수입화물진행정보",
        rtlname: "UNIPASS API SERVICE",
        mini: 'API',
        rtlMini:"API",
        layout: "/svc",
        component: Apipage,
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/trk/ImpCustomsPassInfo.do",
        name: "수입화물진행정보",
        // rtlName: "TERMINAL SCHEDULE",
        // icon: AssignmentTurnedInIcon,
        mini: "API",
        rtlMini: "API",
        component: Apipage,
        layout: "/blgate",
        mainShow:false,
        sideShow:false,
      },
      // {
      //   path: "/uploadbl",
      //   name: "화물추적대상 등록",
      //   rtlName: "BL Upload",
      //   // icon: CloudUploadOutlined,
      //   mini: "BLU",
      //   rtlMini: "BLU",
      //   component: MainUploadBL,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
      // {
      //   path: "/hblupload",
      //   name: "H-BL Upload",
      //   rtlName: "User HBL Upload",
      //   icon: PostAddIcon,
      //   component: HblUpload,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
      // {
      //   path: "/cntrmap",
      //   name: "VIEW CONTAINER MOVEMENT",
      //   mini: "CM",
      //   rtlMini: "CM",
      //   // icon: DirectionsBoatIcon, 
      //   component: ContainerTracking,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:true,
      // },
      // {
      //   path: "/imosearch",
      //   name: "IMO SEARCH",
      //   mini: "IMO",
      //   rtlMini: "IMO",
      //   component: IMOList,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
      {
        path: "/unipassapi",
        id:'1',
        name: "수출이행내역",
        mini: "OFEC",
        rtlMini: "OFEC",
        layout: "/svc",
        component: Apipage,
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/trk/OksFmsEtcCustomsAPI.do",
        name: "수출이행내역",
        // rtlName: "TERMINAL SCHEDULE",
        // icon: AssignmentTurnedInIcon,
        mini: "OFEC",
        rtlMini: "OFEC",
        component: Apipage,
        layout: "/blgate",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "https://unipass.customs.go.kr/clip/index.do",
        name: "관세청 정보(HS-CODE)",
        rtlname: "HSCODE",
        mini: 'HSCODE',
        rtlMini:"HSCODE",
        // icon: AccountBalance,
        // component: Apipage,
        layout: "urlPath",
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/unipassapi",
        id:'2',
        name: "환급정보",
        layout: "/svc",
        component: Apipage,
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/unipassapi",
        id:'3',
        name: "기타",
        layout: "/svc",
        component: Apipage,
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/shipmentIssue",
        name: "스케줄 변경 알림",
        rtlName: "SHIPMENT TRACKING",
        mini: "ST",
        rtlMini: "ST",
        component: TrackingShipment,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
    ]
  },
  {
    collapse: true,
    name: "커뮤니티",
    rtlName: "COMMUNITY",
    icon: Ballot,
    state: "communityCollapse",
    mainShow:true,
    sideShow:true,
    views: [
      {
        path: "/plismboardlist",
        name: "공지사항",
        rtlName: "Plism Board",
        // icon: DvrIcon, 
        mini: "PBO",
        rtlMini: "PBO",
        component: PlismBoardList,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/plismboarddetail",
        name: "공지사항",
        rtlName: "Plism Board",
        icon: DvrIcon, 
        component: PlismBoardDetail,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/board",
        name: "업무공지",
        rtlName: "Notice Board",
        // icon: DvrIcon, 
        mini: "BO",
        rtlMini: "BO",
        component: BoardPage,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/boarddetail",
        name: "업무공지",
        rtlName: "Notice Board",
        icon: DvrIcon, 
        component: BoardDetail,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/boardwrite",
        name: "업무공지",
        rtlName: "Notice Board",
        icon: DvrIcon, 
        component: BoardWrite,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/qna",
        name: "Q&A",
        rtlName: "Q&A",
        // icon: DvrIcon, 
        mini: "QNA",
        rtlMini: "QNA",
        component: QnA,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/qnadetail",
        name: "Q&A",
        rtlName: "Q&A",
        icon: DvrIcon, 
        component: QnADetail,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/qnawrite",
        name: "Q&A",
        rtlName: "Q&A",
        icon: DvrIcon, 
        component: QnAWrite,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      {
        path: "/faq",
        name: "FAQ",
        rtlName: "FAQ",
        // icon: DvrIcon, 
        mini: "FAQ",
        rtlMini: "FAQ",
        component: FAQ,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/faqdetail",
        name: "FAQ",
        rtlName: "FAQ",
        icon: DvrIcon, 
        component: FAQDetail,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      // {
      //   name: "선사게시판",
      //   rtlName: "선사게시판",
      //   layout: "PLISM",
      //   linkParam: {prodNo :32438, devNo:32438},
      //   mini: "CB",
      //   rtlMini: "CB",
      //   mainShow:true,
      //   sideShow:true,
      // },
      {
        path: "/shipperboardlist",
        name: "선사게시판",
        rtlName: "선사게시판",
        // icon: DvrIcon, 
        mini: "CB",
        rtlMini: "CB",
        component: ShipperBoardList,
        layout: "/svc",
        mainShow:true,
        sideShow:true,
      },
      {
        path: "/shipperboarddetail",
        name: "선사게시판",
        rtlName: "선사게시판",
        icon: DvrIcon, 
        component: ShipperBoardDetail,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      // {
      //   path: "https://eservice.logisview.com/uat/uia/eserviceSub_nologin.do?menu_no=32438&link=Y'",
      //   name: "선사게시판",
      //   // rtlName: "선사게시판",
      //   layout: "urlPath",
      //   // linkParam: {prodNo :32438, devNo:32438},
      //   // mini: "CB",
      //   // rtlMini: "CB",
      //   mainShow:true,
      //   sideShow:true,
      // },
      {
        path: "https://eservice.logisview.com/websquare/websquare.html?w2xPath=/sq5/com/uat/pop/CushipPop.xml",
        name: "KL-Net 취합선사",
        // rtlname: "취합선사",
        // mini: '취합선사',
        // rtlMini:"취합선사",
        // icon: AccountBalance,
        // component: Apipage,
        layout: "urlPath",
        mainShow:true,
        sideShow:true,
      },
    ]
  }, 
  // {
  //   collapse: true,
  //   name: "CUSTOMS",
  //   rtlName: "Customs",
  //   icon: AccountBalance,
  //   state: "customsCollapse",
  //   mainShow:false,
  //   sideShow:false,
  //   views: [
  //     {
  //       path: "/unipassapi",
  //       name: "UNIPASS API SERVICE",
  //       rtlName: "UAS",
  //       mini: 'API',
  //       component: Apipage,
  //       layout: "/svc",
  //       mainShow:true,
  //       sideShow:true,
  //     }
  //   ]
  // },
  // {위동으로 대체
  //   path: "/dashboard",
  //   name: "DASHBOARD",
  //   rtlName: "TOTAL CARGO INFO",
  //   icon: InsertChartOutlinedOutlinedIcon,
  //   component: DashPage,
  //   layout: "/svc",
  //   mainShow:false,
  //   sideShow:false,
  // },
  {
    collapse: true,
    name: "UPLOAD",
    rtlName: "UPLOAD",
    icon: CloudUploadOutlined,
    state: "uploadCollapse",
    mainShow:false,
    sideShow:false,
    views: [
      // {
      //   path: "/uploadbl",
      //   name: "BL Upload",
      //   rtlName: "User BL Upload",
      //   icon: CloudUploadOutlined,
      //   component: MainUploadBL,
      //   layout: "/svc",
      //   mainShow:true,
      //   sideShow:true,
      // },
      // {
      //   path: "/hblupload",
      //   name: "H-BL Upload",
      //   rtlName: "User HBL Upload",
      //   icon: PostAddIcon,
      //   component: HblUpload,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
    ]
  },  
  {
    collapse: true,
    name: "DEM/DET/OSC",
    rtlName: "보관기한정보",
    icon: EventAvailable,
    state: "demdetCollapse",
    mainShow:false,
    sideShow:false,
    views: [
      // {
      //   path: "/demDet/Import",
      //   name: "IMPORT DEM/DET/OSC",
      //   rtlName: "수입DEM/DET",
      //   mini: "ID",
      //   rtlMini: "ID",
      //   component: ImportDemDetPage,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
      // {
      //   path: "/demDet/Export",
      //   name: "EXPORT DEM/DET/OSC",
      //   rtlName: "수출DEM/DET",
      //   mini: "ED",
      //   rtlMini: "ED",
      //   component: ExportDemDetPage,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
      // {
      //   path: "/mapService",
      //   name: "DEM&DET SUMMARY",
      //   rtlName: "MAP",
      //   mini: "MP",
      //   rtlMini: "MP",
      //   component: DemDetMapPage,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
    ]
  },  
  // {
  //   collapse: true,
  //   name: "물류정보",
  //   rtlName: "물류정보",
  //   icon: EventAvailable,
  //   state: "infoCollapse",
  //   mainShow:false,
  //   sideShow:false,
  //   views: [
      {
        path: "/serviceReq",
        name: "서비스 이용안내",
        rtlName: "서비스 이용안내",
        mini: "Req",
        rtlMini: "Req",
        component: ServiceReq,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
      // {
      //   path: "/serviceReq?tab=t0",
      //   name: "서비스 이용안내",
      //   rtlName: "서비스 이용안내",
      //   mini: "Req",
      //   rtlMini: "Req",
      //   // component: ServiceReq,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
      // {
      //   path: "/serviceReq?tab=t1",
      //   name: "서비스 이용안내",
      //   rtlName: "서비스 이용안내",
      //   mini: "Req",
      //   rtlMini: "Req",
      //   // component: ServiceReq,
      //   layout: "/svc",
      //   mainShow:false,
      //   sideShow:false,
      // },
      {
        path: "/serviceCarrier",
        name: "서비스 제공 선사",
        rtlName: "서비스 제공 선사",
        mini: "SC",
        rtlMini: "SC",
        component: ServiceCarrier,
        layout: "/svc",
        mainShow:false,
        sideShow:false,
      },
  //   ]
  // },  
  // {
  //   collapse: true,
  //   name: "내륙운송모니터링",
  //   rtlName: "inland transportation monitoring",
  //   icon: LocalShippingOutlined,
  //   state: "inlandCollapse",
  //   views: [
  //     {
  //         path: "/TransportMonitoringShipper",
  //         name: "개별 실화주",
  //         rtlName: "ITM",
  //         mini: 'ITM',
  //         component: TransportMonitoringShipper,
  //         layout: "/svc"   
  //     },
  //     {
  //       path: "/TransportMonitoringAll",
  //       name: "전체 실화주",
  //       rtlName: "ALL",
  //       mini: 'ALL',
  //       component: TransportMonitoringAll,
  //       layout: "/svc"   
  //     }
  //   ]
  // },
  {
    path: "/profile",
    name: "사용자 정보",
    rtlName: "Profile Page",
    icon: Person,
    component: ProfilePage,
    layout: "/svc",
    mainShow:false,
    sideShow:false,
  },
  {
    path: "/setting",
    name: "설정",
    rtlName: "Setting Page",
    icon: Person,
    component: SettingPage,
    layout: "/svc",
    mainShow:false,
    sideShow:false,
  },
  {
    path: "/application",
    name: "서비스이용신청",
    rtlName: "application",
    icon: Person,
    component: ApplicationForm,
    layout: "/svc",
    mainShow:false,
    sideShow:false,
  },
];
export default ServiceRoutes;
