import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImg from 'assetsNew/image/No-Image.gif'
import {Tooltip,} from "@material-ui/core";
const imagePath = process.env.REACT_APP_LINE_IMAGE_PATH;

/*******************************
 * props.logoImgList(서비스전역)로 logo 이미지 props로 가져오는 방법  
 *  props.logoImgList : {
    "line_code": "VSS",
    "sch_line_code": "ECON",
    "line_kr_name": "늘푸른해운항공",
    "sch_logo_img": "ECON.png",
    "line_logo_img": "VSS.jpg",
    "logo_img": "ECON.png"}
    
    carrierInfo = props.logoImgList.find 라인코드나 스케줄라인코드로 찾아서 필요한key값이용;
    선사로고 : line_logo_img 
    스케줄라인로고 : sch_logo_img
    한글 선사명: line_kr_name
    <LoadCarrierImage  classStyle={"img-rounded img-responsive"} 
    logo={(carrierInfo&&carrierInfo['line_logo_img'])||carrierInfo['sch_logo_img']||value.CARRIER_CODE}
    width={} height={}
    kr_name=  {carrierInfo.line_kr_name}/> 
    **************************/

const LoadCarrierImage =(props)=>{
    const {classStyle
        , line_code
        , logo
        , width
        , height
        ,kr_name} =props;
    return  (<>
         <Tooltip title={kr_name?`[${line_code}] ${kr_name}`:''}>
            <LazyLoadImage
                className={"img-rounded img-responsive"}
                src={imagePath+(logo||line_code||'No-Image.gif')}
                width={width} height={height}
                placeholderSrc={PlaceholderImg}
                onError={(e)=>{e.target.src =PlaceholderImg;}}
                visibleByDefault = {PlaceholderImg}
            />
        </Tooltip>
     </>)
}
export default LoadCarrierImage;