/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row,Col, FormGroup,Label,Input,Popover,Button,
    Modal, ModalHeader, ModalBody, ModalFooter,CardBody, CardSubtitle,InputGroupAddon, InputGroupText, InputGroup
} from "reactstrap";

import { makeStyles , } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import InputValid from "components/CustomInput/InputValid.js";
import Select from "react-select";
import * as options from 'muiComponents/common/options.js';
import axios from 'axios';


const useStyles = makeStyles({
    root:{width:'100%'},
    container:{
        height:'400px',
        overflow:'auto'
    },
    table:{
        minWidth:750
        ,minHeight:"400px"
        ,marginBottom:'0'
    },
  
})

export default function SpecialContainer (props){
    const classes = useStyles();
    const [index, setIndex] = useState(0);
    const [special, setSpecial] = useState({});
    const [openType, setOpenType] = useState("");
    const [checkLqYn, setCheckLqYn] = useState(props.special.special_lq_yn=='Y'?true:false);
    const [popOpen,setPopOpen] = useState(false);
    const [dangerList,setDangerList] = useState([]);
    const [searchGb,setSearchGb] = useState(options.dangerSearchOption[0].value);
    const [searchVal,setSearchVal] = useState('');
    const {lineCode,dangerTrue} =props;
    const changeYn = (bool) => {setCheckLqYn(bool); fncOnChangeSelect(bool?'Y':null, 'special_lq_yn')}

    //pagination
    const [rowsPerPage,setRowsPerPage] = React.useState(10);
    const [page,setPage] = React.useState(0);
    const handleChagePage = (e,newPage) => {
        setPage(newPage);
        selectDangerList(newPage);
      } 
    const handleChangeRowsPerPage = event => {
        // setRowsPerPage(parseInt(event.target.value,10));
        setRowsPerPage(+event.target.value);
        setPage(0);
    }
    const  tableCell = {
        paddingRight:'0',
        wordBreak:'keep-all',
        fontSize :'0.7rem',
        cursor:'pointer'
    }
    useEffect(() => {
        setSpecial(props.special);
        setIndex(props.index);
        setOpenType(props.openType);
        props.special&&props.special.special_lq_yn=='Y'?setCheckLqYn(true):setCheckLqYn(false)

        // console.log(special.special_p_group,special.special_p_group&&(options.special_p_group.findIndex(x=>x.value===special.special_p_group)))
        ///반영시 삭제
        // setPopOpen(true); selectDangerList(0);
    },[props]);

    // 완료된 내용은 부모로 전달
    const fncOnBlur = (e) => {
        e.preventDefault();
        props.fncOnBlurSpecial(index, special);
    }
    // 수정된 내용은 container 저장
    const fncOnChange = ( e, key ) => {
        e.preventDefault();
        console.log(e, key)
        setSpecial({...special, [key]:(e.target.value.toUpperCase())||null});
    }
    const fncOnChangeSelect = ( value, key ) => {
        console.log(value, key)
        setSpecial({...special, [key]:value});
        props.fncOnBlurSpecial(index, {...special, [key]:value});
    }

    const selectDangerList = (p,reqP)=>{
        if(lineCode){
            let params = {lineCode:lineCode, page:p, ...(reqP?{ searchParam : reqP ,searchGb:searchGb}:{})  }
            // console.log(params)
            axios.post("/shipper/selectDangerList",params).then(res=>{
                // console.log(res.data)
                setDangerList(res.data)
            })
        }
    }

    const setDangerCode = (e,row) =>{
        console.log(row)
        let dangers = {...special   , 'special_undg':row.undg_code.toUpperCase() || null
                                    , 'special_imdg':row.imdg_code.toUpperCase() || null}
        setSpecial({...dangers});
        props.fncOnBlurSpecial(index, {...dangers});
        setPopOpen(false)
    }
    return (
        <Row>
            <Col>
                <Row>
                    <Col xl="5" lg="5">
                        <Row>
                            <Col xl="4" lg="4">
                                <FormGroup>
                                    <Label className="mb-0">UNNO</Label>
                                    <InputValid 
                                        type="text"
                                        name="special_unno"
                                        id="special_unno"
                                        maxLength="4"
                                        value={special.special_undg?special.special_undg:''}
                                        onClick={()=>{setPopOpen(!popOpen); selectDangerList(page);}}
                                        onChange={(e)=>fncOnChange(e, 'special_undg')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="number"
                                        // required={'BOOK'===openType?true:false} 
                                        required={dangerTrue?true:false} 
                                        feedid="container"
                                        disabled={true}
                                        inputgrouptext={<>
                                        <i id='searchUndg' className="fa fa-search "  style={{cursor:'pointer'}} 
                                        onClick={()=>{setPopOpen(!popOpen); selectDangerList(page);}}
                                        />
                                        <Modal isOpen={popOpen} toggle={()=> setPopOpen(!popOpen)} size="lg"  onClose={(e)=>setPopOpen(e)}>
                                            <ModalHeader className="pb-2">
                                                <Row>
                                                <Col className="ml-auto col-11">
                                                    <h4>위험물 코드</h4>
                                                </Col>
                                                <Col className="ml-auto col-1">
                                                    <Button close onClick={()=>setPopOpen(false)}></Button>
                                                </Col>
                                                </Row>
                                            </ModalHeader>
                                            <ModalBody className='p-1'>
                                                <CardSubtitle className="p-2">
                                                    <Col xl="12" lg="12" sm="12" md="12">
                                                        <InputGroup >
                                                            <Select
                                                                className="customSelect"
                                                                defaultValue={options.dangerSearchOption[0]}
                                                                value={options.dangerSearchOption.find(op => op.value === searchGb)}
                                                                onChange={(e) => setSearchGb(e.value)}
                                                                options={options.dangerSearchOption}
                                                                styles={{menu: provided => ({...provided, zIndex:99})}}
                                                            />
                                                            <Input 
                                                                type="text" 
                                                                value ={searchVal}
                                                                onChange={(e)=>setSearchVal(e.target.value.toUpperCase())}
                                                                onKeyPress ={(e)=>"Enter" === e.key?selectDangerList(1,searchVal):null} 
                                                            />
                                                            <InputGroupAddon addonType="append" onClick={()=>selectDangerList(1,searchVal)}>
                                                                <InputGroupText>
                                                                {/* <Button size="sm" color='link' > */}
                                                                    <i className="fa fa-search" />
                                                                {/* </Button> */}
                                                                </InputGroupText>
                                                            </InputGroupAddon>
                                                        </InputGroup>
                                                    </Col>
                                                </CardSubtitle>
                                                <CardBody className="bg-white p-0">
                                                    <Col xl="12" lg="12" sm="12" md="12">
                                                    <TableContainer className={classes.container}>
                                                        <Table stickyHeader size ='small' className={classes.table} >
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell className="bg-info" style = {tableCell} align='center'>NO</TableCell>
                                                                    <TableCell className="bg-info" style = {tableCell} align='center'>위험물 코드</TableCell>
                                                                    <TableCell className="bg-info" style = {tableCell} align='center'>IMDG 코드</TableCell>
                                                                    <TableCell className="bg-info" style = {tableCell} align='center'>위험물 TYPE</TableCell>
                                                                    <TableCell className="bg-info" style = {tableCell} align='center'>위험물 영문명</TableCell>
                                                                    <TableCell className="bg-info" style = {tableCell} align='center'>위험물 한글명</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                            {dangerList.slice(page*rowsPerPage, page*rowsPerPage +rowsPerPage).map((row,key)=>{
                                                                return(
                                                                    <TableRow hover role='checkbox' tabIndex={-1} key={key} value={row} onClick={(e)=>setDangerCode(e,row)} >
                                                                        <TableCell align='center' style = {tableCell}>{row.rnum }</TableCell>
                                                                        <TableCell align='center' style = {tableCell} >{row.undg_code}</TableCell>
                                                                        <TableCell align='center' style = {tableCell}>{row.imdg_code}</TableCell>
                                                                        <TableCell align='center' style = {tableCell}>{row.code_class}</TableCell>
                                                                        <TableCell style = {tableCell}>{row.undg_desc}</TableCell>
                                                                        <TableCell style = {tableCell}>{row.undg_kr_desc}</TableCell>
                                                                    </TableRow>
                                                                    )
                                                                }) 
                                                            }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <TablePagination
                                                        rowsPerPageOptions={[10,20,30]}
                                                        component="div"
                                                        count={dangerList.length}
                                                        page={page}
                                                        rowsPerPage={rowsPerPage}
                                                        onPageChange={handleChagePage}
                                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                                    />
                                                    </Col>
                                                </CardBody>
                                            </ModalBody>
                                            <ModalFooter>
                                                <Row>
                                                
                                            </Row>
                                            </ModalFooter>
                                        </Modal>
                                        </>
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl="5" lg="5">
                                <FormGroup>
                                    <Label className="mb-0">Technical Name</Label>
                                    <Input type="text" name="special_tech_name" id="special_tech_name" 
                                    placeholder='-'
                                    readOnly
                                    style={{padding:'0', textAlign:'center'}}/>
                                    {/* <InputValid 
                                        type="text"
                                        name="special_tech_name"
                                        id="special_tech_name"
                                        maxLength="70"
                                        value={special.special_tech_name?special.special_tech_name:''}
                                        onChange={(e)=>fncOnChange(e, 'special_tech_name')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="text"
                                        required={'BOOK'===openType?true:false} 
                                        feedid="container"
                                    /> */}
                                </FormGroup>
                            </Col>
                            <Col xl="3" lg="3">
                                <FormGroup disabled>
                                    <Label className="mb-0">CLASS</Label>
                                    <InputValid 
                                        type="text"
                                        name="special_imdg"
                                        id="special_imdg"
                                        maxLength="7"
                                        value={special.special_imdg?special.special_imdg:''}
                                        onChange={(e)=>fncOnChange(e, 'special_imdg')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="eng"
                                        // required={'BOOK'===openType?true:false} 
                                        required={dangerTrue?true:false} 
                                        feedid="container"
                                        // readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl="4" lg="4">
                        <Row>
                            <Col xl="7" lg="7">
                                <FormGroup>
                                    <Label className="mb-0">F/P 인화점</Label>
                                    <Row>
                                        <Col lg='6' className="mr-0 pr-0">
                                                <InputValid 
                                                type="text"
                                                name="special_fp_tmp"
                                                id="special_fp_tmp"
                                                maxLength="3"
                                                value={special.special_fp_tmp?special.special_fp_tmp:''}
                                                onChange={(e)=>fncOnChange(e, 'special_fp_tmp')}
                                                onBlur={(e) => {
                                                    fncOnBlur(e); special.special_fp_tmp&&!special.special_fp_tmp_unit&&fncOnChangeSelect( options.tmp_unit[0].value, 'special_fp_tmp_unit');
                                                }}
                                                validtype="number"
                                                required={'BOOK'===openType?true:false} 
                                                feedid="container"
                                            />
                                        </Col>
                                        <Col lg='6' className="ml-0 pl-0" >
                                        <Select
                                            // isDisabled={true}
                                            className="customSelect"
                                            name="fp_tmp_unit"
                                            value={{value:special.special_fp_tmp_unit?special.special_fp_tmp_unit:'',
                                                    label:special.special_fp_tmp_unit&&(options.tmp_unit.findIndex(x=>x.value===special.special_fp_tmp_unit)>=0)?
                                                        options.tmp_unit[options.tmp_unit.findIndex(x=>x.value===special.special_fp_tmp_unit)].label:
                                                       '선택'
                                            }}
                                            onChange={(e) => fncOnChangeSelect(e.value, 'special_fp_tmp_unit')}
                                            options={options.tmp_unit}
                                        />
                                    </Col>
                                    </Row>
                                </FormGroup>
                            </Col>
                            <Col xl="5" lg="5">
                                <FormGroup>
                                    <Label className="mb-0">P.Group</Label>
                                    <Select
                                        className="customSelect"
                                        name="special_p_group"
                                        value={{value:special.special_p_group?special.special_p_group:'',
                                                label:special.special_p_group&&(options.special_p_group.findIndex(x=>x.value===special.special_p_group)>=0)?
                                                    options.special_p_group[options.special_p_group.findIndex(x=>x.value===special.special_p_group)].label:
                                                    '선택'
                                        }}
                                        onChange={(e) => fncOnChangeSelect(e.value, 'special_p_group')}
                                        options={options.special_p_group}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col xl="3" lg="3">
                        <Row>
                            <Col lg='5'>
                                <FormGroup >
                                    <Label className="mb-0">G.Weight</Label>
                                    <InputValid 
                                        type="text"
                                        name="special_gross_weight"
                                        id="special_gross_weight"
                                        maxLength="19"
                                        value={special.special_gross_weight?special.special_gross_weight:''}
                                        onChange={(e)=>fncOnChange(e, 'special_gross_weight')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="eng"
                                        required={'BOOK'===openType?true:false} 
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg='5'>
                                <FormGroup >
                                    <Label className="mb-0">N.Weight</Label>
                                    <InputValid 
                                        type="text"
                                        name="special_net_weight"
                                        id="special_net_weight"
                                        maxLength="19"
                                        value={special.special_net_weight?special.special_net_weight:''}
                                        onChange={(e)=>fncOnChange(e, 'special_net_weight')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="eng"
                                        required={'BOOK'===openType?true:false} 
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl="2" lg="2" style= {{textAlign : 'left'}}> 
                                <Label className="mb-0">L/Q</Label>
                                <FormGroup check>
                                    <Label check style={{marginBottom: '7px',}}>
                                    <Input type="checkbox"   checked={checkLqYn}
                                    name = 'special_lq_yn'
                                    onChange={()=>changeYn(!checkLqYn)}/>
                                    <span className="form-check-sign" >
                                        <span className = 'check' ></span>
                                    </span>
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col lg='3'>
                        <Row>
                            <Col lg='6'>
                                <FormGroup >
                                    <Label className="mb-0">inner P'kgs</Label>
                                    <InputValid 
                                        type="text"
                                        name="special_inner_pgks"
                                        id="special_inner_pgks"
                                        maxLength="19"
                                        value={special.special_inner_pgks?special.special_inner_pgks:''}
                                        onChange={(e)=>fncOnChange(e, 'special_inner_pgks')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="eng"
                                        required={'BOOK'===openType?true:false} 
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg='6'>
                                <FormGroup >
                                    <Label className="mb-0">outer P'kgs</Label>
                                    <InputValid 
                                        type="text"
                                        name="special_outer_pkgs"
                                        id="special_outer_pkgs"
                                        maxLength="19"
                                        value={special.special_outer_pkgs?special.special_outer_pkgs:''}
                                        onChange={(e)=>fncOnChange(e, 'special_outer_pkgs')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="eng"
                                        required={'BOOK'===openType?true:false} 
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg='4'>
                        <Row>
                            <Col lg='6'>
                                <FormGroup>
                                    <Label className="mb-0">EmS F</Label>
                                    <Select
                                        className="customSelect"
                                        name="special_ems_f"
                                        value={{value:special.special_ems_f?special.special_ems_f:'',
                                                label:special.special_ems_f&&(options.emsList('F').findIndex(x=>x.value===special.special_ems_f)>=0)?
                                                    options.emsList('F')[options.emsList('F').findIndex(x=>x.value===special.special_ems_f)].label:
                                                    '선택'
                                        }}
                                        onChange={(e) => fncOnChangeSelect(e.value, 'special_ems_f')}
                                        options={options.emsList('F')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg='6'>
                                <FormGroup>
                                    <Label className="mb-0">EmS S</Label>
                                    <Select
                                        className="customSelect"
                                        name="special_ems_s"
                                        value={{value:special.special_ems_s?special.special_ems_s:'',
                                                label:special.special_ems_s?(options.emsList('S').findIndex(x=>x.value===special.special_ems_s)>=0)?
                                                    options.emsList('S')[options.emsList('S').findIndex(x=>x.value===special.special_ems_s)].label:
                                                    '선택':'선택'
                                        }}
                                        onChange={(e) => fncOnChangeSelect(e.value, 'special_ems_s')}
                                        options={options.emsList('S')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="2">
                        <FormGroup>
                            <Label className="mb-0">Full/Residue</Label>
                            <Select
                                className="customSelect"
                                name="special_f_r"
                                value={{value:special.special_f_r?special.special_f_r:'',
                                        label:special.special_f_r&&(options.full_residue.findIndex(x=>x.value===special.special_f_r)>=0)?
                                            options.full_residue[options.full_residue.findIndex(x=>x.value===special.special_f_r)].label:
                                            '선택'
                                }}
                                onChange={(e) => fncOnChangeSelect(e.value, 'special_f_r')}
                                options={options.full_residue}
                            />
                        </FormGroup>
                    </Col>
                    <Col lg="3">
                        <Row>
                            <Col xl="5" lg="5">
                                <FormGroup >
                                    <Label className="mb-0">담당자</Label>
                                    <InputValid 
                                        type="text"
                                        name="special_user_name"
                                        id="special_user_name"
                                        maxLength="17"
                                        value={special.special_user_name?special.special_user_name:''}
                                        onChange={(e)=>fncOnChange(e, 'special_user_name')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="eng"
                                        required={'BOOK'===openType?true:false} 
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl="7" lg="7">
                                <FormGroup >
                                    <Label className="mb-0">긴급연락처</Label>
                                    <InputValid 
                                        type="text"
                                        name="special_user_tel"
                                        id="special_user_tel"
                                        maxLength="50"
                                        value={special.special_user_tel?special.special_user_tel:''}
                                        onChange={(e)=>fncOnChange(e, 'special_user_tel')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="eng"
                                        required={'BOOK'===openType?true:false} 
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
              
                <Row>
                    <Col   xl="6" lg="6"> 
                        <FormGroup className="mb-0" >
                            <Label className="mb-0">Line Remark</Label>
                            <Input 
                                style={{lineHeight:'1.4',overflow:'hidden',resize:'unset',paddingRight:'4px',paddingLeft:'4px',textTransform:'uppercase'}}
                                type="textarea"
                                name="special_confirm_remark"
                                id="special_confirm_remark"
                                maxLength="200"
                                value={special.special_confirm_remark?special.special_confirm_remark:''}
                                onChange={(e)=>fncOnChange(e, 'special_confirm_remark')}
                                onBlur={(e) => {fncOnBlur(e)}}
                                validtype="eng"
                                required={'BOOK'===openType?true:false} 
                                feedid="container"
                                // disabled={props.view &&!props.relation ?true:false}
                                // className="border-input"
                                // rows={markData.mark_desc?markData.mark_desc.split("\n").length>6?markData.mark_desc.split("\n").length:9:9}
                                // invalid={}
                                // value={}
                                // onChange={}
                                // onBlur={}
                                />
                            {/* <FormFeedback 
                                feedid="cargo">
                                {!validation.EDICharsetCheck(markData.mark_desc)?validation.EDICheckText(markData.mark_desc):""}
                            </FormFeedback> */}
                        </FormGroup>
                    </Col>
                    <Col xl="6" lg="6"> 
                        <FormGroup className="mb-0" >
                        <Label className="mb-0">Shipper Remark</Label>
                            <Input 
                                style={{lineHeight:'1.4',overflow:'hidden',resize:'unset',paddingRight:'4px',paddingLeft:'4px',textTransform:'uppercase'}}
                                type="textarea"
                                name="special_req_remark"
                                id="special_req_remark"
                                maxLength="200"
                                value={special.special_req_remark?special.special_req_remark:''}
                                onChange={(e)=>fncOnChange(e, 'special_req_remark')}
                                onBlur={(e) => {fncOnBlur(e)}}
                                validtype="eng"
                                required={'BOOK'===openType?true:false} 
                                feedid="container"
                                // disabled={props.view &&!props.relation ?true:false}
                                // className="border-input"
                                // rows={markData.mark_desc?markData.mark_desc.split("\n").length>6?markData.mark_desc.split("\n").length:9:9}
                                // // 
                                // invalid={!validation.EDICharsetCheck(markData.mark_desc)?true:false}
                                // 
                        
                                // value={markData.mark_desc?markData.mark_desc:''}
                                // onChange={(event)=>textareaLimit(event.target.value.replace(/\t/g," "))}
                                // onBlur={onPropsReturn}
                                />
                            {/* <FormFeedback 
                                feedid="cargo">
                                {!validation.EDICharsetCheck(markData.mark_desc)?validation.EDICheckText(markData.mark_desc):""}
                            </FormFeedback> */}
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
