import React,{useState, useEffect} from "react";
// reactstrap components

import { Link, useHistory } from "react-router-dom";

import { Row,Col,Card, Badge} from "reactstrap";
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'
import * as validation from 'components/common/validation.js';
const styles = {
    headerFontStyle:{
      fontSize:'15px',
      color:'#696969',
      fontWeight:'600',
      
    },
    gridTitle:{
      fontSize:'20px',
      color:'#696969',
      fontWeight:'bold'
    },
    progressText:{
      fontSize:'14px',
      color:'black',
      fontWeight:'bold',
    },
    progressTextTime:{
      fontSize:'12px',
      color:'black',
      fontWeight:'bold',
    },
    gridCard:{
      zIndex:0,
      width:'100%',
      padding:'15px'
      // minHeight:'100%'
    },
    gridTitleRow:{
      textAlignLast:'center',
      width:'100%'
    },
    listText:{
      fontSize:'13px',
      color:'#696969',
    }
  
  };

export default function NewRow (props) {
    let history = useHistory();
    const {userData} = props;
    const [value, setValue] = useState(props.parameter!==null?props.parameter:[]);
    const [state, setState] = useState(props.state!==null?props.state:null);
    const [viewWidth,setViewWidth] = useState(props.viewWidth!==null?props.viewWidth:'12.5%');
    useEffect(() => {
        setValue(props.parameter!==null?props.parameter:[]);
        setState(props.state!==null?props.state:null);
        setViewWidth(props.viewWidth!==null?props.viewWidth:'12.5%');
        
    },[props]);

    const onSubmit = (data , type )=>{
        if( 'W' === data.bkg_type &&data.user_no !== userData.user_no){
            props.onAlert("error", '위동 선사는 작성 유저만 조회가능합니다.');
            return false;
        }
        
        switch(type) {
        case 'BKG': 
            history.push({
                pathname: data.bkg_type && 
                'W' === data.bkg_type
                ?`/svc/bookingWdfc`
                :'E'=== data.bkg_type
                    ?'/svc/bkgPlism':`/svc/booking`,
                state:{
                    bkg_no: data.bkg_no?data.bkg_no:null,
                    user_no: data.user_no?data.user_no:null,
                    bkg_date: data.bkg_date?data.bkg_date:null,
                    line_code: data.line_code||null,
                    new_yn:'N'
                }
            });
            break;
        case 'CONFIRM':
            // {pathname: `/svc/confirm`, 
            // state:{user_no:value.p.user_no,
            //  res_bkg_no:value.p.res_bkg_no, 
            //  res_confirm_date:value.p.res_confirm_date}}}>
            history.push({
                pathname: data.bkg_type && 
                'W' === data.bkg_type
                ?`/svc/confirmWdfc`
                :'E'=== data.bkg_type
                    ?'/svc/confirmPlism':`/svc/confirm`,
                state:{
                    user_no: data.user_no?data.user_no:null,
                    res_bkg_no: data.res_bkg_no?data.res_bkg_no:null,
                    res_confirm_date: data.res_confirm_date?data.res_confirm_date:null,
                    line_code: data.line_code||null
                }
            });
            break;
        case 'SR':
            if("N" === data.sr_eshipping_use_yn){
                props.onAlert("error",`SR ${validation.NOT_SERVE_CARRIER}`);
                return false;
            } 
            history.push({
                pathname: data.bkg_type && 
                'W' === data.bkg_type
                ?`/svc/srWDFC`
                :'E'=== data.bkg_type
                    ?'/svc/srPlism':`/svc/sr`,
                state:{
                    res_bkg_no: data.res_bkg_no?data.res_bkg_no:null,
                    user_no: data.user_no?data.user_no:null,
                    sr_no:data.sr_no?data.sr_no:null, 
                    sr_date:data.sr_date?data.sr_date:null,
                    line_code: data.line_code||null,
                    confirm_yn:'Y'
                }
            });
            break;
        case 'BL':
            if("N" === data.sr_eshipping_use_yn){
                props.onAlert("error",`SR ${validation.NOT_SERVE_CARRIER}`);
                return false;
            } 
            history.push({
                pathname: data.bkg_type && 
                'W' === data.bkg_type
                ?`/svc/blWDFC`
                :'E'=== data.bkg_type
                    ?'/svc/blPlism':`/svc/bl`,
                state:{
                    user_no:data.user_no, 
                    mbl_no:data.mbl_no, 
                    issue_date:data.issue_date,
                    line_code: data.line_code||null
                }
            });
        break;
        default : return ;
        } 
    }

    return(
        <Row xl="12" lg="12" md="12" sm="12" xs="12">
            <Card className="no-transition" style={styles.gridCard}>
                <Row xl="12" lg="12" md="12" sm="12" xs="12" style={{margin:'1em 0'}} className="border solid 1px">
                {/* BKG SAVE 단계 */}
                {state.save &&
                    <Col style={{maxWidth:viewWidth}} className={value.p.status1===null?"step":"next"} >
                        {(value.p.user_no !== null&&value.p.bkg_no!==null&&value.p.bkg_date!=null&&value.p.status1!==null)?(
                        <>
                        <div onClick={()=>onSubmit(value.p, 'BKG')} style ={{cursor:'pointer'}}> 
                            <Row>
                                <Col className="text-center" >
                                    {/* <i className={value.p.status1===null?"fa fa-floppy-o fa-3x text-secondary":"fa fa-floppy-o fa-3x text-primary"}/> */}
                                    {/* props.logoImgList.length>0
                                &&(confirm.sch_line_code
                                    ?props.logoImgList.find(x=>x.sch_line_code==confirm.sch_line_code) */}
                                    <LoadCarrierImage classStyle={"img-rounded img-responsive"} width={42} height={40} 
                                    line_code= {value.p.line_code} 
                                    logo= {props.logoImgList.find (x=>x.line_code ==value.p.line_code)?.line_logo_img||''} 
                                    kr_name={props.logoImgList.length>0 &&value.p.line_code
                                        ?(props.logoImgList.find(x=>x.line_code==value.p.line_code)).line_kr_name
                                        :''}
                                />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Badge color="primary">BKG SAVE</Badge>
                                </Col>
                            </Row>
                        </div>        
                        <Row style={{lineHeight:'14px',marginTop:'5px'}}>
                            <Col className="text-center">
                                <span style={styles.progressText}>{value.p.bkg_no}</span>
                            </Col>
                            <Col className="text-center">
                                <span style={styles.progressTextTime}>{value.p.status1}</span>
                            </Col>
                        </Row>
                        </>):
                        (<>
                        <Row>
                            <Col className="text-center" style={{marginRight:'18px'}}>
                                <i className={value.p.status1===null?"fa fa-floppy-o fa-3x text-secondary":"fa fa-floppy-o fa-3x text-primary"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Badge color="primary">BKG SAVE</Badge>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <span style={styles.progressText}>{value.p.bkg_no}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <span style={styles.progressText}>{value.p.status1}</span>
                            </Col>
                        </Row>
                        </>)}
                    </Col>}
        
                    {/* BKG SEND 단계 */}
                    {state.send &&
                    <Col style={{maxWidth:viewWidth}} className={value.p.status2===null?"step":"next"} >
                        {(value.p.user_no !== null&&value.p.bkg_no!==null&&value.p.bkg_date!=null&&value.p.status2!==null)?(
                        <>
                        <div onClick={()=>onSubmit(value.p, 'BKG')} style ={{cursor:'pointer'}}> 
                            <Row>
                                <Col className="text-center" style={{marginRight:'18px'}}>
                                    <i className={value.p.status2===null?"fa fa-paper-plane-o fa-3x text-secondary":"fa fa-paper-plane-o fa-3x text-primary"}/>   
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Badge className="ml-2 mr-2" color="primary">BKG SEND</Badge>&nbsp;<Badge color="primary">{value.p.status_cnt2}</Badge> 
                                </Col>
                            </Row>
                        </div>
                        <Row style={{lineHeight:'14px',marginTop:'5px'}}>
                            <Col className="text-center">
                                <span style={styles.progressText}>{value.p.bkg_no}</span>
                            </Col>
                            <Col className="text-center">
                                <span style={styles.progressTextTime}>{value.p.status2} </span>
                            </Col>
                        </Row>
                        </>
                        ):(
                            <>
                                <Row>
                                    <Col className="text-center" style={{marginRight:'18px'}}>
                                        <i className={value.p.status2===null?"fa fa-paper-plane-o fa-3x text-secondary":"fa fa-paper-plane-o fa-3x text-primary"}/>  
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Badge className="ml-2 mr-2" color="primary">BKG SEND</Badge>
                                        <Badge color="primary">{value.p.status_cnt2}</Badge>
                                    </Col>
                                </Row>
                            </>)}
                    </Col>}
                    {/* BKG 컨펌단계 */}
                    {state.confirm &&
                    <Col style={{maxWidth:viewWidth}} className={value.p.status3===null?"step":"next"}>
                        {(value.p.user_no !== null&&value.p.res_bkg_no!==null&&value.p.res_confirm_date!=null&&value.p.status3!==null)? (
                            <>
                            <div onClick={()=>onSubmit(value.p, 'CONFIRM')} style ={{cursor:'pointer'}}> 
                                <Row>
                                    <Col className="text-center" style={{marginRight:'18px'}}>
                                        <i className={
                                            value.p.status_name3==="Confirm"?"fa fa-check text-primary fa-3x"
                                            :value.p.status_name3==="Reject"?"fa fa-ban text-danger fa-3x"
                                            :value.p.status_name3==="Cancel"?"fa fa-ban text-danger fa-3x"
                                            :"fa fa-ellipsis-h fa-3x text-secondary"}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Badge color="primary">{value.p.status_name3}</Badge>
                                    </Col>
                                </Row>
                            </div>
                            <Row style={{lineHeight:'14px',marginTop:'5px'}}>
                                <Col className="text-center">
                                    <span style={styles.progressText}>{value.p.res_bkg_no}</span>
                                </Col>
                                <Col className="text-center">
                                    <span style={styles.progressTextTime}>
                                        {value.p.status3} 
                                    </span>
                                </Col>
                            </Row>
                            {/* {(value.p.status_name3==="Confirm" && value.p.sr_no === null ) &&
                            <Row>
                                <Col className="text-center"  onClick={()=>onSubmit(value.p, 'SR')} style ={{cursor:'pointer'}}> */}
                                    {/* <Link 
                                        to={{
                                            pathname: `/svc/srWdfc`, 
                                            state:{
                                                res_bkg_no: value.p.res_bkg_no?value.p.res_bkg_no:null,
                                                user_no: value.p.user_no?value.p.user_no:null,
                                                sr_no:value.p.sr_no?value.p.sr_no:null, 
                                                sr_date:value.p.sr_date?value.p.sr_date:null,
                                                confirm_yn:'Y'
                                                }
                                            }}> */}
                                        {/* <Badge className="ml-2 mr-2">SR 작성</Badge> */}
                                    {/* </Link> */}
                                {/* </Col>
                            </Row>} */}
                        </>):(<>
                            <Row>
                                <Col className="text-center" style={{marginRight:'18px'}}>
                                    <i className="fa fa-ellipsis-h fa-3x text-secondary"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Badge color="primary">WAITING FOR CONFIRM</Badge>
                                </Col>
                            </Row>
                        </>)}
                    </Col>}
                    {/* PICK UP 단계 */}
                    {state.pickup &&
                    <Col style={{maxWidth:viewWidth}} className={value.p.pick_up_time===null?"step":"next"}>
                        <Row>
                            <Col className="text-center" style={{marginRight:'18px'}}>
                                <i className={value.p.pick_up_time===null?"fa fa-truck fa-3x text-secondary":"fa fa-truck fa-3x text-primary"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Badge className="ml-2 mr-2" color="primary">EMPTY PICK UP</Badge>
                            </Col>
                        </Row>
                        {value.p.pick_up_time!==null &&
                        <Row>
                            <Col className="text-center">
                                <span style={styles.progressText}>{value.p.pick_up_time_f}</span>
                            </Col>
                        </Row>}
                    </Col>}
                    {/* DROP OFF 단계 */}
                    {state.drop &&
                    <Col style={{maxWidth:viewWidth}} className={value.p.drop_off_time===null?"step":"next"}>
                        <Row>
                            <Col className="text-center" style={{marginRight:'18px'}}>
                                <i className={value.p.drop_off_time===null?"fa fa-ship fa-3x text-secondary":"fa fa-ship fa-3x text-primary"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Badge className="ml-2 mr-2" color="primary">DROP OFF</Badge>
                            </Col>
                        </Row>
                        {value.p.drop_off_time!==null &&
                        <Row>
                            <Col className="text-center">
                                <span style={styles.progressText}>{value.p.drop_off_time_f}</span>
                            </Col>
                        </Row>}
                    </Col>}
                     {/* SR 저장단계 */}
                    {state.srsave &&
                    <Col style={{maxWidth:viewWidth}} className={value.p.sr_no === null?"step":"next"}>
                        {(value.p.user_no !== null&&value.p.sr_no!==null)?(
                            <>
                            <div  onClick={()=>onSubmit(value.p, 'SR')}  style ={{cursor:'pointer'}}>
                                <Row>
                                    <Col className="text-center" style={{marginRight:'18px'}} >
                                        <i className={value.p.sr_no === null?"fa fa-floppy-o fa-3x text-secondary":"fa fa-floppy-o fa-3x text-primary"}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <Badge className="ml-2 mr-2" color="primary">SR SAVE</Badge>
                                    </Col>
                                </Row>
                            </div>
                            <Row style={{lineHeight:'14px',marginTop:'5px'}}>
                                <Col className="text-center" >
                                    <span style={styles.progressText}>{value.p.sr_no}</span>
                                </Col>
                                <Col className="text-center">
                                    <span style={styles.progressTextTime}>{value.p.sr_date}</span>
                                </Col>
                            </Row>
                        </>):(<>
                        <Row>
                            <Col className="text-center" style={{marginRight:'18px'}}>
                                <i className={value.p.sr_no === null?"fa fa-floppy-o fa-3x text-secondary":"fa fa-floppy-o fa-3x text-primary"}/>
                            </Col>
                        </Row>
                        {(value.p.status_name3==="Confirm" && value.p.sr_no === null) ?
                        <Row style={{lineHeight:'14px',marginTop:'5px'}}>
                            <Col className="text-center">
                                <Badge className="ml-2 mr-2" color="success"  style={{cursor:'pointer'}} onClick={()=>onSubmit(value.p, 'SR')}>SR 작성하기</Badge>
                            </Col>
                        </Row>:
                        <Row>
                            <Col className="text-center">
                                <Badge className="ml-2 mr-2" color="primary">SR SAVE</Badge>
                            </Col>
                        </Row>
                        }     
                        </>  )}
                    </Col>}
                    {/* SR 전송단계 */}
                    {state.srsend &&
                    <Col style={{maxWidth:viewWidth}} className={value.p.status4===null?"step":"next"}>
                        {(value.p.user_no !== null&&value.p.sr_no!==null&&value.p.sr_date!=null&&value.p.status4!==null)?(
                        <>
                        <div onClick={()=>onSubmit(value.p, 'SR')} style ={{cursor:'pointer'}}> 
                            <Row>
                                <Col className="text-center" style={{marginRight:'18px'}}>                                    
                                    <i className={value.p.status4===null?"fa fa-file-text-o fa-3x text-secondary":"fa fa-file-text-o fa-3x text-primary"}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Badge className="ml-2 mr-2" color="primary">SR SEND</Badge><Badge color="primary">{value.p.status_cnt4}</Badge>
                                </Col>
                            </Row>
                        </div>
                        <Row style={{lineHeight:'14px',marginTop:'5px'}}>
                            <Col className="text-center">                                
                                <span style={styles.progressText}>{value.p.sr_no}</span>
                            </Col>
                            <Col className="text-center">
                                <span style={styles.progressTextTime}>
                                    {value.p.status4} 
                                </span>
                            </Col>
                        </Row>
                    </>):(
                    <>
                        <Row>
                            <Col className="text-center" style={{marginRight:'18px'}}>
                                <i className={value.p.status4===null?"fa fa-file-text-o fa-3x text-secondary":"fa fa-file-text-o fa-3x text-primary"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Badge className="ml-2 mr-2" color="primary">SR SEND</Badge>
                            </Col>
                        </Row>
                    </>)}
                    </Col>}
                    {/* BL단계 */}
                    {state.bl &&
                    <Col style={{maxWidth:viewWidth}} className={value.p.status5===null?"step ":"next"}>
                    {(value.p.user_no !== null&&value.p.mbl_no!==null&&value.p.issue_date!==null&&value.p.status5!==null)?(
                        <>
                        <div onClick={()=>onSubmit(value.p, 'BL')} style ={{cursor:'pointer'}}> 
                            <Row>
                                <Col className="text-center" style={{marginRight:'18px'}}>
                                    <i className={value.p.status5===null?"fa fa-ellipsis-h fa-3x text-secondary":"fa fa-check fa-3x text-primary"}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Badge className="ml-2" color="primary">BL</Badge>
                                </Col>
                            </Row>
                        </div>
                            <Row style={{lineHeight:'14px',marginTop:'5px',}}>
                                <Col className="text-center">
                                    <span style={styles.progressText}>{value.p.mbl_no}</span>
                                </Col>
                                <Col className="text-center">
                                    <span style={styles.progressTextTime}>
                                    {value.p.status5} 
                                    </span>
                                </Col>
                            </Row>
                        </>):(
                        <>
                            <Row>
                                <Col  className="text-center" style={{marginRight:'18px'}}>
                                    <i className={value.p.status5===null?"fa fa-ellipsis-h fa-3x text-secondary":"fa fa-check fa-3x text-primary"}/>
                                </Col>
                            </Row>
                            <Row  style={{lineHeight:'14px',marginTop:'5px'}}>
                                <Col  className="text-center" >
                                    <Badge  className="ml-2 mr-2"  color="primary">BL</Badge>
                                </Col>
                            </Row>
                        </>)}
                    </Col>}
                   {/*mfcs단계 */}
                   {state.mfcs &&
                    <Col style={{maxWidth:viewWidth}} className={value.o.length === 0?"laststep":"lastnext"}>
                        <Row>
                            <Col className="text-center" style={{marginRight:'18px'}}>
                                <i className={value.o.length === 0?"fa fa-clipboard fa-3x text-secondary":"fa fa-clipboard fa-3x text-primary"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <Badge className="ml-2 mr-2" color="primary">MFCS</Badge>
                            </Col>
                        </Row>
                        {(value.o.length > 0 ) &&
                        <Row>
                            <Col className="text-center">
                                <span style={styles.progressText}>{value.o[0].DPT_DATE_X?value.o[0].DPT_DATE_X:""}</span>
                            </Col>
                        </Row>}
                        {(value.o.length > 0 ) &&
                        <Row>
                            <Col>
                                <span style={styles.progressText}>{value.o[0].STATUS_NAME?value.o[0].STATUS_NAME:""}</span>
                            </Col>
                        </Row>}
                    </Col>}
                </Row>
            </Card>
        </Row>
    )
}
