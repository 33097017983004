/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row,Col, FormGroup,Input,Card,CardHeader,CardBody,FormFeedback} from "reactstrap";
//import AlertModal from 'components/Modals/Alert.js';
import axios from 'axios';
//import InputValid from "components/CustomInput/InputValid.js";
import Select from "react-select";
import * as validation from 'components/common/validation.js';
export default function Goods(props) {
	
	const {goods,bookmark3, triggerNew} = props;	
	const [goodsData, setGoodsData] = useState({});
	const [cardHeight, setCardHeight] = useState('220px');
	const [inputValue, setInputValue] = useState("");
	let msgSvcCode = (serviceCode)=>{
		if((serviceCode === "1" || serviceCode === "11")) {
			return `SHIPPER'S LOAD. COUNT & SEAL\nSAID TO CONTAIN :\n`;
		}else if( (serviceCode === "3" || serviceCode === "13")){
			return `SAID TO CONTAIN :\n`;
		}else if( (serviceCode === "89" || serviceCode === "99")){
			return `SAID TO BE :\n`;
		}else {
			return '';
		}
	}
	useEffect(() => {
		setGoodsData(goods);
	},[goods]);

	useEffect(()=> {
		if(goodsData.trans_service_code !== '89') onPropsReturn();
	},[goodsData.trans_service_code]);
	
	useEffect(()=> {
		if(goodsData.goods_desc) {
			if(goodsData.trans_service_code) {
				let msg = msgSvcCode(goodsData.trans_service_code);
				setInputValue(goodsData.goods_desc.replaceAll(msg,''));
				// if((goodsData.trans_service_code === "1" || goodsData.trans_service_code === "11")) {
				// 	setInputValue(goodsData.goods_desc.replaceAll(`SHIPPER'S LOAD. COUNT & SEAL\nSAID TO CONTAIN :\n`,''))
				// }else if( (goodsData.trans_service_code === "3" || goodsData.trans_service_code === "13")){
				// 	setInputValue(goodsData.goods_desc.replaceAll(`SAID TO CONTAIN :\n`,''))
				// }else {
				// 	setInputValue(goodsData.goods_desc);
				// }
			}else {
				setInputValue(goodsData.goods_desc);
			}
		}else {
			setInputValue("")
		}
			if(goodsData.trans_service_code) {
				if(goodsData.goods_desc) {
					if((goodsData.trans_service_code==="3" || goodsData.trans_service_code==="13")) {
						if(goodsData.goods_desc) {
							setCardHeight('180px');
						}else {
							setCardHeight('180px');
						}
						///2줄
					}else if((goodsData.trans_service_code==="1"||goodsData.trans_service_code==="11")){
						if(goodsData.goods_desc) {
							setCardHeight('160px');
						}else {
							setCardHeight('160px');
						}
					}else {
						if(goodsData.goods_desc) {
							setCardHeight('200px');
						}else {
							setCardHeight('200px');
						}
					}
				}else {
					setCardHeight('200px');
				}
			}else {
				setCardHeight('200px');
			}
		},[goodsData.trans_service_code, goodsData.goods_desc])
		  const onHandleReturnVal = (event,name) => { 
			let list = {...goodsData, [name]:(event.target.value.toUpperCase())||null};
			setGoodsData(list);
		}
	  
		const onPropsReturn = ()=> {
			let list = goodsData;
			let goods = list.goods_desc;
	
			if(goods) {
				var goodsSplit = goods.split('\n');
				goods = goodsSplit.join('\n');
				var goodsDesc = goods.split('\n');
				list = {...list, goods_desc:goodsDesc.join('\n')};	
	
			} else {
				// list = {...list, goods_desc:"SHIPPER'S LOAD. COUNT & SEAL\nSAID TO CONTAIN :\n"};
				list = {...list, goods_desc:""};
			}
			props.propsGoodsData(list);  
		}

	const onChangeGoods =(value)=> {
		if(value) {
			if(value.value > 0) {
				axios.post("/shipper/getUserGoodsBookmark",{user_no:props.userData?props.userData.user_no:'',seq:value.value}).then(res => { 
					if(props.type!=="BOOKMARK"){
						var list;
						let msg = msgSvcCode(goodsData.trans_service_code);
						list = {...res.data[0],
							goods_desc: msg +res.data[0].goods_desc
						};
						// if(("1" === goodsData.trans_service_code || "11" === goodsData.trans_service_code) ) {
						// 	list = {...res.data[0],goods_desc:"SHIPPER'S LOAD. COUNT & SEAL\nSAID TO CONTAIN :\n"+res.data[0].goods_desc};
						// } else if( ("3" === goodsData.trans_service_code || "13" === goodsData.trans_service_code) ) {
						// 	list = {...res.data[0],goods_desc:"SAID TO CONTAIN :\n"+res.data[0].goods_desc};
						// } else {
						// 	list = res.data[0];
						// }
						props.propsGoodsData(list);
					}else {
						props.propsGoodsData(res.data[0]);
					}
					
				});
			} else {
				setGoodsData([]);
			}
		}else {
			if(props.type!=="BOOKMARK"){
				var list;
				let msg = msgSvcCode(goodsData.trans_service_code);
				list = {...goodsData,
						goods_desc: msg,
						cargo_goods_bookmark_seq:null,
						cargo_goods_bookmark_name:null,};
				// if( ("1" === goodsData.trans_service_code || "11" === goodsData.trans_service_code) ) {
				// 	list = {
				// 		...goodsData,
				// 		goods_desc:"SHIPPER'S LOAD. COUNT & SEAL\nSAID TO CONTAIN :\n",
				// 		cargo_goods_bookmark_seq:null,
				// 		cargo_goods_bookmark_name:null,};
				// } else if( ("3" === goodsData.trans_service_code || "13" === goodsData.trans_service_code) ) {
				// 	list = {
				// 		...goodsData,
				// 		goods_desc:"SAID TO CONTAIN :\n",
				// 		cargo_goods_bookmark_seq:null,
				// 		cargo_goods_bookmark_name:null,
				// 	};
				// } else {
				// 	list = {
				// 		...goodsData,
				// 		'goods_desc':null,
				// 		'cargo_goods_bookmark_seq':null,
				// 		'cargo_goods_bookmark_name':null
				// 	}
				// }
				setInputValue("")
				setGoodsData(list);
				props.propsGoodsData(list);
			}else {
				setInputValue("")
				setGoodsData({...goodsData,
					'goods_desc':null,
					'cargo_goods_bookmark_seq':null,
					'cargo_goods_bookmark_name':null});
				props.propsGoodsData({...goodsData,
					'goods_desc':null,
					'cargo_goods_bookmark_seq':null,
					'cargo_goods_bookmark_name':null});
			}
		}
	}
  
	const textareaLimit = (text) => {
		let msg = msgSvcCode(goodsData.trans_service_code);

		if(!text) { 
			props.propsGoodsData({...goodsData, goods_desc:msg});
			// if( ("1" === goodsData.trans_service_code || "11" === goodsData.trans_service_code) ) {
			// 	props.propsGoodsData({...goodsData, goods_desc:"SHIPPER'S LOAD. COUNT & SEAL\nSAID TO CONTAIN :\n"});
			// } else if( ("3" === goodsData.trans_service_code || "13" === goodsData.trans_service_code) ){
			// 	props.propsGoodsData({...goodsData, goods_desc:"SAID TO CONTAIN :\n"});
			// }
		}
		
		let lines = text.split('\n');
		for(let i=0;i<lines.length;i++) {
			if( validation.getByte(lines[i]) <= 80)  continue;
				let j=0;
				const lineLen = lines[i].length;
				let space = lineLen;
				while(j++ <= lineLen) {
					var lengthData= lines[i].substring(0,j);
					if(validation.getByte(lengthData) <= 80) { 
						space = lengthData.length;
					}
				}
				lines[i+1] = lines[i].substring(space+1) + (lines[i+1] || "");
				lines[i]=lines[i].substring(0,space);
		}
		setGoodsData({...goodsData, goods_desc: msg+lines.join('\n')});
		// if( ("1" === goodsData.trans_service_code || "11" === goodsData.trans_service_code) ){
		// 	setGoodsData({...goodsData, goods_desc:"SHIPPER'S LOAD. COUNT & SEAL\nSAID TO CONTAIN :\n"+lines.join('\n')});
		// }else if ( ("3" === goodsData.trans_service_code || "13" === goodsData.trans_service_code) ) {
		// 	setGoodsData({...goodsData, goods_desc:"SAID TO CONTAIN :\n"+lines.join('\n')});
		// }else {
		// 	setGoodsData({...goodsData, goods_desc:lines.join('\n')});
		// }
		setInputValue(text)
	}
  
	return (
		<>
			<Card className="no-transition mb-2" style={{border:'1px solid silver'}}>
			{(!props.relation) &&
				<CardHeader className="pt-1 pb-1">
					<Row>
						<Col xl="6" className="col-12">
							<Select
								className="customSelect bg-white"
								name="cargo_goods_bookmark_seq"
								value={{value:goodsData.cargo_goods_bookmark_seq?goodsData.cargo_goods_bookmark_seq:'',
								label:goodsData.cargo_goods_bookmark_seq?
									(bookmark3.findIndex(x=>x.value===goodsData.cargo_goods_bookmark_seq)>=0)?
											bookmark3[bookmark3.findIndex(x=>x.value===goodsData.cargo_goods_bookmark_seq)].label:
										'선택':'선택'
								}}
								onChange = {(value)=>onChangeGoods(value,'cargo_goods_bookmark_seq')}
								options={bookmark3}
								isClearable={goodsData.cargo_goods_bookmark_seq?true:false}/>

						</Col>
					</Row>
				</CardHeader>}
				{goodsData.trans_service_code && props.type!=="BOOKMARK" &&
				<CardHeader className="pt-0 pb-0">
					<Row>
						<Col xl="6" className="col-12">
							{goodsData.goods_desc && goodsData.goods_desc.split("\n").map((data,key) => {
									return (
										<div key={key}>
											{
											((goodsData.trans_service_code ==="1"||goodsData.trans_service_code ==="11")
											?(key < 2) 
											:(goodsData.trans_service_code ==="3" || goodsData.trans_service_code ==="13"|| goodsData.trans_service_code ==="89"|| goodsData.trans_service_code ==="99")
											?(key < 1):''
											)&& 
												<Row>
													<Col xl="0" className="col-0 pl-0 pr-0 text-center" style={{width:'30px'}}>
														<div key={"gh_"+key}style={{backgroundColor:key+1<16?'yellow':'white',height:'19.5px'}}>{key+1}</div>
													</Col>
													<Col>
														<span key={"th_"+key}>{data}</span>
													</Col>
												</Row>
											}
										</div>	
									)
								// if((goodsData.trans_service_code ==="1"||goodsData.trans_service_code ==="11")) {
								// 	return (
								// 		<div key={key}>
								// 			{(key < 2) && 
								// 				<Row>
								// 					<Col xl="0" className="col-0 pl-0 pr-0 text-center" style={{width:'30px'}}>
								// 						<div key={"gh_"+key}style={{backgroundColor:key+1<16?'yellow':'white',height:'19.5px'}}>{key+1}</div>
								// 					</Col>
								// 					<Col>
								// 						<span key={"th_"+key}>{data}</span>
								// 					</Col>
								// 				</Row>
								// 			}
								// 		</div>
										
								// 	)
								// }else if((goodsData.trans_service_code ==="3" || goodsData.trans_service_code ==="13")) {
								// 	return (
								// 		<div key={key}>
								// 		{(key < 1) && 
								// 			<Row key={key}>
								// 				<Col xl="0" className="col-0 pl-0 pr-0 text-center" style={{width:'30px'}}>
								// 					<div key={"gh_"+key} style={{backgroundColor:key+1<16?'yellow':'white',height:'19.5px'}}>{key+1}</div>
								// 				</Col>
								// 				<Col>
								// 					<span key={"th_"+key}>{data}</span>
								// 				</Col>
								// 			</Row>
								// 		}
								// 		</div>
								// 	)
								// }else {

								// }
							})}
						</Col>
					</Row>
				</CardHeader>}
				<CardBody className="pt-2 pb-3">
					<Row style={{height:cardHeight,overflow:'auto'}}>
						<Col xl="0" className="col-0 pl-0 pr-0 text-center" style={{paddingTop:'10px',width:'30px', display:'flex', flexDirection:'column', justifyContent:'space-between', paddingBottom:'10px'}}>
						{inputValue && inputValue.split("\n").map((data,key) => {
							const tansCode = goodsData.trans_service_code;
							const backgroundLength = (tansCode==="3" || tansCode==="13"||tansCode==="89"||tansCode==="99")?15:(tansCode==="1"||tansCode==="11")?14:16
							
							return (
								<div key={"g_"+key}style={{backgroundColor:key+1<backgroundLength?'yellow':'white',height:'19.5px'}}>
									{(tansCode==="13" || tansCode==="3"||tansCode==="89"||tansCode==="99")?key+2
									:(tansCode==="1" || tansCode==="11")?key+3
									:key+1}
								</div>
							)
						})}
						</Col>
						<Col className="p-0">
							<FormGroup className="mb-0" >
								<Input 
									style={{lineHeight:'1.4',overflow:'hidden',resize:'unset',paddingRight:'4px',paddingLeft:'4px',textTransform:'uppercase'}}
									disabled={props.view &&!props.relation ?true:false} 
									invalid={!props.view && !inputValue?true:(!validation.EDICharsetCheck(inputValue) || !validation.koreanBan(inputValue) ||(inputValue&&inputValue.split("\n").length)>495 )?true:false}
									className="border-input"
									rows={inputValue?inputValue.split("\n").length>6?inputValue.split("\n").length:8:8}
									type="textarea"
									value={inputValue||''}
									onChange={(event)=>textareaLimit(event.target.value.replace(/\t/g," "))}
									onBlur={onPropsReturn}/>
								{/* <FormFeedback feedid="cargo">{validation.REQ_MSG}</FormFeedback> */}
								<FormFeedback feedid="cargo">{
									!validation.EDICharsetCheck(inputValue)
									?validation.EDICheckText(inputValue)
									:!validation.koreanBan(inputValue)
										?validation.KOREAN_BAN_MSG
										:(inputValue&&inputValue.split("\n").length)>495 
												?'글자 수가 허용 범위를 초과하였습니다.'
												:validation.REQ_MSG}
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<div>line:{inputValue?inputValue.split("\n").length:0} {' / '}
						 byte:{validation.getByte(inputValue?inputValue.split("\n")[inputValue?inputValue.split("\n").length-1:0]:'')}
					</div>
				</CardBody>
			</Card>
		</>
	);
}

