import axios from "axios";
import _ from 'lodash';

//공통
export const ynList = [{value:'Y', label:'Y'},{value:'N', label:'N'}];
export const getLabel =  (option, obj, key  ,directI) => {
    // console.log(obj, obj[key],key,directI, !_.isEmpty(obj) ,option)
    let label ;
    if(obj.constructor ==Object && !_.isEmpty(obj)){
            const findIdx = option.findIndex(x=>x.value == obj[key]);    
            label= findIdx >-1 ? option[findIdx].label : false;
    }
    return label ||false;
}   

//BookingCard
export const consolYnList = [{value:null ,label:'N (default)'},{value:'C',label:'Y'}]
export const paymentList=[{value: 'P', label: 'PREPAID'},{value: 'C', label: 'COLLECT'}]
//Cntr
export const specialType = [{value : 'OG' , label : '(OOG) Out of Gauge'},{value : 'IG' , label : 'In Gauge'}];
export const stowageCell = [{value : '01' , label : '[01] On Deck'}
                            ,{value : '02' , label : '[02] Under Deck'}
                            ,{value : '03' , label : '[03] Hot Deleivery'}
                            ,{value : '04' , label : '[04] Top Stowage'}
                            ,{value : '05' , label : '[05] Avoid Boiler'}
                            ,{value : '06' , label : '[06] Bundle'}
                            ,{value : '07' , label : '[07] Damaged'}];
export const CommodityItem = [{value : '209' , label : '건축/설비'}
                            ,{value : '211' , label : '고지(폐지)'}
                            ,{value : '208' , label : '기계류'}
                            ,{value : '220' , label : '기타'}];
export const ventList = [ {label:'Full close',value:'FC'},
                            {label:'Not close',value:'NC'},
                            {label:'Full open',value:'FO'},
                            {label:'10% open',value:'10O'},
                            {label:'20% open',value:'20O'},
                            {label:'30% open',value:'30O'},
                            {label:'40% open',value:'40O'},
                            {label:'50% open',value:'50O'},
                            {label:'60% open',value:'60O'},
                            {label:'70% open',value:'70O'},
                            {label:'80% open',value:'80O'},
                            {label:'90% open',value:'90O'},
                        ]

export const selectLineCodeCntrSztp = (params,lineCode) => {
    axios.post("/shipper/selectLineCodeCntrSztp",{ params,lineCode:lineCode }).then(res => {
        console.log(res.data)
       return res.data;
    });
}

//special
export const tmp_unit = [ {label:'F',value:'F'},
                            {label:'C',value:'C'}]

//{[{key:'1', value:'Great danger'},{key:'2', value:'Medium danger'},{key:'3', value:'Minor danger'}]}
export const special_p_group = [{label:'III',value:'01'},
                        {label:'II',value:'02'},
                        {label:'I',value:'03'},
                        {label:'-',value:'04'},
                        ]
export const dangerSearchOption =[{label:'위험물 코드',value:'undg_code'},
                                    {label:'IMDG 코드',value:'imdg_code'},
                                    {label:'위험물 TYPE',value:'code_class'},
                                    {label:'위험물 영문명',value:'undg_desc'},
                                    {label:'위험물 한글명',value:'undg_kr_desc'}
                                ]
export const emsList = (code) => {
        const list =  [ {label : 'A', value: 'A'}, 
                        {label : 'B', value: 'B'}, 
                        {label : 'C', value: 'C'}, 
                        {label : 'D', value: 'D'}, 
                        {label : 'E', value: 'E'}, 
                        {label : 'F', value: 'F'}, 
                        {label : 'G', value: 'G'}, 
                        {label : 'H', value: 'H'}, 
                        {label : 'I', value: 'I'}, 
                        {label : 'J', value: 'J'}, 
                        {label : 'K', value: 'K'}, 
                        {label : 'L', value: 'L'}, 
                        {label : 'M', value: 'M'}, 
                        {label : 'N', value: 'N'}, 
                        {label : 'O', value: 'O'}, 
                        {label : 'P', value: 'P'}, 
                        {label : 'Q', value: 'Q'}, 
                        {label : 'R', value: 'R'}, 
                        {label : 'S', value: 'S'}, 
                        {label : 'T', value: 'T'}, 
                        {label : 'U', value: 'U'}, 
                        {label : 'V', value: 'V'}, 
                        {label : 'W', value: 'W'}, 
                        {label : 'X', value: 'X'}, 
                        {label : 'Y', value: 'Y'}, 
                        {label : 'Z', value: 'Z'}
                        ]
    return code =='F'?  list.slice(0,10) : list;
}
export const full_residue = [ {label:'full',value:'01'},
                            {label:'residue',value:'02'}];

 // 업종구분(C:운송사 G:일반부두 S:선사 A:검수 P:유관기관 T:터미널 H:화주)[{label:'운송사', value :'C'},
export const comClassOptions=[   
    {label:'운송사', value :'C'},
    {label:'일반부두', value :'G'},
    {label:'선사', value :'S'},
    {label:'화주', value :'H'},
    {label:'터미널', value :'T'},
    {label:'유관기관', value :'P'},
    {label:'기타', value :'A'},
    ];

export const bankCode=[   
    {label:'산업은행', value:'002'},
    {label:'기업은행', value:'003'},
    {label:'국민은행', value:'004'},
    {label:'외환은행', value:'005'},
    {label:'국민은행(구주택은행)', value:'006'},
    {label:'수협중앙회 및 단위조합', value:'007'},
    {label:'중앙회농협', value:'011'},
    {label:'단위농협', value:'012'},
    {label:'축협중앙회', value:'016'},
    {label:'단위축협', value:'017'},
    {label:'우리은행', value:'020'},
    {label:'신한은행(구조흥은행)', value:'021'},
    {label:'제일은행', value:'023'},
    {label:'하나은행(구서울은행)', value:'025'},
    {label:'신한은행', value:'026'},
    {label:'씨티은행(구한미은행)', value:'027'},
    {label:'대구은행', value:'031'},
    {label:'부산은행', value:'032'},
    {label:'광주은행', value:'034'},
    {label:'제주은행', value:'035'},
    {label:'전북은행', value:'037'},
    {label:'경남은행', value:'039'},
    {label:'새마을금고연합회', value:'045'},
    {label:'신용협동중앙회', value:'048'},
    {label:'상호저축은행', value:'050'},
    {label:'씨티은행', value:'053'},
    {label:'홍콩상하이', value:'054'},
    {label:'우체국(정보통신부)', value:'071'},
    {label:'하나은행', value:'081'},
    {label:'하나은행(구보람은행)', value:'082'},
    {label:'평화은행', value:'083'},
    ];