import React, { useCallback, useEffect ,useState, useRef} from 'react';

import _ from 'lodash';
import cn from 'classnames'
// import PropTypes from "prop-types";
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
// import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Backdrop , Modal } from '@material-ui/core';
// core components
import SweetAlertCustom from 'muiComponents/CustomAlert/CustomSweetAlert.js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import LoginPage from 'views/Login/LoginPage.js';
import routes from 'service_routes.js';
import axios from 'axios';
// import styles from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.js';
import { observer, useObserver } from 'mobx-react-lite';
import { userStore } from 'store/userStore.js';
import GlobalAlert from 'components/Alert/GlobalAlert.js';
// import AlertMessage from 'muiComponents/Alert/AlertMessage.js';
import AlertNotiMessage from 'muiComponents/Modals/Alert.js';
import Header from 'muiComponents/headerNew/header'
import Footer from 'muiComponents/footerNew/footer'
import globalStyle from'../styles/global.module.css'
import {Container} from "reactstrap";

import FindInfoPage from "views/Member/FindInfoPage.js";
import VerifyApplication from 'pages/popup/VerifyApplication.js';
import VerifyAdmin from 'pages/popup/VerifyAdmin'
import * as validation from 'muiComponents/common/validation.js';
import { menuAccessLog } from 'muiComponents/common/menuAccess';
import {setCookie, getCookie, removeCookie} from 'muiComponents/common/cookie.js';

// const useStyles = makeStyles(styles);
const useStyles2 = makeStyles((theme)=>({
  modal:{
      display:'flex'
      ,alignItems:'center'
      ,justifyContent:'center'
  }
   ,root:{
    display:'flex'
    ,justifyContent:'center'
    ,flex:'none'
  }
  ,form:{minWidth:'600px', width:'750px'}
  ,findForm:{width:'40%'}
}))

let SERVICE = process.env.REACT_APP_SERVICE;
let PLISM_URL =process.env.REACT_APP_ESERVICE_URL;
const MenuNoSetter = observer(({menu, children}) => {
  useEffect(()=>{
    console.log(menu)
    let menuNo = SERVICE == 'PROD' ? menu?.prodNo : menu?.devNo;
    userStore.currentMenu =menuNo;
  },[menu]);
  return children;
});


const ServiceLayout = (props) => {
  require( "assets/scss/material-dashboard-pro-react.scss");
  const { ...rest } = props;

  const footerRef = useRef();
  const [lockKlnetId, setLockKlnetId] = useState(null);

  const [open, setOpen] = React.useState(false);
  const [viewAlert, setViewAlert] = React.useState(null);
  // 위동 ALERT
  const [openAlert, setOpenAlert] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [alertStyle, setAlertStyle] = React.useState('');
  const [alertTime, setAlertTime] = React.useState(3000);
  
  // 위동 notiAlert
  const [openNotiMessage, setOpenNotiMessage] = React.useState('');
  const [ modalOpen,setModalOpen ]=useState(false);
  const [ modalContent,setModalContent ]=useState( '')

  //logo이미지
  const [ logoImgList, setLogoImgList]=useState([]);


  const classes = useStyles2();
  
  const userStoreMenu = useObserver(() => {
    return userStore.menu;
  });

  const setUserData = (data) => {
    userStore.user = data;
  };
  
  const userData = useObserver(() => {
    return userStore.user;
  });
  
  useEffect(() => {
    //이미지가져오기
    getCarrierLogo();
    return ()=>{
      console.log('clean')
    }
  }, []);
  
  const handleVerifyModal = () =>{
    if(userData&&(userData.comp_verify_type =='A' ||userData.comp_verify_type =='B'||userData.comp_verify_type =='C'||userData.comp_verify_type =='X')
    ||!userData&&lockKlnetId
      ){
        setModalContent('VerifyApplication');
        setModalOpen(true);
      }else if(userStore.isAuth?.result){
        if(getCookie('ctfyRes')){ removeCookie('certifyUser')}
        setModalContent('VerifyAdmin');
        setModalOpen(true);
      }else{
        setModalContent('');
        setModalOpen(false);
      }
  }

  const isLogOut = useCallback(() => {
    if(userStore.user)userStore.logout();
    alertMessage('로그아웃 되었습니다.', 'success');
  }, [userStore]);

  const authLoading = useObserver(() => {
    return userStore.loading;
  });
  
  // useEffect(() => {
  //   // userStore.setLoading=true;
  //   let str = window.location.pathname;
  //   let nameStr = getActiveRoute(routes);
  //   if( str === "/blgate/sch/terminalSchedule.do") {
  //     str="/svc/cal";
  //     nameStr="터미널 스케줄";
  //   }
  //   let last_char = str.charAt(str.length - 1);
  //   if (last_char !== '#') {
  //     axios.post('/api/menuAccessLog', {
  //       user: userData ? userData.user_no : 'GUEST',
  //       path: window.location.pathname,
  //       name: nameStr,
  //       systemName: 'BOOKINGSERVICE',
  //     });
  //   }

  //   if(window.location.pathname){
  //     axios.post('/api/oraMenuAccessLog', {user: userData ? userData.user_no : 'GUEST',pathName : window.location.pathname});
  //   } 

  //   // userStore.setLoading=false;

  //   // return ()=>{userStore.setLoading =false;} 
  // }, [window.location.pathname]);

  const getCarrierLogo = async()=>{
    axios.post(`/api/getCarrierLogo`,{})
    .then(res => { 
        res.data.length>0
        ?setLogoImgList(res.data)
        :  setLogoImgList([])
    }).catch(err => {
        // if(err.response.status !== 403||err.response.status !== 401) {
            // console.log('[오류]', 'error',err);
            setLogoImgList([])
        // }
    });    
  }

  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps';
  };
  // const getActiveRoute = (routes) => {
  //   let activeRoute = '-';
  //   for (let i = 0; i < routes.length; i++) {
  //     if (routes[i].collapse) {
  //       let collapseActiveRoute = getActiveRoute(routes[i].views);
  //       if (collapseActiveRoute !== activeRoute) {
  //         return collapseActiveRoute;
  //       }
  //     } else {
  //       if (
  //         window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
  //       ) {
  //         return routes[i].name;
  //       }
  //     }
  //   }
  //   return activeRoute;
  // };
  // let windowPopObj = null;
  const onOpenHandle = () => {
    if (!userStore.getLoading && userData) {
      // console.log('onOpenHandle getLoading >>>>>>>>>',);
    } else if (!userStore.getLoading && !userData) {
      // console.log(validation.NOTLOGIN_MSG)
      onAlert('error',validation.NOTLOGIN_MSG);
    }
  };
  // const menuAccessLog = async (value) => {<<er
  //   // console.log(value)
  //   try {
  //     await axios.post('/api/oraMenuAccessLog', {layout:value.layout,path:value.path,menuNo:value.menuNo,menuNmKr:value.menuNmKr,plismMenuNo:value.plismMenuNo,user: userData ? userData.user_no : 'GUEST',});

  //     const result = await axios.post('/api/menuAccessLog', {
  //       user: userData ? userData.user_no : 'GUEST',
  //       path: value.layout ? value.layout + value.path : value.path,
  //       name: value.name ? value.name : value,
  //       systemName: 'BOOKINGSEVICE',
  //     });
  //     // console.log(result)
  //   } catch (e) {
  //     // console.log(e);
  //   }
  // };
  
  const checkOptionPage= (element)=>{
    if(element&&element.option1 =='Y'
    &&(userData&&(userData.comp_verify_type =='C')
    // &&(userData&&(userData.comp_verify_type =='A' ||userData.comp_verify_type =='B'||userData.comp_verify_type =='C'||userData.comp_verify_type =='X')
    ||!userData&&lockKlnetId)
    ){ //유료 정보받기
        onAlert('error', '해당 서비스는 과금 서비스 입니다. \n사용을 위해서는 가입 신청서를 제출해주세요(문의:1577-1172)',5000);
        handleVerifyModal();
        return false;
    }else if (element&&element.option2 =='Y'){
        onAlert('error', validation.E1007,5000);
    }else{ return true}
 }

  const goPlism = (param, options) => {
    // console.log(param);
    let linkParam = param?.linkParam||param||null;
    const logout_use_flag = options?.logout_use_flag ||null;
    if(userData?.user_no ) {
      let menuNo = SERVICE == 'PROD' ? linkParam.prodNo : linkParam.devNo;
      if(!menuNo){return}
      const carrier_code = options&&options.carrier_code ||null;
      const queryStr = options&&options.id ||null;
      const userNo = userData?.user_no || 'GUEST';
      axios
        .get('/auth/readyplism')
        .then((res) => {
          if (res.data.auth_code) {
            let plismUrl = `${PLISM_URL}/uat/uia/ownerMain.do?id=${userNo}&auth_code=${res.data.auth_code}&linkMenuNo=${menuNo}${carrier_code?`&ownLineCode=${carrier_code}`:''}${queryStr?`&${queryStr}`:''}`
            window.open(plismUrl, '_plism');
            const value = { path: `plism.com/${menuNo}`, plismMenuNo:menuNo };
            menuAccessLog(value, userData||'GUEST');
          } else {
            onAlert( 'error',validation.NO_PERMISSION_MSG);
          }
        })
        .catch((err) => {
          isLogOut();
        });
    } else if( logout_use_flag =='Y'){
      let pathUrl=param?.path||null;
      if(!pathUrl){return}
        window.open(
          // ` ${PLISM_URL}/uat/uia/plism3Sub.do?menu_no=${menuNo}`,
          ` ${PLISM_URL}${pathUrl}`,
          '_plism'
        );
      const value = { path: pathUrl, plismMenuNo:param?.menuNo ||param?.name};
      menuAccessLog(value, {user_id :'GUEST'});
    }else {
      onAlert( 'error', validation.NOTLOGIN_MSG);
    }
  };

  const getRoutes = (routes) => {
    // console.log('getRoutes',userStore.loading);
    if(!userStore.isAuth){
      return routes.map((prop, key) => {
        if (prop.collapse) {
          // console.log("prop.views   ",prop.views)
          return getRoutes(prop.views);
        }

        if ((prop.layout === '/svc' || prop.layout === '/blgate')) {
          
          // if(prop.id)console.log("prop.layout > ",prop.layout + prop.path + '/:id',prop.component)
          return (
          
            <Route
              path={prop.id? 
                  prop.layout + prop.path +'/:id'
                  :prop.layout + prop.path 
                }
              // path={prop.layout + prop.path + prop.id?'/:id':''}
              //component={prop.component}
              render={() => (
                <MenuNoSetter menu ={prop.menuNo||''} >
                <prop.component
                  openLogin={onOpenHandle}
                  loginClose={(event) => handleLoginClose(event)} //user={userData}
                  userData={userData}
                  isLogOut={isLogOut}
                  authLoading={userStore.loading}
                  alert={(
                    onConfirm,
                    title,
                    type,
                    show,
                    reverseButtons,
                    btnSize,
                    subtitle,
                    showConfirm,
                    showCancel,
                    onCancel
                  ) =>
                    onSetAlertStatus(
                      onConfirm,
                      title,
                      type,
                      show,
                      reverseButtons,
                      btnSize,
                      subtitle,
                      showConfirm,
                      showCancel,
                      onCancel
                    )
                  }
                  onAlert={onAlert}
                  alertMessage={alertMessage}
                  fncClickMenu={fncClickMenu}
                  onNotiAlert={onNotiAlert}
                  logoImgList={logoImgList}
                  goPlism={goPlism} 
                  userStoreMenu={userStoreMenu}
                  checkOptionPage={checkOptionPage}
                  mode ={prop?.mode ||''}
                  componentMenuNo = {prop?.menuNo||''}
                  {...rest}
                /> 
                </MenuNoSetter>
              )}
              key={key}
            />
          );
        }else {
          return null;
        }
      });
    }
  };
  // const sidebarMinimize = () => {
  //   setMiniActive(!miniActive);
  // };
  // const resizeFunction = () => {
  //   if (window.innerWidth <= 960) {
  //     setMobileOpen(false);
  //   }
  // };

  const handleLoginClose = (user) => {
    setOpen(false);
    //console.log("popup close:",user);
    if (user) {
      // setIsAuthenticated(true);
      setUserData(user);
    } else {
      // setIsAuthenticated(false);
      setUserData([]);
    }
    //setIsAuthenticated(true);
    //setUserData(user);
  };

  const onSetAlertStatus = (
    onConfirm,
    title,
    type,
    show,
    reverseButtons,
    btnSize,
    subtitle,
    showConfirm,
    showCancel,
    onCancel
  ) => {
    if (show) {
      setViewAlert(
        <SweetAlertCustom
          onConfirm={onConfirm ? onConfirm : null}
          ontitle={title ? title : ''}
          type={type ? type : 'default'}
          show={show ? true : false}
          reverseButtons={reverseButtons ? reverseButtons : false}
          btnSize={btnSize ? btnSize : 'lg'}
          subtitle={subtitle ? subtitle : null}
          showConfirm={showConfirm ? showConfirm : false}
          showCancel={showCancel ? showCancel : false}
          onCancel={onCancel ? onCancel : null}
        />
      );
    } else {
      setViewAlert(null);
    }
  };

  // //기존 Alert (GlobalAlert)
  const alertMessage = (message,status,time, style) => {
    setOpenAlert(true);
    setStatus(status);
    setMessage(message);
    if(time)setAlertTime(time);
    if(style)setAlertStyle(style);
  };

  // 위동 Alert -> message, status 순서가 다름 
  const onAlert = (status, message,time, style) => {
    setOpenAlert(true);
    setStatus(status);
    setMessage(message);
    if(time)setAlertTime(time);
    if(style)setAlertStyle(style);
  };
  
  const onNotiAlert = (status, meassge) => {
    setOpenNotiMessage(true);
    setStatus(status);
    setMessage(meassge);
    setTimeout(function() {
      setOpenNotiMessage(false);
    }, 3500);
  };
  const fncOpenMessage = (open) => {
    setOpenNotiMessage(open);
  };

  const fncClickMenu = (params) => {};
  const handleLockId = (e)=>{
    return false;
  }

  return (
    <div className={globalStyle.container_servicePage}  id="general">
      <div style={{ backgroundColor: '#FFF' }}>
      <Header   onAlert={onAlert}  userData={userData} isLogOut={isLogOut} 
      goPlism={goPlism} // menuAccessLog={menuAccessLog} 
      setModalOpen={(e)=>setModalOpen(e)} setModalContent={(e)=>setModalContent(e)} 
      alertMessage={alertMessage}
      alert={(
        onConfirm,
        title,
        type,
        show,
        reverseButtons,
        btnSize,
        subtitle,
        showConfirm,
        showCancel,
        onCancel
      ) =>
        onSetAlertStatus(
          onConfirm,
          title,
          type,
          show,
          reverseButtons,
          btnSize,
          subtitle,
          showConfirm,
          showCancel,
          onCancel
        )}
        handleLockId={handleLockId}
        handleVerifyModal={handleVerifyModal}
        checkOptionPage={checkOptionPage}
      />
      {/* <div className={mainPanelClasses} ref={mainPanel}> */}
        {/* <ServiceNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          // isAuthenticated={isAuthenticated}
          // onLoginPageOpen={()=>setOpen(true)}
          authLoading={authLoading}
          onLoginPageOpen={onOpenHandle}
          userData={userData}
          isLogOut={isLogOut}
          {...rest}
        /> */}
    {/* <div className={globalStyle.servicePageWrap}> */}
    <Container>
    {/* {( <>  */}
        {!userStore.isAuth&&!userStore.loading ? (
          getRoute() ? (
            // <div className={classes.content} style={{ padding: '0px' }}>
            <div style={{ padding: '0px', minHeight: "calc(100vh - 123px)" ,width:'100%'}}>
              {/* <div className={classes.container}> */}
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from="/svc" to="/" />
                </Switch>
                {viewAlert}
              </div>
            // </div>
          ) : (
            <div //className={classes.map}
            >
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/svc" to="/" />
              </Switch>
            </div>
          )
        ) : (
          // <div className={classes.content} style={{ padding: '0px' }}>
          <div style={{ padding: '0px', minHeight: "calc(100vh - 123px)" ,width:'100%'}}>
            {/* <div className={classes.container}> */}
              <div
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  zIndex: '100',
                }}
                open={true}
              >
                <CircularProgress />
              </div>
              {viewAlert}
            </div>
          // </div>
        )}  
        {/* </>)}    */}
        </Container>
    
    {/* footer--- */}
      <Footer  goPlism={goPlism} ref={footerRef}/>
    {/* footer--- */}
  

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent style={{ maxWidth: '400px', minWidth: '350px' }}>
          <LoginPage onClose={(event) => handleLoginClose(event)} />
        </DialogContent>
      </Dialog>
      
{/* onNotiAlert */}
      <AlertNotiMessage
        open={openNotiMessage}
        close={() => setOpenNotiMessage(!openNotiMessage)}
        status={status === 'success' ? status : 'danger'}
        message={message}
        fncOpenMessage={fncOpenMessage}
      />

{/* 모달 */}
      <Modal
        className={classes.modal}
        open={modalOpen}
        onClose={() =>  modalContent!=='VerifyApplication'?setModalOpen(false):''}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{timeout:500}}
      >
        <DialogContent className={cn(classes.root , modalContent=='VerifyApplication'?classes.form:classes.findForm)} > 
          {modalContent=='FindInfoPage'&&<FindInfoPage toggle={() => {setModalOpen(false); setModalContent(null);}}
               onAlert={onAlert}
               alertMessage={alertMessage}
          />}
          {modalContent=='VerifyApplication'&&<VerifyApplication 
                                                toggle={() => {setModalOpen(false); setModalContent(null)}}
                                                userData={userData}
                                                onAlert={onAlert}
                                                onHandleFooterAgree={(v)=>footerRef.current.handleClickOpen(v)}
                                                lockKlnetId={lockKlnetId}
                                                />} 
        </DialogContent>
      </Modal>
      {modalContent=='VerifyAdmin'
              &&<Modal
                className={classes.modal}
                open={modalOpen}
                onClose={(event, reason)=>{
                  if(reason=='backdropClick' ){return }
                }}
                // onClose={{event: ()=>{setModalOpen(false); setModalContent(null); userStore.logout(); }, reason: 'backdropClick' }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout:500}}>
                  <DialogContent className={cn(classes.root , modalContent=='VerifyApplication'?classes.form:classes.findForm)} > 
                    <VerifyAdmin
                      toggle={() => {setModalOpen(false); setModalContent(null); userStore.logout(); }}
                      userData={userData}
                      onAlert={onAlert}
                      onHandleFooterAgree={(v)=>footerRef.current.handleClickOpen(v)}
                      lockKlnetId={lockKlnetId}
                      />
                  </DialogContent>
                </Modal>}   
{/* ----*/}
    </div>

{/* onAlert */}
      <GlobalAlert
        message={message}
        isOpen={openAlert}
        isClose={() => setOpenAlert(false)}
        alertColor={status || 'danger'} //선택사항  //primary, secondary, success, danger, warning, info, light, dark
        alertTime={alertTime||3000}
        alertStyle={alertStyle}
      ></GlobalAlert>
    </div>
    )
};

export default observer(ServiceLayout);
