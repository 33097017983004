import {makeAutoObservable, observable, action, configure, } from "mobx"
import _ from 'lodash';
import queryString from 'query-string';

configure({enforceActions:'never'});

class WebLinkStore {
    loading = false;
    webLinkMode= false;
    rolePath= '';
    
    constructor() {
        makeAutoObservable(this, {
            webLinkMode: observable,
            rolePath:observable,
            loading:observable,
            setting: action,
            wlSessionOut: action,
            wlVerifyUser:action,
        },{autoBind:true})
    }

    wlSessionOut(){
        //웹링크 세션아웃 (로그아웃)
    }

    setting(){
        let pathname = window.location.pathname ;
        let search =  window.location.search ;
        console.log(' pathname : ',window.location.pathname)
        console.log(' search : ', window.location.search)
        if(pathname.indexOf('/webLinkSvc')!==0){
            webLinkStore.webLinkMode = false; 
            return false;
        }else{
            const queryObj = queryString.parse(search);
            console.log(queryObj)
            let auth = queryObj['auth']||'';
            if(!auth){return false}

            webLinkStore.webLinkMode = true; 
            webLinkStore.serviceId= queryObj['auth']||'';
            webLinkStore.rolePath= pathname;
            return true;
        }
    }
// if(userData?.role=='WL'){if(pathname.indexOf('/webLinkSvc')!==0)userStore.logout();}
    get getLoading(){
        return this.loading
    }
    set setLoading(e){
        this.loading = e ;
        return this.loading
    }
}

const webLinkStore =  new WebLinkStore();

export {webLinkStore};
