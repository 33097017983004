/**
* excel_Sheet.js
* 엑셀 시트 정의
**/
import {toDateParse, isBeforeDateParse} from './ExpTrackingHeader.js'

export  const  excelSheet = (function () {
	const obj = {}
    
    obj.getList2016Excel = function getList2016Excel() {
    	let sheetnm = '수출화물추적';	// 시트명
        
		// 컬럼 설정
		// header: 엑셀에 표기되는 이름
		// key: 컬럼을 접근하기 위한 key
		// hidden: 숨김 여부
		// width: 컬럼 넓이
		let columns = [
			{  key:'A',		width:13.5  }
			,{ key:'B',		width:13.5  }
			// ,{ key:'C',		width:8.5   }
			,{ key:'D',	  width:8.75  }
			,{ key:'G',		width:11  }
			,{ key:'H',		width:8   }//
			,{ key:'I',		width:12.88 }
			,{ key:'J',		width:12.75 }
			,{ key:'K', 	width:12.88 }
      ,{ key:'E',		width:15   }
			,{ key:'F',		width:6.5   }
			,{ key:'L', 	width:12.13 }
			,{ key:'M', 	width:6.5  }
			,{ key:'N',   width:12.13 }
			,{ key:'O',   width:12.13 }//
			,{ key:'P', 	width:5.5  }
			,{ key:'Q', 	width:8.0   }
			,{ key:'R', 	width:6.5   }
			,{ key:'S', 	width:12.13 }
			,{ key:'T', 	width:12.13 }
			,{ key:'U', 	width:12.13 }
			,{ key:'V', 	width:12.13 }//
			,{ key:'W', 	width:5.5  }
			,{ key:'X', 	width:8.0   }
			,{ key:'Y', 	width:6.5   }
			,{ key:'Z', 	width:12.13 }
			,{ key:'AA', 	width:12.13 }
			,{ key:'AB', 	width:12.13 }
			,{ key:'AC', 	width:12.13 }//
			,{ key:'AD', 	width:5.75  }
			,{ key:'AE', 	width:12.13 }
			,{ key:'AF', 	width:12.13 }
			,{ key:'AK', 	width:5.5  }//f dest
			,{ key:'AI', 	width:12.88 }//inland DEPARTURE
			,{ key:'AJ', 	width:12.88 }//inland arrival
			,{ key:'AG', 	width:12.13 }//full ter
			,{ key:'AH', 	width:15.5 }//full gate
			,{ key:'AL', 	width:12.13 }//empty ter
			,{ key:'AM', 	width:12.13 }//empty gate
			,{ key:'AN', 	width:12.13 }
			,{ key:'AO', 	width:12.13 }
		];        

		// header
		let headerA1 = [
      'SHIPPING INFORMATION', '', '', '', '', 
      'ORIGIN', '', '', '', '', '', '','', '',
      'TS PORT', '', '', '', '', '', '', 
      'TS 2 PORT', '', '', '', '', '', '', 
      'DESTINATION', '', '', '', '', '', '', '', '','',
      'OTHERS'
		];

		let headerA2 = [
      'MBL No.', 'HBL No.',// 'EX/IMPORT', 
      'CARRIER', 'CNTR No.', 'SIZE/TYPE',
      'EMPTY TERMINAL', 'EMPTY GATE OUT', 'FULL TERMINAL', 'FULL GATE IN ', 'POL', 'VESSEL', 'VOYAGE', 'ETD', 'ATD',
      'PORT', 'VESSEL', 'VOYAGE', 'ETA', 'ATA', 'ETD', 'ATD', 
      'PORT', 'VESSEL', 'VOYAGE', 'ETA', 'ATA', 'ETD', 'ATD', 
      'POD', 'ETA', 'ATA', 'F.DEST', 'INLAND DEPARTURE', 'INLAND ARRIVAL', 'FULL TERMINAL', 'FULL GATE OUT', 'EMPTY TERMINAL', 'EMPTY GATE IN', 
      'UPDATE Date', 'Ref No.'
		];

		let header = {
			"A1" : headerA1,
			"A2" : headerA2,
			"cnt" : 2,
			"descCnt" : 0
		}

		// merge 할 header 정보
		let merge_list = [
		  'A1:E1',
		  'F1:N1',
      'O1:U1',
      'V1:AB1',
      'AC1:AL1',
      'AM1:AN1',
		];

		// 컬럼 기본값
		let cellDefault = {
			// 조건식 필드
			exprField: [],
			// 기본값
			defaultValue: [
				{ range:'1:40',value: 0 }
			],
			// 상위 column 과 같은 값은 안보이게
			rowspan: [
				// { range:'1:2' },
			],
			// 시트 탭 color
			tabColor: //sum ? '92D050' :
      'FFC000',
			// 시트 틀고정
			// frozen: {state: 'frozen', xSplit: 3, ySplit: 8}
		};    
        
		// 시트 정보를 return
		let sheetInfo = {
			'sheetnm' : sheetnm,
			'columns' : columns,
			'merge'	  : merge_list,
			'cellDefault': cellDefault,
			'header'  : header,
			// 'fnNm'    : arguments.callee.name
		};

		return sheetInfo;        
    }
  
	return {
		/** public methods **/
		initObj: obj
	}
})();  


	/**
	* make_excel
	* 엑셀 파일을 생성한다.
	* @param {Object} workbook 워크북 개체
	* @param {String} sheetInfo 시트 정보 개체
	* @param {Object} data json data
	**/
	export const make_excel = (workbook, sheetInfo, data) =>{
    var sheet = workbook.addWorksheet(sheetInfo.sheetnm);	// 시트 추가
    let columns = sheetInfo.columns;						// 컬럼 정보를 가져온다.
    let headerDescCnt = sheetInfo.header.descCnt;			// 설명컬럼 줄수      
    
    //*** header setting ***
    // sheetInfo 에서 header 정보를 가져와서 header 를 만든다.
    // 설명 헤더 만들기
    // for (let i=0; i <= 0 ; i++ ) {
    //   let header = eval(`sheetInfo.header.A0`);

    //   for (let j=0; j <= header.length-1; j++) {
    //     sheet.addRow( header[j] );

    //     let headerRow = sheet.getRow(j);

    //     // header cell 스타일 설정
    //     headerRow.eachCell((cell, colNum) => {
    //       setHeaderStyle(cell, true);	// header 의 기본 스타일을 설정한다
    //     });
    //   }
    // }

    // 실제 헤더 만들기
    for (let i=1; i <= sheetInfo.header.cnt ; i++ ) {
      let header = eval(`sheetInfo.header.A${i}`);

      sheet.addRow(header);

      let headerRow = sheet.getRow(i + headerDescCnt); // 설명컬럼 row 수만큼 더한다.

      // header cell 스타일 설정
      headerRow.eachCell((cell, colNum) => {
        let HEAD_GBN = 0;
        if ( colNum <=5 ){HEAD_GBN=1}
        else if ( colNum <=14 ){HEAD_GBN=2}
        else if ( colNum <=21 ){HEAD_GBN=3}
        else if ( colNum <=28 ){HEAD_GBN=4}
        else if ( colNum <=38 ){HEAD_GBN=5}
        else if ( colNum <=40 ){HEAD_GBN=6}
        setHeaderStyle(cell, false, HEAD_GBN);	// header 의 기본 스타일을 설정한다
      });
    }

    // header merge
    for (let merge of sheetInfo.merge) {
      sheet.mergeCells(merge);
    }

    // 시트 설정(시트탭 color, 틀 고정)
    setSheetStyle(sheet, sheetInfo.cellDefault);
    
    //-----------------------------------------------
    // data cell
    //-----------------------------------------------
    sheet.columns = columns;
    // 컬럼 count
    let columnCnt = sheet.columnCount;
    let headerCnt = sheetInfo.header.cnt;
    
    // 각 Data cell에 데이터 삽입 및 스타일 지정
    data.map((item, index) => {
      let {mbl_no, hbl_no,  customs_line_code, vessel_name, voyage_no, cntr_no,sz_tp,
                  e_gate_out_time, f_gate_in_time, pol, pol_name, etd, atd,
                  ts_pol_code_1, ts_pol_name_1, ts_vessel_name_1, ts_voyage_no_1, ts_eta_1, ts_ata_1, ts_etd_1, ts_atd_1,
                  ts_pol_code_2, ts_pol_name_2, ts_vessel_name_2, ts_voyage_no_2, ts_eta_2, ts_ata_2, ts_etd_2, ts_atd_2,
                  pod, pod_name, eta, ata, f_gate_out_time,trans_code,  departure_time, arrival_time, fod, e_gate_in_time,
                  status_time, e_gate_out_terminal, f_gate_in_terminal, f_gate_out_terminal, e_gate_in_terminal, ref_content}= item;

      if(trans_code =='T'){trans_code= 'TRUCK'}
      else if(trans_code =='B'){trans_code= 'BARGE'}
      else if(trans_code =='R'){trans_code= 'RAIL'}
      else { trans_code =''}

      let bindArry = [mbl_no, hbl_no, customs_line_code,cntr_no,sz_tp,
        e_gate_out_terminal, toDateParse(e_gate_out_time), f_gate_in_terminal, toDateParse(f_gate_in_time),
        pol||pol_name, vessel_name, voyage_no, toDateParse(etd), isBeforeDateParse(atd),
        ts_pol_code_1||ts_pol_name_1, ts_vessel_name_1, ts_voyage_no_1, toDateParse(ts_eta_1), isBeforeDateParse(ts_ata_1), toDateParse(ts_etd_1), isBeforeDateParse(ts_atd_1),
        ts_pol_code_2||ts_pol_name_2, ts_vessel_name_2, ts_voyage_no_2, toDateParse(ts_eta_2), isBeforeDateParse(ts_ata_2), toDateParse(ts_etd_2), isBeforeDateParse(ts_atd_2),
        pod||pod_name, toDateParse(eta), isBeforeDateParse(ata), 
        fod, toDateParse(departure_time) , toDateParse(arrival_time), 
        f_gate_out_terminal, toDateParse(f_gate_out_time), e_gate_in_terminal,toDateParse(e_gate_in_time),
        toDateParse(status_time), ref_content];
                
      bindArry= bindArry.map (x=>x?x:'-'); //공백은 '-'로 치환
      let row = sheet.addRow(bindArry);
      
      row.height = 21;
      let HEAD_GBN = item?.HEAD_GBN ||'2';	// row color 를 설정하기 위해 HEAD_GBN 를 가져온다.				
      
      // 컬럼 설정
      for (let loop = 1; loop <= columnCnt; loop++) {
        let col = sheet.getRow(index + headerCnt +1 + headerDescCnt).getCell(loop);
        setCellStyle(col, HEAD_GBN, sheetInfo.cellDefault, sheet, row._number);	// cell 의 기본 스타일을 설정한다
        // console.log('col >>',  col?.value,col?.value.length , columns[loop-1]);
        let defaultWidth = columns[loop-1]?.width ||8;
        if(col?.value.length > defaultWidth )columns[loop-1]['width']=col?.value.length;
        // console.log('col >>',  col?.value,col?.value.length , columns[loop-1]);
        // let cell_val = cell._column._number;	
      }
    });      
    
  }

/**
* setHeaderStyle
* 헤더 스타일 정의
* @param {Object} cell 셀
* @param {Boolean} bDesc 헤더 중 설명헤더, true 이면 설명헤더 이다
**/
export const setHeaderStyle = (cell, bDesc, HEAD_GBN) => {
  try {
    // 설명 header
    if (bDesc) {
      cell.fill = {
        type	: "pattern",
        pattern	: "solid",
        fgColor	: { argb: "ffFFFFFF" }
      };
      cell.font = {
        name: "malgun gothic",
        size: 12,
        bold: true
      };
      cell.alignment = {
        vertical: "left",
        horizontal: "left",
        wrapText: false
      };
    // 실제 header
    } else {
      // HEAD_GBN 에 따라서 color 를 설정한다.
      if (HEAD_GBN) {
        let argb = setColStyle(cell, HEAD_GBN) ;
        cell.fill = {
          type	: "pattern",
          pattern	: "solid",
          fgColor	: { argb: argb }
        };
      }

      cell.border = {
        top		: { style:'thin', color:{argb:'FFA2A2A2'} }
        ,left	: { style:'thin', color:{argb:'FFA2A2A2'} }
        ,bottom	: { style:'thin', color:{argb:'FFA2A2A2'} }
        ,right	: { style:'thin', color:{argb:'FFA2A2A2'} }
      };
      cell.font = {
        name: "malgun gothic",
        size: 8,
        bold: true
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true
      };
    }			
  }
  catch (err) { console.log('Error >>', err); }
};

/**
* setCellStyle
* 각 셀의 스타일 및 기본값을 정의
* @param {Object} cell 셀
* @param {String} HEAD_GBN HEAD_GBN 값
* @param {Object} cellDefault cell 기본값정보 및 기타 정보
* @param {Object} sheet 현재 시트
* @param {Int}	  row_num 현재 row number
**/
const setCellStyle = (cell, HEAD_GBN, cellDefault, sheet, row_num) => {
  try {
    // 일반 컬럼 스타일
    let cell_index = cell._column._number;	// 현재 cell index

    cell.fill = {
      type	: "pattern",
      pattern	: "solid",
      fgColor	: { argb: "ffFFFFFF" }
    };
    cell.border = {
      top		: { style:'thin', color:{argb:'FFA2A2A2'} }
      ,left	: { style:'thin', color:{argb:'FFA2A2A2'} }
      ,bottom	: { style:'thin', color:{argb:'FFA2A2A2'} }
      ,right	: { style:'thin', color:{argb:'FFA2A2A2'} }
    };

    cell.font = {
      name: "malgun gothic",
      size: 8
    };

    // 컬럼설정, 스타일설정에 값이 있는지 확인해서 적용한다.		
    if (!cell.alignment ) {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true
      };
    } else {
      cell.alignment = {
        vertical: "middle",
        wrapText: true
      };				
    }

    // HEAD_GBN 에 따라서 row 의 color 를 설정한다.
    // if (HEAD_GBN) {
    //   let argb = setRowStyle(cell, HEAD_GBN);
    //   cell.fill = {
    //     type	: "pattern",
    //     pattern	: "solid",
    //     fgColor	: { argb: argb }
    //   };
    // }

    // 기본값 설정, 숫자컬럼에 null 일때 0으로 or 미리 설정해놓은 조건식
    if (cellDefault) {
      let defVal = cellDefault.defaultValue;	// 기본값
      let exprField = cellDefault.exprField;	// 조건식 필드
      // rowspan 값이 있으면 가져오기 없으면 1,2 열을 기본으로 한다.
      let rowspan = cellDefault.rowspan ? cellDefault.rowspan : [{ range:'1:2' }] ;		// rowspan

      // 조건식 필드
      for (let i=0; i <= exprField.length-1; i++) {
        let defaultValue = exprField[i];

        if (defaultValue) {
          let startCell_idx = defaultValue.range.split(':')[0];	// cell range 시작 index
          let endCell_idx = defaultValue.range.split(':')[1];		// cell range 종료 index

          if (cell_index >= startCell_idx && cell_index <= endCell_idx ) {
            //console.log(`defaultValue: ${defaultValue}`);

            if (cell.value) {
              cell.value = eval(defaultValue.expr);
            }
          }
        }
      };

      // 기본값
      for (let i=0; i <= defVal.length-1 ; i++ ) {
        let defaultValue = defVal[i];

        if (defaultValue) {
          let startCell_idx = defaultValue.range.split(':')[0];
          let endCell_idx = defaultValue.range.split(':')[1];						

          if (cell_index >= startCell_idx && cell_index <= endCell_idx ) {							
            if (cell.value == null || cell.value == '' || cell.value == undefined ) {
              cell.value = defaultValue.value							
            }
          }
        }
      };

      // rowspan 필드
      for (let i=0; i <= rowspan.length-1 ; i++ ) {
        let rowspanInfo = rowspan[i];

        if (rowspanInfo) {
          let startCell_idx = rowspanInfo.range.split(':')[0];
          let endCell_idx = rowspanInfo.range.split(':')[1];

          if (cell_index >= startCell_idx && cell_index <= endCell_idx ) {
            // 이전 row 가져오기
            let _row = sheet.getRow(row_num-1);

            // 이전 값과 같으면 안보이게 font color를 배경색과 같게 한다.
            if (cell.value == _row.getCell(cell_index).value ) {
              let fgcolor = cell.fill.fgColor.argb ? cell.fill.fgColor.argb : 'aaFFFFFF';

              cell.font = {color: {argb: fgcolor}};
            }
          }
        }
      };
    }
  }
  catch (err) { console.log('Error >>', err); }
};

/**
* setHEADStyle
* HEAD_GBN 에 따라서 row background color 설정
* @param {Object} cell 셀
* @param {String} header 헤더여부(true, false)
**/
const setColStyle = (cell, HEAD_GBN) => {
  if (HEAD_GBN == '1') { return 'fff2f2f2'; }
  else if (HEAD_GBN == '2') { return 'ffddebf7'; }
  else if (HEAD_GBN == '3') { return 'ffe2efda'; }
  else if (HEAD_GBN == '4') { return 'ffdafdd7'; }
  else if (HEAD_GBN == '5') { return 'fffce4d6'; }
  else if (HEAD_GBN == '6') { return 'fff2f2f2'; }
  // else if (HEAD_GBN == '7') { return '92D050'; }
  // else if (HEAD_GBN == '8') { return 'D8BFD8'; }

  return 'ffE9ECF4';
}
  
/**
* setSheetStyle
* sheet 의 frozen 설정 및 시트 전체 스타일 설정
* @param {Object} sheet 시트
* @param {Object} cellDefault 설정값
**/
const setSheetStyle = (sheet, cellDefault) => {
  if (cellDefault) {
    // 시트탭 color 설정
    if (cellDefault.tabColor) {
      let tabColor = cellDefault.tabColor;
      sheet.properties.tabColor = {argb:'ff'+tabColor};
    }

    // 시트 틀고정 설정
    if (cellDefault.frozen) {
      let frozen = cellDefault.frozen;
      //sheet.views = [{state: 'frozen', xSplit: 3, ySplit: 4}]
      sheet.views = [frozen]
    }
  }
}    
