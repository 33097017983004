import React , { useEffect, useState, useRef} from "react";
import { Link ,useLocation, useHistory} from "react-router-dom";
import axios from 'axios';
import cn from 'classnames'
import Router from 'service_routes.js'
import queryString from 'query-string';
import {observer,useObserver} from 'mobx-react-lite';
import {userStore,timerStore} from 'store/userStore.js';
import {webLinkStore} from 'store/webLinkStore.js';
// import { useCookies } from 'react-cookie';
import {Container} from "reactstrap";
import useIsMount from 'muiComponents/common/useIsMount.js'
import routes from 'service_routes.js';

import _ from 'lodash';
import { makeStyles } from "@material-ui/core/styles";
// css
import Style from './header.module.css'
import * as validation from 'muiComponents/common/validation.js';
import {CircularProgress} from '@material-ui/core';
import {AccountCircleOutlined as AccountCircle} from '@material-ui/icons';
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { menuAccessLog } from 'muiComponents/common/menuAccess';
import {setCookie, getCookie, removeCookie} from 'muiComponents/common/cookie.js';

const useStyles = makeStyles(styles);

const REGISTER_URL=`${process.env.REACT_APP_AUTH_URL}/oauth/create_account`
const LOGIN_URL = `${process.env.REACT_APP_AUTH_URL}/oauth/authorize2`
const LOGIN_REDIRECT_URL = `${process.env.REACT_APP_SERVER_URL}/auth/local/callback`

const queryStr = queryString.parse(window.location.search);
const errCode = queryStr&&queryStr.code? queryStr.code: null;

const Header = (props) => {
    const location = useLocation();
    const history = useHistory();
    const isMount = useIsMount();
    const classes = useStyles();

    const {pathname, search, state} = location;
    const {setModalOpen,  userData, isLogOut, setModalContent, onAlert, goPlism, handleLockId, handleVerifyModal} = props;
    // const [cookies, setCookie, removeCookie] = useCookies(['save_id']);
    

    const userAgent = navigator.userAgent.toLowerCase();
    // const [boardData, setBoardData] = useState([]);
    const [locationInfo, setLocationInfo] = useState({});

    const [inpId, setInpId] = useState("");
    const [inpPw, setInpPw] = useState("");
    const [saveId, setSaveId] = useState("");

    const [isHover,setIsHover]=useState(false);
    const [onAlarm,setOnAlarm]=useState(false);
    // const [webLinkMode,setWebLinkMode]=useState(false);
    const [viewAlert, setViewAlert] = useState(null);
    
    const authLoading = useObserver( ()=>{
        return userStore.loading;
    })
    const userStoreMenu = useObserver(() => {
        return userStore.menu;
    });

    const webLinkMode = useObserver(() => {
        return webLinkStore.webLinkMode;
    });

    
    useEffect(() => {
        console.log(webLinkStore.rolePath ,'--',pathname)
        if(webLinkStore.rolePath){
            //웹링크 설정에서 서비스하는 메뉴외에는 로그아웃
            if(webLinkStore.rolePath != pathname)userStore.logout();
        }
    }, [webLinkStore.rolePath]);

    useEffect(() => {
        if(pathname){
            let str = window.location.href;
            let nameStr = getActiveRoute(routes) ||(pathname=='/'||'/home'? '메인화면':'') ;
            let line_code = (state&&state.line_code) ||null
    
            if( str === "/blgate/sch/terminalSchedule.do") {
                str="/svc/cal";
                nameStr="터미널 스케줄";
            }
            axios.post('/api/oraMenuAccessLog', {user: userData ? userData.user_no : 'GUEST',pathName : window.location.pathname});
            let last_char = str.charAt(str.length - 1);
            if (last_char !== '#') {
                axios.post('/api/menuAccessLog', {
                    user: userData ? userData.user_no : 'GUEST',
                    path: pathname+search,
                    name: nameStr,
                    systemName: 'LOGISVIEW',
                    page_line_code: line_code||null
                });
            }
        } 
    }, [window.location.pathname]);

    useEffect(() => {    
        const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
        const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;
        if (isIE) {
        window.location = 'https://go.microsoft.com/fwlink/?linkid=2135547';
        }   

        //로그인 에러 처리 
        if(!userData){
            if(errCode){
                if(errCode=='E1000'||errCode=='E1001'||errCode=='E1002'){
                    onAlert('error',validation[`${errCode}`]);
                }else if(errCode=='E1003'){
                    // E1003 로그인 잠금처리 되었습니다.[비밀번호 5회 실패] 서비스 담당자에게 문의하여 잠금해제 하시기 바랍니다.
                    // '&reason=' + userData.LOGIN_LOCK_REASON
                    const lockReason = queryStr&&queryStr.reason? queryStr.reason: null;
                    if(lockReason){
                        onAlert('error',`로그인 잠금처리 되었습니다.[${lockReason}] \n서비스 담당자에게 문의하여 잠금해제 하시기 바랍니다.`);
                    }else{
                        onAlert('error',`로그인 잠금처리 되었습니다. \n서비스 담당자에게 문의하여 잠금해제 하시기 바랍니다.`);
                    }
                }else if(errCode=='E1004'){
                    // '?errcode=E1004' + param+
                    // '&KLNET_ID=' + userData.KLNET_ID+
                    // '&COMP_TYPE=' + userData.comp_verify_type
                    const klnet_id = queryStr&&queryStr.reason? queryStr.reason: null;
                    if(klnet_id){
                        handleLockId(klnet_id);
                    }else{
                        handleLockId('');
                    }
                }else if(errCode=='E1006'){
                    const reason = queryStr&&queryStr.reason? queryStr.reason: null;
                    onAlert('error',`이용PC${reason?`(IP주소:${reason})`:''}가 아닙니다. \n이전PC에서 [로그오프] 이후 이용하여 주십시오.\n IP가 변경된 경우 서비스 담당자에게 문의하세요.`);
                }else{
                    onAlert('error',validation['E1005']);
                }
            }
        }

        if(getCookie('save_id') !==undefined){
            setInpId(getCookie('save_id'));
            setSaveId(true);
        }
        axios.interceptors.response.use(
            function(response) {
                if( response.data.result ){
                    response.data = response.data.result;
                }
                return response;
            },
            function(error) {
                if (error && error.response&&isMount.current) {
                    if (error.response.status === 403) {
                        if(userStore.user)userStore.user =null;
                    } else if (error.response.status === 401) {
                        onAlert('error',validation.NOTLOGIN_MSG);
                        if(userStore.user)userStore.user =null;
                    } else if (error.response.status === 419) {
                        onAlert( 'error',validation.TOKEN_EXPIRED_MSG);
                        setTimeout(()=>{
                            if(userStore.user)userStore.logout();
                            removeCookie('ctfyRes');
                        },2000);
                    } else if (error.response.status === 500) {
                    } else if (error.response.status === 400) {
                    } else {
                    }
                }   
                return Promise.reject(error);
            }
        );
        // userStore.setLoading=false;
    }, [isMount]);
    
    useEffect(() => {
        if(userStore.isAuth==401){//로그인 되어있는상태만 로그아웃됨
            onAlert('error',validation.NO_PERMISSION_MSG);
            setTimeout(()=>{
                userStore.logout();
            },2000);
        }
        if(userStore.isAuth==419){//로그인 되어있는상태만 로그아웃됨
            onAlert('error',validation.TOKEN_EXPIRED_MSG);
            setTimeout(()=>{
                userStore.logout();
            },2000);
        }
        if(userStore.isAuth?.result){//로그인 이중인증창 띄우기
            onAlert('error','인증번호가 전송되었습니다.');
            handleVerifyModal();
        }
    }, [userStore.isAuth]);

    useEffect(() => {
        // userStore.setLoading=true;
        if(pathname==''||pathname=='/'){ 
            setLocationInfo({});
        }else{
            let main = Router.find(x=>(x.views&& x.views.findIndex(y=>pathname.includes(y.layout+y.path))) >-1);
            let sub = main
                ?main.views.find(y=> (pathname+search).includes(`${y.layout+y.path}${y.id?`/${y.id}`:''}`))
                :Router.find(x=>x.path&& (pathname+search).includes(x.layout+x.path))||'';
            
            let mainDB = userStoreMenu.find(x=>(x.views&& x.views.findIndex(y=>pathname.includes(y.layout+y.path))) >-1);
            let subDB = mainDB  ? mainDB.views.find(y=> (pathname+search).includes(`${y.layout+y.path}${y.id?`/${y.id}`:''}`))
                                : userStoreMenu.find(x=>x.path&& (pathname+search).includes(x.layout+x.path))||'';

            let info = main&&sub? {maintitle: main.name, subtitle : sub.name}
                                :sub?{subtitle : sub.name}
                                    :mainDB&&subDB ? {maintitle: mainDB.name, subtitle : subDB.name ,}
                                                    :subDB  ? {subtitle : subDB.name}:{}
            setLocationInfo({...info});
                
            if(!({...sub,...subDB}&&props.checkOptionPage({...sub,...subDB}))){
                // userStore.setLoading=false;
                history.push('/');
            }
        }
        window.scrollTo(0,0);
        setIsHover(false);
        // userStore.setLoading=false;
    // return  ()=>{userStore.setLoading =false;}
    },[location,userStoreMenu]);

    useEffect(() => {
        if(onAlarm&&!webLinkMode){
            setViewAlert(
                <SweetAlert
                onConfirm={ () => {setOnAlarm(false);  onHandlerSession();}}
                title={'로그인 상태를 유지하시겠습니까?'}
                type={'warning'}
                show={onAlarm }
                reverseButtons={true}
                showConfirm={true}
                showCancel={true}
                onCancel={() => { setOnAlarm(false)}}
                confirmBtnText={'세션 연장'} 
                cancelBtnText={'취소'} 
                confirmBtnStyle={{color: 'white', border:'none', boxShadow :'none',backgroundColor: '#00acc1'}}
                cancelBtnStyle={{color: 'white',border:'none', boxShadow :'none',backgroundColor: '#ff9800'}}
                confirmBtnCssClass={classes.button + " " + classes.info }
	            cancelBtnCssClass={classes.button + " " + classes.warning}
                style ={{boxSizing:'initial', width:'450px'}}
                >
                    <span style={{fontWeight:'bold'}}><TimerKor/> 후 로그인 세션이 종료되어 로그아웃됩니다. </span>
                </SweetAlert>
            )
        }else{
            setViewAlert(null)
        }
    },[onAlarm]);

    const leftTime =useObserver( ()=>{
        return  timerStore.leftTime
      })
      
    React.useEffect(() => {
        if(userData&&(!leftTime||leftTime<1||leftTime== ' -- : -- ')){
            setViewAlert(null);
            isLogOut();
        }   
        if(userData&&leftTime==600){
            setOnAlarm(true)
        }     
    },[leftTime]);
    
    const fncOnKeyPress = (e) => {
        if("Enter" === e.key && inpId && inpPw) {
            onSubmit();
        }
    };

    const Timer = observer(() => {
        return <>
                {userData&&(timerStore.leftTime<600)
                ?<>
                    <div className={Style.timer}>[{timerStore.timer}]</div>
                    <div className={cn(Style.btnExtension,Style.on)}  onClick={onHandlerSession}> 세션 연장</div>
                </> 
                : <div style={{width:'15px'}}> </div>} 
        </>;
    });

    const TimerKor = observer(() => {
        return <>{timerStore.timerKo}</>
    });

    const onHandlerSession = async()=>{
        const result =  await userStore.sessionExtension();
        if(!result){
            onAlert('error','세션 연장이 불가한 세션입니다. 다시 로그인 해주세요.')
        }
    }

    const onSubmit=(event)=> {  
        if(event) {
            event.preventDefault();
        }
         //ID저장
        const expiryDate = new Date();
        expiryDate.setMonth(expiryDate.getMonth()+1);
        if(saveId){
            setCookie('save_id',inpId,{path:'/', expires:expiryDate});
        }else{
            removeCookie('save_id');
        }
        const onCheckedID = validation.validationHanSpc(inpId);
        if(inpId && inpPw&&onCheckedID) {
            // userStore.setLoading= true;
            document.loginForm.submit();
            // userStore.setLoading= false;
        } else {
            if(!inpId || !inpPw) {
                onAlert('error',validation.NO_USER_MSG,);
            }else if(!onCheckedID) {
                onAlert('error','ID : '+validation.NO_CHECK_MSG,);
            }else{
                onAlert('error',`로그인 ${validation.ERR_MSG}`);
            }
        }
    }

    const onSubmitPage= (element)=>{
        if(props.checkOptionPage(element)){
            if (element.layout=='PLISM'){
                // menuAccessLog(element,userData); goPlism에서 기록
                // goPlism(element.linkParam,{id:element.id||null, logout_use_flag:element?.logout_use_flag||'N'}); 
                goPlism(element,{id:element.id||null, logout_use_flag:element?.logout_use_flag||'N'}); 
            }else if(element.layout=='urlPath'){
                menuAccessLog(element, userData);
                window.open(element.path,'_blank');
            }else{
                // menuAccessLog(element, userData); header useEffect(window.location.pathname)에서 기록
                let path = `${element.layout+element.path}${element.id?`/${element.id}`:''}`
                
                if (path == pathname){//같은페이지면 새로고침
                    history.replace(path);
                    window.location.reload();
                }else{
                    history.push(path);
                }
            }
        }
    }

    const handleOnChangeCookie = (checked)=>{
        setSaveId(checked)
    }

    const getActiveRoute = (routes) => {
    let activeRoute ;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path+ `${routes[i].id?`/${routes[i].id}`:''}`) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const isLogOutMySelf =()=>{
    userStore.logoutMySelf();
  }

    return (
    <>
        <div className={Style.header}>
            <div className={Style.rela}>
                <h1>
                    <a href="/"><img src={process.env.PUBLIC_URL  + `/assetsNew/image/etc/etc-logo.png`} /></a>
                </h1>
                {webLinkMode&&userStore.user
                ?<div className={Style.sideItemWrap}>
                    <div className={Style.outloginItemWrap}>
                        {(timerStore.leftTime<600)&&
                            <div className={Style.timer} style={{fontWeight:'bold', marginRight:'20px'}}><TimerKor/> 후 로그인 세션이 종료되어 로그아웃됩니다.</div>}
                            {/* <div className={Style.btnLogout} onClick={isLogOutMySelf}>세션아웃</div> */}
                    </div>
                </div>
                :userStore.isAuth || !userStore.user ? //비로그인
                (<div className={Style.sideItemWrap}>
                    <ul className={Style.linkList}>
                        <li><Link to='#' onClick={()=>{setModalOpen(true);setModalContent('FindInfoPage')}}>ID/PW 찾기</Link></li>
                        <li><a href={REGISTER_URL}>SIGN UP</a></li>
                        <li><a href="https://cyberdesk.klnet.co.kr">HELP DESK</a></li>
                    </ul>
                    <form className={Style.outloginItemWrap} name="loginForm" action={LOGIN_URL} method="POST" onSubmit={onSubmit}>
                            <input type='hidden' name='client_id' value='bWFtbWEgTTAwMDAwMA=='></input>
                            <input type='hidden' name='redirect_uri' value={LOGIN_REDIRECT_URL}></input>
                            <input type='hidden' name='response_type' value='code'></input>
                            <input type='hidden' name='state' value='12345'></input>
                            <input type="text"  name="id" className={Style.inp} placeholder="ID" value={inpId} onChange={(e)=>setInpId(e.target.value.toUpperCase())}/>
                            <input type="password" name="pw" className={Style.inp} placeholder="PW" value={inpPw}
                                onChange={(e)=>{ setInpPw(e.target.value.toUpperCase());}}
                                onKeyPress ={(e)=>fncOnKeyPress(e)}/>
                            <label>
                                <input type="checkbox" onChange={(e)=>handleOnChangeCookie(e.target.checked)} checked={saveId}  />
                                <span>ID 저장</span>
                            </label>
                        {userStore.loading
                        ?<div className={Style.btnLogin} style={{padding:0 , justifyContent:'center'}}><CircularProgress color="primary" size={20} /></div>
                        :<div className={Style.btnLogin} onClick={onSubmit}>LOGIN</div> 
                        }
                    </form>
                </div>)
                :!userStore.loading&&userStore.user?(//로그인
                <div className={Style.sideItemWrap}>
                    <div  className={Style.btnUserEdit}>
                        { userData?.role== "Y"&&
                        // {!userStore.loading&&userStore.user&& userStore.user.klnet_id== "KLTEST01"&&
                        <Link to="/admin" style={{display:'flex', alignItems: 'center' ,marginRight:'10px'}} >
                            <AccountCircle style={{ width: '25px', height: '25px' ,color:'#06b3ba'}}/>
                            <span>관리자 메뉴</span>
                        </Link>}
                        <Link to="/svc/profile" style={{display:'flex', alignItems: 'center'}}  >
                            <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-user-edit.png`} />
                            <span>사용자 정보 수정</span>
                        </Link>
                    </div>
                    <div className={Style.outloginItemWrap}>
                        <div className={Style.btnUser}>
                            <div className={Style.upic}></div>
                            <div className={Style.uname}><strong>{userData.user_name}</strong> 님</div>
                        </div>
                        <Timer/>
                        {userStore.loading
                        ?<div className={Style.btnLogout} style={{padding:0 , justifyContent:'center'}}><CircularProgress color="primary" size={20} /></div>
                        :<div className={Style.btnLogout} onClick={isLogOutMySelf}>{userStore.user && !userStore.admin ?`LOGOUT`:`세션아웃`}</div>
                        }
                    </div>
                </div>):<></>} 
            </div>
            {viewAlert}
            {webLinkMode?<div className={Style.gnbItemWrap} style={{height:'0px', borderTop:'none'}}></div>
            :<div className={Style.gnbItemWrap}  onMouseDown={()=>setIsHover(true)} onMouseLeave={()=>setIsHover(false)}>
                <ul>
                    {userStoreMenu.map((value,ind) => {
                        if(value.mainShow) {
                            return (
                                <li key={ind}>{value.name}</li>
                                )
                        }
                    })}
                </ul>
            </div>}
        </div>
        {/**
        숨김: <div class="totalmenu-item-wrap">
        노출: <div class="totalmenu-item-wrap on">
        * */}
        <div className={cn(Style.totalmenuItemWrap ,isHover?Style.on:'')} >
            <ul onMouseEnter={()=>setIsHover(true)} onMouseLeave={()=>setIsHover(false)}>
                {userStoreMenu.map((value,index) => {  
                    if(value.mainShow) {
                        return (
                        <React.Fragment key = {index}>
                            <li>
                                <ul>
                                {value.views.map((element,ind) => {
                                    return(
                                        element.mainShow?
                                            <li key={ind}>
                                                <div className={Style.anchor} key={"child"+ind} 
                                                onClick={()=>{onSubmitPage(element)}}>
                                                {element.name}</div>
                                            </li>
                                        :null
                                    )
                                })}
                                </ul>
                            </li>
                        </React.Fragment>
                        )
                    }
                })} 
            </ul>
        </div>
        {locationInfo&&locationInfo.subtitle&&!webLinkMode?
        <Container>
            <div className={Style.subHeaderWrap}>
            <div className={Style.stit}></div>
            <ul>
                <li onClick={()=>history.push("/")}>
                    <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-home.png`}/>
                </li>
                {locationInfo.maintitle&&<li>{locationInfo.maintitle}</li>}
                <li><strong>{locationInfo.subtitle}</strong></li> 
            </ul>
        </div>  
        </Container>
        :webLinkMode&&userStore.user?<Container><div className={Style.subHeaderWrap} style ={{height:0}}></div></Container>
        :<></>}
    </>
  )
}

export default Header;
