import React,{useState,useEffect} from "react";
// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import Icon from "@material-ui/core/Icon";
import Grid from '@material-ui/core/Grid';
// core components
import GridItem from "muiComponents/Grid/GridItem.js";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import Card from "muiComponents/Card/Card.js";
import CardHeader from "muiComponents/Card/CardHeader.js";
import CardBody from "muiComponents/Card/CardBody.js";
import CardIcon from "muiComponents/Card/CardIcon.js";
import Button from "muiComponents/CustomButtons/Button.js";
// other import
import axios from 'axios';
import {CSVLink} from 'react-csv';
import setYear from "date-fns/setYear";

const headCells = [
  { id: '선사 LINE', numeric: false, disablePadding: true, label: '선사 LINE' },
  { id: '선사 ORIGINATOR', numeric: false, disablePadding: true, label: '선사 ORIGINATOR' },
  { id: '선사', numeric: false, disablePadding: true, label: '선사' },
  { id: 'KLNET_ID', numeric: false, disablePadding: true, label: 'KLNET_ID' },
  { id: '화주', numeric: false, disablePadding: true, label: '화주' },
  { id: 'COUNT(REQ_BKG_NO)', numeric: false, disablePadding: true, label: 'COUNT(REQ_BKG_NO)' },
];

export default function VslInfoList(props) {
  const {alertMessage} = props;
	const [yearmonth,setYearmonth ] = useState("");
  const [allData,setAllData ] = useState({});

  const onSubmit = () => {
    if(yearmonth){
      if(isNaN(Number(yearmonth))) {
        alertMessage('숫자를 입력해 주세요.','error');
        return false;
      }

      if(yearmonth.length!==6) {
        alertMessage('6자리로 입력해 주세요.','error');
        return false;
      }

      axios.post("/api/getBookingData",{yearmonth:yearmonth})
      .then(res => {
        console.log(res.data)
        if(res.data){
          if(res.data.length>0){
            setAllData(res.data);
          }else{
            alertMessage('해당 연월에 대한 데이터가 없습니다.','error');
            setAllData({});
          }
        }
      }).catch(err => {
        alertMessage('데이터 조회 오류 !','error');
      });
    }else{
      alertMessage('조회 할 연월을 입력해주세요.','error');
    }
  }

  return (
    <div>
    <GridContainer>
    	<GridItem xs={12} sm={12} md={12}>
        <Card style={{marginBottom:'0px'}}>
          <CardHeader color="info" stats icon style={{paddingBottom:'2px'}}>
            <CardIcon color="info" style={{height:'55px'}}>
              <Icon style={{width:'26px',fontSize:'20px',lineHeight:'26px'}}>content_copy</Icon>
            </CardIcon>
            <h4 style={{color:'black',textAlign: "left",}}> 선사별 부킹 통계 </h4>		
          </CardHeader>
          <CardBody style={{paddingBottom: '15px',paddingTop: '10px',paddingLeft: '15px',paddingRight: '15px'}}>
            <div>※ 연월을 입력 후 [Search]버튼을 눌러주세요.</div>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={2}>
                  <TextField id="yearmonth" label="ex) 202301" onChange={event => setYearmonth(event.target.value.trim())} value={yearmonth} inputProps={{maxLength:9}} fullWidth />
                </Grid>
                <Grid item xs={12} md={2} >
                  <Button color="info" onClick = {() => onSubmit()} fullWidth>Search</Button>					
                </Grid>
                {allData&&allData.length>0?
                  <Grid item xs={12} md={2} >
                    <CSVLink data={allData}
                    headers={headCells.filter(x=>x.id !== 'HISTORY_SEQ').map(item =>{ return {label: item.label , key : item.id}})}
                    filename="선사별 부킹 통계자료.csv">
                      <Button color="success" 
                      id='btnExport' disabled={allData.length<1} >EXCEL 다운로드</Button>
                    </CSVLink>
                  </Grid>
                  :<></>
                }
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>		
    </GridContainer>
    </div>
  );
}



