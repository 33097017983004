/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row, Col,FormGroup,Label} from "reactstrap";
//import * as validation from 'muiComponents/common/validation.js';
//import { invalid } from 'moment';
import Select from "react-select";
import InputValid from "components/CustomInput/InputValid.js";

export default function Cargo(props){

    const [cargo, setCargo] = useState({});
    const { lineCode, openType, cargoPackTypeList, cargoTypeList} = props;

    useEffect(() => {
        setCargo(props.cargo);
    },[props.cargo]);

    // 수정된 내용은 cargo 저장
    const fncOnChange = ( e, key ) => {
        e.preventDefault();
        setCargo({...cargo, [key]:(e.target.value.toUpperCase())||null});
    }

    // 완료된 내용은 부모로 전달
    const fncOnBlur = (e) => {
        e.preventDefault();
        props.fncOnBlur( cargo );
    }

    const fncCargoType =(value)=> {
        if( value ) {
            if( "WDFC"=== lineCode && ("3" === value.cargo_type || "4" === value.cargo_type) ) {
                props.onNotiAlert("danger", "위험물 또는 OOG 부킹은 별도 문의 바랍니다.");
                return false;
            }
            setCargo({...cargo,'cargo_type':value.cargo_type})
        } else {
            setCargo({...cargo,'cargo_type':null})
        }
    }

    return (
        <>
            <Row>
                {(openType === "BOOK") &&
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Bookmark Name</Label>
                        <InputValid 
                            type="text"
                            name="cargo_bookmark_name"
                            id="cargo_bookmark_name"
                            maxLength="50"
                            value={cargo.cargo_bookmark_name?cargo.cargo_bookmark_name:''}
                            onChange={(e)=>fncOnChange(e, 'cargo_bookmark_name')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="text"
                            required={'BOOK'===openType?true:false} 
                        />
                    </FormGroup>
                </Col>}
            </Row>            
            <Row>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Type</Label>
                            <Select
                                className="react-select"
                                name="cargo_type"
                                value={{value:cargo.cargo_type?cargo.cargo_type:'선택',
                                    label:cargo.cargo_type?
                                        (cargoTypeList.findIndex(x=>x.value===cargo.cargo_type)>=0)?
                                        cargoTypeList[cargoTypeList.findIndex(x=>x.value===cargo.cargo_type)].label:
                                            cargo.cargo_type_name||'선택':
                                        '선택'
                                }}
                                onChange={(value)=> fncCargoType(value)}
                                options={cargoTypeList}
                                invalid={cargo.cargo_type?false:('CARGO'===openType?true:false)}
                                onBlur={(e)=>fncOnBlur(e)}
                                isClearable={cargo.cargo_type?true:false}
                            />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Pack Type</Label>
                        <Select
                            className="react-select"
                            name="cargo_pack_type"
                            value={{value:cargo.cargo_pack_type?cargo.cargo_pack_type:'',
                                    label:cargo.cargo_pack_type
                                    ?(cargoPackTypeList.findIndex(x=>x.cargo_pack_type===cargo.cargo_pack_type)>=0)
                                    ?cargoPackTypeList[cargoPackTypeList.findIndex(x=>x.cargo_pack_type===cargo.cargo_pack_type)].label
                                    : `[${cargo.cargo_pack_type}] ${cargo.cargo_pack_type_name}`||'선택'
                                    :'선택'}}
                            onChange={(value)=>setCargo({...cargo,'cargo_pack_type':value?value.cargo_pack_type:null})}
                            options={cargoPackTypeList}
                            onBlur={(e)=>fncOnBlur(e)}
                            isClearable={cargo.cargo_pack_type?true:false}
                        />
                        <InputValid
                            hidden
                            type="text"
                            name="cargo_pack_type_ch"
                            id="cargo_pack_type_ch"
                            maxLength="20"
                            value={cargo.cargo_pack_type?cargo.cargo_pack_type:''}
                            validtype="select"
                            required={false} 
                            feedid="cargo"
                            readOnly
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Pack Qty</Label>
                        <InputValid 
                            type="text"
                            name="cargo_pack_qty"
                            id="cargo_pack_qty"
                            maxLength="4"
                            value={cargo.cargo_pack_qty?cargo.cargo_pack_qty:''}
                            onChange={(e)=>fncOnChange(e, 'cargo_pack_qty')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="number"
                            required={false} 
                            feedid="cargo"
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Hs Code</Label>
                        <InputValid
                            type="text"
                            name="cargo_hs_code"
                            id="cargo_hs_code"
                            maxLength="10"
                            minLength="6"
                            onChange={(e)=>fncOnChange(e, 'cargo_hs_code')}
                            onBlur={(e)=>fncOnBlur(e)}
                            value={cargo.cargo_hs_code?cargo.cargo_hs_code:''}
                            validtype="number"
                            required={true} 
                            feedid="cargo"
                        />
                    </FormGroup>
                </Col>
                {/* <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Cargo Weight</Label>
                        <InputValid 
                            type="text"
                            name="cargo_weight"
                            id="cargo_weight"
                            maxLength="10"
                            value={cargo.cargo_weight?cargo.cargo_weight:''}
                            onChange={(e)=>fncOnChange(e, 'cargo_weight')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="number"
                            required={false} 
                            inputgrouptext="kg"
                        />
                    </FormGroup>
                </Col> */}
            </Row>
            <Row>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">GrossWeight</Label>
                        <InputValid 
                            type="text"
                            name="cargo_total_weight"
                            id="cargo_total_weight"
                            maxLength="16"
                            value={cargo.cargo_total_weight?cargo.cargo_total_weight:''}
                            onChange={(e)=>fncOnChange(e, 'cargo_total_weight')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="decimal"
                            decimallength="3"
                            required={false} 
                            inputgrouptext="KG"
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        <Label className="mb-0">Total Volume</Label>
                        <InputValid 
                            type="text"
                            name="cargo_total_volume"
                            id="cargo_total_volume"
                            maxLength="10"
                            value={cargo.cargo_total_volume?cargo.cargo_total_volume:''}
                            onChange={(e)=>fncOnChange(e, 'cargo_total_volume')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="number"
                            required={false} 
                            inputgrouptext="CBM"
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
}