import React, { useState, useEffect, useCallback } from 'react';

import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField, Chip} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Search from "@material-ui/icons/Search";
// core components
import GridItem from "muiComponents/Grid/GridItem.js";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Button from "muiComponents/CustomButtons/Button.js";
import { arrayIncludes } from '@material-ui/pickers/_helpers/utils';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const useStyles = makeStyles((theme)=> ({
	valueContainer: {
		"& > :not(:last-child)": {
		// color: "rgba(255,255,255,.62)",
		// margin: "0",
		// fontSize: "14px",
		marginRight: theme.spacing(1),
		// marginBottom: "0"
		},
		"& > *": {
		// color: "#FFFFFF"
        marginTop: theme.spacing(1),
		}
	},
}))

function SeavantageMap(props) {
    const {userData} = props;
    const classes = useStyles();
    const option = [{label:'VESSEL (IMO)' , value:0},
    {label:'M-B/L' , value:1},
    {label:'H-B/L' , value:2}];
    const [linkUrl,setLinkUrl] = useState("");
    const [type,setType] = useState(0);
    const [typeValue, setTypeValue] = useState(option[0].label);
    const [vesselData,setVesselData] = useState([]);
    const [selectedData,setSelectedData] = useState([]);
    const [open,setOpen] = useState(false);
    const loading = open && vesselData.length ===0;
    const [vslName, setVslName] = useState("");
    const [blNo, setBlNo] = useState("");

    // React.useEffect(() => {
    // },[]);

    React.useEffect(() => {    
        let active = true;
        if (!loading){ return undefined;}
        
        (async()=>{
            await axios.post("/shipper/getVesselData",{
                vesselName:''
            }).then(res => {
                if(res.data.length > 0) {
                    // console.log(res.data)
                    if (active){ setVesselData(res.data)}
                }
            });
        })();

        return ()=> {active = false};
    },[loading]);


    React.useEffect(() => {  
        if (!open){
            onInit();
            setVesselData ([])
        }
    },[open]);

    // useEffect(()=>{
    //     getVesselData();
    // }, [vslName]);

    useEffect(()=>{
        if( blNo &&(type==1||type==2)) {
            if (selectedData.length<5){
                if( blNo.length > 4 ) {
                    getVesselName();
                }
            }else{
                props.onAlert('error','최대 5개까지 설정할 수 있습니다');
                setBlNo("");
            }
        }else{
            setBlNo("");
        }
    }, [blNo,type]);

    const getVesselData = async(vslName)=>{
       await axios.post("/shipper/getVesselData",{
            vesselName:vslName
        }).then(res => {
            if(res.data.length > 0) {
                // console.log(res.data)
                setVesselData(res.data);
            }
        });
    }
    const onDelete = imo => ()=>{
        setSelectedData(val => val.filter(v=> v.IMO_NO !== imo))
    }
    const onSubmit = ()=>{
    
        if(!props.userData){ props.onAlert('error','로그인이 필요한 서비스입니다.'); return false;}
        if(selectedData.length<1){ props.onAlert('error','조회 조건을 입력하십시오.');return false;}
        
        let filteredList =  [...selectedData].map(row=>(
            {
                imoNo : row.IMO_NO   
            }
        ))
    // console.log(selectedData,filteredList)
         axios.post("/com/getTrackingShip",{
            params: filteredList
        }).then(res => {
            if(res.data.length > 0) {
                // console.log(res.data)
                setTimeout(function() {
                    setLinkUrl(res.data);
                },1000);
            }
        })
    }


    const onInit = ()=>{
    
        // if(!props.userData){ props.onAlert('error','로그인이 필요한 서비스입니다.'); return false;}
    // console.log(selectedData,filteredList)
         axios.post("/api/getTrackingShipNoLogin",{
            params: []
        }).then(res => {
            if(res.data.length > 0) {
                // console.log(res.data)
                setTimeout(function() {
                    setLinkUrl(res.data);
                },1000);
            }
        })
    }

    const onChangeBlNo =(value,id)=> {
        setBlNo(value);
    }

    const getVesselName = async() =>{
        
        await axios.post("/shipper/getVesselNameFromBlNo",{
            blNo: blNo,
            type: type,
        }).then(res => {
            if(res.data.length > 0) {
                let objList = selectedData.concat(res.data);
                if( objList.length > 1 ) {
                    let mergeList = objList.filter((item1, idx1)=>{
                        return objList.findIndex((item2, idx2)=>{
                            return item1.IMO_NO == item2.IMO_NO;
                        }) == idx1;
                    });
                    setSelectedData(mergeList);
                } else {
                    setSelectedData(objList);

                }
            } else {
                
            }
        }).catch(error=>{
            if( error.response.data.message ) {
                props.onAlert('error',error.response.data.message);
            } else {
                props.onAlert('error',"Vessel 명을 조회 할 수 없습니다.");
            }
        });
    }

    const onKeyPressBlNo = (e)=>{
        // onSubmit();
    }

    return (
        <>
        <GridItem xs={12} style={{marginTop:'20px', marginBottom:'10px'}}>
            <Card style={{marginTop:'0',marginBottom:'10px'}}>
					<CardBody style={{paddingTop:'15px',paddingBottom:'15px'}}>
						<GridContainer >
                            <GridItem lg={2} md={6} sm={6} xs={12}>
                                <Autocomplete
                                    options = {option}
                                    value={option[type].label}
                                    onChange={(_,newValue)=>{
                                        if( newValue ) {
                                            setType(newValue.value);
                                        } else {
                                            setType(0);
                                        }
                                    }}
                                    inputValue={typeValue}
                                    onInputChange={(event, newInputValue)=>{
                                        setTypeValue(newInputValue.toUpperCase());
                                    }}
                                    getOptionLabel = { (opt) => opt?opt.label?opt.label:opt:'' }
                                    required
                                    renderInput={params => (
                                        <TextField inputProps={{maxLength:4}} {...params} label="* TYPE" fullWidth />
                                    )}
                                />
                            </GridItem>
                            <GridItem lg={3} md={6} sm={6} xs={12}>
                                {type==0&&
                                <Autocomplete
                                    // size="small"
                                    multiple
                                    // // disableCloseOnSelect
                                    limitTags={5}
                                    options = {vesselData}
                                    // // value = {selectedCarrier}
                                    value = {selectedData}
                                    getOptionLabel = { option =>option.VESSEL_NAME}
                                    getOptionSelected = {(option,value) => option.IMO_NO === value.IMO_NO}
                                    id="carrierCode"
                                    onChange={(_,newValue)=>{
                                        if (selectedData.length<5){
                                            setSelectedData(newValue);
                                        }else{
                                            props.onAlert('error','최대 5개까지 설정할 수 있습니다'); 
                                        }
                                    }}
                                    open={open}
                                    onOpen={()=>setOpen(true)}
                                    onClose={()=>setOpen(false)}
                                    loading={loading}
                                    loadingText={userData?"keyword를 입력하세요":'로그인이 필요한 서비스입니다.'}
                                    renderTags={()=>null}
                                    onInputChange={(event, value)=>{
                                        if( value ) {
                                            getVesselData(value.toUpperCase());
                                            setVslName( value.toUpperCase() ); 
                                        }
                                    }}
                                    renderInput={params => (
                                        <TextField {...params} value = {vslName} label="Vessel Name" fullWidth />
                                    )}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ margin: '0'}}
                                            checked={selected}
                                        />
                                        <font size="2">{`${option.VESSEL_NAME} (${option.IMO_NO})`}</font>
                                        </React.Fragment>
                                    )}
                                    noOptionsText={'모선명을 다시 확인하시기 바랍니다.'}
                                    // '모선명을 다시 확인하시기 바랍니다. \n 모선이 조회되지 않으면 고객센터로 연락 바랍니다 \n 고객센터 : 1577-1172'
                                />
                                }
                                {(type==1||type==2)&&
                                <GridItem lg={10} md={10} sm={10} xs={10}>
                                    <TextField 
                                        style={{width:'90%'}}
                                        id="blNo"
                                        label="* BL 번호 "
                                        onChange={(e)=>onChangeBlNo(e.target.value.toUpperCase().trim(), e.target.id)}
                                        // onBlur={(e)=>onChangeBlNo(e.target.value.toUpperCase().trim())}
                                        onKeyPress={(e)=>onKeyPressBlNo(e)}
                                        value={blNo}
                                        />
                                        {/* <Search fontSize="large" style={{marginTop:'20px'}} onClick={}/> */}
                                </GridItem>
                                }
                            </GridItem>
                            <GridItem lg={5} md={6} sm={6} xs={12} >
                                <div className = {classes.valueContainer}>
                                    {selectedData.length>0&&selectedData.map((v,idx)=>(
                                        <Chip key = {idx} label={`${v.VESSEL_NAME} (${v.IMO_NO})` } onDelete ={onDelete(v.IMO_NO)}/>
                                    ))}
                                </div>
                            </GridItem>
                            <GridItem lg={2} md={6} sm={6} xs={12} style ={{display:'flex', justifyContent:'flex-end' ,height: 'fit-content'}}>
                            <Button color="info" 
                                onClick={onSubmit}
                                >SEARCH</Button>  
                            </GridItem>
                            <GridItem lg={6} md={6} sm={6} xs={12}>
                                <div>※ 최대 5개까지 설정할 수 있습니다.</div> 
                                <div> ※ B/L 번호 조회시 신고한 적하목록의 세관 처리상태가 "승인" 인 모선만 조회 가능합니다.</div>
                                <div> ※ 모선이 조회되지 않으면 고객센터로 연락 바랍니다. ☎ 고객센터 : 1577-1172</div>
                            </GridItem>
						</GridContainer>   	
					</CardBody>
                </Card>
        </GridItem>
         {linkUrl&&
         <div style ={{ width:"100%", height:"600px"}}>
            <form target="maplink"> 
                <iframe name="maplink" src={linkUrl} title={"tackingFrame"} 
                width="100%" height="600" display='block' border='none' position="absolute" frameBorder="0" scrolling="auto" allowFullScreen></iframe>
            </form>
        </div>}
        </>
    );
}

  
  
export default SeavantageMap;
