import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {toDateParse, toTimeParse} from './schCalHeader.js'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom :'15px',
        marginTop:'15px'
    },
    table: {
        minWidth: 750,
        minHeight:150,
        borderTop:'2px solid #00b1b7', 
        borderBottom:'2px solid #00b1b7',
    },
    tableHeader: {
        paddingTop:'0',paddingBottom:'0', borderBottom:'2px solid #00b1b7'
    },
    row:{
        fontSize:"12px",paddingLeft:'7px', paddingTop:'10px', paddingBottom:'10px', paddingRight:'7px',
    },
    tableHeaderSpan:{
        display:'block', textAlign: 'center',fontWeight: '500'
    }
}));

export default function SchCalHistory(props) {
const {rows, ieType, ownMrnCalSch,userData,alertMessage} = props;
const classes = useStyles();
const [rowsData, setRowsData] = useState([]);

useEffect(() => {
    // console.log(rows);
    const diffDateRows = [...rows].reduce((acc, cur, currIndx, arr)=>{
        // console.log('acc : ',arr.length,';;;',currIndx+1,';;;',
        // arr[currIndx+1]?.load_end_date, ';;;',arr[currIndx]?.load_end_date);
        let diffList ={
            'diffEndDate': false, 
            'diffEndTime': false,
            'diffBeginDate': false,
            'diffBeginTime': false,
            }
        if(arr.length!==currIndx+1){
            let lastEnd = arr[currIndx+1]?.load_end_date? arr[currIndx+1]?.load_end_date:'';
            let curEnd = arr[currIndx]?.load_end_date? arr[currIndx]?.load_end_date:'';
            let lastBegin = arr[currIndx+1]?.load_begin_date? arr[currIndx+1]?.load_begin_date:'';
            let curBegin = arr[currIndx]?.load_begin_date? arr[currIndx]?.load_begin_date:'';

            diffList = {
                'diffEndDate': lastEnd.substr(0,8)!==curEnd.substr(0,8), 
                'diffEndTime': lastEnd.substr(8,4)!==curEnd.substr(8,4),
                'diffBeginDate': lastBegin.substr(0,8)!==curBegin.substr(0,8), 
                'diffBeginTime': lastBegin.substr(8,4)!==curBegin.substr(8,4),
                }
        }
        //date비교
        acc[currIndx]={...cur,...diffList}
        return acc;
    },[]);
    
    setRowsData(diffDateRows);//원본
},[rows]);

return (
    <div className={classes.root}>
        <h4 style={{color:'black',textAlign: "left", paddingTop:'7px', fontSize:'1rem'}}>HISTORY</h4>
        <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
            stickyHeader={true}
        >
            <TableHead style={{height:'40px'}}>
                <TableRow style={{borderTop:'1px solid',borderColor:'#dddddd'}}>
                    <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>기준 일시</span></TableCell>
                    <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>이슈 사항</span></TableCell>
                    <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>선박명</span></TableCell>
                    <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>항차</span></TableCell>
                    <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>{ieType=='E'?'출항지':'도착항'}</span></TableCell>
                    <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>터미널</span></TableCell>
                    <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>접안(예정)일시</span></TableCell>
                    {ieType=='E'&&<TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>출항(예정)일시</span></TableCell>}
                </TableRow>
                </TableHead>
            <TableBody>
            {rowsData.length>0 
            ?rowsData.map((row, index, arr)=>
                <TableRow style ={{height:'30px'}} key = {index} >
                    <TableCell align="center" className={classes.row} padding="none">
                        {rowsData.length==index+1// 가장 첫번째 히스토리는 수출적하목록 신고
                        ?toDateParse(row?.presenting_date)||toDateParse(ownMrnCalSch?.present_date)||toDateParse(row?.history_key)||''
                        :toDateParse(row?.history_key)||''}
                    </TableCell>
                    <TableCell align="center" className={classes.row} padding="none">
                        {rowsData.length==index+1// 가장 첫번째 히스토리는 수출적하목록 신고
                        ? ieType=='E'?'수출적하목록 신고': '수입적하목록 신고'
                        :'선박 스케줄 변경'}</TableCell>
                    <TableCell align="center" className={classes.row} padding="none">{row?.vessel_name||''}</TableCell>
                    <TableCell align="center" className={classes.row} padding="none">{row?.voyage_no||''}</TableCell>
                    <TableCell align="center" className={classes.row} padding="none">{ieType=='E'?ownMrnCalSch?.pol||'':ownMrnCalSch?.pod||''}</TableCell>
                    <TableCell align="center" className={classes.row} padding="none">{row?.tml_name? `${row?.tml_name}(${row?.terminal})`: row?.terminal||''}</TableCell>

                    <TableCell align="center" className={classes.row} padding="none"
                        // style ={{ color : `${(rowsData.length!==index+1&&arr[index+1]?.load_begin_date!==arr[index]?.load_begin_date)? 'red' : 'black'}`}}
                    >
                        <span style ={{ color : `${row?.diffBeginDate? 'red' : 'black'}`}}>{toDateParse((row?.load_begin_date||'').substr(0,8))||''}</span>
                        &nbsp;<span style ={{ color : `${row?.diffBeginTime? 'red' : 'black'}`}}>{toTimeParse((row?.load_begin_date||'').substr(8))||''}</span>
                    </TableCell>
                    {ieType=='E'&&<TableCell align="center" className={classes.row} padding="none"
                        // style ={{ color : `${(rowsData.length!==index+1&&arr[index+1]?.load_end_date!==arr[index]?.load_end_date)? 'red' : 'black'}`}}
                    >
                        <span style ={{ color : `${row?.diffEndDate? 'red' : 'black'}`}}>{toDateParse((row?.load_end_date||'').substr(0,8))||''}</span>
                        &nbsp;<span style ={{ color : `${row?.diffEndTime? 'red' : 'black'}`}}>{toTimeParse((row?.load_end_date||'').substr(8))||''}</span>
                        {/* {toDateParse(row?.load_end_date)||''}*/}
                        </TableCell>} 
                    {/* <TableCell align="center" className={classes.row} padding="none"
                        style ={{ color : `${(rowsData.length!==index+1&&arr[index+1]?.load_begin_date!==arr[index]?.load_begin_date)? 'red' : 'black'}`}}
                    >{toDateParse(row?.load_begin_date)||''}</TableCell>
                    {ieType=='E'&&<TableCell align="center" className={classes.row} padding="none"
                        style ={{ color : `${(rowsData.length!==index+1&&arr[index+1]?.load_end_date!==arr[index]?.load_end_date)? 'red' : 'black'}`}}
                    >{toDateParse(row?.load_end_date)||''}</TableCell>} */}
                </TableRow>)
            : (<TableRow style={{minHeight: '30px'}}>
                <TableCell colSpan= {ieType=='E'?"8":'7'} align="center" style={{fontSize:"13px"}}>NO DATA</TableCell>	
                </TableRow>
            )}
            </TableBody>
        </Table>
    </div>
);
}
