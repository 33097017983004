import React,{ useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import CardBody from "muiComponents/Card/CardBody.js";
import {
	Collapse,
	Card,
	CardContent,
	TextField,
	TableFooter,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Grid,
	Divider,
	Container,
	Avatar,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper
} from "@material-ui/core";

import Button from "muiComponents/CustomButtons/Button.js";

import {
	Chat as Chat,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	Search as SearchIcon
}from "@material-ui/icons";
import axios from 'axios';
import Filesaver from 'file-saver'
import {yellow, red} from '@material-ui/core/colors'
import Pagination from '@material-ui/lab/Pagination';
import { useHistory } from "react-router-dom";
const ico_m_new = require("assets/img/ico_m_new.gif");

const useRowStyles = makeStyles((theme)=> ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	small: {
		width: theme.spacing(2),
		height: theme.spacing(2),
		backgroundColor: red['700']
	}
}));

function Row(props) {
	const {totalBoardCount ,userData} =props;
	const history = useHistory();
	// const classes = useRowStyles();
	const [row,setRow] = useState(props.row);
	// const [rowNum,setRowNum] = useState(0);
	// const [open, setOpen] = useState(false);0125
	useEffect(()=>{
		setRow(props.row);
	},[props.row,totalBoardCount])
	
	// 0125
	// useEffect(() => {
    //     if(row.board_id===props.num) {
    //         setOpen(true)
    //     }else {
    //         setOpen(false);
    //     }
	// },[props.num]);
	// useEffect(() => {
    //     if(open) {
    //         axios.post("/api/updateBoardHits",{board_id:row.board_id}).then(
    //             res=>{
                    
    //                 if(res.data === 1) {
    //                     setRow(prevState => {
    //                         return {...prevState, hit_count:String(Number(prevState.hit_count)+1)}
    //                     });
    //                 }
    //             }
    //         )
    //     }
    // },[open])

	const onButtonClick = () => {
		if(userData){
			history.push({
				pathname:"/svc/boardDetail",
				...props.row,
			})
		}else{
			props.onAlert("error","로그인 이후 이용해주세요." );
		}
	}
	// const fileDownload = (value) => {0125
    
    //     axios.post("/api/boardFIleDownLoad",{fileName:value.file_name,filePath:value.file_path},{responseType:"arraybuffer"}).then(
    //         res => {
    //         Filesaver.saveAs(new Blob([res.data]),value.real_file_name)
    //         });
	// }
	
	return (

	  	<React.Fragment>
			<TableRow onClick={() => onButtonClick()}>
				<TableCell component="th" scope="row" style={{paddingTop:'10px',paddingBottom:'10px'}}>{props.row.num}</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'
				,color: row.now_top_flag=='Y'?  '#06c': ''
				,fontWeight: row.now_top_flag=='Y'? '600': ''
				,overflowWrap:'break-word'}}
				>{row.title}  {row.new_notice==='Y'?<img src={ico_m_new}/>:''}</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'}}>운영자</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.hit_count}</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.insert_date}</TableCell>
			</TableRow>
			{/* <TableRow>
		  		<TableCell style={{paddingBottom: 0, paddingTop: 0 , paddingLeft:'3px',paddingRight:'3px' }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
			  			<Box margin={1}>
						  	<GridItem>
								<Grid container spacing={1} style={{flex:'1 1 auto', padding:'1.25rem'}}>
									<Grid item xs={4} sm={4} md={4}>
										<GridItem>
											<Grid container spacing={1}>
												<Grid item xs={6} sm={6} md={6} style={{textAlignLast:'center'}}>
													<span style={{fontWeight:'bold'}}>작성자</span>
												</Grid>
												<Grid item xs={6} sm={6} md={6}>
													<span>{row.author_name}</span>
												</Grid>
											</Grid>
										</GridItem>
									</Grid>
									<Grid item xs={4} sm={4} md={4}>
										<GridItem>
											<Grid container spacing={1}>
												<Grid item xs={6} sm={6} md={6} style={{textAlignLast:'center'}}>
													<span style={{fontWeight:'bold'}}>조회수</span>
												</Grid>
												<Grid item xs={6} sm={6} md={6}>
													<span>{row.hit_count}</span>
												</Grid>
											</Grid>
										</GridItem>
									</Grid>
								</Grid>
							</GridItem>
							<GridItem>
								<Grid container>
									<Divider style={{backgroundColor:'#666666'}}/>
								</Grid>

								<Grid container spacing={1} style={{flex:'1 1 auto', padding:'1.25rem'}}>
									<div dangerouslySetInnerHTML={{__html:row.content}}/>
								</Grid>
							</GridItem>
							<GridItem>
								{row.attach_files &&  <>*/}
								{/* <Grid container>
									<Divider style={{backgroundColor:'#666666'}}/>
								</Grid> */}
								{/*<Grid container spacing={1}>
									{row.attach_files.map((value,idx) => {
										return(
											<Button  key={idx} onClick={()=> fileDownload(value)}>
												{value.real_file_name}
											</Button>
										)
									})}

								</Grid></>}
							</GridItem>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow> */}
		</React.Fragment>
	);
  }

export default function Board(props) {

	const history = useHistory();
    const classes = useRowStyles();
    const [boardData,setBoardData] = useState([]);
	const [selectValue,setSelectValue] = useState("1");
	// const [CopySelectValue,setCopySelectValue] = useState("");0125
	// const [openPage, setOpenPage] = useState(0);
	// const [Num,setNum] = useState(1);
	const [subject, setSubject] = useState("");
	// const [copySubject, setCopySubject] = useState("");0125
	const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
	const [totalBoardCount,setTotalBoardCount] = useState(0);
	// const [openAlert, setOpenAlert] = useState(false);
	// const [message, setMessage] = useState('');
	// const [status, setStatus] = useState('');
	const pageCount = 10;

    useEffect(() => {
		onSubmit();
    }, []);

	useEffect(() => {
		onSubmit();
    }, [page]);
	// useEffect(() => {
    //     if(props.location.state) {
    //         setOpenPage(props.location.state.param);
    //     }
    // },[props.location]);
	const onSubmit = () => {
		axios.post("/api/notice",{num:1, gubun:selectValue, keyword:subject, service:'plismplus',num:page})
		.then(res => {
			// setNum(1);
			// setCopySelectValue(selectValue);
			// setCopySubject(subject);
			setBoardData(res.data)
			if(res.data.length>0){
				fncListCount()
			}
		})
		.catch(err => {
			if(err.response.status === 403 || err.response.status === 401) {
			//  setOpenJoin(true);
			}
		});
	  }

	  const fncListCount = () => {
		axios.post("/api/noticeCount",{num:1, gubun:selectValue, keyword:subject, service:'plismplus',num:page})
		.then(res => {
			if(res.data.length>0){
				//총페이지 수
				setTotalCount(Math.ceil(res.data[0].total_count/10))
				setTotalBoardCount(res.data[0].total_count);
			}
		})
	}

	const handleChange = (event, value) => {
		setPage(Number(value));
	  }
	
	const boardWrite = () => {
		history.push({
			pathname:"/svc/boardwrite",
			...props.userData,
		})
	}

    return(
		<React.Fragment>
			<Card className="m-4">
				<CardContent>
					<h4 style={{textAlignLast:'left',color:'black'}}><Chat style={{color:'#00acc1'}}></Chat> 업무공지</h4>
					<GridContainer className="mt-4">
						<GridItem xs={6} sm={4} md={4} >
							<TextField id="Subject" variant="outlined"  onChange={(event)=>setSubject(event.target.value)} fullWidth />
						</GridItem>
						<GridItem xs={6} sm={2} md={2} >
							<FormControl variant="outlined" >
								<InputLabel>OPTION</InputLabel>
								<Select
									label="option"
									value={selectValue}
									onChange={(event)=> setSelectValue(event.target.value)}>
									<MenuItem value="0">전체</MenuItem>
									<MenuItem value="1">제목+내용</MenuItem>
									<MenuItem value="2">작성자</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs={12} sm={6} md={6} >
							<div style={{textAlign:'-webkit-right'}}>
								<Button color="info" endIcon={<SearchIcon/>}  onClick={()=> onSubmit()}>Search</Button>
							</div>
						</GridItem>
					</GridContainer>
				</CardContent>
				<CardBody>
					<div className="mb-1">
						Total:{totalBoardCount}
					</div>
					<Table>
						<TableHead style={{backgroundColor:'#f2fefd'}} className={classes["info" + "TableHeader"]}>
							<TableRow style={{borderBottomStyle:'solid',borderBottomColor:'#00bcd4'}}>
								<TableCell style={{width:'15%'}}>순번</TableCell>
								<TableCell>제목</TableCell>
								<TableCell style={{width:'10%'}}>작성자</TableCell>
								<TableCell style={{width:'10%'}}>조회수</TableCell>
								<TableCell style={{width:'20%'}}>게시일</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{boardData.map((prop, key) => {
								return (
									<Row key={key} 
									// openNum={(e) => setOpenPage(e)} 
									row={prop} 
									// num={openPage} 
									totalBoardCount={totalBoardCount} userData={props.userData} onAlert={props.onAlert}/>
								);
							})}
						</TableBody>
						<TableFooter>
						{(boardData.length > 0 ?
							<TableRow>
								<TableCell colSpan={5} style={{paddingLeft:'35%'}}>
									<Pagination
										count={totalCount}
										page={page}
										onChange={handleChange}
										defaultPage={1}
										color="primary"
										size="small"
										showFirstButton
										showLastButton
										>
									</Pagination> 
								</TableCell> 
							</TableRow>: null )}
							<TableRow >
							{props.userData&&(props.userData.klnet_id === "KLTEST01")?
								<TableCell align="right" colSpan={5} style={{borderTop:'2px solid #06b3ba'}}>
									<Button style={{paddingBottom:'8px'}}color="info" onClick={()=> boardWrite()}>업무공지 작성</Button>
								</TableCell>
							:<></>
							} 
							</TableRow>
						</TableFooter>
					</Table>
				</CardBody>
			</Card>
		</React.Fragment>
		
    );
}