/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState,useEffect } from 'react';
import { Row, Col, FormGroup,Label} from "reactstrap";
import InputValid from "components/CustomInput/InputValid.js";

export default function Notify(props) {
		
	const {loadFormData} = props;	
	const [notifyData,setNotifyData] = useState({});
	
	useEffect(() => {
		setNotifyData(loadFormData);
	},[loadFormData]);

	const onHandleReturnVal = (event,name) => {		
		let list = {...notifyData, [name]:(event.target.value.toUpperCase())||null};
		setNotifyData(list);
	}
	
	const onPropsReturn = ()=> {
		props.propsData(notifyData);
	}
	return (
		<>
			<Row>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0"><font color="red">*</font>BookMark Name</Label>
						<InputValid 
							type="text"
							name="noti_bookmark_name"
							id="noti_bookmark_name"
							maxLength="35"
							value={notifyData.notify_bookmark_name?notifyData.notify_bookmark_name:''}
							onChange={(e)=>onHandleReturnVal(e, 'notify_bookmark_name')}
							onBlur={onPropsReturn}
							validtype="text" 
							required={true}
							feedid="notify"
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xl="5" lg="5" md="12">
					<FormGroup>
						<Label className="mb-0">Name1</Label>
						<InputValid 
							type="text"
							name="noti_name1"
							id="noti_name1"
							maxLength="35"
							value={notifyData.noti_name1?notifyData.noti_name1:''}
							onChange={(e)=>onHandleReturnVal(e, 'noti_name1')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="notify"
						/>
					</FormGroup>
				</Col>
				<Col xl="5" lg="5" md="12">
					<FormGroup>
						<Label className="mb-0">Name2</Label>
						<InputValid 
							type="text"
							name="noti_name2"
							id="noti_name2"
							maxLength="35"
							value={notifyData.noti_name2?notifyData.noti_name2:''}
							onChange={(e)=>onHandleReturnVal(e, 'noti_name2')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="notify"
						/>
					</FormGroup>
				</Col>        
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">Address1</Label>
						<InputValid 
							type="text"
							name="noti_address1"
							id="noti_address1"
							maxLength="35"
							value={notifyData.noti_address1?notifyData.noti_address1:''}
							onChange={(e)=>onHandleReturnVal(e, 'noti_address1')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="notify"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">Address2</Label>
						<InputValid 
							type="text"
							name="noti_address2"
							id="noti_address2"
							maxLength="35"
							value={notifyData.noti_address2?notifyData.noti_address2:''}
							onChange={(e)=>onHandleReturnVal(e, 'noti_address2')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="notify"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">Address3</Label>
						<InputValid 
							type="text"
							name="noti_address3"
							id="noti_address3"
							maxLength="35"
							value={notifyData.noti_address3?notifyData.noti_address3:''}
							onChange={(e)=>onHandleReturnVal(e, 'noti_address3')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="notify"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">Address4</Label>
						<InputValid 
							type="text"
							name="noti_address4"
							id="noti_address4"
							maxLength="35"
							value={notifyData.noti_address4?notifyData.noti_address4:''}
							onChange={(e)=>onHandleReturnVal(e, 'noti_address4')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="notify"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">Address5</Label>
						<InputValid 
							type="text"
							name="noti_address5"
							id="noti_address5"
							maxLength="35"
							value={notifyData.noti_address5?notifyData.noti_address5:''}
							onChange={(e)=>onHandleReturnVal(e, 'noti_address5')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
							feedid="notify"
						/>
					</FormGroup>
				</Col>
			</Row>
    	</>
    );
}