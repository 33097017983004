/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row,Col, CardBody, FormGroup,Label,Input, Card,Table, NavItem, NavLink, TabContent, TabPane, Alert as ReactAlert, Nav, FormFeedback, CardFooter, Button}
     from "reactstrap";
import axios from 'axios';
import Select from "react-select";
import classnames from 'classnames';
import * as validation from 'components/common/validation.js';
export default function Bookmark(props) {

  	const {userData,bookmark,titleProps, loadData} = props;
	  
	// modal 창을 위한 state
	//const [coll, setColl] = useState(false);
	//const [open, setOpen] = useState(false);
	const [titleRelation, setTitleRelation] = useState({});
	const [newBookmarkName, setNewBookmarkName] = useState("");
	const [bookingStatus, setBookingStatus] = useState(true);
    const [bookingExistsNo, setBookingExistsNo] = useState(false);
	const [relationList, setRelationList] = useState([])
	const [activeTab, setActiveTab] = useState('1');
  	useEffect(() => {
	  	setTitleRelation(titleProps);
	},[titleProps]);
	useEffect(()=> {
		setRelationList(props.relationList)
	},[props.relationList])
    useEffect(()=>{
		if( loadData ) {
            if( "RA" === loadData.status_cus ) {
                setBookingStatus(true);
            } else {
                setBookingStatus(false);
            }
        } else {
            setBookingStatus(false);
        }

        let check = false;
		if(bookmark) {
			bookmark.forEach((data, i)=>{
				if( loadData && data.bookmark_sr_no ) {
					if( loadData.sr_no === data.bookmark_sr_no ) {
						setNewBookmarkName( data.bookmark_name );
						setBookingExistsNo(true);
						check = true;
					}
				}
			})
		}
        if( !check ) {
            setNewBookmarkName("");
            setBookingExistsNo(false);
        }
    },[bookmark, loadData]);

	const fncOnchangeRelation = (value,name) => {
		// relationList 정보에 취합
		let row = relationList.find( function( item ) {
			return item.reference_type === name;
		});

		if( row ) {
			row.reference_seq = value?value.value:'';
			row.bookmark_name = value?value.label:'';
			
		} else {
			relationList.push({
				'reference_type': name,
				'reference_seq': value.value,
				'bookmark_name': value.label,
			});
		}
		setRelationList([...relationList]);

		var list={};
		if(name === 'BOOKING') {
			if(value) {
				list={...list,'booking_label':value.label,'booking_value':value.value};
			}else {
				list={...list,'booking_label':'','booking_value':''};
			}
		} else if(name === 'SCHEDULE') {
			if(value) {
				list={...list,'schedule_label':value.label,'schedule_value':value.value};
			}else {
				list={...list,'schedule_label':'','schedule_value':''};
			}
		//} else if(name === 'CARRIER') {
		//	  list={...list,'carrier_label':value.label,'carrier_value':value.value};
		} else if(name === 'SHIPPER') {
			if(value) {
				list={...list,'shipper_label':value.label,'shipper_value':value.value};
			}else {
				list={...list,'shipper_label':'','shipper_value':''};
			}
		} else if(name === 'CONSIGNEE') {
			if(value) {
				list={...list,'consignee_label':value.label,'consignee_value':value.value};
			}else {
				list={...list,'consignee_label':'','consignee_value':''};
			}
		} else if(name === 'NOTIFY') {
			if(value) {
				list={...list,'notify_label':value.label,'notify_value':value.value};
			}else {
				list={...list,'notify_label':'','notify_value':''};
			}
		} else if(name === 'CARGO') {
			if(value) {
				list={...list,'cargo_label':value.label,'cargo_value':value.value};
			}else {
				list={...list,'cargo_label':'','cargo_value':''};
			}
		} else if(name === 'C_SHIPPER') {
			if(value) {
				list={...list,'c_shipper_label':value.label,'c_shipper_value':value.value};
			}else {
				list={...list,'c_shipper_label':'','c_shipper_value':''};
			}
		} else if(name === 'C_CONSIGNEE') {
			if(value) {
				list={...list,'c_consignee_label':value.label,'c_consignee_value':value.value};
			}else {
				list={...list,'c_consignee_label':'','c_consignee_value':''};
			}
		} else if(name === 'C_NOTIFY') {
			if(value) {
				list={...list,'c_notify_label':value.label,'c_notify_value':value.value};
			}else {
				list={...list,'c_notify_label':'','c_notify_value':''};
			}
		}
		setTitleRelation({...titleRelation,...list});
		props.insertProps({...titleRelation,...list});
	} 
	const fncOnChange = (value,name) => {
		setTitleRelation({...titleRelation,[name]:value});
	}
	
	const fncSelectBookmark = (data)=> { 
		props.selectProps(data);
	}
	
	const fncOnBlur =()=>{
		props.insertProps(titleRelation);
	}
	const clickTab = (tab) => {
        if(activeTab !== tab ) {
            setActiveTab(tab);
        }
    }
	const fncSaveBookmark=()=>{
		if( userData && !userData.user_no ) {
			props.onNotiAlert("danger", validation.NOTLOGIN_MSG);
			return false;
		}
		if( bookingExistsNo ) {
			props.onNotiAlert("danger", "이미 해당 SR은 등록되어 있습니다.")
			return false;
		}
		if( !bookingStatus ) {
			props.onNotiAlert("danger", "마감된 SR만 가능합니다.")
			return false;
		}
		if( !newBookmarkName ) {
			props.onNotiAlert("danger", "Bookmark 명을 입력하세요.")
			return false;
		}
		if( loadData.bookmark_sr_no ) {
			props.onNotiAlert("danger", loadData.bookmark_sr_no+" SR번호로 이미 적용되어 있습니다.")
			return false;
		}
		bookmark.new_bookmark_name = newBookmarkName;
		axios.post("/shipper/saveSRBookmarkPaste",{user_no: userData?userData.user_no:null,bookmark: bookmark,relationList: relationList,sr: loadData,makeBooking:"Y",new_bookmark_name:newBookmarkName}).then(res=>{
			props.onLoadData("tt");
		});
	}
  	return (
		<Row className="mb-3">
          	<Col xl="4" lg="4" md="12">
              	<FormGroup style={{height:'400px',overflow:'auto'}} className="mb-0">
              		<Label className="mb-0">Bookmark List</Label>
					<CardBody className="bg-white p-0">
						<Table className="mb-0" responsive hover size="sm">
							<thead>
								<tr>
									<td className="p-2 bg-info">Name</td>
								</tr>
							</thead>
							<tbody>
							{(bookmark.length > 0) && bookmark.map((element,key)=>{
								return(
									<tr key={key} onClick={()=>fncSelectBookmark(element)}
										style={element.bookmark_seq===titleRelation.bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}}>
										<td >{element.bookmark_name}</td>
									</tr>
								)
							})}
							</tbody>
						</Table>
					</CardBody>
                </FormGroup>
			</Col>
			<Col xl="8" lg="8" md="12">
				<Nav tabs>
					<NavItem>
						<NavLink
							className={classnames({ active:activeTab === '1' })}
							onClick={()=>{clickTab('1');}}
							style={{paddingBottom: '10px'}}>
								<h6>BookMark 등록 </h6>
						</NavLink>
					</NavItem>
					{/* <NavItem>
						<NavLink
							className={classnames({ active:activeTab === '2' })}
							onClick={()=>{clickTab('2');}}
							style={{paddingBottom: '10px'}}>
							<h6>현재SR 등록</h6>
						</NavLink>
					</NavItem> */}
				</Nav>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="1">
						<Row>
							<Col>
								<Row>
									<Col>
										<Label className="mb-0">Bookmark</Label>
									</Col>
								</Row>
								<Row>
									<Col>
										<Card style={{zIndex:'70'}} className="card-raised card-form-horizontal no-transition mb-0">
											<CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
												<Row className="pb-2">
													<Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>
														Bookmark Name
													</Col>
													<Col>
														<Row>
															<Col className="col-10 pr-0">
																<Input
																	type="text" 
																	name="bookmark_name" 
																	id="bookmark_name"
																	maxLength="35"
																	value={titleRelation.bookmark_name?titleRelation.bookmark_name:''}
																	onChange={(e)=>fncOnChange(e.target.value, 'bookmark_name')}
																	onBlur={()=>fncOnBlur()}/>
															</Col>
														</Row>
													</Col>
												</Row>
												<Row className="pb-2">
													<Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>
														BOOKING
													</Col>
													<Col>
														<Row>
															<Col className="col-10 pr-0">
																<Select
																	className="react-select react-select-primary"
																	name="BOOKING"
																	value={{
																			value:titleRelation.booking_value?titleRelation.booking_value:'',
																			label:titleRelation.booking_label?titleRelation.booking_label:'사용안함',
																		}}
																	onChange={(value)=>fncOnchangeRelation(value?value:null, 'BOOKING')}
																	options={props.booking}
																	placeholder="사용안함"
																	isClearable={titleRelation.booking_value?true:false}/>
															</Col>
														</Row>
													</Col>
												</Row>
												{/* <Row className="pb-2">
													<Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>
														SCHEDULE
													</Col>
													<Col>
														<Row>
															<Col className="col-10 pr-0">
																<Select
																	className="react-select react-select-primary"
																	name="SCHEDULE"
																	value={{
																		value:titleRelation.schedule_value?titleRelation.schedule_value:'',
																		label:titleRelation.schedule_label?titleRelation.schedule_label:'사용안함',
																	}}
																	onChange={(value)=>fncOnchangeRelation(value?value:null, 'SCHEDULE')}
																	options={props.schedule}
																	placeholder="사용안함"
																	isClearable={titleRelation.schedule_value?true:false}/>
															</Col>
														</Row>
													</Col>
												</Row> */}
												<Row className="pb-2">
													<Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>
														SHIPPER
													</Col>
													<Col>
														<Row>
															<Col className="col-10 pr-0">
																<Select
																	className="react-select react-select-primary"
																	name="SHIPPER"
																	value={{
																		value:titleRelation.shipper_value?titleRelation.shipper_value:'',
																		label:titleRelation.shipper_label?titleRelation.shipper_label:'사용안함',
																	}}
																	onChange={(value)=>fncOnchangeRelation(value?value:null, 'SHIPPER')}
																	options={props.shipper}
																	placeholder="사용안함"
																	isClearable={titleRelation.shipper_value?true:false}/>
															</Col>
														</Row>
													</Col>
												</Row>
												<Row className="pb-2">
													<Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>
														CONSIGNEE
													</Col>
													<Col>
														<Row>
															<Col className="col-10 pr-0">
																<Select
																	className="react-select react-select-primary"
																	name="CONSIGNEE"
																	value={{
																		value:titleRelation.consignee_value?titleRelation.consignee_value:'',
																		label:titleRelation.consignee_label?titleRelation.consignee_label:'사용안함',
																	}}
																	onChange={(value)=>fncOnchangeRelation(value?value:null, 'CONSIGNEE')}
																	options={props.consignee}
																	placeholder="사용안함"
																	isClearable={titleRelation.consignee_value?true:false}/>
															</Col>
														</Row>
													</Col>
												</Row>
												<Row className="pb-2">
													<Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>
														NOTIFY
													</Col>
													<Col>
														<Row>
															<Col className="col-10 pr-0">
																<Select
																	className="react-select react-select-primary"
																	name="NOTIFY"
																	value={{
																		value:titleRelation.notify_value?titleRelation.notify_value:'',
																		label:titleRelation.notify_label?titleRelation.notify_label:'사용안함',
																	}}
																	onChange={(value)=>fncOnchangeRelation(value?value:null, 'NOTIFY')}
																	options={props.notify}
																	placeholder="사용안함"
																	isClearable={titleRelation.notify_value?true:false}/>
															</Col>
														</Row>
													</Col>
												</Row>
												<Row className="pb-2">
													<Col className="mt-2 mb-0 col-5" style={{fontSize:'14px',color:'#696969',fontWeight:'500'}}>
														CARGO
													</Col>
													<Col>
														<Row>
															<Col className="col-10 pr-0">
																<Select
																	className="react-select react-select-primary"
																	name="CARGO"
																	value={{
																		value:titleRelation.cargo_value?titleRelation.cargo_value:'',
																		label:titleRelation.cargo_label?titleRelation.cargo_label:'사용안함',
																	}}
																	onChange={(value)=>fncOnchangeRelation(value?value:null, 'CARGO')}
																	options={props.cargo}
																	placeholder="사용안함"
																	isClearable={titleRelation.cargo_value?true:false}/>
															</Col>
														</Row>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</Col>
								</Row>
							</Col>
						</Row>
					</TabPane>
				</TabContent>
				<TabContent activeTab={activeTab}>
					<TabPane tabId="2">
						<Card style={{zIndex:'70'}} className="card-raised card-form-horizontal no-transition mb-0">
							<CardBody>
								<Row>
									<Col>
										<h6>현재 SR로 Bookmark로 등록합니다.</h6>
									</Col>
								</Row>
								<Row style={{paddingTop: '20px'}}>
									<Col>
										<FormGroup>
											<Label className="mb-0">전체 Bookmark Name</Label>
											<Input
												type="text" name="new_bookmark_name" id="new_bookmark_name"
												placeholder=""
												maxLength="35"
												value={newBookmarkName?newBookmarkName:''}
												onChange={(e)=>setNewBookmarkName(e.target.value)}
												invalid={newBookmarkName?false:true}
											/>
											<FormFeedback>{validation.REQ_MSG}</FormFeedback>
										</FormGroup>
									</Col>
								</Row>
								<Row style={{paddingTop: '10px'}}>
									<Col>
										<FormGroup className="mt-3" check>
											<Label check>
												<Input type="checkbox"
													disabled={true}
													checked={bookingStatus}
												/><span className="form-check-sign" />
												<span>SR 마감 여부(필수)</span>
											</Label>
										</FormGroup>
									</Col>
								</Row>
								<Row style={{paddingTop: '10px'}}>
									<Col>
										<FormGroup className="mt-3" check>
											<Label check>
												<Input type="checkbox"
													disabled={true}
													checked={bookingStatus}
												/><span className="form-check-sign" />
												<span>승인된 부킹 정보로 저장됩니다.</span><br/>
												<span>단, Bookmark 사용시엔 해당 Bookmark를 적용합니다.</span>
											</Label>
										</FormGroup>
									</Col>
								</Row>
								<Row style={{paddingTop: '10px'}}>
									{bookingExistsNo?<Col>
										<ReactAlert color="danger"><b>이미 해당 SR번호로 등록되어 있습니다.</b></ReactAlert>
									</Col>:<></>}
								</Row>
								<Row style={{paddingTop: '10px'}}>
									{loadData.bookmark_sr_no?<Col>
										<ReactAlert color="danger"><b>{loadData.bookmark_sr_no} 해당 SR으로 이미 적용되어 있습니다.</b></ReactAlert>
									</Col>:<></>}
								</Row>
							</CardBody>
							<CardFooter>
								<Row style={{textAlign: 'right'}}>
									<Col>
										<Button onClick={()=>fncSaveBookmark()}>생성하기</Button>
									</Col>
								</Row>
							</CardFooter>
						</Card>
					</TabPane>
				</TabContent>
			</Col>
		</Row>
	);
}