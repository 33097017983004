/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,Col, CardBody
    , Button,FormGroup, Table, UncontrolledTooltip} from "reactstrap";
import Document from "./Document.js";
import axios from 'axios';
import * as validation from 'muiComponents/common/validation.js';

export default function DocumentBookmark(props){
    
    const [open, setOpen] = useState(false);
    const [document, setDocument] = useState({});
    const [documentList, setDocumentList] = useState([]);
    const {userData} = props;


    const toggle = (params) => {
        setOpen(!open);
    }
    useEffect(() => {
        setDocument(props.booking);
    }, [props.booking]);

    useEffect(() => {
        setDocumentList(props.documentList);
    }, [props.documentList]);

    // New Document Bookmark
    const fncInitDocument = (e) => {
        e.preventDefault();
        setDocument({});
    }

    const fncValidation =()=> {
        if( !document.document_bookmark_name ) return false;
        if( document.shp_user_email ) {
            if( !validation.validationEmail(document.shp_user_email) ) return false;
        }
        return true;
    }
    // Save Document Bookmark
    const fncSaveDocumentBookmark = (e) => {
        e.preventDefault(e);
        if( !userData ) {
            props.onAlert("error",validation.NOTLOGIN_MSG);   
            return false;
        }
        if (!fncValidation()) return false;
        if( !document.document_bookmark_seq || '0' === document.document_bookmark_seq ) {
            insertBookingDocumentBookmark();
        } else {
            updateBookingDocumentBookmark();
        }
    }

    // Insert Document Bookmark
    const insertBookingDocumentBookmark = () => {
        axios.post("/shipper/insertBookingDocumentBookmark",{user_no : userData?userData.user_no:null,document}).then(res=>{
            props.onAlert("success", validation.SAVE_MSG);
            props.selectBookingDocumentBookmark();
            setDocument({});
        });
    }

    // Update Document Bookmark
    const updateBookingDocumentBookmark = () => {
        axios.post("/shipper/updateBookingDocumentBookmark",{user_no : userData?userData.user_no:null,document}).then(res => {
            props.onAlert("success", validation.SAVE_MSG);
            props.selectBookingDocumentBookmark();
        });
    }

    // Delete Document Bookmark
    const deleteBookingDocumentBookmark = () => {
        if( !document.document_bookmark_seq ) {
            props.onNotiAlert("danger", "삭제할 Bookmark를 선택하세요.");
            return false;
        }
        axios.post("/shipper/deleteBookingDocumentBookmark",{user_no : userData?userData.user_no:null,document}).then(res=>{
            props.onAlert("success", validation.DEL_MSG);
            props.selectBookingDocumentBookmark();
            setDocument({});
        });
    }

    const fncOnBlurDocument = (document) => {
        setDocument(document);
    }

    return (
        <>
            <Button className="pl-0 pr-0" 
                color="link" id="linebookmark"
                onClick={toggle.bind(this, 'B')}>
                    <i className="fa fa-bookmark-o fa-2x" />
            </Button>
            <UncontrolledTooltip delay={0} target="linebookmark">Bookmark</UncontrolledTooltip>
            <Modal isOpen={open} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Document Bookmark</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>Bookmark List</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xl="12" lg="12" md="12">
                            <FormGroup style={{height:'150px',overflow:'auto'}} className="mb-0">
                                <CardBody className="bg-white p-0">
                                    <Table className="mb-0" responsive hover size="sm">
                                        <thead>
                                            <tr>
                                                <td className="p-2 bg-info">No.</td>
                                                <td className="p-2 bg-info">Bookmark</td>
                                                <td className="p-2 bg-info">NAME</td>
                                                <td className="p-2 bg-info">TEL</td>
                                                <td className="p-2 bg-info">EMAIL</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {(documentList.length>0)?documentList.map((element,key)=>{
                                            return(
                                                <tr key={key} onClick={()=>{setDocument(element)}} style={element.document_bookmark_seq===document.doc_bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}}>
                                                    {/* <td>{element.document_bookmark_seq}</td> */}
                                                    <td>{documentList.length-key}</td>
                                                    <td>{element.document_bookmark_name}</td>
                                                    <td>{element.docu_user_name}</td>
                                                    <td>{element.docu_user_tel}</td>
                                                    <td>{element.docu_user_email}</td>
                                                </tr>
                                            )
                                        }):<></>}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>Bookmark Input</Col>
                    </Row>
                    <hr className="m-2"/>
                    <Document
                        document={document}
                        fncOnBlurDocument={fncOnBlurDocument}
                        openType="BOOK"                        
                        />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(e)=>fncInitDocument(e)}>New</Button>
                    <Button color="primary" onClick={(e)=>fncSaveDocumentBookmark(e)}>Save</Button>
                    <Button color="primary" onClick={(e)=>deleteBookingDocumentBookmark(e)}>Delete</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}