import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {CircularProgress} from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom :'15px',
        marginTop:'15px'
    },
    table: {
        minWidth: 750,
        minHeight:150,
        borderTop:'2px solid #00b1b7', 
        borderBottom:'2px solid #00b1b7',
        display:'flex'
    },
    tableHeader: {
        paddingTop:'0',paddingBottom:'0', borderBottom:'2px solid #00b1b7'
    },
    row:{
        fontSize:"12px",paddingLeft:'7px', paddingTop:'10px', paddingBottom:'10px', paddingRight:'7px',
    },
    tableHeaderSpan:{
        display:'block', textAlign: 'center',fontWeight: '500'
    }
}));

export default function SchCalHistory(props) {
const {setOpenMap, openMap,alertMessage} = props;
const classes = useStyles();
// const [rowsData, setRowsData] = useState([]);
const [linkUrl,setLinkUrl] = useState("");
const [loading,setLoading] = useState(false);

useEffect(() => {
    // setRowsData(parameter);//원본
    if(openMap)getShipMap(props?.parameter)
    return () => {
    };
},[openMap]);

const getShipMap = (data)=>{
    // if(!props.userData) {
    //     props.onAlert('error','로그인이 필요한 서비스입니다.')
    //     return ;
    // }
    let {imo, map_start_day , sch_vessel_name}= data;
        
    let destination = data?.end_port || data?.sch_pod ||'';
    // console.log( destination,data)

    if(!sch_vessel_name){setLinkUrl(''); return }
    axios.post("/api/getShipMapInfo",{
        imo: imo,///imo 올바르지 않음,, 수정필요
        vesselName: sch_vessel_name,
        startDate: map_start_day,
        destination: destination
    }).then(res => {
        if(res.data.length > 0) {
            setLoading(true)
            setLinkUrl(res.data);
        }else{
            throw new Error('no Data')
        }
    }).catch(error=>{
        setLinkUrl('');
        setOpenMap(false);
        if( error.response?.data?.message ) {
            alertMessage(error.response.data.message);
        } else {
            alertMessage("해당 선박은 실시간 선박 위치를 조회할 수 없습니다.");
        }
    });
}
return <>
    {openMap
    ?<div className={classes.root}>
        <h4 style={{color:'black',textAlign: "left", paddingTop:'7px', fontSize:'1rem'}}>실시간 선박 위치</h4>
        <form target="maplink"  className={classes.table}> 
        {loading?<div style = {{width:'100%', display:'flex', justifyContent:'center', alignSelf:'center'}}> <CircularProgress color="primary" /></div>:<></>}
        <iframe name="maplink" src={linkUrl} title={"tackingFrame"} 
            width="100%" height="580" display='block' border='none' position="absolute"
            style={loading? {visibility:'hidden', height:'30px', width:'0px'}:{border:'none'}}
            allowFullScreen
            onLoad={()=>{setLoading(false);}}></iframe>
        </form>
    </div>
    :<div style = {{width:'100%',height:'100px', display:'flex', justifyContent:'center', alignSelf:'center'}}> </div>}
</>}
