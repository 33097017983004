import React,{ useState,useEffect } from "react";
import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Table from "muiComponents/Table/TableSmallLine.js";
import {TableCell, Tooltip, Popover, Collapse, Box, TableRow, Grid, TableBody} from "@material-ui/core";
import Button from "muiComponents/CustomButtons/Button.js";
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoatOutlined';
import SchLinePicPop from "views/Schedule/SchLinePicPop.js";
import TerminalSch from "views/Schedule/TerminalScheduleList.js";
import ChargeSch from "views/Schedule/OceanFreightSearch.js";
import ShipMap from "muiComponents/Map/ShipMap.js";
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'
import axios from 'axios';
import { Link , useHistory } from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import SwapCalls from "@material-ui/icons/SwapCalls";

import * as validation from 'muiComponents/common/validation.js';

import moment from 'moment';

const styles = {
  header: {
    padding: '5px 10px !important',
    borderTop:'2px solid #00b1b7', 
    borderBottom:'2px solid #00b1b7',
    color:'#717172',
    fontWeight:'bold',
    textAlign: 'center',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
  },
  grid: {
    padding: '0px 10px !important',
    textAlign: 'center',
    alignSelf: 'center'
  },
   gridLabel: {
		    textAlign:'left',
		    marginTop:'12px',
		  },
};
const useStyles = makeStyles(styles);

export default function FclScheduleTable(props) {
	const classes = useStyles();
	const {propsData,curPage ,userData,alertMessage,...rest} = props;
	const [area,setArea] = React.useState([]);
	const [popupComp,setPopupComp] = React.useState(null);
	
	const openPopup =async (gubun,data)=> { 
	    if(gubun === 'V') {
			var start_port = data?data.start_port:'KRPUS';
		    var terminalArea = start_port.substring(2,5);
		    // console.log("terminalArea:",terminalArea);
		    
			setArea(area);
			
			axios.post("/api/getTerminalCodeList",{area:terminalArea})
			.then(res => {
			
				//var open = true;
				//setOpen2(open);
				if(res.data) {
				setPopupComp(<Popover
			                 id="pop2"
			                 open={true}
			                 onClose={()=>setPopupComp(null)}
			                 anchorReference="anchorPosition"
			                 anchorPosition={{top:100,left:550}}
			                 anchorOrigin={{vertical:'center',horizontal:'center',}}
			                 transformOrigin={{vertical:'center',horizontal:'center'}}
			               	>
			    				<TerminalSch detailParam={data} startDay={data.start_day} areaProps={terminalArea} terminalList={res.data} onClose={()=>setPopupComp(null)}  {...props}/>
	
			               </Popover>);
				}
		
			});
		
	    } else if(gubun === 'L') {
	    	setPopupComp(<Popover
                    id="pop"
	                    open={true}
	                    onClose={()=>setPopupComp(null)}
	                    anchorReference="anchorPosition"
	                    anchorPosition={{top:80,left:550}}
	                        anchorOrigin={{vertical:'bottom',horizontal:'center',}}
	                        transformOrigin={{vertical:'top',horizontal:'center',}}
		                  >
						  <SchLinePicPop detailParam = {data} onClose={()=>setPopupComp(null)} {...props}/>
	                  </Popover>);
	    } else if(gubun === 'C') {
	    	setPopupComp(<Popover
                    id="pop3"
		                   open={true}
		                    onClose={()=>setPopupComp(null)}
		                    anchorReference="anchorPosition"
		                    anchorPosition={{top:0,left:550}}
		                        anchorOrigin={{vertical:'bottom',horizontal:'center',}}
		                        transformOrigin={{vertical:'top',horizontal:'center',}}
		                  >
						<ChargeSch detailParam = {data} onClose={()=>setPopupComp(null)} {...props}/>
	                  </Popover>);
	    } else if(gubun === 'M') {
			if(!userData){
				alertMessage(validation.NOTLOGIN_MSG, 'error');
				return false
			}
			
			await axios.post('/api/oraMenuAccessLog', 
			{modalName : '/svc/shipmap',
				user: userData ? userData.user_no : 'GUEST',});//pathName

	    	setPopupComp(								
	                  <Popover
	                    id="popover_map"
		                    open={true}
		                    onClose={()=>setPopupComp(null)}
	                        anchorReference="anchorPosition"
	                        anchorPosition={{top:80,left:550}}
		                    anchorOrigin={{vertical:'bottom',horizontal:'center',}}
		                    transformOrigin={{vertical:'top',horizontal:'center',}}>
		                    <ShipMap parameter={data} onClose={()=>setPopupComp(null)} {...props}>
	                    </ShipMap>
	                  </Popover>);	    	
	    }
		
	}

	return (
		<>
			<div style={{marginLeft:'20px',marginRight:'20px'}}>
				<GridContainer justifyContent="center" style={{backgroundColor:'#fafafa'}}>
						<GridItem lg={1} md={1} sm={3} xs={3} className={classes.header}>Carrier</GridItem>
						<GridItem lg={2} md={2} sm={3} xs={5} className={classes.header}>Vessel Name<br/>(선박위치조회)</GridItem>
						<GridItem lg={1} md={1} sm={2} xs={4} className={classes.header}>Voyage No</GridItem>
						<GridItem lg={2} md={2} sm={4} xs={5} className={classes.header}>Origin</GridItem>
						<GridItem lg={2} md={2} sm={4} xs={2} className={classes.header}>Destination</GridItem>
						<GridItem lg={1} md={1} sm={2} xs={6} className={classes.header}>T/Time</GridItem>
						<GridItem lg={1} md={1} sm={2} xs={5} className={classes.header}>T/S</GridItem>
						{/* <GridItem lg={1} md={1} sm={3} xs={2} className={classes.header}>Charge</GridItem> */}
						{/* <GridItem lg={1} md={1} sm={3} xs={3} className={classes.header}>T/S</GridItem> */}
						<GridItem lg={1} md={1} sm={2} xs={6} className={classes.header}>Booking</GridItem>
						<GridItem lg={1} md={1} sm={2} xs={5} className={classes.header}>Detail</GridItem>
				</GridContainer>
			</div>
			{propsData.length > 0?
				<div style={{marginLeft:'5px', marginRight:'5px'}} >

					{propsData.map((data,key) => {//console.log(data)
						return (
							<RowTable key={key} index={key + 1} data={data} openPopup={openPopup} {...props} />
						);
					}
					)}
				</div>:<div style={{padding:'15px 0',textAlignLast:'center',color:'#717172'}}>조회된 (스케쥴) 정보가 없습니다.</div>}
			{propsData.length > 0 && Number(curPage) !== Number(propsData[0].tot_page)?
			<div style={{marginLeft:'20px',marginRight:'20px',textAlign:'center'}}>
				<Button target="_blank" color="info" onClick={()=>props.nextPage(Number(curPage)+1)}>Next ({curPage}/{propsData[0].tot_page})</Button>
			</div>
			:<></>}   
			 {popupComp}
		</>
	);
}



function RowTable(props: { data: ReturnType<typeof createData> }) {
	let history = useHistory();
	const classes = useStyles();
	const {propsData,curPage ,userData,data, key,} = props;
	const [iconstate,setIconstate] = useState("add_circle");
	const [open, setOpen] = React.useState(false);
	const [tsList, setTsList] = useState([]);


	useEffect(() => {
		setIconstate("add_circle");
		setOpen(false);
		setTsList([]);
	}, [propsData]);

	const handleCollOpen = (tsYn) => {		
		if (!open) {
			setIconstate("remove_circle");
			setOpen(true);
			tsYn== "Y" ?selectTsList():selectDirList();
		} else {
			setIconstate("add_circle");
			setOpen(false);
		}
	}

	// const handleCollOpen2 = () => {
	// 	if (!open) {
	// 		setIconstate("remove_circle");
	// 		setOpen(true);
	// 		selectDirList();
	// 	} else {
	// 		setIconstate("add_circle");
	// 		setOpen(false);
	// 	}
	// }

	// TERMINAL ACTIVITY 조회
	const selectTsList = () => {
		return axios ({
			url:'/api/selectTsList',
			method:'POST',
			
			data: {
				xml_msg_id : data.xml_msg_id,
				xmldoc_seq: data.xmldoc_seq,
			}
		})
		.then(res => {
			let returnList = [];
			res.data.forEach( element => {
				//  console.log( element );
				let returnValue = [];
				if( 'NO_DATA' == element.message ){
					returnValue.push( '' );
					returnValue.push( '' );
				} else {
					returnValue.push( element.ts_seq+"");
					returnValue.push( element.vsl_name);
					returnValue.push( element.start_port_code);
					returnValue.push( element.end_port_code);
					returnValue.push( element.doc_closing_date&&element.doc_closing_date.length===12
										?moment(element.doc_closing_date,'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
										:element.doc_closing_date);
					returnValue.push( element.cargo_closing_date&&element.cargo_closing_date.length===12
										?moment(element.cargo_closing_date,'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm')
										:element.cargo_closing_date);
					returnValue.push( element.imo);
					returnValue.push( element.line_nm);
				}
				returnList.push(returnValue);
			});
			setTsList(returnList);
		})
		.catch(err => {
			// console.log(err);
		});

	}

		// TERMINAL ACTIVITY 조회
	const selectDirList = () => {
		return axios ({
			// url:'/sch/selectDirList',
			url:'/api/selectDirList',
			method:'POST',
			
			data: {...data}
		})
		.then(res => {
			let returnList = [];
			res.data.forEach( element => {
				//  console.log( element );
				let returnValue = [];
				if( 'NO_DATA' == element.message ){
					returnValue.push( '' );
					returnValue.push( '' );
				} else {
					returnValue.push( "1");
					returnValue.push( element.vsl_name);
					returnValue.push( element.start_port_code);
					returnValue.push( element.end_port_code);
					returnValue.push( element.doc_closing_date&&element.doc_closing_date.length===12?
						moment(element.doc_closing_date,'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm'):element.doc_closing_date);
					returnValue.push( element.cargo_closing_date&&element.cargo_closing_date.length===12?
						moment(element.cargo_closing_date,'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm'):element.cargo_closing_date);
					returnValue.push( element.imo);
					returnValue.push( element.line_nm);
				}
				returnList.push(returnValue);
			});
			setTsList(returnList);
		})
		.catch(err => {
			// console.log(err);
		});

	}
	const bkgHandler =(bkgType)=>{
		if(!props.userData){
			props.openLogin();
			return false;
		}
		history.push({
            pathname: 'W' === bkgType
			?`/svc/bookingWdfc`
			:'E'=== bkgType
				?'/svc/bkgPlism':`/svc/booking`,
			state:{
				...data,
				user_no:userData?userData.user_no:'',
				schedule_yn:'Y',
				sch_vessel_name:data?data.vsl_name:null,
				sch_vessel_voyage:data?data.voyage_no:null,
				sch_etd:data.sch_etd&&data.sch_etd.length>8?data.sch_etd.substr(0,8):data.sch_etd||null,
				// sch_pol:data?data.start_port:null,
				// sch_pod:data?data.end_port:null,
				// line_code:data?data.line_code:null,
				// sch_etd:data?data.start_day:null,
				// sch_eta:data?data.end_day:null,
			}
        })
	}
	return(
		<>
			<Card style={{margin:'5px 0'}} key={"table_"+key} >
				<CardBody style={{padding:'5px 10px'}}>
					<GridContainer justifyContent="center">
						<GridItem lg={1} md={1} sm={3} xs={3} className={classes.grid}>
							{/* {data.line_code} */}
							{data.logo_img?
							<LoadCarrierImage line_code={data.line_code} logo={data.logo_img} classStyle={"img-rounded img-responsive"} 
							width={42} height={40} kr_name={data.line_nm}
							/>
							:data.line_nm||data.line_code}
						</GridItem>
						<GridItem lg={2} md={2} sm={3} xs={5} className={classes.grid} style={{display:'flex', alignItems: 'center', justifyContent:'center'}}>
							<Tooltip title="Terminal Schedule"  style={{cursor: "pointer",textDecoration:"underline"}}><span onClick={()=>props.openPopup('V',data)} >{data.vsl_name}</span>
							</Tooltip>
							<Tooltip title="현재 선박위치 조회">
								<DirectionsBoatIcon style={{verticalAlign:'bottom',color:'#00acc1',width:'40px',height:'30px',cursor:'pointer'}} 
								onClick={()=>props.openPopup('M',data)} />
							</Tooltip>
						</GridItem>
						<GridItem lg={1} md={1} sm={2} xs={4} className={classes.grid}>{data.voyage_no}</GridItem>
						<GridItem lg={2} md={2} sm={4} xs={5} className={classes.grid}>{data.start_port_name}<br/>({data.start_day})</GridItem>
						<GridItem lg={2} md={2} sm={4} xs={5} className={classes.grid}>{data.end_port_name}<br/>({data.end_day})</GridItem>
						<GridItem lg={1} md={1} sm={2} xs={6} className={classes.grid}>{data.tt}</GridItem>
						<GridItem lg={1} md={1} sm={2} xs={2} className={classes.grid}>
							{data.ts_yn == "Y" ? "T/S": "DIR"}
						</GridItem>
						<GridItem lg={1} md={1} sm={2} xs={6} className={classes.grid}>
							{data.bkg_type
							?	data.booking_yn=='Y'
								?<Button size="sm" target="_blank" onClick={()=>bkgHandler(data.bkg_type)} color="info" >BOOKING</Button>
								:<Button size="sm" disabled  >BOOKING</Button>
							
							:data.line_url ?<Button size="sm" target="_blank" href={data.line_url} color="primary" >BOOKING</Button>
											:<Button size="sm" disabled  >BOOKING</Button>
							}
						</GridItem>
						<GridItem lg={1} md={1} sm={2} xs={2} className={classes.grid}>
							<Icon style={{color:'#00acc1',paddingTop:'2px'}} onClick={()=>handleCollOpen(data.ts_yn )}>{iconstate}</Icon>
												{/* : <Icon style={{color:'#00acc1',paddingTop:'2px'}} onClick={handleCollOpen2}>{iconstate}</Icon>} */}
												</GridItem>
							{/* {data.ts_yn == "Y" ? <Icon style={{color:'#00acc1',paddingTop:'2px'}} onClick={handleCollOpen}>{iconstate}</Icon>
												: <Icon style={{color:'#00acc1',paddingTop:'2px'}} onClick={handleCollOpen2}>{iconstate}</Icon>}</GridItem> */}
					</GridContainer>
				</CardBody>
				<GridItem style={{ paddingBottom: 0, paddingTop: 0, backgroundColor:'#f5f5f5' ,width: '100%' }} >
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1} style={{marginLeft:'0',marginRight:'0'}} >
							<Grid container justifyContent="center">
								<Grid item xs={12} sm={12} md={12} className="m-1">
									<span style ={{fontSize:'15px', fontWeight:'500'}}>
										<SwapCalls style={{color:'#00acc1',verticalAlign:'bottom'}} fontSize="medium"/> {data.ts_yn == "Y" ?'T/S':'DIR'}
									</span>
								</Grid>
								<Table
									expend={true}
									expandedArrow={false}
									tableHeaderColor={"info"}
									tableHead={["No","VESSEL", "POL", "POD", "DOC CLOSE", "CY CLOSE", "IMO No.", "CARRIER"]}
									tableData={tsList}
								/>
								<Grid item xs={12} sm={12} md={12} className="mb-1 pl-2">
									<span style ={{fontSize:'12px', fontWeight:'600'}}>
										{data.remark?'▶ REMARK : '+data.remark:''}
									</span>
								</Grid>
							</Grid>
						</Box>
					</Collapse>
				</GridItem>
			</Card>
		</>
	)
}