import React,{useState,useRef, useEffect} from 'react';
import { Link, useHistory} from "react-router-dom";
import cn from 'classnames';
// import ReactDatetime from "react-datetime";
import Moment from 'moment';
import Style from './main.module.css'
// import {CustomDatePicker} from 'components/CustomInput/reactstrap/CustomInput';
// import 'assets/css/paper-kit.css'
import CalendarBox from "muiComponents/CustomInput/CustomCalendar.js";

// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
import DatePickersCustom from 'muiComponents/CustomInput/DatePickersCustom';
import axios from 'axios';
import QnADetail from 'views/QnA/QnADetail';
const ico_m_new = require("assets/img/ico_m_new.gif");
const ico_qna_reply = require("assets/img/ico_qna_reply.gif");
const lock_icon = require("assets/img/lock-icon.png");

const BoardItemWrap = (props)=>{
    const history = useHistory();
    const {userData,alertMessage} =props;
    const [noticeList,setNoticeList]=useState({});
    const [boardList,setBoardList]=useState({});
    const [qnaList,setQnaList]=useState({});
    const [faqList,setFAQList]=useState({});
    const [notiList,setNotiList]=useState({});
    const [shipperboardList,setShipperboardList]=useState({});
    const [boardTab,setBoardTab]=useState(
        [{path:'/svc/plismboardlist', name:'공지사항',  active:true , data:{} , datafn: ()=>getCarrierNotiData()}
        ,{path:'/svc/board', name:'업무공지',  active:false, data:{}, datafn: ()=>getNoticData()}
        ,{path:'/svc/qna', name:'Q&A',  active:false, data:{}, datafn: ()=>getQnaData()}
        ,{path:'/svc/faq', name:'FAQ',  active:false, data:{}, datafn: ()=>getFaqData()}
        ,{path:'/svc/shipperboardlist', name:'선사게시판',  active:false, data:{}, datafn: ()=>getHapagBoardData()}
        ]
    );
    
    useEffect( ()=>{
        onActiveHandler(boardTab[0].name)
    },[])

    useEffect( ()=>{
        if(userData)
        getCarrierNotiData();
    },[userData])

    const onActiveHandler =async (itemName)=>{
        const old= [...boardTab];
        let newTarget =  old.map((item,index)=> 
            item.name==itemName 
            ? {...item, active: true}
            : {...item, active: false}
         )
         setBoardTab([...newTarget])     
         const result = await newTarget.find(x=>x.active).datafn()
    }

    const getCarrierNotiData=()=>{
        let list ;
        let bbs_id=userData&&(userData.role==='Y'||userData.klnet_id==='KLTEST01')?null:'BBSMSTR_100000000001';


        axios.post("/api/selectPlismBoardList",
            {bbs_id:bbs_id,
            bbs_user_in:'Y',
            lang:'ko',
            firstIndex:1, 
            recordCountPerPage:4, 
            searchCnd:0,
            searchWrd:null,
            sKlnetId:userData?userData.klnet_id:''})
		.then(res => {
            if(res.data.length>0){
                list = res.data.map(item =>{
                    const d= new Date();
                    const year=d.getFullYear();
                    const month=d.getMonth();
                    const day=d.getDate();
                    return {
                        key:'Noti'
                        ,board_id: item.RNUM 
                        ,title:item.NTT_SJ
                        ,author_name:item.FRST_REGISTER_NM
                        ,insert_date:item.FRST_REGISTER_PNTTM
                        ,now_top_flag:item.TOP_FLAG
                        ,is_new:new Date(item.FRST_REGISTER_PNTTM.substring(0,10))>new Date(year,month,day-30)}})
            } setNotiList(res.data);
        }).catch(err => {
            console.log(err)
        }).then(()=> {setBoardList (list)} );
    }

    const getNoticData=()=>{
        axios.post("/api/notice",{num:1, service:'plismplus'})
        .then(res => {
            let result = res.data.length>4? res.data.slice(0,4):res.data;
            let list= result.map(item =>{return {...item,key:'notice',is_new:item.new_notice&&item.new_notice==='Y'?true:false,author_name:'운영자'}})
            setNoticeList(list);
            setBoardList (list);
        })
        .catch(err => {
            console.log(err)
        });
    }

    const getQnaData=()=>{
        axios.post("/api/selectQnaList",{lang:'ko',firstIndex:1, recordCountPerPage:4,})
		.then(res => {
			let result = res.data.length>4? res.data.slice(0,4):res.data;
            let list =result.map(item =>{return {key:'Qna',board_id: item.RNUM ,title:item.NTT_SJ, reply_at:item.REPLY_AT,author_name:item.COMP_NAME,insert_date:item.FRST_REGISTER_PNTTM,password_yn:item.QNA_PASSWORD}})
            //console.log(result, list)
            setQnaList(result);
            setBoardList (list);
		})
    }

    const getFaqData=()=>{
        let list =[ {board_id: '1' ,title:  'FAQ - 준비 중',author_name:'관리자',insert_date:new Date()}]
        axios.post("/api/getFaqData",{rnum:4,})
        .then(res => {
            //console.log(res.data,res.data.length)
            if(res.data.length>0){
                list = res.data
                .map(item =>{
                    return {
                        key:'Faq'
                        ,...item
                        ,board_id: item.RNUM 
                        ,title:item.NTT_SJ
                        ,author_name:item.FRST_REGISTER_NM
                        ,insert_date:item.FRST_REGISTER_PNTTM}})
                // console.log(list)
            }
            setFAQList (list); 
        }).catch(err => {
            console.log(err)
        }).then(()=> {setBoardList (list)} );
    }

    const getHapagBoardData=()=>{
        let list ;
        axios.post("/api/selectPlismBoardHapagList",{})
		.then(res => {
            if(res.data.length>0){
                list = res.data.map(item =>{
                    const d= new Date();
                    const year=d.getFullYear();
                    const month=d.getMonth();
                    const day=d.getDate();
                    return {
                        ...item,
                        key:'Hapag'
                        ,board_id: item.RNUM 
                        ,title:item.NTT_SJ
                        ,author_name:item.FRST_REGISTER_NM
                        ,insert_date:item.FRST_REGISTER_PNTTM
                        ,is_new:new Date(item.FRST_REGISTER_PNTTM.substring(0,10))>new Date(year,month,day-30)}})
                        
            }
            setShipperboardList(list);
        }).catch(err => {
            console.log(err)
        }).then(()=> {setBoardList (list)} );
    }

    const onClick = (key,ind,isLock) => {
        if(key==='Qna'&&isLock!=='Y'){
            if(userData){
                history.push({
                    pathname:"/svc/qnadetail",
                    ...qnaList[ind],
                    login_user_no:userData.user_no,
                    login_user_role:userData.role,
                    login_user_name:userData.name,
                })
            }else{
                history.push({
                    pathname:"/svc/qna",
                })
            }
        }else if(key==='Qna'&&isLock==='Y'){
            history.push({
                pathname:"/svc/qna",
            })
        }else if (key==='notice'){
            if(userData){
                history.push({
                    pathname:"/svc/boardDetail",
                    ...noticeList[ind],
                    login_user_no:userData.user_no,
                    login_user_role:userData.role,
                    login_user_name:userData.name,
                })
            }else{
                history.push({
                    pathname:"/svc/board",
                })
            }
        }else if (key==='Noti'){
            if(userData){
                history.push({
                    pathname:"/svc/plismboarddetail",
                    ...notiList[ind],
                    login_user_no:userData.user_no,
                    login_user_role:userData.role,
                    login_user_name:userData.name,
                })
            }else{
                history.push({
                    pathname:"/svc/plismboardlist",
                })
            }
        }else if (key==='Faq'){
            if(userData){
                history.push({
                    pathname:"/svc/FAQDetail",
                    ...faqList[ind],
                    login_user_no:userData.user_no,
                    login_user_role:userData.role,
                    login_user_name:userData.name,
                })
            }else{
                history.push({
                    pathname:"/svc/faq",
                })
            }
        }else if (key==='Hapag'){
            if(userData){
                history.push({
                    pathname:"/svc/shipperboarddetail",
                    ...shipperboardList[ind],
                    login_user_no:userData.user_no,
                    login_user_role:userData.role,
                    login_user_name:userData.name,
                })
            }else{
                history.push({
                    pathname:"/svc/shipperboardlist",
                })
            }
        }
	}
    return(
    <>      
        <div className={Style.latestItemWrap}>
            <ul className={Style.tabmenuListWrap}>
                {boardTab.map((menu,ind,arr)=>
                    <li key = {ind} ><div className={menu.active?Style.on:''} 
                        onClick={()=>onActiveHandler(menu.name)} >{menu.name}</div></li>
                )}
            </ul>
            {boardTab&&boardTab.find(x=>x.active).path?
            <Link to={boardTab&&boardTab.find(x=>x.active).path} className={Style.btnMore}>
                <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-more01.png`} />
            </Link>
            :boardTab&&boardTab.find(x=>x.active).url?
            <a href={boardTab&&boardTab.find(x=>x.active).url} className={Style.btnMore}>
                <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-more01.png`} />
            </a>
            :
            <a href='#1' onClick={()=>alertMessage('준비중')} className={Style.btnMore}>
                <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-more01.png`} />
            </a>
            }
            <table className={Style.latestListWrap}>
                <colgroup>
                    <col width="100"/>
                    <col width=""/>
                    <col width="100"/>
                    <col width="100"/>
                </colgroup>
                <tbody>
                {boardList&&boardList.length>0&&boardList.map((row, ind, arry)=>
                    <tr key={ind}>
                        <td className={Style.al}>{row.board_id}</td>
                        {/* {row.top_flag&&row.top_flag==='Y'
                        ? */}
                        <td className={Style.al}>
                            <a onClick={e =>onClick(row.key, ind, row.password_yn)}
                            style={{color: row.now_top_flag=='Y'? '#06c': ''
                            ,fontWeight: row.now_top_flag=='Y'? '500': ''}}>
                            {row.title} {row.is_new &&<img src={ico_m_new}/>} {row.reply_at &&row.reply_at==='Y'&& <img src={ico_qna_reply}/>}
                            {row.password_yn &&row.password_yn==='Y'&& <img src={lock_icon}/> }
                            </a>
                        </td>
                        {/* :<td className={Style.al}>
                            <a onClick={e =>onClick(row.key, ind, row.password_yn)}>
                                {row.title} {row.is_new &&<img src={ico_m_new}/>} {row.reply_at &&row.reply_at==='Y'&& <img src={ico_qna_reply}/>} 
                                {row.password_yn &&row.password_yn==='Y'&& <img src={lock_icon}/> }
                            </a>
                        </td>} */}
                        <td>{row.author_name}</td>
                        <td className={Style.ar}>{Moment(row.insert_date).format('YYYY-MM-DD')}</td>
                    </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    </>)
}

export default BoardItemWrap;
