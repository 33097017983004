/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,
    Col, CardBody, Button,FormGroup, Table, UncontrolledTooltip,
    Nav, NavItem, TabContent, TabPane, NavLink, Card} from "reactstrap";
import {ContainerForm} from "./Container.js";
import Door from "./Door.js";
import axios from "axios";
import classnames from 'classnames';
import * as validation from 'components/common/validation.js';

export default function ContainerBookmark (props){
    const [open, setOpen] = useState(false);
    // Container
    const [container, setContainer] = useState({'cntr_soc_yn':'N'});
    const [containerBookmarkList, setContainerBookmarkList] = useState([]);
    const [activeTab, setActiveTab] = useState('1');
    // Container 와 Special Bookmark 관계 목록
    const [specialBookmarkRelationList, setSpecialBookmarkRelationList] = useState([{'key':1}]);
    const {userData} = props;
    // Container Bookmark 초기화
    useEffect(() => {
        setContainerBookmarkList(props.containerBookmarkList);
    },[props]);

    useEffect(()=>{
        if( container.container_bookmark_seq ) {
            selectBookingContainerSpecialBookmarkRelation();
        }
    },[container]);

    const fncInitContainerBookmark=(e)=>{
        e.preventDefault();
        // 초기값
        setContainer( {'cntr_soc_yn':'N'});
        setSpecialBookmarkRelationList([{'key':1}]);
    }

    const validationContainer=()=>{
        if( !container.container_bookmark_name ) return false;
        return true;
    }
    // Container Bookmark 저장
    const fncSaveContainerBookmark=(e)=>{
        e.preventDefault(e);
        if( !userData ) {
            props.onNotiAlert("danger", "로그인을 해주세요.");
            return false;
        }
        if( !validationContainer() ) return false;
        if( !container.container_bookmark_seq || '0' === container.container_bookmark_seq ) {
            insertBoookingContainerBookmark();
        } else {
            updateBoookingContainerBookmark();
        }
    }

    // Insert Container Bookmark
    const insertBoookingContainerBookmark = () => {
        axios.post("/shipper/insertBoookingContainerBookmark",{user_no : userData?userData.user_no:null,container,specialBookmarkRelationList}).then(res=>{
            props.onAlert("success", validation.SAVE_MSG);
            props.selectBookingContainerBookmark();
            props.selectBookingSpecialBookmark();
        });
    }

    // Update Container Bookmark
    const updateBoookingContainerBookmark = () => {
        axios.post("/shipper/updateBoookingContainerBookmark",{user_no : userData?userData.user_no:null,container,specialBookmarkRelationList}).then(res=>{
            props.onAlert("success", validation.SAVE_MSG);
            props.selectBookingContainerBookmark();
            props.selectBookingSpecialBookmark();
        });
    }

    // Container Bookmark 삭제
    const fncDeleteContainerBookmark=(e)=>{
        axios.post("/shipper/deleteBookingContainerBookmark",{user_no : userData?userData.user_no:null,container,}).then(res=>{
            props.onAlert("success", validation.DEL_MSG);
            props.selectBookingContainerBookmark();
            props.selectBookingSpecialBookmark();
            setContainer({});
        });
    }


    // Container Special Relation Bookmark 조회
    const selectBookingContainerSpecialBookmarkRelation = () => {
        axios.post("/shipper/selectBookingContainerSpecialBookmarkRelation",{ user_no: userData?userData.user_no:null,container}).then(res => {
            setSpecialBookmarkRelationList(res.data);
        });
    }
    const clickTab = (tab) => {
        if(activeTab !== tab ) setActiveTab(tab);
    }
    const toggle = (params) => {
        setOpen(!open);
    }
    const fncOnBlur = (container) => {
        setContainer(container);
    }

    const fncOnBlurDoor =(door)=>{
        setContainer(door)
    }
    return (
        <>
            <Button className="pl-0 pr-0" 
                color="link" id="linebookmark"
                onClick={toggle.bind(this, 'B')}>
                    <i className="fa fa-bookmark-o fa-2x" />
            </Button>
            <UncontrolledTooltip delay={0} target="linebookmark">Bookmark</UncontrolledTooltip>
            <Modal isOpen={open} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Container</ModalHeader>
                    <ModalBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active:activeTab === '1' })}
                                    onClick={()=>{clickTab('1');}}
                                    style={{paddingBottom: '20px'}}>
                                        <h3>Container</h3>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} >
                            <TabPane tabId="1">
                                <Row>
                                    <Col>Container Bookmark List</Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup style={{height:'150px',overflow:'auto'}} className="mb-0">
                                            <Card className="card-raised card-form-horizontal no-transition mb-0">
                                                <CardBody className="bg-white p-0">
                                                    <Table hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                <td className="p-2 bg-info">No.</td>
                                                                <td className="p-2 bg-info">Bookmark</td>
                                                                <td className="p-2 bg-info">SIZE/TYPE</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {containerBookmarkList.length>0&&containerBookmarkList.map((element,key)=>{
                                                            return(
                                                                <tr key={key} onClick={()=>{setContainer(element)}}
                                                                style={element.container_bookmark_seq===container.container_bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}}>
                                                                    {/* <td>{element.container_bookmark_seq}</td> */}
                                                                    <td>{containerBookmarkList.length-key}</td>
                                                                    <td>{element.container_bookmark_name}</td>
                                                                    <td>{element.cntr_code_name}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="3">
                                        <ContainerForm
                                            container={container}
                                            fncOnBlur={fncOnBlur}
                                            openType="BOOK"
                                            {...props}
                                            />
                                    </Col>
                                    <Col md="9">
                                        <Door
                                            door={container}
                                            booking={props.booking}
                                            openType="BOOK"
                                            fncOnBlurDoor={fncOnBlurDoor}
                                            {...props}
                                        />
                                    </Col>
                                </Row>
                                <ModalFooter>
                                    <Button color="primary" onClick={(e)=>fncInitContainerBookmark(e)}>New</Button>{' '}
                                    <Button color="primary" onClick={(e)=>fncSaveContainerBookmark(e)}>Save</Button>{' '}
                                    <Button color="primary" onClick={(e)=>fncDeleteContainerBookmark(e)}>Delete</Button>{' '}
                                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                                </ModalFooter>
                            </TabPane>
                        </TabContent>
                    </ModalBody>
            </Modal>
        </>
    );
}