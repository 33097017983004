    import React, { useState, useEffect, useRef } from 'react';
    
    import { makeStyles } from '@material-ui/core/styles';
    import Table from '@material-ui/core/Table';
    import TableBody from '@material-ui/core/TableBody';
    import TableCell from '@material-ui/core/TableCell';
    import TableHead from '@material-ui/core/TableHead';
    import TableRow from '@material-ui/core/TableRow';

    import {toDateParse,onHandleStatus } from './schCalHeader.js'

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            marginBottom :'15px',
            marginTop:'15px'
        },
        table: {
            minWidth: 750,
            minHeight:150,
            borderTop:'2px solid #00b1b7', 
            borderBottom:'2px solid #00b1b7',
        },
        tableHeader: {
            paddingTop:'0',paddingBottom:'0', borderBottom:'2px solid #00b1b7'
        },
        tableHeaderSpan:{
            display:'block', textAlign: 'center',fontWeight: '500'
        },
        row:{
            fontSize:"12px",paddingLeft:'7px', paddingTop:'10px', paddingBottom:'10px', paddingRight:'7px',
        }
    }));

    export default function SchCalStatus(props) {
    const {rows, ieType} = props;
    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);

    useEffect(() => {
        setRowsData(rows);//원본
        },[rows]);

    return (
        <div className={classes.root}>
            <h4 style={{color:'black',textAlign: "left", paddingTop:'7px', fontSize:'1rem'}}>현재 상태</h4>
            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={'small'}
                aria-label="enhanced table"
                stickyHeader={true}
            >
                <TableHead style={{height:'40px'}}>
                    <TableRow style={{borderTop:'1px solid',borderColor:'#dddddd'}}>
                        <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>MBL No.</span></TableCell>
                        <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>HBL No.</span></TableCell>
                        <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>상태</span></TableCell>
                        <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>선박명</span></TableCell>
                        <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>항차</span></TableCell>
                        <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>{ieType=='E'?'출항지':'도착항'}</span></TableCell>
                        <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>터미널</span></TableCell>
                        <TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>접안(예정)일시</span></TableCell>
                        {ieType=='E'&&<TableCell align="center" className={classes.tableHeader} padding="none"><span className={classes.tableHeaderSpan}>출항(예정)일시</span></TableCell>}
                    </TableRow>
                    </TableHead>
                <TableBody>
                {rowsData.length>0 
                ?rowsData.map((row, index)=>
                    <TableRow style ={{minHeight:'30px'}} key = {index} >
                        <TableCell align="center" className={classes.row} padding="none">{row?.mbl_no||'' }</TableCell>
                        <TableCell align="center" className={classes.row} padding="none">
                            {Number(row?.hbl_cnt) > 1
                            ?(<>{row?.hbl_group} 외 <br/> 총 {Number(row?.hbl_cnt)-1}건</>)//row?.hbl_group.split('|').map((x, ind, arr)=>{return <>{x}{ind!==arr.length-1?<br/>:''}</>}) 
                            :row?.hbl_group||'-'}
                        </TableCell>
                        <TableCell align="center" className={classes.row} padding="none">{onHandleStatus(ieType,row)}</TableCell>
                        <TableCell align="center" className={classes.row} padding="none">{row?.vessel_name||''}</TableCell>
                        <TableCell align="center" className={classes.row} padding="none">{row?.voyage||row?.sch_voyage_no||''}</TableCell>
                        <TableCell align="center" className={classes.row} padding="none">{ieType=='E'?row?.pol||'':row?.pod||''}</TableCell>
                        <TableCell align="center" className={classes.row} padding="none">{row?.tml_name? `${row?.tml_name}(${row?.sch_terminal})`: row?.sch_terminal||''}</TableCell>
                        <TableCell align="center" className={classes.row} padding="none">
                            {toDateParse(row?.sch_load_begin_date)||''}
                        </TableCell>
                        {ieType=='E'&&<TableCell align="center" className={classes.row} padding="none">
                            {toDateParse(row?.sch_load_end_date)||''}
                        </TableCell>}
                    </TableRow>)
                : (<TableRow style={{ minHeight: '30px'}}>
                    <TableCell colSpan= {ieType=='E'?"9":'8'} align="center" style={{fontSize:"13px"}}>NO DATA</TableCell>	
                    </TableRow>
                )}
                </TableBody>
            </Table>
        </div>
    );
    }
