import React,{ useState, useEffect, useRef } from "react";
import axios from 'axios';
import cn from 'classnames';
import {sub,format,isValid, differenceInMonths, add, differenceInCalendarMonths} from 'date-fns';
import useInterval from 'muiComponents/common/useInterval';
import * as validation from 'muiComponents/common/validation.js';
import SweetAlert from "react-bootstrap-sweetalert";
import {insertEventLog} from './ExpTrackingHeader.js';
import CardHeader from "muiComponents/Card/CardHeader.js";
import CardIcon from "muiComponents/Card/CardIcon.js";
// core components
import { makeStyles } from "@material-ui/core/styles";
import {TextField, Popover, Modal, Backdrop,
        Dialog, TableContainer, Table, TableHead, TableRow, FormControl,
        OutlinedInput, DialogActions, Icon, 
        TableCell, TableBody, Fab, Zoom,  withStyles, AccordionDetails } from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from '@material-ui/core/Grid';
import {Close,
    Search as SearchIcon,
    Clear as ClearIcon,
    Add as AddIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@material-ui/icons";

import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Button from "muiComponents/CustomButtons/Button.js";
import CalendarBox from "muiComponents/CustomInput/CustomCalendar.js";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
import {infoColor, successColor, roseColor} from "assets/jss/material-dashboard-pro-react.js";

import Style from './ExpTrackingAddModal.module.css';

import DetailTable from "./ExpTrackingTable.js";
import ExpTrackingAddModal from './ExpTrackingAddModal.js';
import ExpTrackingEmailForm from './ExpTrackingEmailForm.js';
import ShipMap from "muiComponents/Map/ShipMap.js";
import ExpTrackingServiceLineModal from './ExpTrackingServiceLineModal.js'

import {observer,useObserver} from 'mobx-react-lite';
import {webLinkStore} from 'store/webLinkStore.js';
import {userStore} from 'store/userStore.js';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const searchTypeList = [//{label:'전체' , value:null},
                        {label:'ETD(ATD)' , value:'etd'},
                        {label:'ETA(ATA)' , value:'eta'},
						];
                        
const useStyles = makeStyles((theme) => ({
    ...styles,
    inputAdornment: {
        marginRight: "18px",
        position: "relative"
    },
    outlinedInput:{
        paddingTop: '10.5px',
        paddingBottom: '10.5px'
    },
    selectInput:{paddingTop:'13px',paddingBottom:'13px'},
    outlinedBtn:{borderColor:'#00acc1',color:'#00acc1', '&:focusVisible' :{outline:'none'}},
    btnLabel:{fontWeight :500,display:'contents','&:focusVisible' :{outline:'none'}},
    focusVisible:{outline:'none'},
    modal:{
        display:'flex'
        ,alignItems:'center'
        ,justifyContent:'center'
    }
    ,modalfindForm:{width:'40%'}
}));
const useStyles1 = makeStyles((theme) => ({
    root: {
      position: 'fixed',
      bottom: theme.spacing(7),
      right: theme.spacing(5),
    },
  }));

  const Accordion = withStyles({
	root: {
	  boxShadow: 'none',
	  '&:not(:last-child)': {
		borderBottom: 0,
	  },
	  '&:before': {
		display: 'none',
	  },
	  '&$expanded': {
        
		margin: '0',
	  },
	},
	expanded: {},
  })(MuiAccordion);
  
export default function ExpTrackingList(props) {
	const {userData,alertMessage, mode} = props;
    const classes = useStyles();
    const classes1 = useStyles1();
	const [loading, setLoading] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    
    const [viewConfirmAlert, setViewConfirmAlert] = useState(null);
	const [trkListData, setTrkListData] = useState([]);

	//조회조건
	const [searchType,setSearchType] = useState(null);
    const [portList, setPortList] = useState([]);
    const [carrierList, setCarrierList] = useState([]);
    const [statusCodeList, setStatusCodeList] = useState([]);
	// const [fromDate,setFromDate] = useState(add(sub(new Date(),{months:1}),{days:1}));
	const [fromDate,setFromDate] = useState(sub(new Date(),{months:6}));
	const [toDate,setToDate] = useState(add(new Date(),{months:3}));

    // const [openSearchDetail, setOpenSearchDetail] = useState(true);

    //조건param
	const [searchBlNo , setSearchBlNo] = useState("");
	const [searchCntrNo , setSearchCntrNo] = useState("");
	const [searchPolArray , setSearchPolArray] = useState([]);
	const [searchPodArray , setSearchPodArray] = useState([]);
	const [searchRefNo , setSearchRefNo] = useState("");
	const [searchInsUser , setSearchInsUser] = useState("");
	const [searchCarrierArray , setSearchCarrierArray] = useState([]);
	const [searchStatusArray , setSearchStatusArray] = useState([]);

    
    //엑셀, 일괄등록 모달
    const [modalOpen,setModalOpen] = useState(false);

    //서비스선사리스트 모달
    const [serviceLineModalOpen,setServiceLineModalOpen] = useState(false);

    //선박위치 모달
    const [popupMap,setPopupMap] = React.useState(null);
    
    //이메일 Dialog
    const [openMailForm,setOpenMailForm] = React.useState(false);
    const [emailItems,setEmailItems] = React.useState('');
    const [emailRow,setEmailRow] = React.useState({});

    //Hbl 등록 Dialog
    const [openHblForm,setOpenHblForm] = React.useState(false);
    const [hblData,setHblData] = React.useState([]);
    const [hblSelected, setHblSelected] = useState([]);
    
    const [call,setCall] = React.useState(false);
    const [updatedData, setUpdatedData] = useState([]);

    const updatingList = useRef([]);
    const refTrkListData = useRef([]);
    const checkBlInfo = useRef([]);
    const newRowNum = useRef(-1);
    const initYn = useRef('Y');
    const [updating,setUpdating] = useState([]);

    const [roleReadOnly,setRoleReadOnly] = useState(mode=='single'?true:false);

    const userMenuRole = useObserver(() => {
        return userStore.userMenuRole;
    });

	useEffect(() => {
        if(userData?.user_no){
            console.log(JSON.stringify(userMenuRole))
            let role = userMenuRole.filter(x=>x!=='GUEST')
            if(role.length>0){
                //유저별 권한 체크
                if(userMenuRole.findIndex(x=>x=='ROLE_FWD_OUT_TRACK_READ')>-1){
                    setRoleReadOnly(true)
                }else{ 
                    if(mode !=='single'){
                        setRoleReadOnly(false); 
                        selectPortCodeInfo();
                        selectCarrierCodeInfo();
                        selectStatusCodeInfo();
                        onSearchInit();
                    }
                };
            }
        }else{
            //GUEST
            console.log(JSON.stringify(userMenuRole))
        }
	return () => {
		};
	},[userMenuRole]);
	
	useEffect(() => {
        let sSearchType=searchType?.value||null;
        if(initYn.current=='Y'&&sSearchType){//검색 중 하나라도 들어갈 시 기간 조건 null(최초)
            let sBlNo = searchBlNo.trim()||null;
            let sCntrNo = searchCntrNo.trim()||null;
            let sRefNo = searchRefNo.trim()||null;
            let sInsUser = searchInsUser.trim()||null;
            if(sBlNo|| sCntrNo|| sRefNo|| sInsUser
                ){
                    setSearchType(null);
                    initYn.current=null;
            }
        }
	return () => {
		};
	},[searchBlNo,searchCntrNo,searchRefNo,searchInsUser,]);
    
    useInterval(()=>{
        if(updatingList.current.length>0){
            updateScrpStatus();
        }
    },updatingList.current.length>0?8000:20000);

    useEffect(() => {
        if(call)updateScrpStatus();
        console.log('call>>');
        setCall(false);
	    return () => setCall(false)
	},[call]);

    useEffect(() => {
        if(!searchType){
            setFromDate(null)
            setToDate(null)
        }else{
            setFromDate(sub(new Date(),{months:3}))
            setToDate(add(new Date(),{months:3}))
        }
	},[searchType]);

	const handleClick = (event) => {
        window.scrollTo({top:0,  behavior: 'smooth',  block: 'center'})
    };

    const updateScrpStatus = async(data)=>{    
        try {
            let checkUpdating = data?data.filter(x=>x?.update_yn>0):[];
            updatingList.current=[...updatingList.current, ...checkUpdating];

            let prevUpdatingList = updatingList.current;//현재 업데이트중 리스트
            const res = await axios.post("/loc/selectScrpStatus",{klnet_id:userData.klnet_id})//전체 중 업데이트 현황조회
            
            //이전 업데이트 리스트 중 끝난 리스트 
            const list = new Set(prevUpdatingList.filter(x=> { return !res.data.some(y=> x?.mbl_no ==y?.mbl_no)}).map(x=>x?.mbl_no));
            if(list.size>0){
                let param = {mblList: [...list]}
                const result = await selectExpTrkList(param);
                setUpdatedData(result)

                
                let data = refTrkListData.current.map(y=>{
                    let llist = result.find(x=> x?.mbl_no==y?.mbl_no);
                    return llist ?{...llist, "rnum": y.rnum, 'up_view_yn':y.up_view_yn,} :y
                })
                refTrkListData.current = [...data]
            }
            updatingList.current = res.data;
            setUpdating(res.data)
        }catch(e){
            console.log(e)
        }
    }
        
    const onSearchInit =async ()=>{
        setSearchBlNo('');
        setSearchRefNo('');
        setSearchInsUser('');
        setSearchType(searchTypeList[0])
        setFromDate(sub(new Date(),{months:3}));
	    setToDate(add(new Date(),{months:3}));

        setSearchCntrNo('');
        setSearchCarrierArray([]);
        setSearchStatusArray([]);
        setSearchPolArray([]);
        setSearchPodArray([]);
        
        let initParam ={
            searchType: 'init',
            fromDate:format(sub(new Date(),{months:3}),'yyyyMMddHHmm'),
            toDate:format(add(new Date(),{months:3}),'yyyyMMddHHmm'),
            }
        await onSubmit(initParam);
    }

    const onHandleSearchBtn = () => {
        if(loading){return }
        let sBlNo = searchBlNo.trim()||null;
        let sCntrNo = searchCntrNo.trim()||null;
        let sRefNo = searchRefNo.trim()||null;
        let sInsUser = searchInsUser.trim()||null;
        let sSearchType=searchType?.value||null;

        if(!sBlNo&& !sCntrNo&& !sRefNo&& !sInsUser&&
            searchPolArray.length<1&&
            searchPodArray.length<1&&
            searchCarrierArray.length<1&&
            searchStatusArray.length<1 && !sSearchType){//조건이 다 null이면 기간필수
            alertMessage('조회 기간을 설정하세요.','error');
            return false;
        }

        if(sSearchType){
            console.log(sSearchType,isValid(fromDate), isValid(toDate), fromDate, toDate)
            if(!isValid(fromDate)||!isValid(toDate)){
                alertMessage('조회 기간 형식을 확인하세요.','error');
                return false;
            }
            // console.log(differenceInMonths(toDate, add(fromDate,{days:1})))
            if(differenceInMonths(toDate, add(fromDate,{days:1}))>5){
                alertMessage('조회 기간은 최대 6개월입니다.','error');
                return false;
            }
        }

        let param ={searchBlNo:sBlNo,
                    searchCntrNo:sCntrNo,
                    searchPolList:searchPolArray.map(x=>x?.port_code)||[],
                    searchPodList:searchPodArray.map(x=>x?.port_code)||[],
                    searchCarrierList:searchCarrierArray.map(x=>x?.line_code)||[],
                    searchStatusList:searchStatusArray.map(x=>x?.code_name)||[],

                    searchType: sSearchType,
                    fromDate:fromDate?format(fromDate,'yyyyMMddHHmm'):'',
                    toDate:toDate?format(toDate,'yyyyMMddHHmm'):'',
                    searchRefNo:sRefNo,
                    searchInsUser:sInsUser,
                    }

		if(userData) {
            onSubmit(param);
		} else {
            props.openLogin();
		}
	}

    const onSubmit = async(param) => {
        let trklist = [];
        setHblSelected([]);
		if(userData) {
            setLoading(true);
            try {
                let result = await selectExpTrkList(param);
                result.length>0 ? trklist = result
                                :roleReadOnly? alertMessage('조회 결과가 없습니다. \n이용 업체에 등록 요청 바랍니다.','error')
                                    : alertMessage(validation.FAIL_CHECK_MSG,'error');
            }catch(err){
                console.log(err)
                alertMessage('오류가 발생했습니다.','error');
            }finally{
                await updateScrpStatus(trklist)
                setTrkListData(trklist);
                refTrkListData.current = trklist;
                initYn.current='Y';
                setLoading(false);
            }
		} else {
            props.openLogin();
		}
	}

    const selectExpTrkList =async(param)=>{
        try { 
            let res = await axios.post(roleReadOnly? "/loc/selectExpTrakingReadList":"/loc/selectExpTrakingList"
                                        ,{klnet_id : userData.klnet_id
                                            , user_id: userData.user_no, param:param||null
                                        })
            const result = res.data;
            return result;
        }catch(err){
            throw err
        }
    }

    const sortNewExpTrkList =async(params, rowYn)=>{
        try { 
            //단건이면 (rowYn true) 속도개선
            if(rowYn || params.length==1){
                let bl_param = params[0]?.hbl_no&&params[0]
                                ?.hbl_no!=='Direct B/L'?params[0]?.hbl_no||params[0]?.mbl_no||null
                                :params[0]?.mbl_no||null;
                if(!bl_param)return false;
                let result = await selectExpTrkList({blNo:bl_param});        
                result = await result.map((e)=>{
                        e.up_view_yn = newRowNum.current;
                        e.rnum = trkListData.length+1;
                    return e
                });
                let data = refTrkListData.current ;
                setTrkListData([...data, ...result]);
                await updateScrpStatus([...data, ...result]);
                refTrkListData.current = [...data, ...result];
            }else{
                let result = await selectExpTrkList();//전체조회 (차후 수정,,)
                result = result.map((e)=>{
                    if(params.findIndex(x=> x.mbl_no == e?.mbl_no ) >-1 ) { 
                        e.up_view_yn = newRowNum.current;
                    } 
                    return e
                })                
                setTrkListData([...result]) ;
                await updateScrpStatus([...result]);
                refTrkListData.current = [...result];
            }
            newRowNum.current =  --newRowNum.current;
            alertMessage(`등록이 완료되었습니다.`,'success');
            return true;
        }catch(err){
            return false
        }
    }

    //단 건 등록
    const onAddUserBl = async(addItem, fnResetValue, setOpentHblTooltip) => {
        if(userData) {
            if(!addItem||!userData?.klnet_id){
                alertMessage('등록 정보를 입력바랍니다.','error');
                return false;
            }
		
            setAddLoading(true);
            let params ={};
            params['bl_no']=addItem.trim();
            await axios.post("/loc/insertUserBlRequest",
            {klnet_id : userData.klnet_id, user_id: userData.user_no, params : [params]}
            ).then(async(res) =>{
                const selectRes = await sortNewExpTrkList([...res.data], true);
                selectRes? fnResetValue():onSearchInit()
            }).catch(err => {
                let errMsg = err.response?.data||err;
                // console.log(errMsg)
                if(errMsg=='E01'){ alertMessage('등록 정보를 확인바랍니다.','error');}
                else if(errMsg=='E02'){ alertMessage('승인 처리된 수출적하목록신고 건만 등록가능합니다.','error');}
                else if(errMsg=='선사 선택 필요'){ //'선사 B/L 정보 없음'=>'선사 선택 필요'수정 && 팝업열기
                    checkBlInfo.current = [{
                        "mbl_no": addItem.trim()||'',
                        "hbl_no": null,
                        "bl_no": addItem.trim()||'',
                        "rnum": 1,
                        "desc": '선사 선택 필요'
                    }];
                    setModalOpen(true); 
                    return;
                } else {alertMessage(errMsg||'등록에 실패하였습니다.','error');}
                setOpentHblTooltip(true);
            }).finally(()=> {
                setAddLoading(false);
            });
		} else {
            props.openLogin();
		}
	}

    const selectPortCodeInfo =async()=>{
        await axios.post("/api/selectTrkPortInfo",{})
        .then(res => setPortList(res.data))
        .catch(err => {
        });
    }

    const selectCarrierCodeInfo =async()=>{
        await axios.post("/api/selectServiceLineList",{})
        .then(res => {
            let result =  res.data ||[];
            if(result.length>0){
                result = result.filter(x=> x?.use_yn =='Y' )
                setCarrierList([...result])
            }
            // setSearchCarrier({line_code:null, line_name:'전체'})
        })
        .catch(err => {
        });
    }
    const selectStatusCodeInfo =async()=>{
        await axios.post("/api/selectTrkStatusCodeList",{})
        .then(res => {
            let result =  res.data ||[];
            if(result.length>0) setStatusCodeList(result)
        })
        .catch(err => {
        });
    }
    
    const hideAlert = ()=>{
        setViewConfirmAlert(null);
    }
    const showAlert = (confirmText,confirmFn)=>{
        setViewConfirmAlert(   
            <SweetAlert
            onConfirm={ () => {hideAlert(); confirmFn();}}
            title={`총 ${confirmText}건을 삭제하시겠습니까?`}
            type={'warning'}
            show={true}
            onCancel={() => { hideAlert(false)}}
            reverseButtons={false}
            showConfirm={true}
            showCancel={true}
            confirmBtnText={'삭제'} 
            cancelBtnText={'취소'} 
            confirmBtnStyle={{color: 'white', border:'none', boxShadow :'none',backgroundColor: roseColor[0]}}
            cancelBtnStyle={{color: 'white',border:'none', boxShadow :'none',backgroundColor: '#00acc1'}}
            // confirmBtnCssClass={classes.button + " " + classes.info }
            // cancelBtnCssClass={classes.button + " " + classes.warning}
            style ={{boxSizing:'initial', width:'450px'}}
            > {/* <span style={{fontWeight:'bold'}}>s후 로그인 세션이 종료되어 로그아웃됩니다. </span> */}
            </SweetAlert>)
    }

    const openPopup = async(data)=>{
        if(!userData){
            props.onAlert('error',validation.NOTLOGIN_MSG);
            return ;
        }
            
        let {imo, map_start_day , start_port, sch_vessel_name, end_port}= data;
        console.log(data)
        if(!imo &&!sch_vessel_name) return false;

        let url = null;
        let param = {imo : imo||null, map_start_day: map_start_day||null, start_port :start_port||null, sch_vessel_name:sch_vessel_name||null
        ,end_port :end_port}

        if(imo||sch_vessel_name){//모두 있으면 경로 map
            param = data; 
        }else{//imo로만 가지고vessel위치 map url 받기
            await axios.post("/com/getTrackingShip",{
                params:[{imoNo : imo}]
            }).then(res => {
                if(res.data.length > 0) {
                    url = res.data;
                }else{
                    if(!sch_vessel_name) throw new Error('no Data');
                }
            }).catch(error=>{
                if( error.response?.data?.message ) {
                    props.onAlert('error',error.response.data.message);
                } else {
                    props.onAlert('error',"선박 조회가 불가합니다.");
                }
                return;
            });
        }

        setPopupMap(				
            <Popover
                id="popover_map"
                open={true}
                onClose={()=>setPopupMap(null)}
                anchorReference="anchorPosition"
                anchorPosition={{top:80,left:550}}
                anchorOrigin={{vertical:'bottom',horizontal:'center',}}
                transformOrigin={{vertical:'top',horizontal:'center',}}>
                <ShipMap propsUrl={url ||null} parameter={param} 
                        onClose={()=>setPopupMap(null)} {...props}></ShipMap>
            </Popover>
        );

        await insertEventLog(userData, 'TRK_SHIP_LOCATION', imo||sch_vessel_name||'' );     
    }

    const onHandleNotiEmail = async(items)=>{
        if(userData) {
            let itemList = items.split(';');
            itemList = itemList.map(e=>e.trim());
            itemList = itemList.filter(Boolean);
            
            //형식체크
            let val = true;
            itemList.forEach((el,key)=>{
                if(!validation.validationEmail(el)){
                    val = false;
                }
            });
            if (!val){
                alertMessage(validation.EMAIL_MSG ,'error'); 
                return false;
            }

            //저장
            if(emailItems!==items){
                await axios.post("/loc/updateUserNotiEmail",{klnet_id : userData.klnet_id, user_id:userData.user_no, emailRow : emailRow , emailList : itemList}
                ).then(res =>{
                    const result = res.data;
                    alertMessage(`수정이 완료되었습니다.`,'success');
                    onSubmit();
                    onCloseMailForm();
                }).catch(err => {
                    alertMessage('정보를 저장하는 데 실패하였습니다. 다시 시도하세요.','error');
                });
            }else{
                onCloseMailForm();
            }
        } else {
            props.openLogin();
        }
    }

    const showModalEmail = async(row) =>{
        setEmailRow({})
        await axios.post("/loc/selectUserNotiEmail",{klnet_id : userData.klnet_id,  param : row}
        ).then(res =>{
            const result = res.data;
            let str = result.length>1? result.map((e)=>e.email+'; '): result[0]?.email||'';
            setEmailItems(str);
            setEmailRow(row);
            setOpenMailForm(true);
        }).catch(err => {
            alertMessage('정보를 가져오는 데 실패하였습니다. 다시 시도하세요.','error');
        });
    }

    const onCloseMailForm = ()=>{
        setOpenMailForm(false);
        setEmailRow({});
        setEmailItems('');
    }
    
    const showModalHbl= async(row)=>{
        if(!row?.mbl_no)return
        setHblSelected([]);
        await axios.post("/loc/selectHblNo",{mbl_no : row?.mbl_no, klnet_id : userData?.klnet_id}
        ).then(res =>{
            const result = res.data;
            setHblData(result);
            setOpenHblForm(true);
        }).catch(err => {
            alertMessage('정보를 가져오는 데 실패하였습니다. 다시 시도하세요.','error');
        });
    }

    const onCloseHblForm = ()=>{
        setOpenHblForm(false);
    }

    const isSelected = (rnum) => {
        return hblSelected.findIndex(x=>x.rnum==rnum) > -1; }
    
    const handleHblSelectAllClick = (event) => {
        if (event.target.checked) {
            let filteredData = hblData.filter(e => e?.exists_yn!=='Y')
            setHblSelected(filteredData);
            return;
        }
        setHblSelected([]);
    };
    
    const handleHblSelectList = (e, data) => {
        if(data){
            let newSelected = [];
            if(hblSelected.findIndex(x=>x.rnum==data.rnum)<0) {
                newSelected = [...hblSelected,data];
            } else {
                newSelected = hblSelected.filter((x)=>x?.rnum !== data.rnum);		
            }
            setHblSelected(newSelected);
        }
    };
    const onHblSelecedSubmit = async(addItems)=>{      
        if(addItems.length<1||!userData?.klnet_id){
            alertMessage('등록 정보를 선택바랍니다.','error');
            return false;
        }
		if(userData) {
            await axios.post("/loc/insertUserBlRequest"
                ,{klnet_id : userData.klnet_id, user_id: userData.user_no,params: addItems}
            ).then(res =>{
                let cnt = res.data?.addCnt||0
                    alertMessage(`등록이 완료되었습니다.`,'success');
                    onSubmit();
            }).catch(err => {
                if(err.response.data=='E01'){ alertMessage('등록 정보를 확인바랍니다.','error');}
                else if(err.response.data=='E02'){ alertMessage('승인처리된 수출적하목록신고 건만 등록가능합니다.','error');}
                else alertMessage('등록에 실패하였습니다.','error');
                onSubmit();
            }).finally(()=>{
                onCloseHblForm();
            });
		} else {
            props.openLogin();
		}
    }

    const handleKeyDown = (e, fn) => {
        if(e.key ==='Enter'){
            fn();
        }
    }
    const showServiceLineList = ()=>{
        setServiceLineModalOpen(true)
    }

    const onHandleSearchStatus = (data)=>{
        if(data.findIndex(x=>x?.code_name =='등록 대기중')>-1) {//등록대기중이면 date null
            setSearchType(null);
        }
        setSearchStatusArray(data||null);
    }

    const onHandleSearchBtnReadOnly = () => {
        if(loading){return }
        let sBlNo = searchBlNo.trim()||null;
        let sCntrNo = searchCntrNo.trim()||null;

        if(!sBlNo&& !sCntrNo){//조건이 다 null이면 기간필수
            alertMessage('B/L번호 또는 컨테이너 번호를 입력하세요.','error');
            return false;
        }

        let param ={singleSearchBlNo:sBlNo,
                    singleSearchCntrNo:sCntrNo,
                    }

		if(userData) {
            onSubmit(param);
		} else {
            props.openLogin();
		}
	}

	return (<>
		<div  style={{marginTop:'20px', marginBottom:'10px'}}>
            <Card style={{marginTop:'0',marginBottom:'5px'}}>
                <CardHeader color="info" stats icon >
                    <CardIcon color="info" style={{height:'35px', width:'35px', display:'flex',justifyContent:'center', alignItems:'center', marginTop:'-13px'}}>
                    <Icon style={{fontSize:'16px'}}>content_copy</Icon>
                    </CardIcon>
                    <h4 style={{color:'black',textAlign: "left", paddingTop:'7px', fontSize:'1.2rem'}}> 
                    {!roleReadOnly?'등록 건 조회':'글로벌 화물 추적 (조회용)'}
                    </h4>
				</CardHeader>
                {/* single페이지 검색 조건 */}
                {mode =='single'?
                <CardBody style={{padding :'20px 40px'}}>
                    <Grid container  spacing ={2} >
                        <Grid item  lg={10} sm={10} xs ={10}>
                            <Grid container spacing={1} style ={{display :'flex', justifyContent:'space-between'}}>	
                                <Grid item style ={{width :'48%'}} >
                                    <TextField id="searchBlNo" label="B/L No." 
                                        onChange={e => setSearchBlNo(e.target.value)} 
                                        onKeyDown={(e)=>handleKeyDown(e, ()=>onHandleSearchBtnReadOnly())}
                                        value={searchBlNo} 
                                        fullWidth/>
                                </Grid>
                                <Grid item style ={{width :'48%'}} >
                                    <TextField id="searchCntrNo" label="CNTR No." 
                                        onChange={e => setSearchCntrNo(e.target.value)} 
                                        onKeyDown={(e)=>handleKeyDown(e, ()=>onHandleSearchBtnReadOnly())}
                                        value={searchCntrNo} fullWidth/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item  lg={2} sm={2} xs ={2} style ={{display:'flex', justifyContent:'flex-end',height:'65px'}} >
                            <Button color="info" endIcon={<SearchIcon/>} size ='lg' style={{width:'120px'}} onClick = {onHandleSearchBtnReadOnly}
                            disabled={loading}
                            >Search</Button>
                        </Grid>
                    </Grid>
                </CardBody>
                :
                <CardBody style={{paddingTop:'0px',paddingBottom:'10px'}}>
                    <Grid container  spacing ={2} >
                        <Grid item  lg={10} sm={10} xs ={10}>
                            <Grid container spacing={1} style ={{display :'flex', justifyContent:'space-between'}}>	
                                <Grid item style ={{width :'24%'}} >
                                    <TextField id="searchBlNo" label="B/L No." 
                                        onChange={e => setSearchBlNo(e.target.value)} 
                                        onKeyDown={(e)=>handleKeyDown(e, ()=>onHandleSearchBtn())}
                                        value={searchBlNo} 
                                        fullWidth/>
                                </Grid>
                                <Grid item style ={{width :'16%'}} >
                                    <TextField id="searchRefNo" label="REFERENCE No." 
                                        onChange={e => setSearchRefNo(e.target.value)} 
                                        onKeyDown={(e)=>handleKeyDown(e, ()=>onHandleSearchBtn())}
                                        value={searchRefNo} fullWidth/>
                                </Grid>
                                <Grid item style ={{width :'16%'}} >
                                    <TextField id="searchInsUser" label="담당자" 
                                        onChange={e => setSearchInsUser(e.target.value.toUpperCase())} 
                                        onKeyDown={(e)=>handleKeyDown(e, ()=>onHandleSearchBtn())}
                                        value={searchInsUser} fullWidth/>
                                </Grid>
                                <Grid item style ={{width :'13%'}} >
                                    <Autocomplete
                                    defaultValue={searchTypeList[0]}
                                    id="searchType"
                                    value={searchType || null}
                                    options = {searchTypeList}
                                    getOptionLabel = { option => option?.label ||''}
                                    onChange={(e, data)=> setSearchType(data)}
                                    renderInput={params => (
                                    <TextField {...params} label='* 기간'  />
                                    )}
                                    />
                                </Grid>
                                <Grid item style ={{width :'15%'}} > 
                                    <CalendarBox
                                        labelText =" "
                                        id="fromDate"
                                        format="yyyy-MM-dd"
                                        variant="inline"
                                        setValue={fromDate}
                                        onChangeValue={date =>setFromDate(date)}
                                        autoOk={true}
                                        fullWidth={true}
                                        disabled ={!searchType?.value}
                                    />
                                </Grid>
                                <Grid item style ={{width :'1%', marginTop:'25px', padding:'0', textAlign:'center'}} > <span > ~ </span> </Grid>
                                <Grid item style ={{width :'15%'}} >
                                    <CalendarBox
                                        labelText =" "
                                        id="toDate"
                                        format="yyyy-MM-dd"
                                        variant="inline"
                                        setValue={toDate}
                                        onChangeValue={date =>setToDate(date)}
                                        autoOk={true}
                                        fullWidth={true}
                                        disabled ={!searchType?.value}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item  lg={2} sm={2} xs ={2} style ={{display:'flex', justifyContent:'space-around',height:'65px'}} >
                            <Button color="info" endIcon={<SearchIcon/>} size ='lg' style={{width:'100px'}} onClick = {onHandleSearchBtn}
                            disabled={loading}
                            >Search</Button>
                            <Button color="info" endIcon={<ClearIcon/>} size ='lg' style={{width:'80px'}} onClick = {onSearchInit}>초기화</Button>
                        </Grid>
                    </Grid>
                    <Grid container  >
                        <Grid item  lg={10} sm={10} xs ={10}>
                            <Grid container spacing={1} style ={{display:'flex', justifyContent:'space-between'}}   >	
                                <Grid item style ={{width :'24%'}}>
                                    <TextField id="searchCntrNo" label="CNTR No." 
                                        onChange={e => setSearchCntrNo(e.target.value)} 
                                        onKeyDown={(e)=>handleKeyDown(e, ()=>onHandleSearchBtn())}
                                        // InputProps={{endAdornment:<AddBoxOutlined color='primary' />}}
                                        value={searchCntrNo} fullWidth/>
                                </Grid> 
                                <Grid item style ={{width :'16%'}}>
                                    <Autocomplete
                                        multiple
                                        limitTags={3}
                                        id="searchCarrier"
                                        options = {carrierList}
                                        value={searchCarrierArray}
                                        getOptionLabel = { option =>option?.line_name?option.line_name:''}
                                        getOptionSelected = {(option,value) => value ? option.line_code == value.line_code : ''}
                                        onChange={(e, data)=> setSearchCarrierArray(data||null)}
                                        renderInput={params => (
                                        <TextField {...params} label='CARRIER'/>
                                        )}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ margin: '0'}}
                                                checked={selected}
                                            />
                                            <font size="2">{option?.line_name}</font>
                                            </React.Fragment>
                                        )}
                                        />
                                </Grid>
                                <Grid item style ={{width :'15%'}}>
                                    <Autocomplete
                                    id="status"
                                    multiple
                                    limitTags={3}
                                    options = {statusCodeList}
                                    value={searchStatusArray}            
                                    getOptionLabel = { option =>option?.code_name?option.code_name:''}
                                    getOptionSelected = {(option,value) => option?.code_name === value?.code_name}
                                    onChange={(e, data)=>onHandleSearchStatus(data)}
                                    renderInput={params => (
                                    <TextField {...params} label='STATUS'/>
                                    )}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ margin: '0'}}
                                            checked={selected}
                                        />
                                        <font size="2">{option?.code_name}</font>
                                        </React.Fragment>
                                    )}
                                    />
                                </Grid>
                                <Grid item style ={{width :'22%'}}>
                                    <Autocomplete
                                        multiple
                                        limitTags={3}
                                        options = {portList}
                                        defaultValue={ []}
                                        value = {searchPolArray||[]}
                                        getOptionLabel = { option =>option?.port_code?`[${option.port_code}] ${option?.port_name}`:''}
                                        getOptionSelected = {(option,value) => option?.port_code === value?.port_code}
                                        id="searchPol"
                                        onChange={(_, data)=> data?setSearchPolArray(data):''}
                                        renderInput={params => (
                                            <TextField {...params} label="POL" />
                                        )}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ margin: '0'}}
                                                checked={selected}
                                            />
                                            <font size="2">{"["+option?.port_code+"] "+option?.port_name}</font>
                                            </React.Fragment>
                                        )}
                                        loading={true}
                                        openOnFocus={true}
                                    />
                                </Grid>
                                <Grid item style ={{width :'22%'}} > 
                                    <Autocomplete
                                        multiple
                                        limitTags={3}
                                        options = {portList}
                                        defaultValue={ []}
                                        value = {searchPolArray||[]}
                                        getOptionLabel = { option =>option?.port_code?`[${option.port_code}] ${option?.port_name}`:''}
                                        getOptionSelected = {(option,value) => option?.port_code === value?.port_code}
                                        id="searchPod"
                                        onChange={(_, data)=> data?setSearchPodArray(data):''}
                                        renderInput={params => (
                                            <TextField {...params} label="POD" />
                                        )}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ margin: '0'}}
                                                checked={selected}
                                            />
                                            <font size="2">{"["+option?.port_code+"] "+option?.port_name}</font>
                                            </React.Fragment>
                                        )}
                                        loading={true}
                                        openOnFocus={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item  lg={2} sm={2} xs ={2} style ={{display:'flex', justifyContent:'flex-end'}}> 
                            </Grid>
                        </Grid>
                    </Grid>
                </CardBody>}
            </Card>
            <DetailTable 
            tableHeaderColor = "info"
            rows = { trkListData }
            loading={loading}
            addLoading={addLoading}
            onSubmit={onSubmit}
            showAlert={showAlert}
            onSearchInit={onSearchInit}
            setModalOpen={(e)=>setModalOpen(e)}
            onAddUserBl={onAddUserBl}
            openPopup={openPopup}
            showModalEmail={(e)=>showModalEmail(e)}
            showModalHbl={(e)=>showModalHbl(e)}
            selectExpTrkList={selectExpTrkList}
            updatedData={updatedData}
            setCall={setCall}
            updating={updating}
            handleKeyDown={handleKeyDown}
            showServiceLineList={showServiceLineList}
            roleReadOnly={roleReadOnly}
            {...props}
            />
            {viewConfirmAlert}
            {popupMap}
            {/* 모달 */}
            {/* {modalOpen&&
            <Modal
                className={classes.modal}
                open={modalOpen}
                onClose={() =>  setModalOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout:500}}> */}
            <ExpTrackingAddModal
                    // toggle={() => {setModalOpen(false);}}
                    onSearchInit={onSearchInit}
                    sortNewExpTrkList={sortNewExpTrkList}
                    modalOpen={modalOpen}
                    setModalOpen={e=> setModalOpen(e)}
                    Backdrop={Backdrop}
                    checkBlInfo={checkBlInfo}
                    {...props}
                    /> 
             {/* </Modal>
            } */}
            <ExpTrackingEmailForm
            onSubmit = {onSubmit}
            onHandleNotiEmail={onHandleNotiEmail}
            openMailForm={openMailForm}
            onCloseMailForm={onCloseMailForm}
            emailRow={emailRow}
            propsEmailItems={emailItems}
            {...props}
            />
            {/* 서비스 선사 리스트 */}
            <ExpTrackingServiceLineModal
                // toggle={() => {setModalOpen(false);}}
                // onSearchInit={onSearchInit}
                // sortNewExpTrkList={sortNewExpTrkList}
                serviceLineModalOpen={serviceLineModalOpen}
                setServiceLineModalOpen={e=> setServiceLineModalOpen(e)}
                // Backdrop={Backdrop}
                {...props}
                /> 
            {/* HBL 등록 Form*/}
            <Dialog 
                onClose={()=>onCloseHblForm(false)}
                color="primary"
                open={openHblForm}
                maxWidth='md'
                >
                <Card style={{margin:'0',paddingTop:'0',paddingBottom:'0', width:'500px'}}>
                    <CardBody >       
                        <div style={{display: 'flex',	flexDirection: 'column',justifyContent: 'center'}}>		
                                <div className={Style.popHeader}>
                                    <div className={Style.ptit}>
                                        <strong>HBL {mode=='single'?' 목록' :' 등록'}</strong>
                                    </div>
                                    <div style={{display:'flex', cursor:'pointer'}} >
                                        <Close onClick={()=>onCloseHblForm(false)}/>
                                    </div>
                                </div>
                        </div>
                        <div className={Style.popCont}>
                            <div className={Style.rtwrap} style={{maxHeight:'200px',display: 'flex',	flexDirection: 'column',justifyContent: 'space-between'}}>
                                <TableContainer className={classes.tableLine}>
                                    <Table 	
                                    stickyHeader aria-label="sticky table"
                                    className={classes.table}
                                    aria-labelledby="tableTitle"
                                    size='small'
                                    style ={{height:'100%'}}
                                    >
                                        <TableHead>
                                            <TableRow>
                                            {mode!=='single'&& <TableCell padding="checkbox" className={classes.tableHeadercell}>
                                               <Checkbox /*헤더 전체 checkBox */
                                                    indeterminate={hblSelected.length > 0 && hblSelected.length <  hblData.filter(e => e?.exists_yn!=='Y').length}
                                                    checked={hblData.filter(e => e?.exists_yn!=='Y').length > 0 && hblSelected.length ===  hblData.filter(e => e?.exists_yn!=='Y').length}
                                                    disabled={hblData.filter(e => e?.exists_yn!=='Y').length <1}
                                                    onChange={handleHblSelectAllClick}
                                                    inputProps={{ 'aria-label': 'select all' }}
                                                    color='primary'
                                                />
                                                </TableCell>}
                                                <TableCell className={classes.tableHeadercell} align="center">HBL No.</TableCell>
                                                {mode!=='single'&&<TableCell className={classes.tableHeadercell} align="center">상태</TableCell>}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody style={{height:'100%'}}> 
                                            {hblData.length>0  
                                            ?hblData.map((row, index) => {
                                                const isItemSelected = isSelected(row?.rnum);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (    
                                                    <TableRow 
                                                        className={classes.root}
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={index}
                                                        // selected={isItemSelected}
                                                        style ={{height:'30px'}}
                                                        >
                                                        {mode!=='single'&&
                                                        <TableCell  component="th" id={labelId} scope="row"   padding="checkbox" 
                                                            onClick={(event) => {row?.exists_yn!=='Y'&&handleHblSelectList(event, row)}}>
                                                            <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                            color='primary'
                                                            disabled={row?.exists_yn=='Y'}
                                                            />
                                                        </TableCell>}
                                                        <TableCell align="center" className={classes.tablecell} >{row.hbl_no ||( row.cnt_hbl ? `${row?.cnt_hbl} 건` :'')}</TableCell>
                                                        {mode!=='single'&&<TableCell align="center" className={classes.tablecell} >
                                                        {row?.exists_yn!=='Y'
                                                        ? <p style={{color:'#00acc1', }}>등록 가능</p> 
                                                        : <p style={{color:'#ed7d31', }}>등록된 HBL 번호</p> }</TableCell>}
                                                    </TableRow>)})
                                                :(<TableRow  >
                                                <TableCell colSpan="6" align="center" style={{fontSize:"10px"}}>일치하는 정보가 없습니다.</TableCell>	
                                            </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>  
                                <div style ={{textAlign:'end', paddingRight:'end'}}>선택 : {hblSelected.length} / {hblData.length}</div>
                            </div>
                        </div>
                        <DialogActions>
                            <Button color="info"
                            onClick={()=>{onHblSelecedSubmit(hblSelected)}}
                            >확인</Button>
                            <Button color='default' onClick={()=>{onCloseHblForm()}}>취소</Button>
                        </DialogActions>
                    </CardBody>
                </Card>
            </Dialog>
		</div> 
        <Zoom in={true} >      
            <div onClick={handleClick} role="presentation" className={classes1.root}>
                <Fab
                    color="secondary"
                    size="small"
                    aria-label="scroll back to top"
                    style={{ backgroundColor: '#26c6da' }}
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            </div>
        </Zoom>
    </>
	);
}