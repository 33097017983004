import React,{ useEffect } from "react";

// core components
import {Dialog, FormControl,
        OutlinedInput, DialogActions,} from "@material-ui/core";
import {Close,
} from "@material-ui/icons";

import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Button from "muiComponents/CustomButtons/Button.js";

import Style from './ExpTrackingAddModal.module.css';

export default function ExpTrackingEmailForm(props) {
  const {openMailForm, onCloseMailForm, onHandleNotiEmail, propsEmailItems} =props;
  //이메일 Dialog
  const [emailItems,setEmailItems] = React.useState('');

  useEffect(() => {
    setEmailItems(propsEmailItems);
	},[propsEmailItems,openMailForm]);

  return (
  <Dialog 
  onClose={()=>onCloseMailForm()}
  color="primary"
  open={openMailForm}
  maxWidth='md'
  >
  <Card style={{margin:'0',paddingTop:'0',paddingBottom:'0', width:'500px'}}>
      <CardBody >       
          <div style={{display: 'flex',	flexDirection: 'column',justifyContent: 'center'}}>		
                  <div className={Style.popHeader}>
                      <div className={Style.ptit}>
                          <strong>메일 알림</strong>
                      </div>
                      <div style={{display:'flex', cursor:'pointer'}} >
                          <Close onClick={()=>onCloseMailForm()}/>
                      </div>
                  </div>
          </div>
          <div className={Style.popCont}>
              <div className={Style.rtwrap} style={{height:'95px',display: 'flex',	flexDirection: 'column',justifyContent: 'space-between'}}>
                  <div>
                      <strong style ={{color:'#04b2b9', fontSize:'14px'}}>STATUS 변경이 발생할 때마다, 이메일을 발송해 드립니다.</strong>
                  </div>
                  <FormControl variant='outlined' hiddenLabel={true} fullWidth>
                          <OutlinedInput
                          id='addItem'
                          fullWidth
                          value={emailItems||''}
                          onChange={event => setEmailItems(event.target.value.toUpperCase())}
                          aria-describedby='add-item'
                          inputProps={{'aria-label':'addItem' }}
                          labelWidth={0}
                          style ={{height:'42px',paddingRight:'0'}}
                      />
                  </FormControl>
                  <div>
                      <strong style ={{color:'#ed7d04'}}>※ 세미콜론( ; )으로 구분하여, 여러 수신자를 추가할 수 있습니다.</strong>
                  </div>
              </div>
          </div>
          <DialogActions>
              <Button color="info"onClick={()=>{onHandleNotiEmail(emailItems)}}>확인</Button>
              <Button color='default' onClick={()=>{onCloseMailForm()}}>취소</Button>
          </DialogActions>
      </CardBody>
  </Card>
</Dialog>)
}
