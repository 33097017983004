import React,{useState,useEffect, useRef} from "react";
import {sub,format,isValid} from 'date-fns';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import {TextField,Table,TableHead, TableRow, TableBody, TableCell, TableContainer, TableFooter, Grid, Paper} from '@material-ui/core'
import GridItem from "muiComponents/Grid/GridItem.js";
import GridContainer from "muiComponents/Grid/GridContainer.js";
//import Table from "muiComponents/Table/Table.js";
import Card from "muiComponents/Card/Card.js";
import CardHeader from "muiComponents/Card/CardHeader.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Button from "muiComponents/CustomButtons/Button.js";
//import CardIcon from "muiComponents/Card/CardIcon.js";
// other import
import axios from 'axios';
import {CSVLink} from 'react-csv';

import Icon from "@material-ui/core/Icon";
import CardIcon from "muiComponents/Card/CardIcon.js";

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    
    width: '100%',
    padding:'5px',
    marginBottom: theme.spacing(2),
  },gridContainer: {
    padding:'5px'
  }
}))

const headCells = [
  { id: 'klnet_id', numeric: false, disablePadding: true, label: 'KL-Net ID' },
  { id: 'company_name', numeric: false, disablePadding: true, label: '업체명' },
  { id: 'user_id', numeric: false, disablePadding: true, label: 'USER ID' },
  { id: 'insert_date', numeric: false, disablePadding: true, label: '동의 날짜' }
];

export default function SchCalAgreementStatic(props) {
  const classes = styles();
  const {alertMessage} = props;
  const [nm, setNm] = useState("");
  const [id, setId] = useState("");
  const [resultData, setResultData] = useState([]);
  const [num, setNum] = useState(1);
  const today = new Date();
  const [downloadData,setDownloadData] = useState([]);
  const csvLink = useRef();
  // const [date, setDate] = useState(format(today, 'yyyyMM'));
  
  useEffect(() => {
    onSubmit(1);
  },[]);

  const onSubmit = (param) => {
    if(props.userData){
      setNum(1);
      axios.post("/com/selectSchCalAgreeStatisticList",{
        id:id||'',
        nm:nm||'',
        num:param||''
      })
      .then(res => {setResultData(res.data)})
      .catch(err => {
        alertMessage('에러','error');
      });
    }else {
      props.openLogin();
    }
  }
  const onMore = (param) => {
    if(props.userData){
      if(num != resultData[0].tot_page) {
      //page ++
      setNum(param);

      axios.post("/com/selectSchCalAgreeStatisticList",{
          id:id,
          nm:nm,
          num:param
      })
      .then(res => setResultData([...resultData,...res.data]))
      .catch(err => {
        alertMessage('에러','error');
      });
      }
    }else{
      props.openLogin();
    }
  }

  const clickDownload = ()=>{
    if(props.userData){
      axios.post("/com/selectSchCalAgreeStatisticList",{
        id:id||'',
        nm:nm||''
      })
      .then(res => {
        const result =res.data;
        setDownloadData( result);
        if(result.length>0){
          csvLink.current.link.click();
        }else{
          alertMessage('데이터없음','error');
        }
      })
      .catch(err => {
        alertMessage('에러','error');
      });
    }else {
      props.openLogin();
    }
  }

  return (
    <div >
    <GridContainer className={classes.gridContainer}>
    	<GridItem xs={12} sm={12} md={12}>
        	<Card style={{marginBottom:'0px'}}>
      			<CardHeader color="info" stats icon style={{paddingBottom:'2px'}}>
					<CardIcon color="info" style={{height:'26px'}}>
						<Icon style={{width:'26px',fontSize:'20px',lineHeight:'26px'}}>content_copy</Icon>
				</CardIcon>
				<h4 className={classes.cardTitleBlack}>이용 현황</h4>
	  		</CardHeader>
          	<CardBody style={{paddingBottom: '0px',paddingTop: '10px',paddingLeft: '15px',paddingRight: '15px'}}>
              {/* <Grid item xs={12} sm={9} md={12}> */}
              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <TextField id="id" label="KLNET ID" onChange={event => setId(event.target.value)} value={id} fullWidth />
                </Grid>	
                <Grid item xs={12} md={3}>
                  <TextField id="NM" label="업체명" onChange={event => setNm(event.target.value)} value={nm} fullWidth />
                </Grid>
                {/* <Grid item xs={12} md={2}>
                  <TextField id="yearmonth" label="ex) 202301" onChange={event => setDate(event.target.value.trim())} value={date} inputProps={{maxLength:9}} fullWidth />
                </Grid> */}
                <Grid item xs={12} md={2} >
                  <Button color="info" onClick = {() => onSubmit(1)}  
                  fullWidth>Search</Button>							
                </Grid>
                <Grid item xs={12} md={2} >
                <Button color="success" 
                      id='btnExport' disabled={resultData.length<1} onClick={()=>{clickDownload();}}>EXCEL 다운로드</Button>
                {downloadData&&downloadData.length>0?
                    <CSVLink data={downloadData}
                    headers={headCells.map(item =>{return {label: item.label , key : item.id}})}
                    ref={csvLink}
                    className='hidden'
                    filename={"스케줄변경알림_동의현황.csv"}>
                    </CSVLink>
                  :<></>
                }
                </Grid>
		      		</Grid>
		     </CardBody>
        </Card>
      </GridItem>
      </GridContainer>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table>
                  <TableHead style={{padding:'5px'}}>
                      <TableRow>
                        <TableCell style={{textAlign:'center'}}>KL-Net ID</TableCell>
                        <TableCell style={{textAlign:'center'}}>업체명</TableCell>
                        <TableCell style={{textAlign:'center'}}>USER ID</TableCell>
                        <TableCell style={{textAlign:'center'}}>동의 날짜</TableCell>
                      </TableRow>               
                  </TableHead>
                  <TableBody>
                      {resultData.length>0?
                          resultData.map((element,key) => {
                              return(
                                <TableRow>    
                                    <TableCell style={{textAlign:'center'}}>{element?.klnet_id}</TableCell>
                                    <TableCell style={{textAlign:'center'}}>{element?.company_name}</TableCell>
                                    <TableCell style={{textAlign:'center'}}>{element?.user_id}</TableCell>
                                    <TableCell style={{textAlign:'center'}}>{element?.insert_date}</TableCell>
                                </TableRow>
                              )
                          })
                      :<TableRow >    
                        <TableCell colSpan='8' style={{textAlign:'center'}}>NO DATA</TableCell>
                      </TableRow>}
                  </TableBody>
                  {
                      resultData.length >= 10 ? (
                      <TableFooter >
                      <TableRow  >
                      <TableCell style={{textAlignLast:'center',paddingTop:'0',paddingBottom:'0'}} colSpan={28}>
                          <Button
                              color="info"
                              onClick={() => onMore(num + 1)}
                              style={{paddingLeft:'60px',paddingRight:'60px'}}
                          >MORE&nbsp;(&nbsp;{num}&nbsp;/&nbsp;{resultData[0].tot_page}&nbsp;)</Button>
                      </TableCell>
                      </TableRow>
                    </TableFooter>):null
                  }
              </Table>
              </TableContainer>
          </Paper>
        </div>
  );
}



