import React,{ useState, useEffect, useMemo ,useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import CardBody from "muiComponents/Card/CardBody.js";
import {
	Collapse,
	Card,
	CardContent,
	TextField,
	TableFooter,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Grid,
	Divider,
	Container,
	Avatar,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Input
} from "@material-ui/core";

import Button from "muiComponents/CustomButtons/Button.js";

import {
	Chat as Chat,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	ReplySharp,
	Search as SearchIcon
}from "@material-ui/icons";
import axios from 'axios';
import Filesaver from 'file-saver';
import {yellow, red} from '@material-ui/core/colors';
import ReactQuill from 'react-quill';
import { useHistory } from "react-router-dom";
import "assets/css/quillEditer.css";

export default function QnADetail(props) {
	const history = useHistory();
	const [qnaInfo,setQnaInfo] = useState({});
	const [reply,setReply] =useState();
	const [updateQnA,setUpdateQnA] =useState(false);
	const [updateReply,setUpdateReply] =useState(false);
	const [content, setContent] = useState([]);
	const [flag, setFlag] = useState('');
	const { userData} = props;
	//quill에디터
	const [value, setValue] = useState('');
	const quillRef = useRef();

	const ico_board_repeat_e = require("assets/img/ico_board_repeat_e.gif");
	useEffect(()=>{
		if(props.location.NTT_ID){
			//console.log (props.location);
			setQnaInfo(props.location);
		}else{
			goQnA()
		}
	},[props])

	useEffect(() => {
		if(props.location.NTT_ID){
			//내용 조회
			axios.post("/api/selectQnaDetail",{ ntt_id:props.location.NTT_ID ,bbs_id:props.location.BBS_ID,reply_lc:props.location.NTT_ID})
			.then(res => {
				//console.log(res.data[0].NTT_CN);
				setContent(res.data[0]?res.data[0].NTT_CN:'');
			})

			//답글 조회
			axios.post("/api/selectQnaDetailReply",{ bbs_id:props.location.BBS_ID,reply_lc:props.location.NTT_ID})
			.then(res => {
				//console.log(res.data);
				setReply(res.data[0]?res.data[0]:'')
			})

			//조회수
			axios.post("/api/updateInqireCo",{ bbs_id:props.location.BBS_ID,ntt_id:props.location.NTT_ID})
			.then(res => { 
				//console.log(res);
			})
		}
    },[])

	const goQnA = () => {
		history.push({
			pathname:"/svc/qna",
		})
	}

	// Quill 에디터에서 사용하고싶은 모듈들을 설정한다.
	// useMemo를 사용해 modules를 만들지 않는다면 매 렌더링 마다 modules가 다시 생성된다.
	const modules = useMemo(() => {
		return {
		toolbar: {
			container: [
			['image'],
			[{ header: [1, 2, 3, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{color:[]},{background:[]}]
			],
		},
		};
	}, []);
	// 위에서 설정한 모듈들 foramts을 설정한다
	const formats = [
		'image',
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'color',
		'background',
		 ];

	const QnASave = () => {

		if(!(value||content)){
			props.onAlert('error','내용을 입력해주세요.');
			return;
		}

		if(flag==='replyInsert'){
			axios.post("/api/insertQnaDetail", {
				bbs_id:props.location.BBS_ID //게시판 아이디
				,sUserId:userData.user_no//게시자 아이디
				,sUserNm:userData.user_name//게시자 명
				,ntt_sj:qnaInfo.NTT_SJ //게시물 제목
				,ntt_cn:value//게시물 내용
				,ntt_no:null//답장글 순번
				,reply_lc:props.location.NTT_ID//답장 게시물 아이디
				,atch_file_id:null//첨부 파일 명
				,ntt_tp:null//게시물 분류
				,qna_pw:null//비밀번호
			}).then(res => {
				if(res.data.rowsAffected>0){
					axios.post("/api/updateReplyYn", {// 게시물 답글 Y로 변경
						reply_lc:props.location.NTT_ID//게시물 아이디
						,bbs_id:props.location.BBS_ID //게시판 아이디
					})
					props.onAlert('success','저장 성공하였습니다.');
					history.push({
						pathname:"/svc/qna",
					})
				}else{
					props.onAlert('error','저장을 실패하였습니다.');
				}
			})
		}else if(flag==='replyUpdate'){
			
			axios.post("/api/updateQnaDetail", {
				ntt_cn:value//게시물 내용
				,ntt_sj:qnaInfo.NTT_SJ //게시물 제목
				,sUserId:userData.user_no//게시자 아이디
				,sUserNm:userData.user_name//게시자 명
				,ntt_id:reply.NTT_ID//게시물 아이디
				,bbs_id:reply.BBS_ID //게시판 아이디
				,ntt_tp:null//게시물 분류
				,frst_register_id_real:reply.FRST_REGISTER_ID_REAL//게시물 작성자 아이디
			}).then(res => {
				//console.log(res.data.rowsAffected)
				if(res.data.rowsAffected>0){
					props.onAlert('success','저장 성공하였습니다');
					history.push({
						pathname:"/svc/qna",
					})
				}else{
					props.onAlert('error','저장을 실패하였습니다.');
				}
			})
		}else if(flag==='QnAUpdate'){

			axios.post("/api/updateQnaDetail", {
				ntt_cn:content//게시물 내용
				,ntt_sj:qnaInfo.NTT_SJ //게시물 제목
				,sUserId:userData.user_no//게시자 아이디
				,sUserNm:userData.user_name//게시자 명
				,ntt_id:props.location.NTT_ID//게시물 아이디
				,bbs_id:props.location.BBS_ID //게시판 아이디
				,ntt_tp:null//게시물 분류
				,frst_register_id_real:qnaInfo.FRST_REGISTER_ID_REAL//게시물 작성자 아이디
			}).then(res => {
				//console.log(res.data.rowsAffected)
				if(res.data.rowsAffected>0){
					props.onAlert('success','저장 성공하였습니다');
					history.push({
						pathname:"/svc/qna",
					})
				}else{
					props.onAlert('error','저장을 실패하였습니다.');
				}
			})
		}

	}

	const replyInsert = () => {
		setUpdateReply(true)
		setFlag('replyInsert');
	}

	const replyUpdate = () => {
		setUpdateReply(true)
		setFlag('replyUpdate');
		setValue(reply.NTT_CN);
	}

	const QnADelete = () => {

		axios.post("/api/deleteQnaDetail", {
			sUserId:userData.user_no//게시자 아이디
			,bbs_id:props.location.BBS_ID //게시판 아이디
			,ntt_id:props.location.NTT_ID//게시물 아이디
		}).then(res => {
			//console.log(res.data)
			if(res.data.rowsAffected>0){
				props.onAlert('success','삭제 성공하였습니다');
				history.push({
					pathname:"/svc/qna",
				})
			}else{
				props.onAlert('error','삭제를 실패하였습니다.');
			}
		})
	  }

	const QnAUpdate = () => {
		setUpdateQnA(true);
		setFlag('QnAUpdate');
	}
	
	return (
	  	<React.Fragment>
			<Card className="m-4" >
				<CardContent>
					<h4 style={{textAlignLast:'left',color:'black'}}><Chat style={{color:'#00acc1'}}></Chat> Q & A 상세내용</h4>
				</CardContent>
				<CardBody >
					<TableContainer style={{overflow:'hidden'}}>
					<Table style={{maxWidth:'100%',tableLayout:'fixed',borderTop:'2px solid #06b3ba'}}>
						<TableBody>
							<TableRow>
								<TableCell style={{backgroundColor:'#f2fefd'}}>제목</TableCell>
								<TableCell colSpan={7} >{qnaInfo.NTT_SJ}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell style={{backgroundColor:'#f2fefd'}}>작성자</TableCell>
								<TableCell  >{qnaInfo.FRST_REGISTER_NM}({qnaInfo.FRST_REGISTER_ID})</TableCell>
								<TableCell style={{backgroundColor:'#f2fefd'}}>구분</TableCell>
								<TableCell  >{qnaInfo.SVC_TYPE_NM}</TableCell>
								<TableCell style={{backgroundColor:'#f2fefd'}}>작성일자</TableCell>
								<TableCell  >{qnaInfo.FRST_REGISTER_PNTTM}</TableCell>
								<TableCell style={{backgroundColor:'#f2fefd'}}>조회수</TableCell>
								<TableCell  >{qnaInfo.INQIRE_CO}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell style={{backgroundColor:'#f2fefd'}}>전화번호</TableCell>
								<TableCell  >{qnaInfo.TEL}</TableCell>
								<TableCell style={{backgroundColor:'#f2fefd'}}>이메일</TableCell>
								<TableCell >{qnaInfo.EMAIL}</TableCell>
								<TableCell style={{backgroundColor:'#f2fefd'}}>업체명</TableCell>
								<TableCell  >{qnaInfo.COMP_NAME}({qnaInfo.KLNET_ID})</TableCell>
								<TableCell style={{backgroundColor:'#f2fefd'}}>핸드폰</TableCell>
								<TableCell  >{qnaInfo.PHONE}</TableCell>
							</TableRow>
							{updateQnA?
								<>
									<TableRow>
										<TableCell colSpan={8} style={{maxWidth:'100%',height:"300px",padding:"0px"}}>
											<ReactQuill
												style={{width: "100%",height:"90%",borderBottom:"50px"}}
												ref={quillRef}
												theme='snow'
												placeholder='답변하기'
												value={content}
												onChange={(value)=>setContent(value)}
												modules={modules}
												formats={formats}
												/>
										</TableCell>
									</TableRow>
									<TableRow  style={{borderTop:'2px solid rgba(0, 0, 0, 0)'}}>
										<TableCell style={{borderTop:'2px solid rgba(0, 0, 0, 0)'}}/>
									</TableRow>
								</>:
								<TableRow>
									<TableCell colSpan={8} style={{borderBottom: '1px solid rgba(0, 0, 0, 0)'}}
									><div dangerouslySetInnerHTML={{__html:content}}/></TableCell>
								</TableRow>
							}
							
							{reply?updateReply?<></>:
							<>
								<TableRow>
									<TableCell colSpan={3} style={{backgroundColor:'#e5e5e5'}}><img src={ico_board_repeat_e}/>  {reply.FRST_REGISTER_NM}({reply.FRST_REGISTER_ID})</TableCell>
									<TableCell align="right" colSpan={5} style={{backgroundColor:'#e5e5e5'}}>{reply.FRST_REGISTER_PNTTM}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={8}><div style={{overflow:'auto'}}dangerouslySetInnerHTML={{__html:reply.NTT_CN}}/></TableCell>
								</TableRow>
							</>:<></>
							}
							{updateReply?
							<>	
								<TableRow>
									<TableCell colSpan={8} style={{backgroundColor:'#e5e5e5'}}><img src={ico_board_repeat_e}/>  운영자({props.location.login_user_no})</TableCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={8} style={{height:"300px",padding:"0px"}}>
										<ReactQuill
											style={{width: "100%",height:"90%",borderBottom:"50px"}}
											ref={quillRef}
											theme='snow'
											placeholder='답변하기'
											value={value}
											onChange={(value)=>setValue(value)}
											modules={modules}
											formats={formats}
											/>
									</TableCell>
								</TableRow>
							</>:<></>
							}
						</TableBody>
						<TableFooter style={{borderTop:"2px solid #fff"}}>
							<>
							<TableRow style={{height:"50px"}}/>
							{(props.userData.klnet_id === "KLTEST01"||props.userData.login_user_role==='Y')?reply? 
							<>
								<TableRow>
									<TableCell align="right" colSpan={8} style={{borderTop:'2px solid #06b3ba'}}>
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> goQnA()}>목록</Button>
										{updateReply?
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnASave()}>저장하기</Button>
										:<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> replyUpdate()}>답변수정</Button>
										}
										{props.location.FRST_REGISTER_ID_REAL===props.location.login_user_no?
										updateQnA?
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnASave()}>저장하기</Button>
										:<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnAUpdate()}>게시물수정</Button>								
										:<></>
										}
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnADelete()}>삭제</Button>
									</TableCell>
								</TableRow>
							</>:
							<>
								<TableRow>
									<TableCell align="right" colSpan={8} style={{borderTop:'2px solid #06b3ba'}}>
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> goQnA()}>목록</Button>
										{updateReply?
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnASave()}>저장하기</Button>
										:<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> replyInsert()}>답변하기</Button>
										}
										{props.location.FRST_REGISTER_ID_REAL===props.location.login_user_no?
										updateQnA?
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnASave()}>저장하기</Button>
										:<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnAUpdate()}>게시물수정</Button>								
										:<></>
										}
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnADelete()}>삭제</Button>
									</TableCell>
								</TableRow>
							</>:<></>
							}
							{(props.userData.klnet_id !== "KLTEST01"&&props.userData.login_user_role!=='Y')?(props.location.FRST_REGISTER_ID_REAL===props.location.login_user_no?
							<>
								<TableRow>
									<TableCell align="right" colSpan={8} style={{borderTop:'2px solid #06b3ba'}}>
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> goQnA()}>목록</Button>
										{updateQnA?
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnASave()}>저장하기</Button>
										:<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnAUpdate()}>게시물수정</Button>
										}
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> QnADelete()} >삭제</Button>
									</TableCell>
								</TableRow>
							</>:
							<>
								<TableRow>
										<TableCell align="right" colSpan={8} style={{borderTop:'2px solid #06b3ba'}}>
											<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> goQnA()}>목록</Button>
										</TableCell>
								</TableRow>
							</>)
							:<></>
							}
							</>
						</TableFooter>
					</Table>
					</TableContainer>
				</CardBody>
			</Card>
		</React.Fragment>
	);
  }
