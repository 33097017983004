import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup,Label} from "reactstrap";
import InputValid from 'components/CustomInput/InputValid.js';
import axios from 'axios';

export default function Document (props){
    const [document, setDocument] = useState({});
    const [openType, setOpenType] = useState("");


    useEffect(() => {
        setDocument(props.document);
    },[props.document]);
    useEffect(() => {
        setOpenType(props.openType);
    },[props.openType]);


    const fncOnChange = ( e, key ) => {
        e.preventDefault();
        setDocument({...document, [key]:(e.target.value.toUpperCase())||null});
    }
    // 완료된 내용은 부모로 전달
    const fncOnBlurDocument = (e) => {
        props.fncOnBlurDocument( document );
    }
    

    return (
        <>
            <Row>
            {(openType === 'BOOK') &&
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Bookmark Name</Label>
                        <InputValid 
                            type="text"
                            name="document_bookmark_name"
                            id="document_bookmark_name"
                            placeholder=""
                            maxLength="50"
                            value={document.document_bookmark_name?document.document_bookmark_name:''}
                            onChange={(e)=>fncOnChange(e, 'document_bookmark_name')}
                            onBlur={(e) => {fncOnBlurDocument(e)}}
                            validtype="text"
                            required={'BOOK'===openType?true:false} 
                        />
                    </FormGroup>
                </Col>}
            </Row>
            <Row>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0">User</Label>
                        <InputValid 
                            type="text"
                            name="docu_user_name"
                            id="docu_user_name"
                            maxLength="35"
                            value={document.docu_user_name?document.docu_user_name:''}
                            onChange={(e)=>fncOnChange(e, 'docu_user_name')}
                            onBlur={(e) => {fncOnBlurDocument(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6">  
                    <FormGroup>
                        <Label className="mb-0">Tel</Label>
                        <InputValid 
                            type="text"
                            name="docu_user_tel"
                            id="docu_user_tel"
                            maxLength="25"
                            value={document.docu_user_tel?document.docu_user_tel:''}
                            onChange={(e)=>fncOnChange(e, 'docu_user_tel')}
                            onBlur={(e) => {fncOnBlurDocument(e)}}
                            validtype="eng"
                            required={'CARD'===openType?true:false} 
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0">Fax</Label>
                        <InputValid 
                            type="text"
                            name="docu_user_fax"
                            id="docu_user_fax"
                            maxLength="25"
                            value={document.docu_user_fax?document.docu_user_fax:''}
                            onChange={(e)=>fncOnChange(e, 'docu_user_fax')}
                            onBlur={(e) => {fncOnBlurDocument(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6">  
                    <FormGroup>
                        <Label className="mb-0">Phone</Label>
                        <InputValid 
                            type="text"
                            name="docu_user_phone"
                            id="docu_user_phone"
                            maxLength="25"
                            value={document.docu_user_phone?document.docu_user_phone:''}
                            onChange={(e)=>fncOnChange(e, 'docu_user_phone')}
                            onBlur={(e) => {fncOnBlurDocument(e)}}
                            validtype="eng"
                            required={'CARD'===openType?true:false} 
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">E-mail</Label>
                        <InputValid 
                            type="text"
                            name="docu_user_email"
                            id="docu_user_email"
                            maxLength="30"
                            value={document.docu_user_email?document.docu_user_email:''}
                            onChange={(e)=>fncOnChange(e, 'docu_user_email')}
                            onBlur={(e) => {fncOnBlurDocument(e)}}
                            validtype="email"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
            </Row> 
            <Row>
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Accounting Mail</Label>
                        <InputValid 
                            type="text"
                            name="docu_tax_email"
                            id="docu_tax_email"
                            maxLength="30"
                            value={document.docu_tax_email?document.docu_tax_email:''}
                            onChange={(e)=>fncOnChange(e, 'docu_tax_email')}
                            onBlur={(e) => {fncOnBlurDocument(e)}}
                            validtype="email"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
            </Row> 
            {/* <Row>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0">Address</Label>
                        <InputValid 
                            type="text"
                            name="docu__address1"
                            id="docu__address1"
                            maxLength="35"
                            value={document.docu__address1?document.docu__address1:''}
                            onChange={(e)=>fncOnChange(e, 'docu__address1')}
                            onBlur={(e) => {fncOnBlurDocument(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0"></Label>
                        <InputValid 
                            type="text"
                            name="docu__address2"
                            id="docu__address2"
                            maxLength="35"
                            value={document.docu__address2?document.docu__address2:''}
                            onChange={(e)=>fncOnChange(e, 'docu__address2')}
                            onBlur={(e) => {fncOnBlurDocument(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
            </Row> */}
            
        </>
    );
}