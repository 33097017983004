import React, { useState, useEffect, forwardRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,Col, CardBody, Collapse,
     Button,FormGroup,Label,Card, UncontrolledTooltip,FormText,ListGroup, ListGroupItem,Badge} from "reactstrap";
import Select from "react-select";
import Switch from "react-bootstrap-switch";
import OthersBookmark from './OthersBookmark.js';
import axios from 'axios';
import InputValid from "components/CustomInput/InputValid.js";
import * as validation from 'components/common/validation.js';


const OthersCard = forwardRef((props,bookingFocus) => {
	const [show,setShow] = useState(false);
	const {bookmark,loadData,openWindow,lineCode, optionCheck, mergeConfig, lineConfigDetail} = props;
	const [others, setOthers] = useState([]);
	const [propsData, setPropsData] = useState({});
	const [bkgData, setBkgData] = useState([]);
	const [bkgNoArry,setBkgNoArry] = useState([]);
	const [coll, setColl] = useState(false);
	const [open, setOpen] = useState(false);
	const [serviceList, setServiceList] = useState([]);
	const [combineYn,setCombineYn] =useState(false);
	const [blTypeList,setBlTypeList] =useState([{value:'5',label:'ORIGINAL B/L'},{value:'3',label:'SURRENDER B/L'}]);
	//const blTypeList = [{value:'5',label:'ORIGINAL B/L'},{value:'3',label:'SURRENDER B/L'}];
	//const blTypeList = [{value:'5',label:'ORIGINAL B/L'},{value:'3',label:'SURRENDER B/L'},{value:'2',label:'SEAWAY B/L'}];
	const hblList = [{value:'Y',label:'Yes'},{value:'N',label:'No'}];
	const linePaymentList = [{value:'P',label:'PREPAID'},{value:'C',label:'COLLECT'}];

	useEffect(() => { 
		// setOthers(loadData);
		setOthers({...loadData,'bk_link':'Y',});
	},[loadData]);
	

	useEffect(() => { 
		if(!others.res_bkg_no){//getUserBookingList();
		setBkgNoArry([]);setCombineYn(false);}
		others.res_bkg_no&&!bkgNoArry[0]&&setBkgNoArry([others])
		if(others.res_bkg_no&&others.bkglist){
			setBkgNoArry(others.bkglist);
			others.bkglist.length>1&&setCombineYn(true);
		}
	},[others]);
	
	useEffect(() => {
		setColl(openWindow);
		// if(openWindow) {
		// 	getUserBookingList();
		// }
	},[openWindow]);
	useEffect(() => {
		if(props.userData && lineCode) {
			axios.post("/shipper/selectLineCodeServiceType",{ params:{line_code:lineCode} }).then(res => setServiceList(res.data));
			getUserBookingList();
		}
	},[lineCode]);

	useEffect(() => {
		// console.log('others.trans_service_code',others.trans_service_code);
		if(others.trans_service_code)
		selectEshipConfigDetail(others.trans_service_code,{...others});
	},[others.trans_service_code]);

	useEffect(() => {
		// console.log(lineConfigDetail)
		if(!lineCode)return;
		let list = blTypeList||[];
		if(lineCode!=='VSS'){list = [{value:'5',label:'ORIGINAL B/L'},{value:'3',label:'SURRENDER B/L'},{value:'2',label:'SEAWAY B/L'}]}//기존 하드코딩,,  
		if(lineConfigDetail?.hideBlTypeVal){list.forEach((x,ind)=>{if(x.value == lineConfigDetail?.hideBlTypeVal){list.splice(ind,1)}})}//추가 config옵션화
		setBlTypeList([...list]);
	},[lineConfigDetail]);

	const toggle = () => {
		props.onLoadData("ot");
		setPropsData({
			other_bookmark_seq:'',
			other_bookmark_name:'',
			sc_no:others.sc_no,
			trans_service_code:others.trans_service_code,
			bl_type:others.bl_type,
			line_payment_type:others.line_payment_type,
			hbl_yn:others.hbl_yn,
		});
		setOpen(!open);
	}
	const getUserBookingList = (e) => {
		if (lineCode){
			axios.post("/shipper/getUserBookingInfo",{klnet_id:props.userData?props.userData.klnet_id:'',bkg_no:'',lineCode:lineCode}).then(res => {
				setBkgData(res.data);
			});
		}
	}

	const onInitData = () => {
		setPropsData({
			other_bookmark_name:'',
			other_bookmark_seq:'',
			sc_no:'',
			trans_service_code:'',
			bl_type:'',
			line_payment_type:'',
			hbl_yn:'',
			bkglist:[],
		});
	}
	const onBookMarkDelete = () => {
		if(propsData && propsData.other_bookmark_seq) {
			axios.post("/shipper/setUserOtherBookmarkDel",{user_no:props.userData?props.userData.user_no:'',data:propsData}).then(res => {
				onInitData();
				props.onLoadData("ot");
				props.onAlert("success","선택한 BOOKMARK 가 삭제되었습니다.");
			});
		} else {
			props.onAlert("error","삭제 할 BOOKMARK를 선택해주세요.");
		}
		
	}
    // 자식의 Data 적용
	const onBookMarkData = (data) => {
		setPropsData(data);
	}
	
	const onSaveBookmark = () => {

		if(propsData.other_bookmark_name) {

			axios.post("/shipper/setUserOthersBookmark",{user_no:props.userData?props.userData.user_no:'',data:propsData}).then(res => {
				props.onLoadData("ot");
				if(propsData.other_bookmark_seq) {
					props.onAlert("success","작성한 BOOKMARK 가 수정되었습니다.");
				} else {
					props.onAlert("success","작성한 BOOKMARK 가 저장되었습니다.");
				}
	  	  	});
		} else {
			props.onAlert("error","other_bookmark_name 는 필수 입력 항목 입니다.");
		}
	}

	const onChangeOthers =(value)=> {
		if(value) {
			if(value.value > 0) {
				axios.post("/shipper/getUserOtherBookmark",{user_no:props.userData?props.userData.user_no:'',seq:value.value}).then(res => {

					const newOhter = {
						bl_type:res.data[0].bl_type?res.data[0].bl_type:others.bl_type,
						cargo_class:res.data[0].cargo_class?res.data[0].cargo_class:others.cargo_class,
						document_no:res.data[0].document_no?res.data[0].document_no:others.document_no,
						hbl_yn:res.data[0].hbl_yn?res.data[0].hbl_yn:others.hbl_yn,
						invoice_no: res.data[0].invoice_no?res.data[0].invoice_no:others.invoice_no,
						label:res.data[0].label?res.data[0].label:others.label,
						lc_expiry_date:res.data[0].lc_expiry_date?res.data[0].lc_expiry_date:others.lc_expiry_date,
						lc_no:res.data[0].lc_no?res.data[0].lc_no:others.lc_no,
						lc_yn:res.data[0].lc_yn?res.data[0].lc_yn:others.lc_yn,
						line_payment_type:res.data[0].line_payment_type?res.data[0].line_payment_type:others.line_payment_type,
						org_bl_need_yn:res.data[0].org_bl_need_yn?res.data[0].org_bl_need_yn:others.org_bl_need_yn,
						other_bookmark_name:res.data[0].other_bookmark_name?res.data[0].other_bookmark_name:others.other_bookmark_name,
						other_bookmark_seq:res.data[0].other_bookmark_seq?res.data[0].other_bookmark_seq:others.other_bookmark_seq,
						part_sr_qty:res.data[0].part_sr_qty?res.data[0].part_sr_qty:others.part_sr_qty,
						po_no:res.data[0].po_no?res.data[0].po_no:others.po_no,
						remark1:res.data[0].remark1?res.data[0].remark1:others.remark1,
						remark2:res.data[0].remark2?res.data[0].remark2:others.remark2,
						remark3:res.data[0].remark3?res.data[0].remark3:others.remark3,
						remark4:res.data[0].remark4?res.data[0].remark4:others.remark4,
						remark5:res.data[0].remark5?res.data[0].remark5:others.remark5,
						sc_no:res.data[0].sc_no?res.data[0].sc_no:others.sc_no,
						sr_amount:res.data[0].sr_amount?res.data[0].sr_amount:others.sr_amount,
						trans_service_code:res.data[0].trans_service_code?res.data[0].trans_service_code:others.trans_service_code,
						value:res.data[0].value?res.data[0].value:others.value,

						declarelist:res.data[0].hbl_yn?res.data[0].hbl_yn!=="N"?[]:others.declarelist:[]
					}
					const mergeData = Object.assign(others,newOhter);
					setOthers({...mergeData,'other_bookmark_seq':value.value,'other_bookmark_name':value.label});
					props.mergeData({...mergeData,'other_bookmark_seq':value.value,'other_bookmark_name':value.label});
					setColl(true);
				});
			}
		}else {
			const list={
				bl_type:null,
				cargo_class:null,
				document_no:null,
				hbl_yn:null,
				invoice_no:null,
				label:null,
				lc_expiry_date:null,
				lc_no:null,
				lc_yn:null,
				line_payment_type:null,
				org_bl_need_yn:null,
				other_bookmark_name:null,
				other_bookmark_seq:null,
				part_sr_qty:null,
				po_no:null,
				remark1:null,
				remark2:null,
				remark3:null,
				remark4:null,
				remark5:null,
				sc_no:null,
				sr_amount:null,
				trans_service_code:null,
				value:null,
				declarelist:[]
			}
			setOthers(list);
			props.mergeData(list);
		}
	}

	const onHandleReturnVal = (event,name) => { 
		let list = {...others, [name]:(event.target.value.toUpperCase())|| null};
		setOthers(list);
	}

	const onHandleSelectReturnVal = (value,name) => {
		let list;
		if('hbl_yn' === name) {
			if(value ==="Y") {
				list = {...others, [name]:value, declarelist:[]};
			}else {
				list = {...others, [name]:value};
			}
		}else {
			list = {...others, [name]:value};
		}
		// if('trans_service_code'===name){240304 삭제 (useEffect에서 체크중)
		// 	selectEshipConfigDetail(value,list);
		// 	return 
		// }
		setOthers(list);	
		props.mergeData(list);

	}
	
	const onPropsReturn = ()=> {
		props.mergeData(others);
	}
	//Term 옵션화 ( ex)CFS->CY CFS->CFS 일때 ~~~ )
	const selectEshipConfigDetail = async(service_code,list) => {
			let text = list;
				try{
					//CNTR_VAL
					// await axios.post("/shipper/selectEshipConfigDetail",{line_code:lineCode, work_code:'SR', set_type:'CNTR_VAL', service_code:service_code}).then(res => {
					// 	if(res.data.length>0){
					// 		res.data.forEach(function(el,index){
					// 			const key=el.component_id
					// 			const value=el.value_1
					// 			text = {...text, [key]:value};
					// 		});
					// 	}	
					// })
					//CARGO_DESC
					// await axios.post("/shipper/selectEshipConfigDetail",{line_code:lineCode, work_code:'SR', set_type:'CARGO_DESC', service_code:service_code}).then(res => {
					// 	if(res.data.length>0){
					// 		res.data.forEach(function(el,index){
					// 			const key=el.component_id
					// 			let value=el.value_1
					// 			if(el.value_2)value+='\n'+el.value_2
					// 			text = {...text, [key]:value};
					// 		});
					// 	}
					// })
					//DECLARE
					// await axios.post("/shipper/selectEshipConfigDetail",{line_code:lineCode, work_code:'SR', set_type:'DECLARE', service_code:service_code}).then(res => {
					// 	if(res.data.length>0){
					// 		res.data.forEach(function(el,index){
					// 			const key=el.component_id
					// 			const value=el.value_1
					// 			text = {...text, [key]:value};
					// 		});
					// 	}
					// })

					//기존 CNTR_VAL, DECLARE :  공통 ConfigDetail에서 사용 
					let result =  await optionCheck('trans_service_code',service_code);
            		let optionList = await mergeConfig({...result});
					text = {...text, ...optionList}; // CARGO_DESC은 하드코딩수정없이 그대로 사용하도록 조치 (추후 분리필요)

					setOthers(text);
					props.mergeData(text);
					// console.log(text)
				}catch(e){console.error(e)}
		}  
	
	const getBkgInfo = async(list,bkgNo,bkglist) => {
		// let optionList={}
		//CNTR_VAL
		// await axios.post("/shipper/selectEshipConfigDetail",{line_code:lineCode, work_code:'SR', set_type:'CNTR_VAL', service_code:bkglist[0]?bkglist[0].trans_service_code:''}).then(res => {
		// 	if(res.data.length>0){
		// 		res.data.forEach(function(el,index){
		// 			const key=el.component_id
		// 			const value=el.value_1
		// 			optionList = {...optionList, [key]:value};
		// 		});
		// 	}	
		// })
		//CARGO_DESC
		// await axios.post("/shipper/selectEshipConfigDetail",{line_code:lineCode, work_code:'SR', set_type:'CARGO_DESC', service_code:bkglist[0]?bkglist[0].trans_service_code:''}).then(res => {
		// 	if(res.data.length>0){
		// 		res.data.forEach(function(el,index){
		// 			const key=el.component_id
		// 			let value=el.value_1
		// 			if(el.value_2)value+='\n'+el.value_2
		// 			optionList = {...optionList, [key]:value};
		// 		});
		// 	}
		// })
		//DECLARE
		// await axios.post("/shipper/selectEshipConfigDetail",{line_code:lineCode, work_code:'SR', set_type:'DECLARE', service_code:bkglist[0]?bkglist[0].trans_service_code:''}).then(res => {
		// 	if(res.data.length>0){
		// 		res.data.forEach(function(el,index){
		// 			const key=el.component_id
		// 			const value=el.value_1
		// 			optionList = {...optionList, [key]:value};
		// 		});
		// 	}
		// })


		//기존 CNTR_VAL, DECLARE :  공통 ConfigDetail에서 사용 
		// CARGO_DESC은 하드코딩수정없이 그대로 사용하도록 조치
		let result =  await optionCheck('trans_service_code',bkglist[0]?bkglist[0].trans_service_code:'');
		let optionList = await mergeConfig({...result});
		// console.log('optionList', optionList)
		// console.log('list.goods_desc' ,list.goods_desc)
		await axios.post("/shipper/getUserBookingInfo",{klnet_id:props.userData?props.userData.klnet_id:'',bkg_no:bkgNo,lineCode:lineCode}).then(res => {
			if(res.data.length > 0 ) {
					var goodsDesc = "";
					if(list.goods_desc) {
						var goodsSplit = list.goods_desc.split('\n');
						if(goodsSplit[0].includes(`SHIPPER'S LOAD. COUNT & SEAL`)||goodsSplit[0].includes(`SAID TO CONTAIN :`)||(goodsSplit[0].includes(`SHIPPER'S LOAD. COUNT & SEAL`)&&goodsSplit[1].includes(`SAID TO CONTAIN :`))) {
							goodsDesc = list.goods_desc
						}else if(goodsSplit[0].includes('COMMODITY')) {
							goodsSplit[0] = "COMMODITY:".concat(res.data[0].commodity?res.data[0].commodity:"");
							goodsDesc = goodsSplit.join("\n");
						}else{
							if(res.data[0].commodity) {
								goodsSplit[0] = "COMMODITY:".concat(res.data[0].commodity?res.data[0].commodity:"");
								for(var i = 1; i < list.goods_desc.split('\n').length; i++) {
									goodsSplit[i] = list.goods_desc.split('\n')[i-1];
								}
								goodsDesc = goodsSplit.join("\n");
							}else {
								goodsDesc = list.goods_desc
							}
						}
					}else {
						// console.log(optionList?.goods_desc)
						// console.log(lineConfigDetail)
						if(optionList?.goods_desc||optionList?.goods_desc===''){
							goodsDesc = optionList.goods_desc;
						}else{
							goodsDesc = "COMMODITY:".concat(res.data[0].commodity?res.data[0].commodity:"");
						}
					}
					setOthers({...list,...res.data[0],goods_desc:goodsDesc,'sch_srd':res.data[0].sch_etd,'bk_link':'Y','bkglist':bkglist});
					props.mergeData({...list,...res.data[0],goods_desc:goodsDesc,'sch_srd':res.data[0].sch_etd,'bk_link':'Y','bkglist':bkglist});
					// setOthers({...list,...res.data[0],goods_desc:goodsDesc,'sch_srd':res.data[0].sch_etd,'bk_link':'Y','bkglist':bkglist,'DeclareCard':optionList.DeclareCard,'cntr_no':optionList.cntr_no,'cntr_code':optionList.cntr_code});
					// props.mergeData({...list,...res.data[0],goods_desc:goodsDesc,'sch_srd':res.data[0].sch_etd,'bk_link':'Y','bkglist':bkglist,'DeclareCard':optionList.DeclareCard,'cntr_no':optionList.cntr_no,'cntr_code':optionList.cntr_code});
			} else {
				setOthers({...list,'res_bkg_no':bkgNo,'bk_link':'N','bkglist':bkglist});	
				props.mergeData({...list,'res_bkg_no':bkgNo,'bk_link':'N','bkglist':bkglist});
				// setOthers({...list,'res_bkg_no':bkgNo,'bk_link':'N','bkglist':bkglist,'DeclareCard':optionList.DeclareCard,'cntr_no':optionList.cntr_no,'cntr_code':optionList.cntr_code});	
				// props.mergeData({...list,'res_bkg_no':bkgNo,'bk_link':'N','bkglist':bkglist,'DeclareCard':optionList.DeclareCard,'cntr_no':optionList.cntr_no,'cntr_code':optionList.cntr_code});
				props.onAlert('danger','부킹컨펌을 가져오는 도중 오류가 발생했습니다.');
			}
		}).catch(err => {
			console.log(err)
			props.onAlert('danger','정보를 가져오는 중 오류가 발생했습니다.');
			// return false;
		});
	}
	// const getBkgInfo = (list,bkgNo,bkglist) => {
		
	// 	axios.post("/shipper/getUserBookingInfo",{klnet_id:props.userData?props.userData.klnet_id:'',bkg_no:bkgNo,lineCode:lineCode}).then(res => {
	// 		if(res.data.length > 0 ) {
	// 			setOthers({...list,...res.data[0],'sch_srd':res.data[0].sch_etd,'bk_link':'Y','bkglist':bkglist});	
	// 			props.mergeData({...list,...res.data[0],'sch_srd':res.data[0].sch_etd,'bk_link':'Y','bkglist':bkglist});
	// 		} else {
	// 			setOthers({...list,'res_bkg_no':bkgNo,'bk_link':'N','bkglist':bkglist});	
	// 			props.mergeData({...list,'res_bkg_no':bkgNo,'bk_link':'N','bkglist':bkglist});
	// 		}
	// 	});
	// }
	const onChangeBookings = async(value) => {
		let bkg_no = value.res_bkg_no;
		// console.log('onChangeBookings >>> value : ',value, 'others : ',others,bkgNoArry,'combine >>:',combineYn)
	
		if(combineYn){///bkg n개 
			if(bkgNoArry.length<10){
				bkgNoArry.push(value);
				setBkgNoArry([...bkgNoArry]) ;
				if(bkgNoArry.length>0){
					props.mergeData({...others,'bkglist':bkgNoArry});
				}else{
					await getBkgInfo(others,bkg_no,value);
					props.mergeData({...others,'bkglist':bkgNoArry});
				}
			}else{ 
				props.onAlert("error","최대 10개 가능합니다");
			}
		}else{ ///bkg1개 
			// console.log('combine false > ', bkgNoArry)
			bkgNoArry[0]=value;
	
			setBkgNoArry([...bkgNoArry]) ;
			await getBkgInfo(others,bkg_no,bkgNoArry);
		}
	}
	const onCombineBkg = async(value)=>{
		// console.log(value,'others',others)
		setCombineYn(!combineYn)
		// console.log('onCombineBkg >',combineYn,[bkgNoArry[0]])
		setBkgNoArry([bkgNoArry[0]]) ;
		props.mergeData({...others,'bkglist':[bkgNoArry[0]]});
		setShow(false)
	}

	const onDeleteBookingNo = async(index,value) => {
		let newBkgArry=bkgNoArry.filter(item=>item.value!==value.value)
		// console.log('newBkgArry',newBkgArry,'others',others)
		setBkgNoArry(newBkgArry)
		props.mergeData({...others,'bkglist':newBkgArry});
		index==0&&getBkgInfo(others,newBkgArry[0].value,newBkgArry)
	}

  	return (
    	<>
			<Row id="Others">
				<Col xl="12" lg="12">
					<Card style={{zIndex:'11',border:'1px solid silver',borderRadius:'10px'}}>
						<CardBody className="pt-3 pb-0">  
							<Row className="pb-2">
								<Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>BOOKING
								</Col>
								<Col>
									<Row>
										<Col className="col-10 pr-0">
											<Select
												className="react-select react-select-primary"
												name="bookingbookmark"
												value={{value:others.other_bookmark_seq?others.other_bookmark_seq:'',label:others.other_bookmark_name?others.other_bookmark_name:'선택'}}
												onChange={(value)=>onChangeOthers(value)}
												options={bookmark}
												placeholder="선택"
												ref={bookingFocus}
												isClearable={others.other_bookmark_seq?true:false}/>
										</Col>
										<Col className="col-2 pl-auto pr-auto">
											<Button className="pl-0 pr-0" color="link" id="otbookmark" onClick={toggle}><i className="fa fa-bookmark-o fa-2x" /></Button>
											<UncontrolledTooltip delay={0} target="otbookmark">Bookmark</UncontrolledTooltip>
										</Col>
									</Row>
								</Col>
							</Row>
							<Collapse isOpen={coll}>
								<hr className="mt-0"/>
								<Row style={{fontSize:'12px'}}>
									<Col className="col-12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="col-2" >
													<Label className="mb-0">Bkg&nbsp;No</Label>
												</Col>
												<Col>
													<Select
														className="customSelect react-select-primary"
														name="bkg_no"
														// placeholder={
														// 	bkgData.length < 1 
														// 	?"Confirm Booking no data"
														// 	:combineYn?"선택":others.res_bkg_no}
														// value={others.res_bkg_no?others.res_bkg_no:''}
														value={{value:others.res_bkg_no?others.res_bkg_no:'',
														//combineYN true 무조건 라벨값: 선택 
														//			false 값 유무로 선택/값 바뀜 (bkgData.length 는 관계없음) 
														label:combineYn? "선택" 
																		:(others?.res_bkg_no)?others.res_bkg_no:"Confirm Booking Data"}}
														// bkgData.length < 1 
														// 		?!lineCode?validation.NO_LINECODE_MSG:"Confirm Booking Data"
														// 		:(combineYn||!others.res_bkg_no)?"선택":others.res_bkg_no
														onChange={onChangeBookings}                                                   
														options={bkgData.filter((obj)=> !bkgNoArry.find((item)=>obj.value==item.value))}
														getOptionLabel={(option)=>option.sr_status?option.label+" [ SR"+option.sr_status+"]":option.label}
														styles={{
																control: provided => ({...provided,border:!others.res_bkg_no?'1px solid red':others.part_bl && others.part_bl === 'Y'?'2px solid green':'' }),
																indicatorContainer: provided => ({...provided,color:''})
															}}
														isDisabled={others.part_bl && others.part_bl === 'Y'?true:false}/>
													<InputValid 
														type="hidden"
														name="res_bkg_no"
														id="res_bkg_no"
														maxLength="35"
														value={others.res_bkg_no?others.res_bkg_no:''}
														validtype="eng" 
														required={true}
														feedid="booking"/>
													{(!combineYn&&others.res_bkg_no&&others.bkglist[0].value==others.res_bkg_no&&others.bk_link === 'Y'&&others.part_bl !== 'Y')&&<FormText className="text-success">Booking info Apply</FormText>}
													{(!combineYn&&others.part_bl === 'Y')&&<FormText className="text-success">Part B/L</FormText>}
													<ListGroup  style={{border : '0px'}} >
														{combineYn&&bkgNoArry.map((value,index)=> (
														<ListGroupItem  key={index} style={{border : '0px'}}>
															<Row>
																<Col xs={10}>
																	{value.value} &nbsp;&nbsp; 
																	{value.value==others.res_bkg_no
																	?others.part_bl && others.part_bl === 'Y'
																		?<FormText className="text-success" style={{display:'inline-block'}}>Part B/L</FormText>
																		:<FormText className="text-success" style={{marginTop: 0 ,display:'inline-block'}}>Booking info Apply</FormText>
																	:''
																	}
																</Col>
																{!(others.part_bl && others.part_bl === 'Y')&&bkgNoArry.length>1&&
																	<Col xs={2}>
																		<button
																		className="close"
																		type="button"
																		style={{fontSize:'15px'}}
																		onClick={()=>onDeleteBookingNo(index,value)
																		}>×</button>
																	</Col>	
																}
															</Row>
														</ListGroupItem>))}
													</ListGroup>
												</Col>
												{others.part_bl !== 'Y'&&others.res_bkg_no&&
												<Col xl="2" className="col-2 pl-1 pt-2">
													{combineYn?
													<Badge className="mr-1" color="success" style={{cursor:'pointer'}} onClick={()=>bkgNoArry.length>1?setShow(true):onCombineBkg()}>
													COMBINE ON
													</Badge>
													:
													<Badge className="mr-1" color="default" style={{cursor:'pointer'}}  onClick={onCombineBkg}>
													COMBINE OFF
													</Badge>
													}
												</Col>
													}
											</Row>
										</FormGroup>
									</Col>
									<Col>
										<FormGroup className="mb-1">
											<Row>
												<Col className="col-2 pr-0">
													<Label className="mb-0">SC Number</Label>
												</Col>
												<Col style={{paddingTop:'2px'}} className="col-4">
													<InputValid 
														type="text"
														name="sc_no"
														id="sc_no"
														maxLength="12"
														value={others.sc_no?others.sc_no:''}
														onChange={(e)=>onHandleReturnVal(e, 'sc_no')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={false}
														feedid="booking"/>
												</Col>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0">BL&nbsp;Type</Label>
												</Col>
												<Col className="col-4">
													<Select
														className="customSelect"
														name="bl_type"
														value={{value:others.bl_type?others.bl_type:'',
														label:others.bl_type?
															(blTypeList.findIndex(x=>x.value===others.bl_type)>=0)?
															blTypeList[blTypeList.findIndex(x=>x.value===others.bl_type)].label:
																'선택':'선택'
														}}
														onChange = {(value)=>onHandleSelectReturnVal(value.value,'bl_type')}
														options={blTypeList}
															styles={{
																control: provided => ({...provided,border:!others.bl_type?'1px solid red':'' }),
																indicatorContainer: provided => ({...provided,color:''})
															}}/>
													<InputValid 
														hidden
														name="bl_type"
														id="bl_type"
														maxLength="1"
														value={others.bl_type?others.bl_type:''}
														validtype="eng"
														required={true} 
														readOnly
														feedid="booking"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="col-2 pr-0">
													<Label className="mb-0">Partner Code</Label>
												</Col>
												<Col style={{paddingTop:'2px'}} className="col-4">
													<InputValid 
														type="text"
														name="fwd_code"
														id="fwd_code"
														maxLength="12"
														value={others.fwd_code?others.fwd_code:''}
														onChange={(e)=>onHandleReturnVal(e, 'fwd_code')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={false}
														feedid="booking"/>
												</Col>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0">Partner Name</Label>
												</Col>
												<Col className="col-4">
													<InputValid 
														type="text"
														name="fwd_name1"
														id="fwd_name1"
														maxLength="12"
														value={others.fwd_name1?others.fwd_name1:''}
														onChange={(e)=>onHandleReturnVal(e, 'fwd_name1')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={false}
														feedid="booking"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col  className="pr-0 pt-1 col-2">
													<Label className="mb-0">Term</Label>
												</Col>
												<Col className="col-4">
													<Select
														className="customSelect"
														name="trans_service_code"
														value={{value:others.trans_service_code?others.trans_service_code:'',
														label:others.trans_service_code?
															(serviceList.findIndex(x=>x.value===others.trans_service_code)>=0)?
																	serviceList[serviceList.findIndex(x=>x.value===others.trans_service_code)].label:
																'선택':'선택'
														}}
														onChange = {(value)=>onHandleSelectReturnVal(value.value,'trans_service_code')}
														options={serviceList}
														styles={{
															control: provided => ({...provided,border:!others.trans_service_code?'1px solid red':'' }),
															indicatorContainer: provided => ({...provided,color:''})
														}}
													/>
													<InputValid 
														hidden
														name="trans_service_code"
														id="trans_service_code"
														maxLength="3"
														value={others.trans_service_code?others.trans_service_code:''}
														validtype="text"
														required={true} 
														readOnly
														feedid="booking"/>
												</Col>
												<Col  className="pr-0 pt-1 col-2">
													<Label className="mb-0">H-BL</Label>
												</Col>
												<Col className="col-4">
													<Select
														className="customSelect"
														name="hbl_yn"
														value={{value:others.hbl_yn?others.hbl_yn:'',
														label:others.hbl_yn?
															(hblList.findIndex(x=>x.value===others.hbl_yn)>=0)?
																	hblList[hblList.findIndex(x=>x.value===others.hbl_yn)].label:
																'선택':'선택'
														}}
														onChange = {(value)=>onHandleSelectReturnVal(value.value,'hbl_yn')}
														options={hblList}
															styles={{
																control: provided => ({...provided,border:!others.hbl_yn?'1px solid red':'' }),
																indicatorContainer: provided => ({...provided,color:''})
															}}
													/>
													<InputValid 
														hidden
														name="hbl_yn"
														id="hbl_yn"
														maxLength="1"
														value={others.hbl_yn?others.hbl_yn:''}
														validtype="eng"
														required={true} 
														readOnly
														feedid="booking"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col>
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 col-2">
													<Label className="mb-0">Ocean Freight</Label>
												</Col>
												<Col style={{paddingTop:'2px'}}>
													<Select
														className="customSelect"
														name="line_payment_type"
														value={{value:others.line_payment_type?others.line_payment_type:'',
														label:others.line_payment_type?
															(linePaymentList.findIndex(x=>x.value===others.line_payment_type)>=0)?
																	linePaymentList[linePaymentList.findIndex(x=>x.value===others.line_payment_type)].label:
																'선택':'선택'
														}}
														onChange = {(value)=>onHandleSelectReturnVal(value.value,'line_payment_type')}
														options={linePaymentList}
															styles={{
																control: provided => ({...provided,border:!others.line_payment_type?'1px solid red':'' }),
																indicatorContainer: provided => ({...provided,color:''})
															}}/>
													<InputValid 
														hidden
														name="line_payment_type"
														id="line_payment_type"
														maxLength="1"
														value={others.line_payment_type?others.line_payment_type:''}
														validtype="eng"
														required={true} 
														readOnly
														feedid="booking"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0">Remark</Label>
												</Col>
												<Col style={{paddingTop:'2px'}}>
													<InputValid 
													type="text"
													name="remark1"
													id="remark1"
													maxLength="70"
													value={others.remark1?others.remark1:''}
													onChange={(e)=>onHandleReturnVal(e, 'remark1')}
													onBlur={onPropsReturn}
													validtype="eng"
													required={false}
													feedid="booking"
													/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0"></Label>
												</Col>
												<Col style={{paddingTop:'2px'}}>
													<InputValid 
													type="text"
													name="remark2"
													id="remark2"
													maxLength="70"
													value={others.remark2?others.remark2:''}
													onChange={(e)=>onHandleReturnVal(e, 'remark2')}
													onBlur={onPropsReturn}
													validtype="eng"
													required={false}
													feedid="booking"
													/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0"></Label>
												</Col>
												<Col style={{paddingTop:'2px'}}>
													<InputValid 
													type="text"
													name="remark3"
													id="remark3"
													maxLength="70"
													value={others.remark3?others.remark3:''}
													onChange={(e)=>onHandleReturnVal(e, 'remark3')}
													onBlur={onPropsReturn}
													validtype="eng" 
													required={false}
													feedid="booking"
													/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0"></Label>
												</Col>
												<Col style={{paddingTop:'2px'}}>
													<InputValid 
													type="text"
													name="remark4"
													id="remark4"
													maxLength="70"
													value={others.remark4?others.remark4:''}
													onChange={(e)=>onHandleReturnVal(e, 'remark4')}
													onBlur={onPropsReturn}
													validtype="eng" 
													required={false}
													feedid="booking"
													/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0"></Label>
												</Col>
												<Col style={{paddingTop:'2px'}}>
													<InputValid 
													type="text"
													name="remark5"
													id="remark5"
													maxLength="70"
													value={others.remark5?others.remark5:''}
													onChange={(e)=>onHandleReturnVal(e, 'remark5')}
													onBlur={onPropsReturn}
													validtype="eng" 
													required={false}
													feedid="booking"
													/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
								</Row>   	
							</Collapse>
						</CardBody>
						<Col className="text-center col-12 p-0" onClick={() => {setColl(!coll);if(!coll){getUserBookingList();}}}>        
							<Button
								className="p-0"
								color="link"
								id="otmore"
								onClick={() => {setColl(!coll);if(!coll){getUserBookingList();}}}
								style={{height:'21px',marginBottom:'4px',width:'100%'}}>
								{coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
							</Button>
							<UncontrolledTooltip delay={0} target="otmore">{coll?'Close':'Open'}</UncontrolledTooltip>
						</Col>
					</Card>
				</Col>
			</Row>
			<Modal isOpen={open} toggle={toggle} className="pt-0" size="lg">
				<ModalHeader toggle={toggle} className="pt-3 pb-3">Booking BookMark</ModalHeader>
					<ModalBody className="p-3">
						<OthersBookmark 
							bookmark={bookmark} 
							loadFormData={propsData} 
							onPropsOtBookmark={onBookMarkData} 
							onPropsOtDeleteBookmark={onBookMarkDelete} 
							term={serviceList}
							blTypeList={blTypeList}
							hblList={hblList}
							linePaymentList={linePaymentList}
							{...props}
						/>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={onInitData}>NEW</Button>
						<Button color="primary" onClick={onSaveBookmark}>SAVE</Button>
						<Button color="primary" onClick={onBookMarkDelete}>DELETE</Button>
						<Button color="secondary" onClick={()=>setOpen(!open)}>CANCEL</Button>	
					</ModalFooter>
			</Modal>
			<Modal isOpen={show}>
			<div className="modal-header no-border-header">
				<button
					className="close"
					type="button"
					onClick={() => setShow(false)}
					>×</button>
			</div>
			<div className="modal-body text-center pl-0 pr-0">
				<h5>{bkgNoArry[0]&&bkgNoArry[0].value}만 남기시겠습니까?</h5>
			</div>
			<div className="modal-footer">
				<div className="left-side">
					<Button className="btn-link" color="danger" type="button" 
					onClick={onCombineBkg}>Yes</Button>
				</div>
				<div className="divider" />
				<div className="right-side">
					<Button className="btn-link" color="default" type="button" onClick={() => setShow(false)}>No</Button>
				</div>
			</div>
		</Modal>
    	</>
    );
});

export default OthersCard;