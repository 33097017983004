import React , { useState }from 'react'
import cn from 'classnames';
import axios from 'axios';
import Filesaver from 'file-saver';

import * as validation from 'muiComponents/common/validation.js'
// css
import Style from './manual.module.css'
import { propTypes } from 'reactstrap/lib/TooltipPopoverWrapper'

// import icoRight from '@/assetsNew/image/ico/ico-arr-right.svg'

const Manual = (props) => {
    const {toggle,onAlert,userData}=props;
    const [manualList,setManualList]=useState(
        // [{downloadPath:'downLoadFile/manual/Manual_eShipping.pdf', name:'e-Shipping',  fileName:'e-Shipping Manual.pdf', fileSize:'' }//'321 KB'
        [{downloadPath:'/OWNER/MANUAL/Manual_eShipping.pdf', name:'e-Shipping',  fileName:'e-Shipping Manual.pdf', fileSize:'' }//'321 KB'
        ,{downloadPath:'', name:'수출입 적하목록',  fileName:'수출입 적하목록 Manual.pdf', fileSize:'' }
        ,{downloadPath:'', name:'e-A/N',  fileName:'e-A/N Manual.pdf', fileSize:'' }
        ,{downloadPath:'', name:'c-D/O',  fileName:'c-D/O Manual.pdf', fileSize:'' }
        ,{downloadPath:'', name:'SUR CHG 인보이스',  fileName:'SUR CHG 인보이스 Manual.pdf', fileSize:'' }
        ,{downloadPath:'', name:'수입 운임 인보이스',  fileName:'수입 운임 인보이스 Manual.pdf', fileSize:'' }
        ,{downloadPath:'', name:'수출 운임 인보이스',  fileName:'수출 운임 인보이스 Manual.pdf', fileSize:'' }
        ]
    );
    const onHandleDownload = (value)=>{
        if(!userData){props.onAlert('error', validation.NOTLOGIN_MSG); return;}
        // downloadPath
        if(!value.downloadPath){onAlert('error','준비 중'); return false;}
        axios.post("/shipper/downloadbyPath",{filePath : value.downloadPath, fileName:value.fileName }, {responseType:'blob',})
        .then(res => {
            Filesaver.saveAs(new Blob([res.data]),value.fileName);
        }).catch(err => {
            console.log(err)
            props.onAlert('error', '다운로드에 실패하였습니다. 관리자 문의바랍니다.');
        })
    }
    return (
        <div className={cn(Style.wrapper, Style.ptype)}>
            <div className={Style.popHeader}>
                <div className={Style.ptit}>
                    <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-manual-tit.png`}/>
                    <strong>로지스뷰 업무 매뉴얼</strong>
                </div>
                <div className={Style.plogo}><img src={process.env.PUBLIC_URL  + `/assetsNew/image/etc/etc-logo.png`}/></div>
            </div>
            <div className={Style.popCont}>
                  <ul>
                      {manualList&&manualList.map((value,index)=>
                      <li key={index}>
                          <div className={Style.key}>{value.name}</div>
                          <div className={Style.value}>
                              <div className={Style.fileInfo}>
                                  <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-pdf.png`}/>
                                  <strong>{value.fileName}</strong>
                                  <span>{value.fileSize}</span>
                              </div>
                              <button type="button" onClick={()=>onHandleDownload(value)}>
                                  <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-download.png`}/>
                                  <span>다운로드</span>
                              </button>
                          </div>
                      </li>
                      )}
                    {/* <li>
                        <div className={Style.key}>e-Shipping</div>
                        <div className={Style.value}>
                            <div className={Style.fileInfo}>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-pdf.png`}/>
                                <strong>e-Shipping Manual.pdf</strong>
                                <span>321 KB</span>
                            </div>
                            <button type="button">
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-download.png`}/>
                                <span>다운로드</span>
                            </button>
                        </div>
                    </li>
                    <li>
                        <div className={Style.key}>수출입 적하목록</div>
                        <div className={Style.value}>
                            <div className={Style.fileInfo}>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-pdf.png`}/>
                                <strong>수출입 적하목록 Manual.pdf</strong>
                                <span>321 KB</span>
                            </div>
                            <button type="button">
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-download.png`}/>
                                <span>다운로드</span>
                            </button>
                        </div>
                    </li>
                    <li>
                        <div className={Style.key}>e-A/N</div>
                        <div className={Style.value}>
                            <div className={Style.fileInfo}>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-pdf.png`}/>
                                <strong>e-A/N Manual.pdf</strong>
                                <span>321 KB</span>
                            </div>
                            <button type="button">
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-download.png`}/>
                                <span>다운로드</span>
                            </button>
                        </div>
                    </li>
                    <li>
                        <div className={Style.key}>c-D/O</div>
                        <div className={Style.value}>
                            <div className={Style.fileInfo}>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-pdf.png`}/>
                                <strong>c-D/O Manual.pdf</strong>
                                <span>321 KB</span>
                            </div>
                            <button type="button">
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-download.png`}/>
                                <span>다운로드</span>
                            </button>
                        </div>
                    </li>
                    <li>
                        <div className={Style.key}>SUR CHG 인보이스</div>
                        <div className={Style.value}>
                            <div className={Style.fileInfo}>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-pdf.png`}/>
                                <strong>SUR CHG 인보이스 Manual.pdf</strong>
                                <span>321 KB</span>
                            </div>
                            <button type="button">
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-download.png`}/>
                                <span>다운로드</span>
                            </button>
                        </div>
                    </li>
                    <li>
                        <div className={Style.key}>수입 운임 인보이스</div>
                        <div className={Style.value}>
                            <div className={Style.fileInfo}>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-pdf.png`}/>
                                <strong>수입 운임 인보이스 Manual.pdf</strong>
                                <span>321 KB</span>
                            </div>
                            <button type="button">
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-download.png`}/>
                                <span>다운로드</span>
                            </button>
                        </div>
                    </li>
                    <li>
                        <div className={Style.key}>수출 운임 인보이스</div>
                        <div className={Style.value}>
                            <div className={Style.fileInfo}>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-pdf.png`}/>
                                <strong>수출 운임 인보이스 Manual.pdf</strong>
                                <span>321 KB</span>
                            </div>
                            <button type="button">
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-download.png`}/>
                                <span>다운로드</span>
                            </button>
                        </div>
                    </li> */}
                </ul>
            </div>
            <div className={Style.popBtnWrap} onClick={toggle}>
                <button type="button">닫기</button>
            </div>
        </div>
    )
  }
  
  export default Manual
  