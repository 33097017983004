import React,{useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
//import Grid from '@material-ui/core/Grid';
//import GridItem from "muiComponents/Grid/GridItem.js";
//import GridContainer from "muiComponents/Grid/GridContainer.js";
import Card from "muiComponents/Card/Card.js";
import CardHeader from "muiComponents/Card/CardHeader.js";
import CardBody from "muiComponents/Card/CardBody.js";
//import Icon from "@material-ui/core/Icon";
//import CardIcon from "muiComponents/Card/CardIcon.js";
import {Grid, TextField, //Paper, 
	Table, TableBody, TableRow, TableCell, TableHead} from '@material-ui/core';

import Button from "muiComponents/CustomButtons/Button.js";
import CardIcon from "muiComponents/Card/CardIcon.js";
//import IconM from "@material-ui/core/Icon";
import axios from 'axios';
//import CustomSelect from "muiComponents/CustomInput/CustomSelect.js";
//import CustomInput from "muiComponents/CustomInput/CustomInput.js";
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import Assignment from "@material-ui/icons/Assignment";

const useStyless = makeStyles(theme => ({

  headerCell: {
    backgroundColor: "#f2fefd",
    width:'30%',
  padding:'7px',
  textAlign:'right'
  },
  bodyCell: {
    textAlign: "left",
  padding:'7px',
  },
  tableHeaderCellStyle: {
    borderStyle:'solid',
    borderColor:'#dbdbdb',
    borderWidth:'1px',
    padding:'7px',
    backgroundColor:'#f2fefd'
  },tableCellStyle: {
    borderStyle:'solid',
    borderColor:'#dbdbdb',
    borderWidth:'1px',
    padding:'7px',
  }
}));
// function Alert(props) {
// 	return <MuiAlert elevation={6} variant="filled" {...props} />;
// }


export default function FlcoLst(props) {
  const {alertMessage} = props;
  // const [severity, setSeverity] = useState("");
  const [userStore, setUseStore] = useState(props.store);
  const classes = useStyless();
  const [tCnt, setTCnt] = useState('0');
  const [flcName, setFlcName] = useState("");
  // const [alertOpen, setAlertOpen] = useState(false);
  // const [errMessage, setErrmessage] = useState("");
  const [gridData, setGridData] = useState([]);
  React.useEffect(() => {
	    return () => {
	      console.log('cleanup');
	    };
	  }, []);
  // const handleAlertClose = (event, reason) => {
  //   if(reason ==='clickaway') {
  //     return;
  //   }
  //   setAlertOpen(false);
  // }
  // const AlertMessage = (message,icon) => {
  //   setErrmessage(message)
  //   setSeverity(icon)
  //   setAlertOpen(true);
  // }

  const onSubmit = () => {
	
	  if(props.userData) {
    axios.post("/com/uniPassApiFlcoLst",{param:flcName}).then(
      res => {
        if(res.data.message == "SUCCESS") {
          alertMessage("조회가 완료되었습니다.","success");
          setTCnt(res.data.infoData.cnt)
          setGridData(res.data.infoData.data);
        }else if (res.data.message == "NO_DATA") {
          alertMessage("조회결과가 없습니다.","error");
          setTCnt("0")
          setGridData([]);
        }else {
          alertMessage(res.data.errMsg,"error")
        }
      }
    ).catch(err => {
        if(err.response.status === 401) {
        	props.openLogin();
        }
        });
	  } else {
		  props.openLogin();
	  }
  }
  return (
    <div>
    <Card>
      <CardBody style={{paddingTop:'0',paddingBottom:'0'}}>
      <Grid item xs={12} sm={9} md={12}>
			     	<Grid container spacing={1} justify="space-between">
			     		<Grid item xs={12} md={3}>
                    <TextField id="" label="항공사명" onChange={event => setFlcName(event.target.value)} value={flcName} fullWidth />			
              </Grid>
              <Grid item xs={2} sm={2} md={2} style={{textAlignLast:'right', paddingTop:"20px"}}>
                {/* <Button color="info" onClick = {onSubmit} startIcon={<CancelIcon/>}>초기화</Button> */}
                <Button color="info" onClick = {onSubmit} endIcon={<SearchIcon/>} >SEARCH</Button>
              </Grid>
            </Grid>
      </Grid>
      </CardBody>
      </Card>
      <Card>
        <CardHeader color="info" stats icon >
	        <CardIcon color="info" style={{padding:'0'}}>
	        <Assignment />
	      </CardIcon>
          <h4 style={{textAlign: "left",color:"#000000", paddingTop:"10px"}}><b>항공사 목록</b></h4>
          <span style={{textAlign: "right",color:"#000000", paddingRight:"20px", paddingTop:"20px"}}>TOTAL: {tCnt}</span>
        </CardHeader>
        <CardBody>
          <Table style={{borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7'}}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeaderCellStyle}>항공사영문명</TableCell>
                  <TableCell className={classes.tableHeaderCellStyle}>항공사한글명</TableCell>
                  <TableCell className={classes.tableHeaderCellStyle}>항공사부호</TableCell>
                  <TableCell className={classes.tableHeaderCellStyle}>대표자명</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                    gridData.map((element,key) => {
                        return(
                          <TableRow key={key}>
                            {element.flcoEnglNm != undefined?<TableCell className={classes.tableCellStyle}>{element.flcoEnglNm._text}</TableCell>:<TableCell className={classes.tableCellStyle}></TableCell>}
                            {element.flcoKoreNm != undefined?<TableCell className={classes.tableCellStyle}>{element.flcoKoreNm._text}</TableCell>:<TableCell className={classes.tableCellStyle}></TableCell>}
                            {element.flcoSgn != undefined?<TableCell className={classes.tableCellStyle}>{element.flcoSgn._text}</TableCell>:<TableCell className={classes.tableCellStyle}></TableCell>}
                            {element.rppnNm != undefined?<TableCell className={classes.tableCellStyle}>{element.rppnNm._text}</TableCell>:<TableCell className={classes.tableCellStyle}></TableCell>}
                          </TableRow>
                        )
                        })
                }
              </TableBody>
            </Table>
          </CardBody>
        </Card>


    
    {/* <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
		<Alert 
			onClose={handleAlertClose}
			severity={severity}>
				{errMessage}

		</Alert>
	</Snackbar> */}
  </div>
  );
}
