import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup,Label} from "reactstrap";
import InputValid from 'components/CustomInput/InputValid.js';
import axios from 'axios';

export default function Shipper (props){
    const [shipper, setShipper] = useState({});
    const [openType, setOpenType] = useState("");

    const {shipperCompanyList,bookingKecaYN} = props;

    useEffect(() => {
        setShipper(props.shipper);
    },[props.shipper]);
    useEffect(() => {
        setOpenType(props.openType);
    },[props.openType]);


    const fncOnChange = ( e, key ) => {
        if(key==='shp_address2'&&!shipper.shp_address1){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        if(key==='shp_address3'&&(!shipper.shp_address1||!shipper.shp_address2)){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        if(key==='shp_address4'&&(!shipper.shp_address1||!shipper.shp_address2||!shipper.shp_address3)){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        if(key==='shp_address5'&&(!shipper.shp_address1||!shipper.shp_address2||!shipper.shp_address3||!shipper.shp_address4)){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }

        e.preventDefault();
        setShipper({...shipper, [key]:(e.target.value.toUpperCase())||null});
    }
    // 완료된 내용은 부모로 전달
    const fncOnBlurShipper = (e) => {
        props.fncOnBlurShipper( shipper );
    }
    const fncOnKeyPress = async(e)=>{
        // enter 인 경우 
        if( 13 === e.charCode ) {
            if( e.target.value.length < 10) return false;
            if( e.target.value && e.target.value.length === 10 ) {
                let row = shipperCompanyList.find(v=>v.business_number === e.target.value)
                if( row ) {
                    axios.post("/com/getCompanyInfo",{ klnetId: row.klnet_id,}).then(res => {
                        let company = res.data[0];
                        if( company.REG_NO ) {
                            setShipper({...shipper, business_number:company.REG_NO, shp_code:row.partner_code, shp_name1:company.CNAME_KR})
                            props.fncBookingParent({...shipper, business_number:company.REG_NO, shp_code:row.partner_code, shp_name1:company.CNAME_KR});
                        } else {
                            setShipper({...shipper, business_number:null, shp_code:null, shp_name1:null})
                            props.fncBookingParent({...shipper, business_number:null, shp_code:null, shp_name1:null});
                        }
                    });
                } else {
                    props.onAlert("danger", "결과가 없습니다.");
                    setShipper({...shipper, business_number:null, shp_code:null, shp_name1:null})
                    props.fncBookingParent({...shipper, business_number:null, shp_code:null, shp_name1:null});
                }
            }
        }
    }


    const fncOnBlurBusinessNumber =(e)=> {
        if( !e.target.value ) {
            setShipper({...shipper, business_number:null, shp_code:null, shp_name1:null})
            props.fncBookingParent({...shipper, business_number:null, shp_code:null, shp_name1:null});
        }
    }



    const fncSearchBn = () => {
        if( !shipper.business_number ) return false;
        if( shipper.business_number.length < 10) return false;
        if( shipper.business_number && shipper.business_number.length === 10 ) {
            let row = shipperCompanyList.find(v=>v.business_number === shipper.business_number)
            if( row ) {
                axios.post("/com/getCompanyInfo",{ klnetId: row.klnet_id,}).then(res => {
                    let company = res.data[0];
                    if( company.REG_NO ) {
                        setShipper({...shipper, business_number:company.REG_NO, shp_code:row.partner_code, shp_name1:company.CNAME_KR})
                        props.fncBookingParent({...shipper, business_number:company.REG_NO, shp_code:row.partner_code, shp_name1:company.CNAME_KR});
                    } else {
                        setShipper({...shipper, business_number:null, shp_code:null, shp_name1:null})
                        props.fncBookingParent({...shipper, business_number:null, shp_code:null, shp_name1:null});
                    }
                });
            } else {
                props.onAlert("danger", "결과가 없습니다.");
                setShipper({...shipper, business_number:null, shp_code:null, shp_name1:null})
                props.fncBookingParent({...shipper, business_number:null, shp_code:null, shp_name1:null});
            }
        }
    }
    return (
        <>
            <Row>
            {(openType === 'BOOK') &&
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Bookmark Name</Label>
                        <InputValid 
                            type="text"
                            name="shipper_bookmark_name"
                            id="shipper_bookmark_name"
                            placeholder=""
                            maxLength="50"
                            value={shipper.shipper_bookmark_name?shipper.shipper_bookmark_name:''}
                            onChange={(e)=>fncOnChange(e, 'shipper_bookmark_name')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="text"
                            required={'BOOK'===openType?true:false} 
                        />
                    </FormGroup>
                </Col>}
            </Row>
            <Row>
             {(openType === 'BOOK')?<></>:
                <Col xl="12" lg="12" md="12">
                    <FormGroup className="mb-1">
                        <Label className="mb-0">사업자 번호</Label>
                        <InputValid 
                            type="text"
                            name="business_number"
                            id="business_number"
                            placeholder="사업자 번호 입력 후 엔터"
                            maxLength="10"
                            value={shipper.business_number?shipper.business_number:''}
                            onChange={(e)=>fncOnChange(e, 'business_number')}
                            onBlur={(e) => {fncOnBlurBusinessNumber(e)}}
                            onKeyPress={(e)=>fncOnKeyPress(e)}
                            validtype="text" 
                            required={true} 
                            feedid="shipper"
                            inputgrouptext={<i className="fa fa-search " onClick={()=>fncSearchBn()}/>}
                        />
                    </FormGroup>
                </Col>}
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Shipper Code</Label>
                        <InputValid 
                            type="text"
                            name="shp_code"
                            id="shp_code"
                            maxLength="35"
                            value={shipper.shp_code?shipper.shp_code:''}
                            onChange={(e)=>fncOnChange(e, 'shp_code')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="engNumber"
                            required={'CARD'===openType?true:false} 
                        />
                    </FormGroup>
                </Col>
                {/* bookingKecaYN&&bookingKecaYN==='Y'?  */}
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Shipper Name(한글)</Label>
                        <InputValid 
                            type="text"
                            name="shp_name1"
                            id="shp_name1"
                            maxLength="35"
                            value={shipper.shp_name1?shipper.shp_name1:''}
                            onChange={(e)=>fncOnChange(e, 'shp_name1')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="text"
                            required={'CARD'===openType?true:false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Shipper Name(영문)</Label>
                        <InputValid 
                            type="text"
                            name="shp_name_eng"
                            id="shp_name_eng"
                            maxLength="35"
                            value={shipper.shp_name_eng?shipper.shp_name_eng:''}
                            onChange={(e)=>fncOnChange(e, 'shp_name_eng')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="eng"
                            required={'CARD'===openType?true:false} 
                        />
                    </FormGroup>
                </Col>
                
            </Row>
            <Row>
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">E-mail</Label>
                        <InputValid 
                            type="text"
                            name="shp_user_email"
                            id="shp_user_email"
                            maxLength="50"
                            value={shipper.shp_user_email?shipper.shp_user_email:''}
                            onChange={(e)=>fncOnChange(e, 'shp_user_email')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="email"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
            </Row> 
            {/* {bookingKecaYN&&bookingKecaYN==='Y'?  */}
            <Row>
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Person Name(한글)</Label>
                        <InputValid 
                            type="text"
                            name="shp_user_name"
                            id="shp_user_name"
                            maxLength="17"
                            value={shipper.shp_user_name?shipper.shp_user_name:''}
                            onChange={(e)=>fncOnChange(e, 'shp_user_name')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="text"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
            </Row> 
            <Row>
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Person Name(영문)</Label>
                        <InputValid 
                            type="text"
                            name="shp_user_name_eng"
                            id="shp_user_name_eng"
                            maxLength="17"
                            value={shipper.shp_user_name_eng?shipper.shp_user_name_eng:''}
                            onChange={(e)=>fncOnChange(e, 'shp_user_name_eng')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
            </Row> 
            <Row>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0">Address</Label>
                        <InputValid 
                            type="text"
                            name="shp_address1"
                            id="shp_address1"
                            maxLength="35"
                            value={shipper.shp_address1?shipper.shp_address1:''}
                            onChange={(e)=>fncOnChange(e, 'shp_address1')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0"></Label>
                        <InputValid 
                            type="text"
                            name="shp_address2"
                            id="shp_address2"
                            maxLength="35"
                            value={shipper.shp_address2?shipper.shp_address2:''}
                            onChange={(e)=>fncOnChange(e, 'shp_address2')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0"></Label>
                        <InputValid 
                            type="text"
                            name="shp_address3"
                            id="shp_address3"
                            maxLength="35"
                            value={shipper.shp_address3?shipper.shp_address3:''}
                            onChange={(e)=>fncOnChange(e, 'shp_address3')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="eng"
                            required={false} 
                        />
                    </FormGroup>
                </Col>
                <Col xl="6" lg="6" md="6">
                    <FormGroup>
                        <Label className="mb-0"></Label>
                        <InputValid 
                            type="text"
                            name="shp_address4"
                            id="shp_address4"
                            maxLength="35"
                            value={shipper.shp_address4?shipper.shp_address4:''}
                            onChange={(e)=>fncOnChange(e, 'shp_address4')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="eng"
                            required={false}
                        />
                    </FormGroup>
                </Col>
            </Row> 
            <Row>
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0"></Label>
                        <InputValid 
                            type="text"
                            name="shp_address5"
                            id="shp_address5"
                            maxLength="35"
                            value={shipper.shp_address5?shipper.shp_address5:''}
                            onChange={(e)=>fncOnChange(e, 'shp_address5')}
                            onBlur={(e) => {fncOnBlurShipper(e)}}
                            validtype="eng"
                            required={false}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
}