import React, {useState, useEffect}from 'react';

import {Input,FormFeedback, InputGroup, InputGroupText, InputGroupAddon, Alert} from 'reactstrap';
import * as validation from 'components/common/validation.js';
import moment from 'moment';

export default function InputValidCustom (props) {
/**
 * <InputValid 
    type="text"
    name="cntr_qty"
    id="cntr_qty"
    bsSize={("MAIN"===openType)?'sm':null}
    placeholder=""
    onChange={(e)=>fncOnChange(e, 'cntr_qty')}
    onBlur={(e) => {fncOnBlur(e)}}
    maxLength="4" --> 필수
    value={container.cntr_qty?container.cntr_qty:''} --> 필수
    validtype="text" --> (email, tel) 필수 형식 체크(custom prperty)
    required={'CARD'===openType||'MAIN'===openType?true:false}  --> 필수입력은 true 아닌경우 false(길이만 체크)(custom prperty)
    feedid="container" --> 필수 validtation 실행 시 open할 Card 정보
    inputgrouptext --> 선택 kg, CBM 등 단위 표시
/>


SEND 시 필수 및 길이 validtiaon 체크
validation.fncValidation();
validation.fncFeedIdInvalidMaxLength('booking');

SAVE 시 길이만 validation 체크
validation.fncValidationMaxLength(); 
validation.fncFeedIdInvalidMaxLength('booking');

 */
    const {
        required,
        validtype,
        maxLength,
        feedid,
        inputgrouptext,
        bsSize,
        disabled,
        minLength,
        decimallength,
        value,
        dupcheck,
        fncvalid
    } = props;
    
    const [pasteCheck, setPasteCheck] = useState(false);
    const [AlertMsg, setAlertMsg] = useState("");
    useEffect(()=>{
        if( pasteCheck ) {
            setTimeout(function(){
                setPasteCheck(false);
            }, 2000)
        }
    },[pasteCheck])
    /**
     * 필수
     * value, maxLength 값은 입력이 필요함.
     * required = true:false 필수여부
     * validtype = text(일반텍스트) email(이메일) tel(전화번호)
     */
    let msgLength = "";
    if( maxLength ) {
        msgLength = " ("+maxLength+"/"+ validation.getByteB(value)+")";
    } else {
        msgLength = "";
    }
    const fncValidation =()=> {
        if( value.trim() ) {
            return fncValidTypeCheck(value);
        } else {
            if( required ) {
                return true;
            }else{
                return false;
            }
        }
    }
    // const fncValidation =()=> {
    //     // 1. 필수 부터 확인
    //     if( required ) {// 1.1 필수 인 경우
    //         if( value.trim() ) {
    //             fncValidTypeCheck(value);
    //         } else {
    //             return true;
    //         }
    //     } else {        // 1.1 필수가 아닌 경우
    //         if( value.trim() ) {
    //             fncValidTypeCheck(value);
    //         } else {
    //             // 필수가 아닌 경우 false
    //             return false;
    //         }
    //     }
    // }

   
    //모두 통과는 false;
    const fncValidTypeCheck=(value)=>{
        // 2. Check validtype
       
        // console.log(props.fncvalid (value)); // 형식에 맞는 경우 true 리턴
            if( props.fncvalid(value) && validation.EDICharsetCheck(value)) { 
                if ( minLength &&validation.validMinLength(value, minLength)) {
                        return true;
                }else {
                    if( maxLength ) {
                        return validation.validMaxLength(value, maxLength);
                    } else {
                        return false;
                    }
                }
            }else {
                return true;
            }
    
        // if( 'text' === validtype ) {
        //     if( validation.koreanBan(value) ) {
        //         if(validation.EDICharsetCheck(value)) { 
        //             if ( minLength ) {
        //                 if(validation.validMinLength(value, minLength)) {
        //                     return true;
        //                 }else {
        //                     if( maxLength ) {
        //                         return validation.validMaxLength(value, maxLength);
        //                     } else {
        //                         return false;
        //                     }
        //                 }
        //             }else {
        //                 if( maxLength ) {
        //                     return validation.validMaxLength(value, maxLength);
        //                 } else {
        //                     return false;
        //                 }
        //             }
        //         }else {
        //             return true;
        //         }
        //     } else {
        //         return true;
        //     }
        // }
    }

    const fncPasteCheck =(e)=>{
        let cpData = e.clipboardData.getData('Text');
        if( cpData ) {
            if( validation.validMaxLength(cpData, maxLength) ) {
                // console.log("선넘네... ", cpData.length, maxLength);
                setAlertMsg('복사값이 커서 붙여넣기가 정상적으로 이루어 지지 않았습니다.')
                setPasteCheck(true)
            } else {
                // console.log("pass... ", cpData.length, maxLength)
                // setPasteCheck(false)
            }
            // setPasteCheck(false)
        }
    }

    return (
        <>
        {inputgrouptext?
            <InputGroup>
                <Input
                    // {...props}
                    value={value}
                    invalid={fncValidation()||dupcheck}
                    disabled={disabled?disabled:false}
                    onPaste={(e)=>{fncPasteCheck(e)}}
                    placeholder={props.id==="c_cons_country_code"||props.id==="c_noti_country_code"||props.id==="c_shp_country_code"?"국가코드 입력(예:KR/CN)":
                                props.id==="cargo_hs_code"? "모를 경우 임의 작성 ex) 000000":""}
                    
                               
                    type={props.type}
                    name={props.name}
                    id={props.id}
                    maxLength={props.maxLength}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    required={props.required}
                    feedid={props.feedid}
                    inputgrouptext={props.inputgrouptext}
                >
                </Input>
                <InputGroupAddon addonType="append"> 
                    <InputGroupText className={bsSize==='sm'?"pb-1":""}>{inputgrouptext}</InputGroupText> 
                </InputGroupAddon>






                {fncValidation()?(
                <FormFeedback
                    feedid={feedid}
                >{!validation.EDICharsetCheck(value)
                    ? validation.EDICheckText(value)
                    : `${required? validation.REQ_MSG :'' }${
                        validation.validMinLength(value, minLength)
                        ?validation.MIN_ERROR_MSG(minLength):
                        // 'text' === validtype ?  validation.LEN_MSG+msgLength:
                        // 'email' === validtype ? validation.EMAIL_MSG+msgLength :
                        // 'tel' === validtype ? validation.TEL_MSG+msgLength :
                        // 'number' === validtype ? validation.NUM_MSG+msgLength :
                        // 'engNumber' === validtype ? validation.ENG_NUM_MSG+msgLength :
                        // 'english' === validtype ? validation.ENG_MSG+msgLength :
                        // 'eng' === validtype ? validation.ENG_MSG+msgLength :
                        // 'koreanban' === validtype ? validation.KOREAN_BAN_MSG+msgLength :
                        // 'ccamban' === validtype ? validation.CCAM_BAN_MSG+msgLength :
                        
                        // 'yyyymmdd' === validtype ? validation.YYYYMMDD_MSG : 
                        // 'yyyymmddhhmm' === validtype ? validation.YYYYMMDDHHMM_MSG :
                        // 'yyyymmdd2' === validtype ? validation.YYYYMMDD2_MSG : 
                        // 'yyyymmddhhmm2' === validtype ? validation.YYYYMMDDHHMM2_MSG :
                        'decimal' === validtype ? `${validation.DECIMAL_FRONT} ${decimallength?decimallength:3} ${validation.DECIMAL_END}` 
                        :props.validmsg? `${props.validmsg} ${props.validLength?msgLength:''}`
                        :''}`
                }</FormFeedback>)




                :(<FormFeedback>{dupcheck?'중복 입력이 불가합니다.':''}</FormFeedback>)}
            </InputGroup>
        :
            <>
                <Input
                    // {...props}
                    // onChange={(value) => {onTextChange(value)}}
                    value={value}
                    invalid={fncValidation()||dupcheck}
                    disabled={disabled?disabled:false}
                    onPaste={(e)=>{fncPasteCheck(e)}}
                    placeholder={props.id==="c_cons_country_code"||props.id==="c_noti_country_code"||props.id==="c_shp_country_code"?"국가코드 입력(예:KR/CN)":
                    props.id==="cargo_hs_code"? "모를 경우 임의 작성     ex) 000000":""}
                    
                    type={props.type}
                    name={props.name}
                    id={props.id}
                    maxLength={props.maxLength}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    required={props.required}
                    feedid={props.feedid}
                    inputgrouptext={props.inputgrouptext}
                    >
                </Input>
                {fncValidation()?(
                <FormFeedback
                    feedid={feedid}
                >{!validation.EDICharsetCheck(value)
                    ? validation.EDICheckText(value)
                    : `${required? validation.REQ_MSG :'' }${
                        validation.validMinLength(value, minLength)
                        ?validation.MIN_ERROR_MSG(minLength):
                        'text' === validtype ?  validation.LEN_MSG+msgLength:
                        'email' === validtype ? validation.EMAIL_MSG+msgLength :
                        'tel' === validtype ? validation.TEL_MSG+msgLength :
                        'number' === validtype ? validation.NUM_MSG+msgLength :
                        'engNumber' === validtype ? validation.ENG_NUM_MSG+msgLength :
                        'english' === validtype ? validation.ENG_MSG+msgLength :
                        'eng' === validtype ? validation.ENG_MSG+msgLength :
                        'koreanban' === validtype ? validation.KOREAN_BAN_MSG+msgLength :
                        'ccamban' === validtype ? validation.CCAM_BAN_MSG+msgLength :
                        
                        'yyyymmdd' === validtype ? validation.YYYYMMDD_MSG : 
                        'yyyymmddhhmm' === validtype ? validation.YYYYMMDDHHMM_MSG :
                        'yyyymmdd2' === validtype ? validation.YYYYMMDD2_MSG : 
                        'yyyymmddhhmm2' === validtype ? validation.YYYYMMDDHHMM2_MSG :
                        
                        'decimal' === validtype ? `${validation.DECIMAL_FRONT} ${decimallength?decimallength:3} ${validation.DECIMAL_END}` 
                        :props.validMsg? `${props.validMsg} ${props.validLength?msgLength:''}`
                        :''}`
                } </FormFeedback>)
                :(<FormFeedback>{dupcheck?'중복 입력이 불가합니다.':''}</FormFeedback>)}           
            </>}
            <Alert isOpen={pasteCheck} color="danger" fade={true}>{AlertMsg}</Alert>
        </>
    )
}