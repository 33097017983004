/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================
c
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

import HomeLayout from 'layouts/HomeLayout';
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import ServiceLayout from "layouts/Service.js";
import WebLinkLayout from "layouts/WebLinkLayout.js";


import HomeLayout_old from "layouts/HomeLayout_old.js";

import ReportLayout from "layouts/ReportLayout.js";
import CertifyLayout from 'layouts/CertifyLayout.js';
import ReturnLoginLayout from 'layouts/ReturnLoginLayout.js';
// import "assets/css/bootstrap.min.css";
const hist = createBrowserHistory();

const NoMatch = (arg) => {
  console.log(arg);
  return (
  <h3>Not Found Page</h3>
  );
}

const Robots = (arg) => {
  return (
    <>
      User-agent: *
      Disallow: /
    </>
  );
}


// const Portal = props =>{
//   const portalRoot = document.querySelector('#root-portal')
//   return ReactDOM.createPortal(<HomeLayout {...props}/>,portalRoot)
// }


ReactDOM.render(
  // <Provider>
  // <Provider rootStore={rootStore}>
 // <Provider UserStore={useStore}>
 <CookiesProvider>
    <Router history={hist}>
      <Switch>
        <Route path="/" exact   component={HomeLayout} /> 
        <Route path="/home"     component={HomeLayout} />
        <Route path="/newhome"  component={HomeLayout} />
        <Route path="/main.do"  component={HomeLayout} />

        <Route path="/svc"      component={ServiceLayout} />
        <Route path="/admin"    component={AdminLayout} />
        <Route path="/authpage" component={AuthLayout} />
        <Route path="/webLinkSvc" component={WebLinkLayout} />

        <Route path="/return_certify" component={CertifyLayout} /> {/*본인인증관련*/}
        <Route path="/returnClose" exact component={ReturnLoginLayout}/> {/*팝업부모장닫기*/}
        {/* <Route path="/home_old" component={HomeLayout_old} /> */}
        {/* <Route path="/temp" render={()=><HomeLayout/>}/> */}
        <Route path="/reportViewer" render={(props) => <ReportLayout {...props} />}/>
        <Route path="/blgate/sch/terminalSchedule.do"     exact component={ServiceLayout}/> {/* 터미널스케줄 */}
        <Route path="/blgate/trk/OksFmsEtcCustomsAPI.do"  exact component={ServiceLayout}/> {/* 수출이행내역 */}
        <Route path="/blgate/trk/ImpCustomsPassInfo.do"   exact component={ServiceLayout}/> {/* 수입화물진행정보 */}
        {/* <Route path="/temp" render={()=><Portal/>}/> */}
        <Route path="/robots.txt" component={Robots} />
        <Route component={NoMatch} />
      </Switch>
    </Router>
    </CookiesProvider>,
   // </Provider>,
  document.getElementById("root")
);
