import React, { useState, useEffect, forwardRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,Col, CardBody, Collapse,
    ButtonGroup, Button,FormGroup,Label,Input, Card, UncontrolledTooltip,CustomInput,FormText} from "reactstrap";
import Container from "./Container.js";
import ContainerBookmark from "./ContainerBookmark.js";
import axios from 'axios';
import { ExcelRenderer } from "react-excel-renderer";
import * as validation from 'components/common/validation.js';
import Select from "react-select";
const ContainerCard = forwardRef((props,cntrFocus) => {
  	const {bookmark,loadData,openWindow,packCodeList} = props;
	const [coll, setColl] = useState(false);
	const [open, setOpen] = useState(false);
	const [cntrSztp,setCntrSztp] = useState([]);
	const [bkgNo,setBkgNo] = useState("");
	const [bkglist,setBkglist] = useState([]);
	// const [packCodeList,setPackCodeList] = useState([]);
	const [initToggle,setInitToggle] = useState(false);
	const [bookmarkView, setBookmarkView] = useState(false);
	const [bookmarkData, setBookmarkData] = useState([]);
	const [selectVal,setSelectVal] =useState({'value':'','label':''});
	const [allCheck,setAllCheck] = useState(true);
	const [fileUploadYn,setFileUploadYn] = useState(null);
	const [fileUploadMsg,setFileUploadMsg] = useState("");
	const [bookmarkSelectMsg, setBookmarkSelectMsg] = useState("");
	const [cntrList, setCntrList] = useState([]);
	const [cntrNolist,setCntrNolist] = useState([]);
	const [cntrSingleList, setCntrSingleList] = useState([]); 
	useEffect(()=>{
		// console.log(loadData);
		let a=loadData.bkglist?loadData.bkglist:{};
		let b=a[0]?a[0]:{};
		if(Object.keys(a).length<2 && ((b.value?b.value:'')===(loadData.res_bkg_no?loadData.res_bkg_no:''))){
			if(loadData.cntrlist && loadData.cntrlist.length>0) {
				var list =loadData.cntrlist;
				if(list) {
					list.forEach((data,key)=>{list[key] = {...data,'cntr_res_bkg_no':loadData.res_bkg_no?loadData.res_bkg_no:'','cntr_yn':data.cntr_yn?data.cntr_yn:allCheck?'Y':'N',sr_date:loadData.sr_date,sr_no:loadData.sr_no,cntr_seq:key+1,special_seq:1};  })
				}
				setCntrList(list);
			} else {
				setCntrList([{'cntr_res_bkg_no':loadData.res_bkg_no?loadData.res_bkg_no:'','cntr_yn':allCheck?'Y':'N',sr_date:loadData.sr_date,sr_no:loadData.sr_no,cntr_seq:cntrList.length,special_seq:1}]);
			}
		}
	},[loadData]);

	useEffect(() => {
		// if(props.userData) {
		// 	codePackage();
		// }
		if(loadData.cntrlist && loadData.cntrlist.length>0) {
			var list =loadData.cntrlist;
			if(list) {
				list.forEach((data,key)=>{list[key] = {...data,'cntr_yn':data.cntr_yn?data.cntr_yn:allCheck?'Y':'N',sr_date:loadData.sr_date,sr_no:loadData.sr_no,cntr_seq:key+1,special_seq:1};  })
			}
			setCntrList(list);
		} else {
			setCntrList([{'cntr_res_bkg_no':loadData.res_bkg_no?loadData.res_bkg_no:'','cntr_yn':allCheck?'Y':'N',sr_date:loadData.sr_date,sr_no:loadData.sr_no,cntr_seq:cntrList.length,special_seq:1}]);
		}
		
		if(loadData.res_bkg_no) {
			setBkgNo(loadData.res_bkg_no);
		} else {
			setBkgNo("");
		}
	},[loadData.cntrlist]);
	
	useEffect(() => {
			
		if(loadData.res_bkg_no) {
			setBkgNo(loadData.res_bkg_no);
		} else {
			setBkgNo("");
		}
		
	},[loadData.res_bkg_no]);
	useEffect(() => {
		if(loadData.bkglist) {
			setBkglist(loadData.bkglist);
		} else {
			setBkglist(loadData.res_bkg_no?loadData.res_bkg_no:'');
		}
		
	},[loadData.bkglist]);
	useEffect(() => {
		setColl(openWindow);
	},[openWindow]);

	// useEffect(() => {
	// 	if(props.userData) {
	// 	    cntrTypeSize();
	// 	}
	// },[props.userData]);
	useEffect(()=> {
		if(props.userData&&loadData.line_code) {
		    cntrTypeSize();
			// codePackage();
		}
	},[loadData.line_code]);

	const cntrTypeSize = ()=> {
		axios.post("/shipper/selectLineCodeCntrSztp",{params:{line_code:loadData.line_code}}).then(res => {
			setCntrSztp(res.data);
		});
	}
	  
  	const toggle = () => {
		props.onLoadData("ct");
		setBookmarkView(true);
		if(!open) {
			setOpen(!open);
		}
	}

  	const onInitData = () => {
		setInitToggle(!initToggle)
		setCntrSingleList([]);
	}
  

  	// const codePackage =()=> {
	// 	axios.post("/shipper/selectLineCodeCargoPackType",{params:{line_code:loadData.line_code}}).then(res => {
	// 		setPackCodeList(res.data)
	// 	});
	// }

  	// bookmark detail select
	const getCntrBookmark = (seq,gubun)=> {

	  	axios.post("/shipper/getUserCntrBookmark",{user_no:props.userData?props.userData.user_no:'',data:loadData,seq:seq}).then(res => {

			let list;

	  	    if(gubun === "P"){
	  	    	list = cntrSingleList;
	
	  	    } else {
	  	    	list = cntrList;
	  	    	
	  	    }
	  		
	  		let listMerge=list;
	  		if(res.data) {
	  			list.forEach((data,key)=>{
                    if(data.cntr_yn === "Y") {
						listMerge[key] = res.data[0].cntr_code?{...list[key],'cntr_code':res.data[0].cntr_code}:{...list[key],'cntr_code':list[key].cntr_code};
						listMerge[key] = res.data[0].cntr_seal?{...list[key],'cntr_seal':res.data[0].cntr_seal}:{...list[key],'cntr_seal':list[key].cntr_seal};
						listMerge[key] = res.data[0].cntr_consolidated_yn?{...list[key],'cntr_consolidated_yn':res.data[0].cntr_consolidated_yn}:{...list[key],'cntr_consolidated_yn':list[key].cntr_consolidated_yn};
						listMerge[key] = res.data[0].cntr_res_bkg_no?{...list[key],'cntr_res_bkg_no':res.data[0].cntr_res_bkg_no}:{...list[key],'cntr_res_bkg_no':list[key].cntr_res_bkg_no};
						listMerge[key] = res.data[0].cntr_truck_no?{...list[key],'cntr_truck_no':res.data[0].cntr_truck_no}:{...list[key],'cntr_truck_no':list[key].cntr_truck_no};
						listMerge[key] = res.data[0].cntr_total_volume?{...list[key],'cntr_total_volume':res.data[0].cntr_total_volume}:{...list[key],'cntr_total_volume':list[key].cntr_total_volume};
						listMerge[key] = res.data[0].cntr_total_weight?{...list[key],'cntr_total_weight':res.data[0].cntr_total_weight}:{...list[key],'cntr_total_weight':list[key].cntr_total_weight};
						listMerge[key] = res.data[0].cntr_weight?{...list[key],'cntr_weight':res.data[0].cntr_weight}:{...list[key],'cntr_weight':list[key].cntr_weight};
						listMerge[key] = res.data[0].cntr_auth_user_name?{...list[key],'cntr_auth_user_name':res.data[0].cntr_auth_user_name}:{...list[key],'cntr_auth_user_name':list[key].cntr_auth_user_name};
						listMerge[key] = res.data[0].cntr_carton_qty?{...list[key],'cntr_carton_qty':res.data[0].cntr_carton_qty}:{...list[key],'cntr_carton_qty':list[key].cntr_carton_qty};
						listMerge[key] = res.data[0].cntr_carton_code?{...list[key],'cntr_carton_code':res.data[0].cntr_carton_code}:{...list[key],'cntr_carton_code':list[key].cntr_carton_code};
						listMerge[key] = res.data[0].cntr_verifying_type?{...list[key],'cntr_verifying_type':res.data[0].cntr_verifying_type}:{...list[key],'cntr_verifying_type':list[key].cntr_verifying_type};
						listMerge[key] = {...list[key],'container_bookmark_seq':res.data[0].container_bookmark_seq}
                    }
	  			});
				if(gubun === "window"){
	  				setCntrSingleList([]);
		  			setCntrSingleList(listMerge);
	 	  	    } else {
	 	  	    	props.mergeData({'cntrlist':listMerge});
	 	  	    }
	  		}
	  	}).catch(err => {
	 	  	if(err.response.status === 403) {
				if(props.isAuth) {props.logOut();}
				props.onAlert("error",validation.NOTLOGIN_MSG+"[code:"+err.response.status+"]");   
			}
	    });
	  
  	}
  
  // bookmark insert
	const onSaveBookmark =() =>{
		if(bookmarkData.container_bookmark_name) {
			axios.post("/shipper/setUserCntrBookmark",{user_no:props.userData?props.userData.user_no:'',data:bookmarkData}).then(res => {
				props.onLoadData("ct");
				if(bookmarkData.container_bookmark_seq) {
					props.onAlert("success","작성한 BOOKMARK가 수정 되었습니다."); 
				} else {
					props.onAlert("success","작성한 BOOKMARK가 저장 되었습니다.");
				}	
			});
		}
	}
  //main View
	const onCntrList =(key,data) => {
		let list = cntrList;
		list[key] = data;
		
		list.map((obj, index, array)=>{
			// for (let i=0;i<array.length;i++){
				const reverseArry =[...array].reverse();
				// console.log(index,obj.cntr_no,array.findIndex(i=>i.cntr_no==obj.cntr_no),reverseArry.findIndex(i=>i.cntr_no==obj.cntr_no ),(array.length-1-reverseArry.findIndex(i=>i.cntr_no==obj.cntr_no)))
				if(array.findIndex(i=>i.cntr_no==obj.cntr_no)==(array.length-1-reverseArry.findIndex(i=>i.cntr_no==obj.cntr_no))){
					// console.log('unique')
					obj.cntr_no_dup=false
				}else{
					// console.log('dup' )
						obj.cntr_no_dup=true;
				}
			// }
		})
		// list.map((obj, index, array)=>{
		// 	for (let i=0;i<array.length;i++){
		// 		if(i!=index){
		// 			console.log(i, array[i].cntr_no,index,obj.cntr_no )
		// 			if( array[i].cntr_no==obj.cntr_no){
		// 				console.log('true' )
		// 				obj.cntr_no_dup=true;
		// 			}else {
		// 				console.log('false' )
		// 				obj.cntr_no_dup=false
		// 			}
		// 		}
		// 	}
		// })
		
		if(data.cntr_yn ==='Y') {
			setBookmarkSelectMsg("");
		}
		props.mergeData({'cntrlist':list});
		
	}
	
	const onAddCntr =()=> {
		//console.log(cntrList);
		setCntrList([...cntrList,{'cntr_res_bkg_no':'','cntr_yn':allCheck?'Y':'N',sr_date:loadData.sr_date,sr_no:loadData.sr_no,cntr_seq:cntrList.length+1,special_seq:1}]);
	}
  
	const onDeleteRow =(key,data) => {
		if(cntrList.length > 1) {
			let list = cntrList;
			if(key > -1) {list.splice(key,1);} else {console.log(">>>>",key);} 
			setCntrList([...list]);
			props.mergeData({'cntrlist':list});
		} else {
			let list ={'cntr_seq':1,'cntr_no':'','res_bkg_no':'','cntr_code':'','cntr_truck_no':'','cntr_consolidated_yn':'','cntr_seal':'','cntr_total_weight':'','cntr_total_volume':'','cntr_res_bkg_no':bkgNo,'cntr_auth_user_name':'','cntr_weight':'','cntr_yn':'Y'};
			setCntrList([list]);
			props.mergeData({'cntrlist':list});
		}
	}

	const onFileupload = (event,name) => {
		let fileObj = event.target.files[0];
		setFileUploadYn(fileObj);
			
		if (!fileObj) {
			setFileUploadYn(null);
			setFileUploadMsg(null);
			props.onAlert("error","No file uploaded");
			return false; 
		}
		
		if (!(fileObj.type === "application/vnd.ms-excel" || fileObj.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ) ) { 
			props.onAlert("error","nknown file format. Only Excel files are uploaded!");
			return false; 
		}
		ExcelRenderer(fileObj, (err, resp) => {

			let newRows = [];
			let ErrRows = [];
				
			if (err) { 
				props.onAlert(err);
			} else {
				if(resp.rows.slice(2).length > 0 && resp.rows.slice(2).length <= 999) {
					resp.rows.slice(2).forEach((data,key)=> {
						if(data[0]) {
							
							var seq = 1;
							var cntrNo = data[0]?String(data[0]).toUpperCase():'';
							var cntr_seal = data[1]?String(data[1]).toUpperCase():'';
							var cntr_code = data[2]?String(data[2]).toUpperCase():'';
							var cntr_weight = data[3]?String(data[3]):'';
							var cntr_total_volume = data[4]?String(data[4]):'';
							var cntr_carton_code = data[5]?String(data[5]).toUpperCase():'';
							var cntr_carton_qty = data[6]?String(data[6]):'';
							var cntr_total_weight = data[7]?String(data[7]):'';
							var cntr_verifying_type = data[8]?String(data[8]).toUpperCase():'';
							var cntr_res_bkg_no = data[9]?String(data[9]):'';
							var cntr_auth_user_name = data[10]?String(data[10]):'';
							
							newRows.push({
								cntr_seq:seq,
								cntr_no:cntrNo,
								cntr_seal:cntr_seal,
								cntr_code:cntr_code,
								cntr_weight:cntr_weight,
								cntr_total_weight:cntr_total_weight,
								cntr_total_volume:cntr_total_volume,
								cntr_carton_code:cntr_carton_code,
								cntr_carton_qty:cntr_carton_qty,
								cntr_verifying_type:cntr_verifying_type,
								cntr_res_bkg_no:cntr_res_bkg_no,
								cntr_auth_user_name:cntr_auth_user_name,
								cntr_yn:'Y'});
							seq ++;
						} else { 
							var seq = 1;
							ErrRows.push({'cntr_seq':seq,'cntr_no':data[0]});
							seq ++;
						}
					});
					
					if(name ==='MAIN') {
						setCntrList(newRows);
						setColl(true);
						//props.setWindow(true);
					} else {
						setCntrSingleList(newRows);
					} 
					props.mergeData({'cntrlist':newRows});
					// var msg = resp.rows.slice(2).length+" 건 데이터가 업로드 되었습니다("+newRows.length+"건 성공/"+ErrRows.length+"건 에러)";
					setFileUploadMsg("["+newRows.length+"]건 업로드 완료 Upload Success! ");
					props.onAlert("success",newRows.length+"건 업로드 완료");
		
				} else { 
					setFileUploadMsg(null);
					props.onAlert("error","Row Data 가 존재 하지 않거나 갯수(999) 를 초과 하였습니다. ");
				}
			}

		});

	};
	const onBookMarkData = (data) => {
		setBookmarkData(data);
	}
  // Cancle window	  
	const onCancelWindow =()=>{
		setSelectVal({'value':'','label':''});
		if(!bookmarkView){
			setCntrSingleList(cntrList);
		}
		setOpen(!open);  
	}
	const onBookCntrDelete = (data) => {
		if(bookmarkData && bookmarkData.container_bookmark_seq) {
			axios.post("/shipper/setUserCntrBookmarkDel",{user_no:props.userData?props.userData.user_no:'',data:bookmarkData}).then(res => {
				setBookmarkData({container_bookmark_seq:''});
				props.onLoadData("ct");
				props.onAlert("success","선택한 BOOKMARK 가 삭제 되었습니다.");
			}).catch(err => {
				if(err.response.status === 403) {
					if(props.isAuth) {props.logOut();}
					props.onAlert("error",validation.NOTLOGIN_MSG+"[code:"+err.response.status+"]");
				}
			});
		} else {
			props.onAlert("error","삭제 할 BOOKMARK를 선택해주세요.");
		}
	}
  //메인 북마크 선택
	const onChangeMainCntr = (value) =>{
		if(value) {
			setSelectVal({'value':value.value,'label':value.label});
			// 선택한 값이 존재
			if(value.value) {
				//전체 체크 
				if(!cntrChecked(cntrList)) {
					setSelectVal({'value':'','label':''});
					setBookmarkSelectMsg("북마크 설정할 대상을 선택해주세요.");
				} else {
					getCntrBookmark(value.value,'M');
				}
			} else {
				setSelectVal({'value':'','label':''});
				setBookmarkSelectMsg("북마크 설정할 대상을 선택해주세요.");
			}
		}else {
			setSelectVal({'value':'','label':''});

			let list = cntrList;
			
			let listMerge=list;
			list.forEach((data,key)=>{
				if(data.cntr_yn === "Y") {
					listMerge[key] = {...list[key],'cntr_code':null};
					listMerge[key] = {...list[key],'cntr_seal':null};
					listMerge[key] = {...list[key],'cntr_consolidated_yn':null};
					listMerge[key] = {...list[key],'cntr_res_bkg_no':null};
					listMerge[key] = {...list[key],'cntr_truck_no':null};
					listMerge[key] = {...list[key],'cntr_total_volume':null};
					listMerge[key] = {...list[key],'cntr_total_weight':null};
					listMerge[key] = {...list[key],'cntr_weight':null};
					listMerge[key] = {...list[key],'cntr_auth_user_name':null};
					listMerge[key] = {...list[key],'cntr_carton_qty':null};
					listMerge[key] = {...list[key],'cntr_carton_code':null};
					listMerge[key] = {...list[key],'cntr_verifying_type':null};
					listMerge[key] = {...list[key],'container_bookmark_seq':null};
				}
			});
			setCntrSingleList([]);
			setCntrSingleList(listMerge);
			props.mergeData({'cntrlist':listMerge});
		}
	}
	const cntrChecked = (data) => { 
		let list = data?data:[];
		let validation = "Y";
		list.forEach((data) =>{ 
			if(data.cntr_yn && data.cntr_yn === "Y") {
				validation = "N";
			}
		});

		if(validation === "Y") {
			return false;
		} else {
			return true;
		}
		
	}
  
	const onContainerCheck =()=> {
		let list = cntrList;
		setBookmarkSelectMsg("");
		var vVal = 'N';
		if(allCheck) {
			vVal = 'N';
		} else {
			vVal = 'Y';
		}
		cntrList.map((data,key)=>
		list[key] = {...data,'cntr_yn':vVal});
		setCntrList(list);
		setAllCheck(!allCheck);
	}
 
  
	const onDownloadHandle = () => {
		axios.post("/loc/downloadSample",{service:'C'},{responseType:'arraybuffer',headers:{'Content-Type':'application/json','Accept':'application/xlsx'}}).then(res => { 
			const url = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href=url;
			link.setAttribute('download','container_sample.xlsx');
			document.body.appendChild(link);
			link.click();
		});
		
	}
  	return (
    	<>
			<Row id="Container">
				<Col xl="12" lg="12">
					<Card  style={{zIndex:'5',border:'1px solid silver',borderRadius:'10px'}}>
						<CardBody className="pt-3 pb-0">  
							<Row className="pb-2">
								<Col xl="8" className="mt-2 mb-0 col-6" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>CONTAINER</Col>
								<Col>
									<Row>
										<Col className="col-10 pr-0" style={{zIndex:'500'}}>
											<Select
												className="react-select react-select-primary"
												name="cntrbookmark"
												value={selectVal}
												onChange={(value)=>onChangeMainCntr(value)}
												options={bookmark}
												placeholder="선택"
												ref={cntrFocus}
												isClearable={selectVal.value?true:false}
												/>
										</Col>
										<Col className="col-1 pl-auto pr-auto">
											<Button className="pl-0 pr-0" color="link" id="cargobookmark" onClick={toggle.bind(this, 'B')}><i className="fa fa-bookmark-o fa-2x" /></Button>
											<UncontrolledTooltip delay={0} target="cargobookmark">Bookmark</UncontrolledTooltip>
										</Col>
									</Row>
									{bookmarkSelectMsg &&
									<Row>
										<FormText className="text-danger pl-5">{bookmarkSelectMsg}</FormText>
									</Row>}
								</Col>
							</Row>
							<Collapse isOpen={coll}>
								<hr className="mt-0"/>
								<Row>
									<Col xl="6" className="mr-auto mb-2 col-12">
										<Row>
											<Col xl="2" className="col-3 ml-2 mr-0 pr-0">
												<FormGroup check className="mt-2">
													<Label check>
														<Input type="checkbox" checked={allCheck} onChange = {()=>onContainerCheck()}/>전체<span className="form-check-sign" />
													</Label>
												</FormGroup>
											</Col>
											<Col xl="8" className="col-8 pr-0">
												<FormGroup className="mb-0">
													<Col>
														<Row>
															<Col xl="12" className="p-0">
																<CustomInput type="file" id="fileupload" name="fileupload" onChange={(event)=>onFileupload(event,'MAIN')}/>
															</Col>
														</Row>
														<Row>
															<Col>
																<FormText>Excel File Upload (*.xlsx)&nbsp;&nbsp;&nbsp;<font color="green">{fileUploadYn?fileUploadMsg:''}</font></FormText>
															</Col>
														</Row>
													</Col>
												</FormGroup>
											</Col>
											<Col className="col-1 ml-2 pl-0">
												<Button className="btn-link p-0" color="default" onClick={onDownloadHandle}><i className="fa fa-file-excel-o fa-2x" /><span style={{fontSize:'1px'}}>SAMPLE</span></Button>
											</Col>
										</Row>
									</Col>
									<Col className="pt-0 pb-2">
										<ButtonGroup className="pull-right">
											<Button className="pt-0 pb-0" color="default" outline size="sm" onClick={onAddCntr}>추가</Button>
										</ButtonGroup>
									</Col>
								</Row>
								<Row>
									<Col>
									{(cntrList.length > 0) && cntrList.map((element, key, listArr)=>{
										return (
										<Container key={key}
											zindex={cntrList.length-key}
											codelist={cntrSztp} pack={packCodeList}
											size="sm"
											bookmarkoption={bookmark}
											checked={allCheck}
											cntrData={element}
											bkgdata={bkgNo}
											bkglist={bkglist}
											sr_date={loadData.sr_date}
											sr_no={loadData.sr_no}
											loadData={loadData}
											deleteRow={(data)=>onDeleteRow(key,data)} 
											propsData={(data)=>onCntrList(key,data)} 
											isDupCheck={listArr&&listArr[key].cntr_no_dup?listArr[key].cntr_no_dup:false}
											{...props}/>
										);
									})}
									</Col>
								</Row>
							</Collapse>
						</CardBody>
						<Col className="text-center col-12 p-0" onClick={() => setColl(!coll)}>       
							<Button
								className="p-0"
								color="link"
								id="cntrmore"
								onClick={() => setColl(!coll)}
								style={{height:'21px',marginBottom:'4px',width:'100%'}}>
								{coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
							</Button>
							<UncontrolledTooltip delay={0} target="cntrmore">{coll?'Close':'Open'}</UncontrolledTooltip>
						</Col>
					</Card>
				</Col>
			</Row>
			<Modal isOpen={open} toggle={toggle} size="xl">
				<ModalHeader toggle={toggle}>Container Info</ModalHeader>
				<ModalBody >
					<ContainerBookmark 
						bookmark = {bookmark} 
						code={cntrSztp} 
						pack={packCodeList}
						init={initToggle}
						BookmarkData={bookmarkData}
						propsData = {onBookMarkData}
						onPropsCntrbookmarkDelete={onBookCntrDelete}
						{...props}
					/>
				</ModalBody>
				
				<ModalFooter>
					<Button color="primary" onClick={onInitData}>NEW</Button>
					<Button color="primary" onClick={onSaveBookmark}>SAVE</Button>
					<Button color="primary" onClick={onBookCntrDelete}>DELETE</Button>
					<Button color="secondary" onClick={onCancelWindow}>CANCEL</Button>
				</ModalFooter>
			</Modal>
		</>
	);
});

export default ContainerCard;