import React,{ useState, useEffect, useRef } from "react";
import {Row, Col, InputGroup,Label,Input, Card, CardSubtitle, CardBody, CardFooter, Button, UncontrolledTooltip, InputGroupAddon, InputGroupText} from "reactstrap";
import Select from 'react-select'
import Moment from 'moment';
import axios from "axios";
import { Link } from "react-router-dom";
import BkgRow from './BkgRow';
import * as validation from 'muiComponents/common/validation.js';
const styles = {
    normalGird:{
        fontSize:'9',
        color:'#696969',
        fontWeight:'bold',
    }
};
export default function AdminSRLIST(props) {
    const {alertMessage} = props;
    const [open,setOpen] = useState(false);
    const [bookingNumber, setBookingNumber] = useState("");
    const [bookingList, setBookingList] = useState([])
    const element=useRef(null);
    useEffect(() => {
        if(!open) {
            document.getElementById("booking_nummber").focus();
        }
    
        return function cleanup() {
            setBookingList([]);
            setBookingNumber('');
        };
    }, [open]);



    

    const onKeyPress = (e) => {
        if(e.key==='Enter') {
            if(bookingNumber.length===0){
                setOpen(true);
            }else {
                // onSubmit();
            }
            
        }
    }
    const onFocusOut =(e) => {
        if(bookingNumber.length ===0) {
          setOpen(true);
        }
    }
    const onSubmit = () => {
        axios.post("/shipper/adminBookingList",{bkgNo:bookingNumber,lineCode:'WDFC'}).then(
            res => {
                if(res.statusText==="OK") {
                    if(res.data.length > 0) {
                        setBookingList(res.data);
                    }else {
                        setBookingList([])
                        alertMessage('조회결과가 없습니다.')
                    }
                }
        })
    }
    return (
        <Card className="card-raised card-form-horizontal no-transition mb-4">
            <CardSubtitle>
                <Col xl="12" lg="12" sm="12" md="12">
                {open?(
                    <Button style={{width:'100%'}} color="default" onClick={()=>{setOpen(false)}}>
                        <i className="fa fa-search"></i>부킹 번호
                    </Button>):(
                    <div>
                        <InputGroup>
                            <Input 
                                type="text" 
                                id="booking_nummber"
                                maxLength="35"
                                ref={element}
                                onChange={(e)=>setBookingNumber(e.target.value)}
                                value={bookingNumber}
                                onKeyPress={onKeyPress}
                                onBlur={onFocusOut}/>
                            <InputGroupAddon addonType="append">
                                <InputGroupText style={{borderLeft:'solid',padding:'0'}}>
                                    <Button size="sm" color='link' onClick={()=> onSubmit()}>
                                        <i className="fa fa-search" />
                                    </Button>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </div>)}
                </Col>
            </CardSubtitle>
            <CardBody>
                <Col xl="12" lg="12" sm="12" md="12">
                <>
                    <Row className="bg-light pb-2 pt-2">
                        <Col className="text-center pt-3 border-right" xl="1" lg="1" md="1" sm="1" xs="1" style={styles.normalGird}>순번</Col>
                        <Col className="text-center pt-3 border-right" xl="4" lg="4" md="4" sm="4" xs="4" style={styles.normalGird}>유저 ID</Col>
                        <Col className="text-center pt-3 border-right" xl="4" lg="4" md="4" sm="4" xs="4" style={styles.normalGird}>부킹 일자</Col>
                        <Col className="text-center pt-3 border-right" xl="3" lg="3" md="3" sm="3" xs="3" style={styles.normalGird}>부킹 번호</Col>
                    </Row>
                    {bookingList.length > 0 && (
                    <>
                        {bookingList.map((value,index) => {
                            return(
                                <BkgRow
                                    index={index} 
                                    key={index} 
                                    value={value}/>
                            )
                        })}
                    </>)}
                    </>
                </Col>
          </CardBody>
        </Card>
    )

}