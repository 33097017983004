import React,{useEffect,useState} from "react";
import {
   Row, Col, Container,Card, CardBody, Button
  } from "reactstrap";
import axios from "axios";
import {Link} from "react-router-dom";
import NoticeDetail from "components/Notice/NoticeDetail.js"
// reactstrap components

// core components
// import { Container } from "reactstrap";
export default function NoticeMain(props) {
const [noticePopupArray, setNoticePopupArray] = useState([]);
const {className} = props;

useEffect(()=> {
    getMainNotice('plismplus');  
},[])

const getMainNotice = (service) => {
  axios.post("/api/notice",{service:service, count:4, url:'main'}).then(res=> {
      if(res.statusText ==="OK") {
          if(res.data.length > 0) {
              let array=[];
              for(var i=0; i < res.data.length; i++) {
                  if(res.data[i].pop_yn === 'Y' && !handleStorage.getStorage(res.data[i].board_id)){
                      array.push(res.data[i]);
                  }
              }
              if(array&&array.length>0){
                  console.log(array);
                  setNoticePopupArray(array);
              }
          }
      }
  })
}
/* 스토리지 제어 함수 정의 */
var handleStorage = {
  // 스토리지 읽어오기
  getStorage: function (name) {
  var now = new Date();
  now = now.setTime(now.getTime());
  // 현재 시각과 스토리지에 저장된 시각을 각각 비교하여
  // 시간이 남아 있으면 true, 아니면 false 리턴
  return parseInt(localStorage.getItem(name)) > now
  }
};

  return (
    <>
      <Container>
      {noticePopupArray && noticePopupArray.length>0 &&
        noticePopupArray.map((data,index) =>
        <NoticeDetail className={className} noticeIndex={index} noticeData={data}/>
      )}
      </Container>
    </>
  );
}


