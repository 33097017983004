/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Label,Input, FormFeedback} from "reactstrap";
import * as validation from 'components/common/validation.js';
import Moment from 'moment';

import BookingBookmark from '../bookmark/BookingBookmark.js';

export default function BookingTitleCard(props){
    
    // 중요내용 부모/자식 공유를 위한 state
    const [booking, setBooking] = useState({});
    
    // 부모로부터 파라미터 전달 받음
    useEffect(()=>{
        if( props.booking ) {
            let bkg_no = props.booking.bkg_no;
            let bkg_date = props.booking.bkg_date;
            let status_cus = props.booking.status_cus;
            let status_cus_kr = props.booking.status_cus_kr;
            let sc_no = props.booking.sc_no;
            let user_no = props.booking.user_no;
            // console.log("status_cus",status_cus)
            setBooking({...booking, 'bkg_no':bkg_no, 'bkg_date':bkg_date
                , 'status_cus':status_cus, 'sc_no':sc_no, 'user_no':user_no
                ,'status_cus_kr':status_cus_kr});

        }
    },[props.booking]);


    // 입력값 useState 입력
    const fncOnChange = (e, key) => {
        setBooking({...booking, [key]:(e.target.value.toUpperCase())||null});
        e.preventDefault();
    }

    const fncBookmarkParent =(e)=>{
        props.fncBookmarkParent(e);
    }

  return (
    <>
    <Row>
        <Col xl="2" lg="2" md="12">
            <FormGroup>
                <Label className="mb-0">Req Bkg No</Label>
                <Input type="text" name="bkg_no" id="bkg_no"
                    maxLength="15"
                    readOnly
                    defaultValue={booking.bkg_no?booking.bkg_no:''}
                    />
                <FormFeedback>{validation.REQ_MSG}</FormFeedback>
            </FormGroup>
        </Col>  
        <Col xl="2" lg="2" md="12">
            <FormGroup>
                <Label className="mb-0">Req Bkg Date</Label>
                <Input type="text" name="sc_no" id="sc_no"
                    placeholder=""
                    maxLength="20"
                    readOnly
                    defaultValue={booking.bkg_date?Moment(booking.bkg_date).format('YYYY-MM-DD'):''}
                />
                <FormFeedback>{validation.REQ_MSG}</FormFeedback>
            </FormGroup>
        </Col>
        <Col xl="2" lg="2" md="12">
            <FormGroup>
                <Label className="mb-0">Status</Label>
                <Input type="text" //type="select" 
                    name="status_cus" id="status_cus"
                    value={booking.status_cus?booking.status_cus_kr:'-'}
                    // onChange={(e)=>fncOnChange(e, 'status_cus')}
                    readOnly>
                    {/* <option value="NO">저장</option>
                    <option value="S0">저장</option>
                    <option value="S9">전송</option>
                    <option value="S4">정정전송</option>
                    <option value="S5">정정작성</option>
                    <option value="4C">정정승인</option>
                    <option value="S1">취소전송</option>
                    <option value="RA">승인</option>
                    <option value="EJ">거절</option>
                    <option value="4D">정정거절</option>
                    <option value="EC">취소승인</option>
                    <option value="EA">승인취소</option> */}
                </Input>
                {/* <Label className="mb-0">저장</Label>
                <Input type="select" name="status_cus" id="status_cus"
                    value={booking.status_cus?booking.status_cus:''}
                    onChange={(e)=>fncOnChange(e, 'status_cus')}
                    readOnly>
                    <option value="NO">저장</option>
                    <option value="S0">저장</option>
                    <option value="S9">전송</option>
                    <option value="S4">정정전송</option>
                    <option value="S5">정정작성</option>
                    <option value="4C">정정승인</option>
                    <option value="S1">취소전송</option>
                    <option value="RA">승인</option>
                    <option value="EJ">거절</option>
                    <option value="4D">정정거절</option>
                    <option value="EC">취소승인</option>
                    <option value="EA">승인취소</option>
                </Input> */}
            </FormGroup>
        </Col>
        <Col xl="3" lg="3" md="12" className="ml-auto">
        <FormGroup>
            <Label className="mb-0"></Label>
            <BookingBookmark
                fncBookmarkParent={fncBookmarkParent}
                {...props}
                />
        </FormGroup>
        </Col>
    </Row>
    </>
    );
}