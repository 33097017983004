import React,{ useState, useEffect } from 'react';
// @material-ui/core components
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Card from "muiComponents/Card/Card.js";
import CardHeader from "muiComponents/Card/CardHeader.js";
import CardBody from "muiComponents/Card/CardBody.js";
import {Grid, Table, TableBody, TableRow, TableCell, TableHead
  , TextField, Tooltip, Switch, FormControlLabel, IconButton
  , Divider, Box, Dialog, DialogTitle, DialogContent, DialogActions
  , Typography} from '@material-ui/core';
// import MuiAlert from '@material-ui/lab/Alert';
import Button from "muiComponents/CustomButtons/Button.js";
import CardIcon from "muiComponents/Card/CardIcon.js";
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import Assignment from "@material-ui/icons/Assignment";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DoneOutline, PriorityHigh, HourglassEmpty,ErrorOutline, ZoomIn} from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

function ScrapPort( props ) {
  const {alertMessage} = props;
  const [portName, setPortName] = useState("");
  const [linePortList, setLinePortList] = useState([]);
  const [element, setElement] = useState({});
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // onScrapLineCode();
    // setPage(1);
    return () => {
    };
  },[]);


  // 페이징처리 변경됐을 경우
  useEffect(()=>{
    if(page){
      getScrapPortList();
    }
  }, [page]);



  // 포트코드 조회
  const getScrapPortList=()=>{
    if(props.userData) {
      axios.post(
        "/loc/getScrapPortList",
        {
          port_name:portName,
          page:page 
        }
      ).then(setLinePortList([]))
      .then(res => {
        console.log(res.data);
        if( res.data.length === 0 ) {
          setPage(Number(1));
          setTotalCount(0);
          alertMessage("조회 결과가 없습니다.","info");
        } else {
          setLinePortList(res.data||[]);
          setPage(Number(res.data[0].cur_page));
          setTotalCount(res.data[0].tot_page);
        }
      }).catch(err => {
        alertMessage("오류가 발생했습니다. 관리자에게 문의하세요.","error");
      });
      // console.log(linePortList);
    } else {
      props.openLogin();
    }
  }

  // 이미지 변경
  const updateIconButton=( key, status )=>{
    let newArray = [...linePortList];
    newArray[key] = {
      ...newArray[key],
      serverity: status
    };
    setLinePortList(newArray);
  }
  // LINE PORT 입력후 저장하자
  const updateScrapPort=(event,data, key)=>{
    if( data.scrp_port ) {
      if(data.scrp_port.length !=5) {
        alertMessage("포트코드는 5자리 입니다.","warning");
        return;
      }
    }else{
      alertMessage("포트코드는 5자리 입니다.","warning");
      return;
    }
    // event.stopPropagation();
    if(props.userData) {
      updateIconButton(key, 'UPDATE');
      axios.post(
        "/loc/updateScrapPort",
        {scrp_port:data.scrp_port, scrp_port_name:data.scrp_port_name, origin_scrp_port_name: data.origin_scrp_port_name, origin_scrp_port: data.origin_scrp_port})
      .then(res => {
        getScrapPortList();
      })
      .catch(err => {
        updateIconButton(key, 'ERROR');
        alertMessage("오류가 발생했습니다. 관리자에게 문의하세요.","error");
      });
      // console.log(linePortList);

    } else {
      props.openLogin();
    }
  }




  // 기존 LinePortList 정보에 새로운 정보를 입력해준다.
  const inputChagneHandler=(e, key)=>{
    if( e.target.value ) {
      let newArray = [...linePortList];
      newArray[key] = {
        ...newArray[key],
        scrp_port:e.target.value.toUpperCase(),
        serverity: 'MODIFY'
      };
      setLinePortList(newArray);
    } else {
      let newArray = [...linePortList];
      newArray[key] = {
        ...newArray[key],
        scrp_port:'',
        serverity: 'ERROR'
      };
      setLinePortList(newArray);
    }
  }


  // const AlertMessage = (message,icon) => {
  //   setErrmessage(message)
  //   setSeverity(icon)
  //   setAlertOpen(true);
  // }
  // const handleAlertClose = (event, reason) => {
  //   if(reason ==='clickaway') {
  //     return;
  //   }
  //   setAlertOpen(false);
  // }
  // function Alert(props) {
  //   return <MuiAlert elevation={6} variant="filled" {...props} />;
  // }

  const pagingHandleChange = (event, value) => {
    setPage(Number(value));
  }


    return(
        <>
        <Card>
          <CardBody>
            <Grid item xs={12} sm={9} md={12}>
              <Grid container >
                <Grid item xs={12} md={3}>
                    {/* <Autocomplete
                        options = {scrapLineCodeList}
                        getOptionLabel = { options => "["+options.customs_line_code+"] "+options.line_code}
                        id="scrap_line_code"
                        onChange={onBlurLineCode}
                        noOptionsText="Please Check Line ..."
                        renderInput={params => (
                        <TextField {...params} id="text_line" label="LINE"/>
                        )}
                    /> */}

                    <TextField id="searchPortName" label={"Port"} onChange={event => setPortName(event.target.value.toUpperCase())} value={portName} //variant="outlined" size="small" 
									fullWidth />
                </Grid>
                <Grid item xs={3} sm={3} md={3} style={{textAlignLast:'right', paddingTop:"20px"}}>
                  <Button onClick={getScrapPortList} color="info" endIcon={<SearchIcon/>} >SEARCH</Button>
                </Grid>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
        <Card>
        <CardHeader color="info" stats icon >
	        <CardIcon color="info" style={{padding:'0'}}>
	        <Assignment />
	      </CardIcon>
          <h4 style={{textAlign: "left",color:"#000000", paddingTop:"10px"}}><b>스크래핑 PORT 코드 정의</b></h4>
          {/* <span style={{textAlign: "right",color:"#000000", paddingRight:"20px", paddingTop:"20px"}}>TOTAL: {cntrCnt}</span> */}
        </CardHeader>
        <CardBody>
          <Table style={{borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7'}}>
              <TableHead>
                <TableRow>
                  <TableCell style={{width:'30%'}} align="center">PORT CODE</TableCell>
                  <TableCell style={{width:'40%'}} align="center">PORT NAME</TableCell>
                  <TableCell style={{width:'20%'}} align="center">SAVE</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  linePortList.length>0&&linePortList.map((element,key) => {
                    return(
                      <TableRow key={key}
                        hover
                        // onClick={(event)=>selectedData(event,element, key)}
                        >
                        {element.scrp_port ?
                        <TableCell align="center">
                          <TextField 
                            value={element.scrp_port}
                            onChange={(e)=>inputChagneHandler(e, key)}
                            // onBlur={(event)=>updateScrapPort(event,element, key)}
                            inputProps={{maxLength:5, minLength:5}}
                            size='small' 
                          ></TextField>
                          </TableCell>:
                          <TableCell>
                            <TextField
                              // value={element.port_code}
                              onChange={(e)=>inputChagneHandler(e, key)}
                              // onBlur={(event)=>updateScrapPort(event,element, key)}
                              inputProps={{maxLength:5, minLength:5}}
                              size='small'
                            ></TextField>
                            {/* <LightTooltip title="비슷한거조회">
                              <IconButton><ZoomIn onClick={(event)=>likeHandleChange(event,element, key)}></ZoomIn></IconButton>
                            </LightTooltip> */}
                            </TableCell>}
                        {element.scrp_port_name !== undefined?<TableCell align="center">{element.scrp_port_name}</TableCell>:<TableCell></TableCell>}
                        <TableCell align="center">
                        {element.serverity !== undefined ? 
                          element.serverity === 'MODIFY' ?
                            <LightTooltip title="수정"><IconButton><PriorityHigh color="secondary" onClick={(event)=>updateScrapPort(event,element, key)}/></IconButton></LightTooltip>
                            :element.serverity === 'UPDATE'?<HourglassEmpty/>:element.serverity === 'SUCCESS'?<DoneOutline/>
                            :element.serverity === 'ERROR'?<ErrorOutline/>:''
                          :''}
                        </TableCell>
                      </TableRow>
                    )
                    })
                }
              </TableBody>
            </Table>
            <Divider/>
              <Box component="span">
                <Pagination
                    count={totalCount}
                    page={page}
                    onChange={pagingHandleChange}
                    defaultPage={1}
                    color="primary"
                    size="large"
                    >
                </Pagination>
              </Box>
          </CardBody>
        </Card>
        </>
    )
}

export default ScrapPort;