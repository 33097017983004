/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import {Row, Col, CardBody, Collapse, UncontrolledTooltip, Button,FormGroup, Label, Card} from "reactstrap";
import InputValid from "components/CustomInput/InputValid.js";
//import $ from 'jquery';


const MemoCard = forwardRef((props, memoFocus) => {

    // modal 창을 위한 state
    const [coll, setColl] = useState(false);
    // 중요내용 부모/자식 공유를 위한 state
    const [booking, setBooking] = useState({});
   
    useEffect(()=>{
        setColl(props.openWindow);
    },[props.openWindow]);
    // 부모로부터 파라미터 전달 받음
    useEffect(()=>{
        if( props.booking.bkg_no ) {
            setBooking(props.booking);
        }
    },[props.booking]);

    // 수정된 내용은 Booking 저장
    const fncOnChange = ( e, key ) => {
        // e.preventDefault();
        let text = {...booking, [key]:(e.target.value.toUpperCase())||null};
        setBooking(text);
    }

    const fncBookingParent =()=>{
        props.fncBookingParent(booking);
    }
    return (
        <>
            <Row id="ReqMemo">
                <Col xl="12" lg="12">
                    <Card style={{zIndex:'100'}}>
                        <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>  
                            <Row className="pb-2 mb-2">
                                <Col className="mt-2 mb-0 col-12" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>REQUEST MEMO</Col>
                            </Row>
                            <Collapse isOpen={coll}>
                                <hr className="mt-0"/>
                                <Row>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">REQUEST<br/>(요청사항)</Label></Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="owner_remark"
                                                        id="owner_remark"
                                                        maxLength="140"
                                                        value={booking.owner_remark?booking.owner_remark:''}
                                                        onChange={(e)=>fncOnChange(e, 'owner_remark')}
                                                        onBlur={fncBookingParent}
                                                        required={false}
                                                        feedid="reqMemo"
                                                        validtype="text"
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>   
                                    </Col>
                                </Row>
                            </Collapse>
                            <div className="text-center" onClick={() => setColl(!coll)}>
                                <div>
                                    <Button className="p-0" color="link" id="linemore" onClick={() => setColl(!coll)} style={{height:'21px'}}>
                                        {coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="linemore">{coll?'Close':'Open'}</UncontrolledTooltip>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
})

export default MemoCard;