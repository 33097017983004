import React, { useState, useEffect , useRef } from 'react';
import { Row, Col, Button, FormGroup, Label, Input, 
    UncontrolledTooltip, Form, Container, CardBody, Card, CardHeader, Popover, PopoverHeader, PopoverBody,FormFeedback,
    Collapse, Badge} from "reactstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
// core components
// import Navbar from "muiComponents/Navbars/Navbar.js";
// import Footer from "muiComponents/Footers/Footer.js";
import {ventList} from 'components/common/options.js';
import * as validation from 'muiComponents/common/validation.js';
import InputGroup from 'reactstrap/lib/InputGroup';
import LogisviewPageHeader from 'components/Headers/LogisviewPageHeader';
export default function ConfirmIndex(props) {
    const history = useHistory();
     // const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState({});
    const [cargo, setCargo] = useState([]);
    const [cargoGoods, setCargoGoods] = useState([]);
    const [cntr, setCntr] = useState([]);
    const [cntrSpecials, setCntrSpecails] = useState([]);
    const [popoverOpen,setPopoverOpen] = useState(false);
    const [sendEmail, setSendEmail] = useState("");
    const [emailList, setEmailList] = useState([]);
    const [bkgResendYN,setBkgResendYN] = useState("");
    const [param, setParam] = useState({user_no: props.location.state && props.location.state.user_no  ?  props.location.state.user_no || '' : '' , 
                                        res_bkg_no: props.location.state && props.location.state.res_bkg_no  ?  props.location.state.res_bkg_no || '' : '' , 
                                        res_confirm_date: props.location.state && props.location.state.res_confirm_date  ?  props.location.state.res_confirm_date || '' : '' 
                                        ,line_code: props.location.state.line_code ||''
                                    });
    const [coll, setColl] = useState({others:false, schedule:false, shipper:false, carrier:false, trasport:false, cargo:false, container:false});
    const [lineCode, setLineCode] = useState(props.site?props.site.line_code:props.location.state.line_code||'');
    //report추가-220712
    const [lineViewerCode, setLineViewerCode] = useState(props.site?props.site.line_email_code:'');
    const {userData} = props;
    const focusRef = useRef(null);

    // useEffect(() => {
    //     props.fncClickMenu('NAV_CONFIRM')
    //   },[]);
    useEffect(() => {
        if(!userData) {
            props.onAlert('danger','로그인이 필요한 서비스입니다.');
            window.history.back();
        }
        if(props.site) {
            setLineCode(props.site.line_code);
        }else if(!lineCode){
            props.onAlert('danger','해당 CONFIRM 정보를 불러올 수 없습니다.');
            window.location.href='/svc/confirmList'
        }

        if ( param.user_no.length > 0 && param.res_bkg_no.length > 0 ) {
            selectShpConfirm();
        }
        return function cleanup() {

        };
    }, []);
    useEffect(()=> {
        selectShpConfirm();
        if(lineCode){
            axios.post("/api/selectEshipConfig", {lineCode: lineCode}).then( res => {
                // console.log('res', res);
                if(res.data && res.data.length > 0) {
                    setBkgResendYN(res.data[0].bkg_resend_yn);
                    setLineViewerCode(res.data[0].line_email_code);
                } 
            }).catch(err => {
                if(err.response.status) {
                    props.onAlert('danger','선사 정보를 가져오는데 실패하였습니다');
                }
            });
        }
    },[lineCode]);

    useEffect(() => {
		if(props.site) {
			setLineCode(props.site.line_code);
            setLineViewerCode(props.site.line_email_code);
		}else if(!(props.location.state&&props.location.state.line_code)||!lineCode){
			props.onAlert('danger','해당 CONFIRM 정보를 불러올 수 없습니다.');
			window.location.href='/svc/confirmList'
		}
	},[props.site]);

    useEffect(()=> {
        if(!popoverOpen) {
            setSendEmail("");
            setEmailList([]);
        }
    },[popoverOpen])
    
    const selectShpConfirm = ( ) => {
        axios.post("/shipper/selectShpConfirm", {lineCode:lineCode,res_confirm_date:param.res_confirm_date,user_no:param.user_no,res_bkg_no:param.res_bkg_no} ).then( res => {
            if(res.data && res.data.length > 0) {
                setConfirm(res.data[0]);
                selectShpConfirmCargo(res.data[0].user_no, res.data[0].res_bkg_no, res.data[0].res_confirm_date, res.data[0].line_code);
                selectShpConfirmCntr(res.data[0].user_no, res.data[0].res_bkg_no, res.data[0].res_confirm_date, res.data[0].line_code, res.data[0].sch_vessel_name);
                selectShpConfirmCntrSpecial(res.data[0].user_no, res.data[0].res_bkg_no, res.data[0].res_confirm_date);
                setColl(Object.assign(...Object.keys(coll).map(k=>({[k]:true}))));
            } else {
                props.onAlert('danger','조회된 결과가 없습니다.');
            }
        }).catch(err => {
            if(err.response.status) {
                props.onAlert('danger','오류가 발생했습니다.');
            }
        });
    }

    const selectShpConfirmCargo = ( user_no, res_bkg_no, res_confirm_date, line_code ) => {
        axios.post("/shipper/selectShpConfirmCargo", { user_no, res_bkg_no, res_confirm_date, line_code }).then( res => {
            // console.log('res.data', res.data);
            if(res.data && res.data.length > 0) {
                setCargo(res.data);
                selectShpConfirmCargoGoods(res.data[0].user_no, res.data[0].res_bkg_no, res.data[0].res_confirm_date, res.data[0].cargo_seq);
            } 
        }).catch(err => {
            if(err.response.status) {
                props.onAlert('danger','오류가 발생했습니다.');
            }
        });
    }
    const selectShpConfirmCargoGoods = ( user_no, res_bkg_no, res_confirm_date, cargo_seq ) => {
        axios.post("/shipper/selectShpConfirmCargoGoods", { user_no, res_bkg_no, res_confirm_date, cargo_seq }).then( res => {
            if(res.data && res.data.length > 0) {
                setCargoGoods(res.data);
            } 
        }).catch(err => {
            if(err.response.status) {
                props.onAlert('danger','오류가 발생했습니다.');
            }
        });
    }

  const selectShpConfirmCntr = ( user_no, res_bkg_no, res_confirm_date, line_code, sch_vessel_name ) => {
        axios.post("/shipper/selectShpConfirmCntr", { user_no, res_bkg_no, res_confirm_date, line_code, sch_vessel_name }).then( res => {
            if(res.data && res.data.length > 0) {
                setCntr(res.data);
            } 
        }).catch(err => {
            if(err.response.status) {
                props.onAlert('danger','오류가 발생했습니다.');
            }
        });
    }

    const selectShpConfirmCntrSpecial = ( user_no, res_bkg_no, res_confirm_date ) => {
        axios.post("/shipper/selectShpConfirmCntrSpecial", { user_no, res_bkg_no, res_confirm_date }).then( res => {
            if(res.data && res.data.length > 0) {
                setCntrSpecails(res.data);
            } 
        }).catch(err => {
            if(err.response.status) {
                props.onAlert('danger','오류가 발생했습니다.');
            }
        });
    }

    const onChange = ( event ) => {
        const { target: {id, value} } = event;
        if (id === 'res_bkg_no') {
            setParam(prevState => ({...prevState, res_bkg_no:value}));
        } else if (id === 'res_confirm_date') {
            setParam(prevState => ({...prevState, res_confirm_date:value}));
        }
    }

    const renderLabel = (id, source, target) => {
        if(id =='cntr_vent_open'){
            source = source? ventList.find(x=>x.value == source)?.label: null ;
            target = target? ventList.find(x=>x.value == target)?.label: null ;
        }
        if(!source) source = null;
        if(!target) target = null; //undefined와 빈값을 null로 세팅
        const style = {textDecoration:"underline", color:"blue"};
        return (
            <>
                <Label className="form-control-sm form-control mb-0"  style={source !== target ? style : {}} id={id}>{source ? source:''}</Label>
                { source !== target &&
                <UncontrolledTooltip placement="top" target={id}>{target ? target:''}</UncontrolledTooltip>}
            </>
        );
    }

    const goConfirmEditing = (res_bkg_no)=>{
        if(!(confirm.status_cus=='EA'||confirm.status_cus=='EC'||confirm.status_cus=='S5'||confirm.status_cus=='EJ')){
            axios.post("/shipper/updateModifyStatus", {user_no: userData?userData.user_no:null,bkg_no:confirm.bkg_no,res_bkg_no:confirm.res_bkg_no, status :'S5'}).then( res => {
                history.push({pathname:`/svc/booking`, state: {user_no:confirm.user_no, bkg_no:confirm.bkg_no, bkg_date:confirm.bkg_date, new_yn:'N',line_code:lineCode}})
            }).catch(err => {
                if(err.response.status) {
                    props.onAlert('danger','오류가 발생했습니다.');
                }
            });

        }else{
            props.onNotiAlert('error','정정작성이 불가한 상태입니다')
        }
    }
    
    const fncReportViewer=()=> {
        if( !(param.user_no && param.res_confirm_date && param.res_bkg_no ) ) {
            props.onNotiAlert("danger","Booking 먼저 조회 하세요.");
            return false;
        }
        if(!lineViewerCode) {
            props.onNotiAlert("danger","사이트 정보가 없습니다.");
            return false;
        }
        if(confirm.status_cus!=='4C'&&confirm.status_cus!=='RA'){
            props.onNotiAlert("danger","상태값이 승인 또는 정정승인일때만 조회가능합니다.");
            return false;
        }
        var obj = {}
        obj.user_no = param.user_no;
        obj.res_confirm_date = param.res_confirm_date;
        obj.res_bkg_no = param.res_bkg_no;
        obj.file_path =lineViewerCode;
        var json = JSON.stringify(obj);

        let form = document.reportForm;
        //form.action = 'http://localhost:5000/shipper/reportViewer';
        form.action = '/shipper/reportViewer';
        form.target = 'popReport'; // window.open() 의 두번째 변수와 동일해야.
        form.file_id.value = 'confirm';
        form.file_path.value = lineViewerCode
        form.name.value = 'FILENAME';
        form.connection.value = process.env.REACT_APP_DB=='ORACLE'?'logisvw' : 'pgsql';
        form.parameters.value = json;
        window.open('', 'popReport', 'width=1050px, height=850px');
        form.submit();
    }

    //email
    const onAddEmailList = (e) => {
        if(window.event.keyCode===13) {
            if(validation.validationEmail(sendEmail)) {
                setEmailList(prev=> [...prev,sendEmail]);
                setSendEmail("");
            }
        }
    }
    const handleRemove = (index) => {
        setEmailList(emailList.filter((value,key) => key !== index ))
    }
    const onAddEmailListButton = () => {
        if(validation.validationEmail(sendEmail)) {
            setEmailList(prev=> [...prev,sendEmail]);
            setSendEmail("");
        }
    }
    const sendConfirmMail = () => {
        if(emailList.length === 0) {
            props.onAlert("error","이메일 목록이 입력되어 있지 않습니다.");
            return;
        }
        if(!param.res_bkg_no) {
            props.onAlert("error","부킹 컨펌번호가 존재하지 않습니다.");
            return;
        }
        if(!param.res_confirm_date) {
            props.onAlert("error","부킹 컨펌일자가 존재하지 않습니다.");
            return;
        }
        axios.post('/com/sendConfirmMail',{emailList:emailList,res_bkg_no:param.res_bkg_no,confirm_date:param.res_confirm_date}).then(res=> {
            if(res.data) {
                props.onAlert("success",`${res.data.length}건의 메일이 전송되었습니다.`);
                setEmailList([]);
            }

        })
    }

    return (
        <>
            {/* <Navbar {...props}/>
            <div className="bg-white page-header page-header-xss" id="general"/> */}
            <div className="section section-white">
                <Form>
                    <Container>
                        <CardBody className="pt-2 pb-2 bg-white">
                            <LogisviewPageHeader
                            carrierInfo = {props.logoImgList.length>0
                                &&(confirm.sch_line_code
                                    ?props.logoImgList.find(x=>x.sch_line_code==confirm.sch_line_code):{})}
                            pageName= 'CONFIRM'
                            />
                        {/* <Row>
                            <Col className="ml-auto mr-auto" xl="12" lg="12" md="12" sm="12" >
                                <h1 className="mt-1 text-start" style={{
                                    background:
                                        "url(" + require("assets/img/bu_contitle_bar.gif") + ") no-repeat"
                                    }}>
                                    <small>Confirm (Booking)</small>
                                </h1>
                            </Col>
                        </Row> */}
                        <Row className="mt-0">
                            <Col className="text-left">
                                {/* <Button id="bkg_search" color="default" outline type="button" className="mr-1"
                                    onClick={(e)=>selectShpConfirm()}>SEARCH</Button>
                                <UncontrolledTooltip delay={0} target="bkg_search">Search</UncontrolledTooltip> */}
                                
                                {/* <Button id="bkg_cancel" color="default" outline type="button" className="mr-1"
                                    onClick={(e)=>sendBooking('CANCEL')}>CANCEL</Button>
                                <UncontrolledTooltip delay={0} target="bkg_cancel">취소문서 전송</UncontrolledTooltip> */}
                            </Col>
                            <Col className="text-right">
                                <Button id="emailSend" color="default" outline type="button" className="mr-1"
                                    onClick={(e)=>{setPopoverOpen(!popoverOpen)}}>E-mail전송</Button>
                                <Button color="default" outline type="button" className="mr-1" onClick={()=> window.history.back()}>뒤로가기</Button>
                                <Button id="report" color="default" outline type="button" className="mr-1"
                                    onClick={(e)=>fncReportViewer()}>REPORT</Button>
                                {bkgResendYN==='Y'&&!(confirm.status_cus=='EA'||confirm.status_cus=='EC'||confirm.status_cus=='S5'||confirm.status_cus=='EJ')?
                                <Button color="default" outline type="button" className="mr-1" onClick={()=>goConfirmEditing(confirm.res_bkg_no)}>
                                 정정작성</Button>
                                :''}
                            </Col>
                            <Popover trigger="hover" placement="left" isOpen={popoverOpen} target="emailSend">
                                <PopoverHeader>
                                    <Row>
                                    <Col>
                                            <InputGroup>
                                            <Input
                                                type="text"
                                                ref={focusRef}
                                                id="sendMail"
                                                maxLength="50"
                                                value={sendEmail}
                                                placeholder="Email 입력"
                                                onChange={(e)=> setSendEmail(e.target.value)}
                                                onKeyUp= {(e)=> onAddEmailList(e)}
                                                invalid={sendEmail?(!validation.validationEmail(sendEmail)):false}
                                                required={false}
                                                // inputgrouptext={}
                                            />
                                            <Button onClick={(e)=> {onAddEmailListButton()}}><i className="fa fa-plus"/></Button>
                                            </InputGroup>
                                            <FormFeedback>이메일 형식에 맞지 않습니다.</FormFeedback>
                                        </Col>
                                    </Row>
                                </PopoverHeader>
                                <PopoverBody>
                                    <Row>
                                        {emailList.map((value,index)=> {
                                            return (
                                                <Col key={index}>
                                                    <Badge>{value}</Badge>
                                                    <Button
                                                        color="danger"
                                                        className="btn-round btn-link"
                                                        onClick={() => handleRemove(index)}>
                                                        <i className="fa fa-times" />
                                                    </Button>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </PopoverBody>
                                {emailList.length > 0 &&
                                <PopoverBody>
                                    <Row>
                                        <Col>
                                            <Button color="default" outline type="button" onClick={()=> sendConfirmMail()}>컨펌 메일 전송</Button>
                                        </Col>
                                    </Row>
                                </PopoverBody>}
                            </Popover>
                        </Row>
                        <hr className="mt-2"/>
                        <Row>
                            {/* Title */}
                            <Col xl="12" lg="12" className="pl-4 pr-4 pb-3">
                                <Row>
                                    <Col xl="3" lg="3" md="12">
                                        <FormGroup>
                                            <Label className="mb-0">Booking Number</Label>
                                            <Input type="text" name="res_bkg_no" id="res_bkg_no"
                                                maxLength="15"
                                                defaultValue={param.res_bkg_no}
                                                onChange={onChange}
                                            />
                                        </FormGroup>
                                    </Col>  
                                    <Col xl="3" lg="3" md="12">
                                        <FormGroup>
                                            <Label className="mb-0">Confirm Date</Label>
                                            <Input type="text" name="res_confirm_date" id="res_confirm_date"
                                                maxLength="15"
                                                defaultValue={param.res_confirm_date}
                                                onChange={onChange}
                                            />
                                        </FormGroup>
                                    </Col>  
                                    <Col xl="3" lg="3" md="12">
                                        <FormGroup>
                                            <Label className="mb-0">현재 상태</Label>
                                            <Input type="text" name="status_cus" id="status_cus"
                                                defaultValue={ 
                                                    (() => {
                                                    switch(confirm.status_cus) {
                                                        case 'S9': return '전송';
                                                        case 'RA': return '승인';
                                                        case 'S4': return '정정전송';
                                                        case 'S1': return '취소전송';
                                                        case 'EJ': return '거절';
                                                        case 'EC': return '취소승인';
                                                        case 'NO': return '저장';
                                                        case 'EA': return '승인취소';
                                                        case 'S5': return '정정작성';
                                                        case '4C': return '정정승인';
                                                        default: return '';
                                                    }
                                                    }).call(this)
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Row>
                                <Row style={{zIndex:'200'}}>
                                    <nav id="cd-vertical-nav">
                                        <ul>
                                            <li>
                                                <a href="#!"
                                                    data-number="10"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setColl(Object.assign(...Object.keys(coll).map(k=>({[k]:true}))));
                                                    }}
                                                >
                                                <span className="cd-dot bg-success" />
                                                <span className="cd-label bg-success"><i className="fa fa-window-restore"/>Open All</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!"
                                                data-number="1"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    document.getElementById("general").scrollIntoView(true);
                                                }}
                                                >
                                                <span className="cd-dot bg-secondary" />
                                                <span className="cd-label">TOP</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!"
                                                data-number="6"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setColl({...coll, 'others':true});
                                                    document.getElementById("others").scrollIntoView(true);
                                                }}
                                                >
                                                <span className="cd-dot bg-secondary" />
                                                <span className="cd-label">Booking</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!"
                                                data-number="7"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setColl({...coll, 'schedule':true});
                                                    document.getElementById("schedule").scrollIntoView(true);
                                                }}
                                                >
                                                <span className="cd-dot bg-secondary" />
                                                <span className="cd-label">Schedule</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!"
                                                data-number="2"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setColl({...coll, 'carrier':true});
                                                    document.getElementById("carrier").scrollIntoView(true);
                                                }}
                                                >
                                                <span className="cd-dot bg-secondary" />
                                                <span className="cd-label">Carrier</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!"
                                                data-number="3"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setColl({...coll, 'shipper':true});
                                                    document.getElementById("shipper").scrollIntoView(true);
                                                }}
                                                >
                                                <span className="cd-dot bg-secondary" />
                                                <span className="cd-label">Shipper</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!"
                                                data-number="6"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setColl({...coll, 'transport':true});
                                                    document.getElementById("transport").scrollIntoView(true);
                                                }}
                                                >
                                                <span className="cd-dot bg-secondary" />
                                                <span className="cd-label">Transport</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!"
                                                data-number="6"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setColl({...coll, 'cargo':true});
                                                    document.getElementById("cargo").scrollIntoView(true);
                                                }}
                                                >
                                                <span className="cd-dot bg-secondary" />
                                                <span className="cd-label">Cargo</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#!"
                                                data-number="7"
                                                //href="#projects"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setColl({...coll, 'container':true});
                                                    document.getElementById("container").scrollIntoView(true);
                                                }}
                                                >
                                                <span className="cd-dot bg-secondary" />
                                                <span className="cd-label">Container</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </Row>
                            </Row>
                            {/* OTHERS */}
                            <Col xl="6" lg="6">
                                <Row>


                                    <Col xl="12" lg="12">
                                        <Card id="others">
                                            {/* <CardHeader className="bg-white"> */}
                                            <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
                                                <Row className="pb-2">
                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>BOOKING</Col>
                                                </Row>
                                                {/* 보이는 영역 */}
                                                <Collapse isOpen={coll.others}>
                                                    <hr className="mt-0"/>
                                                    <Row>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Req Bkg</Label></Col>
                                                                    <Col><Label className="form-control-sm form-control mb-0">{confirm.bkg_no?confirm.bkg_no:''}</Label></Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Contract Number</Label></Col>
                                                                    <Col>{renderLabel("sc_no", confirm.sc_no, confirm.t_sc_no)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Remark</Label></Col>
                                                                    <Col>
                                                                        <Row className="mb-1"><Col><Label className="form-control-sm form-control mb-0">{confirm.res_remark1?confirm.res_remark1:''}</Label></Col></Row>
                                                                        <Row className="mb-1"><Col><Label className="form-control-sm form-control mb-0">{confirm.res_remark2?confirm.res_remark2:''}</Label></Col></Row>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                                <div className="text-center" onClick={() => setColl(prevState => ({...prevState, others:!coll.others}))}>
                                                    <div>
                                                        <Button className="p-0" color="link" id="linemore" onClick={() => setColl(prevState => ({...prevState, others:!coll.others}))} style={{height:'21px'}}>
                                                            {coll.others?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="linemore">{coll.others?'Close':'Open'}</UncontrolledTooltip>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xl="12" lg="12">
                                        <Card id="requestMemo">
                                            {/* <CardHeader className="bg-white"> */}
                                            <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
                                                <Row className="pb-2">
                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>REQUEST MEMO</Col>
                                                </Row>
                                                {/* 보이는 영역 */}
                                                <Collapse isOpen={coll.others}>
                                                    <hr className="mt-0"/>
                                                    <Row>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">ANSWER</Label></Col>
                                                                    <Col><Label className="form-control-sm form-control mb-0" style ={{height:'auto', minHeight:'31px'}}>{confirm.line_remark?confirm.line_remark:''}</Label></Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                                <div className="text-center" onClick={() => setColl(prevState => ({...prevState, others:!coll.others}))}>
                                                    <div>
                                                        <Button className="p-0" color="link" id="linemore" onClick={() => setColl(prevState => ({...prevState, others:!coll.others}))} style={{height:'21px'}}>
                                                            {coll.others?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="linemore">{coll.others?'Close':'Open'}</UncontrolledTooltip>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    





                                </Row>
                            </Col>

                            {/* SCHEDULE */}
                            <Col xl="6" lg="6">
                                <Row>
                                    <Col xl="12" lg="12">
                                        <Card id="schedule">
                                            <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
                                                <Row className="pb-2">
                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>SCHEDULE</Col>
                                                </Row>
                                                <Collapse isOpen={coll.schedule}>
                                                    <hr className="mt-0"/>
                                                    <Row>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Vessel</Label></Col>
                                                                    <Col>{renderLabel("sch_vessel_name", confirm.sch_vessel_name, confirm.t_sch_vessel_name)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Voyage</Label></Col>
                                                                    <Col>{renderLabel("sch_vessel_voyage", confirm.sch_vessel_voyage, confirm.t_sch_vessel_voyage)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Call Sign</Label></Col>
                                                                    <Col>{renderLabel("sch_call_sign", confirm.sch_call_sign, confirm.t_sch_call_sign)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">On board</Label></Col>
                                                                    <Col>{renderLabel("sch_led", confirm.sch_led, confirm.t_sch_led)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Doc Close</Label></Col>
                                                                    <Col>{renderLabel("sch_dct", confirm.sch_dct, confirm.t_sch_dct)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Carry Close</Label></Col>
                                                                    <Col>{renderLabel("sch_cct", confirm.sch_cct, confirm.t_sch_cct)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">T.S Y/N</Label></Col>
                                                                    <Col>{renderLabel("sch_ts_yn", confirm.sch_ts_yn, confirm.t_sch_ts_yn)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">POL</Label></Col>
                                                                    <Col xs="3" xl="3" lg="3" md="3" sm="3" className="pr-1">{renderLabel("sch_pol", confirm.sch_pol, confirm.t_sch_pol)}</Col>
                                                                    <Col xs="7" xl="7" lg="7" md="7" sm="7" className="pl-1">{renderLabel("sch_pol_name", confirm.sch_pol_name, confirm.t_sch_pol_name)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">POD</Label></Col>
                                                                    <Col xs="3" xl="3" lg="3" md="3" sm="3" className="pr-1">{renderLabel("sch_pod", confirm.sch_pod, confirm.t_sch_pod)}</Col>
                                                                    <Col xs="7" xl="7" lg="7" md="7" sm="7" className="pl-1">{renderLabel("sch_pod_name", confirm.sch_pod_name, confirm.t_sch_pod_name)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">POR</Label></Col>
                                                                    <Col xs="3" xl="3" lg="3" md="3" sm="3" className="pr-1">{renderLabel("sch_por", confirm.sch_por, confirm.t_sch_por)}</Col>
                                                                    <Col xs="7" xl="7" lg="7" md="7" sm="7" className="pl-1">{renderLabel("sch_por_name", confirm.sch_por_name, confirm.t_sch_por_name)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">PLD</Label></Col>
                                                                    <Col xs="3" xl="3" lg="3" md="3" sm="3" className="pr-1">{renderLabel("sch_pld", confirm.sch_pld, confirm.t_sch_pld)}</Col>
                                                                    <Col xs="7" xl="7" lg="7" md="7" sm="7" className="pl-1">{renderLabel("sch_pld_name", confirm.sch_pld_name, confirm.t_sch_pld_name)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">FDP</Label></Col>
                                                                    <Col xs="3" xl="3" lg="3" md="3" sm="3" className="pr-1">{renderLabel("sch_fdp", confirm.sch_fdp, confirm.t_sch_fdp)}</Col>
                                                                    <Col xs="7" xl="7" lg="7" md="7" sm="7" className="pl-1">{renderLabel("sch_fdp_name", confirm.sch_fdp_name, confirm.t_sch_fdp_name)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">ETD</Label></Col>
                                                                    <Col>{renderLabel("sch_etd", confirm.sch_etd, confirm.t_sch_etd)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">ETA</Label></Col>
                                                                    <Col>{renderLabel("sch_eta", confirm.sch_eta, confirm.t_sch_eta)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>                                                  
                                                    </Row>
                                                </Collapse>
                                                <div className="text-center" onClick={() => setColl(prevState => ({...prevState, schedule:!coll.schedule}))}>
                                                    <div>
                                                        <Button className="p-0" color="link" id="linemore" onClick={() => setColl(prevState => ({...prevState, schedule:!coll.schedule}))} style={{height:'21px'}}>
                                                            {coll.schedule?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="linemore">{coll.schedule?'Close':'Open'}</UncontrolledTooltip>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>

                            {/* SHIPPER */}
                            <Col xl="6" lg="6">
                                <Row>
                                    <Col xl="12" lg="12">
                                        <Card id="shipper">
                                            <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
                                                <Row className="pb-2">
                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>SHIPPER</Col>
                                                </Row>
                                                {/* 보이는 영역 */}
                                                <Collapse isOpen={coll.shipper}>
                                                    <hr className="mt-0"/>
                                                    <Row>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Name</Label></Col>
                                                                    <Col>
                                                                        <Row className="mb-1"><Col>{renderLabel("shp_name1", confirm.shp_name1, confirm.t_shp_name1)}</Col></Row>
                                                                        <Row className="mb-1"><Col>{renderLabel("shp_name2", confirm.shp_name2, confirm.t_shp_name2)}</Col></Row>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Code</Label></Col>
                                                                    <Col>{renderLabel("shp_code", confirm.shp_code, confirm.t_shp_code)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Address</Label></Col>
                                                                    <Col>
                                                                        <Row className="mb-1"><Col>{renderLabel("shp_address1", confirm.shp_address1, confirm.t_shp_address1)}</Col></Row>
                                                                        <Row className="mb-1"><Col>{renderLabel("shp_address2", confirm.shp_address2, confirm.t_shp_address2)}</Col></Row>
                                                                        <Row className="mb-1"><Col>{renderLabel("shp_address3", confirm.shp_address3, confirm.t_shp_address3)}</Col></Row>
                                                                        <Row className="mb-1"><Col>{renderLabel("shp_address4", confirm.shp_address4, confirm.t_shp_address4)}</Col></Row>
                                                                        <Row className="mb-1"><Col>{renderLabel("shp_address5", confirm.shp_address5, confirm.t_shp_address5)}</Col></Row>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>                                                        
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Payment</Label></Col>
                                                                    <Col>
                                                                    {renderLabel("shp_payment_type", 
                                                                        (() => {
                                                                        switch(confirm.shp_payment_type) {
                                                                            case 'P': return 'Prepaid';
                                                                            case 'C': return 'Collected';
                                                                            default: return ''; 
                                                                        }
                                                                        }).call(this),  
                                                                        (() => {
                                                                        switch(confirm.t_shp_payment_type) {
                                                                            case 'P': return 'Prepaid';
                                                                            case 'C': return 'Collected';
                                                                            default: return ''; 
                                                                        }
                                                                        }).call(this)
                                                                    )}
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>

                                                </Collapse>
                                                <div className="text-center" onClick={() => setColl(prevState => ({...prevState, shipper:!coll.shipper}))}>
                                                    <div>
                                                        <Button className="p-0" color="link" id="linemore" onClick={() => setColl(prevState => ({...prevState, shipper:!coll.shipper}))} style={{height:'21px'}}>
                                                            {coll.shipper?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="linemore">{coll.shipper?'Close':'Open'}</UncontrolledTooltip>
                                                    </div>
                                                </div>
                                            </CardBody>
                                            {/* </CardHeader> */}
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>

                            {/* CARRIER */}
                            <Col xl="6" lg="6">
                                <Row>
                                    <Col xl="12" lg="12">
                                        <Card id="carrier">
                                            {/* <CardHeader className="bg-white"> */}
                                            <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
                                                <Row className="pb-2">
                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>CARRIER</Col>
                                                </Row>
                                                {/* 보이는 영역 */}
                                                <Collapse isOpen={coll.carrier}>
                                                    <hr className="mt-0"/>
                                                    <Row>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Name</Label></Col>
                                                                    <Col>
                                                                        <Row className="mb-1"><Col>{renderLabel("line_name1", confirm.line_name1, confirm.t_line_name1)}</Col></Row>
                                                                        <Row className="mb-1"><Col>{renderLabel("line_name2", confirm.line_name2, confirm.t_line_name2)}</Col></Row>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>                                                        
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Code</Label></Col>
                                                                    <Col>{renderLabel("line_code", confirm.line_code, confirm.t_line_code)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Dept</Label></Col>
                                                                    <Col>{renderLabel("line_user_dept", confirm.line_user_dept, confirm.t_line_user_dept)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Person</Label></Col>
                                                                    <Col>{renderLabel("line_user_name", confirm.line_user_name, confirm.t_line_user_name)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Tel</Label></Col>
                                                                    <Col>{renderLabel("line_user_tel", confirm.line_user_tel, confirm.t_line_user_tel)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Fax</Label></Col>
                                                                    <Col>{renderLabel("line_user_fax", confirm.line_user_fax, confirm.t_line_user_fax)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Email</Label></Col>
                                                                    <Col>{renderLabel("line_user_email", confirm.line_user_email, confirm.t_line_user_email)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Address</Label></Col>
                                                                    <Col>
                                                                        <Row className="mb-1"><Col>{renderLabel("line_address1", confirm.line_address1, confirm.t_line_address1)}</Col></Row>
                                                                        <Row className="mb-1"><Col>{renderLabel("line_address2", confirm.line_address2, confirm.t_line_address2)}</Col></Row>
                                                                        <Row className="mb-1"><Col>{renderLabel("line_address3", confirm.line_address3, confirm.t_line_address3)}</Col></Row>
                                                                        <Row className="mb-1"><Col>{renderLabel("line_address4", confirm.line_address4, confirm.t_line_address4)}</Col></Row>
                                                                        <Row className="mb-1"><Col>{renderLabel("line_address5", confirm.line_address5, confirm.t_line_address5)}</Col></Row>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                                <div className="text-center" onClick={() => setColl(prevState => ({...prevState, carrier:!coll.carrier}))}>
                                                    <div>
                                                        <Button className="p-0" color="link" id="linemore" onClick={() => setColl(prevState => ({...prevState, carrier:!coll.carrier}))} style={{height:'21px'}}>
                                                            {coll.carrier?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="linemore">{coll.carrier?'Close':'Open'}</UncontrolledTooltip>
                                                    </div>
                                                </div>
                                            </CardBody>
                                            {/* </CardHeader> */}
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            
                            {/* TRANSPORT */}
                            <Col xl="6" lg="6">
                                <Row>
                                    <Col xl="12" lg="12">
                                        <Card id="transport">
                                            {/* <CardHeader className="bg-white"> */}
                                            <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
                                                <Row className="pb-2">
                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>TRANSPORT</Col>
                                                </Row>
                                                {/* 보이는 영역 */}
                                                <Collapse isOpen={coll.trasport}>
                                                    <hr className="mt-0"/>
                                                    <Row>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Haulage</Label></Col>
                                                                    <Col>{renderLabel("line_address5",                                                                         (() => {
                                                                        switch(confirm.trans_self_yn) {
                                                                            case 'Y': return 'Merchant haulage';
                                                                            case 'N': return 'Carrier haulage';
                                                                            default: return ''; 
                                                                        }
                                                                        }).call(this), 
                                                                        (() => {
                                                                            switch(confirm.t_trans_self_yn) {
                                                                                case 'Y': return 'Merchant haulage';
                                                                                case 'N': return 'Carrier haulage';
                                                                                default: return ''; 
                                                                            }
                                                                            }).call(this)
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                <Row>
                                                                    <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Service</Label></Col>
                                                                    <Col>{renderLabel("trans_service_name", confirm.trans_service_name, confirm.t_trans_service_name)}</Col>
                                                                </Row>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                                <div className="text-center" onClick={() => setColl(prevState => ({...prevState, trasport:!coll.trasport}))}>
                                                    <div>
                                                        <Button className="p-0" color="link" id="linemore" onClick={() => setColl(prevState => ({...prevState, trasport:!coll.trasport}))} style={{height:'21px'}}>
                                                            {coll.trasport?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="linemore">{coll.trasport?'Close':'Open'}</UncontrolledTooltip>
                                                    </div>
                                                </div>
                                            </CardBody>
                                            {/* </CardHeader> */}
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>

                            {/* CARGO */}
                            <Col xl="12" lg="12">
                                <Row>
                                    <Col xl="12" lg="12">
                                        <Card id="cargo">
                                            {/* <CardHeader className="bg-white"> */}
                                            <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
                                                <Row className="pb-2">
                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>CARGO</Col>
                                                </Row>
                                                {/* 보이는 영역 */}
                                                <Collapse isOpen={coll.cargo}>
                                                    <hr className="mt-0"/>
                                                    <Row>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                {
                                                                    cargo.map((data, index)=>(
                                                                        <Row key={index.toString()}>
                                                                        <Col xl="12" lg="12" md="12">
                                                                            <FormGroup className="mb-1">
                                                                                <Row>
                                                                                <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Cargo Type</Label></Col>
                                                                                    <Col>{renderLabel("cargo_type_name".concat("_").concat(index.toString()), data.cargo_type_name, data.t_cargo_type_name)}</Col>
                                                                                </Row>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xl="12" lg="12" md="12">
                                                                            <FormGroup className="mb-1">
                                                                                <Row>
                                                                                <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Pkg Qty</Label></Col>
                                                                                    <Col>{renderLabel("cargo_pack_qty".concat("_").concat(index.toString()), data.cargo_pack_qty, data.t_cargo_pack_qty)}</Col>
                                                                                </Row>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xl="12" lg="12" md="12">
                                                                            <FormGroup className="mb-1">
                                                                                <Row>
                                                                                <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Pkg Type</Label></Col>
                                                                                    <Col>{renderLabel("cargo_pack_type_name".concat("_").concat(index.toString()), data.cargo_pack_type_name, data.t_cargo_pack_type_name)}</Col>
                                                                                </Row>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xl="12" lg="12" md="12">
                                                                            <FormGroup className="mb-1">
                                                                                <Row>
                                                                                <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Total Weight</Label></Col>
                                                                                    <Col>{renderLabel("cargo_total_weight".concat("_").concat(index.toString()), data.cargo_total_weight, data.t_cargo_total_weight)}</Col>
                                                                                </Row>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xl="12" lg="12" md="12">
                                                                            <FormGroup className="mb-1">
                                                                                <Row>
                                                                                <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Hs Code</Label></Col>
                                                                                    <Col>{renderLabel("cargo_hs_code".concat("_").concat(index.toString()), data.cargo_hs_code, data.cargo_hs_code)}</Col>
                                                                                </Row>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xl="12" lg="12" md="12">
                                                                            <FormGroup className="mb-1">
                                                                                <Row>
                                                                                <Col xs="2" xl="2" lg="2" md="2" sm="2" className="pr-0 pt-1"><Label className="mb-0">Volume</Label></Col>
                                                                                    <Col>{renderLabel("cargo_total_volume".concat("_").concat(index.toString()), data.cargo_total_volume, data.cargo_total_volume)}</Col>
                                                                                </Row>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        {/* <Col xl="12" lg="12" md="12">
                                                                                    <FormGroup className="mb-1">
                                                                                        <Label className="mb-0">Goods</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_address1".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_address1, cntr.t_cntr_drop_off_cy_address1)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_address2".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_address2, cntr.t_cntr_drop_off_cy_address2)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_address3".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_address3, cntr.t_cntr_drop_off_cy_address3)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_address4".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_address4, cntr.t_cntr_drop_off_cy_address4)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_address5".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_address5, cntr.t_cntr_drop_off_cy_address5)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col> */}
                                                                        { index < cargo.length-1 && <hr className="border-secondary"/> }
                                                                        </Row>
                                                                    ))
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            {cargoGoods.map((data,index) =>
                                                            <Card className="no-transition" style={{border:'1px solid silver'}} key={index}>
                                                                <CardHeader className="pt-1 pb-1">
                                                                    <Label className="mt-2" style={{fontWeight:'bold',fontSize:'15px',color:'#696969'}}>{index+1}. Goods</Label>
                                                                </CardHeader>
                                                                <CardBody className="pt-3 pb-3">
                                                                    <Row className="mb-1"><Col>{renderLabel("goods_desc1".concat("_").concat(index.toString()), data.goods_desc1, data.t_goods_desc1)}</Col></Row>
                                                                    <Row className="mb-1"><Col>{renderLabel("goods_desc2".concat("_").concat(index.toString()), data.goods_desc2, data.t_goods_desc2)}</Col></Row>
                                                                    <Row className="mb-1"><Col>{renderLabel("goods_desc3".concat("_").concat(index.toString()), data.goods_desc3, data.t_goods_desc3)}</Col></Row>
                                                                    <Row className="mb-1"><Col>{renderLabel("goods_desc4".concat("_").concat(index.toString()), data.goods_desc4, data.t_goods_desc4)}</Col></Row>
                                                                    <Row className="mb-1"><Col>{renderLabel("goods_desc5".concat("_").concat(index.toString()), data.goods_desc5, data.t_goods_desc5)}</Col></Row>
                                                                </CardBody>
                                                            </Card>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                                <div className="text-center" onClick={() => setColl(prevState => ({...prevState, cargo:!coll.cargo}))}>
                                                    <div>
                                                        <Button className="p-0" color="link" id="linemore" onClick={() => setColl(prevState => ({...prevState, cargo:!coll.cargo}))} style={{height:'21px'}}>
                                                            {coll.cargo?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="linemore">{coll.cargo?'Close':'Open'}</UncontrolledTooltip>
                                                    </div>
                                                </div>
                                            </CardBody>
                                            {/* </CardHeader> */}
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            
                            {/* CONTAINER */}
                            <Col xl="12" lg="12">
                                <Row>
                                    <Col xl="12" lg="12">
                                        <Card id="container">
                                            {/* <CardHeader className="bg-white"> */}
                                            <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>
                                                <Row className="pb-2">
                                                    <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>CONTAINER</Col>
                                                </Row>
                                                {/* 보이는 영역 */}
                                                <Collapse isOpen={coll.container}>
                                                    <hr className="mt-0"/>
                                                    <Row>
                                                        <Col xl="12" lg="12" md="12">
                                                            <FormGroup className="mb-1">
                                                                {
                                                                    cntr.map((cntr, index)=>(
                                                                        <Row key={index.toString()}>
                                                                    <Col>
                                                                    <Card className="no-transition" style={{border:'1px solid silver'}} key={index}>
                                                                        <CardHeader className="pt-1 pb-1">
                                                                            <Label className="mt-2" style={{fontWeight:'bold',fontSize:'15px',color:'#696969'}}>{index+1}. {cntr.cntr_code}</Label>
                                                                        </CardHeader>
                                                                        <CardBody className="pt-3 pb-3">
                                                                            <Row>
                                                                                <Col xl="4" lg="4" md="4">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">Size / Type</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_code".concat("_").concat(index.toString()), cntr.cntr_code, cntr.t_cntr_code)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xl="4" lg="4" md="4">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">Qty</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_qty".concat("_").concat(index.toString()), cntr.cntr_qty, cntr.t_cntr_qty)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xl="4" lg="4" md="4">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">SOC</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_soc_yn".concat("_").concat(index.toString()), cntr.cntr_soc_yn, cntr.t_cntr_soc_yn)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xl="6" lg="6" md="6">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">Frozen Tmp</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_frozen_tmp".concat("_").concat(index.toString()), cntr.cntr_frozen_tmp, cntr.t_cntr_frozen_tmp)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xl="6" lg="6" md="12">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">Pick Up CY</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_pick_up_cy_code".concat("_").concat(index.toString()), cntr.pickup_cy_name, cntr.t_pickup_cy_name)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xl="6" lg="6" md="12">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">Pick Up Date</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_pick_up_date".concat("_").concat(index.toString()), cntr.cntr_pick_up_date, cntr.t_cntr_pick_up_date)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                                <Row>
                                                                                <Col xl="4" lg="4" md="12">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">CY Name</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_pick_up_cy_name1".concat("_").concat(index.toString()), cntr.cntr_pick_up_cy_name1, cntr.t_cntr_pick_up_cy_name1)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xl="4" lg="4" md="12">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0"></Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_pick_up_cy_name2".concat("_").concat(index.toString()), cntr.cntr_pick_up_cy_name2, cntr.t_cntr_pick_up_cy_name2)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xl="4" lg="4" md="12">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">CY Tel</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_pick_up_cy_user_tel".concat("_").concat(index.toString()), cntr.cntr_pick_up_cy_user_tel, cntr.t_cntr_pick_up_cy_user_tel)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xl="12" lg="12" md="12">
                                                                                    <FormGroup className="mb-1">
                                                                                        <Label className="mb-0">Pick Up Cy Address</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_pick_up_cy_address1".concat("_").concat(index.toString()), cntr.cntr_pick_up_cy_address1, cntr.t_cntr_pick_up_cy_address1)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_pick_up_cy_address2".concat("_").concat(index.toString()), cntr.cntr_pick_up_cy_address2, cntr.t_cntr_pick_up_cy_address2)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_pick_up_cy_address3".concat("_").concat(index.toString()), cntr.cntr_pick_up_cy_address3, cntr.t_cntr_pick_up_cy_address3)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_pick_up_cy_address4".concat("_").concat(index.toString()), cntr.cntr_pick_up_cy_address4, cntr.t_cntr_pick_up_cy_address4)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_pick_up_cy_address5".concat("_").concat(index.toString()), cntr.cntr_pick_up_cy_address5, cntr.t_cntr_pick_up_cy_address5)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xl="4" lg="4" md="12">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">Drop off CY name</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_name1".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_name1, cntr.t_cntr_drop_off_cy_name1)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xl="4" lg="4" md="12">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0"></Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_name2".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_name2, cntr.t_cntr_drop_off_cy_name2)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xl="4" lg="4" md="12">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">Drop off CY Tel</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_user_tel".concat("_").concat(index.toString()), cntr.cntr_drop_off_user_tel, cntr.t_cntr_drop_off_user_tel)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xl="12" lg="12" md="12">
                                                                                    <FormGroup className="mb-1">
                                                                                        <Label className="mb-0">Drop off CY Address</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_address1".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_address1, cntr.t_cntr_drop_off_cy_address1)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_address2".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_address2, cntr.t_cntr_drop_off_cy_address2)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_address3".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_address3, cntr.t_cntr_drop_off_cy_address3)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_address4".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_address4, cntr.t_cntr_drop_off_cy_address4)}</Col></Row>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_drop_off_cy_address5".concat("_").concat(index.toString()), cntr.cntr_drop_off_cy_address5, cntr.t_cntr_drop_off_cy_address5)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xl="12" lg="12" md="12">
                                                                                    <FormGroup className="mb-1">
                                                                                        <Label className="mb-0">Remark</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_remark", cntr.cntr_remark, cntr.t_cntr_remark)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xl="4" lg="4" md="4">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">NOR</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_nor_flag", cntr.cntr_nor_flag, cntr.t_cntr_nor_flag)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xl="4" lg="4" md="4">
                                                                                    <FormGroup>
                                                                                        <Label className="mb-0">Humidity</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_humidity", cntr.cntr_humidity, cntr.t_cntr_humidity)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                                <Col xl="4" lg="4" md="4">
                                                                                    <FormGroup> 
                                                                                        <Label className="mb-0">환풍기 개방율</Label>
                                                                                        <Row className="mb-1"><Col>{renderLabel("cntr_vent_open", cntr?.cntr_vent_open, cntr?.t_cntr_vent_open)}</Col></Row>
                                                                                    </FormGroup>
                                                                                </Col>
                                                                            </Row>
                                                                            {cntrSpecials.map((element, key)=>{
                                                                                if( cntr.cntr_seq === element.cntr_seq ) {
                                                                                    return(
                                                                                    <Row key={key}>
                                                                                        <Col xl="6" lg="6" md="6">
                                                                                            <FormGroup>
                                                                                                <Label className="mb-0">UNDG</Label>
                                                                                                <Row className="mb-1"><Col>{renderLabel("special_undg".concat("_").concat(index.toString()), element.special_undg, element.special_undg)}</Col></Row>
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                        <Col xl="6" lg="6" md="6">
                                                                                            <FormGroup>
                                                                                                <Label className="mb-0">IMDG</Label>
                                                                                                <Row className="mb-1"><Col>{renderLabel("special_imdg".concat("_").concat(index.toString()), element.special_imdg, element.special_imdg)}</Col></Row>
                                                                                            </FormGroup>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    )
                                                                                }else {
                                                                                    return null;
                                                                                }
                                                                            })}
                                                                        </CardBody>
                                                                    </Card>
                                                                    </Col>
                                                                    </Row>
                                                                    ))
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                                <div className="text-center" onClick={() => setColl(prevState => ({...prevState, container:!coll.container}))}>
                                                    <div>
                                                        <Button className="p-0" color="link" id="linemore" onClick={() => setColl(prevState => ({...prevState, container:!coll.container}))} style={{height:'21px'}}>
                                                            {coll.container?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                                        </Button>
                                                        <UncontrolledTooltip delay={0} target="linemore">{coll.container?'Close':'Open'}</UncontrolledTooltip>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        </CardBody>
                    </Container>
                </Form>
                <form id="reportForm" name="reportForm" >
                    <input type="hidden" name="system_id"   value="LOGISVIEW" />
                    <input type="hidden" name="user_id"     value={`${(userData && userData.user_no)||'GUEST'}`} />
                    <input type="hidden" name="file_type"   value="pdf" />
                    <input type="hidden" name="file_id"     value="" />
                    <input type="hidden" name="file_path"   value="" />
                    <input type="hidden" name="name"        value="" />
                    <input type="hidden" name="connection"  value={process.env.REACT_APP_DB=='ORACLE'?'logisvw' : 'pgsql'} />
                    <input type="hidden" name="parameters" id="parameters"/>
                </form>
                <form id="termsForm" name="termsForm" >
                    <input type="hidden" name="menuKey"     value="219" />
                    <input type="hidden" name="contentKey"  value="45" />
                </form>
            </div>
            {/* <Footer {...props}/> */}
        </>
    );
}
