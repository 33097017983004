import React, {useState, useEffect, useCallback} from 'react';
import { useHistory } from "react-router-dom";
import cn from 'classnames'
import axios from 'axios';
import _ from 'lodash';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {withStyles} from "@material-ui/core/styles";
import {Tooltip,} from "@material-ui/core";
// css
import Style from './service.module.css'
import PlaceholderImg from 'assetsNew/image/No-Image.gif'
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'

const TooltipCustom = withStyles((theme)=>({
    tooltip:{
        // color:'red',
        fontSize:12
    },
    tooltipPlacementTop:{
        margin:'2px 0'
    }
}))(Tooltip);

const ServiceCarrier = (props) => {
    const history = useHistory();
    const {userStoreMenu} = props;
    
    const [ tabList,setTabList ]=useState( [
        {id :0, tname:'e-Shipping' , path: '/svc/fcl'  },
        {id :1, tname:'e-A/N' , path: {prodNo :157, devNo:157}},
        {id :2, tname:'c-D/O' , path: {prodNo :158, devNo:158}},
        {id :3, tname:'SUR CHG 인보이스'  , path: {prodNo :936, devNo:605}},
        {id :4, tname:'수입 운임 인보이스' , path: {prodNo :1039, devNo:1049} },
        {id :5, tname:'수출 운임 인보이스' , path: {prodNo :2393, devNo:2393}},
    ]) 
    const [tabData, setTabData]= useState(tabList&&tabList[0]);
    
    const [ serviceListAll,setServiceListAll ]=useState([]);//최초한번 데이터
    const [ carrierData,setCarrierData ]=useState([]);
    const [ searchTerm, setSearchTerm]=useState('');
// console.log(props)
    useEffect( ()=>{
        getCarrierData();
        // getCarrierLogo();
    },[])

    useEffect( ()=>{
        searchTerm&&setSearchTerm('')
    },[tabData]);

    useEffect( ()=>{
        !searchTerm&&setCarrierData([...serviceListAll])
    },[searchTerm]);

    const getCarrierData = async()=>{
        const result0 = await axios.post("/api/getCarrierListEbooking",{})
        const result1 = await axios.post("/api/getCarrierListEAN",{})
        const result2 = await axios.post("/api/getCarrierListCDO",{})
        const result3 = await axios.post("/api/getCarrierListINV",{})
        // const logoImg = await axios.post(`/api/getCarrierLogo`,{})
        // const logoList = logoImg.data;
        const resultData0=result0.data.map(item =>{return {img: item.LOGO_IMG || item.LINE_CODE,line_name:item.LINE_KR_NAME, line_code:item.LINE_CODE, sch_line_code:item.SCH_LINE_CODE}})
        const resultData1=result1.data.map(item =>{return {img: item.LOGO || item.PARTNER_LINE, line_name:item.PARTNER_LINE_NM, line_code: item.PARTNER_LINE}})
        const resultData2=result2.data.map(item =>{return {img: item.LOGO || item.PARTNER_LINE, line_name:item.PARTNER_LINE_NM, line_code: item.PARTNER_LINE}})
        const list=result3.data.map(item =>{return {img: item.LOGO || item.LINE_CODE, line_name:item.PARTNER_LINE_NM, line_code: item.LINE_CODE, type : item.TYPE}})

        // const resultData0=result0.data.map(item =>{return {img: item.logo_img ,line_name:item.line_kr_name, line_code:item.line_code}})
        // const resultData1=result1.data.map(item =>{return {img: item.LOGO_IMG|| (logoList.find(x=>x.sch_line_code==item.PARTNER_LINE)&&logoList.find(x=>x.sch_line_code==item.PARTNER_LINE).sch_logo_img)
        //                                                     ,line_name:item.PARTNER_LINE_NM, line_code: item.PARTNER_LINE}})
        // const resultData2=result2.data.map(item =>{return {img: item.LOGO_IMG|| (logoList.find(x=>x.sch_line_code==item.PARTNER_LINE)&&logoList.find(x=>x.sch_line_code==item.PARTNER_LINE).sch_logo_img)
        //                                                     ,line_name:item.PARTNER_LINE_NM, line_code: item.PARTNER_LINE}})
        // const list=result3.data.map(item =>{return {img: item.LOGO_IMG|| (logoList.find(x=>x.sch_line_code==item.LINE_CODE)&&logoList.find(x=>x.sch_line_code==item.LINE_CODE).sch_logo_img)
        //                                                     ,line_name:item.PARTNER_LINE_NM, line_code: item.LINE_CODE, type : item.TYPE}})
        const resultData3=list.filter(x=>x.type==0);
        const resultData4=list.filter(x=>x.type==1);
        const resultData5=list.filter(x=>x.type==2);
        setServiceListAll([resultData0,resultData1,resultData2,resultData3,resultData4,resultData5]);//검색비교 최초 데이터
        setCarrierData([resultData0,resultData1,resultData2,resultData3,resultData4,resultData5])
        // console.log(carrierData)
    }
    const handleClick =useCallback((id)=>{  
            let newData = tabList&&tabList.find(x=>x.id==id);
            setTabData(newData);
            // const pLength = newData.pdata&&newData.pdata.length-1;
        
            // tabPart !==4 && pLength<tabPart &&setTabPart(0);
    },[tabList]);

    const onSearchCarrierData=useCallback(_.debounce((params,tid)=>{
        if (serviceListAll.length>0){
            let arry = _.cloneDeep(serviceListAll);
            let data =_.cloneDeep(arry[tid]);            
            if(!params){
                setCarrierData(arry)
            }else{
                let result = data.filter(val=>{//console.log(aa,'>>>',val.name,val.name.includes(aa))
                    if((val?.line_name||'').includes(params)){ return val}
                })
                setCarrierData(old=> {
                    old[tid] =result;
                    return [...old]})
            }
        }
    },300),[serviceListAll]);

    const goLink =(pathItem, val)=>{
        let main = userStoreMenu.find(x=>(x.views&& x.views.find(y=>(pathItem['prodNo']==y.linkParam['prodNo'])||
        (pathItem ==y.layout+y.path))));
        let sub = main&&(main.views.find(y=> (pathItem['prodNo']==y.linkParam['prodNo'])||(pathItem ==y.layout+y.path)))

        if(props.checkOptionPage(sub)){
            if(pathItem['prodNo']){
                props.goPlism({linkParam:pathItem},{carrier_code:val&&val.line_code||null});
            }else{
                let param = {pathname: pathItem};
                if(val){
                    param['state']={sch_line_code:[{sch_line_code: val.sch_line_code||val.line_code||null
                                    ,line_name2: val.line_name||null}]};
                }
                history.push(param)
            }
        }
    }

    const onKeyPress = (e) => {
        if(e.key==='Enter') {
            onSearchCarrierData(searchTerm,tabData.id)
        }
    }

    return (
        <>
        <div className={Style.subHeaderWrap}>
            <div className={Style.stit}>
                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-sub-sunsa.png`}/>
                <strong>서비스 제공 선사</strong>
            </div>
            {/* <ul>
                <li onClick={()=>history.push("/")}><img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-home.png`}/></li> */}
                {/* <li>물류정보</li> */}
                {/* <li><strong>서비스 제공 선사</strong></li>
            </ul> */}
        </div>
        <div className={Style.sunsaPageWrap}>
            <ul className={Style.tabmenuListWrap}>
                {tabList&&tabList.map((val, ind, arry)=>
                    <li className={cn(Style.tab, val.id==tabData.id? Style.on:'')} key={ind}
                        onClick={()=>{handleClick(val.id)}}>
                        {val.tname}
                    </li>
                )} 
            </ul>
            <div className={Style.sunsaHeaderWrap}>
                <div className={Style.mtit}>{tabData.tname}</div>
                <div className={Style.searchItemWrap}>
                    <input type="text" className={Style.inp} placeholder="CARRIER NAME"
                            value={searchTerm||''}
                            onKeyPress={onKeyPress}
                            onChange={(e)=>{setSearchTerm(e.target.value.toUpperCase())}}/>
                    <button type="button" onClick={()=>onSearchCarrierData(searchTerm,tabData.id)}>
                        <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-search.png`} />    
                    </button>
                    {/* <input type="text" className={Style.inp} placeholder="CARRIER NAME"/>
                    <button type="button"><img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-search.png`}/></button> */}
                </div>
            </div>
            <div className={Style.scrolls}>
                <ul className={Style.bizListWrap}>
                    {carrierData.length>0&&carrierData[tabData.id].map((value, ind)=>
                        <TooltipCustom key={ind} title={`[${value.line_code}]  ${value.line_name}`} placement='top' >
                            <li key={ind} onClick={()=>tabData&&goLink(tabData.path, value)}>
                                <div className={Style.item}>
                                    <LoadCarrierImage line_code={value.line_code} logo={value.img} classStyle={"img-rounded img-responsive"} 
                                    width={32} height={30}
                                    />
                                    {/* <ImgComponent line_code={value.line_code} logo={value.img} /> */}
                                    {/* <ImgComponent line_code={value.line_code} logo={logoImgList.length>0&&logoImgList.filter(x=>x.line_code==value.line_code)} /> */}
                                    {/* <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-slide-list01.png`} /> */}
                                    <span>{value.line_name}</span>
                                </div>
                            </li>
                        </TooltipCustom>
                        )
                    }
                    {/* <li>
                    <a href="#1">
                        <img src={process.env.PUBLIC_URL  + `/assets/image/ico/ico-biz-list04.png`}/>
                        <span>위동항운</span>
                    </a>
                </li> */}
                </ul>
            </div>
        </div>
        </>
    )
}
export default ServiceCarrier
