import React,{useState} from 'react'
import { Link, useHistory } from "react-router-dom";

// import axios from 'axios';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import { Modal, Backdrop } from "@material-ui/core";
import DialogContent from '@material-ui/core/DialogContent';
import Manual from 'pages/popup/manual'
import BusinessStatus from './BusinessStatus'
import ScheduleItemWrap from './ScheduleItemWrap'
import BoardItemWrap from './BoardItemWrap'
import VisualItemWrap from './VisualItemWrap'
import {userStore} from 'store/userStore.js';
import {useObserver} from 'mobx-react-lite';

import './slider.css'
import Style from './main.module.css'

const useStyles = makeStyles((theme)=>({
    modal:{
        display:'flex'
        ,alignItems:'center'
        ,justifyContent:'center'
    }
    ,root:{
        display:'flex'
        ,justifyContent:'center'
        ,flex:'none'
        ,width:'60%'
    }
}))


const MainPage = (props) => {
    const {goPlism, menuAccessLog, userData} =props;
    const classes = useStyles();
    const history = useHistory();
    
    const [ modalOpen,setModalOpen ]=useState( false);

    const userStoreMenu = useObserver(() => {
        return userStore.menu;
    });
    
    const onSubmitPage= (element)=>{        
        if(props.checkOptionPage(element)){
            if (element.layout=='PLISM'){
                // goPlism(element.linkParam,{id:element.id||null, logout_use_flag:element?.logout_use_flag||'N'}); 
                goPlism(element,{id:element.id||null, logout_use_flag:element?.logout_use_flag||'N'}); 
            }else if(element.layout=='urlPath'){
                menuAccessLog(element, userData);
                window.open(element.path,'_blank');
            }else{
                let path = `${element.layout+element.path}${element.id?`/${element.id}`:''}`            
                history.push(path);
            }
        }
    }
    
    return (
    <div className={Style.wrapper}>
        <div className={Style.homePageWrap}>
            <div className={Style.ltItemWrap}>
                {/* visualItemWrap 첫번째 배너*/}
                <VisualItemWrap  {...props}/>
                {/* scheduleItemWrap 두번째 배너*/}
                <ScheduleItemWrap {...props}/>
                <BoardItemWrap {...props}/>
            </div>
            <div className={Style.rtItemWrap}>
                <BusinessStatus {...props}/>
                <div className={Style.custmerItemWrap}>
                    <div className={Style.custHeaderWrap} >
                        <div className={Style.serviceDesk} onClick={()=>window.open("https://cyberdesk.klnet.co.kr")} target="_blank" >
                            <div className={Style.sico}><img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-cust01.png`} /></div>
                            <div className={Style.scont}>
                                <div className={Style.ckey}>서비스데스크</div>
                                <div className={Style.cvalue}>고객지원센터</div>
                            </div>
                        </div>
                        <div className={Style.stel}>1577-1172</div>
                    </div>
                    <ul>
                        <li>
                            <Link to='/svc/serviceReq?tab=t0' >
                                <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-use-list01.png`}/>
                                <span>서비스 요금</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/svc/serviceReq?tab=t1' >
                                <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-use-list02.png`}/>
                                <span>이용 신청서</span>
                            </Link>
                        </li>
                        <li>
                            <Link to='/svc/serviceCarrier'>
                                <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-use-list03.png`}/>
                                <span>서비스 제공 선사</span>
                            </Link>
                        </li>
                        <li onClick={()=>setModalOpen(true)}>
                            <a href="#1">
                                <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-use-list04.png`}/>
                                <span>업무 매뉴얼</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        {/**광고 배너  */}
        {/* <div className={Style.homeBannerWrap}>
            <ul>
                <li>
                    <a href="#1">
                        <div className={Style.mtit}>프리즘</div>
                        <div className={Style.mpic}><img src={process.env.PUBLIC_URL + `/assetsNew/image/etc/etc-home-banner-list01.png`}/></div>
                    </a>
                </li>
                <li>
                    <a href="#1">
                        <div className={Style.mtit}>케이엘넷</div>
                        <div className={Style.mpic}><img src={process.env.PUBLIC_URL + `/assetsNew/image/etc/etc-home-banner-list02.png`}/></div>
                    </a>
                </li>
                <li>
                    <a href="#1">
                        <div className={Style.mtit}>이트랜스</div>
                        <div className={Style.mpic}><img src={process.env.PUBLIC_URL + `/assetsNew/image/etc/etc-home-banner-list03.png`}/></div>
                    </a>
                </li>
                <li>
                    <a href="#1">
                        <div className={Style.mtit}>프리즘</div>
                        <div className={Style.mpic}><img src={process.env.PUBLIC_URL + `/assetsNew/image/etc/etc-home-banner-list04.png`}/></div>
                    </a>
                </li>
            </ul>
        </div> */}
        <div className={Style.homeSitemapWrap}>
            {userStoreMenu.map((value,index) => {  
                if(value.mainShow) {
                    return (
                    <React.Fragment key = {index}>
                        <div className={Style.fitem}>
                            <div className={Style.mtit}>{value.name}</div>
                            <ul>
                            {value.views.map((element,ind) => {
                                return(
                                    element.mainShow?
                                        <li key={ind}>
                                            <div className={Style.anchor} key={"child"+ind} onClick={()=>{onSubmitPage(element)}}>
                                                {element.name}
                                            </div>
                                        </li>
                                    :null
                                )
                            })}
                            </ul>
                        </div>
                    </React.Fragment>
                    )
                }
            })} 
        </div>
        <Modal
            className={classes.modal}
			open={modalOpen}
            onClose={() => setModalOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{timeout:500}}
        >   
            <DialogContent className={classes.root} >
                <Manual toggle={() => setModalOpen(false)} {...props}/>
            </DialogContent>
        </Modal>
    </div>
  )
}

export default MainPage
