import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
//import useScrollTrigger from '@material-ui/core/useScrollTrigger';
//import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { Table } from 'reactstrap';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children } = props;
  //console.log("props:",props);
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  /*  const trigger = useScrollTrigger({
    //target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });*/

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={true}>
      <div onClick={handleClick} role="presentation" className={classes.root}
      style={{position:'absolute'}}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function EmailPolicy(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar style={{ backgroundColor: '#26c6da' ,position:'absolute'}}>
        <Toolbar style={{ paddingRight: '0' }}>
          <Typography variant="h6" style={{ flexGrow: '1' }}>
            이메일 무단 수집 거부 
          </Typography>
          <HighlightOff onClick={() => props.handleClose()} />
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Container>
        <br />본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며 이를 위반시정보통신망법에 의해 형사처벌됨을 유념하시기 바랍니다.
        <br /><br />
        [게시일 2017년 7월 1일]
        <br />
        <br />
        <br />
      </Container>
      {/* <ScrollTop {...props}>
        <Fab
          color="secondary"
          size="small"
          aria-label="scroll back to top"
          style={{ backgroundColor: '#26c6da' }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop> */}
    </React.Fragment>
  );
}
