import React,{ useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import CardBody from "muiComponents/Card/CardBody.js";
import {
	Collapse,
	Card,
	CardContent,
	TextField,
	TableFooter,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	// Grid,
	// Divider,
	// Container,
	// Avatar,
	// Box,
	Table,
	TableBody,
	TableCell,
	// TableContainer,
	TableHead,
	TableRow,
	// Typography,
	// Paper,
	// Input
} from "@material-ui/core";

import Button from "muiComponents/CustomButtons/Button.js";

import {
	Chat as Chat,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	Search as SearchIcon,
	SearchOutlined
}from "@material-ui/icons";
import axios from 'axios';
// import Filesaver from 'file-saver';
import {yellow, red} from '@material-ui/core/colors';
import { useHistory } from "react-router-dom";
// import testimonialsSection from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle";
// import AlertMessage from 'muiComponents/Alert/AlertMessage.js';
import Pagination from '@material-ui/lab/Pagination';

const ico_qna_reply = require("assets/img/ico_qna_reply.gif");
const lock_icon = require("assets/img/lock-icon.png");

const useRowStyles = makeStyles((theme)=> ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	small: {
		width: theme.spacing(2),
		height: theme.spacing(2),
		backgroundColor: red['700']
	}
}));

function Row(props) {
	const history = useHistory();
	const classes = useRowStyles();
	const [row,setRow] = useState(props.row);
	const [open, setOpen] = useState(false);
	const [pwOpen, setPwOpen] = useState(false);
	const [password,setPassword]= useState('');
	const [lock, setLock] = useState(row.QNA_PASSWORD);
	const { userData} = props;
	useEffect(()=>{
		setRow(props.row);
		setPwOpen(false);
	},[props.row])
	useEffect(() => {
        if(row.NTT_ID===props.num) {
            setOpen(true)
        }else {
            setOpen(false);
        }
	},[props.num]);
	useEffect(() => {
        if(row.NTT_ID===props.num2) {
            setPwOpen(true)
        }else {
            setPwOpen(false);
        }
	},[props.num2]);

	const onButtonClick = () => {
		if(props.userData){
			if(!(props.userData.klnet_id === "KLTEST01"||props.userData.role==='Y')&&lock&&lock==='Y'){
				if(row.NTT_ID===props.num2){
					props.openPWInput("")
				}else{
					props.openPWInput(row.NTT_ID)
				}
				return false
			}
	
			history.push({
				pathname:"/svc/qnadetail",
				...props.row,
				login_user_no:props.userData.user_no,
				login_user_role:props.userData.role,
				login_user_name:props.userData.name,
				klnet_id:props.userData.klnet_id,
			})
		}else{
			props.onAlert("error","로그인 이후 이용해주세요." );
		}
	}
	
	const checkPW = () => {
		axios.post("/api/selectQnaPasswordChk",{user_pwd:password ,bbs_id:row.BBS_ID ,ntt_id:row.NTT_ID})
		.then(res => {
			if(res.data[0].CHK_PW==='Y'){
				history.push({
					pathname:"/svc/qnadetail",
					...props.row,
					login_user_no:props.userData.user_no,
					login_user_role:props.userData.role,
					login_user_name:props.userData.name,
				})
			}else{
				props.onAlert("error","Password가 일치하지 않습니다" );
			}
		})
	}
	
	return (

	  	<React.Fragment>
			<TableRow onClick={() => onButtonClick()}>
				<TableCell component="th" scope="row" style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.RNUM}</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.NTT_SJ} {row.REPLY_AT &&row.REPLY_AT==='Y'&& <img src={ico_qna_reply}/>} {row.QNA_PASSWORD &&row.QNA_PASSWORD==='Y'&& <img src={lock_icon}/> }</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.FRST_REGISTER_NM}</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.COMP_NAME}</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.FRST_REGISTER_PNTTM}</TableCell>
			</TableRow>
			<TableRow>
		  		<TableCell style={{ paddingBottom: 0, paddingTop: 0 , paddingLeft:'3px',paddingRight:'3px' }} colSpan={6}>
				    <Collapse in={pwOpen} timeout="auto" unmountOnExit>
			  			<GridContainer style={{justifyContent:'center',marginTop:'13px',marginBottom:'13px'}}>
						  	<GridItem xs={6} sm={2} md={2}>
										<TextField id="pw" name="pw" label={<font size="2">비밀번호</font>} onChange={event => setPassword(event.target.value.toUpperCase()) } value={password}//onKeyPress={onKeyDownEnter} 
                	variant="outlined" size="small" type="password" fullWidth />
							</GridItem>
							<GridItem xs={6} sm={2} md={2}>
										<Button style={{margin:'0px',paddingBottom:'8px'}}color="info" onClick={()=> checkPW()}>확인</Button>
							</GridItem>
						</GridContainer>
					</Collapse>
					
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
  }

export default function Board(props) {

    const classes = useRowStyles();
    const [boardData,setBoardData] = useState([]);
	const [selectValue,setSelectValue] = useState("0");
	// const [CopySelectValue,setCopySelectValue] = useState("");
	const [openPage, setOpenPage] = useState(0);
	const [Num,setNum] = useState(1);
	const [subject, setSubject] = useState("");
	const [copySubject, setCopySubject] = useState("");
	const [openPW, setOpenPW] = useState("");
	// const [openAlert, setOpenAlert] = useState(false);
	// const [message, setMessage] = useState('');
	// const [status, setStatus] = useState('');
	const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
	const [totalQnACount,setTotalQnACount] = useState(0);
	const [bbsList,setBbsList]= useState([]);
	const [bbsId,setBbsId]=useState('default');
	const pageCount = 10;
	const history = useHistory();
	const { userData} = props;
	useEffect(() => {
		onSubmit();
    }, [page]);

	useEffect(() => {
		fncCategoryList();
    }, [userData]);

	useEffect(() => {
        if(props.location.state) {
            setOpenPage(props.location.state.param);
        }
    },[props.location]);
	
	const onSubmit = () => {
		axios.post("/api/selectQnaList",{bbs_id:bbsId==='default'?null:bbsId,lang:'ko',firstIndex:((page-1)*pageCount)+1, recordCountPerPage:((page-1)*pageCount)+pageCount, searchCnd:selectValue,searchWrd:subject?subject:null,sKlnetId:userData?userData.klnet_id:''})
		.then(res => {
			setBoardData(res.data)
			if(res.data.length>0){
				fncListCount()
			}
		})
	
	  }
	
	const fncCategoryList = ()=> {
		if(userData){
			axios.post("/api/selectBbsCfgCodeList",{sKlnetId:userData.klnet_id,bbs_type:'3'})
			.then(res => {
				if(res.data.length > 0) {
					const newArray = res.data.map( item => {
						return {value: item.BBS_ID, label: item.BBS_NM }
					})
					setBbsList(newArray);
				}
			})
		}
	}
	const fncListCount = () => {
		axios.post("/api/selectQnaListCount",{bbs_id:bbsId==='default'?null:bbsId,lang:'ko', searchCnd:selectValue,searchWrd:subject?subject:null,sKlnetId:userData?userData.klnet_id:''})
		.then(res => {
			if(res.data.length>0){
				//총페이지 수
				setTotalCount(Math.ceil(res.data[0].CNT/10))
				setTotalQnACount(res.data[0].CNT);
			}
		})
	}
    
	// const onAlert = (meassge,status) => {
	// 	setOpenAlert(!openAlert);
	// 	setStatus(status);
	// 	setMessage(meassge);
	//   };

	const qnaWrite = () => {
		history.push({
			pathname:"/svc/qnawrite",
			...props.userData,
		})
	}

	const handleChange = (event, value) => {
		setPage(Number(value));
	  }
	
    return(
		<React.Fragment>
			<Card className="m-4">
				<CardContent>
					<h4 style={{textAlignLast:'left',color:'black'}}><Chat style={{color:'#00acc1'}}></Chat> Q & A</h4>
					<GridContainer className="mt-4">
						<GridItem xs={3} sm={1} md={1} >
							<div style={{textAlign:'-webkit-right'}}>
								<FormControl variant="outlined" >
									<InputLabel>OPTION</InputLabel>
									<Select
										label="option"
										value={selectValue}
										onChange={(event)=> setSelectValue(event.target.value)}>
										<MenuItem value="0">제목</MenuItem>
										<MenuItem value="1">내용</MenuItem>
										<MenuItem value="2">작성자</MenuItem>
									</Select>
								</FormControl>
							</div>
						</GridItem>
						<GridItem xs={6} sm={4} md={4} >
							<TextField id="Subject" variant="outlined"  onChange={(event)=>setSubject(event.target.value)} fullWidth />
						</GridItem>
						<GridItem xs={6} sm={2} md={2} >
							<FormControl variant="outlined" >
								<InputLabel>OPTION</InputLabel>
								<Select
									label="option"
									value={bbsId}
									onChange={(event)=> setBbsId(event.target.value)}>
										<MenuItem  value='default'> 전체 </MenuItem>
									{bbsList.map((data,key) =>
						     			<MenuItem  value={data.value}>{data.label}</MenuItem>
						     			)}
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs={9} sm={5} md={5} >
							<div style={{textAlign:'-webkit-right'}}>
								<Button color="info" endIcon={<SearchIcon/>}  onClick={()=> onSubmit()}>Search</Button>
							</div>
						</GridItem>
					</GridContainer>
				</CardContent>
				<CardBody>
					<div className="mb-1">
						Total:{totalQnACount}
					</div>
					<Table>
						<TableHead style={{backgroundColor:'#f2fefd'}} className={classes["info" + "TableHeader"]}>
							<TableRow style={{borderBottomStyle:'solid',borderBottomColor:'#00bcd4'}}>
								<TableCell style={{width:'15%'}}>No</TableCell>
								<TableCell>제목</TableCell>
								<TableCell style={{width:'10%'}}>작성자 구분</TableCell>
								<TableCell style={{width:'10%'}}>업체명</TableCell>
								<TableCell style={{width:'20%'}}>작성일자</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{boardData.map((prop, key) => {
								return (
									<Row key={key} openNum={(e) => setOpenPage(e)}  openPWInput={(e) => setOpenPW(e)} userData={props.userData} row={prop} num={openPage} num2={openPW} onAlert={props.onAlert}/>
								);
							})}
						</TableBody>
						{(boardData.length > 0 ?
						<TableFooter>
							<TableRow>
								<TableCell colSpan={6} style={{paddingLeft:'35%'}}>
									<Pagination
										count={totalCount}
										page={page}
										onChange={handleChange}
										defaultPage={1}
										color="primary"
										size="small"
										showFirstButton
										showLastButton
										>
									</Pagination> 
								</TableCell> 
							</TableRow>
							<TableRow >
							{props.userData?(props.userData.klnet_id === "KLTEST01"||props.userData.role==='Y')?
							//테스트 이후 지울 예정
								<TableCell align="right" colSpan={8} style={{borderTop:'2px solid #06b3ba'}}>
									<Button style={{paddingBottom:'8px'}}color="info" onClick={()=> qnaWrite()}>Q&A 작성</Button>
								</TableCell>
							:
								<TableCell align="right" colSpan={8} style={{borderTop:'2px solid #06b3ba'}}>
									<Button style={{paddingBottom:'8px'}}color="info" onClick={()=> qnaWrite()}>Q&A 작성</Button>
								</TableCell>
							:<></>
							}
							</TableRow>
						</TableFooter>: null )}
					</Table>
				</CardBody>
			</Card>
			{/* <AlertMessage
			message={message}
			isOpen={openAlert}
			isClose={() => setOpenAlert(!openAlert)}
			// fontColor={font}   //선택사항
			alertColor={status === 'success' ? status : 'danger'} //선택사항  //primary, secondary, success, danger, warning, info, light, dark
			// timeOut={2000} //선택사항
			></AlertMessage> */}
		</React.Fragment>
    );
}