import React from "react";
import NewHome from "views/Landing/Home.js";

function HomeLayout_old (props) {
	require("assets/scss/fonts.scss");
	require("assets/scss/mui.scss");
	require("assets/scss/common.scss");
	require("assets/scss/style.scss");
	require("assets/scss/m.style.scss");
	require("assets/scss/main.scss");
	require("assets/scss/m.main.scss");
	// require( "assets/scss/material-dashboard-pro-react.scoped.scss");
	return (
		<NewHome {...props}/>
	)
}

export default HomeLayout_old;