import $ from 'jquery';
import axios from 'axios';

export const REQ_MSG = "필수 ";
export const EML_MSG = "이메일 형태가 아닙니다.";
export const UPP_MSG = "대문자로 입력하세요.";
export const SEND_MSG = "전송되었습니다.";
export const SAVE_MSG = "저장되었습니다.";
export const DEL_MSG = "삭제되었습니다.";
export const ERR_MSG = "오류가 발생했습니다.";
export const NEW_MSG = "등록되었습니다.";
export const EXCD_MSG = "Digit Exceeded Error";
export const NOTLOGIN_MSG = "로그인이 필요한 서비스 입니다.";
export const TOKEN_EXPIRED_MSG = "사용자 인증이 만료된 세션입니다. 다시 로그인하세요.";
export const NO_PERMISSION_MSG = "재로그인이 필요한 세션입니다. 다시 로그인하세요.";
export const FAIL_DOWNLOAD_MSG = '다운로드에 실패하였습니다. 관리자 문의바랍니다.';

/**
 * 부킹 SR 번호 직접 생성
 */
 export const NO_NULL_MSG = "생성할 번호를 직접 입력하세요.";
export const NO_CHECK_MSG = "특수문자 및 한글은 입력 불가합니다.";
export const NO_MAKE_MSG = "번호로 새로 만드시겠습니까?";
export const NO_DUP_CHECK_MSG = "중복체크를 눌러주세요.";
export const NO_BKG_RECIPIENT = "BOOKING 업체 정보가 없습니다. [내 업체 선택] 후 이용하세요.";
export const NO_SR_RECIPIENT = "SR 업체 정보가 없습니다. [내 업체 선택] 후 이용하세요.";

/**
 * InputValid 사용
 */
export const LEN_MSG = "입력가능:";
export const EMAIL_MSG = "이메일 형식이 아닙니다.";
export const TEL_MSG = "전화번호 형식이 아닙니다.";
export const FAX_MSG = "팩스번호 형식이 아닙니다.";
export const BIZ_NO_MSG = "올바른 형식이 아닙니다.";//사업자번호
export const NUM_MSG = "숫자만 입력 가능합니다.";
export const ENG_MSG = "영어만 입력 가능합니다.";
export const KOREAN_BAN_MSG = "한글은 입력 불가합니다.";
export const CCAM_BAN_MSG = "CCAM 금칙문자는 입력 불가합니다.";
export const ENG_NUM_MSG = "영어와 숫자만 입력 가능합니다.";
export const YYYYMMDD_MSG = "년(YYYY)-월(MM)-일(DD)(이)가 유효하지 않습니다.";
export const YYYYMMDDHHMM_MSG = "년(YYYY)-월(MM)-일(DD) 시(00):분(00)(이)가 유효하지 않습니다.";
export const YYYYMMDD2_MSG = "년(YYYY)월(MM)일(DD)(이)가 유효하지 않습니다.";
export const YYYYMMDDHHMM2_MSG = "년(YYYY)월(MM)일(DD)시(00)분(00)(이)가 유효하지 않습니다.";
export const DECIMAL_FRONT = "소수점은 ";
export const DECIMAL_END = "자리까지 입력 가능합니다.";

/**
 *  login err code
 */
export const E1000 = "문제가 발생하여 지금 로그인할 수 없습니다. 나중에 다시 시도하세요.";
export const E1001 = `아이디 혹은 비밀번호가 일치하지 않습니다.\n 입력한 내용을 다시 확인해 주세요.`;
export const E1002 = "입력한 계정은 존재하지 않습니다. 입력한 내용을 다시 확인해 주세요.";
export const E1005 = "로그인 정보를 읽을 수 없습니다. \n 서비스 담당자에게 문의하여 주십시요.";
export const E1007 = "해당 서비스는 현재 점검 중입니다. \n 서비스 이용에 불편을 드려 죄송합니다. \n 점검 시간 (2024년 1월 27일 18:00 ~ 21:00) ";

/**
 * Alert Message
 */
export const NO_USER_MSG="로그인 정보가 없습니다.";
export const NO_LINECODE_MSG="선사 정보가 없습니다. 스케줄을 통해 선택해 주세요";
export const DATE_MSG = "날짜를 다시 확인해주세요.";
export const DATE_CHECK_MSG = '이전 날짜로 조회할 수 없습니다';
export const OK_CHECK_MSG="조회가 완료되었습니다.";
export const FAIL_CHECK_MSG="조회 결과가 없습니다.";
export const LAST_PAGE_MSG="마지막 페이지입니다.";
export const EDI_ERROR_MSG="EDI 금칙문자가 적용되었습니다."
export const NOT_SERVE_CARRIER="서비스를 이용하지 않는 선사입니다. 선사에 문의바랍니다."

// EDI 금칙문자
//"~''@#\\$|^{}[]_!"
// export const EDI_ERROR_CHA = /[\x22\x24\x7e\x7b\x7d\x5b\x5d\x21\x23\x40\x5e\x60\x5c\x5f\x02\u00A0]/g;
// //"~''#\\$|^{}[]_!"
// export const EDI_ERROR_CHA_E = /[\x22\x24\x7e\x7b\x7d\x5b\x5d\x21\x23\x5e\x60\x5c\x5f\u00A0]/g;

// ^][{}`\\ ×，              '，'.charCodeAt(0).toString(16)
// export const EDI_ERROR_CHA = /[\x5e\x5d\x5b\x7b\x7d\x60\x5c\u00A0\xd7\uff0c]/g;
export const EDI_ERROR_CHA = /[^ \r\nㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9~!@#$%&*()_+:"<>?\-=;',.\/]/g;
export const CCAM_ERROR_CHA = /[_=>]/g;
export const KOREAN_CHECK = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
export const validationEmail =(text)=>{
    let type = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let email = text;
    if( email ) {
        let returnValue = type.test(email.toUpperCase());
        return returnValue;
    } else {
        return false;
    }
}
export const EDICharsetCheck =(text)=> {
    if(text) {
        if(text.search(EDI_ERROR_CHA) !== -1) {
            return false;
        }else {
            return true;
        }
    }else {
        return true;
    }
}

export const EDICheckText =(text)=> {
    if(text) {
        return "EDI 금칙문자가 적용되었습니다. " + (text.search(EDI_ERROR_CHA)+1) + "번째";
    }else {
        return "";
    }
    
}

export const validationNo =(text)=>{
    let type = /^[a-zA-Z0-9]*$/;
    if( text ) {
        let returnValue = type.test(text);
        return returnValue;
    } else {
        return false;
    }
}

export const validationHangle =(text)=>{
	let type=/[ㄱ-ㅎ|ㅏ-ㅣ|가-힝]/;
    if(text) {
    	let returnValue = type.test(text);
        return returnValue;
    } else {
        return false;
    }
}

export const validationHanSpc =(text)=>{
	let type=/[ㄱ-ㅎ|ㅏ-ㅣ|가-힝|~!@#$%^&*()_+|<>?:{}]/;
    if(text && !type.test(text)) {
        return true;
    } else {
        return false;
    }
}

export const validationDigit =(text,num)=>{
    if( text && text.length <= num ) {
        return true;
    } else {
        return false;
    }
}

export const getByte =(text)=>{
   return text.split('').map(s=>s.charCodeAt(0)).reduce((prev,c) => (prev +((c === 10)?2:((c>>7)?2:1))),0);
}


export const validationByteMaxLength = (text, maxLength)=> {
    // console.log(">>>>",text)
    if( text ) {
        let j = 0;
        let val = null;
        for( let i=0; i<text.length; i++ ) {
            val = escape(text.charAt(i)).length;
            if( val === 6 ) {
                j++;
            }
            j++;
        }
        return maxLength<=j;
    } else {
        return false;
    }
}

export const getByteLength = (text)=> {
    // console.log(">>>>",text)
    if( text ) {
        let j = 0;
        let val = null;
        for( let i=0; i<text.length; i++ ) {
            val = escape(text.charAt(i)).length;
            if( val === 6 ) {
                j++;
            }
            j++;
        }
        return j;
    } else {
        return 0;
    }
}


/**
 * InputValid 컴포넌트 사용
 * 한글 2byte 계산
 * @param {*} str 
 */
export function getByteB(str) {
    var byte = 0;
    if(str){
        for (var i=0; i<str.length; ++i) {
            // 기본 한글 2바이트 처리
            (str.charCodeAt(i) > 127) ? byte += 2 : byte++ ;
        }
    }
    return byte;
}

/**
 * InputValid 컴포넌트 사용
 * 최대길이(maxLength) 비교
 * @param {*} str 
 * @param {*} maxLength 
 */
export const validMaxLength =( str, maxLength)=> {
    let bytes = getByteB(str);
    // console.log( bytes );
    if( str ) {
        if( bytes > maxLength ) {
            return true;
        } else {
            return false;
        }
    }
}
 

/**
 * InputValid 컴포넌트 사용
 * 최소길이(minLength) 비교
 * @param {*} str 
 * @param {*} minLength 
 */
export const validMinLength =( str, minLength)=> {
    let bytes = getByteB(str);
    // console.log( bytes );
    if(minLength) {
        if( str ) {
            if( bytes < minLength ) {
                return true;
            } else {
                return false;
            }
        }
    }else {
        return false;
    }
}
/**
 * InputValid 컴포넌트 사용
 * 최소길이(minLength)에러문구
 * @param {*} minLength 
 */
export const MIN_ERROR_MSG = (minLength) => {
    if(minLength) {
        return `최소 입력 값은 ${minLength}자 입니다.` 
    }else {
        return "";
    }
}


/**
 * InputValid 컴포는터 사용
 * Emial 형식 체크
 * @param {*} str 
 */
export const validEmail =(str)=> {
    var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;//common공통화 \제외
    return regExp.test(str); // 형식에 맞는 경우 true 리턴 
}

/**
 * InputValid 컴포넌트 사용
 * @param {*} asValue 
 */
export const validCelluar =(asValue)=> {
    var regExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
    return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}

/**
 * InputValid 사용
 * 전화번호 또는 팩스번호 체크
 * @param {*} asValue 
 */
export const validTel =(asValue)=> {
    var regExp = /(?:\d{2}|\d{3})(?:-|)(?:\d{3}|\d{4})(?:-|)\d{4}$/;
    return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}

/**
 * InputValid 사용
 * 영문 대문자 및 숫자만 허용
 * @param {*} asValue 
 */
export const validEngNumber =(asValue)=> {
    var regExp = /^[A-Za-z0-9 ]*$/;
        return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}

/**
 * InputValid 사용
 * 숫자만 허용
 * @param {*} asValue 
 */
export const validNumber =(asValue)=> {
    var regExp = /^[0-9+ ]*$/;
    return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}

/**
 * InputValid 사용
 * 영어만 허용
 * @param {*} asValue 
 */
export const validEnglish =(asValue)=> {
    var regExp = /^[A-Za-z+]*$/;
    return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
}


/**
 * weight 사용
 * 소수점 허용
 * @param {*} asValue 
 */
 export const validDecimal =(asValue, length=3)=> {
    if(asValue.indexOf(',') !== -1 || asValue.indexOf('/') !== -1) {
        return false;
    }else {
        var regExp = new RegExp('^\\d*.?\\d{1,'+length+'}$');
        return regExp.test(asValue); // 형식에 맞는 경우 true 리턴
    }
}



/**
 * 비밀번호 유효성 검사 ( 영문,숫자 혼합 6~20)
 */
export const verifyPassword = (value) => {
    //var passwordRex = /^.*(?=.{6,20})(?=.*[0-9])(?=.*[a-zA-Z]).*$/; ( 영문,숫자 혼합 6~20)
    // var passwordRex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/; //(숫자/대문자/소문자/특수문자를 모두 포함 8자~) //기존 정규식

    
    var passwordRex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_~]).{8,12}$/; // 영문,숫자,특수문자(!@#$%^&*?_~) 조합, 8~12자리 입니다.
    return !passwordRex.test(value)?false:true;
}
/**
 * 스페이스바 검사
 */

export const checkSpace = (value) => {
    if(value.search(/\s/) !== -1) {
      return true;
    }else {
      return false;
    }
  }
 /**
 * ID 유효성 검사
 */ 
export const verifyId = (value)  => {
    var idRex = /^[a-zA-Z0-9]/gi;
  
    if(idRex.test(value) === true) {
      return true;
    }else {
      return false;
    }
  
  }
/**
 * 필수 체크 여부
 * SEND 할때 사용하세요
 */
export const fncValidation=()=>{
    let check = true;
    let feedback = $('.invalid-feedback');
    feedback.each(function(i, item){
        if( 'block' === $(item).css('display') &&
        'deny' !== $(item).attr('feedid') ) {
            check = false;
        }
        // break;
    });
    return check;
}
/**
 * 필수 체크 여부
 * material 사용하세요
 */
 export const fncValidationMui=()=>{
    let check = true;
    let feedback = $('.MuiFormHelperText-root');
    feedback.each(function(i, item){
        if('deny' !== $(item).attr('feedid') ) {
            check = false;
        }
        // break;
    });
    return check;
}

export const fncFeedIdInvalid =( text )=> {
    let feedback = $('.invalid-feedback');
    let check = false;
    feedback.each(function(i, item){
        if( 'block' === $(item).css('display')&&
            'deny' !== $(item).attr('feedid')) {
            if( text ===  $(item).attr("feedid") ) {
                check = true;
                return check;
            }
        }
    });
    return check;

}

/**
 * 필수 제외 자릿수만 체크
 * SAVE 할때 사용하세요
 */
export const fncValidationMaxLength =()=> {
    let check = true;
    let feedback = $('.invalid-feedback');
    feedback.each(function(i, item){
        // console.log(i, item)
        if( 'block' === $(item).css('display') &&
            'deny' !== $(item).attr('feedid') ) {
            
            //<div feedid="booking" class="invalid-feedback">필수 입력가능: (20/28)</div>
            // (20/28) 중 20 꺼내기
            let text = $(item).text();
            let maxNum = text.substring(
                text.lastIndexOf("(")+1,
                text.lastIndexOf("/"),
            )
            // (20/28) 중 28 꺼내기
            let curNum = text.substring(
                text.lastIndexOf("/")+1,
                text.lastIndexOf(")"),
            )
            // console.log(firstNum, curNum)

            if( parseInt(maxNum) <parseInt(curNum) ) {
                check = false;
                return check;
            }
        }
    });
    return check;
}

/**
 * 
 */
export const fncFeedIdInvalidMaxLength =( feedid )=> {
    let feedback = $('.invalid-feedback');
    let check = false;
    feedback.each(function(i, item){
        if( 'block' === $(item).css('display')&&
            'deny' !== $(item).attr('feedid')) {

            //<div feedid="booking" class="invalid-feedback">필수 입력가능: (20/28)</div>
            // (20/28) 중 20 꺼내기
            let text = $(item).text();
            let maxNum = text.substring(
                text.lastIndexOf("(")+1,
                text.lastIndexOf("/"),
            )
            // (20/28) 중 28 꺼내기
            let curNum = text.substring(
                text.lastIndexOf("/")+1,
                text.lastIndexOf(")"),
            )

            if( feedid ===  $(item).attr("feedid")
            && parseInt(maxNum) <parseInt(curNum) ) {
                check = true;
                return check;
            }
        }
    });
    return check;

}


export const fncWeidongDangerCheck =(line_code, start_port_code, end_port_code, vessel_name, callback)=> {
    axios.post(
        "/shipper/selectWeidongDangerCheck"
        ,{
            line_code: line_code,
            start_port_code: start_port_code,
            end_port_code: end_port_code,
            vessel_name: vessel_name
        }
        ,{}
    ).then(
        // props.onAlert("success", "정상 조회 되었습니다.")
    ).then(
        res => {
            const result =  res.data[0];
            console.log( result );
            if( result ) {
                if ("Y" === result.cargo_trans_yn && "Y" === result.vsl_cntr_yn) {
                    callback(true);
                } else {
                    callback(false);
                }
            } else {
                callback(false);
            }
            
        }   
    ).catch(
        error => {
            //
        }
    );
}
/**
 * 전화번호 포맷 return String
 */
export const TELFormatter = (num) => {
    if(num !== null) {
        let Num = num;
        if(!Num) return "";
        let formatNum = "";

        Num = num.replace(/\s/gi,"");

        try{
            formatNum = Num.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,'$1-$2-$3');
        }catch(e) {
            formatNum = Num;
        }
        return formatNum
    }else {
        return num;
    }
}
/**
 * 날짜포맷 '-'삽입
 */
export const YMDFormatter = (num) => {
    let Num = num;
    if(!Num) return "";
    let formatNum = "";

    Num = num.replace(/\s/gi,"");

    try{
        if(Num.length === 8) {
            formatNum = Num.replace(/(\d{4})(\d{2})(\d{2})/,'$1-$2-$3');
        }else if(Num.length ===12 ){
            formatNum = Num.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/,'$1-$2-$3 $4:$5');
        }else if(Num.length ===14 ){
            formatNum = Num.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,'$1-$2-$3 $4:$5:$6');
        }
    }catch(e) {
        formatNum = Num;
    }
    return formatNum
}

/**
 * 사람이름 포맷 ex) 홍길동-> 홍*동, 김구 -> 김*, 김아무개 -> 김**개
 */
export const NameReplace = (value) =>{
    if( value.length !== 0 && value!==null && value!==undefined) {
      if(value.length === 2) {
  
        return value.substr(0,1) + '*'
      }else if (value.length === 1) {
        return '*';
      }else {
        return value.substr(0,1) + '*' + value.substr(value.length-1, value.length);
      }
    } else {
      return null;
    }
  
}
/**
 * 글자수 표시 제한
 * param 1 : 원 문자
 * param 2 : 원 문자 길이 설정
 * param 3 : 대체 문자
 * 
 */
export const textLengthOverCut = (value,length,lastTxt) =>{
    if (value!==null && value!==undefined) {
        if( value.length !== 0) {
        if(length === "0" || length === null || length === 0) {
            length = 10;
        }
        if(lastTxt ==="" || lastTxt ===undefined || lastTxt === null) {
            lastTxt = "...";
        }
        if(value.length > length) {
            value= value.substr(0, length) + lastTxt;
        }
        return value;

        } else {
            return "";
        }
    }else {
        return "";
    }
  
}
export const koreanBan = (value) => {
    if(value) {
        if(value.search(KOREAN_CHECK) !== -1) {
            return false;
        }else {
            return true;
        }
    }else {
        return true;
    }
}

export const ccamBan =(text)=> {
    if(text) {
        if(text.search(CCAM_ERROR_CHA) !== -1) {
            return false;
        }else {
            return true;
        }
    }else {
        return true;
    }
}


export const checkBizNO = (bizNo) => {
    var bizValue = 0;
    var comStr = "13713713";

    for (var i = 0; i < 8; i++){
        bizValue=bizValue + (parseFloat(bizNo.substring(i, i + 1)) * parseFloat(comStr.substring(i, i + 1))) % 10;
    }

    var tempCom = parseFloat(bizNo.substring(8, 9)) * 5 + "0";
    var checkValue = parseFloat(tempCom.substring(0, 1)) + parseFloat(tempCom.substring(1, 2));
    var checkDigit = (10 - (bizValue + checkValue) % 10) % 10;
    if (Number(bizNo.substring(9, 10)) != checkDigit){
        return false;
    }else{
        return true;
    }
}

export const checkCompanyNumber = async ( val )=>{
    if(val){
       return await axios.post(
            "/api/checkCompanyNumber"
            ,{
                CNU:val
            }
        ).then(
            res => {
                const result =  res.data;
                if(result&&result.CRNumber ){
                    return false;
                }else{
                    return true; //유효하지 않음
                }
            }   
        ).catch(
            error => {
                console.log( error );
                return true;
            }
        );
    }else{
        return false
    }
  }