/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect,useState } from 'react';
import { Row, Col, FormGroup,Label,Button} from "reactstrap";
import InputValid from "components/CustomInput/InputValid.js";

export default function Notify(props) {
	const {loadFormData,type} = props;	
	const [notifyData,setNotifyData] = useState({});

	useEffect(() => {
		setNotifyData(loadFormData);
	},[loadFormData]);

	const onHandleReturnVal = (event,name) => {
		let list = {...notifyData, [name]:(event.target.value.toUpperCase())||null};
		setNotifyData(list);
	}
  
	const onPropsReturn = ()=> {
		props.propsData(notifyData);
	}
  
	const onCopyProps=()=>{
		var list = {c_noti_name1:loadFormData.noti_name1,c_noti_name2:loadFormData.noti_name2,c_noti_address1:loadFormData.noti_address1,
				    c_noti_address2:loadFormData.noti_address2,c_noti_address3:loadFormData.noti_address3,c_noti_address4:loadFormData.noti_address4,
				    c_noti_address5:loadFormData.noti_address5}
		props.mergeData(list);
		setNotifyData(list);
		props.onAlert("success","Notify 데이터가 복사 되었습니다.");
	}
  
  	return (
    	<>
			{(props.type ==="I")?
			<Row>
				<Col className="col-12 text-right pr-0">
					<Button color="default" type="button" className="btn-link pr-0 pt-0 pb-0" onClick={()=>onCopyProps()}>
						<span>Copy Notify</span>	
					</Button>
				</Col>
			</Row>
			:<Row>
				<Col xl="6" lg="6" md="12">
					<FormGroup className="mb-2">
						<Label className="mb-0">BookMark Name</Label>
						<InputValid 
							type="text"
							name="c_notify_bookmark_name"
							id="c_notify_bookmark_name"
							maxLength="35"
							value={notifyData.c_notify_bookmark_name?notifyData.c_notify_bookmark_name:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_notify_bookmark_name')}
							onBlur={onPropsReturn}
							validtype="text" 
							required={true}
							feedid="notify2"
						/>
					</FormGroup>
				</Col>
			</Row>}
    		<Row>
				<Col xl="5" lg="5" md="12">
					<FormGroup>
						<Label className="mb-0">상호1</Label>
						<InputValid 
							type="text"
							name="c_noti_name1"
							id="c_noti_name1"
							maxLength="35"
							value={notifyData.c_noti_name1?notifyData.c_noti_name1:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_name1')}
							onBlur={onPropsReturn}
							validtype="ccamban" 
							required={type==="B"?false:false}
							feedid="notify2"
						/>
					</FormGroup>
				</Col>
				<Col xl="5" lg="5" md="12">
					<FormGroup>
						<Label className="mb-0">상호2</Label>
						<InputValid 
							type="text"
							name="c_noti_name2"
							id="c_noti_name2"
							maxLength="35"
							value={notifyData.c_noti_name2?notifyData.c_noti_name2:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_name2')}
							onBlur={onPropsReturn}
							validtype="ccamban" 
							required={false}
							feedid="notify2"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0">담당자이름</Label>
						<InputValid 
							type="text"
							name="c_noti_user_name"
							id="c_noti_user_name"
							maxLength="17"
							value={notifyData.c_noti_user_name?notifyData.c_noti_user_name:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_user_name')}
							onBlur={onPropsReturn}
							validtype="engNumber"
							required={false}
							feedid="notify2"
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">주소1</Label>
						<InputValid 
							type="text"
							name="c_noti_address1"
							id="c_noti_address1"
							maxLength="35"
							value={notifyData.c_noti_address1?notifyData.c_noti_address1:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_address1')}
							onBlur={onPropsReturn}
							validtype="ccamban" 
							required={type==="B"?false:false}
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">주소2</Label>
						<InputValid 
							type="text"
							name="c_noti_address2"
							id="c_noti_address2"
							maxLength="35"
							value={notifyData.c_noti_address2?notifyData.c_noti_address2:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_address2')}
							onBlur={onPropsReturn}
							validtype="ccamban" 
							required={false}
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">주소3</Label>
						<InputValid 
							type="text"
							name="c_noti_address3"
							id="c_noti_address3"
							maxLength="35"
							value={notifyData.c_noti_address3?notifyData.c_noti_address3:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_address3')}
							onBlur={onPropsReturn}
							validtype="ccamban" 
							required={false}
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">주소4</Label>
						<InputValid 
							type="text"
							name="c_noti_address4"
							id="c_noti_address4"
							maxLength="35"
							value={notifyData.c_noti_address4?notifyData.c_noti_address4:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_address4')}
							onBlur={onPropsReturn}
							validtype="ccamban" 
							required={false}
						/>
					{/* <Input type="text" name="c_noti_address4" id="c_noti_address4" placeholder="" 
							value={notifyData.c_noti_address4} maxLength="50" onChange = {(event)=>onHandleReturnVal(event,'c_noti_address4')} onBlur={onPropsReturn}	
						/>*/}
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">주소5</Label>
						<InputValid 
							type="text"
							name="c_noti_address5"
							id="c_noti_address5"
							maxLength="35"
							value={notifyData.c_noti_address5?notifyData.c_noti_address5:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_address5')}
							onBlur={onPropsReturn}
							validtype="ccamban" 
							required={false}
						/>
					</FormGroup>
				</Col>
			</Row>
			<Row>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0">연락처</Label>
						<InputValid 
							type="text"
							name="c_noti_user_tel"
							id="c_noti_user_tel"
							maxLength="35"
							value={notifyData.c_noti_user_tel?notifyData.c_noti_user_tel:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_user_tel')}
							onBlur={onPropsReturn}
							validtype="engNumber" 
							required={false}
							feedid="notify2"
						/>
					</FormGroup>
				</Col> 
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0">USCI NO.</Label>
						<InputValid 
							type="text"
							name="c_noti_code"
							id="c_noti_code"
							maxLength="18"
							value={notifyData.c_noti_code?notifyData.c_noti_code:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_code')}
							onBlur={onPropsReturn}
							validtype="engNumber" 
							required={false}
							feedid="notify2"
						/>
					</FormGroup>
				</Col>
				<Col xl="12" lg="12" md="12">
					<FormGroup>
						<Label className="mb-0">국가코드</Label>
						<InputValid 
							type="text"
							name="c_noti_country_code"
							id="c_noti_country_code"
							maxLength="2"
							value={notifyData.c_noti_country_code?notifyData.c_noti_country_code:''}
							onChange={(e)=>onHandleReturnVal(e, 'c_noti_country_code')}
							onBlur={onPropsReturn}
							validtype="engNumber" 
							required={false}
							feedid="notify2"
						/>
					</FormGroup>
				</Col>
			</Row>
        </>
    );
}