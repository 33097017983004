import React from "react";
import {Table,
	    TableBody,
	    TableCell,
	    TableRow,
	    } from "@material-ui/core";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CardHeader from "muiComponents/Card/CardHeader.js";
import {VerifiedUserOutlined,LockOpenOutlined,Close} from '@material-ui/icons';

// core components
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import Button from "muiComponents/CustomButtons/Button.js";
import CustomInput from "muiComponents/CustomInput/CustomOutInput.js";
import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";
import axios from 'axios';

const useStyles = makeStyles(styles);

export default function FindInfoPage(props) {
	const {alertMessage} = props;

	const classes = useStyles();

	const [uid,setUid] = React.useState("");
	const [passwordCheck,setPasswordCheck] = React.useState();
	const [repasswordCheck,setRepasswordCheck] = React.useState();
	const [userData,setUserData] = React.useState([]);
	const [findFlag,setFindFlag] = React.useState('');
	const [password,setPassword] = React.useState();
	const [repassword,setRepassword] = React.useState();

	function change(value, name) {
		switch (name) {
			case "id":
				setUid(value);
				break;
			case "password":
				setPassword(value);
				if (verifyPassword(value))  setPasswordCheck(false); else setPasswordCheck(true);
				if (repassword) {
					if(value === repassword) {
						setPasswordCheck(false);
						setRepasswordCheck(false);
					} else {
						setPasswordCheck(true);
						setRepasswordCheck(true);
					}
				}	  
				break;
			case "passwordConfirm":
				setRepassword(value);
				if(value) {
					if (value === password ) {
						setRepasswordCheck(false);
					} else {
						setRepasswordCheck(true);
					}
				}
				break;
			default:
				break;
		}
	}

	// 비밀번호 유효성 검사 ( 영문,숫자 혼합 8~20)
	function verifyPassword(value) {
		var passwordRex = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/; //(숫자/대문자/특수문자를 모두 포함 8자~)
		return !passwordRex.test(value)?false:true;
	}

	const Serti = (flagType) => {
		/**<--    테스트 */
		// document.form1.elements.flag.value = "ID"
		// if (document.form1.elements.flag.value === "ID") {
		// 	certifyFindInfo('테스트','01029380030',"ID");
		// }
    	/**테스트  --> */

    	/****반영시 주석풀기  */
    	return axios({url: '/auth/sertify', method: 'POST', data: {site:'booking'}})
        .then(res => {
            // console.log(res.data)
            var form1 = document.form1;
            window.open("", "auth_popup", "width=430,height=640,scrollbar=yes");
            form1.target = "auth_popup";
            form1.tc.value = "kcb.oknm.online.safehscert.popup.cmd.P931_CertChoiceCmd";
            form1.action = "https://safe.ok-name.co.kr/CommonSvl";
            form1.method = "post";
            form1.elements.flag.value = flagType=="PW"?"PW":"ID";
            form1.cp_cd.value = res.data.CP_CD;
            form1.mdl_tkn.value = res.data.MDL_TKN;
            form1.submit();
        }).catch(err => {
            alertMessage(err);
        });
		/****************/ 
	}

	window.event_popup = function() {
		if(document.kcbResultForm.RSLT_CD.value === "B000"){
			if(document.form1.elements.flag.value === "ID") {
				certifyFindInfo(document.kcbResultForm.RSLT_NAME.value,document.kcbResultForm.TEL_NO.value,"ID");
			} else {
				certifyFindInfo(document.kcbResultForm.RSLT_NAME.value,document.kcbResultForm.TEL_NO.value,"PW", uid);
			}
		} else {
			props.onAlert('error',"document.kcbResultForm.RSLT_MSG.value \n error: 사용자 본인 인증에 실패 하였습니다. 다시 시도해주세요.");
		}
	}
	const certifyFindInfo = (userName, phoneNum, findFlag, pId) => {
		let param = {name:userName, phone:phoneNum};
		if(pId) param["id"]=pId;
		axios ({
			url:'/auth/userfinder',
			method:'POST',
			data: param
		}).then(res=>{
			let resArry = res.data;
			if(Array.isArray(resArry)&&resArry.length>0){
				setFindFlag(findFlag);
				setUserData(resArry);
			}else{
				props.onAlert('error',"일치하는 회원 정보가 존재하지 않습니다.");
				return false;
			}
		}).catch(err => {
			if(err.response !== undefined) {
				setUserData([]);
				if(err.response.status === 500) {
					props.onAlert('error',"[ERROR]"+err.response.data);
				} else if(err.response.status === 404) {
					props.onAlert('error',"입력된 회원정보가 존재하지 않습니다. 회원가입 후 이용해주세요.");
				}
			}
		});
	}  

  const cancelHandler = () => {
	props.toggle()
  }
  
  const updatePwHandler = () => {
	  if(!password) {
			 setPasswordCheck(true);
			 props.onAlert('error','비밀번호는 필수 입력 값입니다.');
			 return false;	
		 } else if (passwordCheck){
			 setPasswordCheck(true);
			 props.onAlert('error','올바른 비밀번호를 입력해주세요.');
			 return false;
		 } else if (!repassword) {
			 setRepasswordCheck(true);
			 props.onAlert('error','비밀번호 확인은 필수 입니다.');
			 return false;
		 } else if (repasswordCheck){
			 setRepasswordCheck(true);
			 props.onAlert('error','비밀번호 확인은 필수 입니다.');
			 return false;
		 }

	  axios ({
			url:'/auth/userupdate',
			method:'POST',
			data: {uno:uid, pw:password}
		}).then(res=>{
			props.onAlert('success',"비밀번호가 변경 되었습니다. 로그인 후 사용 가능합니다.");
			setTimeout(()=>{
				props.toggle();
            },2000);
		}).catch(err => {
			if(err.response !== undefined) {
				if(err.response.status === 500) {
					props.onAlert('error',"[ERROR]"+err.response.data,);
				} else if(err.response.status === 404) {
					props.onAlert('error',"[ERROR]입력된 회원정보가 존재하지 않습니다. 회원가입후 이용해주세요.",);
				}
			}
		});
	}
	
	return (
		<div className={classes.container}>
			<form name="form1">
				<input type="hidden" name="tc" />
				<input type="hidden" name="flag"/>	
				<input type="hidden" name="cp_cd" />	
				<input type="hidden" name="mdl_tkn" />	
				<input type="hidden" name="target_id"/>	
			</form>
			<form name="kcbResultForm" method="post">
				<input type="hidden" name="RSLT_CD"/>
				<input type="hidden" name="RSLT_MSG"/>
				<input type="hidden" name="TEL_NO"/>
				<input type="hidden" name="RSLT_NAME"/>
				<input type="hidden" name="RSLT_BIRTHDAY" />
				<input type="hidden" name="RSLT_SEX_CD" />
			</form>  		
			<GridContainer justifyContent="center" style={{with:'500px'}}>	
				<Card className={classes.cardSignup} style={{margin:'0',paddingTop:'0',paddingBottom:'0'}}>
					<CardBody style={{paddingTop:'15px',paddingBottom:'15px'}}>            
						{findFlag==="ID"?
							<div>
								<GridItem xs={12}>
									<Card>
										<CardHeader style={{paddingTop:'10px',paddingBottom:'0'}}>
											<h5 style={{fontSize:'1.05em',fontWeight:'bold',marginBottom:'0'}}> 아이디 찾기 결과</h5>
										</CardHeader>
										<CardBody >
											<Table style={{marginTop:'10px',marginBottom:'10px'}}>
												<TableBody>
												{Object.keys(userData).length>0?
													userData.map((x,ind)=>
													<TableRow key={ind}>
														<TableCell style={{textAlignLast:'start', fontWeight:'bold'}}>{x.ORIGIN_LOCAL_ID}</TableCell>
														<TableCell style={{textAlignLast:'end'}}>가입 : {x.INSERT_DATE}</TableCell>
														<TableCell>
															<font color="red" onClick={()=>{setUid(x.ORIGIN_LOCAL_ID); setFindFlag("PW")}} style ={{cursor:'pointer'}}>
															[비밀번호 변경]</font>
														</TableCell>
													</TableRow>
												):(<TableRow>
														<TableCell colSpan="3"><font color="red">입력된 회원정보가 존재하지 않습니다. 회원가입후 이용해주세요.</font></TableCell>
													</TableRow>)}
												</TableBody>
											</Table>
										</CardBody>
									</Card> 	
								</GridItem>
								<div style={{textAlign:'center'}}>
									<Button	color="info" onClick={cancelHandler}>확인</Button>
								</div>		
							</div>
						:findFlag==="settingId"?
							<div>
								<GridItem xs={12}>
									<Card>
										<CardHeader style={{paddingTop:'10px',paddingBottom:'0'}}>
											<h5 style={{fontSize:'1.05em',fontWeight:'bold',marginBottom:'0',textAlign:'center'}}> 비밀번호를 찾고자하는 아이디를 입력해주세요.</h5>
										</CardHeader>
										<CardBody >
											<CustomInput
												labelText={
													<span>
														<font size="2">아이디</font>
													</span>
												}
												id="id"
												formControlProps={{fullWidth: true, variant:'outlined',size:'small', style:{marginTop:'15px',marginBottom:'15px'}}}
												inputProps={{	
													onChange: e=> change(e.target.value.toUpperCase(), "id"),
													labelWidth:110,
													value:uid
												}}   
											/>
										</CardBody>
									</Card> 	
								</GridItem>
								<div style={{textAlign:'center'}}>
									<Button	color="info" onClick={()=>Serti("Pw")}>확인</Button>
								</div>		
							</div>
						:findFlag==="PW"?
							<div>
								<div style={{textAlignLast:'center'}}><LockOpenOutlined style={{marginTop:'20px',marginBottom:'10px',width:'64px',height:'64px'}}/></div>
								<div style={{textAlignLast:'center'}}>
								<font size="3" style={{fontWeight:'bold'}}>신규 비밀번호를 입력해 주세요.</font><br/><font size="2" style={{color:'silver'}}>비밀번호는 영문,숫자,특수문자등 2종류 이상 문자를 조합하여<br/> 최소 8~15자 입력해주세요.</font></div>
								<GridItem xs={12} sm={12}>
									<CustomInput
										labelText={
											<span>
												<font size="2">아이디</font>
											</span>
										}
										id="id"
										formControlProps={{fullWidth: true, variant:'outlined',size:'small', style:{marginTop:'15px',marginBottom:'15px'}}}
										inputProps={{
											disabled:true,
											labelWidth:110,
											value:	userData.length>0
													?userData.find(x=>x.ORIGIN_LOCAL_ID=uid)&&userData.find(x=>x.ORIGIN_LOCAL_ID=uid)["ORIGIN_LOCAL_ID"]
													:"ERROR"
										}}   
									/>
								</GridItem>
								<GridItem xs={12} sm={12}>
									<CustomInput
									labelText={
										<span>
										<font size="2">비밀번호</font><small>(required)</small>
										</span>
									}
									id="password"
									labelProps={{style:{top:'0'}}}
									formControlProps={{
										fullWidth: true, variant:'outlined',size:'small',error:passwordCheck, style:{marginTop:'3px'}
									}}
									helperText="8자 이상의 숫자/영문(대문자)/특수문자를 포함해야 합니다."
									inputProps={{
										onChange: event => change(event.target.value.toUpperCase(), "password"),
										type: "password",
										autoComplete: "off",
										labelWidth:130
									}}
									/>
									<CustomInput
									labelText={
										<span>
										<font size="2">비밀번호 확인</font> <small>(required)</small>
										</span>
									}
									id="passwordConfirm"
									labelProps={{style:{top:'0'}}}
									formControlProps={{
										fullWidth: true,variant:'outlined',size:'small',error:repasswordCheck, style:{marginTop:'3px'}
									}}
									helperText={repasswordCheck?"비밀번호가 일치하지 않습니다.":" "}
									inputProps={{
										onBlur: event => change(event.target.value.toUpperCase(), "passwordConfirm"),
										type: "password",
										autoComplete: "off",
										labelWidth:160
									}}
									/> 
								</GridItem>
								<div style={{textAlign:'center'}}>
									<Button color="info" onClick={updatePwHandler} >변경</Button>
									<Button color="info" onClick={cancelHandler} >취소</Button>
								</div>
							</div>
							:
							<div style={{height: '250px',display: 'flex',	flexDirection: 'column',justifyContent: 'center'}}>		
								<div style={{alignSelf:'end', cursor:'pointer'}} ><Close onClick={() => props.toggle()} /></div>
								<div style={{textAlignLast:'center'}}><VerifiedUserOutlined style={{marginTop:'20px',marginBottom:'10px',width:'64px',height:'64px'}}/></div>
								<div style={{textAlignLast:'center'}}>
									<font size="3" style={{fontWeight:'bold'}}>본인명의 휴대폰으로 인증</font><br/><font size="2" style={{color:'silver'}}>회원님의 명의로 등록된 휴대폰으로 가입여부 및 본인여부를 확인합니다.</font></div>
								<div style={{textAlignLast:'center', marginTop: '20px'    , width: '100%'  ,  display: 'flex' ,   justifyContent: 'center',    height: '55px'}}>
									<Button color="info" onClick={Serti}  style={{marginTop:'5px',marginBottom:'5px',marginRight: '20px',width: '35%'}}>아이디 찾기</Button>
									<Button color="info" onClick={()=>{setFindFlag("settingId")}}  style={{marginTop:'5px',marginBottom:'5px',width: '35%'}}>패스워드 찾기</Button>
								</div>
							</div>
						}
					</CardBody>
				</Card>
			</GridContainer>
		</div>
	);
}
