import React,{useState,useRef, useEffect} from 'react';
import cn from 'classnames';
import { Link, useHistory} from "react-router-dom";
// import ReactDatetime from "react-datetime";
import Moment from 'moment';
import Style from './main.module.css'
// import {CustomDatePicker} from 'components/CustomInput/reactstrap/CustomInput';
// import 'assets/css/paper-kit.css'
import CalendarBox from "muiComponents/CustomInput/CustomCalendar.js";
import axios from 'axios';
// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.css'
import * as validation from 'components/common/validation.js'
import DatePickersCustom from 'muiComponents/CustomInput/DatePickersCustom';
import {Tooltip,CircularProgress} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";

const TooltipCustom = withStyles((theme)=>({
    tooltip:{
        // color:'red',
        // fontSize:12
    },
    tooltipPlacementTop:{
        margin:'5px 0'
        // margin:'0'
    }
}))(Tooltip)
const BusinessStatus = (props)=>{
    let history = useHistory();
    const {userData,onAlert} =props;
    const [loadingM, setLoadingM] = useState(false);
    const [loadingW, setLoadingW] = useState(false);
    const [toolOpen, setToolOpen] = useState(false);
    const [manifestDate, setManifestDate]=useState({from_date: Moment(new Date()) , to_date: Moment(new Date()).add(1,'days')})
    const [manifestMenu,setManifestMenu]=useState(
        [{type:'EXP',name:'수출적하목록', key:'ETD', active:true}
        ,{type:'IMP',name:'수입적하목록', key:'ETA', active:false}
    ]
    );
    const [manifestData,setManifestData]=useState(
        {type:'EXP', MBL_NOT_EXISTS: 0,  NOT_EQUAL: 0, EXPECT_ERROR:  0, CUS_ERROR: 0, APPRV_CNT: 0}
    )
    const [workTabDate, setWorkTabDate]=useState({from_date: Moment(new Date()) , to_date:  Moment(new Date()).add(6,'days')})
    const [workTabMenu,setWorkTabMenu]=useState(
        [{type:'EXP',name:'수출 업무 현황', key:'ETD', active:true
                , item : [{name : '부킹 확정', path : '/svc/bookinglist', data:['bkg_complete','bkg_total']}
                        , {name : 'S/R 제출', path : '/svc/srlist', data:['sr_complete', 'sr_total']}
                        , {name : 'B/L 확정', path : '/svc/bllist', data:['mbl_complete', 'mbl_total']}] }
        ,{type:'IMP',name:'수입 업무 현황', key:'ETA', active:false
                , item : [{name : 'D/O 발급', path: {prodNo :158, devNo:158} , data:['DO_COMPLETE','DO_TOTAL']}
                        , {name : 'DEM 초과', path : '/svc/demdetImport' , data:['OVER_DEM'] ,  state: {key: 'DEM'} }
                        , {name : 'DET 초과', path : '/svc/demdetImport' , data:['OVER_DET'] ,  state: {key: 'DET'} }]}
        ]
    );
    
    const [workTabData,setWorkTabData]=useState({})
    // const [workTabData,setWorkTabData]=useState(
    //     [{type:'EXP',  bkg_complete: 22, bkg_total: 1,  sr_complete: 44, sr_total:  33, mbl_complete: 55, mbl_total: 123}
    //     ,{type:'IMP',  DO_COM: 0, DO_TOT: 0, OVER_DEM_COM:77, OVER_DEM_TOT: 0, OVER_DET_COM:  0 ,OVER_DET_TOT:1233}]
    // )

    useEffect( ()=>{
        getManifestData(manifestMenu.find(x=>x.active).type)
    },[manifestMenu,userData])
    useEffect( ()=>{
        getWorkTabData(workTabMenu.find(x=>x.active).type)
    },[workTabMenu,userData])
    // useEffect(()=>{
    //     manifestDate.from_date > manifestDate.to_date 
    //     ||workTabDate.from_date > workTabDate.to_date

    // },[manifestDate,workTabDate])

    const onChangeDateHandler =(arry, menu)=>{
        // if (bool){alertMessage(validation.DATE_MSG, 'error'); return false}
        menu=='manifestDate'&& setManifestDate({...manifestDate,...arry})
        menu=='workTabDate' && setWorkTabDate({...workTabDate,...arry}) 
    }
    const onActiveHandler =(arry)=>{
        const data = arry.map((e)=>({...e, active:!e.active}))
        return data;
        // setManifestData(arry.map((e)=>e.active=!e.active))
    }

    const getManifestData=(type)=>{
        if(userData){
            if(Moment(manifestDate.to_date).diff(Moment(manifestDate.from_date),'days')>6){
                onAlert('error','검색기간은 최대 7일이내입니다');
                return false;
            }
            if(manifestDate.from_date>manifestDate.to_date){
                onAlert('error',validation.DATE_MSG); return false
            }
            setLoadingM(true)
            axios.post("/shipper/getManifestData"
            ,{klnet_id:userData.klnet_id
            , from_date:Moment(manifestDate.from_date).format('YYYYMMDD')
            , to_date :Moment(manifestDate.to_date).format('YYYYMMDD')
            , type: type})
            .then(res => { 
                // console.log(res.data)
                res.data.msg
                ? onAlert('error', res.data.msg)
                :setManifestData({type:type,...res.data});
                setLoadingM(false)
            }).catch(
                error => {
                    setManifestData({...manifestData, MBL_NOT_EXISTS: 0,  NOT_EQUAL: 0, EXPECT_ERROR:  0, CUS_ERROR: 0, APPRV_CNT: 0});
                    setLoadingM(false)
                }
                )
             }else{
            setManifestData({...manifestData, MBL_NOT_EXISTS: 0,  NOT_EQUAL: 0, EXPECT_ERROR:  0, CUS_ERROR: 0, APPRV_CNT: 0});
            setLoadingM(false)
        }
    }
    const getWorkTabData=(type)=>{
        let init_data= {bkg_complete: 0, bkg_total: 0,  sr_complete: 0, sr_total:  0, mbl_complete: 0, mbl_total: 0
                        ,  DO_COMPLETE: 0, DO_TOTAL: 0, OVER_DEM:0, OVER_DET:0}
                    
        if(userData){
            if(Moment(workTabDate.to_date).diff(Moment(workTabDate.from_date),'days')>6){
                onAlert('error', '검색기간은 최대 7일이내입니다');
                return false;
            }
            if(workTabDate.from_date>workTabDate.to_date){
                onAlert('error',validation.DATE_MSG); return false
            }
            setLoadingW(true)
            axios.post("/shipper/getWorkTabData"
            ,{klnet_id:userData.klnet_id
            , from_date:Moment(workTabDate.from_date).format('YYYYMMDD')
            , to_date :Moment(workTabDate.to_date).format('YYYYMMDD')
            , type: type})
            .then(res => { 
                // console.log({...init_data,...res.data})
                res.data.msg
                ? onAlert('error',res.data.msg, )
                : setWorkTabData({...init_data,...res.data});setLoadingW(false);
            }).catch(
                error => {
                    setWorkTabData(init_data);
                    setLoadingW(false);
                    }
            )
        }else{
            setWorkTabData(init_data);
            setLoadingW(false)
        }
    }
    const goEService=()=>{
        const serviceNum = manifestData.type=='EXP'?'214'
                            :manifestData.type=='IMP'?'120':''
        props.goPlism({linkParam:{prodNo : serviceNum, devNo:serviceNum}})
    }
    const goPage=(pathItem,param)=>{
        // console.log(pathItem, param)
        pathItem
        ?pathItem['prodNo']
            ?props.goPlism({linkParam:pathItem})
            :history.push({pathname:pathItem,
                            state:{...param,'start_date':Moment(workTabDate.from_date).format('yyyy-MM-DD')
                                    ,'end_date':Moment(workTabDate.to_date).format('yyyy-MM-DD')}})
        :onAlert('error','준비 중')
    }
    
    return(
    <>      
        <div className={Style.manifestItemWrap}>
            <ul className={Style.tabmenuListWrap}>
            {manifestMenu.map((val,i,arry)=>
                (<li key={i}><div className={val.active?Style.on:''} 
                    onClick={()=>!val.active&&setManifestMenu(onActiveHandler(arry))}>{val.name}</div>
                </li>)
            )}
            </ul>
            <div className={cn(Style.tabarea, Style.on)}>{/*<!-- 수출적하목록-->*/}
                <div className={Style.rangeItemWrap}>
                    <div className={Style.key}>{manifestMenu.find(x=>x.active).key}:</div>
                    <div className={Style.dateItemWrap}>
                        <DatePickersCustom 
                                customInputStyle={Style.inp}
                                variant="inline"
                                format="yyyy-MM-dd"
                                setValue={manifestDate.from_date}
                                autoOk={true}
                                onChangeValue={newDate => {
                                    onChangeDateHandler({from_date: newDate},'manifestDate')
                                }}
                                
                        />
                        <span className={Style.e01}>-</span>
                        <DatePickersCustom 
                                customInputStyle={Style.inp}
                                variant="inline"
                                format="yyyy-MM-dd"
                                setValue={manifestDate.to_date}
                                autoOk={true}
                                onChangeValue={newDate => {
                                    onChangeDateHandler({to_date: newDate},'manifestDate')
                                    // setManifestDate({...manifestDate, to_date: newDate})}
                                }}
                        />
                        {/* <input type="text" className={Style.inp}/> */}
                        {!loadingM?
                        <div className={Style.searchBtn} 
                            onClick={()=>{
                                userData?getManifestData(manifestMenu.find(x=>x.active).type)
                                :onAlert('error','로그인이 필요한 서비스입니다.');
                            }}
                        >
                        <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-search.png`} /></div>
                        :<div className={Style.searchBtnLoading} ><CircularProgress size={20} /></div>
                        }
                    </div>
                </div>
                <div className={Style.cntItemWrap}>
                    <ul>
                        {/* {(!loadingM?<> */}
                            <li onClick={()=>{goEService()}}>  
                                <div className={Style.cnt}>{manifestData.MBL_NOT_EXISTS>0?manifestData.MBL_NOT_EXISTS:'-'}</div>
                                <div className={Style.txt}>MBL 미존재</div>
                            </li>
                            <li onClick={()=>{goEService()}}>  
                                <div className={Style.cnt}>{manifestData.NOT_EQUAL>0?manifestData.NOT_EQUAL:'-'}</div>
                                <div className={Style.txt}>불일치</div>
                            </li>
                            <li onClick={()=>{goEService()}}>  
                                <div className={Style.cnt}>{manifestData.EXPECT_ERROR>0?manifestData.EXPECT_ERROR:'-'}</div>
                                <div className={Style.txt}>예상 오류</div>
                            </li>
                            <li onClick={()=>{goEService()}}>  
                                <div className={Style.cnt}>{manifestData.CUS_ERROR>0?manifestData.CUS_ERROR:'-'}</div>
                                <div className={Style.txt}>세관 오류</div>
                            </li>
                            <li onClick={()=>{goEService()}}>  
                                <div className={Style.cnt}>{manifestData.APPRV_CNT>0?manifestData.APPRV_CNT:'-'}</div>
                                <div className={Style.txt}>승인</div>
                            </li>
                            {/* </>
                        :<CircularProgress />)} */}
                    </ul>
                </div>
            </div>
        </div>

        <div className={Style.workItemWrap}>
            <ul className={Style.tabmenuListWrap}>
                {workTabMenu.map((val,i,arry)=>
                    (<li key={i}><div className={val.active?Style.on:''} 
                        onClick={()=>!val.active&&setWorkTabMenu(onActiveHandler(arry))}>{val.name}</div>
                    </li>)
                )}
            </ul>
            <div className={cn(Style.tabarea, Style.on)}>{/*<!-- 수입 업무 현황 -->*/}
                <div className={Style.rangeItemWrap}>
                    <div className={Style.key}>{workTabMenu.find(x=>x.active).key}:</div>
                    <div className={Style.dateItemWrap}>
                        <DatePickersCustom 
                                customInputStyle={Style.inp}
                                variant="inline"
                                format="yyyy-MM-dd"
                                setValue={workTabDate.from_date}
                                autoOk={true}
                                onChangeValue={newDate =>  onChangeDateHandler({from_date: newDate},'workTabDate')}
                                // onChangeValue={newDate => setWorkTabDate({...workTabDate, from_date: newDate})}
                        />
                        <span className={Style.e01}>-</span>
                        <DatePickersCustom 
                                customInputStyle={Style.inp}
                                variant="inline"
                                format="yyyy-MM-dd"
                                setValue={workTabDate.to_date}
                                autoOk={true}
                                onChangeValue={newDate => onChangeDateHandler({to_date: newDate},'workTabDate')}
                        />
                        {!loadingW?
                        <div className={Style.searchBtn} 
                             onClick={()=>{
                                userData?getWorkTabData(workTabMenu.find(x=>x.active).type)
                                :onAlert('error','로그인이 필요한 서비스입니다.');}}>
                            <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-search.png`} />
                        </div>
                        :<div className={Style.searchBtnLoading} ><CircularProgress size={20} /> </div>
                        }  
                    </div>
                </div>
                <div className={Style.cntItemWrap}>
                    <ul>
                    {/* {!loadingW?<></> */}
                    {workTabMenu.find(x=>x.active).item.map((val, ind,arry)=>{
                       
                       return (
                        <li className={Style.on} key ={ind} 
                            onClick={()=>goPage(val.path||null , val.state||{})}>
                            <div className={workTabMenu.find(x=>x.active).type =='IMP'?Style.cntI : Style.cntE}>
                            { val.data.length>1&&workTabData[val.data[1]]>0
                                ?val.name =='D/O 발급'
                                    ?<TooltipCustom  open = { true} title={`승인 건 / 요청 건`} placement='top'>
                                    <p>{workTabData[val.data[0]]} / {workTabData[val.data[1]]}</p>
                                    </TooltipCustom>
                                    : `${workTabData[val.data[0]]} / ${workTabData[val.data[1]]}`
                                : (val.data.length==1&&workTabData[val.data[0]]>0)
                                    ? workTabData[val.data[0]]
                                    :'-'
                            }
                            </div>
                            <div className={Style.txt}>{val.name}</div>
                        </li>)}
                    )}
                    {/* :<CircularProgress />}           */}
                        {/* <li className={Style.on}>
                            <div className={Style.cnt}>10/19</div>
                            <div className={Style.txt}>D/O 발급</div>
                        </li>
                        <li>
                            <div className={Style.cnt}>10/19</div>
                            <div className={Style.txt}>DEM 초과</div>
                        </li>
                        <li>
                            <div className={Style.cnt}>6/10</div>
                            <div className={Style.txt}>DET 초과</div>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    </>)
}

export default BusinessStatus
