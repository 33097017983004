import React,{ useState, useEffect, useMemo ,useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import CardBody from "muiComponents/Card/CardBody.js";
import {
	Collapse,
	Card,
	CardContent,
	TextField,
	TableFooter,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Grid,
	Divider,
	Container,
	Avatar,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Input
} from "@material-ui/core";

import Button from "muiComponents/CustomButtons/Button.js";

import {
	Chat as Chat,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	ReplySharp,
	Search as SearchIcon
}from "@material-ui/icons";
import axios from 'axios';
import Filesaver from 'file-saver';
import {yellow, red} from '@material-ui/core/colors';
import ReactQuill from 'react-quill';
import { useHistory } from "react-router-dom";
import "assets/css/quillEditer.css";

export default function QnADetail(props) {
	const history = useHistory();
	const [boardinfo,setBoardinfo] = useState({});
	const [content, setContent] = useState([]);
	const { userData} = props;
	useEffect(()=>{
		if(props.location.NTT_ID){
			//console.log (props.location);
			setBoardinfo(props.location);
		}else{
			goFAQ()
		}
	},[props])

	useEffect(() => {
		if(props.location.NTT_ID){
			//내용 조회
			axios.post("/api/selectFAQDetail",{ ntt_id:props.location.NTT_ID ,bbs_id:props.location.BBS_ID,reply_lc:props.location.NTT_ID})
			.then(res => {
				setContent(res.data[0]?res.data[0].NTT_CN:'');
			})

			//조회수
			axios.post("/api/updateInqireCo",{ bbs_id:props.location.BBS_ID,ntt_id:props.location.NTT_ID})
			.then(res => { 
				//console.log(res);
			})
		}
    },[])

	const goFAQ = () => {
		history.push({
			pathname:"/svc/faq",
		})
	}
	
	const atchFileId_onclick = function() {
		let fileName = boardinfo.ATCH_FILE_ID;
		const localCheck =window.location.href.split("/")[2];
		if(localCheck.includes('localhost')||localCheck.includes('dev')){
			let fileUrl = "https://testeservice.logisview.com/file_download/";
			let url = fileUrl + fileName;
			window.open(url);
		}else{
			let fileUrl = "https://eservice.logisview.com/file_download/";
			let url = fileUrl + fileName;
			window.open(url);
		}
	};
	
	return (
	  	<React.Fragment>
			<Card className="m-4" >
				<CardContent>
					<h4 style={{textAlignLast:'left',color:'black'}}><Chat style={{color:'#00acc1'}}></Chat> F A Q 상세내용</h4>
				</CardContent>
				<CardBody >
					<TableContainer style={{overflow:'auto'}}>
					<Table style={{maxWidth:'100%',tableLayout:'fixed',borderTop:'2px solid #06b3ba'}}>
						<TableBody>
							<TableRow>
								<TableCell style={{backgroundColor:'#f2fefd'}}>제목</TableCell>
								<TableCell colSpan={4} >{boardinfo.NTT_SJ}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell style={{backgroundColor:'#f2fefd'}}>첨부파일</TableCell>
								<TableCell colSpan={2} >
								{boardinfo.ATCH_FILE_ID?
									<Button onClick={(e) => atchFileId_onclick()}>
										{boardinfo.ATCH_FILE_ID}
									</Button>
									:<></>
								}</TableCell>
								<TableCell style={{backgroundColor:'#f2fefd'}}>조회수</TableCell>
								<TableCell  >{boardinfo.INQIRE_CO}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={5} style={{borderBottom: '1px solid rgba(0, 0, 0, 0)'}}
								><div dangerouslySetInnerHTML={{__html:content}}/></TableCell>
							</TableRow>
							
						</TableBody>
						<TableFooter style={{borderTop:"2px solid #fff"}}>
							<>
							<TableRow style={{height:"50px"}}/>
								<TableRow>
										<TableCell align="right" colSpan={5} style={{borderTop:'2px solid #06b3ba'}}>
											<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> goFAQ()}>목록</Button>
										</TableCell>
								</TableRow>
							</>
						</TableFooter>
					</Table>
					</TableContainer>
				</CardBody>
			</Card>
		</React.Fragment>
	);
  }
