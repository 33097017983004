/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,Col, CardBody, Collapse, UncontrolledTooltip,
    Button,Card, FormGroup, Label} from "reactstrap";
// import Cargo from "./Cargo.js";
import CargoBookmark from "./CargoBookmark.js";
import axios from "axios";
import Select from "react-select";
import Goods from './Goods.js';
import InputValid from "components/CustomInput/InputValid.js";
import _ from 'lodash';

const CargoCard = forwardRef((props, cargoFocus) => {
    // Collapse Flag
    const [coll, setColl] = useState(false);
    // modal 창을 위한 state
    const [open, setOpen] = useState(false);
    // Cargo
    const [booking, setBooking] = useState({});
    // Cargo
    const [cargo, setCargo] = useState({});
    // Cargo Bookmark 목록
    const [cargoBookmarkList, setCargoBookmarkList] = useState([]);
    // Goods Bookmark 목록
    const [goodsBookmarkList, setGoodsBookmarkList] = useState([]);
    // Goods 입력
    const [goodsRelationList, setGoodsRelationList] = useState([]);
    // 콤보박스
    const [cargoTypeList, setCargoTypeList] = useState([]);
    const [cargoPackTypeList, setCargoPackTypeList] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [tempValue, setTempValue] = useState("");
    const {userData, lineCode, optionCheck, lineConfigDetail,mergeConfig} = props;

    useEffect(() => {
        if( userData && userData.user_no ) {
            selectBookingCargoGoodsBookmark();
            // Cargo Type 및 Pack Type 조회
            selectLineCodeCargoType();
            selectLineCodeCargoPackType();
        }
    },[userData]);
    
    // 20210305 Card 내에서의 저장행위는 중지 하도록 한다.
    useEffect(() => {
        setCargo({
            ...cargo,
            ...props.cargo
        });
    },[props.cargo]);

    useEffect(()=>{
        //호출이 너무 자주되어서 수정 (24.01.19)
        //OogType 경우 cargo_pack_type  FB 선택불가
        if(!_.isEmpty(cargo)){
            cargo?.cargo_type=='4'? selectLineCodeCargoPackType(true): selectLineCodeCargoPackType(false);
            // if(cargo.cargo_pack_type&&cargo.cargo_pack_type==='FB')fncOnChangeSelect( false, 'cargo_pack_type' )//reset //selectLineCodeCargoPackType에서 처리
            fetchSetConfig(cargo.cargo_type,cargo.cargo_pack_type )
        }
    },[cargo?.cargo_type,cargo?.cargo_pack_type])

    const fetchSetConfig = async(cargo_type,cargo_pack_type )=>{//동시호출은 await처리
        try{
            const result = await optionCheck( [{'typeVal':'cargo_type','codeVal':cargo_type},{'typeVal':'cargo_pack_type','codeVal':cargo_pack_type}]);
            // const result2 = await optionCheck('cargo_pack_type',cargo_pack_type);
            // await mergeConfig({...result});
            // await mergeConfig({...result1, ...result2});
        }catch(e){
            console.log(e)
        }
    }

    useEffect(()=> {
        if(lineCode) {
            // Cargo Type 및 Pack Type 조회
            selectLineCodeCargoType();
        }
    },[lineCode, booking?.sch_line_code])

    useEffect(()=>{
        setColl(props.openWindow);
    },[props.openWindow]);

    useEffect(()=>{
        if( props.booking.bkg_no ) {
            if( "Y" === props.booking.bookmark_yn && props.booking.bookmark_seq ) {
                if( props.booking.cargo_bookmark_seq ) {
                    setBooking(props.booking);
                    setCargo(props.booking);
                    props.fncCargoParent(props.booking);
                    selectBookingCargoBookmarkRelation( props.booking );
                }
            } else if ( "D" === props.booking.bookmark_yn ) {
                setCargo({...cargo
                    ,'cargo_bookmark_seq': null
                    ,'cargo_bookmark_name': null
                    ,'cargo_hs_code': null
                    ,'cargo_pack_qty': null
                    ,'cargo_pack_type': null
                    // ,'cargo_pack_type_name': null
                    ,'cargo_total_volume': null
                    ,'cargo_type': null
                    // ,'cargo_type_name': null
                    // ,'cargo_weight': null
                    ,'cargo_total_weight': null
                });

                props.fncCargoParent({...cargo
                    ,'cargo_bookmark_seq': null
                    ,'cargo_bookmark_name': null
                    ,'cargo_hs_code': null
                    ,'cargo_pack_qty': null
                    ,'cargo_pack_type': null
                    // ,'cargo_pack_type_name': null
                    ,'cargo_total_volume': null
                    ,'cargo_type': null
                    // ,'cargo_type_name': null
                    // ,'cargo_weight': null
                    ,'cargo_total_weight': null
                });

                setGoodsRelationList([{}]);
                props.fncGoodsParent([{}]);
            }else {
                setBooking(props.booking);
            }
        }
    },[props.booking]);

    useEffect(()=>{
        setCargoBookmarkList(props.cargoBookmarkList);
    },[props.cargoBookmarkList]);

    useEffect(()=>{
        if(cargoPackTypeList.length>0 && cargo?.cargo_pack_type){
            let checkValue = (cargoPackTypeList.findIndex(x=>x.value===cargo.cargo_pack_type)>=0)
            ?cargoPackTypeList.find(x=>x.value===cargo.cargo_pack_type):null
            fncOnChangeSelect( checkValue, 'cargo_pack_type' );
        }
    },[cargoPackTypeList]);
    
    const fncSelectCargo=(e)=>{
        if( e ) {
            if( 1 > e.value ) {
                if ( coll ) {
                    setColl(!coll)
                }
            } else {
                cargoBookmarkList.forEach((element, key)=>{
                    if( e.value === element.cargo_bookmark_seq) {
                        let list = {...cargo
                            ,'cargo_bookmark_seq': element.cargo_bookmark_seq
                            ,'cargo_bookmark_name': element.cargo_bookmark_name
                            // ,'cargo_name':element.cargo_name
                            ,'cargo_hs_code':element.cargo_hs_code
                            ,'cargo_pack_qty': element.cargo_pack_qty?element.cargo_pack_qty:cargo.cargo_pack_qty
                            // 2023.07.05 등록된 bookmark 선사 불일치하여 셀렉트박스에서 찾을수없을때 null 처리 
                            ,'cargo_pack_type': element.cargo_pack_type
                                                ?(cargoPackTypeList.findIndex(x=>x.value===element.cargo_pack_type)>=0)
                                                    ?element.cargo_pack_type
                                                    :cargo.cargo_pack_type ||null                                                        
                                                :null
                            ,'cargo_type': element.cargo_type
                                            ?(cargoTypeList.findIndex(x=>x.value===element.cargo_type)>=0)
                                                ?element.cargo_type
                                                :cargo.cargo_type ||null                                                        
                                            :null
                            // ,'cargo_pack_type': element.cargo_pack_type?element.cargo_pack_type:cargo.cargo_pack_type
                            // ,'cargo_pack_type_name': cargo_pack_type_name?cargo_pack_type_name:cargo.cargo_pack_type_name
                            // ,'cargo_type': element.cargo_type?element.cargo_type:cargo.cargo_type
                            // ,'cargo_type_name': cargo_type_name?cargo_type_name:cargo.cargo_type_name
                            // ,'cargo_remark':element.cargo_remark
                            ,'cargo_total_volume': element.cargo_total_volume?element.cargo_total_volume:cargo.cargo_total_volume
                            ,'cargo_total_weight':element.cargo_total_weight
                            // ,'cargo_weight': element.cargo_weight?element.cargo_weight:cargo.cargo_weight
                            // ,'cargo_selected_yn':'Y'
                        };

                        setCargo(list);
                        props.fncCargoParent(list);
                        selectBookingCargoBookmarkRelation( element );
                    }
                });
                if ( !coll ) {
                    setColl(!coll);
                }
            }
        } else {
            if( cargo.cargo_bookmark_seq) {
                let list = {...cargo
                    ,'cargo_bookmark_seq': null
                    ,'cargo_bookmark_name': null
                    ,'cargo_hs_code': null
                    ,'cargo_pack_qty': null
                    ,'cargo_pack_type': null
                    // ,'cargo_pack_type_name': null
                    ,'cargo_total_volume': null
                    ,'cargo_type': null
                    // ,'cargo_type_name': null
                    // ,'cargo_weight': null
                    ,'cargo_total_weight': null
                    // ,'cargo_selected_yn':'Y'
                };
                setCargo(list);
                props.fncCargoParent(list);
                setGoodsRelationList([{}]);
                props.fncGoodsParent([{}]);
            }
        }
    }

    // Cargo Bookmark 조회
    const selectBookingCargoGoodsBookmark = () => {
        axios.post("/shipper/selectBookingCargoGoodsBookmark",{ user_no: userData?userData.user_no:null }).then(res => {
            setGoodsBookmarkList(res.data);
        });
    }

    // Cargo Type 목록조회
    const selectLineCodeCargoType = () => {
        if(lineCode) {
            axios.post("/shipper/selectLineCodeCargoType",{params:{ line_code:lineCode }, sch_line_code :booking?.sch_line_code||null}).then(res=>{
                setCargoTypeList(res.data);
            });
        }
    }
    // Cargo Pack Type 목록조회
    const selectLineCodeCargoPackType = (checkFROT) => {
        if(lineCode) {
            axios.post("/shipper/selectLineCodeCargoPackType",{params:{ line_code:lineCode ,checkFROT:checkFROT?true:false }}).then(res=>{
                setCargoPackTypeList(res.data);
            });
        }
    }

    // Cargo Bookmark 조회
    const selectBookingCargoBookmarkRelation = ( cargo ) => {
        axios.post("/shipper/selectBookingCargoBookmarkRelation",{ user_no: userData?userData.user_no:null,cargo}).then(res => {
            if( 0 < res.data.length ) {
                setGoodsRelationList(res.data);
                props.fncGoodsParent(res.data);
            }
        });
    }

    const toggle = () => {
        setOpen(!open);
    }

    const fncOnBlur = (cargo) => {
        props.fncCargoParent( cargo );
    }
    const fncOnBlurGoodsRelation = (goodsRelationList) => {
        props.fncCargoParent( cargo );
        setGoodsRelationList(goodsRelationList);
        props.fncGoodsParent(goodsRelationList);
    }
    // 수정된 내용은 cargo 저장
    const fncOnChange = ( e, key ) => {
        e.preventDefault();
        setCargo({...cargo, [key]:(e.target.value.toUpperCase())||null});
    }
    
    // 수정된 내용은 Cargo 저장
    const fncOnChangeSelect = ( e, key ) => {
        if( e ) {
            if( 'cargo_type' === key ) {
                //첨부파일 관련 제어 (기존 : 3->2로 바꿀때 모두 확인창뜨지만 코리아쉬핑으로 2또한 추가 제어필요 -미수정
                let cntrAttachFiles = props.files?Object.values(props.files):[];
                if(cntrAttachFiles&&cntrAttachFiles.length>0 
                    && ("3" !== e.cargo_type && "4" !== e.cargo_type) //기존  --3,4카고타입 변경으로 첨부파일 리셋 // 카고타입상관없이 다 체크하는걸로 수정하려면 주석걸기 
                ) {
                    setConfirm(true);
                    setTempValue(e.cargo_type)
                }else {
                    setCargo({...cargo, 'cargo_type':e.cargo_type});
                    props.fncCargoParent({...cargo, 'cargo_type':e.cargo_type});
                }
            }
            if( 'cargo_pack_type' === key ) {
                setCargo({...cargo, 'cargo_pack_type':e.cargo_pack_type});
                props.fncCargoParent({...cargo, 'cargo_pack_type':e.cargo_pack_type});
            }
        } else {
            setCargo({...cargo, [key]:null});
            props.fncCargoParent({...cargo, [key]:null});            
        }
    }
    const onConfirmYes = () => {
        setConfirm(false);
        setCargo({...cargo, 'cargo_type':tempValue});
        props.fncCargoParent({...cargo, 'cargo_type':tempValue});
        props.deleteAllFiles();
    }
    return (
        <>
            <Row id="Cargo">
                <Col xl="12" lg="12">
                    <Card style ={{zIndex:10}}>
                        <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>  
                            <Row className="pb-2">
                                <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>CARGO
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="col-10 pr-0" >
                                            <Select
                                                className="react-select react-select-primary"
                                                name="cargoBookmark"
                                                value={{
                                                    value:cargo.cargo_bookmark_seq?cargo.cargo_bookmark_seq:'',
                                                    label:cargo.cargo_bookmark_name?cargo.cargo_bookmark_name:'선택'
                                                }}
                                                onChange={(e)=>fncSelectCargo(e?e:null)}
                                                options={cargoBookmarkList}
                                                placeholder="선택"
                                                ref={cargoFocus}
                                                isClearable={cargo.cargo_bookmark_seq?true:false}
                                            />
                                        </Col>
                                        <Col className="col-2 pl-auto pr-auto">
                                            <CargoBookmark
                                                cargoBookmarkList={cargoBookmarkList}
                                                goodsBookmarkList={goodsBookmarkList}
                                                selectBookingCargoBookmark={props.selectBookingCargoBookmark}
                                                selectBookingCargoGoodsBookmark={selectBookingCargoGoodsBookmark}
                                                cargoTypeList={cargoTypeList}
                                                cargoPackTypeList={cargoPackTypeList}
                                                onAlert={props.onAlert}
                                                bookingCargo={cargo}
                                                bookingGoodsYN={props.bookingGoodsYN}
                                                {...props}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Collapse isOpen={coll}>
                                <hr className="mt-0"/>
                                <Col>
                                    <Row>
                                        <Col xl="12" lg="12" md="12">
                                            <FormGroup className="mb-1">
                                                <Row>
                                                    <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Type</Label></Col>
                                                    <Col>
                                                        <Select 
                                                            className="customSelect"
                                                            name="cargo_type"
                                                            placeholder=""
                                                            value={{value:cargo.cargo_type?cargo.cargo_type:'',
                                                                    label:cargo.cargo_type?
                                                                    (cargoTypeList.findIndex(x=>x.cargo_type===cargo.cargo_type)>=0)?
                                                                    cargoTypeList[cargoTypeList.findIndex(x=>x.cargo_type===cargo.cargo_type)].label:'선택':'선택'}}
                                                            onChange={(value)=>fncOnChangeSelect(value, 'cargo_type')}
                                                            options={cargoTypeList}
                                                            isClearable={cargo.cargo_type?true:false}
                                                            styles={{
                                                                control: provided => ({...provided,border:cargo.cargo_type?'':'1px solid red',maxHeight:'3px'}),
                                                                indicatorContainer: provided => ({...provided,color:'',height:'3px'}),
                                                                // menu: provided => ({...provided, zIndex:9999})
                                                            }}
                                                            required={true} 
                                                        />
                                                        <InputValid
                                                            hidden
                                                            type="text"
                                                            name="cargo_type_ch"
                                                            id="cargo_type_ch"
                                                            maxLength="3"
                                                            value={cargo.cargo_type?cargo.cargo_type:''}
                                                            validtype="select"
                                                            required={true} 
                                                            feedid="cargo"
                                                            readOnly
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="12" lg="12" md="12">
                                            <FormGroup className="mb-1">
                                                <Row>
                                                    <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Pack Type</Label></Col>
                                                    <Col>
                                                        <Select 
                                                            className="customSelect"
                                                            name="cargo_pack_type"
                                                            value={{
                                                                value:cargo.cargo_pack_type?cargo.cargo_pack_type:'',
                                                                label:cargo.cargo_pack_type?(cargoPackTypeList.findIndex(x=>x.cargo_pack_type===cargo.cargo_pack_type)>=0)?cargoPackTypeList[cargoPackTypeList.findIndex(x=>x.cargo_pack_type===cargo.cargo_pack_type)].label:'선택':'선택'}}
                                                            onChange={(value)=>fncOnChangeSelect(value, 'cargo_pack_type')}
                                                            options={cargoPackTypeList}                                                        
                                                            required={true} 
                                                            styles={{
                                                                control: provided => ({...provided,border:cargo.cargo_pack_type?'':'1px solid red',maxHeight:'3px'}),
                                                                indicatorContainer: provided => ({...provided,color:'',height:'3px'}),
                                                            }}
                                                            isClearable={cargo.cargo_pack_type?true:false}/>
                                                        <InputValid
                                                            hidden
                                                            type="text"
                                                            name="cargo_pack_type_ch"
                                                            id="cargo_pack_type_ch"
                                                            maxLength="20"
                                                            value={cargo.cargo_pack_type?cargo.cargo_pack_type:''}
                                                            validtype="select"
                                                            required={true} 
                                                            feedid="cargo"
                                                            readOnly
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col xl="12" lg="12" md="12">
                                            <FormGroup className="mb-1">
                                                <Row>
                                                    <Col className="pr-0 pt-1 col-2"><Label className="mb-0">PackQty</Label></Col>
                                                    <Col>
                                                        <InputValid 
                                                            type="text"
                                                            name="cargo_pack_qty"
                                                            id="cargo_pack_qty"
                                                            maxLength="4"
                                                            value={cargo.cargo_pack_qty?cargo.cargo_pack_qty:''}
                                                            onChange={(e)=>fncOnChange(e, 'cargo_pack_qty')}
                                                            onBlur={(e) => {props.fncCargoParent(cargo)}}
                                                            validtype="number"
                                                            required={(goodsRelationList.length>0&&goodsRelationList[0].goods_desc1)?true:false}
                                                            feedid="cargo"
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl="12" lg="12" md="12">
                                            <FormGroup className="mb-1">
                                                <Row>
                                                    <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Hs Code</Label></Col>
                                                    <Col>
                                                        <InputValid
                                                            type="text"
                                                            name="cargo_hs_code"
                                                            id="cargo_hs_code"
                                                            maxLength="10"
                                                            minLength="6"
                                                            onChange={(e)=>fncOnChange(e, 'cargo_hs_code')}
                                                            onBlur={(e) => {props.fncCargoParent(cargo)}}
                                                            value={cargo.cargo_hs_code?cargo.cargo_hs_code:''}
                                                            validtype="number"
                                                            required={true} 
                                                            feedid="cargo"
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* <Col xl="12" lg="12" md="12">
                                            <FormGroup className="mb-1">
                                                <Row>
                                                    <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Weight</Label></Col>
                                                    <Col>
                                                        <InputValid 
                                                            type="text"
                                                            name="cargo_weight"
                                                            id="cargo_weight"
                                                            maxLength="10"
                                                            value={cargo.cargo_weight?cargo.cargo_weight:''}
                                                            onChange={(e)=>fncOnChange(e, 'cargo_weight')}
                                                            onBlur={(e) => {props.fncCargoParent(cargo)}}
                                                            validtype="number"
                                                            required={true}
                                                            feedid="cargo"
                                                            inputgrouptext="KG"
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col> */}
                                        <Col xl="12" lg="12" md="12">
                                            <FormGroup className="mb-1">
                                                <Row>
                                                    <Col className="pr-0 pt-1 col-2"><Label className="mb-0">GrossWeight</Label></Col>
                                                    <Col>
                                                        <InputValid 
                                                            type="text"
                                                            name="cargo_total_weight"
                                                            id="cargo_total_weight"
                                                            maxLength="18"
                                                            value={cargo.cargo_total_weight?cargo.cargo_total_weight:''}
                                                            onChange={(e)=>fncOnChange(e, 'cargo_total_weight')}
                                                            onBlur={(e) => {props.fncCargoParent(cargo)}}
                                                            required={true}
                                                            feedid="cargo"
                                                            validtype="decimal"
                                                            decimallength="3"
                                                            inputgrouptext="KG"
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col xl="12" lg="12" md="12">
                                            <FormGroup className="mb-1">
                                                <Row>
                                                    <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Volume</Label></Col>
                                                    <Col>
                                                        <InputValid 
                                                            type="text"
                                                            name="cargo_total_volume"
                                                            id="cargo_total_volume"
                                                            maxLength="10"
                                                            value={cargo.cargo_total_volume?cargo.cargo_total_volume:''}
                                                            onChange={(e)=>fncOnChange(e, 'cargo_total_volume')}
                                                            onBlur={(e) => {props.fncCargoParent(cargo)}}
                                                            validtype="number"
                                                            required={false}
                                                            feedid="cargo"
                                                            inputgrouptext="CBM"
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* Goods */}
                                {(props.bookingGoodsYN!=='Y')?<></>: 
                                    <><hr/>
                                    <Goods 
                                        zIndex={1}
                                        cargo={booking}
                                        goodsRelationList={goodsRelationList}
                                        goodsBookmarkList={goodsBookmarkList}
                                        fncOnBlur={fncOnBlur}
                                        fncOnBlurGoodsRelation={fncOnBlurGoodsRelation}
                                        openType="MAIN"
                                        {...props}
                                    /></> 
                                }
                            </Collapse>
                            <div className="text-center" onClick={() => setColl(!coll)}>
                                <div>         
                                    <Button className="p-0" color="link" id="linemore" onClick={() => setColl(!coll)} style={{height:'21px'}}>
                                        {coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="linemore">{coll?'Close':'Open'}</UncontrolledTooltip>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Modal
                size="sm"
                isOpen={confirm}
                //toggle={() => setOpen(false)}
                >
                <div className="modal-body text-center pl-0 pr-0">
                    <h5>변경시 첨부된 파일은 삭제됩니다. 변경하시겠습니까?</h5>
                </div>
                <div className="modal-footer">
                    <div className="left-side">
                        <Button className="btn-link" color="danger" type="button" onClick={()=> onConfirmYes()}>Yes</Button>
                    </div>
                    <div className="divider" />
                    <div className="right-side">
                        <Button
                            className="btn-link"
                            color="default"
                            type="button"
                            onClick={() => setConfirm(false)}
                            >No
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
});

export default CargoCard;