import React , { useState , useEffect }from 'react'
import cn from 'classnames';
import { Link, useHistory} from "react-router-dom";
import { createTheme, makeStyles ,withStyles} from '@material-ui/core/styles';
import {Close, AttachFile} from '@material-ui/icons';
import {getDate, add, format, differenceInMonths, isValid, parse} from 'date-fns';
import ko from 'date-fns/locale/ko';

// core components
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
// import Button from "muiComponents/CustomButtons/Button.js";
import CustomInput from "muiComponents/CustomInput/CustomOutInput.js";
import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from "@material-ui/core/Checkbox";
import Divider from '@material-ui/core/Divider';
// css
import Style from './VerifyApplication.module.css'
const LogisviewCheckbox = withStyles({
    root:{
      color:'#5fb0b5',
      '&$checked': {
        color: '#5fb0b5',
      },
      padding:'2px'
    }
  })((props)=><Checkbox color = 'default' {...props}/>)
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background:'#ecf2f2'
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    stepIcon:{color:'yellow',
            '&$active':{color:'pupple'},
            '&$completed':{color:'red'},
        
    }
}));

  
 
const VerifyApplication = (props) => {
    const {toggle,onAlert,userData,lockKlnetId}=props;
    const history = useHistory();
    const classes = useStyles();
    const [agreeOpen,setAgreeOpen] = useState(false);
    const [agreeChecked,setAgreeChecked] = useState(false);
    const [disagreeChecked,setDisagreeChecked] = useState(false);
    const [dueDate,setDueDate] = useState('4월 21일 (금)');

    useEffect(() => {
        if (userData &&userData.comp_verify_type){
            // let klnetId = userData.klnet_id;
            // klnetId = klnetId&&klnetId.toUpperCase();
            // let first_charCode = klnetId.charCodeAt(0);
            // if (first_charCode >= 48 && first_charCode <= 57 ){setDueDate('17일 (월)')}//0-9
            // else if (first_charCode >= 65 && first_charCode <= 68 ){setDueDate('17일 (월)')}//A-D
            // else if (first_charCode >= 69 && first_charCode <= 73 ){setDueDate('18일 (화)')}//E-I
            // else if (first_charCode >= 74 && first_charCode <= 79 ){setDueDate('19일 (수)')}//J-O
            // else if (first_charCode >= 80 && first_charCode <= 83 ){setDueDate('20일 (목)')}//P-S
            // else if (first_charCode >= 84 && first_charCode <= 90 ){setDueDate('21일 (금)')}//T-Z
            // else{ setDueDate('21일 (금)')}//T-Z
            let fdate = new Date('2023-04-16')
            let service_date;
            if(userData.comp_open_order){
                service_date= add(fdate,{days:userData.comp_open_order} )
                setDueDate(format(service_date, 'M월 d일 (eee)',{locale:ko}))
            }else{
                service_date=add(fdate,{days:16} )
                setDueDate(format(service_date, 'M월 d일 (eee)',{locale:ko}))
            }
            
        }
      }, [userData]);
    
    const onSubmit =()=>{
        if(!agreeChecked||disagreeChecked){
            onAlert('error','약관동의는 필수사항입니다.');
            return false;
        }
        if(agreeChecked&&!disagreeChecked){
            const id = userData?userData.klnet_id : lockKlnetId ||null;
            if(id){
                props.toggle(); 
                history.push({
                    pathname: `/svc/application`,
                    state:{
                        agreeYN:'Y'
                        ,lockKlnetId: id
                    }
                })
            }else{
                onAlert('error','신청할 수 없는 정보입니다. 서비스 담당자에게 문의하시기 바랍니다.');
            }
        }
    }
    
    return (<>
        <GridContainer justifyContent="center">	
          <Card className={classes.cardSignup} style={{margin:'0',paddingTop:'0',paddingBottom:'0'}}>
            {/* <CardBody style={{paddingTop:'0',paddingBottom:'15px'}}> */}
			<CardBody style={{paddingTop:'15px',paddingBottom:'15px'}}>       
                <div style={{display: 'flex',	flexDirection: 'column',justifyContent: 'center'}}>		
				{/* <div style={{alignSelf:'end', cursor:'pointer'}} ><Close onClick={() => props.toggle()} /></div> */}
				{/* <div style={{textAlignLast:'center'}}><VerifiedUserOutlined style={{marginTop:'20px',marginBottom:'10px',width:'64px',height:'64px'}}/></div> */}
                    <div className={cn(Style.wrapper, Style.ptype)}>
                        <div className={Style.popHeader}>
                            <div className={Style.ptit}>
                                {/* <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-manual-tit.png`}/> */}
                                {!agreeOpen?<strong>서비스 이용 공지</strong>:<strong>약관동의</strong>}
                            </div>
                            <div style={{display:'flex', cursor:'pointer'}} >
                                <div className={Style.plogo}><img src={process.env.PUBLIC_URL  + `/assetsNew/image/etc/etc-logo.png`}/></div>
                                <Close onClick={() => props.toggle()} />
                            </div>
                        </div>
                    </div>
                </div>
{!agreeOpen&&<>
                <div className={Style.popCont}>
                    <div className={cn(Style.rtwrap,Style.li)}>
                        <dl>
                            <dt>
                                <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-tit.png`}/>
                                <strong>서비스 이용 신청 안내</strong>
                            </dt>
                            {/* 최근 청구가 없는 고객사의 '정보 최신화' 목적 */}
                            {userData&&userData.comp_verify_type =='A'&&
                            <dd>
                                <ul>
                                    <li> 1. 평소 당사의 서비스를 이용하여 주심에 감사드립니다.</li>
                                    <li> 2. 로지스뷰 서비스 유료화에 따라 서비스를 계속 이용하고자 하시는 고객사께서는 아래의 '서비스 이용 신청서 작성' 후 '작성 완료' 하여 주시기 바랍니다.
                                        <br/>&nbsp;&nbsp;가. 유료화 시점 : `23년 4월 1일 이용분부터 (5월 청구)
                                        <br/>&nbsp;&nbsp;&nbsp; -  로지스뷰 / PLISM3.0 공지 참조 : #1673번 '로지스뷰(LogisView) 서비스 기본료 부과 안내' 
                                        <br/>&nbsp;&nbsp;나. 유료화 내용 : 로지스뷰 (www.logisview.com)에서 제공하는 컨텐츠를 이용한 월에 
                                        <br/>&nbsp;&nbsp;&nbsp; 한하여 '로지스뷰 기본료' 1만원(VAT별도) 부과.
                                    </li>
                                    <li><strong style ={{color:'#db3131'}}> 3. 작성 완료를 하시지 않은 고객사께서는 사이트 (PLISM3.0 / 로지스뷰) 접속 시 나오는 안내에 따라 '서비스 이용 신청서' 작성을 서비스 이용 제한 적용일 이전까지 진행하여 서비스 이용 제한이 되지 않도록 협조 부탁드립니다.</strong>
                                    </li>
                                    <li> 4. 아울러 작성 완료를 하시지 않은 고객사에 대하여 서비스 이용 제한 조치를 아래와 같이 요일별로 적용하고자 하오니 많은 양해 부탁드립니다.
                                        <br/>&nbsp;&nbsp;가. 요일별 적용 사유 : 서비스 이용 제한에 따른 일시적인 상담 전화 연결의 어려움 발생 해소 
                                        <br/>&nbsp;&nbsp;<strong style ={{color:'#db3131'}}> 나. 서비스 이용 제한 적용일 : `23년 {dueDate} 09시 </strong>
                                        <br/>&nbsp;&nbsp;다. 관련 문의  : 고객 지원 센터 1577-1172. 끝.
                                    </li>
                                    <li style ={{textDecoration: 'underline', textUnderlinePosition:'under'}}>   <AttachFile fontSize='small'/>  
                                        <a href="https://eservice.logisview.com/file_download/로지스뷰 서비스 기본료 유료화_외부시행.pdf" target='_blank'>
                                        &nbsp;공문_로지스뷰 서비스 기본료 유료화.pdf
                                        </a>
                                    </li>
                                </ul>
                            </dd>
                            }
                            {/* 빌링 미등록 && 신청서 부재 (~23/03/31) */}
                            {userData&&(userData.comp_verify_type =='B'||userData.comp_verify_type =='X')&&
                            <dd>
                                <ul>
                                    <li> 1. 평소 당사의 서비스를 이용하여 주심에 감사드립니다.</li>
                                    <li> 2. 로지스뷰 서비스 유료화에 따라 서비스를 계속 이용하고자 하시는 고객사께서는 서비스 이용 신청서를 작성하여,&nbsp;  명판/직인 날인 후 사업자등록증 사본과 함께 당사로 FAX 송부하여 주시기 바랍니다. 
                                       &nbsp;&nbsp; ( FAX : 02 - 538 - 3078 / 자동 이체 신청시, 계좌 사본 포함 )
                                        <br/>&nbsp;&nbsp; 가. 유료화 시점 : `23년 4월 1일 이용분부터 (5월 청구)
                                        <br/>&nbsp;&nbsp;&nbsp; -  로지스뷰 / PLISM3.0 공지 참조 : #1673번 '로지스뷰(LogisView) 서비스 기본료 부과 안내' 
                                        <br/>&nbsp;&nbsp; 나. 유료화 내용 : 로지스뷰 (www.logisview.com)에서 제공하는 컨텐츠를 이용한 월에 
                                        <br/>&nbsp;&nbsp;&nbsp; 한하여 '로지스뷰 기본료' 1만원(VAT별도) 부과.
                                    </li>
                                    <li><strong style ={{color:'#db3131'}}>  3. 이용 신청을 하시지 않은 고객사께서는 사이트 (PLISM3.0 / 로지스뷰) 접속 시 나오는 안내에 따라 이용 신청 절차를 서비스 이용 제한 적용일 이전까지 진행하여 서비스 이용 제한이 되지 않도록 협조 부탁드립니다.</strong>
                                    </li>
                                    <li> 4. 아울러 작성 완료를 하시지 않은 고객사에 대하여 서비스 이용 제한 조치를 아래와 같이 요일별로 적용하고자 하오니 많은 양해 부탁드립니다.
                                        <br/>&nbsp;&nbsp;가. 요일별 적용 사유 : 서비스 이용 제한에 따른 일시적인 상담 전화 연결의 어려움 발생 해소 
                                        <br/>&nbsp;&nbsp;<strong style ={{color:'#db3131'}}> 나. 서비스 이용 제한 적용일 : `23년 {dueDate} 09시  </strong>
                                        <br/>&nbsp;&nbsp;다. 관련 문의  : 고객 지원 센터 1577-1172. 끝.
                                    </li>
                                    <li style ={{textDecoration: 'underline', textUnderlinePosition:'under'}}>   <AttachFile fontSize='small'/>  
                                        <a href="https://eservice.logisview.com/file_download/로지스뷰 서비스 기본료 유료화_외부시행.pdf" target='_blank'>
                                        &nbsp;공문_로지스뷰 서비스 기본료 유료화.pdf
                                        </a>
                                    </li>
                                </ul>
                            </dd>
                            }
                            {/*미승인고객사  */}
                            {((!userData&&props.lockKlnetId)||userData&&userData.comp_verify_type =='C')&&
                            <dd>
                                <ul>
                                    <li> 1. 평소 당사의 서비스를 이용하여 주심에 감사드립니다.</li>
                                    <li> 2. 로지스뷰 서비스 유료화에 따라 서비스를 계속 이용하고자 하시는 고객사께서는 서비스 이용 신청서를 작성하여,&nbsp;  명판/직인 날인 후 사업자등록증 사본과 함께 당사로 FAX 송부하여 주시기 바랍니다. 
                                    &nbsp;&nbsp; ( FAX : 02 - 538 - 3078 / 자동 이체 신청시, 계좌 사본 포함 )
                                        <br/>&nbsp;&nbsp;가. 유료화 시점 : `23년 4월 1일 이용분부터 (5월 청구)
                                        <br/>&nbsp;&nbsp;&nbsp;  -  로지스뷰/PLISM3.0 공지 참조 : #1673번 '로지스뷰(LogisView) 서비스 기본료 부과 안내' 
                                        <br/>&nbsp;&nbsp;나. 유료화 내용 : 로지스뷰 (www.logisview.com)에서 제공하는 컨텐츠를 이용한 월에 
                                        <br/>&nbsp;&nbsp;&nbsp; 한하여 '로지스뷰 기본료' 1만원(VAT별도) 부과.
                                    </li>
                                    <li><strong style ={{color:'#db3131'}}>  3. 이용 신청을 하시지 않은 고객사께서는 사이트 (PLISM3.0 / 로지스뷰) 접속 시 나오는 안내에 따라 이용 신청 절차를 진행하셔야 서비스 이용 제한이 해제(가입)되어 서비스 이용이 가능합니다.</strong>
                                    </li>
                                    <li> 4. 관련 문의  : 고객 지원 센터 1577-1172. 끝.</li>
                                    <li style ={{textDecoration: 'underline', textUnderlinePosition:'under'}}>   <AttachFile fontSize='small'/>  
                                        <a href="https://eservice.logisview.com/file_download/로지스뷰 서비스 기본료 유료화_외부시행.pdf" target='_blank'>
                                        &nbsp;<font color='#6c757d'>공문_로지스뷰 서비스 기본료 유료화.pdf</font>
                                        </a>
                                    </li>
                                </ul>
                            </dd>
                            }
                            {/*폐사업자  */}
                            {/* {userData&&userData.comp_verify_type =='X'&&
                            <dd>
                                <ul>
                                    <li> 1. 평소 당사의 서비스를 이용하여 주심에 감사드립니다.</li>
                                    <li> 2. 로지스뷰 유료화에 따라 고객사의 경우 등록된 사업자등록번호가 폐사업자로 확인됩니다.
                                        <br/>&nbsp; <strong style ={{color:'#db3131'}}>고객사께서는 로지스뷰 서비스를 계속 이용하시려면 가입신청서를 작성하여,&nbsp;  명판, 직인 날인 후 사업자등록증 사본과 함께 당사로 FAX 송부하여 주시기 바랍니다. </strong>
                                        <br/>&nbsp; ( FAX : 02 - 538 - 3078 / 자동 이체 신청시, 계좌 사본 포함 )
                                        <br/>&nbsp;&nbsp; 가. 유료화 시점 : `23년 4월 1일 이용분부터 (5월 청구)
                                        <br/>&nbsp;&nbsp;&nbsp;  -  로지스뷰/PLISM3.0 공지 참조 : #1673번 '로지스뷰(LogisView) 서비스 기본료 부과 안내' 
                                        <br/>&nbsp;&nbsp; 나. 유료화 내용 : 로지스뷰 (www.logisview.com)에서 제공하는 컨텐츠를 이용한 월에 한하여 
                                        <br/>&nbsp;&nbsp;&nbsp; '로지스뷰 기본료' 1만원(VAT별도) 부과.
                                    </li>
                                    <li style ={{textDecoration: 'underline', textUnderlinePosition:'under'}}>   <AttachFile fontSize='small'/>  
                                        <a href="https://eservice.logisview.com/file_download/로지스뷰 서비스 기본료 유료화_외부시행.pdf" target='_blank'>
                                        &nbsp;<font color='#6c757d'>로지스뷰 서비스 기본료 유료화_외부시행.pdf</font>
                                        </a>
                                    </li>
                                </ul>
                            </dd>
                            } */}
                        </dl>
                    </div>
                </div>
                <div className={Style.popBtnWrap} >
                    <button type="button" className={Style.rtbutton} onClick={()=>{setAgreeOpen(true)}} >
                    {/* <button type="button" className={Style.rtbutton} onClick={()=>{props.setOpenApplicationModal(true);props.toggle();}} > */}
                        <span>서비스 이용신청서 작성</span>
                        <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-arr.png`}/>
                    </button> 
                </div>
</>}
{agreeOpen&&<>
                <div className={Style.popCont}>
                    <div className={cn(Style.rtwrap,Style.li)} style ={{width:'80%'}}>
                        <dl>
                            <dt> 
                                <strong style={{color:'#005a5c',wordBreak:'keep-all',fontWeight:'bolder'}}>'서비스 이용약관' 및 '개인정보 수집 및 이용'에 동의하며, 상기의 내용과 같은 정보를 제공합니다.</strong>
                            </dt>
                            <dd>
                                <ul>
                                    <li style={{fontSize:'14px'}}> - KL-Net 서비스 이용약관 : 
                                        <br/> &nbsp;&nbsp;http://cyberdesk.klnet.co.kr &nbsp;>&nbsp; 가입 및 이용안내&nbsp; >&nbsp;
                                        <a href='#' 
                                        onClick={()=>window.open('http://cyberdesk.klnet.co.kr/cyberdesk/user/provision.jsp','_blank','width=900,height=700')} className={Style.aLink}> 
                                        이용약관 </a></li>
                                    <li style={{fontSize:'14px'}}> - 로지스뷰 2.0 이용약관 및 개인정보 수집 및 이용동의 : 
                                    <br/>&nbsp;&nbsp; http://www.logisview.com &nbsp;> &nbsp;(하단)
                                    <span onClick={()=>props.onHandleFooterAgree('terms')} className={Style.aLink}>
                                        &nbsp;이용약관</span>, &nbsp;
                                    <span onClick={()=>props.onHandleFooterAgree('privacy')} className={Style.aLink}>개인정보 수집 및 이용동의</span></li>
                                </ul>
                                <Divider style={{marginTop:'30px',marginBottom:'5px'}}/>
                                <List style={{display:'flex'}}>
                                    <ListItem dense button style={{padding:"0"}} onClick={() =>setAgreeChecked(true) }>
                                        <ListItemIcon style={{minWidth:"0"}}>
                                            <LogisviewCheckbox   checked={agreeChecked} onChange={() => setAgreeChecked(true)} 
                                            disableRipple />
                                        </ListItemIcon>
                                        <ListItemText primary={<strong style={{fontSize:'14px'}}>동의함 (필수)</strong>}/>
                                    </ListItem>
                                    <ListItem dense button style={{padding:"0"}} onClick={() =>setAgreeChecked(false) }>
                                        <ListItemIcon style={{minWidth:"0"}}>
                                            <LogisviewCheckbox checked={!agreeChecked} onChange={() =>setAgreeChecked(false) } 
                                            disableRipple />
                                        </ListItemIcon>
                                        <ListItemText primary={<strong style={{fontSize:'14px'}}>동의안함</strong>}/>
                                    </ListItem>
                                     {/* <ListItemSecondaryAction> */}
                                        {/* <IconButton edge="end" style={{padding:"0"}}  >
                                            <CommentIcon />
                                        </IconButton> */}
                                    {/* </ListItemSecondaryAction> */}
                                </List>
                            </dd>
                        </dl>
                    </div>
                </div>
                <div className={Style.popBtnWrap} >
                    {/* <Link to={{pathname: `/svc/application`,state: {agreeYN:'Y'}}}> */}
                        <button type="button" className={Style.rtbutton} onClick={onSubmit} >
                        {/* <button type="button" className={Style.rtbutton} onClick={()=>{props.setOpenApplicationModal(true);props.toggle();}} > */}
                            <span>서비스 이용신청서 작성</span>
                            <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-arr.png`}/>
                        </button> 
                    {/* </Link> */}
                </div>
</>}
            </CardBody>
        </Card>
    </GridContainer>
</>
        // </div>
        // <div className={cn(Style.wrapper, Style.ptype)}>
        //     <div className={Style.popHeader}>
        //         <div className={Style.ptit}>
        //             {/* <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-manual-tit.png`}/> */}
        //             <strong>KL-Net 서비스 이용 공지</strong>
        //         </div>
        //         <div className={Style.plogo}><img src={process.env.PUBLIC_URL  + `/assetsNew/image/etc/etc-logo.png`}/></div>
        //     </div>
        
    )
  }
  
  export default VerifyApplication;
  