import {format, parse, subDays, isBefore, isAfter, isValid} from 'date-fns';
// import axios from 'axios';

export const toDateParse =(str)=>{
    let parseDate ='';
    if(str&&str.length==10){str = str+'00'}

    if(str&&str.length>11){
        // console.log(str)
        str = str.substr(0, 12);
        parseDate = parse(str,'yyyyMMddHHmm',new Date);
        if(isValid(parseDate)){
            parseDate= format(parseDate,'yyyy-MM-dd HH:mm');
        }else{
            parseDate=str;
        }
    }else if(str&&str.length==8){
        parseDate = parse(str,'yyyyMMdd',new Date);
        if(isValid(parseDate)){
            parseDate= format(parseDate,'yyyy-MM-dd');
        }else{
            parseDate=str;
        }
    }else{
        return str;
    }

    return parseDate;
}

export const toTimeParse =(str)=>{
    let parseDate ='';
    str = str+'00';
    console.log(str)
    if(str&&str.length>=4){
        str = str.substr(0, 4);
        parseDate = parse(str,'HHmm',new Date);
        if(isValid(parseDate)){
            parseDate= format(parseDate,'HH:mm');
        }else{
            parseDate=str;
        }
    }else{
        return str;
    }

    return parseDate;
}

const fnParseDate =(str)=>{
    let parseDate ='';
    if(!str){return false};

    if(str.length>=12){str = str.substr(0, 12);}
    else if(str.length==10){str = str+'00'}
    else if(str.length==8){str = str+'0000'}
    else {return false}

    parseDate= parse(str,'yyyyMMddHHmm',new Date)
    return parseDate
}

export const isBeforeDate = (str, date)=>{
    let parseDate = fnParseDate(str);
    let keyDate =date ||new Date();
    const result =isBefore(parseDate, keyDate);  
    return result;
}

export const isAfterDate = (str, date)=>{
    let parseDate = fnParseDate(str);
    let keyDate =date ||new Date();
    const result = isAfter(parseDate, keyDate);  
    return result;
}

export const isBeforeDateParse = (str)=>{
    return str&&isBeforeDate(str)? toDateParse(str):'';
}

export const toMapDateParse =(str)=>{
    let parseDate = str ? str.length==12 ? parse(str,'yyyyMMddHHmm',new Date)
                        :str.length==8 ? parse(str+'0000','yyyyMMddHHmm',new Date)
                                        :null
                        :null
    parseDate = parseDate?format(subDays(parseDate,10),'yyyyMMdd'):null //map_start_day
    return parseDate;
}

export const onHandleStatus = (ieType,row)=>{
    
    let load_begin_date  = row?.sch_load_begin_date ||'';
    let load_end_date = row?.sch_load_end_date||'';

    
    if(!ieType||!load_begin_date||!load_end_date){return ''};
    
    // console.log(isBeforeDate(load_begin_date),isAfterDate(load_begin_date),isAfterDate(load_end_date))
    //begin > today 접안 전
    if(isAfterDate(load_begin_date))return '접안 전';
    
    //begin < today < end 접안 완료
    //end < today   출항 완료 (수출만)
    if(ieType=='E'){
        if(isBeforeDate(load_begin_date)&&isAfterDate(load_end_date))return '접안 완료';    
        if(isBeforeDate(load_end_date))return '출항 완료';
    }
    //begin < today 접안 완료
    if(ieType=='I'){
        if(isBeforeDate(load_begin_date))return '접안 완료';    
    }
    
    return '';
}