import {format, parse, subDays, isBefore, isValid} from 'date-fns';
import axios from 'axios';

export const toDateParse =(str)=>{
    let parseDate ='';
    if(str&&str.length>11){
        str = str.substr(0, 12);
        parseDate = parse(str,'yyyyMMddHHmm',new Date);
        if(isValid(parseDate)){
            parseDate= format(parseDate,'yyyy-MM-dd HH:mm');
        }else{
            parseDate=str;
        }
    }else if(str&&str.length==8){
        parseDate = parse(str,'yyyyMMdd',new Date);
        if(isValid(parseDate)){
            parseDate= format(parseDate,'yyyy-MM-dd');
        }else{
            parseDate=str;
        }
    }else{
        return str;
    }

    return parseDate;
}


export const isBeforeDate = (str)=>{
    let parseDate ='';
    if(!str){return false};
    if(str.length>=12){str = str.substr(0, 12);}
    else if(str.length==8){str = str+'0000'}
    else {return false}
    parseDate= parse(str,'yyyyMMddHHmm',new Date)
    const result =isBefore(parseDate, new Date());  
    return result;
}

export const isBeforeDateParse = (str)=>{
    return str&&isBeforeDate(str)? toDateParse(str):'';
}

export const toMapDateParse =(str)=>{
    let parseDate = str ? str.length==12 ? parse(str,'yyyyMMddHHmm',new Date)
                        :str.length==8 ? parse(str+'0000','yyyyMMddHHmm',new Date)
                                        :null
                        :null
    parseDate = parseDate?format(subDays(parseDate,10),'yyyyMMdd'):null //map_start_day
    return parseDate;
}

export const insertEventLog = async(userData, eventName, params)=>{
    const res = await axios.post('/loc/insertTrkEventLogTbl'
            ,{klnet_id:userData.klnet_id, user_id:userData.user_no 
            , event_name: eventName, event_params: params});
    return true;
}

export const handleLineUrl = async(e,data)=>{
    e.preventDefault();
    const {line_url} =data;
    let urlVal = '';
    if(!line_url) return ;
    let line_url_param  = data.line_url_param||'';

    urlVal = line_url + line_url_param;
    // if(url_param=='ALL'){
    //     urlVal=line_url+mbl_no
    // }else{
    //     urlVal=line_url
    // }
    window.open(urlVal,'_blank'); 
}
