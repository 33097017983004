import React,{ useState, useEffect, useMemo } from "react";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import CardBody from "muiComponents/Card/CardBody.js";
import {
	Collapse,
	Card,
	CardContent,
	TextField,
	TableFooter,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Grid,
	Container,
	Avatar,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Input,
	makeStyles,
	List,
	ListItem,
	ListItemText,
	Divider,
	ListItemIcon,
	Checkbox,
} from "@material-ui/core";

import Button from "muiComponents/CustomButtons/Button.js";

import {
	Chat as Chat,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	Search as SearchIcon,
	CloseIcon,
	DoneIcon
}from "@material-ui/icons";
import axios from 'axios';
import Filesaver from 'file-saver';
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'
import {yellow, red} from '@material-ui/core/colors';
import { useHistory } from "react-router-dom";
// import testimonialsSection from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle";
// import AlertMessage from 'muiComponents/Alert/AlertMessage.js';
import Pagination from '@material-ui/lab/Pagination';
import {FixedSizeList} from 'react-window';
import queryString from 'query-string';


const ico_m_new = require("assets/img/ico_m_new.gif");


const useRowStyles = makeStyles((theme)=> ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	small: {
		width: theme.spacing(2),
		height: theme.spacing(2),
		backgroundColor: red['700']
	}
}));

const useStyles = makeStyles((theme) => ({
	root: {
	  width: '100%',
	  maxWidth: 360,
	  backgroundColor: theme.palette.background.paper,
	},
  }));

function Row(props) {
	const history = useHistory();
	const [row,setRow] = useState(props.row);
	const [open, setOpen] = useState(false);
	const [pwOpen, setPwOpen] = useState(false);
	const [password,setPassword]= useState('');
	const [lock, setLock] = useState(false);
	const [isNew,setIsNew] = useState(false);
	const { userData} = props;

	const classes = useStyles();
	useEffect(()=>{
		setRow(props.row);
	},[props.row])
	useEffect(() => {
        if(row.NTT_ID===props.num) {
            setOpen(true)
        }else {
            setOpen(false);
        }
	},[props.num]);
	useEffect(() => {
        if(row.NTT_ID===props.num2) {
            setPwOpen(true)
        }else {
            setPwOpen(false);
        }
	},[props.num2]);


	useEffect(()=>{
		const d= new Date();
		const year=d.getFullYear();
		const month=d.getMonth();
		const day=d.getDate();
		setIsNew(new Date(row.FRST_REGISTER_PNTTM.substring(0,10))>new Date(year,month,day-30));
	},[row]);

	const onButtonClick = () => {
		// if(props.userData){
			if(lock&&lock==='Y'){
				if(row.NTT_ID===props.num2){
					props.openPWInput("")
				}else{
					props.openPWInput(row.NTT_ID)
				}
				return false
			}
	
			history.push({
				pathname:"/svc/shipperboarddetail",
				...props.row,
				// login_user_no:props.userData.user_no,
				// login_user_role:props.userData.role,
				// login_user_name:props.userData.name,
			})
		// }else{
		// 	props.onAlert("error","로그인 이후 이용해주세요." );
		// }
	}
	
	const atchFileId_onclick = function() {
		let fileName = row.ATCH_FILE_ID;
		const localCheck =window.location.href.split("/")[2]||'';
		if(localCheck.includes('localhost')||localCheck.includes('dev')){
			let fileUrl = "https://testeservice.logisview.com/file_download/";
			let url = fileUrl + fileName;
			window.open(url);
		}else{
			let fileUrl = "https://eservice.logisview.com/file_download/";
			let url = fileUrl + fileName;
			window.open(url);
		}
	};
	

	return (

	  	<React.Fragment>
			<TableRow>
				<TableCell onClick={(e) => onButtonClick(e)} component="th" scope="row" style={{paddingTop:'10px',paddingBottom:'10px', cursor:'pointer'}}>{props.totalShipperBoardCount-row.RNUM2+1}</TableCell>
				<TableCell onClick={(e) => onButtonClick(e)} component="th" scope="row" style={{paddingTop:'10px',paddingBottom:'10px', cursor:'pointer'}}>{row.CARRIER_CODE}</TableCell>
				{row.TOP_FLAG &&row.TOP_FLAG==='Y'?
					<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px',fontWeight:'bold',color:'#06c', cursor:'pointer'}}>{row.NTT_SJ} {isNew&& <img src={ico_m_new}/>} </TableCell>
					:<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px', cursor:'pointer'}}>{row.NTT_SJ} {isNew &&<img src={ico_m_new}/>} </TableCell>
				
				}<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px', cursor:'pointer'}}>{row.FRST_REGISTER_PNTTM}</TableCell>
				<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px', cursor:'pointer'}}>{row.FRST_REGISTER_NM}</TableCell>
				<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px', cursor:'pointer'}}>{row.INQIRE_CO}</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'}}>
					{row.ATCH_FILE_ID?
						<Button className="m-0 pt-1 pb-1" style={{backgroundColor:'#00acc1',fontSize:'10px'}} onClick={(e) => atchFileId_onclick()}>
							DOWNLOAD
						</Button>
						:<div style={{fontSize:'14px'}}>-</div>
					}
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
  }

export default function ShipperBoard(props) {

    const classes = useRowStyles();
    const [boardData,setBoardData] = useState([]);
	const [selectValue,setSelectValue] = useState("0");
	// const [CopySelectValue,setCopySelectValue] = useState("");
	const [openPage, setOpenPage] = useState(0);
	// const [Num,setNum] = useState(1);
	const [subject, setSubject] = useState("");
	// const [copySubject, setCopySubject] = useState("");
	const [openPW, setOpenPW] = useState("");
	// const [openAlert, setOpenAlert] = useState(false);
	// const [message, setMessage] = useState('');
	// const [status, setStatus] = useState('');
	const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
	const [totalShipperBoardCount,setTotalShipperBoardCount] = useState(0);
	const [carrierCode,setCarrierCode] =useState('');
	const [selectedBoardID,setSelectedBoardID] =useState('BBSMSTR_500000000001');
	const [checked, setChecked] =useState('ALL');
	const [carrierList, setCarrierList] =useState([]);
	const [carrierSearch, setCarrierSearch] =useState('');
	const [carrierSearchList, setCarrierSearchList] =useState([]);
	const pageCount = 10;
	// const history = useHistory();
	const { userData} = props;

	// useEffect(() => {
	// 	onSubmit();
    // }, [page]);

	useEffect(() => {
		fncCarrierListSelect();
    }, [boardData]);

	// useEffect(() => {
	// 	let carrier = '';
	// 	if(checked){
	// 		let chekedCarrierCode =carrierSearchList[checked]&&carrierSearchList[checked].length>0||carrierSearch
	// 								?carrierSearchList[checked]:carrierList[checked];
	// 		console.log(chekedCarrierCode)
	// 		if(chekedCarrierCode){
	// 			carrier=carrierSearchList.length>0||carrierSearch?chekedCarrierCode?.CARRIER_CODE:chekedCarrierCode?.CARRIER_CODE;
	// 		}else{
	// 			carrier='';
	// 		}
	// 		console.log(carrier)
	// 		setCarrierCode(carrier)
	// 		onSubmit(carrier);
	// 	}
	// }, [checked]);

	useEffect( ()=>{
		if(queryString){
			let query = queryString.parse(window.location.search);
			let carrier = query?.carrier
			// setCarrierCode(carrier);
			onSubmit(carrier);
		}
		// handleToggle(9999)
	},[window.location.search]);

	useEffect(() => {
		if(carrierSearch){
			let newCarrierList=[];
			carrierList.map((data)=>{
				let pushFlag=false;
				if(data.CARRIER_CODE.indexOf(carrierSearch)>=0){
					newCarrierList.push(data);
					pushFlag=true;
				}
				if(pushFlag===false&&data.CARRIER_HNAME.indexOf(carrierSearch)>=0){
					newCarrierList.push(data);
				}
			})
			setCarrierSearchList(newCarrierList);
		}else{
			setCarrierSearchList([]);
		}
    }, [carrierSearch]);

	useEffect(() => {
        if(props.location.state) {
            setOpenPage(props.location.state.param);
        }
    },[props.location]);
	
	const onSubmit = (paramCarrier, parampage) => {
		let pp = parampage ||page||1;
		let carrier= paramCarrier=='ALL'?'' : paramCarrier||carrierCode||'';
		// console.log(pp,carrier)
		setCarrierCode(carrier);
		fncListCount(carrier);
		axios.post("/api/selectShipperBoardList",{carrier_code:carrier,
													bbs_id:selectedBoardID,bbs_user_in:'Y',fnc_chk:'N',lang:'ko',
													firstIndex:((pp-1)*pageCount)+1, 
													recordCountPerPage:((pp-1)*pageCount)+pageCount,
													searchCnd:selectValue,searchWrd:subject?subject:null,
													sKlnetId:userData?userData.klnet_id:''})
		.then(res => {
			setBoardData(res.data);
			// if(res.data.length>0){
			// 	fncListCount(carrierCode, page);
			// }else{
			// 	setTotalCount(0)
			// 	setTotalShipperBoardCount(0);
			// }
		})
	}

	const fncCarrierListSelect = () => {
		axios.post("/api/selectShipperBoardCarrierList",{carrier_code:'',bbs_id:selectedBoardID,bbs_user_in:'Y',fnc_chk:'N',lang:'ko', searchCnd:selectValue,searchWrd:subject?subject:null,sKlnetId:userData?userData.klnet_id:''})
		.then(res => {
			if(res.data.length>0){
				let codeList=[];
				res.data.map((data)=>{
					codeList.push(data);
				})
				setCarrierList(codeList);
			}else{
				setCarrierList([]);
			}
		})
	}

	const fncListCount = (carrierCode) => {
		axios.post("/api/selectShipperBoardListCount",{carrier_code:carrierCode?carrierCode:'',bbs_id:selectedBoardID,bbs_user_in:'Y',lang:'ko', searchCnd:selectValue,searchWrd:subject?subject:null})
		.then(res => {
			if(res.data.length>0){
				//총페이지 수
				setTotalCount(Math.ceil(res.data[0].CNT/10))
				setTotalShipperBoardCount(res.data[0].CNT);
			}else{
				setTotalCount(0)
				setTotalShipperBoardCount(0);
			}
		})
	}
	
	const handleChange = (event, value) => {
		setPage(Number(value));
		onSubmit(null,Number(value));
	}
	
	const handleToggle = (index) => () => {
		let checked =  index===9999?'ALL':String(index);
		let chekedCarrierCode =carrierSearchList[checked]&&carrierSearchList[checked].length>0||carrierSearch
									?carrierSearchList[checked]:carrierList[checked];
		console.log(chekedCarrierCode)

		// if(index===9999){
		// 	setChecked('ALL');
		// 	setCarrierSearch('');
		// 	onSubmit();
		// 	return 
		// } 
		// setChecked(String(index));
		let carrier = 'ALL';
		
		if(chekedCarrierCode){
			carrier=carrierSearchList.length>0||carrierSearch?chekedCarrierCode?.CARRIER_CODE:chekedCarrierCode?.CARRIER_CODE;
		}else{
			carrier='ALL';
		}
		setCarrierSearch('');

		console.log(carrier);
		// setCarrierCode(carrier);
		onSubmit(carrier);
	};

	function renderRow(){
		//console.log(props.logoImgList.find(x=>x.sch_line_code==value.CARRIER_CODE)['line_code']);
		return(
			<>
				<ListItem className="p-0" key={9999} role={undefined} dense button onClick={handleToggle(9999)}>
					<ListItemText className="ml-4" primary={'All'} />
				</ListItem>
				<Divider />
				 {(carrierSearchList.length>0||carrierSearch?carrierSearchList:carrierList).map((value,index) => {
				const labelId = `checkbox-list-label-${index}`;
				let style={};
				if(carrierCode===value.CARRIER_CODE)style={backgroundColor:'#06b3ba'}
				let carrierInfo = props.logoImgList.find(x=>x.sch_line_code==value.CARRIER_CODE);
				return (
				<ListItem style={style}className="p-0" key={index} role={undefined} dense button onClick={handleToggle(index)}>
					<GridItem xs={2} sm={2} md={2} >
						<ListItemIcon className="pt-1">
						<LoadCarrierImage line_code={value.CARRIER_CODE} logo={(carrierInfo&&carrierInfo['line_logo_img'])||value.CARRIER_CODE} classStyle={"img-rounded img-responsive"} 
                                        width={28} height={26}
                                        /> 
						</ListItemIcon>
					</GridItem>
					<GridItem xs={10} sm={10} md={10} >
						<div style={{fontSize:'11px',display:'block',overflow:'hidden',whiteSpace:'nowrap',width:'80px',textOverflow:'ellipsis'}} >{value.CARRIER_HNAME}</div>
						{/* <ListItemText 
							style={{display:'block',overflow:'hidden',whiteSpace:'nowrap',width:'90px',textOverflow:'ellipsis'}} 
							className="pl-2"id={labelId} primary={value.CARRIER_HNAME} /> */}
					</GridItem>
				</ListItem>
				);
			})}
			</>
		);
	}

    return(
		<React.Fragment>
			<Card className="m-4">
				<CardContent>
					<h4 style={{textAlignLast:'left',color:'black'}}><Chat style={{color:'#00acc1'}}></Chat> 선사게시판</h4>
					<GridContainer className="mt-4">
						<GridItem xs={6} sm={4} md={4} >
							<TextField id="Subject" variant="outlined"  onChange={(event)=>setSubject(event.target.value)} fullWidth />
						</GridItem>
						<GridItem xs={6} sm={2} md={2} >
							<FormControl variant="outlined" >
								<InputLabel>OPTION</InputLabel>
								<Select
									label="option"
									value={selectValue}
									onChange={(event)=> setSelectValue(event.target.value)}>
									<MenuItem value="0">제목</MenuItem>
									<MenuItem value="1">내용</MenuItem>
									<MenuItem value="2">작성자</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs={12} sm={6} md={6} >
							<div style={{textAlign:'-webkit-right'}}>
								<Button color="info" endIcon={<SearchIcon/>}  onClick={()=> onSubmit()}>Search</Button>
							</div>
						</GridItem>
					</GridContainer>
					<GridContainer className="mt-3" >
						<GridItem xs={3} sm={2} md={2} >
							<Card>
								<CardBody>
									<FormControl variant="outlined" >
										<InputLabel>Carrier</InputLabel>
										<Input className="mb-3 mt-3" type="text" name="carrier_search" id="carrier_search" 
										style={{textTransform:'uppercase'}}
										value={carrierSearch}
										onChange={(e)=>setCarrierSearch(e.target.value.toUpperCase())}
										/>
									</FormControl>
									<FixedSizeList height={400} itemCount={1} itemSize={35}>
										{renderRow}
									</FixedSizeList>
								</CardBody>
							</Card>
						</GridItem>
						<GridItem xs={9} sm={10} md={10}  >
							<div className="mb-1">
								Total:{totalShipperBoardCount}
							</div>
							<CardBody>
							<Table>
								<TableHead style={{backgroundColor:'#f2fefd'}} className={classes["info" + "TableHeader"]}>
									<TableRow style={{borderBottomStyle:'solid',borderBottomColor:'#00bcd4'}}>
										<TableCell style={{width:'5%',whiteSpace:'nowrap'}}>순번</TableCell>
										<TableCell style={{width:'5%',whiteSpace:'nowrap'}}>선사</TableCell>
										<TableCell style={{width:'30%',whiteSpace:'nowrap'}}>제목</TableCell>
										<TableCell style={{width:'20%',whiteSpace:'nowrap'}}>작성일자</TableCell>
										<TableCell style={{width:'10%',whiteSpace:'nowrap'}}>작성자</TableCell>
										<TableCell style={{width:'10%',whiteSpace:'nowrap'}}>조회수</TableCell>
										<TableCell style={{width:'15%',whiteSpace:'nowrap'}}>첨부파일</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{boardData.map((prop, key) => {
										return (
											<Row key={key} openNum={(e) => setOpenPage(e)}  openPWInput={(e) => setOpenPW(e)} userData={props.userData} row={prop} num={openPage} num2={openPW} onAlert={props.onAlert} totalShipperBoardCount={totalShipperBoardCount}/>
										);
									})}
								</TableBody>
								{(boardData.length > 0 ?
								<TableFooter>
									<TableRow>
										<TableCell colSpan={6} style={{paddingLeft:'35%'}}>
											<Pagination
												count={totalCount}
												page={page}
												onChange={handleChange}
												defaultPage={1}
												color="primary"
												size="small"
												showFirstButton
												showLastButton
												>
											</Pagination> 
										</TableCell> 
									</TableRow>
									<TableRow >
									</TableRow>
								</TableFooter>: null )}
							</Table>
							</CardBody>
						</GridItem>
					</GridContainer>
				</CardContent>
			</Card>
			{/* <AlertMessage
			message={message}
			isOpen={openAlert}
			isClose={() => setOpenAlert(!openAlert)}
			// fontColor={font}   //선택사항
			alertColor={status === 'success' ? status : 'danger'} //선택사항  //primary, secondary, success, danger, warning, info, light, dark
			// timeOut={2000} //선택사항
			></AlertMessage> */}
		</React.Fragment>
    );
}
