


import React from "react";

// reactstrap components

// core components
// import WeidongNavbar from "components/Navbars/WeidongNavbar.js";
// import WeidongHeader from "components/Headers/WeidongHeader.js";
// import FooterWeidong from "components/Footers/FooterWeidong.js";
//import AlertModal from 'components/Modals/Alert.js';
// sections for this page
import Dashboard from "./sections/Dashboard.js";


function DashBoard(props) {
  
  return (
    <>
      {/* <WeidongNavbar {...props} />
      <WeidongHeader /> */}
      <Dashboard  {...props}/>
      {/* <FooterWeidong /> */}
    </>
  );
}

export default DashBoard;
