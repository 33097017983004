/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from 'react';
import { Row,Col, FormGroup,Label,Input,FormFeedback,Popover} from "reactstrap";
//import axios from 'axios';
//import Mark from './Mark.js';
//import Goods from './Goods.js';
import GoodsArea from './GoodsArea.js';
import MarkArea from './MarkArea.js';
import HsCode from './HsCodePopup.js';
import Select from "react-select";
import InputValid from "components/CustomInput/InputValid.js";
import * as validation from 'components/common/validation.js';
export default function Cargo (props){

  	const {view,cargoProps,pack,onHsCode} = props;	
  
	useEffect(() => {
		setCargo(cargoProps);
		setMarkList(cargoProps);
		setGoodsList(cargoProps);
	},[cargoProps]);

	const [cargo,setCargo] = useState({});
	const [markList,setMarkList] = useState([]);
	const [goodsList,setGoodsList] = useState([]);
	const [popOpen, setPopOpen] = useState(false);

	const onHandleReturnVal = (event,name) => { 
		let list = {...cargo, [name]:(event.target.value.toUpperCase())||null};
		setCargo(list);
	}
	const onHandleSelectReturnVal = (value,name) => {
		let list = {...cargo, [name]:value};
		setCargo(list);
		props.propsData(list);
	}
	
    const onPropsReturn = () => {
		props.propsData(cargo);
	}
	const onDataGoodsMerge =(data) => {
		setGoodsList(data);    
		setCargo({...cargo,...data});
		props.propsData({...cargo,...data}); 
	}

	const onDataMarkMerge =(data) => {
		setMarkList(data);    
		setCargo({...cargo,...data});
		props.propsData({...cargo,...data}); 
    }
  	return (
  		<>
			{view &&
			<Row>
				<Col xl="4" lg="4" md="12">
					<FormGroup>
						<Label className="mb-0">Bookmark Name</Label>
						<Input 
							type="text" 
							name="cargo_bookmark_name" 
							id="cargo_bookmark_name" 
							value={cargo.cargo_bookmark_name?cargo.cargo_bookmark_name:'선택'}
							invalid={!cargo.cargo_bookmark_name?true:false}
							onChange = {(event)=>onHandleReturnVal(event,'cargo_bookmark_name')} onBlur={onPropsReturn}/>
						<FormFeedback>{validation.REQ_MSG}</FormFeedback>
					</FormGroup>       
				</Col>
			</Row>}
			<Row>
				<Col xl="3" lg="2" md="12">
					<FormGroup>
						<Label className="mb-0">대표 HS Code</Label>
						<i id="hscode" className="fa fa-search fa-2X" style={{cursor:'pointer'}}/>
						<Popover className="popover-container" isOpen={popOpen} toggle={()=> setPopOpen(!popOpen)} placement="right-start" target="hscode" style={{zIndex:'9999'}}>
							<HsCode onClose={(e)=>setPopOpen(e)} onHsCodeData={onHsCode} onSetHsCode={(data)=>{setCargo({...cargo, 'cargo_hs_code':data}); props.mergeData({...cargo, 'cargo_hs_code':data}); setPopOpen(false);}} {...props}/>
						</Popover>
						<InputValid 
							type="text"
							name="cargo_hs_code" 
							id="cargo_hs_code" 
							maxLength="6"
							minLength="6"
							value={cargo.cargo_hs_code?cargo.cargo_hs_code:''}
							onChange={(event)=>onHandleReturnVal(event,'cargo_hs_code')}
							onBlur={onPropsReturn}
							validtype="number"
							required={!view&&!cargo.cargo_hs_code?true:false}
							feedid="cargo"
						/>
					</FormGroup>
					
				</Col>
				<Col xl="4" lg="4" md="12">
					<FormGroup>
						<Label className="mb-0">PackType&Qty</Label>
						<Row>
							<Col className="col-8 pr-1" style={{zIndex:'2'}}>
								<Select
									className="customSelect"
									name="cargo_pack_type"
									value={{value:cargo.cargo_pack_type?cargo.cargo_pack_type:'',
											label:cargo.cargo_pack_type?(pack.findIndex(x=>x.value===cargo.cargo_pack_type)>=0)?pack[pack.findIndex(x=>x.value===cargo.cargo_pack_type)].label:'선택':'선택'}}
									getOptionLabel = {options=>options.label+" ["+options.value+"] "}
									onChange = {(value)=>onHandleSelectReturnVal(value.value,'cargo_pack_type')}
									options={pack}
									styles={{
										control: provided => ({...provided,border:!view&&!cargo.cargo_pack_type?'1px solid red':'',minHeight:'40px',height:'40px' }),
										indicatorsContainer: provided => ({...provided,height:'40px'})
									}}/>
								<InputValid
									hidden
									name="cargo_pack_type"
									id="cargo_pack_type"
									maxLength="2"
									value={cargo.cargo_pack_type?cargo.cargo_pack_type:''}
									validtype="eng"
									required={!view?true:false} 
									readOnly
									feedid="cargo"
								/>
							</Col>
							<Col className="col-4 pl-1">
								<InputValid 
									type="text"
									name="cargo_pack_qty"
									id="cargo_pack_qty"
									maxLength="8"
									value={cargo.cargo_pack_qty?cargo.cargo_pack_qty:''}
									onChange={(e)=>onHandleReturnVal(e, 'cargo_pack_qty')}
									onBlur={onPropsReturn}
									validtype="number"
									required={!view?true:false} 
									feedid="cargo"
								/>
							</Col>
						</Row>
					</FormGroup>
				</Col>
				<Col xl="5" lg="5" md="12">
					<Row>
						<Col className="col-6">
							<FormGroup>
								<Label className="mb-0">Weight</Label>
								<InputValid 
									type="text" 
									name="cargo_total_weight"
									id="cargo_total_weight"
									maxLength="18"
									invalid={!cargo.cargo_total_weight?true:false}
									value={cargo.cargo_total_weight?cargo.cargo_total_weight:''}
									onChange = {(event)=>onHandleReturnVal(event,'cargo_total_weight')} 
									onBlur={onPropsReturn} 
									feedid="cargo"
									inputgrouptext="KG"
									validtype="decimal"
									decimallength="3"
								/>
							</FormGroup>
						</Col>
						<Col className="col-6">
							<FormGroup>
								<Label className="mb-0">Volume</Label>
								<InputValid 
									type="text" 
									name="cargo_total_volume"
									id="cargo_total_volume"
									maxLength="18"
									validtype="decimal"
									decimallength="3"
									invalid={!cargo.cargo_total_volume?true:false}
									value={cargo.cargo_total_volume?cargo.cargo_total_volume:''} 
									onChange={(event)=>onHandleReturnVal(event,'cargo_total_volume')}
									onBlur={onPropsReturn}
									feedid="cargo"
									inputgrouptext="CBM"
								/>
							</FormGroup>
						</Col>
					</Row>
				</Col>
			</Row>
			{view&&<div className="mt-2">Bookmark Add</div>}
			<hr className="mb-2 mt-0"/>
			<Row>
				<Col xl="12" className="col-12">
					<Row>
						<Col>
							<Label className="mt-2" style={{fontWeight:'bold',fontSize:'15px',color:'#696969'}}>Mark & No</Label>
						</Col>
					</Row>
					<Row>
						<Col>
							<MarkArea 
								propsMarkData={(data)=>onDataMarkMerge(data)}
								view={view} relation={props.relation} mark={markList} {...props} />
						</Col>
					</Row>
				</Col>
				<Col xl="12" className="col-12">
					<Row>
						<Col>
							<Label className="mt-2" style={{fontWeight:'bold',fontSize:'15px',color:'#696969'}}>Cargo Description</Label>
						</Col>
					</Row>
					<Row>
						<Col>
							<GoodsArea 
								propsGoodsData={(data)=>onDataGoodsMerge(data)}
								view={view} relation={props.relation} goods={goodsList} {...props} />
						</Col>
					</Row>
				</Col>
			</Row>
    	</>
    );
}