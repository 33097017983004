import React,{useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
//import Grid from '@material-ui/core/Grid';
//import GridItem from "muiComponents/Grid/GridItem.js";
//import GridContainer from "muiComponents/Grid/GridContainer.js";
import Card from "muiComponents/Card/Card.js";
import CardHeader from "muiComponents/Card/CardHeader.js";
import CardBody from "muiComponents/Card/CardBody.js";
//import Icon from "@material-ui/core/Icon";
//import CardIcon from "muiComponents/Card/CardIcon.js";
import {Grid,  Table, TableBody, TableRow, TableCell, TableHead} from '@material-ui/core';
import Button from "muiComponents/CustomButtons/Button.js";
import CardIcon from "muiComponents/Card/CardIcon.js";
//import IconM from "@material-ui/core/Icon";
import axios from 'axios';
//import CustomSelect from "muiComponents/CustomInput/CustomSelect.js";
import {Snackbar,Tooltip} from '@material-ui/core';
// import MuiAlert from '@material-ui/lab/Alert';
import SearchIcon from '@material-ui/icons/Search';
import Assignment from "@material-ui/icons/Assignment";
import CustomInput from "muiComponents/CustomInput/CustomInput.js";
import CalendarBox from "muiComponents/CustomInput/CustomCalendar.js";
import Moment from 'moment';
const useStyless = makeStyles(theme => ({

  headerCell: {
    backgroundColor: "#f2fefd",
    width:'30%',
  padding:'7px',
  textAlign:'right'
  },
  bodyCell: {
    textAlign: "left",
  padding:'7px',
  },
  tableHeaderCellStyle: {
    borderStyle:'solid',
    borderColor:'#dbdbdb',
    borderWidth:'1px',
    padding:'7px',
    backgroundColor:'#f2fefd',
    width:'15%'
  },tableCellStyle: {
    borderStyle:'solid',
    borderColor:'#dbdbdb',
    borderWidth:'1px',
    padding:'7px',
  }
}));
// function Alert(props) {
// 	return <MuiAlert elevation={6} variant="filled" {...props} />;
// }


export default function SimlXamrttXtrnUserQry(props) {
	const {alertMessage} = props;
//   const [severity, setSeverity] = useState("");
  //const {store} =props;
  const classes = useStyless();
  //const [cntrList, setCntrList] = useState([]);
  const [cntrCnt, setCntrCnt] = useState('0');
  //const [gubunCode, setGubunCode] = useState("A01");
  const [baseDt, setBaseDt] = useState(new Date());
  const [hsSgn, setHsSgn] = useState("");
//   const [alertOpen, setAlertOpen] = useState(false);
//   const [errMessage, setErrmessage] = useState("");
  const [gridData, setGridData] = useState([]);
  
//   const handleAlertClose = (event, reason) => {
//     if(reason ==='clickaway') {
//       return;
//     }
//     setAlertOpen(false);
//   }
//   const AlertMessage = (message,icon) => {
//     setErrmessage(message)
//     setSeverity(icon)
//     setAlertOpen(true);
//   }

  const handleClick = (event) => {
	  const anchor = document.querySelector('#api015_top');
	  if(anchor) {
		  anchor.scrollIntoView();
	  }
  };
  
  const onSubmit = () => {
	 
	  if(props.userData) {
			 
		    axios.post("/com/uniPassApiSimlXamrttQry",{param1:Moment(baseDt).format('YYYYMMDD') , param2:hsSgn}).then(
		      res => {
		        if(res.data.message === "SUCCESS") {
		          setCntrCnt(res.data.infoData.cnt);
		          setGridData(res.data.infoData.data);
		        }else if (res.data.message === "NO_DATA") {
		          alertMessage("조회결과가 없습니다.","error");
		        }else {
		          alertMessage(res.data.errMsg,"error")
		        }
		      }
		    ).catch(err => {
	            if(err.response.status === 401) {
		        	props.openLogin();
		        }
	            });
		 } else {
			props.openLogin();
		 }
  }
  return (
    <div id="api015_top">
    <Card>
      <CardBody style={{paddingTop:'0',paddingBottom:'0'}}>
      <Grid item xs={12} sm={9} md={12}>
			     	<Grid container spacing={1} justify="space-between">
			     	<Grid item xs={12} md={3}>
				     	<CalendarBox
							labelText ="기준일자"
							id="baseDt"
							//variant="inline"
							format="yyyy-MM-dd"
							//inputVariant="outlined"
							//margin="dense"
							setValue={baseDt}
							autoOk={true}
							onChangeValue={date => setBaseDt(date)}
							formControlProps={{style:{marginTop:'11px'},fullWidth: true}} 
				     	/>
			     	</Grid>	
			     	<Grid item xs={12} md={3}>
				     		<CustomInput
					            labelText={
					              <span>
					              <font size="2">HS부호</font>
					              </span>
					            }
					            id="hsSgn"
					            formControlProps={{
					              fullWidth: true
					            }}
					            inputProps={{
					            	onChange: event => setHsSgn(event.target.value)
					            }}   
				          	/>	
			     		</Grid>
              <Grid item xs={2} sm={2} md={2} style={{textAlignLast:'right', paddingTop:"20px"}}>
                <Button color="info" onClick = {onSubmit} endIcon={<SearchIcon/>} >SEARCH</Button>
              </Grid>
            </Grid>
      </Grid>
      </CardBody>
      </Card>
      <Card>
        <CardHeader color="info" stats icon >
	        <CardIcon color="info" style={{padding:'0'}}>
	        <Assignment />
	      </CardIcon>
          <h4 style={{textAlign: "left",color:"#000000", paddingTop:"10px"}}><b>간이정액 환급율표</b></h4>
          <span style={{textAlign: "right",color:"#000000", paddingRight:"20px", paddingTop:"20px"}}>TOTAL: {cntrCnt}</span>
        </CardHeader>
        <CardBody>
	        <Table style={{borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7',borderCollapse:'unset'}}>
		        <TableHead>
		          <TableRow>
		            <TableCell className={classes.tableHeaderCellStyle} style={{width:'4%'}}>단위당환급원화금액</TableCell>
		            <TableCell className={classes.tableHeaderCellStyle}>규격</TableCell>
		            <TableCell className={classes.tableHeaderCellStyle} style={{width:'5%'}} >환급금액기준구분코드</TableCell>
		            <TableCell className={classes.tableHeaderCellStyle} style={{width:'3%'}}>적용일자</TableCell>
		            <TableCell className={classes.tableHeaderCellStyle} style={{width:'3%'}}>중지일자</TableCell>
		            <TableCell className={classes.tableHeaderCellStyle} style={{width:'3%'}} >HS10단위</TableCell>
		          </TableRow>
		        </TableHead>
		        <TableBody>
		          {
		              gridData.map((element,key) => {
		                  return(
		                    <TableRow key={key}>
		                      <TableCell className={classes.tableCellStyle}>{element.prutDrwbWncrAmt._text}</TableCell>
		                      <TableCell className={classes.tableCellStyle}>{element.stsz._text}</TableCell>
		                      <TableCell className={classes.tableCellStyle}>{element.drwbAmtBaseTpcd._text}</TableCell>
		                      <TableCell className={classes.tableCellStyle}>{element.aplyDd._text}</TableCell>
		                      <TableCell className={classes.tableCellStyle}>{element.ceseDt._text}</TableCell>
		                      <TableCell className={classes.tableCellStyle}>{element.hs10._text}</TableCell>
		                    </TableRow>
		                  )
		                  })
		          }
		        </TableBody>
	        </Table>
          </CardBody>
        </Card>
        {gridData.length > 10?
	            <div className={"fixed-plugin"} style={{top:'85%',width:'45px',right:'2%',borderRadius:'8px'}}>
				    <div onClick={handleClick}>
				    	<Tooltip title="Scroll Top">
				    		<i className="fa fa-angle-double-up fa-3x" style={{color:'white'}}/>
				    	</Tooltip>
				    </div>
				</div>:null
	            }
    {/* <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
		<Alert 
			onClose={handleAlertClose}
			severity={severity}>
				{errMessage}

		</Alert>
	</Snackbar> */}
  </div>
  );
}
