import React, { useState, useEffect } from 'react';
import {Row, Col, FormGroup,Label,Input, Card, CardTitle, CardSubtitle, CardBody, CardFooter, Button, UncontrolledTooltip} from "reactstrap";
import {Tooltip, Popover} from "@material-ui/core";
import Moment from 'moment';
import axios from "axios";
import {CustomDatePickerInput} from 'components/CustomInput/reactstrap/CustomInput.js';
import { Link ,useHistory} from "react-router-dom";
import Select from "react-select";
import * as validation from 'muiComponents/common/validation.js';
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'
import ShipMap from "muiComponents/Map/ShipMap.js";

export default function BlList (props) {
  let history = useHistory();
  const [toDate,setToDate] = useState(Moment(new Date()).subtract(7,'days'));
  const [endDate,setEndDate] = useState(Moment(new Date()).add(21,'days'));
  const [data, setData] = useState([]);
  const [num, setNum] = useState(1);
  const [blNumber, setBlNumber] = useState("");
  const [totCnt, setTotCnt] = useState(0);
  const [isBottom, setBottom] = useState(false);
  const [moreTodate, setMoreTodate] = useState(Moment(new Date()).subtract(7,'days'));
  const [moreEndDate,setMoreEndDate] = useState(Moment(new Date()).add(21,'days'));
  const [moreblNumber, setMoreblNumber] = useState("");
  const [carrierList, setCarrierList] = useState([]);
  const [lineCode, setLineCode] = useState('');
  const [popupMap,setPopupMap] = React.useState(null);

  const {userData} = props;

  useEffect(() => {
    document.addEventListener('scroll', onScroll);
      if(userData){
        axios.post("/loc/getCustomLineCode",{}).then(res => setCarrierList(res.data));
      }else {
        props.openLogin();
      }
      if(props.location.state){
        const state = props.location.state;
        state.start_date&& setToDate(state.start_date); 
        state.end_date&& setEndDate(state.end_date);
    }
    return function cleanup() {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    onSubmit();
  },[userData]);

  function onScroll(){
    const view = document.getElementById('card');
    if(view.getBoundingClientRect().bottom <= window.innerHeight) {
      setBottom(true);
    }else {
      setBottom(false);
    }
  }

  const onSubmit = () => {
    if(userData){
      setNum(1);
      setMoreTodate(toDate);
      setMoreEndDate(endDate);
      setMoreblNumber(blNumber);
      if(toDate>endDate){
        props.onAlert("error",validation.DATE_MSG); 
        return false;
      }
      axios.post("/shipper/selectBlList",{
        userNo:userData?userData:'',
        bl_no:blNumber,
        toDate:Moment(toDate).format('YYYYMMDD'),
        endDate:Moment(endDate).format('YYYYMMDD'),
        num:1,
        lineCode: lineCode,
         key:'LOGISVIEW'
        }).then(
        res => {
          if(res.statusText==="OK") {
            if(res.data.length > 0) {
              setTotCnt(res.data[0].tot_cnt);
              setData(res.data);
              props.onAlert("success",validation.OK_CHECK_MSG);
            }else {
              setData([]);
              setTotCnt(0);
              props.onAlert("error",validation.FAIL_CHECK_MSG);
            }
          }
        } 
      )
    }else{
      props.onAlert("error",validation.NO_USER_MSG);
    }
  }

  const onScrolltoTop =() => {
    window.scrollTo(0,0);
  }

  const onMore = (param) => {
    if(userData){
      if((param-1) !== Number(data[0].tot_page)) {
        setNum(param);
        axios.post("/shipper/selectBlList",{
          userNo:userData?userData:''
          , bl_no:moreblNumber
          , toDate:Moment(moreTodate).format('YYYYMMDD')
          , endDate:Moment(moreEndDate).format('YYYYMMDD')
          , num:param
          , lineCode: lineCode
          , key:'LOGISVIEW'}).then(res => {
          setData([...data,...res.data])
        });
      }else {
        props.onAlert("error",validation.LAST_PAGE_MSG);
      }
    }else{ 
      props.onAlert("error",validation.NO_USER_MSG);
    }
  }

  const confirmShpBlDown =(value)=> {
    const {imagebl_path, imagebl_name, mbl_no}=value;

    var filepath =  '/DATA2/BLIMAGE/'+imagebl_path+'/';
    axios.post("/api/boardFIleDownLoad",{filePath:filepath,fileName:imagebl_name,file:mbl_no,type:'bl'},{responseType:'arraybuffer',headers:{'Content-Type':'application/json','Accept':'application/pdf'}}).then(res => { 
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href=url;
        link.setAttribute('download',imagebl_name);
        document.body.appendChild(link);
        link.click();
    }).catch(err => {
        props.onNotiAlert("danger","다운로드에 실패했습니다. 관리자에 문의바랍니다.");
    });
  }
  
  const openPopup = async(data)=>{
    if(!userData){
        props.onAler('error',validation.NOTLOGIN_MSG);
        return false
    }
    
    await axios.post('/api/oraMenuAccessLog', 
    {modalName : '/bkglist/shipmap',
        user: userData ? userData.user_no : 'GUEST',});//pathName

    setPopupMap(								
        <Popover
            id="popover_map"
            open={true}
            onClose={()=>setPopupMap(null)}
            anchorReference="anchorPosition"
            anchorPosition={{top:80,left:550}}
            anchorOrigin={{vertical:'bottom',horizontal:'center',}}
            transformOrigin={{vertical:'top',horizontal:'center',}}>
            <ShipMap parameter={data} onClose={()=>setPopupMap(null)} {...props}></ShipMap>
        </Popover>
        );	    
  }

  return (
    <>
      {/* <Col className="ml-auto mr-auto mt-4" xs="11"> */}
        <Card className="card-raised card-form-horizontal no-transition mb-4 responsive" id="card">
          <CardTitle>
            <div className='search_option'>
                <Col xl='4' className='search_option--number'>
                  <FormGroup row className='search_option--number--formGroup' >
                      <Col className='search_option--number--text col-4'>
                          CARRIER
                      </Col>
                      <Col className='search_option--number--input col-8'>
                          <Select
                          className="customSelect "
                          name="lineCode"
                          value={{
                              value:lineCode||'',
                              label:lineCode?
                              (carrierList.findIndex(x=>x.value===lineCode)>=0)?
                              carrierList[carrierList.findIndex(x=>x.value===lineCode)].label:
                                '선택':'선택'
                          }}
                          onChange={(value)=>setLineCode(value&&value.id||'')}
                          options={carrierList}
                          getOptionLabel={(option)=>option.value+option.label}
                          isClearable={lineCode?true:false}
                          />
                      </Col>
                  </FormGroup> 
              </Col> 
              <Col xl='5' className='search_option--calendar'>
                <FormGroup row>
                  <Label className='search_option--calendar--text'>선적일자</Label>
                  <Col>
                    <CustomDatePickerInput
                      id="startDate"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      value={toDate}
                      onChange={(date)=>setToDate(date)}
                      {...props}/>
                  </Col>
                  <div className='search_option--calendar--text'>
                    ~
                  </div>
                  <Col>
                    <CustomDatePickerInput
                      id="endDate"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      value={endDate}
                      onChange={(date)=>setEndDate(date)}
                      {...props}/>
                  </Col>
                </FormGroup>  
              </Col>
              <Col xl='3' className='search_option--number'>
                  <FormGroup row className='search_option--number--formGroup' >
                    <Col className='search_option--number--text'>
                    BL NUMBER                          
                    </Col>
                    <Col className='search_option--number--input'>
                      <Input 
                        type="text" 
                        id="bknum"
                        placeholder="BL Number"
                        maxLength="50"
                        value={blNumber}
                        onChange={(e)=>setBlNumber(e.target.value.toUpperCase())}
                        onKeyPress ={(e)=> {"Enter" === e.key && blNumber&&onSubmit();}}
                        />
                    </Col>
                  </FormGroup>
                </Col>
              </div>
            </CardTitle>
            <div className='result_option'>
              <CardSubtitle className="text-left result_count">
                <span>[ Data Count: {data.length}건 / {totCnt}건 ]</span>
              </CardSubtitle>
              <Button
                  color="primary"
                  className='search_option--search'
                  onClick={()=>onSubmit()}>SEARCH</Button>  
            </div>
            <CardBody className="result_table" >
              <Row className="table_th bg-light border-top">
                <div className='table_th--no border-right border-left border-bottom'># </div> 
                <Col>
                  <Row >
                    <Col md="6" xs="12">
                      <Row className='table_th--rows'>
                        <Col className="table_th--text" xs="4">MBL NO</Col>
                        <Col className="table_th--text" xs="2">STATUS</Col>
                        <Col className="table_th--text" xs="3">MRN</Col>
                        <Col className="table_th--text" xs="3">SR NO</Col>
                      </Row>
                    </Col>
                    <Col md="2" xs="12">
                      <Row className='table_th--rows'>
                        <Col className="table_th--text" xs="4">CARRIER</Col>
                        <Col className="table_th--text" xs="8">VESSEL NAME</Col>
                      </Row>
                    </Col>
                    <Col md="4" xs="12">
                      <Row className='table_th--rows'>
                        {/* <Col className="table_th--text" xs="3">POL</Col>
                        <Col className="table_th--text" xs="3">POD</Col> */}
                        <Col  xs="5" style ={{padding:0, display:'flex'}}>
                          <div className="table_th--text" style ={{width:'50%'}}>POL</div>
                          <div className="table_th--text" style ={{width:'50%'}}>POD</div>
                        </Col>
                        <Col className="table_th--text" xs="3">ODB</Col>
                        <Col className="table_th--text" xs="2">BL CNTR</Col>
                        <Col className="table_th--text" xs="2">BL DOWN</Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {data.length > 0 &&
              <>
                {data.map((value,key) => {
                  return(
                  
                    <Row  key={key} className="table_tb table_tb--hover border-bottom pb-3">
                      <div className="table_tb--no text-center pt-3 pl-1 pr-1">{value.rownum}
                      </div>
                      <Col>
                        <Row>
                          <Col md="6" xs="12">
                            <Row className='table_tb--rows'>
                              <Col className=" text-center pt-3" xs="4">
                                {/* <Link key={key} to={{  
                                  pathname: 'W' === value.bkg_type
                                  ?`/svc/blWDFC`
                                  :'E'=== value.bkg_type
                                      ?'/svc/blPlism':`/svc/bl`,
                                state: {user_no:value.user_no, mbl_no:value.mbl_no, issue_date:value.issue_date,line_code: value.line_code||null}}}> */}
                                <Button
                                    className="btn-link"
                                    color="primary"
                                    type="button"
                                    style={{width:'100%',padding:'0',backgroundColor : 'transparent'}}
                                    onClick={()=>{
                                      if( 'W' === value.bkg_type &&value.user_no !== userData.user_no){
                                        props.onAlert("error", '위동 선사는 작성 유저만 조회가능합니다.');
                                        return false;
                                    }
                                    history.push({
                                      pathname: 'W' === value.bkg_type
                                      ?`/svc/blWDFC`
                                      :'E'=== value.bkg_type
                                          ?'/svc/blPlism':`/svc/bl`,
                                      state: {user_no:value.user_no, mbl_no:value.mbl_no, issue_date:value.issue_date,line_code: value.line_code||null}
                                    });
                                    }}
                                    disabled={value?.status_cus=='EA'?true:false}
                                    // cssModule ={{'btn-primary.disabled':{backgroundColor : 'transparent'},'btn-primary':{backgroundColor : 'transparent'}}}
                                    >{value.mbl_no}
                                    </Button>
                                  {/* </Link> */}
                              </Col>
                              <Col className=" text-center pt-3" xs="2">{value.status_cus_nm}</Col>
                              <Col className=" text-center pt-3" xs="3">{value.mrn}</Col>
                              <Col className=" text-center pt-3" xs="3">
                              {/* <Link key={key} to={{
                                pathname: 'W' === value.bkg_type
                                ?`/svc/srWDFC`
                                :'E'=== value.bkg_type
                                    ?'/svc/srPlism':`/svc/sr`,
                                  state:{user_no:props.userData?props.userData.user_no:null,
                                  sr_no:value.sr_no?value.sr_no:null,
                                  sr_date:value.sr_date?value.sr_date:null,
                                  line_code: value.line_code||null,
                                  confirm_yn:'Y'		  
                                }}}> */}
                                    <Button
                                    className="btn-link"
                                    color="primary"
                                    type="button"
                                    style={{width:'100%',padding:'0'}}
                                    onClick={()=>{
                                      if( 'W' === value.bkg_type &&value.user_no !== userData.user_no){
                                        props.onAlert("error", '위동 선사는 작성 유저만 조회가능합니다.');
                                        return false;
                                      }
                                      history.push({
                                        pathname: 'W' === value.bkg_type
                                        ?`/svc/srWDFC`
                                        :'E'=== value.bkg_type
                                            ?'/svc/srPlism':`/svc/sr`,
                                          state:{user_no:props.userData?props.userData.user_no:null,
                                          sr_no:value.sr_no?value.sr_no:null,
                                          sr_date:value.sr_date?value.sr_date:null,
                                          line_code: value.line_code||null,
                                          confirm_yn:'Y'		  
                                        }
                                      });
                                    }}
                                    >     
                                    {value?.sr_no} 
                                    {/* {value?.status_cus === 'EA'?<><br/>정정</>:''} */}
                                    </Button>
                                {/* </Link> */}
                                </Col>
                              
                            </Row>
                          </Col>
                          <Col md="2" xs="12">
                            <Row className='table_tb--rows'>
                              <Col className=" text-center pt-3" xs="4">
                                  {value.logo_img?
                                  <LoadCarrierImage line_code={value.line_code} logo={value.logo_img} classStyle={"img-rounded img-responsive"} 
                                  width={42} height={40} kr_name={value.line_kr_name}
                                  />
                                  :value.line_kr_name||value.line_code}
                              </Col>
                              <Col className=" text-center pt-3" xs="8">  
                              {value.sch_vessel_name&& <Tooltip title="현재 선박위치 조회">
                                <div onClick={e =>openPopup(value)} style ={{textDecoration: 'underline', cursor:'pointer'}}>
                                  {value.sch_vessel_name}<br/>
                                  {value.sch_vessel_voyage ? '('+value.sch_vessel_voyage+')':''}
                                </div>
                              </Tooltip>}
                              </Col>
                            </Row>
                          </Col>
                          <Col md="4" xs="12">
                            <Row className='table_tb--rows'>
                              {/* <Col className=" text-center pt-3" xs="3"><span data-html="true" data-toggle="tooltip" title={value.sch_pol_name} >{value.sch_pol}</span></Col>
                              <Col className=" text-center pt-3" xs="3"><span data-html="true" data-toggle="tooltip" title={value.sch_pod_name} >{value.sch_pod}</span></Col> */}
                              <Col  xs="5" style ={{padding:0, display:'flex'}}>
                                <div className=" text-center pt-3" style ={{width:'50%'}}><span data-html="true" data-toggle="tooltip" title={value.sch_pol_name}>{value.sch_pol}</span></div>
                                <div className=" text-center pt-3" style ={{width:'50%'}}><span data-html="true" data-toggle="tooltip" title={value.sch_pod_name}>{value.sch_pod}</span></div>
                              </Col>
                              <Col className=" text-center pt-3" xs="3">{value.sch_obd_format}</Col>
                              <Col className=" text-center pt-3" xs="2">{value.cntr_count}</Col>
                              <Col className=" text-center pt-3" xs="2">
                                {((value.status_cus === 'FA' || value.status_cus === 'RA' || value.status_cus === 'EA') && value.imagebl_path && value.imagebl_name) &&
                                  <Button
                                    className="btn-link"
                                    color="primary"
                                    type="button"
                                    onClick={()=>confirmShpBlDown(value)}
                                    style={{width:'100%',padding:'0'}}
                                    disabled = {value?.status_cus=='EA'?true:false}
                                    > DownLoad
                                  </Button>}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
              </>}
              {popupMap}
            </CardBody>
            <CardFooter className="text-center">
              {data.length > 0 &&
                <Button
                size="lg"
                className="btn-round"
                onClick={() => {
                  onMore(num + 1);
                }}
                color="primary">
                <span>More&nbsp;{num}&nbsp;/&nbsp;{data.length!==0?data[0].tot_page:"0"}</span>
                </Button>}
              {isBottom &&
                <Button
                style={{float:'right'}}
                size="sm"
                id="scrollTop"
                onClick={() => {onScrolltoTop()}}
                color="link">
                <i className="fa fa-chevron-circle-up fa-2x"></i>
                <UncontrolledTooltip delay={0} target="scrollTop">TOP</UncontrolledTooltip>
              </Button>}
            </CardFooter>
        </Card>   
      {/* </Col> */}
    </>
  )
}




