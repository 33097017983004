import React, { useState, useEffect } from 'react';
import { Row,Col, FormGroup,Input,Card,CardHeader,CardBody,FormFeedback} from "reactstrap";
//import AlertModal from 'components/Modals/Alert.js';
import axios from 'axios';
//import InputValid from "components/CustomInput/InputValid.js";
import Select from "react-select";
import * as validation from 'components/common/validation.js';
export default function Goods(props) {	
	const {goods,bookmark3, lineConfigDetail} = props;	
	const [goodsData, setGoodsData] = useState({});
	const [inputValue, setInputValue] = useState("");
	const [cardHeight, setCardHeight] = useState('200px');

	useEffect(() => {
		setGoodsData(goods);
	},[goods]);

	useEffect(()=> {
		if(goodsData?.goods_desc||goodsData?.goods_desc==='') {
			var goodsSplit = goodsData.goods_desc.split('\n');
			//split Text null check
			let spTxt1 = '';
			let spTxt2 = '';
			if(goodsSplit.length>0){
				spTxt1 = goodsSplit[0] ||'';
				spTxt2 = goodsSplit[1] ||'';
			}

			if(spTxt1.includes(`SHIPPER'S LOAD. COUNT & SEAL`)&&spTxt2.includes(`SAID TO CONTAIN :`)) {
				const newArr = goodsSplit.filter((value,index)=> index !== 0&&index !== 1)
				setInputValue(newArr.join("\n"));
			}else if(spTxt1.includes('COMMODITY')||spTxt1.includes(`SHIPPER'S LOAD. COUNT & SEAL`)||spTxt1.includes(`SAID TO CONTAIN :`)) {
				if( spTxt1=='COMMODITY:'&&goodsData.commodity){
					goodsSplit[0] = "COMMODITY:".concat(goodsData?.commodity||'');
					const goodsDescNew = goodsSplit.join("\n");
					setGoodsData({...goodsData, 'goods_desc':goodsDescNew});
					props.propsGoodsData({...goodsData, 'goods_desc':goodsDescNew});
					return ;
				}
				const newArr = goodsSplit.filter((value,index)=> index !== 0)
				setInputValue(newArr.join("\n"));
			}else {
				setInputValue(goodsData.goods_desc||'');
			}
		}else {
			if(goodsData?.commodity) {
				setInputValue("COMMODITY:".concat(goodsData.commodity).concat("\n"));
				setGoodsData({...goodsData, 'goods_desc':"COMMODITY:".concat(goodsData.commodity).concat("\n")});
			}else {
				setInputValue("");
			}
		}
	},[goodsData]);

	const onChangeGoods =(value)=> {
		if(value) {
			if(value.value > 0) {
				axios.post("/shipper/getUserGoodsBookmark",{user_no:props.userData?props.userData.user_no:'',seq:value.value}).then(res => {
					if(props.type !== "BOOKMARK") {
						let list;
						let goodsSplit = res.data[0].goods_desc.split('\n');
						// console.log(lineConfigDetail)
						if(lineConfigDetail?.goods_desc||lineConfigDetail?.goods_desc===''){
							// console.log(',.>>>>',res.data[0].goods_desc)
							list={...res.data[0],goods_desc:`${lineConfigDetail?.goods_desc?lineConfigDetail.goods_desc+'\n':''}${res.data[0].goods_desc}`}
						}else{
							let spText = goodsSplit[0] ||'';
							if(goodsData.commodity && !spText.includes('COMMODITY')) {
								list={...res.data[0],goods_desc:`COMMODITY:${goodsData.commodity}\n ${res.data[0].goods_desc}`}
							}else if(goodsData?.commodity && spText.includes('COMMODITY')) {
								goodsSplit[0]=`COMMODITY:${goodsData.commodity}`;
								const goodsDescNEW=goodsSplit.join("\n");
								list={...res.data[0],goods_desc:goodsDescNEW}
							}else if(!goodsData.commodity && spText.includes('COMMODITY')) {
								list={...res.data[0],goods_desc:res.data[0].goods_desc}
							}else {
								list={...res.data[0],goods_desc:`COMMODITY:\n ${res.data[0].goods_desc}`}
							}
						}
						// console.log(list)
						props.propsGoodsData(list);
					}else {
						//console.log(res.data[0]);
						props.propsGoodsData(res.data[0]);
					}
				});
			} else {
				setGoodsData([]);
			}
		}else {
			if(props.type !== "BOOKMARK") {
				var list;
				if(lineConfigDetail?.goods_desc||lineConfigDetail?.goods_desc==''){
					list = {
						...goodsData,
						goods_desc: lineConfigDetail?.goods_desc||'',
						cargo_goods_bookmark_seq:null,
						cargo_goods_bookmark_name:null,
					}
				}else{
					if(goodsData.commodity) {
						list = {
							...goodsData,
							goods_desc:`COMMODITY:${goodsData.commodity}\n`,
							cargo_goods_bookmark_seq:null,
							cargo_goods_bookmark_name:null,
						}
					}else {
						list = {
							...goodsData,
							goods_desc:`COMMODITY:\n`,
							cargo_goods_bookmark_seq:null,
							cargo_goods_bookmark_name:null,
						}
					}
				}
				setInputValue("");
				setGoodsData(list);
				props.propsGoodsData(list);
			}else {
				setInputValue("");
				setGoodsData({...goodsData,
					'goods_desc':null,
					'cargo_goods_bookmark_seq':null,
					'cargo_goods_bookmark_name':null});
				props.propsGoodsData({...goodsData,
					'goods_desc':null,
					'cargo_goods_bookmark_seq':null,
					'cargo_goods_bookmark_name':null});
			}
		}
	}
	const onPropsReturn = ()=> {
		props.propsGoodsData(goodsData);
	}
	const textareaLimit = (text) =>{
		let lines = text.split('\n');
		for(let i=0;i<lines.length;i++) {

			if( validation.getByte(lines[i]) <= 35)  continue;

			let j=0;
			const lineLen = lines[i].length;
			let space = lineLen;
			while(j++ <= lineLen) {
				var lengthData= lines[i].substring(0,j);
				if(validation.getByte(lengthData) <= 35) {
					space = lengthData.length;
				}
			}

			lines[i+1] = lines[i].substring(space) + (lines[i+1] || "");
			lines[i]=lines[i].substring(0,space);
		}
		setInputValue(lines.join('\n'));
		
		
		var returnText;
		
			if(goodsData?.goods_desc){
				if(goodsData.goods_desc===''|| props.type == "BOOKMARK"){
					returnText =lines.join("\n");
				}else if(goodsData.goods_desc) {
					var goodsSplit = goodsData.goods_desc.split('\n');
					if((goodsSplit[0]||'').includes(`SHIPPER'S LOAD. COUNT & SEAL`)&&(goodsSplit[1]||'').includes(`SAID TO CONTAIN :`)){
						returnText = goodsData.goods_desc.split('\n')[0].concat("\n")+goodsData.goods_desc.split('\n')[1].concat("\n")+lines.join("\n");
					}else if((goodsSplit[0]||'').includes('COMMODITY')||(goodsSplit[0]||'').includes(`SHIPPER'S LOAD. COUNT & SEAL`)||(goodsSplit[0]||'').includes(`SAID TO CONTAIN :`)){
						returnText = goodsData.goods_desc.split('\n')[0].concat("\n")+lines.join("\n");
					}else{
						returnText =lines.join("\n");
					}
				}else {
					lines[0]= "COMMODITY:\n";
					for(var i = 1; i < lines.length; i++) {
						lines[i] = lines[i-1];
					}
					returnText = lines.join("\n");
				}
			}else{
				if(lineConfigDetail.goods_desc==='' || props.type == "BOOKMARK"){
					returnText =lines.join("\n");
				}else if(lineConfigDetail?.goods_desc) {
					var goodsSplit = lineConfigDetail.goods_desc.split('\n');

					//split Text null check
					let spTxt1 = '';
					let spTxt2 = '';
					if(goodsSplit.length>0){
						spTxt1 = goodsSplit[0] ||'';
						spTxt2 = goodsSplit[1] ||'';
					}

					if(spTxt1.includes(`SHIPPER'S LOAD. COUNT & SEAL`)&&spTxt2.includes(`SAID TO CONTAIN :`)) {
						returnText = goodsSplit[0].concat("\n")+goodsSplit[1].concat("\n")+lines.join("\n");
					}else if(spTxt1.includes('COMMODITY')||spTxt1.includes(`SHIPPER'S LOAD. COUNT & SEAL`)||spTxt1.includes(`SAID TO CONTAIN :`)) {
						returnText = goodsSplit[0].concat("\n")+lines.join("\n");
					}else{
						returnText =lines.join("\n");
					}
				}else {
					lines[0]= "COMMODITY:\n";
					for(var i = 1; i < lines.length; i++) {
						lines[i] = lines[i-1];
					}
					returnText = lines.join("\n");
				}
			}
		setGoodsData({...goodsData, 'goods_desc':returnText});
	}
  
	return (
		<>
			<Card className="no-transition mb-2" style={{border:'1px solid silver', minHeight:'310px'}}>
			{(!props.relation) &&
				<CardHeader className="pt-1 pb-1">
					<Row>
						<Col xl="6" className="col-12">
							<Select
								className="customSelect bg-white"
								name="cargo_goods_bookmark_seq"
								value={{value:goodsData.cargo_goods_bookmark_seq?goodsData.cargo_goods_bookmark_seq:'',
								label:goodsData.cargo_goods_bookmark_seq?
									(bookmark3.findIndex(x=>x.value===goodsData.cargo_goods_bookmark_seq)>=0)?
											bookmark3[bookmark3.findIndex(x=>x.value===goodsData.cargo_goods_bookmark_seq)].label:
										'선택':'선택'
								}}
								onChange = {(value)=>onChangeGoods(value,'cargo_goods_bookmark_seq')}
								options={bookmark3}
								isClearable={goodsData.cargo_goods_bookmark_seq?true:false}/>

						</Col>
					</Row>
				</CardHeader>}
				{goodsData.goods_desc 
				&& props.type!=="BOOKMARK" 
				//하드코딩,....삭제필요 옵션화로 체크하자
				&&((goodsData?.goods_desc.split('\n')[0]||'').includes(`COMMODITY`)
					|| (goodsData?.goods_desc.split('\n')[0]||'').includes(`SAID TO CONTAIN :`)
					|| (goodsData?.goods_desc.split('\n')[0]||'').includes(`SHIPPER'S LOAD. COUNT & SEAL`))
				&&
				<CardHeader className="pt-0 pb-0">
					<Row>
						<Col xl="6" className="col-12">
							{goodsData?.goods_desc && goodsData.goods_desc.split("\n").map((data,key) => {
								if(key===0||(key===1&&data==='SAID TO CONTAIN :')) {
									return (
										<div key={key}>
											<Row>
												<Col xl="0" className="col-0 pl-0 pr-0 text-center" style={{width:'30px'}}>
													<div key={"gh_"+key}style={{backgroundColor:'yellow',height:'19.5px'}}>{key+1}</div>
												</Col>
												<Col>
													<span key={"th_"+key}>{data}</span>
												</Col>
											</Row>
										</div>
									)
								}
							})}
						</Col>
					</Row>
				</CardHeader>}
				<CardBody className="pt-2 pb-3">
					<Row style={{height:cardHeight,overflow:'auto'}}>
						<Col xl="0" className="col-0 pl-0 pr-0 text-center" style={{paddingTop:'10px',width:'30px', display:'flex', flexDirection:'column', justifyContent:'space-between', paddingBottom:'10px'}}>
						{inputValue && inputValue.split("\n").map((data,key) => {
							// const backgroundLength = props.goods_desc?props.goods_desc.includes('COMMODITY')?15:16:16
							if(goodsData.goods_desc?goodsData.goods_desc.split('\n').length>2?(goodsData.goods_desc.split('\n')[1]||'').includes(`SAID TO CONTAIN :`):false:false){
								return (
									<div key={"g_"+key}style={{backgroundColor:key+1<15?'yellow':'white',height:'19.5px'}}>{key+3}</div>
								)
							}else if(goodsData.goods_desc?(goodsData.goods_desc.split('\n')[0]||'').includes(`COMMODITY`)||(goodsData.goods_desc.split('\n')[0]||'').includes(`SAID TO CONTAIN :`):false){
								return (
									<div key={"g_"+key}style={{backgroundColor:key+1<15?'yellow':'white',height:'19.5px'}}>{key+2}</div>
								)
							}else if(goodsData.goods_desc?(goodsData.goods_desc===''||!(goodsData.goods_desc.split('\n')[0]||'').includes(`COMMODITY`)):false){
								return (
									<div key={"g_"+key}style={{backgroundColor:key+1<15?'yellow':'white',height:'19.5px'}}>{key+1}</div>
								)
							}else{				
								return (
									<div key={"g_"+key}style={{backgroundColor:key+1<15?'yellow':'white',height:'19.5px'}}>{key+2}</div>
								)
							}
						})}
						</Col>
						<Col className="p-0">
							<FormGroup className="mb-0" >
								<Input 
									style={{lineHeight:'1.4',overflow:'hidden',resize:'unset',paddingRight:'4px',paddingLeft:'4px',textTransform:'uppercase'}}
									disabled={props.view &&!props.relation ?true:false} 
									invalid={!props.view && !inputValue?true:(!validation.EDICharsetCheck(inputValue) || !validation.koreanBan(inputValue) ||(inputValue&&inputValue.split("\n").length)>495 )?true:false}
									className="border-input"
									rows={inputValue?inputValue.split("\n").length>6?inputValue.split("\n").length:8:8}
									type="textarea"
									value={inputValue?inputValue:''}
									onChange={(event)=>textareaLimit(event.target.value.replace(/\t/g," "))}
									onBlur={onPropsReturn}/>
								<FormFeedback feedid="cargo">{
									!validation.EDICharsetCheck(inputValue)
										?validation.EDICheckText(inputValue)
										:!validation.koreanBan(inputValue)
											?validation.KOREAN_BAN_MSG
											:(inputValue&&inputValue.split("\n").length)>495 
												?'글자 수가 허용 범위를 초과하였습니다.'
												:validation.REQ_MSG
										}
								</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<div>line:{inputValue?inputValue.split("\n").length:0} {' / '}
						 byte:{validation.getByte(inputValue?inputValue.split("\n")[inputValue?inputValue.split("\n").length-1:0]:'')}
					</div>
				</CardBody>
			</Card>
		</>
	);
}

