/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import {Row, Col, CardBody, Collapse, UncontrolledTooltip,Button, Card, FormGroup, Label} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import ForwarderBookmark from './ForwarderBookmark.js';
import InputValid from "components/CustomInput/InputValid.js";


const ForwarderCard = forwardRef((props, forwarderFocus) => {
    // Collapse Flag
    const [coll, setColl] = useState(false);
    // modal 창을 위한 state
    const [booking, setBooking] = useState({});
    const [forwarderList, setForwarderList] = useState([]);
    const {userData, forwarderCompanyList, bookingKecaYN} = props;


    useEffect(() => {
        // console.log(' 1booking: ', booking,'forwarderCompanyList,::',forwarderCompanyList )
        if( booking.bkg_no && booking.status_cus =='NO') {
            // console.log(' 2booking: ', booking,'forwarderCompanyList,::',forwarderCompanyList )
            // 단건 일땐 오라클에서 명칭 조회해서 세팅
            if( forwarderCompanyList.length === 1 && !(booking.fwd_code || booking.fwd_user_tel || booking.fwd_user_name || booking.fwd_user_email || booking.fwd_name1)) {
                axios.post("/com/getCompanyInfo",{ klnetId: forwarderCompanyList[0].klnet_id,}).then(res => {
                    // console.log(res)
                    let company = res.data[0];
                    if( company ) {
                        // 포워더 인 경우 EMAIL 자동세팅
                        if("Y" === booking.user_forwarder_yn && userData) {
                            setBooking({...booking
                                ,fwd_user_name:userData.user_name_en
                                ,fwd_user_tel:userData.user_tel
                                ,fwd_user_email:userData.user_email
                                ,fwd_business_number: company.REG_NO
                                ,fwd_code:forwarderCompanyList[0].partner_code
                                ,fwd_name1:company.CNAME_EN});
                            props.fncBookingParent({...booking
                                ,fwd_user_name:userData.user_name_en
                                ,fwd_user_tel:userData.user_tel
                                ,fwd_user_email:userData.user_email
                                ,fwd_business_number: company.REG_NO
                                ,fwd_code:forwarderCompanyList[0].partner_code
                                ,fwd_name1:company.CNAME_EN});
                        } else {
                            // FORWARDER 아닌 경우 그냥 기본정보만 세팅
                            setBooking({...booking
                                ,fwd_business_number: company.REG_NO
                                ,fwd_code:forwarderCompanyList[0].partner_code
                                ,fwd_name1:company.CNAME_EN});
                            props.fncBookingParent({...booking
                                ,fwd_business_number: company.REG_NO
                                ,fwd_code:forwarderCompanyList[0].partner_code
                                ,fwd_name1:company.CNAME_EN});
                        }
                    } else {
                        setBooking({...booking
                            ,fwd_user_name:userData.user_name_en
                            ,fwd_user_tel:userData.user_tel
                            ,fwd_user_email:userData.user_email
                            ,fwd_business_number:null
                            ,fwd_address1:null
                            ,fwd_address2:null
                            ,fwd_address3:null
                            ,fwd_address4:null
                            ,fwd_address5:null
                            ,shp_code:null, shp_name1:null});
                        props.fncBookingParent({...booking
                            ,fwd_user_name:userData.user_name_en
                            ,fwd_user_tel:userData.user_tel
                            ,fwd_user_email:userData.user_email
                            ,fwd_business_number:null, shp_code:null, shp_name1:null});
                    }
                });
                // 다건인 경우엔 그냥 포워더 정보만 세팅
            } else if ( forwarderCompanyList.length > 1 && userData) {
                // console.log(' booking3: ', booking,'forwarderCompanyList,::',forwarderCompanyList )
                if("Y" === booking.user_forwarder_yn && userData) {
                    setBooking({...booking
                        ,fwd_user_name:userData.user_name_en
                        ,fwd_user_tel:userData.user_tel
                        ,fwd_user_email:userData.user_email});
                    props.fncBookingParent({...booking
                        ,fwd_user_name:userData.user_name_en
                        ,fwd_user_tel:userData.user_tel
                        ,fwd_user_email:userData.user_email});
                }
            }
        }
    },[forwarderCompanyList , booking.bkg_no]);

    useEffect(()=>{
        setColl(props.openWindow);
    },[props.openWindow]);

    useEffect(()=>{
        if( props.booking.bkg_no ) {
            setBooking(props.booking);
        }
    },[props.booking]);
    useEffect(()=>{
        setForwarderList(props.forwarderList)
    },[props.forwarderList]);

    const fncSelectForwarder = ( e ) =>{
        if( e ) {
            if( 1 > e.value ) {
                if ( coll ) {
                    setColl(!coll)
                }
            } else {
                forwarderList.forEach((element, key)=>{
                if( e.value === element.forwarder_bookmark_seq) {
                    setBooking({...booking
                        ,'forwarder_bookmark_seq': element.forwarder_bookmark_seq
                        ,'forwarder_bookmark_name': element.forwarder_bookmark_name
                        ,'fwd_name1': bookingKecaYN==='Y'?(element.fwd_name1?element.fwd_name1:booking.fwd_name1):(element.fwd_name_eng?element.fwd_name_eng:booking.fwd_name1)
                        ,'fwd_name2': element.fwd_name2?element.fwd_name2:booking.fwd_name2
                        ,'fwd_code': element.fwd_code?element.fwd_code:booking.fwd_code
                        ,'fwd_user_email': element.fwd_user_email?element.fwd_user_email:booking.fwd_user_email
                        ,'fwd_user_fax': element.fwd_user_fax?element.fwd_user_fax:booking.fwd_user_fax
                        ,'fwd_user_name': bookingKecaYN==='Y'?(element.fwd_user_name?element.fwd_user_name:booking.fwd_user_name):(element.fwd_user_name_eng?element.fwd_user_name_eng:booking.fwd_user_name)
                        ,'fwd_user_tel': element.fwd_user_tel?element.fwd_user_tel:booking.fwd_user_tel
                        ,'fwd_user_dept': element.fwd_user_dept?element.fwd_user_dept:booking.fwd_user_dept
                        ,'fwd_address1': element.fwd_address1?element.fwd_address1:booking.fwd_address1
                        ,'fwd_address2': element.fwd_address2?element.fwd_address2:booking.fwd_address2
                        ,'fwd_address3': element.fwd_address3?element.fwd_address3:booking.fwd_address3
                        ,'fwd_address4': element.fwd_address4?element.fwd_address4:booking.fwd_address4
                        ,'fwd_address5': element.fwd_address5?element.fwd_address5:booking.fwd_address5
                        ,'selected_yn': 'Y'
                    });
                    props.fncBookingParent({...booking
                        ,'forwarder_bookmark_seq': element.forwarder_bookmark_seq
                        ,'forwarder_bookmark_name': element.forwarder_bookmark_name
                        ,'fwd_name1': bookingKecaYN==='Y'?(element.fwd_name1?element.fwd_name1:booking.fwd_name1):(element.fwd_name_eng?element.fwd_name_eng:booking.fwd_name1)
                        ,'fwd_name2': element.fwd_name2?element.fwd_name2:booking.fwd_name2
                        ,'fwd_code': element.fwd_code?element.fwd_code:booking.fwd_code
                        ,'fwd_user_email': element.fwd_user_email?element.fwd_user_email:booking.fwd_user_email
                        ,'fwd_user_fax': element.fwd_user_fax?element.fwd_user_fax:booking.fwd_user_fax
                        ,'fwd_user_name': bookingKecaYN==='Y'?(element.fwd_user_name?element.fwd_user_name:booking.fwd_user_name):(element.fwd_user_name_eng?element.fwd_user_name_eng:booking.fwd_user_name)
                        ,'fwd_user_tel': element.fwd_user_tel?element.fwd_user_tel:booking.fwd_user_tel
                        ,'fwd_user_dept': element.fwd_user_dept?element.fwd_user_dept:booking.fwd_user_dept
                        ,'fwd_address1': element.fwd_address1?element.fwd_address1:booking.fwd_address1
                        ,'fwd_address2': element.fwd_address2?element.fwd_address2:booking.fwd_address2
                        ,'fwd_address3': element.fwd_address3?element.fwd_address3:booking.fwd_address3
                        ,'fwd_address4': element.fwd_address4?element.fwd_address4:booking.fwd_address4
                        ,'fwd_address5': element.fwd_address5?element.fwd_address5:booking.fwd_address5
                        ,'selected_yn':'Y'
                    });
                }
                });
                if ( !coll ) {
                    setColl(!coll);
                }
            }

        } else {
            if( booking.forwarder_bookmark_seq) {
                setBooking({...booking
                    ,'forwarder_bookmark_seq': null
                    ,'forwarder_bookmark_name': null
                    ,'fwd_name1': null
                    ,'fwd_name2': null
                    ,'fwd_code': null
                    ,'fwd_user_email': null
                    ,'fwd_user_fax': null
                    ,'fwd_user_name': null
                    ,'fwd_user_tel': null
                    ,'fwd_user_dept': null
                    ,'fwd_address1':null
                    ,'fwd_address2':null
                    ,'fwd_address3':null
                    ,'fwd_address4':null
                    ,'fwd_address5':null
                    ,'selected_yn': 'Y'
                });
                props.fncBookingParent({...booking
                    ,'forwarder_bookmark_seq': null
                    ,'forwarder_bookmark_name': null
                    ,'fwd_name1': null
                    ,'fwd_name2': null
                    ,'fwd_code': null
                    ,'fwd_user_email': null
                    ,'fwd_user_fax': null
                    ,'fwd_user_name': null
                    ,'fwd_user_tel': null
                    ,'fwd_user_dept': null
                    ,'fwd_address1':null
                    ,'fwd_address2':null
                    ,'fwd_address3':null
                    ,'fwd_address4':null
                    ,'fwd_address5':null
                    ,'selected_yn':'Y'
                });
            }
        }
    }
    const fncOnChange = ( e, key ) => {
        if(key==='fwd_address2'&&!booking.fwd_address1){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        if(key==='fwd_address3'&&(!booking.fwd_address1||!booking.fwd_address2)){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        if(key==='fwd_address4'&&(!booking.fwd_address1||!booking.fwd_address2||!booking.fwd_address3)){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        if(key==='fwd_address5'&&(!booking.fwd_address1||!booking.fwd_address2||!booking.fwd_address3||!booking.fwd_address4)){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        e.preventDefault();
        setBooking({...booking, [key]:(e.target.value.toUpperCase())||null});
    }


    const fncSearchBn =()=>{
        if( !booking.fwd_business_number ) return false;
        if( booking.fwd_business_number.length < 10) return false;
            if( booking.fwd_business_number && booking.fwd_business_number.length === 10 ) {
                let row = forwarderCompanyList.find(v=>v.business_number === booking.fwd_business_number)
                if( row ) {
                    axios.post("/com/getCompanyInfo",{ klnetId: row.klnet_id,}).then(res => {
                        let company = res.data[0];
                        if( company.REG_NO ) {
                            setBooking({...booking, fwd_business_number:company.REG_NO, fwd_code:row.partner_code, fwd_name1:company.CNAME_EN})
                            props.fncBookingParent({...booking, fwd_business_number:company.REG_NO, fwd_code:row.partner_code, fwd_name1:company.CNAME_EN});
                        } else {
                            setBooking({...booking, fwd_business_number:null, fwd_code:null, fwd_name1:null})
                            props.fncBookingParent({...booking, fwd_business_number:null, fwd_code:null, fwd_name1:null});
                        }
                    });
                } else {
                    props.onAlert("danger", "결과가 없습니다.");
                    setBooking({...booking, fwd_business_number:null, shp_code:null, fwd_name1:null})
                    props.fncBookingParent({...booking, fwd_business_number:null, fwd_code:null, fwd_name1:null});
                }
            }
    }

    const fncOnBlurBusinessNumber = (e) => {
        if( !e.target.value ) {
            setBooking({...booking, fwd_business_number:null, fwd_code:null, fwd_name1:null})
            props.fncBookingParent({...booking, fwd_business_number:null, fwd_code:null, fwd_name1:null});
        }
    }

    const fncOnKeyPress = async(e)=>{
        // enter 인 경우 
        if( 13 === e.charCode ) {
            if( e.target.value.length < 10) return false;
            if( e.target.value && e.target.value.length === 10 && booking.fwd_business_number !== booking.business_number ) {
                let row = forwarderCompanyList.find(v=>v.business_number === e.target.value)
                if( row ) {
                    axios.post("/com/getCompanyInfo",{ klnetId: row.klnet_id,}).then(res => {
                        let company = res.data[0];
                        if( company.REG_NO ) {
                            setBooking({...booking, fwd_business_number:company.REG_NO, fwd_code:row.partner_code, fwd_name1:company.CNAME_EN})
                            props.fncBookingParent({...booking, fwd_business_number:company.REG_NO, fwd_code:row.partner_code, fwd_name1:company.CNAME_EN});
                        } else {
                            setBooking({...booking, fwd_business_number:null, fwd_code:null, fwd_name1:null})
                            props.fncBookingParent({...booking, fwd_business_number:null, fwd_code:null, fwd_name1:null});
                        }
                    });
                } else {
                    props.onAlert("danger", "결과가 없습니다.");
                    setBooking({...booking, fwd_business_number:null, fwd_code:null, fwd_name1:null})
                    props.fncBookingParent({...booking, fwd_business_number:null, fwd_code:null, fwd_name1:null});
                }
            }
        }
    }
    return (
        <>
            <Row id="Forwarder">
                <Col xl="12" lg="12">
                    <Card style={{zIndex:'75'}}>
                        <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>  
                            <Row className="pb-2">
                                <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>FORWARDER
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="col-10 pr-0">
                                            <Select
                                                className="react-select react-select-primary"
                                                name="lineBookmark"
                                                value={{value:booking.forwarder_bookmark_seq?booking.forwarder_bookmark_seq:''
                                                ,label:booking.forwarder_bookmark_name?booking.forwarder_bookmark_name:'선택'}}
                                                onChange={(e)=>fncSelectForwarder(e?e:null)}
                                                options={forwarderList}
                                                placeholder="선택"
                                                ref={forwarderFocus}
                                                isClearable={booking.forwarder_bookmark_seq?true:false}
                                            />
                                        </Col>
                                        <Col className="col-2 pl-auto pr-auto">
                                            <ForwarderBookmark
                                                forwarderList={forwarderList}
                                                selectBookingForwarderBookmark={props.selectBookingForwarderBookmark}
                                                onAlert={props.onAlert}
                                                forwarderCompanyList={forwarderCompanyList}
                                                bookingKecaYN={bookingKecaYN}
                                                {...props}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Collapse isOpen={coll}>
                                <hr className="mt-0"/>
                                <Row>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">사업자번호</Label></Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="fwd_business_number"
                                                        id="fwd_business_number"
                                                        placeholder="사업자 번호 입력 후 엔터"
                                                        maxLength="10"
                                                        value={booking.fwd_business_number?booking.fwd_business_number:''}
                                                        onChange={(e)=>fncOnChange(e, 'fwd_business_number')}
                                                        onBlur={(e) => {fncOnBlurBusinessNumber(e)}}
                                                        onKeyPress={(e)=>fncOnKeyPress(e)}
                                                        validtype="number" 
                                                        required={false} 
                                                        feedid="forwarder"
                                                        inputgrouptext={<i className="fa fa-search " onClick={()=>fncSearchBn()}/>}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Code</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_code"
                                                    id="fwd_code"
                                                    maxLength="10"
                                                    value={booking.fwd_code?booking.fwd_code:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_code')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="engNumber" 
                                                    required={booking.shp_payment_type||(booking.fwd_user_tel||booking.fwd_user_dept||booking.fwd_user_email||booking.fwd_user_fax
                                                        ||booking.fwd_name1||booking.fwd_address1)?true:false} 
                                                    // required={booking.shp_payment_type?true:false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Name</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_name1"
                                                    id="fwd_name1"
                                                    maxLength="35"
                                                    value={booking.fwd_name1?booking.fwd_name1:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_name1')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    required={("Y" === booking.user_forwarder_yn)||(booking.fwd_user_tel||booking.fwd_user_dept||booking.fwd_user_email||booking.fwd_user_fax
                                                        ||booking.fwd_code||booking.fwd_address1)?true:false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">User</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_user_name"
                                                    id="fwd_user_name"
                                                    maxLength="17"
                                                    value={booking.fwd_user_name?booking.fwd_user_name:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_user_name')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    required={("Y" === booking.user_forwarder_yn)||(booking.fwd_user_tel||booking.fwd_user_dept||booking.fwd_user_email||booking.fwd_user_fax)?true:false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Tel</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_user_tel"
                                                    id="fwd_user_tel"
                                                    maxLength="25"
                                                    value={booking.fwd_user_tel?booking.fwd_user_tel:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_user_tel')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="tel"
                                                    // required={true} 
                                                    required={("Y" === booking.user_forwarder_yn)||(booking.fwd_user_tel||booking.fwd_user_dept||booking.fwd_user_email||booking.fwd_user_fax)?true:false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Fax</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_user_fax"
                                                    id="fwd_user_fax"
                                                    maxLength="25"
                                                    value={booking.fwd_user_fax?booking.fwd_user_fax:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_user_fax')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="tel"
                                                    required={false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Dept</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_user_dept"
                                                    id="fwd_user_dept"
                                                    maxLength="35"
                                                    value={booking.fwd_user_dept?booking.fwd_user_dept:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_user_dept')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    required={false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">E-mail</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_user_email"
                                                    id="fwd_user_email"
                                                    maxLength="50"
                                                    value={booking.fwd_user_email?booking.fwd_user_email:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_user_email')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="email"
                                                    required={("Y" === booking.user_forwarder_yn)||(booking.fwd_user_tel||booking.fwd_user_dept||booking.fwd_user_email||booking.fwd_user_fax)?true:false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Address</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_address1"
                                                    id="fwd_address1"
                                                    maxLength="35"
                                                    value={booking.fwd_address1?booking.fwd_address1:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_address1')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    required={booking.fwd_code||booking.fwd_name1?true:false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_address2"
                                                    id="fwd_address2"
                                                    maxLength="35"
                                                    value={booking.fwd_address2?booking.fwd_address2:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_address2')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    required={false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_address3"
                                                    id="fwd_address3"
                                                    maxLength="35"
                                                    value={booking.fwd_address3?booking.fwd_address3:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_address3')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    required={false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_address4"
                                                    id="fwd_address4"
                                                    maxLength="35"
                                                    value={booking.fwd_address4?booking.fwd_address4:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_address4')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    required={false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="fwd_address5"
                                                    id="fwd_address5"
                                                    maxLength="35"
                                                    value={booking.fwd_address5?booking.fwd_address5:''}
                                                    onChange={(e)=>fncOnChange(e, 'fwd_address5')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng"
                                                    required={false} 
                                                    feedid="forwarder"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Collapse>
                            <div className="text-center" onClick={() => setColl(!coll)}>
                                <div>         
                                    <Button className="p-0" color="link" id="linemore" onClick={() => setColl(!coll)} style={{height:'21px'}}>
                                        {coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="linemore">{coll?'Close':'Open'}</UncontrolledTooltip>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
});

export default ForwarderCard;