import React,{useState,useRef, useEffect, forwardRef,useLayoutEffect, useCallback, useMemo, useImperativeHandle} from 'react';
import { Link, useHistory} from "react-router-dom";
import cn from 'classnames';
import _ from 'lodash';
import Moment from 'moment';
import Style from './main.module.css'
import axios from 'axios';
import Slider from 'react-slick'
import {Tooltip,} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import MonthPickersCustom from 'muiComponents/CustomInput/MonthPickersCustom';
import * as validation from 'components/common/validation.js'
import SelectBox from './CustomSelectBox.js';
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'


// import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Spinner} from 'reactstrap';
// import PlaceholderImg from 'assetsNew/image/No-Image.gif';
const CustomPrevArrow=(props)=>{
    const {onClick}=props;
  
    return (<div type="button" className={Style.btn} onClick={onClick}>
                    <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-prev01.png`} /></div>)
}
const CustomNextArrow=(props)=>{
    const {onClick}=props;
    
    return  (<div type="button" className={Style.btn} onClick={onClick} ><img
                        src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-next01.png`} /></div>)
}
const TooltipCustom = withStyles((theme)=>({
    tooltip:{
        // color:'red',
        fontSize:12
    },
    tooltipPlacementTop:{
        margin:'2px 0'
    }
}))(Tooltip)
const ScheduleItemWrap = (props)=>{
    let history = useHistory();
    const { onAlert, userData } =props;
    const searchRef = useRef();

    const [ schAllWrapStatus,setSchAllWrapStatus ]=useState( false)
    const [ fclData,setFclData ]=useState( {fcl_month:new Date(), pol:'', pod:''})
    const [ portData , setPortData] =useState([])
	const [sPort,setSPort] = useState(''); 
	const [ePort,setEPort] = useState('');
    
    const [ serviceList , setServiceList ] = useState([]);
    const [ schCarrierList,setSchCarrierList ]=useState([]);

    const [ selected, setSelected] = useState([]);
    const slideSetting ={
        dots:false,
        infinite: true,
        slidesToShow:4,
        slidesToScroll:4,
        prevArrow: <CustomPrevArrow/>
        ,nextArrow:<CustomNextArrow/>
        ,lazyLoad:true
        // ,variableWidth:false
        // ,centerMode:true
    }
    useEffect(() => {
        getPortCodeInfo();

        axios.post("/api/getCarrierInfo",{})
        .then(res => {
            setSchCarrierList(res.data)
            setServiceList(res.data);
        })
        .catch(err => {
            console.log(err)
            if(err.response.status !== 403||err.response.status !== 401) {
                onAlert('error','선사 정보를 불러오는데 실패하였습니다')
            }
        });
    }, []);

    const getPortCodeInfo =()=>{
        axios.post(`/api/getPortCodeInfo`,{})
        .then(res => {      
            // console.log(res.data)
            setPortData(res.data);})
        .catch(err => {
            if(err.response.status !== 403||err.response.status !== 401) {
                // alertMessage('[오류] PORT 조회할 수 없습니다', 'error');
                setPortData([])
            }
        });
    }
// const serviceList= useMemo(()=>{
//     if(!searchTerm){
//          return schCarrierList;
//     }
//     return schCarrierList.filter(carrier =>{
//         return carrier.line_name2.includes(searchTerm)
//         ||carrier.sch_line_code.includes(searchTerm);
//     })
// },[searchTerm,schCarrierList])
    const onSearchTerm = useCallback ((key)=>{
        if(!key){
            setServiceList(schCarrierList);
        }else {
            setSchAllWrapStatus(true);
            setServiceList(schCarrierList.filter(carrier =>{
                return carrier.line_name2.includes(key)
                ||carrier.sch_line_code.includes(key);
            }));
        }
    },[schCarrierList]);

    // const handlePortvalue =(itemType,inputVal)=>{
    //     setFclData({...fclData, [itemType]: inputVal})
    // }

    const onSubmitFcl = ()=>{
        // if(!fclData.pod||!fclData.pol){
        if(!sPort||!ePort){
            onAlert('error','POL, POD는(은) 필수 항목 입니다.');
            return false
        }
        let startDay ;
        if (fclData.fcl_month.getMonth() == new Date().getMonth()){
            startDay = Moment(new Date()).format('yyyy-MM-DD');
        }else {
            startDay =  Moment(fclData.fcl_month).startOf('month').format('yyyy-MM-DD');
        }
        history.push({
            pathname: `/svc/fcl`,
            state:{
                sch_line_code: selected.length == schCarrierList.length?null:selected ||null,
                sch_pol:sPort.port_code.toUpperCase()||null,
                sch_pod:ePort.port_code.toUpperCase()||null,
                start_date: startDay||null,
            }
        })
    }

    const selectCarrier = ( value, type )=>{
        // console.log( value)
        if (type == 'ALL'){
            onSearchTerm(''); searchRef.current.resetTerm();
            selected.length == schCarrierList.length
            ?  setSelected([]) 
            :  setSelected(schCarrierList)
        }else{
            const exists = selected.length>0&&selected.some(x=>x['sch_line_code'] == value.sch_line_code);
            let newArr = [...selected];
            if (exists){
                newArr= newArr.filter(x =>x['sch_line_code'] !==value.sch_line_code); setSelected(newArr);
            }else{
                newArr.push(value); 
                setSelected([...newArr]);
            }
        }
    }

    return (
        <div className={Style.scheduleItemWrap}>
            <HeaderSch onSearchTerm={onSearchTerm} ref={searchRef}/>
            {!schAllWrapStatus?
            <div className={Style.schSlideWrap}>
                <div className={cn(Style.key, selected.length == schCarrierList.length?Style.on:'')} 
                    onClick={()=>{selectCarrier(null,'ALL');}}>ALL</div>
                <div className={Style.schSlideList}>
                    {schCarrierList.length>0?
                    <Slider {...slideSetting} className={Style.slideItemWrap}>
                        {[...schCarrierList].sort((pre,cur)=>{ 
                            let preB =  selected&& selected.some(x=>x['sch_line_code'] == pre.sch_line_code)>0
                            let curB =  selected&& selected.some(x=>x['sch_line_code'] == cur.sch_line_code)>0
                            if(!preB&&curB) return 1;
                            if(preB&&!curB) return -1;
                        }).map((value, ind,arr)=>
                             <div data-index={ind} key={ind} className={Style.slide}   
                             onClick={()=>selectCarrier(value,null)}
                                >
                                 <TooltipCustom title={`[${value.sch_line_code}]  ${value.line_name2}`} placement='top' >
                                    <div className={cn(Style.schItem,selected.some(x=>x['sch_line_code'] == value.sch_line_code)?Style.on:'')}>
                                        {/* <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/${value.logo_img }`} /> */}
                                        {/* <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-slide-list04.png`} /> */}
                                        {/* <ImgComponent sch_line_code={value.sch_line_code} logo_img={value.logo_img}/> */}
                                        <LoadCarrierImage line_code={value.sch_line_code} logo={value.logo_img} classStyle={"img-rounded img-responsive"} 
                                        width={32} height={30}
                                        />
                                        <span>{value.line_name2}</span>
                                    </div>
                                </TooltipCustom>
                            </div>
                        )}
                    </Slider>
                    :   <>
                    <CustomPrevArrow/>
                    <div  className={Style.slideItemWrap}>
                        {[1,2,3,4].map((value, index)=>
                        <div className={Style.slide} key={index}><a href="#1"><div className={Style.schItem}><Spinner color='primary' size='sm' type='grow'/></div></a></div>
                        )}
                        {/* <div className={Style.slide}><a href="#1"><div className={Style.schItem}><Spinner color='primary' size='sm' type='grow'/></div></a></div>
                        <div className={Style.slide}><a href="#1"><div className={Style.schItem}><Spinner color='primary' size='sm' type='grow'/></div></a></div>
                        <div className={Style.slide}><a href="#1"><div className={Style.schItem}><Spinner color='primary' size='sm' type='grow'/></div></a></div> */}
                    </div>
                    <CustomNextArrow/></>
                }
                </div>
                <div className={Style.btnSchMore} onClick={()=>{setSchAllWrapStatus(true)}}>
                    <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-sch-more.png`} />
                    <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-sch-arr-down.png`} />
                </div>
            </div>
            :
            <div className={cn(Style.schAllWrap, Style.on)}>
                    <div className={cn(Style.key, selected.length == schCarrierList.length?Style.on:'')}
                         onClick={()=>{selectCarrier(null,'ALL');}}>ALL</div>
                         <div className={Style.scrolls}>
                    <ul>
                        {serviceList.length>0?
                        serviceList.map((value, ind, arr)=>{
                            return (<li key={ind} 
                            onClick={()=>selectCarrier(value,null)} 
                            >   <TooltipCustom title={`[${value.sch_line_code}]  ${value.line_name2}`} placement='top'>
                                    <div className={cn(Style.schItem,selected.some(x=>x['sch_line_code'] == value.sch_line_code)?Style.on:'')} >
                                    <LoadCarrierImage line_code={value.sch_line_code} logo={value.logo_img} classStyle={"img-rounded img-responsive"} 
                                     width={32} height={30}
                                     />
                                        <span>{value.line_name2}</span>
                                    </div>
                                </TooltipCustom>
                            </li>)
                        })
                    : <li style={{display:'flex',alignItems: 'center'}}><span>No Options...</span></li>
                    }
                    </ul>
                    </div>
                    <div className={Style.btnSchMore} onClick={()=>{setSchAllWrapStatus(false)}}>
                        <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-sch-arr-up.png`} />
                    {/* <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-layer-close.png`} /> */}
                    </div>
                
                {/* <button type="button" className={Style.btnSchClose} onClick={()=>{setSchAllWrapStatus(false)}}>
                    <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-layer-close.png`} />
                </button> */}
            </div>
            }
            <div className={Style.schSearchWrap}>
                <div className={cn(Style.selectItemWrap,Style.month)} >
                    <MonthPickersCustom 
                        type='month'
                        customInputStyle={Style.month}
                        variant="inline"
                        format="MMM"
                        setValue={fclData.fcl_month}
                        autoOk={true}
                        onChangeValue={newDate => {
                            if(newDate.getMonth() <new Date().getMonth()  ){onAlert('error',validation.DATE_CHECK_MSG); return false}
                            setFclData({...fclData, fcl_month: newDate})
                        }}
                        />                          
                </div>
                {/* POL */}
                <SelectBox  itemType='pol' id='pol' key='pol'
                            portData={portData&&portData.filter(x=>x.port_code.substring(0,2)=='KR')} 
                            // handlePortvalue={handlePortvalue} 
                            setPortValue={setSPort} portValue={sPort}
                            {...props}
                />
                {/* POd */}
               <SelectBox  itemType='pod' id='pod' key='pod'
                            portData={portData&&portData}
                            // handlePortvalue={handlePortvalue} 
                            setPortValue={setEPort} portValue={ePort}
                            {...props}
                />
                <button type="button" onClick={onSubmitFcl}  className={Style.schSearchWrapBtn}>
                    <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-search-on.png`} />
                    <span>SEARCH</span>
                </button>
            </div>
        
        </div>
)}

const HeaderSch = forwardRef((props,ref)=>{
    const {onSearchTerm} = props;
    const [ searchTerm , setSearchTerm ] = useState('');

    useImperativeHandle(ref,()=>({
        resetTerm
    }));
    
    const resetTerm = ()=> setSearchTerm('');
    const onKeyPress = (e) => {
        if(e.key==='Enter') {
            onSearchTerm(searchTerm)
        }
    }
    return <div className={Style.schHeaderWrap}>
            <div className={Style.stit}>해상 스케줄</div>
            <div className={Style.sideItemWrap}>
                <input type="text" className={Style.inp} placeholder="CARRIER NAME"
                        value={searchTerm} //ref={searchRef} 
                        onChange={(e)=>setSearchTerm(e.target.value.toUpperCase())}
                        onKeyPress={onKeyPress}
                />
                <button type="button" className={Style.btnSearch} onClick={()=>onSearchTerm(searchTerm)} >
                    <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-search.png`} />
                </button>
                <Link className={Style.btnAdd}
                        to={'/svc/fcl'}>
                    <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-add.png`} />
                </Link>
            </div>
        </div> 
})
export default ScheduleItemWrap