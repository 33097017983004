/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,Col, Card,
    CardBody, Button,FormGroup, Table, UncontrolledTooltip,
    TabContent, TabPane, Nav, NavItem, NavLink} from "reactstrap";
import axios from "axios";
import _ from 'lodash';
import Cargo from "./Cargo.js";
import classnames from 'classnames';
import GoodsBookmarkRelation from './GoodsBookmarkRelation.js';
import GoodsBookmark from './GoodsBookmark.js';
import * as validation from 'components/common/validation.js';

export default function CargoBookmark (props) {
    const [open, setOpen] = useState(false);
    // Cargo
    const [cargo, setCargo] = useState({});
    const [cargoBookmarkList, setCargoList] = useState([]);
    // Goods
    const [goods, setGoods] = useState([]);
    // Goods Bookmark List
    const [goodsBookmarkList, setGoodsBookmarkList] = useState([]);
    const [goodsRelationList, setGoodsRelationList] = useState([]);
    // 전체화면 css 적용을 위한 state
    
    // Tab
    const [activeTab, setActiveTab] = useState('1');
    const {userData, lineCode, cargoTypeList, cargoPackTypeList} = props;

    useEffect(() => {
        setCargoList(props.cargoBookmarkList);
        setGoodsBookmarkList(props.goodsBookmarkList);
    }, [props.cargoBookmarkList, props.goodsBookmarkList]);

    useEffect(() => {
        if( _.isEmpty(props.bookingCargo)){
            setCargo( {cargo_type:cargoTypeList[0]?.cargo_type, cargo_pack_type:cargoPackTypeList[0]?.cargo_pack_type});
        } else {
            setCargo(props.bookingCargo);
        }
    }, [props.bookingCargo]);

    useEffect(() => {
        if( cargo.cargo_bookmark_seq ) {
            selectBookingCargoBookmarkRelation();
        }
    }, [cargo]);

    // Cargo Bookmark 입력하기
    const fncSaveCargoBookmark=(e)=>{
        e.preventDefault(e);
        if( !cargo.cargo_bookmark_seq || '0' === cargo.cargo_bookmark_seq ) {
            insertBookingCargoBookmark();
        } else {
            updateBookingCargoBookmark();
        }
    }

    // Insert Cargo Bookmark
    const insertBookingCargoBookmark = async() => {
        if(goodsRelationList.findIndex(x=>!x?.cargo_bookmark_seq)>-1){
            props.onAlert("error", `${props?.lineConfigDetail?.bkg_goods_title||'Goods'}연결 목록을 확인하세요.`);
            return
        }

        await axios.post("/shipper/insertBookingCargoBookmark",{user_no : userData?userData.user_no:null,cargo,goodsRelationList}).then(res=>{
            props.onAlert("success", validation.SAVE_MSG);
            props.selectBookingCargoBookmark();
            props.selectBookingCargoGoodsBookmark();
        }).catch(err => {
            props.onAlert("error","저장 중 에러가 발생했습니다. 다시 시도해주세요");
        });
    }

    // Update Cargo Bookmark
    const updateBookingCargoBookmark = async() => {
        if(goodsRelationList.findIndex(x=>!x?.cargo_bookmark_seq)>-1){
            props.onAlert("error", `${props?.lineConfigDetail?.bkg_goods_title||'Goods'} 연결 목록을 확인하세요.`);
            return
        }
        await axios.post("/shipper/updateBookingCargoBookmark",{user_no : userData?userData.user_no:null,cargo,goodsRelationList}).then(res=>{
            props.onAlert("success", validation.SAVE_MSG);
            props.selectBookingCargoBookmark();
            props.selectBookingCargoGoodsBookmark();
        }).catch(err => {
            props.onAlert("error","저장 중 에러가 발생했습니다. 다시 시도해주세요");
        });
    }

    // Delete Cargo Bookmark
    const deleteBookingCargoBookmark = async() => {
        if( !cargo.cargo_bookmark_seq ) {
            props.onNotiAlert("danger", "삭제할 Bookmark를 선택하세요.");
            return false;
        }
        await axios.post("/shipper/deleteBookingCargoBookmark",{user_no : userData?userData.user_no:null,cargo}).then(res=>{
            props.onAlert("success", validation.DEL_MSG);
            props.selectBookingCargoBookmark();
            setCargo({});
        }).catch(err => {
            props.onAlert("error","삭제 중 에러가 발생했습니다. 다시 시도해주세요");
        });
    }

    const fncOnBlur = (cargo) => {
        setCargo(cargo);
    }
    const fncOnBlurGoods = (goods) => {
        setGoods(goods);
    }
    const fncOnBlurGoodsRelation = (goodsRelationList) => {
        setGoodsRelationList(goodsRelationList);
    }
    const toggle = (params) => {
                setOpen(!open);
    }

    // New Cargo Bookmark
    const fncInitCargoBookmark = (e) => {
        e.preventDefault();
        // cargo_type, cargo_pack_type 은 초기값
        setCargo( {cargo_type:'1', cargo_pack_type:'1'});
        setGoodsRelationList([{'key':1}]);
    }
    // New Goods Bookmark
    const fncInitGoods = (e) => {
        e.preventDefault();
        setGoods({});
    }

    const clickTab = (tab) => {
        if(activeTab !== tab ) setActiveTab(tab);
    }

    // Cargo Bookmark 입력하기
    const fncSaveGoodsBookmark=(e)=>{
        e.preventDefault(e);
        if( !userData ) {
            props.onNotiAlert("error",validation.NOTLOGIN_MSG);
        }
        if( !goods.cargo_goods_bookmark_seq || '0' === goods.cargo_goods_bookmark_seq ) {
            insertBookingCargoGoodsBookmark();
        } else {
            updateBoookingCargoGoodsBookmark();
        }
    }

    // Insert Goods Bookmark
    const insertBookingCargoGoodsBookmark = () => {
        axios.post("/shipper/insertBookingCargoGoodsBookmark",{user_no : userData?userData.user_no:null,goods}).then(res=>{
            props.onAlert("success", validation.SAVE_MSG);
            props.selectBookingCargoGoodsBookmark();
        });
    }

    // Update Goods Bookmark
    const updateBoookingCargoGoodsBookmark = () => {
        axios.post("/shipper/updateBoookingCargoGoodsBookmark",{user_no : userData?userData.user_no:null,goods}).then(res=>{
            props.onAlert("success", validation.SAVE_MSG);
            props.selectBookingCargoGoodsBookmark();
        });
    }

    // Delete Goods Bookmark
    const deleteBoookingCargoGoodsBookmark = () => {
        if( !goods.cargo_goods_bookmark_seq ) {
            props.onNotiAlert("danger", "삭제할 Bookmark를 선택하세요.");
            return false;
        }
        axios.post("/shipper/deleteBoookingCargoGoodsBookmark",{user_no : userData?userData.user_no:null,goods}).then(res=>{
            props.onAlert("success", validation.SAVE_MSG);
            props.selectBookingCargoGoodsBookmark();
            setCargo({});
        });
    }

    // Cargo Bookmark 조회
    const selectBookingCargoBookmarkRelation = () => {
        if(lineCode) {
            axios.post("/shipper/selectBookingCargoBookmarkRelation",{ user_no: userData?userData.user_no:null,line_code: lineCode,cargo}).then(res => {
                setGoodsRelationList(res.data);
            });
        }
    }

    
    return (
        <>
            <Button className="pl-0 pr-0" 
                color="link" id="linebookmark"
                onClick={toggle.bind(this, 'B')}>
                    <i className="fa fa-bookmark-o fa-2x" />
            </Button>
            <UncontrolledTooltip delay={0} target="linebookmark">Bookmark</UncontrolledTooltip>
            <Modal isOpen={open} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Cargo</ModalHeader>
                <ModalBody>
                    {/* Goods히든 */}
                    {(props.bookingGoodsYN==='N')?<></>: 
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active:activeTab === '1' })}
                                onClick={()=>{clickTab('1');}}
                                style={{paddingBottom: '10px'}}>
                                    <h6>Cargo</h6>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active:activeTab === '2' })}
                                onClick={()=>{clickTab('2');}}
                                style={{paddingBottom: '20px'}}>
                                    <h6>{props?.lineConfigDetail?.bkg_goods_title||'Goods'}</h6>
                                </NavLink>
                        </NavItem> 
                    </Nav>}
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col>Cargo Bookmark List</Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup style={{height:'150px',overflow:'auto'}} className="mb-0">
                                        <CardBody className="bg-white p-0">
                                            <Table className="mb-0" responsive hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <td className="p-2 bg-info">No.</td>
                                                        <td className="p-2 bg-info">Bookmark</td>
                                                        <td className="p-2 bg-info">Type</td>
                                                        <td className="p-2 bg-info">{props?.lineConfigDetail?.bkg_goods_title||'Goods'} 연결</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {(cargoBookmarkList.length>0)?cargoBookmarkList.map((element,key)=>{
                                                    // console.log(cntrList, key, element)
                                                    return(
                                                        <tr key={key} onClick={()=>{setCargo(element)}}
                                                        style={element.cargo_bookmark_seq===cargo.cargo_bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}}>
                                                            {/* <td>{element.cargo_bookmark_seq}</td> */}
                                                            <td>{cargoBookmarkList.length-key}</td>
                                                            <td>{element.cargo_bookmark_name}</td>
                                                            <td>{element.cargo_type?
                                                            (cargoTypeList.findIndex(x=>x.value===element.cargo_type)>=0)?
                                                            cargoTypeList[cargoTypeList.findIndex(x=>x.value===element.cargo_type)].label:
                                                            element.cargo_type_name||'-' :'-'}</td>
                                                            {/* <td>{element.cargo_type}</td> */}
                                                            <td data-toggle="tooltip"
                                                                id={"tooltip"+key}>{element.relation_yn}
                                                            {element.relation_bookmark?<UncontrolledTooltip
                                                                delay={0}
                                                                placement="right"
                                                                target={"tooltip"+key}
                                                            >{element.relation_bookmark}</UncontrolledTooltip>:
                                                            <></>}
                                                            </td>
                                                        </tr>
                                                    )
                                                }):<></>}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Cargo
                                cargo={cargo}
                                fncOnBlur={fncOnBlur}
                                openType="BOOK"
                                cargoTypeList={props.cargoTypeList}
                                cargoPackTypeList={props.cargoPackTypeList}
                                booking={props.booking}
                                // {...props}
                                />
                            <GoodsBookmarkRelation
                                goodsRelationList={goodsRelationList}
                                goodsBookmarkList={goodsBookmarkList}
                                fncOnBlurGoodsRelation={fncOnBlurGoodsRelation}
                                bookingGoodsYN={props?.bookingGoodsYN}
                                lineConfigDetail={props?.lineConfigDetail}
                                onAlert={props?.onAlert}
                                // {...props}
                                />
                        </TabPane>
                        {/* Goods히든 */}
                        {(props.bookingGoodsYN==='N')?<></>: 
                        <TabPane tabId="2">
                            <Row>
                                <Col>Goods Bookmark List</Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup style={{height:'150px',overflow:'auto'}} className="mb-0">
                                        <Card className="card-raised card-form-horizontal no-transition mb-0">
                                            <CardBody className="bg-white p-0">
                                                <Table className="mb-0" responsive hover size="sm">
                                                    <thead>
                                                        <tr>
                                                            <td className="p-2 bg-info">No.</td>
                                                            <td className="p-2 bg-info">Name</td>
                                                            <td className="p-2 bg-info">DESC</td>
                                                            <td className="p-2 bg-info">Cargo 연결</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {(goodsBookmarkList.length>0) && 
                                                        goodsBookmarkList.map((element,key)=>{
                                                        return(
                                                            <tr key={key} onClick={()=>{setGoods(element)}}>
                                                                <td>{element.cargo_goods_bookmark_seq}</td>
                                                                <td>{element.cargo_goods_bookmark_name}</td>
                                                                <td>{element.goods_desc1}</td>
                                                                <td data-toggle="tooltip"
                                                                    id={"tooltip"+key}>{element.relation_yn}
                                                                {element.relation_bookmark?<UncontrolledTooltip
                                                                    delay={0}
                                                                    placement="right"
                                                                    target={"tooltip"+key}
                                                                >{element.relation_bookmark}</UncontrolledTooltip>:
                                                                <></>}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <GoodsBookmark
                                goods={goods}
                                fncOnBlurGoods={fncOnBlurGoods}
                                {...props}
                                />
                        </TabPane>
                        } 
                    </TabContent>
                </ModalBody>
                {activeTab !== '2' 
                ?<ModalFooter>
                    <Button color="primary" onClick={(e)=>fncInitCargoBookmark(e)}>New</Button>{' '}
                    <Button color="primary" onClick={(e)=>fncSaveCargoBookmark(e)}>Save</Button>{' '}
                    <Button color="primary" onClick={(e)=>deleteBookingCargoBookmark(e)}>Delete</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
                :(props.bookingGoodsYN==='N')?<></>
                :<ModalFooter>
                    <Button color="primary" onClick={(e)=>fncInitGoods(e)}>New</Button>{' '}
                    <Button color="primary" onClick={(e)=>fncSaveGoodsBookmark(e)}>Save</Button>{' '}
                    <Button color="primary" onClick={(e)=>deleteBoookingCargoGoodsBookmark(e)}>Delete</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>}
            </Modal>
        </>
    );
}