import axios from 'axios';
import routes from 'service_routes.js';

//goPlism 혹은 urlPath일 경우  (나머지는 페이지내부 header.js)
export const  menuAccessLog = async (value, userData) => {
    let str = window.location.href;
    let plismMenuNo =value.plismMenuNo;
    let nameStr = getActiveRoute(routes,plismMenuNo) ||(value.path=='/'||'/home'? '메인화면':'') ;    
    try {
        let last_char = str.charAt(str.length - 1);
        if (last_char !== '#') {
            await axios.post('/api/oraMenuAccessLog', {layout:value.layout,
                                                    path:value.path,
                                                    menuNo:value.menuNo,
                                                    menuNmKr:value.menuNmKr,
                                                    plismMenuNo:value.plismMenuNo,
                                                    user:  userData?.user_no || 'GUEST',});

            await axios.post('/api/menuAccessLog', {
                user: userData?.user_no || 'GUEST',
                path: value.path ||window.location.pathname,
                name: plismMenuNo? nameStr: value.name ? value.name :value,
                systemName: 'LOGISVIEW',
            });
        }
    } catch (e) {
      // console.log(e);
    }
};


//프리즘 메뉴명 찾기 
const getActiveRoute = (routes,plismMenuNo) => {
    let activeRoute ;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views, plismMenuNo);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if(routes[i].layout=='PLISM'){
            if (            //   window.location.href.indexOf(routes[i].layout + routes[i].path+ `${routes[i].id}?/${routes[i].id}`) !== -1
            routes[i].linkParam['prodNo']==plismMenuNo||routes[i].linkParam['devNo']==plismMenuNo
            ) {
            return routes[i].name;
            }
        }
      }
    }
    return activeRoute;
  };
