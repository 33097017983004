/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row,Col, CardBody, FormGroup,Label,Input, Table
	,InputGroupAddon,InputGroupText,InputGroup}
     from "reactstrap";
import InputValid from "components/CustomInput/InputValid.js";
export default function DeclareBookmark(props){
	
  	const {bookmark,loadFormData,pack} = props;	

	useEffect(() => {
		setBookmarkList(loadFormData); 
	},[loadFormData]);

  	const [bookmarkList, setBookmarkList] = useState({});

	const onHandleReturnVal = (event,name) => {
		if(name ==='declare_pack_type' && event.target.value !=='GT') {
			props.onAlert("error","포장 유형은 PACKAGE[GT]로 만 설정 가능 합니다.");
			return;
		}
		let list = {...bookmarkList, [name]:event.target.value||null};
		setBookmarkList(list);
		
	}

	const onChangeDecReturnVal = (event,name) => {
		
		let list = {...bookmarkList, [name]:event.target.value};
		setBookmarkList(list);
		props.propsData(list);	  
	}
	const onPropsReturn = ()=> {
		props.propsData(bookmarkList);
	}

	const onPropsbookmarkList =(data) =>{
		setBookmarkList(data);
		props.propsData(data);
	}
	

  	return (
    	<>
			<Row>
				<Col>bookmarkList List</Col>
			</Row>
        	<Row className="mb-3">
				<Col xl="12" lg="12" md="12">
					<FormGroup style={{height:'150px',overflow:'auto'}} className="mb-0">
						<CardBody className="bg-white p-0">
							<Table className="mb-0" responsive hover size="sm">
								<thead>
									<tr>
										<td className="p-2 bg-info">No.</td>
										<td className="p-2 bg-info">Bookmark Name</td>
										<td className="p-2 bg-info">Declare Num</td>
									</tr>
								</thead>
								<tbody>
								{bookmark.map((element,key)=>{
									return(
										<tr key={key} onClick={()=>onPropsbookmarkList(element)} style={element.declare_bookmark_seq===bookmarkList.declare_bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}} >
											<td className="p-2">{key+1}</td>
											<td className="p-2">{element.declare_bookmark_name}</td>
											<td className="p-2">{element.declare_num}</td>
										</tr>
									)
								})}
								</tbody>
							</Table>
						</CardBody>
					</FormGroup>
				</Col>
			</Row>
            <Row>
				<Col>bookmarkList Input</Col>
			</Row>
       		<hr className="m-2"/>
			<Row>
       			<Col xl="3" lg="3" md="12">
					<FormGroup>
						<Label className="mb-0">Bookmark Name</Label>
						<InputValid 
							type="text"
							name="declare_bookmark_name"
							id="declare_bookmark_name"
							maxLength="35"
							value={bookmarkList.declare_bookmark_name?bookmarkList.declare_bookmark_name:''}
							onChange={(e)=>onHandleReturnVal(e, 'declare_bookmark_name')}
							onBlur={onPropsReturn}
							validtype="text"
							required={true} 
							feedid="declare"/>
					</FormGroup>		
				</Col>
       			<Col xl="3" lg="3" md="12">
					<FormGroup>
						<Label className="mb-0">포장유형,개수</Label>
						<Row>
							<Col className="col-8 pr-1">
								<Input 
									type="select" 
									value={bookmarkList.declare_pack_type?bookmarkList.declare_pack_type:'GT'} 
									onChange = {(event)=>onHandleReturnVal(event,'declare_pack_type')}>
									<option value="">선택</option>
									{(pack.length > 0) && pack.map((data,key) => 
										<option key ={key} value={data.cargo_pack_type}>{data.cargo_pack_type_desc+" ["+data.cargo_pack_type+"]"}</option>
									)}
								</Input>
							</Col>
							<Col className="col-4 pl-1">
								<InputValid
									type="text"
									name="declare_pack_num"
									id="declare_pack_num"
									maxLength="8"
									value={bookmarkList.declare_pack_num?bookmarkList.declare_pack_num:''}
									onChange={(e)=>onHandleReturnVal(e, 'declare_pack_num')}
									onBlur={onPropsReturn}
									validtype="number"
									required={false} 
									feedid="declare"
								/>
							</Col>
						</Row>
					</FormGroup>
				</Col>
				<Col xl="2" lg="2" md="12">
					<FormGroup>
						<Label className="mb-0">중량</Label>
						<InputValid 
							type="text"
							name="declare_weight"
							id="declare_weight"
							maxLength="18"
							value={bookmarkList.declare_weight?bookmarkList.declare_weight:''}
							onChange = {(event)=>onHandleReturnVal(event,'declare_weight')}
							onBlur={onPropsReturn}
							validtype="decimal"
							decimallength="3"
							inputgrouptext="KG"
							feedid="declare"/>
					</FormGroup>		
				</Col>
				<Col xl="2" lg="2" md="12">
					<FormGroup>
						<Label className="mb-0">분할선적여부</Label>
						<Input 
							type="select" 
							value={bookmarkList.declare_div_load_yn?bookmarkList.declare_div_load_yn:'N'}
							onChange={(event)=>onChangeDecReturnVal(event,'declare_div_load_yn')}>
							<option value="N">N</option>
							<option value="Y">Y</option>
						</Input>
					</FormGroup>
				</Col>
				<Col xl="2" lg="2" md="12">
					<FormGroup>
						<Label className="mb-0">분할선적차수</Label>
						<InputValid 
							type="text"
							name="declare_div_load_no"
							id="declare_div_load_no"
							maxLength="2"
							value={bookmarkList.declare_div_load_no?bookmarkList.declare_div_load_no:''}
							onChange={(e)=>onHandleReturnVal(e, 'declare_div_load_no')}
							onBlur={onPropsReturn}
							validtype="number"
							required={false} 
							feedid="declare"
						/>
					</FormGroup>		
				</Col>
				<Col xl="2" lg="2" md="12">
					<FormGroup>
						<Label className="mb-0">동시포장코드</Label>
						<InputValid 
							type="text"
							name="declare_pack_set_code"
							id="declare_pack_set_code"
							maxLength="1"
							value={bookmarkList.declare_pack_set_code?bookmarkList.declare_pack_set_code:''}
							onChange={(e)=>onHandleReturnVal(e, 'declare_pack_set_code')}
							onBlur={onPropsReturn}
							validtype="english"
							required={false} 
							feedid="declare"
						/>
					</FormGroup>		
				</Col>    
				<Col xl="3" lg="3" md="12">
					<FormGroup>
						<Label className="mb-0">동시포장유형,개수</Label>
						<Row>
							<Col className="col-8 pr-1">
								<Input type="select" value={bookmarkList.declare_pack_set_type?bookmarkList.declare_pack_set_type:''} onChange = {(event)=>onHandleReturnVal(event,'declare_pack_set_type')}>
									<option value="">선택</option>
									{pack.length>0?pack.map((data,key) => <option value={data.cargo_pack_type}>{data.cargo_pack_type_desc+" ["+data.cargo_pack_type+"]"}</option>):<></>}
								</Input>
							</Col>
							<Col className="col-4 pl-1">
							<InputValid 
								type="text"
								name="declare_pack_set_num"
								id="declare_pack_set_num"
								maxLength="8"
								value={bookmarkList.declare_pack_set_num?bookmarkList.declare_pack_set_num:''}
								onChange={(e)=>onHandleReturnVal(e, 'declare_pack_set_num')}
								onBlur={onPropsReturn}
								validtype="number"
								required={false} 
								feedid="declare"
							/>
							</Col>
						</Row>
					</FormGroup>
				</Col>
				<Col xl="4" lg="4" md="12">
					<FormGroup>
						<Label className="mb-0">품목명</Label>
						<InputValid 
							type="text"
							name="declare_goods_desc"
							id="declare_goods_desc"
							maxLength="35"
							value={bookmarkList.declare_goods_desc?bookmarkList.declare_goods_desc:''}
							onChange={(e)=>onHandleReturnVal(e, 'declare_goods_desc')}
							onBlur={onPropsReturn}
							validtype="english"
							required={false} 
							feedid="declare"
						/>
					</FormGroup>		
				</Col>  
			</Row>
   		</>
    );
}