/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import { Row,Col, CardBody, Collapse,
  Button,FormGroup,Label, Card, UncontrolledTooltip,Badge} from "reactstrap";
  import axios from "axios";
  import Select from "react-select";
import Moment from 'moment';
import InputValid from 'components/CustomInput/InputValid.js';

// const selectStyle = {
//     control: base => ({
//       ...base,
//       fontFamily:'Nanum Gothic',
//       fontSize:'0.8rem',
//       color:'rgb(68, 68, 68)'
  
//     }),
//     menu: base => ({
//       ...base,
//       fontFamly: "Nanum Gothic",
//       fontSize:'0.8rem',
//       color:'rgb(68, 68, 68)'
//     })
// }

const ScheduleCard = forwardRef((props, scheduleFocus) => {
  const {
    toggle,
  } = props;
  const [coll, setColl] = useState(false);
  const [booking, setBooking] = useState({});
      // Open Type
    // const [polPortCodeList, setPolPortCodeList] = useState([]);
    // const [podPortCodeList, setPodPortCodeList] = useState([]);
    const [porPortCodeList, setPorPortCodeList] = useState([]);
    const [pldPortCodeList, setPldPortCodeList] = useState([]);
    const [fdpPortCodeList, setFdpPortCodeList] = useState([]);
    // const [searchPol, setSearchPol] = useState("");
    // const [searchPod, setSearchPod] = useState("");
    const [searchPor, setSearchPor] = useState("");
    const [searchPld, setSearchPld] = useState("");
    const [searchFdp, setSearchFdp] = useState("");

  useEffect(() => {
    setColl(props.openWindow);
  }, [props.openWindow]);

  useEffect(() => {
    if (props.booking.bkg_no) {
      setBooking(props.booking);
    }
  }, [props.booking]);
  
    // useEffect(()=>{
    //     setScheduleList(props.scheduleList);
    // },[props.scheduleList]);
    // useEffect(()=>{
    //     if( !props.booking.sch_pol ) {
    //         if( "Y" !== booking.selected_yn ) {
    //             selectPolCodePortList({
    //                 port_code: null
    //             });
    //         }
    //     } else {
    //         // if( booking.sch_pol !== props.booking.sch_pol ){
    //             selectPolCodePortList({
    //                 port_code: props.booking.sch_pol
    //             });
    //         // }
    //     }
    // },[props.booking.sch_pol]);
    // useEffect(()=>{
    //     if( !props.booking.sch_pod ) {
    //         if( "Y" !== booking.selected_yn ) {
    //             selectPodCodePortList({
    //                 port_code: null
    //             });
    //         }
    //     } else {
    //         // if( booking.sch_pod !== props.booking.sch_pod ){
    //             selectPodCodePortList({
    //                 port_code: props.booking.sch_pod
    //             });
    //         // }
    //     }
    // },[props.booking.sch_pod]);
    useEffect(()=>{
        if( !props.booking.sch_por && "Y" !== booking.selected_yn ) {
                selectPorCodePortList({
                    port_code: null
                });
            // }
        } else {
            // if( booking.sch_por !== props.booking.sch_por ){
                //POR의 경우 전체 Port조회
                selectPorCodePortList({
                    port_code: props.booking.sch_por
                });
            // }
        }
    },[props.booking.sch_por]);
    useEffect(()=>{
        if( !props.booking.sch_pld ) {
            if( "Y" !== booking.selected_yn ) {
                selectPldCodePortList({
                    port_code: null
                });
            }
        } else {
            // if( booking.sch_pld !== props.booking.sch_pld ){
                //FDP의 경우 전체 Port조회
                selectPldCodePortList({
                    port_code: props.booking.sch_pld
                });
            // }
        }
    },[props.booking.sch_pld]);
    useEffect(()=>{
      if( !props.booking.sch_fdp && "Y" !== booking.selected_yn ) {
              selectFdpCodePortList({
                  port_code: null
              });
          // }
      } else {
          // if( booking.sch_fdp !== props.booking.sch_fdp ){
              //FDP의 경우 전체 Port조회
              selectFdpCodePortList({
                  port_code: props.booking.sch_fdp
              });
          // }
      }
  },[props.booking.sch_fdp]);
   // 부모로부터 파라미터 전달 받음
//    useEffect(()=>{
//     setScheduleList(props.scheduleList);
// },[props.scheduleList]);
// useEffect(()=>{
//     if( searchPol ) {
//         selectPolCodePortList({
//             port_code: searchPol
//         });
//     }
// },[searchPol])

// useEffect(()=>{
//     if( searchPod ) {
//         selectPodCodePortList({
//             port_code: searchPod
//         });
//     }
// },[searchPod]);
useEffect(()=>{
    if( searchPor ) {
        selectPorCodePortList({
            port_code: searchPor
        });
    }
},[searchPor])

useEffect(()=>{
    if( searchPld ) {
        selectPldCodePortList({
            port_code: searchPld
        });
    }
},[searchPld])
useEffect(()=>{
    if( searchFdp ) {
        selectFdpCodePortList({
            port_code: searchFdp
        });
    }
},[searchFdp])

// 입력값 적용
const fncOnChange = ( e, key ) => {
  e.preventDefault();
  setBooking({...booking, [key]:(e.target.value.toUpperCase())||null});
}
  
const fncOnChangeSelect = ( e, key ) => {
  if( e && key ) {
          setBooking({...booking, [key]:e.value , [key+'_name']:e.port_name});
      // }
  } else {
      setBooking({...booking, [key]:null , [key+'_name']:null});
  }
}

    // const selectLineCodeVesselName = (params) => {
    //     axios.post("/shipper/selectLineCodeVesselName",{ params }).then(res=>{
    //         setLineVesselList(res.data);
    //     });
    // }

    // const selectPolCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setPolPortCodeList(res.data);
    //     });
    // }
    // const selectPodCodePortList = (params)=>{
    //     axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
    //         setPodPortCodeList(res.data);
    //     });
    // }
    const selectPorCodePortList = (params)=>{
        axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
            setPorPortCodeList(res.data);
        });
    }
    const selectPldCodePortList = (params)=>{
        axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
            setPldPortCodeList(res.data);
        });
    }
    const selectFdpCodePortList = (params)=>{
      axios.post("/shipper/selectFdpCodePortList",{ params }).then(res=>{
          setFdpPortCodeList(res.data);
      });
  }


  
    // const fncOnKeyDownPol=(e)=>{
    //     const inputValue = e.replace(/\W/g,'');
    //     setSearchPol( inputValue )
    // }
    // const fncOnKeyDownPod=(e)=>{
    //     const inputValue = e.replace(/\W/g,'');
    //     setSearchPod( inputValue )
    // }
    const fncOnKeyDownPor=(e)=>{
        const inputValue = e.replace(/\W/g,'');
        setSearchPor( inputValue )
    }
    const fncOnKeyDownPld=(e)=>{
        const inputValue = e.replace(/\W/g,'');
        setSearchPld( inputValue )
    }
    const fncOnKeyDownFdp=(e)=>{
      const inputValue = e.replace(/\W/g,'');
      setSearchFdp( inputValue )
  }
  return (
    <>
      <Row id="Schedule">
        <Col xl="12" lg="12">
          <Card style={{ zIndex: '95' }}>
            <CardBody
              className="pt-3 pb-2"
              style={{ border: '1px solid silver', borderRadius: '10px' }}
            >
              <Row className="pb-2">
                <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>SCHEDULE
                                    <Button className="pl-1" color="link" id="lineview" onClick={()=>toggle('S')}><i className="fa fa-pencil-square-o fa-2x"/></Button>
                                    <UncontrolledTooltip delay={0} target="lineview">Input</UncontrolledTooltip>
                </Col>
                {//(booking.sch_etd||booking.sch_vessel_name||booking.sch_pol||booking.sch_pod) &&
                booking.sch_condition&&booking.sch_condition !=='Y'
                  ? (<Col style={{textAlign:'end'}}>
                          <Badge color="danger" size="large" style={{marginTop: '20px', fontSize: '12px'}}>*&nbsp; 
                          {booking.sch_condition&&booking.sch_condition =='N'
                                ?'스케줄 정보가 올바르지 않습니다. 새로 선택하세요'
                                :booking.sch_condition} 
                          &nbsp;*</Badge>
                      </Col>)
                  :''
                }
              </Row>
              <Collapse isOpen={coll}>
                <hr className="mt-0"/>
                <Row>
                  <Col xl="12" lg="12" md="12">
                    <FormGroup className="mb-1">
                      <Row>
                        <Col className="pr-0 pt-1 col-2">
                          <Label className="mb-0">Vessel/Voy</Label>
                        </Col>
                        <Col>
                          <Row>
                          <Col className="col-7 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
                              
                              <InputValid
                                // hidden
                                type="text"
                                name="sch_vessel_name"
                                id="sch_vessel_name"
                                maxLength="35"
                                value={
                                  booking.sch_vessel_name
                                    ? booking.sch_vessel_name
                                    : ''
                                }
                                validtype="select"
                                required={true}
                                feedid="schedule"
                                readOnly
                              />
                            </Col>
                            <Col className="text-center pl-0 pr-0 pt-1">/</Col>
                            <Col className="col-4 pl-1">
                              <InputValid
                                type="text"
                                name="sch_vessel_voyage"
                                id="sch_vessel_voyage"
                                maxLength="17"
                                value={booking.sch_vessel_voyage?booking.sch_vessel_voyage:''}
                                                                onChange={(e)=>fncOnChange(e, 'sch_vessel_voyage')}
                                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                validtype="eng"
                                required={true}
                                feedid="schedule"
                                readOnly
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <FormGroup className="mb-1">
                      <Row>
                        <Col className="pr-0 pt-1 col-2">
                          <Label className="mb-0">Call Sign</Label>
                        </Col>
                        <Col onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
                          <InputValid
                            type="text"
                            name="sch_call_sign"
                            id="sch_call_sign"
                            maxLength="9"
                            value={booking.sch_call_sign?booking.sch_call_sign:''}
                            onChange={(e)=>fncOnChange(e, 'sch_call_sign')}
                            onBlur={(e) => {props.fncBookingParent(booking)}}
                            validtype="eng"
                            required={false}
                            feedid="schedule"
                            readOnly
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <FormGroup className="mb-1">
                      <Row>
                      <Col className="pr-0 pt-1 col-2"><Label className="mb-0">POL</Label></Col>
                                                <Col sm="4" className="col-4 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
                          <InputValid
                            // hidden
                            type="text"
                            name="sch_pol"
                            id="sch_pol"
                            maxLength="5"
                            value={booking.sch_pol ? booking.sch_pol : ''}
                            validtype="select"
                            required={true}
                            feedid="schedule"
                            readOnly
                          />
                        </Col>
                        <Col sm="4" className=" pl-1">
                          <InputValid
                            type="text"
                            name="sch_pol_name"
                            id="sch_pol_name"
                            maxLength="35"
                            value={booking.sch_pol_name ? booking.sch_pol_name : ''}
                            onChange={(e)=>fncOnChange(e, 'sch_pol_name')}
                            onBlur={(e) => {props.fncBookingParent(booking)}}
                            validtype="eng"
                            required={false}
                            feedid="schedule"
                            // readOnly
                          />
                        </Col>
                        <Col className="pl-1">
                          <Label className="mt-2">
                            {booking.sch_etd
                              ? Moment(booking.sch_etd.substr(0, 8)).format(
                                  'MM-DD'
                                )
                              : ''}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <FormGroup className="mb-1">
                      <Row>
                      <Col className="pr-0 pt-1 col-2"><Label className="mb-0">POD</Label></Col>
                      <Col xl="4" lg="4" md="4" sm="4" className="col-4 pr-1" onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}>
                          {/* <Select
                                                        cachedOptions
                                                        defaultOptions
                                                        className="customSelect"
                                                        name="sch_pod"
                                                        id="select_sch_pod"
                                                        value={{value:booking.sch_pod?booking.sch_pod:'',
                                                            label:booking.sch_pod?
                                                            (podPortCodeList.findIndex(x=>x.value===booking.sch_pod)>=0)?
                                                            podPortCodeList[podPortCodeList.findIndex(x=>x.value===booking.sch_pod)].port_code:
                                                                '선택':
                                                            '선택'
                                                        }}
                                                        //onChange={(value, action)=>fncOnChangeSelect(value, 'sch_pod')}
                                                        //onBlur={(e)=>props.fncBookingParent(booking)}
                                                        //onInputChange={fncOnKeyDownPod}
                                                        //options={podPortCodeList}
                                                        isClearable={booking.sch_pod?true:false}
                                                    /> */}
                          <InputValid
                            // hidden
                            type="text"
                            name="sch_pod"
                            id="sch_pod"
                            maxLength="5"
                            value={booking.sch_pod?booking.sch_pod:''}
                            validtype="select"
                            required={true}
                            feedid="schedule"
                            readOnly
                          />
                        </Col>
                        <Col sm="4" className="pl-1">
                          <InputValid
                            type="text"
                            name="sch_pod_name"
                            id="sch_pod_name"
                            maxLength="35"
                            value={booking.sch_pod_name?booking.sch_pod_name:''}
                            onChange={(e)=>fncOnChange(e, 'sch_pod_name')}
                            onBlur={(e) => {props.fncBookingParent(booking)}}
                            validtype="eng"
                            required={false}
                            feedid="schedule"
                            // readOnly
                          />
                        </Col>
                        <Col sm="2" className="col-2 pl-1">
                            <Label className="mt-2">{booking.sch_eta?Moment(booking.sch_eta.substr(0,8)).format('MM-DD'):''}</Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <FormGroup className="mb-1">
                      <Row>
                        <Col className="pr-0 pt-1 col-2"><Label className="mb-0">POR</Label></Col>
                        <Col xl="4" lg="4" md="4" sm="4" className="col-4 pr-1" //onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}
                        >
                          {/* <InputValid
                            // hidden
                            type="text"
                            name="sch_por"
                            id="sch_por"
                            maxLength="5"
                            value={booking.sch_por ? booking.sch_por : ''}
                            validtype="select"
                            required={true}
                            feedid="schedule"
                            readOnly
                          /> */}
                          <Select
                            cachedOptions
                            defaultOptions
                            className="customSelect"
                            name="sch_por"
                            id="select_sch_por"
                            value={{
                              value: booking.sch_por ? booking.sch_por : '',
                              label: booking.sch_por
                                ? porPortCodeList.findIndex(
                                    (x) => x.value === booking.sch_por
                                  ) >= 0
                                  ? porPortCodeList[
                                      porPortCodeList.findIndex(
                                        (x) => x.value === booking.sch_por
                                      )
                                    ].port_code
                                  : '선택'
                                : '선택',
                            }}
                            onChange={(value, action)=>fncOnChangeSelect(value, 'sch_por')}
                            onBlur={(e)=>props.fncBookingParent(booking)}
                            onInputChange={fncOnKeyDownPor}
                            options={porPortCodeList}
                            isClearable={booking.sch_por ? true : false}
                          />
                        </Col>
                        <Col sm="6" className="col-8 pl-1">
                          <InputValid
                            type="text"
                            name="sch_por_name"
                            id="sch_por_name"
                            maxLength="35"
                            value={booking.sch_por_name?booking.sch_por_name:''}
                            onChange={(e)=>fncOnChange(e, 'sch_por_name')}
                            onBlur={(e) => {props.fncBookingParent(booking)}}
                            required={false}
                            validtype="eng"
                            feedid="schedule"
                            // readOnly
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <FormGroup className="mb-1">
                      <Row>
                        <Col className="pr-0 pt-1 col-2"><Label className="mb-0">PLD</Label></Col>
                        <Col xl="4" lg="4" md="4" sm="4" className="col-4 pr-1" // onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}
                        >
                          {/* <InputValid
                            // hidden
                            type="text"
                            name="sch_pld"
                            id="sch_pld"
                            maxLength="5"
                            value={booking.sch_pld ? booking.sch_pld : ''}
                            validtype="select"
                            required={true}
                            feedid="schedule"
                            readOnly
                          /> */}
                          <Select
                            cachedOptions
                            defaultOptions
                            className="customSelect"
                            name="sch_pld"
                            id="select_sch_pld"
                            value={{
                              value: booking.sch_pld ? booking.sch_pld : '',
                              label: booking.sch_pld
                                ? pldPortCodeList.findIndex(
                                    (x) => x.value === booking.sch_pld
                                  ) >= 0
                                  ? pldPortCodeList[
                                      pldPortCodeList.findIndex(
                                        (x) => x.value === booking.sch_pld
                                      )
                                    ].port_code
                                  : '선택'
                                : '선택',
                            }}
                            onChange={(value, action)=>fncOnChangeSelect(value, 'sch_pld')}
                            onBlur={(e)=>props.fncBookingParent(booking)}
                            onInputChange={fncOnKeyDownPld}
                            options={pldPortCodeList}
                            isClearable={booking.sch_pld ? true : false}
                          /> 
                        </Col>
                        <Col sm="6" className="col-8 pl-1">
                          <InputValid
                            type="text"
                            name="sch_pld_name"
                            id="sch_pld_name"
                            maxLength="35"
                            value={booking.sch_pld_name?booking.sch_pld_name:''}
                            onChange={(e)=>fncOnChange(e, 'sch_pld_name')}
                            onBlur={(e) => {props.fncBookingParent(booking)}}
                            validtype="eng"
                            required={false}
                            feedid="schedule"
                            // readOnly
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                  <Col xl="12" lg="12" md="12">
                    <FormGroup className="mb-1">
                      <Row>
                          <Col className="pr-0 pt-1 col-2"><Label className="mb-0">FDP</Label></Col>
                          <Col xl="4" lg="4" md="4" sm="4" className="col-4 pr-1" //onClick={(e)=>props.onNotiAlert("info","스케줄 입력 버튼을 이용해 수정 가능합니다.")}
                          >
                          {/* <InputValid
                            // hidden
                            type="text"
                            name="sch_fdp"
                            id="sch_fdp"
                            maxLength="5"
                            value={booking.sch_fdp ? booking.sch_fdp : ''}
                            validtype="select"
                            required={true}
                            feedid="schedule"
                            readOnly
                          /> */}
                          <Select
                            cachedOptions
                            defaultOptions
                            className="customSelect"
                            name="sch_fdp"
                            id="select_sch_fdp"
                            value={{
                              value: booking.sch_fdp ? booking.sch_fdp : '',
                              label: booking.sch_fdp
                                ? fdpPortCodeList.findIndex(
                                    (x) => x.value === booking.sch_fdp
                                  ) >= 0
                                  ? fdpPortCodeList[
                                      fdpPortCodeList.findIndex(
                                        (x) => x.value === booking.sch_fdp
                                      )
                                    ].port_code
                                  : '선택'
                                : '선택',
                            }}
                            onChange={(value, action)=>fncOnChangeSelect(value, 'sch_fdp')}
                            onBlur={(e)=>props.fncBookingParent(booking)}
                            onInputChange={fncOnKeyDownFdp}
                            options={fdpPortCodeList}
                            isClearable={booking.sch_fdp ? true : false}
                          /> 
                        </Col>
                        <Col xl="6" lg="6" md="6" sm="6" className="col-8 pl-1">
                          <InputValid
                            type="text"
                            name="sch_fdp_name"
                            id="sch_fdp_name"
                            maxLength="35"
                            value={booking.sch_fdp_name?booking.sch_fdp_name:''}
                            onChange={(e)=>fncOnChange(e, 'sch_fdp_name')}
                            onBlur={(e) => {props.fncBookingParent(booking)}}
                            validtype="eng"
                            required={false}
                            feedid="schedule"
                            // readOnly
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
              <div className="text-center" onClick={() => setColl(!coll)}>
                <div>
                                    <Button className="p-0" color="link" id="linemore" onClick={() => setColl(!coll)} style={{height:'21px'}}>
                                        {coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                  </Button>
                  <UncontrolledTooltip delay={0} target="linemore">
                    {coll ? 'Close' : 'Open'}
                  </UncontrolledTooltip>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* {scheduleCalendarOpen && (
        <ScheduleCalendarModal
          open={scheduleCalendarOpen}
          toggle={() => setScheduleCalendarOpen(false)}
          loadData={booking}
          lineCode={lineCode}
          // carrierValue={carrierValue}
          // setCarrierValue={(e) => setCarrierValue(e)}
          selectedEventCalendar={selectedEventCalendar}
          {...props}
        />
      )} */}
    </>
  );
});

export default ScheduleCard;
