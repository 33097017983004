import React,{ useState, useEffect, useMemo ,useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import CardBody from "muiComponents/Card/CardBody.js";
import {
	Collapse,
	Card,
	CardContent,
	TextField,
	TableFooter,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	Grid,
	Divider,
	Container,
	Avatar,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Paper,
	Input
} from "@material-ui/core";

import Button from "muiComponents/CustomButtons/Button.js";

import {
	Chat as Chat,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	ReplySharp,
	Search as SearchIcon
}from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import "assets/css/quillEditer.css";
import Filesaver from 'file-saver'
import {Row,Col} from "reactstrap";

export default function BoardDetail(props) {
	const history = useHistory();
	const {location} = props;
	const [hit,setHit] = useState(location.hit_count);
	const [boardAttachFile, setBoardAttachFile] = useState([]);

	useEffect(()=>{
		//console.log(location);
		//console.log(props.userData);
		if(location?.board_id)onSearchBoardAttachFile(location.board_id);
		if(location?.board_id&&props.userData){
			axios.post("/api/updateBoardHits",{board_id:location.board_id}).then(
				res=>{
					if(res.data === 1) {
						setHit(location.hit_count+1);
					}
				}
			)
		}else{
			goBoard();
		}
	},[location]) 

	const goBoard = () => {
		history.push({
			pathname:"/svc/board",
		})
	}
	
	const onSearchBoardAttachFile=(board_id)=>{
        if(board_id) {
            axios.post("/api/getBoardAttach",{board_id:board_id}).then(
                res=>{
                    setBoardAttachFile(res.data);
                }
            );
        }
    }
	const fileDownload = (value) => {
        axios.post("/api/boardFIleDownLoad",{fileName:value.file_name,filePath:value.file_path},{responseType:"arraybuffer"}).then(
            res => {
            Filesaver.saveAs(new Blob([res.data]),value.real_file_name)
            });
	}

	
	const updateBoard = () => {
		// console.log(location)
		history.push({
			...location,
			pathname:"/svc/boardwrite",
			title:location.title,
			content:location.content,
			board_id:location.board_id,
			board_to:location.board_to,
			attach_files:boardAttachFile,
			pop_yn:location.pop_yn,
		});
	}

	const deleteBoard = () => {
		axios.post("/com/deleteBoardWithFile",{param:location}).then(
            res=> {
                if(res.statusText ==="OK") {
                    if(res.data==="success"){
                        props.onAlert('success','삭제가 완료 되었습니다.');
                    }
                }
            }
        )
		history.push({
			pathname:"/svc/board",
		})
	}
	
	return (
	  	<React.Fragment>
			<Card className="m-4">
				<CardContent>
					<h4 style={{textAlignLast:'left',color:'black'}}><Chat style={{color:'#00acc1'}}></Chat> 업무공지 상세내용</h4>
				</CardContent>
				<CardBody>
					<TableContainer>
					<Table style={{borderTop:'2px solid #06b3ba'}}>
						<TableBody>
							<TableRow>
								<TableCell style={{backgroundColor:'#f2fefd'}}>제목</TableCell>
								<TableCell colSpan={5} >{location.title}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell style={{backgroundColor:'#f2fefd'}}>작성자</TableCell>
								<TableCell  >운영자(MASTER)</TableCell>
								<TableCell style={{backgroundColor:'#f2fefd'}}>게시일</TableCell>
								<TableCell  >{location.insert_date}</TableCell>
								<TableCell style={{backgroundColor:'#f2fefd'}}>조회수</TableCell>
								<TableCell  >{hit}</TableCell>
							</TableRow>
							<TableRow>
									<TableCell colSpan={8} style={{borderBottom: '1px solid rgba(0, 0, 0, 0)'}}
									><div dangerouslySetInnerHTML={{__html:location.content}}/></TableCell>
							</TableRow>
							<TableRow>
								{boardAttachFile.length>0&&  <>
								<TableCell colSpan={8}>
									{boardAttachFile.map((value,idx) => {
										return(
											<Button  key={idx} onClick={()=> fileDownload(value)}>
												{value.real_file_name}
											</Button>
										)
									})}
								</TableCell></>} 
							</TableRow>
						</TableBody>	
						<TableFooter style={{borderTop:"2px solid #fff"}}>
							<TableRow>
									<TableCell  align="right" colSpan={8} style={{borderTop:'2px solid #06b3ba'}}>
										{props.userData&&(props.userData.klnet_id === "KLTEST01"||props.userData.role==='Y')?
                                            <>
                                                <Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> updateBoard()} >수정</Button>
                                                <Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> deleteBoard()} >삭제</Button>
											</>
                                            :<></>
                                        }
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> goBoard()}>목록</Button>
									</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
					</TableContainer>
				</CardBody>
			</Card>
		</React.Fragment>
	);
  }
