import React,{ useState, useEffect } from "react";
import GridItem from "muiComponents/Grid/GridItem.js";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CalendarBox from "muiComponents/CustomInput/CustomCalendar.js";
import { TextField, Paper, Checkbox, Grid,InputLabel, IconButton, InputAdornment, FormControlLabel, withStyles
  , TableContainer, Table, TableHead, TableRow
  , TableCell, TableBody,TableFooter} from "@material-ui/core";
  import FormHelperText from "@material-ui/core/FormHelperText";
import { RemoveCircleOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import CustomInput from "muiComponents/CustomInput/CustomInputBooking";
import Button from "muiComponents/CustomButtons/Button.js";
import Input from "@material-ui/core/Input";
import moment from 'moment';
// import SpecialCardBody from "./SpecialCardBody";
import Typography from '@material-ui/core/Typography';
import Datetime from "react-datetime";
import axios from 'axios';
import LocalAddress from 'muiComponents/LocalAddress/LocalAddress.js';
import {comClassOptions, bankCode} from 'muiComponents/common/options.js';
import Style from 'pages/popup/VerifyApplication.module.css'
import * as validation from 'muiComponents/common/validation.js';
import Confirm from 'components/Confirm/Confirm.js';

const LogisviewCheckbox = withStyles({
  root:{
    color:'#5fb0b5',
    '&$checked': {
      color: '#5fb0b5',
    },
    padding:'2px'
  }
})((props)=><Checkbox color = 'default' {...props}/>)
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  booking_title: {
    boxShadow: 'unset'
  },
  paper: {
    marginTop: theme.spacing(3),
    padding:theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(600+theme.spacing(3)*2)]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    }
  },
  paperInner: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  gridContainer: {
    padding:'30px'
  },
  divider: {
    marginTop:'10px',
    marginBottom:'20px',
    backgroundColor:'#00acc1',
  },
  box: {
    width: '100%',
    marginTop: theme.spacing(3),
    padding:theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  avatar: {
    backgroundColor: '#00ACC1',
    [theme.breakpoints.between("xs", "sm")]: {
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    [theme.breakpoints.between("md", "lg", "xl")]: {
      width: theme.spacing(5),
      height: theme.spacing(5)
    }
  },
  headerCell: {
    textAlign: "left",
    backgroundColor: "#f2fefd",
    width:'150px',
    padding:'7px',
    // fontSize: '15px',
    // fontWeight:'bold'
  },
  dataCell: {
    textAlign: "left",
    padding:'7px',
  },
  grid: {
    padding: '0px 10px !important',
  },
  gridLabel: {
    padding: '0px 10px !important',
    textAlign:'left',
    // marginTop:'12px',
    display: 'flex',
    alignItems:'center',
    marginBottom: '20px',
    wordBreak: 'keep-all'
  },
  modalCloseButton:{
    float:'right',
    padding:'0',
    minWidth:'21px',
    height:'21px'
  },
  modalTitle:{
    padding:'15px 24px 0 24px',
    textAlign:'center'
  },
  modal:{
    maxWidth:'80%'
  },
  tableLine:{
    height:'180px',overflow:'auto',borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7',marginBottom:'10px'
  },
  tablecell:{
    paddingTop:'5px',paddingBottom:'5px',textAlign:'start'
  },
  tableHeadercell:{
    paddingTop:'10px',paddingBottom:'10px',textAlign:'start'
  },
  paperHeader:{
    fontSize:'16px',fontWeight:'500' ,color:'#222',
    // marginBottom:'16px'
  },
}));



const ApplicationParamsForm = (props) => {
  const classes = useStyles();
  const {index,userData, accountData, setAccountData
    , cmsOpen,setCmsOpen, cmsYn ,setCmsYn
    , checkBizNumState, setCheckBizNumState,lockKlnetId} = props;

  const [formData, setFormData] = useState({});
  const [checkState , setCheckState] = useState({
    compToBill : false, usedToBill : false, compToUsed : false,  billToUsed : false
  });

  const [addressOpen,setAddressOpen] = useState(false);
  const [addressMode,setAddressMode] = useState('');
  const [returnAddress, setReturnAddress] = useState({});

  useEffect(()=>{
    setFormData(props.registrationData);
  },[props.registrationData]);

  useEffect(()=>{
    if(returnAddress.roadAddrPart1){
      let list = {};
      switch(addressMode) {
        case 'COM':
          list =  {
            COM_ADDR1: returnAddress.roadAddrPart1||'',
            COM_ADDR2: (returnAddress.useDetailAddr||'')+(returnAddress.roadAddrPart2||''),
            COM_ZIP_CODE: returnAddress.zipNo||''
          };
         break;
        case 'BILL':
          list =  {
            BILL_ADDR1: returnAddress.roadAddrPart1||'',
            BILL_ADDR2: (returnAddress.useDetailAddr||'')+(returnAddress.roadAddrPart2||''),
            BILL_ZIP_CODE: returnAddress.zipNo||'',
          };
          break;
        case 'USED':
          list =  {
            USED_ADDR1: returnAddress.roadAddrPart1||'',
            USED_ADDR2: (returnAddress.useDetailAddr||'')+(returnAddress.roadAddrPart2||''),
            USED_ZIP_CODE: returnAddress.zipNo||'',
          };
          break;
        default: return ;
      }
      setFormData(old=>{return {...old,...list}});
      props.fncOnBlurData(index, {...formData,...list});
      setAddressMode('');
      setReturnAddress({});
    }
  },[returnAddress]);
  
  const fncOnChange =(e)=>{
    let key = e.target.id;
    let value = e.target.value.toUpperCase();
    formData[key] = value;
    setFormData({...formData});
  }
  const fncOnChangeAcc =(e)=>{
    let key = e.target.id;
    let value = e.target?e.target.value.toUpperCase():null;
    setAccountData((old)=>{return{...old, [key] : value}})
  }

  // AutoComplete
  const fncSelectCode =(option, code, index) => {
    setFormData({
      ...formData,
      [code]: option,
    });
    props.fncOnBlurData(index, {
      ...formData,
      [code]: option,
    });
  }

  const handleChange=(e)=>{
    setCheckState({...checkState, [e.target.name]: e.target.checked})
    let caseName = e.target.name;
    let newlist ={};
    if(e.target.checked){
      switch(caseName) {
        case 'compToBill': 
          if(formData.COM_NAME) newlist['BILL_COM_NAME'] = formData.COM_NAME;
          if(formData.COM_NO) newlist['BILL_COM_NO'] = formData.COM_NO;
          if(formData.COM_ADDR1) newlist['BILL_ADDR1'] = formData.COM_ADDR1;
          if(formData.COM_ADDR2) newlist['BILL_ADDR2'] = formData.COM_ADDR2;
          if(formData.COM_ZIP_CODE) newlist['BILL_ZIP_CODE'] = formData.COM_ZIP_CODE;
          break;
        case 'usedToBill':
          if(formData.USED_COM_NAME) newlist['BILL_COM_NAME'] = formData.USED_COM_NAME;
          if(formData.USED_COM_NO) newlist['BILL_COM_NO'] = formData.USED_COM_NO;
          if(formData.USED_PERSON) newlist['BILL_PERSON'] = formData.USED_PERSON;
          if(formData.USED_COM_STATION) newlist['BILL_COM_STATION'] = formData.USED_COM_STATION;
          if(formData.USED_PHONE) newlist['BILL_PHONE'] = formData.USED_PHONE;
          if(formData.USED_FAX) newlist['BILL_FAX'] = formData.USED_FAX;
          if(formData.USED_ADDR1) newlist['BILL_ADDR1'] = formData.USED_ADDR1;
          if(formData.USED_ADDR2) newlist['BILL_ADDR2'] = formData.USED_ADDR2;
          if(formData.USED_ZIP_CODE) newlist['BILL_ZIP_CODE'] = formData.USED_ZIP_CODE;
          break;
        case 'compToUsed':
          if(formData.COM_NAME) newlist['USED_COM_NAME'] = formData.COM_NAME;
          if(formData.COM_NO) newlist['USED_COM_NO'] = formData.COM_NO;
          if(formData.COM_ADDR1) newlist['USED_ADDR1'] = formData.COM_ADDR1;
          if(formData.COM_ADDR2) newlist['USED_ADDR2'] = formData.COM_ADDR2;
          if(formData.COM_ZIP_CODE) newlist['USED_ZIP_CODE'] = formData.COM_ZIP_CODE;
          break;
        case 'billToUsed':
          if(formData.BILL_COM_NAME) newlist['USED_COM_NAME'] = formData.BILL_COM_NAME;
          if(formData.BILL_COM_NO) newlist['USED_COM_NO'] = formData.BILL_COM_NO;
          if(formData.BILL_PERSON) newlist['USED_PERSON'] = formData.BILL_PERSON;
          if(formData.BILL_COM_STATION) newlist['USED_COM_STATION'] =  formData.BILL_COM_STATION;
          if(formData.BILL_PHONE) newlist['USED_PHONE'] =formData.BILL_PHONE;
          if(formData.BILL_FAX) newlist['USED_FAX'] = formData.BILL_FAX;
          if(formData.BILL_ADDR1) newlist['USED_ADDR1'] = formData.BILL_ADDR1;
          if(formData.BILL_ADDR2) newlist['USED_ADDR2'] = formData.BILL_ADDR2;
          if(formData.BILL_ZIP_CODE) newlist['USED_ZIP_CODE'] = formData.BILL_ZIP_CODE;
          break;
        default : return ;
      } 
    }else{
      switch(caseName) {
        case 'compToBill':
          newlist['BILL_COM_NAME'] = null;
          newlist['BILL_COM_NO'] = null;
          newlist['BILL_ADDR1'] = null;
          newlist['BILL_ADDR2'] = null;
          newlist['BILL_ZIP_CODE'] = null;
          break;
        case 'usedToBill':
          newlist['BILL_COM_NAME'] = null;
          newlist['BILL_COM_NO'] = null;
          newlist['BILL_PERSON'] = null;
          newlist['BILL_COM_STATION'] =null;
          newlist['BILL_PHONE'] =null;
          newlist['BILL_FAX'] = null;
          newlist['BILL_ADDR1'] = null;
          newlist['BILL_ADDR2'] = null;
          newlist['BILL_ZIP_CODE'] = null;
          break;
        case 'compToUsed':
          newlist['USED_COM_NAME'] = null;
          newlist['USED_COM_NO'] = null;
          newlist['USED_ADDR1'] = null;
          newlist['USED_ADDR2'] = null;
          newlist['USED_ZIP_CODE'] = null; 
          break;
        case 'billToUsed':
          newlist['USED_COM_NAME'] = null;
          newlist['USED_COM_NO'] = null;
          newlist['USED_PERSON'] = null;
          newlist['USED_COM_STATION'] = null;
          newlist['USED_PHONE'] = null;
          newlist['USED_FAX'] = null;
          newlist['USED_ADDR1'] = null;
          newlist['USED_ADDR2'] = null;
          newlist['USED_ZIP_CODE'] = null;
          break;
        default : return ;
      } 
    }
    setFormData(old=>{return {...old,...newlist}});
    props.fncOnBlurData(index, {...formData,...newlist});
  }

  const {compToBill, usedToBill, compToUsed,  billToUsed} = checkState;

  const onHandleChgYn = async()=>{
    let result = await Confirm({
      title: '변경 신청',
      message: ' 사업자등록번호를 변경하시겠습니까? \n 변경 신청은 신청서를 작성 후 송부해야 합니다.',
      confirmText: "확인",
      cancelText: '취소',
  });
    // let result = window.confirm(' 사업자등록번호를 변경하시겠습니까? \n 변경 신청은 신청서를 작성 후 송부해야 합니다.');
    // <SweetAlert
    //         onConfirm={ () => {setOnAlarm(false);  onHandlerSession();}}
    //         title={' 사업자등록번호를 변경하시겠습니까? \n 변경 신청은 신청서를 작성 후 송부해야 합니다.'}
    //         type={'warning'}
    //         show={onAlarm }
    //         reverseButtons={true}
    //         showConfirm={true}
    //         showCancel={true}
    //         onCancel={() => { setOnAlarm(false)}}
    //         confirmBtnText={'확인'} confirmBtnBsStyle={'primary'} //confirmBtnStyle={{width: '117px'}}
    //         cancelBtnText={'취소'} cancelBtnBsStyle={'warning'} //cancelBtnStyle={{width: '117px'}}
    //         style ={{boxSizing:'initial'}}
    //         >
    // </SweetAlert>
    if(result){
      axios.post(
        "/api/updateCompanyChgYn",
        { klnetId: lockKlnetId,}
      ).then(
          res => {
              if(res.data){
                setFormData(old=>{return {...old,CHG_YN:'Y'}});
                props.fncOnBlurData(index, {
                  ...formData,
                  CHG_YN:'Y',
                });
                props.selectCompanyAccountInfo();
                setCmsOpen(true)
              }else{
                props.onAlert("error",'변경 신청에 실패하였습니다.');
              }
          }
      ).catch(err => {
        console.log(err);
        props.onAlert("error",'변경 신청에 실패하였습니다.');
      });
    }
  }
  const handleChangeCmsAgree = (e)=>{
    let name = e.target.name;
    // setCmsAgree(old =>{return {...old, [name]: !old[name]}});
    setAccountData(old=>{return{...old, [name]: !old[name]}});
  }

  const checkCompanyNumber = async( key )=>{
    const result =  formData&&formData[key]? await validation.checkCompanyNumber(formData[key]) : false;
    // console.log( result );
    setCheckBizNumState(old=>{return {...old, [key]:result}});
  }
return (
  <React.Fragment>
    <h4 style ={{paddingTop:'10px',paddingBottom:'10px',marginLeft:'10px',color:'#069ea4'}}>1. 고객정보</h4>
    <Paper className={classes.paper} key={index} style ={{marginTop:'12px',padding:'12px'}}>
      <GridContainer >
        <GridItem xs={12} sm={12}>
          <h6 className ={classes.paperHeader}>
          ※ 사업자 정보
          </h6>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <Grid container>
            <GridItem xs={12} sm={1} className={classes.gridLabel} >
              <InputLabel style={{ color: "#AAAAAA" }}>
                상호
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                    validtype="text"
                    success={formData.COM_NAME?true:false}
                    error={formData.COM_NAME?false:true}
                    labelText=""
                    maxLength="50"
                    id="COM_NAME"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                        value:formData.COM_NAME?formData.COM_NAME:'',
                    onChange: (e)=>fncOnChange(e),
                    onBlur: ()=>props.fncOnBlurData(index, formData),
                    }}
                    required={true}
                    feedback="formData"
                  />
            </GridItem>
            <GridItem xs={12} sm={1} className={classes.gridLabel}>
              <InputLabel style={{ color: "#AAAAAA" }}>
              대표자명
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={3}>
              <CustomInput
                validtype="text"
                success={formData.COM_CEO?true:false}
                error={formData.COM_CEO?false:true}
                labelText=""
                maxLength="30"
                id="COM_CEO"
                formControlProps={{
                  fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                }}
                inputProps={{
                    value:formData.COM_CEO?formData.COM_CEO:'',
                onChange: (e)=>fncOnChange(e),
                onBlur: ()=>props.fncOnBlurData(index, formData),
                }}
                required={true}
                feedback="formData"
              />
            </GridItem>
            <GridItem xs={12} sm={1} className={classes.gridLabel}>
              <InputLabel style={{ color: "#AAAAAA" }}>
              사업자등록번호
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={formData.COMP_TYPE=='A'&&formData.CHG_YN!=='Y'?2:3}>
              <CustomInput
                validtype="bizNo"
                success={formData.COM_NO?true:false}
                error={formData.COM_NO?false:true}
                labelText=""
                maxLength="10"
                id="COM_NO"
                formControlProps={{
                  fullWidth: true,
                  disabled:formData.COMP_TYPE=='A'&&formData.CHG_YN!=='Y'? true :false,
                  style:{paddingTop:'0',marginBottom:'10px'}
                }}
                inputProps={{
                    value:formData.COM_NO?formData.COM_NO:'',
                    onChange: (e)=>fncOnChange(e),
                    onBlur: ()=>{props.fncOnBlurData(index, formData); checkCompanyNumber('COM_NO')},
                }}
                required={true}
                feedback="formData"
                checkedInvalid={checkBizNumState&&checkBizNumState.COM_NO ? '유효하지 않은 사업자번호입니다.' : false}
              />
                {/* {!checkBizNumState["COM_NO"] &&
                <FormHelperText >
                 '유효하지 않은 사업자등록번호입니다.'
                </FormHelperText>} */}
            </GridItem>
            {formData.COMP_TYPE=='A'&&formData.CHG_YN!=='Y'&&
            <GridItem xs={12} sm={1}>
              <Button color="warning" size="sm" id = 'CHG_YN' onClick={(e)=>{onHandleChgYn()}}>변경 신청</Button>
            </GridItem>
            }
            <GridItem xs={12} sm={1} className={classes.gridLabel}>
              <InputLabel style={{ color: "#AAAAAA" }}>
              사업장 주소
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={7}>
              <CustomInput
                validtype="text"
                success={formData.COM_ADDR1?true:false}
                error={formData.COM_ADDR1?false:true}
                labelText=""
                maxLength="120"
                id="COM_ADDR"
                formControlProps={{
                  fullWidth: true,  style:{paddingTop:'0',marginBottom:'10px'}
                }}
                inputProps={{
                  disabled:true,
                  value: `${formData.COM_ADDR1?formData.COM_ADDR1:''}${formData.COM_ADDR2?`, ${formData.COM_ADDR2}`:''}`
                  ,onClick:()=>{setAddressOpen(true); setAddressMode('COM')}
                }}
                required={true}
                feedback="formData"
              />
            </GridItem>
            <GridItem xs={12} sm={1} className={classes.gridLabel}>
              <InputLabel style={{ color: "#AAAAAA" }}>
              우편번호
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <CustomInput
                validtype="text"
                success={formData.COM_ZIP_CODE?true:false}
                error={formData.COM_ZIP_CODE?false:true}
                labelText=""
                maxLength="7"
                id="COM_ZIP_CODE"
                formControlProps={{
                  fullWidth: true
                  ,style:{paddingTop:'0',marginBottom:'10px'}
                  ,onClick:()=>{setAddressOpen(true); setAddressMode('COM')}
                }}
                inputProps={{
                  disabled:true,
                  value:formData.COM_ZIP_CODE?formData.COM_ZIP_CODE:'',
                }}
                required={true}
                feedback="formData"
              />
            </GridItem>
            <GridItem xs={12} sm={1}>
              <Button color="success" size="sm" onClick={()=>{setAddressOpen(true); setAddressMode('COM')}}>주소찾기</Button>
            </GridItem>
            <GridItem xs={12} sm={1} className={classes.gridLabel}>
              <InputLabel style={{ color: "#AAAAAA" }}>
              업태
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={5}>
              <CustomInput
                validtype="text"
                success={formData.COM_TYPE?true:false}
                error={formData.COM_TYPE?false:true}
                labelText=""
                maxLength="80"
                id="COM_TYPE"
                formControlProps={{
                  fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                }}
                inputProps={{
                    value:formData.COM_TYPE?formData.COM_TYPE:'',
                onChange: (e)=>fncOnChange(e),
                onBlur: ()=>props.fncOnBlurData(index, formData),
                }}
                required={true}
                feedback="formData"
              />
            </GridItem>
            <GridItem xs={12} sm={1} className={classes.gridLabel}>
              <InputLabel style={{ color: "#AAAAAA" }}>
              종목
              </InputLabel>
            </GridItem>
            <GridItem xs={12} sm={5}>
              <CustomInput
                validtype="text"
                success={formData.COM_CLASS?true:false}
                error={formData.COM_CLASS?false:true}
                labelText=""
                maxLength="80"
                id="COM_CLASS"
                formControlProps={{
                  fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                }}
                inputProps={{
                  value:formData.COM_CLASS?formData.COM_CLASS:'',
                  onChange: (e)=>fncOnChange(e),
                  onBlur: ()=>props.fncOnBlurData(index, formData),
                }}
                required={true}
                feedback="formData"
              />
              {/* <Autocomplete
                className={classes.textField}
                options = {comClassOptions}
                getOptionLabel = { option => option.label ||'' }
                getOptionSelected = {(option,value) => option.value == value.value ||''}
                // defaultValue={comClassOptions[0]}
                id="COM_CLASS"
                readOnly={true}
                value= {formData&&comClassOptions.find(v=>v.value === formData.COM_CLASS)
                  ?comClassOptions.find(v=>v.value === formData.COM_CLASS) :''}
                // onChange={(e, option)=>fncOnChange(option&&option.value)}
                onChange={(e, option)=>fncSelectCode(option?option.value:null, 'COM_CLASS', index)}
                // onChange={(e)=>fncOnChange(e)}
                onBlur={ ()=>props.fncOnBlurData(index, formData)}
                renderInput={
                  params =>(
                    <TextField
                      inputProps={{maxLength:30}}
                      {...params}
                      label=""
                      fullWidth
                      id="COM_CLASS"
                      // success={formData.COM_CLASS?true:false}
                      error={formData.COM_CLASS?false:true}
                      helperText={formData.COM_CLASS?null:'필수'}
                      readOnly
                      style={{marginTop:'7px'}}
                    />)}
              /> */}
            </GridItem>
          </Grid>
        </GridItem>
      </GridContainer>
    </Paper>



    <GridContainer>
      <GridItem className={classes.grid} lg={6} md={12} sm={12} xs={12}>
        <Paper className={classes.paper} key={"Pick"+index}style ={{marginTop:'12px',padding:'12px'}} >
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <div style ={{marginBottom:'16px', display:'flex',justifyContent:'space-between'}}>
                <h6 className ={classes.paperHeader}>
                ※ 청구지 정보
                </h6>
                <div style={{display:'flex',}}>
                  <label style={{display:'flex', alignItems:'center'}}>
                    <LogisviewCheckbox  checked={compToBill} onChange={handleChange} name='compToBill'/>
                    <span>사업자 정보와 동일</span>
                  </label>
                  <label style={{display:'flex', alignItems:'center', marginLeft:'12px'}}>
                  <LogisviewCheckbox  checked={usedToBill} onChange={handleChange} name='usedToBill'/>
                    <span>서비스 이용정보와 동일</span>
                  </label>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Grid container>
              <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  상호
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  validtype="text"
                  success={formData.BILL_COM_NAME?true:false}
                  error={formData.BILL_COM_NAME?false:true}
                  labelText=""
                  maxLength="50"
                  id="BILL_COM_NAME"
                  formControlProps={{
                    fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                  }}
                  inputProps={{
                      value:formData.BILL_COM_NAME||'',
                  onChange: (e)=>fncOnChange(e),
                  onBlur: ()=>props.fncOnBlurData(index, formData),
                  }}
                  required={true}
                  feedback="formData"
                />
              </GridItem>
              <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  사업자등록번호
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  validtype="bizNo"
                  success={formData.BILL_COM_NO?true:false}
                  error={formData.BILL_COM_NO?false:true}
                  labelText=""
                  maxLength="10"
                  id="BILL_COM_NO"
                  formControlProps={{
                    fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                  }}
                  inputProps={{
                      value:formData.BILL_COM_NO||'',
                  onChange: (e)=>fncOnChange(e),
                  onBlur: ()=>{props.fncOnBlurData(index, formData); checkCompanyNumber('BILL_COM_NO')}
                  }}
                  required={true}
                  feedback="formData"
                  checkedInvalid={checkBizNumState&&checkBizNumState.BILL_COM_NO ? '유효하지 않은 사업자번호입니다.' : false}
                />
              </GridItem>
              <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  담당자
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  validtype="text"
                  success={formData.BILL_PERSON?true:false}
                  error={formData.BILL_PERSON?false:true}
                  labelText=""
                  maxLength="20"
                  id="BILL_PERSON"
                  formControlProps={{
                    fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                  }}
                  inputProps={{
                      value:formData.BILL_PERSON||'',
                  onChange: (e)=>fncOnChange(e),
                  onBlur: ()=>props.fncOnBlurData(index, formData),
                  }}
                  required={true}
                  feedback="formData"
                />
              </GridItem>
              <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  담당부서
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  validtype="text"
                  success={formData.BILL_COM_STATION?true:false}
                  error={formData.BILL_COM_STATION?false:true}
                  labelText=""
                  maxLength="20"
                  id="BILL_COM_STATION"
                  formControlProps={{
                    fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                  }}
                  inputProps={{
                      value:formData.BILL_COM_STATION||'',
                  onChange: (e)=>fncOnChange(e),
                  onBlur: ()=>props.fncOnBlurData(index, formData),
                  }}
                  required={true}
                  feedback="formData"
                />
              </GridItem>
              <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  전화번호
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  validtype="tel"
                  success={formData.BILL_PHONE?true:false}
                  error={formData.BILL_PHONE?false:true}
                  labelText=""
                  maxLength="20"
                  id="BILL_PHONE"
                  formControlProps={{
                    fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                  }}
                  inputProps={{
                      value:formData.BILL_PHONE||'',
                  onChange: (e)=>fncOnChange(e),
                  onBlur: ()=>props.fncOnBlurData(index, formData),
                  }}
                  required={true}
                  feedback="formData"
                />
              </GridItem>
              <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  팩스번호
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  validtype="fax"
                  success={formData.BILL_FAX?true:false}
                  error={formData.BILL_FAX?false:true}
                  labelText=""
                  maxLength="20"
                  id="BILL_FAX"
                  formControlProps={{
                    fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                  }}
                  inputProps={{
                      value:formData.BILL_FAX||'',
                  onChange: (e)=>fncOnChange(e),
                  onBlur: ()=>props.fncOnBlurData(index, formData),
                  }}
                  required={true}
                  feedback="formData"
                />
              </GridItem>
              <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  이메일 주소
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={10} style={{marginBottom: '10px'}}>
                  <CustomInput
                      validtype="text"
                      success={formData.BILL_EMAIL?true:false}
                      error={formData.BILL_EMAIL?false:true}
                      labelText=""
                      maxLength="50"
                      id="BILL_EMAIL"
                      formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                      }}
                      inputProps={{
                          value:formData.BILL_EMAIL||'',
                      onChange: (e)=>fncOnChange(e),
                      onBlur: ()=>props.fncOnBlurData(index, formData),
                      }}
                      required={true}
                      feedback="formData"
                  />
              </GridItem>
              <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  청구지 주소
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={8} style={{marginBottom: '10px'}}>
                  <CustomInput
                      validtype="text"
                      success={formData.BILL_ADDR1?true:false}
                      error={formData.BILL_ADDR1?false:true}
                      labelText=""
                      maxLength="120"
                      id="BILL_ADDR"
                      formControlProps={{
                      fullWidth: true,  style:{paddingTop:'0',marginBottom:'10px'}
                      }}
                      inputProps={{
                          value: `${formData.BILL_ADDR1?formData.BILL_ADDR1:''}${formData.BILL_ADDR2?`, ${formData.BILL_ADDR2}`:''}`,
                          disabled:true,
                          onClick:()=>{setAddressOpen(true); setAddressMode('BILL')}
                      }}
                      required={true}
                      feedback="formData"
                  />
              </GridItem>
              <GridItem xs={12} sm={2}>
                  <Button color="success" size="sm" onClick={()=>{setAddressOpen(true); setAddressMode('BILL')}}>주소찾기</Button>
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    우편번호
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={4} style={{marginBottom: '10px'}}>
                  <CustomInput
                    validtype="text"
                    success={formData.BILL_ZIP_CODE?true:false}
                    error={formData.BILL_ZIP_CODE?false:true}
                    labelText=""
                    maxLength="7"
                    id="BILL_ZIP_CODE"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                      value:formData.BILL_ZIP_CODE?formData.BILL_ZIP_CODE:'', 
                      disabled:true,
                      onClick:()=>{setAddressOpen(true); setAddressMode('BILL')}
                    }}
                    required={true}
                    feedback="formData"
                  />
                </GridItem>
{/*                 
              <GridItem xs={12} sm={2} style={{marginBottom: '10px',marginTop: '5px'}}>
                  {formData.BILL_ZIP_CODE
                  ?<span>({formData.BILL_ZIP_CODE})</span>:<span>(03232)</span>}
              </GridItem> */}
              {/* <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  Address
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  validtype="text"
                  success={formData.cntr_pick_up_cy_address1?true:false}
                  error={formData.cntr_pick_up_cy_address1?false:true}
                  labelText=""
                  maxLength="35"
                  id="cntr_pick_up_cy_address1"
                  formControlProps={{
                    fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                  }}
                  inputProps={{
                    value:formData.cntr_pick_up_cy_address1?formData.cntr_pick_up_cy_address1:'',
                    onChange: (e)=>fncOnChange(e),
                    onBlur: ()=>props.fncOnBlurData(index, formData),
                    // disabled: transSelfYn
                  }}
                  required={!formData.cntr_pick_up_cy_address1&&(formData.cntr_pick_up_cy_address2||formData.cntr_pick_up_cy_address3||formData.cntr_pick_up_cy_address4||formData.cntr_pick_up_cy_address5)?true:false}
                  feedback="formData"
                />
              </GridItem> */}
              {/* <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  업태
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  validtype="text"
                  success={formData.business_type?true:false}
                  error={formData.business_type?false:true}
                  labelText=""
                  maxLength="20"
                  id="business_type"
                  formControlProps={{
                    fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                  }}
                  inputProps={{
                      value:formData.business_type||'',
                  onChange: (e)=>fncOnChange(e),
                  onBlur: ()=>props.fncOnBlurData(index, formData),
                  }}
                  required={true}
                  feedback="formData"
                />
              </GridItem>
              <GridItem xs={12} sm={2} className={classes.gridLabel}>
                <InputLabel style={{ color: "#AAAAAA" }}>
                  업종
                </InputLabel>
              </GridItem>
              <GridItem xs={12} sm={4}>
                <CustomInput
                  validtype="text"
                  success={formData.sector?true:false}
                  error={formData.sector?false:true}
                  labelText=""
                  maxLength="35"
                  id="sector"
                  formControlProps={{
                    fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                  }}
                  inputProps={{
                      value:formData.sector||'',
                  onChange: (e)=>fncOnChange(e),
                  onBlur: ()=>props.fncOnBlurData(index, formData),
                  }}
                  required={true}
                  feedback="formData"
                />
              </GridItem> */}
              {/* <GridItem xs={12} sm={12}>
                  <p style={{fontSize:'12px', wordBreak:'keep-all'}}>* 상기 정보는 세금계산서 발행 정보와 동일합니다.</p>
                  <p style={{fontSize:'12px', wordBreak:'keep-all'}}>* 상기 세금계산서 정보 등록 오류로 인한 세금계산서 거절 후 재발행시에는 계산서 발행 업무 처리가 지연될 수 있습니다.</p> */}
                {/* <CustomInput
                  validtype="text"
                  success={formData.cntr_pick_up_cy_name2?true:false}
                  error={formData.cntr_pick_up_cy_name2?false:true}
                  labelText=""
                  maxLength="35"
                  id="cntr_pick_up_cy_name2"
                  formControlProps={{
                    fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                  }}
                  inputProps={{
                      value:formData.cntr_pick_up_cy_name2?formData.cntr_pick_up_cy_name2:'',
                  onChange: (e)=>fncOnChange(e),
                  onBlur: ()=>props.fncOnBlurData(index, formData),
                  }}
                  required={false}
                  feedback="formData"
                /> */}
              {/* </GridItem> */}
            </Grid>
            </GridItem>
          </GridContainer>
        </Paper>
      </GridItem>


      <GridItem className={classes.grid} lg={6} md={12} sm={12} xs={12}>
        <Paper className={classes.paper} key={"DOOR_"+index} style ={{marginTop:'12px',padding:'12px'}}>
          <GridContainer>
            <GridItem xs={12} sm={12}>
              <div style ={{marginBottom:'16px', display:'flex',justifyContent:'space-between'}}>
                <h6 className ={classes.paperHeader}>
                ※ 서비스 이용 정보
                </h6>
                <div style={{display:'flex',}}>
                  <label style={{display:'flex', alignItems:'center'}}>
                  <LogisviewCheckbox  checked={compToUsed} onChange={handleChange} name='compToUsed'/>
                    <span>사업자 정보와 동일</span>
                  </label>
                  <label style={{display:'flex', alignItems:'center', marginLeft:'12px'}}>
                  <LogisviewCheckbox  checked={billToUsed} onChange={handleChange} name='billToUsed'/>
                    <span>청구지 정보와 동일</span>
                  </label>
                </div>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12}>
              <Grid container>
                <GridItem xs={12} sm={2} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    상호
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    validtype="text"
                    success={formData.USED_COM_NAME?true:false}
                    error={formData.USED_COM_NAME?false:true}
                    labelText=""
                    maxLength="50"
                    id="USED_COM_NAME"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                        value:formData.USED_COM_NAME||'',
                    onChange: (e)=>fncOnChange(e),
                    onBlur: ()=>props.fncOnBlurData(index, formData),
                    }}
                    required={true}
                    feedback="formData"
                  />
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    사업자등록번호
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    validtype="bizNo"
                    labelText=""
                    maxLength="10"
                    id="USED_COM_NO"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                        value:formData.USED_COM_NO||'',
                    onChange: (e)=>fncOnChange(e),
                    onBlur: ()=>{props.fncOnBlurData(index, formData); checkCompanyNumber('USED_COM_NO')},
                    }}
                    required={true}
                    feedback="formData"
                    checkedInvalid={checkBizNumState&&checkBizNumState.USED_COM_NO ? '유효하지 않은 사업자번호입니다.' : false}
                  />
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    담당자
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    validtype="text"
                    success={formData.USED_PERSON?true:false}
                    error={formData.USED_PERSON?false:true}
                    labelText=""
                    maxLength="20"
                    id="USED_PERSON"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                        value:formData.USED_PERSON||'',
                    onChange: (e)=>fncOnChange(e),
                    onBlur: ()=>props.fncOnBlurData(index, formData),
                    }}
                    required={true}
                    feedback="formData"
                  />
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    담당부서
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    validtype="text"
                    success={formData.USED_COM_STATION?true:false}
                    error={formData.USED_COM_STATION?false:true}
                    labelText=""
                    maxLength="20"
                    id="USED_COM_STATION"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                        value:formData.USED_COM_STATION||'',
                    onChange: (e)=>fncOnChange(e),
                    onBlur: ()=>props.fncOnBlurData(index, formData),
                    }}
                    required={true}
                    feedback="formData"
                  />
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    전화번호
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    validtype="tel"
                    success={formData.USED_PHONE?true:false}
                    error={formData.USED_PHONE?false:true}
                    labelText=""
                    maxLength="20"
                    id="USED_PHONE"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                        value:formData.USED_PHONE||'',
                    onChange: (e)=>fncOnChange(e),
                    onBlur: ()=>props.fncOnBlurData(index, formData),
                    }}
                    required={true}
                    feedback="formData"
                  />
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    팩스번호
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    validtype="fax"
                    success={formData.USED_FAX?true:false}
                    error={formData.USED_FAX?false:true}
                    labelText=""
                    maxLength="20"
                    id="USED_FAX"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                        value:formData.USED_FAX||'',
                    onChange: (e)=>fncOnChange(e),
                    onBlur: ()=>props.fncOnBlurData(index, formData),
                    }}
                    required={true}
                    feedback="formData"
                  />
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    서비스 이용 주소
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={8} style={{marginBottom: '10px'}}>
                    <CustomInput
                        validtype="text"
                        success={formData.USED_ADDR1?true:false}
                        error={formData.USED_ADDR1?false:true}
                        labelText=""
                        maxLength="120"
                        id="USED_ADDR"
                        formControlProps={{
                        fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                        }}
                        inputProps={{
                          value: `${formData.USED_ADDR1?formData.USED_ADDR1:''}${formData.USED_ADDR2?`, ${formData.USED_ADDR2}`:''}`,
                          disabled:true,
                          onClick:()=>{setAddressOpen(true); setAddressMode('USED')}
                        }}
                        required={true}
                        feedback="formData"
                    />
                </GridItem>
                <GridItem xs={12} sm={2}>
                  <Button color="success" size="sm" onClick={()=>{setAddressOpen(true); setAddressMode('USED')}}>주소찾기</Button>
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    우편번호
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={4} style={{marginBottom: '10px'}}>
                  <CustomInput
                    validtype="text"
                    success={formData.USED_ZIP_CODE?true:false}
                    error={formData.USED_ZIP_CODE?false:true}
                    labelText=""
                    maxLength="7"
                    id="USED_ZIP_CODE"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                        value:formData.USED_ZIP_CODE?formData.USED_ZIP_CODE:'',
                        disabled:true,
                        onClick:()=>{setAddressOpen(true); setAddressMode('USED')}    
                    }}
                    required={true}
                    feedback="formData"
                  />
                </GridItem>
              </Grid>
            </GridItem>
          </GridContainer>
        </Paper>
      </GridItem>
    </GridContainer>

{/* 2. 신청 서비스 */}
    <h4 style ={{paddingTop:'10px',paddingBottom:'10px',marginLeft:'10px',color:'#069ea4'}}>2. 신청 서비스</h4>
    <Paper className={classes.paper} key={index} style ={{marginTop:'12px',padding:'12px'}}>
      <GridContainer >
        <GridItem xs={12} sm={2}  style ={{display:'flex',alignItems:'center'}}>
          <h6 style ={{ fontSize:'16px',fontWeight:'500' ,color:'#222',}}>
          서비스 구분
          </h6>
        </GridItem>
        <GridItem xs={12} sm={4} style ={{display:'flex',alignItems:'center'}}> 
          <label style={{display:'flex', alignItems:'center'}}>
            <LogisviewCheckbox  checked={true} />
            <span>로지스뷰</span>
          </label>
        </GridItem>
        <GridItem xs={12} sm={2} style ={{display:'flex',alignItems:'center'}}>
          <h6 style ={{ fontSize:'16px',fontWeight:'500' ,color:'#222',}}>
          KL-Net ID
          </h6>
        </GridItem>
        <GridItem xs={12} sm={4} style ={{display:'flex',alignItems:'center'}}>
          {lockKlnetId}
        </GridItem>
      </GridContainer>
    </Paper>

{/* 3. CMS(자동이체) 신청*/}

  {cmsOpen?<>
    <h4 style ={{paddingTop:'10px',paddingBottom:'10px',marginLeft:'10px',color:'#069ea4'}}>3. CMS(자동이체) 신청</h4>
    <Paper className={classes.paper} key={index} style ={{marginTop:'12px',padding:'12px'}}>
      <GridContainer >
        <GridItem xs={12} sm={2}  style ={{display:'flex',alignItems:'center'}}>
          <h6 style ={{ fontSize:'16px',fontWeight:'500' ,color:'#222',}}>
          신청 구분
          </h6>
        </GridItem>
        <GridItem xs={12} sm={4} style ={{display:'flex',alignItems:'center'}}> 
          <label style={{display:'flex', alignItems:'center'}} >
            <LogisviewCheckbox  checked={cmsYn}  onChange={()=>setCmsYn(true)} />
            <span>신청</span>
          </label>
          <label style={{display:'flex', alignItems:'center', marginLeft:'12px'}}>
            <LogisviewCheckbox  checked={!cmsYn}  onChange={()=>setCmsYn(false)}/>
            <span>신청안함</span>
          </label>
        </GridItem>
      </GridContainer>
      {cmsYn?<>
      <GridContainer style ={{marginTop:'24px'}}>
        <GridItem xs={12} sm={2} style ={{display:'flex',alignItems:'center'}}>
          <h6 style ={{ fontSize:'16px',fontWeight:'500' ,color:'#222',}}>
          이체 계좌 정보
          </h6>
        </GridItem>
        <GridItem xs={12} sm={10} style ={{display:'flex',alignItems:'center'}}>
          <Grid container>
          <GridItem xs={12} sm={1} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    은행명
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <Autocomplete
                  className={classes.textField}
                  options = {bankCode}
                  getOptionLabel = { option => option.label ||''}
                  getOptionSelected = {(option,value) => option.value === value.BANK_CODE ||''}
                  id="BANK_CODE"
                  readOnly={true}
                  value= {accountData&&bankCode.find(v=>v.value === accountData.BANK_CODE)
                    ?bankCode.find(v=>v.value === accountData.BANK_CODE) :''}
                  onChange={(e, option)=>option?setAccountData((old)=>{return {...old,BANK_CODE: option.value,BANK : option.label}}): ''}
                  // onBlur={()=>setAccountData((old)=>{return {...old}})}
                  // onBlur={ ()=>props.fncOnBlurData(index, formData)}
                  // onChange={(e, option)=>fncSelectCode(option?option.value:null, 'BANK', index)}
                  // onBlur={ ()=>props.fncOnBlurData(index, formData)}
                  renderInput={
                    params =>(
                      <TextField
                        inputProps={{maxLength:30}}
                        {...params}
                        label=""
                        fullWidth
                        id="BANK"
                        // success={formData.COM_CLASS?true:false}
                        error={accountData.BANK_CODE?false:true}
                        helperText={accountData.BANK_CODE?null:'필수'}
                        readOnly
                        style={{marginTop:'7px'}}
                      />)}
                />
                </GridItem>
                <GridItem xs={12} sm={1} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    예금주명
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={2}>
                  <CustomInput
                    validtype="text"
                    success={accountData.DEPOSIT?true:false}
                    error={accountData.DEPOSIT?false:true}
                    labelText=""
                    maxLength="20"
                    id="DEPOSIT"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                      value:accountData.DEPOSIT||'',
                      onChange: (e)=>fncOnChangeAcc(e),
                    }}
                    required={true}
                    feedback="formData"
                  />
                </GridItem>
                <GridItem xs={12} sm={1} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    계좌번호
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <CustomInput
                    validtype="number"
                    success={accountData.ACCOUNT_NO?true:false}
                    error={accountData.ACCOUNT_NO?false:true}
                    labelText=""
                    maxLength="20"
                    id="ACCOUNT_NO"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                      value:accountData.ACCOUNT_NO||'',
                      onChange: (e)=>fncOnChangeAcc(e),
                    }}
                    required={true}
                    feedback="formData"
                  />
                </GridItem>
                <GridItem xs={12} sm={2} className={classes.gridLabel}>
                  <InputLabel style={{ color: "#AAAAAA" }}>
                    사업자등록번호(법인) <br/>또는 생년월일(개인)
                  </InputLabel>
                </GridItem>
                <GridItem xs={12} sm={5}>
                  <CustomInput
                    validtype="number"
                    success={accountData.COMP_NO?true:false}
                    error={accountData.COMP_NO?false:true}
                    labelText=""
                    maxLength="10"
                    id="COMP_NO"
                    formControlProps={{
                      fullWidth: true,style:{paddingTop:'0',marginBottom:'10px'}
                    }}
                    inputProps={{
                      value:accountData.COMP_NO||'',
                      onChange: (e)=>fncOnChangeAcc(e),
                    }}
                    required={true}
                    feedback="formData"
                  />
                </GridItem>
                <GridItem xs={12} sm={5} className={classes.gridLabel}>
                  <p style={{fontSize:'12px', wordBreak:'keep-all'}}>*이체일 :  25일 (공휴일인 경우 익일, 잔고부족시 말일 재출금)</p>
                </GridItem>
              </Grid>
            </GridItem>
          </GridContainer>
          <GridContainer >
              
            <GridItem xs={12} sm={2} style ={{display:'flex',alignItems:'center'}}></GridItem>
              <GridItem xs={12} sm={10} style ={{display:'flex',alignItems:'center'}}>
                <div className={Style.agreeScrolls}>
                  <div className={Style.reqWrap}>
                    <div className={Style.lt}>
                      <TableContainer className={classes.tableLine}>
                        <Table 	
                          stickyHeader aria-label="sticky table"
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          size='medium'
                          style ={{height:'100%'}}
                          >
                          <TableHead>
                            <TableRow>
                              <TableCell style={{width: '100%'}} className={classes.tableHeadercell} align="center">개인정보 수집 및 이용동의</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody> 
                            <TableRow className={classes.root}>
                              <TableCell align="center" className={classes.tablecell} style={{display:'flex',height:'100%'}}>
                                - 수집 및 이용 목적 : CMS 출금이체를 통한 요금수납
                          <br/> - 수집항목 : 성명, 생년월일, 전화번호, 휴대전화번호, 금융기관명, 계좌번호
                          <br/> - 보유 및 이용 기간 : 수집 및 이용 동의일로부터 CMS 출금이체 종료일(해지일) 후 5년까지
                          <br/> - 신청자는 개인정보 및 고유식별정보 수집 및 이용을 거부할 권리가 있으며, 권리행사시 자동이체 신청이 거부될 수 있습니다. 
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>  
                      <div style={{display:'flex',}}>
                        <label style={{display:'flex', alignItems:'center'}}>
                          <LogisviewCheckbox  checked={accountData.CMS_AGREE||false} onChange={handleChangeCmsAgree} name='CMS_AGREE'/>
                          <span>동의함</span>
                        </label>
                        <label style={{display:'flex', alignItems:'center', marginLeft:'20px'}}>
                          <LogisviewCheckbox  checked={!accountData.CMS_AGREE||false} onChange={handleChangeCmsAgree} name='CMS_AGREE'/>
                          <span>동의안함</span>
                        </label>
                      </div>
                    </div>
                    <div className={Style.rt}>
                      <TableContainer className={classes.tableLine}>
                        <Table 	
                          stickyHeader aria-label="sticky table"
                          className={classes.table}
                          aria-labelledby="tableTitle"
                          size='medium'
                          style ={{height:'100%'}}
                          >
                          <TableHead>
                            <TableRow>
                              <TableCell style={{width: '100%'}} className={classes.tableHeadercell} align="center">개인정보 제3자 제공동의</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody> 
                            <TableRow className={classes.root}>
                              <TableCell align="center" className={classes.tablecell} style={{display:'flex',height:'100%'}}>
                                  - 개인정보를 제공받는 자 : 사단법인 금융결제원
                              <br/>- 개인정보를 제공받는 자의 개인정보 이용 목적
                              <br/>: CMS 출금이체 서비스 제공 및 출금동의 확인, 출금이체 신규 등록 및 해지 사실 통지
                              <br/>- 제공하는 개인정보의 항목 
                              <br/>: 성명, 생년월일, 전화번호, 휴대전화번호, 금융기관명, 계좌번호
                              <br/>- 개인정보를 제공받는 자의 개인정보 보유 및 이용기간
                              <br/>: CMS 출금이체 서비스 제공 및 출금동의 확인 목적을 달성할 때까지
                              <br/>- 신청자는 개인정보에 대해 금융결제원에 제공하는 것을 거부할 권리가 있으며, 거부시 출금이체 신청이 거부될 수 있습니다.
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>  
                      </TableContainer>  
                            
                      <div style={{display:'flex',}}>
                        <label style={{display:'flex', alignItems:'center'}} >
                          <LogisviewCheckbox  checked={accountData.CMS_THIRD_AGREE||false} onChange={handleChangeCmsAgree} name='CMS_THIRD_AGREE'/>
                          <span>동의함</span>
                        </label>
                        <label style={{display:'flex', alignItems:'center', marginLeft:'12px'}}>
                        <LogisviewCheckbox  checked={!accountData.CMS_THIRD_AGREE||false} onChange={handleChangeCmsAgree} name='CMS_THIRD_AGREE'/>
                          <span>동의안함</span>
                        </label>
                      </div>
                    </div>
                </div>
            </div>
        </GridItem>
      </GridContainer>
      </>:''}
    </Paper>
  </>:''}
    <LocalAddress
			open={addressOpen}
			setOpen={setAddressOpen}
			onClose={()=> setAddressOpen(false)}
			setReturnAddress={setReturnAddress}
      alertMessage={(e)=>{ props.onAlert("error",e)}}
      />
  </React.Fragment>
)}

export default ApplicationParamsForm;