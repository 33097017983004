/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import {Row, Col, CardBody, Collapse, UncontrolledTooltip, Button,FormGroup, Label,Input, Card} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import OtherBookmark from './OtherBookmark.js';
import InputValid from "components/CustomInput/InputValid.js";
import {paymentList} from 'components/common/options.js';
//import $ from 'jquery';


const BookingCard = forwardRef((props, bookingFocus) => {

    // modal 창을 위한 state
    const [coll, setColl] = useState(false);
    // 중요내용 부모/자식 공유를 위한 state
    const [booking, setBooking] = useState({});
    // SC NUMBER state
    const [otherList, setOtherList] = useState([]);
    // ServiceCode 공통코드
    const [serviceList, setServiceList] = useState([]);
    const {lineCode,lineConfigDetail, optionCheck, mergeConfig} = props;

    useEffect(()=>{
        if(lineCode) {
            selectLineCodeServiceType(lineCode);
        }
    },[lineCode]);

    useEffect(()=>{
        setColl(props.openWindow);
    },[props.openWindow]);

    // 부모로부터 파라미터 전달 받음
    useEffect(()=>{
        if( props.booking.bkg_no ) {
            setBooking(props.booking);
        }
    },[props.booking]);

    //Term 옵션화 
    useEffect(()=>{
        checkBookingCardConfig();
    },[booking.trans_service_code]);

    const checkBookingCardConfig = async() => { //async -await 
        const service_code=booking?.trans_service_code||null;
        if(lineCode){
            let result =  await optionCheck([{'typeVal':'SVC_TYPE','codeVal':service_code}]);
            // mergeConfig({...result});
        }
    }

    // 부모로부터 파라미터 전달 받음
    useEffect(()=>{
        setOtherList(props.otherList);
    },[props.otherList]);

    // Other select 선택할때
    const fncSelectOther = (e) => {
        // 선택
        if( e ) {
            if( 1 > e.value ) {
                // setBooking({});
                if ( coll ) {
                    setColl(!coll)
                }
            // 그외 데이터인 경우
            } else {
                otherList.forEach((element, key)=>{
                    if( e.value === element.other_bookmark_seq) {
                        // 2021.10.19 bookmark 비어 있는 항목은 유지하도록 조치
                        let list = {...booking
                            ,'other_bookmark_seq':element.other_bookmark_seq
                            ,'other_bookmark_name':element.other_bookmark_name
                            ,'sc_no': element.sc_no?element.sc_no:booking.sc_no
                            ,'remark1': element.remark1?element.remark1:booking.remark1
                            // ,'remark2': element.remark1?element.remark2:booking.remark2
                            ,'trans_service_code': element.trans_service_code
                            ?(serviceList.findIndex(x=>x.value===element.trans_service_code)>=0)
                                ?element.trans_service_code
                                :booking.trans_service_code ||null                                                        
                            :null
                            // ,'commodity': element.commodity?element.commodity:booking.commodity
                            ,'selected_yn':'Y'
                        }
                        if(!props?.lineConfigDetail?.bkg_goods_title){list ={...list,'commodity': element.commodity?element.commodity:booking.commodity}}
                        if (lineConfigDetail?.shp_payment_type=='true'){
                            list = {...list , 
                                'shp_payment_type': element.shp_payment_type
                                    ?(paymentList.findIndex(x=>x.value===element.shp_payment_type)>=0)
                                        ?element.shp_payment_type
                                        :booking.shp_payment_type||null
                                    :null}
                        }
                        setBooking(list);
                        props.fncBookingParent(list);
                    }
                });
                if ( !coll ) {
                    setColl(!coll);
                }
            }
        } else {
            if( booking.other_bookmark_seq ) {
                let list = {...booking
                    ,'other_bookmark_seq': null
                    ,'other_bookmark_name': null
                    ,'sc_no': null
                    ,'remark1': null
                    // ,'remark2': null
                    ,'trans_service_code': null
                    // ,'shp_payment_type': null
                    ,'commodity': null
                    ,'selected_yn':'Y'
                }
                if (lineConfigDetail?.shp_payment_type=='true') list = {...list ,'shp_payment_type': null }
                setBooking(list);
                props.fncBookingParent(list);
            }
        }
    }

    // // 조회
    const selectBookingOtherBookmark = () => {
        props.selectBookingOtherBookmark();
    }
    // 수정된 내용은 Booking 저장
    const fncOnChange = ( e, key ) => {
        // e.preventDefault();
        let text = {...booking, [key]:(e.target.value.toUpperCase())||null};
        setBooking(text);
    }

    const selectLineCodeServiceType = (lineCode) => {
        axios.post("/shipper/selectLineCodeServiceType",{ params:{line_code:lineCode}}).then(res => {
            setServiceList(res.data)
        });
    }

    const fncBookingParent =()=>{
        props.fncBookingParent(booking);
    }
    return (
        <>
            <Row id="Booking">
                <Col xl="12" lg="12">
                    <Card style={{zIndex:'100'}}>
                        <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>  
                            <Row className="pb-2">
                                <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>BOOKING</Col>
                                <Col>
                                    <Row>
                                        <Col className="col-10 pr-0">
                                            <Select
                                                className="react-select react-select-primary"
                                                name="bookingBookmark"
                                                value={{value:booking.other_bookmark_seq?booking.other_bookmark_seq:''
                                                ,label:booking.other_bookmark_name?booking.other_bookmark_name:'선택'}}
                                                onChange={(e)=>fncSelectOther(e?e:null)}
                                                options={otherList}
                                                placeholder="선택"
                                                ref={bookingFocus}
                                                id="bookingBookmark"
                                                isClearable={booking.other_bookmark_seq?true:false}
                                            />
                                        </Col>
                                        <Col className="col-2 pl-auto pr-auto">
                                            <OtherBookmark
                                                otherList={otherList}
                                                selectBookingOtherBookmark={selectBookingOtherBookmark}
                                                onAlert={props.onAlert}
                                                serviceList={serviceList}
                                                {...props}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Collapse isOpen={coll}>
                                <hr className="mt-0"/>
                                <Row>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Request</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="bkg_no"
                                                    id="bkg_no"
                                                    maxLength="35"
                                                    value={booking.bkg_no?booking.bkg_no:''}
                                                    onChange={(e)=>fncOnChange(e, 'bkg_no')}
                                                    onBlur={fncBookingParent}
                                                    validtype="eng" 
                                                    required={true}
                                                    readOnly
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">SC Number</Label></Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="sc_no"
                                                        id="sc_no"
                                                        maxLength="20"
                                                        value={booking.sc_no?booking.sc_no:''}
                                                        onChange={(e)=>fncOnChange(e, 'sc_no')}
                                                        onBlur={fncBookingParent}
                                                        validtype="engNumber" 
                                                        required={false}
                                                        feedid="booking"
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Term</Label></Col>
                                                <Col>
                                                    <Select
                                                        className="customSelect"
                                                        name="trans_service_code"
                                                        placeholder=""
                                                        value={{value:booking.trans_service_code?booking.trans_service_code:'',
                                                        label:booking.trans_service_code?
                                                            (serviceList.findIndex(x=>x.value===booking.trans_service_code)>=0)?
                                                            serviceList[serviceList.findIndex(x=>x.value===booking.trans_service_code)].label:
                                                                '선택':
                                                            '선택'
                                                        }}
                                                        onChange={(value)=>{setBooking({...booking,'trans_service_code':value.value}); props.fncBookingParent({...booking,'trans_service_code':value.value})}}
                                                        // onBlur={(e)=>props.fncBookingParent(booking)}
                                                        options={serviceList}
                                                            styles={{
                                                                control: provided => ({...provided,border:!booking.trans_service_code?'1px solid red':'' }),
                                                                indicatorContainer: provided => ({...provided,color:''})
                                                            }}
                                                        />
                                                    <InputValid
                                                        hidden
                                                        type="text"
                                                        name="trans_service_code"
                                                        id="trans_service_code"
                                                        maxLength="3"
                                                        value={booking.trans_service_code?booking.trans_service_code:''}
                                                        validtype="select"
                                                        required={true} 
                                                        feedid="booking"
                                                        readOnly
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    {(lineConfigDetail?.shp_payment_type=='true')&&
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Payment</Label></Col>
                                                <Col>
                                                <Select
                                                    className="customSelect"                                                    
                                                    name="shp_payment_type"
                                                    placeholder=""
                                                    value={{value:booking.shp_payment_type?booking.shp_payment_type:'',
                                                    label:booking.shp_payment_type?
                                                        (paymentList.findIndex(x=>x.value===booking.shp_payment_type)>=0)?
                                                        paymentList[paymentList.findIndex(x=>x.value===booking.shp_payment_type)].label:
                                                            '선택': '선택'
                                                    }}
                                                    onChange={(value)=>setBooking({...booking,'shp_payment_type':value.value})}
                                                    onBlur={(e)=>props.fncBookingParent(booking)}
                                                    options={paymentList}
                                                    styles={{
                                                        control: provided => ({...provided,border:!booking.shp_payment_type?'1px solid red':'' }),
                                                        indicatorContainer: provided => ({...provided,color:''})
                                                    }}
                                                    />
                                                    <InputValid
                                                        hidden
                                                        type="text"
                                                        name="shp_payment_type"
                                                        id="shp_payment_type1"
                                                        placeholder=""
                                                        // maxLength="3"
                                                        value={booking.shp_payment_type?booking.shp_payment_type:''}
                                                        validtype="text"
                                                        required={true} 
                                                        feedid="booking"
                                                        readOnly
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    }
                                    {props?.lineConfigDetail?.bkg_goods_title?<></>:
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Commodity</Label></Col>
                                                <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="commodity"
                                                    id="commodity"
                                                    maxLength="70"
                                                    value={booking.commodity?booking.commodity:''}
                                                    onChange={(e)=>fncOnChange(e, 'commodity')}
                                                    onBlur={fncBookingParent}
                                                    validtype="koreanban"
                                                    required={true}
                                                    feedid="booking"
                                                />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    }
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Remark</Label></Col>
                                                <Col>
                                                    <InputValid 
                                                        type="text"
                                                        name="remark1"
                                                        id="remark1"
                                                        maxLength="70"
                                                        value={booking.remark1?booking.remark1:''}
                                                        onChange={(e)=>fncOnChange(e, 'remark1')}
                                                        onBlur={(e)=>props.fncBookingParent(booking)}
                                                        // validtype="english" 
                                                        validtype="koreanban" 
                                                        required={false} //20210624 필수항목제거
                                                        feedid="booking"
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col xl="12" lg="12" md="12">
                                        <FormGroup className="mb-1">
                                            <Row>
                                                <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Send Date</Label></Col>
                                                <Col>
                                                <Input type="text" name="send_date" id="send_date" 
                                                    defaultValue={booking.send_date?booking.send_date:''}
                                                    readOnly/>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Collapse>
                            <div className="text-center" onClick={() => setColl(!coll)}>
                                <div>
                                    <Button className="p-0" color="link" id="linemore" onClick={() => setColl(!coll)} style={{height:'21px'}}>
                                        {coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="linemore">{coll?'Close':'Open'}</UncontrolledTooltip>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
})

export default BookingCard;