/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, createRef } from 'react';
import { Row, Col,  CardHeader, Collapse,FormGroup,Label,Input, Card, CardBody, Button, Tooltip, ButtonGroup,Badge } from "reactstrap";
import Special from './Special';
import axios from 'axios';
import Moment from 'moment';
import InputValid from "components/CustomInput/InputValid.js";
import InputValidCustom from "components/CustomInput/InputValidCustom.js";
import Select from "react-select";
import * as validation from 'muiComponents/common/validation.js';
import * as options from 'muiComponents/common/options.js';
import {CustomDatePicker} from 'components/CustomInput/reactstrap/CustomInput'
import { isMoment } from 'moment';
import _ from 'lodash';
import  FormControlLabel  from '@material-ui/core/FormControlLabel';
import  Checkbox  from '@material-ui/core/Checkbox';

export default function Container(props){
    const [index, setIndex] = useState('0');
    // Container 
    const [container, setContainer] = useState({});
    const [containerSpecialList, setContainerSpecialList] = useState([]);
    // Container Bookmark List  
    const [containerBookmarkList, setContainerBookmarkList] = useState([]);
    // Special
    const [specialBookmarkList, setSpecialBookmarkList] = useState([]);
    // OPEN_TYPE CARD, BOOK
    const [openType, setOpenType] = useState("");
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const [norFlag, setNorFlag] = useState(null);

    const fileInput = createRef();

    const {dangerTrue,allAttachFiles} = props;
    useEffect(() => {
        setContainer(props.container);
    },[props.container]);
    
    useEffect(()=> {
        let cntrAllAttachFiles = props.allAttachFiles?Object.values(props.allAttachFiles):[];
        if(cntrAllAttachFiles&&cntrAllAttachFiles.length<1) {
            setFiles([]);
        }else{
           let cntrNo =  props.container&&props.container.cntr_seq ||0;
           let newFile = allAttachFiles[cntrNo]||[];
           setFiles(newFile)
        }
    },[props.allAttachFiles]);

    useEffect(() => {
        setOpenType(props.openType);
    },[props.openType]);
   
    useEffect(() => {
        setNorFlag(props.container.cntr_nor_flag)        
    },[props.container.cntr_nor_flag]);

    useEffect(() => {
        setIndex(props.index);
    },[props.index]);

    useEffect(() => {
        setContainerBookmarkList( props.containerBookmarkList);
    },[props.containerBookmarkList]);
    
    useEffect(() => {
        setSpecialBookmarkList(props.specialBookmarkList);
    },[props.specialBookmarkList]);

    useEffect(() => {
        setContainerSpecialList(props.containerSpecialList);
    },[props.containerSpecialList]);
    // Container 자식 부모 
    const fncOnBlur=(container)=>{
        setContainer(container);
        props.fncOnBlurContainer( index, container);
    }
    // Special 자식 부모 처리
    const fncOnBlurSpecial=(index, special)=>{
        containerSpecialList[index] = special;
        setContainerSpecialList([...containerSpecialList]);
        props.fncOnBlurSpecialList([...containerSpecialList]);
    }

    // 콤보박스에서 Special Bookmark 선택한 경우(스페셜북마크안씀)
    // const fncSelectSpecialList=(e, i, cntr_seq)=>{
    //     // Special Bookmark 정보에 선택한 정보를 입력한다.
    //     containerSpecialList && containerSpecialList.forEach((element, key)=>{
    //         // Special Bookmark 동일한 콤보박스 위치를 찾는다.
    //         if( key === i ) {
    //             // Special Bookmark 목록을 뒤져서
    //             specialBookmarkList.forEach(( row, i )=> {
    //                 // 어느걸 선택했는지를 찾는다.
    //                 if( e.value === row.container_special_bookmark_seq ) {
    //                     // 찾은 row를 SpecialList 정보에 넣는다.
    //                     row.cntr_seq = cntr_seq;
    //                     containerSpecialList[key] = row;
    //                 } 
    //             });
    //             setContainerSpecialList([...containerSpecialList]);
    //             props.fncOnBlurSpecialList([...containerSpecialList]);
    //         }
    //     });
    // }
    const onDelContainer = ()=>{
        // axios.post("/shipper/deleteAttachRowDel",{container:container,gubun:'BK'}).then(res=>{
        // })
        props.onDelContainer(index);
    }
    const onDelSpecial=(special)=>{
        props.onDelSpecial(special);
    }
    const handleClick = () => {
        if(files && files.length === 3) {
            props.onAlert("error", "첨부파일은 최대 3개 까지 업로드 가능합니다.");
            return;
        }
        fileInput.current.click();
    };
    const handleImageChange = async(file) => {
        file.preventDefault();
        if(!file.target.files[0]) {
            return false;
        }
        if(!validation.koreanBan(file.target.files[0].name)) {
            props.onAlert('error', '파일명에 한글이 포함될 수 없습니다.' );
            return false;
        }
        
        if(!/\.(xls|xlsx|txt|pdf)$/i.test(file.target.files[0].name)) {
            props.onAlert('error', '적절하지 않은 파일 형식입니다.' );
            return false;
        }
        if(file.target.files[0].size > 4000000) {
            props.onAlert('error', '파일의 크기가 너무 큽니다.' );
            return false;
        }
        
        const formData = new FormData();
        formData.append("doc_number", props.booking.bkg_no);
        formData.append("doc_date", props.booking.bkg_date);
        formData.append("doc_user", props.booking.user_no);
//      formData.append("attach_gb",props.cargo.cargo_type==="3"?"S":props.cargo.cargo_type==="4"?"C":null);
        formData.append("attach_gb",props.cargo.cargo_type==="3"?"S":props.cargo.cargo_type==="4"?"C"
        :props.cargo.cargo_type||null);//S와C는 기존에 프리즘에사용중
        // formData.append("attach_gb",props.cargo?.cargo_type||null);///카고타입 추가로 카고타입번호로 대체 (24.02.21)
        formData.append("cntr_seq",container.cntr_seq);
        formData.append("file",file.target.files[0]);
        await axios.post("/shipper/saveAttachFile",formData).then(res => {
                // if(res.data && res.data.success == '1') {
                    const result =res.data.result;
                    setFiles([...files,result]);
                    let obj = {...allAttachFiles}
                    obj[result.cntr_seq]=[...files,result]
                    props.setAllAttachFiles({...obj});
                // }else {
                //     //console.log(res.data);
                // }
        }).catch(e => {
            props.onAlert('error',validation.ERR_MSG);
        })
    };
    const handleRemoveFile = (num) => {
        axios.post('/shipper/deleteAttachFile',{doc_number:props.booking.bkg_no, doc_date:props.booking.bkg_date, doc_gubun:'BK', param:files[num-1]}).then(res => {
            if(res.statusText==="OK") {
                if(res.data==="success") {
                    props.onAlert('success','삭제가 완료되었습니다.');
                    let obj = {...allAttachFiles}
                    let fileData =files[num-1];
                    obj[fileData.cntr_seq]=[files.filter((value,e)=> e !== (num-1))]
                    props.setAllAttachFiles({...obj});
                    setFiles(files.filter((value,e)=> e !== (num-1)))
                }else {
                    props.onAlert('error',validation.ERR_MSG);
                }
            }
        }).catch(e => {
            props.onAlert('error',validation.ERR_MSG);
        })
        fileInput.current.value = null;
    };

    return (
        <>
            <Col>
                <Card className="no-transition" style={{border:'1px solid silver', paddingBottom:'25px', zIndex : 'auto'}} >
                    <CardHeader className="pt-1 pb-1">
                        <Row>
                            <Col className="col-3">
                                <Label className="mb-0">Container</Label>
                                <Select
                                    className="customSelect"
                                    name="container_bookmark"
                                    value={{value:container.container_bookmark_seq?container.container_bookmark_seq:'',
                                            label:container.container_bookmark_seq?(containerBookmarkList.findIndex(x=>x.container_bookmark_seq===container.container_bookmark_seq)>=0)?containerBookmarkList[containerBookmarkList.findIndex(x=>x.container_bookmark_seq===container.container_bookmark_seq)].label:'선택':'선택'
                                    }}
                                    onChange={(e) => props.fncSelectContainerList(e, index)}
                                    options={containerBookmarkList}
                                    isClearable={container.container_bookmark_seq?true:false}
                                />
                            </Col>
                            <Col>
                                <input style={{display:'none'}} type="file" onChange={handleImageChange} ref={fileInput} />
                                <ButtonGroup className="pull-right pr-2 pt-3">
                                    {(props.cargo && props.cargo.cargo_type ==='3' || props.cargo.cargo_type ==='4') &&
                                    <>
                                        <Button
                                            id="btn1"
                                            className="pt-0 pb-0"
                                            color="default"
                                            data-toggle="tooltip"
                                            outline
                                            type="button"
                                            onClick={handleClick}><i className="fa fa-upload"/>업로드
                                        </Button>
                                        <Tooltip placement="top" isOpen={tooltipOpen} target="btn1" toggle={() => setTooltipOpen(!tooltipOpen)}>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <span style={{fontWeight:'bold', fontSize:'1rem'}}>허용 파일</span><span> : xls, xlsx, txt, pdf </span>
                                                        <br></br>
                                                        <span style={{fontWeight:'bold', fontSize:'1rem'}}>파일 크기 제한</span><span> : 40MB</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Tooltip>
                                    </>}
                                    {props.index !== 0 &&
                                    <Button
                                        className="pt-0 pb-0"
                                        id="btn1"
                                        color="default"
                                        data-toggle="tooltip"
                                        outline
                                        type="button"
                                        onClick={() => onDelContainer()}><i className="fa fa-times"/>삭제</Button>}
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-3">
                            </Col>
                            <Col>
                                <Row>
                                {(props.cargo?.cargo_type ==='3' || props.cargo?.cargo_type ==='4') 
                                 &&files.length>0 && files.map((value,index) => {
                                return(
                                    <Col key={index} className="pull-right pr-2">
                                        {value &&
                                        <>
                                        <Button 
                                            className="btn-link" 
                                            outline
                                            style={{paddingLeft:'8px',textDecorationLine:'underline',color:'blue'}} >{validation.textLengthOverCut(value.real_file_name?value.real_file_name:'',20,'...')}
                                        </Button>
                                        <Button
                                            color="danger"
                                            className="btn-round btn-link"
                                            onClick={() => handleRemoveFile(index+1)}><i className="fa fa-times" />
                                        </Button>
                                        </>}
                                    </Col>)
                                })}
                                </Row>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-3 pb-3">
                        <ContainerForm
                            container={container}
                            fncOnBlur={fncOnBlur}
                            openType={openType}
                            norFlag={norFlag}
                            {...props}/>
                        <Row>
                            <Col xl="12" lg="12">
                            {containerSpecialList && containerSpecialList.map((element, key)=>{
                                if(!element.cntr_seq ) {
                                    element.cntr_seq = container.cntr_seq;
                                }
                                if( element.cntr_seq == container.cntr_seq ) {
                                    return (
                                        <Collapse isOpen={dangerTrue} key={index}>
                                            <Row>
                                                <Col xl="12" lg="12">
                                                            <Card className="no-transition mt-4" style={{border:'1px solid silver', paddingBottom:'25px', zIndex : 'auto'}}>
                                                                <CardHeader className="pt-1 pb-1">
                                                                    <Row>
                                                                        <Col>
                                                                            <Label className="mt-2" style={{fontWeight:'bold',fontSize:'15px',color:'#696969'}}>DG INFO</Label>
                                                                        </Col>
                                                                    </Row>
                                                                    {/* <Row>
                                                                        <Col className="col-3">
                                                                            <Input 
                                                                                type="select" 
                                                                                key={key}
                                                                                onChange={(e) => {
                                                                                    fncSelectSpecialList(e, key, container.cntr_seq)
                                                                                }}
                                                                                className={("MAIN"===openType)?"pt-0 pb-0":null}
                                                                                value={element.container_special_bookmark_seq?element.container_special_bookmark_seq:'0'}>
                                                                                <option key={0} value={'0'}>선택</option>
                                                                                {(specialBookmarkList.length>0)?specialBookmarkList.map((row,i)=>{
                                                                                    return(
                                                                                        <option key={i} value={row.container_special_bookmark_seq}>
                                                                                            {row.container_special_bookmark_name}
                                                                                        </option>
                                                                                    )
                                                                                }):<></>}
                                                                            </Input>
                                                                        </Col>
                                                                    </Row> */}
                                                                </CardHeader>
                                                                <CardBody className="pt-3 pb-3">
                                                                    <Col xl="12" lg="12">
                                                                        <Special
                                                                            key={key}
                                                                            index={key}
                                                                            special={element}
                                                                            fncOnBlurSpecial={fncOnBlurSpecial}
                                                                            onDelSpecial={onDelSpecial}
                                                                            lineCode={props.lineCode}
                                                                            dangerTrue={dangerTrue}
                                                                            openType={openType}
                                                                        />
                                                                    </Col>
                                                                </CardBody>
                                                            </Card>
                                                        {/* </Col>
                                                    </Row> */}
                                                </Col>
                                            </Row>
                                        </Collapse>
                                    )
                                }
                            })}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
}

const ContainerForm = (props) => {
    const ynList = [{value:'Y', label:'Y'},{value:'N', label:'N'}];
    const [container, setContainer] = useState({});
    // const [lineCodeCntrSztp, setLineCodeCntrSztp] = useState([]);
    const [lineCodeVesselPickup, setLineCodeVesselPickup] = useState([]);
    const [openType, setOpenType] = useState("");
    const [cntrTypeReefer, setCntrTypeReefer] = useState( false );
    const [oogType, setOogType] = useState( false );
    // const [cyDateValid, setCyDateValid] = useState(container.cntr_pick_up_cy_date?container.cntr_pick_up_cy_date:"");
    const [cyDateValid, setCyDateValid] = useState(false);
    const [cyDateDisabled,setCyDateDisabled]= useState(false);
    // const [cyDateStyleState,setCyDateStyleState]= useState({});
    const [checkEmptyYn,setCheckEmptyYn]= useState( container.cntr_empty_yn||false);
    const [checkNorYn,setCheckNorYn]= useState(false);
    // const [checkNorYn,setCheckNorYn]= useState( container.cntr_nor_flag||false);
    const {lineCode,cargo,lineCodeCntrSztp} = props;
    const changeEmptyYn = (v) => {setCheckEmptyYn(v); fncOnChangeSelect(v?'F':null, 'cntr_empty_yn')}
    const changeNorYn = (v) => {setCheckNorYn(v); fncOnChangeSelect(v?'Y':null, 'cntr_nor_flag')}
    const cntrReset ={
        cntr_frozen_tmp:null, cntr_frozen_tmp_unit:null
        ,cntr_special_type:options.specialType[0].value, cntr_width:null, cntr_height:null, cntr_length:null
        ,cntr_item_qty:null,cntr_stowage_cell:null,cntr_break_bulk_yn:null
        ,cntr_commodity:null,cntr_mng_remark:null
    }
    

    useEffect(() => {
        let obj = {}
        if(container?.cntr_soc_yn !=='Y'){//  soc N or null
            setCyDateDisabled(false); //활성화
            
            if(container?.cntr_pick_up_cy_date){
                let cyDate = Moment(container.cntr_pick_up_cy_date,'YYYYMMDD',true)||container.cntr_pick_up_cy_date;
                if(cyDate.isValid()){
                    obj = {cntr_pick_up_cy_date_name: cyDate.format('YYYY-MM-DD'),
                        cntr_pick_up_cy_date:cyDate.format('YYYYMMDD')
                    }
                    setCyDateValid(true);
                }else{
                    obj = {cntr_pick_up_cy_date_name: null,
                        cntr_pick_up_cy_date:null
                    }
                    setCyDateValid(false);
                }
            }            
        }else{
            setCyDateValid(false);
            setCyDateDisabled(true);
        }
        if(container?.cntr_soc_yn !=='Y'||container?.cntr_empty_yn!=='F'){
            setCheckEmptyYn(false);
        }else{
            setCheckEmptyYn(true);
        }
    },[container?.cntr_soc_yn]);

    useEffect(() => {
        setContainer(props.container);
        setOpenType(props.openType);
        if(props.container){openCntrSztp(props.container.cntr_code,props.container);}
    },[props.container]);

    useEffect(()=>{
        if(props.norFlag=='Y'){setCheckNorYn(true)}else{setCheckNorYn(false)}
    },[props.norFlag]);

    useEffect(()=> {
        if(lineCode) {
            if(lineCode ==='VSS') {
                selectLineCodeVesselPickup({line_code:lineCode,sch_pod:props.booking.sch_pod});
            }else {
                selectLineCodeVesselPickup({line_code:lineCode});
            }
        }
    },[lineCode, props.booking.sch_pod]);
    
    useEffect(() => {
        openCntrSztp(props.container.cntr_code,props.container);
    },[lineCodeCntrSztp]);

    const openCntrSztp = (val,cntr)=>{
        let cntrVal = cntr ||container;
        if(lineCodeCntrSztp.length>0){
            let cntr_iso_code = (lineCodeCntrSztp.findIndex(x=>x.cntr_code===val)>=0)
            ?lineCodeCntrSztp[lineCodeCntrSztp.findIndex(x=>x.cntr_code===val)].iso_code 
            :null ;
            if (cntr_iso_code  && (_.includes(['R','3'],cntr_iso_code.substr(2,1)))) {
                setCntrTypeReefer( true );
                setOogType(false);
                setContainer({...cntrVal, cntr_code:(cntr_iso_code?val:null), cntr_iso_code:cntr_iso_code,  'cntr_length': null, 'cntr_width': null, 'cntr_height': null});
                // props.fncOnBlur({...container, cntr_code:val, cntr_iso_code:cntr_iso_code, 'cntr_length': null, 'cntr_width': null, 'cntr_height': null});
            } else if(cntr_iso_code && (_.includes(['U','P','O','S','F','5','6'],cntr_iso_code.substr(2,1)))) {
                setOogType(true);
                setCntrTypeReefer( false );
                setContainer({...cntrVal, cntr_code:(cntr_iso_code?val:null), cntr_iso_code:cntr_iso_code, 'cntr_frozen_tmp': null, 'cntr_frozen_tmp_unit': null, 'cntr_humidity':null, 'cntr_vent_open': null} );
                // props.fncOnBlur({...container, cntr_code:val, cntr_iso_code:cntr_iso_code, 'cntr_frozen_tmp': null, 'cntr_frozen_tmp_unit': null, 'cntr_humidity':null, 'cntr_vent_open': null} );
            }else {
                setCntrTypeReefer( false );
                setOogType(false);
                setContainer({...cntrVal, cntr_code:(cntr_iso_code?val:null), cntr_iso_code:cntr_iso_code, 'cntr_frozen_tmp': null, 'cntr_frozen_tmp_unit': null, 'cntr_humidity':null, 'cntr_vent_open': null, 'cntr_length': null, 'cntr_width': null, 'cntr_height': null});
                // props.fncOnBlur({...container, cntr_code:val, cntr_iso_code:cntr_iso_code, 'cntr_frozen_tmp': null, 'cntr_frozen_tmp_unit': null, 'cntr_humidity':null, 'cntr_vent_open': null, 'cntr_length': null, 'cntr_width': null, 'cntr_height': null});
            }
        }
    }

    // Container VESSEL PICKUP CY 목록조회
    const selectLineCodeVesselPickup = (params) => {
        axios.post("/shipper/selectLineCodeVesselPickup",{ params,lineCode:lineCode }).then(res=>{
            setLineCodeVesselPickup(res.data);
            if( res.data.length > 0 && container.cntr_pick_up_cy_code ) {       
                let row = res.data.find(( item ) => {
                    return item.pickup_cy_code === container.cntr_pick_up_cy_code;
                });
                if( !row ) {
                    setContainer({
                        ...container,
                        cntr_pick_up_cy_code: null,
                        cntr_pick_up_cy_name1: null,
                        cntr_pick_up_cy_name2: null,
                        cntr_pick_up_cy_address1: null,
                        cntr_pick_up_cy_date: null,
                        cntr_pick_up_cy_date_name:null
                    });
                    props.fncOnBlur({
                        ...container,
                        cntr_pick_up_cy_code: null,
                        cntr_pick_up_cy_name1: null,
                        cntr_pick_up_cy_name2: null,
                        cntr_pick_up_cy_address1: null,
                        cntr_pick_up_cy_date: null,
                        cntr_pick_up_cy_date_name:null
                    });
                }
                setCyDateValid(false);
            }
        });
    }
   
    // 수정된 내용은 cntr 저장
    const fncOnChangeSelect = ( value, key ) => {
        if ( 'cntr_code' === key ) {
            let code = value;
            openCntrSztp(code,container);
        } else if ( 'cntr_pick_up_cy_code' === key ) {
            
            let row = lineCodeVesselPickup.find( function( item ) {
                return item.pickup_cy_code === value;
            });
            const date = props.booking.sch_etd.substr(0,8)|| Moment();
            if( row ) {
                setContainer({...container
                    , cntr_pick_up_cy_code:row.pickup_cy_code
                    , cntr_pick_up_cy_name1:row.pickup_cy_name
                    , cntr_pick_up_cy_name2:null
                    , cntr_pick_up_cy_address1:row.pickup_cy_addr
                    , cntr_pick_up_cy_address2:null
                    , cntr_pick_up_cy_address3:null
                    , cntr_pick_up_cy_address4:null
                    , cntr_pick_up_cy_address5:null
                    , cntr_cfs_code:null
                    , cntr_cfs_name1:null
                    , cntr_cfs_name2:null
                    , cntr_cfs_address1:null
                    , cntr_cfs_address2:null
                    , cntr_cfs_address3:null
                    , cntr_cfs_address4:null
                    , cntr_cfs_address5:null
                    , cntr_pick_up_cy_date:Moment(date).subtract(1,'d').format('YYYYMMDD')
                    , cntr_pick_up_cy_date_name:Moment(date).subtract(1,'d').format('YYYY-MM-DD')
                });
                props.fncOnBlur({...container
                    , cntr_pick_up_cy_code:row.pickup_cy_code
                    , cntr_pick_up_cy_name1:row.pickup_cy_name
                    , cntr_pick_up_cy_name2:null
                    , cntr_pick_up_cy_address1:row.pickup_cy_addr
                    , cntr_pick_up_cy_address2:null
                    , cntr_pick_up_cy_address3:null
                    , cntr_pick_up_cy_address4:null
                    , cntr_pick_up_cy_address5:null
                    , cntr_cfs_code:null
                    , cntr_cfs_name1:null
                    , cntr_cfs_name2:null
                    , cntr_cfs_address1:null
                    , cntr_cfs_address2:null
                    , cntr_cfs_address3:null
                    , cntr_cfs_address4:null
                    , cntr_cfs_address5:null
                    , cntr_pick_up_cy_date:Moment(date).subtract(1,'d').format('YYYYMMDD')
                    , cntr_pick_up_cy_date_name:Moment(date).subtract(1,'d').format('YYYY-MM-DD')
                });
                setCyDateValid(true);
            }
        }else if(key ==='cntr_soc_yn') {
            if(value ==='Y') {
                setContainer({...container, 
                    cntr_pick_up_cy_code1:null,
                    cntr_pick_up_cy_code:null,
                    cntr_pick_up_cy_date_name:null,
                    cntr_pick_up_cy_date:null,
                    [key]:value
                });
                props.fncOnBlur({...container,
                    cntr_pick_up_cy_code1:null,
                    cntr_pick_up_cy_code:null,
                    cntr_pick_up_cy_date_name:null,
                    cntr_pick_up_cy_date:null,
                [key]:value} );
            }else {
                setContainer({...container,cntr_empty_yn:null, [key]:value});
                props.fncOnBlur( {...container, cntr_empty_yn:null, [key]:value} );
            }
        }else {
            setContainer({...container, [key]:value});
            props.fncOnBlur( {...container, [key]:value} );
        }
    }
    // 완료된 내용은 부모로 전달
    const fncOnBlur = (e) => {
        props.fncOnBlur( container );
    }
    // 수정된 내용은 container 저장
    const fncOnChange = ( e, key ) => {
        e.preventDefault();
        setContainer({...container, [key]:(e.target.value.toUpperCase())||null});
    }

    const fncOnChangeDateInput =(val)=> {
        let obj={}
        if(isMoment(val)){
            let newDate = val
            let today = Moment();
            
            if(newDate.isBefore(today.format('YYYYMMDD'))){
                obj = {
                    cntr_pick_up_cy_date_name:today.format('YYYY-MM-DD'),
                    cntr_pick_up_cy_date:today.format('YYYYMMDD')
                }
                props.onAlert('danger','이전 날짜로 설정 하실 수 없습니다.');
            }else{
                obj = {
                    cntr_pick_up_cy_date_name:newDate.format('YYYY-MM-DD'),
                    cntr_pick_up_cy_date:newDate.format('YYYYMMDD')
                }
            }
            setCyDateValid(true);
            setContainer({...container,...obj})
            props.fncOnBlur({...container,...obj})
        }else{
            obj = {cntr_pick_up_cy_date_name:val,
                  cntr_pick_up_cy_date:val
            }
            setCyDateValid(false);
            setContainer({...container,...obj})
        }
    }
    
    return (
        <>
        {("BOOK" === openType) &&
            <Row>
                <Col xl="12" lg="12" md="12">
                    <FormGroup>
                        <Label className="mb-0">Bookmark Name</Label>
                        <InputValid 
                            type="text"
                            name="container_bookmark_name"
                            id="container_bookmark_name"
                            maxLength="50"
                            value={container.container_bookmark_name?container.container_bookmark_name:''}
                            onChange={(e)=>fncOnChange(e, 'container_bookmark_name')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="text"
                            required={'BOOK'===openType?true:false} 
                        />
                    </FormGroup>
                </Col>
            </Row>}
            <Row>
                <Col xl={("BOOK" === openType)?"12":"4"} lg={("Y" === openType)?"12":"4"} md="12" sm="12">
                    <FormGroup>
                        <Label className="mb-0">Size / Type</Label>
                        <Select
                            className="customSelect"
                            name="cntr_code"
                            value={{value:container.cntr_code?container.cntr_code:'',
                                    label:container.cntr_code&&(lineCodeCntrSztp.findIndex(x=>x.cntr_code===container.cntr_code)>=0)?
                                        lineCodeCntrSztp[lineCodeCntrSztp.findIndex(x=>x.cntr_code===container.cntr_code)].label:
                                            '선택'
                            }}
                            onChange={(e) => fncOnChangeSelect(e.value, 'cntr_code')}
                            onBlur={(e) => {fncOnBlur()}}
                            options={lineCodeCntrSztp}
                            styles={{
                                control: provided => ({...provided,border:container.cntr_code?'':(('CARD'===openType||'MAIN'===openType)?'1px solid red':'') ,maxHeight:'3px'}),
                                indicatorContainer: provided => ({...provided,color:'',height:'3px'}),
                                // menu: provided => ({...provided, zIndex:9999})
                            }}
                            // menuPortalTarget={document.body}
                            // menuPosition='fixed'
                        />
                        <InputValid
                            hidden
                            type="text"
                            name="cntr_code1"
                            id="cntr_code1"
                            maxLength="20"
                            value={container.cntr_code?container.cntr_code:''}
                            validtype="select"
                            required={container.cntr_code?'':'CARD'===openType||'MAIN'===openType?true:false} 
                            feedid="container"
                            readOnly
                        />
                    </FormGroup>
                </Col>
                <Col xl={("BOOK" === openType)?"12":"4"} lg={("Y" === openType)?"12":"4"} md="12" sm="12">
                    <FormGroup>
                        <Label className="mb-0">Qty</Label>
                        <InputValid 
                            type="text"
                            name="cntr_qty"
                            id="cntr_qty"
                            maxLength="3"
                            value={container.cntr_qty?container.cntr_qty:''}
                            onChange={(e)=>fncOnChange(e, 'cntr_qty')}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="number"
                            required={'CARD'===openType||'MAIN'===openType?true:false} 
                            feedid="container"
                        />
                    </FormGroup>
                </Col>
                <Col xl={("BOOK" === openType)?"12":"4"} lg={("Y" === openType)?"12":"4"} md="12" sm="12">
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label className="mb-0">SOC</Label>
                                <Select
                                    // isDisabled={true}
                                    className="customSelect"
                                    name="cntr_soc_yn"
                                    // value={{value:container.cntr_soc_yn?container.cntr_soc_yn:'',
                                    //         label:container.cntr_soc_yn?
                                    //             (options.ynList.findIndex(x=>x.value===container.cntr_soc_yn)>=0)?
                                    //             options.ynList[options.ynList.findIndex(x=>x.value===container.cntr_soc_yn)].label:
                                    //                 '선택':
                                    //             '선택'
                                    // }}
                                    value={container.cntr_soc_yn
                                        ?(ynList.findIndex(x=>x.value===container.cntr_soc_yn)>=0)
                                        ?ynList[ynList.findIndex(x=>x.value===container.cntr_soc_yn)]
                                        :ynList[1]:ynList[1]}
                                    onChange={(e) => fncOnChangeSelect(e.value, 'cntr_soc_yn')}
                                    options={ynList}
                                />
                            </FormGroup>
                        </Col>
                        {("BOOK" === openType)?<></>:<>
                        <Col sm="3" style= {{textAlign : 'center',padding:'0'}}>
                            <Label className="mb-0" >EMPTY 선적</Label>
                            <FormControlLabel 
                                        value={checkEmptyYn}
                                        control = {<Checkbox  checked={checkEmptyYn} disabled={container.cntr_soc_yn!=='Y'} color='primary'/>}
                                        // label='EMPTY 선적'
                                        labelPlacement='top'
                                        onChange={()=>changeEmptyYn(!checkEmptyYn)}
                                    />
                            {/* <Label className="mb-0" >EMPTY 선적</Label>
                                <FormGroup check disabled={container.cntr_soc_yn!=='Y'}>
                                    <Label check style={{marginBottom: '7px',marginLeft: '20px'}}>
                                    <Input type="checkbox"  disabled={container.cntr_soc_yn!=='Y'}  checked={checkEmptyYn}
                                     onChange={()=>changeEmptyYn(!checkEmptyYn)}/>
                                    <span className="form-check-sign" >
                                        <span className = 'check' ></span>
                                    </span>
                                   
                                    </Label>
                                </FormGroup> */}
                        </Col>
                        <Col sm="3" style= {{textAlign : 'center',padding:'0'}}>
                            <Label className="mb-0" >NOR</Label>
                            <FormControlLabel 
                                value={checkNorYn}
                                control = {<Checkbox checked={checkNorYn} color='primary'/>}
                                // label='NOR'
                                labelPlacement='top'
                                onChange={()=>changeNorYn(!checkNorYn)}
                            />
                        </Col>
                        </>}
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xl="6" lg="6" md="12">
                    <FormGroup>
                        {("BOOK" === openType)?<></>:
                        <>
                            <Label className="mb-0">Pick Up CY</Label>
                            <Select
                                // isDisabled={container.cntr_soc_yn ==='Y'?true:false}
                                isDisabled={cyDateDisabled}
                                className="customSelect"
                                name="cntr_pick_up_cy_code"
                                value={{value:container.cntr_pick_up_cy_code?container.cntr_pick_up_cy_code:'',
                                        label:container.cntr_pick_up_cy_code?
                                            (lineCodeVesselPickup.findIndex(x=>x.value===container.cntr_pick_up_cy_code)>=0)?
                                            lineCodeVesselPickup[lineCodeVesselPickup.findIndex(x=>x.value===container.cntr_pick_up_cy_code)].label:
                                                '선택':
                                            '선택'
                                }}
                                onChange={(e) => fncOnChangeSelect(e.value, 'cntr_pick_up_cy_code')}
                                options={lineCodeVesselPickup}
                                styles={{
                                    control: provided => ({...provided,border:
                                        !cyDateDisabled&&!container.cntr_pick_up_cy_code
                                        && ('CARD'===openType||'MAIN'===openType)?'1px solid red':''
                                    }),
                                    indicatorContainer: provided => ({...provided,color:''}),
                                }}
                            />
                            <InputValid
                                hidden
                                type="text"
                                name="cntr_pick_up_cy_code1"
                                id="cntr_pick_up_cy_code1"
                                maxLength="20"
                                value={container.cntr_pick_up_cy_code?container.cntr_pick_up_cy_code:''}
                                validtype="select"
                                required={!cyDateDisabled&&!container.cntr_pick_up_cy_code
                                    && ('CARD'===openType||'MAIN'===openType)?true:false} 
                                // required={container.cntr_soc_yn ==='Y'?false:container.cntr_pick_up_cy_code?'':'CARD'===openType||'MAIN'===openType?true:false} 
                                feedid="container"
                                readOnly
                            />
                        </>}
                    </FormGroup>
                </Col>
                {("BOOK" === openType)?<></>:
                <Col xl="6" lg="6" md="9">
                    <FormGroup>
                        <Label className="mb-0">Pick Up Date</Label>
                        {/* <InputValid 
                            disable={container.cntr_soc_yn ==='Y'?1:0}
                            type="text"
                            name="date_test"
                            id="date_test"
                            placeholder="날짜을(를) 입력하세요. (예: 20210101)"
                            maxLength="10"
                            value={container.cntr_pick_up_cy_date_name?container.cntr_pick_up_cy_date_name:''}
                            onChange={(e)=>fncOnChangeDateInput(e.target.value, 'cntr_pick_up_cy_date_name')}
                            onKeyDown={(e)=>fncOnPressDateInput(e)}
                            onBlur={(e) => {fncOnBlur(e)}}
                            validtype="yyyymmdd"
                            required={container.cntr_soc_yn ==='Y'?false:(container.cntr_pick_up_cy_date_name?'':'CARD'===openType||'MAIN'===openType)?true:false} 
                            feedid="container"
                            autoComplete="off"
                        /> */} 
                        <CustomDatePicker
                                        id="pickUpCyDate"
                                        name="date_test"
                                        dateFormat="YYYYMMDD"
                                        timeFormat={false}
                                        value={container.cntr_pick_up_cy_date?container.cntr_pick_up_cy_date:""}
                                        //value={container.cntr_pick_up_cy_date_name?container.cntr_pick_up_cy_date_name:""}
                                        onChange={(date)=>fncOnChangeDateInput(date)}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        // inputProps={cyDateStyleState}
                                        inputProps={{style:{
                                            border: !cyDateDisabled&&!cyDateValid?'1px solid #dc3545':''}
                                            ,disabled:cyDateDisabled}}
                                        {...props}
                                        />
                        <InputValid 
										hidden
										name="date_test"
										id="date_test"
										maxLength="10"
                                        value={container?.cntr_pick_up_cy_date}
										validtype="yyyymmdd2"
                                        required={!cyDateDisabled&&('CARD'===openType||'MAIN'===openType)?true:false} 
                                        //     container.cntr_soc_yn ==='Y'?false:
                                        // (container.cntr_pick_up_cy_date?''
                                        // :'CARD'===openType||'MAIN'===openType)?true:false} 
										feedid="container"
                                        autoComplete="off"
                                        readOnly
									/> 
                    </FormGroup>
                </Col>}
            </Row>
            {("BOOK" === openType)?<></>:
            <Row>
                <Col xl="12" lg="12" md="12">
                    <Collapse isOpen={oogType}>
                        <Row>
                            <Col>
                                <Label className="mt-3 mb-0" style={{fontWeight:'bold',fontSize:'15px',color:'#696969'}}> CONTAINER INFO</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="3">
                                <FormGroup >
                                    <Label className="mb-0">구분</Label>
                                    <Select
                                        className="customSelect"
                                        name="cntr_special_type"
                                        value={{value:container.cntr_special_type?container.cntr_special_type:'',
                                                label:container.cntr_special_type?
                                                    (options.specialType.findIndex(x=>x.value===container.cntr_special_type)>=0)?
                                                    options.specialType[options.specialType.findIndex(x=>x.value===container.cntr_special_type)].label:
                                                    '선택':
                                                    '선택'
                                        }}
                                        onChange={(e) => fncOnChangeSelect(e.value, 'cntr_special_type')}
                                        options={options.specialType}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label className="mb-0">Container Length</Label>
                                    <InputValid 
                                        type="text"
                                        name="cntr_length"
                                        id="cntr_length"
                                        maxLength="10"
                                        value={container.cntr_length?container.cntr_length:''}
                                        onChange={(e)=>fncOnChange(e, 'cntr_length')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="number"
                                        required={("BOOK" !== openType)&&oogType} 
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={("BOOK" === openType)?"12":"4"} lg={("BOOK" === openType)?"12":"4"} md="12">
                                <FormGroup>
                                    <Label className="mb-0">Container Width</Label>
                                    <InputValid 
                                        type="text"
                                        name="cntr_width"
                                        id="cntr_width"
                                        maxLength="15"
                                        value={container.cntr_width?container.cntr_width:''}
                                        onChange={(e)=>fncOnChange(e, 'cntr_width')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="number"
                                        required={("BOOK" !== openType)&&oogType} 
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={("BOOK" === openType)?"12":"4"} lg={("BOOK" === openType)?"12":"4"} md="12">
                                <FormGroup>
                                    <Label className="mb-0">Container height</Label>
                                    <InputValid 
                                        type="text"
                                        name="cntr_height"
                                        id="cntr_height"
                                        maxLength="10"
                                        value={container.cntr_height?container.cntr_height:''}
                                        onChange={(e)=>fncOnChange(e, 'cntr_height')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype={"number"}
                                        required={("BOOK" !== openType)&&oogType} 
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="2">
                                <FormGroup>
                                    <Label className="mb-0">Item Qty</Label>
                                    <InputValid 
                                        type="text"
                                        name="cntr_item_qty"
                                        id="cntr_item_qty"
                                        maxLength="3"
                                        value={container.cntr_item_qty?container.cntr_item_qty:''}
                                        onChange={(e)=>fncOnChange(e, 'cntr_item_qty')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="number"
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Label className="mb-0">Stowage Request</Label>
                                    <Select
                                        className="customSelect"
                                        name="cntr_stowage_cell"
                                        value={{value:container.cntr_stowage_cell?container.cntr_stowage_cell:'',
                                                label:container.cntr_stowage_cell?
                                                    (options.stowageCell.findIndex(x=>x.value===container.cntr_stowage_cell)>=0)?
                                                    options.stowageCell[options.stowageCell.findIndex(x=>x.value===container.cntr_stowage_cell)].label:
                                                        '선택':
                                                    '선택'
                                        }}
                                        onChange={(e) => fncOnChangeSelect(e.value, 'cntr_stowage_cell')}
                                        options={options.stowageCell}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="2">
                                <FormGroup >
                                    <Label className="mb-0">Break Bulk</Label>
                                    <Select
                                        className="customSelect"
                                        name="cntr_break_bulk_yn"
                                        value={{value:container.cntr_break_bulk_yn?container.cntr_break_bulk_yn:'',
                                                label:container.cntr_break_bulk_yn?
                                                    (options.ynList.findIndex(x=>x.value===container.cntr_break_bulk_yn)>=0)?
                                                    options.ynList[options.ynList.findIndex(x=>x.value===container.cntr_break_bulk_yn)].label:
                                                        '선택':
                                                    '선택'
                                        }}
                                        onChange={(e) => fncOnChangeSelect(e.value, 'cntr_break_bulk_yn')}
                                        options={options.ynList}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup >
                                    <Label className="mb-0">Commodity</Label>
                                    <Select
                                        className="customSelect"
                                        name="cntr_commodity"
                                        value={{value:container.cntr_commodity?container.cntr_commodity:'',
                                                label:container.cntr_commodity?
                                                    (options.CommodityItem.findIndex(x=>x.value===container.cntr_commodity)>=0)?
                                                    options.CommodityItem[options.CommodityItem.findIndex(x=>x.value===container.cntr_commodity)].label:
                                                        '선택':
                                                    '선택'
                                        }}
                                        onChange={(e) => fncOnChangeSelect(e.value, 'cntr_commodity')}
                                        options={options.CommodityItem}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col  md="12">
                                <FormGroup>
                                    <Label className="mb-0">담당자 비고</Label>
                                    <InputValid 
                                        type="text"
                                        name="cntr_mng_remark"
                                        id="cntr_mng_remark"
                                        maxLength="200"
                                        value={container.cntr_mng_remark?container.cntr_mng_remark:''}
                                        onChange={(e)=>fncOnChange(e, 'cntr_mng_remark')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype="text"
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
            </Row>}
            {("BOOK" === openType)?<></>:
            <Row>
                <Col xl="12" lg="12" md="12">
                    <Collapse isOpen={cntrTypeReefer}>
                        <Row>
                            <Col xl={("BOOK" === openType)?"12":"4"} lg={("BOOK" === openType)?"12":"4"} md="12">
                                <FormGroup>
                                    <Label className="mb-0">Frozen Tmp</Label>
                                    <InputValidCustom
                                        type="text"
                                        name="cntr_frozen_tmp"
                                        id="cntr_frozen_tmp"
                                        maxLength="15"
                                        value={container.cntr_frozen_tmp?container.cntr_frozen_tmp:''}
                                        onChange={(e)=>fncOnChange(e, 'cntr_frozen_tmp')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        // validtype="number"
                                        required={false} 
                                        disabled={checkNorYn||false}
                                        feedid="container"
                                        inputgrouptext="&#8451;"
                                        fncvalid={(e)=>{if("BOOK" !== openType){ return e&& Number.isInteger(Number(e))}}}
                                        // fncvalid={(e)=>{if("BOOK" !== openType){ return e&&new RegExp(/^[0-9\-]*$/).test(e)}}}
                                        validmsg={'입력 가능 형식이 아닙니다.'}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={("BOOK" === openType)?"12":"4"} lg={("BOOK" === openType)?"12":"4"} md="12">
                                <FormGroup>
                                    <Label className="mb-0">Humidity</Label>
                                    <InputValid 
                                        type="text"
                                        name="cntr_humidity"
                                        id="cntr_humidity"
                                        maxLength="3"
                                        value={container.cntr_humidity?container.cntr_humidity:''}
                                        onChange={(e)=>fncOnChange(e, 'cntr_humidity')}
                                        onBlur={(e) => {fncOnBlur(e)}}
                                        validtype={("BOOK" !== openType)?"number":'false'}
                                        required={false} 
                                        disabled={checkNorYn||false}
                                        feedid="container"
                                    />
                                </FormGroup>
                            </Col>
                            <Col xl={("BOOK" === openType)?"12":"4"} lg={("BOOK" === openType)?"12":"4"} md="12">
                                <FormGroup>
                                    <Label className="mb-0">환풍기 개방율</Label>
                                    <Select
                                        className="customSelect"
                                        name="cntr_vent_open"
                                        value={{value:container.cntr_vent_open?container.cntr_vent_open:'',
                                                label:container.cntr_vent_open?
                                                    (options.ventList.findIndex(x=>x.value===container.cntr_vent_open)>=0)?
                                                    options.ventList[options.ventList.findIndex(x=>x.value===container.cntr_vent_open)].label:
                                                        '선택':
                                                    '선택'
                                        }}
                                        onChange={(e) => fncOnChangeSelect(e.value, 'cntr_vent_open')}
                                        options={options.ventList}
                                        isDisabled={checkNorYn}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Collapse>
                </Col>
            </Row>}
        </>
    );
}

export {Container, ContainerForm};