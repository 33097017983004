import React,{ useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import CardBody from "muiComponents/Card/CardBody.js";
import {
	Collapse,
	Card,
	CardContent,
	TextField,
	TableFooter,
	MenuItem,
	FormControl,
	InputLabel,
	Select,
	// Grid,
	// Divider,
	// Container,
	// Avatar,
	// Box,
	Table,
	TableBody,
	TableCell,
	// TableContainer,
	TableHead,
	TableRow,
	// Typography,
	// Paper,
	// Input
} from "@material-ui/core";

import Button from "muiComponents/CustomButtons/Button.js";

import {
	Chat as Chat,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	Search as SearchIcon
}from "@material-ui/icons";
import axios from 'axios';
// import Filesaver from 'file-saver';
import {yellow, red} from '@material-ui/core/colors';
import { useHistory } from "react-router-dom";
// import testimonialsSection from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle";
// import AlertMessage from 'muiComponents/Alert/AlertMessage.js';
import Pagination from '@material-ui/lab/Pagination';

const ico_qna_reply = require("assets/img/ico_qna_reply.gif");
const lock_icon = require("assets/img/lock-icon.png");

const useRowStyles = makeStyles((theme)=> ({
	root: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	small: {
		width: theme.spacing(2),
		height: theme.spacing(2),
		backgroundColor: red['700']
	}
}));

function Row(props) {
	const history = useHistory();
	const classes = useRowStyles();
	const [row,setRow] = useState(props.row);
	const [open, setOpen] = useState(false);
	const [pwOpen, setPwOpen] = useState(false);
	// const [password,setPassword]= useState('');
	const [lock, setLock] = useState(row.QNA_PASSWORD);
	const { userData} = props;
	useEffect(()=>{
		setRow(props.row);
		//console.log(props.row);
	},[props.row])
	useEffect(() => {
        if(row.NTT_ID===props.num) {
            setOpen(true)
        }else {
            setOpen(false);
        }
	},[props.num]);
	useEffect(() => {
        if(row.NTT_ID===props.num2) {
            setPwOpen(true)
        }else {
            setPwOpen(false);
        }
	},[props.num2]);

	const onButtonClick = () => {
		if(props.userData){
			if(lock&&lock==='Y'){
				if(row.NTT_ID===props.num2){
					props.openPWInput("")
				}else{
					props.openPWInput(row.NTT_ID)
				}
				return false
			}
	
			history.push({
				pathname:"/svc/FAQDetail",
				...props.row,
				login_user_no:props.userData.user_no,
				login_user_role:props.userData.role,
				login_user_name:props.userData.name,
			})
		}else{
			props.onAlert("error","로그인 이후 이용해주세요." );
		}
	}
	
	const atchFileId_onclick = function() {
		let fileName = row.ATCH_FILE_ID;
		const localCheck =window.location.href.split("/")[2];
		if(localCheck.includes('localhost')||localCheck.includes('dev')){
			let fileUrl = "https://testeservice.logisview.com/file_download/";
			let url = fileUrl + fileName;
			window.open(url);
		}else{
			let fileUrl = "https://eservice.logisview.com/file_download/";
			let url = fileUrl + fileName;
			window.open(url);
		}
	};
	

	return (

	  	<React.Fragment>
			<TableRow>
				<TableCell onClick={(e) => onButtonClick(e)} component="th" scope="row" style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.NTT_FAQ_TP}</TableCell>
				<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.NTT_SJ} {row.REPLY_AT &&row.REPLY_AT==='Y'&& <img src={ico_qna_reply}/>} {row.QNA_PASSWORD &&row.QNA_PASSWORD==='Y'&& <img src={lock_icon}/> }</TableCell>
				<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.FRST_REGISTER_PNTTM}</TableCell>
				<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.FRST_REGISTER_NM}</TableCell>
				<TableCell onClick={(e) => onButtonClick(e)} style={{paddingTop:'10px',paddingBottom:'10px'}}>{row.INQIRE_CO}</TableCell>
				<TableCell style={{paddingTop:'10px',paddingBottom:'10px'}}>
					{row.ATCH_FILE_ID&&userData?
						<Button className="m-0 pt-1 pb-1" style={{backgroundColor:'#00acc1',fontSize:'10px'}} onClick={(e) => atchFileId_onclick()}>
							DOWN
						</Button>
						:<></>
					}
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
  }

export default function PlismBoard(props) {

    const classes = useRowStyles();
    const [boardData,setBoardData] = useState([]);
	const [selectValue,setSelectValue] = useState("0");
	// const [CopySelectValue,setCopySelectValue] = useState("");
	const [openPage, setOpenPage] = useState(0);
	// const [Num,setNum] = useState(1);
	const [subject, setSubject] = useState("");
	const [copySubject, setCopySubject] = useState("");
	const [openPW, setOpenPW] = useState("");
	// const [openAlert, setOpenAlert] = useState(false);
	// const [message, setMessage] = useState('');
	// const [status, setStatus] = useState('');
	const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
	const [totalFAQCount,setTotalFAQCount]= useState(0);
	const [boardIDList,setBoardIDList] =useState([]);
	const [selectedBoardID,setSelectedBoardID] =useState('BBSMSTR_100000000002');
	const pageCount = 10;
	const history = useHistory();
	const { userData} = props;

	useEffect(() => {
		onSubmit();
    }, [page]);

	useEffect(() => {
        if(props.location.state) {
            setOpenPage(props.location.state.param);
        }
    },[props.location]);
	
	const onSubmit = () => {
		axios.post("/api/selectFAQList",{firstIndex:((page-1)*pageCount)+1, recordCountPerPage:((page-1)*pageCount)+pageCount, searchCnd:selectValue,searchWrd:subject?subject:null,sKlnetId:userData?userData.klnet_id:''})
		.then(res => {
			setBoardData(res.data);
			//console.log(res.data);
			if(res.data.length>0){
				fncListCount();
			}
		})
	
	  }

	const fncListCount = () => {
		axios.post("/api/selectFAQListCount",{firstIndex:((page-1)*pageCount)+1, recordCountPerPage:((page-1)*pageCount)+pageCount, searchCnd:selectValue,searchWrd:subject?subject:null,sKlnetId:userData?userData.klnet_id:''})
		.then(res => {
			if(res.data.length>0){
				//총페이지 수
				setTotalCount(Math.ceil(res.data[0].CNT/10))
				setTotalFAQCount(res.data[0].CNT);
			}
		})
	}
	
	const handleChange = (event, value) => {
		setPage(Number(value));
	  }

    return(
		<React.Fragment>
			<Card className="m-4">
				<CardContent>
					<h4 style={{textAlignLast:'left',color:'black'}}><Chat style={{color:'#00acc1'}}></Chat> F A Q </h4>
					<GridContainer className="mt-4">
						{/* <GridItem xs={2} sm={2} md={2} >
							<FormControl variant="outlined" >
								<InputLabel>CATEGORY</InputLabel>
								<Select
									label="option"
									value={selectedBoardID}
									onChange={(event)=> setSelectedBoardID(event.target.value)}>
									{boardIDList.map((element, index) => (
												<MenuItem key={index} value={element.BBS_ID}>
													{element.BBS_NM}
												</MenuItem>
											))}
								</Select>
							</FormControl>
						</GridItem> */}
						<GridItem xs={6} sm={4} md={4} >
							<TextField id="Subject" variant="outlined"  onChange={(event)=>setSubject(event.target.value)} fullWidth />
						</GridItem>
						<GridItem xs={6} sm={2} md={2} >
							<FormControl variant="outlined" >
								<InputLabel>OPTION</InputLabel>
								<Select
									label="option"
									value={selectValue}
									onChange={(event)=> setSelectValue(event.target.value)}>
									<MenuItem value="0">제목</MenuItem>
									<MenuItem value="1">내용</MenuItem>
									<MenuItem value="2">작성자</MenuItem>
								</Select>
							</FormControl>
						</GridItem>
						<GridItem xs={12} sm={6} md={6} >
							<div style={{textAlign:'-webkit-right'}}>
								<Button color="info" endIcon={<SearchIcon/>}  onClick={()=> onSubmit()}>Search</Button>
							</div>
						</GridItem>
					</GridContainer>
				</CardContent>
				<CardBody>
					<div className="mb-1">
						Total:{totalFAQCount}
					</div>
					<Table>
						<TableHead style={{backgroundColor:'#f2fefd'}} className={classes["info" + "TableHeader"]}>
							<TableRow style={{borderBottomStyle:'solid',borderBottomColor:'#00bcd4'}}>
								<TableCell style={{width:'15%'}}>구분</TableCell>
								<TableCell style={{width:'40%'}}>제목</TableCell>
								<TableCell style={{width:'10%'}}>작성일자</TableCell>
								<TableCell style={{width:'10%'}}>작성자</TableCell>
								<TableCell style={{width:'10%'}}>조회수</TableCell>
								<TableCell style={{width:'10%'}}>첨부파일</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{boardData.map((prop, key) => {
								return (
									<Row key={key} openNum={(e) => setOpenPage(e)}  openPWInput={(e) => setOpenPW(e)} userData={props.userData} row={prop} num={openPage} num2={openPW} onAlert={props.onAlert}/>
								);
							})}
						</TableBody>
						{(boardData.length > 0 ?
						<TableFooter>
							<TableRow>
								<TableCell colSpan={6} style={{paddingLeft:'40%'}}>
									<Pagination
										count={totalCount}
										page={page}
										onChange={handleChange}
										defaultPage={1}
										color="primary"
										size="small"
										showFirstButton
										showLastButton
										>
									</Pagination> 
								</TableCell> 
							</TableRow>
							<TableRow >
							</TableRow>
						</TableFooter>: null )}
					</Table>
				</CardBody>
			</Card>
			{/* <AlertMessage
			message={message}
			isOpen={openAlert}
			isClose={() => setOpenAlert(!openAlert)}
			// fontColor={font}   //선택사항
			alertColor={status === 'success' ? status : 'danger'} //선택사항  //primary, secondary, success, danger, warning, info, light, dark
			// timeOut={2000} //선택사항
			></AlertMessage> */}
		</React.Fragment>
    );
}