import React, { useState, useEffect } from 'react';
import {Row, Col, FormGroup,Label,Input, Card, CardTitle, CardSubtitle, CardBody, CardFooter, Button, UncontrolledTooltip,Modal} from "reactstrap";
import Select from 'react-select'
import Moment from 'moment';
import axios from "axios";
import {CustomDatePickerInput} from 'components/CustomInput/reactstrap/CustomInput';
import {Tooltip, Popover} from "@material-ui/core";
import * as validation from 'muiComponents/common/validation.js';
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'
import ScheduleCalendarModal from "muiComponents/Modals/ScheduleCalendarModal.js";
import ShipMap from "muiComponents/Map/ShipMap.js";

import {useHistory} from 'react-router-dom';

export default function SrList (props) {
    let history = useHistory();
    const [toDate,setToDate] = useState(Moment(new Date()).subtract(7,'days'));
    const [endDate,setEndDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [num, setNum] = useState(1);
    const [srNo, setSrNo] = useState("");
    const [totCnt, setTotCnt] = useState(0);
    const [moreTodate, setMoreTodate] = useState(Moment(new Date()).subtract(7,'days'));
    const [moreEndDate,setMoreEndDate] = useState(new Date());
    const [moreSrNo, setMoreSrNo] = useState("");
    const [modalMsg, setModalMsg] = useState(null);
    const [sch_vessel_name, setSchVesselName] = useState("");
    const [lineVesselList ,setLineVesselList] = useState([]);
    const [carrierList, setCarrierList] = useState([]);
    const [lineCode, setLineCode] = useState('');
    const [scheduleCalendarOpen, setScheduleCalendarOpen] = useState(false);
    const [popupMap,setPopupMap] = React.useState(null);
    const {userData}=props;
    
    useEffect(()=> {
        if(userData){
          axios.post("/loc/getCustomLineCode",{}).then(res => setCarrierList(res.data));
        }else {
          props.openLogin();
        }
        if(props.location.state){
            const state = props.location.state;
            state.start_date&& setToDate(state.start_date); 
            state.end_date&& setEndDate(state.end_date);
        }
    },[]);

    useEffect(() => {
        onSubmit();
        // let mounted = true;
        // if(lineCode) {
            // if(mounted)onSubmit();
            // selectLineCodeVesselName();
        // }else {
        //     props.onAlert('danger','사이트 정보가 없습니다.');
        // }
        // return ()=>mounted=false;
    },[userData])

    
  
    const onSubmit = () => {
        if(userData){
            setNum(1);
            setMoreTodate(toDate);
            setMoreEndDate(endDate);
            setMoreSrNo(srNo);

            if(toDate > endDate){
                props.onAlert("danger",validation.DATE_MSG);
                return false;
            }

            axios.post("/shipper/selectSrList",{
                userNo:userData?userData:''
                , sr_no:srNo
                , toDate:Moment(toDate).format('YYYYMMDD')
                , endDate:Moment(endDate).format('YYYYMMDD')
                , num:1
                , lineCode: lineCode
                , vsl:sch_vessel_name
                , key:'LOGISVIEW'
            }).then(res => {
                if(res.statusText==="OK") {
                    if(res.data.length > 0) {
                        setTotCnt(res.data[0].tot_cnt);
                        setData(res.data);
                        props.onAlert("success",validation.OK_CHECK_MSG);
                    }else {
                        props.onAlert("danger",validation.FAIL_CHECK_MSG);
                        setData([]);
                        setTotCnt(0);
                    }
                }
            })
        }else{
            props.onAlert("danger",validation.NO_USER_MSG);
        }
    }
  
    const onMore = (param) => {
        if(userData){
            if((param-1) !== Number(data[0].tot_page)) {
                setNum(param);
                axios.post("/shipper/selectSrList",{
                    userNo:userData?userData:''
                    , bkg_no:moreSrNo
                    , toDate:Moment(moreTodate).format('YYYYMMDD')
                    , endDate:Moment(moreEndDate).format('YYYYMMDD')
                    , num:param
                    , lineCode: lineCode
                    , key:'LOGISVIEW'}).then(res => {
                    setData([...data,...res.data])
                });
            }else {
                props.onAlert("warning",validation.LAST_PAGE_MSG);
            }
        }
    }
    // const selectLineCodeVesselName = () => {
    //     axios.post("/shipper/selectLineCodeVesselName",{params:{line_code:lineCode}}).then(res=>{
    //         setLineVesselList(res.data);
    //     });
    // }
    const onDeleteRows = (seq,vVal) => {
        if(vVal && (vVal.status_cus === "NO" ||  vVal.status_cus === "S0") ) {
            setModalMsg(
                <Modal
                    isOpen={true}
                >
                    <div className="modal-header no-border-header">
                        <button
                            className="close"
                            type="button"
                            onClick={() => setModalMsg(null)}>×
                        </button>
                    </div>
                    <div className="modal-body text-center pl-0 pr-0">
                        <h5>선택한 Request 문서 [{vVal.sr_no}]를 삭제 하시겠습니까?</h5>
                    </div>
                    <div className="modal-footer">
                        <div className="left-side">
                            <Button className="btn-link" color="danger" type="button" onClick={()=>fncDeleteRow(seq,vVal)}>Yes</Button>
                        </div>
                        <div className="divider" />
                        <div className="right-side">
                            <Button className="btn-link" color="default" type="button" onClick={() => setModalMsg(null)}>No</Button>
                        </div>
                    </div>
                </Modal>
            );
        } else {
            props.onAlert("error","전송 된  SR 문서는 삭제 할수 없습니다.");
            return false;
        }
    }
    const fncDeleteRow=(seq,vVal)=>{
        axios.post("/shipper/deleteSrList",{user_no:userData?userData.user_no:'', data:vVal, lineCode:lineCode}).then(res => {
            data.splice(seq,1);
            setTotCnt(totCnt-1);
            setModalMsg(null);
            props.onAlert("success",vVal.sr_no+"이 정상 삭제 되었습니다.");
        }).catch(err => {
            props.onNotiAlert("danger","삭제에 실패했습니다. 관리자에 문의바랍니다.");
        });
    }

    const openPopup = async(data)=>{
        if(!userData){
            props.onAler('error',validation.NOTLOGIN_MSG);
            return false
        }
        
        await axios.post('/api/oraMenuAccessLog', 
        {modalName : '/bkglist/shipmap',
            user: userData ? userData.user_no : 'GUEST',});//pathName

        setPopupMap(								
            <Popover
                id="popover_map"
                open={true}
                onClose={()=>setPopupMap(null)}
                anchorReference="anchorPosition"
                anchorPosition={{top:80,left:550}}
                anchorOrigin={{vertical:'bottom',horizontal:'center',}}
                transformOrigin={{vertical:'top',horizontal:'center',}}>
                <ShipMap parameter={data} onClose={()=>setPopupMap(null)} {...props}></ShipMap>
            </Popover>
            );	    
    }
    
    function TableList (props) {
        
        const {propsData,indexs} = props;
        return (
            <Row className="table_tb table_tb--hover border-bottom pb-3 table_th--rows">
                <div className="table_tb--no text-center pt-3 pl-1 pr-1">
                    <Button
                        className="btn-link"
                        color="danger"
                        data-toggle="tooltip"
                        id={"remove_"+indexs}
                        size="sm"
                        type="button"
                        style={{marginBottom:'0'}}
                        onClick={()=>{
                            if( 'W' === propsData.bkg_type &&propsData.user_no !== userData.user_no){
                                props.onAlert("error", '위동 선사는 작성 유저만 삭제 가능합니다.');
                                return false;
                            }else{
                                props.deleteRows(indexs,propsData)
                            }
                        }}>
                        <i className="fa fa-times" />
                    </Button>
                    <UncontrolledTooltip
                        delay={0}
                        placement="top"
                        target={"remove_"+indexs}>
                        Remove
                    </UncontrolledTooltip>
                </div>
              <Col>
                <Row >
                  <Col xl="3" md="6" xs="12">
                    <Row className='table_tb--rows'>
                        <Col className="text-center pt-3" xs="3">
                             {/* {propsData.carrier}  */}
                            {propsData.logo_img?
                            <LoadCarrierImage line_code={propsData.carrier} logo={propsData.logo_img} classStyle={"img-rounded img-responsive"} 
                            width={42} height={40} kr_name={propsData.line_kr_name}
                            />
                            :propsData.line_kr_name||propsData.line_code}
                        </Col>
                        <Col className=" text-center pt-3" xs="5">
                            {/* <Link
                                to={{
                                    pathname: propsData.bkg_type && 
                                    'W' === propsData.bkg_type
                                    ?`/svc/srWDFC`
                                    :'E'=== propsData.bkg_type
                                        ?'/svc/srPlism':`/svc/sr`,
                                    state:{
                                        user_no:propsData.user_no, 
                                        sr_no:propsData.sr_no, 
                                        sr_date:propsData.sr_date, 
                                        line_code:propsData.carrier
                                        ,doc_new: "Y"
                                    }
                                }}> */}
                                <Button 
                                    className="btn-link"
                                    color="primary"
                                    type="button"
                                    style={{width:'100%',padding:'0'}}
                                    onClick={()=>{
                                        if( 'W' === propsData.bkg_type &&propsData.user_no !== userData.user_no){
                                            props.onAlert("error", '위동 선사는 작성 유저만 조회 가능합니다.');
                                            return false;
                                        }
                                        history.push({
                                            pathname: propsData.bkg_type && 
                                            'W' === propsData.bkg_type
                                            ?`/svc/srWDFC`
                                            :'E'=== propsData.bkg_type
                                                ?'/svc/srPlism':`/svc/sr`,
                                            state:{
                                                user_no:propsData.user_no, 
                                                sr_no:propsData.sr_no, 
                                                sr_date:propsData.sr_date, 
                                                line_code:propsData.carrier
                                                ,doc_new: "Y"
                                            }
                                        });
                                    }}
                                    // disabled={ 'W' === propsData.bkg_type &&propsData.user_no !== userData.user_no?true:false}
                                    >
                                    {propsData.sr_no}
                                </Button>
                            {/* </Link> */}
                        </Col>
                        <Col className=" text-center pt-3" xs='4'>{propsData.sr_date_format}</Col>
                      </Row>
                    </Col>
                    <Col xl="3" md="6" xs="12">
                      <Row className='table_tb--rows'>
                        <Col className=" text-center pt-3" xs='3' >{propsData.status_name}{propsData.sending_count?"(".concat(propsData.sending_count).concat(")"):""}</Col>
                        <Col className=" text-center pt-3" xs='4' >{propsData.send_date_format}</Col>
                        <Col className=" text-center pt-3" xs='5'>
                          {propsData.res_mbl_no?
                            // <Link 
                            //     to={{
                            //       pathname: propsData.carrier && 
                            //         'WDFC' === propsData.carrier
                            //         ?`/svc/blWDFC`
                            //         :'JCS'=== propsData.carrier||'YTF'=== propsData.carrier
                            //             ?'/svc/blPlism':`/svc/bl`,
                            //       state:{ 
                            //             user_no:propsData.user_no?propsData.user_no:null,
                            //             mbl_no: propsData.res_mbl_no?propsData.res_mbl_no:null,
                            //             issue_date: propsData.res_issue_date?propsData.res_issue_date:null
                            //             ,line_code: propsData.carrier||null
                            //     }
                            //     }}>
                                <Button 
                                    className="btn-link"
                                    color="primary"
                                    type="button"
                                    style={{width:'100%',padding:'0'}}
                                    onClick={()=>{
                                        if( 'W' === propsData.bkg_type &&propsData.user_no !== userData.user_no){
                                            props.onAlert("error", '위동 선사는 작성 유저만 조회 가능합니다.');
                                            return false;
                                        }
                                        history.push({
                                            pathname: propsData.carrier && 
                                                'WDFC' === propsData.carrier
                                                ?`/svc/blWDFC`
                                                :'JCS'=== propsData.carrier||'YTF'=== propsData.carrier
                                                    ?'/svc/blPlism':`/svc/bl`,
                                            state:{ 
                                                user_no:propsData.user_no?propsData.user_no:null,
                                                mbl_no: propsData.res_mbl_no?propsData.res_mbl_no:null,
                                                issue_date: propsData.res_issue_date?propsData.res_issue_date:null
                                                ,line_code: propsData.carrier||null
                                            }
                                        })
                                    }}
                                    >     
                                    {propsData.res_mbl_no}
                                </Button>
                            // {/* </Link> */}
                            :<></>
                            }
                        </Col>
                      </Row>
                    </Col>
                    <Col xl="3" md="6" xs="12">
                      <Row className='table_tb--rows'>
                        <Col className=" text-center pt-3" xs='5' >{propsData.res_issue_date_format}</Col>
                        <Col className=" text-center pt-3" xs='7' >
                        {propsData.sch_vessel_name&& <Tooltip title="현재 선박위치 조회">
                            <div onClick={e =>openPopup(propsData)} style ={{textDecoration: 'underline', cursor:'pointer'}}>
                                {propsData.sch_vessel_name}<br/>
                                {propsData.sch_vessel_voyage?'('+propsData.sch_vessel_voyage+')':''}
                            </div>
                        </Tooltip>}
                        </Col>
                      </Row>
                    </Col>
                    <Col xl="3" md="6" xs="12">
                      <Row className='table_tb--rows'>
                        <Col className=" text-center pt-3" xs='4' ><span data-html="true" data-toggle="tooltip" title={propsData.sch_pol_name} >{propsData.sch_pol}<br/>{propsData.sch_srd_format?"("+propsData.sch_srd_format+")":''}</span></Col>
                        <Col className=" text-center pt-3" xs='4' ><span data-html="true" data-toggle="tooltip" title={propsData.sch_pod_name} >{propsData.sch_pod}<br/>{propsData.sch_eta_format?"("+propsData.sch_eta_format+")":''}</span></Col>
                        <Col className=" text-center pt-3" xs='2' >{propsData.cargo_pack_qty}</Col>
                        <Col className=" text-center pt-3" xs='2' >{propsData.cntr_count==="0"?"":propsData.cntr_count}</Col>
                    </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )
    }
  
  
    return (
        <>
        {modalMsg}
            {/* var colWidths = ['xs', 'sm', 'md', 'lg', 'xl']; */}
            {/* <Col className="ml-auto mr-auto mt-4" sm="11">                */}
                <Card className="card-raised card-form-horizontal no-transition mb-4" id="card">             
                    <CardTitle>
                        <div className='search_option'>
                          <Col xl='4' className='search_option--number'>
                              <FormGroup row className='search_option--number--formGroup' >
                                <Col className='search_option--number--text col-4'>
                                    CARRIER
                                </Col>
                                <Col className='search_option--number--input col-8'>
                                    <Select
                                    className="customSelect "
                                    name="lineCode"
                                    value={{
                                        value:lineCode||'',
                                        label:lineCode?
                                        (carrierList.findIndex(x=>x.value===lineCode)>=0)?
                                        carrierList[carrierList.findIndex(x=>x.value===lineCode)].label:
                                          '선택':'선택'
                                    }}
                                    onChange={(value)=>setLineCode(value&&value.id||'')}
                                    options={carrierList}
                                    getOptionLabel={(option)=>option.value+option.label}
                                    isClearable={lineCode?true:false}
                                    />
                                </Col>
                              </FormGroup> 
                          </Col> 
                          <Col xl='4' className='search_option--calendar'>
                            <FormGroup row>                               
                              <Label className='search_option--calendar--text'>SR일자</Label>                       
                              <Col>
                                  <CustomDatePickerInput
                                      id="startDate"
                                      dateFormat="YYYY-MM-DD"
                                      timeFormat={false}
                                      value={toDate}
                                      onChange={(date)=>setToDate(date)}
                                      {...props}/>
                              </Col>
                              <div className='search_option--calendar--text'>
                                  ~ 
                              </div>
                              <Col>
                                  <CustomDatePickerInput
                                      id="endDate"
                                      dateFormat="YYYY-MM-DD"
                                      timeFormat={false}
                                      value={endDate}
                                      onChange={(date)=>setEndDate(date)}
                                      {...props}/>
                              </Col>
                            </FormGroup>
                          </Col>
                            {/* <Col xl='4' className='search_option--number'>
                                <FormGroup row className='search_option--number--formGroup' >
                                    <Col className='search_option--number--text col-4'>
                                        VESSEL
                                    </Col>
                                    <Col className='search_option--number--input col-8'>
                                        <Select
                                            className="customSelect "
                                            name="sch_vessel_name"
                                            value={{
                                                value:sch_vessel_name?sch_vessel_name:'',
                                                label:sch_vessel_name?sch_vessel_name:'선택'
                                            }}
                                            onChange={(value)=>value?setSchVesselName(value.vessel_name):setSchVesselName('')}
                                            options={lineVesselList}
                                            isClearable={sch_vessel_name?true:false}
                                        />
                                    </Col>
                                </FormGroup> 
                            </Col>  */}
                          <Col xl='4' className='search_option--number'>
                              <FormGroup row className='search_option--number--formGroup' >
                                  <Col className='search_option--number--text'>
                                      SR NUMBER
                                  </Col>
                                  <Col className='search_option--number--input'>
                                      <Input                                 
                                          type="text" 
                                          id="bknum"
                                          placeholder="SR Number"
                                          maxLength="50"
                                          value={srNo}
                                          onChange={(e)=>setSrNo(e.target.value.toUpperCase())}
                                          onKeyPress ={(e)=> {"Enter" === e.key && srNo&&onSubmit();}}
                                          />
                                  </Col>                  
                              </FormGroup>
                          </Col>                                                        
                        </div>  
                    </CardTitle>
                    <div className='result_option'>
                        <CardSubtitle className="result_count text-left">                     
                        <span>[ Data Count: {data.length}건 / {totCnt}건 ]</span>                          
                        </CardSubtitle>
                        <div className='search_option--new'>
                        {userData &&
                            // <Link to={{pathname: `/svc/sr`, state:{user_no:props.userData?props.userData.user_no:'', sr_no:'', sr_date:'',doc_new:'Y'}}}>
                                <Button color="primary" id="new" type="button" outline onClick={()=>setScheduleCalendarOpen(true)}>
                                    <i className="fa fa-plus"/>
                                    NEW
                                </Button>                          
                            // </Link> 
                        }
                            <Button                          
                                color="primary" 
                                className='search_option--search' 
                                onClick={()=>onSubmit()}>SEARCH</Button>    
                        </div>
                    </div>
                    <CardBody className=" result_table">
                        <Row className="table_th bg-light border-top" >
                            <div className='table_th--no border-right border-left border-bottom'>
                                #
                            </div> 
                            <Col>
                                <Row>                 
                                <Col xl="3" md="6" xs="12">
                                        <Row className='table_th--rows'>
                                            <Col className="table_th--text" xs="3">CARRIER</Col>
                                            <Col className="table_th--text" xs="5">SR NO</Col>
                                            <Col className="table_th--text" xs="4">SR DATE</Col> 
                                        </Row>                          
                                    </Col>
                                    <Col xl="3" md="6" xs="12">
                                        <Row className='table_th--rows'>
                                          <Col className="table_th--text" xs="3">STATUS</Col>                 
                                          <Col className="table_th--text" xs="4">SEND DATE</Col>
                                          <Col className="table_th--text" xs="5">MBL NO</Col>
                                        </Row>
                                    </Col>
                                    <Col xl="3" md="6" xs="12">
                                        <Row className='table_th--rows'>
                                          <Col className="table_th--text" xs="5">RES ISSUE DATE</Col> 
                                          <Col className="table_th--text" xs="7">SCH VSL NAME</Col>
                                        </Row>
                                    </Col>
                                    <Col xl="3" md="6" xs="12">
                                        <Row className='table_th--rows'>
                                            <Col className="table_th--text" xs="4">POL</Col>
                                            <Col className="table_th--text" xs="4">POD</Col>
                                            <Col className="table_th--text" xs="2">CARGO PACK QTY</Col>
                                            <Col className="table_th--text" xs="2">CNTR</Col>
                                        </Row>
                                    </Col>
                                    {/* <Col xs='6' md='4' xl='2' className="table_th--text">SR NO </Col>
                                    <Col xs='3' md='2' xl='1' className="table_th--text">SR DATE</Col>
                                    <Col xs='3' md='2' xl='1' className="table_th--text">STATUS</Col>
                                    <Col xs='3' md='2' xl='1' className="table_th--text">SEND DATE</Col>
                                    <Col xs='3' md='2' xl='1' className="table_th--text">MBL NO</Col>
                                    <Col xs='3' md='2' xl='1' className="table_th--text">RES ISSUE DATE</Col>
                                    <Col xs='3' md='2' xl='1' className="table_th--text">SCH VSL NAME</Col>  
                                    <Col xs='3' md='2' xl='1' className="table_th--text">POL</Col>
                                    <Col xs='3' md='2' xl='1' className="table_th--text">POD</Col>
                                    <Col xs='3' md='2' xl='1' className="table_th--text">CARGO PACK QTY</Col>
                                    <Col xs='3' md='2' xl='1' className="table_th--text">CNTR</Col>     */}
                                </Row>
                            </Col>                       
                        </Row>
                        {(data.length > 0) && data.map((value,index)=> (
                            <TableList key={index} indexs={index} propsData={value} deleteRows={(seq,value)=>onDeleteRows(seq,value)} {...props}/>
                        ))} 
                    {popupMap}
                    </CardBody>
                    <CardFooter className="text-center">
                        {data.length > 0 &&
                        <Button
                            size="lg"
                            className="btn-round"
                            onClick={() => {onMore(num + 1);}}
                            color="primary">
                            <span>More&nbsp;{num}&nbsp;/&nbsp;{data.length!==0?data[0].tot_page:"0"}</span>
                        </Button>}
                    </CardFooter>
                </Card>
            {/* </Col> */}
            {scheduleCalendarOpen&&
				<ScheduleCalendarModal open={scheduleCalendarOpen}  toggle={()=>setScheduleCalendarOpen(false)}
                                selectedEventCalendar={(event)=>{
                                    if("N" === event.sr_eshipping_use_yn){
                                        props.onAlert("error",`SR ${validation.NOT_SERVE_CARRIER}`);
                                        return false;
                                    } 
                                    if( "N" === event.booking_yn ) {
                                        props.onAlert("error","해당 스케줄은 선택 불가합니다.");
                                        return false;
                                    } else {
                                        props.alert(()=>{
                                            history.push({
                                                pathname: 'W' === event.bkg_type
                                                ?`/svc/srWDFC`
                                                :'E'=== event.bkg_type
                                                    ?'/svc/srPlism':`/svc/sr`,
                                                state:{
                                                    ...event,
                                                    schedule_yn:'Y'
                                                    ,doc_new: "Y",
                                                    sch_por:event.sch_por?event.sch_por:event.sch_pol,
                                                    sch_por_name:event.sch_por_name?event.sch_por_name:event.sch_pol_name,
                                                    sch_pld:event.sch_pld?event.sch_pld:event.sch_pod,
                                                    sch_pld_name:event.sch_pld_name?event.sch_pld_name:event.sch_pod_name,
                                                    sch_fdp:event.sch_fdp?event.sch_fdp:event.sch_pod,
                                                    sch_fdp_name:event.sch_fdp_name?event.sch_fdp_name:event.sch_pod_name,
                                                    sch_vessel_name:event.vsl_name||null,
                                                    sch_vessel_voyage:event.voyage_no||null,
                                                    sch_pol:event.start_port||null,
                                                    sch_pod:event.end_port||null,
                                                }
                                            })
                                        }
                                        ,'SR 을 새로 생성하시겠습니까?'
                                        ,'warning',true,true,'lg'
                                        ,`${event&&(event.carrier_knm||event.line_code||event.sch_line_code)}\n SR 번호 : 자동 생성`
                                        ,true,true,()=> {return false}
                                        );
                                    }
                                }}    
                                    {...props}/>
			}
        </>
    )

}