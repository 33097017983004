/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Row,Col, CardBody, FormGroup, Table,Label,Input, FormFeedback} from "reactstrap";
import * as validation from 'components/common/validation.js';
import InputValid from "components/CustomInput/InputValid.js";
import Select from 'react-select';
//import axios from 'axios';
//import Moment from 'moment';

export default function OthersBookmark(props) {
	const {bookmark,loadFormData,term, hblList, linePaymentList, blTypeList} = props;
	const [others, setOthers] = useState({});
	useEffect(() => {
		setOthers(loadFormData);
	},[loadFormData]);
	const onPropsOtBookmark =(data) =>{
		setOthers(data);
		props.onPropsOtBookmark(data);
	}
	const onHandleReturnVal = (event,name) => {
		let list = {...others, [name]:(event.target.value.toUpperCase())||null};
		setOthers(list);
  	}
	  const onPropsReturn = ()=> {
		onPropsOtBookmark(others);
	}
	const onHandleSelectReturnVal = (value,name) => {
		let list = {...others, [name]:value};
		setOthers(list);
	  	onPropsOtBookmark(list);	  
 	}
 	return (
		<>
	        <Row>
	        	<Col>BookMark List</Col>
	        </Row>
	        <Row className="mb-3">
				<Col xl="12" lg="12" md="12">
					<FormGroup style={{height:'150px',overflow:'auto'}} className="mb-0">
						<CardBody className="bg-white p-0">
							<Table className="mb-0" responsive hover size="sm">
								<thead>
									<tr>
										<td className="p-2 bg-info">No.</td>
										<td className="p-2 bg-info">Bookmark Name</td>
										<td className="p-2 bg-info">SC Number</td>
									</tr>
								</thead>
								<tbody>
								{bookmark.map((element,key)=>{
									return(
										<tr key={key} onClick={()=>onPropsOtBookmark(element)} style={element.other_bookmark_seq===others.other_bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}}>
											<td className="p-2">{key+1}</td>
											<td className="p-2">{element.other_bookmark_name}</td>
											<td className="p-2">{element.sc_no}</td>
										</tr>
									)
								})}
								</tbody>
							</Table>
						</CardBody>
					</FormGroup>
				</Col>
			</Row>
	       <hr className="m-2"/>
		   <Row>
				<Col xl="6" lg="6" md="12">
					<FormGroup>
						<Label className="mb-0">BookMark Name</Label>
						<Input 
							type="text" 
							name="other_bookmark_name" 
							id="other_bookmark_name" 
							invalid={others.other_bookmark_name?true:false}
							value={others.other_bookmark_name?others.other_bookmark_name:''} 
							onChange = {(event)=>onHandleReturnVal(event,'other_bookmark_name')} 
							onBlur={onPropsReturn}/>
						<FormFeedback>{validation.REQ_MSG}</FormFeedback>	
					</FormGroup>
				</Col>
			</Row>
			<Row> 
				<Col xl="6" lg="6" md="6">
					<FormGroup>
						<Label className="mb-0">SC Number</Label>
						<InputValid 
							type="text"
							name="sc_no"
							id="sc_no"
							maxLength="15"
							value={others.sc_no?others.sc_no:''}
							onChange={(e)=>onHandleReturnVal(e, 'sc_no')}
							onBlur={onPropsReturn}
							validtype="eng" 
							required={false}
						/>
					</FormGroup>   
				</Col>
				<Col xl="6" lg="6" md="12" className="col-12">
					<FormGroup>
						<Label className="mb-0">Term</Label>
						<Select
							className="customSelect"
							name="trans_service_code1"
							value={{
								value:others.trans_service_code?others.trans_service_code:'',
								label:others.trans_service_code?
								(term.findIndex(x=>x.value===others.trans_service_code)>=0)?
								term[term.findIndex(x=>x.value===others.trans_service_code)].label:'선택':'선택'
							}}
							onChange = {(value)=>onHandleSelectReturnVal(value.value,'trans_service_code')}
							options={term}
							styles={{
								indicatorsContainer: provided => ({...provided,height:'40px'})
							}}
						/>
						<InputValid 
							hidden
							name="trans_service_code"
							id="trans_service_code"
							maxLength="1"
							value={others.trans_service_code?others.trans_service_code:''}
							validtype="eng"
							readOnly
							feedid="booking"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="12" className="col-12">
					<FormGroup>
						<Label className="mb-0">BL_TYPE</Label>
						<Select
							className="customSelect"
							// classNamePrefix="customSelect"
							name="bl_type1"
							value={{value:others.bl_type?others.bl_type:'',
							label:others.bl_type?
								(blTypeList.findIndex(x=>x.value===others.bl_type)>=0)?
										blTypeList[blTypeList.findIndex(x=>x.value===others.bl_type)].label:
									'선택':'선택'
							}}
							onChange = {(value)=>onHandleSelectReturnVal(value.value,'bl_type')}
							options={blTypeList}
							styles={{
								indicatorsContainer: provided => ({...provided,height:'40px'})
							}}
						/>
						<InputValid 
							hidden
							name="bl_type"
							id="bl_type"
							maxLength="1"
							value={others.bl_type?others.bl_type:''}
							validtype="eng"
							readOnly
							feedid="booking"
						/>
					</FormGroup>
				</Col>
				<Col xl="6" lg="6" md="12" className="col-12">
					<FormGroup>
						<Label className="mb-0">HOUSE B/L 유무</Label>
						<Select
							className="customSelect"
							name="hbl_yn"
							value={{value:others.hbl_yn?others.hbl_yn:'',
									label:others.hbl_yn?(hblList.findIndex(x=>x.value===others.hbl_yn)>=0)?hblList[hblList.findIndex(x=>x.value===others.hbl_yn)].label:'선택':'선택'
							}}
							onChange = {(value)=>onHandleSelectReturnVal(value.value,'hbl_yn')}
							options={hblList}
							styles={{
								indicatorsContainer: provided => ({...provided,height:'40px'})
							}}
						/>
						<InputValid 
							hidden
							name="hbl_yn"
							id="hbl_yn"
							maxLength="1"
							value={others.hbl_yn?others.hbl_yn:''}
							validtype="eng"
							readOnly
							feedid="booking"
						/>
					</FormGroup>
				</Col> 
				<Col xl="6" lg="6" md="12" className="col-12">
					<FormGroup>
						<Label className="mb-0">Ocean Freight</Label>
						<Select
							className="customSelect"
							name="line_payment_type"
							value={{value:others.line_payment_type?others.line_payment_type:'',
							label:others.line_payment_type?
								(linePaymentList.findIndex(x=>x.value===others.line_payment_type)>=0)?
										linePaymentList[linePaymentList.findIndex(x=>x.value===others.line_payment_type)].label:
									'선택':'선택'
							}}
							onChange = {(value)=>onHandleSelectReturnVal(value.value,'line_payment_type')}
							options={linePaymentList}
							styles={{
								indicatorsContainer: provided => ({...provided,height:'40px'})
							}}
						/>
						<InputValid 
							hidden
							name="line_payment_type"
							id="line_payment_type"
							maxLength="1"
							value={others.line_payment_type?others.line_payment_type:''}
							validtype="eng"
							readOnly
							feedid="booking"
						/>
					</FormGroup>
				</Col>
			</Row>
    	</>
    );
}