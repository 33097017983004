import React,{useState, useRef, useEffect, useCallback} from 'react'
import { useHistory } from "react-router-dom";
import cn from 'classnames'
import axios from 'axios';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';
import {withStyles} from "@material-ui/core/styles";
import {Tooltip,} from "@material-ui/core";
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'
import {userStore} from 'store/userStore.js';
import { useObserver } from 'mobx-react-lite';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

// import PlaceholderImg from 'assetsNew/image/No-Image.gif'

// css
// import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css'

import './slider.css'
import Style from './main.module.css'

const useStyles = makeStyles((theme)=>({
    modal:{
        display:'flex'
        ,alignItems:'center'
        ,justifyContent:'center'
    }
}))

const TooltipCustom = withStyles((theme)=>({
    tooltip:{
        fontSize:12
    },
    tooltipPlacementTop:{
        margin:'2px 0'
    }
}))(Tooltip)


const VisualItemWrap = (props) => {
    const history = useHistory();
    
    const {userStoreMenu} = props;
    const  [ searchTerm, setSearchTerm]=useState('');
    // const  [ logoImgList, setLogoImgList]=useState([]);
    const  [ tabPart, setTabPart]=useState(0); //서비스제공선사는 무조건 4 ~!
    const [ serviceListAll,setServiceListAll ]=useState([]);//최초한번 데이터
    const [ carrierData,setCarrierData ]=useState([]);
    const [ tabList,setTabList ]=useState( [
        {id :0, tname:'e-Shipping' , pdata : [{pname:'e-Shipping 바로가기', path: '/svc/fcl' }]
        },
        {id :1, tname:'e-A/N' , pdata : [{pname:'e-A/N 바로가기', path: {prodNo :157, devNo:157} }]
                },
        {id :2, tname:'c-D/O 신청' , pdata : [{pname:'c-D/O 바로가기', path: {prodNo :158, devNo:158}}]
                },
        {id :3, tname:'인보이스-신청'  
                , pdata : [
                    {pname:'SUR CHG 인보이스' , path: {prodNo :936, devNo:605} }
                    ,{pname:'수입 운임 인보이스' , path: {prodNo :1039, devNo:1049} }
                    ,{pname:'수출 운임 인보이스' , path: {prodNo :2420, devNo:2417} }
                        ]
                },
    ])
    const tab3=[
        {mtit:`SUR CHG 인보이스`,mdesc:`- 반출/납 기한 연장, 반납지 변경 신청, 내역 관리`}
        ,{mtit:`수입 운임 인보이스`,mdesc:`- D/O 인보이스 신청, 내역 관리`}
        ,{mtit:`수출 운임 인보이스`,mdesc:`- 수출 해상인보이스 내역 관리`}
    ]
    const [tabData, setTabData] = useState(tabList&&tabList[0])

    useEffect( ()=>{
        getCarrierData();
        return 
    },[])

    useEffect( ()=>{
        searchTerm&&setSearchTerm('')
        return 
    },[tabData])

    useEffect( ()=>{
        !searchTerm&&setCarrierData([...serviceListAll])
        return 
    },[searchTerm])

    const getCarrierData = async()=>{
        const result0 = await axios.post("/api/getCarrierListEbooking",{})
        const result1 = await axios.post("/api/getCarrierListEAN",{})
        const result2 = await axios.post("/api/getCarrierListCDO",{})
        const result3 = await axios.post("/api/getCarrierListINV",{})
        // const logoImg = await axios.post(`/api/getCarrierLogo`,{})
        // const logoList = logoImg.data;
        const resultData0=result0.data.map(item =>{return {img: item.LOGO_IMG || item.LINE_CODE,line_name:item.LINE_KR_NAME, line_code:item.LINE_CODE, sch_line_code:item.SCH_LINE_CODE}})
        const resultData1=result1.data.map(item =>{return {img: item.LOGO || item.PARTNER_LINE, line_name:item.PARTNER_LINE_NM, line_code: item.PARTNER_LINE}})
        const resultData2=result2.data.map(item =>{return {img: item.LOGO || item.PARTNER_LINE, line_name:item.PARTNER_LINE_NM, line_code: item.PARTNER_LINE}})
        const resultData3=result3.data.map(item =>{return {img: item.LOGO || item.LINE_CODE, line_name:item.PARTNER_LINE_NM, line_code: item.LINE_CODE, type : item.TYPE}})
        // const resultData0=result0.data.map(item =>{return {img: item.logo_img ,line_name:item.line_kr_name, line_code:item.line_code,sch_line_code:item.sch_line_code}})
        // const resultData1=result1.data.map(item =>{return {img: item.LOGO_IMG|| (logoList.find(x=>x.sch_line_code==item.PARTNER_LINE)&&logoList.find(x=>x.sch_line_code==item.PARTNER_LINE).sch_logo_img)
        //                                                     ,line_name:item.PARTNER_LINE_NM, line_code: item.PARTNER_LINE}})
        // const resultData2=result2.data.map(item =>{return {img: item.LOGO_IMG|| (logoList.find(x=>x.sch_line_code==item.PARTNER_LINE)&&logoList.find(x=>x.sch_line_code==item.PARTNER_LINE).sch_logo_img)
        //                                                     ,line_name:item.PARTNER_LINE_NM, line_code: item.PARTNER_LINE}})
        // const resultData3=result3.data.map(item =>{return {img: item.LOGO_IMG|| (logoList.find(x=>x.sch_line_code==item.LINE_CODE)&&logoList.find(x=>x.sch_line_code==item.LINE_CODE).sch_logo_img)
        //                                                     ,line_name:item.PARTNER_LINE_NM, line_code: item.LINE_CODE, type : item.TYPE}})

        setServiceListAll([resultData0,resultData1,resultData2,resultData3]);//검색비교 최초 데이터
        setCarrierData([resultData0,resultData1,resultData2,resultData3])
    }

    const onSearchCarrierData=useCallback(_.debounce((params,tid)=>{
        if (serviceListAll.length>0){
            let arry = _.cloneDeep(serviceListAll);
            let data =_.cloneDeep(arry[tid]);            
            if(!params){
                setCarrierData(arry)
            }else{
                let result = data.filter(val=>{//console.log(aa,'>>>',val.name,val.name.includes(aa))
                    if((val?.line_name||'').includes(params)){ return val}
                })
                setCarrierData(old=> {
                    old[tid] =result;
                    return [...old]})
            }
        }
    },300),[serviceListAll]);

    const handleClick =useCallback(_.debounce((id, bool)=>{  
        if(bool){
            let newData = tabList&&tabList.find(x=>x.id==id);
            setTabData(newData);
            const pLength = newData.pdata&&newData.pdata.length-1;
            // console.log(pLength,'>>',tabPart)
            tabPart !==4 && pLength<tabPart &&setTabPart(0);
            // onSearchCarrierData('',id)
           
        }
        // console.log(id,tabData.carrierData)
    },200),[tabList,tabPart])
    
    const onHandleTab =useCallback(_.debounce((idx, bool)=>{  
        // console.log(idx, bool)
        if(bool){
            setTabPart(idx)
        }
    },200),[tabData])

    const goLink =(pathItem, val)=>{
        
        // 메뉴 OPTION1 가져오기
        let main = userStoreMenu.find(x=>(x.views&& x.views.find(y=>(pathItem['prodNo']==y.linkParam['prodNo'])||
                                        (pathItem ==y.layout+y.path))));
        let sub = main&&(main.views.find(y=> (pathItem['prodNo']==y.linkParam['prodNo'])||(pathItem ==y.layout+y.path)))
                
        if(props.checkOptionPage(sub)){
            if(pathItem['prodNo']){
                // props.goPlism(pathItem, val&&val.line_code||null)
                props.goPlism({linkParam:pathItem},{carrier_code:val&&val.line_code||null});
            }else{
                let param = {pathname: pathItem};
                if(val){
                    param['state']={sch_line_code:[{sch_line_code: val.sch_line_code||val.line_code||null
                                    ,line_name2: val.line_name||null}]};
                }
                history.push(param)
            }
        }
    }
    const onKeyPress = (e) => {
        if(e.key==='Enter') {
            onSearchCarrierData(searchTerm,tabData.id)
        }
    }

    return (
    <div className={Style.visualItemWrap}>
        <ul className={Style.tabmenuListWrap}>
            {tabList&&tabList.map((item,idx)=>{
            return (
                <li key = {idx} onMouseOver={()=>handleClick(item.id,true)} >
                    <a href="#1" className={item.id == tabData.id ?Style.on:''} >
                        <span className={Style.ico}></span>
                        <span className={Style.txt}>{item.tname}</span>
                    </a>
                </li>
                )
            })}
        </ul>

        <div className={cn(Style.tabarea , Style.on)}>
            <div className={cn(Style.subTabarea
                , tabPart==4?Style[`bg${tabData.id}2`]:Style[`bg${tabData.id}1`]
                , Style.on
                )}>
    
                <div className={Style.stHeaderWrap}>
                    <ul>
                        {tabData&&tabData.pdata.map((val,inx,arry)=>{
                        return (<li  key={inx}  onMouseOver={()=>onHandleTab(inx, true)}
                                    onClick={()=>val.path&&goLink(val.path)}>
                                <div className={cn(Style.pathItem,tabPart== inx? Style.on:'')}>{val.pname}</div>
                            </li>)
                        })
                        }
                        <li  onMouseOver={()=>onHandleTab(4, true)}><div className={cn(Style.pathItem,tabPart== 4 ? Style.on:'')}>서비스 제공 선사</div></li>
                    </ul>
                    {tabPart==4&&
                            <div className={Style.stSearchWrap}>
                                <input type="text" className={Style.inp} placeholder="CARRIER NAME"
                                        value={searchTerm||''}
                                        onKeyPress={onKeyPress}
                                        onChange={(e)=>{setSearchTerm(e.target.value.toUpperCase())}}/>
                                <button type="button" onClick={()=>onSearchCarrierData(searchTerm,tabData.id)}>
                                    <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-search.png`} />    
                                </button>
                            </div>    
                    }
                </div>
            {tabPart==4?   
                tabData&&tabData.id!==3?
                    <div className={Style.serviceBizWrap}>
                        <div className={Style.scrolls}>
                            <ul>
                            {carrierData.length>0&&carrierData[tabData.id].map((value, ind)=>
                                <TooltipCustom key={ind} title={`[${value.line_code}]  ${value.line_name}`} placement='top' >
                                <li key={ind} className={Style.item} onClick={()=>tabData.pdata&&goLink(tabData.pdata[0].path,value)}>
                                    {/* <ImgComponent line_code={value.line_code} logo={value.img} /> */}
                                    <LoadCarrierImage line_code={value.line_code} logo={value.img} classStyle={"img-rounded img-responsive"} 
                                     width={32} height={30}
                                     />
                                    <span>{value.line_name}</span>
                                </li>
                                </TooltipCustom>
                                )
                            }
                            </ul>
                        </div>
                    </div>
                : <div className={Style.serviceGroupWrap}>
                        {tab3.map((subItem,ind)=>{//console.log(carrierList,carrierList&&carrierList.find(x=>x.type==ind))
                            return <div key = {ind} className={Style.serviceBizWrap}>
                                <div className={Style.scrolls}>
                                    {subItem&&(<><div className={Style.mtit}>{subItem.mtit}</div>    
                                    <div className={Style.mdesc}>{subItem.mdesc}</div></>)}
                                    <ul>
                                        {carrierData.length>0&&carrierData[tabData.id].filter(x=>x.type==ind).map((value, indx)=>
                                        <TooltipCustom title={`[${value.line_code}]  ${value.line_name}`} placement='top' >
                                            <li key={indx} className={Style.item} onClick={()=>tabData.pdata&&goLink(tabData.pdata[ind].path,value)}>
                                                     
                                                    {/* <ImgComponent line_code={value.line_code} logo={logoImgList.length>0&&logoImgList.filter(x=>x.line_code==value.line_code)} /> */}
                                                    {/* <ImgComponent line_code={value.line_code} logo={value.img} /> */}
                                                    <LoadCarrierImage line_code={value.line_code} logo={value.img} classStyle={"img-rounded img-responsive"} 
                                                        width={32} height={30}
                                                        />
                                                    <span>{value.line_name}</span>
                                            </li>
                                        </TooltipCustom>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        })}
                    </div>
            : 
            <div className={Style.aboutWrap}>
                <div className={Style.sico}>
                    <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-st-about0${tabData.id+1}.png`} />
                    <strong>{tabData.tname}</strong>
                </div>
                {/*<!--e-Booking -->*/}
                {tabData&&tabData.id==0&&
                    <div className={Style.scont}>
                        <div className={Style.stit}>수출선적업무를 한 곳에서 한 번에 끝!</div>
                        <div className={Style.sdesc}>수출선적업무 통합 플랫폼 서비스로 쉽고 빠른 수출 업무를 지원하고 있습니다.</div>
                    </div>
                }    
                {tabData&&tabData.id==1&&
                <div className={Style.scont}>
                        <div className={Style.stit}>언제든지 조회가 가능한 A/N 서비스</div>
                        <div className={Style.sdesc}>수기로 수신 받던 A/N 서비스를 자동화하여,
                            <br/>선사 업무시간 이외에도 언제든지 수신 및 조회가 가능합니다.
                        </div>
                </div>}
                {tabData&&tabData.id==2&&
                <div className={Style.scont}>
                    <div className={Style.stit}>신속한 D/O 발급 확인 서비스</div>
                    <div className={Style.sdesc}>D/O 신청에서부터 D/O 발급 확인까지 모든 과정이 시스템으로 연계되어
                        <br/>언제든지 신속하게 Cargo Release가 가능합니다.
                    </div>
                </div>}
                {tabData&&tabData.id==3&&
                <div className={Style.scont}>
                   <ul>
                        <li>SUR CHG 인보이스 : 반출/반납 기한 연장, 반납지 변경 신청, 내역 관리</li>
                        <li>수입 운임 인보이스 : 수입 해상인보이스 내역 관리</li>
                        <li>수출 운임 인보이스 : 수출 해상인보이스 내역 관리</li>
                    </ul>
                </div>}
            </div>
            }




            {/*<!--e-A/N -->*/}
            {/* // {tabData&&tabData.id==1&&<>
            //      <div className={Style.aboutWrap}>
            //         <div className={Style.sico}>
            //             <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-st-about0${tabData.id+1}.png`} />
            //             <strong>{tabData.tname}</strong>
            //         </div>
            //         <div className={Style.scont}>
            //             <div className={Style.stit}>언제든지 조회가 가능한 A/N 서비스</div>
            //             <div className={Style.sdesc}>수기로 수신 받던 A/N 서비스를 자동화하여,
            //                 <br/>선사 업무시간 이외에도 언제든지 수신 및 조회가 가능합니다.
            //             </div>
            //         </div>
            //     </div>
            // </>} */}
   
            
            {/*<!--C-D/O신청 -->*/}
            {/* {tabData&&tabData.id==2&&<>
                  <div className={Style.aboutWrap}>
                        <div className={Style.sico}>
                            <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-st-about0${tabData.id+1}.png`} />
                            <strong>{tabData.tname}</strong>
                        </div>
                        <div className={Style.scont}>
                            <div className={Style.stit}>신속한 D/O 발급 확인 서비스</div>
                            <div className={Style.sdesc}>D/O 신청에서부터 D/O 발급 확인까지 모든 과정이 시스템으로 연계되어
                                <br/>언제든지 신속하게 Cargo Release가 가능합니다.
                            </div>
                        </div>
                    </div>
                
            </>} */}

            {/*<!--인보이스 신청 -->*/}
            {/* {tabData&&tabData.id==3&&
                tabPart==4?   
                    <div className={Style.serviceGroupWrap}>
                        {tab3.map((subItem,ind)=>{//console.log(carrierList,carrierList&&carrierList.find(x=>x.type==ind))
                            return <div className={Style.serviceBizWrap}>
                                <div className={Style.scrolls}>
                                    {subItem&&(<><div className={Style.mtit}>{subItem.mtit}</div>    
                                    <div className={Style.mdesc}>{subItem.mdesc}</div></>)}
                                    <ul>
                                        {carrierData.length>0&&carrierData[tabData.id].filter(x=>x.type==ind).map((value, indx)=>
                                            <li key={indx} className={Style.item} onClick={()=>tabData.pdata&&goLink(tabData.pdata[ind].path)}>

                                                    <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-slide-list01.png`} />
                                                   
                                                    <span>{value.line_name}</span>
                                               
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        })}
                    </div>
                :  <div className={Style.aboutWrap}>
                        <div className={Style.sico}>
                            <img src={process.env.PUBLIC_URL + `/assetsNew/image/ico/ico-st-about0${tabData.id+1}.png`} />
                            <strong>{tabData.tname}</strong>
                        </div>
                        <div className={Style.scont}>
                                <ul>
                                    <li>SUR CHG 인보이스 : 반출/반납 기한 연장, 반납지 변경 신청, 내역 관리</li>
                                    <li>수입 운임 인보이스 : 수입 해상인보이스 내역 관리</li>
                                    <li>수출 운임 인보이스 : 수출 해상인보이스 내역 관리</li>
                                </ul>
                        </div>
                    </div>
                } */}

            </div>
        </div>
    </div>
  )
}
// const ImgComponent =(props)=>{
//     const {line_code,logo} =props;
//     const [ imgLoad,setImgLoad]=useState( false);
//     return  (<>
//     <LazyLoadImage
//         src={process.env.PUBLIC_URL +`/assetsNew/image/carrier_logo/${logo||'No-Image.gif'}`}
//         width={32} height={30}
//         placeholderSrc={PlaceholderImg}    
//         onError={(e)=>{e.target.style.display = 'none';}}
//     />
//     {/* <LazyLoadImage
//         src={process.env.PUBLIC_URL +`/assetsNew/image/carrier_logo/${logo.length>0?logo[0].logo_img:'No-Image.gif'}`}
//         width={32} height={30}
//         placeholderSrc={PlaceholderImg}
//         // afterLoad={e=>console.log(e)}
//         onError={(e)=>{e.target.style.display = 'none'; setImgLoad(false)}}
//         // alt='img'
//     /> */}
//         </>)
// }
export default VisualItemWrap
