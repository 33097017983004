/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,Col, CardBody, Collapse,
     Button,FormGroup,Label, Card,UncontrolledTooltip} from "reactstrap";
import Select from "react-select";
import NotifyBookmark from './NotifyBookmark.js';
import axios from 'axios';
//import * as validation from 'components/common/validation.js';
import InputValid from "components/CustomInput/InputValid.js";
const NotifyCard = forwardRef((props,notifyFocus) => {
	const {bookmark,loadData,openWindow, lineConfigDetail} = props;
	// Collapse Flag
	const [coll, setColl] = useState(false);
	// modal 창을 위한 state
	const [open, setOpen] = useState(false);
	const [notify, setNotify] = useState([]);
	const [propsData, setPropsData] = useState([]);
	
	
	useEffect(() => {
		setNotify(loadData);
	},[loadData]);

	useEffect(() => {
		setColl(openWindow);
	},[openWindow]);
	
  	const toggle = (params) => {
		props.onLoadData("nt");
		setPropsData({
			...loadData,
			notify_bookmark_seq:'',
			notify_bookmark_name:'',
			noti_code:notify.noti_code,
			noti_name1:notify.noti_name1,
			noti_name2:notify.noti_name2,
			noti_address1:notify.noti_address1,
			noti_address2:notify.noti_address2,
			noti_address3:notify.noti_address3,
			noti_address4:notify.noti_address4,
			noti_address5:notify.noti_address5,
			noti_user_name:notify.noti_user_name,
			noti_user_tel:notify.noti_user_tel,
			noti_user_fax:notify.noti_user_fax,
			noti_country_code:notify.noti_country_code
		});
		setOpen(!open);
  	}

    // 자식의 Data 적용
	const onBookMarkData = (data) => {
		setPropsData(data)
		
	}

	const onSaveBookmark =()=> {
		if(propsData.notify_bookmark_name) {
			axios.post("/shipper/setUserNotiBookmark",{user_no:props.userData?props.userData.user_no:'',data:propsData}).then(res => {
				props.onLoadData("nt");
				props.onAlert("success","작성한 BOOKMARK 가 저장 되었습니다.");
	  	  	});
		} else {
			props.onAlert("error","notify_bookmark_name 는 필수 입력 항목 입니다.");
		}
	}
	
	const onChangeNoti =(value)=> {
		if(value) {
			if(value.value > 0) {
				axios.post("/shipper/getUserNotiBookmark",{user_no:props.userData?props.userData.user_no:'',seq:value.value}).then(res => {
					const list = {
						noti_name1:res.data[0].noti_name1?res.data[0].noti_name1:notify.noti_name1,
						noti_name2:res.data[0].noti_name1?res.data[0].noti_name2:notify.noti_name2,
						noti_address1:res.data[0].noti_address1?res.data[0].noti_address1:notify.noti_address1,
						noti_address2:res.data[0].noti_address1?res.data[0].noti_address2:notify.noti_address2,
						noti_address3:res.data[0].noti_address1?res.data[0].noti_address3:notify.noti_address3,
						noti_address4:res.data[0].noti_address1?res.data[0].noti_address4:notify.noti_address4,
						noti_address5:res.data[0].noti_address1?res.data[0].noti_address5:notify.noti_address5,
						noti_code:res.data[0].noti_code?res.data[0].label:notify.noti_code,
						noti_country_code:res.data[0].noti_country_code?res.data[0].label:notify.noti_country_code,
						noti_user_dept:res.data[0].noti_user_dept?res.data[0].label:notify.noti_user_dept,
						noti_user_email:res.data[0].noti_user_email?res.data[0].label:notify.noti_user_email,
						noti_user_fax:res.data[0].noti_user_fax?res.data[0].label:notify.noti_user_fax,
						noti_user_name:res.data[0].noti_user_name?res.data[0].label:notify.noti_user_name,
						noti_user_tel:res.data[0].noti_user_tel?res.data[0].label:notify.noti_user_tel,
					};
					const mergeData = Object.assign(notify,list);	  
					setNotify({...mergeData,notify_bookmark_seq:value.value,notify_bookmark_name:value.label});
					props.mergeData({...mergeData,notify_bookmark_seq:value.value,notify_bookmark_name:value.label});
					setColl(true);
				});
			}
		}else {
			const list = {
				...notify,
				noti_name1:null,
				noti_name2:null,
				noti_address1:null,
				noti_address2:null,
				noti_address3:null,
				noti_address4:null,
				noti_address5:null,
				noti_code:null,
				noti_country_code:null,
				noti_user_dept:null,
				noti_user_email:null,
				noti_user_fax:null,
				noti_user_name:null,
				noti_user_tel:null,
				notify_bookmark_seq:null,
				notify_bookmark_name:null
			};
			setNotify(list);
			props.mergeData(list);
		}
	}
	
	
	const onInitData = () => {
		setPropsData({
			...propsData,
			notify_bookmark_seq:'',
			notify_bookmark_name:'',
			noti_code:'',
			noti_name1:'',
			noti_name2:'',
			noti_address1:'',
			noti_address2:'',
			noti_address3:'',
			noti_address4:'',
			noti_address5:'',
			noti_user_name:'',
			noti_user_tel:'',
			noti_user_fax:'',
			noti_user_dep1:''
		});
	}
	
	const onBookMarkDelete = (data) => {
		
		if(propsData && propsData.notify_bookmark_seq) {
			axios.post("/shipper/setUserNotiBookmarkDel",{user_no:props.userData?props.userData.user_no:'',data:propsData}).then(res => {
				onInitData();
				props.onLoadData("nt");
				props.onAlert("success","선택한 BOOKMARK 가 삭제 되었습니다.");
			});
		} else {
			props.onAlert("error","삭제 할 BOOKMARK를 선택해주세요.");
		}
		
	}
	
	const onHandleReturnVal = (event,name) => {
		let list = {...notify, [name]:(event.target.value.toUpperCase())||null};
		setNotify(list);
	}
	
	
	const onPropsReturn = ()=> {
		props.mergeData(notify);
	}
	
	const onCopyData =()=> {
		if(loadData) {
		    var list = {...notify,'noti_name1':'SAME AS CONSIGNEE'};
			setNotify(list);
			props.mergeData(list);
		}
	}
	
  	return (
    	<>
        	<Row id="Notify">
				<Col xl="12" lg="12">
					<Card style={{zIndex:'7',border:'1px solid silver',borderRadius:'10px'}}>
						<CardBody className="pt-3 pb-0" > 
							<Row>
								<Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>NOTIFY</Col>
								<Col>
									<Row>
										<Col className="col-10 pr-0">
											<Select
												className="react-select react-select-primary"
												name="carrierbookmark"
												value={{value:notify.notify_bookmark_seq?notify.notify_bookmark_seq:'',label:notify.notify_bookmark_name?notify.notify_bookmark_name:'선택'}}
												onChange={(value)=>onChangeNoti(value)}
												options={bookmark}
												placeholder="선택"
												ref={notifyFocus}
												isClearable={notify.notify_bookmark_seq?true:false}/>
										</Col>
										<Col className="col-2 pl-auto pr-auto">
											<Button className="pl-0 pr-0" color="link" id="notibookmark" onClick={toggle}><i className="fa fa-bookmark-o fa-2x" /></Button>
											<UncontrolledTooltip delay={0} target="notibookmark">Bookmark</UncontrolledTooltip>
										</Col>			
									</Row>
								</Col>
							</Row>
							<Col className="col-12 text-right pr-0" style={{paddingTop:'3px'}}>
								<Button className="btn-link pr-0 pt-0 pb-0 border-bottom-0" color="info" type="button" size="sm" onClick={() => onCopyData()}>
								same as consignee
								</Button>
							</Col>
							<Collapse isOpen={coll}>
								<hr className="mt-0"/>
								<Row >
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0">Name</Label>
												</Col>
												<Col>
													<InputValid 
														type="text"
														name="noti_name1"
														id="noti_name1"
														maxLength="35"
														value={notify.noti_name1?notify.noti_name1:''}
														onChange={(e)=>onHandleReturnVal(e, 'noti_name1')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={true}
														feedid="notify"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0"></Label>
												</Col>
												<Col>
													<InputValid 
														type="text"
														name="noti_name2"
														id="noti_name2"
														maxLength="35"
														value={notify.noti_name2?notify.noti_name2:''}
														onChange={(e)=>onHandleReturnVal(e, 'noti_name2')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={false}
														feedid="notify"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
								</Row>					                
								<Row style={{fontSize:'12px'}}>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0">Address</Label>
												</Col>
												<Col>
													<InputValid 
														type="text"
														name="noti_address1"
														id="noti_address1"
														maxLength="35"
														value={notify.noti_address1?notify.noti_address1:''}
														onChange={(e)=>onHandleReturnVal(e, 'noti_address1')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={false}
														feedid="notify"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0"></Label>
												</Col>
												<Col>
													<InputValid 
														type="text"
														name="noti_address2"
														id="noti_address2"
														maxLength="35"
														value={notify.noti_address2?notify.noti_address2:''}
														onChange={(e)=>onHandleReturnVal(e, 'noti_address2')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={false}
														feedid="notify"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0"></Label>
												</Col>
												<Col>
													<InputValid 
														type="text"
														name="noti_address3"
														id="noti_address3"
														maxLength="35"
														value={notify.noti_address3?notify.noti_address3:''}
														onChange={(e)=>onHandleReturnVal(e, 'noti_address3')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={false}
														feedid="notify"
													/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0"></Label>
												</Col>
												<Col>
													<InputValid 
														type="text"
														name="noti_address4"
														id="noti_address4"
														maxLength="35"
														value={notify.noti_address4?notify.noti_address4:''}
														onChange={(e)=>onHandleReturnVal(e, 'noti_address4')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={false}
														feedid="notify"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0"></Label>
												</Col>
												<Col >
													<InputValid 
														type="text"
														name="noti_address5"
														id="noti_address5"
														maxLength="35"
														value={notify.noti_address5?notify.noti_address5:''}
														onChange={(e)=>onHandleReturnVal(e, 'noti_address5')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={false}
														feedid="notify"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
								</Row>
								{(lineConfigDetail?.showItem)&&
								(lineConfigDetail?.showItem.includes('noti_user_tel'))?//.noti_user_tel? 
								<Row style={{fontSize:'12px'}}>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0">User</Label>
												</Col>
												<Col>
													<InputValid 
														type="text"
														name="noti_user_name"
														id="noti_user_name"
														maxLength="17"
														value={notify.noti_user_name?notify.noti_user_name :''}
														onChange={(e)=>onHandleReturnVal(e, 'noti_user_name')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={true}
														feedid="notify"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0">TEL</Label>
												</Col>
												<Col>
													<InputValid 
														type="text"
														name="noti_user_tel"
														id="noti_user_tel"
														maxLength="35"
														value={notify.noti_user_tel?notify.noti_user_tel:''}
														onChange={(e)=>onHandleReturnVal(e, 'noti_user_tel')}
														onBlur={onPropsReturn}
														validtype="engNumber" 
														required={true}
														feedid="notify"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="12" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col className="pr-0 pt-1 col-2">
													<Label className="mb-0">FAX</Label>
												</Col>
												<Col>
													<InputValid 
														type="text"
														name="noti_user_fax"
														id="noti_user_fax"
														maxLength="35"
														value={notify.noti_user_fax?notify.noti_user_fax:''}
														onChange={(e)=>onHandleReturnVal(e, 'noti_user_fax')}
														onBlur={onPropsReturn}
														validtype="eng" 
														required={true}
														feedid="notify"/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
								</Row>
								:<></>}
							</Collapse>
						</CardBody>
						<Col className="text-center col-12 p-0" onClick={() => setColl(!coll)}>         
							<Button
								className="p-0"
								color="link"
								id="notimore"
								onClick={() => setColl(!coll)}
								style={{height:'21px',marginBottom:'4px',width:'100%'}}>
								{coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
							</Button>
							<UncontrolledTooltip delay={0} target="notimore">{coll?'Close':'Open'}</UncontrolledTooltip>
						</Col>
					</Card>    
				</Col>
			</Row>
			<Modal isOpen={open} toggle={toggle} className="pt-0" size="lg">
				<ModalHeader toggle={toggle} className="pt-3 pb-3">Notify Info</ModalHeader>
				<ModalBody className="p-3">
					<NotifyBookmark loadFormData={propsData} onPropsNtBookmark={onBookMarkData} onPropsNtDeleteBookmark={onBookMarkDelete}  {...props}/>:
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={onInitData}>NEW</Button>
					<Button color="primary" onClick={onSaveBookmark}>SAVE</Button>
					<Button color="primary" onClick={onBookMarkDelete}>DELETE</Button>
					<Button color="secondary" onClick={()=>setOpen(!open)}>CANCEL</Button>
				</ModalFooter>
			</Modal>
		</>
    );
});

export default NotifyCard;



