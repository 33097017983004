import React, { useState, useEffect } from 'react';
// import {subDays,format, parse } from 'date-fns';
import {toMapDateParse} from './ExpTrackingHeader.js'
import Clipboard from '@react-native-clipboard/clipboard';

import * as trkImages from 'assets/img/images.js'

import {TableCell, Tooltip,} from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom :'50px'
  },  
  tooltipStart : {
    backgroundColor : 'rgba(229,252,255,0.7)',
    color : 'rgba(0,0,0,0.87)',
    boxShadow:theme.shadows[1],
  },
  tooltip1: {
    backgroundColor : 'rgba(251,229,214,0.7)',
    color : 'rgba(0,0,0,0.87)',
    boxShadow:theme.shadows[1],
  },
  tooltip2: {
    backgroundColor : 'rgba(240,230,246,0.7)',
    color : 'rgba(0,0,0,0.87)',
    boxShadow:theme.shadows[1],
  },
  tooltipEnd : {
    backgroundColor : 'rgba(231,230,230,0.7)',
    color : 'rgba(0,0,0,0.87)',
    boxShadow:theme.shadows[1],
  },
  routeText:{color :'rgb(113, 113, 114)',fontSize :'12px'},
  dotImg:{ width:'16px', cursor:'pointer'},
  // highlight:{backgroundColor:'#f2fefd',
  //       "&:hover" : {backgroundColor: 'rgba(0, 0, 0, 0.04)'}},
}));

const TooltipCustom = withStyles((theme)=>({
  tooltip:{
      fontSize:'10px'
  },
  tooltipPlacementTop:{
      margin:'2px 0'
  }
}))(Tooltip)

const TooltipCustomTime = withStyles((theme)=>({
  tooltip:{
      fontSize:'10px',
  },
  tooltipPlacementTop:{
      margin:'2px 0'
  }
}))(Tooltip);

export default function ExpTrackingRoute(props) {
  const {tsCode, rowData, toDateParse, setLocationData, isBeforeDate} = props;
  const [iconImg, setIconImg] = useState('');
  const [iconLocation, setIconLocation] = useState('');
  const [iconInfo, setIconInfo] = useState('');
  const [tsTime, setTsTime] = useState([]);
  const classes = useStyles();
  let tsPoint = Number(rowData?.ts_max_seq||0);
  
  useEffect(() => {
    let code = tsCode.substr(0,2)=='FO'? tsCode.replace('FO', 'TD')
              :tsCode.substr(0,2)=='PS'? tsCode.replace('PS', 'EO')
              :tsCode;
    let codeArr = [...code];
    //map관련 파라미터 정의
    let imo =  rowData?.imo; 
    let mapDate = rowData?.etd ||null;
    let startport = rowData?.pol ||null;//toMapDateParse( 
    let endport = rowData?.pod ||null;//toMapDateParse( 
    let vesselName = rowData?.vessel_name ||null;//toMapDateParse( 
    
    if(codeArr.length==3){
        let endLocation = 84;
        let location = 0;
        let iInfo= `${vesselName} / ${rowData?.voyage_no}`
        let icon= '';
        
        let cntPoint = 2+Number(rowData?.ts_max_seq||0);
        let num =1;//위치번호
                
        let tsList =[];

        switch(codeArr[0]){
          case 'T': icon=`ts_icon_truck`; iInfo= 'TRUCK';break;
          case 'R': icon=`ts_icon_rail` ; iInfo= 'RAIL';break;
          case 'B': icon=`ts_icon_barge`; iInfo= 'BARGE';break;
          default:  icon=`ts_icon_vsl`; break;
        }
        
//        console.log(rowData?.icon)
        if(rowData?.icon){
          num = Number(rowData?.icon);//위치번호
  //        console.log(num)
          icon += cntPoint==num ? '_end':`_${Math.ceil(num)}`;
          if(num>2){ //map관련
              let mNum = cntPoint==num ?  Math.floor(num)-2 : Math.floor(num)-1;
              imo = rowData[`ts_imo_${mNum}`]||rowData?.imo ;
              mapDate = rowData[`ts_etd_${mNum}`]||rowData?.mapDate ;
              startport = rowData[`ts_pol_code_${mNum}`]||rowData?.startport ;
              vesselName = rowData[`ts_vessel_name_${mNum}`]|| rowData?.vessel_name;
              
              iInfo= `${vesselName} / ${rowData[`ts_voyage_no_${mNum}`]}`
          }
          location = endLocation/(cntPoint-1)*(num-1);
        }else{
          if(codeArr[0]!=='V' && codeArr[0]!=='E' &&codeArr[0]!=='F'){
            location=endLocation; num = cntPoint; imo=null; mapDate=null; startport=null; vesselName =null;
          }
          if(codeArr[1]=='A'){icon+='_end'; num = cntPoint; imo = null; mapDate=null; startport=null;vesselName =null;}
          else{icon+='_start'; }
        }
        
        [...Array(tsPoint)].map((val,ind)=>{//위치번호와 포인트 비교 후 time 정보세팅
          // console.log( ind+2 , num )
          if(ind+2>num)  tsList.push({ETA:toDateParse(rowData[`ts_eta_${ind+1}`]) ,ETD:toDateParse(rowData[`ts_etd_${ind+1}`])})
          if(ind+2<num)  tsList.push({ATA:toDateParse(rowData[`ts_ata_${ind+1}`]) ,ATD:toDateParse(rowData[`ts_atd_${ind+1}`])})
          if(ind+2==num) tsList.push({ATA:toDateParse(rowData[`ts_ata_${ind+1}`]) ,ETD:toDateParse(rowData[`ts_etd_${ind+1}`])})
        });

        setTsTime(tsList);
        setIconImg(icon);
        setIconInfo(iInfo);
        setIconLocation(location);
    }
   // console.log(vesselName)
    setLocationData({imo:imo ,sch_vessel_name:vesselName, map_start_day:toMapDateParse(mapDate) , start_port :startport, end_port:endport });
	},[tsCode]);

  const pasteInfo = async(data)=>{
    if(!data){return false}
    try{
      const text = await Clipboard.setString(data);
      props.alertMessage('모선명 & 항차 TEXT 가 \n 복사되었습니다.','info', null,{textAlign:'center'}); 
    }catch(e){
      return false;
    }
  }

  return (<>
    <div style={{display:'flex', justifyContent:'start',alignItems:'flex-end'}}>
        {/* 아이콘 Transport 정보*/}
        <TooltipCustom  title={iconInfo} placement='top' >
          <img src={trkImages[iconImg]} alt={'ts_icon'}  
              style={{width:'15%',position: 'relative', cursor:'pointer' ,left:`${iconLocation}%`,bottom:'-2px'}}
              onClick={()=>{if(String(iconImg).includes('vsl'))pasteInfo(iconInfo)}}
                  />
        </TooltipCustom>
    </div>
    <div> 
      <div style={{width:'100%',display:'flex', justifyContent:'center',alignItems:'flex-start',maxHeight:'15px',
          backgroundImage: `url(${trkImages['ts_line_plain']})`,
          backgroundRepeat:'no-repeat',
          backgroundPositionX:'center',
          backgroundSize:'90%', }}>
          {/* 시간 정보 - point원 */}
          <div style={{display:'flex',width:'90%',justifyContent:"space-between",alignItems:'flex-start'}}>
            <TooltipCustomTime   placement='top' classes={{tooltip: classes.tooltipStart}}
            title={<>{rowData?.etd&&<p>ETD : {toDateParse(rowData?.etd)||''}</p>}
              {rowData?.atd&&isBeforeDate(rowData?.atd)&& 
                <p>ATD : {toDateParse(rowData?.atd)||''}</p>}
                </>}> 
            <img src={trkImages[`ts_dot_start`]} alt={'ts_dot_start'} className={classes.dotImg}/></TooltipCustomTime>
            {[...Array(tsPoint)].map((val, key)=>{
              if(tsTime.length>0){
              return tsTime[key]
              ?(<TooltipCustomTime   key={key} 
                  title={<><p>{Object.keys(tsTime[key])[0]} : {tsTime[key][Object.keys(tsTime[key])[0]]}</p>
                          <p>{Object.keys(tsTime[key])[1]} : {tsTime[key][Object.keys(tsTime[key])[1]]}</p></>}
                  placement='top' classes={{tooltip: classes[`tooltip${key+1}`]}} > 
                  <img src={trkImages[`ts_dot_${key+1}`]} alt={`ts_dot${key+1}`} className={classes.dotImg}/>
                </TooltipCustomTime>)
            : <img src={trkImages[`ts_dot_${key+1}`]} alt={`ts_dot${key+1}`} className={classes.dotImg}/>
              }}
            )}
            <TooltipCustomTime   placement='top' classes={{tooltip: classes.tooltipEnd}}
            title={<>{rowData?.eta&&<p>ETA : {toDateParse(rowData?.eta)||''}</p>}
              {rowData?.ata&&isBeforeDate(rowData?.ata)&& 
                        <p>ATA : {toDateParse(rowData?.ata)||''}</p>}
            </>}>
              <img src={trkImages[`ts_dot_end`]} alt={'ts_dot_end'} className={classes.dotImg}/></TooltipCustomTime>
          </div>
        </div>
        {/* PORT 정보 */}
        <div style={{display:'flex',width:'100%',justifyContent:"space-between",alignItems:'flex-start'}}>
          <div style={{maxWidth:`15%`}}>{rowData.pol||rowData.pol_name||''}</div>
          {[...Array(tsPoint)].map((val, key)=> 
            // <div style={{maxWidth:`15%`}} key ={key}>{rowData[`ts_pol_name_${key+1}`]||''}</div>)
            <div style={{maxWidth:`15%`}} key ={key}>{rowData[`ts_pol_code_${key+1}`]||rowData[`ts_pol_name_${key+1}`]||''}</div>)
          }
          <div style={{maxWidth:`15%`}}>{rowData.pod||rowData.pod_name||''}</div>
        </div>
    </div>
  </>
  )
}
