/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import {Row, Col, CardBody, Collapse, UncontrolledTooltip, Button, Card, FormGroup, Label} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import ShipperBookmark from './ShipperBookmark.js';
import InputValid from "components/CustomInput/InputValid.js";

const ShipperCard = forwardRef((props, shipperFocus) => {
    // 전체화면 css 적용을 위한 state
    // Collapse Flag
    const [coll, setColl] = useState(false);
    // Shipper
    const [shipperList, setShipperList] = useState([]);
    const [booking, setBooking] = useState({});
    const {userData, shipperCompanyList, bookingKecaYN} = props;


    useEffect(()=>{
        setColl(props.openWindow);
    },[props.openWindow]);

    useEffect(() => {
        if( booking.bkg_no && booking.status_cus =='NO' ) {
            if( shipperCompanyList.length === 1 && !(booking.shp_code || booking.shp_name1|| booking.shp_user_email || booking.shp_user_name)) {
                axios.post("/com/getCompanyInfo",{ klnetId: shipperCompanyList[0].klnet_id,}).then(res => {
                    let company = res.data[0];
                    if( company ) {
                        if( "Y" === booking.user_shipper_yn ) {
                            setBooking({...booking
                                ,business_number:company.REG_NO
                                ,shp_code:shipperCompanyList[0].partner_code
                                ,shp_name1:company.CNAME_EN
                                ,shp_user_email: userData.user_email
                                ,shp_user_name: userData.user_name_en})
                            props.fncBookingParent({...booking
                                ,business_number:company.REG_NO
                                ,shp_code:shipperCompanyList[0].partner_code
                                ,shp_name1:company.CNAME_EN
                                ,shp_user_email: userData.user_email
                                ,shp_user_name: userData.user_name_en
                            ,});
                        } else {
                            setBooking({...booking,business_number:company.REG_NO,shp_code:shipperCompanyList[0].partner_code,shp_name1:company.CNAME_EN})
                            props.fncBookingParent({...booking, business_number:company.REG_NO, shp_code:shipperCompanyList[0].partner_code, shp_name1:company.CNAME_EN});
                        }
                    } else {
                        setBooking({...booking, business_number:null, shp_code:null, shp_name1:null})
                        props.fncBookingParent({...booking, business_number:null, shp_code:null, shp_name1:null});
                    }
                });
            } else if( shipperCompanyList.length > 1 && !(booking.shp_code || booking.shp_name1|| booking.shp_user_email|| booking.shp_user_name)) {
                if( userData ) {
                    setBooking({...booking,shp_user_email: userData.user_email,shp_user_name: userData.user_name_en})
                    props.fncBookingParent({...booking,shp_user_email: userData.user_email,shp_user_name: userData.user_name_en});
                }
            }
        }
    },[shipperCompanyList, booking.bkg_no]);

    useEffect(()=>{
        if( props.booking.bkg_no ) {
            setBooking(props.booking);
        }
    },[props.booking]);
    useEffect(()=>{
        setShipperList(props.shipperList);
    },[props.shipperList]);

    const fncSelectShipper = (e) => {
        // 선택
        if( e ) {
            if( 1 > e.value ) {
                if ( coll ) {
                    setColl(!coll)
                }
            // 그외 데이터인 경우
            } else {
                shipperList.forEach((element, key)=>{
                if( e.value === element.shipper_bookmark_seq) {
                    // select로 새로운 document를 세팅한다
                    // 기존 Booking 정보
                    setBooking({...booking
                        ,'shipper_bookmark_seq':element.shipper_bookmark_seq
                        ,'shipper_bookmark_name':element.shipper_bookmark_name
                        // ,'business_number':element.business_number
                        ,'shp_name1': bookingKecaYN==='Y'?(element.shp_name1?element.shp_name1:booking.shp_name1):(element.shp_name_eng?element.shp_name_eng:booking.shp_name1)
                        ,'shp_name2': element.shp_name1?element.shp_name2:booking.shp_name2
                        ,'shp_code': element.shp_code?element.shp_code:booking.shp_code
                        ,'shp_user_email': element.shp_user_email?element.shp_user_email:booking.shp_user_email
                        ,'shp_user_name':bookingKecaYN==='Y'?(element.shp_user_name?element.shp_user_name:booking.shp_user_name):(element.shp_user_name_eng?element.shp_user_name_eng:booking.shp_user_name)
                        ,'shp_address1':element.shp_address1?element.shp_address1:booking.shp_address1
                        ,'shp_address2':element.shp_address2?element.shp_address2:booking.shp_address2
                        ,'shp_address3':element.shp_address3?element.shp_address3:booking.shp_address3
                        ,'shp_address4':element.shp_address4?element.shp_address4:booking.shp_address4
                        ,'shp_address5':element.shp_address5?element.shp_address5:booking.shp_address5
                        ,'selected_yn':'Y' 
                    });
    
                    props.fncBookingParent({...booking
                        ,'shipper_bookmark_seq':element.shipper_bookmark_seq
                        ,'shipper_bookmark_name':element.shipper_bookmark_name
                        // ,'business_number':element.business_number
                        ,'shp_name1': bookingKecaYN==='Y'?(element.shp_name1?element.shp_name1:booking.shp_name1):(element.shp_name_eng?element.shp_name_eng:booking.shp_name1)
                        ,'shp_name2': element.shp_name1?element.shp_name2:booking.shp_name2
                        ,'shp_code': element.shp_code?element.shp_code:booking.shp_code
                        ,'shp_user_email': element.shp_user_email?element.shp_user_email:booking.shp_user_email
                        ,'shp_user_name':bookingKecaYN==='Y'?(element.shp_user_name?element.shp_user_name:booking.shp_user_name):(element.shp_user_name_eng?element.shp_user_name_eng:booking.shp_user_name)
                        ,'shp_address1':element.shp_address1?element.shp_address1:booking.shp_address1
                        ,'shp_address2':element.shp_address2?element.shp_address2:booking.shp_address2
                        ,'shp_address3':element.shp_address3?element.shp_address3:booking.shp_address3
                        ,'shp_address4':element.shp_address4?element.shp_address4:booking.shp_address4
                        ,'shp_address5':element.shp_address5?element.shp_address5:booking.shp_address5
                        ,'selected_yn':'Y' 
                    });
                }
                });
                if ( !coll ) {
                    setColl(!coll);
                }
            }
        } else {
            if( booking.shipper_bookmark_seq) {
                setBooking({...booking
                    ,'shipper_bookmark_seq': null
                    ,'shipper_bookmark_name': null
                    ,'shp_name1': null
                    ,'shp_name2': null
                    ,'shp_code': null
                    ,'shp_user_email': null
                    ,'shp_user_name': null
                    ,'shp_address1': null
                    ,'shp_address2': null
                    ,'shp_address3': null
                    ,'shp_address4': null
                    ,'shp_address5': null
                    ,'selected_yn':'Y' 
                });

                props.fncBookingParent({...booking
                    ,'shipper_bookmark_seq': null
                    ,'shipper_bookmark_name': null
                    ,'shp_name1': null
                    ,'shp_name2': null
                    ,'shp_code': null
                    ,'shp_user_email': null
                    ,'shp_user_name': null
                    ,'shp_address1': null
                    ,'shp_address2': null
                    ,'shp_address3': null
                    ,'shp_address4': null
                    ,'shp_address5': null
                    ,'selected_yn':'Y' 
                });
            }
        }
    }


    const fncOnChange = ( e, key ) => {
        if(key==='shp_address2'&&!booking.shp_address1){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        if(key==='shp_address3'&&(!booking.shp_address1||!booking.shp_address2)){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        if(key==='shp_address4'&&(!booking.shp_address1||!booking.shp_address2||!booking.shp_address3)){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        if(key==='shp_address5'&&(!booking.shp_address1||!booking.shp_address2||!booking.shp_address3||!booking.shp_address4)){
            props.onAlert("danger", "순서대로 입력해주세요.");
            return false
        }
        e.preventDefault();
        setBooking({
            ...booking,[key]:(e.target.value.toUpperCase())||null
        });
    }

    const fncOnKeyPress = async(e) => {
        // enter 인 경우 
        if( 13 === e.charCode ) {
            if( e.target.value.length < 10) return false;
            // console.log(e.target.value.length, e.target.value, booking.init_business_number)
            if( e.target.value && e.target.value.length === 10 ) {
                let row = shipperCompanyList.find(v=>v.business_number === e.target.value)
                if( row ) {
                    axios.post("/com/getCompanyInfo",{klnetId: row.klnet_id,}).then(res => {
                        let company = res.data[0];
                        if( company.REG_NO ) {
                            setBooking({...booking, business_number:company.REG_NO, shp_code:row.partner_code, shp_name1:company.CNAME_EN})
                            props.fncBookingParent({...booking, business_number:company.REG_NO, shp_code:row.partner_code, shp_name1:company.CNAME_EN});
                        } else {
                            setBooking({...booking, business_number:null, shp_code:null, shp_name1:null})
                            props.fncBookingParent({...booking, business_number:null, shp_code:null, shp_name1:null});
                        }
                    });
                } else {
                    props.onAlert("danger", "결과가 없습니다.");
                    setBooking({...booking, business_number:null, shp_code:null, shp_name1:null})
                    props.fncBookingParent({...booking, business_number:null, shp_code:null, shp_name1:null});
                }
            }
        }
    }


    const fncOnBlurBusinessNumber =(e)=> {
        if( !e.target.value ) {
            setBooking({...booking, business_number:null, shp_code:null, shp_name1:null})
            props.fncBookingParent({...booking, business_number:null, shp_code:null, shp_name1:null});
        }
    }



    const fncSearchBn = () => {
        if( !booking.business_number ) return false;
        if( booking.business_number.length < 10) return false;
        if( booking.business_number && booking.business_number.length === 10 ) {
            let row = shipperCompanyList.find(v=>v.business_number === booking.business_number)
            if( row ) {
                axios.post("/com/getCompanyInfo",{klnetId: row.klnet_id,}).then(res => {
                    let company = res.data[0];
                    if( company.REG_NO ) {
                        setBooking({...booking, business_number:company.REG_NO, shp_code:row.partner_code, shp_name1:company.CNAME_EN})
                        props.fncBookingParent({...booking, business_number:company.REG_NO, shp_code:row.partner_code, shp_name1:company.CNAME_EN});
                    } else {
                        setBooking({...booking, business_number:null, shp_code:null, shp_name1:null})
                        props.fncBookingParent({...booking, business_number:null, shp_code:null, shp_name1:null});
                    }
                });
            } else {
                props.onAlert("danger", "결과가 없습니다.");
                setBooking({...booking, business_number:null, shp_code:null, shp_name1:null})
                props.fncBookingParent({...booking, business_number:null, shp_code:null, shp_name1:null});
            }
        }
    }
    return (
        <>
            <Row id="Shipper">
                <Col xl="12" lg="12">
                    <Card style={{zIndex:'85'}}>
                        <CardBody className="pt-3 pb-2" style={{border:'1px solid silver',borderRadius:'10px'}}>  
                            <Row className="pb-2">
                                <Col className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>SHIPPER (실화주)
                                </Col>
                                <Col>
                                    <Row>
                                        <Col className="col-10 pr-0">
                                            <Select
                                                className="react-select react-select-primary"
                                                name="lineBookmark"
                                                value={{value:booking.shipper_bookmark_seq?booking.shipper_bookmark_seq:''
                                                ,label:booking.shipper_bookmark_name?booking.shipper_bookmark_name:'선택'}}
                                                onChange={(e)=>fncSelectShipper(e?e:null)}
                                                options={shipperList}
                                                placeholder="선택"
                                                ref={shipperFocus}
                                                isClearable={booking.shipper_bookmark_seq?true:false}
                                            />
                                        </Col>
                                        <Col className="col-2 pl-auto pr-auto">
                                            <ShipperBookmark
                                                shipperList={shipperList}
                                                selectBookingShipperBookmark={props.selectBookingShipperBookmark}
                                                onAlert={props.onAlert}
                                                shipperCompanyList={shipperCompanyList}
                                                bookingKecaYN={bookingKecaYN}
                                                {...props}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Collapse isOpen={coll}>
                            <hr className="mt-0"/>
                            <Row>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0">사업자번호</Label></Col>
                                            <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="business_number"
                                                    id="business_number"
                                                    placeholder="사업자 번호 입력 후 엔터"
                                                    maxLength="10"
                                                    value={booking.business_number?booking.business_number:''}
                                                    onChange={(e)=>fncOnChange(e, 'business_number')}
                                                    onBlur={(e) => {fncOnBlurBusinessNumber(e)}}
                                                    onKeyPress={(e)=>fncOnKeyPress(e)}
                                                    validtype="number" 
                                                    required={false} 
                                                    feedid="shipper"
                                                    inputgrouptext={<i className="fa fa-search " onClick={()=>fncSearchBn()}/>}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2">
                                                <Label for="shp_code" className="mb-0">Code</Label>
                                            </Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_code"
                                                id="shp_code"
                                                maxLength="35"
                                                value={booking.shp_code?booking.shp_code:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_code')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="engNumber" 
                                                required={false} 
                                                feedid="shipper"
                                            /> 
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Name</Label></Col>
                                            <Col>
                                                <InputValid 
                                                    type="text"
                                                    name="shp_name1"
                                                    id="shp_name1"
                                                    maxLength="35"
                                                    value={booking.shp_name1?booking.shp_name1:''}
                                                    onChange={(e)=>fncOnChange(e, 'shp_name1')}
                                                    onBlur={(e) => {props.fncBookingParent(booking)}}
                                                    validtype="eng" 
                                                    // required={true} 
                                                    required={("Y" === booking.user_shipper_yn)?true:(booking.shp_name1|| booking.shp_user_email || booking.shp_user_name)?true:false} 
                                                    feedid="shipper"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0">E-mail</Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_user_email"
                                                id="shp_user_email"
                                                maxLength="50"
                                                value={booking.shp_user_email?booking.shp_user_email:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_user_email')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="email" 
                                                // required={true} 
                                                required={("Y" === booking.user_shipper_yn)?true:(booking.shp_name1|| booking.shp_user_email || booking.shp_user_name)?true:false} 
                                                feedid="shipper"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Person Name</Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_user_name"
                                                id="shp_user_name"
                                                maxLength="17"
                                                value={booking.shp_user_name?booking.shp_user_name:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_user_name')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                // required={true} 
                                                required={("Y" === booking.user_shipper_yn)?true:(booking.shp_name1|| booking.shp_user_email || booking.shp_user_name)?true:false} 
                                                feedid="shipper"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0">Address</Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_address1"
                                                id="shp_address1"
                                                maxLength="35"
                                                value={booking.shp_address1?booking.shp_address1:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_address1')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                required={false} 
                                                feedid="shipper"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_address2"
                                                id="shp_address2"
                                                maxLength="35"
                                                value={booking.shp_address2?booking.shp_address2:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_address2')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                required={false} 
                                                feedid="shipper"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_address3"
                                                id="shp_address3"
                                                maxLength="35"
                                                value={booking.shp_address3?booking.shp_address3:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_address3')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                required={false} 
                                                feedid="shipper"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_address4"
                                                id="shp_address4"
                                                maxLength="35"
                                                value={booking.shp_address4?booking.shp_address4:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_address4')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                required={false} 
                                                feedid="shipper"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                                <Col xl="12" lg="12" md="12">
                                    <FormGroup className="mb-1">
                                        <Row>
                                            <Col className="pr-0 pt-1 col-2"><Label className="mb-0"></Label></Col>
                                            <Col>
                                            <InputValid 
                                                type="text"
                                                name="shp_address5"
                                                id="shp_address5"
                                                maxLength="35"
                                                value={booking.shp_address5?booking.shp_address5:''}
                                                onChange={(e)=>fncOnChange(e, 'shp_address5')}
                                                onBlur={(e) => {props.fncBookingParent(booking)}}
                                                validtype="eng" 
                                                required={false} 
                                                feedid="shipper"
                                            />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                            </Collapse>
                            <div className="text-center" onClick={() => setColl(!coll)}>
                                <div>         
                                    <Button className="p-0" color="link" id="linemore" onClick={() => setColl(!coll)} style={{height:'21px'}}>
                                        {coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
                                    </Button>
                                    <UncontrolledTooltip delay={0} target="linemore">{coll?'Close':'Open'}</UncontrolledTooltip>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
});

export default ShipperCard;