import React,{ useState, useEffect, useRef } from "react";
import {Row, Col, InputGroup,Label,Input, Card, CardSubtitle, CardBody, CardFooter, Button, UncontrolledTooltip, InputGroupAddon, InputGroupText} from "reactstrap";
import Select from 'react-select'
import Moment from 'moment';
import axios from "axios";
import { Link } from "react-router-dom";
import WeidongSR from './sr/AdminSRList';
import WeidongBKG from './bkg/AdminBKGList'
export default function AdminSRLIST(props) {

    return (
        <Row>
            <Col>
                <Row>
                    <Col>
                        <h3>부킹</h3>
                        <hr style={{borderTop:'3px  solid #fff'}}/>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col>
                        <WeidongBKG {...props}/>  
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>SR</h3>
                        <hr style={{borderTop:'3px  solid #fff'}}/>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col>
                        <WeidongSR {...props}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    )

}