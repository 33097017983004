/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect, forwardRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,Col, CardBody, Collapse,
     Button,FormGroup,Label, Card, UncontrolledTooltip, Popover } from "reactstrap";
import Select from "react-select";
import CargoBookmark from './CargoBookmark.js';
import HsCode from './HsCodePopup.js';
import GoodsArea from './GoodsArea.js';
import MarkArea from './MarkArea.js';
import * as validation from 'components/common/validation.js';
import axios from 'axios';
import InputValid from "components/CustomInput/InputValid.js";


const CargoCard = forwardRef((props,cargoFocus) => {

	const {bookmark,loadData,bookmark2,openWindow, lineCode,packCodeList, lineConfigDetail} = props;
	const [cargo, setCargo] = useState([]);
	const [propsData, setPropsData] = useState([]);
	// Collapse Flag
	const [coll, setColl] = useState(false);
	// modal 창을 위한 state
	const [open, setOpen] = useState(false);
	// const [packCodeList,setPackCodeList] = useState([]);
	const [popOpen,setPopOpen] = useState(false);
	
	const [hsCodeList,setHsCodeList] = useState([]);
	const [tap, setTap] = useState("1");
	const [cargoData, setCargoData] = useState({})
	const [markData, setMarkData] = useState({});
	const [goodsData, setGoodsData] = useState({});
	const [commodity, setCommodity] = useState('');
	useEffect(() => { 
		setCargo(loadData);
	},[loadData]);

	useEffect(() => { 
		setCommodity(props.loadData.commodity);
	},[props.loadData.commodity]);
	
	useEffect(() => {
		setColl(openWindow);
	},[openWindow]);
	
	useEffect(() => {
		if(props.userData) {
			hsCodeSearch();
		}
		// if(lineCode) {
		// 	axios.post("/shipper/selectLineCodeCargoPackType",{params:{line_code:lineCode}}).then(res => {
		// 		setPackCodeList(res.data);
		// 	});
		// }
	},[props.userData, lineCode]);

 	const toggle = () => {
		props.onLoadData("cg");
		setPropsData({
			...loadData,
			cargo_bookmark_name:'',
			cargo_bookmark_seq:'',
			cargo_pack_qty:cargo.cargo_pack_qty,
			cargo_pack_type:cargo.cargo_pack_type,
			cargo_hs_code:cargo.cargo_hs_code,
			cargo_total_weight:cargo.cargo_total_weight,
			cargo_total_volume:cargo.cargo_total_volume,
			mark_desc:'',
			goods_desc:''
		});
		setOpen(!open);
  	}
  

	const hsCodeSearch=()=>{
		axios.post("/shipper/getHsCodeGroupInfo").then(res => {
			setHsCodeList(res.data)
		});
	}
	const onInitData = () => {
		setPropsData({
			...propsData,
			cargo_bookmark_name:'',
			cargo_bookmark_seq:'',
			cargo_pack_qty:'',
			cargo_pack_type:'',
			cargo_hs_code:'',
			cargo_total_weight:'',
			cargo_total_volume:'',
			mark_desc:'',
			cargo_mark_bookmark_name:'',
			cargo_mark_bookmark_seq:'',
			goods_desc:'',
			cargo_goods_bookmark_name:'',
			cargo_goods_bookmark_seq:''
		});
	}
  
  	const onSaveBookmark =()=> {
		if(tap === "1") {
			if(cargoData.cargo_bookmark_name) {	
	            
				axios.post("/shipper/setUserCargoBookmark",{user_no:props.userData?props.userData.user_no:'',data:cargoData}).then(res => {
					props.onLoadData("cg");
					if(cargoData.cargo_bookmark_seq) {
						props.onAlert("success","작성한 BOOKMARK 가 수정 되었습니다.");
					} else {
						props.onAlert("success","작성한 BOOKMARK 가 저장 되었습니다.");  
					}
				}).catch(error => {
					props.onAlert("error",validation.ERR_MSG);
				});
			}
		} else if (tap === "2") {
			
			if(markData.cargo_mark_bookmark_name) {
				axios.post("/shipper/setUserMarkBookmark",{user_no:props.userData?props.userData.user_no:'',data:markData}).then(res => {
					props.onLoadData("mk");
					if(markData.cargo_mark_bookmark_seq) {
						props.onAlert("success","작성한 BOOKMARK 가 수정 되었습니다.");
					}  else {
						props.onAlert("success","작성한 BOOKMARK 가 저장 되었습니다.");
					}
		  	  	});
			}
		} else if( tap === "3") {
			if(goodsData.cargo_goods_bookmark_name) {
				
				axios.post("/shipper/setUserGoodsBookmark",{user_no:props.userData?props.userData.user_no:'',data:goodsData}).then(res => {
					props.onLoadData("gs");
					if(goodsData.cargo_goods_bookmark_seq) {
						props.onAlert("success","작성한 BOOKMARK 가 수정 되었습니다.");
					} else {
						props.onAlert("success","작성한 BOOKMARK 가 저장 되었습니다.");
					}
		  	  	});
			}
		} else {
			console.log("ERROR");
		}
	}

  
	const onChangeTap = (tap)=> {
		if(tap ==="1") {
			setTap("1");
			props.onLoadData("cg");
		} else if(tap ==="2"){
			setTap("2");
			props.onLoadData("mk");
		} else {
			setTap("3");
			props.onLoadData("gs");
		}
	}

	const onDelteCargo =()=>{
		if(tap === "1") {
			if(cargoData && cargoData.cargo_bookmark_seq) {
				axios.post("/shipper/setUserCargoBookmarkDel",{user_no:props.userData?props.userData.user_no:'',data:cargoData}).then(res => {
					onInitData();
					props.onLoadData("cg");
					props.onAlert("success","선택한 BOOKMARK 가 삭제 되었습니다.");
				});
			} else {
				props.onAlert("error","삭제 할 BOOKMARK를 선택해주세요.");
			}
		} else if(tap === "2") {
			if(markData && markData.cargo_mark_bookmark_seq) {
				axios.post("/shipper/setUserMarkBookmarkDel",{user_no:props.userData?props.userData.user_no:'',data:markData}).then(res => {
					onInitData();
					props.onLoadData("mk");
					props.onAlert("success","선택한 BOOKMARK 가 삭제 되었습니다.");
				});
			} else {
				props.onAlert("error","삭제 할 BOOKMARK를 선택해주세요.");
			}
		} else { 
			if(goodsData && goodsData.cargo_goods_bookmark_seq) {
				axios.post("/shipper/setUserGoodsBookmarkDel",{user_no:props.userData?props.userData.user_no:'',data:goodsData}).then(res => {
					onInitData();
					props.onLoadData("gs");
					props.onAlert("success","선택한 BOOKMARK 가 삭제 되었습니다.");
				});
			} else {
				props.onAlert("error","삭제 할 BOOKMARK를 선택해주세요.");
			}
		}
	}
	
	const onChangeCargo =(value)=> {
		if(value) {

			setCargo({
				...cargo,
				cargo_bookmark_seq:value.value,
				cargo_bookmark_name:value.label
			});
			if(value.value > 0) {
				axios.post("/shipper/getUserCargoBookmark",{user_no:props.userData?props.userData.user_no:'',seq:value.value}).then(res => {
					const list = {
						cargo_attached_yn:res.data.cargo_attached_yn?res.data.cargo_attached_yn:cargo.cargo_attached_yn,
						cargo_coastal_yn:res.data.cargo_coastal_yn?res.data.cargo_coastal_yn:cargo.cargo_coastal_yn,
						cargo_frozen_temp:res.data.cargo_frozen_temp?res.data.cargo_frozen_temp:cargo.cargo_frozen_temp,
						cargo_frozen_temp_unit:res.data.cargo_frozen_temp_unit?res.data.cargo_frozen_temp_unit:cargo.cargo_frozen_temp_unit,
						cargo_goods_bookmark_name:res.data.cargo_goods_bookmark_name?res.data.cargo_goods_bookmark_name:cargo.cargo_goods_bookmark_name,
						cargo_goods_bookmark_seq:res.data.cargo_goods_bookmark_seq?res.data.cargo_goods_bookmark_seq:cargo.cargo_goods_bookmark_seq,
						cargo_mark_bookmark_name:res.data.cargo_mark_bookmark_name?res.data.cargo_mark_bookmark_name:cargo.cargo_mark_bookmark_name,
						cargo_mark_bookmark_seq:res.data.cargo_mark_bookmark_seq?res.data.cargo_mark_bookmark_seq:cargo.cargo_mark_bookmark_seq,
						cargo_handling_code:res.data.cargo_handling_code?res.data.cargo_handling_code:cargo.cargo_handling_code,
						cargo_handling_frozen:res.data.cargo_handling_frozen?res.data.cargo_handling_frozen:cargo.cargo_handling_frozen,
						cargo_hs_code:res.data.cargo_hs_code?res.data.cargo_hs_code:cargo.cargo_hs_code,
						cargo_item_hs_code:res.data.cargo_item_hs_code?res.data.cargo_item_hs_code:cargo.cargo_item_hs_code,
						cargo_pack_qty: res.data.cargo_pack_qty?res.data.cargo_pack_qty:cargo.cargo_pack_qty,
						cargo_pack_type: res.data.cargo_pack_type?res.data.cargo_pack_type:cargo.cargo_pack_type,
						cargo_temp: res.data.cargo_temp?res.data.cargo_temp:cargo.cargo_temp,
						cargo_temp_unit: res.data.cargo_temp_unit?res.data.cargo_temp_unit:cargo.cargo_temp_unit,
						cargo_total_volume:res.data.cargo_total_volume?res.data.cargo_total_volume:cargo.cargo_total_volume,
						cargo_total_weight:res.data.cargo_total_weight?res.data.cargo_total_weight:cargo.cargo_total_weight,
						cargo_weight: res.data.cargo_weight?res.data.cargo_weight:cargo.cargo_weight,
						// goods_desc:res.data.goods_desc?res.data.goods_desc:cargo.goods_desc,
						goods_yn:res.data.goods_yn?res.data.goods_yn:cargo.goods_yn,
						mark_desc:res.data.mark_desc?res.data.mark_desc:cargo.mark_desc,
						mark_yn: res.data.mark_yn?res.data.mark_yn:cargo.mark_yn,
						
					}
					const mergeData = Object.assign(cargo,list);	  
					setCargo({...mergeData,'cargo_bookmark_seq':value.value,'cargo_bookmark_name':value.label});
					props.mergeData({...mergeData,'cargo_bookmark_seq':value.value,'cargo_bookmark_name':value.label});
					setColl(true);
				});
				// goods_desc 처리
				onChangeGoods(value);
			}
		}else {
			if( cargo.cargo_bookmark_seq) {

                setCargo({...cargo
					,cargo_bookmark_seq:null
					,cargo_bookmark_name:null
                    ,cargo_attached_yn: null
                    ,cargo_coastal_yn: null
                    ,cargo_frozen_temp: null
                    ,cargo_frozen_temp_unit: null
                    ,cargo_goods_bookmark_name: null
                    ,cargo_goods_bookmark_seq: null
                    ,cargo_handling_code: null
                    ,cargo_handling_frozen: null
                    ,cargo_hs_code: null
					,cargo_item_hs_code: null
					,cargo_pack_qty:null
					,cargo_pack_type:null
					,cargo_temp:null
					,cargo_temp_unit:null
					,cargo_total_volume:null
					,cargo_total_weight:null
					,cargo_weight:null
					,goods_desc:null
					,goods_yn:null
					,mark_desc:null
					,mark_yn:null
                });
				props.mergeData({
					...cargo
					,cargo_bookmark_seq:null
					,cargo_bookmark_name:null
					,cargo_attached_yn: null
                    ,cargo_coastal_yn: null
                    ,cargo_frozen_temp: null
                    ,cargo_frozen_temp_unit: null
                    ,cargo_goods_bookmark_name: null
                    ,cargo_goods_bookmark_seq: null
                    ,cargo_handling_code: null
                    ,cargo_handling_frozen: null
                    ,cargo_hs_code: null
					,cargo_item_hs_code: null
					,cargo_pack_qty:null
					,cargo_pack_type:null
					,cargo_temp:null
					,cargo_temp_unit:null
					,cargo_total_volume:null
					,cargo_total_weight:null
					,cargo_weight:null
					,goods_desc:null
					,goods_yn:null
					,mark_desc:null
					,mark_yn:null
				});
            }
		}
	}	

	const onChangeGoods = (value)=> {
		if(value) {
			if(value.value > 0) {
				axios.post("/shipper/getUserCargoRelation",{user_no:props.userData?props.userData.user_no:'',seq:value.value}).then( res=>{
					if(res.data){
						axios.post("/shipper/getUserGoodsBookmark",{user_no:props.userData?props.userData.user_no:'',seq:res.data.cargo_goods_bookmark_seq}).then(res => {
							let list;
							let goodsSplit = res.data[0].goods_desc.split('\n');
							if(lineConfigDetail?.goods_desc||lineConfigDetail?.goods_desc===''){
								// console.log(',.>>>>',res.data[0].goods_desc)
								list={...res.data[0],goods_desc:`${lineConfigDetail?.goods_desc?lineConfigDetail?.goods_desc+'\n':''}${res.data[0].goods_desc}`}
							}else{
								if(commodity && !goodsSplit[0].includes('COMMODITY')) {
									list={...res.data[0],goods_desc:`COMMODITY:${commodity}\n ${res.data[0].goods_desc}`}
								}else if(commodity && goodsSplit[0].includes('COMMODITY')) {
									goodsSplit[0]=`COMMODITY:${commodity}`;
									const goodsDescNEW=goodsSplit.join("\n");
									list={...res.data[0],goods_desc:goodsDescNEW}
								}else if(!commodity && goodsSplit[0].includes('COMMODITY')) {
									list={...res.data[0],goods_desc:res.data[0].goods_desc}
								}else {
									list={...res.data[0],goods_desc:`COMMODITY:\n ${res.data[0].goods_desc}`}
								}
							}
							onDataGoodsMerge(list);
						});
					}
				});
			} 
		}
	}

	  // 자식의 Data 적용
	const onBookMarkData = (step,data) => {
		if(step === "1") {
			setCargoData(data);
		} else if(step === "2") {
			setMarkData(data)
		} else {
			setGoodsData(data)
		}
		
	}
	// onChange evnet
	const onChangeReturnVal = (event,name) => {
		let list = {...cargo, [name]:(event.target.value.toUpperCase())||null};
		setCargo(list);
	}
	const onChangeReturnVal2 = (value,name) => { 
		let list = {...cargo, [name]:value};
		setCargo(list);
		props.mergeData(list);
	}
	// onBlur event 
	const onPropsReturn = ()=> {
		props.mergeData(cargo);
	}
	//21.05.06 변경
	const onDataMarkMerge =(data) => { 
		setCargo({...cargo,...data});
		props.mergeData({...cargo,...data}); 
	}
	//21.05.06 변경
	const onDataGoodsMerge =(data) => { 
		// console.log('onDataGoodsMerge',data)
		setCargo({...cargo,...data});
		props.mergeData({...cargo,...data}); 
	}
	return (
    	<>
			<Row id="Cargo">
				<Col xl="12" lg="12">
					<Card style={{zIndex:'6',border:'1px solid silver',borderRadius:'10px'}}>
						<CardBody className="pt-3 pb-0" >  
							<Row className="pb-2">
								<Col xl="2" className="mt-2 mb-0 col-5" style={{fontSize:'18px',color:'#696969',fontWeight:'600'}}>CARGO</Col>
								<Col xl="6" className="mt-4 mb-0">
									<span style={{fontWeight:'bold',color:'red',fontSize:'14px'}} >*수량 중량 변경시 CONTAINER 정보변경 필요</span>
								</Col>
								<Col>
									<Row>
										<Col className="col-10 pr-0" style={{zIndex:'500'}}>
											<Select
												className="react-select react-select-primary"
												name="carrierbookmark"
												value={{value:cargo.cargo_bookmark_seq?cargo.cargo_bookmark_seq:'',label:cargo.cargo_bookmark_name?cargo.cargo_bookmark_name:'선택'}}
												onChange={(value)=>onChangeCargo(value)}
												options={bookmark}
												ref={cargoFocus}
												placeholder="선택"
												isClearable={cargo.cargo_bookmark_seq?true:false}
											/>
										</Col>
										<Col className="col-2 pl-auto pr-auto">
											<Button className="pl-0 pr-0" color="link" id="cargobookmark" onClick={toggle.bind(this, 'B')}><i className="fa fa-bookmark-o fa-2x" /></Button>
											<UncontrolledTooltip delay={0} target="cargobookmark">Bookmark</UncontrolledTooltip>
										</Col>
									</Row>
								</Col>
							</Row>
							<Collapse isOpen={coll} className="mb-1">
								<hr className="mt-0"/>
								<Row style={{fontSize:'12px'}}>
									<Col xl="3" lg="12" md="12">
										<FormGroup className="mb-1">
											<Row>
												<Col xl="4" className="pr-0 pt-1 col-3">
													<Label className="mb-0">HS CODE</Label>
													<i id="hscode" className="fa fa-search fa-2X" style={{cursor:'pointer'}}/>
													<Popover className="popover-container" isOpen={popOpen} toggle={()=> setPopOpen(!popOpen)} placement="right-start" target="hscode" style={{zIndex:'9999'}}>
														<HsCode onClose={(e)=>setPopOpen(e)} onHsCodeData={hsCodeList} onSetHsCode={(data)=>{setCargo({...cargo, 'cargo_hs_code':data}); props.mergeData({...cargo, 'cargo_hs_code':data}); setPopOpen(false);}} {...props}/>
													</Popover>
												</Col>
												<Col>
													<InputValid 
														type="text"
														name="cargo_hs_code"
														id="cargo_hs_code"
														maxLength="6"
														minLength="6"
														value={cargo.cargo_hs_code?cargo.cargo_hs_code:''}
														onChange={(e)=>onChangeReturnVal(e, 'cargo_hs_code')}
														onBlur={onPropsReturn}
														validtype="number"
														required={true} 
														feedid="cargo"
													/>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="4" lg="12" md="12">
										<FormGroup>
											<Row>
												<Col xl="2" className="pr-0 pt-1 col-3">
													<Label className="mb-0">Package</Label>
												</Col>
												<Col>
													<Row>
														<Col className="col-8 pr-1" style={{zIndex:'2'}}>
															<Select
																className="customSelect"
																name="cargo_pack_type"
																value={{value:cargo.cargo_pack_type?cargo.cargo_pack_type:'',
																label:cargo.cargo_pack_type?
																	(packCodeList.findIndex(x=>x.value===cargo.cargo_pack_type)>=0)?
																			packCodeList[packCodeList.findIndex(x=>x.value===cargo.cargo_pack_type)].label:
																		'선택':'선택'
																}}
																getOptionLabel = {options=>options.label+" ["+options.value+"] "}
																onChange = {(value)=>onChangeReturnVal2(value.value,'cargo_pack_type')}
																options={packCodeList}
																	styles={{
																		control: provided => ({...provided,border:!cargo.cargo_pack_type?'1px solid red':'' }),
																		indicatorContainer: provided => ({...provided,color:''})
																	}}
															/>
															<InputValid 
																hidden
																type="text"
																name="cargo_pack_type"
																id="cargo_pack_type"
																maxLength="2"
																value={cargo.cargo_pack_type?cargo.cargo_pack_type:''}
																validtype="eng"
																required={true} 
																feedid="cargo"
																readOnly
															/>
														</Col>
														<Col className="col-4 pl-1">
															<InputValid 
																type="text"
																name="cargo_pack_qty"
																id="cargo_pack_qty"
																maxLength="8"
																value={cargo.cargo_pack_qty?cargo.cargo_pack_qty:''}
																onChange={(e)=>onChangeReturnVal(e, 'cargo_pack_qty')}
																onBlur={onPropsReturn}
																validtype="number"
																required={true} 
																feedid="cargo"
															/>
														</Col>
													</Row>
												</Col>
											</Row>
										</FormGroup>
									</Col>
									<Col xl="5" lg="12" md="12">
										<Row>
											<Col xl="6" className="col-12">
												<FormGroup className="mb-1">
													<Row>
														<Col xl="4" className="pr-0 pt-1 col-3">
															<Label className="mb-0">Weight</Label>
														</Col>
														<Col>
															<InputValid 
																type="text" 
																name="cargo_total_weight"
																id="cargo_total_weight"
																maxLength="18"
																invalid={!cargo.cargo_total_weight?true:false}
																value={cargo.cargo_total_weight?cargo.cargo_total_weight:''} 
																onChange = {(event)=>onChangeReturnVal(event,'cargo_total_weight')} 
																onBlur={onPropsReturn}
																feedid="cargo"
																inputgrouptext="KG"
																validtype="decimal"
																decimallength="3"
															/>
														</Col>
													</Row>
												</FormGroup>
											</Col>
											<Col xl="6" className="col-12">
												<FormGroup className="mb-1">
													<Row>
														<Col xl="4" className="pr-0 pt-1 col-3">
															<Label className="mb-0">Volume</Label>
														</Col>
														<Col>
															<InputValid 
																type="text" 
																name="cargo_total_volume"
																id="cargo_total_volume"
																maxLength="18"
																validtype="decimal"
																decimallength="3"
																invalid={!cargo.cargo_total_volume?true:false}
																value={cargo.cargo_total_volume?cargo.cargo_total_volume:''} 
																onChange = {(event)=>onChangeReturnVal(event,'cargo_total_volume')} 
																onBlur={onPropsReturn}
																feedid="cargo"
																inputgrouptext="CBM"
															/>
														</Col>
													</Row>
												</FormGroup>
											</Col>
										</Row>  
									</Col>  
								</Row>
								<hr className="mt-2 mb-2" />
								<Row>
									<Col xl="4">
										<Row>
											<Col>
												<Label className="mt-2" style={{fontWeight:'bold',fontSize:'15px',color:'#696969'}}>Mark & No</Label>
											</Col>
										</Row>
										<Row>
											<Col>
												<MarkArea   bookmark={bookmark2}
															propsMarkData={(data)=>onDataMarkMerge(data)} 
															mark={props.loadData}
															viewType="CARD"
															{...props} />
											</Col>
										</Row>
									</Col>
									<Col xl="8">
										<Row>
											<Col>
												<Label className="mt-2" style={{fontWeight:'bold',fontSize:'15px',color:'#696969'}}>Cargo Description</Label>
											</Col>
										</Row>
										<Row>
											<Col>
												<GoodsArea 
													propsGoodsData={(data)=>onDataGoodsMerge(data)} goods={props.loadData} type="MAIN"  {...props} />
												
											</Col>
										</Row>
									</Col>
								</Row>
							</Collapse>
						</CardBody>
						<Col className="text-center col-12 p-0" onClick={() => setColl(!coll)}>       
							<Button
								className="p-0"
								color="link"
								id="cargomore"
								onClick={() => setColl(!coll)}
								style={{height:'21px',marginBottom:'4px',width:'100%'}}>
								{coll?<i className="fa fa-caret-up fa-2x" />:<i className="fa fa-caret-down fa-2x" />}
							</Button>
							<UncontrolledTooltip delay={0} target="cargomore">{coll?'Close':'Open'}</UncontrolledTooltip>
						</Col>
					</Card>
				</Col>
			</Row>
			<Modal isOpen={open} toggle={toggle} className="pt-0" size="lg">
				<ModalHeader toggle={toggle}>Cargo BookMark</ModalHeader>
					<ModalBody className="pt-0 pb-0">
						<CardBody className="p-0 bg-white">
							<CargoBookmark 
								pack={packCodeList}
								bookmarkProps={propsData} 
								onHsCodeData={hsCodeList}
								onChangeTap={onChangeTap} 
								onPropsCargoBookmark={(step,data)=>onBookMarkData(step,data)}
								{...props}
							/>
						</CardBody>
					</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={onInitData}>NEW</Button>
					<Button color="primary" onClick={onSaveBookmark}>SAVE</Button>
					<Button color="primary" onClick={onDelteCargo}>DELETE</Button>
					<Button color="secondary" onClick={()=>setOpen(!open)}>CANCEL</Button>
				</ModalFooter>
			</Modal>
		</>
	);
});

export default CargoCard;