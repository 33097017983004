import { makeStyles } from '@material-ui/core/styles';
import React,{ useState, useEffect} from "react";
// @material-ui/core components
// core components
import Card from "muiComponents/Card/Card.js";
import CardHeader from "muiComponents/Card/CardHeader.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Icon from "@material-ui/core/Icon";
import CardIcon from "muiComponents/Card/CardIcon.js";
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import {Backdrop, CircularProgress, Typography} from '@material-ui/core';
import HighlightOff from '@material-ui/icons/HighlightOff';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoatOutlined';

const useStyles = makeStyles(theme => ({
    root: {
      width: '1300px',
      height: '640px'
    },
    backdrop:{
      zIndex:theme.zIndex.drawer +1,
      color:'#fff',
    },
    cardStyle:{minHeight:'80%'}
}));
  
export default function ShipMap(props) {
    // console.log(props)
    const {propsUrl} =props;
    const classes = useStyles();
    const [linkUrl,setLinkUrl] = useState("");
    // const [authCode, setAuthCode] = useState("");
    const [processOpen,setProcessOpen] = useState(false);
    const [loading,setLoading] = useState(false);
    useEffect(() => {
      if(!props.userData) {
        props.onAlert('error','로그인이 필요한 서비스입니다.')
        props.onClose();
        return ;
      }
      setProcessOpen(true);
      if(propsUrl){//imo로 가져올수있음
        setProcessOpen(false);
        setLinkUrl(propsUrl);
      }else{
        let {imo, map_start_day , sch_vessel_name}= props?.parameter;
        
        let destination = props?.parameter?.end_port || props?.parameter?.sch_pod ||'';
        // console.log( destination, props?.parameter)
        
        let sch_url = "";
        let appendUrl =  "";

        appendUrl +='&imoNo=';
        appendUrl += encodeURIComponent(imo);
        appendUrl += '&startDate=';
        appendUrl += map_start_day;
        if(destination){
        appendUrl += '&destination=';
        appendUrl += destination;}


        let authCode = sch_url+appendUrl;
        if( authCode ) {
            axios.post("/com/getShipMapInfo",{
              params: authCode,
              imo: props.parameter.imo,///imo 올바르지 않음,, 수정필요
              vesselName: props.parameter.sch_vessel_name,
              startDate: props.parameter.map_start_day,
              destination: destination
            }).then(res => {
              if(res.data.length > 0) {
                  // setTimeout(function() {
                      setProcessOpen(false);
                      setLoading(true);
                      setLinkUrl(res.data);
                  // },1000);
              }else{
                throw new Error('no Data')
              }
            }).catch(error=>{
              if( error.response?.data?.message ) {
                props.onAlert('error',error.response.data.message);
              } else {
                props.onAlert('error',"선박 조회가 불가합니다.");
              }
              props.onClose();
          });
        }
      }
    return () => {
        console.log('cleanup');
        };
    },[]);



    // useEffect(()=>{
    //   if( authCode ) {
    //     axios.post("/com/getShipMapInfo",{
    //       params: authCode,
    //       imo: props.parameter.imo,
    //       vesselName: props.parameter.sch_vessel_name,
    //       startDate: props.parameter.map_start_day,
    //       destination: props.parameter.start_port
    //     }).then(res => {
    //       if(res.data.length > 0) {
    //           setTimeout(function() {
    //               setProcessOpen(false);
    //               setLinkUrl(res.data);
    //           },1000);
    //       }
    //     }).catch(error=>{
    //       if( error.response.data.message ) {
    //         props.onAlert('error',error.response.data.message);
    //       } else {
    //         props.onAlert('error',"선박 조회가 불가합니다.");
    //       }
    //       props.onClose();
    //   });
    //   }
    // },[authCode]);
  
return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={processOpen}>
        <CircularProgress color="primary" />
      </Backdrop>
      <HighlightOff onClick={()=>props.onClose()} style={{color:'#7a7a7a',top:'10',right:'2',position:'absolute'}}/>
      <Card className={classes.cardStyle}>
          <CardHeader color="info" stats icon style={{paddingBottom:'2px'}}>
            <CardIcon color="info" style={{height:'56px'}}>
              <DirectionsBoatIcon style={{width:'26px',fontSize:'20px',lineHeight:'26px'}}>content_copy</DirectionsBoatIcon>
            </CardIcon>
            <Typography variant="h6" style={{flexGrow:'1',textAlign:'start',color:'#7a7a7a'}}>현재 선박 위치 조회</Typography>
          </CardHeader>
          <CardBody style={{paddingBottom:'2px', minHeight:'100%', display:'flex', width:'100%'}}>
            {/* <Grid item xs={12} sm={12} md={12} style={{minHeight:'100%'}}> */}
            {loading?<div style = {{width:'100%',height:'100%',alignItems:'center', display:'flex', justifyContent:'center', alignSelf:'center'}}> <CircularProgress color="primary" /></div>:<></>}
              {/* <form target="maplink" style={{width:'100%'}}>  */}
                  <iframe name="maplink" src={linkUrl} title={"tackingFrame"} 
                  width="100%" height="580" display='block' border='none' position="absolute" 
                  frameBorder="0" scrolling="auto" allowFullScreen
                  style={loading? {visibility:'hidden', height:'30px', width:'0px'}:{border:'none'}}
                  onLoad={()=>{setLoading(false);}}
                  ></iframe>
              {/* </form> */}
            {/* </Grid> */}
          </CardBody>
        </Card> 
    </div>
  );
}
  
  
  
  
  