/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row,Col, UncontrolledTooltip
    , CardBody, Button,FormGroup, Table, Label} from "reactstrap";
import axios from 'axios';
import * as validation from 'components/common/validation.js';
import InputValid from "components/CustomInput/InputValid.js";
import Select from "react-select";
//import { options } from 'headroom.js';

export default function OtherBookmark(props){
    // modal 창을 위한 state
    const [open, setOpen] = useState(false);
    const [other, setOther] = useState({});
    const [otherList, setOtherList] = useState([]);

    const {userData, serviceList} = props;
    useEffect(() => {
        setOtherList(props.otherList);
    },[props.otherList]);

    useEffect(() => {
        setOther(props.booking);
    },[props.booking]);
    const toggle = () => {
        setOpen(!open);
    }
    // 신규 추가 시
    const fncOther = () => {
        setOther({
            other_bookmark_seq: '',
            other_bookmark_name: '',
            sc_no: '',
            remark1: '',
            'trans_service_code': null,
            // 'shp_payment_type': null,
            'commodity': null
            // remark2: ''
        });
    }

    // save
    const saveOtherBookmark = () => {
        if( !userData ) {
            props.onNotiAlert("error",validation.NOTLOGIN_MSG);
        }
        if( !other.other_bookmark_name ) return false;
        
        if( other.other_bookmark_seq ) {
            //수정
            axios.post("/shipper/updateBookingOtherBookmark",{ user_no : userData.user_no,other }).then(res=>{
                props.selectBookingOtherBookmark();
                props.onAlert("success", validation.SAVE_MSG);
            });
        } else {
            //신규
            axios.post("/shipper/insertBookingOtherBookmark",{user_no : userData.user_no,other}).then(res=>{
                props.selectBookingOtherBookmark();
                props.onAlert("success", validation.SAVE_MSG);
            });
        }
    }
    // 삭제
    const deleteBookingOtherBookmark = (e) => {
        e.preventDefault();
        if( !other.other_bookmark_seq ) {
            props.onNotiAlert("danger", "삭제할 Bookmark를 선택하세요.");
            return false;
        }
        axios.post("/shipper/deleteBookingOtherBookmark",{ other }).then(res => {
            props.selectBookingOtherBookmark();
            props.onAlert("success",validation.DEL_MSG);
            setOther({});
        });
    }
    const fncOnChange = (e, key) => {
        e.preventDefault();
        setOther({...other, [key]:(e.target.value.toUpperCase())||null});
    }
    return (
        <>
            <Button className="pl-0 pr-0" 
                color="link" id="linebookmark"
                onClick={toggle.bind(this, 'B')}>
                    <i className="fa fa-bookmark-o fa-2x" />
            </Button>
            <UncontrolledTooltip delay={0} target="linebookmark">Bookmark</UncontrolledTooltip>
            <Modal isOpen={open} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Booking Bookmark</ModalHeader>
                <ModalBody style ={{textAlign:'left'}}>
                    <Row>
                        <Col>Bookmark List</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xl="12" lg="12" md="12">
                            <FormGroup style={{height:'150px',overflow:'auto'}} className="mb-0">
                                    <CardBody className="bg-white p-0">
                                        <Table className="mb-0" responsive hover size="sm">
                                            <thead>
                                                <tr>
                                                    <td className="p-2 bg-info">Bookmark</td>
                                                    <td className="p-2 bg-info">Sc Number</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {otherList.map((element,key)=>{
                                                return(
                                                    <tr key={key}
                                                        onClick={()=>{setOther(element)}}
                                                        style={element.other_bookmark_seq===other.other_bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}}>
                                                        <td>{element.other_bookmark_name}</td>
                                                        <td>{element.sc_no}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col>Bookmark Input</Col>
                    </Row> */}
                    <hr className="m-2"/>
                    <Row className="mb-3">
                    <Col xl="12" lg="12" md="12">
                            <FormGroup>
                                <Label className="mb-0">Bookmark Name</Label>
                                <InputValid 
                                    type="text"
                                    bsSize="sm"
                                    name="other_bookmark_name"
                                    id="other_bookmark_name"
                                    maxLength="50"
                                    value={other.other_bookmark_name?other.other_bookmark_name:''}
                                    onChange={(e)=>fncOnChange(e, 'other_bookmark_name')}
                                    validtype="text" 
                                    required={true}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xl="6" lg="6" md="12">
                            <FormGroup>
                                <Label className="mb-0">SC Number</Label>
                                <InputValid 
                                    type="text"
                                    bsSize="sm"
                                    name="sc_no"
                                    id="sc_no"
                                    maxLength="20"
                                    value={other.sc_no?other.sc_no:''}
                                    onChange={(e)=>fncOnChange(e, 'sc_no')}
                                    validtype="engNumber" 
                                    required={false}
                                />
                            </FormGroup>
                        </Col>
                        <Col xl="6" lg="6" md="6">
                            <FormGroup className="mb-1">
                                <Label className="mb-0">Term</Label>
                                <Select
                                    className="customSelect"
                                    // classNamePrefix="customSelect"
                                    name="trans_service_code"
                                    value={{value:other.trans_service_code?other.trans_service_code:'',
                                            label:other.trans_service_code?
                                            (serviceList.findIndex(x=>x.value===other.trans_service_code)>=0)?
                                            serviceList[serviceList.findIndex(x=>x.value===other.trans_service_code)].label:
                                                other.trans_service_code_name||'선택':
                                            '선택'
                                        }}
                                    onChange={(value)=>setOther({...other,'trans_service_code':value.value})}
                                    options={serviceList}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                    {!props?.lineConfigDetail?.bkg_goods_title&&
                        <Col xl="6" lg="6" md="6">
                            <FormGroup className="mb-1">
                                <Label className="mb-0">Commodity</Label>
                                <InputValid 
                                    type="text"
                                    bsSize="sm"
                                    name="commodity"
                                    id="Commodity"
                                    maxLength="140"
                                    value={other.commodity?other.commodity:''}
                                    onChange={(e)=>fncOnChange(e, 'commodity')}
                                    validtype="engNumber" 
                                    required={false}
                                />
                            </FormGroup>
                        </Col>}
                        <Col xl="6" lg="6" md="6">
                            <FormGroup>
                                <Label className="mb-0">Remark</Label>
                                <InputValid 
                                    type="text"
                                    bsSize="sm"
                                    name="remark1"
                                    id="remark1"
                                    maxLength="70"
                                    value={other.remark1?other.remark1:''}
                                    onChange={(e)=>fncOnChange(e, 'remark1')}
                                    validtype="koreanban" 
                                    required={false}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={fncOther}>New</Button>
                    <Button color="primary" onClick={saveOtherBookmark}>Save</Button>
                    <Button color="primary" onClick={(e)=>deleteBookingOtherBookmark(e)}>Delete</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}