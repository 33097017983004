
import React, { useState, useEffect } from 'react';
import { Row, Col,ButtonGroup,Input, Button, Card, CardHeader, CardBody, Label} from "reactstrap";
import Select from "react-select";
export default function GoodsBookmarkRelation (props){
    const [goodsBookmarkList, setGoodsBookmarkList] = useState([]);
    const [goodsRelationList, setGoodsRelationList] = useState([{'key':1}]);
    useEffect(() => {
        setGoodsBookmarkList(props.goodsBookmarkList);
        if( props.goodsRelationList.length > 0 ) {
            setGoodsRelationList(props.goodsRelationList);
        } else {
            setGoodsRelationList([{'key':1}]);
        }
    },[props]);

    // 콤보박스 Bookmark 선택할 경우
    const fncSelectBookmark = (e, k) => {

        if(goodsRelationList.findIndex(x=>x?.cargo_goods_bookmark_seq ==e?.cargo_goods_bookmark_seq)>-1){
            props.onAlert("danger", "이미 연결된 Bookmark입니다.");
            return;
        }
        goodsRelationList[k] = e||{'key' : k+1};

        setGoodsRelationList([...goodsRelationList]);
        props.fncOnBlurGoodsRelation([...goodsRelationList]);
    }
    
    // const fncSelectBookmark = (e, index) => {
    //     let chDup = false;
    //     goodsRelationList.forEach((n) => {
    //         if( n.cargo_goods_bookmark_seq === e.target.value ) {
    //             chDup = true;
    //             return;
    //         }
    //     });
    //     if( chDup ) {
    //         props.onAlert("danger", "Bookmark는 이미 추가되었습니다.");
    //         return false;
    //     }
    //     goodsRelationList.forEach((element, key)=>{
    //         if( key === index ) {
    //             goodsBookmarkList.forEach(( row, i )=> {
                    
    //                 if( e.target.value === row.cargo_goods_bookmark_seq ) {
    //                     goodsRelationList[key] = row;
    //                 } 
    //             });
    //             // 해당 정보를 Relation에 입력한다
    //             setGoodsRelationList([...goodsRelationList]);
    //             props.fncOnBlurGoodsRelation([...goodsRelationList]);
    //         }
    //     });
    // }
    const onAddCargo = ()=>{
        setGoodsRelationList([...goodsRelationList,{'key':goodsRelationList.length+1}]);
        props.fncOnBlurGoodsRelation([...goodsRelationList,{'key':goodsRelationList.length+1}]);
    }
    const onDelCargo = (key)=>{
        if( goodsRelationList.length === 1 ) {
            setGoodsRelationList([{'key':1}]);
            props.fncOnBlurGoodsRelation( [{'key':1}] );
        } else {
            if(key > -1) goodsRelationList.splice(key,1);
            setGoodsRelationList([...goodsRelationList]);
            props.fncOnBlurGoodsRelation([...goodsRelationList]);
        }
    }

    return (
        <>
            {/* Goods히든 */}
            {(props.bookingGoodsYN=='N')?<></>: 
            <Row>
                <Col>
                    <Label className="mt-2" style={{fontWeight:'bold',fontSize:'15px',color:'#696969'}}>{props?.lineConfigDetail?.bkg_goods_title||'Goods'}</Label>
                </Col>
                <Col>
                    <ButtonGroup className="pull-right pr-2">
                        <Button
                            className="pt-0 pb-0"
                            color="default"
                            outline
                            size="sm"
                            onClick={onAddCargo}
                        >추가
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
            } 
            {/* Goods히든 */}
            {(props?.bookingGoodsYN=='N')?<></>: 
             <Row>
                <Col>
                {goodsRelationList.map((data,index) =>
                    <Card className="no-transition" style={{border:'1px solid silver'}} key={index}>
                        <CardHeader className="pt-1 pb-1">
                            <Row>
                                <Col className="col-6">
                                    <Select 
                                        className="react-select react-select-primary"
                                        onChange={(e) => {
                                            fncSelectBookmark(e,index)
                                        }}
                                        // className="pt-0 pb-0"
                                        value={{
                                            value:data?.cargo_goods_bookmark_seq||'',
                                            label:data?.cargo_goods_bookmark_name||'사용안함',
                                        }}
                                        options={goodsBookmarkList}
                                        placeholder="사용안함"
                                        isClearable={data.cargo_goods_bookmark_seq?true:false}
                                    />
                                    {/* <Input 
                                        type="select"
                                        onChange={(e) => {
                                            fncSelectBookmark(e, index)
                                        }}
                                        className="pt-0 pb-0"
                                        value={data.cargo_goods_bookmark_seq?data.cargo_goods_bookmark_seq:'0'}>
                                        <option key={0} value={'0'}>사용안함</option>
                                        {(goodsBookmarkList.length>0)?goodsBookmarkList.map((element,i)=>{
                                            return(
                                                <option key={"goods"+i} value={element.cargo_goods_bookmark_seq}>
                                                    {element.cargo_goods_bookmark_name}
                                                </option>
                                            )
                                        })
                                        :<></>}
                                    </Input> */}
                                </Col>
                                <Col><button
                                className="close"
                                    type="button"
                                    onClick={() => onDelCargo(index)}
                                    >×</button></Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="pt-3 pb-3">
                            <Label className="form-control-sm form-control mb-0"  id='goods_desc1'>{data.goods_desc1?data.goods_desc1:''}</Label>
                            <Label className="form-control-sm form-control mb-0"  id='goods_desc2'>{data.goods_desc2?data.goods_desc2:''}</Label>
                            <Label className="form-control-sm form-control mb-0"  id='goods_desc3'>{data.goods_desc3?data.goods_desc3:''}</Label>
                            <Label className="form-control-sm form-control mb-0"  id='goods_desc4'>{data.goods_desc4?data.goods_desc4:''}</Label>
                            <Label className="form-control-sm form-control mb-0"  id='goods_desc5'>{data.goods_desc5?data.goods_desc5:''}</Label>
                        </CardBody>
                    </Card>)}
                </Col>
            </Row>
            } 
        </>
    );
}