import React from "react";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Snackbars from "muiComponents/Snackbar/Snackbar.js";
// import Button from "components/CustomButtons/Button.js";

export default function GlobalAlert(props) {
  const {message//={message}
        ,isOpen//={openAlert}
        ,isClose//={() => setOpenAlert(false)}
        ,alertColor//={status || 'danger'}}
        ,alertTime}
        =props;
  React.useEffect(() => {
    if(isOpen)setTimeout(()=>{isClose()},alertTime ||3000) ;

    // Specify how to clean up after this effect:
    return function cleanup() {
      // to stop the warning of calling setTl of unmounted component
      var id = window.setTimeout(null, 0);
      // while (id--) {
      //   window.clearTimeout(id);
      // }
    };
  },[isOpen]);
  const [tl, setTl] = React.useState(false);
  return (
    <>
      {/* <Button color="primary" onClick={() => {
          setTl(true);
          // use this to make the notification autoclose
          setTimeout(
            function() {
              setTl(false)
            },
            6000
          );
        }}>
        Top Left
      </Button> */}
    {isOpen&&
      <Snackbars
        icon={AddAlert}
        place="tc"
        color={alertColor=='error'
                  ? 'danger'
                  : alertColor}
        message={message||''}
        open={isOpen}
        closeNotification={isClose}
        close
      />}
    </>
  );
}