import React,{ useState, useEffect, useMemo ,useRef ,createRef} from "react";
import CardBody from "muiComponents/Card/CardBody.js";
import {
	Card,
	CardContent,
	// Input
} from "@material-ui/core";

import Button from "muiComponents/CustomButtons/Button.js";
import * as validation from 'components/common/validation.js';
import {
	Chat as Chat,
	KeyboardArrowDown as KeyboardArrowDownIcon,
	KeyboardArrowUp as KeyboardArrowUpIcon,
	Search as SearchIcon
}from "@material-ui/icons";
import axios from 'axios';
import ReactQuill from 'react-quill';
import { useHistory } from "react-router-dom";
import "assets/css/quillEditer.css";
import {Row,Col, Input, Badge, Tooltip, Label, FormGroup} from "reactstrap";
import {CustomDatePickerInput} from 'components/CustomInput/reactstrap/CustomInput.js';
const moment = require('moment');

export default function BoardWrite(props) {
	const { userData, location} = props;	
	const history = useHistory();
    const [title, setTitle] = useState("");
	const [value, setValue] = useState('');
    const [boardId, setBoardId] = useState("");
    const [files, setFiles] = useState([]);
    const [tooltipOpen,setTooltipOpen] = useState(false);
	const [lineCode] = useState('plism');
	const [popYn, setPopyn] = useState(false);
	const [endDate, setEndDate] =  useState(moment(new Date()).add(7,'days'));
	//quill에디터
	//TOP_FR_DATE, TOP_TO_DATE 
	const [topFlagYn, setTopFlagYn] = useState(false);
	const [topFrDate, setTopFrDate] = useState(moment(new Date()));
	const [topToDate, setTopToDate] = useState(moment(new Date()).add(7,'days'));
	const quillRef = useRef();
	const fileInput = createRef();

	// useEffect(()=> {
	// 	console.log(popYn);
    // },[popYn]);
   

	// Quill 에디터에서 사용하고싶은 모듈들을 설정한다.
	// useMemo를 사용해 modules를 만들지 않는다면 매 렌더링 마다 modules가 다시 생성된다.
	const modules = useMemo(() => {
		return {
		toolbar: {
			container: [
			['image'],
			[{ header: [1, 2, 3, false] }],
			['bold', 'italic', 'underline', 'strike', 'blockquote'],
			[{color:[]},{background:[]}]
			],
		},
		};
	}, []);
	// 위에서 설정한 모듈들 foramts을 설정한다
	const formats = [
		'image',
		'header',
		'bold',
		'italic',
		'underline',
		'strike',
		'blockquote',
		'color',
		'background',
		];
	useEffect(()=> {
		// console.log(location);
		if(location&&location.board_id) {
			setValue(location.content);
			setTitle(location.title);
			setBoardId(location.board_id);
			setEndDate(location.board_to||moment(new Date()).add(7,'days'));
			setPopyn(location.pop_yn==='Y'?true:false);
			setTopFlagYn(location.top_flag==='Y'?true:false)
            setTopFrDate(location.top_fr_date?moment(location.top_fr_date):moment(new Date()))
            setTopToDate(location.top_to_date?moment(location.top_to_date):moment(new Date()).add(7,'days'))
			if(location.attach_files) {
				setFiles(location.attach_files.map((value,index)=> {return {name:value.real_file_name,boardId:value.board_id,seq:value.board_file_seq,rename:value.file_name,path:value.file_path}}))
			}
		}
	},[]);
	const goBoardList = () => {
		history.push({
			pathname:"/svc/board",
		})
	}
	// const QnASave = () => {
	// 	if(password&&password.length!==4){
	// 		props.onAlert('error','비밀번호를 다시 입력해주세요.');
	// 		return;
	// 	}
	// 	if(!title){
	// 		props.onAlert('error','제목을 입력해주세요.');
	// 		return;
	// 	}
	// 	if(!value){
	// 		props.onAlert('error','내용을 입력해주세요.');
	// 		return;
	// 	}
	// 	if(!bbs){
	// 		props.onAlert('error','구분을 선택해주세요.');
	// 		return;
	// 	}

	// 	axios.post("/api/insertQnaDetail", {
	// 		bbs_id:bbs.value //게시판 아이디
	// 		,sUserId:userData.user_no//게시자 아이디
	// 		,sUserNm:userData.user_name//게시자 명
	// 		,ntt_sj:title //게시물 제목
	// 		,ntt_cn:value//게시물 내용
	// 		,ntt_no:null//답장글 순번
	// 		,reply_lc:null//답장 게시물 아이디
	// 		,atch_file_id:null//첨부 파일 명
	// 		,ntt_tp:null//게시물 분류
	// 		,qna_pw:password//비밀번호
	// 	}).then(res => {
	// 		if(res.data.rowsAffected>0){
	// 			props.onAlert('success','저장');
	// 			history.push({
	// 				pathname:"/svc/qna",
	// 			})
	// 		}else{
	// 			props.onAlert('error','저장을 실패하였습니다.');
	// 		}
	// 	})
	// }

	const handleClick = () => {
        if(files.length===2) {
            props.onAlert("error", "첨부파일은 최대 2개 까지 업로드 가능합니다.");
            return;
        }
        fileInput.current.click();
    };

    const handleImageChange = (e) => {
        e.preventDefault();
        
        if(!e.target.files[0]) {
            return false;
        }

        if(!/\.(hwp|doc|docx|ppt|pptx|xls|xlsx|txt|csv|jpg|jpeg|gif|png|bmp|pdf)$/i.test(e.target.files[0].name)) {
            props.onAlert('error', '적절하지 않은 파일 형식입니다.' );
            return false;
        }
        
        if(e.target.files[0].size > 5000000) {
            props.onAlert('error', '파일의 크기가 너무 큽니다.' );
            return false;
        }
        props.onAlert('success', '업로드 완료' );
        setFiles([...files,e.target.files[0]]);
    };
	const handleRemove = (num) => {
		// console.log(files[num-1],files)
        if( files[num].rename) {
            axios.post('/com/boardFileDelete',{param:files[num]}).then(
                res => {
                    if(res.statusText==="OK") {
                        if(res.data==="success") {
                            props.onAlert('success','삭제가 완료되었습니다.');
                            // setFiles(files.filter((value,index)=> index === num))
                            setFiles(files.filter((value,index)=> index !== num))
                        }else {
                            props.onAlert('error',res.data);
                        }
                    }
				}).catch(error => {
					props.onAlert("error",`삭제하는 데 ${validation.ERR_MSG} 관리자에 문의바랍니다.` ); 
					return false;
				});
        }else {
            // setFiles(files.filter((value,index)=> index === num-1))
            setFiles(files.filter((value,index)=> index !== num))
        }
        


        fileInput.current.value = null;
    };
	const saveBoard = () => {
        if(props.userData){
            if(title.length === 0) {
                props.onAlert('error', '제목을 입력해주세요.' );
                return;
            }
            if(value.length === 0) {
                props.onAlert('error', '내용을 입력해주세요.' );
                return
            }
			if((topFrDate > topToDate)
            // || moment(new Date()) > endDate
            ){
                props.onAlert("error",validation.DATE_MSG);
                return false;
            }

			const today= new Date();
			let params = { 
                userno:userData.user_no
                ,username:userData.user_name
                ,title:title
                ,content:value.toString('html')
				,plism:lineCode
                // ,weidong:lineCode ==='WDFC'?true:false
                // ,ablue:lineCode ==='VSS'?true:false
                // ,hanjoongferry:lineCode ==='CKF'?true:false
                // ,koreashipping:lineCode ==='CSX'?true:false
                // ,pandaexpress:lineCode ==='PEL'?true:false
                // ,staroceanline:lineCode ==='SIM'?true:false
                ,boardId:boardId
                ,boardEnd: moment(endDate).format('YYYYMMDD')
                ,popYn:popYn===true?'Y':'N'
            }
			if (topFlagYn){
                params = {...params
                    ,top_flag : 'Y'
                    ,top_fr_date : moment(topFrDate).format('YYYYMMDD')
                    ,top_to_date : moment(topToDate).format('YYYYMMDD')
                }
            }else{
                params = {...params
                    ,top_flag : 'N'
                    ,top_fr_date : null
                    ,top_to_date : null
                }
            }
            axios.post("/com/saveNotice", params).then(
                res=> {
                    if(res.statusText ==="OK") {
                        if(res.data) {
                            if(files) {
                                const formData = new FormData();
                                formData.append("boardId",res.data.board_id);
                                formData.append("userno", userData.user_no);
                                formData.append("file1",files[0]);
                                formData.append("file2",files[1]);
                                axios.post("/com/saveNoticeFiles",formData).then(
                                    res=>{
                                        if(res.statusText==="OK") {
                                            if(res.data && res.data.success === 1) {
                                                props.onAlert('success','저장이 완료되었습니다.');
                                                goToList();
                                            }else {
												props.onAlert('error','파일첨부를 실패하였습니다. 다시 시도해주세요');
                                            }
                                        }
									}).catch(error => {
										props.onAlert('error','파일첨부를 실패하였습니다. 다시 시도해주세요');
									});
                            }else {
                                props.onAlert('success','저장이 완료 되었습니다.');
                                goToList();
                            }
                        }
                    }else {
                        props.onAlert('error','지금은 저장 할 수 없습니다.')
                    }
				}).catch(error => {
					props.onAlert("error",`저장하는 데 ${validation.ERR_MSG} 관리자에 문의바랍니다.` ); 
					return false;
				});
		}else{
            props.onAlert('error',validation.NO_USER_MSG)
        }
    }      

	const goToList = () => {
		history.push({
			pathname:"/svc/board",
		})
	}

	// const onChangeDate = (date) => {
    //     if(moment().diff(date,'days')>0) {
	// 		console.log(moment().diff(date,'days'));
    //         props.onAlert('error','과거 날짜로 설정 하실 수 없습니다.');
    //         return;
    //     }
    //     setEndDate(date.format('YYYY-MM-DD'));
    // }

	return (
	  	<React.Fragment>
			<Card  className="m-4">
				<CardContent>
					<h4 style={{textAlignLast:'left'}}><Chat style={{color:'#00acc1'}}></Chat> 업무공지 작성</h4>
				</CardContent>
				<CardBody>
				<FormGroup className="mb-1 mt-4 pl-3" >
					<Row style={{height:'45px'}}>
						<Col className="  pr-0 col-2">
							<Label style={{fontWeight:'bold' ,height:'100%', display:'flex', alignItems:'center'}}>제목</Label>
						</Col>
						<Col>
							<Input value={title} onChange={(e)=>setTitle(e.target.value)}></Input>
						</Col>
					</Row>
					{/* <Row style={{height:'45px'}}>
						<Col className="pr-0 col-2 mb-0">
							<Label style={{margin:'0' ,fontWeight:'bold' ,height:'100%', display:'flex', alignItems:'center'}}> 공지 기간</Label>
						</Col>
						<Col className="col-4" style={{display:'flex', alignItems:'center'}}>
							<CustomDatePickerInput
								id="endDate"
								dateFormat="YYYY-MM-DD"
								timeFormat={false}
								value={endDate}
								onChange={(date)=>setEndDate(date)}
								{...props}/>
						</Col>
					</Row> */}
					<Row style={{height:'45px'}}>
						<Col className="pr-0 col-2 mb-0">
							<FormGroup style={{display:'flex', alignItems:'center',height:'100%', justifyContent:'space-between'}}>
								<Label style={{margin:'0' ,fontWeight:'bold' , display:'flex', alignItems:'center'}}>상단 게시</Label>
								<Input type="checkbox" checked={topFlagYn} onChange = {()=>setTopFlagYn(!topFlagYn)} 
									style={{position:'inherit',  }}/>
							</FormGroup>
						</Col>
						<Col className="col-8" style={{display:'flex', alignItems:'center'}}>
							<CustomDatePickerInput
								id="topFrDate"
								dateFormat="YYYY-MM-DD"
								timeFormat={false}
								value={topFrDate}
								onChange={(date)=>setTopFrDate(date)}
								inputProps={{
									disabled: !topFlagYn
								}}
								{...props}
								/>
							<div style={{width:'10%', textAlign:'center'}}  >~</div>
							<CustomDatePickerInput
								id="topToDate"
								dateFormat="YYYY-MM-DD"
								timeFormat={false}
								value={topToDate}
								onChange={(date)=>setTopToDate(date)}
								inputProps={{
									disabled: !topFlagYn
								}}
								{...props}
								/>
						</Col>
						<Col className="pr-0 col-1 mb-0">
							<Label style={{margin:'0' ,fontWeight:'bold' ,height:'100%', display:'flex', alignItems:'center'}}>팝업 유무</Label>
						</Col>
						<Col className="col-1" style={{display:'flex', alignItems:'center'}}>
							<FormGroup>
								<Input type="checkbox" checked={popYn} onChange = {()=>setPopyn(!popYn)} 
									style={{position:'inherit', margin:'auto' }}/>
							</FormGroup>
						</Col>
					</Row>
					<Row style={{height:'45px'}}>
						<Col className="  pr-0 col-2">
							<Label style={{fontWeight:'bold' ,height:'100%', display:'flex', alignItems:'center'}}> 첨부파일
								<Button  id="btn1" className="btn-link btn-icon" color='info' outline
								style={{margin:'0 5px'}}   onClick={handleClick}> 
									<i className='fa fa-flip-vertical fa-paperclip fa-lg' aria-hidden="true" >
										<input style={{display:'none'}} type="file" onChange={handleImageChange} ref={fileInput} />
									</i>
									<Tooltip placement="top" isOpen={tooltipOpen} target="btn1" toggle={() => setTooltipOpen(!tooltipOpen)}>
										<Col>
											<Row>
												<Col>
													<span style={{fontWeight:'bold', fontSize:'1rem'}}>허용 파일</span><span> : hwp, doc, docx, ppt, pptx, xls, xlsx, txt, csv, jpg, jpeg, gif, png, bmp, pdf </span>
													<br></br>
													<span style={{fontWeight:'bold', fontSize:'1rem'}}>파일 크기 제한</span><span> : 5MB</span>
												</Col>
											</Row>
										</Col>
									</Tooltip>
								</Button>
							</Label>
						</Col>
						<Col>
						{files.map((value,index) => {
							return(<>
								<Badge>{validation.textLengthOverCut(value.name,15,'....')}</Badge>
								<Button
									size='sm'
									color="danger"
									className="btn-round btn-link"
									onClick={() => handleRemove(index)}
									>
									<i className="fa fa-times" />
								</Button>
							</>)})}
						</Col>
					</Row>
				</FormGroup>
					{/* <Row className="mt-4">
						<Col xl="6" lg="9" md="9" sm="6" xs="6">
							<Input style={{width:'100%'}}placeholder="제목" value={title|| ''} onChange={(e)=>setTitle(e.target.value)}></Input>
						</Col>
						<Col xl="1" lg="3" md="3" sm="1" xs="1"  >
							<Row>
								<Col className="mt-2 center">
									<FormGroup check id="popYn">
									<Label check style={{marginBottom:'15px'}} >
										<input type="checkbox" checked={popYn} onChange = {()=>setPopyn(!popYn)}/><span className="form-check-sign" />
									</Label>
									</FormGroup>
									<UncontrolledTooltip delay={0} target="popYn">팝업 유무</UncontrolledTooltip>
								</Col>
							</Row>
						</Col>
						 <Col xl="5" lg="7" md="12" sm="12" xs="12">
							<Row>
								<Col className='search_option--number--text' xl="3" lg="3" md="3" sm="3" xs="3">
									공지 기간
								</Col>
								<Col xl="9" lg="9" md="9" sm="9" xs="9">
									<CustomDatePicker
										id="endDate"
										dateFormat="YYYY-MM-DD"
										timeFormat={false}
										value={endDate}
										onChange={(date)=>onChangeDate(date)}
										{...props}/>
								</Col>
							</Row>
						</Col>
						<Col xl="5" lg="5" md="5" sm="5" xs="5">
							<Row>
								<Col xl="4" lg="4" md="4" sm="4" xs="4">
									<input style={{display:'none'}} type="file" onChange={handleImageChange} ref={fileInput} />
									<Button
										id="btn1"
										className="btn-round pr-3 pl-3"
										onClick={handleClick}>
											파일 첨부 
									</Button>
									<Tooltip placement="top" isOpen={tooltipOpen} target="btn1" toggle={() => setTooltipOpen(!tooltipOpen)}>
										<Col>
											<Row>
												<Col>
													<span style={{fontWeight:'bold', fontSize:'1rem'}}>허용 파일</span><span> : hwp, doc, docx, ppt, pptx, xls, xlsx, txt, csv, jpg, jpeg, gif, png, bmp, pdf </span>
													<br></br>
													<span style={{fontWeight:'bold', fontSize:'1rem'}}>파일 크기 제한</span><span> : 5MB</span>
												</Col>
											</Row>
										</Col>
									</Tooltip>
								</Col>
								<Col xl="8" lg="8" md="8" sm="8" xs="8">
									{
										files.map((value,index) => {
											return(
													<Col key={index} style={{display:'inLine'}}>
														<Badge>{validation.textLengthOverCut(value.name,15,'....')}</Badge>
														<Button
															className="btn-round pr-1 pl-1 pt-1 pb-1"
															color="danger"
															onClick={() => handleRemove(index+1)}>
															<i className="fa fa-times" />
														</Button>
													</Col>
											)
										})
									}
								</Col>
							</Row>
						</Col>
					</Row> */}
					<Row>
						{/* <button onClick={onClickContents}>에디터 안의 내용들</button> */}
						<Col style={{height:"600px",padding:"0px"}}>
							<ReactQuill
								style={{width: "100%",height:"90%",borderBottom:"50px"}}
								ref={quillRef}
								theme='snow'
								placeholder='내용'
								value={value}
								onChange={(value)=>setValue(value)}
								modules={modules}
								formats={formats}
								/>
						</Col>
					</Row>
					<Row>
						<Col style={{textAlign:"right"}}>
							<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> goBoardList()}>목록</Button>
							<Button style={{paddingBottom:'8px'}}color="info" onClick={()=> saveBoard()}>게시물 등록</Button>
						</Col>
					</Row>
					{/* <TableContainer>
					<Table style={{borderTop:'2px solid #06b3ba'}}>
						<TableBody>
							
							<TableRow>
								<TableCell colSpan={4} style={{height: "600px",borderBottom: '1px solid rgba(0, 0, 0, 0)'}}>
								<button onClick={onClickContents}>에디터 안의 내용들</button> 
								<ReactQuill
									style={{height: "90%",borderBottom:"50px"}}
									ref={quillRef}
									theme='snow'
									placeholder='내용'
									value={value}
									// onChange={setValue}
									modules={modules}
									formats={formats}
      								/>
								</TableCell>
							</TableRow>
						</TableBody>
						<TableFooter>
								<TableRow>
									<TableCell align="right" colSpan={4} style={{borderTop:'2px solid #06b3ba'}}>
										<Button style={{paddingBottom:'8px',marginRight:'10px'}}color="info" onClick={()=> goQnA()}>목록</Button>
										<Button style={{paddingBottom:'8px'}}color="info" onClick={()=> QnAsave()}>게시물 등록</Button>
									</TableCell>
								</TableRow>
						</TableFooter>
					</Table>
					</TableContainer> */}
				</CardBody>
			</Card>
		</React.Fragment>
	);
  }
