import React , { useState, useEffect, useRef, useCallback} from "react";
import {Row,Col,CardBody,UncontrolledTooltip,Container,Form,Button,
    Card, Collapse} from "reactstrap";
import LoadCarrierImage from 'components/common/LoadCarrierImage.js'
const LogisviewPageHeader = (props)=>{
    const {
        carrierInfo 
        ,logoKey//line_logo_img or sch_logo_img 
        ,pageName
    } =props;

    return <Row>
        <Col className="ml-auto mr-auto" xl="12" lg="12" md="12" sm="12" >
            <h1 
                className="mt-1 text-start" 
                >
                <LoadCarrierImage logo={carrierInfo&&(carrierInfo[logoKey]||carrierInfo['sch_logo_img'])|| null} 
                classStyle={"img-rounded img-responsive"} 
                height={35} 
                />
                <strong style ={{marginLeft:'15px', fontSize:'28px' , fontWeight:'500'}}>
                    {`${carrierInfo&&carrierInfo.line_kr_name&& `${carrierInfo.line_kr_name} - `||''} ${pageName}`}</strong>
            </h1>
        </Col>
    </Row>
}

export default LogisviewPageHeader;