import React , { useState , useEffect, useRef, createRef, useCallback}from 'react'
import cn from 'classnames';
import { Link, useHistory} from "react-router-dom";
import { createTheme, makeStyles ,withStyles} from '@material-ui/core/styles';
import {Close, AttachFile, CloudUpload,} from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import { ExcelRenderer } from "react-excel-renderer";
import Filesaver from 'file-saver';
import axios from 'axios';
import * as validation from 'muiComponents/common/validation.js';
// core components
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import {IconButton,  TableContainer, Table, TableHead, TableRow
    , TableCell, TableBody,DialogContent, CircularProgress,
    RadioGroup,
	Radio,
	FormControlLabel,
	Modal,
    Dialog,
    DialogActions,} from "@material-ui/core"
import Button from "muiComponents/CustomButtons/Button.js";    
import {
    BackupOutlined as BackupOutLinedIcon,
    ArrowForwardIos as ArrowFoward,
    Error as IconError
} from "@material-ui/icons"
// css
import Style from './ExpTrackingAddModal.module.css';
import Clipboard from '@react-native-clipboard/clipboard';

const LogisviewCheckbox = withStyles({
    root:{
      color:'#5fb0b5',
      '&$checked': {
        color: '#5fb0b5',
    },
        padding:'2px'
    }
})((props)=><Checkbox color = 'default' {...props}/>);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background:'#ecf2f2',
        '& >*': {
            width:200,
        }
    },
    modalroot:{
        display:'flex'
        ,justifyContent:'center'
        ,flex:'none'
      },
    modalform2:{minWidth:'600px',width:'750px'},
    modalform1:{minWidth:'600px',width:'750px'},
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    stepIcon:{color:'yellow',
            '&$active':{color:'pupple'},
            '&$completed':{color:'red'},
        
    },
    root: {
        width: '100%',
    },
    booking_title: {
        boxShadow: 'unset'
    },
    paper: {
        marginTop: theme.spacing(3),
        padding:theme.spacing(2),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up(600+theme.spacing(3)*2)]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        }
    },
    paperInner: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    gridContainer: {
    padding:'30px'
    },
    divider: {
        marginTop:'10px',
        marginBottom:'20px',
        backgroundColor:'#00acc1',
    },
    box: {
        width: '100%',
        marginTop: theme.spacing(3),
        padding:theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    avatar: {
    backgroundColor: '#00ACC1',
    [theme.breakpoints.between("xs", "sm")]: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },
    [theme.breakpoints.between("md", "lg", "xl")]: {
        width: theme.spacing(5),
        height: theme.spacing(5)
    }
    },
    headerCell: {
        textAlign: "left",
        backgroundColor: "#f2fefd",
        width:'150px',
        padding:'7px',
    // fontSize: '15px',
    // fontWeight:'bold'
    },
    dataCell: {
        textAlign: "left",
        padding:'7px',
    },
    grid: {
        padding: '0px 10px !important',
    },
    gridLabel: {
        padding: '0px 10px !important',
        textAlign:'left',
        // marginTop:'12px',
        display: 'flex',
        alignItems:'center',
        marginBottom: '20px',
        wordBreak: 'keep-all'
    },
    modalCloseButton:{
        float:'right',
        padding:'0',
        minWidth:'21px',
        height:'21px'
    },
    modalTitle:{
        padding:'15px 24px 0 24px',
        textAlign:'center'
    },
    modal:{
        display:'flex'
        ,alignItems:'center'
        ,justifyContent:'center'
    },
    tableLine:{
        height:'300px',overflow:'auto',borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7',marginBottom:'10px'
    },
    tablecell:{
        padding:'5px 16px',textAlign:'start'
    },
    tableHeadercell:{
        padding:'10px 16px',textAlign:'start',fontWeight: '500'
    },
    paperHeader:{
        fontSize:'16px',fontWeight:'500' ,color:'#222',
    // marginBottom:'16px'
    },
    uploadDiv: {
    borderStyle:'dotted',
    color:'#00b1b7'
    },
    uploadComponent: {
    margin:'0 auto',
    textAlign:'center',
    textAlignLast:'center',
    },
    putComponent:{
    margin:'0 auto',
    textAlign:'center',
    textAlignLast:'center',
    },
    putDiv: {
    borderStyle:'dotted',
    color:'#36B8CF'
    },
    wrapButton: {
        // marginTop:'10px'
        display : 'flex',
        height : '100%',
        alignItems : 'center'
    },
    iconSize: {
    width:50,
    height:50
    },
    arrowIconSize: {
    width:15,
    height:15
    },
    titleName: {
    fontWeight:'bold',
    fontSize:'17px'
    },
    progressStyle: {
    marginTop:'50px',
    textAlign:'center',
    textAlignLast:'center',
    },
    useN:{
        color :'#7f7f7f',
        backgroundColor:'#f2f2f2'
    }
}));



export default function ExpTrackingServiceLineModal (props){
    const {userData,alertMessage, serviceLineModalOpen, setServiceLineModalOpen}=props;

    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect( ()=>{
        if(serviceLineModalOpen){
            selectServiceLineList();
        }
    },[serviceLineModalOpen]);

    const  selectServiceLineList = async()=>{
        setLoading(true);
        axios.post("/api/selectServiceLineList",{}
        ).then(res =>{
            const result = res.data;
            setRowsData(result)
        }).catch(err => {
            alertMessage('정보를 가져오는 데 실패하였습니다. 다시 시도하세요.','error');
        }).finally(()=> setLoading(false));
    }


    return ( <Dialog 
        onClose={()=>setServiceLineModalOpen(false)}
        color="primary"
        open={serviceLineModalOpen}
        maxWidth='md'
        >
        <Card style={{margin:'0',paddingTop:'0',paddingBottom:'0', width:'700px'}}>
            <CardBody >       
                <div style={{display: 'flex',	flexDirection: 'column',justifyContent: 'center'}}>		
                    <div className={Style.popHeader}>
                        <div className={Style.ptit}>
                            <strong>화물추적 제공 선사 리스트</strong>
                        </div>
                        <div style={{display:'flex', cursor:'pointer'}} >
                            <Close onClick={()=>setServiceLineModalOpen(false)}/>
                        </div>
                    </div>
                </div>
                <div className={Style.popCont}>
                    <div className={Style.rtwrap} style={{maxHeight:'500px',display: 'flex',	flexDirection: 'column',justifyContent: 'space-between'}}>
                        <div style={{display:'flex', marginBottom:'5px'}}>
                            <p>리스트 외 선사 추가 요청 시, 고객지원센터로 연락 바랍니다. (고객센터 : 1577-1172)</p>
                        </div>
                        {loading?<div style = {{display:'flex',justifyContent:'center',alignItems:'center', padding:'40px', width:'100%',height:'310px'}}>
                                        <CircularProgress/></div>
                        :<TableContainer className={classes.tableLine}>
                            <Table 	
                            stickyHeader aria-label="sticky table"
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size='small'
                            style ={{height:'100%'}}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.tableHeadercell} align = 'center'>Mo.</TableCell>
                                        <TableCell className={classes.tableHeadercell} align="center">선사명</TableCell>
                                        <TableCell className={classes.tableHeadercell} align="center">선사명 (영문)</TableCell>
                                        <TableCell className={classes.tableHeadercell} align="center">선사코드</TableCell>
                                        <TableCell className={classes.tableHeadercell} align="center">비고</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{height:'100%'}}> 
                                   {rowsData.length>0  
                                    ?rowsData.map((row, index) => {
                                        return (    
                                            <TableRow 
                                                className={classes.root}
                                                hover
                                                // role="checkbox"
                                                // aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={index}
                                                style ={{height:'30px'}}
                                                >
                                                <TableCell align="center" className={cn(classes.tablecell,row?.use_yn=='N'?classes.useN:'')} >{index+1}</TableCell>
                                                <TableCell align="center" className={cn(classes.tablecell,row?.use_yn=='N'?classes.useN:'')} >{row?.line_name}</TableCell>
                                                {row?.use_yn =='N' 
                                                ? <TableCell align="center" className={cn(classes.tablecell,row?.use_yn=='N'?classes.useN:'')} colSpan={3} style ={{textAlign:'center'}} >오픈 예정</TableCell>
                                                :<>
                                                    <TableCell align="center" className={cn(classes.tablecell,row?.use_yn=='N'?classes.useN:'')} > {row?.line_en_name}</TableCell>
                                                    <TableCell align="center" className={cn(classes.tablecell,row?.use_yn=='N'?classes.useN:'')} > {row?.line_code}</TableCell>
                                                    <TableCell align="center" className={cn(classes.tablecell,row?.use_yn=='N'?classes.useN:'')} > </TableCell></>}
                                            </TableRow>)})
                                        :(<TableRow  >
                                        <TableCell colSpan="6" align="center" style={{fontSize:"10px"}}>정보가 없습니다.</TableCell>	
                                    </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>  
                        }
                        {/* <div style ={{textAlign:'end', paddingRight:'end'}}>선택 : {hblSelected.length} / {hblData.length}</div> */}
                    </div>
                </div>
                 <DialogActions>
                {/*    <Button color="info"
                    // onClick={()=>{onHblSelecedSubmit(hblSelected)}}
                    >확인</Button>
                    <Button color='default' onClick={()=>{setServiceLineModalOpen(false)}}>취소</Button>*/}
                </DialogActions> 
            </CardBody>
        </Card>
    </Dialog>
    )
}