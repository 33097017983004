import React , { useState , useEffect, useRef, useCallback}from 'react'
import cn from 'classnames';
// import { Link, useHistory} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {Close, AttachFile, CloudUpload,} from '@material-ui/icons';
// import Dropzone from 'react-dropzone';
import { ExcelRenderer } from "react-excel-renderer";
import Filesaver from 'file-saver';
import axios from 'axios';
import * as validation from 'muiComponents/common/validation.js';
// core components
import GridContainer from "muiComponents/Grid/GridContainer.js";
import GridItem from "muiComponents/Grid/GridItem.js";
import Card from "muiComponents/Card/Card.js";
import CardBody from "muiComponents/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField,  TableContainer, Table, TableHead, TableRow
    , TableCell, TableBody,DialogContent, CircularProgress,
    // RadioGroup,
	// Radio,
	// InputLabel,FormControl,Select,
	Modal,} from "@material-ui/core"
import Button from "muiComponents/CustomButtons/Button.js";    
// import {
//     BackupOutlined as BackupOutLinedIcon,
//     ArrowForwardIos as ArrowFoward,
//     Error as IconError
// } from "@material-ui/icons"
// css
import Style from './ExpTrackingAddModal.module.css';
import Clipboard from '@react-native-clipboard/clipboard';

// const LogisviewCheckbox = withStyles({
//     root:{
//       color:'#5fb0b5',
//       '&$checked': {
//         color: '#5fb0b5',
//     },
//         padding:'2px'
//     }
// })((props)=><Checkbox color = 'default' {...props}/>);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background:'#ecf2f2',
        '& >*': {
            width:200,
        }
    },
    modalroot:{
        display:'flex'
        ,justifyContent:'center'
        ,flex:'none'
      },
    modalform2:{minWidth:'600px',width:'1000px'},
    modalform1:{minWidth:'600px',width:'780px'},
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
    // stepIcon:{color:'yellow',
    //         '&$active':{color:'pupple'},
    //         '&$completed':{color:'red'},
        
    // },
    root: {
        width: '100%',
    },
    booking_title: {
        boxShadow: 'unset'
    },
    paper: {
        marginTop: theme.spacing(3),
        padding:theme.spacing(2),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up(600+theme.spacing(3)*2)]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(3),
        }
    },
    paperInner: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    gridContainer: {
    padding:'30px'
    },
    divider: {
        marginTop:'10px',
        marginBottom:'20px',
        backgroundColor:'#00acc1',
    },
    box: {
        width: '100%',
        marginTop: theme.spacing(3),
        padding:theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    avatar: {
    backgroundColor: '#00ACC1',
    [theme.breakpoints.between("xs", "sm")]: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },
    [theme.breakpoints.between("md", "lg", "xl")]: {
        width: theme.spacing(5),
        height: theme.spacing(5)
    }
    },
    headerCell: {
        textAlign: "left",
        backgroundColor: "#f2fefd",
        width:'150px',
        padding:'7px',
    // fontSize: '15px',
    // fontWeight:'bold'
    },
    dataCell: {
        textAlign: "left",
        padding:'7px',
    },
    grid: {
        padding: '0px 10px !important',
    },
    gridLabel: {
        padding: '0px 10px !important',
        textAlign:'left',
        // marginTop:'12px',
        display: 'flex',
        alignItems:'center',
        marginBottom: '20px',
        wordBreak: 'keep-all'
    },
    modalCloseButton:{
        float:'right',
        padding:'0',
        minWidth:'21px',
        height:'21px'
    },
    modalTitle:{
        padding:'15px 24px 0 24px',
        textAlign:'center'
    },
    modal:{
        display:'flex'
        ,alignItems:'center'
        ,justifyContent:'center'
    },
    tableLine:{
        height:'300px',overflow:'auto',borderTop:'2px solid #00b1b7', borderBottom:'2px solid #00b1b7',marginBottom:'10px'
    },
    tablecell:{
        padding:'5px 16px',textAlign:'start'
    },
    tableHeadercell:{
        padding:'10px 16px',textAlign:'start',fontWeight: '500'
    },
    paperHeader:{
        fontSize:'16px',fontWeight:'500' ,color:'#222',
    // marginBottom:'16px'
    },
    uploadDiv: {
    borderStyle:'dotted',
    color:'#00b1b7'
    },
    uploadComponent: {
    margin:'0 auto',
    textAlign:'center',
    textAlignLast:'center',
    },
    putComponent:{
    margin:'0 auto',
    textAlign:'center',
    textAlignLast:'center',
    },
    putDiv: {
    borderStyle:'dotted',
    color:'#36B8CF'
    },
    wrapButton: {
        // marginTop:'10px'
        display : 'flex',
        height : '100%',
        alignItems : 'center'
    },
    iconSize: {
    width:50,
    height:50
    },
    arrowIconSize: {
    width:15,
    height:15
    },
    titleName: {
    fontWeight:'bold',
    fontSize:'17px'
    },
    progressStyle: {
    marginTop:'50px',
    textAlign:'center',
    textAlignLast:'center',
    }
}));



export default function ExpTrackingAddModal (props){
    const {Backdrop,sortNewExpTrkList,userData,alertMessage, modalOpen, setModalOpen, checkBlInfo}=props;
    const headers = ['mbl_no', 'hbl_no', 'bl_no'];
    let pasteRef = useRef(null); 
    let fileInput = React.createRef();

    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [selected, setSelected] = useState([]);

    const [checkDataYn, setCheckDataYn] = useState(false);
    const [loading, setLoading] = useState(false);

    const [carrierList, setCarrierList] = useState([]);
    const [refColOpen, setRefColOpen] = useState(false);
    // const [insertType , setInsertType] = useState(headers[0]);
    // let insertTypeRef = useRef(headers[0]);

    useEffect( ()=>{
        setTimeout(()=>{const anchor = document.querySelector('#modal-dialog');
        if (anchor) pasteRef.current.addEventListener('paste',  fetchCopiedText);
        },0);
        if(checkBlInfo.current.length>0 ){ //단 건 선택 => 선사선택 로직 추가
            setRowsData([...checkBlInfo.current]); 
            setCheckDataYn(true) ;
        }else{ 
            setRowsData([]);
        }
        if(modalOpen){
            selectCarrierCodeInfo();
        }
        setSelected([]);
        setRefColOpen(false);
        return ()=>{ pasteRef.current=null; fileInput.current = null;}
    },[modalOpen]);

    const selectCarrierCodeInfo =async()=>{
        await axios.post("/api/selectServiceLineList",{})
        .then(res => {
            let result =  res.data ||[];
            result = result.filter(x=> x.use_yn =='Y' )
            setCarrierList([...result])
        })
        .catch(err => {
        });
    }

    const onSubmit =(addItems)=>{      
        if(addItems.length<1||!userData?.klnet_id){
            alertMessage('등록 정보를 선택바랍니다.','error');
            return false;
        }
		if(userData) {
            setLoading(true);
            axios.post("/loc/insertUserBlRequest"
                ,{klnet_id : userData.klnet_id, user_id: userData.user_no,params: addItems}
            ).then(async res =>{
                let cnt = res.data?.addCnt||0;
                const result = await sortNewExpTrkList(addItems)
                
                setSelected([]);
                modalClose();
            }).catch(err => {
                if(err.response?.data=='E01'){ alertMessage('등록 정보를 확인바랍니다.','error');}
                else if(err.response?.data=='E02'){ alertMessage('승인처리된 수출적하목록신고 건만 등록가능합니다.','error');}
                else if(err.response?.data=='E03'){ alertMessage('선사 선택 필요','error');}
                else alertMessage('등록에 실패하였습니다.','error'); console.log(err)
            }).finally(()=>{
                setLoading(false);
            });
		} else {
            props.openLogin();
            setLoading(false);
		}
    }
    
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let filteredData = rowsData.filter(e => e?.desc=='Y'||e?.desc2=='Y')
            setSelected(filteredData);
            return;
        }
        setSelected([]);
    };
    

    const handleSelectList = (e, data) => {
        if(data){
            let newSelected = [];
            if(selected.findIndex(x=>x.rnum==data.rnum)<0) {
                newSelected = [...selected,data];
            } else {
                newSelected = selected.filter((x)=>x.rnum !== data.rnum);		
            }
        setSelected(newSelected);
        // console.log(newSelected)
        }
    };

    const isSelected = (rnum) => selected.findIndex(x=>x.rnum==rnum)!== -1; //selected.indexOf(name) !== -1;

    const fileHandler = (event) => {
        console.log('>>>>in')
        if(!userData?.klnet_id){ alertMessage(validation.NOTLOGIN_MSG,'error'); return false}
        
        let fileObj = event.target.files[0]; 
        if (!fileObj) {
            alertMessage('파일 첨부가 불가합니다. 다시 시도해주세요.','error');
            setCheckDataYn(false) ;
            return false; 
        };   
        if (!(fileObj.type === "application/vnd.ms-excel" 
            || fileObj.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ) ) { 
            alertMessage('엑셀 양식을 이용하여 첨부바랍니다.','error');
            setCheckDataYn(false) 
          return false; 
        }
        ExcelRenderer(fileObj, async(err, resp) => {
            if (err) { 
                alertMessage(String(err),'error');
                return false;
            } 
            setCheckDataYn(true) ;
            setLoading(true);
            let result = resp.rows.slice(2);
            if(result.length>100){alertMessage('100건까지 입력 가능합니다.','error'); result.splice(100)}
            result = result.map((val) => {
                return {mbl_no : val[0]||null, hbl_no :val[1]||null, ref_no :val[2]||null}
            });
            onBlData(result);
            setRefColOpen(true);
        });
        return false; 
    }
    
    const onBlData = async(data)=>{
        if(!userData){props.onAlert('error', validation.NOTLOGIN_MSG); setLoading(false); return; }
        checkBlInfo.current=[];
        await axios.post("/loc/selectBlData",{
            params: data, klnet_id : userData.klnet_id}
        ).then(res =>{
            const list = res.data||[];
            setRowsData(list.sort((a,b)=>a.rnum -b.rnum)) ;
            
            //등록가능 체크가 디폴트
            let filteredData = list.filter(e => e?.desc=='Y'||e?.desc2=='Y')
            setSelected(filteredData);

            setCheckDataYn(true);
        }).catch(err => {
            console.log(err)
            alertMessage(validation.FAIL_CHECK_MSG,'error');
        }).finally(()=>{
            setLoading(false);
        });
    }

    const onDownloadSampleFile = () => {
        if(!userData){props.onAlert('error', validation.NOTLOGIN_MSG); return;}
        
        axios.post("/shipper/downloadbyPath",
        {filePath : '/OWNER/downloadFiles/trk_excel_sample.xlsx'
        , fileName:'traking excel sample.xlsx'}, {responseType:'blob',})
        .then(res => {
            Filesaver.saveAs(new Blob([res.data]),'traking excel sample.xlsx');
        }).catch(err => {
            console.log(err)
            props.onAlert('error', '다운로드에 실패하였습니다. 관리자 문의바랍니다.');
        })
	}
    
    const fetchCopiedText =  useCallback(async (e) => {
        setRefColOpen(false);
        const text = await Clipboard.getString();
        copyTextToRowsData(text)
    },[modalOpen]);
    // },[insertType]);

    const copyTextToRowsData = useCallback((text) => {
        if( text ) {
            setRowsData([]);
            let rowList = text.split('\n');
            let tempList = [];
            if(rowList.length>100){alertMessage('100건까지 입력 가능합니다.','error'); rowList.splice(100)}
            rowList.forEach((row, index)=>{
                let convRow = [];//[mbl,hbl,bl]
                row = row.replace("\r", "") 
                if(row.includes('\t')){ //탭구분자있으면 mbl
                    convRow = row.split('\t').filter(Boolean);
                }else{
                    convRow[2]=row//없으면 bl no
                }
                // else{insertTypeRef.current==headers[1]?convRow[1]=row: convRow[0]=row}
                if(row.trim().length === 0 ||convRow.length ==0) return ;
                let json = generateJson(convRow, index);
                tempList.push(json);
            }); 
            setLoading(true);
            onBlData(tempList);
        }
    },[modalOpen]);
    // },[insertType]);
    
    const generateJson = (row, index)=> {
        let json = {};
        for (let i in headers) {
            if(row[i])  json[headers[i]] = row[i]//.replace("\r", "") 
            else        json[headers[i]] = null;
        }
        return json;
    }

    // const changeInsertMode=(e)=>{
    //     // setInsertType(e.target.value);
    //     // insertTypeRef.current = e.target.value;
    //     pasteRef.current.focus();
    // }

    const modalClose = ()=>{
        setModalOpen(false); 
        checkBlInfo.current=[];
        setCheckDataYn(false);
    }

    const checkCarrierVal = async(e, data, row, index)=>{
        let no = row?.bl_no ||row?.mbl_no;
        let check = {};


        if(!data?.line_mbl_prefix){check={...row, desc2 : '선사 B/L 정보 없음', customs_line_code:''}}
        else if(data?.line_mbl_prefix =='ALL'){check ={...row, desc2 : 'Y'}}
        else{
            const regex_prefix = new RegExp('^'+data?.line_mbl_prefix ,'i');
            const matchedLinecode = no.match(regex_prefix);
            if(!matchedLinecode){
                check={...row, desc2 : '선사 B/L 정보 없음', customs_line_code:''}
            }else{
                check ={...row, desc2 : 'Y'}
            }
        }

        if(check?.desc2 =='Y'){//중복체크하기
            await axios.post("/loc/checkExistYn",{bl_no:no, line_code:data?.line_code, klnet_id : userData.klnet_id})
            .then(res => {
                check = {...check, ...res.data}
                console.log(check)
            }).catch(err => {
                console.log(err)
                props.onAlert('error', '조회 중 오류가 발생했습니다. 관리자 문의바랍니다.');
                return;
            });
        }

        //등록가능이면 체크박스 체크하기
        if(check?.desc2 =='Y' ) setSelected([...selected, check])
        else  setSelected(selected.filter((x)=>x.rnum !== row.rnum) ) ;
        
        const ind = rowsData.findIndex(x=>x.rnum == row.rnum)
        let list  =[...rowsData];
        console.log(rowsData[ind])
        list[ind] = {...rowsData[ind],...check}
        console.log(list[ind])
        setRowsData(list);
    }

    return (  <Modal
        className={classes.modal}
        open={modalOpen}
        onClose={()=>modalClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{timeout:500}}>
    <DialogContent className={cn(classes.modalroot , refColOpen?classes.modalform2 : classes.modalform1)} id="modal-dialog" > 
        <GridContainer justifyContent="center">	
            <Card className={classes.cardSignup} style={{margin:'0',paddingTop:'0',paddingBottom:'0'}}>
                <CardBody style={{paddingTop:'15px',paddingBottom:'15px'}}>       
                    <div style={{display: 'flex',	flexDirection: 'column',justifyContent: 'center'}} >		
                        <div className={cn(Style.wrapper, Style.ptype)}>
                            <div className={Style.popHeader}>
                                <div className={Style.ptit}>
                                    <strong>B/L {checkBlInfo.current.length>0?'':'일괄'} 등록</strong>
                                </div>
                                <div style={{display:'flex', cursor:'pointer'}} >
                                    <Close onClick={() => modalClose()} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={Style.popCont}>
                        <div className={cn(Style.rtwrap,Style.li)}>
                            <dl>
                                <dt>
                                {checkBlInfo.current.length>0
                                ?<strong>※ MBL의 경우, 선사를 선택하여 등록하시기 바랍니다. (선사 자동 확인 불가)
                                    <br/>※ HBL 번호 등록은 KL-Net으로 적하목록 신고 건만 가능합니다. </strong>
                                :<strong>※ HBL 번호 등록은 KL-Net으로 적하목록 신고 건만 가능합니다. 
                                    <br/> [MBL 번호 등록은 무관] (고객센터 : 1577-1172)</strong>}
                                    <div style={{display:'flex'}}>
                                        <Button color="info" size ='sm' startIcon={<CloudUpload/>}  style={{fontSize:'12px'}} onClick={()=>{fileInput.current.click();}} >
                                            엑셀 첨부
                                            <input type="file"  style={{display:'none'}}  onChange={(event)=>fileHandler(event)} ref={fileInput} />
                                        </Button>
                                        <Button color="info" size ='sm' startIcon={<AttachFile/>}  style={{fontSize:'12px'}} onClick={()=>onDownloadSampleFile()} >
                                            일괄 등록 엑셀 양식
                                        </Button> 
                                    </div>
                                </dt>
                            </dl> 
                            <GridContainer>
                                 {/* onClick={()=>{  pasteRef.current.focus();}}> */}
                                <GridItem xs={12} sm={12} md={12}>
                                    {/* <FormControl style={{marginLeft:'10px', position:"center"}} component="div">
                                        <RadioGroup row aria-label="position" name="position" defaultValue="mbl_no" onChange={changeInsertMode}
                                        value={insertType}
                                            >
                                            <FormControlLabel
                                                value={headers[0]}
                                                control={<Radio color="primary" size="small" />}
                                                label={<span style={{fontSize:'smaller', color:'#000000'}}>Master BL</span>}
                                                style={{margin:'0px'}}
                                                />
                                                
                                            <FormControlLabel
                                                value={headers[1]}
                                                style={{margin:'0px'}}
                                                control={<Radio color="primary" size="small" />}
                                                label={<span style={{fontSize:'smaller', color:'#000000'}}>House BL</span>}
                                                />
                                        </RadioGroup>
                                    </FormControl>	 */}
                                </GridItem>
                            <div style ={{width:'0', height:'0', overflow:'hidden'}}>
                                <input ref = {pasteRef} autoFocus/>
                            </div>
                            {loading?<div style ={{display:'flex',justifyContent:'center',alignItems:'center', padding:'40px', width:'100%',height:'310px'}}>
                                        <CircularProgress/></div>
                            :checkDataYn ? 
                                <GridItem xs={12} sm={12} style ={{display:'flex',alignItems:'center'}}>
                                    <div className={Style.agreeScrolls}>
                                        <div className={Style.reqWrap}>
                                            <div className={Style.lt}>
                                                <TableContainer className={classes.tableLine}>
                                                    <Table 	
                                                    stickyHeader aria-label="sticky table"
                                                    className={classes.table}
                                                    aria-labelledby="tableTitle"
                                                    size='small'
                                                    style ={{height:'100%'}}
                                                    >
                                                        <TableHead  onClick={()=>{  pasteRef.current.focus();}}>
                                                            <TableRow>
                                                                <TableCell padding="checkbox" className={classes.tableHeadercell}
                                                                // style={{paddingTop:'0',paddingBottom:'0',borderBottomWidth:'3px',fontWeight:'bold',fontWeight:'500',color:'#717172',borderBottom:'2px solid #00b1b7'}} 
                                                                >
                                                                <Checkbox /*헤더 전체 checkBox */
                                                                    indeterminate={selected.length > 0 && selected.length <  rowsData.filter(e => (e?.desc=='Y'||e?.desc2=='Y')).length}
                                                                    checked={rowsData.filter(e => (e?.desc=='Y'||e?.desc2=='Y')).length > 0 && selected.length ===  rowsData.filter(e => (e?.desc=='Y'||e?.desc2=='Y')).length}
                                                                    disabled={rowsData.filter(e => (e?.desc=='Y'||e?.desc2=='Y')).length <1}
                                                                    onChange={handleSelectAllClick}
                                                                    inputProps={{ 'aria-label': 'select all' }}
                                                                />
                                                                </TableCell>
                                                                <TableCell className={classes.tableHeadercell} align="center">MBL No.</TableCell>
                                                                <TableCell className={classes.tableHeadercell} align="center">HBL No.</TableCell>
                                                                {refColOpen&&<TableCell className={classes.tableHeadercell} align="center">Ref No.</TableCell>}
                                                                <TableCell className={classes.tableHeadercell} align="center">확인</TableCell>
                                                                <TableCell className={classes.tableHeadercell} align="center">선사</TableCell>
                                                                <TableCell className={classes.tableHeadercell} align="center">상태</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody style={{height:'100%'}}> 
                                                            {rowsData.length>0 //|| emptyRows > 0 
                                                            ?rowsData.map((row, index) => {
                                                                const isItemSelected = isSelected(row.rnum);
                                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                                let selectedItem=isItemSelected&&selected.find(x=>x.rnum==row.rnum)
                                                                return (    
                                                                    <TableRow 
                                                                        className={classes.root}
                                                                        hover
                                                                        role="checkbox"
                                                                        aria-checked={isItemSelected}
                                                                        tabIndex={-1}
                                                                        key={index}
                                                                        selected={isItemSelected}
                                                                        style ={{height:'30px'}}
                                                                        >
                                                                        <TableCell  component="th" id={labelId} scope="row"   padding="checkbox" 
                                                                            onClick={(event) => {(row?.desc=='Y'||row?.desc2=='Y')&&handleSelectList(event, row)}}>
                                                                            <Checkbox
                                                                            checked={isItemSelected}
                                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                                            color='secondary'
                                                                            disabled={row?.desc!=='Y'&&row?.desc2!=='Y'}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell align="center" className={classes.tablecell} onClick={()=>{  pasteRef.current.focus();}}  >
                                                                        {row?.desc_hbl=='Y' &&row?.desc_mbl ?
                                                                        <><p style ={{textDecoration:'line-through'}}>{row?.desc_mbl}</p><p>(MBL 확인 불가)</p></>
                                                                        :row?.mbl_no}
                                                                        {/* {row?.mbl_no} */}
                                                                        </TableCell>
                                                                        <TableCell align="center" className={classes.tablecell} onClick={()=>{  pasteRef.current.focus();}}  >
                                                                            {row?.hbl_no
                                                                            ? (row?.desc=='Y'||row?.desc2=='Y' )&&row?.desc_hbl == 'N' 
                                                                                ?<><p style ={{textDecoration:'line-through'}}>{row.hbl_no}</p><p>(HBL 확인 불가)</p></>
                                                                                :row.hbl_no 
                                                                            : ( row.cnt_hbl ? `${row?.cnt_hbl} 건` :'')}
                                                                        </TableCell>
                                                                        {refColOpen&&<TableCell align="center" className={classes.tablecell} onClick={()=>{  pasteRef.current.focus();}}  >{row?.ref_no||''}</TableCell>}
                                                                        <TableCell align="center" className={classes.tablecell} onClick={()=>{  pasteRef.current.focus();}}  >
                                                                            {row?.desc=='Y'||row?.desc2=='Y'
                                                                            ? <strong style={{color:'#00acc1', }}>O</strong> 
                                                                            : <strong style={{color:'#c00000', }}>X</strong> }
                                                                        </TableCell>
                                                                        <TableCell align="center" className={classes.tablecell} 
                                                                            style = {{width : row?.mbl_no && (row?.desc =='선사 선택 필요'||row?.desc2 =="선사 B/L 정보 없음")?'145px':'50px'}} 
                                                                            >
                                                                            {row?.mbl_no && (row?.desc =='선사 선택 필요'||row?.desc2 =="선사 B/L 정보 없음")?
                                                                            // <FormControl fullWidth>
                                                                                <Autocomplete
                                                                                    id="searchCarrier"
                                                                                    options = {carrierList}
                                                                                    onChange={(e, data)=> checkCarrierVal(e, data, row, index)}
                                                                                    // value={row?.customs_line_code}
                                                                                    getOptionLabel = { option =>option?.line_name?option.line_name:''}
                                                                                    renderInput={params => (<TextField {...params} placeholder='선사 선택' label='' style ={{fontSize : '0.875rem'}}/>)}
                                                                                    fullWidth={false}
                                                                                    disableClearable={true}
                                                                                />
                                                                                // </FormControl>
                                                                                :row?.customs_line_code||''
                                                                                // <FormControl fullWidth>
                                                                                // <Select 
                                                                                // native
                                                                                // id = "areaSelect"
                                                                                // //disabled={tapNum != 0}
                                                                                // // value={area}
                                                                                // label=""
                                                                                // // onChange={handleArea}
                                                                                // style = {{fontSize:'inherit', fontFamily:'inherit'}}
                                                                                // >
                                                                                // {carrierList.length>0&&carrierList.map(x=>
                                                                                //     <option value={x?.line_code}>{x?.line_name}</option>
                                                                                // )}
                                                                                // </Select>
                                                                                // </FormControl>
                                                                        }</TableCell>
                                                                        <TableCell align="center" className={classes.tablecell} style ={{textAlign:'center'}} onClick={()=>{  pasteRef.current.focus();}}>
                                                                            {(row?.desc=='Y'||row?.desc2=='Y' )
                                                                            ? row?.desc_hbl == 'N' 
                                                                                ? <p style ={{textAlign:'center'}}>MBL 등록 가능</p>
                                                                                :row?.desc_hbl=='Y' &&row?.desc_mbl 
                                                                                    ? <p style ={{textAlign:'center'}}>등록 가능 <br/>(MBL : {row?.mbl_no})</p>
                                                                                    : '등록 가능' 
                                                                            : row?.desc2 || row?.desc || '등록 불가'}
                                                                        </TableCell>
                                                                    </TableRow>)})
                                                                :(<TableRow  >
                                                                <TableCell colSpan={refColOpen?'7':"6"} align="center" style={{fontSize:"10px"}} onClick={()=>{  pasteRef.current.focus();}}>일치하는 정보가 없습니다.</TableCell>	
                                                            </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>  
                                                <div style ={{textAlign:'end', paddingRight:'end'}}>선택 : {selected.length} / {rowsData.length}</div>
                                            </div>
                                        </div>
                                    </div>
                                </GridItem>
                                : 
                                <GridItem xs={12} sm={12} style ={{display:'flex',alignItems:'center', justifyContent:'center'}} onClick={()=>{  pasteRef.current.focus();}}>
                                    <Card className={classes.cardSignup} style={{margin:'15px',paddingTop:'0',paddingBottom:'0'}}>
                                        <CardBody style={{paddingTop:'15px',paddingBottom:'15px'}}>     
                                            <div style={{textAlign:'center', fontSize:'14px'}}> 텍스트 붙여넣기 혹은 엑셀 파일 첨부가 가능합니다. </div>
                                        </CardBody>
                                    </Card>
                                </GridItem>
                                }
                            </GridContainer>
                        </div>
                    </div>
                    <div className={Style.popBtnWrap} >
                        <button type="button" className={Style.rtbutton} onClick={()=>{onSubmit(selected)}}>
                            <span>등록</span>
                            <img src={process.env.PUBLIC_URL  + `/assetsNew/image/ico/ico-req-arr.png`}/>
                        </button> 
                        <button type="button" className={Style.rtbutton} style = {{background:'#999'}} 
                        onClick={()=>modalClose()}>
                            <span>취소</span>
                        </button> 
                    </div>
                </CardBody>
            </Card>
        </GridContainer>
    </DialogContent></Modal>
    )
}