import React, {useState, useEffect} from 'react';
import GridItem from "muiComponents/Grid/GridItem.js";
import GridContainer from "muiComponents/Grid/GridContainer.js";
import Button from "muiComponents/CustomButtons/Button.js";
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
// import Typography from 'views/Components/Typography';
// import classes from '*.module.css';
import {useStyles} from 'views/Booking/BKG/styles';
import {
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    ListItemText,
    List, ListItem,Typography,
    Box,
    Divider,
    ListItemSecondaryAction,
    Grid,
    InputLabel,
    Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
    } from '@material-ui/core';
import {ViewComfy,  Close} from "@material-ui/icons";



const userStylesMain = makeStyles((theme) => ({
    root: {
        width:'600px',
        // width: '100%',
        // maxHeight: 840,
        height: '100%',
        minHeight:'200px'
    },
    primary: {
        fontWeight:'bold'
    }
}))

export default function LocalAddress( props ) {
    const style = userStylesMain();
    const classes = useStyles();
    const [open, setOpen] = useState(props.open);
    const itemsPerPage = 5;
    const [address, setAddress] = useState(props.params);//검색keyword
    const [addressList, setAddressList] = useState([]);
    // const [common, setCommon] = useState({});
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0)
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [jsonData, setJsonData] = useState({});
    const [useDetailAddr, setUseDetailAddr] = useState('');
    const [detailShow,setDetailShow] = useState(false);

    // 부모의 open, params 변경됐을 경우
    useEffect(()=>{
        setAddress( props.params );
        setOpen(props.open);
    }, [props.open, props.params]);

    // 페이징처리 변경됐을 경우
    useEffect(()=>{
        if(addressList.length>0&&totalCount>0)localAddress();
    }, [page]);

    // const reload = ()=> {
    // 	props.reload();
    // }

    /**
     * 행안부 주소 API 호출
     * 비로그인 호출 URL : /api/localAddress
     */
    const localAddress = () => {
        if( !address || address.length<2){props.alertMessage('검색어는  두글자 이상  입력되어야 합니다.'); return false};
        axios.post("/api/localAddress"
            ,{
                address:address
                ,currentPage:page
                // ,countPerPage
                ,resultType:'json'
                ,keyword:address
            }
            
        ).then(res => {
            // console.log(res);
            const result = res.data;
            const resultC =result&&result.common||null;
            const resultJ =result&&result.juso||null;
            if(resultC&&resultC.errorCode !== '0') {
                props.alertMessage('[주소 검색 오류] 관리자에게 문의하시기 바랍니다.');
                return false;
            }
            if(resultC&&resultJ.length>0){
                setAddressList(resultJ);
                setPage(Number(resultC.currentPage));
                setTotalCount(Math.ceil(resultC.totalCount/itemsPerPage));
            }else{
                props.alertMessage('검색된 내용이 없습니다.');
                reset();
            }
        }).catch(err => {
            console.log(err, err.message)
            props.alertMessage('[주소 검색 오류] 관리자에게 문의하시기 바랍니다.');
        });
    }

    const listItemClick = (event , index)=>{
        setSelectedIndex(index);
        setJsonData( addressList[index] );
        setDetailShow(true)
    }

    const handleChange = (event, value) => {
        setPage(Number(value));
    }

    const onDoubleClickItem = (event, value) => {
        props.setReturnAddress({...jsonData,useDetailAddr:useDetailAddr});
        props.setOpen(false);
        setAddress("");
        reset();
    }

    const handleClose=() => {
        reset();
        setAddress("");
        props.setOpen(false);
        setOpen(false);
    }

    const reset = ()=>{
        // setAddress("");검색키워드는 따로
        setAddressList([]);
        setPage(1);
        setTotalCount(0);
        setSelectedIndex(0);
        setJsonData({});
        setUseDetailAddr('');
        setDetailShow(false);
    }

    // Enter Event Addr
    const fncOnKeyDownAddr = (e) => {
	    if( "Enter" === e.key &&!detailShow ) {
		    if( "" === e.target.value) return false;
		    localAddress();
        }
    }
    
    return (
        <Dialog
            open={open}
            keepMounted
            // style={{width:"600px"}}
            onClose={handleClose}
            >
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                style={{display:'flex',justifyContent:'space-between'}}
                // className={classes.modalHeader}
            >
                <h4 >
                {/* <ViewComfy fontSize={'medium'} style={{marginRight: '20px'}}/> */}
                도로명 주소 검색
                </h4>
                
                <Button
                justIcon
                // className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                size="sm"
                onClick={()=>{handleClose()}}
                ><Close />
                </Button>
            </DialogTitle>
            <DialogContent className={style.root}>
                {/* <DialogContentText style ={{fontSize:'16px',fontWeight:'600' ,color:'#222',}}>
                    도로명 주소 검색
                </DialogContentText> */}
                <GridContainer>
                    <GridItem xs={10} ms={10} md={10} >
                        <TextField 
                            variant="outlined"
                            size="small"
                            label="주소검색"
                            placeholder="예) 도로명(역삼로 153), 건물명(케이엘넷빌딩), 지번(역삼동738-14)"
                            value={address||''}
                            onChange={(e)=>{setAddress(e.target.value); reset();}}
                            fullWidth
                            onKeyDown={fncOnKeyDownAddr} />
                    </GridItem>
                    <GridItem xs={2} ms={2} md={2} >
                        <Button color="info" onClick={localAddress} style={{width:'100%', margin:0}}>조회</Button>
                    </GridItem>
                    <Divider/>
                    {!detailShow?
                    // {!detailShow&&addressList&&addressList.length > 0?
                    <GridItem xs={12} ms={12} md={12} >
                        <List component="nav" aria-label="main mailbox folders">
                            {addressList.length > 0?
                            addressList
                            // .slice((page-1)*itemsPerPage, page*itemsPerPage)
                            .map((item, index)=> (
                                <ListItem
                                    key={index}
                                    button
                                    selected={selectedIndex === index}
                                    onClick={(event)=>listItemClick(event, index)}>
                                        <ListItemText
                                            // onDoubleClick={onDoubleClickItem}
                                            primary={<Typography className={style.primary}>{item.roadAddr}</Typography>}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography
                                                        component="span"
                                                        variant="body2"
                                                        color="textPrimary">
                                                            {item.jibunAddr}
                                                    </Typography>
                                                </React.Fragment>
                                            }
                                        >    
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <Typography className={style.primary}>{item.zipNo}</Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                            )):
                            <ListItem>
                                <ListItemText
                                    primary={<Typography className={style.primary}>검색한 내용이 없습니다.</Typography>}
                                >    
                                </ListItemText>
                            </ListItem>}
                        </List>
                        <Divider/>
                        <Box component="span">
                            <Pagination
                                count={totalCount}
                                page={page}
                                onChange={handleChange}
                                defaultPage={1}
                                // color="default"
                                size="small"
                                showFirstButton
                                showLastButton>
                            </Pagination>
                        </Box>
                    </GridItem>
                :''}
                {detailShow&&jsonData?
                    <Grid container style={{margin:'15px 5px 0'}}>
                        <GridItem xs={12} ms={12} md={12} >
                            {/* <Grid container> */}
                                <Table style={{maxWidth:'100%',tableLayout:'fixed',borderTop:'2px solid #06b3ba',borderBottom:'2px solid #06b3ba'}}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{backgroundColor:'#f2fefd'}}>도로명주소</TableCell>
                                            <TableCell colSpan={7} >{jsonData.roadAddrPart1}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{backgroundColor:'#f2fefd'}}>상세주소입력</TableCell>
                                            <TableCell colSpan={7} >  
                                                <TextField  label='' onChange={e => setUseDetailAddr(e.target.value)}
                                                    value={useDetailAddr}
                                                size="small" fullWidth />
                                                {jsonData.roadAddrPart2}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            {/* </Grid> */}
                        </GridItem>
                    </Grid>
                     :''} 
                </GridContainer>
            </DialogContent>
            {detailShow&&jsonData?
            <DialogActions style={{margin:'auto'}}>
                <Button size="lg" color="primary" onClick={onDoubleClickItem}>주소 입력</Button>
            </DialogActions>
            :''} 
        </Dialog>
    );

}