/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState,useEffect } from 'react';
import { Row,Col, CardBody, FormGroup, Table,
    TabContent, TabPane, Nav, NavItem, NavLink,Label } from "reactstrap";
import Cargo from "./Cargo.js";
import GoodsArea from "./GoodsArea.js";
import MarkArea from "./MarkArea.js";
import axios from 'axios';
//import HsCode from './HsCodePopup.js';
import InputValid from "components/CustomInput/InputValid.js";

export default function CargoBookmark(props){

	const {bookmark,bookmarkProps,bookmark2,bookmark3,pack} =props;
	const [hTabs, setHTabs] = useState("1");

	const [cargo,setCargo] = useState({});
	const [mark,setMark] = useState({});
	const [goods,setGoods] = useState({});
	
	useEffect(() => {
		setCargo(bookmarkProps); 
		setMark(bookmarkProps);
		setGoods(bookmarkProps);
	},[bookmarkProps]);

	
	const onHandleSetTap = (data) => {
		if(data === "1") {
			setCargo(bookmarkProps);
		} else if(data === "2") {
			setMark({}); 
		} else {
			setGoods({});
		}
		
		setHTabs(data);
		props.onChangeTap(data);
	}
	
	const onClickSelected = (tap,data) => { 
		if(tap === "1") {
			axios.post("/shipper/getUserCargoRelation",{user_no:props.userData?props.userData.user_no:'',seq:data.cargo_bookmark_seq}).then(res => { 
				const mergeData = Object.assign(data,res.data);
				setCargo(mergeData);
				props.onPropsCargoBookmark(tap,mergeData);
			});
		} else if( tap === "2") {
			setMark(data);
			props.onPropsCargoBookmark(tap,data);
		} else {
			setGoods(data);
			props.onPropsCargoBookmark(tap,data);
		} 
	}
  
  	const onHandleReturnVal = (event,name) => { 
		if (hTabs === "2") {
			let list = {...mark, [name]:(event.target.value.toUpperCase())||null};
			setMark(list);
		} else {
			let list = {...goods, [name]:(event.target.value.toUpperCase())||null};
			setGoods(list);
		}
  	}
  
  	const onPropsCargoBookmark =(data) =>{ 
		if( hTabs === "1") {
			setCargo(data);
		  	props.onPropsCargoBookmark(hTabs,data); 
	  	} else if (hTabs === "2") {
		  	props.onPropsCargoBookmark(hTabs,mark); 
	  	} else if (hTabs ==="3") {
		  	props.onPropsCargoBookmark(hTabs,goods); 
	  	} else {
		  	console.log("error");
	  	}
	}
  	//21.05.06 변경
	const onDataGoodsMerge =(data) => { 
		let list = {...goods, ...data};
		setGoods(list);
		props.onPropsCargoBookmark("3",list);
	}
	const onDataMarkMerge =(data) => {  
		let list = {...mark, ...data};
		setMark(list);
		props.onPropsCargoBookmark("2",list);
	}
  
  	return (
    	<>
	    	<div className="nav-tabs-navigation text-left mb-3">
		    	<div className="nav-tabs-wrapper">
		      		<Nav id="tabs" role="tablist" tabs>
		        		<NavItem>
							<NavLink
								className={hTabs === "1" ? "active" : ""}
								onClick={() => onHandleSetTap("1")}>
		            			Cargo
		          			</NavLink>
		        		</NavItem>
		        		<NavItem>
							<NavLink
								className={hTabs === "2" ? "active" : ""}
								onClick={() => onHandleSetTap("2")}>
									Mark
							</NavLink>
						</NavItem>
		        		<NavItem>
							<NavLink
								className={hTabs === "3" ? "active" : ""}
								onClick={() => onHandleSetTap("3")}>
									Goods
							</NavLink>
		        		</NavItem>
		      		</Nav>
		    	</div>
		  	</div>
		  	<TabContent activeTab={"hTabs" + hTabs}>
	          	<TabPane tabId="hTabs1">
		          	<Row className="m-0">
	              		<Col>Cargo BookMark List</Col>
	              	</Row>
			      	<Row className="m-0">
			          	<Col xl="12" lg="12" md="12" className="p-0">
			              	<FormGroup style={{height:'150px',overflow:'auto'}} className="mb-0">
			                	<CardBody className="bg-white p-0">
									<Table className="mb-0" responsive hover size="sm">
										<thead>
											<tr>
												<td className="p-2 bg-info">No.</td>
												<td className="p-2 bg-info">Bookmark Name</td>
												<td className="p-2 bg-info">MARK</td>
												<td className="p-2 bg-info">GOODS</td>
											</tr>
										</thead>
										<tbody>
										{(bookmark.length > 0 ) && bookmark.map((data,key) =>
											<tr key={key} onClick={()=>onClickSelected("1",data)} style={data.cargo_bookmark_seq===cargo.cargo_bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}}>
												<td className="p-1">{key+1}</td>
												<td className="p-1">{data.cargo_bookmark_name}</td>
												<td className="p-1">{data.mark_yn}</td>
												<td className="p-1">{data.goods_yn}</td>
											</tr>
										)}
			                            </tbody>
									</Table>
								</CardBody>
			              	</FormGroup>
			          	</Col>
			      	</Row>
			      	<hr/>
			      	<Cargo cargoProps={cargo} view={true} relation={false} propsData={onPropsCargoBookmark} type="BOOKMARK" pack={pack} {...props}/> 
				</TabPane>
	          	<TabPane tabId="hTabs2">
					<Row className="m-0">
	              		<Col>Marks BookMark List</Col>
		          	</Row>
		          	<Row className="m-0">
		              	<Col xl="12" lg="12" md="12">
							<FormGroup style={{height:'150px',overflow:'auto'}} className="mb-0">
								<CardBody className="bg-white p-0">
									<Table className="mb-0" responsive size="sm">
										<thead>
											<tr>
												<td className="p-2 bg-info">No.</td>
												<td className="p-2 bg-info">Bookmark Name</td>
											</tr>
										</thead>
										<tbody>
										{(bookmark2.length > 0 ) && bookmark2.map((data,key) =>
											<tr key={key} onClick={()=>onClickSelected("2",data)} style={data.cargo_mark_bookmark_seq===mark.cargo_mark_bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}}>
												<td className="p-1" >{key+1}</td>
												<td className="p-1" >{data.cargo_mark_bookmark_name}</td>
											</tr>
										)}
										</tbody>
									</Table>
								</CardBody>
		                  	</FormGroup>
		              	</Col>
		          	</Row>
		          	<hr/>
			        <Row>
					    <Col xl="5" lg="5" md="12">
							<FormGroup>
								<Label className="mb-0">Bookmark Name</Label>
								<InputValid 
									type="text"
									name="cargo_mark_bookmark_name"
									id="cargo_mark_bookmark_name"
									maxLength="35"
									value={mark.cargo_mark_bookmark_name?mark.cargo_mark_bookmark_name:''}
									onChange={(e)=>onHandleReturnVal(e, 'cargo_mark_bookmark_name')}
									onBlur={onPropsCargoBookmark}
									validtype="text"
									required={true} 
									feedid="cargo"/>
							</FormGroup>
					    </Col>
						<Col xl="12" lg="12" md="12">
							<Label className="mb-0">Mark&No</Label>
							<MarkArea 
								view={false}
								propsMarkData={(data)=>onDataMarkMerge(data)}
								mark={mark}
								relation={true}
								{...props} />
						</Col>
					</Row>
				</TabPane>
				<TabPane tabId="hTabs3">
					<Row className="m-0">
						<Col>Goods BookMark List</Col>
					</Row>
					<Row className="m-0">
						<Col xl="12" lg="12" md="12">
							<FormGroup style={{height:'150px',overflow:'auto'}} className="mb-0">
								<CardBody className="bg-white p-0">
									<Table className="mb-0" responsive hover size="sm">
										<thead>
											<tr>
												<td className="p-2 bg-info">No.</td>
												<td className="p-2 bg-info">Bookmark Name</td>
											</tr>
										</thead>
										<tbody>
										{(bookmark3.length > 0 ) && bookmark3.map((data,key) =>
											<tr key={key} onClick={()=>onClickSelected("3",data)} style={data.cargo_goods_bookmark_seq===goods.cargo_goods_bookmark_seq?{backgroundColor:'aliceblue'}:{backgroundColor:''}}>
												<td className="p-1" >{key+1}</td>
												<td className="p-1" >{data.cargo_goods_bookmark_name}</td>
											</tr>
										)}
										</tbody>
									</Table>
								</CardBody>
							</FormGroup>
						</Col>
					</Row>
					<hr/>
					<Row>
						<Col xl="5" lg="5" md="12">
							<Label className="mb-0">Bookmark Name</Label>
							<InputValid 
								type="text"
								name="cargo_goods_bookmark_name"
								id="cargo_goods_bookmark_name"
								maxLength="35"
								value={goods.cargo_goods_bookmark_name?goods.cargo_goods_bookmark_name:''}
								onChange={(e)=>onHandleReturnVal(e, 'cargo_goods_bookmark_name')}
								onBlur={onPropsCargoBookmark}
								validtype="text"
								required={true} 
								feedid="cargo"
							/>
						</Col>
						<Col xl="12" lg="12" md="12">
							<Label className="mb-0">Description</Label>
							<GoodsArea 
								view={true} 
								propsGoodsData={(data)=>onDataGoodsMerge(data)} relation={true} type="BOOKMARK" goods={goods} {...props} />
						</Col>
					</Row> 
				</TabPane>
			</TabContent>
		</>
	);
}